import { StyleSheet } from 'react-native'

import { Colors, Metrics, Fonts, ApplicationStyles } from 'APP/Themes'

const imageWidth = Metrics.screenWidth <= 420 ? 150 : 300
const imageHeight = Metrics.screenWidth <= 420 ? 100 : 200
export default StyleSheet.create({
  ...ApplicationStyles.screen,
  popoverBg: {
    width: '100%',
    height: '100%',
    backgroundColor: Colors.appBg,
    position: 'absolute',
  },
  popoverWindow: {
    backgroundColor: Colors.contrastElementBg,
    top: Metrics.tabBarHeight,
    margin: Metrics.baseMargin,
    padding: Metrics.baseMargin,
    borderRadius: Metrics.baseMargin,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    elevation: 2,
    alignContent: 'center',
    display: 'flex',
  },
  popoverContent: {
    margin: Metrics.baseMargin,
    flexDirection: 'column',
    display: 'flex',
  },
  closeButton: {
    borderWidth: 0,
    borderRadius: 0,
    alignSelf: 'flex-end',
  },
  closeIcon: {
    fontSize: 30,
    color: Colors.white,
    borderRadius: 0,
  },
  tooltip: {
    alignSelf: 'flex-end',
    height: 24,
    width: 24,
  },
  popImageContainer: {
    margin: Metrics.baseMargin,
    borderRadius: Metrics.baseMargin / 2,
    borderWidth: 1,
    borderColor: Colors.black,
    overflow: 'hidden',
    width: imageWidth + 2,
    alignSelf: 'center',
  },
  popImage: {
    resizeMode: 'stretch',
    height: imageHeight,
    width: imageWidth,
  },
  popText: {
    ...Fonts.style.h4,
    fontWeight: 'bold',
    color: Colors.text,
    alignItems: 'baseline',
    textAlign: 'center',
    marginTop: Metrics.baseMargin,
  },
  popSubtitle: {
    ...Fonts.style.body,
    color: Colors.text,
    alignItems: 'baseline',
    textAlign: 'center',
    marginTop: Metrics.baseMargin,
  },
})
