import React, { useState } from 'react'
import { View } from 'react-native'
import { Immutable } from 'immer'

import styled from 'styled-components/native'
import moment from 'moment'

import Button from 'APP/Converse/Button'
import Typography from 'APP/Converse/Typography'
import { DATE_FORMAT } from 'APP/Redux/AppointmentBookingRedux'
import I18n from 'APP/Services/i18n'
import { Colors, Metrics } from 'APP/Themes'
import useShowBookingSheet from 'APP/Hooks/useShowBookingSheet'

import { Availabilities } from '../utils/types'
import { NoAvailableSlots } from '../common/noAvailableSlots'
import { TimeslotSection } from './timeslotSection'
import { DayPicker } from './dayPicker'
import { LoadingIndicator } from '../common/loadingIndicator'
import BottomSheetModal from 'APP/Components/BottomSheetModal'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import { isWeb } from 'APP/Helpers/checkPlatform'
import { useSelector } from 'react-redux'
import i18n from 'APP/Services/i18n'
import { TimeFrameMessage } from '../common/timeFrameMessage'
import { AvailabilitySlot } from '@dialogue/timekeeper'

const Root = styled.View`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  align-items: center;
  padding-bottom: ${Metrics.bottomSpace};
  background-color: ${Colors.selectedBg};
`

const ContentGrid = styled.View`
  width: 100%;
  margin: 0;
`

const StyledTimeframe = styled(TimeFrameMessage)`
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin}px;
`

const QuitButtonWrapper = styled(View)`
  min-height: ${({ theme }) => theme.metrics.button.height - 2}px;
`

const TopContainer = styled.View`
  background-color: ${Colors.elementsBg};
  ${({ theme }) => theme.shadows.normal};
`

const TimeslotSectionStyled = styled(TimeslotSection)`
  margin-bottom: 24px;
`

const TimeSlotForDay = styled.View`
  margin-top: 12px
  padding: 3%;
`

const BottomSheetStyled = styled(BottomSheetModal)`
  display: flex;
  align-items: center;
`

const BottomSheetContainer = styled(View)`
  padding-left: ${Metrics.baseMargin};
  padding-right: ${Metrics.baseMargin};
  padding-bottom: ${Metrics.bottomSpace || Metrics.baseMargin};
  display: flex;
  align-items: center;
  word-wrap: break-word;
  max-width: 550px;
  flex-wrap: wrap;
`

const BottomSheetDescriptionContainer = styled(View)`
  background-color: ${Colors.bannerBg};
  border-radius: ${Metrics.baseMargin}px;
  padding: ${Metrics.baseMargin}px;
  margin-bottom: ${Metrics.baseMargin}px;
`

const BottomSheetDescriptionTitleRow = styled(View)`
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
`

const BottomSheetDescriptionTitle = styled(Typography)`
  padding-top: ${Metrics.baseMargin / 4}px,
  padding-bottom: ${Metrics.baseMargin / 4}px,
  flex-basis: fit-content;
`

const BottomSheetDescription = styled(Typography)`
  padding-left: ${isWeb() ? Metrics.baseMargin * 2 : Metrics.baseMargin * 1.5}px;
  line-height: ${Metrics.baseMargin * 1.5}px;
`

const BottomSheetDescriptionIcon = styled(MaterialIcon)`
  padding-right: ${isWeb() ? Metrics.baseMargin : Metrics.baseMargin / 2}px;
  flex-basis: fit-content;
`

export interface TimeslotSelectionProps {
  appointmentType: string
  availabilities: Immutable<Availabilities>
  dateFrom: moment.Moment
  dateUntil: moment.Moment
  hasExistingAppointment: boolean
  selectDay: (day: moment.Moment) => void
  selectedDay: moment.Moment
  selectedAppointment?: AvailabilitySlot | null
  bookAppointmentError?: Record<string, any> | null
  selectAppointment: (availableAppointment: AvailabilitySlot) => void
  getAvailabilities: (day: moment.Moment) => void
  quitScheduling: () => void
  /**
   * for testing purposes
   */
  now?: moment.Moment
}

export enum SheetType {
  CANCEL_APPOINTMENT = 'cancelAppointmentSheet',
  AVAILABLE_APPOINTMENT = 'availableAppointmentSheet',
}

interface BookingBottomSheetProps {
  isVisible: boolean
  onCloseModal: () => void
  onConfirmCancel: () => void
  sheetType: SheetType
}

interface TextProps {
  title: string
  subtitle: string
  descriptionTitle: string
  description: string
  button: string
  analyticsName: string
}

interface TextMappingProps {
  [SheetType.CANCEL_APPOINTMENT]: TextProps
  [SheetType.AVAILABLE_APPOINTMENT]: TextProps
}

export const TimeslotSelection: React.FC<TimeslotSelectionProps> = ({
  appointmentType,
  availabilities,
  dateFrom,
  dateUntil,
  hasExistingAppointment,
  selectDay,
  selectedDay,
  getAvailabilities,
  quitScheduling,
  selectedAppointment,
  bookAppointmentError,
  selectAppointment,
  now,
}) => {
  now = now || dateFrom
  const showAvailableAppointmentSheet = useShowBookingSheet(appointmentType)
  const [isSheetVisible, setIsSheetVisible] = useState(false)
  // @ts-expect-error store not typed
  const { rescheduleAppointmentMX } = useSelector((state) => state.features)
  const formattedSelectedDay = selectedDay.format(DATE_FORMAT)
  const availabilitiesForSelectedDay = availabilities[formattedSelectedDay] || {}
  const { isLoading, hasAvailabilities, times } = availabilitiesForSelectedDay

  const handleOpenSheet = () => setIsSheetVisible(true)
  const handleCloseSheet = () => setIsSheetVisible(false)
  const handleSubmitSheet = () => {
    handleCloseSheet()
    quitScheduling()
  }
  const showCancelAppointmentSheet = rescheduleAppointmentMX && hasExistingAppointment

  const renderAppointmentsList = () => {
    return (
      <View>
        <StyledTimeframe
          dateFrom={dateFrom}
          dateUntil={dateUntil}
          messageKey="AppointmentBooking.recommended_timeframe"
        />
        {Object.entries(times || {}).map(([partOfDay, availabilities]) => {
          return availabilities.length > 0 ? (
            <TimeslotSectionStyled
              key={partOfDay}
              title={I18n.t(`AppointmentBooking.${partOfDay.toLowerCase()}`)}
              appointments={availabilities}
              selectedAppointment={selectedAppointment}
              onSelect={selectAppointment}
            />
          ) : null
        })}
        <QuitButtonWrapper>
          {!selectedAppointment && (
            <Button
              small={false}
              variant="tertiary"
              widthVariant="full"
              testID="appointmentBookingNoneOfTheseWork"
              title={I18n.t('AppointmentBooking.none_of_these_work') || undefined}
              onPress={
                showCancelAppointmentSheet || showAvailableAppointmentSheet
                  ? handleOpenSheet
                  : quitScheduling
              }
              analyticsName="AppointmentBooking None of these times work for me"
            />
          )}
        </QuitButtonWrapper>
      </View>
    )
  }

  return (
    <>
      <Root testID="timeslotSelection">
        <ContentGrid>
          <TopContainer>
            <DayPicker
              dateFrom={now}
              dateUntil={dateUntil}
              selectedDate={selectedDay}
              onSelect={selectDay}
              getAvailabilities={getAvailabilities}
              availabilities={availabilities}
            />
          </TopContainer>
          {!isLoading && (
            <TimeSlotForDay>
              {!!bookAppointmentError ? (
                <Typography variant="bodyNormal" align="center" color="error">
                  {I18n.t('AppointmentBooking.book_appointment_error')}
                </Typography>
              ) : hasAvailabilities ? (
                renderAppointmentsList()
              ) : (
                <NoAvailableSlots onQuit={handleSubmitSheet} />
              )}
            </TimeSlotForDay>
          )}
        </ContentGrid>
        {(isLoading || bookAppointmentError) && <LoadingIndicator />}
      </Root>
      <BookingBottomSheet
        isVisible={isSheetVisible}
        onCloseModal={handleCloseSheet}
        onConfirmCancel={handleSubmitSheet}
        sheetType={
          showCancelAppointmentSheet
            ? SheetType.CANCEL_APPOINTMENT
            : SheetType.AVAILABLE_APPOINTMENT
        }
      />
    </>
  )
}

export const BookingBottomSheet: React.FC<BookingBottomSheetProps> = ({
  isVisible,
  onCloseModal,
  onConfirmCancel,
  sheetType,
}) => {
  const textMapping: TextMappingProps = {
    [SheetType.CANCEL_APPOINTMENT]: {
      title: i18n.t('AppointmentBooking.cancelAppointmentSheet.title'),
      subtitle: i18n.t('AppointmentBooking.cancelAppointmentSheet.subtitle'),
      descriptionTitle: i18n.t('AppointmentBooking.cancelAppointmentSheet.descriptionTitle'),
      description: i18n.t('AppointmentBooking.cancelAppointmentSheet.description'),
      button: i18n.t('AppointmentBooking.cancelAppointmentSheet.continue')!,
      analyticsName: 'Yes, cancel appointment from reschedule',
    },
    [SheetType.AVAILABLE_APPOINTMENT]: {
      title: i18n.t('AppointmentBooking.availableAppointmentSheet.title'),
      subtitle: i18n.t('AppointmentBooking.availableAppointmentSheet.subtitle'),
      descriptionTitle: i18n.t('AppointmentBooking.availableAppointmentSheet.descriptionTitle'),
      description: i18n.t('AppointmentBooking.availableAppointmentSheet.description'),
      button: i18n.t('AppointmentBooking.availableAppointmentSheet.continue')!,
      analyticsName: 'Yes, manually assist with scheduling',
    },
  }
  return (
    <BottomSheetStyled
      isVisible={isVisible}
      title={textMapping[sheetType].title}
      subtitle={textMapping[sheetType].subtitle}
      onClose={onCloseModal}
      testID="noneOfTheseWorkModal"
      goBack={null}
      style={null}
    >
      <BottomSheetContainer>
        <BottomSheetDescriptionContainer>
          <BottomSheetDescriptionTitleRow>
            <BottomSheetDescriptionIcon name="error-outline" size={16} color={Colors.text} />
            <BottomSheetDescriptionTitle variant="bodyNormalBold">
              {textMapping[sheetType].descriptionTitle}
            </BottomSheetDescriptionTitle>
          </BottomSheetDescriptionTitleRow>
          <BottomSheetDescription variant="bodyNormal">
            {textMapping[sheetType].description}
          </BottomSheetDescription>
        </BottomSheetDescriptionContainer>
        <Button
          small={false}
          onPress={onConfirmCancel}
          variant="tertiaryAlert"
          title={textMapping[sheetType].button}
          analyticsName={textMapping[sheetType].analyticsName}
          widthVariant="full"
          testID="noneOfTheseWorkContinue"
          textColor="error"
        />
      </BottomSheetContainer>
    </BottomSheetStyled>
  )
}
