import React from 'react'
import { Text, View } from 'react-native'
import { useSelector } from 'react-redux'
import { getNameDisplay } from 'APP/Lib/ProfileHelpers'

// Styling
import Styles from './style'

const ProfileHeaderCard = ({
  givenName,
  preferredName,
  familyName,
  pronouns,
  subTitle,
  testID,
}) => {
  const showPronouns = useSelector((state) => state.features.pronounDisplay)

  const { nameDisplay } = getNameDisplay(
    givenName,
    preferredName,
    pronouns,
    showPronouns,
    familyName
  )
  if (!nameDisplay && !subTitle) return null

  return (
    <View style={Styles.container} testID={testID}>
      {!!nameDisplay && (
        <Text style={Styles.name} numberOfLines={1}>
          {nameDisplay}
        </Text>
      )}
      {!!subTitle && <Text style={Styles.subTitle}>{subTitle}</Text>}
    </View>
  )
}

export default ProfileHeaderCard
