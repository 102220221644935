import React from 'react'
import { connect } from 'react-redux'
import { Text, View } from 'react-native'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import Icon from 'react-native-vector-icons/MaterialIcons'

import Styles from './style'
import { Colors } from '../../Themes'

import I18n from 'APP/Services/i18n'
import { getDisplayableBrands, openExternalBrandApp } from 'APP/Lib/BrandHelpers'

function AppLaunchpad({ eligibleBrands, style, isEligible }) {
  const renderLaunchpadCard = (brandId) => {
    const appName = I18n.t(`AppLaunchpad.${brandId}.title`)

    return (
      <DialogueTouchableOpacity
        style={Styles.cardContainer}
        onPress={() => openExternalBrandApp(appName, brandId)}
        key={brandId}
      >
        <View style={Styles.copyContainer}>
          <Text style={Styles.cardTitle}>{appName}</Text>
          <Text style={Styles.cardBody}>{I18n.t(`AppLaunchpad.${brandId}.body`)}</Text>
        </View>
        <Icon name="exit-to-app" size={24} color={Colors.accent} />
      </DialogueTouchableOpacity>
    )
  }

  const filteredBrands = getDisplayableBrands(eligibleBrands)
  // This CTA is replaced by InactiveInfoCard
  if (!isEligible) return null

  return (
    (filteredBrands && filteredBrands.length > 0 && (
      <View style={style}>
        <Text style={Styles.launchpadHeader}>{I18n.t('AppLaunchpad.header')}</Text>
        {filteredBrands.map((brand) => renderLaunchpadCard(brand))}
      </View>
    )) ||
    null
  )
}

const mapStateToProps = ({ patient }) => {
  const eligibleServices = patient?.profile?.eligibleServices
  return {
    eligibleBrands: patient?.profile?.eligibleBrands ?? [],
    isEligible: eligibleServices && Object.keys(eligibleServices).length > 0,
  }
}

export default connect(mapStateToProps)(AppLaunchpad)
