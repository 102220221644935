import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Metrics, Fonts } from 'APP/Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  splashLogo: {
    resizeMode: 'contain',
    alignSelf: 'baseline',
    marginLeft: Metrics.baseMargin,
    marginBottom: (Metrics.baseMargin * 3) / 2,
    height: 56,
    width: 56,
  },
  title: {
    ...Fonts.style.h4,
    fontWeight: 'normal',
    color: Colors.text,
    alignItems: 'baseline',
    textAlign: 'left',
    marginLeft: Metrics.baseMargin,
    marginRight: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin / 2,
  },
  subtitle: {
    ...Fonts.style.body,
    color: Colors.text,
    fontSize: Fonts.size.regular,
    textAlign: 'left',
    marginLeft: Metrics.baseMargin,
    marginRight: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin,
    lineHeight: 20,
  },
  instructionContainer: {
    marginVertical: Metrics.baseMargin / 2,
    marginRight: Metrics.baseMargin,
    marginLeft: Metrics.baseMargin,
  },
  instruction: {
    ...Fonts.style.medium,
    fontWeight: 'bold',
    color: Colors.text,
    marginBottom: Metrics.baseMargin / 2,
  },
  buttonContainer: {
    paddingBottom: Metrics.baseMargin * 3,
  },
  sectionBody: {
    ...ApplicationStyles.section,
    flex: 1,
    marginRight: Metrics.baseMargin * 2,
  },
})
