import Config from 'APP/Config'

export const REGIONS = {
  CANADA: 'CA',
  EUROPE: 'EU',
  USA: 'US',
}

export const isCanada = () => Config.REGION === REGIONS.CANADA
export const isEurope = () => Config.REGION === REGIONS.EUROPE
export const isUSA = () => Config.REGION === REGIONS.USA
