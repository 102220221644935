import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { View, RefreshControl } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import { Appearance } from 'react-native-appearance'
import { ActiveMinutes } from '@dialogue/services'
import Toast from 'react-native-toast-message'

import Analytics from 'APP/Services/Analytics'
import I18n from 'APP/Services/i18n'
import UrlBuilder from 'APP/Services/UrlBuilder'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import { ResponsiveScrollView } from 'APP/Converse/Layout'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import ActiveMinutesActions from 'APP/Redux/ActiveMinutesRedux'
import { useRefreshControlHandlers } from 'APP/Hooks/RefreshControlHandlers'
import {
  comingSoonTrackers,
  supportedTrackerActivities,
  supportedTrackers,
} from 'APP/Lib/ActiveMinutes/trackers/constants'
import withFeatureEnabled from 'APP/Helpers/withFeatureEnabled'
import ProviderIntroModal from './ProviderIntroModal'
import TrackerSection from './TrackerSection'
import Typography from 'APP/Converse/Typography'
import { Colors, Metrics } from 'APP/Themes'
import Styles from './style'
import HealthTrackerSettingsScreen_legacy from '../HealthTrackerSettingsScreen_legacy'

const SECTION_KEYS = {
  CONNECTED: 'connected',
  SUPPORTED: 'supported',
  COMING_SOON: 'comingSoon',
}

const SECTIONS = [
  {
    key: SECTION_KEYS.CONNECTED,
    title: I18n.t('HealthTrackerSettings.sections.usersTrackers'),
    ctaStatusMap: {
      [ActiveMinutes.Types.TrackerStatus.Connected]: I18n.t(
        'HealthTrackerSettings.ctas.disconnect'
      ),
      [ActiveMinutes.Types.TrackerStatus.Broken]: I18n.t('HealthTrackerSettings.ctas.reconnect'),
    },
  },
  {
    key: SECTION_KEYS.SUPPORTED,
    title: I18n.t('HealthTrackerSettings.sections.allTrackers'),
    ctaStatusMap: {
      default: I18n.t('HealthTrackerSettings.ctas.connect'),
    },
    trackers: supportedTrackers,
  },
  {
    key: SECTION_KEYS.COMING_SOON,
    title: I18n.t('HealthTrackerSettings.sections.comingSoon'),
    ctaStatusMap: {
      default: I18n.t('HealthTrackerSettings.ctas.request'),
    },
    trackers: comingSoonTrackers,
  },
]

const HealthTrackerSettingsScreen = ({ navigation, route }) => {
  const providerInfoModalRef = useRef()
  const dispatch = useDispatch()
  const getConnectedTrackers = useCallback(
    () => dispatch(ActiveMinutesActions.getConnectedTrackers()),
    []
  )
  const requestNewConnection = (provider) =>
    dispatch(ActiveMinutesActions.requestNewConnection(provider))
  const resetRequestNewConnection = () => dispatch(ActiveMinutesActions.resetRequestNewConnection())
  const removeConnection = (provider, id) =>
    dispatch(ActiveMinutesActions.removeConnection(provider, id))
  const {
    loading,
    retry,
    data: connectedTrackers,
  } = useSelector((state) => state.activeMinutes.connectedTrackers)
  const newConnectionRequest = useSelector((state) => state.activeMinutes.newConnectionRequest)
  const removeConnectionState = useSelector((state) => state.activeMinutes.removeConnection)
  const refreshControlHandlers = useRefreshControlHandlers(connectedTrackers, getConnectedTrackers)
  const [providerInfoModal, setProviderInfoModal] = useState(null)
  const requestedActivity = route?.params?.requestedActivity
  const enableGoogleFit = useSelector((state) => state.features.enableGoogleFit)

  useFocusEffect(
    useCallback(() => {
      getConnectedTrackers()
    }, [])
  )

  useEffect(() => {
    // propagate redux error to navigation params
    // to handle all errors in one place
    if (newConnectionRequest.error) {
      navigation.setParams({ error: newConnectionRequest.error })
      resetRequestNewConnection()
    }
  }, [newConnectionRequest.error])
  useEffect(() => {
    // if the outcome of the connection request is an established connection (a case for SDK providers)
    // propagate success to navigation params
    // to handle all success banners in one place
    if (newConnectionRequest.connectionCreated) {
      navigation.setParams({ success: true, provider: newConnectionRequest.provider })
      resetRequestNewConnection()
    }
  }, [newConnectionRequest.connectionCreated])
  // handle success/error banners
  useEffect(() => {
    const toastCleanUp = {
      onShow: () =>
        navigation.setParams({
          success: undefined,
          error: undefined,
          provider: undefined,
        }),
    }

    if (route.params?.success) {
      // refresh connected trackers
      getConnectedTrackers()

      const successKey = `HealthTrackerSettings.connectionResolution.success.body.${route?.params?.provider}`
      const deafaultKey = 'HealthTrackerSettings.connectionResolution.success.body.default'

      Toast.show({
        text1: I18n.t('HealthTrackerSettings.connectionResolution.success.title'),
        text2: I18n.t(I18n.exists(successKey) ? successKey : deafaultKey),
        ...toastCleanUp,
      })
      Analytics.trackEvent('banner_view', { banner_name: 'Tracker successful banner' })
    }

    if (route.params?.error) {
      const errorKey = `HealthTrackerSettings.connectionResolution.error.body.${route.params.error}`
      const deafaultKey = 'HealthTrackerSettings.connectionResolution.error.body.default'

      Toast.show({
        text1: I18n.t('HealthTrackerSettings.connectionResolution.error.title'),
        text2: I18n.t(I18n.exists(errorKey) ? errorKey : deafaultKey),
        ...toastCleanUp,
      })
      Analytics.trackEvent('banner_view', { banner_name: 'Tracker error banner' })
    }
  }, [route.params])

  const connectedAndSupportedTrackers = useMemo(
    () => connectedTrackers.filter(({ provider }) => supportedTrackers.includes(provider)) || [],
    [connectedTrackers]
  )

  const trackerSections = useMemo(() => {
    const getTrackersForSection = (section) => {
      if (section.key === SECTION_KEYS.CONNECTED) {
        return connectedAndSupportedTrackers
      }

      return section.trackers
        .filter(
          (provider) =>
            !connectedAndSupportedTrackers.some(
              ({ provider: connectedProvider }) => connectedProvider === provider
            )
        )
        .map((provider) => ({ provider }))
    }

    return SECTIONS.map((section) => ({
      ...section,
      trackers: getTrackersForSection(section),
    }))
  }, [connectedAndSupportedTrackers])

  const handleHelpPress = () => {
    const uri = UrlBuilder.cmsConsultUrl('healthTrackers', Appearance.getColorScheme())
    navigation.navigate('cmsScreen', { uri, screen: 'cms', showShareButton: false })
  }

  const handleTrackerCtaPress = (sectionKey, provider) => {
    if (
      sectionKey === SECTION_KEYS.SUPPORTED &&
      I18n.exists(`HealthTrackerSettings.modal.${provider}`)
    ) {
      setProviderInfoModal(provider)
    }

    if (sectionKey === SECTION_KEYS.COMING_SOON) {
      Analytics.trackEvent('button_click', {
        button_value: provider,
        trigger: 'request_tracker',
      })
      Toast.show({
        text1: I18n.t('HealthTrackerSettings.connectionResolution.request.title'),
        text2: I18n.t('HealthTrackerSettings.connectionResolution.request.body'),
      })
    }

    if (sectionKey === SECTION_KEYS.CONNECTED) {
      Analytics.trackEvent('button_click', {
        button_value: provider,
        trigger: 'disconnect_tracker',
      })
      removeConnection(provider, connectedTrackers.find(({ provider: p }) => p === provider)?.id)
    }
  }

  const handleTrackerIntroConnect = async (provider) => {
    await providerInfoModalRef.current?.terminateModal()
    requestNewConnection(provider)
    Analytics.trackEvent('button_click', {
      button_value: provider,
      trigger: 'connect_tracker',
    })
  }

  return (
    <NoticeBarContainer>
      <ResponsiveScrollView
        refreshControl={<RefreshControl {...refreshControlHandlers} />}
        testID="scroll"
      >
        {!loading && retry && (
          <DialogueTouchableOpacity
            style={Styles.refresh}
            onPress={() => getConnectedTrackers()}
            analyticsName="Refresh"
            testID="retry"
          >
            <MaterialIcon name="refresh" size={Metrics.icons.large} color={Colors.text} />
          </DialogueTouchableOpacity>
        )}
        {!retry && (
          <View>
            <DialogueTouchableOpacity
              style={Styles.help}
              testID="help"
              onPress={handleHelpPress}
              analyticsName="How trackers work"
            >
              <Typography variant="subheaderBold">
                {I18n.t('HealthTrackerSettings.help')}
              </Typography>
              <MaterialIcon
                name="arrow-forward-ios"
                size={Metrics.icons.tiny}
                color={Colors.accent}
              />
            </DialogueTouchableOpacity>
            {trackerSections.map(({ key, title, trackers, ctaStatusMap }) => (
              <View key={key}>
                {trackers.length > 0 && (
                  <Typography
                    variant="captionBold"
                    color="secondary"
                    testID="sectionTitle"
                    style={Styles.header}
                  >
                    {title}
                  </Typography>
                )}
                {trackers.map((tracker) => {
                  const supportsRequestedActivity =
                    !!requestedActivity && key !== SECTION_KEYS.COMING_SOON
                      ? supportedTrackerActivities[tracker.provider]?.includes(requestedActivity)
                      : true

                  const modifying =
                    (newConnectionRequest.provider === tracker.provider &&
                      newConnectionRequest.processing) ||
                    (removeConnectionState.provider === tracker.provider &&
                      removeConnectionState.processing)

                  const disabled =
                    newConnectionRequest.processing || removeConnectionState.processing

                  if (
                    tracker.provider === ActiveMinutes.Types.TrackerName.GoogleFit &&
                    !enableGoogleFit &&
                    key != SECTION_KEYS.CONNECTED
                  ) {
                    return (
                      <TrackerSection
                        key={tracker.provider}
                        supported={false}
                        temporaryDisabled={true}
                        ctaStatusMap={ctaStatusMap}
                        disabled={true}
                        onPress={null}
                        {...tracker}
                      />
                    )
                  } else {
                    return (
                      <TrackerSection
                        key={tracker.provider}
                        supported={supportsRequestedActivity}
                        ctaStatusMap={ctaStatusMap}
                        disabled={disabled}
                        modifying={modifying}
                        onPress={() => handleTrackerCtaPress(key, tracker.provider)}
                        {...tracker}
                      />
                    )
                  }
                })}
              </View>
            ))}
          </View>
        )}
      </ResponsiveScrollView>
      <ProviderIntroModal
        ref={providerInfoModalRef}
        provider={providerInfoModal}
        onClose={() => setProviderInfoModal(undefined)}
        onConnect={handleTrackerIntroConnect}
      />
    </NoticeBarContainer>
  )
}

export default withFeatureEnabled({
  memoizationKey: 'HealthTrackerSettings',
  enabled: HealthTrackerSettingsScreen,
  default: HealthTrackerSettingsScreen_legacy,
  featureKey: 'terraDrivenWellness',
})
