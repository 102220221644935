import apisauce from 'apisauce'
import url from 'url'

const create = (loginStore, source) => {
  const authToken = loginStore.accessToken
  const sourceURLObj = url.parse(source, true)
  const baseURL = url.format({
    ...sourceURLObj,
    search: undefined,
    pathname: undefined,
    query: undefined,
  })
  const api = apisauce.create({
    baseURL,
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    timeout: 20000,
  })

  // API
  const search = (query) => {
    return api.get(sourceURLObj.pathname, { ...sourceURLObj.query, query }).then((response) => {
      if (response.ok) {
        if (response.data) {
          return response.data.results
        }
      } else {
        throw response.problem
      }
    })
  }

  return {
    search,
  }
}

export default {
  create,
}
