import * as Sentry from '@sentry/react-native'
import { shouldRenderComponent } from 'APP/Lib/ContentHelpers'

// missing features are captured once per app session
const capturedMissingFeatures = {}
export const resolveServiceFeatures = (serviceFeaturesConfig, eligibleServices) => {
  const map = (serviceFeaturesConfig || []).reduce(
    (acc, { feature_id, service_filter }) => ({
      ...acc,
      [feature_id]: shouldRenderComponent(service_filter, eligibleServices),
    }),
    {}
  )
  // eslint-disable-next-line no-undef
  return new Proxy(map, {
    get(target, phrase) {
      if (phrase in target) return target[phrase]

      if (!(phrase in capturedMissingFeatures)) {
        Sentry.captureMessage(`Accessing a Service Feature that is missing from the config`, {
          level: 'warning',
          extra: {
            feature_id: phrase,
          },
        })
        console.warn('Service Features', `${phrase} is not in the config`)
        capturedMissingFeatures[phrase] = true
      }

      // Service features are enabled by default
      return true
    },
  })
}
