import { StyleSheet } from 'react-native'
import { Metrics } from 'APP/Themes'

export default StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  radioContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  radio: {
    height: 20,
    width: 20,
    borderRadius: 10,
    margin: Metrics.baseMargin / 2,
  },
  dot: {
    height: 10,
    width: 10,
    borderRadius: 5,
  },
})
