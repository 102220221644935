import { StyleSheet } from 'react-native'
import { ApplicationStyles, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  containerInviteFam: {
    flex: 1,
    paddingHorizontal: Metrics.baseMargin * 2,
  },
  topSection: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    maxHeight: 150,
    marginVertical: Metrics.baseMargin,
  },
  section: {
    marginVertical: Metrics.section / 2,
  },
  sectionTitle: {
    marginBottom: Metrics.baseMargin,
  },
  listContainer: {
    gap: 5,
  },
})
