import React, { memo } from 'react'
import styled from 'styled-components/native'
import { Avatar } from 'APP/Converse/Avatar'
import Typography from 'APP/Converse/Typography'
import { DescriptionStyle } from 'APP/Services/Mattermost/types'
import { Images } from 'APP/Themes'
import AppointmentCardStatic from 'APP/Components/AppointmentCard/AppointmentCardStatic'
import { QuestionDescriptionProps } from './types'

const Card = styled.View`
  background-color: ${({ theme }) => theme.colors.clientCardBg};
  border-radius: ${({ theme }) => theme.metrics.baseMargin}px;
  padding: ${({ theme }) =>
    `${theme.metrics.baseMargin}px ${theme.metrics.baseMargin}px ${
      theme.metrics.baseMargin / 2
    }px`};
  gap: ${({ theme }) => theme.metrics.baseMargin}px;
`

const Header = styled.View`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.metrics.baseMargin * 0.75}px;
  align-items: center;
`

const Container = styled.View`
  flex-shrink: 3;
  flex-grow: 0;
  width: 100%;
`

export const QuestionDescription = memo<QuestionDescriptionProps>(
  ({ children, member_app_fullscreen }) => {
    switch (member_app_fullscreen?.description_style) {
      case DescriptionStyle.card:
        return (
          <Container>
            <Card>
              <Header>
                <Avatar size="regular" source={Images.logoNurse} resizeMode="contain" />
                <Typography variant="h3">{member_app_fullscreen.description_heading}</Typography>
              </Header>
              {children}
            </Card>
          </Container>
        )
      case DescriptionStyle.card_with_aside:
        return (
          <Container>
            {children}
            <AppointmentCardStatic
              date={member_app_fullscreen?.description_aside}
              timeslot={member_app_fullscreen?.description_title}
              providerName={member_app_fullscreen?.description_heading}
              providerRole={member_app_fullscreen?.description_subheading}
              reasonForConsult={member_app_fullscreen?.description_content}
            />
          </Container>
        )
      default:
        return <Container>{children}</Container>
    }
  }
)
