import { StyleSheet } from 'react-native'

import { ApplicationStyles, Colors, Metrics, Fonts } from 'APP/Themes'

export const Styles = StyleSheet.create({
  headerContainer: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.elementsBg,
    borderRadius: 20,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    ...ApplicationStyles.shadow,
    paddingTop: Metrics.baseMargin * 2,
    paddingBottom: Metrics.baseMargin * 2 + Metrics.bottomSpace * 0.7,
    paddingHorizontal: Metrics.baseMargin * 2 + 25,
  },
  titleContainer: {
    alignItems: 'center',
  },
  titleText: {
    ...Fonts.style.h4,
    color: Colors.text,
  },
  headerButton: {
    position: 'absolute',
    top: Metrics.baseMargin * 1.75,
    right: Metrics.baseMargin * 2,
    flexGrow: 0,
    width: 25,
    height: 25,
  },
  headerText: {
    ...Fonts.style.h4,
    color: Colors.text,
  },
})
