import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Fonts, Metrics } from '../../Themes'

const bar = {
  backgroundColor: Colors.elementsBg,
  shadowColor: Colors.shadow,
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.4,
  shadowRadius: 4,
  borderBottomWidth: 1,
  borderBottomColor: Colors.shadow,
}

export const modalTrackerMarkdownAlert = {
  p: {
    marginRight: Metrics.baseMargin * 2,
    marginBottom: 0,
    ...Fonts.style.body,
    lineHeight: Metrics.baseMargin * 1.25,
  },
}

export const modalTrackerMarkdownContent = {
  body: {
    lineHeight: 21,
    marginLeft: Metrics.baseMargin,
  },
  a: {
    color: Colors.accent,
  },
}

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    minHeight: '100%',
  },
  refresh: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%',
  },
  help: {
    ...bar,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: Metrics.baseMargin,
    paddingHorizontal: Metrics.baseMargin * 1.5,
    paddingVertical: Metrics.baseMargin,
  },
  header: {
    textTransform: 'uppercase',
    marginTop: Metrics.baseMargin * 2,
    marginBottom: Metrics.baseMargin / 2,
    paddingHorizontal: Metrics.baseMargin * 1.5,
  },
  trackerWrapper: bar,
  disabledTrackerWrapper: {
    backgroundColor: Colors.selectedBg,
  },
  tracker: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: Metrics.baseMargin * 1.5,
    paddingVertical: Metrics.baseMargin / 2,
  },
  image: {
    width: 45,
    height: 45,
    marginRight: Metrics.baseMargin * 0.25,
  },
  name: {
    flex: 1,
    paddingHorizontal: Metrics.baseMargin / 2,
  },
  subInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: Metrics.baseMargin * 1.25,
    paddingBottom: Metrics.baseMargin * 0.6,
  },
  modalContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: Metrics.statusBarHeight + Metrics.baseMargin,
  },
  modal: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: Colors.appBg,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    ...ApplicationStyles.shadow,
    shadowOffset: {
      height: -4,
    },
  },
  row: {
    flexDirection: 'row',
  },
  modalHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: Metrics.baseMargin * 1.25,
    paddingHorizontal: Metrics.baseMargin / 2,
    backgroundColor: Colors.appBg,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    ...ApplicationStyles.shadow,
  },
  modalHeaderClose: {
    position: 'absolute',
    left: Metrics.baseMargin,
    zIndex: 1,
  },
  modalTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Metrics.baseMargin * 2,
    marginLeft: Metrics.baseMargin,
  },
  modalTitleImage: {
    width: 45,
    height: 45,
    marginRight: Metrics.baseMargin * 0.85,
  },
  modalSubtitle: {
    paddingHorizontal: Metrics.baseMargin * 1.25,
    marginBottom: Metrics.baseMargin,
  },
  modalContent: {
    paddingHorizontal: Metrics.baseMargin * 1.25,
    paddingVertical: Metrics.baseMargin + Metrics.bottomSpace,
  },
  modalAlert: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: Colors.bannerBg,
    borderRadius: 16,
    paddingVertical: Metrics.baseMargin,
    paddingHorizontal: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin,
    marginHorizontal: Metrics.baseMargin * 0.5,
  },
  modalAlertIcon: {
    marginRight: Metrics.baseMargin * 0.5,
  },
  modalTrackerCta: {
    marginBottom: Metrics.baseMargin * 2,
  },
  disclaimer: {
    paddingHorizontal: Metrics.baseMargin * 2,
  },
  disclaimerLink: {
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
})
