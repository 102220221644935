import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Metrics } from 'APP/Themes'

export const Styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    backgroundColor: Colors.appBg,
  },
  scroll: {
    flex: 1,
    backgroundColor: Colors.appBg,
  },
  scrollContentStyle: {
    flex: 1,
  },
  webContainer: {
    flexGrow: 1,
    borderRadius: Metrics.baseMargin * 1.25,
    overflow: 'hidden',
    marginVertical: Metrics.baseMargin * 2,
    marginHorizontal: Metrics.baseMargin,
    ...ApplicationStyles.shadow,
  },
})
