import React from 'react'
import { View, TouchableOpacity } from 'react-native'

import { Colors, Images } from 'APP/Themes'

import { Styles } from './style'
import Icon from 'APP/Converse/Icon'
import Typography from 'APP/Converse/Typography'

const InputFile = ({ fileData, onCancel, onSubmit }) => {
  return (
    <View style={Styles.container}>
      <View style={Styles.cardContainer}>
        <View style={Styles.row}>
          <View style={Styles.innerContainer}>
            {fileData.fileType === 'application/pdf' ? (
              <Icon
                src="file-outline"
                testID="icon-file"
                variant="material"
                size={24}
                color="accent"
              />
            ) : (
              <Icon src="image" testID="icon-image" variant="material" size={24} color="accent" />
            )}
            <Typography variant="bodyNormal">{fileData.fileName}</Typography>
          </View>
          <TouchableOpacity style={Styles.action} onPress={onCancel} testID="CLOSE_BTN">
            <Icon src="close" variant="material" size={20} color="text" />
          </TouchableOpacity>
        </View>
      </View>
      <TouchableOpacity style={Styles.submitIcon} onPress={onSubmit} testID="SUBMIT_BTN">
        <Images.sendMessage accentFillLine={Colors.accent} width={20} height={20} />
      </TouchableOpacity>
    </View>
  )
}

export default InputFile
