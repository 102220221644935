import { View, Image } from 'react-native'
import styled from 'styled-components/native'
import { LinearGradient } from 'expo-linear-gradient'

import Config from 'APP/Config'
import { Metrics, Colors } from 'APP/Themes'

import { Video } from 'expo-av'

export const StyledViewContainer = styled(View)`
  flex: 1;
  background-color: ${({ theme: { isBigScreen }, slideIndex }) =>
    isBigScreen ? 'auto' : Colors[`welcomeScreenBackgroundSlide${slideIndex}`]};
  flex-direction: ${({ theme: { isBigScreen } }) => (isBigScreen ? 'row' : 'column')};
  align-items: center;
  justify-content: center;
`

export const StyledViewHero = styled(View)`
  flex-direction: column;
  align-items: center;
  flex: 1;
  width: ${({ theme: { isBigScreen } }) => (isBigScreen ? '50%' : '100%')};
`

export const StyledViewCTAs = styled(View)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${({ theme: { isBigScreen } }) => (isBigScreen ? '100%' : 'auto')};
  width: ${({ theme: { isBigScreen } }) => (isBigScreen ? '50%' : '100%')};
  position: ${({ theme: { isBigScreen } }) => (isBigScreen ? 'relative' : 'absolute')};
  bottom: ${({ theme: { isBigScreen }, hasDialogueBranding }) =>
    isBigScreen ? 'auto' : hasDialogueBranding ? '0' : '5%'};
  padding: ${Metrics.baseMargin}px;
`

export const StyledViewQRContainer = styled(View)`
  position: ${({ theme: { isBigScreen } }) => (isBigScreen ? 'absolute' : 'sticky')};
  bottom: ${Metrics.baseMargin * 2}px;
  width: inherit;
  align-items: center;
`

export const StyledViewCarousel = styled(View)`
  height: 100%;
  width: 100%;
  background-color: ${({ theme: { isBigScreen }, slideIndex }) =>
    isBigScreen ? Colors[`welcomeScreenBackgroundSlide${slideIndex}`] : 'auto'};
`

export const StyledImageHero = styled(Image)`
  flex: ${({ theme: { isBigScreen } }) => (isBigScreen ? '0.7' : '1')};
  width: auto;
  ${(!Config.WELCOMESCREEN || !Config.WELCOMESCREEN.SLIDE_IMAGE_STRETCH) &&
  `
  padding: ${Metrics.baseMargin}px;
  margin-top: ${({ theme: { isBigScreen } }) => (isBigScreen ? '0' : `${Metrics.baseMargin}px`)};
  `}
`

export const StyledViewCTAsContainer = styled(View)`
  ${({ theme: { isSmallScreen } }) => isSmallScreen && 'width: 100%;'}
  max-width: ${({ theme: { isBigScreen } }) => (isBigScreen ? '80%' : '100%')};
  padding: ${Metrics.baseMargin}px;
`

export const StyledViewTextContainer = styled(View)`
  align-items: center;
  text-align: center;
  padding: ${Metrics.baseMargin}px;
  gap: ${Metrics.baseMargin}px;
`

export const StyledViewVideoContainer = styled(View)`
  flex-direction: column;
  flex: ${({ theme: { isBigScreen } }) => (isBigScreen ? '0.8' : '1')};
`

export const StyledViewImageContainer = styled(View)`
  flex: 1;
  background-color: ${({ item: { color } }) => color};
`

export const StyledImageLogo = styled(Image)`
  height: 45px;
  width: 45px;
  resize-mode: contain;
`

export const StyledVideo = styled(Video)`
  width: 100%;
  flex: 1.25;
  flex-grow: 1.25;
  flex-shrink: 0;
  background-color: ${Colors.welcomeScreenBackgroundSlide0};
`

export const StyledViewDotsContainer = styled(View)`
  flex-direction: row;
  position: absolute;
  bottom: ${({ theme: { isBigScreen } }) => (isBigScreen ? '5%' : '30%')};
`

export const StyledLinearGradient = styled(LinearGradient)`
  height: 200px;
  width: 100%;
  margin-top: -200px;
`

export const StyledViewButtonsContainer = styled(View)`
  gap: ${Metrics.baseMargin}px;
`

export default {
  // To pass custom styles to the internal video component
  videoStyle: {
    height: '100%',
    width: '100%',
  },
  icon: {
    height: 40,
    width: 100,
  },
}
