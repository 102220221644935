import React, { useContext, useMemo, memo } from 'react'
import { Text, View } from 'react-native'

import { getPractitionerFromChat } from 'APP/Lib/PractitionerHelpers'

import moment from 'moment'
import { Styles } from './style'
import I18n from 'APP/Services/i18n'

import Avatar from 'APP/Components/FelixChat/Avatar'
import PostReceipt from 'APP/Components/FelixChat/PostReceipt'
import SingleChoice from 'APP/Components/FelixChat/SingleChoice'
import { ChatContext, PostContext } from 'APP/Lib/Context'
import {
  getPostComponent,
  isCalendar,
  isCmsContent,
  onlyDisplayedWhenLastPost,
} from 'APP/Lib/MessagingHelpers'
import DialogueMarkdown from 'APP/Components/DialogueMarkdown'
import MultiChoice from 'APP/Components/FelixChat/MultiChoice'
import FelixProviderList from 'APP/Components/FelixChat/ProviderList'

const Post = () => {
  const { users } = useContext(ChatContext)
  const {
    post,
    previousPostIsSameUser,
    isMember,
    nextPostIsSameUser,
    previousPostIsSameDay,
    isLastPost,
  } = useContext(PostContext)

  const PostComponent = useMemo(() => getPostComponent(post), [post])

  const renderLastPostComponent = () => {
    if (!isLastPost) return
    /* calendar and cms content is a single choice message type
    but we don't want to render it if it's the last post */
    if (isCalendar(post) || isCmsContent(post)) return
    if (post?.props?.type === 'prompt_choice_multi' || post?.props?.type === 'multiSelect.v1') {
      return (
        <View style={Styles.postContainerRight}>
          <MultiChoice />
        </View>
      )
    }
    if (post?.props?.type === 'prompt_provider_list') {
      return (
        <View style={Styles.postContainerRight}>
          <FelixProviderList />
        </View>
      )
    }
    if (post?.props?.choices?.length > 0) {
      return (
        <View style={Styles.postContainerRight}>
          <SingleChoice />
        </View>
      )
    }
    if (post?.props?.question?.note) {
      return (
        <View style={Styles.markdownContainer}>
          <DialogueMarkdown styles={Styles.markdownStyles} markdown={post?.props?.question?.note} />
        </View>
      )
    }
  }

  const renderDateComponent = () => {
    if (previousPostIsSameDay) return

    return (
      <View style={Styles.dateContainer}>
        <Text style={Styles.dateText}>{moment(post.create_at).calendar()}</Text>
      </View>
    )
  }

  if (!isLastPost && onlyDisplayedWhenLastPost(post)) {
    return null
  }

  const slimContainer = nextPostIsSameUser ? Styles.slimContainer : {}

  if (isMember) {
    return (
      <View style={Styles.flip}>
        {renderDateComponent()}
        <View style={{ ...Styles.rightContainer, ...slimContainer }}>
          <View style={Styles.postContainerRight}>
            <PostComponent />
          </View>
          {renderLastPostComponent()}
          {post?.props?.failed ? (
            <View>
              {post?.props.errorMessage && (
                <Text style={Styles.error}>{post?.props.errorMessage}</Text>
              )}
              <Text style={Styles.error}>{I18n.t('ConversationScreen.errorSending')}</Text>
            </View>
          ) : (
            <PostReceipt />
          )}
        </View>
      </View>
    )
  } else {
    const { first_name, last_name } = getPractitionerFromChat({ post, isMember, users }) || {}

    const name = [first_name, last_name].filter((el) => el).join(' ') || I18n.t('Common.clinic')

    return (
      <View style={Styles.flip}>
        {renderDateComponent()}
        <View style={{ ...Styles.leftContainer, ...slimContainer }}>
          <View style={Styles.columnContainer}>
            {(!previousPostIsSameUser || !previousPostIsSameDay) && (
              <Text style={Styles.leftName}>{name}</Text>
            )}
            <View style={Styles.postContainerLeft}>
              <Avatar />
              <PostComponent />
            </View>
          </View>
          {renderLastPostComponent()}
        </View>
      </View>
    )
  }
}

export default memo(Post)
