import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import { PharmacyType } from '@dialogue/coredata'

import RemoteRequestHelper from '../Lib/RemoteRequestHelper'
import { createSelector } from '@reduxjs/toolkit'
import { calculateAge } from 'APP/Lib/DateHelpers'

// External pharmacy service considers anyone under 16 years of age a dependent who cannot consult for themselves
export const MINIMUM_ELIGIBLE_EPHARMACY_AGE = 16

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  ...RemoteRequestHelper.actionGenerator('patientCreditCardFetch'),
  ...RemoteRequestHelper.actionGenerator('patientCreditCardCharge'),
  patientCreditCardRegisterRequest: ['token', 'chargeId'],
  patientCreditCardRegisterSuccess: ['data'],
  patientCreditCardRegisterFailure: ['error'],
  scribeV2UserFetchRequest: null,
  scribeV2UserFetchSuccess: ['profile'],
  scribeV2UserFetchFailure: null,
  scribeV2UserEligibilitiesFetchRequest: null,
  scribeV2UserEligibilitiesFetchSuccess: ['profile'],
  scribeV2UserEligibilitiesFetchFailure: null,
  userFetchHealthResourcesRequest: null,
  userFetchHealthResourcesSuccess: ['healthResources'],
  userFetchHealthResourcesFailure: null,
  patientEmailChangeRequest: ['email', 'resend'],
  patientEmailChangeSuccess: null,
  patientEmailChangeFailure: ['error'],
  patientProfileFetchRequest: null,
  patientProfileFetchRunning: null,
  patientProfileFetchSuccess: ['profile'],
  patientProfileFetchFailure: ['error'],
  patientUpdateLocationSuccess: ['location'],
  patientUpdateLocationFailure: ['error'],
  patientProfileMedicalIdFetchRequest: null,
  patientProfileMedicalIdFetchSuccess: ['idCardUrl'],
  patientProfileMedicalIdFetchFailure: ['error'],
  patientProfileClear: null,
  patientProfileGiveConsent: ['forms'],
  patientProfileDeclineConsent: ['forms'],
  patientProfileGiveConsentSuccess: ['profile'],
  patientProfileGiveConsentFailure: ['error'],
  patientProfileRevokeConsent: null,
  patientProfileUpdateRequest: ['patient'],
  patientProfileUpdateSuccess: ['profile'],
  patientProfileUpdateFailure: null,
  patientProfileBasicUpdateRequest: ['patient'],
  patientSkipIdCard: null,
  patientClearIdCard: null,
  patientIdCardUpdateRequest: ['fileObj'],
  patientIdCardUpdateSuccess: ['idCardUrl'],
  patientIdCardUpdateFailure: ['error'],
  patientLocationPermissionRequest: ['skip'],
  patientLocationServiceAccess: ['skip'],
  patientEnrolWithEnrolmentCodesRequest: ['codes'],
  patientEnrolWithEnrolmentCodesSuccess: ['data'],
  patientEnrolWithEnrolmentCodesFailure: ['error'],
  userInfoFetchRequest: null,
  userInfoFetchSuccess: ['userInfo'],
  userInfoFetchFailure: ['error'],
  unchallengedInvitationSearchRequest: null,
  unchallengedInvitationSearchSuccess: null,
  unchallengedInvitationSearchFailure: null,
  challengedInvitationSearchRequest: null,
  challengedInvitationSearchSuccess: ['results'],
  challengedInvitationSearchFailure: null,
  challengedInvitationQueueRequest: ['queue'],
  challengedInvitationQueuePop: [],
  challengedInvitationQueueNext: [],
  challengedInvitationQueueEmpty: [],
  challengeInvitationRequest: ['challenge', 'uniqueIdentifiers'],
  challengeInvitationSuccess: null,
  challengeInvitationFailure: ['error'],
  resendInvitationRequest: ['email'],
  resendInvitationSuccess: null,
  resendInvitationFailure: ['error'],
  pillwayAuthenticationRequest: ['request'],
  pillwayAuthenticationSuccess: ['accessToken'],
  pillwayAuthenticationFailure: ['error'],
  onboardingDeferredEnrolmentComplete: null,
  setIsOnboarding: ['isOnboarding'],
  postNotificationsRequest: null,
  changeEmailRequest: ['password'],
  setChangeEmailToken: ['token'],
  changeEmailFailure: ['error'],
  changeEmailSuccess: ['email'],
  runStartupAfterChangeEmail: [],
  submitCommitment: null,
  setDisplayCommitmentScreen: ['displayCommitmentScreen'],
  skipEmailVerification: null,
  submitHealthProfileCompleted: null,
  setHealthProfileSuccess: null,
})

export const PatientTypes = Types

export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  profile: {
    location: {},
    contentTags: [],
    healthResources: [],
    creditCard: false,
    eligibleServices: [],
    eligibleBrands: [],
    eligibilityIntervals: [],
    hasFamilyAccess: false,
    cobrands: [],
    mfaRequired: false,
  },
  requestRunning: false,
  error: null,
  idCardSkipped: false,
  idCardUrl: null,
  idCardLocalPath: null,
  giveConsentRunning: false,
  profileFetchRunning: false,
  changeEmailRunning: false,
  changeEmailSuccess: null,
  changeEmailError: null,
  userInfo: null,
  pillway: {
    running: true,
    accessToken: null,
    error: null,
  },
  unchallengedInvitationSearchRunning: false,
  challengedInvitationSearchRunning: false,
  enrolWithEnrolmentCodesResult: null,
  challengedInvitationQueue: [],
  challengeInvitationRunning: false,
  challengeInvitationError: null,
  isOnboarding: false,
  patientCreditCardRegisterRunning: false,
  patientCreditCardRegisterSuccess: false,
  patientCreditCardRegisterFailure: false,
  changeEmailToken: {},
  isSturtupRunning: false,
  displayCommitmentScreen: false,
  healthProfileCompleted: null,
})

/* ------------- Reducers ------------- */

// reset profile redux to initial state
export const profileClear = (state) =>
  INITIAL_STATE.merge({
    changeEmailToken: state.changeEmailToken,
  })

export const setIsSturtupRunning = (state, action) =>
  state.merge({ isSturtupRunning: action.data ?? true })

// start request
export const request = (state) => state.merge({ requestRunning: true })

export const success = (state) => state.merge({ requestRunning: false })

// request failed
export const failure = (state, action) =>
  state.merge({ requestRunning: false, error: action.error })

// profile fetched successfully
export const profileChangeSuccess = (state, action) => {
  const newProfile = state.profile.merge(action.profile)
  return state.merge({ requestRunning: false, error: null, profile: newProfile })
}

// medical id fetched successfully
export const patientMedicalIdFetchSuccess = (state, action) => {
  return state.merge({ idCardUrl: action.idCardUrl })
}

// user has given consent
// FIXME consent data format
export const profileGiveConsent = (state) => {
  const newProfile = state.profile.merge({
    hasConsented: true,
    consentDate: new Date(),
  })

  return state.merge({
    profile: newProfile,
    giveConsentRunning: true,
  })
}

export const profileGiveConsentSuccess = (state, action) => {
  const newProfile = state.profile.merge(action.profile)
  return state.merge({ giveConsentRunning: false, error: null, profile: newProfile })
}

export const profileGiveConsentFailure = (state, { error }) => {
  const newProfile = state.profile.merge({ hasConsented: false, hasConsentedLocally: false })
  return state.merge({ giveConsentRunning: false, error: error, profile: newProfile })
}

// consent revoked
// FIXME consent data format
export const profileRevokeConsent = (state) => {
  const newProfile = state.profile.merge({ hasConsented: false, consentDate: null })
  return state.merge({ profile: newProfile })
}

// basic profile updated successfully
export const profileUpdateRequest = (state, action) => {
  const profile = state.profile.merge(action.patient)
  return state.merge({ requestRunning: true, profile: profile })
}

// location data has changed
export const updateLocationSuccess = (state, action) => {
  const updatedProfile = state.profile.merge({
    locationRequestRunning: false,
    location: action.location,
  })
  return state.merge({ profile: updatedProfile })
}

// location data was not available
export const updateLocationFailure = (state, action) => {
  const updatedProfile = state.profile.merge({
    locationRequestRunning: false,
    location: action.error,
  })
  return state.merge({ profile: updatedProfile })
}

// set skip id card flag
export const skipIdCard = (state) => state.merge({ idCardSkipped: true })

// unset skip id card flag
export const clearIdCard = (state) => state.merge({ idCardSkipped: false })

export const idCardUpdateRequest = (state, action) =>
  state.merge({ requestRunning: true, idCardLocalPath: action.path })

export const idCardUpdateSuccess = (state, action) =>
  state.merge({ requestRunning: false, idCardUrl: action.idCardUrl })

export const profileLocationSuccess = (state, action) =>
  state.merge({ requestRunning: false, idCardUrl: action.idCardUrl })

export const profileLocationFailure = (state, action) =>
  state.merge({ requestRunning: false, idCardUrl: action.idCardUrl })

export const profileFetchRequest = (state) =>
  state.merge({ requestRunning: true, profileFetchRunning: true })

export const profileFetchSuccess = (state, action) =>
  profileChangeSuccess(state.merge({ profileFetchRunning: false }), action)

export const profileFetchFailure = (state, action) =>
  failure(state.merge({ profileFetchRunning: false }), action)

export const scribeV2UserFetchSuccess = (state, action) => {
  return state.merge({
    profile: {
      ...state.profile,
      healthResources: action.profile.healthResources,
      contentTags: action.profile.contentTags,
      creditCard: action.profile.creditCard,
      eligibleServices: action.profile.eligibleServices,
      eligibleBrands: action.profile.eligibleBrands,
      hasFamilyAccess: action.profile.hasFamilyAccess,
      cobrands: action.profile.cobrands,
    },
  })
}

export const scribeV2UserFetchFailure = (state) => {
  return state.merge({
    profile: {
      ...state.profile,
      healthResources: [],
      contentTags: [],
      creditCard: false,
      eligibleServices: [],
      eligibleBrands: [],
      hasFamilyAccess: false,
      cobrands: [],
    },
  })
}

export const scribeV2UserEligibilitiesFetchSuccess = (state, action) => {
  return state.merge({
    profile: {
      ...state.profile,
      mfaRequired: action.profile.isMfaRequired,
      forcedSessionInactivityRequired: action.profile.isForcedSessionInactivityRequired,
      eligibilityIntervals: action.profile.eligibilityIntervals,
    },
  })
}
export const scribeV2UserEligibilitiesFetchFailure = (state) => {
  return state.merge({
    profile: {
      ...state.profile,
      mfaRequired: false,
    },
  })
}

export const userFetchHealthResourcesSuccess = (state, action) => {
  return state.merge({
    profile: {
      ...state.profile,
      healthResources: action.healthResources,
    },
  })
}

export const userFetchHealthResourcesFailure = (state) => {
  return state.merge({
    profile: {
      ...state.profile,
      healthResources: [],
    },
  })
}

export const creditCardFetchSuccess = (state, action) => {
  if (!action.data) return state
  return state.merge({
    profile: {
      ...state.profile,
      creditCard: {
        name: action.data.cardholder_name,
        last4Digits: action.data.last_4_digits,
        expirationYear: action.data.expiration_year,
        expirationMonth: action.data.expiration_month,
      },
    },
  })
}

export const emailChangeRequest = (state) =>
  state.merge({ changeEmailRunning: true, changeEmailError: null })

export const emailChangeSuccess = (state) =>
  state.merge({ changeEmailRunning: false, changeEmailError: null })

export const emailChangeFailure = (state, action) =>
  state.merge({ changeEmailRunning: false, changeEmailError: action.error })

export const userInfoFetchSuccess = (state, action) => state.merge({ userInfo: action.userInfo })

export const userInfoFetchFailure = (state) => state.merge({ userInfo: {} })

export const pillwayAuthenticationRequest = (state) => state.merge({ pillway: { running: true } })

export const pillwayAuthenticationSuccess = (state, action) =>
  state.merge({ pillway: { accessToken: action.accessToken, running: false, error: null } })

export const pillwayAuthenticationFailure = (state, action) =>
  state.merge({ pillway: { accessToken: null, running: false, error: action.error } })

export const unchallengedInvitationSearchRequest = (state) =>
  state.merge({ unchallengedInvitationSearchRunning: true })

export const unchallengedInvitationSearchSuccess = (state) =>
  state.merge({ unchallengedInvitationSearchRunning: false })

export const unchallengedInvitationSearchFailure = (state) =>
  state.merge({ unchallengedInvitationSearchRunning: false })

export const challengedInvitationSearchRequest = (state) =>
  state.merge({ challengedInvitationSearchRunning: true })

export const challengedInvitationSearchSuccess = (state) =>
  state.merge({ challengedInvitationSearchRunning: false })

export const challengedInvitationSearchFailure = (state) =>
  state.merge({ challengedInvitationSearchRunning: false })

export const challengedInvitationQueueRequest = (state, action) =>
  state.merge({ challengedInvitationQueue: action.queue, challengeInvitationError: null })

export const challengedInvitationQueuePop = (state) =>
  state.merge({
    challengedInvitationQueue: state.challengedInvitationQueue.slice(1),
    challengeInvitationError: null,
  })

export const challengedInvitationQueueEmpty = (state) =>
  state.merge({ challengedInvitationQueue: [], challengeInvitationError: null })

export const enrolWithEnrolmentCodesSuccess = (state, action) => {
  return state.merge({
    enrolWithEnrolmentCodesResult:
      action?.data?.map?.((enrolmentResult) => enrolmentResult.organization) ?? [],
    requestRunning: false,
  })
}

export const challengeInvitationRequest = (state) =>
  state.merge({ challengeInvitationRunning: true, challengeInvitationError: null })

export const challengeInvitationSuccess = (state) =>
  state.merge({ challengeInvitationRunning: false, challengeInvitationError: null })

export const challengeInvitationFailure = (state, action) =>
  state.merge({ challengeInvitationRunning: false, challengeInvitationError: action.error })

export const setIsOnboarding = (state, { isOnboarding }) => {
  return state.merge({ isOnboarding })
}

export const patientCreditCardRegisterRequest = (state) => {
  return state.merge({
    patientCreditCardRegisterRunning: true,
    patientCreditCardRegisterSuccess: false,
    patientCreditCardRegisterFailure: false,
  })
}

export const patientCreditCardRegisterSuccess = (state, action) => {
  return state.merge({
    patientCreditCardRegisterRunning: false,
    patientCreditCardRegisterSuccess: true,
    patientCreditCardRegisterFailure: false,
    profile: {
      ...state.profile,
      creditCard: {
        name: action.data.cardholder_name,
        last4Digits: action.data.last_4_digits,
        expirationYear: action.data.expiration_year,
        expirationMonth: action.data.expiration_month,
      },
    },
  })
}

export const patientCreditCardRegisterFailure = (state, action) => {
  return state.merge({
    patientCreditCardRegisterRunning: false,
    patientCreditCardRegisterFailure: action.error,
  })
}

export const changeEmailSuccess = (state, { email }) => {
  return state.merge({
    profile: { email },
    emailChangeSuccess: true,
    error: null,
    requestRunning: false,
    changeEmailToken: {},
  })
}

export const changeEmailFailure = (state, { error }) => {
  return state.merge({
    emailChangeSuccess: false,
    error: error,
    requestRunning: false,
    changeEmailToken: {},
  })
}

export const setChangeEmailToken = (state, { token }) => state.merge({ changeEmailToken: token })

export const setDisplayCommitmentScreen = (state, { displayCommitmentScreen }) => {
  return state.merge({ displayCommitmentScreen })
}

export const submitCommitment = (state) => {
  return state.merge({ displayCommitmentScreen: false })
}

export const setHealthProfileSuccess = (state) => {
  return state.merge({ healthProfileCompleted: true })
}

/* ------------- Hookup Reducers To Types ------------- */

const handlers = {
  ...RemoteRequestHelper.handlerGenerator(
    'PATIENT_CREDIT_CARD_FETCH',
    null,
    creditCardFetchSuccess,
    null
  ),
  ...RemoteRequestHelper.handlerGenerator('PATIENT_CREDIT_CARD_CHARGE', null, null, null),
  [Types.PATIENT_CREDIT_CARD_REGISTER_REQUEST]: patientCreditCardRegisterRequest,
  [Types.PATIENT_CREDIT_CARD_REGISTER_SUCCESS]: patientCreditCardRegisterSuccess,
  [Types.PATIENT_CREDIT_CARD_REGISTER_FAILURE]: patientCreditCardRegisterFailure,
  [Types.SCRIBE_V2_USER_FETCH_SUCCESS]: scribeV2UserFetchSuccess,
  [Types.SCRIBE_V2_USER_FETCH_FAILURE]: scribeV2UserFetchFailure,
  [Types.PILLWAY_AUTHENTICATION_REQUEST]: pillwayAuthenticationRequest,
  [Types.PILLWAY_AUTHENTICATION_SUCCESS]: pillwayAuthenticationSuccess,
  [Types.PILLWAY_AUTHENTICATION_FAILURE]: pillwayAuthenticationFailure,
  [Types.SCRIBE_V2_USER_ELIGIBILITIES_FETCH_SUCCESS]: scribeV2UserEligibilitiesFetchSuccess,
  [Types.SCRIBE_V2_USER_ELIGIBILITIES_FETCH_FAILURE]: scribeV2UserEligibilitiesFetchFailure,
  [Types.USER_FETCH_HEALTH_RESOURCES_SUCCESS]: userFetchHealthResourcesSuccess,
  [Types.USER_FETCH_HEALTH_RESOURCES_FAILURE]: userFetchHealthResourcesFailure,
  [Types.PATIENT_PROFILE_MEDICAL_ID_FETCH_REQUEST]: request,
  [Types.PATIENT_PROFILE_MEDICAL_ID_FETCH_SUCCESS]: patientMedicalIdFetchSuccess,
  [Types.PATIENT_PROFILE_MEDICAL_ID_FETCH_FAILURE]: failure,
  [Types.PATIENT_PROFILE_FETCH_REQUEST]: profileFetchRequest,
  [Types.PATIENT_PROFILE_FETCH_SUCCESS]: profileFetchSuccess,
  [Types.PATIENT_PROFILE_FETCH_FAILURE]: profileFetchFailure,
  [Types.PATIENT_PROFILE_CLEAR]: profileClear,
  [Types.PATIENT_PROFILE_GIVE_CONSENT]: profileGiveConsent,
  [Types.PATIENT_PROFILE_GIVE_CONSENT_SUCCESS]: profileGiveConsentSuccess,
  [Types.PATIENT_PROFILE_GIVE_CONSENT_FAILURE]: profileGiveConsentFailure,
  [Types.PATIENT_PROFILE_REVOKE_CONSENT]: profileRevokeConsent,
  [Types.PATIENT_PROFILE_UPDATE_REQUEST]: profileUpdateRequest,
  [Types.PATIENT_PROFILE_UPDATE_SUCCESS]: profileChangeSuccess,
  [Types.PATIENT_PROFILE_UPDATE_FAILURE]: failure,
  [Types.PATIENT_PROFILE_BASIC_UPDATE_REQUEST]: profileUpdateRequest,
  [Types.PATIENT_UPDATE_LOCATION_SUCCESS]: updateLocationSuccess,
  [Types.PATIENT_UPDATE_LOCATION_FAILURE]: updateLocationFailure,
  [Types.PATIENT_SKIP_ID_CARD]: skipIdCard,
  [Types.PATIENT_CLEAR_ID_CARD]: clearIdCard,
  [Types.PATIENT_EMAIL_CHANGE_REQUEST]: emailChangeRequest,
  [Types.PATIENT_EMAIL_CHANGE_SUCCESS]: emailChangeSuccess,
  [Types.PATIENT_EMAIL_CHANGE_FAILURE]: emailChangeFailure,
  [Types.PATIENT_ID_CARD_UPDATE_REQUEST]: idCardUpdateRequest,
  [Types.PATIENT_ID_CARD_UPDATE_SUCCESS]: idCardUpdateSuccess,
  [Types.PATIENT_ID_CARD_UPDATE_FAILURE]: failure,
  [Types.PATIENT_ENROL_WITH_ENROLMENT_CODES_REQUEST]: request,
  [Types.PATIENT_ENROL_WITH_ENROLMENT_CODES_SUCCESS]: enrolWithEnrolmentCodesSuccess,
  [Types.PATIENT_ENROL_WITH_ENROLMENT_CODES_FAILURE]: failure,
  [Types.USER_INFO_FETCH_SUCCESS]: userInfoFetchSuccess,
  [Types.USER_INFO_FETCH_FAILURE]: userInfoFetchFailure,
  [Types.UNCHALLENGED_INVITATION_SEARCH_REQUEST]: unchallengedInvitationSearchRequest,
  [Types.UNCHALLENGED_INVITATION_SEARCH_SUCCESS]: unchallengedInvitationSearchSuccess,
  [Types.UNCHALLENGED_INVITATION_SEARCH_FAILURE]: unchallengedInvitationSearchFailure,
  [Types.CHALLENGED_INVITATION_SEARCH_REQUEST]: challengedInvitationSearchRequest,
  [Types.CHALLENGED_INVITATION_SEARCH_SUCCESS]: challengedInvitationSearchSuccess,
  [Types.CHALLENGED_INVITATION_SEARCH_FAILURE]: challengedInvitationSearchFailure,
  [Types.CHALLENGED_INVITATION_QUEUE_REQUEST]: challengedInvitationQueueRequest,
  [Types.CHALLENGED_INVITATION_QUEUE_POP]: challengedInvitationQueuePop,
  [Types.CHALLENGED_INVITATION_QUEUE_EMPTY]: challengedInvitationQueueEmpty,
  [Types.CHANGE_EMAIL_REQUEST]: request,
  [Types.CHANGE_EMAIL_SUCCESS]: changeEmailSuccess,
  [Types.CHANGE_EMAIL_FAILURE]: changeEmailFailure,
  [Types.SET_CHANGE_EMAIL_TOKEN]: setChangeEmailToken,

  [Types.RESEND_INVITATION_REQUEST]: request,
  [Types.RESEND_INVITATION_SUCCESS]: success,
  [Types.RESEND_INVITATION_FAILURE]: failure,
  [Types.CHALLENGE_INVITATION_REQUEST]: challengeInvitationRequest,
  [Types.CHALLENGE_INVITATION_SUCCESS]: challengeInvitationSuccess,
  [Types.CHALLENGE_INVITATION_FAILURE]: challengeInvitationFailure,
  [Types.SET_IS_ONBOARDING]: setIsOnboarding,
  [Types.RUN_STARTUP_AFTER_CHANGE_EMAIL]: emailChangeRequest,
  [Types.SET_DISPLAY_COMMITMENT_SCREEN]: setDisplayCommitmentScreen,
  [Types.SUBMIT_COMMITMENT]: submitCommitment,
  [Types.SET_HEALTH_PROFILE_SUCCESS]: setHealthProfileSuccess,
}

export const reducer = createReducer(INITIAL_STATE, handlers)

export const selectPatientState = (state) => state.patient

export const selectPatientProfile = (state) => state.patient?.profile
export const selectPatientProfileLocation = createSelector(
  [selectPatientProfile],
  (profile) => profile?.location?.province || profile?.residesIn
)
export const selectPreferredLanguage = createSelector(
  [selectPatientProfile],
  (profile) => profile?.preferred_language || 'en'
)

export const selectExternalServices = createSelector([selectPatientProfile], (profile) => {
  const externalServices = profile?.healthResources
  const age = calculateAge(profile?.dateOfBirth)

  return externalServices?.filter((service) => {
    if (service.type !== PharmacyType.epharmacy) return true
    return age >= MINIMUM_ELIGIBLE_EPHARMACY_AGE
  })
})

export const selectEpharmacyServiceByExternalId = createSelector(
  [selectPatientProfile, (_, externalId) => externalId],
  (profile, externalId) => {
    const externalServices = profile?.healthResources
    return externalServices?.find(
      (service) =>
        service.type === PharmacyType.epharmacy &&
        service.attributes.epharmacyIdentifier === externalId
    )
  }
)

export const selectEpharmacyExternalServicesByProvider = createSelector(
  [selectExternalServices, (_, provider) => provider],
  (externalServices, provider) =>
    externalServices?.filter(
      (service) =>
        service.type === PharmacyType.epharmacy && service.attributes.epharmacyProvider === provider
    )
)
