import React from 'react'
import { View } from 'react-native'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import styled, { useTheme } from 'styled-components/native'

import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import Typography from 'APP/Converse/Typography'

import I18n from 'APP/Services/i18n'
import { getServiceOrServiceGroupTileIcon } from 'APP/Lib/ContentHelpers'

interface CmsIcon {
  id?: string
  title?: string
  url?: string
  filename?: string
  content_type?: string
  description?: string
}

interface Service {
  title: string
  icon?: CmsIcon
  appIconName?: string
  providerTitle?: string
  browseTitle?: string
  browseType?: string
}

interface ServiceCardProps {
  service: Service
  onPress: () => void
  testID?: string
  maxWidth: number
}

const CardContainer = styled(DialogueTouchableOpacity)`
  margin: ${({ theme }) => theme.metrics.baseMargin * 0.75}px;
  background-color: ${({ theme }) => theme.colors.elementsBg};
  border-radius: 16px;
  width: ${({ maxWidth }) => maxWidth}px;
  padding-horizontal: ${({ theme }) => theme.metrics.baseMargin * 1.5}px;
  padding-vertical: ${({ theme }) => theme.metrics.baseMargin * 2}px;
  flex-direction: row;
  ${({ theme }) => theme.shadows.normal}
`

const ImageContainer = styled(View)`
  width: ${({ theme }) => theme.metrics.icon.large}px;
  height: ${({ theme }) => theme.metrics.icon.large}px;
  margin-right: ${({ theme }) => theme.metrics.baseMargin / 2}px;
`

const TextContainer = styled(View)`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const SubtitleContainer = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin / 4}px;
`

const TitleContainer = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  flex-wrap: wrap;
`

const ExternalProviderContainer = styled(View)`
  flex-direction: row;
  align-items: center;
`

const ExternalProviderIcon = styled(MaterialIcon)`
  margin-right: ${({ theme }) => theme.metrics.baseMargin / 4}px;
`

const ServiceCard: React.FC<ServiceCardProps> = ({ service, onPress, testID, maxWidth = 328 }) => {
  const theme = useTheme()
  const title = service?.browseTitle || service.title
  const subtitle = I18n.t(`ServiceGroup.browse.${service?.browseType}`)
  const provider =
    service?.providerTitle &&
    I18n.t('ServiceGroup.external.browse', { provider: service?.providerTitle })

  return (
    <CardContainer onPress={onPress} maxWidth={maxWidth} testID={testID}>
      <ImageContainer>
        {getServiceOrServiceGroupTileIcon(service, {
          width: theme.metrics.icon.large,
          height: theme.metrics.icon.large,
          fillLine: theme.colors.serviceIconPrimary,
          accentFillLine: theme.colors.serviceIconAccent,
        })}
      </ImageContainer>
      <TextContainer>
        <SubtitleContainer variant="caption">{subtitle}</SubtitleContainer>
        <TitleContainer variant="h3">{title}</TitleContainer>
        {provider && (
          <ExternalProviderContainer>
            <ExternalProviderIcon
              name={'open-in-new'}
              size={theme.metrics.icon.tiny}
              color={theme.colors.serviceIconAccent}
            />
            <Typography variant="bodySmall">{provider}</Typography>
          </ExternalProviderContainer>
        )}
      </TextContainer>
    </CardContainer>
  )
}

export default ServiceCard
