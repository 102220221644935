import React, { memo } from 'react'
import styled from 'styled-components/native'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import { Images } from 'APP/Themes/index'
import Styles from 'APP/Containers/VideoCallScreen/style.web'
import Typography from 'APP/Converse/Typography'
import I18n from 'APP/Services/i18n'
import SessionManager from 'APP/NativeModules/SessionManager.web'
import Button from 'APP/Converse/Button'
import { videoSessionActions } from 'APP/Store/VideoSession'

const ButtonContainer = styled.View`
  align-items: center;
  gap: ${({ theme }) => theme.metrics.baseMargin / 2}px;
`

const WhiteButton = styled(Button)`
  ${({ theme, variant }) => variant === 'tertiary' && `background-color: ${theme.colors.white};`}
`

const Actions = () => {
  const dispatch = useDispatch()
  const videoSession = useSelector((state) => state.videoSession)
  const profileId = useSelector((state) => state.patient.profile.id)

  const isMuted = videoSession.patientIsMuted
  const muteTestId = isMuted ? 'button-muted-container' : 'button-unmuted-container'
  const muteTitle = isMuted ? 'VideoCallScreen.unmute' : 'VideoCallScreen.mute'

  const muteProps = isMuted
    ? {
        icon: Images.micMuted,
        analyticsName: 'Unmute audio',
        testID: 'button-unmute',
      }
    : {
        icon: Images.micUnmuted,
        analyticsName: 'Mute audio',
        testID: 'button-mute',
      }

  const toggleMutedAudio = () => {
    if (videoSession.patientIsMuted) {
      dispatch(videoSessionActions.patientUnmutedAudio({ userId: profileId }))
    } else {
      dispatch(videoSessionActions.patientMutedAudio({ userId: profileId }))
    }
  }

  const endCall = () => {
    dispatch(videoSessionActions.patientEndedCall({ userId: profileId }))
  }

  return (
    <View style={Styles.buttonRow}>
      <ButtonContainer testID={muteTestId}>
        <WhiteButton
          hasShadow
          iconVariant="image"
          variant="tertiary"
          widthVariant="circle"
          onPress={toggleMutedAudio}
          {...muteProps}
        />
        <Typography variant="subheader">{I18n.t(muteTitle)}</Typography>
      </ButtonContainer>
      {SessionManager.videoSourceCount > 1 && (
        <ButtonContainer>
          <WhiteButton
            hasShadow
            iconVariant="image"
            variant="tertiary"
            widthVariant="circle"
            icon={Images.switchCamera}
            onPress={() => SessionManager.cycleVideo()}
            analyticsName="Cycle camera"
            testID="cycle-camera"
          />
          <Typography variant="subheader">{I18n.t('VideoCallScreen.camera')}</Typography>
        </ButtonContainer>
      )}
      <ButtonContainer>
        <WhiteButton
          hasShadow
          iconVariant="image"
          icon={Images.hangUp}
          widthVariant="circle"
          variant="error"
          onPress={endCall}
          analyticsName="End call"
          testID="button-end-call"
        />
        <Typography variant="subheader">{I18n.t('VideoCallScreen.leave')}</Typography>
      </ButtonContainer>
    </View>
  )
}

export default memo(Actions)
