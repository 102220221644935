import Config from 'APP/Config'

export const getSignupOrgOptions = (rawSignupOptions, locale) => {
  if (!rawSignupOptions || !locale) return []
  const localizedSignupOptions = rawSignupOptions.map((option) => {
    return {
      ...option,
      label: option && option.label[locale],
    }
  })
  return localizedSignupOptions
}

export const getBrandConfig = (brandId, locale = 'en') => {
  const brandMap = Config.BRAND_MAP
  const brandKey = Config.BRAND_VERSION ? `${brandId}_${Config.BRAND_VERSION}` : brandId
  const brandConfig = brandMap && brandMap[brandKey]
  const url =
    brandConfig && brandConfig.branchLinks && brandConfig.branchLinks[Config.PROD ? 'prod' : 'dev']

  return {
    url,
    appName: brandConfig && brandConfig.appName,
    productName: brandConfig && brandConfig.productName[locale],
  }
}
