import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Metrics, Fonts } from 'APP/Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  scrollContainer: {
    flex: 1,
    backgroundColor: Colors.appBg,
  },
  container: {
    flex: 1,
    paddingHorizontal: Metrics.baseMargin * 2,
    paddingTop: Metrics.baseMargin * 2,
    paddingBottom: Metrics.baseMargin,
  },
  subtitle: {
    textAlign: 'left',
    marginBottom: Metrics.baseMargin,
  },
  bold: {
    fontFamily: Fonts.type.bold,
    fontWeight: 'bold',
  },
  button: {
    marginHorizontal: 0,
    marginBottom: Metrics.baseMargin,
  },
})
