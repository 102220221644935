import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Fonts, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  container: {
    flexGrow: 1,
    marginTop: 100 + Metrics.baseMargin,
  },
  overlay: {
    flex: 1,
  },
  list: {
    alignSelf: 'stretch',
  },
  listFooter: {
    color: Colors.text,
    paddingVertical: Metrics.baseMargin,
  },
  listHeader: {
    color: Colors.text,
    padding: Metrics.baseMargin,
  },
  listEmpty: {
    color: Colors.text,
    marginVertical: Metrics.baseMargin,
    alignSelf: 'center',
  },
  listItemTitle: {
    color: Colors.text,
    ...Fonts.style.body,
  },
  listItemSubtitle: {
    marginVertical: Metrics.baseMargin / 2,
    color: Colors.text,
    ...Fonts.style.bodySmall,
  },
  modal: {
    ...ApplicationStyles.shadowBottomLit,
    paddingVertical: Metrics.baseMargin * 2,
    marginTop: 'auto',
    backgroundColor: Colors.appBg,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  content: {
    paddingHorizontal: Metrics.baseMargin * 1.375,
  },
  dt: {
    ...Fonts.style.bodyBold,
    marginTop: 6,
    color: Colors.text,
  },
  dd: {
    ...Fonts.style.body,
    color: Colors.text,
  },
})
