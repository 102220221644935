import { StyleSheet } from 'react-native'
import { Colors, Fonts, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.appBg,
    flex: 1,
  },
  noContent: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    minHeight: Metrics.screenHeight - 225,
  },
  list: {
    paddingBottom: 100,
  },
  filterBtn: {
    height: 'auto',
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
  },
  refresh: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    alignSelf: 'center',
  },
  retry: {
    display: 'flex',
    alignItems: 'center',
    marginTop: Metrics.baseMargin * 4,
  },
  retryTitle: {
    ...Fonts.style.h3,
    color: Colors.text,
    marginTop: Metrics.baseMargin * 2,
    marginBottom: Metrics.baseMargin,
  },
  retryCopy: {
    ...Fonts.style.bodyLarge,
    color: Colors.text,
  },
  retryCta: {
    marginTop: Metrics.baseMargin * 2,
    minWidth: 140,
  },
  filterDot: {
    position: 'absolute',
    top: Metrics.baseMargin / 2,
    right: Metrics.baseMargin / 2,
    backgroundColor: Colors.accent,
    width: 8,
    height: 8,
    borderRadius: 4,
  },
})
