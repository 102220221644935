import { LogBox, Text } from 'react-native' // eslint-disable-line no-restricted-imports
import * as Sentry from '@sentry/react'
import { isObjectLike } from 'lodash'
import defaultConfig from '../../config.json'
import brandMap from './brandMap.json'
import debugSettings from './debugSettings.json'
import analyticsSchemas from './analyticsSchemas.json'
import reduxPersist from './ReduxPersist'
import { getBaseConfig, mergeWithBase } from 'APP/Helpers/brandVersionedUtils'
import { RESERVED_KEYS } from './constants'

const BASE_CONFIG = getBaseConfig(defaultConfig)

/**
 * Web auto-determines the environment based on the hostname on app start see `currentEnvironment`
 * Because of this, initialization has been simplified, AsyncStorage is no longer needed, and switching environments is redundant
 * No updates will occur to the config/env throughout the app lifecycle on web since MA redirects to corresponding host.
 * Mobile determines environment based on locale and may change throughout the app lifecycle
 *
 * Mobile: ConfigPicker updates the env through handlers + event listening
 * Web: ConfigPicker updates env through changing hostname
 *
 * WHY THE CHANGE TO CLASS
 * - Testing became inconsistent and difficult. This ensures it will always be consistent
 * - Improve devEx for future ICs (previously used and implemented with prototype causing unexpected and buggy behaviour)
 */

class ConfigClass {
  constructor() {
    this.configHostOverrides = BASE_CONFIG.HOST_OVERRIDES?.[window?.location?.hostname] || {}
    this.currentEnvironment =
      this.getURLEnvironment(window?.location?.hostname) ??
      (this.isEnvSupported(BASE_CONFIG.DEFAULT_ENVIRONMENT)
        ? BASE_CONFIG.DEFAULT_ENVIRONMENT
        : undefined)
    this.completeInit = () => {}
    this.initPromise = new Promise((resolve) => {
      this.completeInit = resolve
    })
    /*
     * Proxy to handle dynamic property access.
     * when accessing Config methods, return method
     * otherwise return BASE_CONFIG property based on `getConfigValue`
     */
    return new Proxy(this, {
      get: (target, prop) => {
        if (prop in target) {
          return target[prop]
        } else {
          return target.getConfigValue(prop)
        }
      },
    })
  }

  isEnvSupported(env) {
    return BASE_CONFIG?.SUPPORTED_ENVIRONMENTS?.includes?.(env)
  }

  getURLEnvironment(hostname) {
    return BASE_CONFIG?.SUPPORTED_ENVIRONMENTS?.find((env) => {
      const currentEnv = BASE_CONFIG?.ENVIRONMENTS?.[env]
      return currentEnv?.WEB_DOMAIN?.includes(hostname)
    })
  }

  applyConfigSettings() {
    if (__DEV__ && !this.getConfigValue('ENABLE_LOGS')) {
      !this.getConfigValue('DEBUG_SETTINGS').yellowBox && LogBox.ignoreAllLogs()
    }
    // Allow/disallow font-scaling in app
    Text.allowFontScaling =
      this.getConfigValue('ALLOW_TEXT_FONT_SCALING') !== undefined
        ? this.getConfigValue('ALLOW_TEXT_FONT_SCALING')
        : true
  }

  init() {
    try {
      this.applyConfigSettings()
      this.completeInit()
    } catch (e) {
      Sentry.captureException(new Error('Config initialization failed'), {
        extra: { originalError: e },
      })
      console.error(e)
    }
  }

  isInitialized() {
    return this.initPromise
  }

  changeEnvironment() {
    return Promise.resolve()
  }

  overrideKey(key, value) {
    BASE_CONFIG[key] = value
  }

  onConfigKeyChange() {
    return () => undefined
  }

  getConfigValue(key, environment = this.currentEnvironment) {
    switch (key) {
      case RESERVED_KEYS.ENVIRONMENT:
        return environment
      case RESERVED_KEYS.BRAND_MAP:
        return brandMap
      case RESERVED_KEYS.DEBUG_SETTINGS:
        return debugSettings
      case RESERVED_KEYS.ANALYTICS_SCHEMAS:
        return analyticsSchemas
      case RESERVED_KEYS.REDUX_PERSIST:
        return reduxPersist
      default: {
        // cascade the power to override the config in order of priority
        // from lowest to highest:
        // base < host < env select
        let value = BASE_CONFIG[key]

        if (this.configHostOverrides[key]) {
          value = this.configHostOverrides[key]
        }

        if (
          this.isEnvSupported(environment) &&
          key in (BASE_CONFIG.ENVIRONMENTS?.[environment] || {})
        ) {
          const envValue = BASE_CONFIG.ENVIRONMENTS[environment][key]

          value = isObjectLike(envValue) ? mergeWithBase(BASE_CONFIG[key], envValue) : envValue
        }

        return value
      }
    }
  }
}

const Config = new ConfigClass()

export default Config
