import { StyleSheet } from 'react-native'
import { Colors, Metrics, Fonts } from 'APP/Themes'

const postContainer = {
  width: '100%',
  display: 'flex',
  alignItems: 'flex-end',
}

export const Styles = StyleSheet.create({
  flip: {
    transform: [{ scaleY: -1 }],
  },
  leftContainer: {
    display: 'flex',
    width: '100%',
    marginBottom: 12,
  },
  rightContainer: {
    display: 'flex',
    width: '100%',
    marginBottom: 12,
  },
  columnContainer: {
    flexDirection: 'column',
    width: '100%',
  },
  slimContainer: {
    marginBottom: Metrics.baseMargin / 4,
  },
  postContainerLeft: {
    ...postContainer,
    flexDirection: 'row',
  },
  postContainerRight: {
    ...postContainer,
    flexDirection: 'row-reverse',
  },
  leftName: {
    ...Fonts.style.caption,
    color: Colors.textSecondary,
    marginBottom: Metrics.baseMargin / 4,
    marginLeft: Metrics.baseMargin * 3,
  },
  markdownContainer: {
    marginVertical: Metrics.baseMargin / 2,
  },
  markdownStyles: {
    body: {
      color: Colors.buttonPrimarySelected,
      fontSize: 12,
    },
    ul: {
      marginTop: 0,
      marginBottom: Metrics.baseMargin / 2,
      marginLeft: -12,
    },
  },
  dateContainer: {
    marginTop: Metrics.baseMargin / 2,
    marginBottom: Metrics.baseMargin,
  },
  dateText: {
    ...Fonts.style.caption,
    color: Colors.text,
    textAlign: 'center',
  },
  error: {
    textAlign: 'right',
    ...Fonts.style.caption,
    color: Colors.errorText,
  },
})
