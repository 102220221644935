import Cookies from 'js-cookie'
import * as Sentry from '@sentry/react'

export const setSecureStorageEntry = (key: string, value: string): Promise<void> => {
  try {
    Cookies.set(key, JSON.stringify(value || null), { secure: true, sameSite: 'Strict' })
  } catch (e) {
    Sentry.captureException(e)
  }
  return Promise.resolve()
}

export const getSecureStorageEntry = (key: string): Promise<string | null> => {
  try {
    const entry = Cookies.get(key)
    return Promise.resolve(entry ? JSON.parse(entry) : null)
  } catch (e) {
    Sentry.captureException(e)
    return Promise.resolve(null)
  }
}

export const removeSecureStorageEntry = (key: string): Promise<void> => {
  try {
    Cookies.remove(key)
  } catch (e) {
    Sentry.captureException(e)
  }
  return Promise.resolve()
}
