import React from 'react'
import { Colors, Metrics } from 'APP/Themes'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'

import styled from 'styled-components/native'
import Typography from 'APP/Converse/Typography'
import { useDialog } from './provider'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import { BottomDrawerHeaderProps } from '../BottomDrawer/header'

const Container = styled.View`
  flex-direction: column;
  width: 100%;
`

const Title = styled(Typography)`
  margin: ${Metrics.baseMargin}px 0;
`

const Button = styled(DialogueTouchableOpacity)`
  align-self: flex-end;
`

type Props = BottomDrawerHeaderProps

function Header({ title, closeButtonProps }: Props) {
  const { onClose } = useDialog()

  return (
    <Container>
      <Button
        onPress={onClose}
        accessibilityLabel="Close modal"
        accessibilityRole="button"
        testID="closeButton"
        {...closeButtonProps}
      >
        <MaterialIcon
          name="close"
          size={Metrics.icons.small}
          color={Colors.text}
          testID="modal.close"
        />
      </Button>
      <Title variant="h3">{title}</Title>
    </Container>
  )
}

export default Header
