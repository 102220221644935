import React, { useLayoutEffect, useEffect } from 'react'
import { View } from 'react-native'
import { Appearance } from 'react-native-appearance'

import CmsScreen from 'APP/Containers/CmsScreen'
import { useShareDeepLinkFromConfig } from 'APP/Hooks/Share'
import ButtonShare from 'APP/Components/ButtonShare'
import Analytics from 'APP/Services/Analytics'
import sprigClient from 'APP/Services/Sprig'
import UrlBuilder from 'APP/Services/UrlBuilder'
import { routeNameToAnalyticsName } from 'APP/Nav'
import Styles from './style'

const TRIGGER = {
  PROGRESS: 'progress',
  PLAY: 'play',
}

const ANALYTICS = {
  PAGE_VIEWED: 'CMS_SINGLE_AUDIO_PAGE_VIEWED',
  PAGE_CLOSED: 'CMS_SINGLE_AUDIO_PAGE_CLOSED',
  AUDIO_PLAY: 'CMS_AUDIO_PLAY',
  AUDIO_PROGRESS: 'CMS_AUDIO_PROGRESS',
}

const CmsAudioScreen = (props) => {
  const { navigation, route } = props
  const {
    id,
    title = '',
    services,
    hideBackButton,
    showShareButton,
  } = { ...route?.params, ...props }
  const uri = UrlBuilder.cmsAudioUrl(id, Appearance.getColorScheme())

  const analyticsName = routeNameToAnalyticsName(route?.name)

  const shareAudio = useShareDeepLinkFromConfig(
    'CMS_AUDIO_SCREEN_DEEP_LINK_URL',
    {
      services: 'wellness_content',
      id,
    },
    { analyticsName }
  )

  useEffect(() => {
    sprigClient.track(ANALYTICS.PAGE_VIEWED)
    return () => {
      sprigClient.track(ANALYTICS.PAGE_CLOSED)
    }
  }, [])

  useLayoutEffect(() => {
    const headerRight =
      showShareButton &&
      (() => <ButtonShare analyticsName={analyticsName} onPress={() => shareAudio.share()} />)

    navigation.setOptions({ headerRight })
  }, [analyticsName, hideBackButton, navigation, shareAudio, showShareButton, title])

  const onMessage = ({ nativeEvent: { data } }) => {
    const attributes = JSON.parse(data)

    if (attributes?.trigger === TRIGGER.PLAY) {
      sprigClient.track(ANALYTICS.AUDIO_PLAY)
      Analytics.trackEvent('button_click', {
        screen_name: analyticsName,
        trigger: uri,
        button_value: 'play',
      })
    }

    if (attributes?.trigger === TRIGGER.PROGRESS) {
      sprigClient.track(
        `${ANALYTICS.AUDIO_PROGRESS}_${attributes.type.toUpperCase()}_${attributes.value}`
      )
      Analytics.trackEvent('audio_progress', {
        ...attributes,
        screen_name: analyticsName,
        trigger: uri,
        id,
      })
    }
  }

  return (
    <View style={Styles.container}>
      <CmsScreen
        uri={uri}
        screen={'cms'}
        onMessage={onMessage}
        analyticsName={`${analyticsName}: ${id}`}
        navigation={navigation}
        title=""
        /*
          TODO: Double-check with Heather if we can remove screenUri. Seems like a typo
          (note: screenUri prop is not used inside the component)
        */
        screenUri={title}
        services={services}
      />
    </View>
  )
}

export default CmsAudioScreen
