import React from 'react'
import { Image, View, Text } from 'react-native'

// Styles
import Styles from './style'
import { getImageAspectRatio } from 'APP/Lib/StylingHelpers'
import { Images } from 'APP/Themes'

// Components
import { PrimaryButton, SecondaryButton, TertiaryButton } from 'APP/Components/Buttons'
import Header from 'APP/Converse/Header'
import { ResponsiveScrollView, ResponsiveView } from 'APP/Converse/Layout'

const WelcomeCtaContainer = ({
  title,
  subtitle,
  primaryButton,
  secondaryButton,
  tertiaryButton,
  hintText,
  hintCta,
  onBackButtonPress,
}) => {
  const iconSource = Images.logoBlue
  const iconAspectRatio = getImageAspectRatio(iconSource)

  return (
    <ResponsiveScrollView style={Styles.scrollContainer}>
      <ResponsiveView style={Styles.container}>
        {onBackButtonPress && <Header onBackButtonPress={onBackButtonPress} />}
        <Image style={[Styles.logo, { aspectRatio: iconAspectRatio }]} source={iconSource} />
        <Text style={Styles.title}>{title}</Text>
        <Text style={Styles.subtitle}>{subtitle}</Text>
        {primaryButton && (
          <PrimaryButton
            style={Styles.button}
            title={primaryButton.title}
            analyticsName={primaryButton.analyticsName}
            onPress={primaryButton.onPress}
            testID="primaryBtn"
          />
        )}
        {secondaryButton && (
          <SecondaryButton
            style={Styles.button}
            title={secondaryButton.title}
            analyticsName={secondaryButton.analyticsName}
            onPress={secondaryButton.onPress}
            testID="secondaryBtn"
          />
        )}
        {tertiaryButton && (
          <TertiaryButton
            style={Styles.button}
            title={tertiaryButton.title}
            analyticsName={tertiaryButton.analyticsName}
            onPress={tertiaryButton.onPress}
            testID="tertiaryBtn"
          />
        )}
        <View style={Styles.hintContainer}>
          <Text style={Styles.hint}>{hintText}</Text>
          {hintCta?.text && (
            <Text style={Styles.link} onPress={hintCta.onPress} testID="hintCta">
              {hintCta.text}
            </Text>
          )}
        </View>
      </ResponsiveView>
    </ResponsiveScrollView>
  )
}

export default WelcomeCtaContainer
