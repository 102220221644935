import React, { useContext, memo } from 'react'
import { View } from 'react-native'
import Typography from 'APP/Converse/Typography'
import { useSelector } from 'react-redux'
import { PostContext, ChatContext } from 'APP/Lib/Context'

import moment from 'moment'
import { Styles } from './style'
import I18n from 'APP/Services/i18n'

const PostReceipt = () => {
  const { users, episode, episodeMembers } = useContext(ChatContext)
  const { post, isLastPost } = useContext(PostContext)
  const readReceipts = useSelector((state) => state.features.readReceipts)

  if (!isLastPost) return

  const assigneeId = episode?.assigneeId
  if (readReceipts && assigneeId && episodeMembers?.length > 0) {
    const matchingPractitionerUser = users?.find((user) => user.app_id === String(assigneeId))
    const matchingEpisodeMember = episodeMembers?.find(
      (member) => member.userId === matchingPractitionerUser?.id
    )

    const lastViewedAt = matchingEpisodeMember?.lastViewedAt
    if (lastViewedAt && lastViewedAt >= post.create_at) {
      return (
        <View testID="read-receipt" style={Styles.container}>
          <Typography variant="caption">{moment(lastViewedAt).fromNow()}</Typography>
          <Typography variant="captionBold">{I18n.t('ConversationScreen.receipt.read')}</Typography>
        </View>
      )
    }
  }

  if (!post?.isLocal) {
    return (
      <View testID="delivered-receipt" style={Styles.container}>
        <Typography variant="caption">{I18n.t('ConversationScreen.receipt.delivered')}</Typography>
      </View>
    )
  }
}

export default memo(PostReceipt)
