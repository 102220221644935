import React, { useEffect } from 'react'
import { ActivityIndicator, Text, View } from 'react-native'

import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'

import I18n from 'APP/Services/i18n'
import WellnessCenterActions from 'APP/Redux/WellnessCenterRedux'

import { ChallengeCard } from 'APP/Components/Challenge_legacy'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import { PrimaryButton } from 'APP/Components/Buttons'
import { ResponsiveScrollView } from 'APP/Converse/Layout'
import Retry from 'APP/Images/retry.svg'
import { Colors, Metrics } from 'APP/Themes'
import Styles from './style'

const CompletedChallenges_legacy = () => {
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const { retry, loading, data } = useSelector((state) => state.wellnessCenter.completedChallenges)
  const { eligibilityIntervals } = useSelector((state) => state.patient.profile)
  const fetchData = () => dispatch(WellnessCenterActions.getCompletedChallenges())

  useEffect(() => {
    fetchData()
  }, [])

  const navigateToChallenge = (id) => {
    navigation.navigate('wcChallenge', { id })
  }

  return (
    <NoticeBarContainer>
      {loading && !data && (
        <ActivityIndicator
          size="large"
          color={Colors.text}
          style={Styles.loading}
          testID="loading"
        />
      )}
      {retry && (
        <View style={Styles.retry} testID="retry">
          <Retry fillLine={Colors.text} width={Metrics.icons.large} height={Metrics.icons.large} />
          <Text style={Styles.retryTitle}>{I18n.t('WellnessCenter.challenges.retry.title')}</Text>
          <Text style={Styles.retryCopy}>{I18n.t('WellnessCenter.challenges.retry.copy')}</Text>
          <PrimaryButton
            style={Styles.retryCta}
            title={I18n.t('WellnessCenter.challenges.retry.cta')}
            onPress={fetchData}
          />
        </View>
      )}
      {!retry && data?.length === 0 && (
        <View style={Styles.empty} testID="empty-view">
          <Text style={Styles.emptyTitle} testID="empty-title">
            {I18n.t('WellnessCenter.challenges.emptyList.completed.title')}
          </Text>
          <Text style={Styles.emptyCopy} testID="empty-copy">
            {I18n.t('WellnessCenter.challenges.emptyList.completed.copy')}
          </Text>
        </View>
      )}
      {!retry && data?.length > 0 && (
        <ResponsiveScrollView
          contentContainerStyle={Styles.scrollViewContentContainer}
          scrollIndicatorInsets={{ right: 1 }}
          testID="challenge-list"
        >
          {data.map((challenge, i) => (
            <ChallengeCard
              key={challenge.id}
              challenge={challenge}
              eligibilityIntervals={eligibilityIntervals}
              onPress={() => navigateToChallenge(challenge.id)}
              analyticsName={`completedChallenges.card.${i}`}
            />
          ))}
        </ResponsiveScrollView>
      )}
    </NoticeBarContainer>
  )
}

export default CompletedChallenges_legacy
