import { StyleSheet } from 'react-native'
import { Colors, Fonts, Metrics, ApplicationStyles } from 'APP/Themes'

export const MarkdownStyles = {
  p: {
    ...Fonts.style.body,
    paddingHorizontal: Metrics.baseMargin * 1.25,
  },
  strong: {
    ...Fonts.style.subheader,
  },
}

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  flex: {
    flex: 1,
  },
  flexGrow: {
    flexGrow: 1,
  },
  takeAssessment: {
    marginVertical: Metrics.baseMargin * 1.25,
  },
  wellBeingIndexCard: {
    backgroundColor: Colors.elementsBg,
    borderRadius: Metrics.baseMargin,
    marginHorizontal: Metrics.baseMargin * 1.25,
    marginVertical: Metrics.baseMargin,
    padding: Metrics.baseMargin * 0.5,
    ...ApplicationStyles.shadow,
  },
  emptyWellBeingCardTitle: {
    ...Fonts.style.h4,
    color: Colors.text,
    flex: 1,
    textAlign: 'center',
    paddingHorizontal: Metrics.baseMargin * 2,
    paddingTop: Metrics.baseMargin * 0.5,
    marginHorizontal: Metrics.baseMargin * 3.25,
  },
  wellBeingEmptyCardTitle: {
    ...Fonts.style.h3,
    color: Colors.text,
    flex: 1,
    textAlign: 'center',
    paddingTop: Metrics.baseMargin * 0.5,
    paddingHorizontal: Metrics.baseMargin * 2,
    marginHorizontal: Metrics.baseMargin * 1,
  },
  wellBeingCardTitle: {
    ...Fonts.style.h3,
    color: Colors.text,
    flex: 1,
    paddingHorizontal: Metrics.baseMargin,
  },
  emptyChartImage: {
    resizeMode: 'contain',
    height: 140,
    aspectRatio: 2.1,
    marginHorizontal: Metrics.baseMargin,
    marginVertical: Metrics.baseMargin * 2,
  },
  loadingContainer: {
    marginTop: Metrics.baseMargin * 6,
  },
})
