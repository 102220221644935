import React, { useEffect, useState, useMemo, useImperativeHandle } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import HabitsActions from 'APP/Redux/HabitsRedux'
import CalendarWeekly from 'APP/Components/CalendarWeekly'
import I18n from 'APP/Services/i18n'

// Use forwardRef & useImperativeHandle to expose a 'refresh' method through a ref
// that would be used to update data when the parent knows it is outdated
const HabitDailyProgressCalendar = React.forwardRef(
  ({ currentDay, selectedDay, onSelectedDayChange }, ref) => {
    const dispatch = useDispatch()

    const [fromDate] = useState(() =>
      moment().startOf('isoWeek').subtract(1, 'weeks').format(I18n.t('DateFormat'))
    )
    const [toDate] = useState(() => moment().endOf('isoWeek').format(I18n.t('DateFormat')))

    const dailyProgressState = useSelector((state) => state.habits.dailyProgress)
    const doneHabitState = useSelector((state) => state.habits.doneHabit)
    const undoneHabitState = useSelector((state) => state.habits.undoneHabit)
    const removeHabitState = useSelector((state) => state.habits.removeHabit)

    const fetchDailyProgressList = () =>
      dispatch(HabitsActions.getDailyProgressList(fromDate, toDate))

    useImperativeHandle(ref, () => ({
      refresh: fetchDailyProgressList,
    }))

    useEffect(() => {
      fetchDailyProgressList()
    }, [fromDate, toDate, doneHabitState, undoneHabitState, removeHabitState])

    const percentages = useMemo(
      () =>
        dailyProgressState.data.reduce((dp, { day, progress }) => {
          dp[day] = progress?.percentage
          return dp
        }, {}),
      [dailyProgressState.data]
    )

    return (
      <CalendarWeekly
        startDate={fromDate}
        endDate={toDate}
        currentDay={currentDay}
        selectedDay={selectedDay}
        percentages={percentages}
        onSelectedDayChange={onSelectedDayChange}
      />
    )
  }
)
HabitDailyProgressCalendar.displayName = 'HabitDailyProgressCalendar'

export default HabitDailyProgressCalendar
