import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  scrollContainer: {
    flex: 1,
    backgroundColor: Colors.appBg,
  },
  container: {
    flex: 1,
    paddingHorizontal: Metrics.baseMargin * 2,
    paddingTop: Metrics.baseMargin * 2,
    paddingBottom: Metrics.baseMargin,
  },
  primaryButton: {
    marginHorizontal: 0,
    marginBottom: Metrics.baseMargin,
  },
})
