import { Platform, StyleSheet } from 'react-native'
import styled from 'styled-components/native'
import { Fonts, Colors, Metrics } from 'APP/Themes'

export const MAX_WIDTH = Platform.select({
  ios: 175,
  android: 175,
  native: 392,
  default: 392,
})

export const MAX_SERVICE_WIDTH = Platform.select({
  ios: 328,
  android: 328,
  native: 392,
  default: 392,
})

export const LIST_ITEM_MARGIN = Metrics.baseMargin * 0.75

export const StyledHeaderContainer = styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: ${({ theme }) => theme.metrics.baseMargin}px;
  margin-left: ${({ theme }) => theme.metrics.baseMargin * 1.5}px;
  margin-right: ${({ theme }) => theme.metrics.baseMargin * 1.5}px;
`

export default StyleSheet.create({
  copy: {
    ...Fonts.style.bodySmall,
    color: Colors.text,
  },
  altCopy: {
    textTransform: 'capitalize',
    paddingLeft: Metrics.baseMargin * 0.6,
  },
  sectionTitle: {
    flex: 1,
  },
  scrollIcon: {
    paddingTop: Metrics.baseMargin * 0.75,
    paddingBottom: Metrics.baseMargin * 0.75,
  },
  scrollIconRight: {
    paddingLeft: Metrics.baseMargin / 2,
  },
  scrollIconLeft: {
    paddingRight: Metrics.baseMargin / 2,
  },
  list: {
    paddingHorizontal: Metrics.baseMargin * 0.75,
  },
})
