import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { PharmaciesState } from './types'
import { LimitedPharmacy, PharmaciesApiGetPharmaciesRequest } from '@dialogue/coredata'

const initialState: PharmaciesState = {
  data: [],
  loading: false,
  error: null,
}

const { actions, reducer } = createSlice({
  name: 'pharmacies',
  initialState,
  reducers: {
    getPharmacies: (state, action: PayloadAction<PharmaciesApiGetPharmaciesRequest>) => {
      state.loading = true
      // Reference the action to avoid ESLint warning
      void action
    },
    getPharmaciesSuccess: (state, action: PayloadAction<LimitedPharmacy[]>) => {
      state.loading = false
      state.error = null
      state.data = action.payload
    },
    getPharmaciesFailure: (state, action: PayloadAction<{ error: Error }>) => {
      state.loading = false
      state.error = action.payload.error
    },
  },
})

export { actions as pharmaciesActions, initialState }

export default reducer
