import ControllerInput from 'APP/Components/ControllerFields/ControllerInput'
import I18n from 'APP/Services/i18n'
import React, { useMemo } from 'react'
import styled from 'styled-components/native'
import { PromptTextItemProps } from 'APP/Components/Intake/IntakeMultiSubPrompt/types'
import {
  Control,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
} from 'react-hook-form/dist/types/form'
import { FieldValues } from 'react-hook-form'
import { isValidPhoneNumber } from 'APP/Lib/PhoneNumberHelpers'
import Regex from 'APP/Lib/Regex'
import { KeyboardTypeOptions } from 'react-native'
import { validateIsValidDate } from 'APP/Lib/DateHelpers'
import ControllerDatePicker from 'APP/Components/ControllerFields/ControllerDatePicker'
import { useIntakeContext } from 'APP/Components/Intake/Context/IntakeProvider'

const Container = styled.View`
  padding: 0 ${({ theme }) => theme.metrics.baseMargin}px;
`

interface PromptTextControllersProps {
  name: string
  item: PromptTextItemProps
  control: Control<FieldValues, unknown>
  trigger: UseFormTrigger<FieldValues>
  setValue: UseFormSetValue<FieldValues>
  clearErrors: UseFormClearErrors<FieldValues>
  getValues: UseFormGetValues<FieldValues>
  isFocused: boolean
  onFocus: (value: string | null) => void
}

export const PromptTextControllers = ({
  name,
  item,
  control,
  trigger,
  setValue,
  getValues,
  clearErrors,
  isFocused,
  onFocus,
}: PromptTextControllersProps) => {
  const { scrollViewRef } = useIntakeContext()

  const { required, showRequiredAsterisk, text_type, title, placeholder_text } = item
  const commonProps = {
    name: `${name}`,
    testID: `${name}`,
    control,
    label: title,
    placeholder: placeholder_text,
    showRequiredAsterisk,
    isFocused: isFocused,
    clearErrors,
    onFocus: () => {
      onFocus(`${name}`)
    },
  }

  const Controller = useMemo(() => {
    switch (text_type) {
      case 'phone_number':
        return (
          <ControllerInput
            {...commonProps}
            rules={{
              required: required && (I18n.t('Form.error.invalidNumber') as string),
              validate: (value) => {
                return (
                  isValidPhoneNumber(value.toString()) ||
                  (I18n.t('Form.error.invalidNumber') as string)
                )
              },
            }}
            keyboardType={'phone-pad' as KeyboardTypeOptions}
          />
        )

      case 'email':
        return (
          <ControllerInput
            {...commonProps}
            rules={{
              required: required && (I18n.t('Form.error.invalidEmail') as string),
              validate: (value) => {
                return (
                  Regex.email.test(value.toString()) ||
                  (I18n.t('Form.error.invalidEmail') as string)
                )
              },
            }}
            keyboardType={'email-address' as KeyboardTypeOptions}
          />
        )

      case 'date':
        return (
          <ControllerDatePicker
            scrollViewRef={scrollViewRef}
            {...commonProps}
            rules={{
              required: required && (I18n.t('Form.error.invalidDate') as string),
              validate: (value) => {
                return (
                  validateIsValidDate(value.toString()) ||
                  (I18n.t('Form.error.invalidDate') as string)
                )
              },
            }}
            value={getValues(`${name}`) ?? undefined}
            onSelect={() => {
              onFocus(null)
              trigger(`${name}`)
            }}
            setValue={(name: string, value: string) => {
              setValue(name, value)
            }}
          />
        )

      case 'plain_text':
      default:
        return (
          <ControllerInput
            {...commonProps}
            rules={{
              required: required && (I18n.t('Form.error.required') as string),
            }}
            keyboardType={'default' as KeyboardTypeOptions}
          />
        )
    }
  }, [text_type, required, isFocused])

  return <Container>{Controller}</Container>
}
