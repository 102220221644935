import { View } from 'react-native'
import StyledSpinner from 'APP/Converse/Spinner'
import Typography from 'APP/Converse/Typography'
import styled from 'styled-components/native'
import Icon from 'APP/Converse/Icon'

export enum StepStatus {
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

interface ChecklistItemProps {
  status: StepStatus
  title: string
}

const StyledContainer = styled(View)`
  flex-direction: row;
  align-items: flex-start;
  gap: ${({ theme }) => theme.metrics.baseMargin / 2}px;
`

const StyledTypography = styled(Typography)`
  flex: 1
`

const ChecklistItem = ({ status, title }: ChecklistItemProps) => {
  return (
    <StyledContainer>
      {status === StepStatus.LOADING && <StyledSpinner color="accent" />}
      {status === StepStatus.SUCCESS && <Icon src="check" variant="material" color="success" />}
      {status === StepStatus.ERROR && <Icon src="close" variant="material" color="error" />}
      <StyledTypography variant="bodySmallBold">{title}</StyledTypography>
    </StyledContainer>
  )
}

export default ChecklistItem
