import { Colors } from 'APP/Themes'

export const RANGES = {
  LOW: 'LOW',
  MID: 'MID',
  HIGH: 'HIGH',
}
export const RANGE_COLORS = {
  [RANGES.LOW]: Colors.rejectButtonBg,
  [RANGES.MID]: '#EABB13',
  [RANGES.HIGH]: Colors.acceptButtonBg,
}
export const getScoreRange = (score) => {
  switch (score) {
    case 0:
    case 1:
      return RANGES.LOW
    case 2:
    case 3:
      return RANGES.MID
    default:
      return RANGES.HIGH
  }
}
