import { StyleSheet } from 'react-native'
import { Colors, Fonts, Metrics } from 'APP/Themes'

export const MarkdownStyles = {
  p: {
    marginBottom: 0,
    ...Fonts.style.body,
    lineHeight: Metrics.baseMargin * 1.25,
  },
}

export default StyleSheet.create({
  content: {
    paddingHorizontal: Metrics.baseMargin * 1.375,
    paddingBottom: Metrics.bottomSpace || Metrics.baseMargin,
  },
  targetLabel: {
    alignItems: 'center',
    paddingTop: Metrics.baseMargin * 2,
    paddingBottom: Metrics.baseMargin,
  },
  h1: {
    ...Fonts.style.h1,
    color: Colors.text,
  },
  bodyLarge: {
    ...Fonts.style.bodyLarge,
    color: Colors.text,
  },
  bodySmall: {
    ...Fonts.style.bodySmall,
    color: Colors.textSecondary,
  },
  body: {
    ...Fonts.style.body,
    color: Colors.textSecondary,
  },
  slider: {
    paddingBottom: Metrics.baseMargin,
    display: 'flex',
    alignItems: 'center',
  },
  sliderUnselectedBar: { backgroundColor: Colors.buttonLinen },
  sliderValues: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
  },
  banner: {
    padding: Metrics.baseMargin * 1.3,
    borderRadius: 16,
    backgroundColor: Colors.bannerBg,
    flexDirection: 'row',
  },
  markdown: {
    paddingRight: Metrics.baseMargin * 1.3,
    paddingLeft: Metrics.baseMargin * 0.6,
    maxWidth: '100%',
  },
})
