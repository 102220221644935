import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Animated from 'react-native-reanimated'
import Analytics from 'APP/Services/Analytics'
import I18n from 'APP/Services/i18n'
import sprigClient from 'APP/Services/Sprig'
import AnimationComponent from 'APP/Components/Animation'
import { PrimaryButton } from 'APP/Components/Buttons'
import ActiveMinutesActions from 'APP/Redux/ActiveMinutesRedux'
import useHideAnimation from 'APP/Hooks/useHideAnimation'

const ANALYTICS = {
  JOIN_CHALLENGE: 'WELLNESS_CENTER_JOINED_CHALLENGE',
}

const Animation = ({ children }) => (
  <AnimationComponent text="🔥" numberOfElems={25} testID="confetti">
    <AnimationComponent text="💯" numberOfElems={20}>
      {children}
    </AnimationComponent>
  </AnimationComponent>
)

const JoinButton = (props) => {
  const hasLeftChallenge = props.hasLeftChallenge
  const dispatch = useDispatch()
  const [showConfetti, setShowConfetti] = useState(false)

  const { challenge } = useSelector((state) => state.activeMinutes.selectedChallenge)
  const { loading, retry, success } = useSelector((state) => state.activeMinutes.joinChallenge)

  const { animatedStyles, triggerHideAnimation, triggerAppearAnimation, onLayout } =
    useHideAnimation({
      duration: 500,
      initiallyVisible: !challenge.joined_at,
    })

  useEffect(() => {
    if (success && challenge.joined_at) {
      setShowConfetti(true)
      setTimeout(() => {
        triggerHideAnimation()
      }, 1000)
      dispatch(ActiveMinutesActions.resetJoinChallenge())
    }

    if (!challenge.joined_at && hasLeftChallenge) {
      setShowConfetti(false)
      setTimeout(() => {
        triggerAppearAnimation()
      }, 500)
      dispatch(ActiveMinutesActions.resetLeaveChallenge())
    }
  }, [success, challenge.joined_at])

  const title = useMemo(() => {
    if (retry) {
      return I18n.t('WellnessCenter.challenges.retryJoin')
    }

    if (showConfetti) {
      return I18n.t('WellnessCenter.challenges.successJoin')
    }

    return I18n.t('WellnessCenter.challenges.join')
  }, [retry, showConfetti, challenge.joined_at])

  const joinChallenge = () => {
    Analytics.trackEvent('button_click', {
      button_value: 'Join challenge',
      trigger: `Join challenge id: ${challenge.id}`,
    })
    sprigClient.track(ANALYTICS.JOIN_CHALLENGE)

    dispatch(ActiveMinutesActions.joinChallenge(challenge.id))
  }

  const AnimationContainer = showConfetti ? Animation : React.Fragment

  return (
    <AnimationContainer>
      <Animated.View style={animatedStyles} onLayout={onLayout} testID="animatedView">
        <PrimaryButton
          {...props}
          title={title}
          showActivityIndicator={loading}
          disabled={loading}
          onPress={joinChallenge}
        />
      </Animated.View>
    </AnimationContainer>
  )
}

export default JoinButton
