import React, { useMemo } from 'react'
import { Platform } from 'react-native'
import { Appearance, ColorSchemeName } from 'react-native-appearance'
import { ThemeProvider, DefaultTheme } from 'styled-components/native'
import Config from 'APP/Config'
import useScreenView from 'APP/Hooks/useScreenView'
import * as defaultThemes from './defaultThemes'

// TODO: Move this somewhere better
type AtLeastOne<T> = { [K in keyof T]-?: Pick<T, K> & Partial<Omit<T, K>> }[keyof T]

const themes = defaultThemes as Record<string, DefaultTheme>

export function getThemeName(colorScheme: ColorSchemeName, brandId: string, brandVersion?: string) {
  const themeSuffix = colorScheme === 'dark' ? 'Dark' : 'Light'
  const baseTheme = `${brandId}${themeSuffix}`

  if (brandVersion) {
    const versionTheme = `${baseTheme}_${brandVersion}`

    if (themes[versionTheme]) return versionTheme

    console.warn(
      `Versioned theme: ${versionTheme} expected but not found. Defaulting to ${baseTheme}`
    )
  }

  return baseTheme
}

export function getEnhancedTheme(
  appTheme: DefaultTheme,
  isBigScreen: boolean,
  isSmallScreen: boolean
): DefaultTheme {
  return {
    ...appTheme,
    isBigScreen,
    isSmallScreen,
  }
}

export function getAppTheme({
  storybookTheme,
  defaultTheme,
}: AtLeastOne<{
  storybookTheme?: DefaultTheme
  defaultTheme?: DefaultTheme
}>): DefaultTheme {
  const theme = storybookTheme ? { ...storybookTheme } : { ...defaultTheme }

  return theme as DefaultTheme
}

export const AppThemeProvider = ({
  theme: storybookTheme,
  children,
}: {
  theme: DefaultTheme
  children: React.ReactNode
}) => {
  const colorScheme = Platform.OS === 'web' ? 'light' : Appearance.getColorScheme()

  const { isBigScreen, isSmallScreen } = useScreenView()

  const themeName = useMemo(
    () => getThemeName(colorScheme, Config.BRAND_ID as string, Config.BRAND_VERSION as string),
    [colorScheme]
  )

  const appTheme = useMemo(
    () => getAppTheme({ storybookTheme, defaultTheme: themes[themeName] }),
    [storybookTheme, themeName]
  )

  const enhancedTheme = useMemo(
    () => getEnhancedTheme(appTheme, isBigScreen, isSmallScreen),
    [appTheme, isBigScreen, isSmallScreen]
  )

  return <ThemeProvider theme={enhancedTheme}>{children}</ThemeProvider>
}

export default AppThemeProvider
