import React, { useState, useEffect } from 'react'
import { FlatList, Linking, Image, Text, View } from 'react-native'
import { connect } from 'react-redux'
import { useNavigation } from '@react-navigation/native'

import PatientActions from 'APP/Redux/PatientRedux'
import Alert from 'APP/Converse/Alert'
import { PrimaryButton, TertiaryButton } from 'APP/Components/Buttons'
import ChallengedInvitationFooter from 'APP/Components/ChallengedInvitationFooter'

import I18n from 'APP/Services/i18n'
import Config from 'APP/Config'
import UrlBuilder from 'APP/Services/UrlBuilder'
import { routeNameToAnalyticsName } from 'APP/Nav'
import * as Sentry from '@sentry/react-native'

import { Images, Colors } from 'APP/Themes'
import { getImageAspectRatio } from 'APP/Lib/StylingHelpers'
import Styles from './style'
import { ResponsiveScrollView } from 'APP/Converse/Layout'
import TextInput from 'APP/Converse/Inputs/TextInput'
import { logDdError } from 'APP/Lib/Datadog'

const ChallengedEnrolmentUniqueIdScreen = ({
  analyticsName,
  busy,
  challengedInvitation,
  dispatchChallengeInvitation,
  challengeInvitationError,
}) => {
  const navigation = useNavigation()
  const fields = challengedInvitation.challengeForm?.fields?.map?.((field) => {
    const [state, setState] = useState({
      value: '',
      error: undefined,
    })
    const validate = () => {
      const { value } = state
      if (!value?.trim()) {
        setState({ ...state, error: I18n.serverString(field.requiredError) })
        return false
      } else {
        setState({ ...state, error: undefined })
        return true
      }
    }

    return {
      data: field,
      state,
      setState,
      validate,
    }
  })

  useEffect(() => {
    if (challengeInvitationError) {
      fields.forEach((field) => {
        field.setState({ ...field.state, error: I18n.serverString(field.data.invalidError) })
      })
    }
  }, [challengeInvitationError])

  const handleClaimAccess = () => {
    const allFieldsAreValid = fields.every((field) => field.validate())
    if (allFieldsAreValid) {
      dispatchChallengeInvitation(
        challengedInvitation,
        fields.map((field) => field.state.value)
      )
    }
  }

  const handleSupport = () => {
    if (Config.CUSTOM_SUPPORT_PAGE) {
      navigation.navigate('contactUs')
    } else {
      const url = UrlBuilder.supportEmailUrl()
      if (Linking.canOpenURL(url)) {
        Linking.openURL(url).catch((reason) => {
          const msg = `ChallengedEnrolmentUniqueIdScreen. Error opening contact us link. message: ${reason.message}, url: ${url}`
          Sentry.captureException(msg)
          logDdError(msg, 'ChallengedEnrolmentUniqueIdScreen.handleSupport')
          Alert.alert(I18n.t('CannotOpenEmail.title'), I18n.t('CannotOpenEmail.body'), [
            { text: I18n.t('CannotOpenEmail.ok') },
          ])
        })
      }
    }
  }

  const getLocalizedTooltipConfig = (toolTipConfig) => {
    const localizedTooltipConfig = {}
    const localeKey = I18n.locale === 'fr' ? 'fr' : 'en'

    if (toolTipConfig?.title?.[localeKey]?.length > 0) {
      localizedTooltipConfig.text = toolTipConfig.title[localeKey]
    }

    if (toolTipConfig?.subtitle?.[localeKey]?.length > 0) {
      localizedTooltipConfig.subtitle = toolTipConfig.subtitle[localeKey]
    }

    if (toolTipConfig?.img?.[localeKey]?.length > 0) {
      localizedTooltipConfig.img = toolTipConfig.img[localeKey]
    }

    // Only return something iff the tooltip has elements
    if (Object.keys(localizedTooltipConfig).length > 0) {
      return { ...localizedTooltipConfig, iconColor: Colors.buttonPrimary }
    } else {
      return null
    }
  }

  const renderInputField = ({ item: { data, state, setState, validate } }) => {
    const tooltipConfig = getLocalizedTooltipConfig(data.infoTip)

    return (
      <TextInput
        value={state.value}
        error={state.error}
        placeholder={I18n.serverString(data.label)}
        infotip={I18n.serverString(data.hint)}
        tooltipConfig={tooltipConfig}
        onChangeText={(val) => setState({ ...state, value: val })}
        onFocus={() => setState({ ...state, error: undefined })}
        onBlur={() => validate()}
      />
    )
  }

  return (
    <View style={Styles.fullPrimaryContainer}>
      <ResponsiveScrollView>
        <View style={Styles.content}>
          <Image
            source={Images.logoBlue}
            style={[Styles.splashLogo, { aspectRatio: getImageAspectRatio(Images.logoBlue) }]}
          />
          <Text style={Styles.title}>
            {I18n.serverString(challengedInvitation.challengeForm.title)}
          </Text>
          <Text style={Styles.subtitle}>
            {I18n.serverString(challengedInvitation.challengeForm.body)}
          </Text>

          <View style={Styles.listContainer}>
            <FlatList
              data={fields}
              keyExtractor={(_, i) => `${challengedInvitation.id}-${i}`}
              renderItem={renderInputField}
            />
          </View>

          <PrimaryButton
            title={I18n.t('ChallengedEnrolmentUniqueIdScreen.claimAccessButton')}
            analyticsName={`${analyticsName}.claimAccessButton`}
            showActivityIndicator={busy}
            activityIndicatorColor={Colors.buttonPrimaryText}
            onPress={handleClaimAccess}
          />
          <TertiaryButton
            title={I18n.t('Common.contactSupport')}
            analyticsName={'Common.contactSupport'}
            onPress={handleSupport}
          />
          <ChallengedInvitationFooter analyticsName={analyticsName} />
        </View>
      </ResponsiveScrollView>
    </View>
  )
}

const mapStateToProps = (state, props) => {
  const { patient } = state
  return {
    ...props.route?.params,
    analyticsName: routeNameToAnalyticsName(props.route?.name),
    busy: patient?.challengeInvitationRunning ?? false,
    challengeInvitationError: patient?.challengeInvitationError ?? null,
  }
}
const mapDispatchToProps = (dispatch) => ({
  dispatchChallengeInvitation: (invitationId, uniqueIdentifier) =>
    dispatch(PatientActions.challengeInvitationRequest(invitationId, uniqueIdentifier)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ChallengedEnrolmentUniqueIdScreen)
