import React from 'react'
import { View } from 'react-native'

// Styles
import { Metrics, Colors } from 'APP/Themes'

const size = Metrics.baseMargin / 2

const badgeStyle = {
  backgroundColor: Colors.errorSnackBarBg,
  height: size,
  width: size,
  minWidth: size,
  maxWidth: size,
  borderRadius: size / 2,
}

const menuBadgeStyle = {
  ...badgeStyle,
  top: 0,
  right: 0,
}

export const tabBarBadgeStyle = {
  ...badgeStyle,
  top: 5,
  right: -13,
}

export const Badge = ({ style, ...rest }) => <View style={[menuBadgeStyle, style]} {...rest} />
