import moment from 'moment'
import React, { useMemo, useRef, useLayoutEffect } from 'react'
import { FlatList, Text, View } from 'react-native'
import style, { ROW_HEIGHT } from './style'
import { getWeeks } from './utils'
import I18n from 'APP/Services/i18n'

const Week = ({ days, isFirst, isLast, today, activeDates }) => {
  let activeDays = 0
  const spacing = [isFirst && style.topSpace, isLast && style.bottomSpace]
  return (
    <View style={[style.row, ...spacing]}>
      <View style={[style.week]}>
        {days.map((day) => {
          const isHidden =
            (isFirst && !day.isSame(days[6], 'M')) || (isLast && !day.isSame(days[0], 'M'))
          const isToday = today.isSame(day, 'day')
          const isInactive = today.isBefore(day, 'day')
          const isActive = !isInactive && activeDates[day.format(I18n.t('DateFormat'))]
          const isStartOfTheMonth = day.isSame(day.clone().startOf('month'), 'D')
          if (isActive) activeDays++
          return (
            <View key={day.toString()} style={[style.dayContainer, isHidden && style.dayHidden]}>
              {isStartOfTheMonth && (
                <Text style={style.monthText} testID={`month-${day.format('MM-YYYY')}`}>
                  {day.format('MMM')}
                </Text>
              )}
              <View style={[style.day, isActive && style.dayActive]}>
                <Text
                  style={[
                    style.dayText,
                    isActive && style.dayTextActive,
                    isInactive && style.dayTextInactive,
                  ]}
                >
                  {day.format('D')}
                </Text>
              </View>
              {isToday && <View style={style.todayMark} testID={'todayMark'} />}
            </View>
          )
        })}
      </View>
      <View style={style.streak}>
        {activeDays > 2 && <Text>🔥</Text>}
        <Text style={style.streakText}>{activeDays > 2 ? `3+` : activeDays}</Text>
      </View>
    </View>
  )
}

export const CalendarList = ({ startDay, endDay, activeDates = {}, style: styleProp }) => {
  const weeks = useMemo(() => getWeeks(startDay, endDay), [startDay, endDay])
  const today = moment()
  const flatListRef = useRef()

  useLayoutEffect(() => {
    if (flatListRef.current && weeks && weeks.length > 0) {
      flatListRef.current.scrollToEnd()
    }
  }, [])

  return (
    <FlatList
      ref={flatListRef}
      data={weeks}
      keyExtractor={(days) => days[0].toString()}
      style={[style.container, styleProp]}
      getItemLayout={(_, index) => ({ length: ROW_HEIGHT, offset: ROW_HEIGHT * index, index })}
      renderItem={({ item, index }) => (
        <Week
          isFirst={index === 0}
          isLast={index + 1 === weeks.length}
          days={item}
          today={today}
          activeDates={activeDates}
        />
      )}
    />
  )
}
