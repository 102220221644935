import { Dimensions, StyleSheet } from 'react-native'
import { Colors, Fonts, Metrics } from '../../Themes'
import { isWeb } from 'APP/Helpers/checkPlatform'

export const DAY_ITEM_SIZE = 33
export const DAY_ITEM_BORDER_WIDTH = 3
export const DAY_ITEM_OFFSET = DAY_ITEM_SIZE + DAY_ITEM_BORDER_WIDTH

export const WEEK_ITEM_SIZE = Dimensions.get('window').width - DAY_ITEM_SIZE

export default StyleSheet.create({
  container: {
    paddingLeft: Metrics.baseMargin,
    paddingRight: Metrics.baseMargin / 2,
  },
  weekItemContainer: {
    backgroundColor: Colors.appBg,
    paddingBottom: Metrics.baseMargin * 1.25,
    paddingHorizontal: Metrics.baseMargin * 0.375,
    display: 'flex',
    flexDirection: 'row',
    width: isWeb() ? 'auto' : WEEK_ITEM_SIZE,
    justifyContent: 'space-between',
  },
  progressBarCircle: {
    fontSize: Fonts.size.regular,
    opacity: 1,
  },
  progressBarCircleAfter: {
    color: Colors.buttonSecondaryDisabled,
    opacity: 0.5,
  },
  dot: {
    marginTop: 6,
    width: 4,
    height: 4,
    borderRadius: 2,
    backgroundColor: Colors.text,
    alignSelf: 'center',
  },
  currentDayText: {
    ...Fonts.style.button,
  },
  prevDayText: {
    ...Fonts.style.subheader,
  },
})
