import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Image, View, Text, ActivityIndicator } from 'react-native'

import { Images } from 'APP/Themes'
import I18n from 'APP/Services/i18n'
import Toast from 'APP/Components/Toast'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import { ResponsiveScrollView } from 'APP/Converse/Layout'
import WellnessCenterActions from 'APP/Redux/WellnessCenterRedux'

import { Colors } from 'APP/Themes'
import Styles from './style'
import Button from 'APP/Converse/Button'

const TeamList = ({ goBack }) => {
  const dispatch = useDispatch()

  const { joining, retry, challenge, participants } = useSelector(
    (state) => state.wellnessCenter.selectedChallenge
  )

  // Prevents list from changing when user joins because API returns participants in order of most to least distance
  const sortedParticipants = [...participants].sort((a, b) => a?.content?.title > b?.content?.title)

  const [loadingItemId, setLoadingItemId] = useState(null)

  useEffect(() => {
    if (loadingItemId && !joining && !retry) {
      goBack()
    }

    if (loadingItemId && retry) {
      setLoadingItemId(false)
    }
  }, [loadingItemId, joining, retry])

  const joinTeam = (id) => {
    if (!loadingItemId) {
      setLoadingItemId(id)
      dispatch(WellnessCenterActions.joinTeamChallenge(challenge.id, id))
    }
  }

  return (
    <ResponsiveScrollView style={Styles.container}>
      <View style={Styles.nav}>
        <Button
          variant="tertiary"
          widthVariant="circle"
          icon={Images.backIcon}
          iconVariant="image"
          size={40}
          onPress={goBack}
          analyticsName="Back"
          testID="backBtn"
          accessibilityLabel={I18n.t('AccessibilityIcons.back')}
        />
      </View>
      <Text style={Styles.title}>{I18n.t('WellnessCenter.challenges.joinTeam')}</Text>
      <ResponsiveScrollView
        contentContainerInnerStyle={Styles.list}
        showsVerticalScrollIndicator={false}
      >
        {sortedParticipants?.map((item) => (
          <DialogueTouchableOpacity
            key={item.id}
            onPress={() => joinTeam(item.id)}
            style={Styles.tileContainer}
            testID="team"
          >
            <View style={Styles.tile}>
              <Image
                style={Styles.image}
                source={{ uri: item?.content?.image }}
                defaultSource={Images.contentLoading}
                resizeMode="cover"
              />
              <Text style={Styles.teamName}>
                {item?.content?.title} ({item.participants_count})
              </Text>
              {(loadingItemId === item.id || false) && (
                <View style={Styles.loading}>
                  <ActivityIndicator
                    size="large"
                    color={Colors.text}
                    testID={`loading-team-${item.id}`}
                  />
                </View>
              )}
            </View>
          </DialogueTouchableOpacity>
        ))}
      </ResponsiveScrollView>
      <Toast />
    </ResponsiveScrollView>
  )
}

export default TeamList
