import Config from 'APP/Config'
import UrlBuilder from 'APP/Services/UrlBuilder'
import { Linking } from 'react-native'
import Alert from 'APP/Converse/Alert'
import I18n from 'APP/Services/i18n'
import { navigationRef as Nav } from 'APP/Nav'
import * as Sentry from '@sentry/react-native'
import { logDdError } from 'APP/Lib/Datadog'

export const handlePressContactSupport = () => {
  const customContactUs = Config.CUSTOM_SUPPORT_PAGE
  if (customContactUs) {
    Nav.navigate('contactUs')
  } else {
    const url = UrlBuilder.supportEmailUrl()
    if (Linking.canOpenURL(url)) {
      Linking.openURL(url).catch((reason) => {
        const msg = `Error opening contact us link. message: ${reason.message}, url: ${url}`
        Sentry.captureException(msg)
        logDdError(msg, 'handlePressContactSupport')
        Alert.alert(I18n.t('CannotOpenEmail.title'), I18n.t('CannotOpenEmail.body'), [
          { text: I18n.t('CannotOpenEmail.ok') },
        ])
      })
    }
  }
}
