import React, { memo } from 'react'
import { Animated } from 'react-native'
import styled from 'styled-components/native'

const DOT_SIZE = 10

const TypingContainer = styled.View`
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

const TypingDot = styled(Animated.View)`
  width: ${DOT_SIZE}px;
  height: ${DOT_SIZE}px;
  background-color: ${({ theme }) => theme.colors.dim};
  border-radius: ${DOT_SIZE}px;
`

const Typing = () => {
  const animationValues = [
    { opacity: new Animated.Value(0), scale: new Animated.Value(1) },
    { opacity: new Animated.Value(0), scale: new Animated.Value(1) },
    { opacity: new Animated.Value(0), scale: new Animated.Value(1) },
  ]

  const animateDots = () => {
    Animated.stagger(
      200,
      animationValues.map((values) => {
        return Animated.sequence([
          Animated.parallel([
            Animated.timing(values.opacity, {
              toValue: 1,
              useNativeDriver: true,
            }),
            Animated.timing(values.scale, {
              toValue: 1,
              useNativeDriver: true,
            }),
          ]),
          Animated.parallel([
            Animated.timing(values.opacity, {
              toValue: 0.3,
              useNativeDriver: true,
            }),
            Animated.timing(values.scale, {
              toValue: 0.7,
              useNativeDriver: true,
            }),
          ]),
        ])
      })
    ).start(animateDots)
  }
  React.useEffect(() => {
    animateDots()
  }, [])

  return (
    <TypingContainer>
      {animationValues.map((value, index) => (
        <TypingDot
          key={index}
          style={{
            opacity: value.opacity,
            transform: [{ scaleX: value.scale }, { scaleY: value.scale }],
          }}
        />
      ))}
    </TypingContainer>
  )
}

export default memo(Typing)
