import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Metrics, Fonts } from 'APP/Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  scrollContainer: {
    flex: 1,
    backgroundColor: Colors.appBg,
  },
  container: {
    flex: 1,
    paddingHorizontal: Metrics.baseMargin * 2,
    paddingTop: Metrics.baseMargin * 2,
    paddingBottom: Metrics.baseMargin,
  },
  navbar: {
    marginTop: 45,
    marginHorizontal: Metrics.baseMargin,
  },
  logo: {
    height: 45,
    marginTop: Metrics.baseMargin * 6,
    marginBottom: Metrics.baseMargin * 2,
    resizeMode: 'contain',
  },
  title: {
    ...Fonts.style.h1,
    color: Colors.text,
    alignItems: 'baseline',
    textAlign: 'left',
    marginBottom: Metrics.baseMargin,
  },
  subtitle: {
    ...Fonts.style.body,
    lineHeight: 21,
    color: Colors.text,
    textAlign: 'left',
    marginBottom: Metrics.baseMargin,
  },
  button: {
    marginHorizontal: 0,
    marginBottom: Metrics.baseMargin,
  },
  hintContainer: {
    marginTop: Metrics.baseMargin,
    justifyContent: 'center',
    alignItems: 'center',
  },
  hint: {
    ...Fonts.style.bodySmall,
    color: Colors.text,
    paddingBottom: Metrics.baseMargin / 2,
  },
  link: {
    ...Fonts.style.bodySmallBold,
    color: Colors.link,
    textDecorationLine: 'underline',
  },
})
