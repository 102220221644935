import React from 'react'
import { Modal as RNModal, ActivityIndicator, Text, View, KeyboardAvoidingView } from 'react-native'

// Styling
import Styles from './style'
import { Metrics } from 'APP/Themes'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'

export default class Modal extends React.Component {
  handleCloseModal = () => this.props.handleCloseModal && this.props.handleCloseModal()
  handlePrimaryAction = () => this.props.handlePrimaryAction && this.props.handlePrimaryAction()
  handleSecondaryAction = () =>
    this.props.handleSecondaryAction && this.props.handleSecondaryAction()

  renderTitle() {
    const { title } = this.props
    if (!title) return

    return <Text style={[Styles.title, Styles.textSpacing]}>{title}</Text>
  }

  renderSubtitle() {
    const { subtitle, children } = this.props
    if (!subtitle) return

    return <Text style={[Styles.subtitle, !!children && Styles.textSpacing]}>{subtitle}</Text>
  }

  renderPrimaryAction() {
    const {
      primaryActionText,
      primaryActionRunning,
      primaryActionDisabled,
      primaryActionColor = Styles.primaryActionText.color,
    } = this.props

    if (!primaryActionText) return

    return (
      <DialogueTouchableOpacity
        style={Styles.actionButton}
        disabled={primaryActionDisabled}
        onPress={this.handlePrimaryAction}
        testID={'BASE_MODAL_PRIMARY_BTN'}
        analyticsName={primaryActionText}
      >
        {primaryActionRunning ? (
          <ActivityIndicator color={primaryActionColor} />
        ) : (
          <Text style={{ ...Styles.primaryActionText, color: primaryActionColor }}>
            {primaryActionText}
          </Text>
        )}
      </DialogueTouchableOpacity>
    )
  }

  renderSecondaryAction() {
    const {
      secondaryActionText,
      secondaryActionRunning,
      secondaryActionDisabled,
      secondaryActionColor = Styles.secondaryActionText.color,
    } = this.props

    if (!secondaryActionText) return

    return (
      <DialogueTouchableOpacity
        style={Styles.actionButton}
        disabled={secondaryActionDisabled}
        testID={'BASE_MODAL_SECONDARY_BTN'}
        onPress={this.handleSecondaryAction}
        analyticsName={secondaryActionText}
      >
        {secondaryActionRunning ? (
          <ActivityIndicator color={secondaryActionColor} />
        ) : (
          <Text style={{ ...Styles.secondaryActionText, color: secondaryActionColor }}>
            {secondaryActionText}
          </Text>
        )}
      </DialogueTouchableOpacity>
    )
  }

  render() {
    const {
      transparent,
      animationType = 'fade',
      visible,
      avoidKeyboard = false,
      children,
    } = this.props
    return (
      <RNModal
        transparent={transparent}
        animationType={animationType}
        visible={visible}
        onRequestClose={this.handleCloseModal}
      >
        <View style={Styles.container}>
          <DialogueTouchableOpacity
            activeOpacity={1.0}
            style={Styles.background}
            onPress={this.handleCloseModal}
            analyticsName="Close bg"
          />
          <KeyboardAvoidingView
            behavior="position"
            enabled={avoidKeyboard}
            keyboardVerticalOffset={Metrics.baseMargin}
            style={Styles.contentContainer}
          >
            <View style={Styles.content}>
              {this.renderTitle()}
              {this.renderSubtitle()}
              {children}
              <View style={Styles.actionsContainer}>
                {this.renderSecondaryAction()}
                {this.renderPrimaryAction()}
              </View>
            </View>
          </KeyboardAvoidingView>
        </View>
      </RNModal>
    )
  }
}
