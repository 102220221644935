import { v4 as uuid } from 'uuid'
import { Platform } from 'react-native'
import { all, select, call, put, spawn, delay } from 'redux-saga/effects'
import DeviceInfo from 'react-native-device-info'
import compareVersions from 'compare-versions'
import { getTimeSinceStartup } from 'react-native-startup-time'
import * as Sentry from '@sentry/react-native'
import Analytics, { LAUNCH_CATEGORY } from 'APP/Services/Analytics'
import Alert from 'APP/Converse/Alert'
import { navigationRef as Nav } from 'APP/Nav'

// Actions
import LoginActions, { LoginTypes, TOKEN_VERSION } from 'APP/Redux/LoginRedux'
import PatientActions from 'APP/Redux/PatientRedux'
import PatientHistoryActions from 'APP/Redux/PatientHistoryRedux'
import AppSessionActions from 'APP/Redux/AppSessionRedux'
import StartupActions from 'APP/Redux/StartupRedux'
import LoggerActions from 'APP/Redux/LoggerRedux'
import DeeplinkActions from 'APP/Redux/DeeplinkRedux'
import HabitActions from 'APP/Redux/HabitsRedux'
import { familyActions } from 'APP/Store/Family'
import { checkAppleHealthDisconnectedStatus } from 'APP/Sagas/TerraSagas'
import { isIOS } from 'APP/Helpers/checkPlatform'

// Sagas
import {
  logout,
  checkAppLockSupport,
  toggleMfa,
  checkPublicComputer,
  login,
  renewAuth,
  retrieveLoginData,
} from './AuthSagas'
import {
  startupNavigation,
  updateLocation,
  requestMessagingCredentials,
  changeEmailAuthorized,
  fetchUserInfo,
} from './PatientSagas'
import { fetchAppointments } from './PatientHistorySagas'
import { featuresMonitor } from './FeaturesSaga'
import { watchDeeplinkRequests } from './DeeplinkSaga'
import { getActiveMinutes } from './ActiveMinutesSagas'

import I18n from 'APP/Services/i18n'
import { waitForLDInitialization, ldClient } from 'APP/Services/LaunchDarkly'
import PushIt from 'APP/Services/PushIt'
import Config from 'APP/Config'
import { APP_LOCK_STATE } from 'APP/Services/AppLock'
import { checkPostNotificationsState } from 'APP/NativeModules/PermissionsService'
import { createChannels } from 'APP/Services/PushNotificationServices'
import authClient from 'APP/Services/AuthClient'
import sprigClient from 'APP/Services/Sprig'

import { hasLoginHash } from 'APP/Lib/Utilities'
import { isWeb } from 'APP/Helpers/checkPlatform'
import { logDdError } from 'APP/Lib/Datadog'

// Exported to make available for tests

export const loginState = (state) => (state && state.login) || {}
export const startupState = (state) => (state && state.app) || {}
export const patientState = (state) => (state && state.patient) || {}
export const patientProfileState = (state) =>
  (state && state.patient && state.patient.profile) || {}
export const appSessionState = (state) => (state && state.appSession) || {}
export const featuresState = (state) => state.features

// Config'
export const REGISTER_PUSH_TOKEN_RETRY_LIMIT = 5
export const LOCKED_TIME_LIMIT_IN_MILLIS = __DEV__ ? 10000 : 60000
// 1 min session expiry in dev, 3 hours in prod.
// Note that since we're comparing against token expiry we need a negative delta in dev (resolves to 1 min since token was issued)
export const FORCED_SESSION_INACTIVITY_TIME_MILLIS = __DEV__ ? -3540000 : 10800000

export function* enableAppLock() {
  const appLockState = yield call(checkAppLockSupport)
  if (appLockState === APP_LOCK_STATE.ENABLED_BLOCKED) {
    //Log out & clear app lock enabled state
    yield call(logout, { reason: 'App lock enabled blocked' })
  } else {
    if (Nav.getCurrentRoute()?.name !== 'videoCall') {
      const app = yield select(appSessionState)
      const backgroundTimestamp = app && app.backgroundTimestamp
      const now = yield call(getTimeSinceStartup)
      const diffInMillis = now - backgroundTimestamp
      yield put(
        LoggerActions.log(`App lock timeout? ${now} ${backgroundTimestamp} ${diffInMillis}`)
      )
      if (diffInMillis > LOCKED_TIME_LIMIT_IN_MILLIS) {
        switch (appLockState) {
          case APP_LOCK_STATE.ENABLED_BLOCKED:
            //Log out & clear app lock enabled state - shouldn't reach this anyhow
            yield call(logout, { reason: 'App lock enabled blocked' })

            break
          case APP_LOCK_STATE.ENABLED:
            //Trigger auth

            // TODO: Verify, should this actually be a replace?
            yield call(Nav.navigate, 'applock')
            break
          case APP_LOCK_STATE.DISABLED:
            //Do nothing
            break
        }
      }
    }
  }
}

let watchDeeplinkRequestsTask
// process STARTUP actions
export function* startup(action) {
  try {
    yield call(retrieveLoginData)
    const hash = window?.location?.hash
    // After saga send login request to auth0 it redirects with access token in hash
    // We capture it and process.
    if (isWeb() && hash && hasLoginHash(hash)) {
      const inAuth0Popup = window?.opener
      if (inAuth0Popup) {
        // When logging into Auth0 via popup the popup itself redirects back to the app
        // The popup should fall into this if, passing execution back to the parent window and closing itself
        authClient.popupCallback()
        return
      } else {
        yield call(login, { withHash: true })

        const {
          profile: { email },
          changeEmailToken: { new_email, token },
        } = yield select(patientState)
        // Capture cases:
        //  new_email - when unauthorised user was redirected at first time;
        //  email - when user successfully changed email and re-login with new email;
        if ((email || new_email) && token) {
          // Unauthorised user was redirect at first time before change email to get token
          // in this case the store contain changeEmailToken info,
          // and we don't need to run the rest of sturtup.
          yield call(changeEmailAuthorized, token, new_email)
          return
        }
      }
    }

    // Kill previous saga execution when LOGOUT action is put
    if (action && action.type === LoginTypes.LOGOUT) return
    yield spawn(Analytics.trackLoadingTime, { action: LAUNCH_CATEGORY.START })
    yield put(LoggerActions.log('Startup'))
    yield put(LoginActions.setIsLoggingIn(false))
    yield put(PatientActions.setChangeEmailToken({}))

    yield call(featuresMonitor)

    const { tokenVersion, accessToken } = yield select(loginState)
    const supported = yield call(appVersionIsSupported, DeviceInfo)
    if (!supported) {
      yield spawn(Analytics.trackLoadingTime, {
        action: LAUNCH_CATEGORY.EXIT,
        label: 'Not supported',
      })
      yield call(Nav.reset, { index: 0, routes: [{ name: 'unsupportedVersion' }] })
      return
    }

    // force logout users based on store
    if (TOKEN_VERSION !== tokenVersion) {
      yield put(LoginActions.logout('Forced logged out based on store token version'))
      return
    }

    if (!watchDeeplinkRequestsTask) {
      watchDeeplinkRequestsTask = yield spawn(watchDeeplinkRequests)
      Analytics.trackLoadingTime({ action: 'deeplink request watched' })
    }

    if (accessToken) {
      const wasLoggedOut = yield call(forcedSessionInactivity)
      if (wasLoggedOut) {
        Analytics.trackLoadingTime({
          action: LAUNCH_CATEGORY.EXIT,
          label: 'Forced session inactivity',
        })
        return
      }

      if (isWeb()) {
        // Check if the user needs to be logged out due to an expired public session
        yield call(checkPublicComputer)

        yield call(launchStartupSequence)
      } else {
        yield call(checkAppLock)
      }
    } else {
      //Reset app lock on explicit login
      yield put(StartupActions.setAppLockToggle(false))
      Analytics.trackLoadingTime({
        action: LAUNCH_CATEGORY.EXIT,
        label: 'Welcome redirection',
      })

      yield call(Nav.reset, { index: 0, routes: [{ name: 'welcome' }] })
    }
  } catch (err) {
    yield put(AppSessionActions.setSystemError(err, true))
    Analytics.trackLoadingTime({
      action: LAUNCH_CATEGORY.EXIT,
      label: 'System error',
    })
  }
}

export function* checkAppLock() {
  const appLockState = yield call(checkAppLockSupport)
  yield put(LoggerActions.log(`triggerAppLock => ${appLockState}`))
  switch (appLockState) {
    case APP_LOCK_STATE.ENABLED_BLOCKED:
      Analytics.trackLoadingTime({
        action: LAUNCH_CATEGORY.EXIT,
        label: 'App Lock blocked',
      })
      //Log out & clear app lock enabled state
      yield call(logout, { reason: 'App lock enabled blocked' })
      break
    case APP_LOCK_STATE.ENABLED:
      //Trigger auth
      Analytics.trackLoadingTime({ action: 'App lock auth triggered' })
      // TODO: Verify, should this actually be a replace?
      yield call(Nav.navigate, 'applock')
      break
    case APP_LOCK_STATE.DISABLED:
      yield call(launchStartupSequence)
      break
  }
}

export function* launchStartupSequence() {
  yield call(renewAuth)
  yield put(LoginActions.startRefreshAuthPoll())
  yield call(startupNavigation)
  Analytics.trackLoadingTime({
    action: LAUNCH_CATEGORY.EXIT,
    label: 'Startup navigation finished',
  })
  yield call(requestMessagingCredentials)
}

export function* appVersionIsSupported(deviceInfo) {
  if (Platform.OS === 'web') return true

  // Fetch the minimum supported versions
  const supportedVersionsResponse = yield call(
    [ldClient, ldClient.jsonVariation],
    `${Config.BRAND_ID}-supported-version`,
    null
  )
  if (supportedVersionsResponse) {
    const minVersion = supportedVersionsResponse.ios.min_version
    const nativeAppVersion = deviceInfo.getVersion()
    if (compareVersions(nativeAppVersion, minVersion) === -1) {
      return false
    }
  }
  return true
}

export function* gotoForeground() {
  const { accessToken, isLoggingIn } = yield select(loginState)
  if (!accessToken && !isLoggingIn) {
    try {
      yield all([
        put(PatientActions.patientProfileClear()),
        put(PatientHistoryActions.patientHistoryClear()),
        put(familyActions.familyClear()),
        call(uuid),
        put(DeeplinkActions.setReadyToProcess(false)),
        sprigClient.logout(),
        put(LoginActions.clearState()),
        put(LoginActions.stopRefreshAuthPoll()),
        put(HabitActions.cancelAllHabitLocalNotifications()),
      ])
    } catch (e) {
      // don't break root saga
    }
  }

  // Run the effects required for a partial startup for a logged in user
  if (accessToken && !isLoggingIn) {
    try {
      const wasLoggedOut = yield call(forcedSessionInactivity)
      if (wasLoggedOut) {
        return
      }
      yield call(waitForLDInitialization)
      yield call(renewAuth)
      yield call(fetchUserInfo, accessToken)
      let patient = yield select(patientState)
      const emailVerified = patient.userInfo?.email_verified ?? false
      if (!emailVerified) {
        // if the member's email is not verified, we skip the api fetches
        return
      }
      yield call(enableAppLock)
      yield put(PatientActions.patientProfileFetchRequest())
      yield spawn(updateLocation)
      yield put(PatientHistoryActions.mattermostStart())
      yield put(PatientHistoryActions.fetchChannelsRequest(false))
      const { pushToken } = yield select(startupState)
      // Proactively register device on launch
      if (pushToken) {
        yield call(registerPushToken, { pushToken })
      }
      yield put(LoginActions.startRefreshAuthPoll())
      yield spawn(fetchAppointments)
      yield spawn(getActiveMinutes)

      if (isIOS()) {
        yield spawn(checkAppleHealthDisconnectedStatus)
      }
    } catch {
      // If the checkRenewAuth fails and logs the user out, we need to catch and do nothing
      // to avoid breaking the root saga
    }
  }
}

export function* gotoBackground() {
  const { accessToken } = yield select(loginState)
  if (accessToken) {
    const timestamp = yield call(getTimeSinceStartup)
    yield put(LoggerActions.log(`About to set background timestamp ${timestamp}`))
    yield put(AppSessionActions.setAppBackgroundedTimestamp(timestamp))
    yield put(LoginActions.stopRefreshAuthPoll())
  }
}

export function* setIsConnected({ isConnected }) {
  if (isConnected) {
    yield put(PatientHistoryActions.mattermostStart())

    yield put(PatientHistoryActions.fetchChannelsRequest(false))
  }
}

export function* registerPushToken({ retryCount = 0, pushToken }) {
  const patientProfile = yield select(patientProfileState)
  const isLoggedIn = !!patientProfile.id
  if (!isLoggedIn) return

  yield put(LoggerActions.log('Registering device'))
  const login = yield select(loginState)
  const communicationLanguage = patientProfile.preferred_language || I18n.baseLocale
  const pushIt = PushIt.create(login)

  const res = yield call(pushIt.registerPushToken, pushToken, communicationLanguage)
  if (res.ok) {
    yield put(StartupActions.setPushTokenSuccess(pushToken))
    yield put(LoggerActions.log('Registered device successfully'))
  } else {
    const errorCode = res.problem || 'UNKNOWN_ERROR'
    const errMsg = `Failed to register push token ${pushToken}. Error: ${errorCode}`
    const shouldRetry = retryCount < REGISTER_PUSH_TOKEN_RETRY_LIMIT
    yield put(StartupActions.setPushTokenFailure(pushToken, errMsg, retryCount))
    yield put(LoggerActions.log('Failed to register device'))
    if (shouldRetry) {
      retryCount++
      const expoRetryDelay = Math.pow(2, retryCount) * 1000
      yield delay(expoRetryDelay)
      yield call(registerPushToken, { pushToken, retryCount })
    } else {
      logDdError(errMsg, errorCode)
      yield call(Sentry.captureException, errorCode)
    }
  }
}

export function* unregisterPushToken() {
  const patientProfile = yield select(patientProfileState)
  const login = yield select(loginState)

  const pushIt = PushIt.create(login, Config.PUSHIT_DOMAIN)

  const unregistrationResponse = yield call(pushIt.unregisterPushToken, patientProfile.id)
  if (unregistrationResponse.ok) {
    if (__DEV__) console.log('Successfully unregistered push token')
  } else {
    if (__DEV__) console.log('Failed to unregister push token')
  }
}

export function* toggleMandatoryMfa() {
  yield put(LoginActions.mandatoryMfaRequest())
  yield call(toggleMfa)
  const patient = yield select(patientState)
  const isMfaEnabled = patient?.userInfo?.user_metadata?.mfa_enabled ?? false

  if (isMfaEnabled) {
    yield put(StartupActions.mandatoryMfaToggled())
  } else {
    yield put(LoggerActions.log('Failed to enable MFA for user'))
  }
}

/**
 * Create push notification channels immediately on
 * - iOS
 * - Android < 13
 * - Android >= 13 and permission is granted
 */
export function* createNotificationChannels() {
  const android13OrNewer = Platform.OS === 'android' && Platform.Version >= 33
  const result = yield call(checkPostNotificationsState)

  if (!android13OrNewer || (android13OrNewer && result === 'granted')) {
    createChannels()
  }
}

export function* forcedSessionInactivity() {
  const { forcedSessionInactivityRequired } = yield select(patientProfileState)

  if (forcedSessionInactivityRequired) {
    const { tokenExpiration } = yield select(loginState)
    const diffInMillis = Date.now() - tokenExpiration

    if (diffInMillis > FORCED_SESSION_INACTIVITY_TIME_MILLIS) {
      yield call(logout, { reason: 'Forced session inactivity' })

      Alert.alert(
        I18n.t('Common.forcedSessionInactivity.alertTitle'),
        I18n.t('Common.forcedSessionInactivity.alertDescription'),
        undefined,
        { cancelable: false }
      )
      // Indicate that the user was logged out
      return true
    }
  }

  return false
}
