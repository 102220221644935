import React from 'react';
import styled from 'styled-components/native'

import { Colors, Metrics } from 'APP/Themes'
import Icon from 'APP/Converse/Icon'
import Typography from 'APP/Converse/Typography'
import { View } from 'react-native';

export enum ContentNoticeType {
  WARNING = 'warning',
  INFO = 'info',
}

interface ContentCardNoticeProps {
  type: ContentNoticeType;
  name: string;
}

const StyledContainer = styled(View)<{ isWarning: boolean }>`
  position: absolute;
  top: ${Metrics.baseMargin * 0.75}px;
  right: ${Metrics.baseMargin * 0.625}px;
  flex-direction: row;
  border-radius: ${Metrics.baseMargin * 1.85}px;
  border-width: 1px;
  border-color: ${Colors.appBg};
  background-color: ${({ isWarning }) => isWarning ? Colors.contentCardWarningTagBg : Colors.contentCardInfoTagBg};
  padding: ${Metrics.baseMargin * 0.375}px;
`

const maxCharacters = 15;
const StyledTypography = styled(Typography).attrs(() => ({
  variant: 'buttonText'
}))`
  font-size: ${({ theme }) => theme.fonts.fontSize.small}px;
  max-width: ${({ theme }) => theme.fonts.fontSize.small * maxCharacters}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ isWarning }) => isWarning ? Colors.contentCardWarningTagText : Colors.contentCardInfoTagText};
`

const StyledWarningIcon = styled(Icon).attrs(() => ({
  src: 'error',
  variant: 'materialIcons',
  size: Metrics.baseMargin
}))`
  margin-right: ${Metrics.baseMargin * 0.625}px;
  color: ${Colors.contentCardWarningTagText};
`


const ContentCardNotice: React.FC<ContentCardNoticeProps> = ({ type, name }) => {
  const isWarning = type === ContentNoticeType.WARNING;

  return (
    <StyledContainer isWarning={isWarning} testID={`${type}-${name}`}>
      {isWarning && <StyledWarningIcon />}
      <StyledTypography>{name}</StyledTypography>
    </StyledContainer>
  );
};

export default ContentCardNotice
