import { StyleSheet } from 'react-native'
import { Colors } from 'APP/Themes'

export default StyleSheet.create({
  applicationView: {
    flex: 1,
    flexGrow: 1,
    backgroundColor: Colors.elementsBg,
  },
  container: {
    flex: 1,
    flexGrow: 1,
    flexDirection: 'row',
  },
})
