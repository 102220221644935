import Config from 'APP/Config'
import AsyncStorage from '@react-native-community/async-storage'
import { persistStore } from 'redux-persist'
import StartupActions from 'APP/Redux/StartupRedux'
import { navigationRef as Nav } from 'APP/Nav'
import { migrateTokensFromAsyncStorage } from 'APP/Config/ReduxPersist'

const storesToPurge = ['patient', 'history', 'family']

const updateReducers = async (store) => {
  const newVersion = Config.REDUX_PERSIST.reducerVersion
  const config = Config.REDUX_PERSIST.storeConfig
  const startup = () => {
    // If router is ready, let's kick off startup
    if (Nav?.isReady()) {
      store.dispatch(StartupActions.startup())
    } else {
      // If not, check again in a moment
      setTimeout(startup, 10)
    }
  }
  // tokens are blacklisted from being stored in async storage
  // members updating to this version of the app still have it saved
  // after rehydration is run those valuas will be removed following the blacklist instruction
  // thus a migration needs to happen before that
  // TODO: remove this migration script eventually
  // when most if not all members have updated the app to the latest version
  await migrateTokensFromAsyncStorage()
  const persistor = persistStore(store, config, startup)

  // Check latest reducer version
  AsyncStorage.getItem('reducerVersion').then((oldVersion) => {
    outputChange(oldVersion, newVersion)
    if (oldVersion !== newVersion) persistor.purge(storesToPurge)
    AsyncStorage.setItem('reducerVersion', newVersion)
  })
}

const outputChange = (oldVersion, newVersion) => {
  if (__DEV__) {
    console.log({
      name: 'VERSIONING',
      value: {
        'Old Version:': oldVersion,
        'New Version:': newVersion,
      },
      preview: 'Store and Reducer Version is ' + newVersion.toString(),
    })
  }
}

export default { updateReducers }
