import React, { ReactNode } from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'

import { getImageAspectRatio } from 'APP/Lib/StylingHelpers'
import { Images } from 'APP/Themes'
import Typography from 'APP/Converse/Typography'
import styled from 'styled-components/native'

interface SignupLogoHeaderProps {
  title: string | ReactNode
  subtitle: string | ReactNode
  style?: StyleProp<ViewStyle>
}

const LogoImage = styled.Image`
  height: 45px;
  margin: ${({ theme }) => theme.metrics.baseMargin}px 0px;
  resize-mode: contain;
  align-self: flex-start;
`

const Container = styled.View`
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin}px;
  gap: ${({ theme }) => theme.metrics.baseMargin}px;
`

const SignupLogoHeader = ({ title, subtitle, style }: SignupLogoHeaderProps) => {
  const iconSource = Images.logoBlue
  const iconAspectRatio = getImageAspectRatio(iconSource)

  return (
    <Container style={style}>
      <LogoImage style={{ aspectRatio: iconAspectRatio }} source={iconSource} />
      <Typography variant="h1">{title}</Typography>
      <Typography>{subtitle}</Typography>
    </Container>
  )
}

export default SignupLogoHeader
