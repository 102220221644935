import React, { useEffect, useMemo, useState } from 'react'
import { ActivityIndicator, Platform, Text, View } from 'react-native'
import moment from 'moment'
import { useForm, Controller } from 'react-hook-form'
import Toast from 'react-native-toast-message'
import { useDispatch, useSelector } from 'react-redux'
import HabitsActions from 'APP/Redux/HabitsRedux'
import { selectNotificationConfiguration } from 'APP/Sagas/HabitsSagas'
import I18n from 'APP/Services/i18n'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import DateSheet from 'APP/Components/DateSheet'
import DialogueDatePicker from 'APP/Components/DialogueDatePicker'
import { PrimaryButton } from 'APP/Components/Buttons'
import NotificationIcon from 'APP/Images/notification-icon.svg'
import { WeekDayPicker } from 'APP/Components/WeeklyDayPicker'
import ToggleSwitch from 'APP/Converse/Inputs/ToggleSwitch'

import { Colors, Metrics } from 'APP/Themes'
import Styles from './style'

const REMINDER_TIME_FORMAT = 'HH:mm:ss'
const mapDataToFormValues = (notification = {}) => ({
  enabled: Boolean(notification.enabled),
  weekDays: new Array(7)
    .fill(false)
    .map((_, i) => (!notification.week_days ? true : notification.week_days.indexOf(i + 1) > -1)),
  reminderTime: notification.reminder_time
    ? moment(notification.reminder_time, REMINDER_TIME_FORMAT).local().toDate()
    : null,
})
const mapFormValuesToData = (values) => ({
  ...values,
  weekDays: values.weekDays.reduce((acc, v, i) => (v ? acc.concat(i + 1) : acc), []),
  reminderTime: values.reminderTime
    ? moment(values.reminderTime).startOf('minute').format(REMINDER_TIME_FORMAT)
    : null,
})

const NetworkStatsSection = ({ loading, retry, refresh }) => {
  if (!loading && !retry) return null
  return (
    <View style={Styles.networkSection}>
      {loading && <ActivityIndicator size="large" color={Colors.text} testID="loading" />}
      {retry && (
        <DialogueTouchableOpacity analyticsName="Refresh" onPress={refresh} testID="retry">
          <MaterialIcon name="refresh" size={Metrics.icons.large} color={Colors.text} />
        </DialogueTouchableOpacity>
      )}
    </View>
  )
}

const HabitReminder = ({ habit, processing, testID }) => {
  const dispatch = useDispatch()
  const {
    loading,
    retry,
    data: notification,
  } = useSelector((state) => selectNotificationConfiguration(state, habit.identifier)) || {}
  const submitState = useSelector((state) => state.habits.updateNotificationConfiguration)
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    reset,
    formState: { dirtyFields, isValid },
  } = useForm({
    mode: 'all',
  })
  const isDirty = Object.values(dirtyFields).some(Boolean)
  const defaultValues = useMemo(() => mapDataToFormValues(notification), [notification])
  const [timePickerOpened, setTimePickerOpened] = useState(false)
  const disabled = loading || retry || submitState.loading || processing

  const fetchNotification = () =>
    dispatch(HabitsActions.getNotificationConfigurations(habit.identifier))
  const resetUpdateNotificationState = () =>
    dispatch(HabitsActions.resetUpdateNotificationConfiguration())
  const submit = (values) =>
    dispatch(
      HabitsActions.updateNotificationConfiguration(habit.identifier, notification?.type, values)
    )

  const onPickerChange = (_, time) => {
    if (time) {
      setValue('reminderTime', time, { shouldDirty: true })
      trigger('enabled')
    }
    // onDone does not get fired for Android
    if (Platform.OS === 'android') {
      onPickerDone()
    }
  }
  const onPickerDone = () => {
    setTimePickerOpened(false)
  }
  const onSubmit = (values) => {
    setTimePickerOpened(false)
    submit(mapFormValuesToData(values))
  }

  useEffect(() => {
    if (habit?.identifier) {
      fetchNotification()
    }
  }, [habit?.identifier])
  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues])
  useEffect(() => {
    // when a member modifies any field
    // and when toggle was disabled initially
    // flip the toggle on
    if (isDirty && !defaultValues.enabled) setValue('enabled', true, { shouldValidate: true })
  }, [isDirty])
  useEffect(() => {
    if (submitState.retry) {
      Toast.show({ text1: I18n.t('Habits.editReminder.error') })
      resetUpdateNotificationState()
    }
  }, [submitState.retry, defaultValues])

  const hiddenStyle = !notification && Styles.hidden

  return (
    <>
      <View style={[Styles.container, !timePickerOpened && Styles.bottomSpace]} testID={testID}>
        <View style={Styles.toggleContainer}>
          <Text style={Styles.label}>{I18n.t('Habits.editReminder.enableToggleLabel')}</Text>
          <Controller
            control={control}
            name="enabled"
            rules={{
              // allow enabling notifications only with reminderTime
              validate: (value) => defaultValues.enabled || (value && !!getValues().reminderTime),
            }}
            render={({ field: { value, onChange } }) => (
              <ToggleSwitch
                checked={value}
                disabled={disabled}
                onValueChange={onChange}
                testID="enableToggle"
              />
            )}
          />
        </View>
        <View style={Styles.preview}>
          <View style={Styles.row}>
            <NotificationIcon fillLine={Colors.darkText} />
            <Text style={Styles.previewTitle} numberOfLines={1}>
              {I18n.t('Habits.notification.title')}
            </Text>
          </View>
          <Text style={[Styles.reminderTitle, hiddenStyle]} numberOfLines={1}>
            {notification?.habit?.title?.[I18n.baseLocale] || ' '}
          </Text>
          <Text style={[Styles.reminderInstruction, hiddenStyle]} numberOfLines={1}>
            {notification?.instruction?.[I18n.baseLocale] ||
              I18n.t('Habits.notification.defaultInstruction')}
          </Text>
        </View>
        <Text style={Styles.label}>{I18n.t('Habits.editReminder.weekDayPickerLabel')}</Text>
        <Controller
          control={control}
          name="weekDays"
          // at least one day should be enabled
          rules={{ validate: (value) => value.some(Boolean) }}
          render={({ field: { value, onChange } }) => (
            <WeekDayPicker
              value={value}
              onChange={onChange}
              disabled={disabled}
              style={Styles.weekDayPicker}
            />
          )}
        />
        <Controller
          control={control}
          name="reminderTime"
          render={({ field: { value } }) => (
            <DialogueDatePicker
              value={value}
              format="LT"
              disabled={disabled}
              label={I18n.t('Habits.editReminder.timePickerLabel')}
              placeholder={I18n.t('Habits.editReminder.timePickerLabel')}
              isFocused={timePickerOpened}
              onPress={() => setTimePickerOpened(true)}
              inputContainerStyle={Styles.dateInput}
              testID="timePicker"
              analyticsName="reminders.timePicker"
            />
          )}
        />
        <PrimaryButton
          title={I18n.t('Habits.editReminder.save')}
          onPress={handleSubmit(onSubmit)}
          showActivityIndicator={submitState.loading}
          analyticsName="HabitNotifications.save"
          disabled={!isDirty || !isValid || disabled}
          style={Styles.save}
        />
        <NetworkStatsSection loading={loading} retry={retry} refresh={fetchNotification} />
      </View>
      {timePickerOpened && (
        <DateSheet
          mode="time"
          display={Platform.select({ ios: 'spinner', android: 'default' })}
          onChange={onPickerChange}
          onDone={onPickerDone}
          date={getValues().reminderTime || new Date()}
          maximumDate={null}
          show
        />
      )}
    </>
  )
}

export default HabitReminder
