import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Fonts, Metrics } from 'APP/Themes'

export const Styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    padding: Metrics.baseMargin,
    marginTop: Metrics.baseMargin / 4,
    backgroundColor: Colors.elementsBg,
    flexDirection: 'row',
    flex: 1,
    ...ApplicationStyles.shadows.shadowBottomLit,
  },
  ctaContainer: {
    flexDirection: 'column',
  },
  loading: {
    width: 150,
  },
  disabled: {
    backgroundColor: Colors.contrastElementBg,
  },
  icon: {
    paddingRight: 12,
  },
  image: {
    resizeMode: 'contain',
    tintColor: Colors.accent,
    width: 20,
    height: 20,
  },
  innerContainer: {
    maxWidth: 225,
  },
  title: {
    ...Fonts.style.h4,
    color: Colors.text,
  },
  mainTitle: {
    ...Fonts.style.button,
    color: Colors.text,
    marginTop: Metrics.baseMargin / 4,
  },
  subtitle: {
    ...Fonts.style.body,
    marginTop: Metrics.baseMargin / 4,
    color: Colors.text,
  },
  action: {
    marginLeft: 'auto',
    justifyContent: 'center',
  },
  cta: {
    display: 'flex',
    flex: 1,
  },
  ctaButton: {
    marginBottom: 0,
    marginHorizontal: 0,
    marginTop: Metrics.baseMargin,
  },
  row: {
    flexDirection: 'row',
    flex: 1,
  },
})
