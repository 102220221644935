import React, { useLayoutEffect, useCallback } from 'react'
import { View } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { Appearance } from 'react-native-appearance'
import { useRoute } from '@react-navigation/native'

import { useShareDeepLinkFromConfig } from 'APP/Hooks/Share'
import ButtonShare from 'APP/Components/ButtonShare'
import WebContentView from 'APP/Components/WebContentView'
import Analytics from 'APP/Services/Analytics'
import UrlBuilder from 'APP/Services/UrlBuilder'
import I18n from 'APP/Services/i18n'
import sprigClient from 'APP/Services/Sprig'
import { routeNameToAnalyticsName } from 'APP/Nav'
import Styles from './style'

const ANALYTICS = {
  PAGE_VIEWED: 'CMS_PAGE_VIEWED',
  PAGE_CLOSED: 'CMS_PAGE_CLOSED',
}

const SUPPORTED_THEMES = ['light', 'dark']

function CmsScreen(props) {
  const { navigation } = props
  const route = useRoute()
  const {
    uri,
    id,
    locale,
    sprig,
    onMessage,
    showShareButton,
    hideNavigation,
    services,
    analyticsName: analyticsNameProp,
  } = {
    ...route?.params,
    ...props,
  }

  // analyticsName is passed from <CMSContentList /> to track wellness articles
  // used to track active wellness users
  const analyticsName = analyticsNameProp || routeNameToAnalyticsName(route?.name)

  let colorScheme = Appearance.getColorScheme()
  //default to light mode for < Android 10 users
  if (!SUPPORTED_THEMES.includes(colorScheme)) {
    colorScheme = 'light'
  }
  // State|Props `screenUri` and `uri` searchQueryParams could be not UTF-8 encoded
  // Some searchQueryParams interpretation could failed on browser or webview from external app
  // See example of possible bugs at https://dialoguemd.atlassian.net/browse/DIA-40227
  // @todo ensure each searchQueryParams are properly encoded
  const screenUri = uri
    ? // Enforce uri to have a `style` with colorScheme defined
      // Most of in app urls are generated with style=light and could not matching with current user device
      UrlBuilder.setQueryParams(uri, { style: colorScheme })
    : UrlBuilder.cmsArticleUrl(id, colorScheme, locale || I18n.baseLocale)

  const shareParams = uri
    ? // Use share url with uri params and ensure style is not set.
      { uri: UrlBuilder.deleteQueryParams(uri, ['style']) }
    : // Or default to a locale|id tuple
      { locale, id }

  const shareArticle = useShareDeepLinkFromConfig('CMS_SCREEN_DEEP_LINK_URL', shareParams, {
    analyticsName,
  })

  useFocusEffect(
    useCallback(() => {
      Analytics.trackEvent('cms_content_loaded', {
        screen_name: analyticsName, // Used by both articlesTipsContent & practitionerProfile
        trigger: screenUri,
        services: services,
      })

      if (sprig) {
        sprigClient.track(ANALYTICS.PAGE_VIEWED)
      }

      return () => {
        if (sprig) {
          sprigClient.track(ANALYTICS.PAGE_CLOSED)
        }
      }
    }, [analyticsName, screenUri, services, sprig])
  )

  useLayoutEffect(() => {
    if (hideNavigation) {
      navigation.setOptions({
        headerShown: false,
      })
    } else {
      let options = {}
      if (showShareButton) {
        options.headerRight = () => (
          <ButtonShare
            analyticsName={analyticsName}
            onPress={() => shareArticle.share()}
            testID="shareBtn"
          />
        )
      }
      navigation.setOptions(options)
    }
  }, [analyticsName, hideNavigation, navigation, showShareButton, shareArticle])

  return (
    <View style={Styles.container}>
      <WebContentView uri={screenUri} screen={'cms'} onMessage={onMessage} />
    </View>
  )
}

export default CmsScreen
