import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react'
import { useSelector } from 'react-redux'
import debounce from 'lodash.debounce'

// Services
import Analytics from 'APP/Services/Analytics'
import ChoiceSearchService from 'APP/Services/ChoiceSearch'

export const initialSearchSourceState = {
  error: undefined,
  running: false,
  results: [],
  query: undefined,
}
export function SearchSourceReducer(state, action) {
  switch (action.type) {
    case 'fetchStart':
      return {
        ...state,
        error: undefined,
        running: true,
        query: action.query,
      }
    case 'fetchSuccess':
      if (state.query === action.query) {
        return {
          ...state,
          error: undefined,
          running: false,
          results: action.results || initialSearchSourceState.results,
        }
      }
      return {
        ...state,
        running: false,
      }
    case 'fetchFailure':
      return {
        ...state,
        error: action.error,
        running: false,
      }
    case 'resetState':
      return initialSearchSourceState
    default:
      throw new Error()
  }
}
export const useChoiceSearchSource = (source, debounceMs = 1000) => {
  const [state, dispatch] = useReducer(SearchSourceReducer, initialSearchSourceState)
  const loginStore = useSelector((state) => state.login)
  const ChoiceSearch = useRef(ChoiceSearchService.create(loginStore, source)).current
  const callId = useRef()

  const debouncedFetch = useRef(
    debounce(async (query) => {
      const currentId = Date.now()
      callId.current = currentId

      let results
      let error

      try {
        if (query) {
          results = await ChoiceSearch.search(query)
        }
      } catch (e) {
        error = e
      }

      if (currentId === callId.current) {
        if (error) {
          dispatch({ type: 'fetchFailure', error })
        } else {
          dispatch({ type: 'fetchSuccess', query, results })
        }
      }
    }, debounceMs)
  ).current

  const submitQuery = useCallback((query) => {
    if (query && query.length > 2) {
      dispatch({ type: 'fetchStart', query })
      debouncedFetch(query)
    } else {
      dispatch({ type: 'resetState' })
    }
  }, [])

  return [state, submitQuery]
}

export const normalizeGroup = (choices, group_data) => {
  const groups = Object.assign({}, group_data)
  const choicesNormalized = {}
  const groupsOrder = []

  choices.forEach((choice) => {
    if (!choice.group_id) throw new Error()

    const group = Object.assign({}, groups[choice.group_id])

    if (!group.choices) {
      group.choices = [choice.id]
    } else {
      group.choices.push(choice.id)
    }

    groups[choice.group_id] = group

    if (groupsOrder.indexOf(choice.group_id) === -1) {
      groupsOrder.push(choice.group_id)
    }

    choicesNormalized[choice.id] = choice
  })

  return {
    groups,
    choices: choicesNormalized,
    order: groupsOrder,
  }
}

export const getDenormalizedGroup = ({ groups, choices, order }, choiceIds) => {
  const newOrder =
    choiceIds &&
    choiceIds.reduce((prev, choiceId) => {
      const groupId = choices[choiceId] && choices[choiceId].group_id
      if (groupId && prev.indexOf(groupId) === -1) {
        return prev.concat([groupId])
      }
      return prev
    }, [])

  return (newOrder || order).map((groupId) => {
    const group = groups[groupId]
    const choicesOrder = newOrder
      ? group.choices.filter((id) => choiceIds.indexOf(id) !== -1)
      : group.choices
    return {
      ...group,
      choices: choicesOrder.map((id) => choices[id]),
    }
  })
}

const initialChoiceSearchState = {
  groups: [],
  choices: {},
  order: [],
  normalizationFinished: false,
  normalizationError: false,
}
function ChoiceSearchReducer(state, action) {
  switch (action.type) {
    case 'normalizeChoices':
      return {
        ...state,
        groups: action.groups,
        choices: action.choices,
        order: action.order,
        normalizationError: false,
        normalizationFinished: true,
      }
    case 'normalizeChoicesError':
      return {
        ...state,
        normalizationError: true,
        normalizationFinished: true,
      }
    default:
      throw new Error()
  }
}

export const useChoiceSearch = (group_data, choicesProp, source, debounceMs) => {
  const [{ groups, choices, order, normalizationError }, dispatch] = useReducer(
    ChoiceSearchReducer,
    initialChoiceSearchState
  )
  useEffect(() => {
    try {
      dispatch({ type: 'normalizeChoices', ...normalizeGroup(choicesProp, group_data) })
    } catch {
      dispatch({ type: 'normalizeChoicesError' })
    }
  }, [choicesProp, group_data])

  const [{ error, running, results, query }, submitQuery] = useChoiceSearchSource(
    source,
    debounceMs
  )

  const denormalizedGroups = useMemo(
    () =>
      getDenormalizedGroup(
        { groups, choices, order },
        query && query.length > 2 ? results : undefined
      ),
    [groups, order, choices, results]
  )

  useEffect(() => {
    if (query) {
      Analytics.trackEvent('choice_search_source_results_displayed', {
        source,
        search_query: query,
        results_displayed: denormalizedGroups.reduce(
          (idsDisplayed, group) => idsDisplayed.concat(group.choices.map(({ id }) => id)),
          []
        ),
      })
    }
  }, [denormalizedGroups])

  const state = useMemo(
    () => ({
      error: error || (normalizationError && 'Invalid data'),
      running,
      results,
    }),
    [error, normalizationError, running, results]
  )

  return [denormalizedGroups, submitQuery, state]
}
