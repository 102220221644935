export const RESERVED_KEYS = {
  ENVIRONMENT: 'ENVIRONMENT',
  BRAND_MAP: 'BRAND_MAP',
  DEBUG_SETTINGS: 'DEBUG_SETTINGS',
  ANALYTICS_SCHEMAS: 'ANALYTICS_SCHEMAS',
  REDUX_PERSIST: 'REDUX_PERSIST',
}

export const OVERRIDE_CONFIG_KEY = 'override-config'

export const MAX_USER_AGE = 120
