import { StyleSheet } from 'react-native'
import { ApplicationStyles, Fonts, Colors, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  container: {
    ...ApplicationStyles.screen.fullPrimaryContainer,
  },
  zeroEligibilityContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  coverageList: {
    paddingTop: Metrics.baseMargin,
  },
  sectionHeader: {
    ...Fonts.style.bodySmallBold,
    color: Colors.text,
    padding: 20,
    textAlign: 'center',
  },
  itemContainer: {
    paddingTop: Metrics.baseMargin,
    paddingBottom: Metrics.baseMargin,
    paddingLeft: 24,
    paddingRight: 24,
    marginTop: 0.5,
    marginBottom: 0.5,
  },
  activeItemContainer: {
    ...ApplicationStyles.shadow,
    backgroundColor: Colors.elementsBg,
  },
  inactiveItemContainer: {
    backgroundColor: Colors.inactiveCoverageBg,
  },
  itemTitle: {
    ...Fonts.style.subheaderBold,
  },
  activeItemText: {
    color: Colors.text,
  },
  inactiveItemText: {
    color: Colors.inactiveCoverageText,
  },
  addCoverageButton: {
    marginHorizontal: Metrics.baseMargin * 2,
  },
  footerDescription: {
    ...Fonts.style.bodySmall,
    color: Colors.text,
    textAlign: 'center',
    marginBottom: Metrics.baseMargin * 2,
  },
})
