import { Linking } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import Config from 'APP/Config'
import PatientHistoryActions from 'APP/Redux/PatientHistoryRedux'
import { getMicroAppTitle, MICRO_APP_TRACKING_TEXT } from 'APP/Lib/MicroAppHelpers'
import { handleNavigationContactInfo } from 'APP/Helpers/handleNavigationContactInfo'
import sprigClient from 'APP/Services/Sprig'
import Analytics from 'APP/Services/Analytics'

export const serviceGroupActionType = {
  episodeCreation: 'episode_creation',
  url: 'url',
  navigation: 'navigation',
  showContactInfo: 'show_contact_info',
}

const useHandleOnBenefitsPress = () => {
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const { stressScoreToWBI } = useSelector((state) => state.features)

  const episodeDispatch = (actionValue, placeholderHealthIssueTypeId) => {
    if (stressScoreToWBI && actionValue === Config.EPISODE_CREATION_COMMANDS.wbiAssessment) {
      dispatch(PatientHistoryActions.getOrCreateWbiEpisodeRequest(null))
    } else {
      dispatch(
        PatientHistoryActions.launchEpisodeCommand(actionValue, placeholderHealthIssueTypeId)
      )
    }
  }

  const navigateToMicroApp = (name) => {
    const { appId, microAppProps } = Config.MICROAPPS_CONFIG['navigation']
    if (appId) {
      const title = getMicroAppTitle(appId)
      Analytics.trackEvent('button_click', {
        button_text: title,
        button_value: 'Open microapp',
        trigger: `${MICRO_APP_TRACKING_TEXT[appId]} - ${name}`,
      })
      navigation.navigate('microApp', {
        microAppProps: { appId, ...microAppProps },
        title,
      })
    }
  }

  return (item, analyticsScreenName, serviceGroup) => {
    const { name, url, id, title } = item || {}
    const actionType = item?.action_type || item?.actionType
    const actionValue = item?.action_value || item?.actionValue
    const contentType = item?.content_type || item?.contentType
    const navigationUrl = item?.navigation_url || item?.navigationUrl
    const contactInfo = item?.contactInfo || null
    sprigClient.track(`TILE_CLICKED_${name}`)
    Analytics.trackEvent('button_click', {
      button_value: `serviceAction : ${contentType} : ${id} : ${name}`,
      button_text: title,
      trigger: analyticsScreenName,
    })

    const placeholderHealthIssueTypeId = item?.placeholderHealthIssueTypeId
    if (actionType === serviceGroupActionType.episodeCreation) {
      episodeDispatch(actionValue, placeholderHealthIssueTypeId)
    } else if (actionType === serviceGroupActionType.url || url) {
      Linking.openURL(actionValue || url)
    } else if (actionType === serviceGroupActionType.navigation && !navigationUrl) {
      navigateToMicroApp(name)
    } else if (actionType === serviceGroupActionType.navigation && navigationUrl) {
      navigation.navigate(actionValue, {
        navigationUrl,
        serviceGroup: serviceGroup || null,
      })
    } else if (actionType === serviceGroupActionType.showContactInfo && contactInfo) {
      handleNavigationContactInfo(contactInfo, navigation)
    }
  }
}

export default useHandleOnBenefitsPress
