import React, { useState } from 'react'
import { View, Text, Image, Modal } from 'react-native'
import { Colors, Images } from 'APP/Themes'
import Styles from './style'

import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import Button from 'APP/Converse/Button'

const TooltipButton = ({ config, style }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false)

  const showPopover = () => {
    setTooltipVisible(true)
  }

  const closePopover = () => {
    setTooltipVisible(false)
  }

  return (
    <View style={style}>
      <Button
        testID="tooltip-button"
        variant="primary"
        widthVariant="icon"
        iconVariant="material"
        icon="help"
        onPress={showPopover}
        analyticsName="Tooltip"
      />
      <Modal
        statusBarTranslucent
        transparent={true}
        animationType="fade"
        visible={tooltipVisible}
        onRequestClose={closePopover}
      >
        <DialogueTouchableOpacity
          testID="close-tooltip-btn"
          activeOpacity={1.0}
          style={Styles.popoverBg}
          onPress={closePopover}
          analyticsName="Close popover bg"
        />
        <View style={Styles.popoverWindow}>
          <DialogueTouchableOpacity
            underlayColor={Colors.listFocus}
            onPress={closePopover}
            style={Styles.closeButton}
            analyticsName="Close"
          >
            <Image source={Images.closeIcon} style={{ tintColor: Colors.text }} />
          </DialogueTouchableOpacity>
          <View style={Styles.popoverContent}>
            {config.img && (
              <View style={Styles.popImageContainer}>
                <Image style={Styles.popImage} source={{ uri: config.img }} />
              </View>
            )}
            {config.text && <Text style={Styles.popText}>{config.text}</Text>}
            {config.subtitle && <Text style={Styles.popSubtitle}>{config.subtitle}</Text>}
          </View>
        </View>
      </Modal>
    </View>
  )
}

export default TooltipButton
