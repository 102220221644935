import PushNotification from 'react-native-push-notification'
import * as Sentry from '@sentry/react-native'
import { Platform } from 'react-native'
import moment from 'moment'
import Config from 'APP/Config'
import I18n from 'APP/Services/i18n'
import makeAsynchronous from 'APP/Helpers/MakeAsynchronous'
import { logDdError } from 'APP/Lib/Datadog'

const REPEAT_TYPES = {
  DAY: 'day',
  WEEK: 'week',
}

export const removeLocalNotificationsForOneHabit = async (habitId) => {
  try {
    if (!habitId) {
      throw new Error('habit id is required')
    }

    const allScheduledNotifications = await makeAsynchronous(
      PushNotification.getScheduledLocalNotifications.bind(PushNotification)
    )
    const notificationsForHabit = (allScheduledNotifications || []).filter(
      (n) => n?.data?.habitId === habitId
    )

    const ids = notificationsForHabit.map((n) => {
      PushNotification.cancelLocalNotification(n.id)
      return n.id
    })

    return ids
  } catch (err) {
    Sentry.captureException(err)
    logDdError(err.message, err.stack)
    throw new Error(err)
  }
}

function scheduleLocalNotification(
  { date: requestedDate, title, message, subtitle, userInfo },
  repeatType
) {
  if (![requestedDate, message, userInfo?.habitId, repeatType].every(Boolean)) {
    throw new Error('Missing required params')
  }

  const date = requestedDate.clone()
  //notification can't be scheduled starting from today
  if (date.isBefore(moment(), 'minutes')) {
    date.add(1, repeatType)
  }

  PushNotification.localNotificationSchedule({
    channelId: Config.ANDROID_APP_IDENTIFIER + '.habit_channel',
    date: date.toDate(),
    userInfo: { ...userInfo, repeatType },
    isTimeZoneAgnostic: true,
    repeatType,
    ...Platform.select({
      ios: {
        title,
        subtitle,
        message,
      },
      // the order it appears on Android
      android: {
        subText: title,
        title: subtitle,
        message,
      },
    }),
  })
}

export const updateLocalNotificationsForOneHabit = async (notificationConfig) => {
  try {
    if (!notificationConfig) {
      throw new Error('notificationConfig config is required')
    }

    // 1: CLEAR ALL PREVIOUSLY SCHEDULED NOTIFICATIONS
    await removeLocalNotificationsForOneHabit(notificationConfig.habit.identifier)

    if (!notificationConfig.enabled) return

    // 2: SCHEDULE NEW NOTIFICATIONS
    const config = {
      title: I18n.t('Habits.notification.title'),
      message:
        notificationConfig.instruction?.[I18n.baseLocale] ||
        I18n.t('Habits.notification.defaultInstruction'),
      subtitle: notificationConfig.habit.title[I18n.baseLocale],
      userInfo: { habitId: notificationConfig.habit.identifier, type: notificationConfig.type },
      date: moment(notificationConfig.reminder_time, 'HH:mm:ss').local(),
    }

    if (!notificationConfig.week_days || notificationConfig.week_days.length === 7) {
      scheduleLocalNotification(config, REPEAT_TYPES.DAY)
    } else {
      notificationConfig.week_days.forEach((weekDay) => {
        scheduleLocalNotification(
          { ...config, date: config.date.clone().isoWeekday(weekDay) },
          REPEAT_TYPES.WEEK
        )
      })
    }
  } catch (err) {
    Sentry.captureException(err)
    logDdError(err.message, err.stack)
    throw new Error(err)
  }
}

export const removeLocalNotificationsById = async (notificationId) => {
  try {
    if (!notificationId) {
      throw new Error('notification id is required')
    }

    PushNotification.cancelLocalNotification(notificationId)

    return notificationId
  } catch (err) {
    Sentry.captureException(err)
    logDdError(err.message, err.stack)
    throw new Error(err)
  }
}

export const ensureParityWithConfigs = async (notificationConfigs) => {
  try {
    if (!notificationConfigs) {
      throw new Error('notificationConfigs config is required')
    }

    const allScheduledNotifications = await makeAsynchronous(
      PushNotification.getScheduledLocalNotifications.bind(PushNotification)
    )

    // Place to capture all notifications that do align to a config.
    // Used at the end to ensure there weren't any stray scheduled notifications
    const notificationsAccountedFor = []

    // Loop through the configs and ensure all related scheduled notifications match the config
    for (const config of notificationConfigs) {
      // Ignore any configs that aren't enabled
      if (!config.enabled) {
        return
      }

      const notificationsForHabit = (allScheduledNotifications || []).filter(
        (n) => n?.data?.habitId === config.habit.identifier && n?.data?.type === config.type
      )

      // Capture notifications now that we've processed them
      notificationsAccountedFor.push(...notificationsForHabit)

      let diffExists = false

      // Diff 1. No scheduled notifications were found for enabled config
      if (notificationsForHabit.length === 0) {
        diffExists = true
      }

      for (const notification of notificationsForHabit) {
        // Diff 2. The time of at least one notification doesn't match enabled config
        const time = moment(notification.date).format('HH:mm:ss')
        if (time !== config.reminder_time) {
          diffExists = true

          // don't need to check other notifications, cause at least one doesn't match
          break
        }

        const configIsForEveryWeekday = !config.week_days || config.week_days.length === 7

        // Diff 3. Version 1: Config exists for every week day but the notification isn't set to repeat daily
        if (configIsForEveryWeekday && notification?.data?.repeatType !== REPEAT_TYPES.DAY) {
          diffExists = true

          break
        }

        // Diff 3. Version 2: Config exists for some week days but the notification isn't set to repeat weekly
        if (!configIsForEveryWeekday && notification?.data?.repeatType !== REPEAT_TYPES.WEEK) {
          diffExists = true

          break
        }

        // Diff 3. Version 3: Config exists for some week days but the notification doesn't match any of the days
        const day = parseInt(moment(notification.date).format('E'), 10)
        if (!configIsForEveryWeekday && !config.week_days.includes(day)) {
          diffExists = true

          break
        }
      }

      if (diffExists) {
        await updateLocalNotificationsForOneHabit(config)
      }
    }

    // Diff 4. Config did NOT exist for some notifications
    if (allScheduledNotifications.length !== notificationsAccountedFor.length) {
      allScheduledNotifications.forEach(async (notification) => {
        // Ignore notifications that match a config
        if (notificationsAccountedFor.includes(notification)) {
          return
        }

        // Delete stray notifications
        await removeLocalNotificationsById(notification.id)
      })
    }
  } catch (err) {
    Sentry.captureException(err)
    logDdError(err.message, err.stack)
    throw new Error(err)
  }
}
