import I18n from 'APP/Services/i18n'
import { useState, useMemo, useEffect, useCallback } from 'react'
import Analytics from 'APP/Services/Analytics'
import { pick } from 'lodash'
import { clone } from 'ramda'
import { shouldRenderComponent, shouldRenderService } from 'APP/Lib/ContentHelpers'
import { normalizeServiceV2 } from 'APP/Lib/ContentHelpers'

const CONTENT_TITLE = I18n.t('WellnessCenter.library.filters.content.title')
export const CONTENT_FILTERS = {
  VIDEO: 'video',
  CONTENT: 'content',
  AUDIO: 'audio',
  APP_EXTERNAL_SERVICE: 'appExternalService',
  APP_INTERNAL_SERVICE: 'appInternalService',
  EMPLOYEE_BENEFIT: 'employeeBenefit',
}

const DIFFICULTY_TITLE = I18n.t('WellnessCenter.library.filters.difficulty.title')
const DIFFICULTY_FILTERS_EN = {
  BEGINNER: I18n.t('WellnessCenter.library.filters.difficulty.options.beginner', { lng: 'en' }),
  INTERMEDIATE: I18n.t('WellnessCenter.library.filters.difficulty.options.intermediate', {
    lng: 'en',
  }),
  ADVANCED: I18n.t('WellnessCenter.library.filters.difficulty.options.advanced', { lng: 'en' }),
}
const DIFFICULTY_FILTERS_FR = {
  BEGINNER: I18n.t('WellnessCenter.library.filters.difficulty.options.beginner', { lng: 'fr' }),
  INTERMEDIATE: I18n.t('WellnessCenter.library.filters.difficulty.options.intermediate', {
    lng: 'fr',
  }),
  ADVANCED: I18n.t('WellnessCenter.library.filters.difficulty.options.advanced', { lng: 'fr' }),
}

const LENGTH_TITLE = I18n.t('WellnessCenter.library.filters.length.title')

const LANGUAGE_TITLE = I18n.t('WellnessCenter.library.filters.language.title')
const LANGUAGE_FILTERS = {
  EN: 'en',
  FR: 'fr',
}

export const INITIAL_LIBRARY_FILTERS = [
  {
    title: CONTENT_TITLE,
    checked: [CONTENT_FILTERS.VIDEO, CONTENT_FILTERS.CONTENT, CONTENT_FILTERS.AUDIO],
    filters: {
      [CONTENT_FILTERS.VIDEO]: I18n.t('WellnessCenter.library.filters.content.options.videos'),
      [CONTENT_FILTERS.CONTENT]: I18n.t('WellnessCenter.library.filters.content.options.articles'),
      [CONTENT_FILTERS.AUDIO]: I18n.t('WellnessCenter.library.filters.content.options.audio'),
    },
  },
  {
    title: DIFFICULTY_TITLE,
    checked: [
      DIFFICULTY_FILTERS_EN.BEGINNER,
      DIFFICULTY_FILTERS_EN.INTERMEDIATE,
      DIFFICULTY_FILTERS_EN.ADVANCED,
    ],
    filters: {
      [DIFFICULTY_FILTERS_EN.BEGINNER]: I18n.t(
        'WellnessCenter.library.filters.difficulty.options.beginner'
      ),
      [DIFFICULTY_FILTERS_EN.INTERMEDIATE]: I18n.t(
        'WellnessCenter.library.filters.difficulty.options.intermediate'
      ),
      [DIFFICULTY_FILTERS_EN.ADVANCED]: I18n.t(
        'WellnessCenter.library.filters.difficulty.options.advanced'
      ),
    },
  },
  {
    title: LENGTH_TITLE,
    range: [0, 60],
    selectedRange: [0, 60],
  },
]

export const INITIAL_LANGUAGE_FILTERS = [
  {
    title: LANGUAGE_TITLE,
    checked: [LANGUAGE_FILTERS[I18n.baseLocale.toUpperCase()] || LANGUAGE_FILTERS.EN],
    exclusive: true,
    uniqueAnswer: true,
    baseFilter: LANGUAGE_FILTERS[I18n.baseLocale.toUpperCase()],
    filters: {
      [LANGUAGE_FILTERS.EN]: I18n.t('WellnessCenter.library.filters.language.options.en'),
      [LANGUAGE_FILTERS.FR]: I18n.t('WellnessCenter.library.filters.language.options.fr'),
    },
  },
]

export const checkIfFiltersModified = (filters) => {
  // First, assert if even one filter has changed
  const filtersAreModified = filters.find((group) => {
    if (group.checked && !group.exclusive)
      return group.checked.length !== Object.keys(group.filters).length

    if (group.checked && group.exclusive)
      return group.checked.length !== 1 || group.checked[0] !== group.baseFilter

    if (group.range)
      return group.range[0] !== group.selectedRange[0] || group.range[1] !== group.selectedRange[1]
  })

  return !!filtersAreModified
}

const countModifiedFilters = (filters) => {
  let count = 0

  filters.forEach((filterGroup) => {
    // NOTE: we ignore exclusive filters (i.e. language) because they behave differently.

    if (filterGroup.checked && !filterGroup.exclusive)
      count += (filterGroup.checked.length - Object.keys(filterGroup.filters).length) * -1

    // If range and either end is different than base
    if (
      filterGroup.range &&
      (filterGroup.range[0] !== filterGroup.selectedRange[0] ||
        filterGroup.range[1] !== filterGroup.selectedRange[1])
    )
      count += 1
  })

  return count
}

export const countItemsBasedOnNewFilters = (
  library,
  filters,
  eligibleServices,
  healthResources,
  useBenefitsNavigationBrowse = false
) => {
  let updatedItemsCount = 0
  let updatedLibrary = []
  // Loop through every subcategory group in content
  library.forEach((subcategoryGroup) => {
    let updatedSubcategoryGroup = {
      id: subcategoryGroup.id || null,
      name: subcategoryGroup.name || subcategoryGroup.subcategory,
      title: subcategoryGroup.title || subcategoryGroup.subcategory,
      data: [],
    }
    // Loop through every item in each subcategory group
    subcategoryGroup.data.forEach((item) => {
      let contentIsActive = null
      let difficultyIsActive = null
      let lengthIsActive = null
      let languageIsActive = null
      let isEligible = false

      const isContentTypeService =
        useBenefitsNavigationBrowse &&
        [
          CONTENT_FILTERS.APP_EXTERNAL_SERVICE,
          CONTENT_FILTERS.APP_INTERNAL_SERVICE,
          CONTENT_FILTERS.EMPLOYEE_BENEFIT,
        ].includes(item.content_type)

      // Check each item against all filter groups
      filters.forEach((option) => {
        // 1. Content Type Filter - Ensure content type of item is an active filter
        if (option.title === CONTENT_TITLE) {
          contentIsActive = option.checked.includes(
            Object.keys(option.filters).find((key) => item[key] !== undefined)
          )
        }

        // 2. Difficulty Type - Find the difficulty filter related to this item (if difficulty exists)
        if (option.title === DIFFICULTY_TITLE) {
          if (!item.difficulty) {
            // If no difficulty defined, let it through
            difficultyIsActive = true
            return
          }

          // Else, check that item's difficulty is within the checked filters
          const difficultyKey = Object.keys(option.filters).find((key) => {
            const sanitizedContentDifficulty = item.difficulty.toLowerCase()
            const sanitizedKey = key.toUpperCase()

            // We need to handle for EN and FR content where the difficulty value may change
            // Importantly - regardless of language the member's device is, if they toggle the filters
            // to see content in both EN and FR, we need to properly filter on difficulty.
            // For example, in EN, a member wants to see all beginner content in both EN and FR,
            // while the difficulty string is "beginner", we also need to filter on "débutant" for FR.
            // TLDR: return difficulty key in consistent lang (EN) regardless of what lang the content is in.

            return (
              sanitizedContentDifficulty === DIFFICULTY_FILTERS_FR[sanitizedKey].toLowerCase() ||
              sanitizedContentDifficulty === DIFFICULTY_FILTERS_EN[sanitizedKey].toLowerCase()
            )
          })

          difficultyIsActive =
            option.checked.filter((option) => option === difficultyKey).length > 0
        }

        // 3. Length - Verify the item is within the requested length range
        if (option.title === LENGTH_TITLE) {
          if (!item.length) {
            // If no length defined, let it through
            lengthIsActive = true
            return
          }

          const parsedLength = parseInt(item.length, 10)

          if (option.selectedRange[1] === option.range[1]) {
            // If selected range is at max, allow any length greater than max through
            lengthIsActive = parsedLength >= option.selectedRange[0]
            return
          }

          // Else, check that item's length is within min and max values
          lengthIsActive =
            parsedLength >= option.selectedRange[0] && parsedLength <= option.selectedRange[1]
        }

        // 4. Language - Find the language filter related to this item
        if (option.title === LANGUAGE_TITLE) {
          languageIsActive = option.checked.includes(item.locale || item.language)
        }
        // 5. Eligible services - Check if item is visible based on user's eligible services
        if (
          isContentTypeService &&
          shouldRenderService(normalizeServiceV2(item), eligibleServices, healthResources)
        ) {
          isEligible = true
        } else if (
          !isContentTypeService &&
          shouldRenderComponent(item.service_filter, eligibleServices)
        ) {
          isEligible = true
        }
      })

      const needsToRenderAsService = isContentTypeService && isEligible
      const needsToRenderAsDocument = [
        contentIsActive,
        difficultyIsActive,
        lengthIsActive,
        languageIsActive,
        isEligible,
      ].every((isActive) => isActive === null || isActive)

      if (needsToRenderAsService || needsToRenderAsDocument) {
        // Add item to total count
        updatedItemsCount += 1
        // Add item to its subcategory
        updatedSubcategoryGroup.data.push(item)
      }
    })
    updatedLibrary.push(updatedSubcategoryGroup)
  })
  return {
    filtersCount: countModifiedFilters(filters),
    itemsCount: updatedItemsCount,
    library: updatedLibrary,
  }
}

const getNextFilterState = (
  library,
  baseFilteredLibrary,
  options,
  eligibleServices,
  healthResources,
  useBenefitsNavigationBrowse
) => {
  const filtersAreModified = checkIfFiltersModified(options)
  const filter = countItemsBasedOnNewFilters(
    library,
    options,
    eligibleServices,
    healthResources,
    useBenefitsNavigationBrowse
  )
  // Filters are in initial state
  if (!filtersAreModified) {
    return {
      items: baseFilteredLibrary,
      itemsCount: undefined,
      modifiedFiltersCount: undefined,
    }
  }

  return {
    items: filter.library,
    itemsCount: filter.itemsCount,
    modifiedFiltersCount: filter.filtersCount,
  }
}
const exposedStateKeys = [
  'options',
  'processing',
  'updatedLibrary',
  'appliedFiltersCount',
  'modifiedFiltersCount',
  'itemsCount',
]
export function useLibraryFilters({
  baseOptions,
  library: libraryProp,
  eligibleServices,
  healthResources,
  persisted,
  useBenefitsNavigationBrowse = false,
}) {
  const [filterState, setFilterState] = useState({
    libraryUpdateRequested: false,
    processing: true,
    items: undefined,
    itemsCount: undefined,
    modifiedFiltersCount: undefined,
    appliedFiltersCount: undefined,
    updatedLibrary: undefined,
    fallbackOptions: baseOptions,
    options: baseOptions,
  })

  // #1 get the base and memoize lib
  // to avoid race condition in updates
  const { library, baseFilteredLibrary } = useMemo(() => {
    if (libraryProp && baseOptions) {
      return {
        library: libraryProp,
        baseFilteredLibrary: countItemsBasedOnNewFilters(
          libraryProp,
          baseOptions,
          eligibleServices,
          healthResources,
          useBenefitsNavigationBrowse
        )?.library,
      }
    }
    return {}
  }, [libraryProp, baseOptions, eligibleServices, healthResources, useBenefitsNavigationBrowse])

  // (re-)init
  useEffect(() => {
    if (!library || !baseFilteredLibrary) return
    setFilterState((state) => ({
      ...state,
      libraryUpdateRequested: true,
      processing: true,
      options: persisted ? state.options : baseOptions,
      fallbackOptions: persisted ? state.fallbackOptions : baseOptions,
      updatedLibrary: baseFilteredLibrary,
    }))
  }, [library, baseFilteredLibrary])

  // on options change filter the library
  // and apply changes if init is not completed
  useEffect(() => {
    if (!library || !baseFilteredLibrary) return
    let nextFilterState = getNextFilterState(
      library,
      baseFilteredLibrary,
      filterState.options,
      eligibleServices,
      healthResources,
      useBenefitsNavigationBrowse
    )
    nextFilterState.processing = false
    if (filterState.libraryUpdateRequested) {
      nextFilterState.libraryUpdateRequested = false
      nextFilterState.appliedFiltersCount = nextFilterState.modifiedFiltersCount
      nextFilterState.updatedLibrary = nextFilterState.items
    }
    setFilterState((state) => ({ ...state, ...nextFilterState }))
  }, [
    library,
    baseFilteredLibrary,
    filterState.libraryUpdateRequested,
    filterState.options,
    healthResources,
    useBenefitsNavigationBrowse,
  ])

  const discardFilterChanges = useCallback(() =>
    setFilterState((state) => ({
      ...state,
      options: state.fallbackOptions,
    }))
  )
  const clearFilters = useCallback(() =>
    setFilterState((state) => ({
      ...state,
      libraryUpdateRequested: true,
      appliedFiltersCount: undefined,
      options: baseOptions,
      fallbackOptions: baseOptions,
    }))
  )
  const applyFilters = useCallback(() =>
    setFilterState((state) => {
      if (!state.processing) {
        return {
          ...state,
          updatedLibrary: state.items,
          appliedFiltersCount: state.modifiedFiltersCount,
          fallbackOptions: state.options,
        }
      }
      return state
    })
  )

  const handleFilterPress = useCallback((key, group) =>
    setFilterState((state) => {
      Analytics.trackEvent('button_click', {
        button_value: key,
        trigger: 'Wellness Center Filter',
        group: state.options[group].title,
      })

      const updatedOptions = clone(state.options) // clone array without reference

      const updatedFilterIndex = updatedOptions[group].checked.findIndex((filter) => filter === key)

      if (updatedFilterIndex >= 0) {
        updatedOptions[group].checked.splice(updatedFilterIndex, 1)
      } else {
        if (updatedOptions[group]?.uniqueAnswer) {
          updatedOptions[group].checked = []
        }
        updatedOptions[group].checked.push(key)
      }
      return {
        ...state,
        processing: true,
        options: updatedOptions,
      }
    })
  )

  const handleFilterSlide = useCallback((values, group) =>
    setFilterState((state) => {
      Analytics.trackEvent('button_click', {
        button_value: values.join('-'),
        trigger: 'Wellness Center Filter',
        group: state.options[group].title,
      })

      const updatedOptions = clone(state.options) // clone array without reference
      updatedOptions[group].selectedRange = values

      return {
        ...state,
        processing: true,
        options: updatedOptions,
      }
    })
  )

  const api = useMemo(() => {
    const exposedState = pick(filterState, exposedStateKeys)

    return {
      ...exposedState,
      clearFilters,
      applyFilters,
      discardFilterChanges,
      handleFilterSlide,
      handleFilterPress,
    }
  }, [
    ...Object.values(pick(filterState, exposedStateKeys)),
    clearFilters,
    applyFilters,
    discardFilterChanges,
    handleFilterSlide,
    handleFilterPress,
  ])

  return api
}
