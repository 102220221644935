import type { Moment } from 'moment'

import Typography from 'APP/Converse/Typography'
import I18n from 'APP/Services/i18n'

interface Props {
  messageKey: string
  dateFrom: Moment
  dateUntil: Moment
}

enum Difference {
  WEEK = 'weeks',
  DAY = 'days',
  HOUR = 'hours',
}

const getTimeframeUnit = (dateUntil: Moment, dateFrom: Moment) => {
  if (dateUntil.diff(dateFrom, Difference.WEEK) > 1) {
    return Difference.WEEK
  } else if (dateUntil.diff(dateFrom, Difference.DAY) > 1) {
    return Difference.DAY
  }
  return Difference.HOUR
}

export const TimeFrameMessage = ({ dateFrom, dateUntil, messageKey, ...rest }: Props) => {
  const timeframeUnit = getTimeframeUnit(dateUntil, dateFrom)
  const difference = dateUntil.diff(dateFrom, timeframeUnit)
  return (
    <Typography variant="bodyNormal" align="center" {...rest}>
      {I18n.t(messageKey, {
        difference,
        timeframe: I18n.t(`AppointmentBooking.timeframe_${timeframeUnit}`),
      })}
    </Typography>
  )
}
