import React, { useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { View } from 'react-native'

// Components
import Banner from 'APP/Components/Banner'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import Cobranding from 'APP/Components/Cobranding'
import DialogueBranding from 'APP/Components/DialogueBranding'
import sprigClient from 'APP/Services/Sprig'

import { useAppComponentMapper } from 'APP/Lib/ContentHelpers'
import { RootScreens } from 'APP/Lib/Enums'
// Styling
import Styles from './style'
import { ResponsiveScrollView } from 'APP/Converse/Layout'
import useScreenView from 'APP/Hooks/useScreenView'
import { featuresState } from 'APP/Sagas/FeaturesSaga'

// TODO: Switch ScrollView for FlatList, use with scrolltoindex()
// use https://reactnative.dev/docs/flatlist#scrolltoindex

const selectAppComponents = (state) => {
  const screens = state?.content?.layout?.data?.screens
  return screens?.find((screen) => screen?.mobile_scene_key === RootScreens.today)?.components
}

const TodayScreen = ({ navigation, route }) => {
  const { enabledSprigEvents } = useSelector(featuresState)
  const appComponents = useSelector(selectAppComponents)
  const mapAppComponent = useAppComponentMapper()
  const { isSmallScreen } = useScreenView()

  const eligibleComponents = useMemo(
    () =>
      appComponents?.reduce((eligibleComponents, appComponent) => {
        const Component = mapAppComponent(appComponent)

        if (Component) {
          eligibleComponents.push({ Component, appComponent })
        }

        return eligibleComponents
      }, []),
    [appComponents, mapAppComponent]
  )

  const renderComponent = ({ Component, appComponent }) => {
    // Launchpad needs some special styling
    if (appComponent.mobile_component_name === 'AppLaunchpad') {
      return (
        <Component
          key={appComponent.id}
          testId={appComponent.mobile_component_name}
          style={[Styles.appComponentVerticalSpacing, Styles.launchpad]}
          navigation={navigation}
          route={route}
        />
      )
    } else {
      return (
        <Component
          key={appComponent.id}
          appComponent={appComponent}
          testId={appComponent.mobile_component_name}
          style={Styles.appComponentVerticalSpacing}
          navigation={navigation}
          route={route}
        />
      )
    }
  }

  useEffect(() => {
    if (enabledSprigEvents?.MX_APP_LAUNCH) {
      sprigClient.track('MX_APP_LAUNCH')
    }
  }, [enabledSprigEvents])

  /* Logic note:
    <Banner /> is explicitly defined twice, as we want it to render
    1. Inside the scrollable view when on a small screen
    2. outside of the scrollable view when on a large screen
  */

  return (
    <View style={Styles.container}>
      {!isSmallScreen && <Banner />}
      <ResponsiveScrollView
        wrapIntoView={true}
        footer={
          <View style={Styles.footer}>
            <Cobranding style={Styles.brandingContainer} />
            <DialogueBranding style={Styles.brandingContainer} />
          </View>
        }
      >
        {isSmallScreen && <Banner />}
        {eligibleComponents?.map(renderComponent)}
      </ResponsiveScrollView>
      <NoticeBarContainer inline />
    </View>
  )
}

export default TodayScreen
