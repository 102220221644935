import React, { useImperativeHandle, useMemo, useState } from 'react'
import { useActionSheet } from '@expo/react-native-action-sheet'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import Styles from './style'
import { ApplicationStyles } from 'APP/Themes'
import Config from 'APP/Config'
import I18n from 'APP/Services/i18n'
import { isWeb } from 'APP/Helpers/checkPlatform'
import Typography from 'APP/Converse/Typography'

const OTHER_REGION_ENV_KEY = 'other'
const ConfigPicker = React.forwardRef(({ busy }, ref) => {
  const { showActionSheetWithOptions } = useActionSheet()
  const [nextEnv, setNextEnv] = useState(Config.ENVIRONMENT)

  const supportedEnvs = (Config.SUPPORTED_ENVIRONMENTS || []).filter(
    (envKey) => 'REGION' in (Config.ENVIRONMENTS?.[envKey] || {})
  )
  const configIsNotDynamic = supportedEnvs.length <= 1

  useImperativeHandle(ref, () => ({
    applyConfigChanges: applyChanges,
  }))

  const applyChanges = async () => {
    const nextSupportedEnv = supportedEnvs.includes(nextEnv) ? nextEnv : Config.DEFAULT_ENVIRONMENT
    if (nextSupportedEnv === Config.ENVIRONMENT) return
    await Config.changeEnvironment(nextSupportedEnv)
  }

  const handleRegionPress = async (envKey) => {
    if (isWeb()) {
      // fail-safe: go to self when no domain is specified
      window.location.href = Config?.ENVIRONMENTS?.[envKey]?.WEB_DOMAIN ?? window.location.href
      return
    }
    setNextEnv(envKey)
  }

  const actions = useMemo(() => {
    return [
      ...supportedEnvs.map((envKey) => ({
        value: I18n.t(`Regions.${Config.ENVIRONMENTS[envKey].REGION}`),
        handler: () => handleRegionPress(envKey),
      })),
      {
        value: I18n.t(`Regions.other`),
        handler: () => handleRegionPress(OTHER_REGION_ENV_KEY),
      },
      {
        value: I18n.t(`Regions.cancel`),
        cancellable: true,
      },
    ]
  }, [])

  const toogleEnvActionSheet = () => showActionSheetWithOptions(...actionsSheetOptions)

  const actionsSheetOptions = useMemo(() => {
    return [
      {
        ...ApplicationStyles.actionSheet,
        options: actions.map(({ value }) => value),
        destructiveButtonIndex: actions.findIndex(({ destructive }) => destructive),
        cancelButtonIndex: actions.findIndex(({ cancellable }) => cancellable),
      },
      (choiceIndex) => actions[choiceIndex]?.handler?.(),
    ]
  }, [actions])

  if (configIsNotDynamic) return null

  return (
    <DialogueTouchableOpacity disabled={busy} onPress={toogleEnvActionSheet} testID="configPicker">
      <Typography style={Styles.label} key={nextEnv}>
        {I18n.t(
          `Regions.${
            supportedEnvs.includes(nextEnv)
              ? Config.ENVIRONMENTS[nextEnv].REGION
              : OTHER_REGION_ENV_KEY
          }`
        )}{' '}
        &#9662;
      </Typography>
    </DialogueTouchableOpacity>
  )
})
ConfigPicker.displayName = 'ConfigPicker'

export default ConfigPicker
