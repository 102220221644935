import { put, take, spawn, all, select, call } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'

// Redux Actions
import { featuresActions } from 'APP/Redux/FeaturesSlice'

// Services
import { ldClient, initLD } from 'APP/Services/LaunchDarkly'
import { LD_FEATURES } from 'APP/Helpers/ldFeatures'

export const featuresState = (state) => state?.features || {}

export function* featuresMonitor() {
  yield call(initLD)
  yield spawn(monitorLDFeatures)
}

export function* monitorLDFeatures() {
  // Listener for any changes to the LD flags
  const changeChannel = eventChannel((emit) => {
    ldClient.ldClientInstance.on('change', () => emit(true))
    return () => ldClient.ldClientInstance.off('change')
  })

  try {
    do {
      // Ensures to grab override features
      const currentFeatures = yield select(featuresState)

      const initialFeatures = yield all(
        LD_FEATURES.reduce(
          (promiseDict, { name, ldKey, defaultValue }) => ({
            ...promiseDict,
            [name]: ldClient.ldClientInstance.variation(ldKey, defaultValue),
          }),
          {}
        )
      )

      yield put(featuresActions.setLdFlags(initialFeatures))

      const overrides = currentFeatures?.overrides || {}
      const hasOverrides = overrides && Object.keys(overrides).length > 0

      const features = hasOverrides ? { ...initialFeatures, ...overrides } : { ...initialFeatures }

      yield put(featuresActions.setFeatures(features))
    } while (yield take(changeChannel))
  } finally {
    changeChannel.close()
  }
}
