import moment from 'moment'
import I18n from 'APP/Services/i18n'
import { SupportedLocales } from 'APP/Models/Locale'

/**
 * Returns the date and time format strings for a given locale
 *
 * @param locale - The locale to get the date and time format for.
 * @returns An object with the timeFormat and dateFormat strings.
 */
export function getDateAndTimeFormatByLocale(locale: SupportedLocales) {
  const timeFormat = locale === 'fr' ? 'HH[h]mm' : 'h:mm A'
  const dateFormat = locale === 'fr' ? 'Do MMMM' : 'MMMM Do'
  return { timeFormat, dateFormat }
}

/**
 * Formats a UTC timestamp for displaying an appointment date/time, localized
 *
 * - If the date is today: returns "Today at 5:15 PM" for EN, or "Aujourd'hui à 17h15" for FR
 * - If the date is tomorrow: returns "Tomorrow at 5:15 PM" for EN or "Demain à 17h15" for FR
 * - Otherwise: returns "May 5th at 5:15 PM" for EN or "5 mai à 17h15" for FR
 *
 *
 *
 * @param utcTimestamp - The UTC timestamp string.
 * @returns A localized formatted date/time string.
 */
export function formatTimeForAppointment(utcTimestamp: string): string {
  const locale: SupportedLocales = I18n?.locale || 'en'
  const appointment = moment.utc(utcTimestamp).locale(locale).local()
  const today = moment().local().startOf('day')
  const tomorrow = moment().local().add(1, 'day').startOf('day')

  const { timeFormat, dateFormat } = getDateAndTimeFormatByLocale(locale)

  const labelAt = I18n.t('ConversationScreen.appointmentBanner.at')

  if (appointment.isSame(today, 'day')) {
    return `${I18n.t('ConversationScreen.appointmentBanner.today')} ${labelAt} ${appointment.format(
      timeFormat
    )}`
  }
  if (appointment.isSame(tomorrow, 'day')) {
    return `${I18n.t(
      'ConversationScreen.appointmentBanner.tomorrow'
    )} ${labelAt} ${appointment.format(timeFormat)}`
  }

  return `${appointment.format(dateFormat)} ${labelAt} ${appointment.format(timeFormat)}`
}
