import apisauce from 'apisauce'
import Config from '../../Config'
import { camelizeKeys } from 'humps'

import { resolveExtraProps } from '../ExtraPropsSourceService'
import { APP_SENDER } from 'APP/Lib/constants'

const buildGetUserAvatarURL = (userId) => `/api/v4/users/${userId}/image`

// constants
const DEFAULT_POSTS_LIMIT = 60

class RequestError extends Error {
  constructor(name, message, ...params) {
    super(...params)
    if (Error.captureStackTrace) Error.captureStackTrace(this, RequestError)
    this.name = name
    this.message = message
  }
}

const create = (accessToken, baseURL = Config.MATTERMOST_API_URL) => {
  const api = apisauce.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    timeout: 15000,
  })

  const handleResponse = (response, extraProps) => {
    if (response.ok) {
      if (extraProps) {
        return resolveExtraProps(response.data, accessToken)
      }
      return response.data
    } else {
      throw new RequestError(response.problem, response?.data?.message)
    }
  }

  const buildQueryString = (parameters) => {
    const keys = Object.keys(parameters)
    if (keys.length === 0) {
      return ''
    }

    let query = '?'
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      query += key + '=' + encodeURIComponent(parameters[key])

      if (i < keys.length - 1) {
        query += '&'
      }
    }

    return query
  }

  const getBaseRoute = () => {
    return 'api/v4'
  }

  const getUsersRoute = () => {
    return `${getBaseRoute()}/users`
  }

  const getChannelsRoute = () => {
    return `${getBaseRoute()}/channels`
  }

  const getChannelRoute = (channelId) => {
    return `${getChannelsRoute()}/${channelId}`
  }

  const getUserRoute = (userId) => {
    return `${getUsersRoute()}/${userId}`
  }

  const getFilesRoute = () => {
    return `${getBaseRoute()}/files`
  }

  const getPostsRoute = () => {
    return `${getBaseRoute()}/posts`
  }

  const getMe = () => {
    return api.get(getUserRoute('me')).then(handleResponse)
  }

  const getPostsBeforePostId = (channelId, postId, limit = DEFAULT_POSTS_LIMIT) => {
    const url = `${getChannelRoute(channelId)}/posts${buildQueryString({
      before: postId,
      page: 0,
      per_page: limit,
    })}`

    return api.get(url).then((response) => handleResponse(response, true))
  }

  const getMyChannels = (teamId) => {
    const url = `${getUserRoute('me')}/teams/${teamId}/channels`
    return api.get(url).then(handleResponse)
  }

  const getMyChannelsMembers = (teamId) => {
    const url = `${getUserRoute('me')}/teams/${teamId}/channels/members`
    return api.get(url).then(handleResponse)
  }

  const getMyChannelMembers = (channelId) => {
    const url = `${getBaseRoute()}/channels/${channelId}/members`
    return api.get(url).then((response) => camelizeKeys(response.data))
  }

  const viewMyChannel = (channelId) => {
    const data = { channel_id: channelId }
    const url = `${getChannelsRoute()}/members/me/view`
    return api.post(url, data).then(handleResponse)
  }

  const getPosts = (channelId, options = {}) => {
    const limit = options.limit || DEFAULT_POSTS_LIMIT
    const url = `${getChannelRoute(channelId)}/posts${buildQueryString({
      page: 0,
      per_page: limit,
    })}`

    return api.get(url).then((response) => handleResponse(response, true))
  }

  const getPostsSince = (channelId, time) => {
    const url = `${getChannelRoute(channelId)}/posts${buildQueryString({ since: time })}`
    return api.get(url).then((response) => {
      if (response.ok) {
        // For some reason this endpoint returns deleted posts, so we have to filter them
        const posts = response.data.posts
        const order = response.data.order
        var filteredPosts = Object.keys(posts).reduce((filtered, key) => {
          const currentPost = posts[key]
          if (currentPost.delete_at) {
            const postId = currentPost.id
            const index = order.indexOf(postId)
            if (index > -1) {
              order.splice(index, 1)
            }
          } else {
            filtered[key] = currentPost
          }
          return filtered
        }, {})

        return resolveExtraProps({ order, posts: filteredPosts }, accessToken)
      } else {
        throw new RequestError(response.problem)
      }
    })
  }

  const getUserPost = (channelId, text, file_ids, deviceId, localId, answer, flags, payload) => {
    let props = { sender: APP_SENDER, ...flags, deviceId, localId }

    if (answer) {
      if (typeof answer === 'string') {
        props.answer = answer
      } else {
        props.answer = JSON.stringify(answer)
      }
    }

    if (payload) {
      props.payload = payload
    }

    const post = {
      channel_id: channelId,
      message: text,
      props,
      file_ids,
    }
    return post
  }

  const createUserPost = (channelId, text, file_ids, deviceId, localId, answer, flags, payload) => {
    const post = getUserPost(channelId, text, file_ids, deviceId, localId, answer, flags, payload)
    const url = `${getPostsRoute()}`

    return api.post(url, post)
  }

  const getPublicUrl = ({ fileId }) => {
    const url = `${getFilesRoute()}/${fileId}/link`

    return api.get(url).then((response) => {
      if (response.ok) {
        return response.data.link
      } else {
        throw new RequestError(response.problem)
      }
    })
  }

  const getPostFileInfo = ({ postId }) => {
    const url = `${getPostsRoute()}/${postId}/files/info`
    return api.get(url).then(handleResponse)
  }

  const getFileData = (file) => {
    let name =
      file.fileName ||
      (file.fileType?.startsWith('image') ? `${+new Date()}.jpg` : `${+new Date()}.pdf`)

    return {
      uri: file.fileUri,
      type: file.fileType,
      name,
    }
  }

  const getFileFormData = (channelId, fileObj) => {
    const body = new FormData()
    // If we have a file of type `File`, we're on web and we can send that file object directly
    // Otherwise, we're on mobile and we need to create a file object from the uri
    const fileData = fileObj.file ? fileObj.file : getFileData(fileObj)
    body.append('files', fileData)
    body.append('channel_id', channelId)
    return body
  }

  const uploadFile = (channelId, fileObj) => {
    const url = `${getFilesRoute()}`
    const formData = getFileFormData(channelId, fileObj)

    return api
      .post(url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(handleResponse)
  }

  return {
    buildQueryString,
    getBaseRoute,
    getUsersRoute,
    getChannelsRoute,
    getChannelRoute,
    getUserRoute,
    getFilesRoute,
    getPostsRoute,
    getMe,
    getMyChannels,
    getUserPost,
    getMyChannelsMembers,
    getMyChannelMembers,
    getFileData,
    getFileFormData,
    viewMyChannel,
    getPosts,
    getPostsBeforePostId,
    getPostsSince,
    createUserPost,
    uploadFile,
    getPublicUrl,
    getPostFileInfo,
  }
}

export default {
  create,
}

export const buildAvatarUrl = (userId) => {
  return Config.MATTERMOST_API_URL + buildGetUserAvatarURL(userId)
}
