import { StyleSheet } from 'react-native'
import { Colors, Metrics, Fonts } from 'APP/Themes'

export default StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
  },
  persistentIndicator: {
    backgroundColor: Colors.errorSnackBarBg,
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    width: 40,
    height: 40,
    borderRadius: 20,
    top: 15,
    right: 15,
  },
  tempIndicator: {
    backgroundColor: Colors.errorSnackBarBg,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    display: 'flex',
    maxWidth: '90%',
    width: 400,
    position: 'relative',
    borderRadius: 4,
    paddingVertical: Metrics.baseMargin,
    paddingHorizontal: Metrics.baseMargin,
  },
  textContainer: {
    flex: 1,
    marginLeft: Metrics.baseMargin,
  },
  icon: {
    height: 16,
    width: 16,
    tintColor: Colors.darkText,
  },
  title: {
    ...Fonts.style.subheaderBold,
    color: Colors.darkText,
  },
  body: {
    ...Fonts.style.caption,
    color: Colors.darkText,
  },
})
