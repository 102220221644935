import React from 'react'
import ChatScreen from 'APP/Containers/ChatScreen'
import IntakeScreen from 'APP/Containers/IntakeScreen'
import { useSelector } from 'react-redux'

const ConversationScreen = (props) => {
  const { navigation, route } = props
  const { channelId } = { ...route?.params, ...props }
  const { posts, order } = useSelector((state) => state.history.channels[channelId]) || {}
  const myMessagingUserId = useSelector((state) => state.login.messagingUserId)

  const lastNonPatientPostIndex = order?.findIndex(
    (id) => posts?.[id].user_id !== myMessagingUserId
  )
  const lastNonPatientPostId = order?.[lastNonPatientPostIndex]
  const post = lastNonPatientPostId && posts?.[lastNonPatientPostId]
  const fullScreenState = !posts?.props?.missingData && post?.props?.member_app_fullscreen

  return fullScreenState ? (
    <IntakeScreen channelId={channelId} navigation={navigation} route={route} />
  ) : (
    <ChatScreen channelId={channelId} navigation={navigation} route={route} />
  )
}

export default ConversationScreen
