import { StyleSheet } from 'react-native'
import { Metrics, Colors, Fonts } from 'APP/Themes'

export default StyleSheet.create({
  section: {
    marginTop: Metrics.baseMargin * 1.75,
    borderRadius: 20,
  },
  container: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: Metrics.baseMargin * 1.5,
  },
  textContainer: {
    gap: 10,
  },
  title: {
    ...Fonts.style.h4,
    color: Colors.text,
  },
  loading: {
    paddingRight: 10,
    paddingBottom: 11,
  },
  switch: {
    transform: [{ scaleX: 0.7 }, { scaleY: 0.7 }],
    paddingBottom: 10,
  },
  thumb: {
    backgroundColor: Colors.switchThumb,
  },
  track: {
    backgroundColor: Colors.switchOff,
  },
  trackOnDisabled: {
    // Hexadecimal color code for transparency, 50% in this case
    backgroundColor: `${Colors.switchOn}80`,
  },
  trackOffDisabled: {
    // Hexadecimal color code for transparency, 50% in this case
    backgroundColor: `${Colors.switchOff}80`,
  },
  toggleSwitchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  description: {
    color: Colors.textSecondary,
  },
})
