import React from 'react'
import { useDispatch } from 'react-redux'
import PatientActions from 'APP/Redux/PatientRedux'

import Analytics from 'APP/Services/Analytics'

import I18n from 'APP/Services/i18n'
import Typography from 'APP/Converse/Typography'
import styled from 'styled-components/native'

const TouchableContainer = styled.TouchableOpacity`
  align-items: center;
`

interface ChallengedInvitationFooterProps {
  analyticsName?: string
  onPress?(): void
}

const ChallengedInvitationFooter = ({
  analyticsName,
  onPress,
}: ChallengedInvitationFooterProps) => {
  const dispatch = useDispatch()
  const handlePress = () => {
    Analytics.trackEvent('button_click', {
      button_text: 'Skip for now',
      button_value: `${analyticsName}.skipForNow`,
    })

    if (onPress) {
      onPress()
    } else {
      dispatch(PatientActions.challengedInvitationQueuePop())
      dispatch(PatientActions.challengedInvitationQueueNext())
    }
  }
  return (
    <TouchableContainer onPress={handlePress}>
      <Typography>{I18n.t('ChallengedInvitationFooter.body')}</Typography>
      <Typography variant="link" testID="SKIP_FOR_NOW">
        {I18n.t('ChallengedInvitationFooter.skipForNow')}
      </Typography>
    </TouchableContainer>
  )
}

export default ChallengedInvitationFooter
