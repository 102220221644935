import { StyleSheet } from 'react-native'
import { Metrics } from 'APP/Themes'

export default StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    marginTop: Metrics.baseMargin / 2,
    width: '80%',
    marginBottom: Metrics.baseMargin / 2,
  },
  noticeContainer: {
    marginTop: Metrics.baseMargin,
    width: '80%',
    flex: 1,
  },
  noticeText: {
    marginBottom: Metrics.baseMargin,
  },
  divider: {
    flex: 1,
    height: 1,
  },
  dividerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80%',
    marginTop: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin,
  },
  dividerText: {
    marginHorizontal: Metrics.baseMargin,
  },
  subtext: {
    marginTop: Metrics.baseMargin,
    width: '80%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
})
