import React, { useMemo } from 'react'
import { View } from 'react-native'
import { Immutable } from 'immer'

import styled from 'styled-components/native'

import Typography from 'APP/Converse/Typography'

import TimeslotButton from './timeslotButton'
import { AvailabilitySlot } from '@dialogue/timekeeper'

interface Props {
  title: string
  appointments: Immutable<AvailabilitySlot[]>
  selectedAppointment?: AvailabilitySlot | null
  onSelect: (appointment: AvailabilitySlot) => void
}

const Header = styled.View`
  padding-bottom: 12px;
  align-content: center;
`
const SectionContainer = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
`

export const TimeslotSection: React.FC<Props> = ({
  title,
  appointments,
  selectedAppointment,
  onSelect,
  ...rest
}) => {
  const buttons = useMemo(
    () =>
      appointments.map((appointment) => {
        return (
          <TimeslotButton
            key={appointment.start_at}
            onSelect={onSelect}
            appointment={appointment}
            selected={appointment?.start_at === selectedAppointment?.start_at}
          />
        )
      }),
    [appointments, selectedAppointment, onSelect]
  )

  return (
    <View {...rest}>
      <Header>
        <Typography variant="bodyNormalBold" align="center">
          {title}
        </Typography>
      </Header>
      <SectionContainer>{buttons}</SectionContainer>
    </View>
  )
}

export default TimeslotSection
