//Api
import { hasHardwareAsync, isEnrolledAsync, authenticateAsync } from 'expo-local-authentication'

// I18n
import I18n from 'APP/Services/i18n'

export const APP_LOCK_STATE = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
  SUPPORTED: 'supported',
  NOT_SUPPORTED: 'notSupported',
  NOT_ENROLLED: 'notEnrolled',
  ENABLED_BLOCKED: 'enabledBlocked',
}

export async function biometricsAvailable() {
  try {
    const hardware = await hasHardwareAsync()
    const enrolled = await isEnrolledAsync()
    return hardware && enrolled
  } catch (ex) {
    console.log(`biometricsAvailable rejection`, ex)
    return false
  }
}

export async function biometricsState() {
  try {
    const hardware = await hasHardwareAsync()
    const enrolled = await isEnrolledAsync()
    if (hardware && enrolled) {
      return APP_LOCK_STATE.SUPPORTED
    } else if (!hardware) {
      return APP_LOCK_STATE.NOT_SUPPORTED
    } else {
      return APP_LOCK_STATE.NOT_ENROLLED
    }
  } catch (ex) {
    console.log(`biometricsAvailable rejection`, ex)
    return false
  }
}

export async function triggerBiometrics() {
  try {
    return await authenticateAsync({
      promptMessage: I18n.t('AppLock.promptMessage'),
      cancelLabel: I18n.t('AppLock.cancelMessage'),
      disableDeviceFallback: false,
    })
  } catch (ex) {
    console.log(`triggerBiometrics rejection`, ex)
    return false
  }
}
