import React, { useMemo } from 'react'
import { View, Text, TouchableOpacity } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Images, Colors } from 'APP/Themes'
import { useSelector } from 'react-redux'
import Analytics from 'APP/Services/Analytics'

import { shouldRenderComponent } from 'APP/Lib/ContentHelpers'

// Styling
import Styles from './style'

const QuickActions = ({ appComponent, style }) => {
  const navigation = useNavigation()
  const eligibleServices = useSelector((state) => state?.patient?.profile?.eligibleServices)

  const actions = appComponent?.data?.actions

  const normalizedActions = useMemo(
    () =>
      actions
        ?.reduce((filteredActions, action) => {
          if (shouldRenderComponent(action?.service_filter, eligibleServices)) {
            filteredActions.push({
              name: action.name,
              title: action.title,
              iconName: action.icon_name,
              handlePress: () => {
                Analytics.trackEvent(
                  'button_click',
                  {
                    button_text: 'quick_action',
                    button_value: action.name,
                  },
                  ['quick_action']
                )
                navigation.navigate(action.screen_name, {
                  tabName: action.tab_name, // Optional tab_name parameter
                })
              },
            })
          }

          return filteredActions
        }, [])
        ?.slice(0, 3),
    [actions, eligibleServices]
  )
  if (!normalizedActions || normalizedActions.length === 0) {
    return null
  }

  return (
    <View style={[Styles.quickActionsContainer, style]}>
      {normalizedActions?.map((quickAction, index) => (
        <QuickAction
          key={index}
          title={quickAction.title}
          icon={quickAction.iconName}
          onPress={quickAction.handlePress}
        />
      ))}
    </View>
  )
}

const QuickAction = ({ title, icon, onPress }) => {
  const Icon = Images[icon]
  return (
    <TouchableOpacity style={Styles.quickAction} onPress={onPress} testID="quickAction">
      {icon && (
        <View style={Styles.iconWrapper}>
          <Icon fillLine={Colors.accent} />
        </View>
      )}
      <View style={Styles.titleWrapper}>
        <Text style={Styles.title}>{title}</Text>
      </View>
    </TouchableOpacity>
  )
}

export default QuickActions
