import moment from 'moment'
import { EmeraldTypes } from '@dialogue/services'
import { type AppointmentBookingProps } from '.'

export const enum BOOKING_FLOW {
  TIME_BASED = 'time_based',
  PROVIDER_BASED = 'provider_based',
}

export const PROVIDER_SELECTION_FEATURE_NAME = 'provider_selection'

export enum PARTS_OF_DAY {
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
  EVENING = 'evening',
}

export enum PART_OF_DAY_END_HOUR {
  MORNING = 12,
  AFTERNOON = 17,
  EVENING = 24,
}

// used in TimeAppointmentBooking (TimeBased) and timeslotSelection
export const TEST_APPOINTMENT_BOOKING_STATE = {
  availabilities: {
    [moment().format('MM/DD/YYYY')]: {
      times: {
        morning: [
          {
            start_at: moment().add(1, 'day').add(1, 'hour').add(15, 'minutes').toISOString(),
            end_at: moment().add(1, 'day').add(1, 'hour').add(30, 'minutes').toISOString(),
            provider_id: 1,
          },
        ],
        afternoon: [],
        evening: [],
      },
      isLoading: false,
      hasAvailabilities: true,
    },
  },
  selectedAppointment: null,
  selectedDay: moment().format('MM/DD/YYYY'),
  bookAppointmentRequest: {
    isLoading: false,
    data: null,
    error: null,
  },
}

// used in TimeAppointmentBooking (TimeBased) and timeslotSelection
export const TEST_APPOINTMENT_DEFAULT_PROPS: AppointmentBookingProps = {
  appointment_type: 'pc_rn_fu',
  userLocation: 'not shared',
  id: 'selfScheduling',
  appId: 'selfScheduling',
  displayMode: 'drawer',
  post: {
    id: 'xsbs1r1co7r3bk4gjyitbngboh',
    create_at: 1719945710728,
    update_at: 1719945710728,
    edit_at: 0,
    delete_at: 0,
    is_pinned: false,
    user_id: 'u0000000000000000000000403',
    channel_id: 'test-channel-id',
    root_id: '',
    parent_id: '',
    original_id: '',
    message: 'Select a timeslot',
    type: '',
    props: {
      microapp: {
        appointment_id: '-1234',
        appointment_type: EmeraldTypes.ApptType.APPT,
        date_from: moment().toISOString(),
        date_until: moment().add(7, 'days').toISOString(),
        dia_provider_id: '12345678',
        displayed_on: 'last_message',
        episode_title: 'Homers Episode Title',
        id: 'selfScheduling',
        is_rescheduling: true,
        patient_id: '87654321',
        provider_id: 1,
        requested_slot: 'booking_appointment_selected_with_micro_app',
      },
      patient_visible: true,
      practitioner_visible: true,
      sender: 'rasa',
      type: 'microAppCta.v1',
      update_channel_state: false,
      utterance: 'utter_ask_booking_appointment_selected_with_micro_app',
    },
    hashtags: '',
    pending_post_id: '',
    actionType: 'none',
  },
  userId: 'u0000000000000000000000403',
  messageFormat: 'mattermost',
}
