import styled, { DefaultTheme } from 'styled-components/native'

interface SpinnerProps {
  theme: DefaultTheme
  color?: keyof DefaultTheme['colors']
}

const StyledSpinner = styled.ActivityIndicator.attrs(({ theme, color }: SpinnerProps) => ({
  color: theme.colors?.[color! || 'primary'],
}))<SpinnerProps>`
  justify-content: center;
  align-items: center;
`

export default StyledSpinner
