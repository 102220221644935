import React, { useCallback } from 'react'
import I18n from 'APP/Services/i18n'
import styled from 'styled-components/native'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import moment from 'moment'
import { useDispatch } from 'react-redux'

import { Colors, Metrics } from 'APP/Themes'
import Button from 'APP/Converse/Button'
import Typography from 'APP/Converse/Typography'
import { DATE_FORMAT, providerBookingActions } from 'APP/Store/ProviderBooking'

import { DateTimeInfo } from '../utils/types'

const Wrapper = styled.View`
  align-items: center;
  padding-top: 16px;
`

const StyledButton = styled(Button)`
  align-self: center;
  margin-top: 20px;
  margin-bottom: 12px;
`

const Copy = styled(Typography)`
  padding: 24px 0 18px;
`

interface Props {
  onQuit: () => void
  hasNextAvailability?: boolean
  nextAvailability?: DateTimeInfo
}

export const NoAvailableSlots: React.FC<Props> = ({
  onQuit,
  hasNextAvailability = false,
  nextAvailability,
  ...rest
}) => {
  const dispatch = useDispatch()

  const handleGoToNextAvailability = useCallback(() => {
    if (nextAvailability) {
      const formattedDate = moment(nextAvailability.start_date).format(DATE_FORMAT)
      dispatch(providerBookingActions.selectDay(formattedDate))
    }
  }, [nextAvailability, dispatch])

  const dateFormat = I18n.t('AppointmentBooking.date_available', {
    defaultValue: 'dddd, MMMM D [at] h:mm A',
  })
  const formattedNextAvailability = nextAvailability
    ? moment(`${nextAvailability?.start_date}T${nextAvailability?.start_time}`).format(dateFormat)
    : ''

  return (
    <Wrapper {...rest}>
      {hasNextAvailability ? (
        <>
          <Typography variant="bodyNormal">
            {I18n.t('AppointmentBooking.next_availability')}
          </Typography>
          <Copy variant="subheaderBold">{formattedNextAvailability}</Copy>
          <StyledButton
            title={I18n.t('AppointmentBooking.go_to_next_available').toUpperCase()}
            variant="primary"
            widthVariant="content"
            onPress={handleGoToNextAvailability}
          />
        </>
      ) : (
        <>
          <MaterialIcon
            name="block"
            size={Metrics.icons.small}
            color={Colors.buttonSecondaryDisabled}
          />
          <Copy variant="bodyNormal" align="center">
            {I18n.t('AppointmentBooking.no_availability')}
          </Copy>
        </>
      )}
      <Button
        onPress={onQuit}
        title={I18n.t('AppointmentBooking.close') || undefined}
        variant="tertiary"
        widthVariant="full"
        analyticsName="appointmentBooking.noAvailability.closeButton"
      />
    </Wrapper>
  )
}
