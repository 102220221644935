import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  container: {
    flex: 1,
    ...ApplicationStyles.screen.navContainerBottomSpace,
    backgroundColor: Colors.appBg,
    paddingTop: 0,
    paddingBottom: 0,
  },
  noticeContainer: {
    backgroundColor: Colors.appBg,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 0,
    paddingBottom: 0,
  },
  navBarContainer: {
    paddingTop: Metrics.statusBarHeight,
    borderBottomWidth: 1,
    borderBottomColor: Colors.elementsBorder,
  },
  viewContainer: {
    minHeight: Metrics.baseMargin,
    flex: 1,
  },
})
