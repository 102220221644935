import { StyleSheet } from 'react-native'
import { ApplicationStyles, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  centeredContainer: {
    alignItems: 'center',
    paddingHorizontal: Metrics.baseMargin,
  },
  spacedColumnContainer: {
    flexDirection: 'column',
    marginTop: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin * 2,
  },
  buttonContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    marginHorizontal: Metrics.baseMargin * 2,
  },
  button: {
    marginBottom: Metrics.baseMargin / 2,
  },
  spacedRowContainer: {
    flexDirection: 'row',
  },
  avatarContainer: {
    marginTop: Metrics.baseMargin * 2,
    marginBottom: Metrics.baseMargin,
    borderRadius: 60,
    zIndex: 0,
  },
  roundImage: {
    width: 120,
    height: 120,
    borderRadius: 60,
  },
})
