import React, { useEffect } from 'react'
// eslint-disable-next-line no-restricted-imports
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import styled from 'styled-components/native'

// Components
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import { ResponsiveScrollView } from 'APP/Converse/Layout'
import { ServiceGroupScreenIcon } from './ServiceGroupScreenIcon'
import { EmergencyServicesWarning } from './EmergencyServicesWarning'
import { ServiceButtonsContainer } from './ServiceButtonsContainer'
import { PrivacyPolicyGroupCard } from './PrivacyPolicyGroupCard'
import { ScopeOfPracticeGroupCard } from './ScopeOfPracticeGroupCard'
import { ContactInfoGroupCard } from './ContactInfoGroupCard'

// Services
import Analytics from 'APP/Services/Analytics'

// Styling
import Typography from 'APP/Converse/Typography'
import {
  determineContactInfo,
  determineExternalProvider,
  shouldRenderService,
} from 'APP/Lib/ContentHelpers'

import { getAnalyticsScreenName } from './utils'

const HeaderContainer = styled(View)`
  padding-top: ${({ theme }) => theme.metrics.baseMargin * 2}px;
  padding-bottom: ${({ theme }) => theme.metrics.baseMargin * 2}px;
`

const TitleContainer = styled(Typography)`
  margin-horizontal: ${({ theme }) => theme.metrics.baseMargin * 2}px;
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin / 2}px;
`

const SubtitleContainer = styled(Typography)`
  margin-horizontal: ${({ theme }) => theme.metrics.baseMargin * 2}px;
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin * 2}px;
  margin-top: ${({ theme }) => theme.metrics.baseMargin / 2}px;
`

const ServiceGroup = (props) => {
  const { route } = props
  const { id } = { ...route?.params, ...props }
  const serviceGroups = useSelector((state) => state.content.serviceGroups)
  const profile = useSelector((state) => state.patient.profile)
  const serviceGroup = serviceGroups.data
    ?.flatMap((item) => item.serviceGroups)
    .find((service) => service.id === id)

  const services = serviceGroup.services.filter((service) =>
    shouldRenderService(service, profile?.eligibleServices, profile?.healthResources)
  )

  const hasExternalProvider = determineExternalProvider(services)
  const contactInfo = determineContactInfo(services)
  const analyticsScreenName = getAnalyticsScreenName(serviceGroup)

  useEffect(() => {
    Analytics.trackScreen(analyticsScreenName)
  }, [analyticsScreenName])

  return (
    <ResponsiveScrollView>
      <NoticeBarContainer>
        <HeaderContainer>
          <ServiceGroupScreenIcon
            serviceGroup={serviceGroup}
            hasExternalProvider={hasExternalProvider}
          />
          <TitleContainer variant="h1">{serviceGroup?.title}</TitleContainer>
          <SubtitleContainer>
            <Typography variant="bodyLarge">{serviceGroup?.subtitle}</Typography>
          </SubtitleContainer>
          {serviceGroup?.showEmergencyBanner && <EmergencyServicesWarning />}
          {services?.length > 0 && (
            <ServiceButtonsContainer
              services={services}
              hasExternalProvider={hasExternalProvider}
              serviceGroup={serviceGroup}
            />
          )}
          {serviceGroup?.scopeOfPractice && (
            <ScopeOfPracticeGroupCard serviceGroup={serviceGroup} />
          )}
          {serviceGroup?.showPrivacyPolicy && (
            <PrivacyPolicyGroupCard serviceGroup={serviceGroup} />
          )}
          {contactInfo && (
            <ContactInfoGroupCard serviceGroup={serviceGroup} contactInfo={contactInfo} />
          )}
        </HeaderContainer>
      </NoticeBarContainer>
    </ResponsiveScrollView>
  )
}

export default ServiceGroup
