import React from 'react'
import { Linking, Image, View } from 'react-native'
import styled from 'styled-components/native'

import I18n from 'APP/Services/i18n'
import DeviceInfo from 'APP/Helpers/DeviceInfo'
import { isAndroid, isIOS } from 'APP/Helpers/checkPlatform'

import Typography from 'APP/Converse/Typography'
import Button from 'APP/Converse/Button'
import { Images } from 'APP/Themes'

const Container = styled(View)`
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.metrics.baseMargin}px;
`

const Title = styled(Typography)`
  text-align: center;
`

const Subheader = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin * 2}px;
  text-align: center;
`

const PushNotificationPermissionModalScreen = ({ navigation }) => {
  const onDismiss = () => navigation.goBack()
  const onAllow = () => {
    if (isAndroid()) {
      Linking.sendIntent('android.settings.APP_NOTIFICATION_SETTINGS', [
        {
          key: 'android.provider.extra.APP_PACKAGE',
          value: DeviceInfo.getBundleId(),
        },
      ])
    } else if (isIOS()) {
      Linking.openSettings()
    }

    navigation.goBack()
  }

  return (
    <Container>
      <Image source={Images.notificationsExample} />
      <Title variant="h1">{I18n.t('PushNotificationPermissionModalScreen.title')}</Title>
      <Subheader variant="subheader">
        {I18n.t('PushNotificationPermissionModalScreen.subheader')}
      </Subheader>

      <Button
        variant="primary"
        widthVariant="full"
        title={I18n.t('PushNotificationPermissionModalScreen.primaryButton')}
        onPress={onAllow}
        testID={'allow-button'}
        analyticsName="allow-button"
      />
      <Button
        variant="tertiary"
        widthVariant="full"
        title={I18n.t('PushNotificationPermissionModalScreen.secondaryButton')}
        onPress={onDismiss}
        testID={'dismiss-button'}
        analyticsName="dismiss-button"
      />
    </Container>
  )
}

export default PushNotificationPermissionModalScreen
