import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Fonts } from '../../Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  scrollContainer: {
    minHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  background: {
    backgroundColor: Colors.appBg,
    opacity: 0.4,
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  title: {
    ...Fonts.style.h2,
    color: Colors.text,
    paddingTop: 15,
    paddingBottom: 15,
  },
  subtitle: {
    ...Fonts.style.body,
    color: Colors.text,
    textAlign: 'center',
  },
})
