import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import { ActiveMinutes } from '@dialogue/services'

export const CHALLENGE_ACTIVITY_ICONS = {
  [ActiveMinutes.Types.ActivityType.CYCLING]: {
    Lib: MaterialIcon,
    name: 'directions-bike',
  },
  [ActiveMinutes.Types.ActivityType.RUNNING]: {
    Lib: MaterialIcon,
    name: 'directions-run',
  },
  [ActiveMinutes.Types.ActivityType.SWIMMING]: {
    Lib: MaterialCommunityIcon,
    name: 'swim',
  },
  [ActiveMinutes.Types.ActivityType.STEPS]: {
    Lib: MaterialIcon,
    name: 'directions-walk',
  },
}
