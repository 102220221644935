import { StyleSheet } from 'react-native'

import { Colors, Fonts, Metrics } from 'APP/Themes'

const diaLogo = {
  minWidth: 25,
  minHeight: 25,
  height: 25,
  width: 25,
  resizeMode: 'contain',
}

export default StyleSheet.create({
  clickableContainer: {
    width: '100%',
  },
  carePlanContainer: {
    flexDirection: 'row',
    padding: Metrics.baseMargin,
    alignItems: 'center',
    backgroundColor: Colors.elementsBg,
  },
  carePlanLeft: {
    flex: 1,
    flexDirection: 'row',
  },
  carePlanInfo: {
    flex: 1,
    paddingHorizontal: 12,
  },
  carePlanReason: {
    ...Fonts.style.h4,
    color: Colors.text,
  },
  carePlanFrom: {
    ...Fonts.style.body,
    color: Colors.text,
    marginTop: 4,
  },
  carePlanTimestamp: {
    ...Fonts.style.caption,
    color: Colors.textSecondary,
    paddingBottom: 6,
  },
  carePlanMetadata: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  diaLogo,
  avatar: {
    ...diaLogo,
    borderRadius: 25,
  },
})
