import React, { useState, useEffect } from 'react'
import { Linking } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'

// i18n
import I18n from 'APP/Services/i18n'

// Actions
import LoginActions from 'APP/Redux/LoginRedux'

// Components
import Modal from 'APP/Components/Modal'
import SignupLogoHeader from 'APP/Components/SignupLogoHeader'
import Alert from 'APP/Converse/Alert'
import Button from 'APP/Converse/Button'
import Typography from 'APP/Converse/Typography'
import { ResponsiveScrollView, ResponsiveView } from 'APP/Converse/Layout'
import usePrevious from 'APP/Hooks/usePrevious'

//Config
import Config from 'APP/Config'

// Services
import UrlBuilder from 'APP/Services/UrlBuilder'

// Styles
import Styles from './style'

const VerifyEmailHelpScreen = () => {
  const dispatch = useDispatch()
  const [showEmailSentModal, setShowEmailSentModal] = useState(false)
  const [showGenericErrorModal, setShowGenericErrorModal] = useState(false)

  const { email } = useSelector((state) => state.login?.customClaims)
  const { emailVerificationResendRunning, emailVerificationResendFailed } = useSelector(
    (state) => state.login
  )

  const previousEmailVerificationResendRunning = usePrevious(emailVerificationResendRunning)
  useEffect(() => {
    if (previousEmailVerificationResendRunning && !emailVerificationResendRunning) {
      if (emailVerificationResendFailed) {
        setShowGenericErrorModal(true)
      } else {
        setShowEmailSentModal(true)
      }
    }
  }, [
    previousEmailVerificationResendRunning,
    emailVerificationResendRunning,
    emailVerificationResendFailed,
  ])

  const handlePressContactSupport = () => {
    const url = UrlBuilder.supportEmailUrl()
    if (Linking.canOpenURL(url)) {
      Linking.openURL(url).catch(() => {
        Alert.alert(I18n.t('CannotOpenEmail.title'), I18n.t('CannotOpenEmail.body'), [
          { text: I18n.t('CannotOpenEmail.ok') },
        ])
      })
    }
  }

  const renderEmailSentModalBody = () => {
    return (
      <Typography variant="bodyNormal">
        {I18n.t('VerifyEmailScreen.emailSentModal.body')}
        <Typography variant="bodyNormalBold"> {email}.</Typography>
      </Typography>
    )
  }

  const renderFooter = () => {
    return (
      <Typography variant="bodySmall" style={Styles.footer}>
        {I18n.t('VerifyEmailHelpScreen.contactUs')}
        {'\n'}
        <Typography
          variant="bodySmall"
          style={Styles.smallLink}
          onPress={handlePressContactSupport}
        >
          {Config.SUPPORT_EMAIL[I18n.locale]}
        </Typography>
      </Typography>
    )
  }

  return (
    <ResponsiveScrollView contentContainerStyle={Styles.scrollContainer} footer={renderFooter()}>
      <ResponsiveView style={Styles.container}>
        <SignupLogoHeader
          title={I18n.t('VerifyEmailHelpScreen.title')}
          subtitle={I18n.t('VerifyEmailHelpScreen.subtitle')}
        />

        <Button
          testID="emailVerificationResendRequestBtn"
          widthVariant="full"
          variant="primary"
          onPress={() => dispatch(LoginActions.emailVerificationResendRequest())}
          title={I18n.t('VerifyEmailHelpScreen.resendEmailVerification')}
        />

        <Button
          variant="secondary"
          widthVariant="full"
          onPress={() => dispatch(LoginActions.logout('Log out from verify email screen'))}
          title={I18n.t('AccountScreen.signOut')}
          testID="logoutBtn"
          textColor="error"
          lineColor="error"
        />
      </ResponsiveView>
      <Modal
        statusBarTranslucent
        visible={showEmailSentModal}
        transparent
        title={I18n.t('VerifyEmailScreen.emailSentModal.title')}
        subtitle={renderEmailSentModalBody()}
        primaryActionText={I18n.t('VerifyEmailScreen.emailSentModal.action')}
        handleCloseModal={() => setShowEmailSentModal(false)}
        handlePrimaryAction={() => setShowEmailSentModal(false)}
      />
      <Modal
        statusBarTranslucent
        visible={showGenericErrorModal}
        transparent
        title={I18n.t('VerifyEmailScreen.error.generic.title')}
        subtitle={I18n.t('VerifyEmailScreen.error.generic.body')}
        primaryActionText={I18n.t('VerifyEmailScreen.error.generic.action')}
        handleCloseModal={() => setShowGenericErrorModal(false)}
        handlePrimaryAction={() => setShowGenericErrorModal(false)}
      />
    </ResponsiveScrollView>
  )
}

export default VerifyEmailHelpScreen
