import { ApplicationStyles, Colors, Metrics } from 'APP/Themes'

export default {
  borderEpisode: {
    borderTopWidth: 1,
    borderColor: Colors.selectedBg,
  },
  episodesContainer: {
    paddingTop: Metrics.baseMargin,
    paddingVertical: 0,
    marginBottom: Metrics.baseMargin,
  },
  headerContainer: {
    flexDirection: 'row',
    paddingHorizontal: Metrics.baseMargin * 1.5,
    marginBottom: Metrics.baseMargin,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listContainer: {
    marginHorizontal: Metrics.baseMargin,
    backgroundColor: Colors.elementsBg,
    ...ApplicationStyles.softBorder,
    borderRadius: Metrics.baseMargin,
    ...ApplicationStyles.normalShadow,
  },
}
