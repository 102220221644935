import { StyleSheet } from 'react-native'
import { ApplicationStyles, Metrics, Colors } from 'APP/Themes'
import { isWeb } from 'APP/Helpers/checkPlatform'

export default StyleSheet.create({
  cardContainer: {
    marginTop: Metrics.baseMargin * 0.5,
    display: 'flex',
    borderRadius: Metrics.baseMargin,
    backgroundColor: Colors.elementsBg,
    ...ApplicationStyles.shadow,
  },
  appointmentContainer: {
    flexDirection: 'row',
  },
  dateContainer: {
    justifyContent: 'center',
    backgroundColor: Colors.appointmentDateElement,
    borderTopLeftRadius: Metrics.baseMargin,
    borderBottomLeftRadius: Metrics.baseMargin,
  },
  dateDay: {
    padding: Metrics.baseMargin,
    textAlign: 'center',
  },
  appointmentDetailContainer: {
    flex: 1,
    margin: Metrics.baseMargin,
  },
  timeContainer: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    marginBottom: Metrics.baseMargin / 1.5,
    // gap: Metrics.baseMargin / 2,
  },
  time: {
    marginLeft: Metrics.baseMargin / 2,
  },
  child: {
    backgroundColor: Colors.tagBg,
    borderRadius: 10,
    overflow: 'hidden',
    paddingTop: 4,
    paddingRight: 8,
    paddingBottom: 4,
    paddingLeft: 8,
    marginLeft: 8,
  },
  practitionerContainer: {
    flexDirection: 'row',
  },
  practitionerInnerContainer: {
    flex: 1,
    marginLeft: Metrics.baseMargin,
    alignSelf: 'center',
    gap: Metrics.baseMargin / 4,
  },
  practitionerAvatar: {
    ...Metrics.avatar,
    minWidth: 40,
    minHeight: 40,
    resizeMode: 'contain',
    borderRadius: 40,
  },
  buttonContainer: {
    marginTop: Metrics.baseMargin * 1.5,
    marginBottom: Metrics.baseMargin / 2,
  },
  button: {
    marginHorizontal: 0,
    marginVertical: 0,
  },
  bottomModal: {
    alignItems: 'center',
  },
  bottomModalContainer: {
    paddingHorizontal: Metrics.baseMargin * 2,
    paddingBottom: Metrics.bottomSpace || Metrics.baseMargin,
    alignItems: 'center',
    maxWidth: 550,
  },
  bottomModalDescription: {
    backgroundColor: Colors.bannerBg,
    borderRadius: Metrics.baseMargin,
    padding: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin,
  },
  descriptionTitleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
  },
  descriptionTitle: {
    display: 'flex',
    paddingTop: Metrics.baseMargin / 4,
    paddingBottom: Metrics.baseMargin / 4,
  },
  description: {
    paddingLeft: isWeb() ? Metrics.baseMargin * 2 : Metrics.baseMargin * 1.5,
    lineHeight: Metrics.baseMargin * 1.5,
  },
  descriptionIcon: {
    paddingRight: isWeb() ? Metrics.baseMargin : Metrics.baseMargin / 2,
  },
})
