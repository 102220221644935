import { StyleSheet } from 'react-native'
import { Colors } from 'APP/Themes'

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.appBg,
  },
  flex: {
    flex: 1,
  },
  gradient: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 10,
  },
})
