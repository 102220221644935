import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import { WellnessCenter } from '@dialogue/services'
import I18n from 'APP/Services/i18n'

const { Challenges } = WellnessCenter

export const CHALLENGE_ACTIVITY_ICONS = {
  [Challenges.Types.ActivityName.CYCLING]: {
    Lib: MaterialIcon,
    name: 'directions-bike',
  },
  [Challenges.Types.ActivityName.RUNNING]: {
    Lib: MaterialIcon,
    name: 'directions-run',
  },
  [Challenges.Types.ActivityName.SWIMMING]: {
    Lib: MaterialCommunityIcon,
    name: 'swim',
  },
  [Challenges.Types.ActivityName.WALKING]: {
    Lib: MaterialIcon,
    name: 'directions-walk',
  },
  [Challenges.Types.ActivityName.STEPS]: {
    Lib: MaterialIcon,
    name: 'directions-walk',
  },
}

export const CHALLENGE_TYPE_ICONS = {
  [Challenges.Types.ChallengeType.INDIVIDUAL]: {
    Lib: MaterialIcon,
    name: 'person',
  },
  [Challenges.Types.ChallengeType.TEAM]: {
    Lib: MaterialIcon,
    name: 'group',
  },
}

export const goalMetric = (status, metric, showTotal, isTeam) => {
  if (!metric || status === Challenges.Types.ChallengeStatus.UPCOMING) {
    return null
  }

  if (showTotal) {
    return I18n.t(
      `WellnessCenter.challenges.metric.${metric}${(isTeam && 'Team') || ''}Total`,
      I18n.t(`WellnessCenter.challenges.metric.${metric}Total`)
    )
  }

  return I18n.t(`WellnessCenter.challenges.metric.${metric}`)
}

const ORG_TAG = 'org:'
const PLAN_ID = 'plan_id_'

export const challengeOrganizationName = (challengeTags, memberEligibilityIntervals) => {
  // If user has only 1 eligibility record, cancel out
  if ((memberEligibilityIntervals || []).filter((e) => e.status === 'active').length <= 1) {
    return null
  }

  const challengeOrganization = (challengeTags || []).find((tag) => tag.includes(ORG_TAG))

  // If specific challenge has no org specified, cancel out
  if (!challengeOrganization) {
    return null
  }

  // Return localized organization name using the challenge org tag
  const relevantPlan = (memberEligibilityIntervals || []).find(
    (e) =>
      e.status === 'active' &&
      e.planId.toString() === challengeOrganization.split(`${ORG_TAG}${PLAN_ID}`)[1]
  )

  return relevantPlan?.eligibilityRecord?.organization.displayName[I18n.baseLocale]
}
