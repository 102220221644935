import { StyleSheet } from 'react-native'
import { Metrics, Colors, Fonts } from 'APP/Themes'

export default StyleSheet.create({
  label: {
    flexShrink: 0,
    alignSelf: 'center',
    ...Fonts.style.subheader,
    color: Colors.text,
    paddingTop: Metrics.baseMargin * 1.5,
  },
})
