import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Fonts, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  content: {
    marginHorizontal: Metrics.baseMargin,
  },
  splashLogo: {
    resizeMode: 'contain',
    alignSelf: 'baseline',
    marginLeft: Metrics.baseMargin,
    marginTop: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin,
    height: 45,
  },
  title: {
    ...Fonts.style.h1,
    color: Colors.text,
    marginHorizontal: Metrics.baseMargin,
    paddingBottom: 10,
  },
  subtitle: {
    ...Fonts.style.body,
    color: Colors.text,
    lineHeight: 21,
    marginHorizontal: Metrics.baseMargin,
  },
  textInput: {
    ...ApplicationStyles.screen.textInput,
  },
  formInputContainer: {
    ...ApplicationStyles.screen.formInputContainer,
    marginTop: 0,
    marginHorizontal: Metrics.baseMargin / 2,
  },
  listContainer: {
    marginTop: Metrics.baseMargin,
    marginHorizontal: Metrics.baseMargin,
  },
})
