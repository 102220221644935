import Config from 'APP/Config'
import { Images } from 'APP/Themes'

export const getCtaAction = (ctaId) =>
  (Config.MICROAPPS_CONFIG && Config.MICROAPPS_CONFIG[ctaId]) || {}

export const getCtaIcon = (ctaId) => {
  return Images.homeCta[ctaId] || Images.logoBlue
}

export const getServiceCategoryIcon = (iconName) => {
  return Images.serviceCategories[iconName] || Images.logoBlue
}

export const getServiceGroupIcon = (iconName) => {
  return Images.serviceGroups[iconName]
}
