import { StyleSheet } from 'react-native'
import { Metrics } from 'APP/Themes'

export default StyleSheet.create({
  scrollContainer: {
    minHeight: '100%',
  },
  container: {
    display: 'flex',
    width: '100%',
    alignSelf: 'center',
    paddingHorizontal: Metrics.baseMargin * 1.5,
    gap: Metrics.baseMargin / 2,
  },
  section: {
    paddingBottom: Metrics.baseMargin * 1.5,
    gap: Metrics.baseMargin * 0.5,
  },
  divider: {
    paddingTop: 24,
  },
})
