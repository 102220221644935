import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components/native'
import { SectionList } from 'react-native'

import { AvailabilitySlot } from '@dialogue/timekeeper'

import Typography from 'APP/Converse/Typography'
import Button from 'APP/Converse/Button'
import I18n from 'APP/Services/i18n'

import { ProviderProfileModal } from './profile/ProviderProfileModal'
import { ProviderButton } from './providerButton'
import { PARTS_OF_DAY } from '../constants'
import Analytics from 'APP/Services/Analytics'

const ListWrapper = styled.View`
  margin: ${({ theme }) => theme.metrics.baseMargin / 2}px 0;
`

const SectionLabel = styled(Typography)`
  margin: ${({ theme }) => theme.metrics.baseMargin / 2}px 0;
`

const ProviderButtonStyled = styled(ProviderButton)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin}px;
`

interface ProviderSection {
  title: string
  data: AvailabilitySlot[]
}

export interface Props {
  slots: Record<PARTS_OF_DAY, AvailabilitySlot[]>
  onConfirm: (slot: AvailabilitySlot | null) => void
  onQuit: () => void
}

export const ProviderSectionList = ({ slots, onConfirm, onQuit }: Props) => {
  const [activeTimeslot, setActiveTimeslot] = useState<AvailabilitySlot | null>(null)
  const sections: ProviderSection[] = useMemo(
    () =>
      Object.entries(slots || {}).reduce((acc, curr) => {
        const [partOfDay, availabilities] = curr
        if (availabilities.length > 0) {
          acc.push({
            title: I18n.t(`AppointmentBooking.${partOfDay.toLowerCase()}`),
            data: availabilities,
          })
        }
        return acc
      }, [] as ProviderSection[]),
    [slots]
  )

  const handlePress = useCallback((item: AvailabilitySlot) => {
    setActiveTimeslot(item)
    Analytics.trackEvent('button_click', {
      button_value: 'provider_card',
    })
  }, [])

  const handleCloseModal = useCallback(() => {
    setActiveTimeslot(null)
  }, [])

  return (
    <ListWrapper>
      <SectionList
        sections={sections}
        keyExtractor={(item) => item.provider_id.toString()}
        renderItem={({ item }) => (
          <ProviderButtonStyled
            providerId={item.provider_id}
            startAt={item.start_at}
            endAt={item.end_at}
            attributes={item.provider_matching_attributes}
            onPress={() => handlePress(item)}
          />
        )}
        renderSectionHeader={({ section: { title } }) => (
          <SectionLabel variant="subheader">{title}</SectionLabel>
        )}
      />
      <Button
        variant="tertiary"
        widthVariant="full"
        title={I18n.t('AppointmentBooking.none_of_these_work') || undefined}
        onPress={onQuit}
        analyticsName="providerBooking.nonOfTheseWork"
        small={false}
      />
      <ProviderProfileModal
        baseChosenTimeslot={activeTimeslot}
        onConfirm={onConfirm}
        onClose={handleCloseModal}
        withAvailabilitySelection
      />
    </ListWrapper>
  )
}
