export const PERMISSIONS = {}
export const RESULTS = {}
export const openLimitedPhotoLibraryPicker = async () => {}
export const openSettings = async () => {}
export const check = async () => {}
export const request = async () => {}
export const checkLocationAccuracy = async () => {}
export const requestLocationAccuracy = async () => {}
export const checkNotifications = async () => {}
export const requestNotifications = async () => {}
export const checkMultiple = async () => {}
export const requestMultiple = async () => {}

export default {
  PERMISSIONS,
  RESULTS,
  check,
  checkLocationAccuracy,
  checkMultiple,
  checkNotifications,
  openLimitedPhotoLibraryPicker,
  openSettings,
  request,
  requestLocationAccuracy,
  requestMultiple,
  requestNotifications,
}
