import { Appearance } from 'react-native-appearance'
import { debounce } from 'lodash'
import { StatusBar } from 'react-native'

// For maping react-navigation statusBarStyle values to react-native StatusBar styles,
// so we don't need to reconfigure when we update expo and remove all of this.
const statusBarStyleMap = {
  dark: 'dark-content',
  light: 'light-content',
}

const defaultStyle = Appearance.getColorScheme() === 'dark' ? 'light-content' : 'dark-content'

export const createStatusBarStyleSetter = () => {
  let lastStyle = null

  const setStatusBarStyle = (style) => {
    const newStyle = statusBarStyleMap[style] || defaultStyle
    if (newStyle !== lastStyle) {
      StatusBar.setBarStyle(newStyle, true)
      lastStyle = newStyle
    }
  }

  return debounce(setStatusBarStyle, 100)
}
