import React from 'react'
import { openInbox } from 'react-native-email-link'
import { useSelector } from 'react-redux'

import { PrimaryButton, TertiaryButton } from 'APP/Components/Buttons'
import ChallengedInvitationFooter from 'APP/Components/ChallengedInvitationFooter'

import I18n from 'APP/Services/i18n'

import Config from 'APP/Config'
import { getBrandConfig } from 'APP/Lib/ConfigHelpers'
import { ResponsiveView } from 'APP/Converse/Layout'
import { handlePressContactSupport } from 'APP/Helpers/handlePressContactSupport'
import SignupLogoHeader from 'APP/Components/SignupLogoHeader'
import styled from 'styled-components/native'
import { Trans } from 'react-i18next'
import Typography from 'APP/Converse/Typography'

const Page = styled(ResponsiveView)`
  flex: 1;
  padding: ${({
    theme: {
      metrics: { baseMargin },
    },
  }) => `0 ${baseMargin}px ${baseMargin}px`};
`

const CheckEmailScreen = ({ route }) => {
  const { analyticsName, email } = route?.params || {}

  const { challengedInvitationQueue } = useSelector((state) => state.patient)

  const isInChallengedQueue = (challengedInvitationQueue ?? []).length > 0

  const { productName } = getBrandConfig(Config.BRAND_ID, I18n.locale)

  const subtitle = (
    <Trans i18n={I18n} i18nKey="CheckEmailScreen.subtitle" values={{ productName, email }}>
      <Typography variant="subheaderBold">{{ productName }}</Typography>
      <Typography variant="subheaderBold">{{ email }}</Typography>
    </Trans>
  )

  return (
    <Page>
      <SignupLogoHeader title={I18n.t('CheckEmailScreen.title')} subtitle={subtitle} />
      <PrimaryButton
        title={I18n.t('CheckEmailScreen.openEmailButton')}
        analyticsName={'CheckEmailScreen.openEmailApp'}
        onPress={openInbox}
      />
      <TertiaryButton
        title={I18n.t('Common.contactSupport')}
        analyticsName={'Common.contactSupport'}
        onPress={handlePressContactSupport}
      />
      {isInChallengedQueue ? <ChallengedInvitationFooter analyticsName={analyticsName} /> : null}
    </Page>
  )
}

export default CheckEmailScreen
