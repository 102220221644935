import React from 'react'
import { Text, View, TouchableHighlight } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import Moment from 'moment'

//Services
import Analytics from 'APP/Services/Analytics'
import I18n from 'APP/Services/i18n'
import { getPractitionerName, getEmptyPractitioner, getImage } from 'APP/Lib/PractitionerHelpers'

// Styling
import Styles from './style'
import { Colors } from 'APP/Themes'
import IcCarePlan from 'APP/Images/ic_care_plan.svg'

const CarePlanListItem = ({ carePlan, episode, disabled, showDivider = true, style }) => {
  const navigation = useNavigation()
  const { practitioner, reason, endTime } = carePlan
  const emptyPractitioner = getEmptyPractitioner(I18n.baseLocale, 'carePlanList')
  const practitionerName = getPractitionerName(practitioner) || emptyPractitioner?.name
  const onCarePlanClicked = () => {
    Analytics.trackEvent('button_click', {
      button_value: 'CarePlanList.carePlan',
    })
    navigation.navigate('carePlan', { carePlan, channelId: episode.channelId })
  }
  const styles = practitioner ? Styles.avatar : Styles.diaLogo
  const dividerStyle = showDivider
    ? { borderBottomWidth: 1, borderBottomColor: Colors.elementsBorder }
    : {}

  return (
    <TouchableHighlight
      style={[Styles.clickableContainer, style]}
      underlayColor={Colors.listFocus}
      disabled={disabled}
      onPress={onCarePlanClicked}
    >
      <View style={[Styles.carePlanContainer, dividerStyle]}>
        <View style={Styles.carePlanLeft}>
          <IcCarePlan height={20} width={20} accentFillLine={Colors.accent} />
          <View style={Styles.carePlanInfo}>
            <Text style={Styles.carePlanReason} numberOfLines={1} ellipsizeMode="tail">
              {reason}
            </Text>
            <Text style={Styles.carePlanFrom}>
              {I18n.t('CarePlanList.from', {
                practitionerName,
                interpolation: { escapeValue: false },
              })}
            </Text>
          </View>
        </View>
        <View style={Styles.carePlanMetadata}>
          <Text style={Styles.carePlanTimestamp}>{Moment.utc(endTime).local().fromNow()}</Text>
          {getImage(practitioner, styles)}
        </View>
      </View>
    </TouchableHighlight>
  )
}

export default CarePlanListItem
