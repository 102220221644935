import Config from '../../Config'
import EventEmitter from 'eventemitter2'
import * as Sentry from '@sentry/react-native'
import { logDdError } from 'APP/Lib/Datadog'

const KEEP_ALIVE_INTERVAL = 30000
const MAX_RECONNECTION_DELAY = 10000
const MIN_RECONNECTION_DELAY = 500

export const instances = {}
export const create = (token, url = Config.MATTERMOST_SOCKET_URL) => {
  let socketUrl = url.replace('{accessToken}', token)
  const instanceId = socketUrl
  const instancedIsCached = !!instances[instanceId]

  if (__DEV__) console.log(`MattermostSocket.create(${token}, ${url}) Cached: ${instancedIsCached}`)
  if (instancedIsCached) return instances[instanceId]

  let seq = 0
  let emitter = new EventEmitter()
  let keepAliveTimer
  let ws
  let destroyed = false
  let wsRetryCount = 0

  // Utilities to help with testing of socket stability
  let originalSocketUrl = null
  const testUtils = {
    chatSocketKill: () => ws.close(),
    chatSocketKillUntilReload: () => {
      if (!originalSocketUrl) originalSocketUrl = socketUrl
      socketUrl = url
      ws.close()
    },
    chatSocketRestore: () => {
      if (originalSocketUrl) {
        socketUrl = originalSocketUrl
        originalSocketUrl = null
      }
      ws.close()
    },
  }

  const destroy = () => {
    clearTimeout(keepAliveTimer)
    if (ws) wsCleanup()
    seq = null
    emitter = null
    if (instances[instanceId]) {
      instances[instanceId].destroyed = true
      delete instances[instanceId]
    }
    destroyed = true
  }

  const sendMessage = (action, data) => {
    seq++
    if (ws && ws.readyState === 1) {
      const payload = JSON.stringify({ action, data, seq })
      ws.send(payload)
    }
  }

  const sendPing = () => {
    sendMessage('ping')
  }

  const onMessageSuccess = () => {
    wsRetryCount = 0
  }

  const wsCleanup = () => {
    ws.onopen = null
    ws.onerror = null
    ws.onclose = null
    ws.onmessage = null
    ws.close()
    ws = null
  }

  const wsRetry = () => {
    const retryDelay = Math.min(MIN_RECONNECTION_DELAY * wsRetryCount, MAX_RECONNECTION_DELAY)
    setTimeout(wsInit, retryDelay)
    wsRetryCount++
  }

  const wsInit = () => {
    if (ws) wsCleanup()
    ws = new WebSocket(socketUrl)
    ws.onopen = () => {
      if (destroyed) return
      if (emitter) emitter.emit('open', { type: 'open' })
    }

    ws.onerror = () => {
      if (destroyed) return
      if (emitter) emitter.emit('error', { type: 'error' })
    }

    ws.onclose = () => {
      if (emitter) emitter.emit('close', { type: 'close' })
      if (!destroyed) wsRetry()
    }

    ws.onmessage = (e) => {
      if (destroyed) return
      if (e.type === 'message' && e.data) {
        try {
          const data = JSON.parse(e.data)
          const text = data && data.data && data.data.text
          if (text !== 'pong') {
            if (emitter) emitter.emit('message', data)
            if (data.status === 'OK') onMessageSuccess()
          }
        } catch (err) {
          Sentry.captureException(err)
          logDdError(err.message, err.stack)
        }
      }

      clearTimeout(keepAliveTimer)
      keepAliveTimer = setTimeout(sendPing, KEEP_ALIVE_INTERVAL)
    }
  }

  wsInit()

  instances[instanceId] = {
    addListener: emitter.addListener.bind(emitter),
    removeListener: emitter.removeListener.bind(emitter),
    sendMessage,
    sendPing,
    destroy,
    testUtils,
  }

  return instances[instanceId]
}

export default {
  create,
}
