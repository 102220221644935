import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors } from '../../Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  webView: {
    flex: 1,
    backgroundColor: Colors.appBg,
  },
  loadingOverlay: {
    backgroundColor: Colors.appBg,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    zIndex: 111,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
