import React, { useContext, memo } from 'react'
import { Text, View } from 'react-native'

import { Colors, Fonts } from 'APP/Themes'
import { Styles } from './style'
import Icon from 'react-native-vector-icons/MaterialIcons'

import { PostContext } from 'APP/Lib/Context'

const PostWithMissingData = () => {
  const { post } = useContext(PostContext)

  return (
    <View style={[Styles.bubble, Styles.bubbleFailed]}>
      <View style={Styles.bubbleFailedTextContainer}>
        <Text style={[Styles.bubbleText, Styles.bubbleTextFailed]}>{post.message}</Text>
      </View>
      <Icon name="error-outline" size={Fonts.size.h2} color={Colors.accent} />
    </View>
  )
}

export default memo(PostWithMissingData)
