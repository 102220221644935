import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Metrics, Fonts } from '../../Themes'
import { isWeb } from 'APP/Helpers/checkPlatform'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  header: {
    marginTop: Metrics.navBarHeight,
    marginBottom: Metrics.baseMargin,
  },
  headerLogo: {
    height: 56,
    resizeMode: 'contain',
    marginHorizontal: Metrics.baseMargin * 2,
  },
  consentFormContainer: {
    marginBottom: Metrics.baseMargin,
    height: isWeb() ? 700 : 'auto',
  },
  consentFormView: {
    // AutoHeightWebView will grow the width regardless of margin causing clipping issues. Hardcoding the width prevents this
    width: Metrics.screenWidth - Metrics.baseMargin * 2,
    marginHorizontal: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin,
  },
  guardianConsentContainer: {
    paddingVertical: Metrics.baseMargin,
  },
  buttonBar: {
    flexDirection: 'row',
    justifyContent: 'center',
    borderTopColor: Colors.grayBg,
    borderTopWidth: 1,
    paddingBottom: Metrics.bottomSpace,
    display: 'flex',
  },
  button: {
    flex: 1,
  },
  noRightSpacing: {
    marginRight: 0,
  },
  consentButtonText: {
    ...Fonts.style.body,
    color: Colors.text,
  },
  disagreeButtonText: {
    ...Fonts.style.body,
    color: Colors.text,
  },
  title: {
    ...Fonts.style.h1,
    color: Colors.text,
    alignItems: 'baseline',
    textAlign: 'left',
    marginTop: Metrics.baseMargin,
    marginHorizontal: Metrics.baseMargin * 2,
  },
  subtitle: {
    ...Fonts.style.body,
    color: Colors.text,
    textAlign: 'left',
    marginTop: Metrics.baseMargin,
    marginHorizontal: Metrics.baseMargin * 2,
  },
  formContent: {
    marginHorizontal: Metrics.baseMargin * 2,
    marginBottom: Metrics.baseMargin,
    gap: Metrics.baseMargin / 4,
    width: '100%',
    maxWidth: isWeb() ? '100%' : '80%',
  },
  activityIndicator: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#00000077',
  },
})
