import React, { useMemo } from 'react'
import { ActivityIndicator, View } from 'react-native'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { ResponsiveFlatList } from 'APP/Converse/Layout'
import Typography from 'APP/Converse/Typography'
import Styles from './style'
import { Colors, Metrics } from 'APP/Themes'
import { transformChildrenToAValidElement } from './helpers'
import DialogueTouchableOpacity from '../DialogueTouchableOpacity'
import { SUBTITLE_VARIANT } from './constants'

const RankedListRow = ({ item, nonUniqueRanks, renderSeparator }) => {
  const issubtitleVariantError = item.subtitleVariant === SUBTITLE_VARIANT.ERROR
  const textColor = item.highlighted ? 'darkText' : 'text'
  return (
    <>
      <View style={[Styles.item, item.highlighted && Styles.highlighted]} testID="ranked-list-row">
        <View style={Styles.rankContainer}>
          <Typography variant="h2" color={textColor}>
            {item.rank} {nonUniqueRanks.includes(String(item.rank)) ? '=' : ' '}
          </Typography>
        </View>
        <View style={Styles.avatarContainer}>
          <Typography variant="subheaderBold" color="background">
            {item.title?.[0]?.toUpperCase()}
          </Typography>
        </View>
        <View style={Styles.bodyContainer}>
          <Typography variant="bodyLargeBold" numberOfLines={2} color={textColor}>
            {item.title}
          </Typography>
          {item.subtitle && (
            <View style={Styles.subtitle} testID="subtitle">
              {issubtitleVariantError && (
                <MaterialIcon name="error" color={Colors.errorText} style={Styles.errorIcon} />
              )}
              <Typography variant="bodySmall" color={issubtitleVariantError ? 'error' : textColor}>
                {item.subtitle}
              </Typography>
            </View>
          )}
        </View>
        <View style={Styles.valueContainer}>
          <Typography variant="subheaderBold" color={textColor}>
            {item.value}
          </Typography>
        </View>
      </View>
      {renderSeparator && (
        <View style={Styles.seperator} testID="separator">
          <MaterialIcon
            name="drag-handle"
            color={Colors.textSecondary}
            size={Metrics.icons.small}
          />
        </View>
      )}
    </>
  )
}

const RankedList = ({
  loading,
  retry,
  onRetryPress,
  separatorIndex,
  items,
  headerComponent,
  title,
  valueLabelTitle,
  emptyTitle,
  emptyCopy,
  ...rest
}) => {
  const insets = useSafeAreaInsets()

  // Members sharing the same rank should be displayed as equal
  // identifies the ranks that are not unique (shared) to make that distinction
  const nonUniqueRanks = useMemo(
    () =>
      Object.entries(
        items?.reduce((acc, item) => {
          acc[item.rank] = (acc[item.rank] || 0) + 1
          return acc
        }, {}) || {}
      )
        .map(([rank, numberOfValues]) => (numberOfValues > 1 ? rank : undefined))
        .filter(Boolean),
    [items]
  )

  const header = (
    <>
      {transformChildrenToAValidElement(headerComponent)}
      <View style={Styles.headerContainer}>
        <Typography variant="h3">{title}</Typography>
        <Typography variant="captionBold">{valueLabelTitle?.toUpperCase()}</Typography>
      </View>
    </>
  )

  const emptyComponent = (
    <View style={Styles.emptyContainer}>
      {retry && (
        <DialogueTouchableOpacity onPress={onRetryPress} analyticsName="Refresh" testID="retry">
          <MaterialIcon name="refresh" size={Metrics.icons.large} color={Colors.text} />
        </DialogueTouchableOpacity>
      )}
      {loading && <ActivityIndicator size="large" color={Colors.text} testID="loading" />}
      {!loading && !retry && (
        <>
          <Typography variant="h3">{emptyTitle}</Typography>
          <Typography variant="captionBold">{emptyCopy}</Typography>
        </>
      )}
    </View>
  )

  return (
    <ResponsiveFlatList
      data={items}
      renderItem={({ item, index }) => (
        <RankedListRow
          item={item}
          nonUniqueRanks={nonUniqueRanks}
          renderSeparator={index === separatorIndex}
        />
      )}
      keyExtractor={(item, index) => `${item.rank}-${index}`}
      extraData={nonUniqueRanks}
      ListHeaderComponent={header}
      ListEmptyComponent={emptyComponent}
      contentContainerStyle={{ paddingBottom: insets.bottom + Metrics.baseMargin }}
      {...rest}
    />
  )
}

export default RankedList
