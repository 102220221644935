import React from 'react'
import Typography from 'APP/Converse/Typography'
import styled from 'styled-components/native'

const StyledTooltipContainer = styled.View`
  margin-top: ${({ theme }) => theme.metrics.baseMargin * 0.25}px;
`

interface InfotipProps {
  error?: string
  infotip?: string
  isFocused?: boolean
}

const Infotip = ({ error, infotip, isFocused }: InfotipProps) => {
  return (
    <StyledTooltipContainer>
      {Boolean(error) && (
        <Typography variant="bodySmallBold" color="error">
          {error}
        </Typography>
      )}
      {Boolean(infotip) && !error && isFocused && (
        <Typography variant="bodySmallBold">{infotip}</Typography>
      )}
    </StyledTooltipContainer>
  )
}

export default Infotip
