import Moment from 'moment'

export const getChildren = (family) => {
  if (!family?.members) return []

  const entries = Object.entries(family?.members)
  if (!entries) return []

  return entries.reduce((children, entry) => {
    if (entry[1]?.type === 'child') {
      children.push({
        id: Number(entry[1].id),
        givenName: entry[1].givenName,
      })
    }
    return children
  }, [])
}

export const normalizeAppointments = (rawAppointments, family) => {
  if (!rawAppointments) return []

  const groups = {
    past: [],
    upcoming: [],
  }

  const now = Moment.utc().valueOf()
  const children = getChildren(family)

  rawAppointments.forEach((currentData) => {
    if (currentData?.appointments) {
      currentData.appointments.forEach((appointment) => {
        // Skip rescheduled or cancelled appointments
        if (appointment.status === 'rescheduled' || appointment.status === 'cancelled') {
          return
        }

        const child = children.find((child) => child.id === appointment?.member?.id)

        const endTime = Moment.utc(appointment.end_at).valueOf()
        const appointmentWithPractitioner = {
          ...appointment,
          child,
          // Attach the returned practitioners to their appointments
          practitioner: currentData?.practitioners?.find(
            (practitioner) => practitioner.emr_id === appointment.practitioner_emr_id
          ),
        }

        if (endTime > now) {
          groups.upcoming.push(appointmentWithPractitioner)
        } else {
          groups.past.push(appointmentWithPractitioner)
        }
      })
    }
  })

  groups.upcoming.sort((a, b) => Moment(a.start_at).diff(Moment(b.start_at)))

  return groups
}
