import React, { useRef, useEffect, useState } from 'react'
import { ActivityIndicator, StyleSheet } from 'react-native'
import SessionManager from 'APP/NativeModules/SessionManager'

import { Colors } from 'APP/Themes'
import { VideoContainer } from './Container'

const PublisherView = ({ isMuted }) => {
  const videoWrapper = useRef()

  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (videoWrapper.current) {
      SessionManager.publish().then((videoElement) => {
        if (videoElement?.style) {
          videoElement.style.width = '100%'
          videoElement.style.height = '100%'
          videoElement.style.objectFit = 'cover'
        }
        videoElement.addEventListener('loadeddata', () => setIsReady(true))
        if (typeof videoWrapper.current.appendChild === 'function') {
          videoWrapper.current.appendChild(videoElement)
        }
      })
    }
    return () => {
      SessionManager.unpublish()
    }
  }, [])

  useEffect(() => {
    SessionManager.mutePublisher(isMuted)
  }, [isMuted])

  return (
    <>
      <VideoContainer data-testid="publisher-video" ref={videoWrapper} isReady={isReady} />
      {!isReady && (
        <ActivityIndicator
          color={Colors.videoBorder}
          animating
          size="large"
          style={StyleSheet.absoluteFillObject}
        />
      )}
    </>
  )
}

export default PublisherView
