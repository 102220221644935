import React, { useMemo } from 'react'
import { SectionList } from 'react-native'
import styled from 'styled-components/native'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { AvailabilitySlot } from '@dialogue/timekeeper'

import MaterialIcon from 'react-native-vector-icons/MaterialIcons'

import { Colors, Metrics } from 'APP/Themes'
import I18n from 'APP/Services/i18n'
import Typography from 'APP/Converse/Typography'
import { splitSlotsByDay } from 'APP/Components/AppointmentBooking/utils/helpers'
import { selectProvider, selectProviderAvailabilities } from 'APP/Store/ProviderBooking/selectors'
import { RootState } from 'APP/Store/CreateStore'

const Wrapper = styled.View`
  padding: ${({ theme }) => theme.metrics.baseMargin}px 0;
`

const Title = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin / 2}px;
`

const SectionTitle = styled(Typography)`
  margin: ${({ theme }) => theme.metrics.baseMargin / 2}px 0;
`

const Item = styled.TouchableHighlight<{ $selected: boolean }>`
  padding: ${({ theme }) => theme.metrics.baseMargin}px;
  margin: ${({ theme }) => theme.metrics.baseMargin / 4}px 0;
  background-color: ${({ $selected, theme }) =>
    $selected ? theme.colors.clientCardBg : theme.colors.elementsBg};
  border-radius: ${({ theme }) => theme.metrics.borderRadius.small}px;
  border-width: 1;
  border-color: ${({ $selected, theme }) =>
    $selected ? theme.colors.accent : theme.colors.elementsBorder};
  ${({ theme }) => theme.shadows.soft};
`

const ItemContent = styled.View`
  flex-direction: row;
  align-items: center;
`

const ItemIcon = styled(MaterialIcon)<{ $selected: boolean }>`
  margin-right: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  color: ${({ theme, $selected }) => ($selected ? theme.colors.accent : theme.colors.disabled)};
`

const ActiveSlot = styled.View`
  padding: ${({ theme }) => theme.metrics.baseMargin}px;
  margin: ${({ theme }) => theme.metrics.baseMargin / 4}px 0;
  background-color: ${({ theme }) => theme.colors.clientCardBg};
  border-radius: ${({ theme }) => theme.metrics.borderRadius.small}px;
  border-width: 1;
  border-color: ${({ theme }) => theme.colors.accent};
  ${({ theme }) => theme.shadows.soft};
`

const TimeOffset = styled(Typography)`
  margin-left: ${({ theme }) => theme.metrics.baseMargin / 1.5}px;
  color: ${({ theme }) => theme.colors.dim};
`

interface Props {
  providerId: number
  selectedSlot: AvailabilitySlot | null
  onSelect: (slot: AvailabilitySlot | null) => void
}

const formatTimeslot = (startAt: string, endAt: string) =>
  `${moment(startAt).format('hh:mm A')} - ${moment(endAt).format('hh:mm A')}`

export const ProviderAvailabilities = ({ providerId, selectedSlot, onSelect }: Props) => {
  const calendarFormat = I18n.t('AppointmentBooking.providers_timeslot_section_formats', {
    returnObjects: true,
  })
  const availabilities = useSelector((state) => selectProviderAvailabilities(state, providerId))
  const provider = useSelector((state: RootState) => selectProvider(state, providerId))

  const slotsSections = useMemo(() => {
    const groupedSlots = splitSlotsByDay(availabilities)
    return Object.entries(groupedSlots).map(([day, slots]) => ({
      title: moment(day).calendar(calendarFormat),
      data: slots,
    }))
  }, [availabilities, calendarFormat])

  const handleSelect = (slot: AvailabilitySlot) => {
    const slotAlreadySelected = slot.start_at === selectedSlot?.start_at
    onSelect(slotAlreadySelected ? null : slot)
  }

  const renderItem = ({ item }: { item: AvailabilitySlot }) => {
    const isToday = moment(item.start_at).isSame(moment(), 'day')
    const isSelected = selectedSlot?.start_at === item.start_at
    return (
      <Item
        $selected={isSelected}
        underlayColor={Colors.multiSelectMsgBg}
        onPress={() => handleSelect(item)}
      >
        <ItemContent>
          <ItemIcon
            name={isSelected ? 'radio-button-checked' : 'radio-button-unchecked'}
            size={Metrics.icons.small}
            $selected={isSelected}
          />
          <Typography variant="bodyNormal">{formatTimeslot(item.start_at, item.end_at)}</Typography>
          {isToday && (
            <TimeOffset variant="bodySmall">{moment(item.start_at).fromNow()}</TimeOffset>
          )}
        </ItemContent>
      </Item>
    )
  }

  return (
    <Wrapper>
      <Title variant="h4">
        {I18n.t('AppointmentBooking.availabilities', { name: provider?.givenName })}
      </Title>
      <SectionList
        sections={slotsSections}
        keyExtractor={(item) => item.start_at}
        renderItem={renderItem}
        initialNumToRender={10}
        renderSectionHeader={({ section: { title } }) => (
          <SectionTitle variant="subheader">{title}</SectionTitle>
        )}
      />
    </Wrapper>
  )
}

export const ProviderAvailability = ({
  slot,
  selection,
}: {
  slot?: AvailabilitySlot | null
  selection?: string | null
}) => {
  const calendarFormat = I18n.t('AppointmentBooking.providers_timeslot_section_formats', {
    returnObjects: true,
  })

  return (
    <Wrapper>
      {slot && (
        <>
          <Title variant="h4">{moment(slot.start_at).calendar(calendarFormat)}</Title>
          <ActiveSlot>
            <Typography variant="bodyNormal">
              {formatTimeslot(slot.start_at, slot.end_at)}
            </Typography>
          </ActiveSlot>
        </>
      )}
      {selection && (
        <ActiveSlot>
          <Typography variant="bodyNormal">{selection}</Typography>
        </ActiveSlot>
      )}
    </Wrapper>
  )
}
