import React, { useCallback, useLayoutEffect, useRef } from 'react'
import { View, Animated } from 'react-native'
import { Input } from 'react-native-elements'
// Styles
import { Styles } from './style'
import { Colors } from 'APP/Themes'
import Color from 'color'

// Components
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import Icon from 'APP/Converse/Icon'

const ClearInput = ({ show, onPress }) => {
  const animatedState = useRef(new Animated.Value(0)).current

  useLayoutEffect(() => {
    Animated.spring(animatedState, {
      toValue: show ? 1 : 0,
      speed: 12,
      useNativeDriver: true,
    }).start()
  }, [show])

  const AnimatedStyles = {
    transform: [{ scale: animatedState }],
    opacity: animatedState.interpolate({ inputRange: [0, 0.5, 1], outputRange: [0, 0, 1] }),
  }

  return (
    <Animated.View style={AnimatedStyles} testID="clear-input">
      <DialogueTouchableOpacity
        activeOpacity={0.5}
        onPress={onPress}
        analyticsName="SearchField.clear"
      >
        <Icon src="close-circle" variant="material" color="disabled" size={25} />
      </DialogueTouchableOpacity>
    </Animated.View>
  )
}

const FocusBorderColor = Color(Colors.accent).rgb().string()
const BlurBorderColor = Color(Colors.accent).alpha(0).rgb().string()

const SearchField = ({
  value,
  onChange,
  placeholder,
  showClearButton,
  onClearButtonPress,
  disabled,
  style,
}) => {
  const animatedState = useRef(new Animated.Value(0)).current
  const onFocus = useCallback(() => {
    Animated.timing(animatedState, {
      toValue: 1,
      duration: 250,
      useNativeDriver: false,
    }).start()
  })
  const onBlur = useCallback(() => {
    Animated.timing(animatedState, {
      toValue: 0,
      duration: 250,
      useNativeDriver: false,
    }).start()
  })

  const animatedStyle = {
    borderWidth: 1,
    borderColor: animatedState.interpolate({
      inputRange: [0, 1],
      outputRange: [BlurBorderColor, FocusBorderColor],
    }),
  }

  return (
    <View style={[Styles.container, style]}>
      <Icon src="magnify" variant="material" color="accent" size={24} />
      <Animated.View style={[Styles.inputContainer, animatedStyle]}>
        <Input
          testID="search-field"
          returnKeyType="search"
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          placeholderTextColor={Colors.buttonPrimarySelected}
          inputStyle={Styles.textInput}
          inputContainerStyle={Styles.textInputContainer}
          containerStyle={Styles.input}
          selectionColor={Colors.accent}
          onChangeText={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          renderErrorMessage={false}
        />
        <ClearInput show={showClearButton} onPress={onClearButtonPress} />
      </Animated.View>
    </View>
  )
}

export default SearchField
