import React, { useState, useMemo, useCallback } from 'react'
import I18n from 'APP/Services/i18n'
import { Recommender } from '@dialogue/services'

import { FocusChart } from '../Charts'
import DefaultRecommendation from './DefaultRecommendation'
import HabitDrawer from '../HabitDrawer'
import ArticlesDrawer from '../ArticlesDrawer'

function FocusRecommendation(props) {
  const { sources, score_range, actions, ...rest } = props
  const [isHabitDrawerVisible, setIsHabitDrawerVisible] = useState()
  const [isArticlesDrawerVisible, setIsArticlesDrawerVisible] = useState()

  const [showHabitsActionConfig, showArticlesActionConfig] = useMemo(
    () =>
      (actions || []).reduce((nextConfig, config) => {
        switch (config.action) {
          case Recommender.Types.ActionType.SHOW_HABITS:
            nextConfig[0] = config
            break
          case Recommender.Types.ActionType.SHOW_ARTICLES:
            nextConfig[1] = config
            break
        }
        return nextConfig
      }, []),
    [actions]
  )

  const onActionPress = useCallback(({ action }) => {
    switch (action) {
      case Recommender.Types.ActionType.SHOW_HABITS:
        setIsHabitDrawerVisible(true)
        break
      case Recommender.Types.ActionType.SHOW_ARTICLES:
        setIsArticlesDrawerVisible(true)
        break
    }
  }, [])

  return (
    <>
      <FocusChart {...rest} {...sources} score_range={score_range} />
      <DefaultRecommendation {...props} onActionPress={onActionPress} />
      {!!showHabitsActionConfig && (
        <HabitDrawer
          isVisible={isHabitDrawerVisible}
          title={showHabitsActionConfig.description?.[I18n.baseLocale]}
          category={showHabitsActionConfig.habits_category}
          onClose={() => setIsHabitDrawerVisible(false)}
        />
      )}
      {!!showArticlesActionConfig && (
        <ArticlesDrawer
          isVisible={isArticlesDrawerVisible}
          title={showArticlesActionConfig.text?.[I18n.baseLocale]}
          category={showArticlesActionConfig.articles_category}
          subcategory={showArticlesActionConfig.articles_subcategory}
          onClose={() => setIsArticlesDrawerVisible(false)}
        />
      )}
    </>
  )
}

export default FocusRecommendation
