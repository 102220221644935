import React from 'react'

import Typography from 'APP/Converse/Typography'
import { ShadowView } from 'APP/Converse/ShadowView'
import {
  TextInput as PlatformInput,
  ViewStyle,
  TextInputProps as NativeTextInputProps,
} from 'react-native'
import Infotip from 'APP/Converse/Inputs/Infotip'
import Styles from 'APP/Converse/Inputs/style'
import { StyledInputContainer } from 'APP/Converse/Inputs/styles'
import TooltipButton from 'APP/Components/TooltipButton'
import { isWeb } from 'APP/Helpers/checkPlatform'
import { Colors } from 'APP/Themes'

interface TextInputProps extends NativeTextInputProps {
  label: string
  infotip?: string
  isFocused?: boolean
  error?: string
  showRequiredAsterisk?: boolean
  isSecureEntry?: boolean
  disabled?: boolean
  placeholder?: string
  hide?: boolean
  tooltipConfig?: {
    text?: string
    subtitle?: string
    img?: string
    iconColor?: string
  }
}

const TextInput = React.forwardRef(
  (
    {
      label,
      infotip,
      isFocused,
      error,
      isSecureEntry,
      onChangeText,
      disabled,
      placeholder,
      hide,
      showRequiredAsterisk,
      tooltipConfig,
      ...rest
    }: TextInputProps,
    ref
  ) => {
    const webEvents = isWeb() ? { onClick: rest.onPressIn } : {}
    const requiredAsterisk = showRequiredAsterisk && <Typography color="error"> *</Typography>

    return (
      <StyledInputContainer hide={hide}>
        <Typography variant="bodyNormalBold" color={error ? 'error' : 'text'}>
          {label}
          {requiredAsterisk}
        </Typography>
        <ShadowView>
          <PlatformInput
            style={(disabled ? Styles.disabledInputStyles : Styles.inputStyles) as ViewStyle}
            // @ts-expect-error https://github.com/storybookjs/storybook/issues/21686
            ref={ref}
            secureTextEntry={isSecureEntry}
            onChangeText={onChangeText}
            disabled={disabled}
            editable={!disabled}
            placeholder={placeholder}
            placeholderTextColor={error ? Colors.errorText : '#999'}
            {...rest}
            {...webEvents}
          />
          {!!tooltipConfig && <TooltipButton config={tooltipConfig} style={Styles.tooltipStyles} />}
        </ShadowView>
        <Infotip error={error} infotip={infotip} isFocused={isFocused} />
      </StyledInputContainer>
    )
  }
)

export default TextInput
