import React from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import Analytics from 'APP/Services/Analytics'
import Typography from 'APP/Converse/Typography'

const ServiceGroupCardContainer = styled(DialogueTouchableOpacity)`
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin / 16}px;
  padding-horizontal: ${({ theme }) => theme.metrics.baseMargin * 2}px;
  padding-vertical: ${({ theme }) => theme.metrics.baseMargin}px;
  background-color: ${({ theme }) => theme.colors.elementsBg};
  flex-direction: row;
  ${({ theme }) => theme.shadows.normal};
`

const ServiceGroupCardIconContainer = styled(View)`
  margin-right: ${({ theme }) => theme.metrics.baseMargin * 0.75}px;
`

const ServiceGroupCardTextContainer = styled(View)`
  flex: 1;
`

const CallToActionText = styled(Typography)`
  margin-horizontal: ${({ theme }) => theme.metrics.baseMargin / 4}px;
`

interface ServiceGroupCardProps {
  onPress: () => void
  testID: string
  analyticsName: string
  icon: React.ReactNode
  cardText: string
  callToAction: string
  analyticsScreenName: string
  trackEventValue: string
  trackEventText: string
}

export const ServiceGroupCard: React.FC<ServiceGroupCardProps> = ({
  onPress,
  testID,
  analyticsName,
  icon,
  callToAction,
  analyticsScreenName,
  trackEventValue,
  trackEventText,
  cardText = '',
}) => {
  const handlePress = () => {
    if (onPress) onPress()

    Analytics.trackEvent('button_click', {
      button_value: trackEventValue,
      button_text: trackEventText,
      trigger: analyticsScreenName,
    })
  }

  return (
    <ServiceGroupCardContainer onPress={handlePress} testID={testID} analyticsName={analyticsName}>
      <ServiceGroupCardIconContainer>{icon}</ServiceGroupCardIconContainer>
      <ServiceGroupCardTextContainer>
        <Typography variant="bodyNormal" color="dim">
          {cardText}
          <CallToActionText variant="bodyNormal" color="accent">
            {callToAction}
          </CallToActionText>
        </Typography>
      </ServiceGroupCardTextContainer>
    </ServiceGroupCardContainer>
  )
}
