import React from 'react'
import { View, Image, TouchableOpacity, ActivityIndicator } from 'react-native'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import { Appearance } from 'react-native-appearance'
import moment from 'moment'
import { ActiveMinutes } from '@dialogue/services'

import I18n from 'APP/Services/i18n'
import Typography from 'APP/Converse/Typography'
import { Colors, Images, Metrics } from 'APP/Themes'
import Styles from './style'

const TrackerSection = ({
  supported,
  provider,
  status,
  disabled,
  modifying,
  last_synced_at,
  ctaStatusMap,
  onPress,
  temporaryDisabled,
}) => (
  <View
    style={
      !supported ? [Styles.trackerWrapper, Styles.disabledTrackerWrapper] : Styles.trackerWrapper
    }
    testID="tracker"
  >
    <View style={Styles.tracker}>
      <Image style={Styles.image} source={Images.trackers?.[provider]} resizeMode="cover" />
      <Typography variant="subheaderBold" style={Styles.name}>
        {I18n.t(`HealthTrackerSettings.trackerProviders.${provider}`)}
      </Typography>
      {supported ? (
        <TouchableOpacity testID={`trackerCta_${provider}`} disabled={disabled} onPress={onPress}>
          {modifying ? (
            <ActivityIndicator testID={`${provider}-modifying`} color={Colors.accent} />
          ) : (
            <Typography variant="buttonText" color="accent">
              {ctaStatusMap?.[status] || ctaStatusMap?.default}
            </Typography>
          )}
        </TouchableOpacity>
      ) : (
        <Typography variant="buttonText" color="disabled" testID="notCompatible">
          {temporaryDisabled
            ? I18n.t('HealthTrackerSettings.ctas.temporaryDisabled')
            : I18n.t('HealthTrackerSettings.ctas.notCompatible')}
        </Typography>
      )}
    </View>
    {last_synced_at && (
      <View style={Styles.subInfo} testID="trackerSync">
        <Typography variant="bodySmall">
          {I18n.t('HealthTrackerSettings.synced')} {moment.utc(last_synced_at).fromNow()}
        </Typography>
        <MaterialIcon name="done" size={Metrics.icons.small} color={Colors.accent} />
      </View>
    )}
    {status === ActiveMinutes.Types.TrackerStatus.Broken && (
      <View style={Styles.subInfo} testID="trackerReconnect">
        <Typography variant="bodySmall">{I18n.t('HealthTrackerSettings.reconnect')}</Typography>
        <MaterialIcon
          name="error"
          size={Metrics.icons.small}
          color={Appearance.getColorScheme() === 'dark' ? Colors.text : Colors.errorText}
        />
      </View>
    )}
  </View>
)

export default TrackerSection
