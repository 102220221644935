import React, { useEffect } from 'react'
import { ActivityIndicator, Text, View } from 'react-native'

import { ResponsiveFlatList } from 'APP/Converse/Layout'

import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import ActiveMinutesActions from 'APP/Redux/ActiveMinutesRedux'

import I18n from 'APP/Services/i18n'

import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import { PrimaryButton } from 'APP/Components/Buttons'
import { ChallengeCard } from 'APP/Components/Challenge'

import Retry from 'APP/Images/retry.svg'
import { Colors, Metrics } from 'APP/Themes'
import withFeatureEnabled from 'APP/Helpers/withFeatureEnabled'
import CompletedChallenges_legacy from '../WellnessCenterCompletedChallengesScreen'
import Styles from './style'

const CompletedChallenges = () => {
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const getMyCompletedChallenges = () => dispatch(ActiveMinutesActions.getMyCompletedChallenges())
  const { loading, retry, completed } = useSelector(
    (state) => state.activeMinutes.myCompletedChallenges
  )

  useEffect(() => {
    getMyCompletedChallenges()
  }, [])

  const navigateToChallenge = (id) => {
    navigation.navigate('wcChallenge', { id })
  }

  return (
    <NoticeBarContainer>
      {loading && !completed && (
        <ActivityIndicator
          size="large"
          color={Colors.text}
          style={Styles.loading}
          testID="loading"
        />
      )}
      {retry && (
        <View style={Styles.retry} testID="retry">
          <Retry fillLine={Colors.text} width={Metrics.icons.large} height={Metrics.icons.large} />
          <Text style={Styles.retryTitle}>{I18n.t('WellnessCenter.challenges.retry.title')}</Text>
          <Text style={Styles.retryCopy}>{I18n.t('WellnessCenter.challenges.retry.copy')}</Text>
          <PrimaryButton
            style={Styles.retryCta}
            title={I18n.t('WellnessCenter.challenges.retry.cta')}
            onPress={getMyCompletedChallenges}
          />
        </View>
      )}
      {!retry && completed?.length === 0 && (
        <View style={Styles.empty} testID="empty-view">
          <Text style={Styles.emptyTitle} testID="empty-title">
            {I18n.t('WellnessCenter.challenges.emptyList.completed.title')}
          </Text>
          <Text style={Styles.emptyCopy} testID="empty-copy">
            {I18n.t('WellnessCenter.challenges.emptyList.completed.copy')}
          </Text>
        </View>
      )}
      {!retry && completed?.length > 0 && (
        <ResponsiveFlatList
          data={completed}
          renderItem={({ item }) => (
            <ChallengeCard
              testID="completed-challenge-card"
              key={item.id}
              challenge={item}
              onPress={() => navigateToChallenge(item.id)}
            />
          )}
          keyExtractor={(challenge) => challenge.id}
          scrollIndicatorInsets={{ right: 1 }}
        />
      )}
    </NoticeBarContainer>
  )
}

export default withFeatureEnabled({
  memoizationKey: 'CompletedChallenges',
  enabled: CompletedChallenges,
  default: CompletedChallenges_legacy,
  featureKey: 'terraDrivenWellness',
})
