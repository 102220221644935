import React, { PropsWithChildren, useMemo } from 'react'
import { Animated, LayoutChangeEvent, ViewProps } from 'react-native'
import styled from 'styled-components/native'
import { useBottomDrawer } from './provider'
import { useOnLayout } from 'APP/Hooks/Layout'
import { ApplicationStyles, Colors } from 'APP/Themes'

const Container = styled.View`
  ${ApplicationStyles.shadowHeavy};
  background-color: ${Colors.appBg};
  border-top-left-radius: ${({ theme }) => theme.metrics.borderRadius.medium}px;
  border-top-right-radius: ${({ theme }) => theme.metrics.borderRadius.medium}px;
`

type Props = PropsWithChildren & ViewProps

export const BottomDrawerContent = ({ children, ...rest }: Props) => {
  const { slideIn } = useBottomDrawer()

  const [{ height: modalHeight }, onModalLayout] = useOnLayout() as [
    { x: number; y: number; width: number; height: number },
    (event: LayoutChangeEvent) => void
  ]

  const animatedStyle = useMemo(
    () => ({
      transform: [
        {
          translateY: slideIn.interpolate({
            inputRange: [0, 1],
            outputRange: [modalHeight, 0],
          }),
        },
      ],
    }),
    [slideIn, modalHeight]
  )

  return (
    <Animated.View style={animatedStyle}>
      <Container onLayout={onModalLayout} {...rest}>
        {children}
      </Container>
    </Animated.View>
  )
}
