import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {
  AdultInviteSuccessPayload,
  CancelAdultInviteSuccessPayload,
  FamilyState,
  FetchFamilySuccessPayload,
  UpdateChildProfilePayload,
  Member,
  RequestError,
} from './types'

const initialState: FamilyState = {
  id: null,
  members: {},
  permissions: {},
  requestRunning: false,
  requestError: null,
}

const { actions, reducer } = createSlice({
  name: 'family',
  initialState,
  reducers: {
    fetchFamilySuccess: (state, action: PayloadAction<FetchFamilySuccessPayload>) => {
      const { familyId, family, permissions } = action.payload
      const familyMap = family.reduce((acc, member) => {
        acc[member.id] = member
        return acc
      }, {} as Record<string, Member>)

      state.id = familyId
      state.members = familyMap
      state.permissions = permissions
      state.requestRunning = false
      state.requestError = null
    },
    adultInviteSuccess: (state, action: PayloadAction<AdultInviteSuccessPayload>) => {
      const { member, familyId } = action.payload
      state.members[member.id] = member
      state.id = familyId
      state.requestRunning = false
      state.requestError = null
    },
    cancelAdultInviteSuccess: (state, action: PayloadAction<CancelAdultInviteSuccessPayload>) => {
      const { member } = action.payload
      const members = { ...state.members }
      delete members[member.id]
      state.members = members
      state.requestRunning = false
      state.requestError = null
    },
    updateChildProfileState: (state, action: PayloadAction<UpdateChildProfilePayload>) => {
      const { childProfile, familyId } = action.payload
      state.members[childProfile.id] = childProfile
      state.id = familyId
      state.requestRunning = false
      state.requestError = null
    },

    // Saga request actions
    fetchFamilyRequest: (state) => {
      state.requestRunning = true
      state.requestError = null
    },
    adultInviteRequest: (state) => {
      state.requestRunning = true
      state.requestError = null
    },
    updateChildProfileRequest: (state) => {
      state.requestRunning = true
      state.requestError = null
    },
    createChildProfileRequest: (state) => {
      state.requestRunning = true
      state.requestError = null
    },
    cancelAdultInviteRequest: (state) => {
      state.requestRunning = true
      state.requestError = null
    },

    familyError: (state, action: PayloadAction<{ error: RequestError }>) => {
      state.requestRunning = false
      state.requestError = action.payload.error
    },
    familyClear: () =>
      ({
        id: null,
        members: {},
        permissions: {},
        requestRunning: false,
        requestError: null,
      } as FamilyState),
  },
})

export { actions as familyActions }

export default reducer
