import Config from 'APP/Config'
import I18n from 'APP/Services/i18n'

export const getAdminAreas = () =>
  Config.ADMIN_AREAS.map((a) => ({
    ...a,
    label: I18n.t(`AdminAreas.${Config.REGION}.${a.key}`),
    value: a.key,
  }))

export const getAdminAreaString = (adminArea) => {
  const key = `AdminAreas.${Config.REGION}.${adminArea}`
  return adminArea && I18n.exists(key) && I18n.t(key)
}

export const isAreaServiced = (adminAreaLevel1) => {
  const adminAreas = getAdminAreas()
  const area = adminAreas && adminAreas.find((a) => a.key === adminAreaLevel1)
  return area && area.serviced
}

export const getServicedAdminAreasString = (adminAreas) => {
  const servicedAreas = adminAreas && filterAreasServiced(adminAreas).map((a) => a.label)
  const count = servicedAreas.length

  if (count === 0) return ''
  if (count === 1) return servicedAreas[0]

  const allButLast = servicedAreas.slice(0, count - 1).join(', ')
  const last = servicedAreas[count - 1]
  return `${allButLast} ${I18n.t('Common.and')} ${last}`
}

export const filterAreasServiced = (adminAreas) => {
  return adminAreas && adminAreas.filter((a) => a.serviced)
}

export default {
  getAdminAreaString,
  getAdminAreas,
  isAreaServiced,
  filterAreasServiced,
}
