import React, { useCallback, useEffect, useLayoutEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { View } from 'react-native'
import Config from 'APP/Config'
import HeaderTabs from 'APP/Converse/HeaderTabs'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'

import { TabScreens, shouldRenderComponent, useAppComponentMapper } from 'APP/Lib/ContentHelpers'
import Analytics from 'APP/Services/Analytics'
import sprigClient from 'APP/Services/Sprig'

import Styles from './style'
import { HelpButtonComponent } from 'APP/Components/HelpButtonComponent'

const CmsAppTabScreen = (props) => {
  const { navigation, route } = props
  const { tabName } = { ...route?.params, ...props }
  const name = route.name

  const screen = useSelector((state) =>
    state?.content?.layout?.data?.screens?.find((screen) => screen?.mobile_scene_key === name)
  )
  const patient = useSelector((state) => state?.patient)
  const history = useSelector((state) => state?.history)
  const eligibleServices = patient?.profile?.eligibleServices
  const orderedEpisodes = history?.orderedEpisodes
  const carePlans = useSelector((state) => state?.history?.completedCarePlans)
  const unreadMessages = useSelector((state) => state.history?.hasUnreadChannels)

  const features = useSelector((state) => state.features)
  const showWeeklyActiveMinutes = features?.weeklyActiveMinutes || false

  const mapAppComponent = useAppComponentMapper()

  const tabs = useMemo(
    () =>
      screen?.tabs?.reduce((filteredTabs, tab) => {
        let shouldRenderTab = shouldRenderComponent(tab.service_filter, eligibleServices)
        let extraProps = {}

        if (tab.name === TabScreens.chat.inbox) {
          shouldRenderTab = shouldRenderTab || orderedEpisodes?.length > 0
          extraProps = {
            newContent: unreadMessages,
          }
        } else if (tab.name === TabScreens.chat.carePlans) {
          shouldRenderTab = shouldRenderTab || carePlans?.length > 0
        } else if (tab.name === TabScreens.move.challenges) {
          if (Config.BRAND_ID === 'sunlifeus') {
            shouldRenderTab = shouldRenderTab || showWeeklyActiveMinutes
          }
        }

        if (shouldRenderTab) {
          filteredTabs.push({
            ...tab,
            ReactComponent: mapAppComponent(tab.component),
            extraProps,
          })
        }
        return filteredTabs
      }, []) ?? [],
    [screen, unreadMessages, showWeeklyActiveMinutes]
  )

  const activeTab = tabs.find((tab) => tab.name === tabName) || tabs[0]

  const { ReactComponent, component } = activeTab ?? {}

  const goToTabByName = useCallback(
    (name) => {
      if (name) navigation.navigate(screen?.mobile_scene_key, { tabName: name })
    },
    [navigation, screen?.mobile_scene_key]
  )

  useLayoutEffect(() => {
    if (name !== 'mindTab') return
    navigation.setOptions({
      headerRight: () => <HelpButtonComponent />,
    })
  })

  // Track tab changes as screen views
  useEffect(() => {
    if (tabName) {
      Analytics.trackScreen(tabName)
      sprigClient.track(`SCREEN_VIEW_${tabName}`)
    }
  }, [tabName])

  useEffect(() => {
    if (!tabName && tabs.length) goToTabByName(tabs[0].name)
  }, [tabs])

  return (
    <View style={{ flex: 1 }}>
      <HeaderTabs tabs={tabs} activeTabName={tabName} onPress={goToTabByName} testID="tabs" />
      {/* Content */}
      <View style={Styles.container}>
        <NoticeBarContainer containerStyle={Styles.noticeContainer}>
          <View style={Styles.viewContainer} testID={component?.mobile_component_name}>
            {ReactComponent && (
              <ReactComponent
                key={component?.id}
                goToTabByName={goToTabByName}
                contentId={component?.data?.id}
              />
            )}
          </View>
        </NoticeBarContainer>
      </View>
    </View>
  )
}

export default CmsAppTabScreen
