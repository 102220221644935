import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PatientActions from 'APP/Redux/PatientRedux'
import I18n from 'APP/Services/i18n'
import { Colors, Images } from 'APP/Themes'
import Button from 'APP/Converse/Button'
import Config from 'APP/Config'
import SelectableCard from 'APP/Converse/SelectableCard'
import OnboardingScreen from 'APP/Converse/Screens/OnboardingScreen'
import styled from 'styled-components/native'
import { useDevTools } from 'APP/Lib/DevTools'
import { isCommitmentsRequiredState } from 'APP/Sagas/PatientSagas'


const StyledCommitmentContainer = styled.View`
  gap: ${({ theme }) => theme.metrics.baseMargin}px;
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin * 2}px;
`

const StyledButtonContainer = styled.View`
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin}px;
`

function CommitmentScreen() {
  const dispatch = useDispatch()
  const [selectedCommitments, setSelectedCommitments] = useState<string[]>([])

  const { setToolName, setToolFunction } = useDevTools()
  const isCommitmentScreenRequired = useSelector(isCommitmentsRequiredState)

  useEffect(() => {
    setToolName('Skip')
    setToolFunction(() => () => {
      dispatch(PatientActions.submitCommitment())
    })
    return () => {
      setToolFunction(null)
      setToolName(null)
    }
  }, [])

  useEffect(() => {
    if (!isCommitmentScreenRequired) {
      dispatch(PatientActions.submitCommitment())
    }
  }, [isCommitmentScreenRequired])

  const commitments = Config.COMMITMENT.commitments

  const handleOnSubmit = () => {
    dispatch(PatientActions.submitCommitment())
  }

  const handleCommitmentPress = (commitment: string) => {
    if (selectedCommitments.includes(commitment)) {
      setSelectedCommitments(selectedCommitments.filter((c) => c !== commitment))
    } else {
      setSelectedCommitments([...selectedCommitments, commitment])
    }
  }
  if (!isCommitmentScreenRequired) {
    return null
  }
  return (
    <OnboardingScreen
      title={I18n.t('CommitmentScreen.title')}
      subtitle={I18n.t('CommitmentScreen.subtitle')}
    >
      <StyledCommitmentContainer>
        {commitments.map((commitment, index) => {
          const IconComponent =
            Images['commitments'][commitment as keyof (typeof Images)['commitments']]

          return (
            <SelectableCard
              key={index}
              title={I18n.t(`CommitmentScreen.commitments.${commitment}.title`)}
              subtitle={I18n.t(`CommitmentScreen.commitments.${commitment}.description`)}
              icon={<IconComponent fillLine={Colors.text} accentFillLine={Colors.accent} />}
              isSelected={selectedCommitments.includes(commitment)}
              onPress={() => handleCommitmentPress(commitment)}
              testID={`commitment-${commitment}`}
            />
          )
        })}
      </StyledCommitmentContainer>
      <StyledButtonContainer>
        <Button
          disabled={selectedCommitments.length !== commitments.length}
          onPress={handleOnSubmit}
          title={I18n.t('CommitmentScreen.ctaButton')!!}
          widthVariant="full"
          analyticsName="agreeCommitment"
          testID="commitment-cta-button"
        />
      </StyledButtonContainer>
    </OnboardingScreen>
  )
}

export default CommitmentScreen
