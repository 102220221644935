interface Back {
  enabled: boolean
}

interface Progress {
  current: number
  total: number
}

interface Loading {
  steps: string[]
}

export enum DescriptionStyle {
  card = 'card',
  card_with_aside = 'card_with_aside',
}

export interface MemberAppFullscreen {
  back: Back
  progress?: Progress
  loading?: Loading
  description_heading?: string
  description_subheading?: string
  description_title?: string
  description_aside?: string
  description_content?: string
  description_style?: DescriptionStyle
  description_icon?: string
}
