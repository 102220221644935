import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { View, Image, Text, Modal } from 'react-native'
import { BodyPartSelector } from 'APP/Components/LegacyBodyPartSelector'

import I18n from 'APP/Services/i18n'
import { isMobile } from 'APP/Helpers/checkPlatform'

// Actions
import LoggerActions from 'APP/Redux/LoggerRedux'

//Components
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import { PrimaryButton, SecondaryButton } from 'APP/Components/Buttons'
import ZoomHint from 'APP/Components/ZoomHint'

// Styles
import { Images } from 'APP/Themes'
import Styles from './style'

export class RightIcon extends React.Component {
  state = {
    open: false,
  }

  toggleOpen = () => {
    if (!this.state.open && typeof this.props.log) {
      this.props.log(`BodyPartScreen. Tooltip opened.`)
    }
    this.setState(({ open }) => ({ open: !open }))
  }

  render() {
    const { maxSelectionCount } = this.props

    return (
      <Fragment>
        <View styles={{ position: 'relative' }}>
          <DialogueTouchableOpacity
            style={Styles.rightIconButton}
            onPress={this.toggleOpen}
            analyticsName="More info"
          >
            <Image
              source={Images.questionMarkCircle}
              style={Styles.rightIcon}
              resizeMode={'contain'}
            />
          </DialogueTouchableOpacity>
          {this.state.open && (
            <View style={Styles.popoverWindow}>
              <View style={Styles.popoverTail} />
              <View style={Styles.popoverContent}>
                <Text style={Styles.popText}>{I18n.t('BodyPart.hint.title')}</Text>
                <Text style={Styles.popSubtitle}>
                  {I18n.t('ImageMap.hint.description', { maxSelectionCount })}
                </Text>
              </View>
            </View>
          )}
        </View>
        <Modal
          statusBarTranslucent
          visible={this.state.open}
          transparent
          onRequestClose={this.toggleOpen}
        >
          <DialogueTouchableOpacity
            activeOpacity={1.0}
            style={Styles.popoverOverlayButton}
            onPress={this.toggleOpen}
            analyticsName="Overlay"
          />
        </Modal>
      </Fragment>
    )
  }
}

class BodyPartScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedValues: [],
      maxReached: false,
    }

    this.updateRouterNav()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.navigation.isFocused()) {
      this.updateRouterNav()
    }
  }

  updateRouterNav() {
    this.props.navigation.setOptions({
      headerRight: () => (
        <RightIcon
          log={this.props && this.props.log}
          maxSelectionCount={this.props.maxSelectionCount}
        />
      ),
    })
  }

  defaultNotices = []
  maxReachedNotices = [
    {
      title: I18n.t('ImageMap.notice.maxReached.title', {
        maxSelectionCount: this.props.maxSelectionCount,
      }),
    },
  ]

  setZoomRef = (node) => {
    if (node) {
      this.zoomRef = node
    }
  }

  resetZoomScale() {
    if (this.zoomRef) {
      this.zoomRef.reset()
    }
  }

  disableSubmit() {
    if (this.state.selectedValues.length >= this.props.minSelectionCount) return false
    return true
  }

  onReset = () => {
    this.setState({ selectedValues: [], maxReached: false })
    this.resetZoomScale()
  }

  onSubmit = () => {
    this.props.submit(this.state.selectedValues)
    this.props.navigation?.pop()
  }

  onChange = (values) => {
    this.setState({ selectedValues: values })

    if (values.length >= this.props.maxSelectionCount) {
      this.setState({ maxReached: true })
    } else {
      this.setState({ maxReached: false })
    }
  }

  getNotices() {
    if (this.state.maxReached) return this.maxReachedNotices
    return this.defaultNotices
  }

  render() {
    const { selectedValues } = this.state
    const { resourceId, maxSelectionCount, minSelectionCount } = this.props
    return (
      <NoticeBarContainer
        top
        float
        centerText
        containerStyle={Styles.container}
        style={Styles.notice}
        localNotices={this.getNotices()}
      >
        <BodyPartSelector
          zoom
          zoomRef={this.setZoomRef}
          maximumZoomScale={8}
          style={Styles.bodyPicker}
          innerContainerStyle={Styles.bodyPickerInnerContainer}
          imageScale={0.9}
          resourceId={resourceId}
          maxSelectionCount={maxSelectionCount}
          minSelectionCount={minSelectionCount}
          selectedValues={selectedValues}
          lang={I18n.baseLocale}
          onChange={this.onChange}
        />
        <View style={Styles.footer}>
          <SecondaryButton
            style={Styles.button}
            onPress={this.onReset}
            analyticsName="BodyPart.actions.reset"
          >
            {I18n.t('BodyPart.actions.reset')}
          </SecondaryButton>
          <PrimaryButton
            analyticsName="Submit"
            disabled={this.disableSubmit()}
            style={Styles.button}
            onPress={this.onSubmit}
          >
            {I18n.t('BodyPart.actions.submit')}
          </PrimaryButton>
        </View>
        {isMobile() ? <ZoomHint style={Styles.zoomHintOverlay} /> : null}
      </NoticeBarContainer>
    )
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    ...props.route?.params,
    log: (message) => dispatch(LoggerActions.log(message)),
  }
}

BodyPartScreen.defaultProps = {
  minSelectionCount: 1,
  maxSelectionCount: 5,
  onSubmit: () => {},
}

export default connect(null, mapDispatchToProps)(BodyPartScreen)
