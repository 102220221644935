import { call, put, retry, select } from 'redux-saga/effects'

// Actions
import AccountLinkingActions from 'APP/Redux/AccountLinkingRedux'
import LoginActions from 'APP/Redux/LoginRedux'
import LoggerActions from 'APP/Redux/LoggerRedux'

// Services
import Multipass from 'APP/Services/Multipass'

// Sagas
import { loginState, logout, checkRenewAuth, refreshAuth, processLoginSuccess } from './AuthSagas'

const RETRY_LIMIT = 3
const DELAY_TIME = 1500

export function* requestAccountLinking(action = {}) {
  // If accessToken is expired request new one
  yield call(checkRenewAuth)

  const { accountCreation } = action
  const login = yield select(loginState)
  const multipass = Multipass.create(login)
  const {
    refreshToken,
    secondaryAccessToken,
    customClaims: { sso: ssoStatus, 'sso-connection': claimConnection },
  } = login

  // Request linking
  try {
    const payload = !accountCreation ? secondaryAccessToken : undefined
    yield retry(RETRY_LIMIT, DELAY_TIME, multipass.linkUserAccount, payload)
  } catch (e) {
    const stringify = JSON.stringify(e)
    const errorType = accountCreation ? 'creation' : 'linking'
    yield put(AccountLinkingActions.accountLinkingFailure(stringify, errorType))
    yield put(LoggerActions.error(`Error requesting account linking ${stringify}`))
    return
  }

  yield put(LoginActions.clearSecondaryAccessToken())
  yield put(LoginActions.clearConnection())

  if (accountCreation) {
    // It is required for the user to re-give explicit consent, which means we cannot log them in silently.
    // https://auth0.com/docs/authenticate/login/configure-silent-authentication#error-responses
    // By using the connection in the SSO claim, auth0 bypasses some of the auth steps for the user.
    yield put(LoginActions.loginRequestWithConnection(claimConnection))
    yield put(AccountLinkingActions.accountLinkingSuccess())
    return
  }

  yield put(AccountLinkingActions.accountLinkingSuccess())

  // Request token refresh
  try {
    yield call(refreshAuth, refreshToken)
  } catch (e) {
    yield put(LoggerActions.error(`Error requesting token refresh ${JSON.stringify(e)}`))
    yield call(logout, { reason: 'Error requesting token refresh ' })
    return
  }

  try {
    yield call(processLoginSuccess, ssoStatus, true)
  } catch (e) {
    const stringify = JSON.stringify(e)
    yield put(LoggerActions.error(`Error calling processLoginSuccess ${stringify}`))
    yield put(LoginActions.loginFailure(stringify))
  }
}
