import { StyleSheet } from 'react-native'
import { Colors, Fonts } from 'APP/Themes'

export const Styles = StyleSheet.create({
  leftText: {
    ...Fonts.style.body,
    fontSize: 16,
    lineHeight: 20,
    color: Colors.text,
  },
  rightText: {
    ...Fonts.style.body,
    fontSize: 16,
    lineHeight: 20,
    color: Colors.darkText,
  },
  dateContainer: {
    height: 12,
  },
  date: {
    ...Fonts.style.caption,
    color: Colors.textSecondary,
  },
  link: {
    ...Fonts.style.bodyLargeBold,
    fontSize: 16,
    lineHeight: 20,
    textDecorationLine: 'underline',
  },
})
