import React from 'react'

import ProgressBar from 'APP/Components/ProgressBar'

import {
  Styles,
  SearchContainer,
  ContentContainer,
  StyledSearchField,
  ButtonContainer,
} from './style'
import { isWeb } from 'APP/Helpers/checkPlatform'
import IntakeBottomSheet from 'APP/Components/Intake/IntakeBottomSheet'
import Button from 'APP/Converse/Button'

const SearchCard = ({
  title,
  placeholder,
  searchTerm,
  onClearSearchButtonPress,
  disabled,
  onTextChange,
  running,
  content,
  style,
  ctaTitle,
  ctaOnPress,
  ctaDisabled,
  ctaOnCancel,
}) => {
  const Header = (
    <SearchContainer>
      <StyledSearchField
        placeholder={placeholder}
        showClearButton={searchTerm && searchTerm.length > 0}
        onClearButtonPress={onClearSearchButtonPress}
        disabled={disabled}
        value={searchTerm}
        onChange={onTextChange}
      />
      <ProgressBar total={1} current={1} processing show={running} />
    </SearchContainer>
  )

  if (isWeb()) {
    return (
      <ContentContainer style={style}>
        {Header}
        {content}
        {ctaOnPress && ctaTitle && (
          <ButtonContainer>
            {ctaOnCancel && <Button testID="cancel-button" title="cancel" onPress={ctaOnCancel} />}
            <Button
              testID="submit-button"
              title={ctaTitle}
              onPress={ctaOnPress}
              disabled={ctaDisabled}
            />
          </ButtonContainer>
        )}
      </ContentContainer>
    )
  }

  return (
    <IntakeBottomSheet
      title={title}
      ContentHeaderComponent={Header}
      ContentBodyComponent={content}
      style={style}
      scrollStyle={Styles.scroll}
      scrollContentStyle={Styles.scrollContent}
      contentContainerStyle={Styles.contentContainer}
    />
  )
}

export default SearchCard
