import { autoRehydrate } from 'redux-persist'
import { createLogger } from 'redux-logger'
import Config from 'APP/Config'
import createSagaMiddleware from 'redux-saga'
import { configureStore } from '@reduxjs/toolkit'

import { createMiddleware } from 'APP/Lib/VideoSession/middleware'
import { attachEventListeners } from 'APP/Lib/VideoSession/eventListener'
import * as Sentry from '@sentry/react-native'

import * as R from 'ramda'
import RehydrationServices from 'APP/Services/RehydrationServices'
import sentryMiddleware from './SentryMiddleware'
import SessionManager from 'APP/NativeModules/SessionManager'
import AppSessionActions from 'APP/Redux/AppSessionRedux'
import rootSaga from 'APP/Sagas'
import rootReducer from 'APP/Redux'
import { logDdError } from 'APP/Lib/Datadog'

/* ------------- Saga Middleware ------------- */
const sagaMiddleware = createSagaMiddleware({
  onError: (err, errorInfo) => {
    console.error('Unhandled Exception in Sagas.', err)
    Sentry.captureException(err)
    logDdError(err.message, errorInfo.sagaStack)
    // Note: relies on store being hoisted from below. Sorry not sorry.
    store.dispatch(AppSessionActions.setSystemError(String(err), true))
  },
})

const middleware = [
  sagaMiddleware,
  sentryMiddleware,
  /* ------------- video sesion kit Middleware ----------- */
  createMiddleware(SessionManager),
]

/* ------------- Logger Middleware ------------- */
if (__DEV__) {
  const SAGA_LOGGING_BLACKLIST = [
    'EFFECT_TRIGGERED',
    'EFFECT_RESOLVED',
    'EFFECT_REJECTED',
    'persist/REHYDRATE',
  ]
  // the logger master switch
  const USE_LOGGING = Config.DEBUG_SETTINGS.reduxLogging
  // silence these saga-based messages
  // create the logger
  const logger = createLogger({
    predicate: (_, { type }) => USE_LOGGING && R.not(R.includes(type, SAGA_LOGGING_BLACKLIST)),
  })
  middleware.push(logger)
}

// Now we're ready to create the store
const store = configureStore({
  reducer: rootReducer,
  /* ------------- Assemble Middleware ------------- */
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: true,
    }).concat(middleware),
  devTools: __DEV__,

  /* ------------- AutoRehydrate Enhancer ------------- */
  enhancers: (defaultEnhancers) => defaultEnhancers.concat(autoRehydrate()),
})

attachEventListeners(store, SessionManager)

// kick off root saga
sagaMiddleware.run(rootSaga)

// Hydrate store. This is also responsible for kicking off the startup action.
RehydrationServices.updateReducers(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
