import React, { useEffect } from 'react'
// eslint-disable-next-line no-restricted-imports
import { Image, Linking, Text, View } from 'react-native'
import styled from 'styled-components/native'
import { Appearance } from 'react-native-appearance'
import { useDispatch, useSelector } from 'react-redux'
import Config from 'APP/Config'

// Actions
import PatientHistoryActions from 'APP/Redux/PatientHistoryRedux'

// Components
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import BannerCard from 'APP/Components/BannerCard'
import ServiceButtonCard from 'APP/Components/ServiceButtonCard'
import RichTextRender from 'APP/Components/RichTextRenderer'
import ListItem from 'APP/Converse/ListItem'
import Icon from 'APP/Converse/Icon'
import { ResponsiveScrollView } from 'APP/Converse/Layout'

// Services
import I18n from 'APP/Services/i18n'
import { getCtaIcon } from 'APP/Lib/CtaInfoHelpers'
import UrlBuilder from 'APP/Services/UrlBuilder'
import Analytics from 'APP/Services/Analytics'
import { getMicroAppTitle, MICRO_APP_TRACKING_TEXT } from 'APP/Lib/MicroAppHelpers'

// Styling
import Styles, { StyledScopeOfPracticeTitle, StyledScopeOfPracticeSubtitle } from './style'
import { Colors, Fonts } from 'APP/Themes'
import { normalizeServiceCategory } from 'APP/Lib/ContentHelpers'
import { getImageAspectRatio } from 'APP/Lib/StylingHelpers'
import sprigClient from 'APP/Services/Sprig'
import { bigExternalLogos, wideExternalLogos } from 'APP/Themes/Images'

const ServiceContainer = styled(View)`
  flex-direction: ${({ theme }) => (theme.isSmallScreen ? 'column' : 'row')};
  flex-wrap: ${({ theme }) => (theme.isSmallScreen ? 'nowrap' : 'wrap')};
  justify-content: space-between;
  align-content: stretch;
  gap: ${({ theme }) =>
    theme.isSmallScreen ? theme.metrics.baseMargin : theme.metrics.baseMargin * 2}px;
  margin-left: ${({ theme }) => theme.metrics.baseMargin * 1.5}px;
  margin-right: ${({ theme }) => theme.metrics.baseMargin * 1.5}px;
`

const ServiceCardContainer = styled(View)`
  flex-basis: ${({ theme }) => (theme.isSmallScreen ? 'auto' : '47%')};
`

const WIDE_ASPECT_RATIO = 1.5

const ServiceCategory = (props) => {
  const { navigation, route } = props
  const { name } = { ...route?.params, ...props }
  const dispatch = useDispatch()
  const { serviceCategories } = useSelector((state) => state.content)
  const { profile } = useSelector((state) => state.patient)
  const eligibleServices = profile?.eligibleServices

  const foundService = serviceCategories?.find((service) => service.name === name)
  const serviceCategory = foundService && normalizeServiceCategory(foundService, eligibleServices)

  const services = serviceCategory?.services
  const { stressScoreToWBI } = useSelector((state) => state.features)

  useEffect(() => {
    Analytics.trackEvent('cms_content_loaded', {
      screen_name: 'serviceCategory',
      trigger: serviceCategory?.name,
    })
  }, [serviceCategory?.name])

  const handleServiceClick = ({ actionType, actionValue, name }) => {
    sprigClient.track(`TILE_CLICKED_${serviceCategory.name}_${name}`)

    const placeholderHealthIssueTypeId = serviceCategory.placeholderHealthIssueTypeId
    if (actionType === 'episode_creation') {
      if (stressScoreToWBI && actionValue === Config.EPISODE_CREATION_COMMANDS.wbiAssessment) {
        dispatch(PatientHistoryActions.getOrCreateWbiEpisodeRequest(null))
      } else {
        dispatch(
          PatientHistoryActions.launchEpisodeCommand(actionValue, placeholderHealthIssueTypeId)
        )
      }
    } else if (actionType === 'url') {
      Linking.openURL(actionValue)
    } else if (actionType === 'navigation') {
      const { appId, microAppProps } = Config.MICROAPPS_CONFIG['navigation']
      if (appId) {
        const title = getMicroAppTitle(appId)
        Analytics.trackEvent('button_click', {
          button_text: title,
          button_value: 'Open microapp',
          trigger: `${MICRO_APP_TRACKING_TEXT[appId]} - ${serviceCategory?.name}`,
        })
        navigation.navigate('microApp', {
          microAppProps: { appId, ...microAppProps },
          title,
        })
      }
    }
  }

  const renderServices = () => {
    return (
      <ServiceContainer>
        {services.map((service, index) => {
          const { title, shortSubtitle, icon, tag, actionType } = service

          const iconProps = icon
            ? { variant: 'svgUrl', src: icon?.url }
            : { variant: 'material', src: 'chat-plus-outline' }

          return (
            <ServiceCardContainer key={index} testID="SERVICE_CARD">
              <ServiceButtonCard
                iconTitle={<Icon color="accent" {...iconProps} />}
                title={title}
                description={shortSubtitle}
                tag={tag}
                onPress={() => handleServiceClick(service)}
                external={actionType === 'url'}
              />
            </ServiceCardContainer>
          )
        })}
      </ServiceContainer>
    )
  }

  const renderEmergencyServicesWarning = () => {
    const iconTitle = (
      <Icon
        name={I18n.t('ServiceCategory.emergencyService.icon')}
        size={Fonts.size.large}
        color={Colors.darkText}
      />
    )
    return (
      <View style={Styles.emergencyServicesContainer} testID="EMERGENCY_SERVICES_WARNING">
        <BannerCard
          iconTitle={iconTitle}
          title={I18n.t('ServiceCategory.emergencyService.title')}
          description={I18n.t('ServiceCategory.emergencyService.description')}
        />
      </View>
    )
  }

  const renderPrivacyPolicy = () => {
    const privacyUrl = UrlBuilder.privacyUrl()

    const openURL = (url) => {
      Linking.openURL(url).catch((reason) => {
        console.log('openUrl error', url, reason)
      })
    }

    return (
      <Text style={Styles.privacy} testID="PRIVACY_POLICY">
        {I18n.t('ServiceCategory.privacyPolicy.text')}{' '}
        <Text style={Styles.link} onPress={() => openURL(privacyUrl)}>
          {I18n.t('HomeScreen.privacyPolicy.link')}
        </Text>
        .
      </Text>
    )
  }

  const handleNavigation = () => {
    const scope = serviceCategory.scopeOfPractice
    let uri = UrlBuilder.cmsScopeUrl(scope.id, Appearance.getColorScheme())

    Analytics.trackEvent('button_click', {
      button_value: 'Navigate to cms',
      button_text: scope.title,
      trigger: `Navigate to ${scope.title} from Intro Screen`,
    })
    navigation.navigate('cmsScreen', { uri, showShareButton: false })
  }

  const renderScopeOfPractice = () => {
    return (
      <View>
        <Text style={Styles.sectionHeader}>{I18n.t('ServiceCategory.learnMore')}:</Text>
        <View style={Styles.scope}>
          <ListItem
            title={I18n.t('ServiceCategory.scopeOfPractice.title')}
            TitleComponent={StyledScopeOfPracticeTitle}
            subtitle={serviceCategory.scopeOfPractice.subtitle}
            SubtitleComponent={StyledScopeOfPracticeSubtitle}
            onPress={() => handleNavigation()}
          />
        </View>
      </View>
    )
  }

  const ctaIcon = getCtaIcon(serviceCategory?.iconName)
  const iconAspectRatio = getImageAspectRatio(ctaIcon)
  const isWideIcon = iconAspectRatio > WIDE_ASPECT_RATIO

  const isBigLogo = bigExternalLogos.includes(serviceCategory?.iconName)
  const isWideLogo = wideExternalLogos.includes(serviceCategory?.iconName)

  return (
    <ResponsiveScrollView>
      <NoticeBarContainer>
        <View style={Styles.headerContainer}>
          <Image
            style={
              isBigLogo
                ? [Styles.bigLogo, isWideLogo && Styles.bigLogoGutter]
                : [Styles.headerLogo, isWideIcon && Styles.wideLogo]
            }
            source={ctaIcon}
          />
          <Text style={Styles.titleText} numberOfLines={1}>
            {serviceCategory?.title}
          </Text>
          <View style={Styles.subtitle}>
            <RichTextRender text={serviceCategory?.longSubtitle} />
          </View>
          {serviceCategory?.showEmergencyServices && renderEmergencyServicesWarning()}

          {services?.length > 0 && renderServices()}
          {serviceCategory?.scopeOfPractice && renderScopeOfPractice()}
          {serviceCategory?.showPrivacyPolicy && renderPrivacyPolicy()}
        </View>
      </NoticeBarContainer>
    </ResponsiveScrollView>
  )
}

export default ServiceCategory
