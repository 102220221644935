import i18n from 'i18next'
import Config from 'APP/Config'
import Moment from 'moment-timezone'
import { getLocales } from 'react-native-localize'
import 'moment/locale/fr'
import 'moment/locale/de'
import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import resourcesToBackend from 'i18next-resources-to-backend'

import EN from 'APP/I18n/en.json'
import FR from 'APP/I18n/fr.json'

import { isWeb } from 'APP/Helpers/checkPlatform'
import { getBrandVersionedI18n } from 'APP/Helpers/brandVersionedUtils'

const locale = getLocales()[0].languageCode || ''

const languageDetector = isWeb()
  ? LanguageDetector
  : {
      init: Function.prototype,
      type: 'languageDetector',
      detect: () => locale,
      cacheUserLanguage: Function.prototype,
    }

const getResources = () => {
  const sources = {
    en: EN,
    fr: FR,
  }

  let resources = {}
  Config.SUPPORTED_LANGUAGES.forEach((lang) => {
    resources[lang] = {
      translation: getBrandVersionedI18n(lang, sources[lang], Config.BRAND_VERSION),
    }
  })

  return resources
}
const resources = getResources()

let commonOptions = {
  fallbackLng: Config.DEFAULT_LANGUAGE,
  supportedLngs: Config.SUPPORTED_LANGUAGES,
  preload: Config.SUPPORTED_LANGUAGES,
  initImmediate: false,
  ns: ['translation', 'services-and-options', 'health-issue-types'],
  backend: {
    backends: [resourcesToBackend(resources), HttpBackend],
    backendOptions: [
      null,
      { loadPath: `${Config.CMS.baseUrl}/translations/{{ns}}/?locale={{lng}}` },
    ],
  },
}

const initOptions = isWeb()
  ? {
      ...commonOptions,
      detection: {
        order: ['querystring', 'navigator'],
        convertDetectedLanguage: (lng) => lng.slice(0, 2),
      },
      nonExplicitWhitelist: false,
      whitelist: Config.SUPPORTED_LANGUAGES,
    }
  : {
      ...commonOptions,
      compatibilityJSON: 'v3', // Use old plurals behavior TODO: migrate to new version https://www.i18next.com/misc/migration-guide#v20.x.x-to-v21.0.0
    }

i18n.use(languageDetector).use(ChainedBackend).init(initOptions)

i18n.locale = isWeb() ? i18n.language : locale

// Define global string replacements
i18n.options.interpolation.defaultVariables = {
  productName: i18n.t('ProductName'),
}

Object.defineProperty(i18n, 'baseLocale', {
  get: function () {
    if (isWeb()) return i18n.language

    const languages = Object.keys(resources)
    const defaultLocale = Config.DEFAULT_LANGUAGE

    if (languages.includes(locale)) {
      return locale
    }

    if (languages.includes(defaultLocale)) {
      return defaultLocale
    }

    return languages[0]
  },
})

Moment.locale(i18n.baseLocale)

const communicationLanguage = i18n.baseLocale === 'fr' ? 'french' : 'english'
i18n.communicationLanguage = communicationLanguage

i18n.serverString = (serverStringObj) => {
  return (i18n.baseLocale === 'fr' ? serverStringObj?.fr : serverStringObj?.en) ?? ''
}

export default i18n
