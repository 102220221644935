// TODO(WEB): Implement react-native-appearance shim
import React from 'react'

export const Appearance = {
  getColorScheme: () => 'light',
  addChangeListener: () => {
    return {
      remove: () => {},
    }
  },
}

export const AppearanceProvider = ({ children }) => {
  return <>{children}</>
}
