import moment from 'moment'
import { NativeEventEmitter, NativeModules } from 'react-native'
import AppleHealthKit from 'react-native-health'
import base64 from 'base-64'
import { WellnessCenter } from '@dialogue/services'
import { transformDateLimit, formatDateToISOString } from './DateHelpers'
import * as Sentry from '@sentry/react-native'
import AsyncStorage from '@react-native-community/async-storage'
import I18n from 'APP/Services/i18n'
import { logDdError } from 'APP/Lib/Datadog'
export * from 'react-native-health'

const { ActivityName, MetricName } = WellnessCenter.Challenges.Types

export const getDailyStepCountSamplesAsync = (options) => {
  return new Promise((resolve, reject) => {
    AppleHealthKit.getDailyStepCountSamples(options, (err, results) => {
      return err ? reject(err) : resolve(results)
    })
  })
}

export const getAnchoredWorkoutsAsync = (options) => {
  return new Promise((resolve, reject) => {
    AppleHealthKit.getAnchoredWorkouts(options, (err, results) => {
      return err ? reject(err) : resolve(results)
    })
  })
}

export const transformWorkoutOrSampleToMetrics = (metric, { start, end, value }) => {
  const handler = {
    [MetricName.STEPS]: () => {
      return {
        metric: MetricName.STEPS,
        value: Math.max(1, Math.round(value)),
      }
    },
    [MetricName.DISTANCE]: () => {
      return {
        metric: MetricName.DISTANCE,
        value: Math.max(1, Math.round(value)),
      }
    },
    [MetricName.DURATION]: () => {
      return {
        metric: MetricName.DURATION,
        value: Math.max(moment(end).diff(moment(start), 'minutes'), 1),
      }
    },
    [MetricName.CALORIES]: () => {
      return {
        metric: MetricName.CALORIES,
        value: Math.max(1, Math.round(value)),
      }
    },
  }[metric]
  return handler()
}

export const makeTransformDailySamplesToActivities = (activity, metrics) => {
  return (samples) => {
    return samples.map(({ startDate, endDate, value }) => ({
      activity: activity,
      activity_time: formatDateToISOString(startDate),
      unique_id: base64.encode(`${activity}:${startDate}:${endDate}:${value}`),
      metrics: metrics.map((m) =>
        transformWorkoutOrSampleToMetrics(m, { start: startDate, end: endDate, value })
      ),
    }))
  }
}

/**
 * Transform samples options to be compatible with `addMetricsTracker`.
 * Apple Health Kit doesn't take into consideration milliseconds.
 * When a request sample is made with a date range with milliseconds as `2022-03-07 22:29:09.069000`.
 * Returns can contain an activity happening at `2022-03-07 22:29:09.010000` and `addMetricsTracker` request returns a 400.
 * To prevent this error a cast is made to transform `fromDate|toDate` to `2022-03-07 22:29:09.000|2022-03-07 22:29:09.999`.
 * This ensure no out of bounds range.
 * @param {Object} options
 * @param {string} options.startDate - startDate of a sample.
 * @param {string} options.endDate - endDate of a sample.
 * @returns {Object} addMetricsParams
 * @returns {string} addMetricsParam.startDate - ISO Date.
 * @returns {string} addMetricsParam.endDate - ISO Date.
 * @returns {string} addMetricsParam.fromDate - ISO Date, cast to the start of a second.
 * @returns {string} addMetricsParam.toDate - ISO Date, cast to the end of a second.
 */
export const transformSamplesOptionsToAddMetricsParams = (options = {}) => {
  return {
    startDate: formatDateToISOString(options.startDate),
    endDate: formatDateToISOString(options.endDate),
    fromDate: moment(formatDateToISOString(options.startDate)).startOf('second').toISOString(),
    toDate: transformDateLimit(options.endDate, moment()).endOf('second').toISOString(),
  }
}

export const transformStepsCountSamplesToActivities = makeTransformDailySamplesToActivities(
  ActivityName.STEPS,
  [MetricName.STEPS]
)

export const convertMilesToMeters = (miles) => {
  return miles * 1609.344
}

export const transformDailyAnchoredWorkoutSamples = ({ anchor, data }, activities) => {
  if (!data) return []

  return data
    .filter(({ distance, activityName }) => {
      return distance > 0 && activities.includes(activityName.toLowerCase())
    })
    .map(({ start, end, distance, activityName }) => ({
      activity: activityName.toLowerCase(),
      activity_time: formatDateToISOString(start),
      unique_id: base64.encode(`${anchor}:${activityName}:${start}:${end}`),
      metrics: [
        {
          metric: MetricName.DISTANCE,
          value: Math.max(1, Math.round(convertMilesToMeters(distance))),
        },
        {
          metric: MetricName.DURATION,
          value: Math.max(moment(end).diff(moment(start), 'minutes'), 1),
        },
      ],
    }))
}

function addTrackerSentryBreadcrumb({ startDate, endDate }, samplesLength, methodName) {
  Sentry.addBreadcrumb({
    category: 'tracker',
    level: samplesLength > 0 ? 'info' : 'warning',
    message: `AppleHealthKit samples ${samplesLength > 0 ? 'fulfilled' : 'empty'}`,
    data: {
      method: methodName,
      size: samplesLength,
      startDate,
      endDate,
    },
  })
}

export const getDailyStepCountActivitySamples = async (options) => {
  const samples = await getDailyStepCountSamplesAsync(options)
  const samplesLength = samples.length

  addTrackerSentryBreadcrumb(options, samplesLength, 'getDailyStepCountActivitySamples')
  return transformStepsCountSamplesToActivities(samples)
}

export const getDailyAnchorActivitySamples = async (options, activities) => {
  const samples = await getAnchoredWorkoutsAsync(options)
  const samplesLength = samples.data.length

  addTrackerSentryBreadcrumb(options, samplesLength, 'getDailyAnchorActivitySamples')
  return transformDailyAnchoredWorkoutSamples(samples, activities)
}

export const getAllActivitySamples = async (options) => {
  const { startDate, endDate } = transformSamplesOptionsToAddMetricsParams(options)

  const samples = await Promise.all([
    getDailyStepCountActivitySamples({ startDate, endDate }),
    getDailyAnchorActivitySamples({ startDate, endDate }, [
      ActivityName.RUNNING,
      ActivityName.SWIMMING,
      ActivityName.CYCLING,
    ]),
  ])

  return samples.flatMap((a) => a)
}

/**
 * Register a listener to react on new data.
 */
export const listenToAllActivities = (callback) => {
  const activities = ['Cycling', 'Running', 'StepCount', 'Swimming', 'Walking', 'Workout']

  const listeners = activities.map((activity) => {
    return new NativeEventEmitter(NativeModules.AppleHealthKit).addListener(
      `healthKit:${activity}:new`,
      () => callback(null, activity)
    )
  }, [])

  return () => {
    listeners.forEach((listener) => listener.remove())
  }
}

// If HealthKit cache needs to be reset
// bump the version ONLY
const HK_CACHE_VERSION = '1'
const HK_CACHE_PREFIX = 'healthKitActivitiesCache'
export const HK_CACHE_KEY_NAME = `${HK_CACHE_PREFIX}.v${HK_CACHE_VERSION}`

export async function cleanupUnusedCache() {
  try {
    const allASKeys = await AsyncStorage.getAllKeys()
    const HKUnusedKeys = allASKeys.filter(
      (key) => key !== HK_CACHE_KEY_NAME && key.startsWith(HK_CACHE_PREFIX)
    )
    if (!HKUnusedKeys.length) return
    await AsyncStorage.multiRemove(HKUnusedKeys)
    Sentry.addBreadcrumb({
      category: 'tracker',
      level: 'info',
      message: `AppleHealthKit cache was cleared`,
      data: {
        removedKeys: HKUnusedKeys,
        newKey: HK_CACHE_KEY_NAME,
      },
    })
  } catch (e) {
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
  }
}
cleanupUnusedCache()

export const limitCache = (healthKitActivitiesCache) => {
  const CACHE_LIMIT_DATE = moment().subtract(3, 'months').format(I18n.t('DateFormat'))

  if (!healthKitActivitiesCache) return {}

  const sortedCache = Object.fromEntries(Object.entries(healthKitActivitiesCache).sort())
  const CACHE_ACTIVITIES_LIMIT = 1012

  Object.keys(sortedCache).every((date) => {
    if (
      moment(date).isBefore(CACHE_LIMIT_DATE) ||
      Object.values(sortedCache).reduce((length, activities) => length + activities.length, 0) >
        CACHE_ACTIVITIES_LIMIT
    ) {
      delete sortedCache[date]
      return true
    }
    return false
  })

  return sortedCache
}

export const setHealthKitCache = (healthKitActivitiesCache) => {
  const limitedCache = limitCache(healthKitActivitiesCache)
  AsyncStorage.setItem(HK_CACHE_KEY_NAME, JSON.stringify(limitedCache))
}

export const getHealthKitCache = async () => {
  const healthKitActivitiesCache = await AsyncStorage.getItem(HK_CACHE_KEY_NAME)
  return limitCache(JSON.parse(healthKitActivitiesCache))
}

export const clearHealthKitCache = () => {
  return AsyncStorage.removeItem(HK_CACHE_KEY_NAME)
}
