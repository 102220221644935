import { Platform, StyleSheet } from 'react-native'
import { ApplicationStyles, Metrics, Colors } from 'APP/Themes'

export default StyleSheet.create({
  getListItem: (maxWidth) => ({
    margin: Metrics.baseMargin * 0.75,
    backgroundColor: Colors.elementsBg,
    borderRadius: 16,
    width: maxWidth,
    ...ApplicationStyles.shadow,
  }),
  imageWrapper: {
    position: 'relative',
    overflow: 'hidden',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  image: {
    aspectRatio: Platform.select({
      ios: 1.57 / 1,
      android: 1.57 / 1,
      native: 2 / 1,
      default: 2 / 1,
    }),
    width: '100%',
  },
  description: {
    padding: Metrics.baseMargin,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  infoSection: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    marginTop: Metrics.baseMargin / 2,
  },
  tag: {
    textTransform: 'capitalize',
    paddingLeft: Metrics.baseMargin / 2,
  },
})
