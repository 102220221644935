import { createActions, createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  requestAccountLinking: ['accountCreation'],
  resetAccountLinking: null,
  accountLinkingSuccess: null,
  accountLinkingFailure: ['error', 'errorType'],
})

export const AccountLinkingTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  running: false,
  error: null,
  errorType: null,
})

/* ------------- Reducers ------------- */

export const resetLinking = (state) => state.merge(INITIAL_STATE)

export const linkingRunning = (state) =>
  state.merge({ running: true, error: null, errorType: null })

export const linkingSuccess = (state) =>
  state.merge({ running: false, error: null, errorType: null })

export const linkingFailure = (state, { error, errorType }) =>
  state.merge({ running: false, error, errorType })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REQUEST_ACCOUNT_LINKING]: linkingRunning,
  [Types.RESET_ACCOUNT_LINKING]: resetLinking,
  [Types.ACCOUNT_LINKING_SUCCESS]: linkingSuccess,
  [Types.ACCOUNT_LINKING_FAILURE]: linkingFailure,
})
