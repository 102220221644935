import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const useShowBookingSheet = (appointmentType) => {
  const { confirmNoneOfTheseTimesWorkForMe } = useSelector((state) => state.features)

  const showSheet = useMemo(() => {
    const isMHSInitial = !!['smwb_mhs_initial', 'pc_mhs_core_initial', 'eap_mhs_initial'].find(
      (type) => appointmentType?.includes(type)
    )
    return confirmNoneOfTheseTimesWorkForMe && isMHSInitial
  }, [appointmentType, confirmNoneOfTheseTimesWorkForMe])

  return showSheet
}

export default useShowBookingSheet
