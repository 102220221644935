import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import InactiveChatInfoCard from 'APP/Components/InfoCard/InactiveChatInfoCard'
import { shouldRenderComponent } from 'APP/Lib/ContentHelpers'
import ServiceCategoryTile from 'APP/Components/ServiceCategoryTile'
import I18n from 'APP/Services/i18n'
import styled from 'styled-components/native'
import Typography from 'APP/Converse/Typography'
import { ResponsiveScrollView } from 'APP/Converse/Layout'
import { UnreadMessages } from 'APP/Components/UnreadMessages'

export const CtaContainer = styled.View`
  flex-direction: ${({ theme }) => (theme.isSmallScreen ? 'column' : 'row')};
  padding: 0px ${({ theme }) => theme.metrics.baseMargin * 1.5}px;
  flex-wrap: wrap;
  gap: ${({ theme }) =>
    theme.isSmallScreen ? theme.metrics.baseMargin : theme.metrics.baseMargin * 2}px;
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin}px;
`
// We need this to allign with grid gap and flex-basis: 46%;
const UnreadMessagesContainer = styled.View`
  margin-left: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  padding-right: ${({ theme }) =>
    theme.isSmallScreen ? `${theme.metrics.baseMargin / 2}px` : '4%'};
`

export const TitleContainer = styled.View`
  padding: ${({ theme }) => theme.metrics.baseMargin * 1.25}px 0px;
`

const GetCareScreen_legacy = () => {
  const { serviceCategories } = useSelector((state) => state.content)
  const { profile } = useSelector((state) => state.patient)
  const eligibleServices = profile?.eligibleServices

  const filteredServiceCategories = useMemo(
    () =>
      serviceCategories?.reduce((filteredItems, item) => {
        if (shouldRenderComponent(item?.service_filter, eligibleServices)) {
          filteredItems.push(item)
        }

        return filteredItems
      }, []),
    [serviceCategories, eligibleServices]
  )

  const isEligible = eligibleServices && Object.keys(eligibleServices).length > 0

  if (!isEligible) {
    return <InactiveChatInfoCard />
  }

  return (
    <ResponsiveScrollView testID="GET_CARE_VIEW_LEGACY">
      <UnreadMessagesContainer>
        <UnreadMessages amountOfDays={1} hideNavigateToInboxBtn />
      </UnreadMessagesContainer>
      <TitleContainer>
        <Typography variant="h2" color="text" numberOfLines={2} align="center">
          {I18n.t('HomeScreen.subWelcomeOpen')}
        </Typography>
      </TitleContainer>
      {Boolean(filteredServiceCategories) && (
        <CtaContainer>
          {filteredServiceCategories.map((item) => (
            <ServiceCategoryTile key={item.id} name={item.name} />
          ))}
        </CtaContainer>
      )}
    </ResponsiveScrollView>
  )
}

export default GetCareScreen_legacy
