import moment from 'moment'
import { formatDateToISOString } from './DateHelpers'

export const makeDateRangeIterator = function* (options) {
  const startDate = formatDateToISOString(options.startDate)
  const endDate = formatDateToISOString(options.endDate)
  const interval = options?.interval || [1, 'day']

  let currentRangeStart = moment(startDate)
  const needsIterate = () => {
    const isBetween = currentRangeStart.isBetween(startDate, endDate, undefined, '[)')
    return isBetween
  }

  while (needsIterate()) {
    let currentRangeEnd = currentRangeStart.clone().add(...interval)
    currentRangeEnd = currentRangeEnd.isAfter(endDate) ? moment(endDate) : currentRangeEnd
    const range = [currentRangeStart.toISOString(), currentRangeEnd.toISOString()]
    yield range
    currentRangeStart = currentRangeEnd
  }
}

export const mapDateRangeAsync = async (options, iteratee) => {
  const it = makeDateRangeIterator(options)
  let result = it.next()
  while (!result.done) {
    await iteratee(result.value)
    result = it.next()
  }
}
