import { useCallback, useEffect, useRef, useState } from 'react'

/**
 * Custom hook to manage the refreshing state for a pull-to-refresh component. This hook serves two main purposes:
 * 1. Ensures that the refresh spinner is visible for a minimum duration, improving UX for fast API calls.
 * 2. Maintains a separate refreshing state so that network requests triggered by other parts of the app
 *    do not interfere with the spinner's visibility.
 *
 * @param {any} refreshableData - Data that is being refreshed. Used to re-trigger the effect when data changes.
 * @param {Function} onRefreshProp - Function to call when the refresh action is triggered.
 * @param {number} [minRefreshingDuration=700] - Minimum time (in milliseconds) the spinner should be visible.
 *
 * @returns {Object} - An object containing the 'refreshing' state and the 'onRefresh' callback function.
 */
export const useRefreshControlHandlers = (
  refreshableData,
  onRefreshProp,
  minRefreshingDuration = 700
) => {
  const [refreshing, setRefreshing] = useState(false)
  const timeoutRef = useRef({
    id: undefined,
    refreshLanchedAt: undefined,
  })

  useEffect(() => {
    if (!timeoutRef.current.refreshLanchedAt) return

    const cleanup = () => {
      if (timeoutRef.current.id) {
        clearTimeout(timeoutRef.current.id)
        timeoutRef.current.id = undefined
      }
    }
    const actualTime = Date.now() - timeoutRef.current.refreshLanchedAt
    const timeLeft = Math.max(0, minRefreshingDuration - actualTime)

    timeoutRef.current.id = setTimeout(() => {
      setRefreshing(false)
      cleanup()
    }, timeLeft)

    return cleanup
  }, [refreshableData])

  const onRefresh = useCallback(() => {
    timeoutRef.current.refreshLanchedAt = Date.now()
    setRefreshing(true)
    if (onRefreshProp) {
      onRefreshProp({ refresh: true })
    }
  }, [onRefreshProp])

  return { refreshing, onRefresh }
}
