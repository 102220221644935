import React from 'react'
import { useSelector } from 'react-redux'

import RecentCarePlans from 'APP/Components/RecentCarePlans'

import InactiveChatInfoCard from 'APP/Components/InfoCard/InactiveChatInfoCard'

import Styles from './style'

const CarePlansScreen = ({ goToTabByName }) => {
  const { completedCarePlans } = useSelector((state) => state.history)

  const { profile } = useSelector((state) => state.patient)

  const eligibleServices = profile?.eligibleServices
  const isEligible = eligibleServices && Object.keys(eligibleServices).length > 0

  const hasCarePlans = completedCarePlans?.length > 0

  if (!isEligible && !hasCarePlans) {
    return <InactiveChatInfoCard />
  } else {
    return (
      <RecentCarePlans
        style={!hasCarePlans && Styles.noCarePlans}
        goToTabByName={goToTabByName}
        fromChatScreen
      />
    )
  }
}

export default CarePlansScreen
