import React from 'react'
import { View } from 'react-native'

import Analytics from 'APP/Services/Analytics'

import { getFormattedEpisodeItem, isEpisodeVisible } from 'APP/Lib/EpisodeListHelpers'

import Styles from './style'

import { ResponsiveFlatList } from 'APP/Converse/Layout'
import { useNavigation } from '@react-navigation/native'
import Row from './row'

const EpisodeList = ({
  healthIssueTypeIcons,
  orderedEpisodes,
  episodes,
  channels,
  practitioners,
  login,
  maxItems,
  listSpacer,
}) => {
  const navigation = useNavigation()

  const data = orderedEpisodes
    .slice(0, maxItems)
    .filter((id) => isEpisodeVisible(episodes[id], channels[id]))
    .map((id) =>
      getFormattedEpisodeItem(
        episodes[id],
        channels[id],
        practitioners,
        login,
        healthIssueTypeIcons
      )
    )

  const keyExtractor = (item) => item.channelId

  const onEpisodeClicked = (item) => {
    Analytics.trackEvent('button_click', {
      button_value: 'Episode clicked',
    })
    navigation.navigate('conversation', { channelId: item.channelId })
  }

  return (
    <View style={Styles.episodesStateContainer} testID="EPISODE_LIST_VIEW">
      <View style={Styles.episodesList}>
        <ResponsiveFlatList
          ListHeaderComponent={null}
          enableEmptySections
          data={data}
          renderItem={({ item }) => (
            <Row item={item} onPress={() => onEpisodeClicked(item)} practitioners={practitioners} />
          )}
          keyExtractor={keyExtractor}
        />
      </View>
      {listSpacer && <View style={Styles.listSpacer} />}
    </View>
  )
}

export default EpisodeList
