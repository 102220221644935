import { all, call, put, select } from 'typed-redux-saga'
import {
  NewAppointment,
  GenderAttributeDetails,
  CulturalBackgroundAttributeDetails,
  AdditionalInterestAttributeDetails,
  AppointmentBookingExperience,
} from '@dialogue/timekeeper'
import moment from 'moment'

import { SagaActionProps } from 'APP/Models/Actions'
import CoreData from 'APP/Services/CoreData'
import { getBio } from 'APP/Services/CMS'
import {
  getAppointmentsApi,
  getAvailabilitiesApi,
  getProvidersApi,
} from 'APP/Store/utils/timekeeper'
import { selectAccessToken } from 'APP/Redux/LoginRedux'

import {
  GetAvailabilitiesParams,
  ProviderDetailsData,
  ProviderAttributes,
  UpdateAppointmentParams,
} from './types'
import { providerBookingActions } from './slice'

export function* getAvailabilities({ payload }: SagaActionProps<GetAvailabilitiesParams>) {
  try {
    const api = yield* call(getAvailabilitiesApi)
    const {
      appointment_type,
      location,
      language,
      start_at,
      end_at,
      provider,
      genders,
      cultural_backgrounds,
      additional_interests,
    } = payload

    const startAt = moment(start_at).format()
    const endAt = moment(end_at).format()

    const response = yield* call(
      api.getAvailabilities,
      appointment_type,
      location,
      language,
      startAt,
      endAt,
      provider,
      undefined,
      genders,
      cultural_backgrounds,
      additional_interests
    )
    const availabilities = response.data?.data
    const providers: number[] = availabilities?.map(({ provider_id }) => provider_id)
    yield put(providerBookingActions.getProviders([...new Set(providers)]))
    yield put(providerBookingActions.getAvailabilitiesSuccess(availabilities))
  } catch (e) {
    yield put(providerBookingActions.getAvailabilitiesFailure({ error: e as Error }))
  }
}

export function* getProviders({ payload }: SagaActionProps<number[]>) {
  try {
    const accessToken: string = yield select(selectAccessToken)
    const coredataClient = CoreData.create(accessToken)

    const results = yield* all(payload.map((id) => call(coredataClient.getPractitioner, id)))

    for (let result of results) {
      const provider = result.data?.data
      if (provider) {
        yield put(providerBookingActions.getProviderSuccess(provider))
      }
    }
    yield put(providerBookingActions.getProvidersSuccess())
  } catch (e) {
    yield put(providerBookingActions.getProvidersFailure({ error: e as Error }))
  }
}

export function* getProviderBio({ payload: providerId }: SagaActionProps<number>) {
  try {
    const response = yield* call(getBio, providerId)
    const extendedProvider = {
      id: providerId,
      bio: response?.bio,
      jobTitle: response?.job_title,
    }

    yield put(providerBookingActions.getProviderBioSuccess(extendedProvider))
  } catch (e) {
    yield put(providerBookingActions.getProviderBioFailure())
  }
}

function hideIrrelevantValues<Type extends { id: string }>(attribute: Type[]): Type[] {
  return attribute?.filter((a: Type) => a.id !== 'not_disclosed') || []
}

export function* getProvidersAttributes() {
  try {
    const api = yield* call(getProvidersApi)
    const response = yield* call(api.getProvidersAttributes)

    const data = response.data?.data

    const attributes: ProviderAttributes = {
      genders: hideIrrelevantValues<GenderAttributeDetails>(data.genders),
      culturalBackgrounds: hideIrrelevantValues<CulturalBackgroundAttributeDetails>(
        data.cultural_backgrounds
      ),
      additionalInterests: hideIrrelevantValues<AdditionalInterestAttributeDetails>(
        data.additional_interests
      ),
    }
    yield put(providerBookingActions.getProvidersAttributesSuccess({ attributes }))
  } catch (e) {
    yield put(providerBookingActions.getProvidersAttributesFailure({ error: e as Error }))
  }
}

export function* getProviderById({ payload: providerId }: SagaActionProps<number>) {
  try {
    const api = yield* call(getProvidersApi)
    const response = yield* call(api.getById, providerId)
    const data = response.data?.data
    const providerData: ProviderDetailsData = {
      gender: data.gender,
      culturalBackgrounds: data.cultural_backgrounds,
      additionalInterests: data.additional_interests,
      otherInterests: data.other_interests,
      id: data.id,
    }

    yield put(providerBookingActions.getProviderByIdSuccess({ providerData }))
  } catch (e) {
    yield put(providerBookingActions.getProviderByIdFailure({ error: e as Error }))
  }
}

export function* getAppointmentTypes() {
  try {
    const api = yield* call(getAppointmentsApi)
    const response = yield* call(
      api.getAppointmentTypes,
      undefined,
      AppointmentBookingExperience.ProviderBased
    )

    const data = response.data?.data

    yield put(providerBookingActions.getAppointmentTypesSuccess(data))
  } catch (e) {
    yield put(providerBookingActions.getAppointmentTypesFailure({ error: e as Error }))
  }
}

export function* bookAppointment({ payload }: SagaActionProps<NewAppointment>) {
  try {
    const api = yield* call(getAppointmentsApi)
    const response = yield* call(api.create, payload)

    yield put(providerBookingActions.createAppointmentSuccess(response.data?.data))
  } catch (e) {
    yield put(providerBookingActions.createAppointmentFailure({ error: e as Error }))
  }
}

export function* updateAppointment(payload: UpdateAppointmentParams) {
  try {
    const api = yield* call(getAppointmentsApi)
    const { appointment_id, appointment } = payload
    const response = yield* call(api.update, appointment_id, appointment)
    yield put(providerBookingActions.updateAppointmentSuccess(response.data?.data))
  } catch (e) {
    yield put(providerBookingActions.updateAppointmentFailure({ error: e as Error }))
    return { error: e.response }
  }
}
