import React, { useState } from 'react'
import { Keyboard } from 'react-native'
import Spinner from 'react-native-loading-spinner-overlay'
import I18n from 'APP/Services/i18n'
import { validateIsChild, validateIsNotFuture, validateIsValidDate } from 'APP/Lib/DateHelpers'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import Button from 'APP/Converse/Button'
import { ResponsiveScrollView } from 'APP/Converse/Layout'

import { useForm } from 'react-hook-form'
import ControllerInput from 'APP/Components/ControllerFields/ControllerInput'
import ControllerDatePicker from 'APP/Components/ControllerFields/ControllerDatePicker'
import Typography from 'APP/Converse/Typography'
import styled from 'styled-components/native'
import UrlBuilder from 'APP/Services/UrlBuilder'
import Alert from 'APP/Converse/Alert'
import WebContentView from 'APP/Components/WebContentView'
import { familyActions } from 'APP/Store/Family'
import { useNavigation } from '@react-navigation/native'
import { useSelector, useDispatch } from 'react-redux'

const StyledView = styled.View`
  padding: ${({ theme }) => theme.metrics.baseMargin}px;
`

const StyledConsentButtonview = styled.View`
  margin: ${({ theme }) => theme.metrics.baseMargin}px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${({ theme }) => theme.metrics.baseMargin / 2}px;
`

const StyledConsentView = styled.View`
  flex: 1;
  width: 100%;
  max-width: 800px;
  align-self: center;
`

const AddDependentScreen = () => {
  const [focusedField, setFocusedField] = useState(null)
  const { requestRunning } = useSelector((state) => state.family)
  const { givenName: guardianGivenName, familyName: guardianFamilyName } = useSelector(
    (state) => state.patient.profile
  )
  const dispatch = useDispatch()
  const [showConsentForm, setShowConsentForm] = useState(false)
  const [consentLoaded, setConsentLoaded] = useState(false)
  const navigation = useNavigation()

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'all',
  })

  const onSubmitForm = () => {
    Keyboard.dismiss()
    setShowConsentForm(true)
  }

  const onSubmit = (formData) => {
    const childProfile = {
      givenName: formData.givenName,
      familyName: formData.familyName,
      dateOfBirth: formData.dateOfBirth,
    }
    dispatch(familyActions.createChildProfileRequest({ childData: childProfile }))
  }

  const onSubmitConsentForm = () => {
    handleSubmit(onSubmit)()
  }

  const renderGivenNameInput = () => {
    return (
      <ControllerInput
        name="givenName"
        testID="firstNameInput"
        control={control}
        label={I18n.t('FamilyScreen.AddDependentScreen.add.legalName')}
        infotip={String(I18n.t('FamilyScreen.AddDependentScreen.add.hint.givenName'))}
        onFocus={() => setFocusedField('givenName')}
        isFocused={focusedField === 'givenName'}
        rules={{
          required: String(I18n.t('ProfileScreen.error.invalidGivenName')),
        }}
      />
    )
  }

  const renderFamilyNameInput = () => {
    return (
      <ControllerInput
        name="familyName"
        testID="lastNameInput"
        control={control}
        label={I18n.t('FamilyScreen.AddDependentScreen.add.familyName')}
        infotip={String(I18n.t('FamilyScreen.AddDependentScreen.add.hint.familyName'))}
        onFocus={() => setFocusedField('familyName')}
        isFocused={focusedField === 'familyName'}
        rules={{
          required: String(I18n.t('ProfileScreen.error.invalidFamilyName')),
        }}
      />
    )
  }

  const renderDateOfBirthInput = () => {
    return (
      <ControllerDatePicker
        control={control}
        name="dateOfBirth"
        rules={{
          required: {
            value: true,
          },
          validate: {
            isValid: (value) =>
              validateIsValidDate(value) || I18n.t('ProfileScreen.error.invalidDate'),
            isChild: (value) =>
              validateIsChild(value) ||
              I18n.t('FamilyScreen.AddDependentScreen.add.error.invalidBday'),
            isNotFuture: (value) =>
              validateIsNotFuture(value) ||
              I18n.t('FamilyScreen.AddDependentScreen.add.error.invalidFutureDate'),
          },
        }}
        testID="dobFormInput"
        isFocused={focusedField === 'dateOfBirth'}
        label={I18n.t('UpdateProfileScreen.dateOfBirth')}
        onFocus={() => {
          setFocusedField('dateOfBirth')
        }}
        value={getValues().dateOfBirth}
        onSelect={() => {
          setFocusedField(null)
          trigger('dateOfBirth')
        }}
        setValue={setValue}
      />
    )
  }

  const handleGiveConsentPress = () => {
    return Alert.alert(
      I18n.t('DependentConsentScreen.title'),
      String(I18n.t('DependentConsentScreen.body')),
      [
        { text: String(I18n.t('DependentConsentScreen.decline')) },
        {
          text: String(I18n.t('DependentConsentScreen.agree')),
          onPress: onSubmitConsentForm,
        },
      ]
    )
  }
  const handleContentLoad = () => setConsentLoaded(true)

  const handleConsentButtonPress = () => navigation.navigate('family')

  const { givenName, familyName } = getValues()
  const consentUrl =
    givenName && familyName
      ? UrlBuilder.guardianConsentUrl(guardianGivenName, guardianFamilyName, givenName, familyName)
      : ''

  return (
    <NoticeBarContainer>
      <Spinner visible={requestRunning} cancelable={false} />
      {showConsentForm ? (
        <StyledConsentView>
          <WebContentView
            uri={consentUrl}
            onLoad={handleContentLoad}
            busy={requestRunning}
            screen="consent"
          />
          {consentLoaded && (
            <StyledConsentButtonview>
              <Button
                title={String(I18n.t('DependentConsentScreen.decline'))}
                onPress={handleConsentButtonPress}
                testID="DEPENDENT_CONSENT_SCREEN_DECLINE_BUTTON"
                analyticsName="Dependent Consent decline"
                variant="secondary"
                widthVariant="flex"
              />
              <Button
                title={String(I18n.t('DependentConsentScreen.agree'))}
                onPress={handleGiveConsentPress}
                analyticsName="Dependent Consent agree"
                testID="DEPENDENT_CONSENT_SCREEN_AGREE_BUTTON"
                widthVariant="flex"
              />
            </StyledConsentButtonview>
          )}
        </StyledConsentView>
      ) : (
        <ResponsiveScrollView alwaysBounceVertical={false}>
          <StyledView>
            <Typography variant="bodyNormal" align="center">
              {I18n.t('FamilyScreen.AddDependentScreen.add.body')}
            </Typography>
          </StyledView>
          <StyledView>
            {renderGivenNameInput()}
            {renderFamilyNameInput()}
            {renderDateOfBirthInput()}

            <Button
              title={String(I18n.t('FamilyScreen.AddDependentScreen.add.button'))}
              widthVariant="full"
              onPress={onSubmitForm}
              analyticsName="Next"
              testID="UPDATE_DEPENDENT_SCREEN_NEXT_BUTTON"
              disabled={!isDirty || !isValid}
            />
          </StyledView>
        </ResponsiveScrollView>
      )}
    </NoticeBarContainer>
  )
}

export default AddDependentScreen
