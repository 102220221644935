import React from 'react'
import { StyleSheet, View } from 'react-native'
import moment from 'moment'
import ProgressBarCircle from 'APP/Components/ProgressBarCircle'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import Styles, { DAY_ITEM_SIZE, DAY_ITEM_BORDER_WIDTH } from './style'
import { Colors } from 'APP/Themes'

const DayItem = ({
  day,
  selectedDay,
  isAfter,
  isBefore,
  isCurrent,
  text,
  percentage,
  onPress,
  testID = 'dayItem',
}) => {
  let progressDayText = {}

  if (isCurrent) {
    progressDayText = Styles.currentDayText
  }

  if (isBefore) {
    progressDayText = Styles.prevDayText
  }

  return (
    <DialogueTouchableOpacity
      onPress={onPress}
      disabled={isAfter}
      testID={testID}
      accessibilityLabel={moment(day).format('LL')}
    >
      <ProgressBarCircle
        percentage={isAfter ? 0 : percentage}
        radius={DAY_ITEM_SIZE / 2}
        strokeWidth={DAY_ITEM_BORDER_WIDTH}
        primaryColor={isAfter ? Styles.progressBarCircleAfter.color : undefined}
        primaryOpacity={
          isAfter ? Styles.progressBarCircleAfter.opacity : Styles.progressBarCircle.opacity
        }
        textFontSize={Styles.progressBarCircle.fontSize}
        text={text}
        textColor={isAfter ? Colors.buttonSecondaryDisabled : undefined}
        textStyles={progressDayText}
      />
      {selectedDay === day && <View style={Styles.dot} testID={`${testID}Dot`} />}
      <View style={StyleSheet.absoluteFillObject} />
    </DialogueTouchableOpacity>
  )
}

export default DayItem
