import { Image } from 'react-native'
import Card from 'APP/Converse/Card'
import Typography from 'APP/Converse/Typography'
import React, { memo, useCallback, useEffect, useState } from 'react'
import I18n from 'APP/Services/i18n'
import { Images } from 'APP/Themes'
import styled from 'styled-components/native'
import { handlePressContactSupport } from 'APP/Helpers/handlePressContactSupport'
import { useDispatch } from 'react-redux'
import PatientActions from 'APP/Redux/PatientRedux'
import Button from 'APP/Converse/Button'
import { VerifyEmailErrors } from 'APP/Lib/Enums'

const ContentCard = styled(Card)`
  width: 100%;
  max-width: 550px;
  align-items: stretch;
`

const Logo = styled(Image)`
  height: 50px;
  width: 200px;
  margin-top: ${({ theme }) => theme.metrics.baseMargin}px;
  align-self: center;
`

const Content = styled.View`
  padding: ${({ theme }) => theme.metrics.baseMargin}px;
  gap: ${({ theme }) => theme.metrics.baseMargin}px;
  text-align: center;
`

const Page = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background};
`

interface VerifyNewEmailResultScreenProps {
  route: {
    params: {
      errorType?: VerifyEmailErrors
      success?: boolean
      email?: string
    }
  }
}

const VerifyNewEmailResultScreen = ({
  route: {
    params: { errorType, success, email },
  },
}: VerifyNewEmailResultScreenProps) => {
  const [isLoading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const messageKey = success ? 'success' : `error.${errorType}`

  const onAuth = useCallback(() => {
    dispatch(PatientActions.runStartupAfterChangeEmail())
    setLoading(true)
  }, [])

  useEffect(() => {
    setLoading(false)
  }, [success])

  return (
    <Page>
      <ContentCard>
        <Logo source={Images.logoBlue} resizeMode="contain" />
        <Content>
          <Typography variant="h3">{I18n.t(`VerifyNewEmailScreen.${messageKey}.title`)}</Typography>
          <Typography>{I18n.t(`VerifyNewEmailScreen.${messageKey}.body1`, { email })}</Typography>
          {!success && (
            <Typography>{I18n.t(`VerifyNewEmailScreen.${messageKey}.body2`)}</Typography>
          )}
        </Content>
        {success ? (
          <Button
            analyticsName="Submit VerifyNewEmailScreen"
            onPress={onAuth}
            title={I18n.t('VerifyNewEmailScreen.success.continue')}
            widthVariant="full"
            disabled={isLoading}
            isLoading={isLoading}
          />
        ) : (
          <Button
            analyticsName="Submit VerifyNewEmailScreen"
            onPress={handlePressContactSupport}
            title={I18n.t('VerifyNewEmailScreen.error.ctaContactUs')}
            widthVariant="full"
          />
        )}
      </ContentCard>
    </Page>
  )
}

export default memo(VerifyNewEmailResultScreen)
