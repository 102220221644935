import { StyleSheet } from 'react-native'
import { Colors, Fonts, Metrics } from 'APP/Themes'

const dotSize = 14
const tooltipTailSize = 7

export default StyleSheet.create({
  indexBar: {
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: Metrics.baseMargin,
    paddingHorizontal: Metrics.baseMargin * 2,
  },
  indexBarSection: {
    flex: 1,
    paddingHorizontal: 2,
    marginBottom: Metrics.baseMargin * 4.5,
  },
  indexLineDeactivated: {
    backgroundColor: Colors.buttonLinen,
    height: 6,
  },
  indexLineLow: {
    height: 6,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  indexLineLowActive: {
    backgroundColor: Colors.rejectButtonBg,
  },
  indexBarLineDotLow: {
    backgroundColor: Colors.rejectButtonBg,
    width: dotSize,
    height: dotSize,
    bottom: 4,
    marginLeft: -dotSize / 2,
    borderRadius: dotSize,
    borderColor: Colors.rejectButtonBg,
  },
  indexLineMid: {
    height: 6,
  },
  indexLineMidActive: {
    backgroundColor: '#EABB13',
  },
  indexBarLineDotMid: {
    backgroundColor: '#EABB13',
    width: dotSize,
    height: dotSize,
    bottom: 4,
    marginLeft: -dotSize / 2,
    borderRadius: dotSize,
    borderColor: '#EABB13',
  },
  indexLineHigh: {
    height: 6,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
  indexLineHighActive: {
    backgroundColor: Colors.acceptButtonBg,
  },
  indexBarLineDotHigh: {
    backgroundColor: '#27C595',
    width: dotSize,
    height: dotSize,
    marginLeft: -dotSize / 2,
    bottom: 4,
    borderRadius: dotSize,
    borderColor: '#27C595',
  },
  sentimentIcon: {
    alignSelf: 'center',
    margin: Metrics.baseMargin * 0.75,
  },
  tooltipContainer: {
    position: 'absolute',
    bottom: 0,
    alignItems: 'center',
    backgroundColor: 'yellow',
    paddingLeft: 2,
  },
  tooltip: {
    position: 'absolute',
    top: Metrics.baseMargin * 1.5,
    alignSelf: 'center',
    alignItems: 'center',
    padding: Metrics.baseMargin / 2,
    backgroundColor: Colors.text,
    borderRadius: 4,
    maxWidth: 100,
  },
  tooltipContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tail: {
    position: 'absolute',
    alignSelf: 'center',
    top: -tooltipTailSize,
    transform: [{ rotate: '180deg' }],
    borderLeftWidth: tooltipTailSize,
    borderTopWidth: tooltipTailSize,
    borderRightWidth: tooltipTailSize,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderTopColor: Colors.text,
  },
  tooltipLabel: {
    ...Fonts.style.subheaderBold,
    color: Colors.textInverted,
  },
  scoreValue: {
    ...Fonts.style.subheaderBold,
    color: Colors.textInverted,
  },
  scoreValuePoint: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginRight: Metrics.baseMargin / 4,
  },
})
