import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import styled from 'styled-components/native'
import Checkbox from 'APP/Converse/Inputs/Checkbox'
import Typography from 'APP/Converse/Typography'
import { ShadowView } from 'APP/Converse/ShadowView'

// TODO: Remove reference to primaryText as a background colour here
const StyledTouchableOpacity = styled(TouchableOpacity)<{ isSelected: boolean }>`
  border-radius: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  padding: ${({ theme }) => theme.metrics.baseMargin}px;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.primaryText : theme.colors.elementsBg};
  ${({ theme }) => theme.shadows.soft};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.metrics.baseMargin}px;
`

const StyledContentView = styled(View)`
  flex: 1;
  gap: ${({ theme }) => theme.metrics.baseMargin / 2}px;
`

interface SelectableCardProps {
  title: string
  subtitle: string
  icon?: JSX.Element
  isSelected: boolean
  onPress: () => void
  testID?: string
}

const SelectableCard = ({
  title,
  subtitle,
  icon,
  isSelected,
  onPress,
  testID,
}: SelectableCardProps) => {
  return (
    <ShadowView testID={testID} isSelected={isSelected}>
      <StyledTouchableOpacity onPress={onPress} isSelected={isSelected}>
        <StyledContentView>
          {icon}
          <Typography variant="h3">{title}</Typography>
          <Typography variant="bodySmall">{subtitle}</Typography>
        </StyledContentView>
        <Checkbox onPress={onPress} status={isSelected ? 'checked' : 'unchecked'} />
      </StyledTouchableOpacity>
    </ShadowView>
  )
}

export default SelectableCard
