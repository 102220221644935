// I18n

import { getAdminAreas } from 'APP/Services/AdminAreas'
import Config from 'APP/Config'
import I18n from 'APP/Services/i18n'

const getLanguages = () => {
  return Config.SUPPORTED_LANGUAGES.map((lang) => {
    return {
      key: lang,
      label: I18n.t(`ProfileScreen.${lang}`),
      value: lang,
    }
  })
}

export const KeyLabelArrays = {
  GENDER: [
    { key: 'male', label: I18n.t('ProfileScreen.genders.male'), value: 'male' },
    { key: 'female', label: I18n.t('ProfileScreen.genders.female'), value: 'female' },
    { key: 'non_binary', label: I18n.t('ProfileScreen.genders.nonBinary'), value: 'non_binary' },
    { key: 'two_spirit', label: I18n.t('ProfileScreen.genders.twoSpirit'), value: 'two_spirit' },
    { key: 'i_dont_know', label: I18n.t('ProfileScreen.genders.dontKnow'), value: 'i_dont_know' },
    {
      key: 'prefer_not_to_disclose',
      label: I18n.t('ProfileScreen.genders.preferNotToDisclose'),
      value: 'prefer_not_to_disclose',
    },
    {
      key: 'prefer_to_self_identify',
      label: I18n.t('ProfileScreen.genders.preferToSelfIdentify'),
      value: 'prefer_to_self_identify',
    },
  ],
  PROVINCES: getAdminAreas(),
  LANGUAGES: getLanguages(),
}

// Utility function
const keyLabelToObject = (keyLabelArray) => {
  const object = {}
  keyLabelArray.forEach((keyLabel) => {
    object[keyLabel.key] = keyLabel.label
  })
  return object
}

export const Format = {
  gender: (gender) => {
    const genders = keyLabelToObject(KeyLabelArrays.GENDER)
    return genders[gender]
  },

  jurisdiction: (province) => {
    const provinces = keyLabelToObject(KeyLabelArrays.PROVINCES)
    return provinces[province] || null
  },

  phoneNumber: (phoneNumber) => {
    if (!phoneNumber) {
      return phoneNumber
    } else if (phoneNumber.length === 11) {
      const phoneNumberParts = [
        '+',
        phoneNumber.substr(0, 1),
        ' (',
        phoneNumber.substr(1, 3),
        ') ',
        phoneNumber.substr(4, 3),
        '-',
        phoneNumber.substr(7, 4),
      ]
      return phoneNumberParts.join('')
    } else if (phoneNumber.length === 10) {
      const phoneNumberParts = [
        '(',
        phoneNumber.substr(0, 3),
        ') ',
        phoneNumber.substr(3, 3),
        '-',
        phoneNumber.substr(6, 4),
      ]
      return phoneNumberParts.join('')
    } else {
      return phoneNumber
    }
  },

  language: (language) => {
    const languages = keyLabelToObject(KeyLabelArrays.LANGUAGES)
    return languages[language] || null
  },

  DISPLAY_DATE: 'LL',

  STORE_DATE: I18n.t('DateFormat'),
}
