export const transform = (toTransform, operation) => {
  const type = operation?.type
  let params = operation?.params
  let func

  switch (type) {
    case 'replace':
      func = toTransform?.replace
      // Have to use regex replace because the runtime is guaranteed to
      // have replaceAll
      params = [new RegExp(`${params[0]}`, 'g'), params[1]]
      break
    case 'padStart':
      func = toTransform?.padStart
      // JS padStart uses target length then padding character
      // the opposite of what the API returns
      params = [params[1], params[0]]
      break
    case 'join':
      func = toTransform?.join
      break
    default:
      console.warn(`Unknown operation: ${type}`)
      return toTransform
  }

  if (!func || typeof func !== 'function') {
    console.warn(`Operation: ${type} is not a supported function for ${toTransform}`)
    return toTransform
  }

  return func?.apply(toTransform, params) ?? toTransform
}

export default {
  transform,
}
