import { StyleSheet } from 'react-native'
import { Colors, Fonts, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  scrollViewContentContainer: {
    paddingTop: Metrics.baseMargin,
    paddingBottom: Metrics.bottomSpace + Metrics.baseMargin,
    paddingHorizontal: Metrics.baseMargin * 0.5,
  },
  loading: {
    flex: 1,
  },
  retry: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  retryTitle: {
    ...Fonts.style.h3,
    color: Colors.text,
    marginTop: Metrics.baseMargin * 2,
    marginBottom: Metrics.baseMargin,
  },
  retryCopy: {
    ...Fonts.style.bodyLarge,
    color: Colors.text,
  },
  retryCta: {
    marginTop: Metrics.baseMargin * 2,
    minWidth: 140,
  },
  empty: {
    marginTop: Metrics.baseMargin * 5,
    paddingHorizontal: Metrics.baseMargin * 2,
  },
  emptyTitle: {
    ...Fonts.style.subheaderBold,
    color: Colors.text,
    textAlign: 'center',
    marginBottom: Metrics.baseMargin * 1.25,
  },
  emptyCopy: {
    ...Fonts.style.body,
    color: Colors.text,
    textAlign: 'center',
  },
})
