import {
  addGlobalContexts,
  clearUserData,
  newTracker,
  setUserId,
  trackSelfDescribingEvent,
  trackStructEvent,
} from '@snowplow/browser-tracker'
import Config from 'APP/Config'

export class _Snowplow {
  init() {
    newTracker(Config.SNOWPLOW.NAMESPACE, Config.SNOWPLOW.ENDPOINT, {
      appId: Config.SNOWPLOW.APP_ID,
      contexts: {
        session: true,
      },
    })
  }

  setGlobalContexts = (entities) => {
    addGlobalContexts(entities)
  }

  trackEvent = (schema, properties, trackedEntities) => {
    trackSelfDescribingEvent({
      event: {
        schema: schema,
        data: properties,
      },
      context: trackedEntities,
    })
  }

  trackStructuredEvent = (event) => {
    trackStructEvent(event)
  }

  setSubject = (id) => {
    if (id === null) {
      clearUserData()
      return
    }
    setUserId('' + id)
  }

  trackScreen = () => {
    //No op on web, it uses `trackEvent` instead
  }
}

let Snowplow = new _Snowplow()

export default Snowplow
