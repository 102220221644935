import React from 'react'
import { useDispatch } from 'react-redux'
import { Image, View, Text, TouchableOpacity } from 'react-native'
import DialogueMarkdown from 'APP/Components/DialogueMarkdown'
import { Images, Colors, Metrics } from 'APP/Themes'
import MaterialIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import I18n from 'APP/Services/i18n'
import PatientHistoryActions from 'APP/Redux/PatientHistoryRedux'
import Analytics from 'APP/Services/Analytics'
import sprigClient from 'APP/Services/Sprig'
import Config from 'APP/Config'
import * as NestedNavHelper from 'APP/Nav/NestedNavHelper'
import * as Sentry from '@sentry/react-native'
import { RootScreens } from 'APP/Lib/Enums'

import Styles, { markdownStyles } from './style'
import { Recommender } from '@dialogue/services'
import { logDdError } from 'APP/Lib/Datadog'

const navigationLinks = {
  icbt_toolkits: RootScreens.mind,
}

function DefaultRecommendation({
  recommendation: recommendationText,
  actions,
  onActionPress: onActionPressProp,
  score,
  style,
}) {
  const dispatch = useDispatch()
  const navigateToLink = (action) => {
    try {
      const routeName = navigationLinks[action.link]

      if (!NestedNavHelper.getRouteNames().indexOf(routeName) === -1) {
        throw Error('navigation key does not exist', { cause: [routeName, action] })
      }

      NestedNavHelper.navigate(routeName)
    } catch (err) {
      Sentry.captureException(Error('navigate to link failed', { cause: err }))
      logDdError(err.message, err.stack)
    }
  }
  const onActionPress = (action) => {
    const actionType = action.action
    sprigClient.track('WBI_RECOMMENDATION_CLICKED_' + String(actionType).toUpperCase())
    Analytics.trackEvent('button_click', {
      button_value: actionType,
      trigger: 'wbi recommendation',
      screen_name: 'WellBeingIndexScreen.wellBeingIndexRecommendation',
      wbi_score: score,
    })

    if (typeof onActionPressProp === 'function') {
      onActionPressProp(action)
      return
    }

    switch (actionType) {
      case Recommender.Types.ActionType.NEW_CONSULT:
        if (action.command_post) {
          dispatch(
            PatientHistoryActions.launchEpisodeCommand(
              Config.EPISODE_CREATION_COMMANDS.mhIntake || '/r mh_intake'
            )
          )
        }
        return
      case Recommender.Types.ActionType.LINK:
        navigateToLink(action)
    }
  }
  return (
    <View style={[Styles.container, style]}>
      <View style={Styles.author}>
        <Image source={Images.logoNurse} style={Styles.authorIcon} />
      </View>
      <View style={Styles.textBubbleContainer}>
        <Text style={Styles.authorLabel}>
          {I18n.t('WellBeingIndexScreen.recommendationAuthor')}
        </Text>
        <View style={Styles.textBubble} testID="textBubble">
          <DialogueMarkdown
            markdown={recommendationText?.[I18n.baseLocale]}
            styles={markdownStyles}
          />
          <View style={Styles.textBubbleTail} />
        </View>
        {actions?.map((action, i) => (
          <TouchableOpacity
            key={i}
            onPress={() => onActionPress(action)}
            style={Styles.action}
            testID="recommendationAction"
          >
            <Text style={Styles.actionText} numberOfLines={2}>
              {action.text[I18n.baseLocale]}
            </Text>
            <MaterialIcon
              accessibilityRole="button"
              color={Colors.accent}
              size={Metrics.icons.small}
              name="chevron-right"
            />
          </TouchableOpacity>
        ))}
      </View>
    </View>
  )
}

export default DefaultRecommendation
