import React from 'react'
import { Text, View, Image } from 'react-native'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import moment from 'moment'

import I18n from 'APP/Services/i18n'
import { WellnessCenter } from '@dialogue/services'
import { nth } from 'APP/Lib/Helpers'
import { cleanupMetric } from 'APP/Helpers/Wellness/index'

import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'

import Styles from './style'
import { Images, Metrics, Colors } from 'APP/Themes'
import {
  CHALLENGE_ACTIVITY_ICONS,
  CHALLENGE_TYPE_ICONS,
  challengeOrganizationName,
} from '../constants'

const { Challenges } = WellnessCenter

export const CARD_SIZE = {
  SMALL: 'small',
  LARGE: 'large',
}

const Tag = ({ icon: TagIcon, copy }) => (
  <View style={Styles.tag} testID="tag">
    <TagIcon.Lib name={TagIcon.name} size={Metrics.icons.tiny} style={Styles.tagIcon} />
    {copy && <Text style={Styles.tagCopy}>{copy}</Text>}
  </View>
)

const ChallengeCard = ({
  size = CARD_SIZE.LARGE,
  challenge,
  eligibilityIntervals,
  onPress,
  analyticsName = 'challenge-card',
  testID = 'challenge-card',
}) => {
  const isSmall = size === CARD_SIZE.SMALL
  const { id, status, activity, type, participant, content, end_date, start_date, team, metric } =
    challenge
  const isTeam = type === Challenges.Types.ChallengeType.TEAM
  const isIndividual = type === Challenges.Types.ChallengeType.INDIVIDUAL
  const isCompleted = status === Challenges.Types.ChallengeStatus.COMPLETE
  const shouldShowRank =
    status !== Challenges.Types.ChallengeStatus.UPCOMING &&
    ((isIndividual && participant) || (isTeam && team))
  const shouldShowTotalValue = isCompleted && isIndividual && participant

  const activityTag = activity && {
    icon: CHALLENGE_ACTIVITY_ICONS[activity],
    copy: I18n.t(`WellnessCenter.challenges.activity.${activity}`),
  }
  const typeTag = type &&
    (!isSmall || isTeam) && {
      icon: CHALLENGE_TYPE_ICONS[type],
      copy: !isSmall && I18n.t(`WellnessCenter.challenges.type.${type}`),
    }
  const subtitle = challengeOrganizationName(challenge.tags, eligibilityIntervals)

  return (
    <DialogueTouchableOpacity
      onPress={onPress}
      style={[Styles.container, isSmall && Styles.containerSmall]}
      analyticsName={analyticsName}
      testID={testID}
      key={id}
    >
      <View style={Styles.card}>
        <View>
          <Image
            style={Styles.image}
            // force use of cache on iOS
            source={{ uri: content?.image, cache: 'force-cache' }}
            defaultSource={Images.contentLoading}
            resizeMode="cover"
          />
          <View style={Styles.tagList}>
            {[activityTag, typeTag].filter(Boolean).map((tagProps, index) => (
              <Tag key={index} {...tagProps} />
            ))}
          </View>
          <View style={Styles.tagList}></View>
        </View>
        <View style={Styles.description}>
          <Text style={Styles.title} numberOfLines={3}>
            {content?.title}
          </Text>
          {subtitle && (
            <Text testID="subtitle" style={Styles.subtitle}>
              {subtitle}
            </Text>
          )}
          <View style={Styles.details} testID="details">
            {/* Countdown */}
            <View style={Styles.detailsItem}>
              <MaterialIcon
                name="schedule"
                size={Metrics.icons.tiny}
                color={Colors.accent}
                style={Styles.clockIcon}
              />
              <Text style={Styles.detailsCopy} numberOfLines={1} testID="challengeCountdown">
                {status === Challenges.Types.ChallengeStatus.ONGOING &&
                  `${I18n.t('WellnessCenter.challenges.countdown.ongoing')} ${moment
                    .utc(end_date)
                    .fromNow(true)}`}
                {status === Challenges.Types.ChallengeStatus.UPCOMING &&
                  `${I18n.t('WellnessCenter.challenges.countdown.upcoming')} ${moment
                    .utc(start_date)
                    .fromNow(true)}`}
                {status === Challenges.Types.ChallengeStatus.COMPLETE &&
                  `${moment(start_date).format('MMM D')} - ${moment.utc(end_date).format('MMM D')}`}
              </Text>
            </View>
            {/* Rank */}
            {shouldShowRank && (
              <View style={Styles.detailsItem}>
                <MaterialCommunityIcon
                  name="trophy-outline"
                  size={Metrics.icons.tiny}
                  color={Colors.accent}
                  style={Styles.clockIcon}
                />
                <Text style={Styles.detailsCopy} numberOfLines={1} testID="challengeRank">
                  {type === Challenges.Types.ChallengeType.INDIVIDUAL &&
                    nth(participant.rank, I18n.baseLocale)}
                  {isTeam && nth(team.rank, I18n.baseLocale)}
                  &nbsp;
                  {I18n.t('WellnessCenter.challenges.rank')}
                </Text>
              </View>
            )}
          </View>
          {shouldShowTotalValue && (
            <View style={Styles.totalValue} testID="total-value">
              <Text style={Styles.totalValueText}>
                {cleanupMetric(participant.total_value, metric)}{' '}
                {I18n.t(`WellnessCenter.challenges.metric.${metric}`)}
              </Text>
            </View>
          )}
        </View>
      </View>
    </DialogueTouchableOpacity>
  )
}

export default ChallengeCard
