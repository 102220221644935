import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Metrics } from '../../Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  navContainer: {
    flex: 1,
    backgroundColor: Colors.appBg,
    marginBottom: Metrics.bottomSpace,
  },
  navContainerBottomSpace: {
    flex: 1,
    backgroundColor: Colors.appBg,
    marginBottom: Metrics.bottomSpace,
  },
  navContainerDrawer: {
    flex: 1,
    backgroundColor: Colors.appBg,
    height: Metrics.screenHeight - Metrics.navBarHeight,
    marginBottom: Metrics.bottomSpace,
  },
  navContainerNavbarOverride: {
    flex: 1,
    height: Metrics.screenHeight - Metrics.navBarHeight,
    backgroundColor: Colors.appBg,
    marginBottom: Metrics.bottomSpace,
  },
})
