// TODO(WEB): Implement replacement for chromecast button, or remove on web.
// Might be supported if running chrome?

import React from 'react'
import { View } from 'react-native'

// Lets you add chromecast button anywhere on a screen
export const ChromecastButton = () => <View />

const Chromecast = ({ children }) => {
  return children
}

export default Chromecast
