import React from 'react'
import { Text, View, Animated, Image } from 'react-native'

// Styling
import Styles from './style'
import { Colors, Images } from 'APP/Themes'

import I18n from 'APP/Services/i18n'

export default class VideoCallPacketLossIndicator extends React.Component {
  state = {
    aniOpacityPersistentIndicator: new Animated.Value(0),
    aniOpacityTempIndicator: new Animated.Value(0),
    aniColor: new Animated.Value(0),
    shouldShowTempIndicator: true,
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { hasPacketLoss, hasCriticalPacketLoss } = nextProps
    const hasPacketLossChanged = hasPacketLoss !== state.hasPacketLoss
    const hasCriticalPacketLossChanged = hasCriticalPacketLoss !== state.hasCriticalPacketLoss

    let { tempIndicatorTimeout } = state
    let { shouldShowTempIndicator } = state

    if (hasPacketLossChanged) {
      Animated.timing(state.aniOpacityPersistentIndicator, {
        toValue: hasPacketLoss ? 1 : 0,
        duration: 300,
        useNativeDriver: false,
      }).start()
    }

    if (hasPacketLoss && shouldShowTempIndicator) {
      shouldShowTempIndicator = false
      Animated.timing(state.aniOpacityTempIndicator, {
        toValue: 1,
        duration: 300,
        useNativeDriver: false,
      }).start()

      tempIndicatorTimeout = setTimeout(() => {
        Animated.timing(state.aniOpacityTempIndicator, {
          toValue: 0,
          duration: 500,
        }).start()
      }, 10000)
    }

    if (hasCriticalPacketLossChanged) {
      Animated.timing(state.aniColor, {
        toValue: hasCriticalPacketLoss ? 1 : 0,
        // If we went from no packet loss directly to critical, don't animate from warning to critical
        duration: hasPacketLossChanged ? 0 : 1000,
        useNativeDriver: false,
      }).start()
    }

    return {
      ...state,
      hasPacketLoss,
      hasCriticalPacketLoss,
      tempIndicatorTimeout,
      shouldShowTempIndicator,
    }
  }

  componentWillUnmount() {
    if (this.state.tempIndicatorTimeout) clearTimeout(this.state.tempIndicatorTimeout)
  }

  aniColorInterpolateConfig = {
    inputRange: [0, 1],
    outputRange: [Colors.warningSnackBarBg, Colors.errorSnackBarBg],
  }

  persistentIndicatorStyle = [
    Styles.persistentIndicator,
    {
      opacity: this.state.aniOpacityPersistentIndicator,
      backgroundColor: this.state.aniColor.interpolate(this.aniColorInterpolateConfig),
    },
  ]

  tempIndicatorStyle = [
    Styles.tempIndicator,
    {
      opacity: this.state.aniOpacityTempIndicator,
      backgroundColor: this.state.aniColor.interpolate(this.aniColorInterpolateConfig),
    },
  ]

  renderPersistentIndicator = () => (
    <Animated.View
      style={this.persistentIndicatorStyle}
      testID={
        this.props.hasPacketLoss && this.props.hasCriticalPacketLoss
          ? 'poor-connection-icon-will-be-displayed'
          : 'poor-connection-icon-wont-be-displayed'
      }
    >
      <Image style={Styles.icon} source={Images.poorConnection} />
    </Animated.View>
  )

  renderTempIndicator = () => (
    <Animated.View
      style={this.tempIndicatorStyle}
      testID={
        this.props.hasPacketLoss && this.props.hasCriticalPacketLoss
          ? 'packet-loss-container-will-be-displayed'
          : 'packet-loss-container-wont-be-displayed'
      }
    >
      <View>
        <Image style={Styles.icon} source={Images.poorConnection} />
      </View>
      <View style={Styles.textContainer}>
        <Text numberOfLines={1} adjustsFontSizeToFit={true} style={Styles.title}>
          {I18n.t('VideoCallScreen.packetLossTitle')}
        </Text>
        <Text style={Styles.body}>{I18n.t('VideoCallScreen.packetLossBody')}</Text>
      </View>
    </Animated.View>
  )

  render() {
    const { containerStyle } = this.props
    return (
      <View style={[Styles.container, containerStyle]}>
        {this.renderPersistentIndicator()}
        {this.renderTempIndicator()}
      </View>
    )
  }
}
