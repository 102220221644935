import { memoizeWith } from 'ramda'
import React from 'react'
import { useSelector } from 'react-redux'

/**
 * A Higher-Order Component that conditionally renders a component based on a feature flag.
 * It uses memoization to ensure that the same instance of the component is returned for a given memoization key,
 * which helps in preventing unnecessary re-mounting of the component. All options are required.
 *
 * @param {Object} options - The options for configuring the HOC.
 * @param {string|number} options.memoizationKey - A unique key used for memoizing the component instance. Must be unique for each instance and is required.
 * @param {React.ComponentType} options.enabled - The component to render if the feature is enabled. Required.
 * @param {React.ComponentType} options.default - The fallback component to render if the feature is not enabled. Required.
 * @param {string} options.featureKey - The key to lookup in the `features` state to determine if the feature is enabled. Required.
 *
 * @returns {React.ComponentType} A component that renders either the `enabled` component or the `default` component based on the feature flag.
 *
 * @throws {Error} If `memoizationKey` is not provided, an error is thrown. It is essential to provide all options for the HOC to function correctly.
 *
 * @example
 * const EnhancedComponent = withFeatureEnabled({
 *   enabled: FeatureEnabledComponent,
 *   default: FeatureDisabledComponent,
 *   featureKey: 'newFeatureFlag',
 *   memoizationKey: 'uniqueKeyForThisInstance'
 * });
 */
const withFeatureEnabled = memoizeWith(
  ({ memoizationKey }) => String(memoizationKey),
  ({ enabled: Component, default: ReplacementComponent, featureKey, memoizationKey }) => {
    if (!memoizationKey) {
      throw new Error('withFeatureEnabled: memoizationKey is required')
    }

    if (!featureKey) {
      return ReplacementComponent
    }

    const ComponentWithCondition = (props) => {
      const featureEnabled = useSelector((state) => state.features[featureKey])

      let ComponentToRender = featureEnabled ? Component : ReplacementComponent

      return <ComponentToRender {...props} />
    }

    return ComponentWithCondition
  }
)

export default withFeatureEnabled
