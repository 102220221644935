import { Colors, Fonts, Metrics } from 'APP/Themes'
import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dayContainer: {
    alignItems: 'center',
    flex: 1,
  },
  dayLabel: {
    ...Fonts.style.subheader,
    color: Colors.text,
    paddingBottom: Metrics.baseMargin,
  },
})
