import { StyleSheet } from 'react-native'
import { Colors, Metrics, Fonts } from 'APP/Themes'
import { isWeb } from 'APP/Helpers/checkPlatform'

export const Styles = StyleSheet.create({
  answerContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingRight: Metrics.baseMargin * 2,
    paddingBottom: Metrics.baseMargin,
  },
  answerInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 0,
  },
  answer: {
    display: isWeb() ? 'inline-block' : 'flex',
    borderRadius: 20,
    padding: Metrics.baseMargin,
    minWidth: 240,
    maxWidth: Metrics.screenWidth * 0.8,
    backgroundColor: Colors.patientMsgBg,
  },
  answerErrorIcon: { marginLeft: Metrics.baseMargin },
  answerText: {
    fontSize: 16,
    color: Colors.darkText,
    textAlign: 'right',
  },
  answerImageContainer: {
    flex: 1,
    height: Metrics.screenHeight * 0.2,
  },
  answerImage: {
    resizeMode: 'cover',
    borderRadius: 10,
  },
  answerImageMap: {
    flexGrow: 1,
    height: Metrics.screenHeight * 0.4,
    borderRadius: 10,
    backgroundColor: Colors.appBg,
  },
  messageErrorText: {
    ...Fonts.style.bodySmall,
    color: Colors.text,
    fontWeight: '400',
    padding: Metrics.baseMargin,
  },
  answerRetryText: {
    color: Colors.accent,
    fontWeight: '600',
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
  },
})
