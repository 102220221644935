import { put, call, select, all, take, race } from 'redux-saga/effects'
import moment from 'moment'

// Redux Actions
import HabitActions from 'APP/Redux/HabitsRedux'
import LoggerActions from 'APP/Redux/LoggerRedux'
import { PatientTypes } from 'APP/Redux/PatientRedux'
import { featuresActions } from 'APP/Redux/FeaturesSlice'
import { LoginTypes } from 'APP/Redux/LoginRedux'

// Services
import Config from 'APP/Config'
import { Habits } from '@dialogue/services'
import { getHabit } from '../Services/CMS'
import {
  removeLocalNotificationsForOneHabit,
  updateLocalNotificationsForOneHabit,
  ensureParityWithConfigs,
} from '../Services/LocalNotifications'
import PushNotification from 'react-native-push-notification'
import * as Sentry from '@sentry/react-native'
import I18n from 'APP/Services/i18n'
import { logDdError } from 'APP/Lib/Datadog'

export const selectNotificationConfiguration = (
  state,
  habitId,
  type = Habits.Habits.Types.NotificationType.DO_HABIT
) => {
  const notificationsState = state.habits.notificationConfigurations?.[habitId]
  if (!notificationsState) return

  return {
    ...notificationsState,
    data: (notificationsState.data || []).find((n) => n.type === type),
  }
}

export function* createHabits() {
  const accessToken = yield select(selectAccessToken)

  return {
    Habits: new Habits.Habits.Service(accessToken, Config.HABITS_SERVICE_URL),
    Categories: new Habits.Categories.Service(accessToken, Config.HABITS_SERVICE_URL),
  }
}

export const selectHabitById = (state, habitId) => {
  const { data } = state.habits.habits
  return data.find(({ identifier }) => identifier === habitId)
}

export const selectHasHabitActionInProgress = (state) => {
  return [
    state.habits.removeHabitPrompt.loading,
    state.habits.updateHabitPrompt.loading,
    state.habits.removeHabit.loading,
  ].some((loading) => loading === true)
}

export const selectAccessToken = (state) => state.login.accessToken

// Private methods start
function* getProgressStatsForHabits(habits) {
  const HabitsService = yield call(createHabits)

  let progressStatsByHabit = {}

  for (let i = 0; i < habits.length; i += 1) {
    // Get high level stats and progress this week
    const stats = yield call(HabitsService.Habits.getStats, {
      habitId: habits[i].identifier,
    })

    const shouldFetchProgress = moment().isoWeekday() >= 3 // Wednesday (earliest day in week they could complete a habit 3 times)
    const progress = shouldFetchProgress
      ? yield call(HabitsService.Habits.getDailyProgress, {
          habitId: habits[i].identifier,
          fromDate: moment().isoWeekday(1).format(I18n.t('DateFormat')),
          toDate: moment().format(I18n.t('DateFormat')),
        })
      : []
    const [thirdTimeCompletedOn, timesCompleted] = progress.reduceRight(
      (acc, { progress: { percentage }, day }) => {
        let nextThirdTimeCompletedOn = acc[0]
        let nextTimesCompleted = acc[1]
        if (percentage === 100) nextTimesCompleted++
        if (nextTimesCompleted === 3 && !nextThirdTimeCompletedOn) nextThirdTimeCompletedOn = day
        return [nextThirdTimeCompletedOn, nextTimesCompleted]
      },
      [undefined, 0]
    )

    progressStatsByHabit[habits[i].identifier] = {
      completedFirstTime: stats.commits.done === 1,
      weekly: {
        thirdTimeCompletedOn,
        timesCompleted,
      },
    }
  }

  return progressStatsByHabit
}
// Private methods end

export function* getCategories() {
  try {
    const HabitsService = yield call(createHabits)

    const categories = yield call(HabitsService.Categories.getCategoryList)

    yield put(HabitActions.getCategoriesSuccess(categories))
  } catch (e) {
    yield put(HabitActions.getCategoriesFailure())
  }
}

export function* getTopics({ id }) {
  try {
    if (!id) throw Error('missing required parameters')
    const HabitsService = yield call(createHabits)

    const topics = yield call(HabitsService.Categories.getCategoryTopicList, {
      categoryId: id,
    })

    yield put(HabitActions.getTopicsSuccess(topics))
  } catch (e) {
    yield put(HabitActions.getTopicsFailure())
  }
}

export function* getSingleHabit({ id }) {
  try {
    if (!id) throw Error('missing required parameters')

    const HabitsService = yield call(createHabits)

    const habit = yield call(HabitsService.Habits.getHabitDetails, {
      habitId: id,
    })

    yield put(HabitActions.getSingleHabitSuccess(habit))
  } catch (e) {
    yield put(HabitActions.getSingleHabitFailure())
  }
}

export function* getSingleHabitStats({ id }) {
  try {
    const HabitsService = yield call(createHabits)

    const stats = yield call(HabitsService.Habits.getStats, {
      habitId: id,
    })

    yield put(HabitActions.getSingleHabitStatsSuccess(id, stats))
  } catch (e) {
    yield put(HabitActions.getSingleHabitStatsFailure(id))
  }
}

export function* getSingleHabitProgress({ id, fromDate }) {
  try {
    const HabitsService = yield call(createHabits)

    const progress = yield call(HabitsService.Habits.getDailyProgress, {
      habitId: id,
      fromDate,
      toDate: moment.utc().format(I18n.t('DateFormat')),
    })

    yield put(HabitActions.getSingleHabitProgressSuccess(id, progress))
  } catch (e) {
    yield put(HabitActions.getSingleHabitProgressFailure(id))
  }
}

export function* getHabitList({ date }) {
  try {
    const HabitsService = yield call(createHabits)
    const habits = yield call(HabitsService.Habits.getHabitList, { date })

    const progressStatsByHabit = yield call(getProgressStatsForHabits, habits)

    // 1. "To do" && newest -> oldest
    // 2. "Done" && newest -> oldest
    habits.sort(
      (a, b) =>
        a.status.percentage - b.status.percentage ||
        moment(b.started_at).unix() - moment(a.started_at).unix()
    )
    yield put(HabitActions.getHabitListSuccess(habits, progressStatsByHabit))
  } catch (e) {
    yield put(HabitActions.getHabitListFailure())
  }
}

export function* getHabitContent({ id }) {
  try {
    if (!id) throw Error('missing required parameters')

    const habitContent = yield call(getHabit, id)

    if (!habitContent.id) {
      throw new Error('Habit not in CMS')
    }

    yield put(
      HabitActions.getHabitContentSuccess(id, {
        relatedContent: habitContent.related_content,
        expert: habitContent.expert,
      })
    )
  } catch (e) {
    yield put(HabitActions.getHabitContentFailure(id))
  }
}

export function* startHabit({ id, hasSupportingContent }) {
  try {
    const HabitsService = yield call(createHabits)
    yield call(HabitsService.Habits.startHabit, {
      habitId: id,
      userRequest: {
        prompt: null,
        start_date: moment().format(I18n.t('DateFormat')),
      },
    })
    yield put(
      HabitActions.startHabitSuccess(id, {
        showCupboard: hasSupportingContent,
        showPromptModal: true,
      })
    )
  } catch (e) {
    yield put(HabitActions.startHabitFailure())
  }
}

export function* removeHabit({ id, date }) {
  try {
    if (!id) throw Error('missing required parameters')

    const HabitsService = yield call(createHabits)

    yield call(HabitsService.Habits.stopHabit, {
      habitId: id,
    })

    yield call(removeLocalNotificationsForOneHabit, id)

    yield call(getHabitList, { date })

    yield put(HabitActions.removeHabitSuccess())
  } catch (e) {
    yield put(HabitActions.removeHabitFailure())
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
  }
}

export function* doneHabit({ id, date }) {
  try {
    const HabitsService = yield call(createHabits)
    yield call(HabitsService.Habits.performHabit, {
      habitId: id,
      date,
    })
    yield call(getHabitList, { date })
    yield put(HabitActions.doneHabitSuccess())
  } catch (e) {
    yield put(HabitActions.doneHabitFailure())
  }
}

export function* undoneHabit({ id, date }) {
  try {
    if (!id) throw Error('missing required parameters')

    const HabitsService = yield call(createHabits)
    yield call(HabitsService.Habits.cancelHabit, {
      habitId: id,
      date,
    })

    yield call(getHabitList, { date })

    yield put(HabitActions.undoneHabitSuccess())
  } catch (e) {
    yield put(HabitActions.undoneHabitFailure())
  }
}

export function* getDailyProgressList({ fromDate, toDate }) {
  try {
    if (!fromDate || !toDate) {
      throw Error('missing required parameters')
    }
    const HabitsService = yield call(createHabits)
    const dailyProgressList = yield call(HabitsService.Habits.getDailyProgressList, {
      fromDate: moment(fromDate).format(I18n.t('DateFormat')),
      toDate: moment(toDate).format(I18n.t('DateFormat')),
    })
    yield put(HabitActions.getDailyProgressListSuccess(dailyProgressList))
  } catch (e) {
    yield put(HabitActions.getDailyProgressListFailure())
  }
}

export function* removeHabitPrompt({ id, date }) {
  try {
    if (!id) {
      throw Error('missing required parameters')
    }
    const HabitsService = yield call(createHabits)
    yield call(HabitsService.Habits.removePrompt, {
      habitId: id,
    })
    if (date) {
      yield call(getHabitList, { date })
    }
    yield put(HabitActions.removeHabitPromptSuccess())
  } catch (err) {
    yield put(HabitActions.removeHabitPromptFailure())
  }
}

export function* updateHabitPrompt({ id, prompt, date }) {
  try {
    if (!id || !prompt) {
      throw Error('missing required parameters')
    }
    const HabitsService = yield call(createHabits)
    if (prompt.identifier) {
      yield call(HabitsService.Habits.updateBuiltInPrompt, {
        habitId: id,
        promptId: prompt.identifier,
      })
    } else if (prompt.custom_message) {
      yield call(HabitsService.Habits.updateCustomPrompt, {
        habitId: id,
        customMessage: prompt.custom_message,
      })
    } else {
      throw Error('missing prompt required parameters')
    }
    if (date) {
      yield call(getHabitList, { date })
    }
    yield put(HabitActions.updateHabitPromptSuccess())
  } catch (err) {
    yield put(HabitActions.updateHabitPromptFailure())
  }
}

export function* cancelAllHabitLocalNotifications() {
  try {
    yield call([PushNotification, PushNotification.cancelAllLocalNotifications])
  } catch (err) {
    Sentry.captureException(err)
    logDdError(err.message, err.stack)
  }
}

export function* onHabitNotificationFeatureChange() {
  const isHabitNotificationsDisable = yield select((state) => !state.features.habitNotifications)
  const isWellnessHabitsDisable = yield select((state) => {
    const eligibleServices = state.patient?.profile?.eligibleServices || {}
    return !('wellness_habits' in eligibleServices)
  })

  if (isHabitNotificationsDisable || isWellnessHabitsDisable) {
    yield call(cancelAllHabitLocalNotifications)
  }
}

export function* getAllNotificationConfigurations() {
  try {
    const HabitsService = yield call(createHabits)

    const notifications = yield call(HabitsService.Habits.getAllNotifications, { enabled: true })

    yield put(HabitActions.getAllNotificationConfigurationsSuccess(notifications))
  } catch (err) {
    yield put(HabitActions.getAllNotificationConfigurationsFailure())
    Sentry.captureException(err)
    logDdError(err.message, err.stack)
  }
}

export function* getNotificationConfigurations({ habitId }) {
  try {
    const HabitsService = yield call(createHabits)

    const notifications = yield call(HabitsService.Habits.getNotifications, { habitId })

    yield put(HabitActions.getNotificationConfigurationsSuccess(habitId, notifications))
  } catch (err) {
    yield put(HabitActions.getNotificationConfigurationsFailure(habitId))
    Sentry.captureException(err)
    logDdError(err.message, err.stack)
  }
}

export function* updateNotificationConfiguration({ habitId, notificationType, values }) {
  try {
    const HabitsService = yield call(createHabits)

    const notification = yield call(HabitsService.Habits.updateNotification, {
      habitId,
      notificationType,
      enabled: values.enabled,
      weekDays: values.enabled ? values.weekDays : null,
      reminderTime: values.enabled ? values.reminderTime : null,
    })

    yield call(updateLocalNotificationsForOneHabit, notification)
    yield call(getAllNotificationConfigurations)

    yield put(HabitActions.updateNotificationConfigurationSuccess(habitId, notification))
  } catch (err) {
    yield put(HabitActions.updateNotificationConfigurationFailure())
    Sentry.captureException(err)
    logDdError(err.message, err.stack)
  }
}

export function* watchForHabitNotificationSafetyCheck(action) {
  while (true) {
    // Is explicitly triggered on startup; not aiming to run syncs on live updates.
    // Trigger action WHEN:
    // 1. Member is already logged in and data is successfully fetched
    // 2. Member has just logged in and data is successfully fetched
    const effects = yield race({
      reAuth: all([
        take(PatientTypes.SCRIBE_V2_USER_FETCH_SUCCESS),
        take(featuresActions.setFeatures),
      ]),
      login: all([take(LoginTypes.LOGIN_SUCCESS), take(PatientTypes.SCRIBE_V2_USER_FETCH_SUCCESS)]),
    })

    yield put(
      LoggerActions.log(
        `Habit notifications safety check running after "${Object.keys(effects)[0]}"`
      )
    )

    yield call(action)
  }
}

export function* ensureNotificationParity() {
  const isHabitNotificationsEnabled = yield select((state) => state.features.habitNotifications)
  const isWellnessHabitsEnabled = yield select((state) => {
    const eligibleServices = state.patient?.profile?.eligibleServices || {}
    return 'wellness_habits' in eligibleServices
  })

  if (isHabitNotificationsEnabled && isWellnessHabitsEnabled) {
    try {
      yield call(getAllNotificationConfigurations)

      const notificationConfigs = yield select(
        (state) => state.habits.allNotificationConfigurations.notifications
      )

      yield call(ensureParityWithConfigs, notificationConfigs)
    } catch (err) {
      Sentry.captureException(err)
      logDdError(err.message, err.stack)
    }
  }
}
