import { StyleSheet } from 'react-native'
import { ApplicationStyles, Metrics, Fonts } from 'APP/Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  scrollContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  container: {
    paddingHorizontal: Metrics.baseMargin * 2,
    paddingTop: Metrics.baseMargin * 2,
    paddingBottom: Metrics.baseMargin,
    gap: Metrics.baseMargin,
  },
  smallLink: {
    textDecorationLine: 'underline',
    fontFamily: Fonts.type.bold,
    fontWeight: '500',
  },
  footer: {
    marginTop: 'auto',
    paddingBottom: Metrics.baseMargin * 3,
    textAlign: 'center',
  },
})
