import React, { useEffect, useState } from 'react'
import { KeyboardAvoidingView } from 'react-native'
import { AppearanceProvider } from 'react-native-appearance'
import { Provider as ReduxProvider } from 'react-redux'
import { ActionSheetProvider } from '@expo/react-native-action-sheet'
import * as Sentry from '@sentry/react-native'
import { initialWindowMetrics, SafeAreaProvider } from 'react-native-safe-area-context'
import { StripeProvider } from '@stripe/stripe-react-native'
import { GestureHandlerRootView } from 'react-native-gesture-handler'

import Config from 'APP/Config'
import RootContainer from 'APP/Containers/RootContainer'
import store from 'APP/Store'

import { initConfig } from 'APP/Lib/Initializers'

import { NavProvider } from 'APP/Nav/Provider'
import AppThemeProvider from 'APP/Theme/AppThemeProvider'
import DevToolsProvider from 'APP/Lib/DevTools'
import { isIOS } from 'APP/Helpers/checkPlatform'
import { PortalProvider } from 'APP/Helpers/Portal'

const style = { flex: 1 }

async function init(cb) {
  await initConfig()
  cb(true)
}

export const App = () => {
  const [initializersConfig, setInitRequired] = useState(false)

  useEffect(() => {
    init(setInitRequired)
  }, [])

  if (!initializersConfig) return null

  return (
    <SafeAreaProvider initialMetrics={initialWindowMetrics}>
      <Sentry.TouchEventBoundary>
        <KeyboardAvoidingView style={style} behavior={isIOS() ? 'padding' : null}>
          <GestureHandlerRootView style={style}>
            <StripeProvider publishableKey={Config.STRIPE_PUBLIC_KEY}>
              <ActionSheetProvider>
                <AppearanceProvider>
                  <AppThemeProvider>
                    <DevToolsProvider>
                      <ReduxProvider store={store}>
                        <NavProvider>
                          <PortalProvider>
                            <RootContainer />
                          </PortalProvider>
                        </NavProvider>
                      </ReduxProvider>
                    </DevToolsProvider>
                  </AppThemeProvider>
                </AppearanceProvider>
              </ActionSheetProvider>
            </StripeProvider>
          </GestureHandlerRootView>
        </KeyboardAvoidingView>
      </Sentry.TouchEventBoundary>
    </SafeAreaProvider>
  )
}

export default App
