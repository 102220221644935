import React, { useLayoutEffect } from 'react'
import { LayoutAnimation, View } from 'react-native'
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  useAnimatedScrollHandler,
  interpolate,
  interpolateColor,
  Extrapolate,
  useAnimatedReaction,
  runOnJS,
} from 'react-native-reanimated'
import { LinearGradient } from 'expo-linear-gradient'
import Color from 'color'
import { useOnLayout } from 'APP/Hooks/Layout'
import * as Haptics from 'expo-haptics'

import { Colors } from 'APP/Themes'
import Styles, { StyledSlide } from './style'
import NetworkWrapper from 'APP/Components/NetworkWrapper'
import { animationConfiguration } from '../helpers'
import { isWeb } from 'APP/Helpers/checkPlatform'

const Slide = ({ sliderEnabled, slideHeight, children, ...rest }) => {
  return (
    <StyledSlide sliderEnabled={sliderEnabled} slideHeight={slideHeight} {...rest}>
      {React.cloneElement(children, {
        limitedSpace: sliderEnabled,
      })}
    </StyledSlide>
  )
}

const Indicator = ({ scrollOffset, index, slideHeight }) => {
  const animatedStyle = useAnimatedStyle(() => {
    const input = scrollOffset.value / slideHeight
    const animatedColor = interpolateColor(
      input,
      [index - 1, index, index + 1],
      [Colors.deactivatedElementBg, Colors.text, Colors.deactivatedElementBg]
    )
    const size = interpolate(input, [index - 2, index, index + 2], [6, 8, 6], Extrapolate.CLAMP)

    return {
      height: size,
      width: size,
      backgroundColor: animatedColor,
    }
  })

  return <Animated.View style={[Styles.indicator, animatedStyle]} />
}

const triggerHapticFeedback = () => {
  if (isWeb()) return
  Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light)
}
const MIN_SLIDE_HEIGHT = 566
const GRADIENT_COLORS = [
  Color(Colors.appBg).alpha(0).rgb().string(),
  Color(Colors.appBg).alpha(0.6).rgb().string(),
  Colors.appBg,
]
let prevScrollHeight
const Slider = ({ children, slideOffset, bottomCta, isScrollEnabled }) => {
  const [{ height: layoutScrollHeight }, onContainerLayout] = useOnLayout()
  const scrollHeight = layoutScrollHeight || (!!bottomCta && prevScrollHeight)
  const slideHeight =
    (scrollHeight && Math.max(scrollHeight - slideOffset, MIN_SLIDE_HEIGHT)) || undefined
  // if the slider is not enabled, content is rendered in a simple scroll view
  const sliderEnabled = !isWeb() && (!slideHeight || slideHeight > MIN_SLIDE_HEIGHT)

  const scrollOffset = useSharedValue(0)
  const scrollHandler = useAnimatedScrollHandler({
    onScroll: (event) => {
      scrollOffset.value = event.contentOffset.y
    },
  })

  // haptic feedback on slide change if slider is enabled
  useAnimatedReaction(
    () => {
      const index =
        (slideHeight && sliderEnabled && Math.round(scrollOffset.value / slideHeight)) || 0
      return index
    },
    (result, previous) => {
      if (previous !== null && result !== previous) {
        runOnJS(triggerHapticFeedback)()
      }
    },
    [slideHeight, sliderEnabled]
  )

  useLayoutEffect(() => {
    if (layoutScrollHeight && prevScrollHeight !== layoutScrollHeight && animationConfiguration)
      LayoutAnimation.configureNext(animationConfiguration)
    // preserve the height for the next render with bottom cta only
    // to reduce loading state when viewing previous results
    if (layoutScrollHeight && bottomCta) {
      prevScrollHeight = layoutScrollHeight
    }
  }, [layoutScrollHeight, bottomCta])

  return (
    <View style={Styles.container}>
      <View style={Styles.scrollContainer}>
        <Animated.ScrollView
          vertical
          scrollEventThrottle={1}
          snapToInterval={sliderEnabled ? slideHeight : undefined}
          decelerationRate="fast"
          snapToAlignment="start"
          disableIntervalMomentum
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            alignItems: 'center',
            justifyContent: 'center',
            flex: !slideHeight ? 1 : undefined,
          }}
          scrollEnabled={isScrollEnabled}
          style={Styles.scroll}
          onScroll={scrollHandler}
          onLayout={onContainerLayout}
          testID="slider"
        >
          <NetworkWrapper loading={!slideHeight}>
            {React.Children.map(children, (child, index) => {
              const isLast = index === children.length - 1
              return (
                <Slide
                  key={index}
                  scrollOffset={scrollOffset}
                  isLast={isLast}
                  scrollHeight={scrollHeight}
                  slideHeight={slideHeight}
                  sliderEnabled={sliderEnabled}
                  style={isLast && { marginBottom: slideOffset }}
                >
                  {child}
                </Slide>
              )
            })}
          </NetworkWrapper>
        </Animated.ScrollView>
        <LinearGradient
          colors={GRADIENT_COLORS}
          locations={[0, 0.3, 1]}
          style={[Styles.gradient, { height: slideOffset }]}
        />
      </View>
      {!!bottomCta && <View style={Styles.bottomCta}>{bottomCta}</View>}
      <View style={Styles.indicatorContainer}>
        {!!slideHeight &&
          sliderEnabled &&
          React.Children.map(children, (_, index) => {
            return (
              <Indicator
                key={index}
                index={index}
                scrollOffset={scrollOffset}
                slideHeight={slideHeight}
              />
            )
          })}
      </View>
    </View>
  )
}

export default Slider
