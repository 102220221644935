import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Fonts, Metrics } from 'APP/Themes'
import { Platform } from 'react-native'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  container: {
    padding: Metrics.baseMargin * 1.5,
    backgroundColor: Colors.elementsBg,
    borderBottomWidth: 1,
    borderBottomColor: Colors.elementsBorder,
  },
  name: {
    ...Fonts.style.h3,
    color: Colors.text,
    marginBottom: Metrics.baseMargin / 2,
    paddingTop: Platform.OS === 'android' ? Metrics.baseMargin * 0.5 : 0,
  },
  subTitle: {
    ...Fonts.style.body,
    color: Colors.text,
  },
  pronouns: {
    ...Fonts.style.body,
    color: Colors.textSecondary,
  },
})
