import React, { useEffect, useRef } from 'react'
import { Animated, View } from 'react-native'
import { style } from './style'

const Arrow = ({ isActive, color }) => {
  const animation = useRef(new Animated.Value(0)).current

  const initPartAStyle = () => {
    return [
      style.partA,
      { backgroundColor: color },
      {
        transform: [
          {
            translateY: animation.interpolate({
              inputRange: [0, 1],
              outputRange: [2, 0],
            }),
          },
          {
            translateX: animation.interpolate({
              inputRange: [0, 1],
              outputRange: [0, 2],
            }),
          },
          { rotate: '45deg' },
        ],
      },
    ]
  }

  const initPartBStyle = () => {
    return [
      style.partB,
      { backgroundColor: color },
      {
        transform: [
          {
            translateY: animation.interpolate({
              inputRange: [0, 1],
              outputRange: [0, 7],
            }),
          },
        ],
      },
    ]
  }

  useEffect(() => {
    Animated.timing(animation, {
      toValue: isActive ? 1 : 0,
      duration: 250,
      useNativeDriver: true,
    }).start()
  }, [isActive])

  const stylePartA = initPartAStyle()
  const stylePartB = initPartBStyle()

  return (
    <View style={[style.baseView]}>
      <Animated.View style={stylePartA} testID="animated-view-part-a">
        <Animated.View style={stylePartB} testID="animated-view-part-b" />
      </Animated.View>
    </View>
  )
}

export default Arrow
