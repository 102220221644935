import React from 'react'
import { StyleSheet, View } from 'react-native'
import { useForm } from 'react-hook-form'
import RNRestart from 'react-native-restart'

import Button from 'APP/Converse/Button'
import ControllerInput from 'APP/Components/ControllerFields/ControllerInput'
import ControllerRadioGroup from 'APP/Components/ControllerFields/ControllerRadioGroup'

import Config from 'APP/Config'

const Styles = StyleSheet.create({
  submitButton: {
    marginTop: 16,
  },
})

// Exposed for testing
export const TENANT_SWITCHER_PIN = 342975

function TenantSwitcherScreen() {
  const onSubmit = async ({ tenantRadio }) => {
    // Override the Auth0 configs with the selected tenant and then restart the app
    await Config.overrideKey('AUTH0_CLIENT_ID', Config.HOST_OVERRIDES[tenantRadio].AUTH0_CLIENT_ID)
    await Config.overrideKey(
      'AUTH0_WEB_CLIENT_ID',
      Config.HOST_OVERRIDES[tenantRadio].AUTH0_WEB_CLIENT_ID
    )
    await Config.overrideKey(
      'AUTH0_CONNECTION',
      Config.HOST_OVERRIDES[tenantRadio].AUTH0_CONNECTION
    )

    RNRestart.Restart()
  }

  const tenants = Object.keys(Config.HOST_OVERRIDES).map((tenant) => ({
    label: tenant,
    value: tenant,
  }))

  const { control, watch, handleSubmit } = useForm({
    defaultValues: {
      // Current tenant aka default value can be determined by the current AUTH0_CLIENT_ID
      tenantRadio: Object.entries(Config.HOST_OVERRIDES).find(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([_, value]) => value.AUTH0_CLIENT_ID == Config.AUTH0_CLIENT_ID
      )[0],
    },
  })
  const watchPin = watch('pin')

  // Hide the tenant switcher until the PIN is correct
  return watchPin != TENANT_SWITCHER_PIN ? (
    <View>
      <ControllerInput
        name="pin"
        control={control}
        testID="pinInput"
        label="Enter PIN"
        keyboardType="phone-pad"
      />
    </View>
  ) : (
    <View>
      <ControllerRadioGroup
        name="tenantRadio"
        label="Pick a Tenant"
        control={control}
        options={tenants}
        testID="tenantRadio"
      />
      <Button
        style={Styles.submitButton}
        testID="tenantSubmit"
        onPress={() => handleSubmit(onSubmit)()}
        widthVariant="full"
        title="Submit"
      />
    </View>
  )
}

export default TenantSwitcherScreen
