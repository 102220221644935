import { useWindowDimensions } from 'react-native'
import metrics from 'APP/Themes/Metrics'
import { useMemo } from 'react'

const useScreenView = () => {
  const dimensions = useWindowDimensions()

  const isSmallScreen = useMemo(() => dimensions.width < metrics.mobileView.max, [dimensions])
  const isBigScreen = useMemo(() => dimensions.width >= metrics.tabletView.max, [dimensions])

  return {
    isSmallScreen,
    isBigScreen,
  }
}

export default useScreenView
