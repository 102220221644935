export const storeName = 'videoSession'
export const signals = {
  CALLING: 'calling',
  RINGING: 'ringing',
  ACCEPTED_CALL: 'acceptedCall',
  ASKING_FOR_PERMISSION: 'askingForPermission',
  ACCEPTED_PERMISSION: 'acceptedPermission',
  DECLINED_PERMISSION: 'declinedPermission',
  MUTED_AUDIO: 'mutedAudio',
  UNMUTED_AUDIO: 'unmutedAudio',
  APP_BACKGROUNDED: 'appBackgrounded',
  APP_FOREGROUNDED: 'appForegrounded',
  ENDED_CALL: 'endedCall',

  properties: {
    USER_ID: 'userId', // Contains the 'appId' of the signed in user
    SENDER: 'sender',
    CONNECTION_ID: 'connectionId',
    CALL_ID: 'callId',
    senderTypes: {
      PATIENT: 'patient',
      CARE_PLATFORM: 'care-platform',
    },
    PERMISSIONS_TYPE: 'permissionType',
    permissionTypes: {
      VIDEO: 'video',
      AUDIO: 'audio',
    },
  },
}

export const packetLossStatuses = {
  WARNING: 'WARNING',
  CRITICAL: 'CRITICAL',
  OKAY: 'OKAY',
}

export const packetLossThresholds = {
  WARNING: 2.5,
  CRITICAL: 5,
}
