import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import styled from 'styled-components/native'

const StyledView = styled(View)`
  border-radius: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  padding: ${({ theme }) => theme.metrics.baseMargin}px;
  background-color: ${({ theme }) => theme.colors.elementsBg};
  ${({ theme }) => theme.shadows.normal};
`

const StyledTouchableOpacity = styled(TouchableOpacity)`
  border-radius: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  padding: ${({ theme }) => theme.metrics.baseMargin}px;
  background-color: ${({ theme }) => theme.colors.elementsBg};
  ${({ theme }) => theme.shadows.normal};
`

interface CardProps {
  children: React.ReactNode
  onPress?: () => void
  disabled?: boolean
  style?: object
  testID?: string
}

const Card = ({
  children,
  onPress = undefined,
  disabled = false,
  style = {},
  testID = '',
}: CardProps) => {
  if (Boolean(onPress)) {
    return (
      <StyledTouchableOpacity onPress={onPress} disabled={disabled} style={style} testID={testID}>
        {children}
      </StyledTouchableOpacity>
    )
  } else {
    return (
      <StyledView style={style} testID={testID}>
        {children}
      </StyledView>
    )
  }
}

export default Card
