import React from 'react'
import styled from 'styled-components/native'
import { Appearance } from 'react-native-appearance'
import I18n from 'APP/Services/i18n'
import UrlBuilder from 'APP/Services/UrlBuilder'
import Button from 'APP/Converse/Button'
import useScreenView from 'APP/Hooks/useScreenView'
import { useNavigation } from '@react-navigation/native'

const HelpButton = styled(Button)`
  height: 30px;
`

export const HelpButtonComponent = () => {
  const { isBigScreen } = useScreenView()
  const navigation = useNavigation()

  return (
    <HelpButton
      variant="tertiary"
      widthVariant="content"
      title={isBigScreen && I18n.t('MindScreen.help')}
      icon="warning-outline"
      iconVariant="ionicons"
      analyticsName="mind-emergency"
      onPress={() =>
        navigation.navigate('cmsScreen', {
          uri: UrlBuilder.cmsConsultUrl('emergencyCare', Appearance.getColorScheme()),
          screen: 'cms',
          showShareButton: false,
        })
      }
      testID="TOOLBAR_RIGHT_BUTTON"
    />
  )
}
