import React from 'react'
import { View, Image } from 'react-native'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import moment from 'moment'

import I18n from 'APP/Services/i18n'
import { ActiveMinutes } from '@dialogue/services'
import { nth } from 'APP/Lib/Helpers'

import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'

import Styles from './style'
import { Images, Metrics, Colors } from 'APP/Themes'
import { CHALLENGE_ACTIVITY_ICONS } from '../constants'
import Typography from 'APP/Converse/Typography'
import { cleanupMetricValue } from 'APP/Lib/ActiveMinutes/challenges'
import { getLocalUnitLabelForMetric } from 'APP/Lib/ActiveMinutes/general/helpers'

export const CARD_SIZE = {
  SMALL: 'small',
  LARGE: 'large',
}

const Tag = ({ icon: TagIcon, copy }) => (
  <View style={Styles.tag} testID="tag">
    <TagIcon.Lib name={TagIcon.name} size={Metrics.icons.tiny} style={Styles.tagIcon} />
    {copy && (
      <Typography variant="caption" style={Styles.tagCopy}>
        {copy}
      </Typography>
    )}
  </View>
)

const ChallengeCard = ({
  size = CARD_SIZE.LARGE,
  challenge,
  onPress,
  analyticsName = 'challenge-card',
  testID = 'challenge-card',
}) => {
  const isSmall = size === CARD_SIZE.SMALL
  const {
    id,
    status,
    activity,
    rank,
    image,
    title,
    brought_by,
    ends_at,
    starts_at,
    joined_at,
    metric,
    score,
  } = challenge
  const isTeam = false
  const isIndividual = true
  const isCompleted = status === ActiveMinutes.Types.ChallengeStatus.Completed
  const shouldShowRank =
    status !== ActiveMinutes.Types.ChallengeStatus.Upcoming && isIndividual && !!joined_at

  const shouldShowTotalValue = isCompleted && isIndividual && !!joined_at

  const activityTag = activity && {
    icon: CHALLENGE_ACTIVITY_ICONS[activity],
    copy: I18n.t(`WellnessCenter.challenges.activity.${activity}`),
  }
  const subtitle = brought_by?.[I18n.baseLocale]
  return (
    <View style={[Styles.container, isSmall && Styles.containerSmall]}>
      <DialogueTouchableOpacity
        onPress={onPress}
        style={Styles.card}
        analyticsName={analyticsName}
        testID={testID}
        key={id}
      >
        <View>
          <Image
            style={Styles.image}
            // force use of cache on iOS
            source={{ uri: image?.[I18n.baseLocale], cache: 'force-cache' }}
            defaultSource={Images.contentLoading}
            resizeMode="cover"
          />
          <View style={Styles.tagList}>
            {[activityTag].filter(Boolean).map((tagProps, index) => (
              <Tag key={index} {...tagProps} />
            ))}
          </View>
          <View style={Styles.tagList}></View>
        </View>
        <View style={Styles.description}>
          <Typography variant="h4" style={Styles.title} numberOfLines={3}>
            {title?.[I18n.baseLocale]}
          </Typography>
          {subtitle && (
            <Typography variant="bodySmall" testID="subtitle" style={Styles.subtitle}>
              {subtitle}
            </Typography>
          )}
          <View style={Styles.details} testID="details">
            {/* Countdown */}
            <View style={Styles.detailsItem}>
              <MaterialIcon
                name="schedule"
                size={Metrics.icons.tiny}
                color={Colors.accent}
                style={Styles.clockIcon}
              />
              <Typography
                variant="bodySmall"
                style={Styles.shrink}
                numberOfLines={1}
                testID="challengeCountdown"
              >
                {status === ActiveMinutes.Types.ChallengeStatus.Ongoing &&
                  `${I18n.t('WellnessCenter.challenges.countdown.ongoing')} ${moment(
                    ends_at
                  ).fromNow(true)}`}
                {status === ActiveMinutes.Types.ChallengeStatus.Upcoming &&
                  `${I18n.t('WellnessCenter.challenges.countdown.upcoming')} ${moment(
                    starts_at
                  ).fromNow(true)}`}
                {status === ActiveMinutes.Types.ChallengeStatus.Completed &&
                  `${moment(starts_at).format('MMM D')} - ${moment(ends_at).format('MMM D')}`}
              </Typography>
            </View>
            {/* Rank */}
            {shouldShowRank && (
              <View style={Styles.detailsItem}>
                <MaterialCommunityIcon
                  name="trophy-outline"
                  size={Metrics.icons.tiny}
                  color={Colors.accent}
                  style={Styles.clockIcon}
                />
                <Typography
                  variant="bodySmall"
                  style={Styles.shrink}
                  numberOfLines={1}
                  testID="challengeRank"
                >
                  {nth(rank, I18n.baseLocale)}
                  {isTeam && nth(team.rank, I18n.baseLocale)}
                  &nbsp;
                  {I18n.t('WellnessCenter.challenges.rank')}
                </Typography>
              </View>
            )}
          </View>
          {shouldShowTotalValue && (
            <View style={Styles.totalValue}>
              <Typography variant="h4" testID="total-value">
                {cleanupMetricValue(score, metric)} {getLocalUnitLabelForMetric(metric)}
              </Typography>
            </View>
          )}
        </View>
      </DialogueTouchableOpacity>
    </View>
  )
}

export default ChallengeCard
