import React from 'react'
import { Text, View } from 'react-native'
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons'

import AppointmentCard from 'APP/Components/AppointmentCard'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'

import Analytics from 'APP/Services/Analytics'
import I18n from 'APP/Services/i18n'
import { Colors } from 'APP/Themes'

import Styles from './style'
import { ResponsiveScrollView } from 'APP/Converse/Layout'

const UpcomingAppointmentScreen = (props) => {
  const { navigation, route } = props
  const { appointment } = { ...route?.params, ...props }

  const prepareIcons = [
    'bell-badge',
    'camera',
    'microphone',
    'map-marker',
    'shield-home',
    'wifi',
    'headphones',
    'card-account-details',
  ]
  const openChatText = I18n.t('UpcomingAppointmentScreen.openChat', { returnObjects: true })

  const handleOpenChatPress = () => {
    Analytics.trackEvent('button_click', {
      button_value: 'UpcomingAppointmentScreen.openChat',
    })
    navigation.navigate('conversation', { channelId: appointment.episode_id })
  }

  return (
    <NoticeBarContainer>
      <ResponsiveScrollView>
        <View style={Styles.container}>
          <AppointmentCard appointment={appointment} disabled={true} showButtons={true} />

          <View style={Styles.innerContainer}>
            <Text style={Styles.prepareTitle}>{I18n.t('UpcomingAppointmentScreen.prepare')}</Text>
            {I18n.t('UpcomingAppointmentScreen.prepareList', { returnObjects: true }).map(
              (text, i) => (
                <View key={i} style={Styles.prepareItem}>
                  <MaterialCommunityIcon name={prepareIcons[i]} size={20} color={Colors.accent} />
                  <Text style={Styles.prepareText}>{text}</Text>
                </View>
              )
            )}
            <View style={Styles.termsContainer}>
              <Text style={Styles.prepareTerms}>{I18n.t('UpcomingAppointmentScreen.terms')}</Text>
            </View>
            {appointment.episode_id && (
              <Text style={Styles.openChat}>
                {openChatText[0]}{' '}
                <Text
                  style={Styles.openChatBold}
                  onPress={handleOpenChatPress}
                  testID={'OPEN_CHAT'}
                >
                  {openChatText[1]}
                </Text>
              </Text>
            )}
          </View>
        </View>
      </ResponsiveScrollView>
    </NoticeBarContainer>
  )
}

export default UpcomingAppointmentScreen
