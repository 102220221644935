import moment from 'moment'
import { createSelector } from '@reduxjs/toolkit'

import { AvailabilitySlot } from '@dialogue/timekeeper'

import type { RootState } from 'APP/Store/CreateStore'
import { DATE_FORMAT } from '.'

export const selectProviderBookingState = (state: RootState) => state.providerBooking
export const selectLoadingAvailabilities = (state: RootState) =>
  state.providerBooking.availabilities.loading
export const selectProvider = (state: RootState, id: number) =>
  state.providerBooking.providers.data[id]
export const selectProviderById = (state: RootState) =>
  state.providerBooking.providerDetails?.data
export const selectLoadingProviders = (state: RootState) =>
  state.providerBooking.providers.loadingList
export const selectLoadingProviderBio = (state: RootState) =>
  state.providerBooking.providers.loadingBio
export const selectProvidersAttributesLoading = (state: RootState) =>
  state.providerBooking.attributes.loading
export const selectProvidersAttributesError = (state: RootState) =>
  state.providerBooking.attributes.error
export const selectProvidersAttributes = (state: RootState) => state.providerBooking.attributes.data

export const selectBookAppointmentRequest = (state: RootState) =>
  state.providerBooking.bookAppointmentRequest

export const selectSelectedDay = (state: RootState) => state.providerBooking.selectedDay

export const selectAllAvailabilities = (state: RootState) =>
  state.providerBooking.availabilities.data

export const selectAvailabilityPerDateWithTime = createSelector(
  [selectAllAvailabilities],
  (availabilities: AvailabilitySlot[]) => {
    const availabilitiesWithDateTime = new Map<string, { start_date: string; start_time: string }>()

    availabilities.forEach((slot) => {
      const date = moment(slot.start_at).format('YYYY-MM-DD')
      const time = moment(slot.start_at).format('HH:mm:ss')
      const existingSlot = availabilitiesWithDateTime.get(date)
      const earlierSlot = moment(slot.start_at).isBefore(
        moment(existingSlot?.start_date + 'T' + existingSlot?.start_time)
      )
      if (!existingSlot || earlierSlot) {
        availabilitiesWithDateTime.set(date, { start_date: date, start_time: time })
      }
    })

    return Array.from(availabilitiesWithDateTime.values())
  }
)

export const selectProviderPerDayAvailabilities = createSelector(
  [selectAllAvailabilities, selectSelectedDay],
  (availabilities, selectedDay) =>
    availabilities.reduce((acc: AvailabilitySlot[], curr: AvailabilitySlot) => {
      const slotDay = moment(curr.start_at).format(DATE_FORMAT)
      if (slotDay !== selectedDay) return acc
      const alreadyIncluded = acc.some(
        (item: AvailabilitySlot) => item.provider_id === curr.provider_id
      )
      return alreadyIncluded ? acc : [...acc, curr]
    }, [])
)

export const selectProviderAvailabilities = createSelector(
  [selectAllAvailabilities, (_, providerId: number) => providerId],
  (availabilities, providerId) => availabilities.filter((slot) => slot.provider_id === providerId)
)

export const selectProvidersAttributesByKeys = createSelector(
  [selectProvidersAttributes, (_, locale: string) => locale],
  (attributes, locale) => {
    if (!attributes) return {}
    return Object.values(attributes)
      .flat()
      .reduce((acc, curr) => ({ ...acc, [curr.id]: curr[locale] }), {})
  }
)

export const selectSelectedPreferences = (state: RootState) =>
  state.providerBooking.selectedPreferences

export const selectAppointmentTypesData = (state: RootState) =>
  state.providerBooking.appointmentTypes.data
export const selectLoadingAppointmentTypes = (state: RootState) =>
  state.providerBooking.appointmentTypes.loading

export const selectAppointmentTypes = createSelector([selectAppointmentTypesData], (types) =>
  types.map((type) => type.appointment_type)
)
