import { StyleSheet } from 'react-native'
import { Colors, Metrics, Fonts } from 'APP/Themes'

export default StyleSheet.create({
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingBottom: Metrics.baseMargin / 2,
  },
  listItemBullet: {
    paddingRight: Metrics.baseMargin / 2,
    color: Colors.accent,
  },
  listItemText: {
    ...Fonts.style.body,
    paddingRight: Metrics.baseMargin,
    color: Colors.text,
  },
})
