import { StyleSheet } from 'react-native'
import { Colors, Metrics, ApplicationStyles } from 'APP/Themes'

export const Styles = StyleSheet.create({
  container: {
    width: '100%',
    borderRadius: 20,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    ...ApplicationStyles.shadow,
    backgroundColor: Colors.elementsBg,
    minHeight: 60,
    position: 'relative',
    bottom: -Metrics.baseMargin / 2,
    padding: Metrics.baseMargin * 1.5,
    paddingBottom: Metrics.bottomSpace,
  },
  textInput: {
    ...ApplicationStyles.textInput,
    borderBottomWidth: 0,
    color: Colors.text,
    maxHeight: Metrics.screenHeight * 0.15,
  },
  textInputContainer: {
    borderBottomWidth: 0,
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
})
