import UrlBuilder from 'APP/Services/UrlBuilder'
import { Appearance } from 'react-native-appearance'
import Analytics from 'APP/Services/Analytics'

export const handleNavigationContactInfo = (contactInfo, navigation) => {
  const { id, name } = contactInfo || {}

  const uri = UrlBuilder.cmsContactInfoUrl(id, Appearance.getColorScheme())

  navigation.navigate('cmsScreen', { uri, showShareButton: false })
  Analytics.trackScreen(`contactInfoScreen : ${id} : ${name}`)
}
