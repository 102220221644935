import React, { useState } from 'react'
import { View, Linking, Text } from 'react-native'
import { connect } from 'react-redux'

import StartupActions from 'APP/Redux/StartupRedux'
import LoginActions from 'APP/Redux/LoginRedux'

import Modal from 'APP/Components/Modal'
import SignupLogoHeader from 'APP/Components/SignupLogoHeader'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import { PrimaryButton, TertiaryButton } from 'APP/Components/Buttons'
import Alert from 'APP/Converse/Alert'
import { ResponsiveView } from 'APP/Converse/Layout'

import Styles from './style'
import { Colors } from 'APP/Themes'

import Config from 'APP/Config'
import UrlBuilder from 'APP/Services/UrlBuilder'
import I18n from 'APP/Services/i18n'
import { navigationRef as Nav } from 'APP/Nav'
import ScrollViewWithFooter from 'APP/Components/ScrollViewWithFooter'

const Footer = ({ logout }) => {
  return (
    <View style={Styles.footerContainer}>
      <DialogueTouchableOpacity
        style={Styles.clickableContainer}
        onPress={() => logout('Declined MFA challenge')}
        analyticsName="Logout"
        testID={'LOGOUT'}
      >
        <Text style={Styles.clickableText}>{I18n.t('MandatoryMFAScreen.signout')}</Text>
      </DialogueTouchableOpacity>
    </View>
  )
}

const MandatoryMFAScreen = ({ isMfaLoading, toggleMfa, logout }) => {
  const [showMFAToggleOnModal, setShowMFAToggleOnModal] = useState(false)

  const handleAgreeMFAToggleOnPress = () => {
    toggleMfa()
    closeMFAToggleOnModal()
  }
  const closeMFAToggleOnModal = () => setShowMFAToggleOnModal(false)

  const handlePressContactSupport = () => {
    const customContactUs = Config.CUSTOM_SUPPORT_PAGE
    if (customContactUs) {
      Nav.navigate('contactUs')
    } else {
      const url = UrlBuilder.supportEmailUrl()
      if (Linking.canOpenURL(url)) {
        Linking.openURL(url).catch(() => {
          Alert.alert(I18n.t('CannotOpenEmail.title'), I18n.t('CannotOpenEmail.body'), [
            { text: I18n.t('CannotOpenEmail.ok') },
          ])
        })
      }
    }
  }
  const subtitle = I18n.t('MandatoryMFAScreen.subtitle', { returnObjects: true })

  return (
    <ScrollViewWithFooter footerComponent={<Footer logout={logout} />}>
      <ResponsiveView style={Styles.container}>
        <SignupLogoHeader
          style={Styles.header}
          title={I18n.t('MandatoryMFAScreen.title')}
          subtitle={
            <Text style={Styles.subtitle}>
              {subtitle[0]}
              <Text style={Styles.bold}>{subtitle[1]}</Text>
              {'\n\n'}
              {subtitle[2]}
            </Text>
          }
        />
        <PrimaryButton
          analyticsName="MandatoryMFAScreen.setupBtn"
          onPress={() => setShowMFAToggleOnModal(true)}
          activityIndicatorColor={Colors.buttonPrimaryText}
          showActivityIndicator={isMfaLoading}
        >
          {I18n.t('MandatoryMFAScreen.setupBtn')}
        </PrimaryButton>
        <TertiaryButton
          onPress={() => handlePressContactSupport()}
          analyticsName="Common.contactSupport"
        >
          {I18n.t('MandatoryMFAScreen.contactUsBtn')}
        </TertiaryButton>
        <Modal
          statusBarTranslucent
          visible={showMFAToggleOnModal}
          transparent
          title={I18n.t('MultiFactorAuthentication.confirmModal.title')}
          subtitle={I18n.t('MultiFactorAuthentication.confirmModal.description')}
          primaryActionText={I18n.t('MultiFactorAuthentication.confirmModal.confirm')}
          secondaryActionText={I18n.t('MultiFactorAuthentication.confirmModal.cancel')}
          handleCloseModal={() => closeMFAToggleOnModal()}
          handleSecondaryAction={() => closeMFAToggleOnModal()}
          handlePrimaryAction={() => handleAgreeMFAToggleOnPress()}
        />
      </ResponsiveView>
    </ScrollViewWithFooter>
  )
}

const mapStateToProps = (state, props) => {
  const { login } = state
  return {
    ...props.route?.params,
    isMfaLoading: login.toggleMfaRequestRunning,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleMfa: () => dispatch(StartupActions.mandatoryMfaToggleRequest()),
    logout: (reason) => dispatch(LoginActions.logout(reason)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MandatoryMFAScreen)
