import Config from 'APP/Config'

const type = Config.FONTS

const size = {
  h1: 30,
  h2: 24,
  h3: 20,
  h4: 16,

  large: 18,
  regular: 14,

  small: 12,
  captionBold: 11,
  caption: 10,
}

const lineHeight = {
  h1: 36,
  h2: 29,
  h3: 24,
  h4: 19,

  large: 28,
  regular: 16,
  small: 14,
  tinyBold: 13,
  tiny: 12,
}

const style = {
  h1: {
    fontFamily: type.header,
    fontSize: size.h1,
    lineHeight: lineHeight.h1,
  },
  h2: {
    fontFamily: type.header,
    fontSize: size.h2,
    lineHeight: lineHeight.h2,
  },
  h3: {
    fontFamily: type.header,
    fontSize: size.h3,
    lineHeight: lineHeight.h3,
  },
  h4: {
    fontFamily: type.header,
    fontSize: size.h4,
    lineHeight: lineHeight.h4,
  },

  body: {
    fontFamily: type.light,
    fontSize: size.regular,
    lineHeight: lineHeight.regular,
  },
  bodyLarge: {
    fontFamily: type.light,
    fontWeight: '300',
    fontSize: size.large,
    lineHeight: lineHeight.large,
  },
  bodyLargeBold: {
    fontFamily: type.base,
    fontWeight: 'bold',
    fontSize: size.large,
    lineHeight: lineHeight.large,
  },
  bodySmall: {
    fontFamily: type.light,
    fontWeight: '300',
    fontSize: size.small,
    lineHeight: lineHeight.small,
  },
  bodySmallBold: {
    fontFamily: type.bold,
    fontWeight: '500',
    fontSize: size.small,
    lineHeight: lineHeight.small,
  },

  button: {
    fontFamily: type.button,
    fontSize: size.regular,
    letterSpacing: 1.5,
    lineHeight: 16,
    textTransform: 'uppercase',
  },

  caption: {
    fontFamily: type.base,
    fontSize: size.caption,
    fontWeight: 'normal',
    lineHeight: 12,
  },
  captionBold: {
    fontFamily: type.bold,
    fontSize: size.captionBold,
    fontWeight: '500',
    lineHeight: lineHeight.captionBold,
  },

  subheader: {
    fontFamily: type.base,
    fontSize: size.regular,
    lineHeight: lineHeight.regular,
  },
  subheaderBold: {
    fontFamily: type.bold,
    fontSize: size.regular,
    lineHeight: lineHeight.regular,
  },
}

export default {
  type,
  size,
  style,
  lineHeight,
}
