import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Fonts, Metrics } from 'APP/Themes'

export const MarkdownStyles = {
  p: {
    marginBottom: 0,
  },
}

export default StyleSheet.create({
  keyboardAvoidingView: {
    flex: 1,
  },
  overlay: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.5)',
    justifyContent: 'flex-end',
  },
  modal: {
    ...ApplicationStyles.shadowHeavy,
    paddingTop: Metrics.baseMargin * 2,
    backgroundColor: Colors.appBg,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingLeft: Metrics.baseMargin * 1.8,
    paddingRight: Metrics.baseMargin * 2,
  },
  goBackCta: {
    marginRight: Metrics.baseMargin * 0.3,
    marginTop: 5,
  },
  headerText: {
    flex: 1,
  },
  title: {
    ...Fonts.style.h2,
    color: Colors.text,
    paddingBottom: Metrics.baseMargin,
    paddingRight: Metrics.baseMargin * 1.25,
  },
  subtitle: {
    ...Fonts.style.caption,
    color: Colors.text,
    textTransform: 'uppercase',
  },
  content: {
    paddingHorizontal: Metrics.baseMargin * 1.375,
  },
  targetLabel: {
    alignItems: 'center',
    paddingTop: Metrics.baseMargin * 2,
    paddingBottom: Metrics.baseMargin,
  },
  h1: {
    ...Fonts.style.h1,
    color: Colors.text,
  },
  bodyLarge: {
    ...Fonts.style.bodyLarge,
    color: Colors.text,
  },
  bodySmall: {
    ...Fonts.style.bodySmall,
    color: Colors.textSecondary,
  },
  body: {
    ...Fonts.style.body,
    color: Colors.textSecondary,
  },
  slider: {
    paddingBottom: Metrics.baseMargin,
    display: 'flex',
    alignItems: 'center',
  },
  sliderMarker: {
    backgroundColor: Colors.accent,
    borderColor: Colors.accent,
    marginTop: 4,
    width: 20,
    height: 20,
  },
  sliderTrack: {
    height: 6,
  },
  sliderBar: { backgroundColor: Colors.accent },
  sliderUnselectedBar: { backgroundColor: Colors.textInverted },
  sliderRange: {
    ...Fonts.style.bodyLarge,
    width: '100%',
    color: Colors.text,
  },
  sliderValues: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
  },
  banner: {
    padding: Metrics.baseMargin * 1.3,
    borderRadius: 16,
    backgroundColor: Colors.bannerBg,
    flexDirection: 'row',
  },
  markdown: {
    paddingRight: Metrics.baseMargin * 1.3,
    paddingLeft: Metrics.baseMargin * 0.6,
  },
})
