import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { FlatList, View } from 'react-native'
import { PrimaryButton } from 'APP/Components/Buttons'
import I18n from 'APP/Services/i18n'
import IntakeQuestion from '../IntakeQuestion'
import { Styles } from './style'
import { PromptTextControllers } from './PromptTextControllers'
import { useForm } from 'react-hook-form'
import { PromptChoiceSingleController } from './PromptChoiceSingleController'
import { PromptAddressController } from './PromptAddressController'
import { pick } from 'ramda'
import { APP_SENDER } from 'APP/Lib/constants'
import { ConditionalOverlayForm } from './ConditionalOverlayForm'
import { usePlacesSearch } from 'APP/Hooks/PlacesSearch'
import { transformAnswer } from 'APP/Lib/PlacesHelper'
import { MultiSubPromptType } from './types'
import ProgressBar from 'APP/Converse/ProgressBar'
import { Portal } from 'APP/Helpers/Portal'

const extendData = (promptType, values) => {
  switch (promptType) {
    case MultiSubPromptType.CHOICE_SINGLE:
    case MultiSubPromptType.ADDRESS:
      if (values?.props) return values?.props
      break
    case MultiSubPromptType.TEXT:
      if (values) return { text: values }
      break
  }
  return values
}

const subResponseString = (promptType, response) => {
  switch (promptType) {
    case MultiSubPromptType.CHOICE_SINGLE:
    case MultiSubPromptType.ADDRESS:
      return response?.display ?? ''
    case MultiSubPromptType.TEXT:
      return response ?? ''
    default:
      return ''
  }
}

// Function to build the prefilled value based on the prompt type
export const prefilledValueBuilder = async (prompt, searchAddress) => {
  switch (prompt.type) {
    case MultiSubPromptType.CHOICE_SINGLE: {
      const value = prompt.choices.find((el) => el.id === prompt.prefilled_values)
      return value
        ? {
            display: value.display,
            props: { selected_choice: value },
          }
        : null
    }
    case MultiSubPromptType.ADDRESS: {
      const answer = await searchAddress(prompt.prefilled_values)
      const selected_address = answer && transformAnswer(answer)
      return selected_address
        ? {
            display: selected_address.address,
            selected_address,
          }
        : null
    }
    case MultiSubPromptType.TEXT:
      return prompt.prefilled_values

    default:
      return null
  }
}

const fieldNameBuilder = (index, type) => `${index}_${type}`

const IntakeMultiSubPrompt = ({ prompts: promptsProp, sendAnswer, deactivatePortal, ...props }) => {
  const [, , resolvePlace, getPlacePredictions] = usePlacesSearch()
  const [conditionalFormKey, setConditionalFormKey] = useState(null)
  const [focusedSubPrompt, setFocusedSubPrompt] = useState(null)

  const displayProgressBar = props?.member_app_fullscreen?.show_question_progress

  // Function to fetch address details based on a query
  const searchAddress = async (query) => {
    if (!query) return null // Early return if no query is provided

    const predictions = await getPlacePredictions(query)

    const placeId = predictions[0]?.place_id
    if (!placeId) return null // Return null if no predictions are found

    const result = await resolvePlace(placeId)
    return { ...result, placeId }
  }

  const {
    watch,
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    clearErrors,
    formState: { isValid },
  } = useForm({ mode: 'onTouched' })

  // useEffect for fetching and setting prefilled values
  useEffect(() => {
    const fetchPrefilledValues = async () => {
      try {
        await Promise.allSettled(
          promptsProp.map(async (prompt, index) => {
            const name = fieldNameBuilder(index, prompt.type)
            const prefilledValue = await prefilledValueBuilder(prompt, searchAddress)

            if (prefilledValue) {
              setValue(name, prefilledValue, { shouldValidate: true })
            }
          })
        )
      } catch (error) {
        console.error('Error fetching prefilled values:', error)
      }
    }
    fetchPrefilledValues()
  }, [])

  const prompts = useMemo(() => {
    const showAsteriskForRequiredFields = !promptsProp.every((p) => p.required)
    return promptsProp.map((prompt) => {
      return {
        ...prompt,
        showRequiredAsterisk: showAsteriskForRequiredFields && prompt.required,
      }
    })
  }, [promptsProp])

  const sendAdditionalAnswer = useCallback(
    (conditionalFormValue) => {
      setValue(conditionalFormKey.fieldName, conditionalFormValue, { shouldValidate: true })

      setConditionalFormKey(null)
    },
    [setValue, conditionalFormKey?.fieldName]
  )

  const renderSubPromptListItem = ({ item, index }) => {
    const name = fieldNameBuilder(index, item.type)

    switch (item.type) {
      case MultiSubPromptType.TEXT:
        return (
          <PromptTextControllers
            name={name}
            item={item}
            control={control}
            trigger={trigger}
            setValue={setValue}
            clearErrors={clearErrors}
            getValues={getValues}
            isFocused={focusedSubPrompt === name}
            onFocus={setFocusedSubPrompt}
          />
        )
      case MultiSubPromptType.ADDRESS:
        return (
          <PromptAddressController
            name={name}
            item={item}
            control={control}
            openConditionalForm={setConditionalFormKey}
            isFocused={focusedSubPrompt === name}
            onFocus={setFocusedSubPrompt}
          />
        )
      case MultiSubPromptType.CHOICE_SINGLE:
        return (
          <PromptChoiceSingleController
            name={name}
            control={control}
            setValue={setValue}
            item={item}
            memberAppFullscreen={props?.member_app_fullscreen}
            utterance={props?.utterance}
            isFocused={focusedSubPrompt === name}
            onFocus={setFocusedSubPrompt}
          />
        )
    }
  }

  const submitForm = useCallback(
    (values) => {
      const answers = prompts.reduce((acc, prompt, index) => {
        const promptAnswers = pick(['text_type', 'place_type', 'payload'], prompt)
        const extendedData = extendData(prompt.type, values[`${index}_${prompt.type}`])

        // omit empty optional responses
        if (!extendedData || Object.values(extendedData).every((value) => !value)) return acc

        return [
          ...acc,
          {
            sender: APP_SENDER,
            type: `${prompt.type}_response`,
            ...promptAnswers,
            ...extendedData,
          },
        ]
      }, [])

      const display = prompts
        .map((prompt, index) =>
          I18n.t('ConversationScreen.multiSubPrompt.response', {
            index: index + 1,
            question: prompt.title,
            response: subResponseString(prompt.type, values[`${index}_${prompt.type}`]),
            interpolation: { escapeValue: false },
          })
        )
        .join('\n---\n\n')

      sendAnswer(answers, display)
    },
    [prompts, sendAnswer]
  )

  const onCloseConditionModal = useCallback(() => {
    setConditionalFormKey(null)
  }, [])

  const allFields = watch()

  const progress = useMemo(() => {
    const totalFields = Object.keys(allFields).length
    const filledFields = Object.values(allFields).filter((value) => value).length
    return (filledFields / totalFields) * 100
  }, [allFields])

  return (
    <View style={Styles.flatListContainer}>
      <FlatList
        data={prompts}
        keyExtractor={(prompt, index) => `${prompt.title} ${index}`}
        renderItem={renderSubPromptListItem}
        stickyHeaderIndices={[0]}
        ListHeaderComponent={
          <IntakeQuestion
            post={{ message: props.title, props: props }}
            animated={false}
            scrollEnabled={false}
          />
        }
        ListFooterComponent={
          <Portal name="multiSubPromptSubmitPortal" deactivate={deactivatePortal}>
            {displayProgressBar && <ProgressBar progress={progress} />}
            <PrimaryButton
              testID="intakeMultiPromptNextBtn"
              analyticsName="IntakeComponent.multiSubPrompt.next"
              title={I18n.t('IntakeComponent.next')}
              disabled={!isValid}
              onPress={() => handleSubmit(submitForm)()}
            />
          </Portal>
        }
        ListFooterComponentStyle={Styles.footer}
        contentContainerStyle={Styles.listContainer}
      />
      {!!conditionalFormKey && (
        <ConditionalOverlayForm
          sendAnswer={sendAdditionalAnswer}
          onClose={onCloseConditionModal}
          conditionalOverlayFormData={conditionalFormKey.item}
        />
      )}
    </View>
  )
}

export default IntakeMultiSubPrompt
