import { StyleSheet } from 'react-native'
import { ApplicationStyles, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  container: {
    paddingRight: Metrics.baseMargin * 2,
    paddingLeft: Metrics.baseMargin * 2,
    paddingBottom: Metrics.baseMargin,
    gap: Metrics.baseMargin,
  },
})
