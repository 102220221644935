import styled from 'styled-components/native'
import React from 'react'
import { MessageContainerProps, StyledViewProps } from './types'

const TRIANGLE_SIZE = 6

const MessageWrapper = styled.View<StyledViewProps>`
  position: relative;
  background: ${({ theme: { colors }, color }) => colors[color]};
  padding: ${({ theme }) => theme.metrics.baseMargin * 0.75}px;
  border-radius: 10px;
  border-bottom-${({ position }) => position}-radius: 0;
  margin-bottom: ${TRIANGLE_SIZE}px;
  max-width: ${({ theme: { isBigScreen } }) => (isBigScreen ? '600px' : '240px')};;
`

const Triangle = styled.View<StyledViewProps>`
  position: absolute;
  ${({ position }) => position}: 0;
  transform: rotate(${({ position }) => (position === 'left' ? 0 : 90)}deg);
  bottom: -${TRIANGLE_SIZE}px;
  width: 0;
  height: ${TRIANGLE_SIZE}px;
  borderstyle: solid;
  border-right-width: ${TRIANGLE_SIZE}px;
  border-top-width: ${TRIANGLE_SIZE}px;
  border-right-color: transparent;
  border-top-color: ${({ theme: { colors }, color }) => colors[color]};
`

export const MessageContainer = ({ children, rtl, isError }: MessageContainerProps) => {
  const position = rtl ? 'right' : 'left'
  const color = isError ? 'disabled' : rtl ? 'patientMsgBg' : 'practitionerMsgBg'
  return (
    <MessageWrapper position={position} color={color}>
      {children}
      <Triangle position={position} color={color} />
    </MessageWrapper>
  )
}
