// Useful for methods that have callback methods
const makeAsynchronous = (method) => {
  return new Promise((resolve) => {
    method((data) => {
      resolve(data)
    })
  })
}

export default makeAsynchronous
