import React from 'react'

import Typography from 'APP/Converse/Typography'
import Infotip from 'APP/Converse/Inputs/Infotip'
import RNPickerSelect, { Item, PickerStyle } from 'react-native-picker-select'
import { Appearance } from 'react-native-appearance'
import Styles from 'APP/Converse/Inputs/style'
import I18n from 'APP/Services/i18n'
import { ShadowView } from 'APP/Converse/ShadowView'
import Icon from 'APP/Converse/Icon'
import { StyledInputContainer } from 'APP/Converse/Inputs/styles'

interface PickerProps {
  value: string
  label?: string
  infotip?: string
  isFocused?: boolean
  error?: string
  disabled?: boolean
  onValueChange: (value: string) => void
  analyticsName?: string
  onFocus?: () => void
  items: Item[]
  testID?: string
  placeholder: string
}

const Picker = ({
  value,
  label,
  infotip,
  isFocused,
  error,
  disabled,
  onValueChange,
  onFocus,
  items,
  testID,
  placeholder,
}: PickerProps) => {
  const isDarkTheme = Appearance.getColorScheme() === 'dark'

  return (
    <StyledInputContainer testID={testID}>
      <Typography variant="bodyNormalBold" testID="PICKER_LABEL">
        {label}
      </Typography>
      <ShadowView>
        <RNPickerSelect
          placeholder={{ label: placeholder }}
          doneText={String(I18n.t('Common.formNavBar.done'))}
          value={value}
          onValueChange={onValueChange}
          items={items}
          style={(disabled ? Styles.disabledPickerStyles : Styles.pickerStyles) as PickerStyle}
          pickerProps={{
            accessibilityLabel: placeholder,
          }}
          // @ts-expect-error https://github.com/lawnstarter/react-native-picker-select/issues/478
          Icon={() => (
            <Icon
              src="chevron-down"
              variant="material"
              style={disabled ? Styles.disabledPickerStyles.icon : Styles.pickerStyles.icon}
              color={disabled ? 'darkText' : 'text'}
            />
          )}
          onOpen={onFocus}
          useNativeAndroidPickerStyle={false}
          darkTheme={isDarkTheme}
          disabled={disabled}
        />
      </ShadowView>
      <Infotip error={error} infotip={infotip} isFocused={isFocused} />
    </StyledInputContainer>
  )
}

export default Picker
