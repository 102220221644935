import React, { useCallback, useState } from 'react'
import { Text, View, ActivityIndicator } from 'react-native'
import { useNavigation, useFocusEffect } from '@react-navigation/native'
import { useSelector, useDispatch } from 'react-redux'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'

import Analytics, { SCROLL_BEHAVIOUR_ACTIONS } from 'APP/Services/Analytics'
import { PrimaryTextButton } from 'APP/Components/Buttons'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import ActiveMinutes from 'APP/Containers/ActiveMinutes'
import WellnessCenterActions from 'APP/Redux/WellnessCenterRedux'
import { ChallengeCard, CARD_SIZE } from 'APP/Components/Challenge_legacy'

import I18n from 'APP/Services/i18n'

import Styles from './style'
import { Colors, Metrics } from 'APP/Themes'
import { ResponsiveScrollView } from 'APP/Converse/Layout'

const ChallengeSection = ({
  fetchAction,
  stateSelector,
  row,
  emptyTitle,
  emptyCopy,
  analyticsName,
}) => {
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const { retry, loading, data } = useSelector(stateSelector)
  const { eligibilityIntervals } = useSelector((state) => state.patient.profile)
  const fetchData = () => dispatch(fetchAction())
  const navigateToChallenge = (id) => {
    navigation.navigate('wcChallenge', { id })
  }

  // Fetch data on screen getting back into focus
  useFocusEffect(
    useCallback(() => {
      fetchData()
    }, [])
  )

  return (
    <View style={Styles.section}>
      {loading && !data && (
        <ActivityIndicator
          size="large"
          color={Colors.text}
          style={Styles.loading}
          testID="loading"
        />
      )}
      {retry && (
        <DialogueTouchableOpacity
          style={Styles.refresh}
          onPress={fetchData}
          analyticsName="Refresh"
          testID="retry"
        >
          <MaterialIcon
            name="refresh"
            size={Metrics.icons.large}
            color={Colors.text}
            style={Styles.refreshIcon}
          />
        </DialogueTouchableOpacity>
      )}
      {!retry && data?.length === 0 && (
        <View style={Styles.empty} testID="empty-view">
          <Text style={Styles.emptyTitle} testID="empty-title">
            {emptyTitle}
          </Text>
          <Text style={Styles.emptyCopy} testID="empty-copy">
            {emptyCopy}
          </Text>
        </View>
      )}
      {!retry && data?.length > 0 && (
        <View style={row && Styles.row} testID="challenge-list">
          {data.map((challenge, i) => (
            <ChallengeCard
              key={challenge.id}
              size={row ? CARD_SIZE.SMALL : CARD_SIZE.LARGE}
              challenge={challenge}
              eligibilityIntervals={eligibilityIntervals}
              onPress={() => navigateToChallenge(challenge.id)}
              analyticsName={`${analyticsName}.${i}`}
            />
          ))}
        </View>
      )}
    </View>
  )
}

const selectMyChallenges = (state) => state.wellnessCenter.myChallenges
const selectNewChallenges = (state) => state.wellnessCenter.newChallenges

const Challenges_legacy = () => {
  const { eligibleServices } = useSelector((state) => state.patient?.profile)
  const navigation = useNavigation()
  const features = useSelector((state) => state.features)
  const showWeeklyActiveMinutes = features?.weeklyActiveMinutes || false
  const [trackScrollProps, setTrackScrollProps] = useState(null)

  // When layout is ready set up the onScroll handler
  const onActiveMinutesLayout = useCallback((event) => {
    const { height } = event.nativeEvent.layout
    setTrackScrollProps({
      // iOS requires us to specify the interval in ms with which
      // the scroll event will be fired. 16 is the biggest most responsive interval
      // https://reactnative.dev/docs/scrollview#scrolleventthrottle-ios
      scrollEventThrottle: 16,
      onScroll: (event) => {
        if (event.nativeEvent.contentOffset.y > height * 0.5) {
          Analytics.trackScrollingBehaviour({
            label: 'weekly active minutes',
            action: SCROLL_BEHAVIOUR_ACTIONS.SCROLL_OUT_OF_VIEW,
          })
          setTrackScrollProps(null)
        }
      },
    })
  }, [])

  return (
    <ResponsiveScrollView {...trackScrollProps} testID="challenges-legacy">
      {showWeeklyActiveMinutes && <ActiveMinutes onLayout={onActiveMinutesLayout} />}
      {/* check for brands that have active minutes enabled (LD flag) hence the tab is shown
      but if they don't have challenges enabled explicitly - hide them */}
      {!!eligibleServices.wellness_challenges && (
        <>
          <View style={Styles.sectionHeader} testID="challenge_list_header">
            <Text style={Styles.sectionTitle}>
              {I18n.t('WellnessCenter.challenges.sections.my')}
            </Text>
            <PrimaryTextButton
              title={I18n.t('WellnessCenter.challenges.sections.completed')}
              containerStyle={Styles.sectionHeaderButton}
              onPress={() => navigation.navigate('wcCompletedChallenges')}
            />
          </View>
          <ChallengeSection
            fetchAction={WellnessCenterActions.getMyChallenges}
            stateSelector={selectMyChallenges}
            emptyTitle={I18n.t('WellnessCenter.challenges.emptyList.my.title')}
            emptyCopy={I18n.t('WellnessCenter.challenges.emptyList.my.copy')}
            analyticsName="myChallenges.card"
          />
          <View style={Styles.sectionHeader}>
            <Text style={Styles.sectionTitle}>
              {I18n.t('WellnessCenter.challenges.sections.new')}
            </Text>
          </View>
          <ChallengeSection
            row
            fetchAction={WellnessCenterActions.getNewChallenges}
            stateSelector={selectNewChallenges}
            emptyTitle={I18n.t('WellnessCenter.challenges.emptyList.upcoming.title')}
            emptyCopy={I18n.t('WellnessCenter.challenges.emptyList.upcoming.copy')}
            analyticsName="newChallenges.card"
          />
        </>
      )}
    </ResponsiveScrollView>
  )
}

export default Challenges_legacy
