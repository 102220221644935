import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Metrics } from 'APP/Themes'
import { isMobile } from 'APP/Helpers/checkPlatform'

export const Styles = StyleSheet.create({
  scrollArrow: {
    position: 'absolute',
    right: 0,
    zIndex: 100,
  },
  singleChoiceContainer: {
    borderRadius: 20,
  },
  singleChoiceContainerOverflowing: {
    maxHeight: isMobile() ? 0.5 * Metrics.screenHeight : 0.21 * Metrics.screenHeight,
    width: isMobile() && Metrics.screenWidth,
  },
  singleChoiceScrollView: {
    flexGrow: 0,
    borderRadius: 20,
  },
  singleChoice: {
    display: 'flex',
    backgroundColor: Colors.contrastElementBg,
    borderRadius: 20,
    marginHorizontal: Metrics.baseMargin * 2,
    marginBottom: Metrics.bottomSpace || Metrics.baseMargin,
    ...ApplicationStyles.shadow,
  },
  singleChoiceOverflowing: {
    marginHorizontal: 0,
  },
  singleChoiceOption: {
    display: 'flex',
    marginBottom: 1,
    justifyContent: 'center',
    backgroundColor: Colors.elementsBg,
    padding: Metrics.baseMargin,
    minWidth: 240,
  },
  singleChoiceOptionOverflowing: {
    alignItems: 'flex-start',
    paddingHorizontal: Metrics.baseMargin * 2,
  },
  singleChoiceOptionSelected: {
    backgroundColor: Colors.multiSelectMsgBg,
  },
  firstSingleChoiceOption: {
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  lastSingleChoiceOption: {
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    marginBottom: 0,
  },
  singleChoiceOptionSkipButton: {
    display: 'flex',
    marginBottom: 0,
    justifyContent: 'center',
    alignItems: 'flex-end',
    backgroundColor: Colors.appBg,
    padding: Metrics.baseMargin,
    minWidth: 240,
    shadowColor: Colors.appBg,
  },
  singleChoiceSkipButton: {
    display: 'flex',
    backgroundColor: Colors.contrastElementBg,
    borderRadius: 20,
    marginHorizontal: Metrics.baseMargin * 2,
    marginBottom: Metrics.bottomSpace || Metrics.baseMargin,
  },
  singleChoiceDropdown: {
    paddingHorizontal: Metrics.baseMargin,
  },
  singleChoiceHorizontal: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'stretch',
    marginHorizontal: Metrics.baseMargin * 2,
    marginTop: Metrics.baseMargin / 2,
  },
  singleChoiceHorizontalOption: {
    marginBottom: 1,
    backgroundColor: Colors.elementsBg,
    padding: Metrics.baseMargin / 1.5,
    borderColor: Colors.contrastElementBg,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  firstSingleChoiceHorizontalOption: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    borderWidth: 1,
  },
  lastSingleChoiceHorizontalOption: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },
  singleChoiceHorizontalContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minWidth: 30,
    marginTop: 8,
  },
  horizontalOptionSubtext: {
    textAlign: 'center',
    marginTop: 4,
  },
  horizontalSubtextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginHorizontal: Metrics.baseMargin * 2,
  },
  singleSubtextContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
})
