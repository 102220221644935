import React from 'react'
import { Text, View, TouchableOpacity, ActivityIndicator, Image } from 'react-native'
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import { PrimaryButton } from 'APP/Components/Buttons'

import { Colors } from 'APP/Themes'
import { Styles } from './style'

const ActionCard = ({
  onPress,
  title,
  subtitle,
  icon,
  iconName,
  isLoading,
  ctaTitle,
  ctaOnPress,
  containerStyle,
}) => {
  const isCardDisabled = !onPress
  const WrapperComponent = isCardDisabled ? View : TouchableOpacity

  return (
    <WrapperComponent
      style={[
        Styles.container,
        isCardDisabled && !ctaOnPress && Styles.disabled,
        isCardDisabled && ctaOnPress && Styles.ctaContainer,
        containerStyle,
      ]}
      testID="actionCard"
      onPress={onPress}
    >
      <View style={Styles.row}>
        {icon && (
          <View style={Styles.icon}>
            <Image style={Styles.image} source={icon} />
          </View>
        )}
        {iconName && (
          <View style={Styles.icon}>
            <MaterialCommunityIcon name={iconName} size={20} color={Colors.accent} />
          </View>
        )}
        <View style={Styles.innerContainer}>
          {isLoading ? (
            <View style={Styles.loading}>
              <ActivityIndicator size="large" color={Colors.text} />
            </View>
          ) : (
            <>
              <Text style={subtitle ? Styles.title : Styles.mainTitle}>{title}</Text>
              {subtitle && <Text style={Styles.subtitle}>{subtitle}</Text>}
            </>
          )}
        </View>
        {onPress && (
          <View style={Styles.action}>
            <MaterialCommunityIcon name={'chevron-right'} size={20} color={Colors.accent} />
          </View>
        )}
      </View>
      {ctaTitle && ctaOnPress && (
        <View style={Styles.cta}>
          <PrimaryButton
            style={Styles.ctaButton}
            title={ctaTitle}
            onPress={ctaOnPress}
            analyticsName="ctaTitle"
          />
        </View>
      )}
    </WrapperComponent>
  )
}

export default ActionCard
