// Services
import Config from 'APP/Config'
import I18n from 'APP/Services/i18n'
import DeviceInfo from 'react-native-device-info'
import URL from 'url'

const getVersion = () => DeviceInfo.getReadableVersion()

export const deleteQueryParams = (uri, paramKeyOrArray = []) => {
  let paramKeys = paramKeyOrArray
  if (typeof paramKeyOrArray === 'string') {
    paramKeys = [paramKeyOrArray]
  }
  const { query, ...parts } = URL.parse(uri, true)
  paramKeys.reduce((q, key) => {
    if (key in q) {
      delete q[key]
    }
    return q
  }, query)
  return URL.format({
    ...parts,
    search: null,
    query,
  })
}

export const appendQueryParams = (uri, queryParams = {}, { encode = true } = {}) => {
  const { query, search, ...parts } = URL.parse(uri, true)

  const needsPushValueToList = (key) => key in query && Array.isArray(query[key])
  const needsTransformToList = (key) => key in query && Array.isArray(query[key]) === false

  Object.entries(queryParams).reduce((q, [key, value]) => {
    if (needsPushValueToList(key)) {
      q[key].push(value)
      return q
    }
    if (needsTransformToList(key)) {
      q[key] = [query[key], value]
      return q
    }
    q[key] = value
    return q
  }, query)

  return URL.format({
    ...parts,
    search: encode ? null : search,
    query,
  })
}

export const setQueryParams = (uri, queryParams = {}, { encode = true } = {}) => {
  const { query, search, ...parts } = URL.parse(uri, true)

  Object.entries(queryParams).reduce((q, [key, value]) => {
    if (value) {
      q[key] = value
    }
    return q
  }, query)

  return URL.format({
    ...parts,
    search: encode ? null : search,
    query,
  })
}

// Url Builders
export const aboutUrl = (locale = I18n.baseLocale) => {
  return Config.URLS.ABOUT[locale]
}

export const consentUrl = (locale = I18n.baseLocale, version = getVersion()) => {
  return Config.URLS.CONSENT[locale].replace('{{VERSION}}', version)
}

export const helpAndSupportUrl = (locale = I18n.baseLocale) => {
  return Config.URLS.HELP_AND_SUPPORT[locale]
}

export const privacyUrl = (locale = I18n.baseLocale) => {
  return Config.URLS.PRIVACY[locale]
}

export const supportEmailUrl = (locale = I18n.baseLocale) => {
  const email = Config.SUPPORT_EMAIL && Config.SUPPORT_EMAIL[locale]
  const subject = (Config.SUPPORT_SUBJECT && Config.SUPPORT_SUBJECT[locale]) || ''
  const body = (Config.SUPPORT_BODY && Config.SUPPORT_BODY[locale]) || ''

  return setQueryParams(`mailto:${email}`, { subject, body })
}

export const privacyEmailUrl = (locale = I18n.baseLocale) => {
  const email = Config.PRIVACY_EMAIL && Config.PRIVACY_EMAIL[locale]
  return `mailto:${email}`
}

export const termsUrl = (locale = I18n.baseLocale) => {
  return Config.URLS.TERMS[locale]
}

export const guardianConsentUrl = (
  guardianGivenName,
  guardianFamilyName,
  dependentGivenName,
  dependentFamilyName,
  locale = I18n.baseLocale
) => {
  const url = Config.URLS.GUARDIAN_CONSENT[locale]
    .replace('{{GUARDIAN_GIVEN_NAME}}', guardianGivenName || '')
    .replace('{{GUARDIAN_FAMILY_NAME}}', guardianFamilyName || '')
    .replace('{{DEPENDENT_GIVEN_NAME}}', dependentGivenName || '')
    .replace('{{DEPENDENT_FAMILY_NAME}}', dependentFamilyName || '')
  return encodeURI(url)
}

export const cmsBioUrl = (id = '', locale = I18n.baseLocale, style = 'light', memberId) => {
  const baseUrl = Config.CMS.baseUrl
  const memberQuery = memberId ? `&member_id=${memberId}` : ''
  return `${baseUrl}/profile/${id}?locale=${locale}&style=${style}${memberQuery}`
}

export const cmsConsultUrl = (
  ctaId = 'mentalHealth',
  style = 'light',
  locale = I18n.baseLocale
) => {
  const baseUrl = Config.CMS.baseUrl
  const consultIds = Config.CMS.consultIds
  const consultId = consultIds && consultIds[ctaId]
  return `${baseUrl}/scope-of-practice/${consultId}?locale=${locale}&style=${style}`
}

export const cmsScopeUrl = (scopeId, style = 'light', locale = I18n.baseLocale) => {
  const baseUrl = Config.CMS.baseUrl
  return `${baseUrl}/scope-of-practice/${scopeId}?locale=${locale}&style=${style}`
}

export const cmsContactInfoUrl = (id, style = 'light', locale = I18n.baseLocale) => {
  const baseUrl = Config.CMS.baseUrl
  return `${baseUrl}/contact_info/${id}?locale=${locale}&style=${style}`
}

export const cmsArticleUrl = (id, style = 'light', locale = I18n.baseLocale) => {
  const baseUrl = Config.CMS.baseUrl
  return `${baseUrl}/document/${id}?locale=${locale}&style=${style}`
}

export const cmsVideoUrl = (id, style = 'light', locale = I18n.baseLocale) => {
  const baseUrl = Config.CMS.baseUrl
  return `${baseUrl}/video/${id}?locale=${locale}&style=${style}`
}

export const cmsAudioUrl = (id, style = 'light', locale = I18n.baseLocale) => {
  const baseUrl = Config.CMS.baseUrl
  return `${baseUrl}/audio/${id}?locale=${locale}&style=${style}`
}

export const cobrandAssetUrl = (cobrand, mode = 'light', locale = I18n.baseLocale) => {
  const baseUrl = Config.COBRANDING_IMAGES_URL
  return `${baseUrl}/${cobrand}/${mode}/${locale}/image.png`
}

export const activeMinutesAssets = (name) => {
  const baseUrl = Config.ACTIVE_MINUTES_ASSETS_URL
  return `${baseUrl}/${name}`
}

export const patientCharterUrl = (locale = I18n.baseLocale) => {
  return Config.URLS.PATIENT_CHARTER_URL[locale]
}

export default {
  deleteQueryParams,
  setQueryParams,
  aboutUrl,
  consentUrl,
  guardianConsentUrl,
  helpAndSupportUrl,
  privacyUrl,
  supportEmailUrl,
  privacyEmailUrl,
  termsUrl,
  cmsBioUrl,
  cmsConsultUrl,
  cmsScopeUrl,
  cmsContactInfoUrl,
  cmsArticleUrl,
  cmsVideoUrl,
  cmsAudioUrl,
  cobrandAssetUrl,
  activeMinutesAssets,
  patientCharterUrl,
}
