import { StyleSheet } from 'react-native'

export const style = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  baseView: {
    width: 16,
    height: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  partA: {
    position: 'absolute',
    width: 2,
    height: 9,
  },
  partB: {
    position: 'absolute',
    width: 9,
    height: 2,
    left: -7,
  },
})
