import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { View } from 'react-native'
import { useNavigation, useFocusEffect } from '@react-navigation/native'
import I18n from 'APP/Services/i18n'
import Analytics from 'APP/Services/Analytics'
import ActiveMinutesActions from 'APP/Redux/ActiveMinutesRedux'
import Animation from 'APP/Components/Animation'
import {
  WeeklyProgress,
  DailyProgress,
  TrackerStatusWrapper,
  FAQModal,
  LastActivitySync,
} from 'APP/Components/ActiveMinutes'
import { useUserTrackerStatus_legacy } from 'APP/Hooks/Tracker'
import TargetModals from './TargetModal'

const AnimationContainer = ({ disabled, children }) => {
  return (
    <View testID="animation">
      <Animation disabled={disabled} text="🔥" numberOfElems={25}>
        <Animation disabled={disabled} text="💯" numberOfElems={20}>
          {children}
        </Animation>
      </Animation>
    </View>
  )
}

const ActiveMinutes = ({ onLayout }) => {
  const dispatch = useDispatch()
  const navigation = useNavigation()

  const { loading, retry, data } = useSelector((state) => state.activeMinutes.activeMinutes)
  const setConfettiShownState = useSelector((state) => state.activeMinutes.setConfettiShown)

  // (re)fetch data on screen focus
  useFocusEffect(
    useCallback(() => {
      getActiveMinutes()
    }, [])
  )

  const getActiveMinutes = () => dispatch(ActiveMinutesActions.getActiveMinutes())
  const retryFetch = () => getActiveMinutes()
  const onAnimationShown = () => dispatch(ActiveMinutesActions.setConfettiShown())
  const trackerStatus = useUserTrackerStatus_legacy({
    enabled: data && !retry,
    isNoData: !data?.weekly_progress?.minutes,
    onSyncFinished: retryFetch,
  })

  const [isFAQModalOpen, setIsFAQModalOpen] = useState(false)
  const openFAQModal = () => setIsFAQModalOpen(true)
  const closeFAQModal = () => setIsFAQModalOpen(false)
  const [isChangeTargerModalOpen, setIsChangeTargerModalOpen] = useState(false)
  const openChangeTargetModal = () => setIsChangeTargerModalOpen(true)
  const closeChangeTargetModal = () => setIsChangeTargerModalOpen(false)
  const [shouldAnimate, setShouldAnimate] = useState(false)

  const isTargetAchieved = data?.weekly_progress?.percentage >= 100
  const isConfettiShown = data?.confetti_already_shown
  useEffect(() => {
    if (data && isTargetAchieved && !isConfettiShown) {
      // if update has failed don't show animation again
      if (!setConfettiShownState.retry) {
        setTimeout(() => setShouldAnimate(true), 1000)
        Analytics.trackScreen('Confetti cannon')
      }
      onAnimationShown()
    }
  }, [isTargetAchieved, isConfettiShown, setConfettiShownState.retry])

  return (
    <>
      <AnimationContainer disabled={!shouldAnimate}>
        <View testID="activeMinutes" onLayout={onLayout}>
          <WeeklyProgress
            loading={loading}
            retry={retry}
            data={data}
            refetch={retryFetch}
            menuOptions={[
              {
                value: I18n.t('ActiveMinutes.menu.trackerSettings'),
                handler: () => navigation.navigate('healthTrackerSettings'),
              },
              {
                value: I18n.t('ActiveMinutes.menu.changeTarget'),
                handler: () => openChangeTargetModal(),
              },
              {
                value: I18n.t('ActiveMinutes.menu.faq'),
                handler: () => openFAQModal(),
              },
            ]}
          />
          <TrackerStatusWrapper status={trackerStatus}>
            {!retry && !!data?.days && (
              <>
                <DailyProgress dailyProgress={data?.days} dailyGoal={data?.daily_goal} />
                <LastActivitySync lastSync={data?.last_update} />
              </>
            )}
          </TrackerStatusWrapper>
        </View>
      </AnimationContainer>
      <FAQModal isVisible={isFAQModalOpen} onClose={closeFAQModal} />
      <TargetModals
        currentTarget={data?.weekly_goal}
        newTarget={data?.suggested_weekly_goal}
        isChangeTargetModalOpen={isChangeTargerModalOpen}
        onChangeTargetModalClose={closeChangeTargetModal}
      />
    </>
  )
}

export default ActiveMinutes
