import React, { useEffect } from 'react'
import { useNavigation } from '@react-navigation/native'
import { connect } from 'react-redux'
import { Image, Linking, Text, View } from 'react-native'
import Icon from 'react-native-vector-icons/MaterialIcons'

//Components
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import Alert from 'APP/Converse/Alert'
//Services
import Analytics from 'APP/Services/Analytics'

import I18n from 'APP/Services/i18n'

// Styling
import Styles from './style'
import { Colors } from 'APP/Themes'
import { isWeb } from 'APP/Helpers/checkPlatform'

const iconMapping = {
  video: 'play-circle-outline',
  article: 'chevron-right',
  external: 'exit-to-app',
}

const PromotedContent = ({ content, style }) => {
  if (!content || Object.keys(content).length === 0) {
    return <></>
  }
  const navigation = useNavigation()
  const url = I18n.locale === 'en' ? content.url_en : content.url_fr
  const title = I18n.locale === 'en' ? content.title_en : content.title_fr
  const subtitle = I18n.locale === 'en' ? content.subtitle_en : content.subtitle_fr

  useEffect(() => {
    Analytics.trackEvent('banner_view', {
      banner_name: 'promotedContent',
      content: title,
    })
  }, [])

  const handlePressCard = () => {
    Analytics.trackEvent('button_click', {
      button_text: title,
      button_value: content.type,
    })

    if (content.type === 'external' || isWeb()) {
      if (Linking.canOpenURL(url)) {
        Linking.openURL(url).catch(() => {
          Alert.alert(I18n.t('CannotOpenUrl.title'), I18n.t('CannotOpenUrl.body'), [
            { text: I18n.t('CannotOpenUrl.ok') },
          ])
        })
      }
    } else {
      navigation.navigate('promotedContent', {
        uri: url,
      })
    }
  }

  return (
    <View style={[Styles.container, style]}>
      <Text style={Styles.title}>{I18n.t('HomeScreen.promotedContentTitle')}</Text>
      <DialogueTouchableOpacity
        style={Styles.cardContainer}
        onPress={handlePressCard}
        testID="card-container"
      >
        {content.image_url && (
          <Image style={Styles.cardImage} source={{ uri: content.image_url }} />
        )}
        <View style={Styles.titleContainer}>
          <View style={Styles.copyContainer}>
            <Text style={Styles.cardTitle}>{title}</Text>
            <Text style={Styles.cardBody}>{subtitle}</Text>
          </View>
          <Icon name={iconMapping[content.type]} size={24} color={Colors.accent} />
        </View>
      </DialogueTouchableOpacity>
    </View>
  )
}

const mapStateToProps = ({ features: { promotedContent } }) => {
  return { content: promotedContent }
}
export default connect(mapStateToProps)(PromotedContent)
