import React from 'react'
import { Text } from 'react-native'
import Moment from 'moment'

import DialogueTouchableOpacity from '../DialogueTouchableOpacity'

import Styles from './style'
import { Colors, Metrics } from 'APP/Themes'

import I18n from 'APP/Services/i18n'
import { Format } from 'APP/Lib/ProfileDataFormat'

const DialogueDatePicker = (props) => {
  const {
    disabled,
    isFocused,
    isError,
    value,
    onPress,
    inputContainerStyle,
    format = Format.DISPLAY_DATE,
    label = I18n.t('UpdateProfileScreen.dateOfBirth'),
    placeholder = I18n.t('ProfileScreen.bday'),
    testID = 'dateOfBirthPicker',
    analyticsName = 'dateOfBirthPicker',
  } = props

  const focusedBorderColor = isFocused || value ? Colors.text : Colors.buttonPrimarySelected
  const borderBottomColor = isError ? Colors.errorText : focusedBorderColor

  const borderBottomWidth = isFocused || isError ? 2 : 1
  const labelFocusedColor = isFocused ? Colors.text : Colors.buttonPrimarySelected

  return (
    <DialogueTouchableOpacity
      style={[
        Styles.pickerFormInputContainer,
        { borderBottomColor, borderBottomWidth, marginTop: Metrics.baseMargin * 1.25 },
        inputContainerStyle,
      ]}
      disabled={disabled}
      onPress={onPress}
      testID={testID}
      analyticsName={analyticsName}
    >
      <Text style={[Styles.inputLabel, { color: isError ? Colors.errorText : labelFocusedColor }]}>
        {value ? label : ''}
      </Text>
      <Text
        style={[
          value ? Styles.pickerFormInputText : Styles.pickerFormInputPlaceholder,
          Styles.dateOfBirthOffset,
        ]}
      >
        {value ? Moment(value).format(format) : placeholder}
      </Text>
    </DialogueTouchableOpacity>
  )
}

export default DialogueDatePicker
