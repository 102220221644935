import React from 'react'
import { View } from 'react-native'

// Components
import { MenuItem } from './MenuItem'

// Styles
import { Metrics } from 'APP/Themes'

const styles = {
  container: {
    flexDirection: 'column',
    marginTop: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin,
  },
}

export const MenuNavList = ({ items, selectedItemName, onItemPress, testID }) => (
  <View style={styles.container} testID={testID}>
    {items?.map(({ name, options }) => {
      const focused = selectedItemName === name
      const onPress = () => onItemPress(name)
      const itemProps = { name, options, onPress, focused }
      return <MenuItem key={name} {...itemProps} />
    })}
  </View>
)
