import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ActiveMinutesActions from 'APP/Redux/ActiveMinutesRedux'
import RankedList from 'APP/Components/RankedList'
import I18n from 'APP/Services/i18n'
import { prepareLeaderboard } from '../helpers'
import { getLocalUnitLabelForMetric } from 'APP/Lib/ActiveMinutes/general/helpers'

const Leaderboard = ({ children, challengeId: challengeIdProp, ...rest }) => {
  const dispatch = useDispatch()
  const memberUserId = useSelector((state) => state.patient?.profile?.id)
  const { challenge } = useSelector((state) => state.activeMinutes.selectedChallenge)
  const { supportedActivities } = useSelector((state) => state.activeMinutes.connectedTrackers)
  const { loading, retry, data, challengeId } = useSelector(
    (state) => state.activeMinutes.leaderboard
  )
  const getLeaderboard = () =>
    dispatch(ActiveMinutesActions.getChallengeLeaderboard(challengeIdProp))
  useEffect(() => {
    getLeaderboard()
  }, [challengeIdProp, challenge?.joined_at])

  const dataLoaded = !retry && challengeId === challengeIdProp && !!data
  const showLoading = !dataLoaded && loading

  const isActivitySupported = challenge?.activity
    ? supportedActivities?.includes(challenge?.activity)
    : true

  const [items, separatorIndex] = useMemo(
    () =>
      prepareLeaderboard({
        data,
        isActivitySupported,
        memberUserId,
        metric: challenge?.metric,
      }),
    [data, memberUserId, isActivitySupported, challenge?.metric]
  )

  return (
    <RankedList
      headerComponent={children}
      items={!dataLoaded ? null : items}
      loading={showLoading}
      retry={retry}
      onRetryPress={getLeaderboard}
      separatorIndex={separatorIndex}
      title={I18n.t('WellnessCenter.challenges.leaderboard')}
      valueLabelTitle={getLocalUnitLabelForMetric(challenge?.metric, true)}
      emptyTitle={I18n.t('WellnessCenter.challenges.noParticipants.title')}
      emptyCopy={I18n.t('WellnessCenter.challenges.noParticipants.copy')}
      {...rest}
    />
  )
}

export default Leaderboard
