import React from 'react'
import { useNavigation } from '@react-navigation/native'

import Button from 'APP/Converse/Button'
import Carousel, { SlideData, SlideImage } from 'APP/Converse/Carousel'

import useScreenView from 'APP/Hooks/useScreenView'
import Analytics from 'APP/Services/Analytics'
import I18n from 'APP/Services/i18n'
import { RootScreens } from 'APP/Lib/Enums'
import { Images } from 'APP/Themes'

type SlideText = {
  title: string
  description: string
  primaryCta?: string
  tertiaryCta?: string
}

const TakeATourScreen = () => {
  const navigation = useNavigation()
  const { isBigScreen } = useScreenView()

  const images: SlideImage[] = [
    {
      source: Images.takeATour?.[I18n.baseLocale]?.slide0,
      resizeMode: 'contain',
    },
    {
      source: Images.takeATour?.[I18n.baseLocale]?.slide1,
      resizeMode: 'contain',
    },
    {
      source: Images.takeATour?.[I18n.baseLocale]?.slide2,
      resizeMode: isBigScreen ? 'contain' : 'cover',
    },
    {
      source: Images.takeATour?.[I18n.baseLocale]?.slide3,
      resizeMode: isBigScreen ? 'contain' : 'cover',
    },
    {
      source: Images.takeATour?.[I18n.baseLocale]?.slide4,
      resizeMode: 'contain',
    },
  ]

  const onCloseScreen = (isCta: boolean) => {
    Analytics.trackEvent('button_click', {
      button_value: isCta ? 'Finish the tour' : 'Close screen',
    })
    navigation.goBack()
  }

  const onChatWithAProfessional = () => {
    Analytics.trackEvent('button_click', {
      button_value: 'Chat with a professional',
    })
    navigation.navigate(RootScreens.chat)
  }

  const slideTexts: SlideText[] = I18n.t('TakeATour.carousel', { returnObjects: true })
  const slideData: SlideData[] = slideTexts.map((text, index) => {
    const data: SlideData = {
      title: text.title,
      description: text.description,
      image: images[index],
    }

    if (text.primaryCta) {
      data.primaryButtonProps = {
        title: text.primaryCta,
        onPress: () => onCloseScreen(true),
      }
    }

    if (text.tertiaryCta) {
      data.tertiaryButtonProps = {
        title: text.tertiaryCta,
        onPress: onChatWithAProfessional,
      }
    }

    return data
  })

  const renderCloseButton = () => {
    return (
      <Button
        variant="tertiary"
        iconVariant="materialIcons"
        icon="close"
        small={false}
        widthVariant="icon"
        iconPosition="right"
        onPress={() => onCloseScreen(false)}
        analyticsName="Take a tour close button"
        testID="take-a-tour-close-button"
      />
    )
  }

  return <Carousel slideData={slideData} header={renderCloseButton()} />
}

export default TakeATourScreen
