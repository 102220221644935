import styled from 'styled-components/native'
import React, { useCallback, useEffect, useRef } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import {
  AppointmentTypeInput,
  AvailabilitySlot,
  Language,
  Location,
  NewAppointment,
} from '@dialogue/timekeeper'

import I18n from 'APP/Services/i18n'
import { AppointmentBookingProps } from 'APP/Components/AppointmentBooking'
import { useCompatibility } from 'APP/Components/AppointmentBooking/utils/useCompatibility'
import { ProviderSelection } from 'APP/Components/AppointmentBooking/ProviderBased/providerSelection'
import ExpiredSchedule from 'APP/Components/AppointmentBooking/common/expiredSchedule'
import { providerBookingActions, DATE_FORMAT } from 'APP/Store/ProviderBooking'
import { selectPatientProfile, selectPatientProfileLocation } from 'APP/Redux/PatientRedux'
import {
  selectBookAppointmentRequest,
  selectSelectedPreferences,
} from 'APP/Store/ProviderBooking/selectors'
import { GetAvailabilitiesParams } from 'APP/Store/ProviderBooking/types'
import { BOOKING_FLOW } from '../constants'

const StyledView = styled.View`
  flex: 1;
`

const StyledScrollView = styled.ScrollView.attrs({
  contentContainerStyle: {
    flexGrow: 1,
  },
})`
  flex-grow: 1;
`

export const ProviderAppointmentBooking = (props: AppointmentBookingProps) => {
  const dispatch = useDispatch()
  const isBookedRef = useRef(false)
  const {
    close,
    sendAnswer,
    provider_id,
    appointment_type,
    date_from,
    date_until,
    member_id,
    guardian_id,
  } = useCompatibility(props, BOOKING_FLOW.PROVIDER_BASED)

  // @ts-expect-error store not typed
  const episodeId = useSelector((state) => state.history.currentChannelId)
  const profile = useSelector(selectPatientProfile)
  const province = useSelector(selectPatientProfileLocation)
  const location = `CA-${province}` as Location
  const language: Language = profile?.preferred_language || 'en'
  const {
    data: bookedAppointment,
    error: bookingError,
    loading: isBooking,
  } = useSelector(selectBookAppointmentRequest)
  const selectedPreferences = useSelector(selectSelectedPreferences)

  const isExpired = moment(date_until).isBefore(moment())

  const handleGetAvailabilities = useCallback(
    (params?: Partial<GetAvailabilitiesParams>) => {
      dispatch(
        providerBookingActions.getAvailabilities({
          appointment_type: appointment_type as AppointmentTypeInput,
          location,
          language,
          start_at: date_from,
          end_at: date_until,
          provider: provider_id ? [parseInt(provider_id, 10)] : undefined,
          ...params,
        })
      )
    },
    [dispatch, appointment_type, language, location, provider_id, date_from, date_until]
  )

  const handleQuit = useCallback(() => {
    sendAnswer({
      display: I18n.t('AppointmentBooking.none_of_these_work'),
      payload: { is_booked: false },
    })
    close?.()
  }, [sendAnswer, close])

  const handleBookAppointment = useCallback(
    (timeslot: AvailabilitySlot | null) => {
      // Don't even attempt to book something if we don't receive a timeslot.
      if (!timeslot) {
        return
      }

      const memberId = Number(member_id)
      const episodeOwnerId = Number(guardian_id) || memberId
      const payload: NewAppointment = {
        ...timeslot,
        appointment_type,
        episode_owner_id: episodeOwnerId,
        member_id: memberId,
        episode_id: episodeId,
      }

      dispatch(providerBookingActions.createAppointment(payload))
    },
    [appointment_type, episodeId, guardian_id, member_id, dispatch]
  )

  const onSuccessBooking = useCallback(() => {
    if (!bookedAppointment) return
    const displayMessage = moment.tz(bookedAppointment.start_at, moment.tz.guess()).format('LLL')
    const payload = {
      is_booked: true,
      ...bookedAppointment,
      // the following two properties are required by BM
      // They're Emerald/Input Health artifacts, and cannot be safely removed yet.
      until_at: bookedAppointment.end_at,
      service_id: -1,
      member_preference: selectedPreferences,
    }

    // NICHE BUG FIX ALERT 🐛 -------------
    // On a successful booking, before sending the member along in the flow,
    // clear the redux store, to allow for multiple appointment bookings in the same session
    dispatch(providerBookingActions.resetAppointment())
    // END OF NICHE BUG FIX 🐛 ------------

    sendAnswer({ display: displayMessage, payload })
    close?.()
  }, [bookedAppointment, sendAnswer, close, dispatch, selectedPreferences])

  const onErrorBooking = useCallback(() => {
    setTimeout(() => {
      dispatch(providerBookingActions.resetAppointment())
    }, 2000)
  }, [dispatch])

  useEffect(() => {
    dispatch(providerBookingActions.selectDay(moment(date_from).format(DATE_FORMAT)))
  }, [date_from, dispatch])

  useEffect(() => {
    handleGetAvailabilities()
  }, [handleGetAvailabilities])

  useEffect(() => {
    if (bookedAppointment && !isBooking && !isBookedRef.current) {
      isBookedRef.current = true
      onSuccessBooking()
    }
  }, [bookedAppointment, isBooking, onSuccessBooking])

  useEffect(() => {
    if (bookingError && !isBooking) {
      onErrorBooking()
    }
  }, [bookingError, isBooking, onErrorBooking])

  return (
    <StyledView testID="providerAppointmentBooking">
      <StyledScrollView>
        {isExpired ? (
          <ExpiredSchedule quitScheduling={handleQuit} />
        ) : (
          <ProviderSelection
            dateFrom={date_from}
            dateUntil={date_until}
            onConfirm={handleBookAppointment}
            onQuit={handleQuit}
            onGetAvailabilities={handleGetAvailabilities}
            appointmentType={appointment_type}
          />
        )}
      </StyledScrollView>
    </StyledView>
  )
}
