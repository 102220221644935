import React from 'react'
import { View, Text } from 'react-native'
import Tooltip from 'APP/Components/Tooltip'
import I18n from 'APP/Services/i18n'
import Styles from './style'
import moment from 'moment'

const DailyBreakDown = ({ currendDayText, dailyGoalMessage, acitveMinutes }) => {
  return (
    <View style={Styles.dailyBreakDownContainer} testID="dailyBreakdown">
      <Text style={Styles.dailyBreakDownWeekday}>{currendDayText}</Text>
      <Text style={Styles.dailyBreakDownMinutes}>
        {I18n.t('ActiveMinutes.dailyProgress.dailyBreakdown.title', { count: acitveMinutes })}
      </Text>
      <Text style={Styles.dailyBreakDownSubheader}>{dailyGoalMessage}</Text>
    </View>
  )
}

const ProgressPill = ({ disabled, progress = 0 }) => (
  <View style={[Styles.progressPillContainer, disabled && Styles.progressPillContainerDisabled]}>
    <View style={[Styles.progressPill, { flexGrow: disabled ? 0 : progress / 100 }]} />
  </View>
)

const weekDays = [1, 2, 3, 4, 5, 6, 7]
const DailyProgress = ({ dailyProgress, dailyGoal }) => {
  return (
    <View style={Styles.container} testID="dailyProgress">
      {weekDays.map((i) => {
        const progress = (dailyProgress || []).find(
          ({ date }) => moment(date, I18n.t('DateFormat')).isoWeekday() === i
        )
        const todaysWeekDay = moment().isoWeekday()
        const currentWeekDayLabel = moment.weekdays(i)
        const isCurrent = i === todaysWeekDay
        const isAfterCurrent = i > todaysWeekDay
        return (
          <Tooltip
            key={i}
            disabled={isAfterCurrent}
            testID={'dailyBreakdownTooltip'}
            analyticsName={`Daily progress ${currentWeekDayLabel}`}
            content={
              <DailyBreakDown
                currendDayText={currentWeekDayLabel}
                dailyGoalMessage={I18n.t('ActiveMinutes.dailyProgress.goal', {
                  dailyGoal: dailyGoal || 0,
                })}
                acitveMinutes={progress?.minutes}
              />
            }
          >
            <View style={Styles.dayContainer}>
              <ProgressPill disabled={isAfterCurrent} progress={progress?.percentage} />
              <Text
                style={[
                  Styles.dayLabel,
                  isAfterCurrent && Styles.textDisabled,
                  isCurrent && Styles.dayLabelCurrent,
                ]}
              >
                {currentWeekDayLabel.charAt(0)}
              </Text>
              {isCurrent && <View style={Styles.dot} testID="dot" />}
            </View>
          </Tooltip>
        )
      })}
    </View>
  )
}

export default DailyProgress
