import React, { useCallback, useEffect } from 'react'
import { destroy } from 'redux-form'
import { View } from 'react-native'
import Typography from 'APP/Converse/Typography'
import { useNavigation } from '@react-navigation/native'
import { connect, useDispatch } from 'react-redux'
import Spinner from 'react-native-loading-spinner-overlay'

// Services
import I18n from 'APP/Services/i18n'

// Actions
import { familyActions } from 'APP/Store/Family'

// Components
import { PrimaryButton } from 'APP/Components/Buttons'
import List from 'APP/Components/List'
import ListItem from 'APP/Converse/ListItem'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import DialogueBranding from 'APP/Components/DialogueBranding'
import Alert from 'APP/Converse/Alert'

// Styles
import Styles from './style'
import { Colors, Images } from 'APP/Themes'
import { selectServiceFeatures } from 'APP/Store/Content/selectors'
import { ResponsiveScrollView, ResponsiveView } from 'APP/Converse/Layout'

function handleCreateDependent(destroyForm, navigation) {
  destroyForm()
  navigation.navigate('newDependent')
}

function ScreenWithoutFamily({ isConnected, destroyForm, canCreateChild }) {
  const navigation = useNavigation()
  return (
    <ResponsiveView style={Styles.containerWithoutFamily} testID="no-family-view">
      <View style={Styles.topSection}>
        <Images.emptyFamilyIcon
          fillLine={Colors.text}
          accentFillLine={Colors.textInverted}
          testID="emptyFamilyIcon"
        />
        <Typography style={Styles.sectionText}>{I18n.t('FamilyScreen.empty.body')}</Typography>
      </View>
      <PrimaryButton
        title={I18n.t('FamilyScreen.empty.buttonA')}
        onPress={() => navigation.navigate('inviteFamilyMember')}
        analyticsName="FamilyScreen.empty.inviteAdult"
        disabled={!isConnected}
      />
      <View style={Styles.disclaimerContainer}>
        <Typography style={Styles.secondarySectionText}>
          {I18n.t('FamilyScreen.empty.disclaimerA')}
        </Typography>
      </View>
      {canCreateChild && (
        <>
          <PrimaryButton
            title={I18n.t('FamilyScreen.empty.buttonC')}
            analyticsName="FamilyScreen.empty.addChild"
            onPress={() => handleCreateDependent(destroyForm, navigation)}
            disabled={!isConnected}
          />
          <View style={Styles.disclaimerContainer}>
            <Typography style={Styles.secondarySectionText}>
              {I18n.t('FamilyScreen.empty.disclaimerC')}
            </Typography>
          </View>
        </>
      )}
    </ResponsiveView>
  )
}

function Family({ familyMembers }) {
  const navigation = useNavigation()
  if (!familyMembers || familyMembers.length === 0) return null
  return (
    <List containerStyle={Styles.listLine}>
      {familyMembers.map((member) => {
        const isChild = member.type === 'child'
        return (
          <ListItem
            key={member.id}
            title={
              (member.preferredName ? member.preferredName : member.givenName) +
              ' ' +
              member.familyName
            }
            subtitle={isChild ? I18n.t('FamilyScreen.full.child') : member.email}
            hideIconComponent={!isChild}
            onPress={
              isChild
                ? () => {
                    navigation.navigate('dependentProfile', { childId: member.id })
                  }
                : null
            }
            testID={isChild ? 'child-family-member' : 'registered-family-member'}
          />
        )
      })}
    </List>
  )
}

function InvitedFamily({ invitedFamilyMembers, cancelAdultInvite }) {
  if (!invitedFamilyMembers || invitedFamilyMembers.length === 0) return null

  const handleDeleteInvite = (member) => {
    return () =>
      Alert.alert(
        I18n.t('FamilyScreen.deleteInvite.title'),
        I18n.t('FamilyScreen.deleteInvite.body') + member.email + '?',
        [
          { text: I18n.t('FamilyScreen.deleteInvite.cancel') },
          {
            text: I18n.t('FamilyScreen.deleteInvite.delete'),
            onPress: () => cancelAdultInvite(member.id, member.email),
          },
        ]
      )
  }

  return (
    <View>
      <Typography style={Styles.sectionTitle}>{I18n.t('FamilyScreen.full.invited')}</Typography>
      <List containerStyle={Styles.listContainer}>
        {invitedFamilyMembers.map((member) => {
          return (
            <ListItem
              key={member.email}
              title={member.email}
              iconName={member.permissions === 'delete' ? 'close' : 'chevron-right'}
              onPress={handleDeleteInvite(member)}
              testID="invited-family-member"
            />
          )
        })}
      </List>
    </View>
  )
}

function ScreenWithFamily({
  canInviteAdult,
  canCreateChild,
  cancelAdultInvite,
  cancelAdultInviteRunning,
  familyMembers,
  invitedFamilyMembers,
  isConnected,
  destroyForm,
}) {
  const navigation = useNavigation()

  return (
    <View style={Styles.withFamily} testID={'with-family-view'}>
      <Spinner visible={cancelAdultInviteRunning} cancelable={false} />
      <ResponsiveScrollView>
        <Family familyMembers={familyMembers} />
        <InvitedFamily
          invitedFamilyMembers={invitedFamilyMembers}
          cancelAdultInvite={cancelAdultInvite}
        />
      </ResponsiveScrollView>
      <ResponsiveView>
        {canInviteAdult && (
          <PrimaryButton
            title={I18n.t('FamilyScreen.full.buttonA')}
            analyticsName="FamilyScreen.full.inviteAdult"
            onPress={() => navigation.navigate('inviteFamilyMember')}
            disabled={!isConnected}
          />
        )}
        {canCreateChild && (
          <PrimaryButton
            title={I18n.t('FamilyScreen.full.buttonC')}
            analyticsName="FamilyScreen.full.addChild"
            onPress={() => {
              destroyForm()
              navigation.navigate('newDependent')
            }}
            disabled={!isConnected}
          />
        )}
      </ResponsiveView>
    </View>
  )
}

function FamilyScreen(props) {
  const dispatch = useDispatch()
  const getFamily = useCallback(() => dispatch(familyActions.fetchFamilyRequest()), [dispatch])
  const { familyMembers, invitedFamilyMembers } = props

  useEffect(() => {
    getFamily()
  }, [getFamily])

  return (
    <NoticeBarContainer>
      {familyMembers.length > 0 || invitedFamilyMembers.length > 0 ? (
        <ScreenWithFamily {...props} />
      ) : (
        <ScreenWithoutFamily {...props} />
      )}
      <DialogueBranding />
    </NoticeBarContainer>
  )
}

const mapStateToProps = (state, props) => {
  const family = state.family
  const profile = state.patient?.profile
  const hasFamilyAccess = profile?.hasFamilyAccess
  const memberArray = Object.keys(family.members).map((key) => family.members[key])
  const serviceFeatures = selectServiceFeatures(state)

  const getInvitedMembers = (members) =>
    members.filter((member) => member && member.authId === null && member.type === 'guardian')

  const getRegisteredMembers = (selfId, members) => {
    return members.filter((member) => {
      return (
        member &&
        ((member.authId !== null && `${selfId}` !== `${member.id}`) || member.type === 'child')
      )
    })
  }

  return {
    ...props.route?.params,
    familyMembers: getRegisteredMembers(state.patient.profile.id, memberArray),
    invitedFamilyMembers: getInvitedMembers(memberArray),
    cancelAdultInviteRunning: family.requestRunning,
    canInviteAdult: hasFamilyAccess,
    canCreateChild: serviceFeatures.adding_children && hasFamilyAccess,
    isConnected: state.appSession.isConnected,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    destroyForm: () => dispatch(destroy('updateDependentForm')),
    cancelAdultInvite: (memberId, email) =>
      dispatch(familyActions.cancelAdultInviteRequest({ memberId, email })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FamilyScreen)
