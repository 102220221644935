import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { contentSelectors } from 'APP/Store/Content'
import { resolveServiceFeatures } from 'APP/Lib/ServiceFeatures'

export function useServiceFeatures() {
  const serviceFeatures = useSelector(contentSelectors.selectServiceFeaturesState)
  const eligibleServices = useSelector((state) => state?.patient?.profile?.eligibleServices)

  const resolvedServiceFeaturesMap = useMemo(
    () => resolveServiceFeatures(serviceFeatures, eligibleServices),
    [serviceFeatures, eligibleServices]
  )

  return resolvedServiceFeaturesMap
}
