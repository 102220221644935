import { StyleSheet, Platform } from 'react-native'
import { Colors, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.appBg,
  },
  headerRightButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 2,
    ...Platform.select({
      android: { marginRight: Metrics.baseMargin },
    }),
  },
})
