import React, { useEffect } from 'react'
import { Linking, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

import PatientActions from 'APP/Redux/PatientRedux'
// Services
import I18n from 'APP/Services/i18n'
import Analytics from 'APP/Services/Analytics'

// Components
import ChallengedInvitationFooter from 'APP/Components/ChallengedInvitationFooter'
import SignupLogoHeader from 'APP/Components/SignupLogoHeader'
import { ResponsiveScrollView } from 'APP/Converse/Layout'

// Styles
import { Metrics } from 'APP/Themes'
import ListItem from 'APP/Converse/ListItem'
import { handlePressContactSupport } from 'APP/Helpers/handlePressContactSupport'
import { helpAndSupportUrl } from 'APP/Services/UrlBuilder'
import { useDevTools } from 'APP/Lib/DevTools'

const contentContainerInnerStyle = {
  justifyContent: 'space-between',
  flex: 1,
}

const contentContainerStyle = {
  flex: 1,
  paddingBottom: Metrics.baseMargin,
}

const signupLogoHeaderStyle = {
  marginHorizontal: Metrics.baseMargin,
}

export const EligibilityClaimScreen = ({ navigation, route }) => {
  const { analyticsName, code } = route?.params || {}

  const { challengedInvitationQueue, isOnboarding } = useSelector((state) => state.patient)

  const { setToolName, setToolFunction } = useDevTools()

  useEffect(() => {
    setToolName('Access Code')
    setToolFunction(() => () => {
      navigateToClaimAccessScreen()
    })
    return () => {
      setToolFunction(null)
      setToolName(null)
    }
  }, [])

  const dispatch = useDispatch()

  const isInChallengedQueue = (challengedInvitationQueue ?? []).length > 0

  const handleSupport = () => {
    Analytics.trackEvent(
      'button_click',
      {
        button_text: 'Contact us',
        button_value: `ClaimAccessScreen.contactUs`,
      },
      ['banner_context']
    )
    handlePressContactSupport()
  }

  const onboardingDeferredEnrolmentComplete = () => {
    dispatch(PatientActions.onboardingDeferredEnrolmentComplete())
  }
  const navigateToClaimAccessScreen = () => {
    navigation.navigate('claimAccessScreen', { code })
  }

  const resendInvite = () => {
    navigation.navigate('invitationEmail')
  }

  const navigateToFAQ = () => {
    Linking.openURL(helpAndSupportUrl())
  }
  return (
    <ResponsiveScrollView
      alwaysBounceVertical={false}
      keyboardShouldPersistTaps="always"
      contentContainerInnerStyle={contentContainerInnerStyle}
      contentContainerStyle={contentContainerStyle}
    >
      <View>
        <SignupLogoHeader
          title={I18n.t('EligibilityClaimScreen.title')}
          subtitle={I18n.t('EligibilityClaimScreen.subtitle')}
          style={signupLogoHeaderStyle}
        />
        <ListItem
          title={I18n.t('EligibilityClaimScreen.options.accessCodeTitle')}
          subtitle={I18n.t('EligibilityClaimScreen.options.accessCodeSubtitle')}
          onPress={navigateToClaimAccessScreen}
        />
        <ListItem
          title={I18n.t('EligibilityClaimScreen.options.resendEmailTitle')}
          subtitle={I18n.t('EligibilityClaimScreen.options.resendEmailSubtitle')}
          onPress={resendInvite}
        />
        <ListItem
          title={I18n.t('EligibilityClaimScreen.options.faqTitle')}
          subtitle={I18n.t('EligibilityClaimScreen.options.faqSubtitle')}
          iconName="exit-to-app"
          onPress={navigateToFAQ}
        />
        <ListItem
          title={I18n.t('EligibilityClaimScreen.options.supportTitle')}
          subtitle={I18n.t('EligibilityClaimScreen.options.supportSubtitle')}
          iconName="exit-to-app"
          onPress={handleSupport}
        />
      </View>
      {(isOnboarding || isInChallengedQueue) && (
        <ChallengedInvitationFooter
          analyticsName={analyticsName}
          onPress={isOnboarding && onboardingDeferredEnrolmentComplete}
        />
      )}
    </ResponsiveScrollView>
  )
}
