import { put, call, select } from 'redux-saga/effects'

import Config from 'APP/Config'
import { Recommender } from '@dialogue/services'
import CoreData from 'APP/Services/CoreData'
import WellBeingActions from 'APP/Redux/WellBeingRedux'
import { selectLoginState } from 'APP/Redux/LoginRedux'
import { selectPatientState } from 'APP/Redux/PatientRedux'

export const selectAccessToken = (state) => state.login.accessToken
export const selectIsWellBeingIndexAssessmentEligible = (state) => {
  const eligibleServices = state.patient?.profile?.eligibleServices
  return Boolean(eligibleServices?.wellbeing_index_assessment)
}

export function* createRecommendationsService() {
  const accessToken = yield select(selectAccessToken)

  return new Recommender.Service(accessToken, Config.RECOMMENDER_SERVICE_URL)
}

export function* getLatestWellBeingAssessment() {
  try {
    const login = yield select(selectLoginState)
    const patient = yield select(selectPatientState)
    const coredataClient = CoreData.create(login.accessToken)
    const response = yield call(coredataClient.getMemberQuestionnaires, {
      memberId: patient.profile.id,
      params: { type: 'WBI', limit: 1 },
    })

    if (response.ok) {
      yield put(WellBeingActions.getLatestSuccess((response.data?.data || [])[0] || null))
    } else {
      throw response.originalError
    }
  } catch (e) {
    yield put(WellBeingActions.getLatestFailure())
  }
}

export function* getRecommendations() {
  try {
    const RecommenderService = yield call(createRecommendationsService)
    const recommendations = yield call(RecommenderService.getWbiRecommendations)

    yield put(WellBeingActions.getRecommendationsSuccess(recommendations))
  } catch (e) {
    yield put(WellBeingActions.getRecommendationsFailure())
  }
}
