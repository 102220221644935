import React, { useEffect, useState, useMemo } from 'react'
import {
  View,
  Text,
  FlatList,
  Button,
  Modal,
  Pressable,
  TouchableWithoutFeedback,
  ScrollView,
} from 'react-native'
import moment from 'moment'
import PushNotification from 'react-native-push-notification'
import I18n from 'APP/Services/i18n'
import { Metrics } from 'APP/Themes'
import { ListItem } from 'react-native-elements'
import Styles from './style'
import { Colors } from '../../Themes'
import Alert from 'APP/Converse/Alert'

const ScheduledLocalNotificationListScreen = () => {
  const [scheduledNotifications, setScheduledNotifications] = useState([])
  const [isRefreshing, setRefreshing] = useState(false)
  const [scheduledNotificationDetails, setScheduledNotificationDetails] = useState(null)

  const getScheduledLocalNotifications = () => {
    setRefreshing(true)
    PushNotification.getScheduledLocalNotifications((scheduledNotifications) => {
      setScheduledNotifications(scheduledNotifications)
      setRefreshing(false)
    })
  }

  const cancelAllLocalNotifications = () => {
    PushNotification.cancelAllLocalNotifications()
  }

  const cancelLocalNotification = (notificationId) => {
    PushNotification.cancelLocalNotification(notificationId)
  }

  useEffect(() => {
    getScheduledLocalNotifications()
    return () => {
      setScheduledNotificationDetails(null)
    }
  }, [])

  const handleCancelAllScheduleNotification = () => {
    Alert.alert(I18n.t('ScheduledLocalNotificationListScreen.cancelAllNotificationsAlert'), '', [
      {
        text: I18n.t('ScheduledLocalNotificationListScreen.cancel'),
        style: 'cancel',
      },
      {
        text: I18n.t('ScheduledLocalNotificationListScreen.confirm'),
        onPress: () => {
          cancelAllLocalNotifications()
          getScheduledLocalNotifications()
        },
      },
    ])
  }

  const handleCancelScheduleNotification = (scheduledNotification) => {
    Alert.alert(I18n.t('ScheduledLocalNotificationListScreen.cancelNotificationAlert'), '', [
      {
        text: I18n.t('ScheduledLocalNotificationListScreen.cancel'),
        style: 'cancel',
      },
      {
        text: I18n.t('ScheduledLocalNotificationListScreen.confirm'),
        onPress: () => {
          cancelLocalNotification(scheduledNotification.id)
          getScheduledLocalNotifications()
          setScheduledNotificationDetails(null)
        },
      },
    ])
  }

  const hasScheduledNotificationDetailsData = useMemo(() => {
    return Boolean(scheduledNotificationDetails?.data)
  }, [scheduledNotificationDetails])

  return (
    <View style={Styles.container}>
      <FlatList
        contentContainerStyle={Styles.list}
        onEndReachedThreshold={0.8}
        data={scheduledNotifications}
        onRefresh={() => getScheduledLocalNotifications()}
        refreshing={isRefreshing}
        ListHeaderComponent={() => {
          return (
            <View style={Styles.listHeader}>
              <Text>
                {`${I18n.t('ScheduledLocalNotificationListScreen.total')} ${
                  scheduledNotifications.length
                }`}
              </Text>
            </View>
          )
        }}
        ListEmptyComponent={() => {
          return (
            <View style={Styles.listEmpty}>
              <Text>{I18n.t('ScheduledLocalNotificationListScreen.listEmpty')}</Text>
              <Button title="Refresh" onPress={() => getScheduledLocalNotifications()} />
            </View>
          )
        }}
        ListFooterComponent={() => {
          return (
            <View style={Styles.listFooter}>
              {scheduledNotifications.length > 0 && (
                <Button
                  title="Cancel all notifications"
                  onPress={() => handleCancelAllScheduleNotification()}
                  color={Colors.rejectButtonBg}
                />
              )}
            </View>
          )
        }}
        renderItem={({ item }) => {
          return (
            <ListItem
              key={item.id}
              topDivider
              bottomDivider
              onPress={() => setScheduledNotificationDetails(item)}
            >
              <ListItem.Content>
                <View style={Styles.listItemTitle}>
                  <Text>{`${item.id}`}</Text>
                  <Text>
                    {item.date
                      ? item.date.toString()
                      : I18n.t('ScheduledLocalNotificationListScreen.notDefined')}
                  </Text>
                </View>
                <View style={Styles.listItemSubtitle}>
                  <Text>{`${item.title}`}</Text>
                  <Text>{`${item.message}`}</Text>
                </View>
              </ListItem.Content>
            </ListItem>
          )
        }}
      />
      <Modal
        statusBarTranslucent
        transparent={true}
        visible={scheduledNotificationDetails !== null}
        onRequestClose={() => setScheduledNotificationDetails(null)}
      >
        {scheduledNotificationDetails && (
          <Pressable onPress={() => setScheduledNotificationDetails(null)} style={Styles.overlay}>
            <TouchableWithoutFeedback>
              <View style={Styles.modal}>
                <ScrollView style={Styles.content}>
                  <View>
                    <Text style={Styles.dt}>
                      {I18n.t('ScheduledLocalNotificationListScreen.id')}
                    </Text>
                    <Text style={Styles.dd}>{scheduledNotificationDetails.id}</Text>
                    <Text style={Styles.dt}>
                      {I18n.t('ScheduledLocalNotificationListScreen.dateUTC')}
                    </Text>
                    <Text style={Styles.dd}>
                      {moment.utc(scheduledNotificationDetails.date || null).isValid()
                        ? moment
                            .utc(scheduledNotificationDetails.date)
                            .format('YYYY-MM-DD T HH:mm:ssZ')
                        : I18n.t('ScheduledLocalNotificationListScreen.dateInvalid')}
                    </Text>
                    <Text style={Styles.dt}>
                      {I18n.t('ScheduledLocalNotificationListScreen.dateLocale')}
                    </Text>
                    <Text style={Styles.dd}>
                      {moment.utc(scheduledNotificationDetails.date || null).isValid()
                        ? moment
                            .utc(scheduledNotificationDetails.date)
                            .local()
                            .format('YYYY-MM-DD T HH:mm:ssZ')
                        : I18n.t('ScheduledLocalNotificationListScreen.dateInvalid')}
                    </Text>
                    <Text style={Styles.dt}>
                      {I18n.t('ScheduledLocalNotificationListScreen.repeatInterval')}
                    </Text>
                    <Text style={Styles.dd}>
                      {scheduledNotificationDetails.repeatInterval ||
                        I18n.t('ScheduledLocalNotificationListScreen.notDefined')}
                    </Text>
                    <Text style={Styles.dt}>
                      {I18n.t('ScheduledLocalNotificationListScreen.notification')}
                    </Text>
                    <Text style={Styles.dd}>{scheduledNotificationDetails.title}</Text>
                    <Text style={Styles.dd}>{scheduledNotificationDetails.message}</Text>
                    <Text style={Styles.dt}>
                      {I18n.t('ScheduledLocalNotificationListScreen.soundName')}
                    </Text>
                    <Text style={Styles.dd}>
                      {scheduledNotificationDetails.soundName ||
                        I18n.t('ScheduledLocalNotificationListScreen.notDefined')}
                    </Text>
                    <Text style={Styles.dt}>
                      {I18n.t('ScheduledLocalNotificationListScreen.number')}
                    </Text>
                    <Text style={Styles.dd}>
                      {scheduledNotificationDetails.number ||
                        I18n.t('ScheduledLocalNotificationListScreen.notDefined')}
                    </Text>
                    <Text style={Styles.dt}>
                      {I18n.t('ScheduledLocalNotificationListScreen.data')}
                    </Text>
                    <View style={[Styles.dd, { marginLeft: Metrics.baseMargin }]}>
                      {hasScheduledNotificationDetailsData ? (
                        Object.entries(scheduledNotificationDetails.data || {}).map(
                          ([key, value], index) => {
                            return (
                              <View
                                key={key}
                                style={{ marginTop: index === 0 ? Metrics.baseMargin / 2 : 0 }}
                              >
                                <Text style={Styles.dt}>{key}</Text>
                                <Text style={Styles.dd}>{JSON.stringify(value)}</Text>
                              </View>
                            )
                          }
                        )
                      ) : (
                        <Text>{I18n.t('ScheduledLocalNotificationListScreen.notDefined')}</Text>
                      )}
                    </View>
                  </View>
                  <Button
                    title={I18n.t('ScheduledLocalNotificationListScreen.cancelNotification')}
                    onPress={() => handleCancelScheduleNotification(scheduledNotificationDetails)}
                    color={Colors.rejectButtonBg}
                  />
                </ScrollView>
              </View>
            </TouchableWithoutFeedback>
          </Pressable>
        )}
      </Modal>
    </View>
  )
}

export default ScheduledLocalNotificationListScreen
