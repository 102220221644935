import React, { Component } from 'react'
import { AppState, View, Platform, StatusBar } from 'react-native'
import RNRestart from 'react-native-restart'
import { Appearance } from 'react-native-appearance'
import NetInfo from '@react-native-community/netinfo'
import PushNotificationIOS from '@react-native-community/push-notification-ios'
import PushNotification from 'react-native-push-notification'
import 'react-native-url-polyfill/auto'
import { connect } from 'react-redux'
import * as SystemUI from 'expo-system-ui'
import _ from 'lodash'
import * as RNLocalize from 'react-native-localize'

// Actions
import StartupActions from 'APP/Redux/StartupRedux'
import AppSessionActions from 'APP/Redux/AppSessionRedux'
import DeeplinkActions from 'APP/Redux/DeeplinkRedux'
import HabitsActions from 'APP/Redux/HabitsRedux'

// Components
import InAppMessageNotification from 'APP/Components/InAppMessageNotification'
import DebugTools from 'APP/Components/DebugTools'
import Toast from 'APP/Components/Toast'
import { navigationRef as Nav, NavigationRoot, PREVENT_DARK_MODE_RESTART_ROUTES } from 'APP/Nav'

// Services
import Config from 'APP/Config'
import {
  getChannelIdFromNotification,
  getDeeplinkFromNotification,
} from 'APP/Lib/NotificationHelpers'
import { openUrl } from 'APP/Services/Branch'

// Styles
import { Colors } from 'APP/Themes'
import styles from './style'
import { AlertRoot } from 'APP/Converse/Alert'
import IdleTimer from 'APP/Lib/IdleTimer'
import { isMobile } from 'APP/Helpers/checkPlatform'

class RootContainer extends Component {
  constructor(props) {
    super(props)
    // Configuration must be outside of any component LifeCycle
    PushNotification.configure({
      onRegister: (token) => {
        this._handlePushNotificationRegistration(token)
      },
      onNotification: (notification) => this._handleRemoteNotification(notification),
      onRegistrationError: (err) => {
        this._handlePushNotificationRegistrationError(err)
      },
      permissions: {
        alert: true,
        badge: true,
        sound: true,
      },
      popInitialNotification: false,
      requestPermissions: false,
    })

    PushNotification.popInitialNotification((notification) =>
      this._handleInitialNotification(notification)
    )

    this.colorScheme = Appearance.getColorScheme()
  }

  componentDidMount() {
    this.unsubscribeConnectionListener = () => {}

    NetInfo.fetch().then((state) => {
      this.props.setIsConnected(state.isConnected)
    })
    this.unsubscribeConnectionListener = NetInfo.addEventListener(this._handleNetworkChange)

    this.appearanceChangeSubscription = Appearance.addChangeListener(this._handledAppearanceChange)
    this.appStateSubscription = AppState.addEventListener('change', this._handleEventChange)
    if (isMobile()) {
      RNLocalize.addEventListener('change', (e) => {
        console.log('--RNLocalize', e)
        this.props.updateLocalize()
      })
    }

    if (Platform.OS !== 'ios') {
      // Do we actually need this on ios? It causes a bug with iOS modals
      // (white background with white content looks weird)
      SystemUI.setBackgroundColorAsync(Colors.appBg)
    }
    this.props.createNotificationChannels()
  }

  componentWillUnmount() {
    clearTimeout(this.colorSchemeRestartTimeout)
    this.unsubscribeConnectionListener()
    this.appStateSubscription.remove()
    this.appearanceChangeSubscription.remove()
  }

  /*
    Current implementation of dark mode support requires a bundle reload.
    Some screens with unrecoverable states do not support this, so we retry
    until the user is on a supported screen.
  */
  colorSchemeRestartCheck = () => {
    if (this.colorScheme !== Appearance.getColorScheme()) {
      const routeName = Nav?.getCurrentRoute()?.name
      const routeSupportsRestart = PREVENT_DARK_MODE_RESTART_ROUTES.indexOf(routeName) === -1

      routeSupportsRestart
        ? RNRestart.Restart()
        : (this.colorSchemeRestartTimeout = setTimeout(this.colorSchemeRestartCheck, 5000))
    }
  }

  /*
    Debouncing to handle a bug in Appearance which causes it to trigger an event with
    incorrect colorScheme when the app is backgrounded, followed momentarily by the correct value.
    https://github.com/expo/expo/issues/10815
  */
  _handledAppearanceChange = _.debounce(this.colorSchemeRestartCheck, 1000)

  _handleInitialNotification = (notification) => {
    const channelId = getChannelIdFromNotification(notification)

    if (channelId) {
      this.props.setDeferredDeeplink('conversation', { channelId })
    }
  }

  _handleRemoteNotification = (notification) => {
    // See if there's a deeplink in the notification, if there is its an ✨ E N G A G E M E N T ✨ notification
    const deeplink = getDeeplinkFromNotification(notification)

    if (deeplink) {
      // Forward link to Branch, which will cause DeeplinkSaga.watchDeeplinkRequests to be triggered
      // Not reachable on web, so we don't need to worry about this until we implement notifications on web
      openUrl(deeplink)
    } else {
      // Received notification in the background, technically only fires on iOS afaik but just double check before calling these APIs
      if (Platform.OS === 'ios') {
        // Only tick the badge over for messaging notifications
        if (!this.props.isActive && getChannelIdFromNotification(notification)) {
          PushNotification.setApplicationIconBadgeNumber(1)
        }
        notification.finish(PushNotificationIOS.FetchResult.NoData) //required
      }
    }
  }

  _handlePushNotificationRegistration = (tokenResult) => {
    const { token } = tokenResult
    if (token && token.length > 0) {
      this.props.registerToken(token)
    }
  }

  _handlePushNotificationRegistrationError = (error) => {
    this.props.registerTokenFailure(null, `Failed to get device token. Error: ${error}`)
  }

  _handleEventChange = (appState) => {
    if (appState === 'active' && !this.props.isActive) this.props.foreground()
    if (appState === 'background') this.props.background()
  }

  _handleNetworkChange = (state) => {
    this.props.setIsConnected(state.isInternetReachable)
  }

  render() {
    return (
      <View style={styles.applicationView}>
        <StatusBar translucent backgroundColor={'transparent'} />
        <NavigationRoot />
        <InAppMessageNotification />
        {this.props.showDebugFab && <DebugTools />}
        <Toast />
        <AlertRoot />
        <IdleTimer />
      </View>
    )
  }
}

const mapStateToProps = (state) => {
  const showDebugFab = (__DEV__ || Config.ENABLE_DEBUG_MENU) && state.app.showDebugFab
  return {
    showDebugFab,
    isActive: state.appSession.isActive,
    systemErrors: state.appSession.systemErrors,
  }
}

const mapDispatchToProps = (dispatch) => ({
  foreground: (prevState) => dispatch(AppSessionActions.setAppForegrounded({ prevState })),
  background: () => dispatch(AppSessionActions.setAppBackgrounded()),
  setIsConnected: (isConnected) => dispatch(AppSessionActions.setIsConnected(isConnected)),
  registerToken: (pushToken) => dispatch(StartupActions.setPushTokenRequest(pushToken)),
  registerTokenFailure: (pushToken, error) =>
    dispatch(StartupActions.setPushTokenFailure(pushToken, error, 0)),
  setDeferredDeeplink: (path, props) => dispatch(DeeplinkActions.setDeferredDeeplink(path, props)),
  createNotificationChannels: () => dispatch(StartupActions.createNotificationChannels()),
  updateLocalize: () => dispatch(HabitsActions.ensureNotificationParity()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RootContainer)
