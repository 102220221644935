import React from 'react'

import { HistoricalLineChart } from './../Charts'
import DefaultRecommendation from './DefaultRecommendation'

const ProgressRecommendation = (props) => (
  <>
    <HistoricalLineChart {...props.sources} onSetScrollEnabled={props.onSetScrollEnabled} />
    <DefaultRecommendation {...props} />
  </>
)

export default ProgressRecommendation
