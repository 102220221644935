import React, { memo, useCallback, useContext, useEffect } from 'react'
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import { useDispatch, useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components/native'

import Button from 'APP/Converse/Button'
import Typography from 'APP/Converse/Typography'
import { getServiceOrServiceGroupTileIcon } from 'APP/Lib/ContentHelpers'
import { PostContext } from 'APP/Lib/Context'
import { navigationRef as Nav } from 'APP/Nav'
import {
  selectEpharmacyServiceByExternalId,
  selectPatientProfile,
  selectPreferredLanguage,
} from 'APP/Redux/PatientRedux'
import { contentActions } from 'APP/Store/Content'
import { selectOneServiceGroupState } from 'APP/Store/Content/selectors'
import { selectPharmacyById } from 'APP/Store/Pharmacies/selectors'
import { RootState } from 'APP/Store/CreateStore'

import TextMessage from '../TextMessage'

interface PharmacyService {
  attributes: {
    cmsContent: {
      cmsContentId: string
      cmsContentType: string
    }[]
    epharmacyIdentifier: string
    epharmacyProvider: string
  }
}

const Container = styled.View`
  flex: 1;
  max-width: 600px;
`

const Wrapper = styled.View<{ $disabled: boolean }>`
  flex-direction: column;
  border-radius: ${({ theme }) => theme.metrics.borderRadius.small}px;
  padding: ${({ theme }) => theme.metrics.baseMargin}px;
  margin-top: ${({ theme }) => theme.metrics.baseMargin / 4}px;
  background-color: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.lightGray : theme.colors.elementsBg};
`

const Row = styled.View`
  flex-direction: row;
`

const InnerContainer = styled.View`
  flex: 1;
`

const IconContainer = styled.View`
  margin-right: ${({ theme }) => theme.metrics.baseMargin / 1.5}px;
`

const Title = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin / 2}px;
`

const CtaButton = styled(Button)`
  margin-top: ${({ theme }) => theme.metrics.baseMargin}px;
`

const EpharmacyPrescription = () => {
  const dispatch = useDispatch()
  const { post } = useContext(PostContext)
  const theme = useTheme()

  const locale = useSelector(selectPreferredLanguage)
  const profile = useSelector(selectPatientProfile)
  const context = post?.props?.choices[0]?.context
  const serviceGroupId: string = context?.entry_id?.[locale]
  const pharmacyId = Number(context?.epharmacy_id)
  const isNotPreferredPharmacy = pharmacyId !== profile?.pharmacy_id
  const { data: serviceGroup } =
    useSelector((state: RootState) => selectOneServiceGroupState(state, serviceGroupId)) || {}

  const pharmacy = useSelector((state: RootState) => selectPharmacyById(state, pharmacyId))
  const pharmacyService: PharmacyService = useSelector((state: RootState) =>
    // @ts-expect-error selector is not typed
    selectEpharmacyServiceByExternalId(state, pharmacy?.external_id)
  )

  const cmsContentIds =
    pharmacyService?.attributes.cmsContent.map((entry) => entry.cmsContentId) || []

  const serviceContent = serviceGroup?.services.find((service) =>
    cmsContentIds.includes(service.id)
  )

  useEffect(() => {
    dispatch(contentActions.getOneServiceGroup({ id: serviceGroupId, locale }))
  }, [dispatch, serviceGroupId, locale])

  const navigationUrl =
    serviceContent && 'navigationUrl' in serviceContent ? serviceContent.navigationUrl : null

  const onPress = useCallback(() => {
    // @ts-expect-error navigationRef is not typed
    Nav.navigate('pillwayPortalScreen', {
      navigationUrl,
      serviceGroup,
    })
  }, [navigationUrl, serviceGroup])

  return (
    <Container testID="epharmacy-card-view">
      <TextMessage />
      {serviceContent && (
        <Wrapper $disabled={isNotPreferredPharmacy}>
          <Row>
            <IconContainer>
              {getServiceOrServiceGroupTileIcon(serviceContent, {
                width: 24,
                height: 24,
                fillLine: theme.colors.serviceIconPrimary,
                accentFillLine: theme.colors.serviceIconAccent,
              })}
            </IconContainer>
            <InnerContainer>
              <Title variant="h4">{serviceContent.title}</Title>
              <Typography variant="bodySmall">
                <MaterialCommunityIcon name="open-in-new" color={theme.colors.accent} />{' '}
                {serviceContent.subtitle}
              </Typography>
            </InnerContainer>
          </Row>
          <CtaButton
            variant="primary"
            disabled={isNotPreferredPharmacy}
            title={serviceContent.ctaLabel}
            onPress={onPress}
            analyticsName="ctaTitle"
            widthVariant="full"
          />
        </Wrapper>
      )}
    </Container>
  )
}

export default memo(EpharmacyPrescription)
