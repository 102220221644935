import { StyleSheet } from 'react-native'
import { Metrics, Colors } from 'APP/Themes'
import { isWeb } from 'APP/Helpers/checkPlatform'

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.appBg,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dotsContainer: {
    flexDirection: 'row',
    paddingBottom: Metrics.baseMargin * 1.5,
    alignItems: 'center',
  },
  imageContainer: {
    height: isWeb() ? '60%' : '50%',
    backgroundColor: Colors.contrastElementBg,
    paddingTop: Metrics.baseMargin * 2,
  },
  heroImage: {
    height: '100%',
    width: 'auto',
  },
  heroTexts: {
    alignItems: 'center',
    textAlign: 'center',
    gap: Metrics.baseMargin,
    padding: Metrics.baseMargin * 1.5,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 400,
    textAlignVertical: 'center',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  textContainer: {
    gap: Metrics.baseMargin,
  },
  headerContainer: {
    display: 'flex',
    paddingTop: Metrics.statusBarHeight + 10,
    paddingBottom: 10,
    zIndex: 2,
    width: '100%',
    alignItems: 'flex-end',
    paddingRight: Metrics.baseMargin + 4,
  },
  buttonContainer: {
    alignItems: 'center',
    paddingBottom: Metrics.baseMargin * 2,
  },
})
