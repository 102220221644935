import React from 'react'

import { Text, View } from 'react-native'
import { ForeignObject, G } from 'react-native-svg'

import Styles, { triangleSize } from './style'

const maxLabelSize = 150
const symbolSize = 7
const minTriangleLeft = 2

const TooltipFlyout = ({ x, y, datum }) => {
  const labelLength = datum.textLabel.length
  const toolTipSize = Math.min(maxLabelSize, labelLength * symbolSize + 10)

  const dx = x - toolTipSize / 2
  const isTooltipOut = dx < 0

  return (
    <G style={{ pointerEvents: 'none' }}>
      <ForeignObject
        x={Math.max(0, dx)}
        y={y - 75}
        width={toolTipSize}
        height="60"
        style={{ overflow: 'visible' }}
      >
        <View style={[Styles.container, { width: toolTipSize }]}>
          <View style={Styles.labelContainer}>
            <Text style={Styles.textLabel}>{datum.textLabel}</Text>
            <Text style={Styles.scoreLabel}>{datum.y}</Text>
          </View>
          <View
            style={[
              Styles.triangle,
              isTooltipOut
                ? {
                    alignSelf: 'flex-start',
                    left: Math.max(toolTipSize / 2 - triangleSize + dx, minTriangleLeft),
                  }
                : null,
            ]}
          />
        </View>
      </ForeignObject>
    </G>
  )
}

export default TooltipFlyout
