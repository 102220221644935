import React from 'react'
import { useTheme } from 'styled-components/native'
import { useNavigation } from '@react-navigation/native'

import { handleNavigationContactInfo } from 'APP/Helpers/handleNavigationContactInfo'
import Icon from 'APP/Converse/Icon'

import { getAnalyticsScreenName } from '../utils'
import { ServiceGroupCard } from '../ServiceGroupCard'

export const ContactInfoGroupCard = ({
  serviceGroup,
  contactInfo,
}: {
  serviceGroup: Record<string, unknown>
  contactInfo: { id: string; name: string; cardText: string; cardCallToAction: string }
}) => {
  const theme = useTheme()
  const navigation = useNavigation()
  const analyticsScreenName = getAnalyticsScreenName(serviceGroup)
  const { id, name } = contactInfo || {}
  const onPress = () => {
    handleNavigationContactInfo(contactInfo, navigation)
  }

  return (
    <ServiceGroupCard
      onPress={onPress}
      testID="CONTACT_INFO"
      analyticsName="contactInfo"
      icon={
        <Icon
          src="help-outline"
          variant="materialIcons"
          color="accent"
          size={theme.metrics.icon.xsmall}
        />
      }
      cardText={contactInfo.cardText}
      callToAction={contactInfo.cardCallToAction}
      analyticsScreenName={analyticsScreenName}
      trackEventValue={`showContactInfo : ${id} : ${name}`}
      trackEventText="Show Contact Info"
    />
  )
}
