import { StyleSheet } from 'react-native'
import { ApplicationStyles, Metrics, Colors, Fonts } from 'APP/Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  containerWithoutFamily: {
    flex: 1,
  },
  topSection: {
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: 300,
    marginVertical: Metrics.baseMargin,
  },
  underButtonText: {
    ...ApplicationStyles.secondarySectionText,
    marginHorizontal: Metrics.baseMargin,
  },
  secondaryHalfButton: {
    backgroundColor: Colors.elementsBg,
    borderWidth: 1,
    borderColor: Colors.acceptButtonBg,
    margin: Metrics.baseMargin,
    padding: 0,
    height: 41,
  },
  listLine: {
    ...ApplicationStyles.listLine,
    marginTop: 0,
    paddingTop: 0,
  },
  listItem: {
    ...ApplicationStyles.line,
  },
  listItemContainer: {
    borderBottomColor: Colors.grayBg,
  },
  sectionTitle: {
    color: Colors.text,
    textAlign: 'left',
    marginTop: Metrics.baseMargin,
    marginLeft: Metrics.baseMargin,
    paddingBottom: 10,
    textTransform: 'uppercase',
    ...Fonts.style.bodySmallBold,
  },
  disclaimerContainer: {
    marginHorizontal: Metrics.baseMargin,
  },
  withFamily: {
    flex: 1,
  },
  sectionText: {
    ...Fonts.style.h3,
    color: Colors.text,
    marginVertical: Metrics.baseMargin,
    marginHorizontal: Metrics.baseMargin,
    textAlign: 'center',
  },
})
