import styled from 'styled-components/native'
import Icon from 'APP/Converse/Icon'
import Typography from 'APP/Converse/Typography'
import React, { useEffect, useState } from 'react'
import { EstimatedTimeComponentProps } from './types'
import I18n from 'APP/Services/i18n'
import { Expand } from 'APP/Converse/Expand'
import useScreenView from 'APP/Hooks/useScreenView'

const EstimationCard = styled.View<{ expanded: boolean }>`
  border-radius: 30px;
  padding: ${({ theme: { metrics } }) => metrics.baseMargin}px;
  background-color: ${({ theme }) => theme.colors.elementsBg};
  ${({ theme }) => theme.shadows.normal};
  gap: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.elementsBorder};
  align-self: center;
  max-width: ${({ theme }) => (theme.isBigScreen ? 600 : 350)}px;
`

const ExpandContent = styled(Typography)`
  max-width: ${({ theme }) => (theme.isBigScreen ? 500 : 250)}px;
`

const Title = styled(ExpandContent)`
  margin-bottom: ${({ expanded, theme }) => (expanded ? theme.metrics.baseMargin / 4 : 0)}px;
`

export const EstimatedTimeComponent = ({ positionInQueue }: EstimatedTimeComponentProps) => {
  const [expanded, setExpanded] = useState(true)
  const [savedPositionInQueue, setPositionInQueue] = useState<number | undefined>()

  const { isBigScreen } = useScreenView()

  useEffect(() => {
    setTimeout(() => {
      setExpanded(false)
    }, 5000)
  }, [])

  // The position in the queue should never increase.
  useEffect(() => {
    if (savedPositionInQueue === undefined || savedPositionInQueue > positionInQueue) {
      setPositionInQueue(positionInQueue)
    }
  }, [savedPositionInQueue, positionInQueue])

  return (
    <EstimationCard expanded={expanded}>
      <Icon variant="material" src="timer-sand" color="accent" size={expanded ? 40 : 20} />
      <Expand
        expanded={expanded}
        onExpand={setExpanded}
        expandToHeight={isBigScreen ? 32 : 100}
        collapseTriggerContent={
          <Title color="text" variant={expanded ? 'h3' : 'h4'} expanded={expanded}>
            {I18n.t('ConversationScreen.estimatedTime.title', {
              count: savedPositionInQueue,
            })}
          </Title>
        }
      >
        {expanded && (
          <ExpandContent color="dim">
            {I18n.t('ConversationScreen.estimatedTime.numberOfOtherPeople', {
              count: savedPositionInQueue,
            })}
          </ExpandContent>
        )}
      </Expand>
    </EstimationCard>
  )
}
