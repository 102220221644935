import React from 'react'
import { Pressable, Image } from 'react-native'
import _ from 'lodash'
import { value, styler, listen, pointer, calc, inertia, tween } from 'popmotion'

// Styling
import Styles from './style'

// Images
import iconPlus from './resources/ic_plus.png'
import iconMinus from './resources/ic_minus.png'
import iconZoom from './resources/ic_zoom.png'
import maleEn from './resources/male_en.png'
import maleFr from './resources/male_fr.png'
import femaleEn from './resources/female_en.png'
import femaleFr from './resources/female_fr.png'
import highlightImage from './resources/highlight.png'

// Data
import maleRegionsData from './resources/male.json'
import femaleRegionsData from './resources/female.json'

//Services
import I18n from 'APP/Services/i18n'
import Analytics from 'APP/Services/Analytics'

/*
  This BodyPart component is pulled from legacy member-webapp, and should be deleted once
  legacy bodypart messages are fully removed from the backend.
*/

const HIGHLIGHT_SCALE_FACTOR = 0.25 // Expand region 25% of it's size in all directions
const HIGHLIGHT_OPACITY = 0.6

const resources = {
  male: {
    imgMap: {
      en: maleEn,
      fr: maleFr,
    },
    regions: maleRegionsData,
  },
  female: {
    imgMap: {
      en: femaleEn,
      fr: femaleFr,
    },
    regions: femaleRegionsData,
  },
}

export class BodyPartSelector extends React.Component {
  constructor(props) {
    super(props)
    const { resourceId, readOnly, style, lang } = props
    const { imgMap } = resources[resourceId]
    const imgSrc = imgMap[lang] || imgMap.en

    this.containerStyles = Object.assign(
      {},
      Styles.container,
      readOnly && Styles.containerDisabled,
      style
    )
    this.svgContainerStyles = Object.assign({}, Styles.bodyPartContainer, {
      WebkitMaskImage: `url('${imgSrc}')`,
      opacity: HIGHLIGHT_OPACITY,
    })

    this.state = {
      imageSize: [0, 0],
      scale: props.imageScale,
    }
  }

  slider = {}
  moved = false
  initialPointerPosition = { x: 0, y: 0 }
  startDrag = false
  scale = this.props.imageScale
  nextScale = this.props.imageScale

  componentDidMount() {
    if (this.props.zoom) {
      const innerContainerStyler = styler(this.innerContainerRef)
      this.innerContainerXY = value({ x: 0, y: 0 }, innerContainerStyler.set)
      this.innerContainerScale = value(this.props.imageScale, (scale) =>
        innerContainerStyler.set({
          width: 100 * scale + '%',
          height: 100 * scale + '%',
        })
      )
      this.mouseDownListener = listen(this.containerRef, 'mousedown').start(() => {
        this.moved = false
        this.startDrag = true
      })
      this.mouseMoveListener = listen(this.containerRef, 'mousemove').start(() => {
        if (this.startDrag) {
          this.initialPointerPosition = this.innerContainerXY.get()
          this.startDrag = false
          pointer(this.initialPointerPosition)
            .pipe(this.getXYValuesClapmpPipe(this.getXYBounds(), 0.2), this.trackMovement)
            .start(this.innerContainerXY)
        }
      })
      this.mouseUpListener = listen(document, 'mouseup').start(() => {
        this.startDrag = false
        if (this.scale === this.nextScale) {
          const { xMax, xMin, yMax, yMin } = this.getXYBounds()
          inertia({
            min: { x: xMin, y: yMin },
            max: { x: xMax, y: yMax },
            from: this.innerContainerXY.get(),
            velocity: this.innerContainerXY.getVelocity(),
            power: 0.1,
            bounceStiffness: 400,
            bounceDamping: 40,
          }).start(this.innerContainerXY)
        } else {
          this.resize()
        }
      })
      if (typeof this.props.zoomRef === 'function') {
        this.props.zoomRef({
          zoom: this.resize,
          reset: this.zoomReset,
        })
      }
    }
  }

  imageLoadEventToDimensionsPx = (event) => {
    const { width, height } = event.currentTarget.getBoundingClientRect()
    return [width, height]
  }

  clickEventToFloatCoords = (event) => {
    const boundingRect = event.currentTarget.getBoundingClientRect()
    const { width, height, left, top } = boundingRect
    const x = event.pageX - window.scrollX - left
    const y = event.pageY - window.scrollY - top
    return [x / width, y / height]
  }

  selectMatchingRegions = (regions, [x, y]) => {
    const matches = regions.filter(
      ({ coords: [x1, y1, x2, y2] }) => x > x1 && x < x2 && y > y1 && y < y2
    )
    const uniqueMatches = _.uniqBy(matches, 'sz')
    return uniqueMatches
  }

  coordsWithScaling = ([x1, y1, x2, y2]) => {
    const w = (x2 - x1) * HIGHLIGHT_SCALE_FACTOR
    const h = (y2 - y1) * HIGHLIGHT_SCALE_FACTOR
    return [x1 - w, y1 - h, x2 + w, y2 + h]
  }

  BodyPart = (props) => {
    const [x1, y1, x2, y2] = this.coordsWithScaling(props.coords)
    const w = x2 - x1
    const h = y2 - y1
    const zIndex = 1000 - Math.ceil(w * h * 1000) // Smaller areas on top
    return (
      <image
        // eslint-disable-next-line react/no-unknown-property
        testID={`bodyPartRegion-${props.sz}`}
        key={props.id}
        x={x1 * 100 + '%'}
        y={y1 * 100 + '%'}
        width={w * 100 + '%'}
        height={h * 100 + '%'}
        style={{ zIndex }}
        href={`${highlightImage}`}
        preserveAspectRatio="none"
      />
    )
  }

  componentWillUnmount() {
    if (this.mouseDownListener) this.mouseDownListener.stop()
    if (this.mouseMoveListener) this.mouseMoveListener.stop()
    if (this.mouseUpListener) this.mouseUpListener.stop()
  }

  setInnerContainerRef = (node) => {
    if (node) this.innerContainerRef = node
  }

  setContainerRef = (node) => {
    if (node) this.containerRef = node
  }

  trackMovement = (value) => {
    if (!this.moved) {
      const { x, y } = value
      const { x: initX, y: initY } = this.initialPointerPosition
      this.moved = [initX - x, initY - y].some((v) => Math.abs(v) > 4)
    }
    return value
  }

  clampValueWithTug = (v, min, max, tug) => {
    if (v < min) return calc.getValueFromProgress(min, v, tug)
    if (v > max) return calc.getValueFromProgress(max, v, tug)
    return v
  }

  getXYBounds = (scale = this.scale, rect = this.innerContainerRef.getBoundingClientRect()) => {
    const { width: x, height: y } = rect
    const allowedMovement = (scale - this.props.imageScale) / scale / 2
    return {
      xMax: x * allowedMovement,
      xMin: -x * allowedMovement,
      yMax: y * allowedMovement,
      yMin: -y * allowedMovement,
    }
  }

  getXYValuesClapmpPipe =
    ({ xMin, xMax, yMin, yMax }, tug = 0) =>
    ({ x, y }) => ({
      x: this.clampValueWithTug(x, xMin, xMax, tug),
      y: this.clampValueWithTug(y, yMin, yMax, tug),
    })

  resize = (nextRequestedScale = this.nextScale) => {
    const { maxZoomScale, minZoomScale } = this.props
    let nextScale = nextRequestedScale
    if (nextRequestedScale < minZoomScale) nextScale = minZoomScale
    if (nextRequestedScale > maxZoomScale) nextScale = maxZoomScale

    const { width, height } = this.innerContainerRef.getBoundingClientRect()
    const currentScale = this.innerContainerScale.get()
    const nextRect = {
      width: (width * nextScale) / currentScale,
      height: (height * nextScale) / currentScale,
    }
    const clamp = this.getXYValuesClapmpPipe(this.getXYBounds(nextScale, nextRect))
    const currentXY = this.innerContainerXY.get()
    const animationDuration = (500 * Math.abs(currentScale - nextScale)) / this.props.zoomStep
    tween({
      from: currentXY,
      to: clamp({
        x: (currentXY.x * nextScale) / currentScale,
        y: (currentXY.y * nextScale) / currentScale,
      }),
      duration: animationDuration,
    }).start(this.innerContainerXY)
    tween({
      from: currentScale,
      to: nextScale,
      duration: animationDuration,
    }).start(this.innerContainerScale)

    this.nextScale = nextScale
    this.scale = nextScale
  }

  zoomIn = () => {
    Analytics.trackEvent('button_click', {
      button_text: 'Zoom in',
      button_value: 'Zoom in tap',
      button_container: 'bodyPartPicker',
    })
    this.nextScale = Math.min(this.scale + this.props.zoomStep, this.props.maxZoomScale)
    this.setState({ nextScale: this.nextScale })
    setTimeout(() => {
      this.resize()
    }, 100)
  }
  zoomOut = () => {
    Analytics.trackEvent('button_click', {
      button_text: 'Zoom out',
      button_value: 'Zoom out tap',
      button_container: 'bodyPartPicker',
    })
    this.nextScale = Math.max(this.scale - this.props.zoomStep, this.props.minZoomScale)
    this.setState({ nextScale: this.nextScale })
    setTimeout(() => {
      this.resize()
    }, 100)
  }
  zoomReset = () => {
    Analytics.trackEvent('button_click', {
      button_text: 'Zoom reset',
      button_value: 'Zoom reset tap',
      button_container: 'bodyPartPicker',
    })
    this.nextScale = this.props.minZoomScale
    this.setState({ nextScale: this.nextScale })
    setTimeout(() => {
      this.resize()
    }, 100)
  }

  isRegionSelected = (region) => {
    const { sz } = region
    return this.props.selectedValues.indexOf(sz) !== -1
  }

  setSelectedValues = (nextValues) => {
    const {
      selectedValues,
      maxSelectionCount,
      minSelectionCount,
      onChange,
      onMaxReached,
      onMinReached,
    } = this.props
    const maxReached = nextValues.length > maxSelectionCount
    const minReached = nextValues.length <= minSelectionCount
    if (maxReached) {
      onMaxReached(selectedValues)
      return
    }
    if (minReached) {
      onMinReached(nextValues)
    }
    onChange(nextValues)
  }

  toggleSelection = (region) => {
    const { selectedValues } = this.props
    if (this.isRegionSelected(region)) {
      this.setSelectedValues(selectedValues.filter((v) => v !== region.sz))
    } else {
      this.setSelectedValues([...selectedValues, region.sz])
    }
  }

  track = (payload) => {
    const { props } = payload
    if (props) {
      Analytics.trackEvent('button_click', {
        button_value: props.value,
        button_container: 'chat',
        ...props,
      })
    }
  }

  handleMapClick = (event) => {
    const { resourceId } = this.props
    if (this.moved) {
      this.track({
        props: {
          mouseMoved: this.moved,
          value: `User clicked on Body Part, but unable to select due to mouse was moving`,
        },
      })
      return
    }

    const { regions } = resources[resourceId]
    const eventCoords = this.clickEventToFloatCoords(event)
    const matchingRegions = this.selectMatchingRegions(regions, eventCoords)
    matchingRegions.forEach(this.toggleSelection)

    this.track({
      props: {
        mouseMoved: this.moved,
        resourceId,
        region: _.get(matchingRegions, '[0].text'),
        value: `User clicked and selected a body part`,
      },
    })
  }

  handleImageLoad = (event) => {
    const imageSize = this.imageLoadEventToDimensionsPx(event)
    this.setState({ imageSize })
  }

  render() {
    const { resourceId, className, zoom, lang } = this.props
    const { imageSize } = this.state
    const { imgMap, regions } = resources[resourceId]
    const imgSrc = imgMap[lang] || imgMap.en
    return (
      <div
        className={className}
        style={this.containerStyles}
        ref={this.setContainerRef}
        onClick={this.onContainerClick}
        // eslint-disable-next-line react/no-unknown-property
        testID="bodyPartSelector"
      >
        <div style={Styles.containerInner} ref={this.setInnerContainerRef}>
          <img
            src={imgSrc}
            style={Styles.bodyImage}
            onLoad={this.handleImageLoad}
            alt={I18n.t('ImageMap.alt')}
          />
          <svg viewBox={`0 0 ${imageSize[0]} ${imageSize[1]}`} style={this.svgContainerStyles}>
            {regions.filter(this.isRegionSelected).map(this.BodyPart)}
            <rect
              // eslint-disable-next-line react/no-unknown-property
              testID="bodyPartSelectorClickHandler"
              width="100%"
              height="100%"
              fill="transparent"
              onClick={this.handleMapClick}
            />
          </svg>
        </div>
        {zoom && (
          <div style={Styles.zoomContainer}>
            <Pressable
              style={Styles.iconButton}
              onPress={this.zoomIn}
              ariaLabel={I18n.t('Common.zoomIn')}
            >
              <Image resizeMode="contain" source={{ uri: iconPlus }} style={Styles.zoomIcon} />
            </Pressable>
            <img src={iconZoom} style={Styles.zoomIcon} />
            <Pressable
              style={Styles.iconButton}
              onPress={this.zoomOut}
              ariaLabel={I18n.t('Common.zoomOut')}
            >
              <Image resizeMode="contain" source={{ uri: iconMinus }} style={Styles.zoomIcon} />
            </Pressable>
          </div>
        )}
      </div>
    )
  }
}

BodyPartSelector.defaultProps = {
  selectedValues: [],
  onChange: () => {},
  onContainerClick: () => {},
  onError: () => {},
  onMaxReached: () => {},
  onMinReached: () => {},
  imageScale: 0.9,
  zoomStep: 1.5,
  maxZoomScale: 4,
  minZoomScale: 0.9,
  readOnly: false,
  lang: 'en',
  resourceId: 'male',
}

export default BodyPartSelector
