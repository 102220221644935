import { StyleSheet } from 'react-native'
import { Colors, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  contentWrapper: {
    alignItems: 'flex-start',
  },
  logo: {
    marginBottom: Metrics.baseMargin,
    marginHorizontal: 0,
  },
  mainHeader: {
    marginBottom: Metrics.baseMargin * 0.5,
  },
  subHeader: {
    marginBottom: Metrics.baseMargin * 2,
    color: Colors.text,
  },
  content: {
    alignItems: 'center',
  },
  wrapper: {
    marginBottom: Metrics.baseMargin * 1.25,
    width: 311,
  },
  button: {
    marginRight: Metrics.baseMargin * 0.5,
    marginTop: Metrics.baseMargin,
  },
  contactUs: {
    marginTop: Metrics.baseMargin * 0.75,
    textAlign: 'center',
    color: Colors.text,
  },
  link: {
    fontWeight: 'bold',
    color: Colors.text,
    textDecorationLine: 'underline',
  },
})
