const { ActiveMinutes } = require('@dialogue/services')
const { Platform } = require('react-native')

export const SDK_TRACKERS = [ActiveMinutes.Types.TrackerName.AppleHealth]

const defaultSupportedTrackers = [
  ActiveMinutes.Types.TrackerName.GoogleFit,
  ActiveMinutes.Types.TrackerName.Fitbit,
  ActiveMinutes.Types.TrackerName.Garmin,
  ActiveMinutes.Types.TrackerName.Strava,
]

export const supportedTrackers = Platform.select({
  ios: [ActiveMinutes.Types.TrackerName.AppleHealth, ...defaultSupportedTrackers],
  default: defaultSupportedTrackers,
})

export const comingSoonTrackers = [
  ActiveMinutes.Types.TrackerName.SamsungHealth,
  ActiveMinutes.Types.TrackerName.Withings,
]

export const supportedTrackerActivities = {
  [ActiveMinutes.Types.TrackerName.AppleHealth]: [
    ActiveMinutes.Types.ActivityType.STEPS,
    ActiveMinutes.Types.ActivityType.RUNNING,
    ActiveMinutes.Types.ActivityType.CYCLING,
    ActiveMinutes.Types.ActivityType.SWIMMING,
  ],
  [ActiveMinutes.Types.TrackerName.GoogleFit]: [
    ActiveMinutes.Types.ActivityType.STEPS,
    ActiveMinutes.Types.ActivityType.RUNNING,
    ActiveMinutes.Types.ActivityType.CYCLING,
    ActiveMinutes.Types.ActivityType.SWIMMING,
  ],
  [ActiveMinutes.Types.TrackerName.Fitbit]: [
    ActiveMinutes.Types.ActivityType.STEPS,
    ActiveMinutes.Types.ActivityType.RUNNING,
    ActiveMinutes.Types.ActivityType.CYCLING,
    ActiveMinutes.Types.ActivityType.SWIMMING,
  ],
  [ActiveMinutes.Types.TrackerName.Garmin]: [
    ActiveMinutes.Types.ActivityType.STEPS,
    ActiveMinutes.Types.ActivityType.RUNNING,
    ActiveMinutes.Types.ActivityType.CYCLING,
    ActiveMinutes.Types.ActivityType.SWIMMING,
  ],
  [ActiveMinutes.Types.TrackerName.Strava]: [
    ActiveMinutes.Types.ActivityType.RUNNING,
    ActiveMinutes.Types.ActivityType.CYCLING,
    ActiveMinutes.Types.ActivityType.SWIMMING,
  ],
}
