import React, { useCallback } from 'react'
import { Modal, StyleSheet, View } from 'react-native'
import { Styles } from './style'
import IntakeReplyForm from 'APP/Components/Intake/IntakeReplyForm'
import { isWeb } from 'APP/Helpers/checkPlatform'

const Wrapper = ({ children }) =>
  isWeb() ? (
    <View testID="webview-wrapper" style={StyleSheet.absoluteFillObject}>
      {children}
    </View>
  ) : (
    <Modal statusBarTranslucent visible transparent animationType="slide" testID="modal-wrapper">
      {children}
    </Modal>
  )

export const ConditionalOverlayForm = ({ conditionalOverlayFormData, sendAnswer, onClose }) => {
  const sendAdditionalAnswer = useCallback(
    (answer) => {
      sendAnswer(answer)
    },
    [sendAnswer]
  )

  return (
    <Wrapper>
      <IntakeReplyForm
        inline={false}
        onClose={onClose}
        style={[Styles.conditionalOverlayForm]}
        sendAnswer={sendAdditionalAnswer}
        {...conditionalOverlayFormData}
      />
    </Wrapper>
  )
}
