// TODO(WEB): Implement react-native-health shim / refactor away from this.

export const getDailyStepCountSamples = () => {}

export const getAnchoredWorkouts = () => {}

export const initHealthKit = () => {}

export const clearHealthKitCache = () => {}

export const transformSamplesOptionsToAddMetricsParams = () => {
  return { fromDate: '', toDate: '' }
}

export const getAllActivitySamples = () => {
  return { fromDate: '', toDate: '' }
}

export const listenToAllActivities = () => {
  return { fromDate: '', toDate: '' }
}

export const Constants = {
  Permissions: {
    AppleExerciseTime: true,
    DistanceCycling: true,
    DistanceSwimming: true,
    DistanceWalkingRunning: true,
    StepCount: true,
    Steps: true,
    Workout: true,
  },
}

export default {
  getDailyStepCountSamples,
  getAnchoredWorkouts,
  initHealthKit,
  clearHealthKitCache,
  transformSamplesOptionsToAddMetricsParams,
  getAllActivitySamples,
  listenToAllActivities,
  Constants,
}
