import React, { useState, useEffect } from 'react'
import { StyleSheet, Modal, AlertButton } from 'react-native'

import Card from 'APP/Converse/Card'
import styled from 'styled-components/native'
import Typography from 'APP/Converse/Typography'
import Button from 'APP/Converse/Button'

const ModalOverlay = styled.View`
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
`

const AlertCard = styled(Card)`
  width: 100%;
  max-width: 550px;
`

const Content = styled.View`
  padding: ${({ theme }) => theme.metrics.baseMargin}px 0;
`

const Footer = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.metrics.baseMargin}px;
`

type AlertProps = {
  title: string
  message: string
  buttons?: AlertButton[]
} | null

type AlertHandler = {
  api: {
    alert: (title: string, message: string, buttons?: AlertButton[]) => void
  }
  use: () => [AlertProps, (previousAlertsCallback: AlertProps) => void]
}

function createAlertHandler(): AlertHandler {
  let subscriber: ((alertProps: AlertProps) => void) | undefined
  let alertProps: AlertProps

  let setAlerts = (previousAlertsCallback: AlertProps) => {
    alertProps = previousAlertsCallback
    // Notify alert root
    if (subscriber) subscriber(alertProps)
  }

  function use(): [AlertProps, (previousAlertsCallback: AlertProps) => void] {
    let [localAlerts, subscription] = useState(alertProps)

    // Subscribe to external changes
    useEffect(() => {
      subscriber = subscription
      return () => {
        subscriber = undefined
      }
    }, [subscription])

    // Set callback
    return [localAlerts, setAlerts]
  }

  return {
    api: {
      alert: (title: string, message: string, buttons?: AlertButton[]) => {
        setAlerts({ title, message, buttons })
      },
    },
    use,
  }
}
const AlertHandler = createAlertHandler()

export function AlertRoot() {
  const [alertProps, setAlerts] = AlertHandler.use()

  const onClose = () => {
    setAlerts(null)
  }

  const onPressBtn = (onPressEvent: AlertButton['onPress']) => () => {
    if (onPressEvent) onPressEvent()
    onClose()
  }

  if (!alertProps) return null

  const { title, message, buttons } = alertProps

  return (
    <Modal visible transparent animationType="fade" onRequestClose={onClose}>
      <ModalOverlay style={[StyleSheet.absoluteFill]}>
        <AlertCard>
          <Typography variant="h3">{title}</Typography>
          {Boolean(message) && (
            <Content>
              <Typography>{message}</Typography>
            </Content>
          )}
          {Boolean(buttons) && (
            <Footer>
              {buttons?.map(({ text, onPress }, buttonIndex) => (
                <Button
                  key={buttonIndex}
                  widthVariant="content"
                  variant={buttonIndex ? 'primary' : 'secondary'}
                  title={text}
                  onPress={onPressBtn(onPress)}
                />
              ))}
            </Footer>
          )}
        </AlertCard>
      </ModalOverlay>
    </Modal>
  )
}

export default AlertHandler.api
