import Icon from 'APP/Converse/Icon'
import Typography from 'APP/Converse/Typography'
import I18n from 'APP/Services/i18n'
import React from 'react'
import { useNavigation } from '@react-navigation/native'
import styled from 'styled-components/native'
import Card from 'APP/Converse/Card'
import { Images } from 'APP/Themes/index'
import { RootScreens } from 'APP/Lib/Enums'

const CardContainer = styled(Card)`
  padding: ${({ theme }) => theme.metrics.baseMargin}px;
  flex-direction: row;
  max-width: 252px;
  margin: ${({ theme }) => theme.metrics.baseMargin}px auto;
  gap: ${({ theme }) => theme.metrics.baseMargin}px;
  justify-content: space-between;
`

const TextContainer = styled.View`
  flex: 1;
`

const Header = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin / 4}px;
`

const SubHeader = styled(Typography)`
  flex-shrink: 1;
`

const ChevronRightIcon = styled(Icon)`
  align-self: center;
`

export const CompleteCard = () => {
  const navigation = useNavigation()

  return (
    <CardContainer
      onPress={() => navigation.navigate(RootScreens.chat)}
      testID="card-complete-container"
    >
      <Icon
        src={Images.taskComplete}
        variant="image"
        size={20}
        color="accent"
        testID="task-complete-icon"
      />
      <TextContainer>
        <Header variant="h4">{I18n.t('ConversationScreen.complete.title')}</Header>
        <SubHeader variant="bodyNormal">{I18n.t('ConversationScreen.complete.subTitle')}</SubHeader>
      </TextContainer>
      <ChevronRightIcon
        src="chevron-right"
        variant="materialIcons"
        size={20}
        color="accent"
        testID="chevron-right-icon"
      />
    </CardContainer>
  )
}
