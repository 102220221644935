import React, { useMemo } from 'react'
import { View } from 'react-native'
import { ForeignObject, G, Circle } from 'react-native-svg'

import { Metrics } from 'APP/Themes'
import { tooltipStyles as Styles } from './style'

const ActiveScore = (props) => {
  const { x, y, color, origin, renderContent } = props

  const positionFrom = useMemo(() => {
    if (y < origin.y * 0.6) return 'top'
    return 'bottom'
  }, [origin, y])

  return (
    <G style={Styles.container}>
      <Circle cx={x} cy={y} r={7} fill={color} />
      <ForeignObject x={0} y={0} style={Styles.foreignObject}>
        <View style={Styles.outerContainer}>
          <View
            style={[
              Styles.innerContainer,
              {
                left: x,
                top: y,
              },
            ]}
          >
            <View style={[Styles.tooltip, { [positionFrom]: Metrics.baseMargin * 1.25 }]}>
              <View style={Styles.content}>
                {typeof renderContent === 'function' && renderContent(props)}
              </View>
              <View style={[Styles.tail, Styles[`${positionFrom}Tail`]]} />
            </View>
          </View>
        </View>
      </ForeignObject>
    </G>
  )
}

export default ActiveScore
