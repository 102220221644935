import { View } from 'react-native'
import { Appearance } from 'react-native-appearance'
import styled from 'styled-components/native'

export const ShadowView = styled(View)<{ isSelected?: boolean }>`
  padding: 0px;
  margin: 0px;
  border-radius: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  border: ${({ theme, isSelected }) =>
    Appearance.getColorScheme() === 'dark'
      ? isSelected
        ? `1px solid ${theme.colors.elementsBorder}`
        : 'transparent'
      : 'none'};
  ${({ theme }) => theme.shadows.border};
`
