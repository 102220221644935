import React from 'react'
import Overlay from './overlay'
import { DialogProvider } from './provider'
import { Body, BottomDrawerProps } from 'APP/Converse/BottomDrawer'
import Header, { HeaderProps } from './header'
import Button from 'APP/Converse/Button'
import { ApplicationStyles, Metrics } from 'APP/Themes'
import styled from 'styled-components/native'
import Typography from '../Typography'

const Container = styled.View`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

type ContentContainerProps = {
  width?: number
}

const ContentContainer = styled.View<ContentContainerProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: ${({ width, theme: { isBigScreen } }) => (isBigScreen ? width : '90%')};
  width: 90%;
`

export const Subtitle = styled(Typography)`
  margin-bottom: ${Metrics.baseMargin}px;
  width: 100%;
`

const Content = styled.View`
  ${ApplicationStyles.shadow};
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: ${({ theme }) => theme.metrics.borderRadius.medium}px;
  padding: ${Metrics.baseMargin * 1.5}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export type DialogProps = ContentContainerProps & BottomDrawerProps & HeaderProps

/**
 * A Dialog component that can display a title, subtitle, action buttons, and custom content.
 * It is useful for presenting users with a choice or additional information that requires their attention.
 *
 * @param {DialogProps} props - The props for the Dialog component.
 * @returns {JSX.Element} The rendered Dialog component.
 */
function Dialog({
  title,
  subtitle,
  isVisible,
  onClose,
  primaryButtonProps,
  secondaryButtonProps,
  testId = 'dialog',
  children,
  width = 600,
  closeButtonProps,
}: DialogProps) {
  return (
    <DialogProvider isVisible={isVisible} onClose={onClose} testID={testId}>
      <Container>
        <Overlay />
        <ContentContainer width={width}>
          <Content>
            {title && <Header title={title} closeButtonProps={closeButtonProps} />}
            {subtitle && <Subtitle variant="bodySmall">{subtitle}</Subtitle>}
            {children || (
              <Body>
                {secondaryButtonProps && (
                  <Button
                    variant="secondary"
                    widthVariant="half"
                    textColor="error"
                    small
                    {...secondaryButtonProps}
                  />
                )}
                {primaryButtonProps && (
                  <Button variant="primary" widthVariant="half" small {...primaryButtonProps} />
                )}
              </Body>
            )}
          </Content>
        </ContentContainer>
      </Container>
    </DialogProvider>
  )
}

export default Dialog
