import { StyleSheet } from 'react-native'
import { Colors, Fonts, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.appBg,
  },
  scrollViewContentContainer: {
    paddingBottom: Metrics.bottomSpace + Metrics.baseMargin,
  },
  title: {
    ...Fonts.style.h3,
    color: Colors.text,
    margin: Metrics.baseMargin,
  },
  noPastData: {
    display: 'flex',
    alignItems: 'center',
    paddingVertical: Metrics.baseMargin * 4.5,
  },
  noPastDataTitle: {
    ...Fonts.style.subheaderBold,
    color: Colors.text,
    marginBottom: Metrics.baseMargin * 0.75,
  },
  noPastDataCopy: {
    ...Fonts.style.body,
    color: Colors.text,
  },
  loading: {
    flex: 1,
  },
  retry: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  retryTitle: {
    ...Fonts.style.h3,
    color: Colors.text,
    marginTop: Metrics.baseMargin * 2,
    marginBottom: Metrics.baseMargin,
  },
  retryCopy: {
    ...Fonts.style.bodyLarge,
    color: Colors.text,
  },
  retryCta: {
    marginTop: Metrics.baseMargin * 2,
    minWidth: 140,
  },
  seeMoreButton: {
    marginBottom: Metrics.baseMargin * 0.5,
  },
})
