import React from 'react'
import { Text, Image, ScrollView } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'
import I18n from 'APP/Services/i18n'

// Actions
import PatientActions from 'APP/Redux/PatientRedux'

// Styles
import Styles from './style'
import { Images } from 'APP/Themes'

// Components
import Spinner from 'react-native-loading-spinner-overlay'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import DialogueBranding from 'APP/Components/DialogueBranding'
import { PrimaryButton } from 'APP/Components/Buttons'

// TODO: Delete this, or delete GeolocationServiceScreen. They are almost the same, save for the branding.
// Main difference includes on the saga action when the user accepts or skips location permission
const GeolocationScreen = () => {
  const dispatch = useDispatch()
  const locationRequestRunning = useSelector((state) => state.app.locationRequestRunning)

  const handleShareLocationPress = () =>
    dispatch(PatientActions.patientLocationPermissionRequest(false))

  return (
    <NoticeBarContainer float>
      <Spinner visible={locationRequestRunning} cancelable={false} />
      <ScrollView contentContainerStyle={Styles.container}>
        <Image style={Styles.placeholderImage} source={Images.geolocation} />
        <Text style={Styles.subtitle}>{I18n.t('GeolocationScreen.infoText')}</Text>
        <PrimaryButton
          title={I18n.t('GeolocationScreen.shareMyLocationButton')}
          analyticsName="GeolocationScreen.shareMyLocationButton"
          onPress={handleShareLocationPress}
        />
        <DialogueBranding />
      </ScrollView>
    </NoticeBarContainer>
  )
}

export default GeolocationScreen
