import { StyleSheet } from 'react-native'
import { Colors, Fonts, Metrics } from 'APP/Themes'

let style = StyleSheet.create({
  dateSheetIOS: {
    backgroundColor: Colors.elementsBg,
    marginLeft: 0,
    marginRight: 0,
    alignItems: 'flex-end',
  },
  dateSheetSpacer: {
    height: 200,
  },
  done: {
    marginRight: Metrics.baseMargin,
    marginLeft: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin / 2,
    marginTop: Metrics.baseMargin / 2,
    fontFamily: Fonts.type.bold,
    fontWeight: '500',
    fontSize: Fonts.size.large,
    lineHeight: Fonts.lineHeight.large,
    color: Colors.nativeIosBlue,
  },
})

export default style
