import React from 'react'
import { View, Text } from 'react-native'
import I18n from 'APP/Services/i18n'
import Button from 'APP/Converse/Button'

import Styles from './style'

const NoHabitsForPastDay = () => (
  <View style={Styles.card} testID="noHabitsCard">
    <Text style={Styles.title}>{I18n.t('Habits.noHabits.pastDayTitle')}</Text>
    <Text style={Styles.copy}>{I18n.t('Habits.noHabits.pastDayCopy')}</Text>
  </View>
)

const NoHabitsForToday = ({ onAddHabitPress }) => (
  <View style={Styles.cardOutline} testID="noHabitsCard">
    <Text style={Styles.copy}>{I18n.t('Habits.noHabits.copy')}</Text>
    <Button
      variant="oomph"
      title={I18n.t('Habits.noHabits.cta')}
      onPress={onAddHabitPress}
      analyticsName="noHabits.add"
    />
  </View>
)

const NoHabitsCard = ({ isToday, onAddHabitPress }) => {
  const Compoenent = isToday ? NoHabitsForToday : NoHabitsForPastDay
  return <Compoenent onAddHabitPress={onAddHabitPress} />
}

export default NoHabitsCard
