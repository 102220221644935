import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Fonts, Metrics } from '../../../Themes'

const baseParticipant = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderBottomWidth: 1,
  // TODO: Remove references to fixed shadow color
  borderBottomColor: Colors.shadow,
}

export default StyleSheet.create({
  participantsHeader: {
    marginBottom: Metrics.baseMargin * 0.75,
    paddingHorizontal: Metrics.baseMargin * 1.9,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  participantsTitle: {
    ...Fonts.style.h3,
    color: Colors.text,
  },
  participantsMetric: {
    ...Fonts.style.captionBold,
    textTransform: 'uppercase',
    color: Colors.textSecondary,
    flexShrink: 1,
  },
  noParticipants: {
    display: 'flex',
    flexDirection: 'column',
    paddingHorizontal: Metrics.baseMargin * 1.9,
    paddingTop: Metrics.baseMargin * 2.8,
  },
  noParticipantsTitle: {
    ...Fonts.style.subheaderBold,
    textAlign: 'center',
    alignSelf: 'center',
    color: Colors.text,
    marginBottom: Metrics.baseMargin * 1.5,
  },
  noParticipantsCopy: {
    ...Fonts.style.body,
    textAlign: 'center',
    alignSelf: 'center',
    color: Colors.text,
    width: '80%',
  },
  joinCta: {
    width: '100%',
    marginTop: Metrics.baseMargin * 1.9,
    marginHorizontal: 0,
    marginBottom: Metrics.baseMargin * 4,
  },
  participants: {
    ...ApplicationStyles.shadow,
    backgroundColor: Colors.elementsBg,
  },
  seperator: {
    padding: Metrics.baseMargin * 0.6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.appBg,
  },
  addtlParticipants: {
    ...ApplicationStyles.shadow,
    backgroundColor: Colors.elementsBg,
  },
  participant: {
    ...baseParticipant,
    paddingVertical: Metrics.baseMargin * 1.25,
  },
  simpleParticipant: {
    ...baseParticipant,
    padding: Metrics.baseMargin * 1.25,
  },
  highlight: {
    backgroundColor: Colors.selected,
  },
  rank: {
    ...Fonts.style.h2,
    color: Colors.text,
    width: 54,
    textAlign: 'center',
  },
  icon: {
    width: 30,
    height: 30,
    borderRadius: 30 / 2,
    ...Fonts.style.subheaderBold,
    textAlign: 'center',
    lineHeight: 28,
    overflow: 'hidden',
    backgroundColor: Colors.accent,
    color: Colors.textInverted,
    textTransform: 'uppercase',
  },
  teamImage: {
    width: 30,
    height: 30,
    borderRadius: 30 / 2,
  },
  person: {
    marginHorizontal: Metrics.baseMargin,
    flexGrow: 1,
    flex: 1,
  },
  name: {
    ...Fonts.style.bodyLarge,
    fontWeight: '400',
    color: Colors.text,
    marginTop: Metrics.baseMargin * -0.3,
  },
  subtextWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  subtext: {
    ...Fonts.style.bodySmall,
    color: Colors.text,
  },
  progress: {
    marginRight: Metrics.baseMargin * 1.9,
    ...Fonts.style.bodyBold,
    color: Colors.text,
    textAlign: 'right',
  },
  highlightedCopy: {
    color: Colors.darkText,
  },
  errorIcon: {
    fontSize: 12,
    marginRight: 5,
  },
  errorSubtext: {
    ...Fonts.style.bodySmall,
    color: Colors.errorText,
  },
  loading: {
    padding: Metrics.baseMargin,
  },
  refresh: {
    padding: Metrics.baseMargin,
    display: 'flex',
    alignSelf: 'center',
  },
})
