import React from 'react'
import { View } from 'react-native'
import styled, { useTheme } from 'styled-components/native'
import { getServiceGroupIcon } from 'APP/Lib/CtaInfoHelpers'
import { ServiceGroupImage } from 'APP/Components/ServiceGroupImage'
import { getServiceOrServiceGroupTileIcon } from 'APP/Lib/ContentHelpers'

const ServiceTileContainer = styled(View)`
  margin-horizontal: ${({ theme }) => theme.metrics.baseMargin * 2}px;
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin * 1.5}px;
`

export const ServiceGroupScreenIcon = ({
  serviceGroup,
  hasExternalProvider,
}: {
  serviceGroup: Record<string, unknown> & { services: Record<string, unknown>[] }
  hasExternalProvider?: boolean
}) => {
  const theme = useTheme()
  const screenIconOverride = getServiceGroupIcon(serviceGroup?.screenIconOverride)
  const providerIcon = getServiceGroupIcon(serviceGroup?.services[0]?.providerIcon)

  if (serviceGroup?.screenIconOverride && screenIconOverride) {
    return (
      <ServiceGroupImage
        source={screenIconOverride}
        serviceGroup={serviceGroup}
        testID={'service-group-image-override-icon'}
      />
    )
  } else if (hasExternalProvider && providerIcon) {
    return (
      <ServiceGroupImage
        source={providerIcon}
        serviceGroup={serviceGroup}
        testID={'service-group-image-provider-icon'}
      />
    )
  } else {
    return (
      <ServiceTileContainer>
        {getServiceOrServiceGroupTileIcon(serviceGroup, {
          width: theme.metrics.icon.xlarge,
          height: theme.metrics.icon.xlarge,
          fillLine: theme.colors.serviceIconPrimary,
          accentFillLine: theme.colors.serviceIconAccent,
        })}
      </ServiceTileContainer>
    )
  }
}
