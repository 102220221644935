import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components/native'

import Analytics from 'APP/Services/Analytics'
import {
  ProviderChoice,
  ProviderList,
} from 'APP/Components/AppointmentBooking/ProviderBased/providerList'
import {
  Selection,
  ProviderProfileModal,
} from 'APP/Components/AppointmentBooking/ProviderBased/profile/ProviderProfileModal'

const Container = styled.View`
  padding: ${({ theme }) => `${theme.metrics.baseMargin}px ${theme.metrics.baseMargin * 2}px`};
`

interface Props {
  choices: ProviderChoice[]
  sendAnswer: (value: ProviderChoice) => void
}

const IntakeProviderList = ({ choices, sendAnswer }: Props) => {
  const [activeChoice, setActiveChoice] = useState<ProviderChoice | null>(null)

  const activeTimeslot: Selection | null = useMemo(() => {
    return activeChoice?.provider_id
      ? {
          choice: activeChoice?.display || '',
          providerId: parseInt(activeChoice.provider_id, 10),
        }
      : null
  }, [activeChoice])

  const handleSelect = useCallback((choice: ProviderChoice) => {
    setActiveChoice(choice)
    Analytics.trackEvent('button_click', { button_value: 'Provider choice selected' })
  }, [])

  const handleSelectMoreOptions = useCallback(
    (choice: ProviderChoice) => {
      Analytics.trackEvent('button_click', {
        button_value: 'Provider choice - more options selected',
      })
      sendAnswer(choice)
    },
    [sendAnswer]
  )

  const handleCloseModal = useCallback(() => {
    setActiveChoice(null)
  }, [])

  const handleConfirm = useCallback(() => {
    if (activeChoice) {
      Analytics.trackEvent('button_click', { button_value: 'Provider choice confirmed' })
      sendAnswer(activeChoice)
    }
  }, [activeChoice])

  return (
    <Container>
      <ProviderList
        data={choices}
        onPress={handleSelect}
        onPressMoreOptions={handleSelectMoreOptions}
      />
      <ProviderProfileModal
        selection={activeTimeslot}
        onConfirm={handleConfirm}
        onClose={handleCloseModal}
      />
    </Container>
  )
}

export default IntakeProviderList
