import { StyleSheet } from 'react-native'
import { ApplicationStyles, Metrics, Colors, Fonts } from '../../Themes'

const diaLogo = {
  ...Metrics.avatar,
  borderRadius: 0,
  minWidth: 40,
  minHeight: 40,
  resizeMode: 'contain',
}

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  practitionerContainer: {
    backgroundColor: Colors.elementsBg,
    flexDirection: 'row',
    padding: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin * 0.75,
    ...ApplicationStyles.shadow,
  },
  diaLogo,
  avatar: {
    ...diaLogo,
    borderRadius: 40,
  },
  practitionerTitleContainer: {
    flex: 1,
    paddingHorizontal: Metrics.baseMargin,
  },
  practitionerName: {
    ...Fonts.style.h4,
    color: Colors.text,
  },
  practitionerSpecialization: {
    ...Fonts.style.body,
    color: Colors.text,
  },
  carePlanTimestamp: {
    ...Fonts.style.caption,
    color: Colors.textSecondary,
  },
  cardContainer: {
    ...ApplicationStyles.shadow,
    backgroundColor: Colors.elementsBg,
    marginHorizontal: Metrics.baseMargin,
    marginVertical: Metrics.baseMargin * 0.75,
    padding: Metrics.baseMargin,
    borderRadius: 12,
    flexDirection: 'row',
  },
  cardContent: {
    flex: 1,
    paddingHorizontal: Metrics.baseMargin,
  },
  cardLogo: {
    width: 20,
    resizeMode: 'contain',
  },
  cardTitle: {
    ...Fonts.style.h4,
    color: Colors.text,
    paddingBottom: 8,
  },
  cardBody: {
    ...Fonts.style.body,
    color: Colors.text,
  },
  footer: {
    ...Fonts.style.subheader,
    color: Colors.text,
    textAlign: 'center',
    paddingVertical: Metrics.baseMargin * 0.75,
    paddingHorizontal: Metrics.baseMargin,
  },
  footerCta: {
    ...Fonts.style.subheaderBold,
    color: Colors.accent,
    textDecorationLine: 'underline',
  },
})
