import React from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import I18n from 'APP/Services/i18n'
// Services
import UrlBuilder from 'APP/Services/UrlBuilder'

// Actions
import { familyActions } from 'APP/Store/Family'

// Components
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import WebContentView from 'APP/Components/WebContentView'
import { PrimaryButton, SecondaryButton } from 'APP/Components/Buttons'
import Alert from 'APP/Converse/Alert'

// Styling
import Styles from './style'

class DependentConsentScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      consentLoaded: false,
    }
  }

  handleGiveConsentPress = () => {
    return Alert.alert(
      I18n.t('DependentConsentScreen.title'),
      I18n.t('DependentConsentScreen.body'),
      [
        { text: I18n.t('DependentConsentScreen.decline') },
        {
          text: I18n.t('DependentConsentScreen.agree'),
          onPress: this.handleCreateChildProfile,
        },
      ]
    )
  }

  handleCreateChildProfile = () => {
    if (!this.props.isConnected) return
    const { childFormData, createChildProfile } = this.props
    const childProfile = {
      dateOfBirth: childFormData.dateOfBirth,
      familyName: childFormData.familyName,
      givenName: childFormData.givenName,
      preferredName: childFormData.preferredName,
    }
    const childIdUri = childFormData.photoIdLocalPath
    createChildProfile(childProfile, childIdUri)
  }

  handleContentLoad = () => this.setState({ consentLoaded: true })

  handleConsentButtonPress = () => this.props.navigation.pop(3)

  render() {
    let {
      childFormData: { givenName, familyName },
      guardianGivenName,
      guardianFamilyName,
      isConnected,
      createChildProfileRequestRunning,
    } = this.props

    const consentUrl = UrlBuilder.guardianConsentUrl(
      guardianGivenName,
      guardianFamilyName,
      givenName,
      familyName
    )

    return (
      <NoticeBarContainer>
        <View style={Styles.responsiveWebView}>
          <WebContentView
            uri={consentUrl}
            onLoad={this.handleContentLoad}
            busy={createChildProfileRequestRunning}
            screen="consent"
          />
          {this.state.consentLoaded && isConnected && (
            <View style={Styles.buttonBar}>
              <SecondaryButton
                style={[Styles.button, Styles.noRightSpacing]}
                onPress={this.handleConsentButtonPress}
                testID="DEPENDENT_CONSENT_SCREEN_DECLINE_BUTTON"
                analyticsName="Dependent Consent decline"
              >
                {I18n.t('DependentConsentScreen.decline')}
              </SecondaryButton>
              <PrimaryButton
                style={Styles.button}
                onPress={this.handleGiveConsentPress}
                analyticsName="Dependent Consent agree"
                testID="DEPENDENT_CONSENT_SCREEN_AGREE_BUTTON"
              >
                {I18n.t('DependentConsentScreen.agree')}
              </PrimaryButton>
            </View>
          )}
        </View>
      </NoticeBarContainer>
    )
  }
}

const mapStateToProps = (state, props) => {
  const childFormData = getFormValues('updateDependentForm')(state)
  return {
    ...props.route?.params,
    childFormData,
    guardianGivenName: state.patient.profile.givenName,
    guardianFamilyName: state.patient.profile.familyName,
    createChildProfileRequestRunning: state.family.requestRunning,
    isConnected: state.appSession.isConnected,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createChildProfile: (childData, childIdUri) =>
      dispatch(familyActions.createChildProfileRequest({ childData, childIdUri })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DependentConsentScreen)
