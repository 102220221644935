import { StyleSheet } from 'react-native'
import { Metrics, Colors } from 'APP/Themes'

export const Styles = StyleSheet.create({
  dialogueBrandingContainer: {
    flexShrink: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: Metrics.baseMargin,
    gap: Metrics.baseMargin / 2,
  },
  dialogueBrandingLabel: {
    marginRight: 8,
    color: Colors.text,
    fontSize: 12,
  },
  dialogueBrandingLogo: {
    resizeMode: 'contain',
    width: 120,
    height: 60,
  },
})