import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Fonts, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  splashLogo: {
    resizeMode: 'contain',
    alignSelf: 'baseline',
    marginLeft: Metrics.baseMargin,
    marginTop: Metrics.baseMargin * 2,
    marginBottom: Metrics.baseMargin,
    height: 45,
  },
  title: {
    ...Fonts.style.h1,
    color: Colors.text,
    marginLeft: Metrics.baseMargin,
    paddingBottom: 10,
  },
  subtitle: {
    ...Fonts.style.body,
    color: Colors.text,
    lineHeight: 21,
    marginLeft: Metrics.baseMargin,
  },
  orgList: {
    marginVertical: Metrics.baseMargin,
  },
  orgNameCard: {
    ...ApplicationStyles.shadow,
    margin: Metrics.baseMargin,
    backgroundColor: Colors.elementsBg,
    padding: 20,
    borderRadius: 10,
  },
  orgName: {
    ...Fonts.style.h4,
    color: Colors.text,
  },
  contactUs: {
    ...Fonts.style.body,
    textAlign: 'center',
    color: Colors.text,
    marginTop: Metrics.baseMargin,
  },
  link: {
    fontFamily: Fonts.type.bold,
    fontWeight: 'bold',
    color: Colors.text,
    textDecorationLine: 'underline',
    textTransform: 'capitalize',
  },
})
