import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ProviderAppointmentBooking } from './ProviderBased'
import TimeAppointmentBooking from './TimeBased'
import { MattermostQuestion, SilkroadSelfSchedulingQuestion } from './utils/types'
import { PROVIDER_SELECTION_FEATURE_NAME } from './constants'
import { selectPatientProfile } from 'APP/Redux/PatientRedux'
import {
  selectAppointmentTypes,
  selectLoadingAppointmentTypes,
} from 'APP/Store/ProviderBooking/selectors'
import { providerBookingActions } from 'APP/Store/ProviderBooking'
import { LoadingIndicator } from './common/loadingIndicator'

export interface AppointmentBookingProps {
  date_until: string
  date_from: string
  provider_id: string
  appointment_type: string
  member_id: string
  appointment_title: string
  guardian_id?: string
  post?: SilkroadSelfSchedulingQuestion | MattermostQuestion
  is_rescheduling: boolean
  appointment_id?: string
  /* eslint-disable-next-line */
  sendAnswer: (value: any, originalPost: void) => void
  dia_provider_id?: string
  close: () => void
}

const AppointmentBooking = (props: AppointmentBookingProps) => {
  const dispatch = useDispatch()
  const profile = useSelector(selectPatientProfile)
  const isLoadingTypes = useSelector(selectLoadingAppointmentTypes)
  const appointmentTypes = useSelector(selectAppointmentTypes)

  const eligibleServices = profile?.eligibleServices

  const hasProviderSelection = !!eligibleServices?.[PROVIDER_SELECTION_FEATURE_NAME]
  const showProviderSelection =
    hasProviderSelection && appointmentTypes.includes(props.appointment_type)

  useEffect(() => {
    if (hasProviderSelection) {
      dispatch(providerBookingActions.getAppointmentTypes())
    }
  }, [hasProviderSelection])

  if (isLoadingTypes) {
    return <LoadingIndicator />
  }

  return showProviderSelection ? (
    <ProviderAppointmentBooking {...props} />
  ) : (
    <TimeAppointmentBooking {...props} />
  )
}

export default AppointmentBooking
