import React from 'react'
import { View, Text, Image, TouchableOpacity, Modal, ScrollView } from 'react-native'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import Styles, { modalTrackerMarkdownContent, modalTrackerMarkdownAlert } from './style'
import { PrimaryButton } from 'APP/Components/Buttons'
import { useTerminateModal } from 'APP/Hooks/TerminateModal'
import { Colors, Metrics } from 'APP/Themes'
import I18n from 'APP/Services/i18n'
import DialogueMarkdown from 'APP/Components/DialogueMarkdown'

const TrackerIntroModal = React.forwardRef(({ tracker, onConnect, onClose }, ref) => {
  const isVisible = tracker != null

  const shouldDisplayMarkdown = tracker
    ? I18n.exists(`HealthTrackerSettings.modal_legacy.${tracker.tracker_name}.markdown`)
    : false
  const shouldDisplayAlert = tracker
    ? I18n.exists(`HealthTrackerSettings.modal_legacy.${tracker.tracker_name}.alert`)
    : false

  const modalProps = useTerminateModal(ref, isVisible, onClose)

  return (
    <Modal
      statusBarTranslucent
      transparent
      animationType="slide"
      visible={isVisible}
      testID="trackerIntroModal"
      {...modalProps}
    >
      {tracker && (
        <View style={Styles.modalContainer}>
          <View style={Styles.modal}>
            <View style={Styles.modalHeader}>
              <View style={Styles.row}>
                <TouchableOpacity
                  onPress={onClose}
                  testID="closeHabitProgressModal"
                  style={Styles.modalHeaderClose}
                >
                  <MaterialIcon name="close" size={Metrics.icons.small} color={Colors.text} />
                </TouchableOpacity>
                <Text style={Styles.modalHeaderTitle}>
                  {I18n.t(`HealthTrackerSettings.modal_legacy.${tracker.tracker_name}.title`)}
                </Text>
              </View>
            </View>
            <ScrollView
              showsVerticalScrollIndicator={false}
              style={Styles.modalContent}
              testID="modalListContainer"
            >
              <View style={Styles.modalTitleContainer}>
                <Image
                  style={Styles.modalTitleImage}
                  source={{ uri: tracker.content.badge['300x300'] }}
                />
                <Text style={Styles.modalTitle}>
                  {I18n.t(`HealthTrackerSettings.trackers_legacy.${tracker.tracker_name}`)}
                </Text>
              </View>
              <Text style={Styles.modalSubtitle}>
                {I18n.t(`HealthTrackerSettings.modal_legacy.${tracker.tracker_name}.subtitle`)}
              </Text>
              {shouldDisplayAlert && (
                <View style={Styles.modalAlert}>
                  <MaterialIcon
                    name="info-outline"
                    style={Styles.modalAlertIcon}
                    size={24}
                    color={Colors.text}
                  />
                  <DialogueMarkdown
                    markdown={I18n.t(
                      `HealthTrackerSettings.modal_legacy.${tracker.tracker_name}.alert`
                    )}
                    styles={modalTrackerMarkdownAlert}
                  />
                </View>
              )}
              {shouldDisplayMarkdown && (
                <DialogueMarkdown
                  markdown={I18n.t(
                    `HealthTrackerSettings.modal_legacy.${tracker.tracker_name}.markdown`
                  )}
                  styles={modalTrackerMarkdownContent}
                />
              )}
              <PrimaryButton
                onPress={() => onConnect(tracker)}
                title={I18n.t(
                  `HealthTrackerSettings.modal_legacy.${tracker.tracker_name}.connectCta`
                )}
                testID="trackerConnectCta"
                style={Styles.modalTrackerCta}
              />
            </ScrollView>
          </View>
        </View>
      )}
    </Modal>
  )
})
TrackerIntroModal.displayName = 'TrackerIntroModal'

export default TrackerIntroModal
