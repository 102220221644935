import React from 'react'

import styled from 'styled-components/native'
import Typography from 'APP/Converse/Typography'
import { Avatar } from 'APP/Converse/Avatar'

import { EpisodeAssigneeProps } from './types'
import I18n from 'APP/Services/i18n'
import { Trans } from 'react-i18next'
const Container = styled.View`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.metrics.baseMargin / 2}px;
`

export const EpisodeAssignee = ({ assigneePicture, assigneeFirstName }: EpisodeAssigneeProps) => {
  return (
    <Container>
      <Avatar size="small" source={{ uri: assigneePicture }} />
      <Typography variant="bodySmall" color="dim">
        <Trans
          i18n={I18n}
          i18nKey="ConversationScreen.assignee.userAssigned"
          values={{ name: assigneeFirstName }}
        >
          <Typography variant="bodySmallBold" color="dim">
            {{ assigneeFirstName }}
          </Typography>
        </Trans>
      </Typography>
    </Container>
  )
}
