import apisauce from 'apisauce'
import Config from '../Config'
import { isMobile } from 'APP/Helpers/checkPlatform'
import { camelizeKeys } from 'humps'

const create = (loginStore, baseURL = Config.SCRIBE_DOMAIN) => {
  const authToken = loginStore.accessToken
  const api = apisauce.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    timeout: 20000,
  })

  // URL Builders
  const getScribeV2UserUrl = (userId) => `/v2/users/${userId}`
  const getScribeV2UserEligibilitiesUrl = (userId) => `/v2/users/${userId}/eligible_intervals`
  const buildUsersHealthResourcesURL = (userId) => `/v2/users/${userId}/health_resources`
  const enrolWithEnrolmentCodeUrl = (userId) => `/v2/users/${userId}/enrol_by_enrolment_code_v2`
  const resendInvitationUrl = `/v2/invitations/resend`
  const buildRegisterCardUrl = (userId) => `/v1/users/${userId}/stripe_registrations`
  const buildChargeCardUrl = (chargeId) => `/v1/charges/${chargeId}capture`
  const buildFamilyMembersUrl = (userId) => `/v1/users/${userId}/family_members`
  const buildFamilyMemberUrl = (familyId, memberId) =>
    `/v1/families/${familyId}/members/${memberId}`
  const buildChildUrl = (userId) => `/v1/users/${userId}/children`
  const buildOrganizationMemberSearchUrl = (firstName, lastName, dateOfBirth, brandId) =>
    `/v1/member-signup` +
    `?first_name=${encodeURIComponent(firstName)}` +
    `&last_name=${encodeURIComponent(lastName)}` +
    `&birthdate=${encodeURIComponent(dateOfBirth)}` +
    `&brand_id=${encodeURIComponent(brandId)}`
  const buildUnchallengedInvitationSearchUrl = (firstName, lastName, dateOfBirth, brandId) =>
    `/v2/invitations/unchallenged` +
    `?first_name=${encodeURIComponent(firstName)}` +
    `&last_name=${encodeURIComponent(lastName)}` +
    `&date_of_birth=${encodeURIComponent(dateOfBirth)}` +
    `&brand_id=${encodeURIComponent(brandId)}`
  const buildChallengedInvitationSearchUrl = (firstName, lastName, dateOfBirth, brandId) =>
    `/v2/invitations/challenged?${new URLSearchParams({
      first_name: firstName,
      last_name: lastName,
      date_of_birth: dateOfBirth,
      brand_id: brandId,
    }).toString()}`
  const buildChallengeInvitationUrl = (invitationId) => `/v2/invitations/${invitationId}/challenge`

  // Transforms
  const normalizeFamily = (rawFamily) => rawFamily.map((member) => normalizeFamilyMember(member))

  const normalizeFamilyId = (relationships) =>
    relationships.family && relationships.family.data && relationships.family.data.id

  const normalizeFamilyMember = (rawFamilyMember) => ({
    id: rawFamilyMember.id,
    email: rawFamilyMember.attributes.email,
    givenName: rawFamilyMember.attributes.first_name,
    preferredName: rawFamilyMember.attributes.preferred_name,
    pronouns: rawFamilyMember.attributes.pronouns,
    genderIdentity: rawFamilyMember.attributes.gender_identity,
    genderIdentityDescription: rawFamilyMember.attributes.gender_identity_description,
    familyName: rawFamilyMember.attributes.last_name,
    birthdate: rawFamilyMember.attributes.birthdate,
    picture: rawFamilyMember.attributes.picture,
    type: rawFamilyMember.attributes.type,
    authId: rawFamilyMember.attributes.auth_id,
    healthcardUrl:
      rawFamilyMember.links &&
      rawFamilyMember.links.healthcard &&
      rawFamilyMember.links.healthcard.href,
    permissions:
      rawFamilyMember.links &&
      rawFamilyMember.links.self &&
      rawFamilyMember.links.self.meta &&
      rawFamilyMember.links.self.meta.methods &&
      (rawFamilyMember.links.self.meta.methods.includes('delete') // usually for invited members
        ? 'delete'
        : rawFamilyMember.links.self.meta.methods.includes('put') // usually only for children
        ? 'put'
        : 'none'),
  })

  const normalizeFamilyPermissions = (links) => ({
    children:
      links &&
      links.children &&
      links.children.meta &&
      links.children.meta.methods &&
      links.children.meta.methods.includes('post')
        ? 'create'
        : 'read',
    dependents:
      links &&
      links.dependents &&
      links.dependents.meta &&
      links.dependents.meta.methods &&
      links.dependents.meta.methods.includes('post')
        ? 'create'
        : 'read',
  })

  const normalizeUserV2 = (rawUser) => {
    const contentTags = rawUser.content_tags?.map((ct) => ct?.name) || []
    const creditCard = rawUser.credit_card ? rawUser.credit_card : null
    const eligibleServices = rawUser.eligible_services?.[Config.BRAND_ID] || {}
    const eligibleBrands = Object.keys(rawUser.eligible_services || [])
    const healthResources = camelizeKeys(rawUser.health_resources)
    const hasFamilyAccess = Object.values(eligibleServices).some(
      (service) => service?.family_access !== 'none'
    )
    const cobrands = rawUser.cobrands || []
    return {
      healthResources,
      contentTags,
      creditCard,
      eligibleServices,
      eligibleBrands,
      hasFamilyAccess,
      cobrands,
    }
  }

  // Currently only used for org level forced MFA, but this could be used for other things in the future
  const normalizeUserV2Eligibilities = (rawEligibilities) => {
    const isMfaRequired = rawEligibilities.some(
      (interval) =>
        (interval?.status === 'active' ?? false) &&
        (interval?.eligibility_record?.organization?.forced_mfa ?? false)
    )
    // This should work only for mobile as for a desktop version we have checkPublicComputer action.
    const isForcedSessionInactivityRequired =
      isMobile() &&
      rawEligibilities.some(
        (interval) =>
          (interval?.status === 'active' ?? false) &&
          (interval?.eligibility_record?.organization?.forced_mobile_session_inactivity ?? false)
      )
    const eligibilityIntervals = rawEligibilities
      .filter(
        // We don't filter by brand at the request to get an accurate forced MFA value but we do want to
        // filter eligibility intervals that are used by the app
        (interval) => interval?.eligibility_record?.organization?.brand_id === Config.BRAND_ID
      )
      .map((interval) => {
        return {
          id: interval?.id,
          planId: interval?.plan_id,
          startDate: interval?.start_date,
          endDate: interval?.end_date,
          status: interval?.status,
          eligibilityRecord: normalizeEligibilityRecord(interval?.eligibility_record),
        }
      })
    return { isMfaRequired, isForcedSessionInactivityRequired, eligibilityIntervals }
  }

  const normalizeUsersHealthResources = (rawHealthResources) => camelizeKeys(rawHealthResources)

  const normalizeOrganization = (rawOrganization) => {
    return {
      id: rawOrganization.id,
      careTeamNotes: rawOrganization?.care_team_notes,
      displayName: {
        en: rawOrganization?.display_name?.en ?? rawOrganization?.name,
        fr: rawOrganization?.display_name?.fr ?? rawOrganization?.name,
      },
      forcedMfa: rawOrganization?.forced_mfa,
      forcedSessionInactivity: rawOrganization?.forced_mobile_session_inactivity,
      name: rawOrganization?.name,
    }
  }

  const normalizeEligibilityRecord = (rawEligibilityRecord) => {
    return {
      id: rawEligibilityRecord.id,
      organization: normalizeOrganization(rawEligibilityRecord?.organization),
      participantId: rawEligibilityRecord.participant_id,
    }
  }

  const normalizeChallengeForm = (rawChallengeForm) => {
    return {
      title: rawChallengeForm?.title,
      body: rawChallengeForm?.body,
      fieldsOperation: rawChallengeForm?.fields_operation,
      challengeAttribute: rawChallengeForm?.challenge_attribute,
      fields: rawChallengeForm?.fields?.map?.((field) => {
        return {
          hint: field?.hint,
          infoTip: field?.info_tip,
          invalidError: field?.invalid_error,
          label: field?.label,
          requiredError: field?.required_error,
          type: field?.type,
          operation: field?.operation,
        }
      }),
    }
  }

  const normalizeChallengedInvitation = (rawInvitation) => {
    return {
      id: rawInvitation?.id,
      challengeForm: normalizeChallengeForm(rawInvitation?.challenge_form),
      organization: normalizeOrganization(rawInvitation?.organization),
    }
  }

  // API
  const getScribeV2User = (userId, params) => {
    return api.get(getScribeV2UserUrl(userId), params).then((response) => {
      if (response.ok) {
        if (response.data) {
          return normalizeUserV2(response.data.data)
        } else {
          throw (
            response.data.errors[0] || {
              status: response.status,
              title: 'Empty response body',
              detail: 'Missing response body',
            }
          )
        }
      } else {
        throw (
          response.data.errors[0] || {
            status: response.status,
            title: 'Empty error body',
            detail: 'none',
          }
        )
      }
    })
  }

  const getScribeV2UserEligibilities = (userId) => {
    return api.get(getScribeV2UserEligibilitiesUrl(userId)).then((response) => {
      if (response.ok) {
        if (response.data) {
          return normalizeUserV2Eligibilities(response.data.data)
        } else {
          throw (
            response.data.errors[0] || {
              status: response.status,
              title: 'Empty response body',
              detail: 'Missing response body',
            }
          )
        }
      } else {
        throw (
          response.data.errors[0] || {
            status: response.status,
            title: 'Empty error body',
            detail: 'none',
          }
        )
      }
    })
  }

  const getUsersHealthResources = (userId, params) => {
    return api.get(buildUsersHealthResourcesURL(userId), params).then((response) => {
      if (response.ok) {
        if (response.data) {
          return normalizeUsersHealthResources(response.data.data)
        } else {
          throw (
            response.data?.errors[0] || {
              status: response.status,
              title: 'Empty response body',
              detail: 'Missing response body',
            }
          )
        }
      } else {
        throw (
          response.data.errors[0] || {
            status: response.status,
            title: 'Empty error body',
            detail: 'none',
          }
        )
      }
    })
  }

  const registerCreditCard = (userId, creditCardToken) => {
    return api
      .post(buildRegisterCardUrl(userId), {
        data: {
          type: 'stripe_registration',
          attributes: {
            token: creditCardToken,
          },
        },
      })
      .then((response) => {
        if (response.ok) {
          if (response.data.data && response.data.data.attributes) {
            return response.data.data.attributes
          } else {
            throw (
              response.data.errors[0] || {
                status: response.status,
                title: 'Incomplete response body',
                detail: 'Missing "attributes" key in response body',
              }
            )
          }
        } else {
          throw (
            response.data.errors[0] || {
              status: response.status,
              title: 'Empty error body',
              detail: 'none',
            }
          )
        }
      })
  }

  const chargeCreditCard = (chargeId) => {
    return api.post(buildChargeCardUrl(chargeId)).then((response) => {
      if (response.ok) {
        return response.data.data
      } else {
        throw (
          response.data.errors[0] || {
            status: response.status,
            title: 'Empty error body',
            detail: 'none',
          }
        )
      }
    })
  }

  const getFamilyMembers = (userId) => {
    return api.get(buildFamilyMembersUrl(userId)).then((response) => {
      if (response.ok) {
        if (response.data) {
          const data = response.data
          const familyId = normalizeFamilyId(data.relationships)
          const family = normalizeFamily(data.data)
          const permissions = normalizeFamilyPermissions(data.links)
          return {
            familyId,
            family,
            permissions,
          }
        }
      }
      throw (
        response.data.errors[0] || {
          status: response.status,
          title: 'Empty error body',
          detail: 'none',
        }
      )
    })
  }

  const addAdult = (userId, emailToInvite, brandId) => {
    return api
      .post(buildFamilyMembersUrl(userId), {
        data: {
          type: 'family_member',
          attributes: {
            brand_id: brandId,
            email: emailToInvite,
            type: 'guardian',
          },
        },
      })
      .then((response) => {
        if (response.ok && response.data && response.data.data) {
          const data = response.data.data
          return {
            member: normalizeFamilyMember(data),
            familyId: normalizeFamilyId(data.relationships),
          }
        }
        throw (
          response.data.errors[0] || {
            status: response.status,
            title: 'Empty error body',
            detail: 'none',
          }
        )
      })
  }

  const removeAdult = (familyId, memberId) => {
    return api.delete(buildFamilyMemberUrl(familyId, memberId)).then((response) => {
      if (response.ok) {
        if (response.data) return response.data.data
      }
      throw (
        response.data.errors[0] || {
          status: response.status,
          title: 'Empty error body',
          detail: 'none',
        }
      )
    })
  }

  const addChild = (userId, child) => {
    const { givenName, preferredName, pronouns, familyName, dateOfBirth } = child
    return api
      .post(buildChildUrl(userId), {
        data: {
          type: 'child',
          attributes: {
            create_health_card_url: false, // DIA-62447 - We don't want to create a health card for the child yet
            first_name: givenName,
            preferred_name: preferredName,
            pronouns: pronouns,
            last_name: familyName,
            birthdate: dateOfBirth,
          },
        },
      })
      .then((response) => {
        if (response.ok) {
          if (response.data) {
            if (response.data.data && response.data.data.attributes && response.data.data.links) {
              return {
                child: normalizeFamilyMember(response.data.data),
                url: response.data.data.links.healthcard.meta.post_info.url,
                fields: response.data.data.links.healthcard.meta.post_info.fields,
                familyId: normalizeFamilyId(response.data.data.relationships),
              }
            }
          }
        }
        throw (
          response.data.errors[0] || {
            status: response.status,
            title: 'Empty error body',
            detail: 'none',
          }
        )
      })
  }

  // Note: This is unfortunately not the same code path as updaing a user's id.
  // We should probably consolidate this code replication
  const uploadChildIdCard = (url, fields, fileObj) => {
    let path = fileObj?.fileUri || ''
    if (
      fileObj?.fileUri?.indexOf('content://') === -1 &&
      fileObj?.fileUri?.indexOf('file://') === -1
    ) {
      path = `file://${path}`
    }

    const photo = fileObj?.file
      ? fileObj.file
      : {
          uri: path,
          type: 'image/jpeg',
          name: 'photo.jpg',
        }

    let body = new FormData()

    Object.keys(fields).forEach((key) => {
      body.append(key, fields[key])
    })
    body.append('file', photo)

    const aws = apisauce.create({
      baseURL: url,
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'multipart/form-data',
      },
      timeout: 20000,
    })

    return aws.post(url, body).then((response) => {
      if (response.ok) return response
      throw (
        (response.data && response.data.errors && response.data.errors[0]) || {
          status: response.status,
          title: 'Empty error body',
          detail: 'none',
        }
      )
    })
  }

  const updateChild = (familyId, memberId, child) => {
    const {
      givenName,
      preferredName,
      pronouns,
      familyName,
      dateOfBirth,
      genderIdentity,
      genderIdentityDescription,
    } = child
    return api
      .patch(buildFamilyMemberUrl(familyId, memberId), {
        data: {
          type: 'child',
          attributes: {
            first_name: givenName,
            preferred_name: preferredName,
            pronouns: pronouns,
            last_name: familyName,
            birthdate: dateOfBirth,
            gender_identity: genderIdentity,
            gender_identity_description: genderIdentityDescription,
          },
        },
      })
      .then((response) => {
        if (response.ok) {
          if (response.data) {
            if (response.data.data && response.data.data.attributes && response.data.data.links) {
              return {
                child: normalizeFamilyMember(response.data.data),
                url: response.data.data.links.healthcard.meta.post_info.url,
                fields: response.data.data.links.healthcard.meta.post_info.fields,
              }
            }
          }
        }
        throw (
          response.data.errors[0] || {
            status: response.status,
            title: 'Empty error body',
            detail: 'none',
          }
        )
      })
  }

  const searchMembers = (firstName, lastName, dateOfBirth, brandId) => {
    return api
      .get(buildOrganizationMemberSearchUrl(firstName, lastName, dateOfBirth, brandId))
      .then((response) => {
        if (response.ok) return response
        throw (
          response.data.errors[0] || {
            status: response.status,
            title: 'Empty error body',
            detail: 'none',
          }
        )
      })
  }

  const enrolWithEnrolmentCode = (userId, code, brandId) => {
    return api
      .post(enrolWithEnrolmentCodeUrl(userId), {
        enrolment_code: code,
        brand_id: brandId,
      })
      .then((response) => {
        if (response.ok) {
          return {
            profile: normalizeUserV2(response?.data?.data?.user),
            organization: normalizeEligibilityRecord(response?.data?.data?.eligibility_record)
              ?.organization,
          }
        }

        let error
        if (response.data) {
          error = response.data.errors
            ? response.data.errors[0]
            : {
                status: response.status,
                title: 'Unable to enrol',
              }
        } else {
          error = { status: response.problem }
        }
        throw error
      })
  }

  const unchallengedInvitationSearch = (firstName, lastName, dateOfBirth, brandId) => {
    return api
      .get(buildUnchallengedInvitationSearchUrl(firstName, lastName, dateOfBirth, brandId))
      .then((response) => {
        if (response.ok) {
          return response?.data?.data
        }
        throw (
          response.data.errors || {
            status: response.status,
            title: 'Empty error body',
            detail: 'none',
          }
        )
      })
  }

  const challengedInvitationSearch = (firstName, lastName, dateOfBirth, brandId) => {
    return api
      .get(buildChallengedInvitationSearchUrl(firstName, lastName, dateOfBirth, brandId))
      .then((response) => {
        if (response.ok) {
          return response?.data?.data?.map?.((invite) => normalizeChallengedInvitation(invite))
        }

        throw (
          response.data?.errors || {
            status: response.status,
            title: 'Empty error body',
            detail: 'none',
          }
        )
      })
  }

  const challengeInvitation = (invitationId, attribute) => {
    return api.post(buildChallengeInvitationUrl(invitationId), attribute).then((response) => {
      if (response.ok) {
        return response?.data?.data
      }
      throw (
        response?.data?.detail ?? {
          status: response.status,
          title: 'Failed challenge',
        }
      )
    })
  }

  const resendInvitation = (email, brandId) => {
    return api
      .post(resendInvitationUrl, {
        email,
        brand_id: brandId,
      })
      .then((response) => {
        if (response.ok) {
          return response
        }
        const errors = response.data.errors
        const error = errors
          ? errors[0]
          : {
              status: response.status,
              title: 'Unable to resend invitation',
            }
        throw error
      })
  }

  return {
    getScribeV2User,
    getScribeV2UserEligibilities,
    getUsersHealthResources,
    registerCreditCard,
    chargeCreditCard,
    getFamilyMembers,
    addAdult,
    removeAdult,
    addChild,
    uploadChildIdCard,
    updateChild,
    searchMembers,
    enrolWithEnrolmentCode,
    unchallengedInvitationSearch,
    challengedInvitationSearch,
    resendInvitation,
    challengeInvitation,
  }
}

export default {
  create,
}
