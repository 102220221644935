import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ScrollView, View, Text, ActivityIndicator } from 'react-native'
import moment from 'moment'
import ActiveMinutesActions from 'APP/Redux/ActiveMinutesRedux'
import { hasMoreActiveHistory } from 'APP/Helpers/Wellness'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import { HistoricalWeeklySummary, WHOBanner, FAQModal } from 'APP/Components/ActiveMinutes'
import { PrimaryButton } from 'APP/Components/Buttons'
import { Colors, Metrics } from 'APP/Themes'
import I18n from 'APP/Services/i18n'
import Retry from 'APP/Images/retry.svg'
import Styles from './style'
import Analytics from 'APP/Services/Analytics'

const RecentActiveHistoryScreen = ({ navigation, route }) => {
  const analyticsName = route?.name
  const dispatch = useDispatch()

  const { loading, retry, data } = useSelector((state) => state.activeMinutes.history)

  const [isFAQModalOpen, setFAQModalOpen] = useState(false)
  const openFAQModal = () => setFAQModalOpen(true)
  const closeFAQModal = () => setFAQModalOpen(false)

  useEffect(() => {
    getHistory()
    Analytics.trackScreen('Mini history page')
  }, [])

  const from = moment().format(I18n.t('DateFormat'))
  const weeksBackCount = 12

  const getHistory = () => dispatch(ActiveMinutesActions.getHistory(from, weeksBackCount))

  const currentWeek = (data || [])[0]
  const pastWeeks = (data || [])?.slice(1, 4)
  const VIEW_ALL_BTN = hasMoreActiveHistory(data)

  return (
    <NoticeBarContainer>
      {loading && (
        <ActivityIndicator
          size="large"
          color={Colors.text}
          style={Styles.loading}
          testID="loading"
        />
      )}
      {retry && (
        <View style={Styles.retry} testID="retry">
          <Retry fillLine={Colors.text} width={Metrics.icons.large} height={Metrics.icons.large} />
          <Text style={Styles.retryTitle}>{I18n.t('RecentActiveHistoryScreen.retry.title')}</Text>
          <Text style={Styles.retryCopy}>{I18n.t('RecentActiveHistoryScreen.retry.copy')}</Text>
          <PrimaryButton
            style={Styles.retryCta}
            title={I18n.t('RecentActiveHistoryScreen.retry.cta')}
            onPress={getHistory}
          />
        </View>
      )}
      {!loading && !retry && (
        <ScrollView
          contentContainerStyle={Styles.scrollViewContentContainer}
          testID="history"
          scrollIndicatorInsets={{ right: 1 }}
        >
          <View style={Styles.container}>
            <Text style={Styles.title}>
              {I18n.t('RecentActiveHistoryScreen.subtitles.thisWeek')}
            </Text>
            {currentWeek && (
              <HistoricalWeeklySummary
                startDate={currentWeek?.start}
                endDate={currentWeek?.end}
                weeklyGoal={currentWeek?.weekly_goal}
                weeklyProgress={currentWeek?.weekly_progress?.minutes}
                activities={currentWeek?.activities}
              />
            )}
          </View>
          <View>
            <Text style={Styles.title}>{I18n.t('RecentActiveHistoryScreen.subtitles.past')}</Text>
            {pastWeeks?.length === 0 && (
              <View style={Styles.noPastData}>
                <Text style={Styles.noPastDataTitle}>
                  {I18n.t('RecentActiveHistoryScreen.emptyStates.noPastWeeks.title')}
                </Text>
                <Text style={Styles.noPastDataCopy}>
                  {I18n.t('RecentActiveHistoryScreen.emptyStates.noPastWeeks.copy')}
                </Text>
              </View>
            )}
            {pastWeeks.map((week) => (
              <HistoricalWeeklySummary
                key={week.dateRange}
                startDate={week.start}
                endDate={week.end}
                weeklyGoal={week.weekly_goal}
                weeklyProgress={week.weekly_progress.minutes}
                activities={week.activities}
              />
            ))}
          </View>
          {VIEW_ALL_BTN && (
            <PrimaryButton
              style={Styles.seeMoreButton}
              title={I18n.t('RecentActiveHistoryScreen.seeMoreButton')}
              analyticsName={`${analyticsName} View all`}
              onPress={() => navigation.navigate('seeMoreActiveHistory')}
            />
          )}
          <WHOBanner onCtaPress={openFAQModal} />
        </ScrollView>
      )}
      <FAQModal isVisible={isFAQModalOpen} onClose={closeFAQModal} />
    </NoticeBarContainer>
  )
}

export default RecentActiveHistoryScreen
