import { StyleSheet } from 'react-native'
import { Colors, Fonts, Metrics, ApplicationStyles } from 'APP/Themes'

const baseHabitCta = {
  ...ApplicationStyles.primaryButton,
  paddingHorizontal: Metrics.baseMargin,
  paddingVertical: Metrics.baseMargin,
  borderRadius: 30,
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  flex: 2,
}

export default StyleSheet.create({
  container: {
    flexGrow: 1,
    backgroundColor: Colors.appBg,
  },
  overlay: {
    justifyContent: 'center',
    alignContent: 'center',
    height: 157,
  },
  retryIcon: {
    alignSelf: 'center',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  habits: {
    flex: 1,
    paddingHorizontal: Metrics.baseMargin * 1.5,
  },
  habit: {
    backgroundColor: Colors.elementsBg,
    paddingVertical: Metrics.baseMargin * 1.25,
    marginBottom: Metrics.baseMargin * 1.25,
    borderRadius: 16,
  },
  lastHabit: {
    marginBottom: 0,
  },
  habitCompleted: {
    backgroundColor: Colors.contrastElementBg,
  },
  habitActions: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: Metrics.baseMargin * 1.25,
    paddingHorizontal: Metrics.baseMargin * 1.25,
  },
  habitTitle: {
    ...Fonts.style.h4,
    color: Colors.text,
    paddingHorizontal: Metrics.baseMargin * 1.25,
  },
  habitTitleDone: {
    ...Fonts.style.subheaderBold,
    color: Colors.textSecondary,
    textDecorationLine: 'line-through',
    paddingHorizontal: Metrics.baseMargin * 1.25,
  },
  habitSubtitle: {
    ...Fonts.style.body,
    color: Colors.text,
    paddingTop: Metrics.baseMargin * 0.25,
    paddingHorizontal: Metrics.baseMargin * 1.25,
  },
  habitSubtitleCompleted: {
    color: Colors.textSecondary,
  },
  habitCta: {
    ...baseHabitCta,
    backgroundColor: Colors.buttonPrimary,
  },
  habitCtaIcon: {
    marginRight: Metrics.baseMargin / 2,
    marginTop: -4,
  },
  habitCtaText: {
    ...Fonts.style.button,
    color: Colors.textInverted,
  },
  habitCtaDone: {
    ...baseHabitCta,
    backgroundColor: Colors.habitDoneAccent,
  },
  habitCtaDoneText: {
    ...Fonts.style.button,
    color: Colors.darkText,
  },
  superscript: {
    ...Fonts.style.button,
    color: Colors.darkText,
    fontSize: Fonts.size.caption,
    marginTop: -4,
  },
  spinner: {
    height: 16,
  },
  habitHelpers: {
    flex: 1,
    flexDirection: 'row',
    maxWidth: '40%',
    marginLeft: Metrics.baseMargin * 1.25,
    justifyContent: 'flex-end',
  },
  habitHelper: {
    paddingLeft: Metrics.baseMargin / 2,
  },
  progressFire: {
    position: 'absolute',
    bottom: '-10%',
    right: '-25%',
  },
  guidanceBar: {
    marginHorizontal: Metrics.baseMargin * 1.5,
    marginBottom: Metrics.baseMargin,
    padding: Metrics.baseMargin * 1.25,
    backgroundColor: Colors.tagBg,
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'row',
  },
  guidanceText: {
    ...Fonts.style.body,
    color: Colors.darkText,
    marginLeft: Metrics.baseMargin * 0.75,
    flex: 1,
  },
  cupboardCta: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: Metrics.baseMargin,
    paddingHorizontal: Metrics.baseMargin * 1.25,
  },
  cupboardCtaText: {
    ...Fonts.style.subheaderBold,
    color: Colors.text,
    textDecorationLine: 'underline',
  },
  loadingContent: {
    marginVertical: Metrics.baseMargin,
  },
  retryContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: Metrics.baseMargin * 1.25,
    paddingBottom: Metrics.baseMargin * 0.25,
  },
  retryContentText: {
    ...Fonts.style.body,
    color: Colors.text,
    width: '55%',
    textAlign: 'center',
    marginTop: Metrics.baseMargin * 0.25,
  },
})
