import React, { useState, useMemo, useEffect } from 'react'
import { TouchableOpacity, Text, View, Image, ScrollView } from 'react-native'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import { useNavigation } from '@react-navigation/native'

import I18n from 'APP/Services/i18n'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import BottomSheetModal from 'APP/Components/BottomSheetModal'
import { Colors, Metrics, Images } from 'APP/Themes'
import Styles from './style'
import NetworkWrapper from 'APP/Components/NetworkWrapper'
import { isMobile } from 'APP/Helpers/checkPlatform'

const HabitDrawer = ({ isVisible, onClose, title: titleProp, habitTopics, ...rest }) => {
  const [activeTopic, setActiveTopic] = useState()
  const navigation = useNavigation()

  const [title, items] = useMemo(() => {
    const nextTitle = activeTopic
      ? habitTopics?.find(({ identifier }) => identifier === activeTopic)?.title[I18n.baseLocale]
      : titleProp
    let nextItems = []
    if (!activeTopic) {
      nextItems = habitTopics?.map(({ identifier, title }) => ({
        identifier,
        title: title[I18n.baseLocale],
        onPress: () => setActiveTopic(identifier),
      }))
    } else {
      nextItems = habitTopics
        ?.find(({ identifier }) => identifier === activeTopic)
        ?.habits?.map(({ identifier, title }) => ({
          identifier,
          title: title[I18n.baseLocale],
          onPress: () => {
            onClose()
            setTimeout(() => navigation.navigate('habitDetailScreen', { id: identifier }), 500)
          },
        }))
    }
    return [nextTitle, nextItems]
  }, [titleProp, activeTopic, habitTopics])

  useEffect(() => {
    if (isVisible) {
      setActiveTopic()
    }
  }, [isVisible])

  return (
    <BottomSheetModal
      isVisible={isVisible}
      onClose={onClose}
      title={title}
      style={isMobile() && Styles.drawer}
      testID="habitRecommendationDrawer"
    >
      <View style={Styles.container}>
        <NetworkWrapper {...rest}>
          {!!activeTopic && (
            <Text style={Styles.subtitle}>{I18n.t('Habits.habitDrawer.subtitle')}</Text>
          )}
          <ScrollView>
            {items?.map((item) => (
              <TouchableOpacity
                key={item.identifier}
                style={Styles.itemContainer}
                onPress={item.onPress}
                testID="habitTopic"
              >
                <Text style={Styles.itemText}>{item.title}</Text>
                <MaterialIcon
                  accessibilityRole="button"
                  color={Colors.accent}
                  size={Metrics.icons.small}
                  name="chevron-right"
                />
              </TouchableOpacity>
            ))}
          </ScrollView>
          {!!activeTopic && (
            <DialogueTouchableOpacity
              onPress={() => setActiveTopic()}
              style={Styles.backButton}
              testID="backBtn"
              analyticsName="Back"
            >
              <Image style={Styles.backIcon} source={Images.backIcon} />
              <Text style={Styles.backText}>{I18n.t('AccessibilityIcons.back')}</Text>
            </DialogueTouchableOpacity>
          )}
        </NetworkWrapper>
      </View>
    </BottomSheetModal>
  )
}

export default HabitDrawer
