import { StyleSheet } from 'react-native'
import { Colors, ApplicationStyles, Metrics, Fonts } from '../../Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  container: {
    flex: 1,
    backgroundColor: Colors.appBg,
    borderTopColor: Colors.elementsBorder,
    borderTopWidth: 1,
    marginTop: Metrics.baseMargin * 0.5,
  },
  containerInner: {
    flex: 1,
    justifyContent: 'center',
  },
  network: {
    flex: 1,
    zIndex: 1,
    alignSelf: 'center',
    position: 'absolute',
  },
  categories: {
    zIndex: 1,
    backgroundColor: Colors.appBg,
    paddingBottom: Metrics.baseMargin,
  },
  topics: {
    paddingTop: Metrics.baseMargin * 0.75,
    paddingBottom: Metrics.bottomSpace,
  },
  topic: {
    marginTop: Metrics.baseMargin * 1.25,
    marginBottom: Metrics.baseMargin,
  },
  topicTitle: {
    ...Fonts.style.h2,
    color: Colors.text,
    paddingHorizontal: Metrics.baseMargin * 1.25,
    marginBottom: Metrics.baseMargin * 1.25,
  },
  habit: {
    ...ApplicationStyles.shadow,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: Colors.elementsBg,
    borderBottomWidth: 1,
    borderBottomColor: Colors.shadow,
    paddingVertical: Metrics.baseMargin,
    paddingLeft: Metrics.baseMargin * 1.25,
    paddingRight: Metrics.baseMargin,
  },
  habitText: {
    ...Fonts.style.subheaderBold,
    color: Colors.text,
    paddingRight: Metrics.baseMargin,
    flex: 1,
  },
  habitSubheader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginTop: 5,
  },
  habitStateText: {
    ...Fonts.style.bodySmall,
    color: Colors.text,
    marginLeft: 5,
  },
})
