import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { View, Text, InteractionManager } from 'react-native'
import { WellnessCenter } from '@dialogue/services'
import Animation from 'APP/Components/Animation'
import { useInViewPort } from 'APP/Hooks/ViewPort'
import I18n from 'APP/Services/i18n'

import { goalMetric } from 'APP/Components/Challenge_legacy/constants'
import Styles from './style'
import Analytics from 'APP/Services/Analytics'

const { Challenges } = WellnessCenter

const CONFETTIS = ['🔥', '💯', '👍', '😎', '💪', '⭐', '🎊', '🏅', '🏆', '🎉']
const CONFETTIS_NUMBER = 3

const RandomizeConfettis = ({ confettis = [], index = 0, numberOfElems = 10, children }) => {
  if (confettis.length === 0 || confettis.length <= index) {
    return children
  }

  return (
    <RandomizeConfettis confettis={confettis} index={index + 1} numberOfElems={numberOfElems}>
      <Animation text={confettis[index]} numberOfElems={numberOfElems}>
        {children}
      </Animation>
    </RandomizeConfettis>
  )
}

const ProgressTracker = () => {
  const { challenge, userChallengeGoal } = useSelector(
    (state) => state.wellnessCenter.selectedChallenge
  )

  const { progress, isChallengeGoalReached } = useMemo(() => {
    const progress = (userChallengeGoal / challenge.participant_goal) * 100
    const isChallengeGoalReached = progress >= 100
    return {
      progress,
      isChallengeGoalReached,
    }
  }, [challenge.id, challenge.participant_goal, userChallengeGoal])

  const cleanupGoal = (goal) => {
    const value = challenge.metric !== Challenges.Types.MetricName.STEPS ? goal / 1000 : goal

    let fixedMetric = +parseFloat(value).toFixed(2)
    const decimals = fixedMetric.toString().split('.')[1]

    if (decimals && decimals.length === 1) {
      fixedMetric = `${fixedMetric}0`
    }

    return fixedMetric.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  const randomizeElements = (src, take) => {
    let takeValue = take

    if (takeValue > src.length) {
      takeValue = src.length
    }

    const elements = src.slice(0)
    let i = elements.length
    const min = i - takeValue
    let temp
    let index
    while (i-- > min) {
      index = Math.floor((i + 1) * Math.random())
      temp = elements[index]
      elements[index] = elements[i]
      elements[i] = temp
    }
    return elements.slice(min)
  }

  const {
    ref: challengeGoalReachedRef,
    isInViewPort,
    unwatch: unwatchChallengeGoalReachedRef,
  } = useInViewPort()
  const [confettis, setConfettis] = useState([])
  useEffect(() => {
    const interaction = InteractionManager.runAfterInteractions(() => {
      if (isInViewPort && isChallengeGoalReached) {
        setConfettis(randomizeElements(CONFETTIS, CONFETTIS_NUMBER))
        Analytics.trackScreen('Confetti cannon')
        unwatchChallengeGoalReachedRef()
      }
    })
    return () => {
      interaction.cancel()
    }
  }, [isChallengeGoalReached, isInViewPort])

  return (
    <View style={Styles.wrapper} testID="progressTracker">
      {isChallengeGoalReached && (
        <Text style={Styles.challengeGoalCompleted}>
          {I18n.t('WellnessCenter.challenges.challengeGoalCompleted')}
        </Text>
      )}
      <RandomizeConfettis confettis={confettis}>
        <View ref={challengeGoalReachedRef} style={Styles.header} collapsable={false}>
          <Text style={Styles.headerCopy}>{I18n.t('WellnessCenter.challenges.you')}</Text>
          <Text style={Styles.headerCopy}>{I18n.t('WellnessCenter.challenges.challengeGoal')}</Text>
        </View>
      </RandomizeConfettis>
      <View style={Styles.progressBar}>
        <View style={Styles.progressBarComplete} width={`${progress}%`}></View>
      </View>
      <View style={Styles.footer}>
        <Text style={Styles.footerCopy} testID="userProgress">
          {cleanupGoal(userChallengeGoal)} {goalMetric(null, challenge.metric)}
        </Text>
        <Text style={Styles.footerCopy} testID="goal">
          {cleanupGoal(challenge.participant_goal)}
        </Text>
      </View>
    </View>
  )
}

export default ProgressTracker
