import React from 'react'
import { KeyboardAvoidingView, Pressable, View } from 'react-native'
import styled from 'styled-components/native'
import { isIOS } from 'APP/Helpers/checkPlatform'

const OuterContainer = styled(View)`
  flex: 1;
`

const DismissOverlay = styled(Pressable)`
  flex: 1;
`

const InnerContainer = styled(View)`
  padding: ${({ theme }) => theme.metrics.baseMargin * 2}px;
  background-color: ${({ theme: { colors } }) => colors.elementsBg};
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
`

interface BottomSheetModalProps {
  children: React.ReactNode
  navigation: any
}

const BottomSheetModal = ({ children, navigation }: BottomSheetModalProps) => {
  const onDismiss = () => navigation.goBack()

  return (
    <KeyboardAvoidingView style={{ flex: 1 }} behavior={isIOS() ? 'padding' : null}>
      <OuterContainer>
        {/* DismissOverlay covers the rest of the screen to allow for dismiss on click outside the modal behaviour */}
        <DismissOverlay onPress={onDismiss} testID="dismiss-overlay" />
        <InnerContainer>{children}</InnerContainer>
      </OuterContainer>
    </KeyboardAvoidingView>
  )
}

export const withBottomSheetModal = (Component: any) => (props: any) =>
  (
    <BottomSheetModal {...props}>
      <Component {...props} />
    </BottomSheetModal>
  )

export default BottomSheetModal
