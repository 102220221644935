import React from 'react'
import { Linking, Text } from 'react-native'
import { connect } from 'react-redux'

import BulletListItem from 'APP/Components/BulletListItem'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import { PrimaryButton } from 'APP/Components/Buttons'
import { ResponsiveScrollView } from 'APP/Converse/Layout'

import Config from 'APP/Config'
import I18n from 'APP/Services/i18n'
import UrlBuilder from 'APP/Services/UrlBuilder'
import { getBrandConfig } from 'APP/Lib/ConfigHelpers'

import Styles from './style'

function CloseAccount({ loginEmail }) {
  const { productName } = getBrandConfig(Config.BRAND_ID, I18n.locale)
  const supportEmail = Config.SUPPORT_EMAIL[I18n.locale]

  const openURL = (url) => {
    Linking.openURL(url).catch((reason) => {
      console.log('openUrl error', url, reason)
    })
  }

  const goToPrivacyPolicy = () => {
    openURL(UrlBuilder.privacyUrl())
  }

  const goToTermsOfUse = () => {
    openURL(UrlBuilder.termsUrl())
  }

  const goToSupportEmail = () => {
    openURL(`mailto:${supportEmail}`)
  }

  const goToPrivacyEmail = () => {
    openURL(UrlBuilder.privacyEmailUrl())
  }

  const renderTexts = () => {
    const privacyEmail = Config.PRIVACY_EMAIL[I18n.baseLocale]

    const permanent = I18n.t('CloseAccountScreen.permanent', { returnObjects: true })
    const note = I18n.t('CloseAccountScreen.note', { returnObjects: true })
    const privacy = I18n.t('CloseAccountScreen.privacy', { returnObjects: true })
    const support = I18n.t('CloseAccountScreen.support', { returnObjects: true })

    const renderBulletListItem = (item) => {
      return (
        <BulletListItem bulletStyle={Styles.bulletStyle}>
          <Text style={Styles.text}>{item[0]}</Text>
          <Text style={Styles.bold}> {item[1]} </Text>
          <Text style={Styles.text}>{item[2]}</Text>
        </BulletListItem>
      )
    }

    return (
      <>
        <Text style={Styles.title}>{I18n.t('CloseAccountScreen.title')}</Text>
        <Text style={Styles.text}>
          {permanent[0]}
          <Text style={Styles.bold}> {permanent[1]} </Text>
          {permanent[2]}
        </Text>

        <Text style={Styles.bold}>{note[0]}</Text>
        {note.slice(1).map((value) => renderBulletListItem(value))}

        <Text style={Styles.text}>
          {privacy[0]}{' '}
          <Text style={Styles.link} onPress={goToPrivacyPolicy} testID="PRIVACY_POLICY_LINK">
            {privacy[1]}
          </Text>{' '}
          {privacy[2]}{' '}
          <Text style={Styles.link} onPress={goToTermsOfUse} testID="TERMS_OF_USE_LINK">
            {privacy[3]}
          </Text>{' '}
          {privacy[4]}
          <Text style={Styles.link} onPress={goToPrivacyEmail} testID="PRIVACY_EMAIL_LINK">
            {privacyEmail}
          </Text>
          .
        </Text>

        <Text style={Styles.text}>
          {support[0]}
          <Text style={Styles.link} onPress={goToSupportEmail} testID="SUPPORT_EMAIL_LINK">
            {supportEmail}
          </Text>
          {support[1]}
        </Text>
      </>
    )
  }

  const handleOnPressCta = () => {
    const subject = I18n.t('CloseAccountScreen.cta.subject', { productName })
    const body = I18n.t('CloseAccountScreen.cta.body', { productName, loginEmail })
    const mailto = `mailto:${supportEmail}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`
    openURL(mailto)
  }

  return (
    <NoticeBarContainer>
      <ResponsiveScrollView alwaysBounceVertical={false} style={Styles.scrollView}>
        {renderTexts()}
        <PrimaryButton
          title={I18n.t('CloseAccountScreen.cta.button')}
          onPress={handleOnPressCta}
          analyticsName="CloseAccountScreen.button"
        />
      </ResponsiveScrollView>
    </NoticeBarContainer>
  )
}

const mapStateToProps = (state, props) => {
  return {
    ...props.route?.params,
    loginEmail: state.patient?.profile?.email,
  }
}

export default connect(mapStateToProps, null)(CloseAccount)
