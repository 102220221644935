import React from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'

import Button from 'APP/Converse/Button'
import Typography from 'APP/Converse/Typography'
import I18n from 'APP/Services/i18n'
import { Colors, Metrics } from 'APP/Themes'

const Root = styled.View`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  align-items: center;
  padding-bottom: ${Metrics.bottomSpace};
  background-color: ${Colors.elementsBg};
`

const TopContainer = styled.View`
  background-color: ${Colors.errorSnackBarBg};
  ${({ theme }) => theme.shadows.normal};
`

const WarningMsg = styled(Typography)`
  padding: 12px;
  // you would think this isn't required, but the Typography component ignores the color prop
  color: ${Colors.darkText} !important;
`

interface ExpiredScheduleProps {
  quitScheduling: () => void
}

export const ExpiredSchedule: React.FC<ExpiredScheduleProps> = ({ quitScheduling }) => {
  return (
    <Root>
      <TopContainer>
        <WarningMsg variant="bodyNormalBold">
          {I18n.t('AppointmentBooking.expired_error')}
        </WarningMsg>
      </TopContainer>
      <View>
        <Button
          onPress={quitScheduling}
          variant="tertiary"
          title={I18n.t('AppointmentBooking.close') || undefined}
          widthVariant="full"
          analyticsName='appointmentBooking.expired.closeButton'
        />
      </View>
    </Root>
  )
}

export default ExpiredSchedule
