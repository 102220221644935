import React from 'react'
import moment from 'moment'
import styled from 'styled-components/native'
import { useSelector } from 'react-redux'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'

import { AvailabilitySlotProviderMatchingAttributes } from '@dialogue/timekeeper'

import { Colors, Images, Metrics } from 'APP/Themes'
import Typography from 'APP/Converse/Typography'
import {
  selectLoadingProviders,
  selectProvider,
  selectProvidersAttributesByKeys,
} from 'APP/Store/ProviderBooking/selectors'
import I18n from 'APP/Services/i18n'

import { LoadingIndicator } from 'APP/Components/AppointmentBooking/common/loadingIndicator'

const StyledTouchableHighlight = styled.TouchableHighlight`
  background-color: ${({ theme }) => theme.colors.elementsBg};
  border: 1px solid ${({ theme }) => theme.colors.elementsBorder};
  border-radius: 12px;
  padding: ${({ theme }) => theme.metrics.baseMargin}px;

  ${({ theme }) => theme.shadows.soft};
`

const Wrapper = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const InfoWrapper = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const FlexContainer = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ScheduleIcon = styled(MaterialIcon)`
  margin-right: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  color: ${({ theme }) => theme.colors.accent};
`

const ProviderInfo = styled(FlexContainer)`
  margin-top: ${({ theme }) => theme.metrics.baseMargin / 2}px;
`

const ProviderImage = styled.Image`
  height: 32px;
  width: 32px;
  margin-right: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  border-radius: 16px;
`

const AttributesWrapper = styled.View`
  margin-top: ${({ theme }) => theme.metrics.baseMargin / 2}px;
`

// @ts-expect-error Typography is not typed
const AttributeText = (props) => <Typography variant="bodySmall" color="dim" {...props} />

const NonMatchedAttributeText = styled(AttributeText)`
  text-decoration-line: line-through;
`

const AttributeCaption = styled(Typography)`
  font-weight: 500;
`

const formatTimeslot = (startAt?: string, endAt?: string) => {
  const calendarFormat = I18n.t('AppointmentBooking.providers_timeslot_formats', {
    returnObjects: true,
  })
  return `${moment(startAt).calendar(calendarFormat)} - ${moment(endAt).format('hh:mm A')}`
}

type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>
  }[Keys]

interface BaseProps {
  providerId: number
  startAt?: string
  endAt?: string
  title?: string
  attributes?: AvailabilitySlotProviderMatchingAttributes
  onPress?: () => void
}

type Props = RequireAtLeastOne<BaseProps, 'title' | 'startAt'>

interface MoreOptionsButtonProps {
  display?: string
  onPress: () => void
}

export const MoreOptionsButton = ({
  onPress,
  display,
  ...rest
}: MoreOptionsButtonProps) => (
  <StyledTouchableHighlight onPress={onPress} underlayColor={Colors.multiSelectMsgBg} {...rest}>
    <Wrapper>
      <Typography variant="subheader">
        {display}
      </Typography>
    </Wrapper>
  </StyledTouchableHighlight>
)

export const ProviderButton = ({
  providerId,
  title,
  startAt,
  endAt,
  attributes,
  onPress,
  ...rest
}: Props) => {
  // @ts-expect-error store isn't typed
  const provider = useSelector((state) => selectProvider(state, providerId))
  const loading: boolean = useSelector(selectLoadingProviders)
  const timeslot = title || formatTimeslot(startAt, endAt)

  const locale = I18n.locale === 'fr' ? 'fr' : 'en'

  const attributesMapper = useSelector((state) => selectProvidersAttributesByKeys(state, locale))

  return (
    <StyledTouchableHighlight onPress={onPress} underlayColor={Colors.multiSelectMsgBg} {...rest}>
      <Wrapper>
        <InfoWrapper>
          <FlexContainer>
            <ScheduleIcon size={Metrics.icons.small} name="schedule" />
            <Typography variant="subheader">{timeslot}</Typography>
          </FlexContainer>
          <ProviderInfo>
            <ProviderImage
              source={provider?.picture ? { uri: provider?.picture } : Images.logoNurse}
            />
            <Typography variant="subheader">
              {loading ? (
                <LoadingIndicator size="small" />
              ) : (
                `${provider?.givenName} ${provider?.familyName}`
              )}
            </Typography>
          </ProviderInfo>
          {attributes && (
            <AttributesWrapper>
              <FlexContainer>
                <AttributeCaption variant="bodySmallBold">
                  {I18n.t('AppointmentBooking.applicable')}{' '}
                </AttributeCaption>
                <AttributeText>
                  {attributes?.matched_attributes
                    ?.map((attribute: string) => attributesMapper[attribute] || attribute)
                    .join(', ')}
                </AttributeText>
              </FlexContainer>
              <FlexContainer>
                <AttributeCaption variant="bodySmallBold">
                  {I18n.t('AppointmentBooking.notApplicable')}{' '}
                </AttributeCaption>
                {attributes?.non_matched_attributes?.map((attribute: string, index: number) => (
                  <>
                    <NonMatchedAttributeText key={attribute}>
                      {attributesMapper[attribute] || attribute}
                    </NonMatchedAttributeText>
                    <AttributeText>
                      {index < attributes.non_matched_attributes.length - 1 ? ', ' : ''}
                    </AttributeText>
                  </>
                ))}
              </FlexContainer>
            </AttributesWrapper>
          )}
        </InfoWrapper>
        <MaterialIcon
            accessibilityRole="button"
            color={Colors.accent}
            size={Metrics.icons.medium}
            name="chevron-right"
          />
      </Wrapper>
    </StyledTouchableHighlight>
  )
}
