import ColorLibrary from './ColorLibrary'
import ColorsBase from './ColorsBase'
import ColorsDark from './ColorsDark'
import { getBrandVersionedColors } from 'APP/Helpers/brandVersionedUtils'
import Config from 'APP/Config'

function getColorValues(data) {
  Object.entries(data).forEach(([key, value]) => {
    data[key] = ColorLibrary[value]
  })

  return data
}

const base = getColorValues(ColorsBase)
const dark = getColorValues(ColorsDark)

export const Colors = getBrandVersionedColors(base, dark, ColorLibrary, Config.BRAND_VERSION)

export default Colors
