import React from 'react'
import styled from 'styled-components/native'
import Typography from 'APP/Converse/Typography'
import { MessageContainer } from 'APP/Converse/Chat/MessageContainer'
import { TypingIndicatorProps } from './types'
import TypingAnimation from './TypingAnimation'

const Avatar = styled.Image`
  width: 40px;
  height: 40px;
  border-radius: ${({ theme }) => theme.metrics.borderRadius.medium}px;
`

const TypingContainer = styled.View`
  padding-top: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  gap: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  flex-direction: row;
  align-items: flex-end;
`

const ContentContainer = styled.View`
  flex-direction: column;
  gap: ${({ theme }) => theme.metrics.baseMargin / 2}px;
`

export const TypingIndicator = ({ avatarSource, assigneName }: TypingIndicatorProps) => {
  return (
    <TypingContainer>
      <Avatar
        source={{
          uri: avatarSource,
        }}
      />
      <ContentContainer>
        <Typography variant="caption" color="dim">
          {assigneName}
        </Typography>
        <MessageContainer>
          <TypingAnimation />
        </MessageContainer>
      </ContentContainer>
    </TypingContainer>
  )
}
