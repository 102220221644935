import React, { PureComponent } from 'react'
// eslint-disable-next-line no-restricted-imports
import { Linking, Text } from 'react-native'
import styled from 'styled-components/native'

import I18n from 'APP/Services/i18n'
import Typography from 'APP/Converse/Typography'
import { Colors } from 'APP/Themes'
import Config from 'APP/Config'
import UrlBuilder from 'APP/Services/UrlBuilder'

const LinkText = styled(Text)`
  color: ${Colors.link};
  font-weight: bold;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-color: ${Colors.link};
`

const MATCH_CONFIG = {
  URL: {
    // Matches http and https links.
    regex: /\b(?:(?:https?|www):\/\/|www\.)[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|](?!@)/i,
    linkPrefix: '',
  },
  EMAIL: {
    // Matches email addresses.
    regex: /\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b/i,
    linkPrefix: 'mailto:',
  },
  INTERNATIONAL_PHONE: {
    // Matches international phone numbers with optional '+' at the beginning,
    // followed by 1-3 digits (country code), optional space, optional area code in parentheses (1-4 digits)
    // and two groups of digits (1-4 and 1-9 digits respectively) separated by a space, dot, or dash.
    regex: /(\+?\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}\b/,
    linkPrefix: 'tel:',
  },
}

// Combine all the regular expressions into one.
const combinedRegex = new RegExp(
  Object.values(MATCH_CONFIG)
    .map((config) => config.regex.source)
    .join('|'),
  'gi'
)

class DialogueAutolink extends PureComponent {
  handleLinkPress = (url, type) => {
    if (url?.toLowerCase()?.includes(Config.SUPPORT_EMAIL[I18n.baseLocale]?.toLowerCase())) {
      // Open the email app for the support email address.
      Linking.openURL(UrlBuilder.supportEmailUrl())
      return
    }
    const linkPrefix = MATCH_CONFIG[type].linkPrefix
    Linking.openURL(linkPrefix + url)
  }

  render() {
    const { text: textProp, ...other } = this.props
    const text = String(textProp)
    const matches = text.match(combinedRegex) || []
    let lastIndex = 0
    const parts = []
    matches.forEach((match) => {
      const index = text.indexOf(match, lastIndex)
      if (index > lastIndex) {
        parts.push({ part: text.slice(lastIndex, index), type: null })
      }
      let type = null
      for (const [key, config] of Object.entries(MATCH_CONFIG)) {
        if (config.regex.test(match)) {
          type = key
          break
        }
      }
      parts.push({ part: match, type })
      lastIndex = index + match.length
    })

    if (lastIndex < text.length) {
      parts.push({ part: text.slice(lastIndex), type: null })
    }

    return (
      <Typography {...other}>
        {parts.map((part, index) => {
          if (part.type) {
            return (
              <LinkText key={index} onPress={() => this.handleLinkPress(part.part, part.type)}>
                {part.part}
              </LinkText>
            )
          } else {
            return <Text key={index}>{part.part}</Text>
          }
        })}
      </Typography>
    )
  }
}

export default DialogueAutolink
