import { all, call, put, select, take, spawn } from 'redux-saga/effects'
import moment from 'moment'
import * as Terra from 'terra-react'
import * as Sentry from '@sentry/react-native'

import LoggerActions from '../Redux/LoggerRedux'
import { ActiveMinutes, WellnessCenter } from '@dialogue/services'
import Config from 'APP/Config'
import { createActiveMinutes, selectTerraReferenceId } from './ActiveMinutesSagas'
import { Connections, getUserId as getTerraUserId } from 'terra-react'
import ActiveMinutesActions, { ActiveMinutesTypes } from 'APP/Redux/ActiveMinutesRedux'
import StartupActions from 'APP/Redux/StartupRedux'
import { logDdError } from 'APP/Lib/Datadog'

export const connectedTrackersState = (state) => state.activeMinutes.connectedTrackers

export const AppleHealtKitPermissions = [
  Terra.CustomPermissions.ACTIVITY_SUMMARY,
  Terra.CustomPermissions.ACTIVE_DURATIONS,
  Terra.CustomPermissions.EXERCISE_DISTANCE,
  Terra.CustomPermissions.STEPS,
  Terra.CustomPermissions.WORKOUT_TYPES,
]

export const SupportedTerraTrackers = {
  [ActiveMinutes.Types.TrackerName.AppleHealth]: {
    connection: Terra.Connections.APPLE_HEALTH,
    permissions: AppleHealtKitPermissions,
  },
  // TODO: remove this entry needed to support TT Lecacy flow once the rework is completed
  // TT Tracker names !== Terra Provider names
  [WellnessCenter.Trackers.Types.TrackerName.AppleHealthkit]: {
    connection: Terra.Connections.APPLE_HEALTH,
    permissions: AppleHealtKitPermissions,
  },
}

export function* initTerra() {
  try {
    const terraReferenceId = yield select(selectTerraReferenceId)
    if (!terraReferenceId) {
      throw new Error('Login not completed. Terra reference id is not set.')
    }

    const terraResponseMessage = yield call(Terra.initTerra, Config.TERRA_DEVID, terraReferenceId)

    if (!terraResponseMessage?.success) {
      throw new Error(`SDK call completed with error: ${terraResponseMessage?.error}`)
    }
    yield spawn(checkAppleHealthDisconnectedStatus)
  } catch (error) {
    yield put(
      LoggerActions.log(
        `initTerra saga failed. error name: ${error?.name}; error message: ${error?.message}`
      )
    )
    Sentry.captureException(error)
    logDdError(error.message, error.stack)
  }
}

// terra connection is happening as a detached process alongside a TT connection
// none of the terra erros would be communicated to the user nor they would affect the TT connection
export function* initTerraConnection({ trackerName, passThroughErrors = false }) {
  try {
    const TerraTrackerConfig = SupportedTerraTrackers[trackerName]
    if (!TerraTrackerConfig || !Config.TERRA_DEVID) return

    const activeMinutes = yield call(createActiveMinutes)
    const result = yield call(activeMinutes.authenticateTerra)
    const isAppleHealthClientSideBackfill = yield select(
      (state) => state.features.appleHealthClientSideBackfill
    )

    if (!result?.data?.token) {
      const msg = `Terra token creation failed. Status: ${result?.data?.status}`
      yield put(LoggerActions.log(msg))
      Sentry.captureMessage(msg)
      return
    }

    const message = yield call(
      Terra.initConnection,
      TerraTrackerConfig.connection,
      result?.data?.token,
      true,
      TerraTrackerConfig.permissions
    )

    if (message?.success) {
      if (isAppleHealthClientSideBackfill) {
        yield call(backfillTerraData, TerraTrackerConfig.connection)
      }
    } else {
      throw new Error(`Terra.initConnection() failed: ${message?.error}`)
    }
  } catch (error) {
    yield put(
      LoggerActions.log(
        `initTerraConnection saga failed. error name: ${error?.name}; error message: ${error?.message}`
      )
    )
    const extra = { extra: { trackerName, info: 'terra connection failed' } }
    Sentry.captureException(error, extra)
    logDdError(error.message, error.stack, 'CUSTOM', extra)

    if (passThroughErrors) {
      throw error
    }
  }
}

export function* checkAppleHealthDisconnectedStatus() {
  try {
    const { userId } = yield call(getTerraUserId, Connections.APPLE_HEALTH)
    // if terra cannot find the user, we need to reconnect them if they had previously established a connection
    if (!userId) {
      // cross reference with active-minutes API
      yield put(ActiveMinutesActions.getConnectedTrackers())
      yield take([
        ActiveMinutesTypes.GET_CONNECTED_TRACKERS_SUCCESS,
        ActiveMinutesTypes.GET_CONNECTED_TRACKERS_FAILURE,
      ])

      const { data } = yield select(connectedTrackersState)

      const appleTracker = data?.find(
        (tracker) => tracker['provider'] === ActiveMinutes.Types.TrackerName.AppleHealth
      )

      if (appleTracker) {
        // display reconnection prompt banner to user
        yield put(StartupActions.setAppleWatchDisconnectedBannerVisible(true))
      } else {
        yield put(StartupActions.setAppleWatchDisconnectedBannerVisible(false))
      }
    } else {
      yield put(StartupActions.setAppleWatchDisconnectedBannerVisible(false))
    }
  } catch (error) {
    Sentry.captureException(error)
    logDdError(error.message, error.stack)
  }
}

export function* backfillTerraData(connection) {
  const startDate = moment.utc().subtract(30, 'days').toDate()
  const endDate = moment.utc().toDate()

  try {
    yield all([
      call(Terra.getDaily, connection, startDate, endDate),
      call(Terra.getActivity, connection, startDate, endDate),
    ])
  } catch (error) {
    yield put(
      LoggerActions.log(
        `backfillTerraData saga failed. error name: ${error?.name}; error message: ${error?.message}`
      )
    )
    const extra = { extra: { connection, info: 'terra backfill failed' } }
    Sentry.captureException(error, extra)
    logDdError(error.message, error.stack, 'CUSTOM', extra)
  }
}
