import moment from 'moment'
import I18n from 'APP/Services/i18n'

// Date input is a string of format 'YYYY-MM-DD'
// Returns a Date object with a removed timezone offset from locale timezone
export const convertStringToDate = (date) => {
  const yearMonthDayArray = date.split('-')
  const year = parseInt(yearMonthDayArray[0])
  const month = parseInt(yearMonthDayArray[1]) - 1
  const day = parseInt(yearMonthDayArray[2])
  return new Date(year, month, day)
}

export const formatDateToISOString = (value) => {
  if (!isDateValid(value)) {
    throw Error('value is an invalid date')
  }
  return moment(value).toISOString()
}

export const transformDateLimit = (value, limitDate) => {
  if (!isDateValid(value) || !isDateValid(limitDate)) {
    throw Error('inputs must be valid dates')
  }
  return moment(value).isSameOrBefore(limitDate) ? moment(value) : moment(limitDate)
}

export const isDateValid = (value) => {
  const date = moment(value || null)
  return date.isValid()
}

export const validateIsValidDate = (value) => moment(value, I18n.t('DateFormat'), true).isValid()

export const validateIsChild = (value) => moment(value).isAfter(moment().subtract(14, 'years'))

export const validateIsAdult = (value) => moment(value).isBefore(moment().subtract(14, 'years'))

export const validateIsNotFuture = (value) => !moment(value).isAfter(moment())

export const calculateAge = (birthdate) => moment().diff(moment(birthdate, 'YYYY-MM-DD'), 'years')
