import { StyleSheet, ActivityIndicator } from 'react-native'
import styled from 'styled-components/native'
import { Appearance } from 'react-native-appearance'

import Typography from 'APP/Converse/Typography'
import { Colors, Fonts, Metrics, ApplicationStyles } from 'APP/Themes'

export default StyleSheet.create({
  container: {
    paddingHorizontal: Metrics.baseMargin * 1.75,
    paddingBottom: Metrics.bottomSpace,
  },
  guidanceBar: {
    padding: Metrics.baseMargin * 1.25,
    backgroundColor: Colors.tagBg,
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'row',
  },
  guidanceText: {
    ...Fonts.style.body,
    color: Colors.darkText,
    marginLeft: Metrics.baseMargin * 0.75,
    flex: 1,
  },
  bold: {
    fontFamily: Fonts.type.bold,
  },
  titleContainer: {
    backgroundColor: Colors.contrastElementBg,
    paddingVertical: Metrics.baseMargin * 0.5,
  },
  listWrapper: {
    ...ApplicationStyles.shadow,
    marginBottom: Metrics.baseMargin,
    marginTop: Metrics.baseMargin,
  },
  list: {
    ...ApplicationStyles.shadow,
    backgroundColor: Colors.elementsBg,
    borderRadius: 10,
    overflow: 'hidden',
  },
  listItemProcessing: {
    position: 'absolute',
    right: 5,
  },
  customMessageInputHintContainer: {
    ...ApplicationStyles.screen.formInputHelpText,
    color: Colors.textButtonSelected,
    paddingVertical: Metrics.baseMargin * 0.5,
    marginHorizontal: Metrics.baseMargin / 2,
    marginTop: 0,
    marginBottom: 0,
    paddingHorizontal: 0,
  },
  customMessageInputHintText: {
    ...Fonts.style.bodySmall,
    textAlign: 'right',
    color: Colors.textButtonSelected,
  },
  customMessageSubmitButton: {
    ...ApplicationStyles.screen.primaryButton,
    marginHorizontal: 0,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  viewOptionsCta: {
    margin: 0,
  },
})

export const StyledPromptOptionTitle = styled(Typography).attrs(() => ({
  variant: 'bodySmallBold',
  align: 'center',
}))`
  width: 100%;
`

export const StyledPromptOption = styled(Typography).attrs(() => ({
  variant: 'subheader',
  align: 'center',
}))`
  width: 100%;
`

export const StyledHighlightedPromptOption = styled(StyledPromptOption)`
  color: ${({ theme: { colors } }) =>
    Appearance.getColorScheme() === 'dark' ? colors.darkText : colors.text};
`

export const StyledActivityIndicator = styled(ActivityIndicator).attrs(({ theme }) => ({
  color: theme.colors.text,
  size: 20,
  testID: 'promptOptionLoading',
}))``

export const StyledHighlightedActivityIndicator = styled(StyledActivityIndicator).attrs(
  ({ theme: { colors } }) => ({
    color: Appearance.getColorScheme() === 'dark' ? colors.darkText : colors.text,
  })
)``
