import { PhoneNumberUtil } from 'google-libphonenumber'

const isValidPhoneNumber = (phoneNumber) => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance()

    // Following the same phone validation as in beautiful-mind.
    // First try to parse the phone number for CA as the default region,
    // so that country code +1 is optional,
    // but it will parse for other regions as well with a country code.
    // Then validate the phone number.
    const parsedPhoneNumber = phoneUtil.parse(phoneNumber, 'CA')
    return phoneUtil.isValidNumber(parsedPhoneNumber)
  } catch (e) {
    return false
  }
}

export { isValidPhoneNumber }
