import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Fonts, Metrics } from '../../Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    minHeight: '100%',
  },
  refresh: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%',
  },
  image: {
    height: 238,
    width: '100%',
  },
  body: {
    backgroundColor: Colors.appBg,
    paddingHorizontal: Metrics.baseMargin * 1.4,
    paddingTop: 0,
    paddingBottom: Metrics.baseMargin * 3.75,
  },
  tagList: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: Metrics.baseMargin / 2,
    marginTop: Metrics.baseMargin * -1,
  },
  tag: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Colors.tabSelected,
    paddingVertical: Metrics.baseMargin * 0.3,
    paddingHorizontal: Metrics.baseMargin / 2,
    borderRadius: 2,
    marginRight: Metrics.baseMargin * 0.3,
  },
  tagIcon: {
    color: Colors.textInverted,
  },
  tagCopy: {
    ...Fonts.style.caption,
    color: Colors.textInverted,
    textTransform: 'uppercase',
    marginLeft: Metrics.baseMargin * 0.3,
  },
  title: {
    ...Fonts.style.h1,
    marginTop: Metrics.baseMargin * 0.65,
    marginHorizontal: Metrics.baseMargin / 2,
    marginBottom: Metrics.baseMargin * 1.75,
    color: Colors.text,
  },
  timeBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Metrics.baseMargin * 0.9,
    marginHorizontal: Metrics.baseMargin / 2,
  },
  time: {
    ...Fonts.style.bodySmall,
    paddingHorizontal: Metrics.baseMargin / 5.5,
    color: Colors.text,
  },
  timeBold: {
    ...Fonts.style.bodySmallBold,
    color: Colors.text,
  },
  description: {
    ...Fonts.style.body,
    color: Colors.text,
    paddingHorizontal: Metrics.baseMargin / 2,
  },
  org: {
    ...Fonts.style.body,
    color: Colors.text,
    paddingHorizontal: Metrics.baseMargin / 2,
    marginTop: Metrics.baseMargin,
  },
  ctaWrapper: {
    marginTop: Metrics.baseMargin,
  },
  joinCta: {
    marginTop: Metrics.baseMargin * 1.25,
    marginBottom: 0,
    marginHorizontal: 0,
  },
  trackerTitle: {
    ...Fonts.style.h4,
    color: Colors.text,
    paddingBottom: Metrics.baseMargin / 2,
  },
  trackerCta: {
    backgroundColor: Colors.bannerBg,
    marginTop: Metrics.baseMargin * 1.25,
    paddingTop: Metrics.baseMargin * 1.55,
    paddingHorizontal: Metrics.baseMargin * 1.55,
    paddingBottom: Metrics.baseMargin * 1.55,
    borderRadius: 16,
  },
  trackerCopy: {
    ...Fonts.style.body,
    color: Colors.text,
  },
  trackerButton: {
    marginTop: Metrics.baseMargin * 1.55,
    marginHorizontal: 0,
    marginBottom: 0,
    width: '100%',
  },
  metrics: {
    backgroundColor: Colors.elementsBg,
    paddingTop: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin * 3.75,
    ...ApplicationStyles.shadow,
    borderTopWidth: 1,
    borderTopColor: Colors.shadow,
  },
  modalText: {
    alignSelf: 'flex-start',
    color: Colors.text,
  },
})
