import React, { useEffect } from 'react'
import { View, Text, ActivityIndicator, Image } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import UrlBuilder from 'APP/Services/UrlBuilder'
import { PrimaryButton } from 'APP/Components/Buttons'
import ProgressBarCircle from 'APP/Components/ProgressBarCircle'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import ButtonMoreAction from 'APP/Components/ButtonMoreAction'
import { Colors, Metrics } from 'APP/Themes'
import I18n from 'APP/Services/i18n'
import Retry from 'APP/Images/retry.svg'
import Styles from './style'
import Analytics from 'APP/Services/Analytics'

const WeeklyProgress = ({ retry, loading, refetch, data, menuOptions }) => {
  const navigation = useNavigation()
  const percentage = data?.weekly_progress?.percentage
  const achievedTarget = percentage >= 100

  const navigateToRecentHistory = () => {
    navigation.navigate('recentActiveHistory')
  }

  useEffect(() => {
    Analytics.trackScreen('Keep moving widget')
  }, [])

  return (
    <View>
      <View style={Styles.headerContainer}>
        <Text style={Styles.header}>
          {achievedTarget
            ? I18n.t('ActiveMinutes.weeklyProgress.achieved.title')
            : I18n.t('ActiveMinutes.weeklyProgress.title')}
        </Text>
        {menuOptions?.length && (
          <View style={Styles.menuContainer}>
            <ButtonMoreAction
              analyticsName="activeMinutesMenu"
              actions={[
                ...menuOptions,
                {
                  value: I18n.t('ActiveMinutes.menu.cancel'),
                  cancellable: true,
                  enable: true,
                },
              ]}
              size={24}
            />
          </View>
        )}
      </View>
      {!data && loading && (
        <ActivityIndicator
          size="large"
          color={Colors.text}
          style={Styles.loading}
          testID="loading"
        />
      )}
      {retry && (
        <View style={Styles.retry} testID="retry">
          <Retry fillLine={Colors.text} width={Metrics.icons.large} height={Metrics.icons.large} />
          <Text style={Styles.retryTitle}>{I18n.t('ActiveMinutes.retry.title')}</Text>
          <Text style={Styles.retryCopy}>{I18n.t('ActiveMinutes.retry.copy')}</Text>
          <PrimaryButton
            style={Styles.retryCta}
            title={I18n.t('ActiveMinutes.retry.cta')}
            onPress={refetch}
            analyticsName="Retry activeMinutes"
          />
        </View>
      )}
      {!retry && !!data && (
        <>
          <Text style={Styles.subheading} testID="subheading">
            {achievedTarget
              ? I18n.t('ActiveMinutes.weeklyProgress.achieved.subheading', {
                  activeMin: data?.weekly_goal,
                })
              : I18n.t('ActiveMinutes.weeklyProgress.subheading', {
                  activeMin: data?.weekly_goal,
                })}
          </Text>
          <View testID="progress">
            <Image
              source={{ uri: UrlBuilder.activeMinutesAssets('illustration-left.png') }}
              style={Styles.image}
            />
            <ProgressBarCircle
              gradientWhenFull
              percentage={percentage}
              radius={100}
              strokeWidth={16}
              secondaryColor={achievedTarget ? Colors.habitDoneAccent : Colors.accent}
            >
              <View style={Styles.progressWrapper}>
                <View style={Styles.progressFraction}>
                  <Text
                    style={[
                      Styles.progress,
                      achievedTarget ? Styles.achievedHighlight : Styles.inProgressHighlight,
                    ]}
                    testID="progressFractionNumerator"
                  >
                    {data?.weekly_progress?.minutes || 0}
                  </Text>
                  <Text style={Styles.progress} testID="progressFractionDenominator">
                    {' '}
                    / {data?.weekly_goal || 0}
                  </Text>
                </View>
                <Text style={Styles.progressCopy}>
                  {I18n.t('ActiveMinutes.weeklyProgress.minutesThisWeek')}
                </Text>
              </View>
              <DialogueTouchableOpacity
                onPress={navigateToRecentHistory}
                testID="navigateToRecentHistory"
                analyticsName="Explore"
              >
                <Text style={Styles.historyCta}>
                  {I18n.t('ActiveMinutes.weeklyProgress.explore')}
                </Text>
              </DialogueTouchableOpacity>
            </ProgressBarCircle>
            <Image
              source={{ uri: UrlBuilder.activeMinutesAssets('illustration-right.png') }}
              style={[Styles.image, Styles.imageRight]}
            />
          </View>
        </>
      )}
    </View>
  )
}

export default WeeklyProgress
