// External Libs
import React, { useEffect } from 'react'
import { View, Text, ScrollView, Platform } from 'react-native'
import PushNotification from 'react-native-push-notification'
import { connect, useDispatch, useSelector } from 'react-redux'

import StartupActions from 'APP/Redux/StartupRedux'
import PatientActions from 'APP/Redux/PatientRedux'
import NotificationActions from 'APP/Redux/NotificationRedux'

import BulletListItem from 'APP/Components/BulletListItem'
import SignupLogoHeader from 'APP/Components/SignupLogoHeader'
import { PrimaryButton } from 'APP/Components/Buttons'

import I18n from 'APP/Services/i18n'
import { createChannels } from 'APP/Services/PushNotificationServices'

import { featuresState } from 'APP/Sagas/FeaturesSaga'

import Styles from './style'
import Notification from 'APP/Images/notifications.svg'
import { Colors, Metrics } from 'APP/Themes'
import { useDevTools } from 'APP/Lib/DevTools'
import { buildSMSPreference } from 'APP/Lib/PreferenceHelpers'

const PushNotificationPermissionScreen = ({
  setNotificationPermissionAccepted,
  postNotificationsRequest,
}) => {
  const { setToolFunction, setToolName } = useDevTools()
  const dispatch = useDispatch()
  const { newNotificationCenter } = useSelector(featuresState)

  useEffect(() => {
    setToolName('Allow')
    setToolFunction(() => () => {
      dispatch(StartupActions.setNotificationPermissionAccepted(true))
    })
    return () => {
      setToolFunction(null)
      setToolName(null)
    }
  }, [])

  const handleCtaPress = () => {
    // This screen should only be accessible on Android >= 13, and iOS
    if (Platform.OS === 'android' && Platform.Version >= 33) {
      createChannels()
      postNotificationsRequest()
    } else if (Platform.OS === 'ios') {
      PushNotification.requestPermissions().then((res) => {
        /*
        The response tell you which permissions (Alert, badge, sound) have been accepted.
        The user can only chose a subset later in the iOS settings.
        Since we request all of them, the state of res.alert equals the state of the requested permissions
        */
        setNotificationPermissionAccepted(res?.alert)

        const sms = buildSMSPreference(newNotificationCenter, res?.alert, res?.alert)
        dispatch(NotificationActions.updateNotificationPreference(sms))
      })
    }
  }

  const renderTexts = () => {
    const bodyNotifyBulletPoints = I18n.t(
      'PushNotificationPermissionScreen.bodyNotifyBulletPoints',
      { returnObjects: true }
    )

    const renderBulletListItem = (item) => {
      return (
        <BulletListItem bulletStyle={Styles.bulletStyle}>
          <Text style={Styles.text}>{item}</Text>
        </BulletListItem>
      )
    }

    return (
      <>
        <Text style={Styles.boldNotify}>
          {I18n.t('PushNotificationPermissionScreen.bodyNotify')}
        </Text>
        {renderBulletListItem(bodyNotifyBulletPoints[0])}
        {renderBulletListItem(bodyNotifyBulletPoints[1])}
      </>
    )
  }

  const bodyAllow = I18n.t('PushNotificationPermissionScreen.bodyAllow', { returnObjects: true })
  const subtitle = (
    <Text style={Styles.text}>
      {bodyAllow[0]}
      <Text style={Styles.bold}> {bodyAllow[1]}</Text>
    </Text>
  )

  return (
    <ScrollView style={Styles.scrollContainer}>
      <View style={Styles.container}>
        <SignupLogoHeader
          title={I18n.t('PushNotificationPermissionScreen.title')}
          subtitle={subtitle}
        />
        {renderTexts()}
        <View style={Styles.imageSection}>
          <Notification
            fillLine={Colors.text}
            fillLineInverted={Colors.textInverted}
            width={Metrics.screenWidth - Metrics.baseMargin}
          />
        </View>
        <PrimaryButton
          style={Styles.primaryButton}
          title={I18n.t('PushNotificationPermissionScreen.cta')}
          analyticsName={'PushNotificationPermissionScreen.allowNotifications'}
          onPress={() => handleCtaPress()}
        />
      </View>
    </ScrollView>
  )
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    ...props.route?.params,
    setNotificationPermissionAccepted: (isAccepted) =>
      dispatch(StartupActions.setNotificationPermissionAccepted(isAccepted)),
    postNotificationsRequest: () => dispatch(PatientActions.postNotificationsRequest()),
  }
}

export default connect(null, mapDispatchToProps)(PushNotificationPermissionScreen)
