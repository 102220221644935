import React from 'react'
import Typography from 'APP/Converse/Typography'
import { View, Linking } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import Styles from './style'
import Button from 'APP/Converse/Button'
import I18n from 'APP/Services/i18n'
import { ServiceGroupImage } from 'APP/Components/ServiceGroupImage'
import UrlBuilder from 'APP/Services/UrlBuilder'
import DialogueAutolink from 'APP/Components/DialogueAutolink'

const PillwayAuthenticationError = ({ onRetry, screenIconOverride, serviceGroup }) => {
  const navigation = useNavigation()

  const handleEmailPress = () => {
    const url = UrlBuilder.supportEmailUrl()
    Linking.openURL(url).catch((err) => {
      console.error('Error opening email:', err)
    })
  }

  return (
    <View testID="pillway-authentication-error" style={Styles.container}>
      <View style={Styles.contentWrapper}>
        <ServiceGroupImage
          style={Styles.logo}
          source={screenIconOverride}
          serviceGroup={serviceGroup}
        />
        <Typography variant="h1" style={Styles.mainHeader}>
          {I18n.t('PillwayAuthenticationScreen.failedRetryError.header')}
        </Typography>
        <Typography variant="subheader" style={Styles.subHeader}>
          {I18n.t('PillwayAuthenticationScreen.failedRetryError.subHeader')}
        </Typography>
      </View>

      <View style={Styles.content}>
        <View style={Styles.wrapper}>
          <Button
            variant="primary"
            title={I18n.t('PillwayAuthenticationScreen.failedRetryError.retryButton')}
            widthVariant="full"
            testID="retryButton"
            onPress={onRetry}
            style={Styles.button}
          />
          <Button
            variant="secondary"
            title={I18n.t('PillwayAuthenticationScreen.failedRetryError.returnButton')}
            widthVariant="full"
            testID="goBackButton"
            onPress={() => navigation.goBack()}
            style={Styles.button}
          />
        </View>
        <Typography variant="subheader" style={Styles.contactUs}>
          {I18n.t('PillwayAuthenticationScreen.failedRetryError.contactUs')}{' '}
          <DialogueAutolink
            text={I18n.t('PillwayAuthenticationScreen.failedRetryError.sendEmail')}
            onPress={handleEmailPress}
            style={Styles.link}
          />
        </Typography>
      </View>
    </View>
  )
}

export default PillwayAuthenticationError
