import { put, call, select } from 'redux-saga/effects'
import Moment from 'moment-timezone'
import * as Sentry from '@sentry/react-native'
import moment from 'moment'

// Redux Actions
import AppointmentBookingActions, { PartsOfDay } from 'APP/Redux/AppointmentBookingRedux'

// Services
import { logDdError } from 'APP/Lib/Datadog'
import { getAppointmentsApi, getAvailabilitiesApi } from 'APP/Store/utils/timekeeper'

export const selectAccessToken = (state) => state.login.accessToken
export const selectPatientProfile = (state) => state.patient?.profile
export const selectFeatures = (state) => state.features

export const processAvailabilities = (availabilities, timezone, untilAt) => {
  const processedAvailabilities = {
    [PartsOfDay.MORNING]: [],
    [PartsOfDay.AFTERNOON]: [],
    [PartsOfDay.EVENING]: [],
  }
  let hasAvailabilities = false

  availabilities.forEach((availability) => {
    const startAt = Moment.tz(availability.start_at, timezone)

    // filter out availabilities that are past the until-at
    if (startAt.isAfter(untilAt)) {
      return
    }

    const startAtHour = startAt.hour()

    if (
      startAtHour >= 0 &&
      startAtHour < 12 &&
      processedAvailabilities[PartsOfDay.MORNING].length < 9
    ) {
      processedAvailabilities[PartsOfDay.MORNING].push(availability)
      hasAvailabilities = true
    } else if (
      startAtHour >= 12 &&
      startAtHour < 17 &&
      processedAvailabilities[PartsOfDay.AFTERNOON].length < 9
    ) {
      processedAvailabilities[PartsOfDay.AFTERNOON].push(availability)
      hasAvailabilities = true
    } else if (
      startAtHour >= 17 &&
      startAtHour < 24 &&
      processedAvailabilities[PartsOfDay.EVENING].length < 9
    ) {
      processedAvailabilities[PartsOfDay.EVENING].push(availability)
      hasAvailabilities = true
    }
  })

  return { availabilities: processedAvailabilities, hasAvailabilities }
}

export function* getAvailableAppointments({ day, dateUntil, payload }) {
  try {
    const profile = yield select(selectPatientProfile)
    const province = profile?.location?.province || profile?.residesIn

    const timekeeperClient = yield call(getAvailabilitiesApi)
    const startAt = moment(payload.start_at).format()
    const endAt = moment(payload.start_at).endOf('day').format()
    const response = yield call(
      timekeeperClient.getAvailabilities,
      payload.type,
      `CA-${province}`,
      profile?.preferred_language,
      startAt,
      endAt,
      payload?.provider_ids,
      true
    )

    const { availabilities, hasAvailabilities } = processAvailabilities(
      response?.data?.data ?? [],
      Moment.tz.guess(),
      Moment(dateUntil)
    )

    yield put(
      AppointmentBookingActions.getAvailableAppointmentsSuccess(
        availabilities,
        hasAvailabilities,
        day
      )
    )
  } catch (e) {
    yield put(AppointmentBookingActions.getAvailableAppointmentsError(e, day))
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
  }
}

export function* bookAppointment({ payload }) {
  try {
    const timekeeperClient = yield call(getAppointmentsApi)
    const response = yield call(timekeeperClient.create, payload)

    yield put(AppointmentBookingActions.bookAppointmentSuccess(response))
  } catch (e) {
    yield put(AppointmentBookingActions.bookAppointmentError(e))
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
  }
}
