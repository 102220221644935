import { v4 as uuid } from 'uuid'
import { all, put, putResolve, call, race, select, take } from 'redux-saga/effects'
import * as Sentry from '@sentry/react-native'
import Toast from 'react-native-toast-message'

// Actions
import LoginActions from 'APP/Redux/LoginRedux'
import StartupActions from 'APP/Redux/StartupRedux'
import LoggerActions from 'APP/Redux/LoggerRedux'
import PatientActions from 'APP/Redux/PatientRedux'
import PatientHistoryActions from 'APP/Redux/PatientHistoryRedux'
import { familyActions } from 'APP/Store/Family'
import DeeplinkActions from 'APP/Redux/DeeplinkRedux'

//Types
import { LoginTypes } from 'APP/Redux/LoginRedux'
import { AppSessionTypes } from 'APP/Redux/AppSessionRedux'

// Sagas
import {
  startupNavigation,
  navigateWithEmailVerified,
  requestMessagingCredentials,
  fetchUserInfo,
  navigateWithPermission,
  patientState,
  fetchPatientProfile,
  fetchProfileData,
  fetchScribeV2User,
} from './PatientSagas'
import { registerPushToken, unregisterPushToken } from './StartupSagas'
import { requestAccountLinking } from './AccountLinkingSagas'

// Libs
import { getExpiration, getCustomClaims } from 'APP/Lib/AuthHelpers'
import { serviceHeaders } from 'APP/Lib/ServiceHeaders'

// Config
import Config from 'APP/Config'
// Services
import authClient from 'APP/Services/AuthClient'
import sprigClient from 'APP/Services/Sprig'

import I18n from 'APP/Services/i18n'
import { Silkroad, SilkroadUnAuthenticated } from '@dialogue/services'
import { biometricsAvailable, APP_LOCK_STATE, triggerBiometrics } from 'APP/Services/AppLock'
import Analytics, { LAUNCH_CATEGORY } from 'APP/Services/Analytics'
import { navigationRef as Nav } from 'APP/Nav'
import * as NestedNavHelper from 'APP/Nav/NestedNavHelper'
import { getMaxPublicSessionTimestamp, MINUTE_IN_MILLISECOND } from 'APP/Lib/Utilities'
import { PublicComputerType } from 'APP/Lib/Enums'
import { delay } from 'typed-redux-saga'
import {
  getSecureStorageEntry,
  removeSecureStorageEntry,
  setSecureStorageEntry,
} from 'APP/Lib/SecureStorage'
import { getSecureStorageKeys, securelyPersistedLoginEntries } from 'APP/Config/ReduxPersist'
import { logDdError } from 'APP/Lib/Datadog'

export const fullState = (state) => state
export const loginState = (state) => state.login
export const appState = (state) => state.app
export const featuresState = (state) => state.features

export const minimumProfileState = (state) => {
  return {
    id: state.patient.profile.id,
    user_id: state.patient.profile.user_id,
    givenName: state.patient.profile.givenName,
    familyName: state.patient.profile.familyName,
    email: state.patient.profile.email,
  }
}

export const isMinimumProfileComplete = (profile) => {
  return profile.id && profile.user_id && profile.givenName && profile.familyName && profile.email
}

export function* retrieveLoginData() {
  try {
    const keys = getSecureStorageKeys()
    let results = yield all(keys.map((key) => call(getSecureStorageEntry, key)))

    const entries = securelyPersistedLoginEntries.reduce((acc, key, index) => {
      acc[key] = results[index]
      return acc
    }, {})

    yield put(LoginActions.rehydrateReducer(entries))
  } catch (e) {
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
  }
}

export function* persistLoginData(data) {
  try {
    const loginDataSecureStorageKeys = getSecureStorageKeys()
    yield all(
      securelyPersistedLoginEntries
        .map((key) => data[key])
        .map((value, index) =>
          call(setSecureStorageEntry, loginDataSecureStorageKeys[index], value)
        )
    )
  } catch (e) {
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
  }
}

export function* cleanupAuthData() {
  try {
    const keys = getSecureStorageKeys()
    yield all(keys.map((key) => call(removeSecureStorageEntry, key)))
  } catch (e) {
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
  }
}

export function* login(params, action) {
  yield put(LoginActions.setIsLoggingIn(true))
  const { accessToken, refreshToken } = yield select(loginState)
  const {
    triggerStartupNav,
    triggerLogoutOnFailure,
    triggerSilentlogin = false,
    isEphemeralSession = true,
    openSignUp = false,
    saveSecondaryAccessToken = false,
    withHash = false,
  } = { ...params, ...action?.options }
  try {
    const auth0Method = withHash
      ? authClient.parseHash
      : openSignUp
      ? authClient.signup
      : authClient.authorize
    const credentials = yield call(auth0Method, {
      connection: action?.connection,
      triggerSilentlogin,
      isEphemeralSession,
    })

    if (credentials) {
      const secondaryAccessToken = saveSecondaryAccessToken ? accessToken : undefined
      const customClaims = getCustomClaims(credentials.accessToken)
      const { sso: ssoStatus } = customClaims
      yield putResolve(
        LoginActions.loginSuccess(
          undefined, // Username is not available for universal login
          credentials.idToken,
          credentials.accessToken,
          credentials.refreshToken ? credentials.refreshToken : refreshToken,
          getExpiration(credentials.expiresIn),
          customClaims,
          secondaryAccessToken
        )
      )

      Analytics.attachEntity('tenant', { tenant_id: customClaims.tenant_id })
      sprigClient.setVisitorAttribute('tenant_id', `${customClaims.tenant_id}`)

      if (secondaryAccessToken) {
        yield call(requestAccountLinking)
        return
      }

      yield call(processLoginSuccess, ssoStatus, triggerStartupNav)
    }
  } catch (e) {
    yield put(LoginActions.setIsLoggingIn(false))
    // this type of error can't be parsed like below
    if (e?.message === 'Unknown WebAuth error') {
      Sentry.captureException(e)
      logDdError(e.message, e.stack)
    }

    const stringify = JSON.stringify(e)
    yield put(LoginActions.loginFailure(stringify))

    const parsedError = JSON.parse(stringify)
    // MultiPass user provisioning error
    if (parsedError?.message === 'Failed to run login actions.') {
      yield call(Nav.navigate, 'loginErrorScreen')
      return
    }

    if (triggerLogoutOnFailure) {
      yield call(logout, { reason: 'Failed to login' })
    }
  }
}

export function* processLoginSuccess(ssoStatus, triggerStartupNav) {
  try {
    const { userInfo } = yield select(patientState)
    const emailVerified = userInfo?.email_verified ?? false

    if ((!ssoStatus || ssoStatus === 'linked') && emailVerified) {
      yield call(requestMessagingCredentials)
      yield call(registerToken)
    }

    if (triggerStartupNav) {
      yield call(startupNavigation)
    }

    yield put(LoginActions.startRefreshAuthPoll())
    yield put(LoginActions.loginFetchComplete())
  } catch (e) {
    const stringify = JSON.stringify(e)
    yield put(LoggerActions.error(`Error in processLoginSuccess ${stringify}`))
    throw new Error(stringify)
  }
}

// Authentication via password-realm for post-account creation, to be removed once accounts are created in UL
export function* loginWithUsernameAndPassword(action, silent) {
  yield put(LoginActions.setIsLoggingIn(true))
  const { refreshToken } = yield select(loginState)
  const { username, password } = action

  try {
    const response = yield call(authClient.loginWithUsernameAndPassword, { username, password })
    const customClaims = getCustomClaims(response.accessToken)
    if (response.ok) {
      // dispatch successful logins
      yield putResolve(
        LoginActions.loginSuccess(
          username,
          response.idToken,
          response.accessToken,
          response.refreshToken ? response.refreshToken : refreshToken,
          getExpiration(response.expiresIn),
          customClaims,
          undefined // Secondary access token is only available through certain deeplinks for now
        )
      )

      Analytics.attachEntity('tenant', { tenant_id: customClaims.tenant_id })
      sprigClient.setVisitorAttribute('tenant_id', `${customClaims.tenant_id}`)

      if (!silent) {
        yield put(StartupActions.setPersistedLoginId(username))
        yield call(requestMessagingCredentials)
        yield call(startupNavigation)
        yield put(LoginActions.loginFetchComplete())
        yield call(registerToken)
        yield put(LoginActions.startRefreshAuthPoll())
      }
    } else {
      yield put(LoginActions.stopRefreshAuthPoll())
      if (response?.error) {
        yield put(LoginActions.loginFailure(response.error))
      } else {
        yield put(LoginActions.loginFailure(response.problem))
      }
    }
  } catch (e) {
    yield put(LoginActions.setIsLoggingIn(false))
    yield put(LoginActions.stopRefreshAuthPoll())
    yield put(LoginActions.loginFailure(JSON.stringify(e)))
  }
}

export function* logout(action) {
  yield put(LoginActions.setIsLoggingIn(false))
  yield put(LoginActions.setIsLoggingOut(true))
  yield put(LoggerActions.log('Disconnect and logout...'))
  Analytics.trackStructuredEvent('Logout', action?.reason ?? 'Unknown logout reason')
  yield call(unregisterPushToken)
  yield put(StartupActions.setAppLockToggle(false))

  try {
    yield all([
      put(PatientActions.patientProfileClear()),
      put(PatientHistoryActions.patientHistoryClear()),
      put(familyActions.familyClear()),
      call(uuid),
      put(DeeplinkActions.setReadyToProcess(false)),
      sprigClient.logout(),
      put(LoginActions.stopRefreshAuthPoll()),
    ])
    yield call(authClient.logout)
    Analytics.detachEntity('tenant')
    yield call(Nav.reset, { index: 0, routes: [{ name: 'welcome' }] })
  } catch (error) {
    yield put(LoggerActions.error(`Error while logging out ${JSON.stringify(error)}`))
  } finally {
    yield put(LoginActions.setIsLoggingOut(false))
  }
}

export function* registerToken() {
  const { pushToken } = yield select(appState)
  if (pushToken) {
    yield call(registerPushToken, { pushToken })
  }
}

export function* checkBiometricsAvailable() {
  return yield call(biometricsAvailable)
}

export function* checkAppLockSupport() {
  const bioAvailable = yield call(biometricsAvailable)
  yield put(LoggerActions.log(`bioAvailable ${bioAvailable}`))
  const app = yield select(appState)
  const appLockToggled = app && app.appLock && app.appLock.isToggled
  yield put(LoggerActions.log(`bioAvailable ${bioAvailable} && appLockToggled ${appLockToggled}`))

  if (!appLockToggled) {
    return APP_LOCK_STATE.DISABLED
  } else if (!bioAvailable) {
    return APP_LOCK_STATE.ENABLED_BLOCKED
  } else {
    return APP_LOCK_STATE.ENABLED
  }
}

export function* triggerAppLock() {
  const result = yield call(triggerBiometrics)
  yield put(LoggerActions.log(`App lock result ${JSON.stringify(result)}`))
  try {
    if (!result || !result.success) {
      yield put(LoginActions.logout('Failed app lock challenge'))
    } else {
      //proceed with refresh token if not complete
      yield call(NestedNavHelper.pop)
      // If we returned to the splash screen we need to refresh the token and run startup navigation
      if (Nav.getCurrentRoute()?.name === 'loading') {
        yield call(checkRenewAuth)
        yield call(startupNavigation)
      }
    }
  } catch {
    // If the checkRenewAuth fails and logs the user out, we need to catch and do nothing
    // to avoid breaking the root saga
  }
}

export function* toggleMfa() {
  const patient = yield select(patientState)
  const { accessToken, refreshToken } = yield select(loginState)
  const silkroad = new Silkroad(accessToken, Config.SILKROAD_DOMAIN, undefined, serviceHeaders)
  const isMfaEnabled = patient?.userInfo?.user_metadata?.mfa_enabled ?? false

  try {
    if (!isMfaEnabled) {
      yield call(silkroad.setMFA, { enable: true })
      // Once mfa_enabled is set to true, the user needs to re-authenticate and setup their second factor
      yield call(login, {
        triggerStartupNav: false,
        triggerLogoutOnFailure: true, // If the auth fails for any reason we need to log the user out, which will force them to setup their second factor
      })
    } else {
      yield call(silkroad.setMFA, { enable: false })
      // Must refresh token to get the new user_metadata
      yield call(refreshAuth, refreshToken)
    }

    yield call(fetchUserInfo, accessToken)
    yield put(LoginActions.toggleMfaSuccess())
  } catch (error) {
    yield put(LoggerActions.error(`Error toggling MFA: ${error}`))
    yield put(LoginActions.toggleMfaFailure())
  }
}

export function* activateAppLockToggle() {
  const result = yield call(triggerBiometrics)
  yield put(
    LoggerActions.log(`Activate app lock toggle result ${Boolean(result && result.success)}`)
  )
  yield put(StartupActions.setAppLockToggle(Boolean(result && result.success)))
}

export function* refreshAuth(refreshToken) {
  try {
    const response = yield call(authClient.renewAuth, { refreshToken })

    if (response?.accessToken) {
      const customClaims = getCustomClaims(response.accessToken)
      yield put(
        LoginActions.refreshAuthSuccess(
          response.idToken,
          response.accessToken,
          response.refreshToken ? response.refreshToken : refreshToken,
          getExpiration(response.expiresIn),
          customClaims
        )
      )
      Analytics.attachEntity('tenant', { tenant_id: customClaims.tenant_id })
      sprigClient.setVisitorAttribute('tenant_id', `${customClaims.tenant_id}`)
    } else if (response?.error) {
      // FIXME: return a boolean instead of throwing an error
      throw response.error
    }
  } catch (error) {
    yield put(LoginActions.refreshAuthFailure(error))
    Analytics.trackLoadingTime({
      action: LAUNCH_CATEGORY.EXIT,
      label: 'Refresh token failed',
    })
    throw error
  }
}

export function* resetPassword(action) {
  const { username } = action

  // Silkroad returns a 204 and the way dialogue/services returns a responsese
  // means that we only have access to the empty response body
  try {
    const silkroad = new SilkroadUnAuthenticated(Config.SILKROAD_DOMAIN, undefined, serviceHeaders)
    yield call(silkroad.resetPassword, {
      email: username,
      brand_id: Config.BRAND_ID,
    })
    yield put(LoggerActions.log('Successfully sent password reset request'))
  } catch (error) {
    yield put(LoggerActions.error(`Error requesting password reset ${JSON.stringify(error)}`))
  }
}

export function* emailVerificationCheck(silentCheck) {
  const { accessToken, refreshToken } = yield select(loginState)

  let emailVerified = false

  yield put(LoginActions.emailVerificationCheck(silentCheck))
  try {
    yield call(fetchUserInfo, accessToken)
    const patient = yield select(patientState)
    emailVerified = patient.userInfo?.email_verified ?? false

    if (emailVerified) {
      // we need to fetch a new token when the user is verified to have the tenant
      yield call(refreshAuth, refreshToken)
      yield call(fetchPatientProfile)
      yield all([call(fetchScribeV2User), call(requestMessagingCredentials), call(registerToken)])
      yield call(fetchProfileData)

      yield put(LoginActions.emailVerificationCheckSuccess())
      yield call(navigateWithEmailVerified)
    } else {
      yield put(LoginActions.emailVerificationCheckFailure())
    }
  } catch (err) {
    yield put(LoginActions.emailVerificationCheckFailure())
    throw err
  }
}

export function* watchEmailVerificationCheckRequests() {
  while (true) {
    const { silentCheck, logout } = yield race({
      userRequestedCheck: take(LoginTypes.REQUEST_EMAIL_VERIFICATION_CHECK),
      silentCheck: take(AppSessionTypes.SET_APP_FOREGROUNDED),
      logout: take(LoginTypes.LOGOUT),
    })

    // Prevent calling email verification check when there is a countdown block going on
    const { emailVerificationBlock } = yield select(loginState)

    if (emailVerificationBlock) {
      break
    }

    if (logout) {
      break
    }

    const emailVerified = yield call(emailVerificationCheck, !!silentCheck)

    if (emailVerified) {
      break
    }
  }
}

export function* requestEmailVerificationResend() {
  try {
    const login = yield select(loginState)
    const silkroad = new Silkroad(
      login.accessToken,
      Config.SILKROAD_DOMAIN,
      undefined,
      serviceHeaders
    )
    const communication_language = I18n.communicationLanguage
    yield call(silkroad.resendVerificationEmail, { communication_language })
    yield put(LoginActions.emailVerificationResendSuccess())
  } catch (error) {
    yield put(LoginActions.emailVerificationResendFailure())
  }
}

export function* renewAuth() {
  const { refreshToken } = yield select(loginState)

  // attempt the token renewal 3 times if we get a timeout or network error
  let attempt = 0
  let originalError
  while (attempt < 3) {
    attempt++
    try {
      yield call(refreshAuth, refreshToken)
      return
    } catch (error) {
      // If we get either a network error or timeout we retry after a short delay
      originalError = error
      if (error === 'NETWORK_ERROR' || error === 'TIMEOUT_ERROR') {
        delay(150)
        break
      }

      // Other "non recoverable" error we:
      // 1. log the user out
      // 2. navigate to the welcome screen
      // 3. show a toast
      // 4. throw the error so it can cascade up the sagas
      yield put(LoginActions.logout('Failed to refresh token'))
      yield call(Nav.reset, { index: 0, routes: [{ name: 'welcome' }] })

      Toast.show({
        text1: I18n.t('WelcomeScreen.errorTitle'),
        text2: I18n.t('WelcomeScreen.errorSubtitle'),
      })

      throw error
    }
  }
  // Throw original error
  throw originalError
}
export function* checkRenewAuth() {
  const { accessToken, tokenExpiration } = yield select(loginState)

  // On web, we refresh the session when we're 8 minutes away from the session expiry (which last 1 hour)
  // On mobile, we refresh the tokens when we're 8 minutes away from the token expiry
  const expiryOffset = 8 * MINUTE_IN_MILLISECOND
  const expiryTime = Date.now() + expiryOffset
  const shouldRenewAuth = expiryTime > tokenExpiration
  if (shouldRenewAuth && accessToken) {
    yield call(renewAuth)

    // If we had to renew the Auth, we have to restart the mattermost websocket
    yield call(requestMessagingCredentials)
  }
}

export function* addRememberedDevice(action) {
  // Only add the device if the user checked the "dont ask me again on this computer"
  try {
    if (!action.data.dontAskPublicComputer) {
      yield call(navigateWithPermission)
    } else {
      const { accessToken, deviceId } = yield select(loginState)

      const silkroad = new Silkroad(accessToken, Config.SILKROAD_DOMAIN, undefined, serviceHeaders)

      const publicValue = action.data.publicComputer === PublicComputerType.ANSWERED_YES

      yield call(silkroad.addDevice, { device_id: deviceId, is_public: publicValue })
      yield call(navigateWithPermission)
    }
  } catch (error) {
    yield put(LoggerActions.error(`Add remember device: ${error}`))
    throw error
  }
}

export function* checkPublicComputer() {
  const { accessToken, publicComputer, lastActivity } = yield select(loginState)
  if (
    accessToken &&
    publicComputer === PublicComputerType.ANSWERED_YES &&
    lastActivity &&
    getMaxPublicSessionTimestamp() >= lastActivity
  ) {
    // The user is on a public computer and they logged out for the last time more than the
    //public session length on a public computer, so we log them out
    yield put(LoginActions.logout())
  }
}
