import React from 'react'

import ChecklistItem, { StepStatus } from 'APP/Converse/ChecklistItem'
import styled from 'styled-components/native'

interface IntakeWaitingStepsProps {
  steps: string[]
  currentStepIndex: number
}

const StyledStepContainer = styled.View`
  margin-top: ${({ theme }) => theme.metrics.baseMargin}px;
  gap: 12px;
  padding-bottom: ${({ theme }) => theme.metrics.baseMargin}px;
`

const IntakeWaitingSteps = ({ steps, currentStepIndex }: IntakeWaitingStepsProps) => {
  return (
    <StyledStepContainer>
      {steps.slice(0, currentStepIndex).map((step, index) => {
        const status = index === currentStepIndex - 1 ? StepStatus.LOADING : StepStatus.SUCCESS
        return <ChecklistItem key={index} title={step} status={status} />
      })}
    </StyledStepContainer>
  )
}

export default IntakeWaitingSteps
