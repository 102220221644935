/* istanbul ignore file */

import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { View, Pressable } from 'react-native'

// Components
import { StyledH3 } from 'APP/Converse/Typography'

// Config
const INDEX_OFFSET = 0

// Styles
const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: 20,
    backgroundColor: 'pink',
  },
  crumb: {
    marginRight: 10,
  },
  tabsCrumbContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}

export const routeIsASubNavigator = ({ state }) => state?.type === 'tab' || state?.type === 'drawer'

export const Crumb = ({ route, selected, label }) => {
  const navigation = useNavigation()
  const title =
    label ||
    route?.params?.title ||
    route?.params?.screenUri ||
    route?.name ||
    route?.params?.screen
  const onPress = () => navigation.navigate(route.name, route.params)

  if (routeIsASubNavigator(route)) {
    return <TabCrumbs route={route} />
  }

  return (
    <Pressable style={styles.crumb} onPress={onPress}>
      <StyledH3>
        {selected ? '> ' : '  '}
        {title}
      </StyledH3>
    </Pressable>
  )
}

export const TabCrumbs = ({ route }) => {
  const { state } = route
  const { routes, index } = state
  return (
    <View style={styles.tabsCrumbContainer}>
      {routes.map((route, index2) => (
        <Crumb
          key={route.key}
          routeIndex={route}
          index={index2}
          selected={index === index2}
          route={route}
        />
      ))}
    </View>
  )
}

export const DebugCrumbs = ({ stackRoutes, descriptors }) => {
  if (!stackRoutes || stackRoutes.length <= INDEX_OFFSET) {
    return null
  }
  return (
    <View style={styles.container} testID="debugCrumbs">
      {stackRoutes?.map((route, index) => {
        if (index < INDEX_OFFSET) {
          return null
        }
        const label = descriptors[route.key]?.options?.title || route?.name
        return <Crumb label={label} key={route.key} route={route} selected={true} />
      })}
    </View>
  )
}
