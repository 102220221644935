import React from 'react'
import { ActivityIndicator, View, Platform } from 'react-native'
import _ from 'lodash'
import { Appearance } from 'react-native-appearance'
import { WebView } from 'react-native-webview'
import * as Sentry from '@sentry/react-native'

import Animated, { FadeOut, Layout } from 'react-native-reanimated'

import Config from 'APP/Config'
import { Colors, Metrics } from 'APP/Themes'
import MicroAppManifest from '@dialogue/microapps/microapp-manifest.json'
import Styles from './style'
import { connect } from 'react-redux'
import { isWeb } from 'APP/Helpers/checkPlatform'
import { logDdError } from 'APP/Lib/Datadog'

/*
  If set to a string will be used as the location from which to load the MicroApp HTML.
  Useful for local development with live-reload.
*/
// const __DEV_HTTP_APP_OVERRIDE__ = __DEV__ && 'http://localhost:3000'
const __DEV_HTTP_APP_OVERRIDE__ = false

/*
  When set to true renders two 100px long bars side by side at top of instance.

  Top / Magenta: React-native host
  Bottom / Red: MicroApp html

  Useful for comparing outer render and inner render relative scale. We want them to match.
*/
const __ENABLE_SCALE_OVERLAY__ = false

const LDProps = {
  LDClientId: Config.LD_CLIENT_ID,
  LDUserKey: Config.LD_USER_KEY[Platform.OS],
}

const isConsoleMessage = (data) => data && data.type && data.type.indexOf('console.') === 0

const calcDimensionsState = ({ metrics = {} }) => {
  const { width, height } = metrics
  return {
    width: typeof width === 'number' ? width : '100%',
    height: typeof height === 'number' ? height : '100%',
  }
}

class MicroApp extends React.Component {
  constructor(props) {
    super(props)
    const manifest = MicroAppManifest[props.appId] || {}
    this.state = {
      ...calcDimensionsState(manifest),
      manifest,
      loading: true,
    }
  }

  componentDidMount() {
    if (isWeb()) {
      window.addEventListener('message', this.handleMessage, false)
    }
  }

  componentWillUnmount() {
    if (isWeb()) {
      window.removeEventListener('message', this.handleMessage)
    }
  }

  componentDidUpdate = () => {
    this.postEventToMicroApp({
      type: 'update',
      props: this.getProps(),
    })
  }

  setLoadingState = _.debounce((loading) => this.setState({ loading }), 400)

  onLoadStart = () => this.setLoadingState(true)
  onLoadEnd = () => this.setLoadingState(false)

  getDataFromRawMessageEvent = (event) => {
    try {
      const data = isWeb() ? event.data : event.nativeEvent.data
      return JSON.parse(data)
    } catch (err) {
      Sentry.captureException(err)
      logDdError(err.message, err.stack)
    }
    return {}
  }

  handleMessage = (event) => {
    const data = this.getDataFromRawMessageEvent(event)
    switch (data.type) {
      case 'ready':
        this.postEventToMicroApp({
          type: 'config',
          theme: this.getTheme(),
          props: this.getProps(),
          environment: this.getEnvironment(),
        })
        break
      case 'action': {
        const functionNames = data.props && Object.keys(data.props)
        functionNames.forEach((name) => {
          const propFunction = this.props[name]
          if (typeof propFunction === 'function') {
            this.props[name].apply(this, [data.props[name]])
          } else {
            console.log('no function found for: ', name)
          }
        })
        break
      }
      case 'close':
        typeof this.props.close === 'function' && this.props.close()
        break
      default:
        if (isConsoleMessage(data)) {
          this.handleConsole(data)
        }
        break
    }
  }

  handleConsole = ({ type = '', args = [] }) => {
    const consoleType = type.replace('console.')
    const consoleFunction = console[consoleType] || console.log
    consoleFunction(`[html.${type}]`, ...args)
  }

  postEventToMicroApp = (payload) => {
    const stringifiedPayload = JSON.stringify(payload)
    if (this.webViewRef) {
      if (isWeb()) {
        // Allow to interact with the microapp locally or externally from the web
        this.webViewRef.postMessage(
          stringifiedPayload,
          __DEV_HTTP_APP_OVERRIDE__ || window.location.origin
        )
      } else {
        this.webViewRef.postMessage(stringifiedPayload)
      }
    } else {
      const msg = `webViewRef not defined. Payload: ${stringifiedPayload}`
      Sentry.captureException(msg)
      logDdError(msg, 'MicroApp.postEventToMicroApp')
    }
  }

  getSource = () => {
    const { manifest = {} } = this.state
    const { appPath = '' } = manifest
    const isRemote = appPath.indexOf('http') === 0
    const isAndroid = Platform.OS === 'android'
    let uri = appPath
    if (isWeb()) {
      uri = `./microapps${manifest.appPath}`
    } else if (!isRemote) {
      uri = `${isAndroid ? 'file:///android_asset' : './dist'}${appPath}`
    }
    return {
      uri: __DEV_HTTP_APP_OVERRIDE__ || uri,
    }
  }

  // Note: this will be useful for injecting configuration directly instead of posting messages
  getInjectedJavaScriptBeforeContentLoaded = () => ``

  getInjectedJavascript = () =>
    `var meta = document.createElement('meta');meta.setAttribute('name', 'viewport');meta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no');document.getElementsByTagName('head')[0].appendChild(meta);`

  getNativeConfig = () => ({ props: { webContentsDebuggingEnabled: true, console: console } })

  getProps = () => {
    return isWeb()
      ? {
          ...this.props,
          enableScaleOverlay: false,
          messageFormat: 'mattermost',
        }
      : {
          enableScaleOverlay: __ENABLE_SCALE_OVERLAY__,
          renderNavBar: false,
          ...LDProps,
          ...this.props,
        }
  }

  getEnvironment = () => ({
    host: Platform.OS,
    locale: this.props.preferred_language,
    env: Config.MICROAPP_ENV,
  })

  getTheme = () => ({
    colors: {
      primary: Colors.accent,
      secondary: Colors.text,
      accent: Colors.accent,
      border: Colors.grayBg,
      backgroundBanner: Colors.bannerBg,
      backgroundPrimary: Colors.appBg,
      backgroundSecondary: Colors.elementsBg,
      buttonPrimary: Colors.buttonPrimary,
      buttonPrimaryText: Colors.buttonPrimaryText,
      textPrimary: Colors.text,
      textPrimaryContrast: Colors.textInverted,
      textPrimaryChat: Colors.text,
      textSecondary: Colors.text,
      textSecondaryContrast: Colors.text,
      textSecondaryChat: Colors.textChatSecondary,
      shadow: Colors.shadow, //TODO: Remove this at some point, as it uses the opaque shadow color instead of black + opacity
    },
    metrics: {
      textPrimary: 16,
      textSecondary: 16,
      textPrimaryChat: 16,
      textSecondaryChat: 12,
      baseMargin: Metrics.baseMargin,
      bottomSpace: Metrics.bottomSpace,
    },
    palette: {
      type: Appearance.getColorScheme(),
      primary: {
        main: Colors.text,
      },
      sliders: {
        from: Colors.sliderFrom,
        to: Colors.sliderTo,
      },
    },
  })

  setWebViewRef = (ref) => {
    this.webViewRef = isWeb() ? ref?.contentWindow : ref
  }

  renderLoadingOverlay = () => {
    return (
      <Animated.View style={Styles.loadingOverlay} exiting={FadeOut} layout={Layout}>
        <ActivityIndicator color={Colors.text} />
      </Animated.View>
    )
  }

  render() {
    const { width, height } = this.state

    return (
      <View style={[{ width, height }, this.props.style]} testID="microApp">
        {__ENABLE_SCALE_OVERLAY__ && <View style={Styles.scaleOverlay}></View>}
        {isWeb() ? (
          <iframe
            style={{
              borderWidth: '0',
              padding: '2rem',
            }}
            src={this.getSource()?.uri}
            height={'100%'}
            onLoad={this.onLoadEnd}
            ref={this.setWebViewRef}
            sandbox="allow-scripts allow-same-origin"
          />
        ) : (
          <WebView
            source={this.getSource()}
            originWhitelist={['*']}
            nativeConfig={this.getNativeConfig()}
            allowFileAccess={true}
            allowFileAccessFromFileURLs={true}
            style={Styles.webView}
            injectedJavaScript={this.getInjectedJavascript()}
            injectedJavaScriptBeforeContentLoaded={this.getInjectedJavaScriptBeforeContentLoaded()}
            scalesPageToFit={false}
            onLoadEnd={this.onLoadEnd}
            onLoadStart={this.onLoadStart}
            bounces={false}
            ref={this.setWebViewRef}
            allowsInlineMediaPlayback={true}
            javaScriptEnabled={true}
            cacheEnabled={false}
            onMessage={this.handleMessage}
          />
        )}
        {this.state.loading && this.renderLoadingOverlay()}
      </View>
    )
  }
}

const mapStateToProps = (state) => {
  const profile = state.patient?.profile
  const episodeId = state.history.currentChannelId
  const conversation = (state.history.channels && state.history.channels[episodeId]) || {}
  const province = profile?.location?.province || profile?.residesIn
  return {
    userId: profile?.id,
    patientId: profile?.id,
    preferred_language: profile?.preferred_language,
    accessToken: state.login.accessToken,
    adminAreaCode: `CA-${province}`,
    episodeTitle: conversation.header,
    episodeId: episodeId,
  }
}

export default connect(mapStateToProps)(MicroApp)
