import React from 'react'
import Typography from 'APP/Converse/Typography'
import {
  StyledPrimaryButton,
  StyledSecondaryButton,
  StyledTertiaryButton,
  StyledButtonContent,
  StyledButton,
  StyledLinearGradient,
  StyledSuccessButton,
  StyledErrorButton,
} from './style'
import StyledSpinner from 'APP/Converse/Spinner'
import Icon from 'APP/Converse/Icon'
import { baseColors } from 'APP/Theme/Theme'

import Config from 'APP/Config'

export interface ButtonProps {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'success' | 'error' | 'oomph' | 'tertiaryAlert'
  title?: string
  onPress?: () => void
  isLoading?: boolean
  icon?: string
  iconPosition?: 'left' | 'right'
  iconVariant?: 'image' | 'svg' | 'svgUrl' | 'material' | 'materialIcons' | 'ionicons'
  widthVariant?: 'fixed' | 'full' | 'content' | 'circle' | 'icon' | 'flex' | 'half'
  disabled?: boolean
  analyticsName?: string
  testID?: string
  hasShadow?: boolean
  textColor?: string
  iconColor?: keyof typeof baseColors
  // temp fix be removed when we rework button styling to remove capitalization,
  // reduce padding and reduce letter spacing
  small?: boolean
  lineColor?: keyof typeof baseColors
  iconSize?: number
}

const OomphButton = ({
  disabled,
  children,
  ...props
}: ButtonProps & { children: React.ReactNode }) => {
  if (disabled) {
    return (
      <StyledPrimaryButton {...props} disabled={disabled} activeOpacity={0.5}>
        {children}
      </StyledPrimaryButton>
    )
  }
  return (
    <StyledButton {...props} disabled={disabled} activeOpacity={0.5}>
      <StyledLinearGradient {...props} start={{ x: 0, y: 0 }} end={{ x: 1, y: 1 }}>
        {children}
      </StyledLinearGradient>
    </StyledButton>
  )
}

const ButtonVariantMapping = {
  primary: StyledPrimaryButton,
  secondary: StyledSecondaryButton,
  tertiary: StyledTertiaryButton,
  success: StyledSuccessButton,
  error: StyledErrorButton,
  oomph: OomphButton,
  tertiaryAlert: StyledTertiaryButton,
}

const Button = ({
  variant = 'primary',
  title,
  onPress,
  isLoading,
  icon,
  iconPosition = 'left',
  iconVariant,
  widthVariant = 'fixed',
  disabled = false,
  textColor,
  iconColor,
  small = false,
  lineColor,
  iconSize,
  ...rest
}: ButtonProps) => {
  const ButtonComponent = ButtonVariantMapping[variant]
  if (variant === 'oomph' && !Config.GRADIENT_PRIMARY_BUTTONS) {
    variant = 'primary'
  }

  const getTextColor = () => {
    if (variant === 'success' || variant === 'error') {
      return 'white'
    }

    if (disabled) {
      if (variant === 'primary' || variant === 'oomph') {
        return 'primaryTextDisabled'
      }
      return 'disabled'
    }

    if (variant === 'oomph') {
      return 'darkText'
    }

    if (variant === 'secondary' || variant === 'tertiary') {
      return 'secondary'
    }

    return 'primaryText'
  }

  const currentTextColor = textColor || getTextColor()

  // Only secondary button has a distinct line color (e.g. 1px solid charcoal)
  const currentLineColor = variant === 'secondary' && lineColor ? lineColor : undefined

  return (
    <ButtonComponent
      onPress={onPress}
      disabled={disabled}
      widthVariant={widthVariant}
      small={small}
      lineColor={currentLineColor}
      {...rest}
    >
      {isLoading ? (
        <StyledSpinner color={currentTextColor} />
      ) : (
        <StyledButtonContent>
          {icon && iconVariant && (iconPosition === 'left' || !iconPosition) && (
            <Icon src={icon} variant={iconVariant} color={currentTextColor} testID="buttonIcon" />
          )}
          {title && (
            <Typography
              variant={small ? 'bodyNormalBold' : 'buttonText'}
              color={currentTextColor}
              align="center"
            >
              {title}
            </Typography>
          )}
          {icon && iconVariant && iconPosition === 'right' && (
            <Icon
              src={icon}
              variant={iconVariant}
              color={iconColor || currentTextColor}
              testID="buttonIcon"
              {...(iconSize && { size: iconSize })}
            />
          )}
        </StyledButtonContent>
      )}
    </ButtonComponent>
  )
}

export default Button
