//used for i18n
export const brandVersionedI18nFiles = require.context('../I18n', true, /\.json$/)

//used for colors
export const brandVersionedThemeJsons = require.context('../Themes/brandVersions', false, /\.json$/)

//used for images
export const brandVersionedThemeFiles = require.context('../Themes/brandVersions', false, /\.js$/)

// used for config
export const brandVersionConfigFiles = require.context('../../', false, /\/config.*\.json$/)
