import React, { useContext, useEffect } from 'react'
import PortalContext from './PortalContext'

interface PortalProps {
  children: React.ReactNode
  name: string
  deactivate?: boolean
}
const Portal: React.FC<PortalProps> = ({ children, name, deactivate }) => {
  const { addComponent, removeComponent } = useContext(PortalContext)
  useEffect(() => {
    if (deactivate) return
    addComponent({ name, component: children })
    return () => {
      removeComponent(name)
    }
  }, [children, name, deactivate])

  return deactivate && children
}
export default Portal
