import * as Sentry from '@sentry/react-native'
import Moment from 'moment'
import Config from 'APP/Config'

import Snowplow from 'APP/Services/Analytics/Snowplow'
import DeviceInfo from 'react-native-device-info'
import { isWeb } from 'APP/Helpers/checkPlatform'
import { logDdError } from 'APP/Lib/Datadog'

export const LAUNCH_CATEGORY = {
  START: 'start launch',
  EXIT: 'exit launch',
}
export const SCROLL_BEHAVIOUR_ACTIONS = {
  SCROLL_OUT_OF_VIEW: 'scroll component out of view',
}

export class _Analytics {
  init() {
    this.schemas = Config.ANALYTICS_SCHEMAS
    this.eventPairing = this.schemas.events
    this.entityPairing = this.schemas.entities
    this.activeEntities = {}
    this.currentId = null
    this.launchStartTime = null

    Snowplow.init()
    if (__DEV__) {
      console.log(`Connecting to analytics (${Config.SNOWPLOW.ENDPOINT})...`)
    }

    this.attachEntity('app_info', {
      brand_id: Config.BRAND_ID,
      app_version: DeviceInfo.getReadableVersion(),
    })

    Snowplow.setGlobalContexts(this.getEntities(['app_info', 'tenant']))
  }

  extractAdditionalProperties = (eventName) => this.eventPairing[eventName]

  getEntities = (entityList) => {
    return Object.entries(this.activeEntities)
      .filter((key) => entityList.includes(key[0]))
      .map((key) => key[1])
  }

  trackEvent = (eventName, props, additionalEntities = []) => {
    const eventInfo = this.extractAdditionalProperties(eventName)

    if (!eventInfo) {
      const error = `Attempting to send an unmapped event ${eventName}`
      if (__DEV__) {
        console.log(error)
      }
      Sentry.captureMessage(error)
      return
    }

    const properties = { ...eventInfo.properties, ...props }

    const trackedEntities = this.getEntities([...(eventInfo.entities || []), ...additionalEntities])

    if (__DEV__) {
      console.log(`Track event: ${eventName}`, properties, trackedEntities)
    }

    Snowplow.trackEvent(eventInfo.schema, properties, trackedEntities)
  }

  identify = (id) => {
    if (id && id !== this.currentId && id !== undefined && id !== 'undefined') {
      if (__DEV__) {
        console.log(`Track identify setSubjectData ${id}`)
      }
      Snowplow.setSubject(id)
      this.currentId = id
    }
  }

  clearIdentify = () => {
    if (__DEV__) {
      console.log(`Track identify setSubjectData - null`)
    }
    Snowplow.setSubject(null)
    this.currentId = null
  }

  trackScreen = (name = 'splash', screenType) => {
    if (__DEV__) {
      console.log(`Track screen ${name}`, Object.values(this.activeEntities))
    }

    if (isWeb()) {
      if (name) {
        this.trackEvent('navigation', {
          screen_name: name,
        })
      }
    } else {
      Snowplow.trackScreen(name, screenType, this.getEntities(['app_info', 'tenant']))
    }
  }

  trackLoadingTime = ({
    category = 'Launch time',
    action = 'Action',
    label,
    property = 'time',
    value = Moment().valueOf(),
  } = {}) => {
    this.trackStructuredEvent(category, action, label, property, value)
    Sentry.addBreadcrumb({
      category: 'Metrics',
      message: `${action}${label ? ' - ' + label : ''}`,
      level: 'info',
    })
    const timeSpan = this.launchTimeDiff(value)
    switch (action) {
      case LAUNCH_CATEGORY.START:
        this.launchStartTime = value
        break
      case LAUNCH_CATEGORY.EXIT:
        if (__DEV__) {
          console.log(`Launch time total span: ${timeSpan}`)
        }
        this.launchStartTime = null
        break
    }
  }

  launchTimeDiff = (endTime) => {
    if (this.launchStartTime && this.launchStartTime > 0) {
      return endTime - this.launchStartTime
    }
    return 0
  }

  trackScrollingBehaviour = ({
    category = 'Scrolling behaviour',
    action = SCROLL_BEHAVIOUR_ACTIONS.SCROLL_OUT_OF_VIEW,
    label,
  } = {}) => {
    this.trackStructuredEvent(category, action, label)
  }

  trackStructuredEvent = (category, action, label, property, value) => {
    if (__DEV__) {
      console.log(
        `Track structured event ${category} - ${action} ${label ? label : ''}`,
        Object.values(this.activeEntities)
      )
    }

    Snowplow.trackStructuredEvent(
      { category, action, label, property, value },
      this.getEntities(['app_info', 'tenant'])
    )
  }

  attachEntity = (entityName, payload = {}) => {
    const exploredEntity = this.entityPairing[entityName]
    if (exploredEntity) {
      this.activeEntities[entityName] = {
        schema: exploredEntity.schema,
        data: { ...exploredEntity.data, ...payload },
      }
    } else {
      const error = `Attempt to attach untracked entity ${entityName}`
      if (__DEV__) {
        console.log(error)
      }
      Sentry.captureException(error)
      logDdError(error, 'Analytics.attachEntity')
    }
    if (__DEV__) {
      console.log(`Tracked entity added ${entityName}`, this.activeEntities)
    }
  }

  detachEntity = (entityName) => {
    if (this.activeEntities[entityName]) {
      delete this.activeEntities[entityName]
    }
    if (__DEV__) {
      console.log(`Entity removed ${entityName}`, this.activeEntities)
    }
  }
}

let Analytics = new _Analytics()

export default Analytics
