import React from 'react'
import { Linking } from 'react-native'
import { useSelector } from 'react-redux'
import Moment from 'moment'
import DeviceInfo from 'react-native-device-info'
import Clipboard from '@react-native-clipboard/clipboard'
import Toast from 'react-native-toast-message'

// Services
import UrlBuilder from 'APP/Services/UrlBuilder'
import I18n from 'APP/Services/i18n'
import Config from 'APP/Config'
import { isWeb } from 'APP/Helpers/checkPlatform'

// Components
import ListItem from 'APP/Converse/ListItem'
import List from 'APP/Components/List'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import DialogueBranding from 'APP/Components/DialogueBranding'

// Styling
import Styles from './style'
import { ResponsiveScrollView } from 'APP/Converse/Layout'
import { selectFamilyMembers } from 'APP/Store/Family/selectors'
import { Member } from 'APP/Store/Family/types'
import { featuresState } from 'APP/Sagas/FeaturesSaga'

interface Dependent {
  givenName: string
  familyName: string
}

const getFamilyChildrenList = (familyMembers: Record<string, Member>) => {
  return Object.values(familyMembers)
    .filter((familyMember) => familyMember.type === 'child')
    .map((child) => {
      return {
        givenName: child?.givenName,
        familyName: child?.familyName,
      }
    })
}

const LegalScreen = ({ navigation }) => {
  const { displayMemberCharter } = useSelector(featuresState)

  const profile = useSelector((state) => state.patient.profile)
  const guardianGivenName = profile?.givenName
  const guardianFamilyName = profile?.familyName
  const consentDate = profile?.consentDate

  const includesRequiredService =
    profile?.eligibleServices &&
    Object.keys(profile?.eligibleServices)?.some(
      (service) => Config.COMMITMENT.requiredServices.indexOf(service) >= 0
    )
  const familyMembers = useSelector(selectFamilyMembers)

  const showPatientCharterUrl = displayMemberCharter && includesRequiredService

  const openURL = (url: string) => {
    Linking.openURL(url).catch((reason) => {
      console.log('openUrl error', url, reason)
    })
  }

  const goToConsentScreen = () => {
    navigation.navigate('consentDocument', { uri: UrlBuilder.consentUrl() })
  }

  const goToCloseAccount = () => {
    navigation.navigate('closeAccount')
  }

  const gotoEmail = () => {
    const customContactUs = Config.CUSTOM_SUPPORT_PAGE
    if (customContactUs) {
      navigation.navigate('contactUs')
    } else {
      const url = UrlBuilder.supportEmailUrl()
      Linking.openURL(url).catch((reason) => {
        console.log('gotoEmail error', url, reason)
      })
    }
  }

  const gotoGuardianConsentScreen = (dependentGivenName: string, dependentFamilyName: string) => {
    navigation.navigate('guardianConsentDocument', {
      uri: UrlBuilder.guardianConsentUrl(
        guardianGivenName,
        guardianFamilyName,
        dependentGivenName,
        dependentFamilyName
      ),
    })
  }

  const renderDependent = (dependent: Dependent, i: number) => {
    const { givenName, familyName } = dependent
    return (
      <ListItem
        key={i}
        title={I18n.t('LegalScreen.guardianConsent')}
        subtitle={`${givenName} ${familyName}`}
        onPress={() => gotoGuardianConsentScreen(givenName, familyName)}
      />
    )
  }

  const displayGuardianConsents = () => {
    const dependentList = getFamilyChildrenList(familyMembers)
    return dependentList.map(renderDependent)
  }

  return (
    <NoticeBarContainer>
      <ResponsiveScrollView alwaysBounceVertical={false}>
        <List containerStyle={Styles.listContainer}>
          {/* DeviceInfo.getVersion() is not available on web */}
          {!isWeb() && (
            <ListItem
              key="appVersion"
              title={I18n.t('LegalScreen.version')}
              subtitle={DeviceInfo.getVersion()}
              hideIconComponent
              onLongPress={() => {
                Clipboard.setString(DeviceInfo.getVersion())
                Toast.show({
                  text1: String(I18n.t('Common.copiedToClipboard')),
                  visibilityTime: 1000,
                })
              }}
            />
          )}
          <ListItem
            key="consent"
            title={I18n.t('LegalScreen.consent')}
            subtitle={Moment(consentDate)
              .format(String(I18n.t('LegalScreen.dateFormat')))
              .toString()}
            onPress={goToConsentScreen}
          />
          {displayGuardianConsents()}
          <ListItem
            key="closeAccount"
            title={I18n.t('LegalScreen.closeAccount')}
            onPress={goToCloseAccount}
          />
          {UrlBuilder.aboutUrl() && (
            <ListItem
              key="about"
              title={I18n.t('LegalScreen.about')}
              onPress={() => openURL(UrlBuilder.aboutUrl())}
              iconName="exit-to-app"
            />
          )}
          <ListItem
            key="privacyPolicy"
            title={I18n.t('LegalScreen.privacy')}
            onPress={() => openURL(UrlBuilder.privacyUrl())}
            iconName="exit-to-app"
          />
          <ListItem
            key="termsOfService"
            title={I18n.t('LegalScreen.terms')}
            onPress={() => openURL(UrlBuilder.termsUrl())}
            iconName="exit-to-app"
          />
          <ListItem
            key="viewSupport"
            title={I18n.t('LegalScreen.viewSupport')}
            onPress={() => openURL(UrlBuilder.helpAndSupportUrl())}
            iconName="exit-to-app"
          />
          <ListItem
            key="emailSupport"
            title={I18n.t('LegalScreen.emailSupport')}
            onPress={gotoEmail}
            iconName={Config.CUSTOM_SUPPORT_PAGE ? 'chevron-right' : 'exit-to-app'}
          />
          {showPatientCharterUrl && (
            <ListItem
              key="patientCharterUrl"
              title={I18n.t('LegalScreen.patientCharter')}
              onPress={() => openURL(UrlBuilder.patientCharterUrl())}
              iconName="exit-to-app"
            />
          )}
        </List>
      </ResponsiveScrollView>
      <DialogueBranding />
    </NoticeBarContainer>
  )
}

export default LegalScreen
