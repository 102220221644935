import React, { Component } from 'react'
import { Text, View, TouchableOpacity } from 'react-native'
import { connect } from 'react-redux'
import I18n from 'APP/Services/i18n'
import Icon from 'react-native-vector-icons/FontAwesome'

// Styling
import Styles from './style'
import { ApplicationStyles, Colors, Metrics } from 'APP/Themes'
import Analytics from 'APP/Services/Analytics'

class NoticeBarContainer extends Component {
  wrappedOnPress = (originalOnPress) => (event) => {
    Analytics.trackEvent('button_click', { button_value: 'Notice pressed' })
    originalOnPress(event)
  }

  renderNoticeItem = (notice) => {
    if (!notice || (typeof notice.title !== 'string' && typeof notice.text !== 'string'))
      return null

    const noticeItemStyle = [
      this.props.float ? Styles.noticeBarFloat : Styles.noticeBarInline,
      this.props.style,
      notice.style,
    ]

    const textStyles = [this.props.centerText && Styles.centerText]

    const noticeItemBody = (
      <View style={Styles.noticeItemStyleInner}>
        {notice.title && <Text style={[Styles.title, ...textStyles]}>{notice.title}</Text>}
        {notice.text && (
          <Text style={[Styles.text, ...textStyles, notice.textStyle]}>{notice.text}</Text>
        )}
      </View>
    )

    return notice.onPress && typeof notice.onPress === 'function' ? (
      <TouchableOpacity
        style={noticeItemStyle}
        onPress={this.wrappedOnPress(notice.onPress)}
        activeOpacity={0.5}
      >
        <View style={Styles.noticePressContainer}>
          {noticeItemBody}
          <Icon
            name={notice.iconName || 'angle-right'}
            size={Metrics.icons.medium}
            color={Colors.accent}
            style={Styles.icon}
          />
        </View>
      </TouchableOpacity>
    ) : (
      <View style={noticeItemStyle}>{noticeItemBody}</View>
    )
  }

  renderNoticeBar = () => {
    let noticeItemStack = []
    // Global offline notice
    if (!this.props.isConnected) {
      noticeItemStack.push({
        text: I18n.t('Navigation.offlineWarning'),
        style: Styles.offlineStyle,
        textStyle: Styles.offlineTextStyle,
      })
    }

    // Local notices, if provided
    if (this.props.localNotices && this.props.localNotices.length) {
      noticeItemStack = noticeItemStack.concat(this.props.localNotices)
    }

    if (noticeItemStack.length) {
      switch (this.props.stackStyle) {
        default:
        case 0:
          // Single item at a time, FIFO
          return this.renderNoticeItem(noticeItemStack[0])
        case 1:
          // All items in notice stack
          return noticeItemStack.map(this.renderNoticeItem)
      }
    } else {
      return null
    }
  }

  render() {
    if (this.props.inline) {
      return <View style={this.props.containerStyle}>{this.renderNoticeBar()}</View>
    }

    return (
      <View
        style={[ApplicationStyles.screen.fullPrimaryContainer, this.props.containerStyle]}
        onLayout={this.props.onLayout}
      >
        {this.props.top && this.renderNoticeBar()}
        <View style={{ flex: 1 }}>{this.props.children}</View>
        {(this.props.float || this.props.bottom || !this.props.top) && this.renderNoticeBar()}
      </View>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isConnected: state.appSession.isConnected,
  }
}

export default connect(mapStateToProps, null)(NoticeBarContainer)
