import React from 'react'
import { View, Text } from 'react-native'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import moment from 'moment'
import I18n from 'APP/Services/i18n'
import { Colors, Metrics } from 'APP/Themes'
import { Constants } from 'APP/Components/ActiveMinutes'
import Styles from './style'

const HistoricalWeeklySummary = ({
  startDate = '',
  endDate = '',
  weeklyGoal = 0,
  weeklyProgress = 0,
  activities = [],
}) => {
  const achieved = weeklyProgress / weeklyGoal >= 1

  const datesAreSameMonth = moment(startDate).format('MMM') === moment(endDate).format('MMM')

  return (
    <View style={Styles.card} testID="historicalWeeklySummary">
      {startDate && endDate && (
        <Text style={Styles.dateRange} testID="dateRange">
          {datesAreSameMonth ? moment(startDate).format('D') : moment(startDate).format('D MMM')}
          &nbsp;-&nbsp;{moment(endDate).format('D MMM')}
        </Text>
      )}
      <View style={Styles.progress}>
        <View style={Styles.current}>
          {achieved && (
            <MaterialIcon
              name="check"
              size={Metrics.icons.small}
              color={Colors.habitDoneAccent}
              style={Styles.checkmark}
            />
          )}
          <Text style={[Styles.weeklyProgress, achieved && Styles.achievedWeeklyProgress]}>
            {weeklyProgress} {I18n.t('RecentActiveHistoryScreen.min')}
          </Text>
        </View>
        <Text style={Styles.weeklyGoal}>
          {weeklyGoal} {I18n.t('RecentActiveHistoryScreen.min')}
        </Text>
      </View>
      <View style={Styles.progressBar}>
        <View
          style={[
            Styles.currentProgress,
            { width: `${(weeklyProgress / weeklyGoal) * 100}%` },
            achieved && Styles.achievedCurrentProgress,
          ]}
        ></View>
      </View>
      {(activities || []).length === 0 && (
        <Text testID="noActivities" style={Styles.noActivities}>
          {I18n.t('RecentActiveHistoryScreen.emptyStates.noActivitiesForWeek')}
        </Text>
      )}
      {activities?.map(({ activity, minutes }) => {
        const Icon = Constants.ACTIVITY_ICON[activity]

        return (
          <View testID="activity" key={`activity-${activity}-${minutes}`} style={Styles.activity}>
            <View style={Styles.activityTypeGroup}>
              <Icon.Icon
                name={Icon.name}
                size={Metrics.icons.small}
                color={Colors.text}
                style={Styles.activityIcon}
              />
              <Text style={Styles.activityType}>
                {I18n.t(`RecentActiveHistoryScreen.activity.${activity}`)}
              </Text>
            </View>
            <Text style={Styles.activityDuration}>
              {minutes} {I18n.t('RecentActiveHistoryScreen.min')}
            </Text>
          </View>
        )
      })}
    </View>
  )
}

export default HistoricalWeeklySummary
