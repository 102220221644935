import React from 'react'

// eslint-disable-next-line no-restricted-imports
import { Text, View, TouchableOpacity, Image } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import Tag from 'APP/Components/Tag'
import Analytics from 'APP/Services/Analytics'

import Styles from './style'
import { getServiceCategoryIcon } from 'APP/Lib/CtaInfoHelpers'
import { useSelector } from 'react-redux'
import { normalizeServiceCategory } from 'APP/Lib/ContentHelpers'
import { getImageAspectRatio } from 'APP/Lib/StylingHelpers'
import styled from 'styled-components/native'
import sprigClient from 'APP/Services/Sprig'
import { bigExternalLogos } from 'APP/Themes/Images'

const ServiceCategoryCard = styled(TouchableOpacity)`
  border-radius: ${({ theme }) => theme.metrics.baseMargin}px;
  padding: ${({ theme }) => theme.metrics.baseMargin}px;
  background-color: ${({ theme }) => theme.colors.elementsBg};
  flex-basis: ${({ theme }) => (theme.isSmallScreen ? 'auto' : '46%')};
  width: 100%;
  ${({ theme }) => theme.shadows.normal};
`

const WIDE_ASPECT_RATIO = 1.5

const ServiceCategoryTile = ({ name, index }) => {
  const navigation = useNavigation()
  const { serviceCategories } = useSelector((state) => state.content)
  const foundService = serviceCategories?.find((service) => service.name === name)
  const serviceCategory = foundService && normalizeServiceCategory(foundService)

  const handlePress = () => {
    Analytics.trackEvent(
      'button_click',
      {
        button_text: 'service_category',
        button_value: serviceCategory.name,
      },
      ['service_category']
    )
    sprigClient.track(`TILE_CLICKED_${serviceCategory.name}`)
    navigation.navigate('serviceCategory', { name })
  }

  const Icon = getServiceCategoryIcon(serviceCategory?.iconName)
  const iconAspectRatio = getImageAspectRatio(Icon)
  const isWideIcon = iconAspectRatio > WIDE_ASPECT_RATIO

  const isBigLogo = bigExternalLogos.includes(serviceCategory?.iconName)

  return (
    <ServiceCategoryCard key={index} onPress={handlePress} testID="CATEGORY_TILE">
      {serviceCategory?.tag && (
        <View style={Styles.tagWrapper}>
          <Tag style={Styles.tag} copy={serviceCategory.tag} />
        </View>
      )}
      <View style={Styles.imageWrapper}>
        {Icon && (
          <Image
            style={isBigLogo ? [Styles.bigLogo] : [Styles.image, isWideIcon && Styles.wideImage]}
            source={Icon}
          />
        )}
      </View>
      <View>
        <Text style={Styles.title}>{serviceCategory.title}</Text>

        <Text style={Styles.subtitle}>{serviceCategory.shortSubtitle}</Text>
      </View>
    </ServiceCategoryCard>
  )
}

export default ServiceCategoryTile
