import { call, put, select } from 'redux-saga/effects'
import { RESULTS as PermissionState } from 'react-native-permissions'

import { navigationRef as Nav } from 'APP/Nav'
import Engage from 'APP/Services/Engage'
import { checkPostNotificationsState } from 'APP/NativeModules/PermissionsService'

import NotificationActions from 'APP/Redux/NotificationRedux'
import { isMobile, isWeb } from 'APP/Helpers/checkPlatform'
import _ from 'lodash'

export const loginState = (state) => state.login
export const patientState = (state) => state.patient
export const featuresState = (state) => state.features

export function* fetchNotificationPreferences() {
  yield put(NotificationActions.getNotificationPreferences())
  const login = yield select(loginState)
  const features = yield select(featuresState)
  const patient = yield select(patientState)
  const engage = Engage.create(login)

  let preferences = {}
  try {
    // On the new notification center, we display all notifications on both platforms
    if (features?.newNotificationCenter) {
      const pushPreferences = yield call(engage.getPushPreferences, patient.profile.id)
      preferences = _.merge(preferences, pushPreferences)
    } else if (isMobile()) {
      // FYI, this endpoint will be deprecated. If it does, we can use `engage.getPushPreferences` instead
      const pushPreferences = yield call(engage.getPreferences)
      preferences = _.merge(preferences, pushPreferences)
    }

    yield put(NotificationActions.getNotificationPreferencesSuccess(preferences))
  } catch (err) {
    yield put(NotificationActions.getNotificationPreferencesFailure())
  }

  if (features?.enableSMSAppointmentReminders) {
    try {
      const smsPreferences = yield call(
        engage.getSMSPreferences,
        patient.profile.id,
        features?.newNotificationCenter
      )
      if (features?.newNotificationCenter) {
        preferences = _.merge(preferences, smsPreferences)
      } else {
        preferences = _.assign(
          { appointmentReminders: smsPreferences?.appointmentReminders },
          preferences
        )
      }
      yield put(NotificationActions.getNotificationPreferencesSuccess(preferences))
    } catch (err) {
      yield put(NotificationActions.getNotificationPreferencesFailure())
    }
  }
}

export function* updateNotificationPreference(preferences) {
  yield put(NotificationActions.getNotificationPreferences())
  const login = yield select(loginState)
  const engage = Engage.create(login)
  const data = preferences?.data
  const patient = yield select(patientState)
  const features = yield select(featuresState)

  try {
    if (features?.newNotificationCenter) {
      yield call(engage.newUpdatePreferences, patient.profile.id, data)
    } else {
      const isAppointmentReminder = _.has(data, 'appointment_reminders')

      if (isAppointmentReminder) {
        const payload = {
          sms: {
            ...data,
          },
        }
        yield call(engage.newUpdatePreferences, patient.profile.id, payload)
      } else {
        yield call(engage.updatePreferences, data)
      }
    }

    yield call(fetchNotificationPreferences)
  } catch (err) {
    yield put(NotificationActions.getNotificationPreferencesFailure())
  }
}

export function* triggerNotificationPermissionModal() {
  // Only check on mobile
  if (isWeb()) return

  const permissionState = yield call(checkPostNotificationsState)

  if (permissionState === PermissionState.DENIED || permissionState === PermissionState.BLOCKED) {
    yield call(Nav.navigate, 'pushNotificationPermissionModal')
  }
}
