import React from 'react'
import styled from 'styled-components/native'
import Icon from 'react-native-vector-icons/MaterialIcons'
import { View } from 'react-native'
import Card from 'APP/Converse/Card'

import Typography from 'APP/Converse/Typography'
import { Colors, Fonts } from 'APP/Themes'

const CardContainer = styled(Card)`
  border-radius: 16px;
`

const InnerContainer = styled(View)`
  min-height: 60px;
  flex-direction: row;
  gap: ${({ theme }) => theme.metrics.baseMargin / 2}px;
`

const LeftContainer = styled(View)`
  flex-direction: row;
  flex: 1;
  align-self: center;
  gap: ${({ theme }) => theme.metrics.baseMargin / 2}px;
`

const TextContainer = styled(View)`
  flex-direction: column;
  flex: 1;
  gap: ${({ theme }) => theme.metrics.baseMargin / 2}px;
`

const TagContainer = styled(View)`
  position: absolute;
  right: 0;
  top: 0;
  padding-top: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  padding-bottom: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  padding-left: ${({ theme }) => theme.metrics.baseMargin * 0.75}px;
  padding-right: ${({ theme }) => theme.metrics.baseMargin * 0.75}px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 16px;
  background-color: ${({ theme }) => theme.colors.tagBg};
`

const TagTypography = styled(Typography)`
  text-transform: uppercase;
`

const RightIcon = styled(Icon)`
  align-self: center;
`

interface ServiceButtonCardProps {
  description?: string
  iconTitle?: React.ReactNode
  title?: string
  onPress?: () => void
  disabled?: boolean
  testID?: string
  tag?: string
  external?: boolean
}

function ServiceButtonCard({
  description,
  iconTitle,
  title,
  onPress,
  disabled,
  testID,
  tag,
  external = false,
}: ServiceButtonCardProps) {
  return (
    <CardContainer testID={testID || 'BUTTON_CARD'} disabled={disabled} onPress={onPress}>
      <InnerContainer>
        <LeftContainer>
          {iconTitle}
          <TextContainer>
            {title && <Typography variant="h4">{title}</Typography>}
            {description && <Typography variant="bodyNormal">{description}</Typography>}
          </TextContainer>
        </LeftContainer>
        <RightIcon
          name={external ? 'exit-to-app' : 'chevron-right'}
          size={Fonts.size.h2}
          color={Colors.accent}
          testID="BUTTON_CARD_RIGHT_ICON"
        />
      </InnerContainer>
      {tag && (
        <TagContainer>
          <TagTypography variant="bodySmallBold">{tag}</TagTypography>
        </TagContainer>
      )}
    </CardContainer>
  )
}

export default ServiceButtonCard
