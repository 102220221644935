import React, { useState } from 'react'
import { View, TouchableOpacity, Text, Modal } from 'react-native'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import I18n from 'APP/Services/i18n'
import { SliderComponent } from 'APP/Converse/Slider'
import { PrimaryButton, TertiaryButton } from 'APP/Components/Buttons'

import Styles from './style'
import { Colors, Metrics } from 'APP/Themes'
import { ResponsiveScrollView, ResponsiveView } from 'APP/Converse/Layout'

const PAGES = {
  LIBRARY_FILTERS: 'LIBRARY_FILTERS',
  LANGUAGE_FILTERS: 'LANGUAGE_FILTERS',
}

const Filters = ({ visible, onClose, languageFilters, libraryFilters, fetching }) => {
  const [page, setPage] = useState(PAGES.LIBRARY_FILTERS)
  const filters = {
    [PAGES.LIBRARY_FILTERS]: libraryFilters,
    [PAGES.LANGUAGE_FILTERS]: languageFilters,
  }
  const activeFilter = filters[page]
  const handleFinalUserInteraction = () => {
    if (page === PAGES.LANGUAGE_FILTERS) {
      setPage(PAGES.LIBRARY_FILTERS)
    } else {
      onClose()
    }
  }
  const handleCancel = () => {
    activeFilter.discardFilterChanges()
    handleFinalUserInteraction()
  }
  const handleClear = () => {
    activeFilter.clearFilters()
    handleFinalUserInteraction()
  }
  const handleApply = () => {
    activeFilter.applyFilters()
    handleFinalUserInteraction()
  }

  const applyCtaCopy = () => {
    if (page === PAGES.LANGUAGE_FILTERS) return I18n.t('WellnessCenter.library.filters.save')
    if (activeFilter.itemsCount === undefined)
      return I18n.t('WellnessCenter.library.filters.showAll')

    if (activeFilter.itemsCount === 1) return I18n.t('WellnessCenter.library.filters.showItem')
    if (activeFilter.itemsCount > 1)
      return I18n.t('WellnessCenter.library.filters.showItems', {
        count: activeFilter.itemsCount,
      })

    return I18n.t('WellnessCenter.library.filters.noItems')
  }

  // If we are on the language filter page, the save button should be clickable after the fetch
  // is finished and at least one language is selected.
  // Otherwise, it should be only clickable if there are 1 or more items available
  const isSaveButtonDisabled =
    page === PAGES.LANGUAGE_FILTERS
      ? !activeFilter?.options?.[0]?.checked.length > 0 || fetching
      : activeFilter.itemsCount === 0

  return (
    <Modal
      statusBarTranslucent
      animationType="slide"
      visible={visible}
      onRequestClose={onClose}
      testID="filtersModal"
      transparent
    >
      <View style={Styles.modal}>
        <ResponsiveScrollView showsVerticalScrollIndicator={false}>
          <View style={Styles.topOptions}>
            <TouchableOpacity testID="cancel" onPress={handleCancel} style={Styles.textCta}>
              <Text style={Styles.textCtaCopy}>
                {I18n.t('WellnessCenter.library.filters.cancel')}
              </Text>
            </TouchableOpacity>
            {page === PAGES.LIBRARY_FILTERS && (
              <TouchableOpacity onPress={handleClear} style={Styles.textCta} testID="clearFilters">
                <Text style={Styles.textCtaCopy}>
                  {I18n.t('WellnessCenter.library.filters.clear')}
                  {activeFilter.appliedFiltersCount ? ` (${activeFilter.appliedFiltersCount})` : ''}
                </Text>
              </TouchableOpacity>
            )}
          </View>
          <View style={Styles.content}>
            {activeFilter.options.map((option, i) => (
              <View key={option.title} style={Styles.filterGroup}>
                <Text style={Styles.filterTitle}>{option.title}</Text>
                {option.range && (
                  <View style={Styles.slider}>
                    <SliderComponent
                      value={option.selectedRange}
                      minValue={option.range[0]}
                      maxValue={option.range[1]}
                      onChangeFinish={(values) => activeFilter.handleFilterSlide(values, i)}
                    />
                    <Text style={Styles.sliderRange}>
                      {option.selectedRange[0]} -{' '}
                      {option.selectedRange[1] === option.range[1]
                        ? `${option.selectedRange[1]}+`
                        : option.selectedRange[1]}{' '}
                      {I18n.t('WellnessCenter.library.filters.length.minutes')}
                    </Text>
                  </View>
                )}

                {!option.range &&
                  Object.entries(option.filters).map(([key, copy]) => (
                    <TouchableOpacity
                      key={key}
                      onPress={() => activeFilter.handleFilterPress(key, i)}
                      style={Styles.filter}
                      testID={`filter-${key}`}
                    >
                      <Text style={Styles.filterCopy}>{copy}</Text>
                      {option.checked.includes(key) && (
                        <MaterialIcon
                          name="done"
                          size={Metrics.icons.small}
                          color={Colors.accent}
                          style={Styles.filterIcon}
                        />
                      )}
                    </TouchableOpacity>
                  ))}
              </View>
            ))}
          </View>
        </ResponsiveScrollView>
        <ResponsiveView style={Styles.footer}>
          <PrimaryButton
            style={Styles.footerCta}
            title={applyCtaCopy()}
            onPress={handleApply}
            showActivityIndicator={
              page === PAGES.LANGUAGE_FILTERS ? fetching : activeFilter.processing
            }
            activityIndicatorColor={Colors.textInverted}
            disabled={isSaveButtonDisabled}
          />
          {page === PAGES.LIBRARY_FILTERS && (
            <TertiaryButton
              style={Styles.footerCta}
              title={I18n.t('WellnessCenter.library.filters.langSettings')}
              onPress={() => setPage(PAGES.LANGUAGE_FILTERS)}
            />
          )}
        </ResponsiveView>
      </View>
    </Modal>
  )
}

export default Filters
