import React from 'react'
import { StyledLinearGradient, StyledModal, StyledModalWrapper, StyledOomphModal } from './style'

const OomphModal = ({ children }) => {
  return (
    <StyledOomphModal>
      <StyledLinearGradient start={{ x: 1, y: 0 }} end={{ x: 0, y: 1 }}>
        {children}
      </StyledLinearGradient>
    </StyledOomphModal>
  )
}

const Modal = ({ children, variant }) => {
  return (
    <StyledModalWrapper>
      {variant === 'oomph' ? (
        <OomphModal>{children}</OomphModal>
      ) : (
        <StyledModal>{children}</StyledModal>
      )}
    </StyledModalWrapper>
  )
}

export default Modal
