import React from 'react'
import { View } from 'react-native'
import Modal from 'react-native-modal'

import { ResponsiveScrollView } from 'APP/Converse/Layout'
import Button from 'APP/Converse/Button'
import Typography from 'APP/Converse/Typography'

import I18n from 'APP/Services/i18n'
import { Images } from 'APP/Themes'
import Styles from './style'

interface QRCodeAppModalProps {
  isVisible: boolean
  onClose: () => void
}

export const QRCodeAppModal = ({ isVisible, onClose }: QRCodeAppModalProps) => {
  return (
    <Modal
      statusBarTranslucent
      isVisible={isVisible}
      animationIn="zoomIn"
      animationOut="zoomOut"
      testID="QRCodeAppModal"
    >
      <View style={Styles.modal}>
        <View style={Styles.header}>
          <Button
            variant="tertiary"
            analyticsName="WelcomeScreen.downloadApp"
            icon="close"
            iconPosition="right"
            iconVariant="material"
            widthVariant="circle"
            onPress={onClose}
            testID="QRCodeAppModalCloseButton"
          />
        </View>
        <ResponsiveScrollView
          contentContainerInnerStyle={Styles.innerContent}
        >
          <Typography variant="h1" style={Styles.title}>
            {I18n.t('QRCodeAppModal.title')}
          </Typography>
          <Typography variant='bodyNormal'>
            {I18n.t('QRCodeAppModal.subtitle')}
          </Typography>

          <Images.qrCodeAppStore width={200} height={200} />
        </ResponsiveScrollView>
      </View>
    </Modal>
  )
}
