import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import Config from 'APP/Config'
import { LD_FEATURES, LD_FEATURES_TYPE } from 'APP/Helpers/ldFeatures'

interface FeatureFlagsOverrides {
  [flagName: string]: boolean
}

const OVERRIDES: FeatureFlagsOverrides =
  (Config.FEATURE_OVERRIDES &&
    Config.FEATURE_OVERRIDES.enabled &&
    Config.FEATURE_OVERRIDES.values) ||
  {}

// These features would be tied to LD bool flags
// with real time updates.

const initialFlags = {
  ...(LD_FEATURES.reduce(
    (ldFeatures, { name, defaultValue }) => ({
      ...ldFeatures,
      [name]: defaultValue,
    }),
    {}
  ) as LD_FEATURES_TYPE),
  ...OVERRIDES,
}

const initialState = {
  ...initialFlags,
  ldFlags: {},
  overrides: {},
}

const { actions, reducer } = createSlice({
  name: 'features',
  initialState,
  reducers: {
    setFeatures: (state, action: PayloadAction<LD_FEATURES_TYPE>) => ({
      ...state,
      ...action.payload,
      ...OVERRIDES,
    }),
    setLdFlags(state, action: PayloadAction<LD_FEATURES_TYPE>) {
      state.ldFlags = action.payload
    },
    setOverrides: (state, action: PayloadAction<LD_FEATURES_TYPE>) => ({
      ...state,
      ...action.payload,
      overrides: {
        ...action.payload,
      },
    }),
    resetFeatures: (state) => ({
      ...state,
      ...state.ldFlags,
      overrides: {},
    }),
  },
})

export { actions as featuresActions, initialState }
export default reducer
