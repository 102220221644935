import React, { useEffect } from 'react'
import { Text, View } from 'react-native'

// services
import Analytics from 'APP/Services/Analytics'

// Components
import Button from 'APP/Converse/Button'

// Styling
import Styles from './InactiveInfoCard/style'

const InfoCard = ({
  analyticsName,
  buttonText,
  onButtonPressed,
  buttonAnalyticsName,
  busy,
  style,
  icon,
  title,
  subtitle,
  customSubtitle,
  linkText,
  linkCta,
  onLinkPressed,
  testId,
}) => {
  const hasLinkCta = linkText && linkCta && onLinkPressed
  const updateBannerContext = () => {
    if (analyticsName) {
      const bannerPayload = { banner_name: analyticsName }
      Analytics.attachEntity('banner_context', bannerPayload)
    } else {
      Analytics.detachEntity('banner_context')
    }
  }

  useEffect(() => {
    updateBannerContext()
  }, [])

  const renderLinkCta = () => {
    return (
      <View style={Styles.bodyContainer}>
        <Text style={Styles.bodyAction}>
          {linkText}
          <Text style={Styles.link} onPress={() => onLinkPressed()}>
            {linkCta}
          </Text>
        </Text>
      </View>
    )
  }

  return (
    <View style={[Styles.container, style]} testID={testId}>
      {icon}
      <View testID="content" style={Styles.content}>
        <Text style={Styles.title}>{title}</Text>
        {subtitle && <Text style={Styles.topSubtitle}>{subtitle}</Text>}
        {customSubtitle}
        {!!buttonText && (
          <Button
            variant="oomph"
            title={buttonText}
            onPress={onButtonPressed}
            analyticsName={buttonAnalyticsName}
            isLoading={busy}
          />
        )}
      </View>
      {hasLinkCta && renderLinkCta()}
    </View>
  )
}

export default InfoCard
