import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { View, ScrollView, TouchableHighlight } from 'react-native'
import Icon from 'react-native-vector-icons/Ionicons'

// Utils
import { useOnLayout, useScrollToTop } from 'APP/Hooks/Layout'
import Analytics from 'APP/Services/Analytics'
import I18n from 'APP/Services/i18n'
import { isMobile, isWeb } from 'APP/Helpers/checkPlatform'

// Styles
import { Colors } from 'APP/Themes'
import { Styles } from './style'

// Components
import Button from 'APP/Converse/Button'
import Picker from 'APP/Converse/Inputs/Picker'
import Typography from 'APP/Converse/Typography'

const IntakeSingleChoice = ({
  choices,
  choice,
  sendAnswer,
  disabled,
  member_app_fullscreen,
  choices_display_variant,
  isMultiPrompt = false,
}) => {
  const ref = useScrollToTop([choices])
  const [{ height: containerHeight }, onScrollLayout] = useOnLayout()
  const [yOffset, setYOffset] = useState(0)
  const [choiceSelectedIndex, setChoiceSelectedIndex] = useState()
  const [contentHeight, setContentHeight] = useState()
  const [overflowing, setOverflowing] = useState(false)
  const onContentSizeChange = useCallback(
    (width, height) => setContentHeight(height),
    [setContentHeight]
  )
  const nearTop = yOffset <= 20
  const nearBottom = yOffset >= contentHeight - containerHeight - 40
  useEffect(() => {
    // shcedule a cancelable update
    // 'cause state values don't get updated at the same time
    // in one render cycle
    // Smelly
    const id = setTimeout(() => {
      if (contentHeight && containerHeight) {
        setOverflowing(
          // Smelly
          isMobile() ? contentHeight > containerHeight : contentHeight >= containerHeight
        )
      }
    }, 0)
    return () => clearTimeout(id)
  }, [containerHeight, contentHeight, setOverflowing])

  useEffect(() => {
    const choiceId = choice?.props?.selected_choice?.id
    if (choiceId && choiceSelectedIndex === undefined) {
      const index = choices.findIndex((el) => el.id === choiceId)
      setChoiceSelectedIndex(index)
    }
  }, [choice, choices, choiceSelectedIndex])

  const getOnPress = (choice, index) => () => {
    Analytics.trackEvent('button_click', { button_value: 'Single choice selected' })
    sendAnswer(choice)
    setChoiceSelectedIndex(index)
  }

  const getOnSelect = (choice) => {
    Analytics.trackEvent('button_click', { button_value: 'Single choice selected from dropdown' })
    sendAnswer(choice)
  }

  const styleOfSingleChoiceView = useMemo(() => {
    if (member_app_fullscreen?.layout_style === 'skip_button') {
      return [
        Styles.singleChoiceSkipButton,
        overflowing && Styles.singleChoiceSkipButtonOverflowing,
      ]
    } else if (choices_display_variant === 'horizontal') {
      return [Styles.singleChoiceHorizontal, overflowing && Styles.singleChoiceOverflowing]
    } else if (choices_display_variant === 'dropdown') {
      return [Styles.singleChoiceDropdown, overflowing && Styles.singleChoiceOverflowing]
    } else {
      return [Styles.singleChoice, overflowing && Styles.singleChoiceOverflowing]
    }
  }, [member_app_fullscreen, choices_display_variant, overflowing])

  const renderChoice = useCallback(() => {
    if (choices?.length === 1) {
      return (
        <Button
          title={choices[0].display}
          disabled={disabled}
          onPress={getOnPress(choices[0])}
          widthVariant="full"
        />
      )
    }
    if (choices?.length === 2 && member_app_fullscreen?.layout_style === 'skip_button') {
      return (
        <View style={Styles.singleChoiceOptionSkipButton}>
          <Button
            title={choices[0].display}
            disabled={disabled}
            onPress={getOnPress(choices[0])}
            widthVariant="full"
          />
          <Button
            variant="tertiary"
            title={choices[1].display}
            disabled={disabled}
            onPress={getOnPress(choices[1])}
            widthVariant="full"
          />
        </View>
      )
    }
    if (choices_display_variant === 'horizontal') {
      return choices.map((choice, i, array) => (
        <View key={i} style={Styles.singleChoiceHorizontalContainer}>
          <TouchableHighlight
            underlayColor={Colors.multiSelectMsgBg}
            disabled={disabled}
            style={[
              Styles.singleChoiceHorizontalOption,
              overflowing && Styles.singleChoiceHorizontalOptionOverflowing,
              i === 0 && Styles.firstSingleChoiceHorizontalOption,
              i === array.length - 1 && Styles.lastSingleChoiceHorizontalOption,
              i === choiceSelectedIndex && Styles.singleChoiceOptionSelected,
            ]}
            onPress={getOnPress(choice, i)}
          >
            <Typography variant="bodyNormal" align="center">
              {choice.display}
            </Typography>
          </TouchableHighlight>
        </View>
      ))
    }
    if (choices_display_variant === 'dropdown') {
      const options = choices.map(({ id, display }) => ({
        key: id,
        label: display,
        value: id,
      }))
      return (
        <View>
          <Picker
            value={choice?.props?.selected_choice?.id}
            items={options}
            onValueChange={(_, i) => getOnSelect(choices[i - 1])}
            placeholder={I18n.t('IntakeComponent.dropdownPlaceholder')}
          />
        </View>
      )
    }
    return choices.map((choice, i, array) => (
      <TouchableHighlight
        key={i}
        underlayColor={Colors.multiSelectMsgBg}
        disabled={disabled}
        style={[
          Styles.singleChoiceOption,
          overflowing && Styles.singleChoiceOptionOverflowing,
          i === 0 && Styles.firstSingleChoiceOption,
          i === array.length - 1 && Styles.lastSingleChoiceOption,
          i === choiceSelectedIndex && Styles.singleChoiceOptionSelected,
        ]}
        onPress={getOnPress(choice, i)}
      >
        <Typography variant="bodyNormal" align={isMultiPrompt ? 'center' : 'right'}>
          {choice.display}
        </Typography>
      </TouchableHighlight>
    ))
  }, [
    choices,
    disabled,
    overflowing,
    member_app_fullscreen,
    choiceSelectedIndex,
    choices_display_variant,
    isMultiPrompt,
    getOnPress,
  ])

  const renderDescription = useCallback(() => {
    if (choices_display_variant === 'horizontal' && choices.some((choice) => choice.description)) {
      const numDescriptions = choices.filter((choice) => choice.description).length
      return choices.map((choice, i) => (
        <View
          key={i}
          style={[
            Styles.singleSubtextContainer,
            { flex: numDescriptions === 2 ? 1.5 : choice.description ? 3 : 1 },
          ]}
        >
          <Typography
            variant="bodySmall"
            color="secondary"
            style={Styles.horizontalOptionSubtext}
            textAlign="center"
          >
            {choice.description}
          </Typography>
        </View>
      ))
    }
    return null
  }, [
    choices,
    disabled,
    overflowing,
    member_app_fullscreen,
    getOnPress,
    choiceSelectedIndex,
    choices_display_variant,
  ])

  return (
    <View
      style={[Styles.singleChoiceContainer, overflowing && Styles.singleChoiceContainerOverflowing]}
    >
      {overflowing && !nearTop && (
        <Icon
          name="arrow-up"
          color={Colors.overlay}
          size={25}
          pointerEvents="none"
          style={[Styles.scrollArrow, { top: 0 }]}
        />
      )}
      {overflowing && !nearBottom && (
        <Icon
          name="arrow-down"
          color={Colors.overlay}
          size={25}
          pointerEvents="none"
          style={[Styles.scrollArrow, { bottom: 0 }]}
        />
      )}
      <ScrollView
        ref={ref}
        onLayout={onScrollLayout}
        onScroll={isWeb() ? ({ nativeEvent }) => setYOffset(nativeEvent.contentOffset.y) : null}
        onScrollEndDrag={({ nativeEvent }) => setYOffset(nativeEvent.contentOffset.y)}
        onMomentumScrollEnd={({ nativeEvent }) => setYOffset(nativeEvent.contentOffset.y)}
        onContentSizeChange={onContentSizeChange}
        alwaysBounceVertical={false}
        scrollIndicatorInsets={{ right: 1, top: 10, bottom: 10 }}
        style={[Styles.singleChoiceScrollView]}
      >
        <View style={styleOfSingleChoiceView}>{renderChoice()}</View>
        {choices_display_variant === 'horizontal' && (
          <View style={Styles.horizontalSubtextContainer}>{renderDescription()}</View>
        )}
      </ScrollView>
    </View>
  )
}

export default IntakeSingleChoice
