import React, { Component } from 'react'
import { TouchableOpacity, Text, View, Animated } from 'react-native'
import { connect } from 'react-redux'
import moment from 'moment'
import PushNotification from 'react-native-push-notification'
import * as Sentry from '@sentry/react-native'

import Modal from 'APP/Components/Modal'
import Checkbox from 'APP/Converse/Inputs/Checkbox'

// Actions
import StartupActions from 'APP/Redux/StartupRedux'
import PatientActions from 'APP/Redux/PatientRedux'
import { featuresActions } from 'APP/Redux/FeaturesSlice'

// Styling
import Styles from './style'

// Services
import {
  disableRequests as extraPropsSourceRequestsDisabled,
  toggleDisableExtraPropsSourceRequests,
} from 'APP/Services/ExtraPropsSourceService'
import { instances as MattermostSocketInstances } from 'APP/Services/Mattermost/MattermostSocket'
import { ScrollView } from 'react-native-gesture-handler'
import { navigationRef as Nav } from 'APP/Nav'

export const DebugButton = ({ onPress, style, inline = false }) => (
  <TouchableOpacity
    style={[style, Styles.fab, inline && Styles.fabInline]}
    onPress={onPress}
    testID={'SPIDER'}
  >
    <Text>🕷</Text>
  </TouchableOpacity>
)

const ToolButton = ({ title, onPress, disabled }) => (
  <TouchableOpacity
    style={Styles.button}
    onPress={onPress}
    disabled={disabled}
    testID={`TOOL-BUTTON-${title}`}
  >
    <Text style={disabled ? Styles.buttonTextDisabled : Styles.buttonText}>{title}</Text>
  </TouchableOpacity>
)

const InfoTextBoolean = ({ isTrueText, isFalseText, isTrue }) => (
  <View style={isTrue ? Styles.infoTextItemTrue : Styles.infoTextItemFalse}>
    <Text style={Styles.infoText}>{isTrue ? isTrueText : isFalseText}</Text>
  </View>
)

const flashValue = (animation) => {
  Animated.sequence([
    Animated.timing(animation, {
      duration: 0,
      toValue: 0.5,
      useNativeDriver: false,
    }),
    Animated.timing(animation, {
      delay: 150,
      duration: 1000,
      toValue: 1,
      useNativeDriver: false,
    }),
  ]).start()
}

class InfoTextValue extends Component {
  state = {
    opacity: new Animated.Value(1),
    color: 'grey',
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.value !== state.value && state.value) {
      flashValue(state.opacity)
    }
    return {
      ...state,
      color: nextProps.color || state.color,
      value: nextProps.value,
    }
  }

  render() {
    return (
      <Animated.View
        style={[
          Styles.infoTextItem,
          { opacity: this.state.opacity, backgroundColor: this.state.color },
        ]}
      >
        <Text style={Styles.infoText}>{this.state.value}</Text>
      </Animated.View>
    )
  }
}

class DebugTools extends Component {
  state = {
    features: {},
    showFeaturesForm: false,
  }

  toggleToolBox = () => {
    this.setState({
      showTools: !this.state.showTools,
    })
  }

  openNetworkLogger = () => {
    Nav.navigate('networkLogger')
    this.toggleToolBox()
  }

  openDeviceLogsScreen = () => {
    Nav.navigate('deviceLogsScreen')
    this.toggleToolBox()
  }

  openFeaturesForm = () => {
    this.setState({
      showFeaturesForm: true,
    })
  }

  openScheduledLocalNotifications = () => {
    Nav.navigate('scheduledLocalNotificationList')
    this.toggleToolBox()
  }

  closeFeaturesForm = () => {
    this.setState({
      showFeaturesForm: false,
      features: {},
    })
  }

  getFeatureChangeHandler = (label) => () =>
    this.setState(({ features }) => ({
      features: {
        ...features,
        [label]: !features[label],
      },
    }))

  setOverrides = () => {
    this.props.setOverrides(this.state.features)
    this.closeFeaturesForm()
  }

  resetFeatures = () => {
    this.props.resetFeatures()
    this.closeFeaturesForm()
  }

  getFeatureStatus = (feature) =>
    (
      this.state.features[feature] === undefined
        ? this.props.features[feature]
        : this.state.features[feature]
    )
      ? 'checked'
      : 'unchecked'

  chatSocketKill = () => {
    Object.values(MattermostSocketInstances).forEach((mmSocket) =>
      mmSocket.testUtils.chatSocketKill()
    )
  }

  chatSocketKillUntilReload = () => {
    Object.values(MattermostSocketInstances).forEach((mmSocket) =>
      mmSocket.testUtils.chatSocketKillUntilReload()
    )
  }

  chatSocketRestore = () => {
    Object.values(MattermostSocketInstances).forEach((mmSocket) =>
      mmSocket.testUtils.chatSocketRestore()
    )
  }

  toggleDisableExtraPropsSourceRequests = () => {
    toggleDisableExtraPropsSourceRequests()
    this.forceUpdate()
  }

  getTimeFormated = (time) => moment(time).format('HH:mm:ss')

  render() {
    const features = Object.assign({}, this.props.features)
    delete features.ldFlags
    delete features.overrides

    return this.props.showDebugFab ? (
      <View style={Styles.container}>
        {this.state.showTools && (
          <View style={Styles.toolsContainer} testID={'DEBUG_TOOLS_CONTAINER'}>
            <View style={Styles.infoTextContainer}>
              {Nav?.getCurrentRoute()?.name === 'conversation' && [
                <InfoTextValue key="1" value={`Channel ${this.props.currentChannelId}`} />,
                <InfoTextValue
                  key="2"
                  value={`${this.props.currentChannelTotalRawMsgCount} total messages since channel fetch`}
                />,
                <InfoTextValue
                  key="3"
                  value={`${this.props.currentChannelMsgCount} patient visible messages loaded`}
                />,
                <InfoTextValue
                  key="4"
                  value={`Last post at ${this.getTimeFormated(this.props.lastPostAt)}`}
                />,
                <InfoTextBoolean
                  key="5"
                  isTrue={this.props.channelFetchingPosts}
                  isTrueText="Fetching posts"
                  isFalseText="Not Fetching posts"
                />,
                <InfoTextValue
                  key="6"
                  color={this.props.currentChannelIsActive ? 'green' : 'orange'}
                  value={`Episode is ${this.props.currentChannelIsActive ? 'active' : 'inactive'}`}
                />,
              ]}
              <InfoTextBoolean
                isTrue={!this.props.mmSocketNotReady}
                isTrueText="Chat Socket Ready"
                isFalseText="Chat Socket Not Ready"
              />
            </View>
            <ToolButton
              title="Kill Chat Socket"
              onPress={this.chatSocketKill}
              disabled={this.props.mmSocketNotReady || !this.props.accessToken}
            />
            <ToolButton
              title="Kill Chat Socket Until Reload"
              onPress={this.chatSocketKillUntilReload}
              disabled={this.props.mmSocketNotReady || !this.props.accessToken}
            />
            <ToolButton
              title="Restore Chat Socket"
              onPress={this.chatSocketRestore}
              disabled={!this.props.mmSocketNotReady || !this.props.accessToken}
            />
            <ToolButton
              title={`${!this.props.showDebugCrumbs ? 'Enable' : 'Disable'} Debug Crumbs`}
              onPress={this.props.toggleDebugCrumbs}
            />
            <ToolButton
              title={`${
                extraPropsSourceRequestsDisabled ? 'Enable' : 'Disable'
              } Extra Props Source`}
              onPress={this.toggleDisableExtraPropsSourceRequests}
            />
            <ToolButton title="Reset Consent" onPress={this.props.resetConsent} />
            <ToolButton title="Override features" onPress={this.openFeaturesForm} />
            <ToolButton title="Network Logger" onPress={this.openNetworkLogger} />
            <ToolButton title="Device Logger" onPress={this.openDeviceLogsScreen} />
            <ToolButton title="Disable Debug Tools" onPress={this.props.toggleDebugFab} />
            <ToolButton title="Invalidate token" onPress={this.props.invalidate} />
            <ToolButton title="Soft crash" onPress={this.props.forceCrash} />
            <ToolButton title="Native crash" onPress={this.props.nativeCrash} />
            <ToolButton
              title="Scheduled Local Notifications"
              onPress={this.openScheduledLocalNotifications}
            />
          </View>
        )}
        <Modal
          statusBarTranslucent
          visible={this.state.showFeaturesForm}
          transparent
          title="Override features"
          handleCloseModal={this.closeFeaturesForm}
          primaryActionText={'Set overrides'}
          handlePrimaryAction={this.setOverrides}
          secondaryActionText={'Reset'}
          handleSecondaryAction={this.resetFeatures}
        >
          <ScrollView style={Styles.featuresModal}>
            {Object.keys(features).map((label) => (
              <Checkbox
                key={label}
                label={label}
                status={this.getFeatureStatus(label)}
                onPress={this.getFeatureChangeHandler(label)}
              />
            ))}
          </ScrollView>
        </Modal>
        <DebugButton onPress={this.toggleToolBox} />
      </View>
    ) : null
  }
}

const mapStateToProps = (state) => {
  const accessToken = state.login && state.login.accessToken
  const mmSocketNotReady = state.history.mmSocketNotReady
  const showDebugCrumbs = state.app.showDebugCrumbs
  const showDebugFab = state.app.showDebugFab
  const currentChannelId = state.history && state.history.currentChannelId
  const channel =
    state.history && state.history.channels && state.history.channels[currentChannelId]
  const channelFetchingPosts = channel && channel.fetchingPosts
  const currentChannelTotalRawMsgCount = channel && channel.msgCount
  const currentChannelMsgCount = channel?.order?.length
  const currentChannelIsActive = channel && channel.active
  const lastPostId = channel && channel.order[0]
  const lastPost = channel && channel.posts[lastPostId]
  const lastPostAt = lastPost && lastPost.create_at
  const features = state.features
  return {
    lastPostAt,
    currentChannelId,
    currentChannelTotalRawMsgCount,
    currentChannelMsgCount,
    currentChannelIsActive,
    accessToken,
    mmSocketNotReady,
    showDebugCrumbs,
    showDebugFab,
    channelFetchingPosts,
    features,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleDebugCrumbs: () => dispatch(StartupActions.toggleDebugCrumbs()),
    toggleDebugFab: () => dispatch(StartupActions.toggleDebugFab()),
    forceCrash: () => this.is.it,
    invalidate: () => PushNotification.unregister(),
    resetConsent: () =>
      dispatch(PatientActions.patientProfileUpdateRequest({ hasConsented: false })),
    setOverrides: (features) => dispatch(featuresActions.setOverrides(features)),
    resetFeatures: () => dispatch(featuresActions.resetFeatures()),
    nativeCrash: () => Sentry.nativeCrash(),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DebugTools)
