import React from 'react'
import { View, Text, Image } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'
import I18n from 'APP/Services/i18n'

// Actions
import PatientActions from 'APP/Redux/PatientRedux'

// Styles
import Styles from './style'
import { Images } from 'APP/Themes'

// Components
import Spinner from 'react-native-loading-spinner-overlay'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import { PrimaryButton } from 'APP/Components/Buttons'

const GeolocationServiceScreen = () => {
  const dispatch = useDispatch()
  const locationRequestRunning = useSelector((state) => state.app.locationRequestRunning)

  const handleShareLocationPress = () =>
    dispatch(PatientActions.patientLocationServiceAccess(false))

  return (
    <NoticeBarContainer float>
      <Spinner visible={locationRequestRunning} cancelable={false} />
      <View style={Styles.container}>
        <Image style={Styles.placeholderImage} source={Images.geolocation} />
        <Text style={Styles.subtitle}>{I18n.t('GeolocationServiceScreen.infoText')}</Text>
        <PrimaryButton
          title={I18n.t('GeolocationServiceScreen.activateLocationServiceButton')}
          analyticsName="GeolocationServiceScreen.activateLocationServiceButton"
          onPress={handleShareLocationPress}
        />
      </View>
    </NoticeBarContainer>
  )
}

export default GeolocationServiceScreen
