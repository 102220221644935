import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons'

const ACTIVITY_TYPE = {
  STEPS: 'steps',
  CYCLING: 'cycling',
  SWIMMING: 'swimming',
  WHEELCHAIR: 'wheelchair',
  YOGA: 'yoga',
  OTHER: 'other',
}

const ACTIVITY_ICON = {
  [ACTIVITY_TYPE.STEPS]: {
    Icon: MaterialIcon,
    name: 'directions-run',
  },
  [ACTIVITY_TYPE.CYCLING]: {
    Icon: MaterialIcon,
    name: 'directions-bike',
  },
  [ACTIVITY_TYPE.WHEELCHAIR]: {
    Icon: MaterialCommunityIcon,
    name: 'wheelchair-accessibility',
  },
  [ACTIVITY_TYPE.SWIMMING]: {
    Icon: MaterialCommunityIcon,
    name: 'swim',
  },
  [ACTIVITY_TYPE.YOGA]: {
    Icon: MaterialCommunityIcon,
    name: 'yoga',
  },
  [ACTIVITY_TYPE.OTHER]: {
    Icon: MaterialIcon,
    name: 'accessibility-new',
  },
}

export default {
  ACTIVITY_TYPE,
  ACTIVITY_ICON,
}
