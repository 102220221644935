import { StyleSheet } from 'react-native'
import { Colors, ApplicationStyles } from 'APP/Themes'

export const Styles = StyleSheet.create({
  ...ApplicationStyles.screen,
  bubble: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Colors.elementsBg,
    paddingVertical: 8,
    paddingHorizontal: 12,
    marginTop: 8,
  },
  bubbleFailed: {
    backgroundColor: null,
    paddingVertical: null,
    paddingHorizontal: null,
  },
  bubbleFailedTextContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
    paddingHorizontal: 12,
    marginRight: 8,
    opacity: 0.5,
    backgroundColor: Colors.deactivatedElementBg,
  },
  bubbleText: {
    fontSize: 16,
    color: Colors.text,
    fontWeight: '300',
  },
  bubbleTextFailed: {
    color: Colors.deactivatedElementText,
  },
})
