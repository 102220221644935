import React from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'
import Typography from 'APP/Converse/Typography'
import I18n from 'APP/Services/i18n'

const EmergencyServicesContainer = styled(View)`
  margin-horizontal: ${({ theme }) => theme.metrics.baseMargin * 2}px;
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin * 0.75}px;
  align-items: center;
`

const EmergencyServicesText = styled(Typography)`
  text-align: center;
`

export const EmergencyServicesWarning = () => {
  return (
    <EmergencyServicesContainer testID="EMERGENCY_SERVICES_WARNING">
      <EmergencyServicesText variant="captionBold">
        {I18n.t('ServiceGroup.emergencyService.description')}
      </EmergencyServicesText>
    </EmergencyServicesContainer>
  )
}
