import React, { memo, useEffect } from 'react'
import styled from 'styled-components/native'
import Typography from 'APP/Converse/Typography'
import PatientHistoryActions from 'APP/Redux/PatientHistoryRedux'
import i18n from 'APP/Services/i18n'
import { ResponsiveScrollView, ResponsiveView } from 'APP/Converse/Layout'
import { RouteProp } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'

interface PractitionerProp {
  route: RouteProp<{
    params: {
      userAppId: string
      profileId: string
    }
  }>
}

interface NotFoundProps {
  message: string
}

interface PractitionerInfoProps {
  practitionerBio: {
    name: string
    jobTitle: string
    bio: string
    profilePicture: {
      url: string
    }
  }
}

const ViewCenter = styled.View`
  justify-content: center;
  align-items: center;
`

const PageContainer = styled(ViewCenter)`
  gap: ${({ theme }) => theme.metrics.baseMargin}px;
  padding: ${({ theme }) => theme.metrics.baseMargin * 1.5}px;
  padding-bottom: ${({ theme }) => theme.metrics.baseMargin * 2}px;
`

export const ProfilePicture = styled.Image`
  height: 200px;
  width: 200px;
  border-radius: 200px;
`

const NotFoundImage = styled.Image`
  height: 120px;
  width: 240px;
`

const Title = styled(Typography)`
  text-align: center;
`

const PractitionerInfo = ({ practitionerBio }: PractitionerInfoProps) => {
  return (
    <ResponsiveScrollView>
      <PageContainer>
        <ProfilePicture source={{ uri: practitionerBio?.profilePicture?.url }} />
        <ViewCenter>
          <Title variant="h1">{practitionerBio?.name}</Title>
          <Typography variant="h2" color="accent">
            {practitionerBio?.jobTitle}
          </Typography>
        </ViewCenter>
        <Typography variant="bodyLarge">{practitionerBio?.bio}</Typography>
      </PageContainer>
    </ResponsiveScrollView>
  )
}

const NotFound = ({ message }: NotFoundProps) => {
  return (
    <ResponsiveView>
      <PageContainer>
        <NotFoundImage
          source={{ uri: 'https://cms.dev.dialoguecorp.com/static/images/404_light.svg' }}
        />
        <Typography variant="h1">{i18n.t('PractitionerScreen.oops')}</Typography>
        <Typography variant="bodyLarge">{message}</Typography>
      </PageContainer>
    </ResponsiveView>
  )
}

export const PractitionerScreen = memo<PractitionerProp>(
  ({
    route: {
      params: { userAppId, profileId },
    },
  }) => {
    const dispatch = useDispatch()

    const practitionerBio = useSelector((stats) => stats?.history?.practitionerBio)
    const fetchPractitionerBioError = useSelector(
      (stats) => stats?.history?.fetchPractitionerBioError
    )

    useEffect(() => {
      dispatch(PatientHistoryActions.fetchPractitionerBio(userAppId, profileId))
      return () => {
        dispatch(PatientHistoryActions.fetchPractitionerBioSuccess({}))
        dispatch(PatientHistoryActions.fetchPractitionerBioFailure(null))
      }
    }, [])

    return fetchPractitionerBioError ? (
      <NotFound message={fetchPractitionerBioError.data} />
    ) : (
      <PractitionerInfo practitionerBio={practitionerBio} />
    )
  }
)
