import { StyleSheet } from 'react-native'
import { ApplicationStyles, Metrics, Fonts, Colors } from 'APP/Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  container: {
    flex: 1,
    backgroundColor: Colors.appBg,
    paddingHorizontal: Metrics.baseMargin,
    paddingTop: Metrics.baseMargin * 2,
    paddingBottom: Metrics.baseMargin,
  },
  header: {
    marginHorizontal: Metrics.baseMargin,
  },
  subtitle: {
    ...Fonts.style.body,
    color: Colors.text,
    fontWeight: '300',
    lineHeight: 21,
    marginHorizontal: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin * 2,
  },
  bold: {
    fontFamily: Fonts.type.bold,
    fontWeight: 'bold',
  },
  footerContainer: {
    marginTop: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin,
  },
  clickableContainer: {
    padding: Metrics.baseMargin / 2,
    alignSelf: 'center',
    marginBottom: Metrics.baseMargin * 1.5,
  },
  clickableText: {
    ...Fonts.style.button,
    color: Colors.text,
    textAlign: 'center',
  },
})
