import { Colors, Fonts, Metrics } from 'APP/Themes'
import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: Metrics.baseMargin,
    paddingBottom: Metrics.baseMargin * 2,
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  title: {
    ...Fonts.style.h1,
    color: Colors.text,
  },
})
