// TODO(WEB): Remove this temporary shim and use sentry-expo: https://docs.expo.dev/guides/using-sentry/

import { ErrorBoundary } from '@sentry/react'
export * from '@sentry/react'

export const TouchEventBoundary = ErrorBoundary

export function nativeCrash() {
  // Trigger stack overflow
  nativeCrash()
}

export const Severity = {
  Info: 'info',
  Error: 'error',
  Warning: 'warning',
}
