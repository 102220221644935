import React from 'react'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import MaterialIcons from 'react-native-vector-icons/MaterialIcons'
import Ionicons from 'react-native-vector-icons/Ionicons'
import { useTheme } from 'styled-components/native'
import { Image, ViewStyle } from 'react-native'
import { SvgUri } from 'react-native-svg'
import { isWeb } from 'APP/Helpers/checkPlatform'
import { baseColors } from 'APP/Theme/Theme'

interface IconProps {
  src: string
  variant: 'image' | 'svg' | 'svgUrl' | 'material' | 'materialIcons' | 'ionicons'
  size?: number
  color?: keyof typeof baseColors
  style?: ViewStyle
  testID?: string
  fillLine?: keyof typeof baseColors
}

const Icon = ({
  src,
  variant,
  size = 20,
  color = 'darkText',
  style,
  testID,
  fillLine,
}: IconProps) => {
  const theme = useTheme()
  const iconColor = theme.colors[color]
  const fillLineColor = fillLine && theme.colors[fillLine]

  const switchedVariant = isWeb() && variant === 'svgUrl' ? 'image' : variant

  switch (switchedVariant) {
    case 'image':
      return (
        <Image
          style={{ tintColor: iconColor, width: size, height: size, ...style }}
          source={src}
          resizeMode="contain"
          testID={testID}
        />
      )
    case 'svg': {
      const IconComponent = src
      return (
        <IconComponent fill={iconColor} style={style} testID={testID} fillLine={fillLineColor} />
      )
    }
    case 'svgUrl':
      return (
        <SvgUri
          uri={src}
          color={iconColor}
          fill={iconColor}
          width={size}
          height={size}
          style={style}
          testID={testID}
        />
      )
    case 'material':
      return (
        <MaterialCommunityIcons
          name={src}
          size={size}
          color={iconColor}
          style={style}
          testID={testID}
        />
      )
    case 'materialIcons':
      return (
        <MaterialIcons name={src} size={size} color={iconColor} style={style} testID={testID} />
      )
    case 'ionicons':
      return <Ionicons name={src} size={size} color={iconColor} style={style} testID={testID} />
    default:
      return null
  }
}

export default Icon
