import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import I18n from 'APP/Services/i18n'
import HabitsActions from 'APP/Redux/HabitsRedux'
import { selectHabitById } from 'APP/Sagas/HabitsSagas'
import HabitEdit from './HabitEdit'
import { HabitCustomPrompt, HabitPrompt } from './HabitPrompt'
import HabitReminder from './HabitReminder'
import BottomSheetModal from 'APP/Components/BottomSheetModal'

export const HABIT_ACTION = {
  ADD_HABIT: 'ADD_HABIT',
  MODIFY_HABIT: 'MODIFY_HABIT',
}

const HABIT_ACTION_SUB_VIEWS = {
  EDIT: 'EDIT',
  PROMPT: 'PROMPT',
  CUSTOM_PROMPT: 'CUSTOM_PROMPT',
  REMINDER: 'REMINDER',
}

const ACTION_CONFIG = {
  [HABIT_ACTION.ADD_HABIT]: {
    analyticsName: 'addHabitActionsModal',
    defaultSubView: HABIT_ACTION_SUB_VIEWS.PROMPT,
    subViewConfig: {
      [HABIT_ACTION_SUB_VIEWS.PROMPT]: {
        Component: HabitPrompt,
        props: {
          title: I18n.t('Habits.updateOrRemovePrompt.title'),
          subtitle: I18n.t('Habits.setupHabit.title'),
          showGuidance: true,
          testID: 'promptView',
        },
        nextSubView: HABIT_ACTION_SUB_VIEWS.REMINDER,
        prevSubView: null,
      },
      [HABIT_ACTION_SUB_VIEWS.CUSTOM_PROMPT]: {
        Component: HabitCustomPrompt,
        props: {
          title: I18n.t('Habits.updateCustomPrompt.title'),
          subtitle: I18n.t('Habits.setupHabit.title'),
          testID: 'customPromptView',
        },
        nextSubView: HABIT_ACTION_SUB_VIEWS.REMINDER,
        prevSubView: null,
      },
      [HABIT_ACTION_SUB_VIEWS.REMINDER]: {
        Component: HabitReminder,
        props: {
          title: I18n.t('Habits.editReminder.addTitle'),
          subtitle: I18n.t('Habits.setupHabit.title'),
          testID: 'reminderView',
        },
        nextSubView: null,
        prevSubView: null,
      },
    },
  },
  [HABIT_ACTION.MODIFY_HABIT]: {
    analyticsName: 'editHabitActionsModal',
    defaultSubView: HABIT_ACTION_SUB_VIEWS.EDIT,
    subViewConfig: {
      [HABIT_ACTION_SUB_VIEWS.EDIT]: {
        Component: HabitEdit,
        props: {
          title: I18n.t('Habits.editHabit.title'),
          testID: 'editView',
        },
        nextSubView: null,
        prevSubView: null,
      },
      [HABIT_ACTION_SUB_VIEWS.PROMPT]: {
        Component: HabitPrompt,
        props: {
          title: I18n.t('Habits.updateOrRemovePrompt.title'),
          testID: 'promptView',
        },
        nextSubView: null,
        prevSubView: HABIT_ACTION_SUB_VIEWS.EDIT,
      },
      [HABIT_ACTION_SUB_VIEWS.CUSTOM_PROMPT]: {
        Component: HabitCustomPrompt,
        props: {
          title: I18n.t('Habits.updateCustomPrompt.title'),
          testID: 'customPromptView',
        },
        nextSubView: null,
        prevSubView: HABIT_ACTION_SUB_VIEWS.EDIT,
      },
      [HABIT_ACTION_SUB_VIEWS.REMINDER]: {
        Component: HabitReminder,
        props: {
          title: I18n.t('Habits.editReminder.title'),
          testID: 'reminderView',
        },
        nextSubView: null,
        prevSubView: HABIT_ACTION_SUB_VIEWS.EDIT,
      },
    },
  },
}

const HabitActionModal = ({
  isVisible: isVisibleProp,
  action = HABIT_ACTION.MODIFY_HABIT,
  habitId,
  selectedDay,
  onClose,
}) => {
  const { defaultSubView, subViewConfig, analyticsName } = ACTION_CONFIG[action] || {}
  const dispatch = useDispatch()
  const navigation = useNavigation()

  const habit = useSelector((state) => selectHabitById(state, habitId))

  const removeHabitState = useSelector((state) => state.habits.removeHabit)
  const updateHabitPromptState = useSelector((state) => state.habits.updateHabitPrompt)
  const removeHabitPromptState = useSelector((state) => state.habits.removeHabitPrompt)
  const updateNotificationConfigurationState = useSelector(
    (state) => state.habits.updateNotificationConfiguration
  )
  const processing =
    removeHabitState?.loading ||
    removeHabitPromptState?.loading ||
    updateHabitPromptState?.loading ||
    updateNotificationConfigurationState?.loading
  const actionWrapper =
    (fn) =>
    (...args) => {
      if (!processing) fn(...args)
    }

  const isHabitNotificationsFeatureEnabled = useSelector(
    (state) => state.features.habitNotifications
  )
  const [activeSubView, setActiveSubView] = useState(defaultSubView)
  const {
    nextSubView,
    prevSubView,
    props: subViewProps,
    Component,
  } = subViewConfig?.[activeSubView] || {}

  const isVisible = useMemo(() => {
    return (
      isVisibleProp && Boolean(habit && habitId && habitId === habit.identifier && activeSubView)
    )
  }, [habitId, habit, activeSubView, isVisibleProp])

  // reset subview
  useEffect(() => {
    setActiveSubView(defaultSubView)
  }, [habitId, defaultSubView])
  useEffect(() => {
    if (isVisible) setActiveSubView(defaultSubView)
  }, [isVisible])

  // Navigation
  const goBack = prevSubView ? () => setActiveSubView(prevSubView) : undefined
  const onNext =
    nextSubView &&
    // REMINDER subView is only availible if feature flag is on
    (nextSubView !== HABIT_ACTION_SUB_VIEWS.REMINDER || isHabitNotificationsFeatureEnabled)
      ? () => setActiveSubView(nextSubView)
      : // if nextSubView is not defined close the modal
        onClose
  const goToHabitPrompt = actionWrapper(() => {
    setActiveSubView(HABIT_ACTION_SUB_VIEWS.PROMPT)
  })
  const goToHabitCustomPrompt = actionWrapper(() => {
    setActiveSubView(HABIT_ACTION_SUB_VIEWS.CUSTOM_PROMPT)
  })
  const goToHabitReminder = actionWrapper(() => {
    setActiveSubView(HABIT_ACTION_SUB_VIEWS.REMINDER)
  })
  const navigateToHabit = actionWrapper(() => {
    onClose()
    navigation.navigate('habitDetailScreen', {
      id: habitId,
      hideCTAs: true,
    })
  })

  // Automatic naviagtion based on taken actions
  const takenActions = {
    habitRemoved: removeHabitState?.removed,
    habitPromptUpdated: updateHabitPromptState?.updated,
    notificationConfigurationUpdated: updateNotificationConfigurationState?.updated,
  }
  useEffect(() => {
    if (Object.values(takenActions).some(Boolean)) {
      if (typeof onNext === 'function') onNext()

      //clean up
      Object.keys(takenActions)
        .filter((key) => takenActions[key])
        .forEach((key) => {
          switch (key) {
            case 'habitRemoved':
              dispatch(HabitsActions.resetRemoveHabit())
              break
            case 'habitPromptUpdated':
              dispatch(HabitsActions.resetUpdateHabitPrompt())
              break
            case 'notificationConfigurationUpdated':
              dispatch(HabitsActions.resetUpdateNotificationConfiguration())
              break
          }
        })
    }
  }, Object.values(takenActions))

  const commonSubViewProps = {
    processing,
    selectedDay,
    habit,
    goToHabitPrompt,
    goToHabitCustomPrompt,
    goToHabitReminder,
    navigateToHabit,
    analyticsName,
  }

  return (
    <BottomSheetModal
      isVisible={isVisible}
      onClose={onClose}
      goBack={goBack}
      title={subViewProps?.title}
      subtitle={subViewProps?.subtitle}
      testID={analyticsName}
    >
      {!!Component && <Component {...subViewProps} {...commonSubViewProps} />}
    </BottomSheetModal>
  )
}

export default HabitActionModal
