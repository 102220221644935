import { useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react'
import { Platform } from 'react-native'

/**
 * `useTerminateModal` is a custom React hook designed to manage the lifecycle of modals,
 * particularly in iOS environments. This hook addresses the issue of race conditions that
 * might occur during transitions between two modals. In scenarios where closing one modal
 * and immediately opening another could lead to the second modal failing to appear,
 * this hook provides a reliable solution.
 *
 * It exposes a `terminateModal` method through `componentRef`, allowing the parent component
 * to await the closure of the current modal before proceeding to open a new one. This
 * functionality is crucial for ensuring smooth transitions between modals on iOS, preventing
 * the second modal from getting stuck in a non-visible state.
 *
 * @param {React.Ref} componentRef - A ref object for the component using this hook. This ref
 *                                   is typically set up via `forwardRef` in the parent component,
 *                                   enabling it to control the modal's dismissal process.
 * @param {boolean} isVisible - A state flag indicating the current visibility of the modal.
 *                              This flag is used to monitor and respond to visibility changes,
 *                              especially on non-iOS platforms.
 * @param {Function} onClose - A callback function invoked to initiate the closing of the modal.
 *
 * @returns {Object} modalProps - An object containing properties for the modal component,
 *                                including an `onDismiss` handler that is triggered upon modal dismissal.
 *
 * Usage:
 * This hook is particularly useful in complex modal workflows on iOS, where managing the
 * sequence of opening and closing multiple modals is crucial. By leveraging the `terminateModal`
 * method exposed through the `componentRef`, parent components can effectively control the
 * modal transitions, ensuring that one modal fully closes before another opens, thereby
 * avoiding race conditions and ensuring the modals behave as expected.
 */
export const useTerminateModal = (componentRef, isVisible, onClose) => {
  const dismissPromiseRef = useRef(null)
  const wasVisibleRef = useRef(isVisible)

  const handleDismiss = useCallback(() => {
    if (dismissPromiseRef.current) {
      dismissPromiseRef.current.resolve()
      dismissPromiseRef.current = null
    }
  }, [])

  // Handle non-iOS platforms
  useEffect(() => {
    // onDismiss props is only awailible for iOS
    // so when the visibility changes for other platforms we can simulate a dismiss
    if (Platform.OS !== 'ios' && wasVisibleRef.current && !isVisible) {
      handleDismiss()
    }
    wasVisibleRef.current = isVisible
  }, [isVisible])

  useImperativeHandle(componentRef, () => ({
    terminateModal: () => {
      return new Promise((resolve) => {
        dismissPromiseRef.current = { resolve }
        onClose()
      })
    },
  }))

  const modalProps = useMemo(
    () => ({
      onDismiss: handleDismiss,
    }),
    [handleDismiss]
  )

  return modalProps
}
