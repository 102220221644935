import { StyleSheet } from 'react-native'
import { ApplicationStyles } from '../../Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  navTabContainer: {
    ...ApplicationStyles.screen.tabContainer,
    paddingBottom: 0,
  },
  loading: {
    flex: 1,
  },
  qrCodeText: {
    display: 'flex',
    flexDirection: 'row',
  },
})
