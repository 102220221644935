import { useEffect, useState } from 'react'
import { navigationRef } from 'APP/Nav'

// Note: Seems to have an incompatibility with hot reload

const stateToRoutes = (state) => state?.routes || []

export const useStackRoutes = () => {
  const [routes, setResult] = useState([])

  useEffect(() => {
    if (navigationRef.isReady()) {
      const unsubscribe = navigationRef.addListener('state', (e) => {
        setResult(stateToRoutes(e.data.state))
      })
      return unsubscribe
    }
  }, [])

  return routes
}
