import { Colors, Fonts, Metrics, ApplicationStyles } from 'APP/Themes'

const inputStyles = {
  ...Fonts.style.bodyLarge,
  backgroundColor: Colors.elementsBg,
  lineHeight: undefined,
  color: Colors.text,
  paddingHorizontal: Metrics.baseMargin * 0.75,
  ...ApplicationStyles.softBorder,
  ...ApplicationStyles.softShadow,
  height: 46,
  elevation: 2,
  borderRadius: Metrics.baseMargin / 2,
}

const disabledInputStyles = {
  ...inputStyles,
  backgroundColor: Colors.elementsBorder,
  color: Colors.deactivatedElementBg,
}

const Styles = {
  inputStyles,
  disabledInputStyles,
  pickerStyles: {
    inputIOS: {
      ...inputStyles,
    },
    inputAndroid: {
      ...inputStyles,
      padding: 0,
    },
    placeholder: {
      ...inputStyles,
      color: Colors.textSecondary,
    },
    inputWeb: {
      ...inputStyles,
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
    },
    icon: {
      marginTop: 14,
      marginRight: Metrics.baseMargin / 2,
    },
  },
  disabledPickerStyles: {
    inputIOS: {
      ...disabledInputStyles,
    },
    inputAndroid: {
      ...disabledInputStyles,
      padding: 0,
    },
    placeholder: {
      ...disabledInputStyles,
    },
    inputWeb: {
      ...disabledInputStyles,
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
    },
    icon: {
      marginTop: 14,
      marginRight: Metrics.baseMargin / 2,
      color: Colors.deactivatedElementBg,
    },
  },
  tooltipStyles: {
    position: 'absolute',
    top: 10,
    right: Metrics.baseMargin / 2,
  },
}

export default Styles
