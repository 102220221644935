import styled, { DefaultTheme } from 'styled-components/native'
import { LinearGradient } from 'expo-linear-gradient'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import { ButtonProps } from 'APP/Converse/Button'
import { Platform } from 'react-native'

const getButtonWidth = ({
  widthVariant,
  theme,
}: {
  widthVariant: ButtonProps['widthVariant']
  theme: DefaultTheme
}) => {
  switch (widthVariant) {
    case 'fixed':
      return `${theme.metrics.button.width}px`
    case 'full':
      return '100%'
    case 'content':
      return Platform.OS === 'web' ? 'fit-content' : 'auto'
    case 'circle':
      return `${theme.metrics.button.height}px`
    case 'icon':
      return '24px'
    case 'half':
      return '50%'
    case 'flex':
    default:
      return 'auto'
  }
}

const getButtonHeight = ({
  widthVariant,
  theme,
  small,
}: {
  widthVariant: ButtonProps['widthVariant']
  theme: DefaultTheme
  small: boolean
}) => {
  if (small) {
    return `${theme.metrics.button.small}px`
  }
  switch (widthVariant) {
    case 'icon':
      return '24px'
    default:
      return `${theme.metrics.button.height}px`
  }
}

export const StyledButton = styled(DialogueTouchableOpacity)<ButtonProps>`
  ${({ widthVariant }) => widthVariant === 'flex' && 'flex: 1;'}
  width: ${({ widthVariant, theme }) => getButtonWidth({ widthVariant, theme })};
  align-self: ${({ widthVariant }) => (widthVariant === 'content' ? 'flex-start' : 'auto')};
  height: ${({ theme, widthVariant, small }) => getButtonHeight({ widthVariant, theme, small })};
  border-radius: ${({ theme }) => theme.metrics.borderRadius.large}px;
  justify-content: center;
  align-items: center;
  padding: 0
    ${({ theme, widthVariant }) =>
      widthVariant === 'icon' ? '4px' : `${theme.metrics.baseMargin}px`};
  ${({ hasShadow, theme }) => hasShadow && theme.shadows.normal}
`

export const StyledButtonContent = styled.View`
  flex-direction: row;
  align-items: center;
  gap: 10px;
`

export const StyledPrimaryButton = styled(StyledButton)`
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.disabled : theme.colors.primary};
`

export const StyledSecondaryButton = styled(StyledButton)`
  background-color: 'transparent';
  border: 1px solid
    ${({ theme, disabled, lineColor }) =>
      disabled
        ? theme.colors.disabled
        : lineColor
        ? theme.colors[lineColor]
        : theme.colors.secondary};
`

export const StyledTertiaryButton = styled(StyledButton)`
  background-color: 'transparent';
`

export const StyledOomphButton = styled(StyledButton)`
  background-color: 'transparent';
`
export const StyledSuccessButton = styled(StyledPrimaryButton)`
  background-color: ${({ theme }) => theme.colors.success};
  ${({ hasShadow, theme }) => hasShadow && theme.shadows.normal}
`

export const StyledErrorButton = styled(StyledPrimaryButton)`
  background-color: ${({ theme }) => theme.colors.error};
`

export const StyledLinearGradient = styled(LinearGradient).attrs(({ theme }) => ({
  colors: [theme.colors.gradientStart, theme.colors.gradientEnd],
}))<ButtonProps>`
  width: ${({ widthVariant, theme }) => getButtonWidth({ widthVariant, theme })};
  height: ${({ theme }) => theme.metrics.button.height}px;
  border-radius: ${({ theme }) => theme.metrics.borderRadius.large}px;
  justify-content: center;
  align-items: center;
`
