import { StyleSheet } from 'react-native'

import { Metrics } from 'APP/Themes'

export const Styles = StyleSheet.create({
  replyPanel: {
    flexGrow: 1,
    flexShrink: 0,
    position: 'relative',
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    zIndex: 10,
  },
  replyPanelNoGrow: {
    flexGrow: 0,
  },
  replyPanelShrink: {
    flexShrink: 1,
  },
  replyPanelAnswerContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  inline: {
    marginRight: Metrics.baseMargin,
    marginLeft: Metrics.baseMargin * 4,
    marginBottom: Metrics.baseMargin * 2,
  },
  replyForm: {
    width: '100%',
    maxWidth: 800,
    alignSelf: 'center',
  },
  replyPanelMultiSubprompt: {
    flexGrow: 1,
    flexShrink: 0,
    position: 'relative',
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    overflow: 'hidden',
  },
  replyFormMultiSubprompt: {
    flexGrow: 1,
  },
})
