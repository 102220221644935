import { StyleSheet } from 'react-native'
import { Metrics, Colors, Fonts } from 'APP/Themes'

export default StyleSheet.create({
  title: {
    ...Fonts.style.h3,
    color: Colors.text,
    paddingHorizontal: Metrics.baseMargin * 0.5,
    marginBottom: Metrics.baseMargin * 0.5,
    marginHorizontal: Metrics.baseMargin,
  },
  cardStyle: {
    marginHorizontal: Metrics.baseMargin,
  },
  cardSpacer: {
    marginBottom: Metrics.baseMargin,
  },
  // Make space for card shadow, unintuitive but since the list is inverted
  // the index goes backwards, so apply a bottom spacer to all but the first element
  appointmentList: {
    marginTop: -4,
  },
  appointmentListContainer: {
    paddingTop: 4,
  },
})
