import React, { useEffect, useState } from 'react'
import { ActivityIndicator, View } from 'react-native'
import { Appearance } from 'react-native-appearance'
import AsyncStorage from '@react-native-community/async-storage'
import { useDispatch, useSelector } from 'react-redux'
import PatientHistoryActions from 'APP/Redux/PatientHistoryRedux'

import DialogueWebView from 'APP/Components/DialogueWebView'

import I18n from 'APP/Services/i18n'
import Config from 'APP/Config'
import { isWeb } from 'APP/Helpers/checkPlatform'

import { Colors } from 'APP/Themes'
import Styles from './style'

const IcbtPortalScreen = () => {
  const dispatch = useDispatch()
  const accessToken = useSelector((state) => state?.login?.accessToken)
  const eligibleServices = useSelector((state) => state?.patient?.profile?.eligibleServices)

  const [uriWithAccessToken, setUriWithAccessToken] = useState()

  const icbtConfig = Config.MICROAPPS_CONFIG.icbt
  const hasIcbtGuided = Object.prototype.hasOwnProperty.call(eligibleServices, 'icbt_guided')

  const onEvent = (event) => {
    if (event.data) {
      const eventData = isWeb() ? event.data : JSON.parse(event.data)
      if (
        eventData?.type === 'url_changed' &&
        eventData.url &&
        event?.url?.startsWith(Config.ECOUCH_APP_URL)
      ) {
        AsyncStorage.setItem('icbt_iframe_last_url', eventData.url)
      } else if (eventData?.type === 'coach_button_clicked') {
        dispatch(PatientHistoryActions.getOrCreateIcbtEpisodeRequest('icbtCoach', null, false))
      }
    }
  }

  useEffect(() => {
    const colorScheme = Appearance.getColorScheme()
    const uriWithLangAndTheme = `${icbtConfig.url[I18n.locale] || icbtConfig.url['en']}?lang=${
      I18n.baseLocale
    }&theme=${Config.BRAND_ID}-${colorScheme}${hasIcbtGuided ? '&features=guided' : ''}`

    AsyncStorage.getItem('icbt_iframe_last_url').then((lastUrl) => {
      let redirectUri = ''
      if (lastUrl && !lastUrl.includes('auth/sso')) {
        redirectUri = `&redirect_uri=${lastUrl}`
      }

      setUriWithAccessToken(`${uriWithLangAndTheme}${redirectUri}#access_token=${accessToken}`)
    })
  }, [])

  return (
    <>
      {uriWithAccessToken ? (
        <DialogueWebView uri={uriWithAccessToken} onEvent={onEvent} />
      ) : (
        <View style={Styles.loadingOverlay}>
          <ActivityIndicator color={Colors.text} />
        </View>
      )}
    </>
  )
}

export default IcbtPortalScreen
