import {
  Keyboard,
  Image,
  KeyboardAvoidingView,
  Platform,
} from 'react-native'
import Card from 'APP/Converse/Card'
import Typography from 'APP/Converse/Typography'
import React, { memo, useEffect } from 'react'
import I18n from 'APP/Services/i18n'
import { Colors } from 'APP/Themes'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Images } from 'APP/Themes'
import styled from 'styled-components/native'
import ControllerInput from 'APP/Components/ControllerFields/ControllerInput'
import PatientActions from 'APP/Redux/PatientRedux'
import Button from 'APP/Converse/Button'
import { patientState } from 'APP/Sagas/PatientSagas'
import Styles from 'APP/Components/BottomSheetModal/style'
import { Metrics } from 'APP/Themes'

const ContentCard = styled(Card)`
  width: 100%;
  max-width: 550px;
  align-items: stretch;
`

const Logo = styled(Image)`
  height: 50px;
  width: 200px;
  margin-top: ${({ theme }) => theme.metrics.baseMargin}px;
  align-self: center;
`

const Content = styled.View`
  padding: ${({ theme }) => theme.metrics.baseMargin}px;
  gap: ${({ theme }) => theme.metrics.baseMargin}px;
  text-align: center;
`

const Page = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.appBg};
`

interface FormProps {
  password: string
}

const VerifyNewEmailScreen = () => {
  const { changeEmailToken, requestRunning } = useSelector(patientState)
  const dispatch = useDispatch()
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      newEmail: '',
      oldEmail: '',
      password: '',
    },
  })
  const onSubmit = (values: FormProps) => {
    dispatch(PatientActions.changeEmailRequest(values.password))
  }

  useEffect(() => {
    setValue('oldEmail', changeEmailToken.old_email)
    setValue('newEmail', changeEmailToken.new_email)
  }, [])

  return (
    <KeyboardAvoidingView
      style={Styles.keyboardAvoidingView}
      behavior={Platform.OS === 'ios' ? 'padding' : null}
      keyboardVerticalOffset={Metrics.baseMargin}
    >
      <Page>
        <ContentCard>
          <Logo source={Images.logoBlue} resizeMode="contain" />
          <Content>
            <Typography variant="h3">{I18n.t('VerifyNewEmailScreen.confirm.title')}</Typography>
          </Content>
          <ControllerInput
            disabled
            control={control}
            name="oldEmail"
            label={I18n.t('VerifyNewEmailScreen.confirm.currentEmail')}
          />
          <ControllerInput
            disabled
            control={control}
            name="newEmail"
            label={I18n.t('VerifyNewEmailScreen.confirm.newEmail')}
          />
          <ControllerInput
            control={control}
            isSecureEntry
            name="password"
            testID="changeEmailPassword"
            label={I18n.t('VerifyNewEmailScreen.confirm.password')}
            rules={{
              required: I18n.t('VerifyNewEmailScreen.confirm.passwordRequired'),
            }}
          />

          <Button
            onPress={() => {
              Keyboard.dismiss()
              handleSubmit(onSubmit)()
            }}
            widthVariant="full"
            isLoading={requestRunning}
            analyticsName="Submit VerifyNewEmailScreen"
            activityIndicatorColor={Colors.buttonPrimaryText}
            disabled={!isValid || requestRunning}
            title={I18n.t('VerifyNewEmailScreen.confirm.cta')}
            testID="changeEmailSubmit"
          />
        </ContentCard>
      </Page>
    </KeyboardAvoidingView>
  )
}

export default memo(VerifyNewEmailScreen)
