import React, { useEffect, useState } from 'react'
import { View, ActivityIndicator, Text, TouchableOpacity } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import HabitActions from 'APP/Redux/HabitsRedux'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import Toggles from 'APP/Components/Toggles'
import I18n from 'APP/Services/i18n'
import { Colors, Metrics } from 'APP/Themes'

import Styles from './style'
import { Habits } from '@dialogue/services'
import { ResponsiveScrollView } from 'APP/Converse/Layout'

const ExploreHabits = () => {
  const dispatch = useDispatch()
  const navigation = useNavigation()

  const { categories, topics } = useSelector((state) => state.habits)

  const [activeCategory, setActiveCategory] = useState(null)

  const loading = categories.loading || topics.loading
  const retry = categories.retry || topics.retry

  useEffect(() => {
    getCategories()
  }, [])

  // Fetch initial category topics
  useEffect(() => {
    if (categories.data?.length > 0 && !activeCategory) {
      getCategoryTopics(categories.data[0].identifier)
      setActiveCategory(categories.data[0].title[I18n.baseLocale])
    }
  }, [categories, activeCategory])

  const getCategories = () => dispatch(HabitActions.getCategories())
  const getCategoryTopics = (id) => dispatch(HabitActions.getTopics(id))

  const handleCategoryPress = (index) => {
    const category = categories.data[index]
    getCategoryTopics(category.identifier)
  }

  const handleHabitPress = (id) => {
    navigation.navigate('habitDetailScreen', { id })
  }

  return (
    <NoticeBarContainer containerStyle={Styles.container}>
      <View style={Styles.containerInner}>
        <ResponsiveScrollView
          contentContainerStyle={Styles.topics}
          scrollIndicatorInsets={{ right: 1 }}
        >
          {!categories.loading && categories.data?.length > 0 && (
            <View style={Styles.categories}>
              <Toggles
                toggles={categories.data.map((c) => ({
                  name: c.title[I18n.baseLocale],
                  title: c.title[I18n.baseLocale],
                }))}
                activeToggle={activeCategory}
                onChange={handleCategoryPress}
                slim
              />
            </View>
          )}
          {!topics.loading &&
            topics.data?.length > 0 &&
            topics.data.map((topic) => (
              <View key={topic.identifier} style={Styles.topic}>
                <Text style={Styles.topicTitle}>{topic.title[I18n.baseLocale]}</Text>
                {topic.habits.map((habit) => (
                  <TouchableOpacity
                    style={Styles.habit}
                    key={habit.identifier}
                    onPress={() => handleHabitPress(habit.identifier)}
                    testID="habit"
                  >
                    <View>
                      <Text style={Styles.habitText}>{habit.title[I18n.baseLocale]}</Text>
                      {habit.state !== Habits.Habits.Types.State.Pending &&
                        I18n.exists(`ExploreHabitsScreen.habitState.${habit.state}`) && (
                          <View style={Styles.habitSubheader}>
                            <MaterialIcon
                              color={Colors.accent}
                              size={Metrics.icons.tiny}
                              name="done"
                            />
                            <Text style={Styles.habitStateText}>
                              {I18n.t(`ExploreHabitsScreen.habitState.${habit.state}`)}
                            </Text>
                          </View>
                        )}
                    </View>
                    <MaterialIcon
                      accessibilityRole="button"
                      color={Colors.accent}
                      size={Metrics.icons.small}
                      name="chevron-right"
                    />
                  </TouchableOpacity>
                ))}
              </View>
            ))}
        </ResponsiveScrollView>
        {loading && !retry && (
          <ActivityIndicator
            size="large"
            color={Colors.text}
            style={Styles.network}
            testID="loading"
          />
        )}
        {retry && (
          <DialogueTouchableOpacity
            style={Styles.network}
            onPress={getCategories}
            analyticsName="Refresh"
            testID="retry"
          >
            <MaterialIcon name="refresh" size={Metrics.icons.large} color={Colors.text} />
          </DialogueTouchableOpacity>
        )}
      </View>
    </NoticeBarContainer>
  )
}

export default ExploreHabits
