/* eslint-disable @typescript-eslint/no-unused-vars */
import apisauce from 'apisauce'
import Config from '../Config'
import Moment from 'moment'
import { camelizeKeys } from 'humps'
import I18n from 'APP/Services/i18n'

export const mapPatientFromCoreDataV1 = (patient) => {
  // Because CoreData v1 returns *some fields* of the model with a different casing,
  // we need to map them here to avoid modifying the whole code base
  const {
    given_name,
    family_name,
    date_of_birth,
    id_card,
    phone_number,
    resides_in,
    has_consented,
    user_type,
    ...formattedPatient
  } = patient
  return {
    ...formattedPatient,
    givenName: patient['given_name'],
    preferredName: patient['preferred_name'],
    pronouns: patient['pronouns'],
    familyName: patient['family_name'],
    dateOfBirth: patient['date_of_birth'],
    idCard: patient['id_card'],
    phoneNumber: patient['phone_number'],
    residesIn: patient['resides_in'],
    genderIdentity: patient['gender_identity'],
    genderIdentityDescription: patient['gender_identity_description'],
    hasConsented: patient['has_consented'],
    userType: patient['user_type'],
    profileSetupStatus: patient['profile_setup_status'],
  }
}

export const mapEncoutersFromCoreDataV1 = (encounters) => {
  return encounters.map((encounter) => {
    const {
      careplans,
      diagnostic_orders,
      end_time,
      is_open,
      medication_orders,
      patient_id,
      practitioner_id,
      rtc_session,
      start_time,
      ...formattedEncounter
    } = encounter
    return {
      ...formattedEncounter,
      carePlans: encounter['careplans'],
      diagnosticOrders: encounter['diagnostic_orders'],
      endTime: encounter['end_time'],
      isOpen: encounter['is_open'],
      medicationOrders: encounter['medication_orders'],
      patient: mapPatientFromCoreDataV1(encounter['patient']),
      patientId: encounter['patient_id'],
      practitionerId: encounter['practitioner_id'],
      rtcSession: encounter['rtc_session'],
      startTime: encounter['start_time'],
    }
  })
}

export const mapPatientToCoreDataV1 = (patient) => {
  //Because CoreData v1 needs *some fields* of the model with a different casing,
  //we need to map them here to avoid modifying the whole code base
  const {
    preferredName,
    pronouns,
    genderIdentity,
    genderIdentityDescription,
    ...formattedPatient
  } = patient

  return {
    ...formattedPatient,
    preferred_name: preferredName,
    pronouns: pronouns,
    gender_identity: genderIdentity,
    gender_identity_description: genderIdentityDescription,
    profile_setup_status: 'complete',
  }
}

// our "constructor"
const create = (accessToken, baseURL = `${Config.EMERGENCY_ROOM_DOMAIN}/v1`) => {
  const api = apisauce.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
    // 30 second timeout...
    timeout: 30000,
  })

  const hasToken = () => {
    return !!api.headers['Authorization']
  }

  const resetToken = () => {
    api.deleteHeader('Authorization')
  }

  const getPatient = () => {
    // Because CoreData v1 returns *some fields* of the model with a different casing,
    // we need to map them here to avoid modifying the whole code base
    return api.get('/patients/me').then((response) => {
      return {
        ...response,
        data: {
          ...response.data,
          data: response.ok ? mapPatientFromCoreDataV1(response.data.data) : null,
        },
      }
    })
  }

  const getPatientEncounters = () => {
    return api.get(`/patients/me/encounters?limit=100`).then((response) => {
      // Because CoreData v1 returns *some fields* of the model with a different casing,
      // we need to map them here to avoid modifying the whole code base
      return {
        ...response,
        data: {
          ...response.data,
          data: response.ok ? mapEncoutersFromCoreDataV1(response.data.data) : null,
        },
      }
    })
  }

  const getPatientMedicalIdURL = () => {
    return api.get('/patients/me/medical_id/download').then((response) => response)
  }

  const getPatientMedicalIdUploadURL = () => {
    return api.post('/patients/me/medical_id/upload').then((response) => response)
  }

  // Note: This is unfortunately not the same code path as updaing a child's id.
  // We should probably consolidate this code replication
  const uploadPatientMedicalID = (request, fileObj) => {
    let path = fileObj?.fileUri || ''
    if (
      fileObj?.fileUri?.indexOf('content://') === -1 &&
      fileObj?.fileUri?.indexOf('file://') === -1
    ) {
      path = `file://${path}`
    }

    const photo = fileObj?.file
      ? fileObj.file
      : {
          uri: path,
          type: 'image/jpeg',
          name: 'photo.jpg',
        }

    const fields = request.fields || {}
    let body = new FormData()

    Object.keys(fields).forEach((key) => {
      body.append(key, fields[key])
    })
    body.append('file', photo)

    const aws = apisauce.create({
      baseURL: request.url,
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'multipart/form-data',
      },
      timeout: 20000,
    })

    return aws.post(request.url, body).then((response) => {
      return response
    })
  }

  const updatePatient = (payload) => {
    if (payload.dateOfBirth) {
      payload.dateOfBirth = Moment(payload.dateOfBirth).format(I18n.t('DateFormat'))
    }
    return api.patch('/patients/me', mapPatientToCoreDataV1(payload)).then((response) => {
      return response
    })
  }

  const getMemberQuestionnaires = ({ memberId, params }) => {
    return api
      .get(`/members/${memberId}/properties/questionnaires`, params)
      .then((response) => response)
  }

  const getEpisode = (episodeId) => {
    return api
      .get(`/patients/me/episodes/${episodeId}`)
      .then((response) => camelizeKeys(response.data.data))
  }

  const updateEpisodeState = (episodeId, state) => {
    return api.post(`/episodes/${episodeId}/state`, { state_id: state }).then((response) => {
      if (response.ok) {
        return response
      } else {
        throw {
          status: response.status,
          title: `Unable to update episode state: ${state}`,
          detail: response?.data?.detail,
        }
      }
    })
  }

  const getPractitioner = (practitionerId) =>
    api.get(`/practitioners/${practitionerId}`).then((response) => response)

  return {
    hasToken,
    resetToken,
    getPatient,
    getPatientEncounters,
    getPatientMedicalIdURL,
    getPatientMedicalIdUploadURL,
    uploadPatientMedicalID,
    updatePatient,
    getMemberQuestionnaires,
    getEpisode,
    updateEpisodeState,
    getPractitioner,
  }
}

export default {
  create,
}
