import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  avatar: {
    minWidth: 32,
    minHeight: 32,
    height: 32,
    width: 32,
    borderRadius: 18,
  },
})
