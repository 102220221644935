import { Colors, Metrics, ApplicationStyles } from 'APP/Themes'
import { StyleSheet } from 'react-native'

// Styles
export default StyleSheet.create({
  seperator: {
    padding: Metrics.baseMargin * 0.6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.appBg,
    borderBottomWidth: 1,
    borderBottomColor: Colors.elementsBorder,
    ...ApplicationStyles.shadow,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: Metrics.baseMargin * 1.5,
    paddingVertical: Metrics.baseMargin,
    backgroundColor: Colors.elementsBg,
    borderBottomWidth: 1,
    borderBottomColor: Colors.elementsBorder,
    ...ApplicationStyles.shadow,
  },
  item: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: Metrics.baseMargin * 1.5,
    paddingVertical: Metrics.baseMargin,
    backgroundColor: Colors.elementsBg,
    borderBottomWidth: 1,
    borderBottomColor: Colors.elementsBorder,
  },
  emptyContainer: {
    paddingVertical: Metrics.baseMargin * 2,
    paddingHorizontal: Metrics.baseMargin * 1.5,
    backgroundColor: Colors.elementsBg,
    alignItems: 'center',
  },
  highlighted: {
    backgroundColor: Colors.selected,
  },
  rankContainer: {
    paddingRight: Metrics.baseMargin * 0.5,
  },
  avatarContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.accentLight,
    width: Metrics.baseMargin * 2,
    height: Metrics.baseMargin * 2,
    borderRadius: Metrics.baseMargin,
  },
  bodyContainer: {
    flex: 1,
    paddingLeft: Metrics.baseMargin,
  },
  subtitle: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: Metrics.baseMargin * 0.25,
  },
  valueContainer: {
    paddingLeft: Metrics.baseMargin,
  },
  errorIcon: {
    fontSize: 12,
    paddingRight: Metrics.baseMargin * 0.25,
  },
})
