import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getLatest: [],
  getLatestSuccess: ['assessment'],
  getLatestFailure: [],

  getRecommendations: [],
  getRecommendationsSuccess: ['recommendations'],
  getRecommendationsFailure: [],
})

export const WellBeingTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  latest: {
    loading: true,
    data: null,
  },
  recommendations: {
    loading: false,
    retry: false,
    data: [],
  },
})

/* ------------- Reducers ------------- */
export const getLatest = (state) =>
  state.merge({
    latest: {
      loading: true,
      data: null,
    },
  })

export const getLatestSuccess = (state, { assessment }) =>
  state.merge({
    latest: {
      loading: false,
      data: assessment,
    },
  })

export const getLatestFailure = (state) =>
  state.merge({
    latest: {
      loading: false,
    },
  })

export const getRecommendations = (state) =>
  state.merge({
    recommendations: {
      loading: true,
      retry: false,
      data: state.recommendations.data,
    },
  })

export const getRecommendationsSuccess = (state, { recommendations }) =>
  state.merge({
    recommendations: {
      loading: false,
      retry: false,
      data: recommendations,
    },
  })

export const getRecommendationsFailure = (state) =>
  state.merge({
    recommendations: {
      loading: false,
      retry: true,
    },
  })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_LATEST]: getLatest,
  [Types.GET_LATEST_SUCCESS]: getLatestSuccess,
  [Types.GET_LATEST_FAILURE]: getLatestFailure,

  [Types.GET_RECOMMENDATIONS]: getRecommendations,
  [Types.GET_RECOMMENDATIONS_SUCCESS]: getRecommendationsSuccess,
  [Types.GET_RECOMMENDATIONSE_FAILURE]: getRecommendationsFailure,
})
