import React from 'react'
import { Image, Text } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import Icon from 'react-native-vector-icons/Ionicons'
import UrlBuilder from 'APP/Services/UrlBuilder'
import { Appearance } from 'react-native-appearance'

// Services
import Analytics from 'APP/Services/Analytics'

// Components
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'

// Styles
import { Colors, Fonts, Images } from 'APP/Themes'
import Styles from 'APP/Nav/style'

export const HeaderButton = ({ image, icon, title, disabled, ...rest }) => {
  return (
    <DialogueTouchableOpacity
      style={Styles.headerButton}
      disabled={disabled}
      analyticsName={title}
      {...rest}
    >
      {image && <Image source={image} />}
      {icon && (
        <Icon name={icon} style={Styles.buttonIcon} size={Fonts.size.h2} color={Colors.text} />
      )}
      {title && !icon && (
        <Text style={[Styles.buttonText, disabled && { opacity: 0.5 }]}>{title}</Text>
      )}
    </DialogueTouchableOpacity>
  )
}

export const HeaderModalCloseButton = ({ onClose, ...rest }) => {
  const navigation = useNavigation()
  return (
    <DialogueTouchableOpacity
      onPress={() => {
        if (typeof onClose === 'function') {
          onClose()
        }
        Analytics.trackEvent('button_click', { button_value: 'Back' })
        navigation.goBack()
      }}
      style={Styles.headerModalCloseButton}
      testID="backBtn"
      analyticsName="Back"
      {...rest}
    >
      <Image style={Styles.headerModalCloseIcon} source={Images.closeIcon} />
    </DialogueTouchableOpacity>
  )
}

export const HeaderMentalHealthHelpButton = () => {
  const navigation = useNavigation()

  return (
    <HeaderButton
      icon="warning-outline"
      testID="mentalHealthHelpBtn"
      onPress={() =>
        navigation.navigate('cmsScreen', {
          uri: UrlBuilder.cmsConsultUrl('emergencyCare', Appearance.getColorScheme()),
          screen: 'cms',
          showShareButton: false,
        })
      }
    />
  )
}
