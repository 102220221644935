import React from 'react'
import { Linking, Image, ScrollView, View } from 'react-native'

// Services
import I18n from 'APP/Services/i18n'
import Config from 'APP/Config'

// Components
import DialogueAutolink from 'APP/Components/DialogueAutolink'
import { PrimaryButton } from 'APP/Components/Buttons'
import BulletListItem from 'APP/Components/BulletListItem'
import Alert from 'APP/Converse/Alert'
import Typography from 'APP/Converse/Typography'

// Styling
import Styles from './style'
import Images from 'APP/Themes/Images'

interface Button {
  url: { [key: string]: string }
  label: { [key: string]: string }
}

interface BulletPoint {
  [key: string]: string
}

interface CustomSupportPage {
  buttons: Button[]
  bulletPoints: BulletPoint[]
  title: { [key: string]: string }
  body: { [key: string]: string }
}

const ContactUsScreen: React.FC = () => {
  const openURL = (url: string) => {
    return Linking.openURL(url).catch(() => {
      Alert.alert(I18n.t('CannotOpenUrl.title' as string), I18n.t('CannotOpenUrl.body') as string, [
        { text: I18n.t('CannotOpenUrl.ok') as string },
      ])
    })
  }

  const renderButtons = () => {
    const buttons = Config.CUSTOM_SUPPORT_PAGE?.buttons
    if (!buttons) return null

    return buttons.map((button: Button, index: number) => {
      if (!button || !button.url || !button.label) return null
      const action = () => {
        openURL(button.url[I18n.baseLocale])
      }
      return (
        <PrimaryButton
          key={index}
          title={button.label[I18n.baseLocale]}
          onPress={action}
          analyticsName="ContactUs"
        />
      )
    })
  }

  const renderBulletPoints = () => {
    const bullets = Config.CUSTOM_SUPPORT_PAGE?.bulletPoints
    if (!bullets) return null
    return bullets.map((bulletPoint: Button, index: number) => {
      return <BulletListItem key={index}>{bulletPoint[I18n.baseLocale]}</BulletListItem>
    })
  }

  const CustomSupport = Config.CUSTOM_SUPPORT_PAGE as CustomSupportPage

  return (
    <View style={Styles.fullPrimaryContainer}>
      <ScrollView
        alwaysBounceVertical={false}
        keyboardShouldPersistTaps="always"
        style={Styles.keyboardCoveredContent}
      >
        <View style={Styles.content}>
          <Image source={Images.logoBlue} style={Styles.splashLogo} />
          <Typography style={Styles.title}>{CustomSupport.title[I18n.baseLocale]}</Typography>
          <DialogueAutolink text={CustomSupport.body[I18n.baseLocale]} style={Styles.subtitle} />
          <View style={Styles.sectionBody}>{renderBulletPoints()}</View>
          <View style={Styles.buttonContainer}>{renderButtons()}</View>
        </View>
      </ScrollView>
    </View>
  )
}

export default ContactUsScreen