import React from 'react'
import { pick } from 'ramda'

import I18n from 'APP/Services/i18n'

// Components
import IntakeSingleChoice from '../IntakeSingleChoice'
import IntakeText from '../IntakeText'
import IntakeMultiChoice from '../IntakeMultiChoice'
import IntakeSearchChoice from '../IntakeSearchChoice'
import IntakeAddressSearch from '../IntakeAddressSearch'
import IntakePhoto from '../IntakePhoto'
import IntakeImageMap from '../IntakeImageMap'
import IntakeBooking from '../IntakeBooking'
import IntakeProviderList from '../IntakeProviderList'
import IntakeMultiSubPrompt from '../IntakeMultiSubPrompt'

const getOnAnswerSend =
  (props, sendAnswer, transformer) =>
  (...args) => {
    const answer = transformer(...args)
    sendAnswer({
      ...answer,
      props: {
        type: `${props.type}_response`,
        ...answer.props,
        ...pick(['member_app_fullscreen', 'member_app_visible', 'provider_app_visible'], props),
      },
    })
  }

export const INTAKE_MESSAGE_TYPES = {
  prompt_text: 'prompt_text',
  prompt_choice_single: 'prompt_choice_single',
  prompt_choice_multi: 'prompt_choice_multi',
  prompt_choice_search: 'prompt_choice_search',
  prompt_choice_image_map: 'prompt_choice_image_map',
  prompt_booking: 'prompt_booking',
  prompt_address: 'prompt_address',
  prompt_file: 'prompt_file',
  prompt_provider_list: 'prompt_provider_list',
  prompt_multi_subprompt: 'prompt_multi_subprompt',
}

export const REPLY_FORMS = {
  [INTAKE_MESSAGE_TYPES.prompt_text]: {
    createSubmitHandler: (...args) =>
      getOnAnswerSend(...args, (message) => ({
        display: message,
      })),
    component: IntakeText,
  },
  [INTAKE_MESSAGE_TYPES.prompt_choice_single]: {
    createSubmitHandler: (...args) =>
      getOnAnswerSend(...args, (choice) => ({
        display: choice.display,
        props: {
          selected_choice: choice,
        },
      })),
    component: IntakeSingleChoice,
  },
  [INTAKE_MESSAGE_TYPES.prompt_choice_multi]: {
    createSubmitHandler: (...args) =>
      getOnAnswerSend(...args, (choices) => ({
        display: choices.map(({ display }) => display).join(', '),
        props: {
          selected_choices: choices,
          ...pick(['payload', 'minimum_selection', 'maximum_selection'], args[0]),
        },
      })),
    component: IntakeMultiChoice,
  },
  [INTAKE_MESSAGE_TYPES.prompt_choice_search]: {
    createSubmitHandler: (...args) =>
      getOnAnswerSend(...args, (choice) => ({
        display: choice.display,
        props: {
          selected_choice: choice,
          ...pick(['payload', 'group_data'], args[0]),
        },
      })),
    component: IntakeSearchChoice,
  },
  [INTAKE_MESSAGE_TYPES.prompt_address]: {
    createSubmitHandler: (...args) =>
      getOnAnswerSend(...args, (place) => ({
        display: place.address,
        props: {
          selected_address: place,
          ...pick(['payload', 'place_type'], args[0]),
        },
      })),
    component: IntakeAddressSearch,
  },
  [INTAKE_MESSAGE_TYPES.prompt_choice_image_map]: {
    createSubmitHandler: (...args) =>
      getOnAnswerSend(...args, (choices, image_metadata) => ({
        display: choices.map(({ display }) => display).join(', '),
        props: {
          selected_choices: choices,
          image_metadata,
          ...pick(['payload', 'image_source'], args[0]),
        },
      })),
    component: IntakeImageMap,
  },
  [INTAKE_MESSAGE_TYPES.prompt_booking]: {
    createSubmitHandler: (...args) =>
      getOnAnswerSend(...args, ({ display, payload }) => ({
        display,
        props: {
          ...payload,
          ...pick(['payload', 'member_id'], args[0]),
        },
      })),
    component: IntakeBooking,
  },
  [INTAKE_MESSAGE_TYPES.prompt_file]: {
    createSubmitHandler: (...args) =>
      getOnAnswerSend(...args, (fileObj) => ({
        fileObj,
      })),
    component: IntakePhoto,
  },
  [INTAKE_MESSAGE_TYPES.prompt_provider_list]: {
    createSubmitHandler: (...args) =>
      getOnAnswerSend(...args, (provider) => ({
        display: provider.display,
        props: {
          selected_choice: provider,
        },
      })),
    component: IntakeProviderList,
  },
  [INTAKE_MESSAGE_TYPES.prompt_multi_subprompt]: {
    createSubmitHandler: (...args) =>
      getOnAnswerSend(...args, (promptAnswers, display) => {
        const { payload } = args[0]
        return {
          display,
          props: {
            payload,
            responses: promptAnswers,
          },
        }
      }),
    component: IntakeMultiSubPrompt,
  },
}

const IntakeReplyForm = ({ sendAnswer, ...rest }) => {
  const replyFormConfig = REPLY_FORMS[rest.type]

  if (
    replyFormConfig &&
    replyFormConfig.component &&
    typeof replyFormConfig.createSubmitHandler === 'function'
  ) {
    const Component = replyFormConfig.component
    const onAnswerSend = replyFormConfig.createSubmitHandler(rest, sendAnswer)

    return <Component {...rest} sendAnswer={onAnswerSend} />
  }

  return null
}

export default IntakeReplyForm
