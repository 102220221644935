import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  log: (message) => ({ type: 'LOG', message, timestamp: Date.now() }),
  logButtonPress: ['id'],
  error: ['message'],
  trackVideoQualityChange: ['current', 'previous'],
  triggerTrackScreen: null,
  triggerEvent: ['eventName', 'props'],
})

export const LoggerTypes = Types
export default Creators
