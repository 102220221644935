/* eslint-disable react/display-name */
import React from 'react'
import { Text } from 'react-native'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import BulletListItem from 'APP/Components/BulletListItem'

import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import Styles from './style'

const richTextOptions = {
  renderMark: {
    [MARKS.BOLD]: (children) => <Text style={Styles.bold}>{children}</Text>,
    [MARKS.ITALIC]: (children) => <Text style={Styles.italic}>{children}</Text>,
    [MARKS.UNDERLINE]: (children) => <Text style={Styles.underline}>{children}</Text>,
    [MARKS.CODE]: (children) => <Text style={Styles.text}>{children}</Text>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node, children) => <Text style={Styles.text}>{children}</Text>,
    [BLOCKS.HEADING_1]: (_node, children) => <Text style={Styles.h1}>{children}</Text>,
    [BLOCKS.HEADING_2]: (_node, children) => <Text style={Styles.h2}>{children}</Text>,
    [BLOCKS.HEADING_3]: (_node, children) => <Text style={Styles.h3}>{children}</Text>,
    [BLOCKS.HEADING_4]: (_node, children) => <Text style={Styles.h4}>{children}</Text>,
    [BLOCKS.UL_LIST]: (_node, children) => {
      return children
    },
    [BLOCKS.LIST_ITEM]: (_node, children) => {
      return (
        <BulletListItem
          key={children?.[0]?.props?.children?.[0]}
          bulletStyle={Styles.bulletStyle}
          textStyle={Styles.textBulletPoint}
        >
          {children}
        </BulletListItem>
      )
    },
  },
}
const RichTextRender = ({ text }) => {
  return documentToReactComponents(text, richTextOptions)
}

export default RichTextRender
