import { StyleSheet } from 'react-native'
import { ApplicationStyles, Metrics, Colors, Fonts } from 'APP/Themes/'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  title: {
    ...Fonts.style.subheaderBold,
    textAlign: 'center',
    color: Colors.text,
  },
  topSubtitle: {
    ...Fonts.style.body,
    textAlign: 'center',
    color: Colors.text,
    marginVertical: 12,
  },
  bottomSubtitle: {
    ...Fonts.style.body,
    textAlign: 'center',
    color: Colors.text,
    marginBottom: 12,
  },
  link: {
    fontFamily: Fonts.type.bold,
    fontWeight: 'bold',
    color: Colors.text,
    textDecorationLine: 'underline',
    textTransform: 'capitalize',
  },
  container: {
    display: 'flex',
    borderRadius: 20,
    backgroundColor: Colors.elementsBg,
    flexDirection: 'column',
    alignItems: 'center',
    ...ApplicationStyles.shadow,
    paddingTop: 16,
    paddingLeft: 12,
    paddingRight: 12,
    paddingBottom: Metrics.baseMargin,
    marginLeft: 12,
    marginRight: 12,
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  bodyAction: {
    ...Fonts.style.body,
    lineHeight: Fonts.size.h4,
    textAlign: 'center',
    color: Colors.text,
    marginTop: 12,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: Metrics.baseMargin,
  },
  inactiveContainer: {
    marginTop: 32,
    marginBottom: 32,
  },
  multiInactiveContainer: {
    marginBottom: 32,
  },
})
