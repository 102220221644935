import React from 'react'
import { View, Image, StyleProp, ViewStyle, ImageStyle } from 'react-native'

import { Images } from 'APP/Themes'
import Config from 'APP/Config'
import I18n from 'APP/Services/i18n'
import { Styles } from './style'
import Typography from 'APP/Converse/Typography'

interface DialogueBrandingProps {
  style?: StyleProp<ViewStyle>
}

const DialogueBranding: React.FC<DialogueBrandingProps> = ({ style = null }) => {
  return (
    Config.DIALOGUE_BRANDING_ENABLED && (
      <View style={[Styles.dialogueBrandingContainer, style]} testID="dialogueBrandingId">
        <Typography variant="bodyNormal">{I18n.t('DialogueBranding.poweredBy')}</Typography>
        <Image style={Styles.dialogueBrandingLogo as StyleProp<ImageStyle>} source={Images.logoBrand} />
      </View>
    )
  )
}

export default DialogueBranding