import React, { useEffect, useRef } from 'react'
import { View, Animated, Text, Easing } from 'react-native'

// -- Example: -- //
// <Animation text="😎" numberOfElems={35} />

const SingleAnimation = ({ text, disabled }) => {
  const yAnim = useRef(new Animated.Value(0)).current
  const xAnim = useRef(new Animated.Value(0)).current
  const opacityAnim = useRef(new Animated.Value(0)).current

  // Values that only need to be generated on element creation
  const { randomBaseLeft, randomConfettiTop, randomConfettiBottom, randomConfettiWobble } = useRef({
    randomBaseLeft: Math.floor(Math.random() * (60 - 40 + 1) + 40),
    randomConfettiTop: Math.floor(Math.random() * (200 - 25 + 1) + 25) * -1,
    randomConfettiBottom: Math.floor(Math.random() * (250 - 150 + 1) + 150),
    randomConfettiWobble: Math.floor(
      (Math.random() * (150 - 1 + 1) + 1) * (Math.round(Math.random()) ? 1 : -1)
    ),
  }).current

  // Values that change every time they're called
  const generateHigherVariety = () =>
    Math.floor((Math.random() * (6 - 3 + 1) + 5) * (Math.round(Math.random()) ? 1 : -1))
  const generateRandomFallSpeed = () => Math.floor(Math.random() * (300 - 50 + 1) + 50)

  useEffect(() => {
    if (disabled) return
    Animated.parallel([
      Animated.stagger(135, [
        // Show elements first
        Animated.timing(opacityAnim, {
          toValue: 0.1,
          duration: 10,
          useNativeDriver: true,
        }),
        // Initial "explosion" moving confetti from center out
        Animated.timing(xAnim, {
          toValue: randomConfettiWobble,
          duration: 175,
          useNativeDriver: true,
        }),
        // Wobble as confetti "falls"
        Animated.sequence([
          Animated.timing(xAnim, {
            toValue: randomConfettiWobble + generateHigherVariety(),
            duration: generateRandomFallSpeed(),
            useNativeDriver: true,
          }),
          Animated.timing(xAnim, {
            toValue: randomConfettiWobble + generateHigherVariety(),
            duration: generateRandomFallSpeed(),
            useNativeDriver: true,
          }),
          Animated.timing(xAnim, {
            toValue: randomConfettiWobble + generateHigherVariety(),
            duration: generateRandomFallSpeed(),
            useNativeDriver: true,
          }),
          Animated.timing(xAnim, {
            toValue: randomConfettiWobble + generateHigherVariety(),
            duration: generateRandomFallSpeed(),
            useNativeDriver: true,
          }),
        ]),
      ]),
      Animated.stagger(600, [
        // Confetti "explosion" first up, then down
        Animated.sequence([
          Animated.timing(yAnim, {
            toValue: randomConfettiTop,
            duration: 140,
            easing: Easing.ease,
            useNativeDriver: true,
          }),
          Animated.timing(yAnim, {
            toValue: randomConfettiBottom,
            duration: Math.floor(Math.random() * (1100 - 950 + 1) + 950),
            easing: Easing.quad,
            useNativeDriver: true,
          }),
        ]),
        // Fade to non-existence
        Animated.timing(opacityAnim, {
          toValue: 1,
          duration: 500,
          useNativeDriver: true,
        }),
      ]),
    ]).start()
  }, [disabled])

  return (
    <Animated.View
      style={{
        position: 'absolute',
        bottom: '50%',
        left: `${randomBaseLeft}%`,
        opacity: opacityAnim.interpolate({
          inputRange: [0, 0.1, 1],
          outputRange: [0, 1, 0],
        }),
        transform: [
          {
            translateY: yAnim,
          },
          {
            translateX: xAnim,
          },
        ],
      }}
    >
      <Text>{text}</Text>
    </Animated.View>
  )
}

const Animation = ({ text, numberOfElems, children, disabled, ...props }) => (
  <View {...props}>
    {children}
    {[...Array(numberOfElems)].map((e, i) => (
      <SingleAnimation key={i} disabled={disabled} text={text} />
    ))}
  </View>
)

export default Animation
