import {
  AdditionalInterest,
  Appointment,
  AppointmentTypeInput,
  AvailabilitySlot,
  CulturalBackground,
  EditAppointment,
  Gender,
  Language,
  Location,
  GenderAttributeDetails,
  CulturalBackgroundAttributeDetails,
  AdditionalInterestAttributeDetails,
  AllProviderMatchingAttributes,
  AppointmentTypeOutput,
  ProviderGender,
  CulturalBackgrounds,
  AdditionalInterests,
  OtherInterests,
} from '@dialogue/timekeeper'

export interface Provider {
  id: number
  givenName: string
  familyName: string
  picture: string
  bio?: string
  jobTitle?: string
}

export interface ProviderDetailsData {
  gender: ProviderGender
  culturalBackgrounds: CulturalBackgrounds
  additionalInterests: AdditionalInterests
  otherInterests: OtherInterests | null
  id: number
}

export interface ProviderDetails {
  loading: boolean
  error: Error | null
  data: null | ProviderDetailsData
}
export interface ProviderAttributes {
  genders: Array<GenderAttributeDetails>
  culturalBackgrounds: Array<CulturalBackgroundAttributeDetails>
  additionalInterests: Array<AdditionalInterestAttributeDetails>
}
export interface ProviderBookingState {
  selectedDay: string | null
  availabilities: {
    data: AvailabilitySlot[]
    loading: boolean
    error: Error | null
  }
  bookAppointmentRequest: {
    data: Appointment | null
    loading: boolean
    error: Error | null
  }
  providers: {
    data: Record<number, Provider>
    loadingList: boolean
    loadingBio: boolean
    error: Error | null
  }
  attributes: {
    loading: boolean
    error: Error | null
    data: null | ProviderAttributes
  }
  providerDetails: {
    loading: boolean
    error: Error | null
    data: null | ProviderDetailsData
  }
  appointmentTypes: {
    data: AppointmentTypeOutput[]
    loading: boolean
    error: Error | null
  }
  updateAppointmentRequest: {
    data: Appointment | null
    loading: boolean
    error: Error | null
  }
  selectedPreferences: AllProviderMatchingAttributes | null
}

export interface GetAvailabilitiesParams {
  appointment_type: AppointmentTypeInput
  location: Location
  language: Language
  provider?: number[]
  start_at: string
  end_at: string
  genders?: Gender[]
  cultural_backgrounds?: CulturalBackground[]
  additional_interests?: AdditionalInterest[]
}

export interface UpdateAppointmentParams {
  appointment_id: number
  member_id: number
  appointment: EditAppointment
}

export enum Attributes {
  GENDERS = 'genders',
  CULTURAL_BACKGROUNDS = 'cultural_backgrounds',
  ADDITIONAL_INTERESTS = 'additional_interests',
}
