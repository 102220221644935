import React, { useState, useEffect, useCallback, useRef } from 'react'
import styled from 'styled-components/native'
import Analytics from 'APP/Services/Analytics'

import I18n from 'APP/Services/i18n'
import { Images, Metrics } from 'APP/Themes'

import Typography from 'APP/Converse/Typography'
import Icon from 'APP/Converse/Icon'

const COPY_INDICATION_DELAY = 4000

const StyledTouchableOpacity = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: ${Metrics.baseMargin * 2}px;
`

const StyledIcon = styled(Icon)`
  margin-right: ${Metrics.baseMargin * 0.5}px;
  height: 20px;
  width: 20px;
`

export const ButtonShare = ({ onPress, analyticsName }) => {
  const [showCopied, setShowCopied] = useState(false)
  const timeoutRef = useRef()

  const handleOnPress = useCallback(() => {
    onPress()
    setShowCopied(true)
    clearTimeout(timeoutRef.current)
    Analytics.trackEvent('button_click', {
      button_value: 'Copy Link',
      screen: analyticsName,
    })

    timeoutRef.current = setTimeout(() => {
      setShowCopied(false)
    }, COPY_INDICATION_DELAY)
  }, [onPress])

  useEffect(
    () => () => {
      clearTimeout(timeoutRef.current)
    },
    []
  )

  const IconSrc = showCopied ? Images.copiedIcon : Images.copyIcon
  const IconTestID = showCopied ? 'copiedIcon' : 'copyIcon'

  return (
    <StyledTouchableOpacity onPress={handleOnPress}>
      <StyledIcon variant="svg" src={IconSrc} testID={IconTestID} color={'accent'} />
      <Typography variant="buttonText">{I18n.t('copyLink')}</Typography>
    </StyledTouchableOpacity>
  )
}

export default ButtonShare
