import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { openInbox } from 'react-native-email-link'
import { Trans } from 'react-i18next'

import I18n from 'APP/Services/i18n'

import LoginActions from 'APP/Redux/LoginRedux'
import PatientActions from 'APP/Redux/PatientRedux'
import usePrevious from 'APP/Hooks/usePrevious'
import { isMobile } from 'APP/Helpers/checkPlatform'

import Button from 'APP/Converse/Button'
import SignupLogoHeader from 'APP/Components/SignupLogoHeader'
import Typography from 'APP/Converse/Typography'
import { ResponsiveScrollView, ResponsiveView } from 'APP/Converse/Layout'

// Styles
import Styles from './style'
import { useDevTools } from 'APP/Lib/DevTools'

const VerifyEmailScreen = ({ navigation }) => {
  const dispatch = useDispatch()
  const [emailVerificationBlockTimeout, setEmailVerificationBlockTimeout] = useState(0)

  const { emailVerificationRunning, emailVerificationCheckSilent } = useSelector(
    (state) => state.login
  )
  const { email } = useSelector((state) => state.login?.customClaims)

  const { setToolName, setToolFunction } = useDevTools()

  useEffect(() => {
    setToolName('Skip')
    setToolFunction(() => () => {
      dispatch(PatientActions.skipEmailVerification())
    })
    return () => {
      setToolFunction(null)
      setToolName(null)
    }
  }, [dispatch, setToolName, setToolFunction])

  const startTimeout = () => {
    setEmailVerificationBlockTimeout(5)
    const interval = setInterval(
      () =>
        setEmailVerificationBlockTimeout((emailVerificationBlockTimeout) => {
          const timeLeft = emailVerificationBlockTimeout - 1 || 0
          if (!timeLeft) {
            clearInterval(interval)
          }
          return timeLeft
        }),
      1000
    )
  }

  const prevEmailVerificationRunning = usePrevious(emailVerificationRunning)
  useEffect(() => {
    if (
      prevEmailVerificationRunning &&
      !emailVerificationCheckSilent &&
      !emailVerificationRunning
    ) {
      startTimeout()
    }
  }, [emailVerificationRunning, emailVerificationCheckSilent, prevEmailVerificationRunning])

  useEffect(() => {
    dispatch(
      LoginActions.setEmailVerificationBlock(
        (emailVerificationRunning && !emailVerificationCheckSilent) ||
          !!emailVerificationBlockTimeout
      )
    )
  }, [
    dispatch,
    emailVerificationRunning,
    emailVerificationCheckSilent,
    emailVerificationBlockTimeout,
  ])

  const requestEmailVerificationCheck = () => {
    dispatch(LoginActions.requestEmailVerificationCheck())
  }

  const subtitle = (
    <Trans i18n={I18n} i18nKey="VerifyEmailScreen.subtitle" values={{ email }}>
      <Typography variant="bodyNormalBold">{{ email }}</Typography>
    </Trans>
  )

  return (
    <ResponsiveScrollView style={Styles.scrollContainer}>
      <ResponsiveView style={Styles.container}>
        <SignupLogoHeader title={I18n.t('VerifyEmailScreen.title')} subtitle={subtitle} />

        {isMobile() && (
          <Button
            testID="openInboxBtn"
            variant="primary"
            onPress={() => openInbox()}
            title={I18n.t('VerifyEmailScreen.openEmailApp')}
            widthVariant="full"
            style={Styles.button}
          />
        )}
        <Button
          testID="verifyEmailBtn"
          variant="secondary"
          onPress={requestEmailVerificationCheck}
          title={I18n.t('VerifyEmailScreen.verify')}
          isLoading={emailVerificationRunning && !emailVerificationCheckSilent}
          analyticsName="VerifyEmailScreen.verify"
          disabled={
            (emailVerificationRunning && !emailVerificationCheckSilent) ||
            !!emailVerificationBlockTimeout
          }
          widthVariant="full"
          style={Styles.button}
        />
        {!!emailVerificationBlockTimeout && (
          <View>
            <Typography variant="bodyNormal" align="center">
              {I18n.t('VerifyEmailScreen.notVerified')}
            </Typography>
            <Typography variant="bodyNormal" align="center">
              {I18n.t('VerifyEmailScreen.verificationBlock', {
                seconds: emailVerificationBlockTimeout,
              })}
            </Typography>
          </View>
        )}
        <Button
          testID="needHelpBtn"
          variant="tertiary"
          onPress={() => navigation.navigate('verifyEmailHelp')}
          title={I18n.t('VerifyEmailScreen.needHelp')}
          analyticsName="VerifyEmailScreen.verify"
          widthVariant="full"
          style={Styles.button}
        />
      </ResponsiveView>
    </ResponsiveScrollView>
  )
}

export default VerifyEmailScreen
