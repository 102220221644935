import React from 'react'
import { View, ScrollView, Linking } from 'react-native'
import { useNavigation } from '@react-navigation/native'

import { PrimaryButton, TertiaryButton } from 'APP/Components/Buttons'
import SignupLogoHeader from 'APP/Components/SignupLogoHeader'

import I18n from 'APP/Services/i18n'
import UrlBuilder from 'APP/Services/UrlBuilder'
import Config from 'APP/Config'
import Alert from 'APP/Converse/Alert'
import Styles from './style'

function LoginErrorScreen() {
  const navigation = useNavigation()
  const handlePressContactSupport = () => {
    if (Config.CUSTOM_SUPPORT_PAGE) {
      navigation.navigate('contactUs')
    } else {
      const url = UrlBuilder.supportEmailUrl()
      if (Linking.canOpenURL(url)) {
        Linking.openURL(url).catch(() => {
          Alert.alert(I18n.t('CannotOpenEmail.title'), I18n.t('CannotOpenEmail.body'), [
            { text: I18n.t('CannotOpenEmail.ok') },
          ])
        })
      }
    }
  }

  return (
    <ScrollView style={Styles.scrollContainer}>
      <View style={Styles.container}>
        <SignupLogoHeader
          title={I18n.t('LoginErrorScreen.title')}
          subtitle={I18n.t('LoginErrorScreen.subtitle')}
        />

        <View>
          <View>
            <PrimaryButton
              style={Styles.primaryButton}
              analyticsName="LoginErrorScreen.login"
              onPress={() => navigation.navigate('welcome', { openUniversalLogin: true })}
            >
              {I18n.t('LoginErrorScreen.login')}
            </PrimaryButton>
            <TertiaryButton
              style={Styles.primaryButton}
              analyticsName="LoginErrorScreen.contactSupport"
              onPress={handlePressContactSupport}
            >
              {I18n.t('Common.contactSupport')}
            </TertiaryButton>
          </View>
        </View>
      </View>
    </ScrollView>
  )
}

export default LoginErrorScreen
