export * from 'APP/Nav/Provider'
export * from 'APP/Nav/Utils'
export { StackScreens as NavigationRoot } from 'APP/Nav/Stack'

import { RootScreens } from 'APP/Lib/Enums'
import { TabBarSideMenu } from 'APP/Nav/TabSets/TabBar/SideMenu'

/*
  Index of side menus that are associated with screens.
  The CustomeStackMenuNavigator uses this index to render a side menu when the screen is mounted.
*/
export const STACK_SIDE_MENUS = {
  tabbar: TabBarSideMenu,
}

/*
  If a scene is deeplinked through branch, add it here so that we know to never change them.
  Only remove from this if you're sure that the external usage of that deeplink has ended completely.
*/
export const DEEP_LINKED_ROUTES = [
  'cmsAudioScreen',
  'cmsScreen',
  'cmsVideoScreen',
  'conversation',
  'habitDetailScreen',
  'wcChallenge',
  'verifyNewEmail',
]

/*
  We currently reload the bundle to render dark mode.
  We don't want to do that if the user is in an unrecoverable
  situation, like a video call.
*/
export const PREVENT_DARK_MODE_RESTART_ROUTES = ['videoCall', 'incomingCall']

/*
  We want some stuff to behave differently when we're on conversation screens
*/
export const CONVERSATION_ROUTES = ['conversation', 'newConversation', 'healthProfileConversation']

/*
  These are the screens which could be displaying
  realtime messages, so polling should be quick loop
*/
export const CURRENT_CHANNEL_POLL_ROUTES = [
  'conversation',
  'newConversation',
  'healthProfileConversation',
]

/*
  We don't want to show in-app notifications on some screens
*/
export const NOTIFICATION_BLACKLIST_ROUTES = ['videoCall', 'incomingCall']

/*
  We map many screens to different names for analytics.
  Could we slim this list down so there's a single definition for most of them?
*/
export const ANALYTICS_NAME_MAP = {
  loading: 'splash',
  microAppDrawer: 'microApp',
  infoWarning: 'signupErrorGeneric',
  infoWarning_noInvitationsFound: 'noInvitationsFound',
  newProfile: 'onboardingProfileScreen',
  geolocationService: 'requestGeolocationSystemAccess',
  updateGeolocation: 'requestGeolocationPermission',
  pushNotificationPermission: 'requestNotificationPermission',
  family: 'familyList',
  inviteFamilyMember: 'inviteAdultDependent',
  newDependent: 'inviteAdultDependent',
  dependentProfile: 'viewChildDependent',
  updateDependentPhotoId: 'viewChildDependentPhotoId',
  dependentConsent: 'inviteChildDependentConsent',
  editDependent: 'viewUpdateChildDependent',
  account: 'account',
  legal: 'infoLegal',
  updatePhotoId: 'viewPhotoId',
  addPayment: 'payment',
  emptyPayment: 'payment',
  conversation: 'chat',
  serviceCategory: 'secondaryScreen',
  serviceGroup: 'serviceGroupScreen',
  articlesTipsScreen: 'articleTipsList',
  cmsScreen: 'articlesTipsContent',
  bodyPart: 'bodyPartPicker',
  promotedContent: 'promotedContentScreen',
  scheduledLocalNotificationList: 'scheduledLocalNotificationListScreen',
  wcChallengeTeam: 'wellnessCentreTeamDetail',
  claimAccessScreen: 'claimAccess',
  eligibilityClaimScreen: 'eligibilityClaimScreen',
  claimAccessSuccessScreen: 'claimAccessSuccess',
  challengedEnrolmentsSearchScreen: 'enrolmentMultipleMatches',
  checkEmail: 'checkEmailScreen',
  challengedEnrolmentUniqueId: 'enrolmentUniqueId',
  profile: 'profile',
  seeMoreActiveHistory: 'seeMoreCompleteActiveHistory',
  [RootScreens.browse]: 'browseScreen',
  [RootScreens.chat]: 'chatScreen',
  [RootScreens.mind]: 'mindScreen',
  [RootScreens.today]: 'todayScreen',
  [RootScreens.move]: 'moveScreen',
}

/*
  This index is used to create a hardcoded mapping that links route names to
  their nested navigation structure.
*/
export const NESTED_SCREEN_INDEX = {
  [RootScreens.browse]: ['tabbar', { screen: RootScreens.browse }],
  [RootScreens.chat]: ['tabbar', { screen: RootScreens.chat }],
  [RootScreens.mind]: ['tabbar', { screen: RootScreens.mind }],
  [RootScreens.today]: ['tabbar', { screen: RootScreens.today }],
  [RootScreens.move]: ['tabbar', { screen: RootScreens.move }],
  /*
  deepNestExample: [
    'aRootScreen',
    {
      screen: '1xNestedScreen',
      params: { screen: '2xNestedScreen', params: { screen: 'deepNestExample' } },
    },
  ],
  */
}

export const ANALYTICS_SCREEN_BLACKLIST = ['tabbar']
