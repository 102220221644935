import { StyleSheet } from 'react-native'
import { Metrics } from 'APP/Themes'
import styled from 'styled-components/native'

export const Styles = StyleSheet.create({
  button: {
    marginBottom: Metrics.bottomSpace + Metrics.baseMargin,
  },
})

export const Container = styled.View`
  gap: ${({ theme }) => theme.metrics.baseMargin}px;
  text-align: right;
  padding-top: 2rem;
`

export const ButtonContainer = styled.View`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  gap: ${({ theme }) => theme.metrics.baseMargin}px;
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin * 4}px;
`
