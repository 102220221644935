import { WellnessCenter } from '@dialogue/services'

const { Challenges } = WellnessCenter

export const cleanupMetric = (metric, challengeMetric) => {
  const value = challengeMetric !== Challenges.Types.MetricName.STEPS ? metric / 1000 : metric

  let fixedMetric = +parseFloat(value).toFixed(2)
  const decimals = fixedMetric.toString().split('.')[1]

  if (decimals && decimals.length === 1) {
    fixedMetric = `${fixedMetric}0`
  }

  return fixedMetric.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
