import React, { PropsWithChildren } from 'react'
import styled from 'styled-components/native'

import Button, { ButtonProps } from 'APP/Converse/Button'
import Typography from 'APP/Converse/Typography'
import { Metrics } from 'APP/Themes'

import { BottomDrawerProvider } from './provider'
import { BottomDrawerContent } from './content'
import Header, { HeaderProps } from './header'

export const Subtitle = styled(Typography)`
  margin-bottom: ${Metrics.baseMargin}px;
`

export const Body = styled.View`
  align-items: flex-start;
  flex-direction: row;
  gap: ${Metrics.baseMargin / 1.6}px;
  justify-content: center;
  width: 100%;
`

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.text};
`

export const ContentContainer = styled(BottomDrawerContent)`
  padding: ${Metrics.baseMargin * 1.5}px;
  justify-content: center;
  flex-direction: row;
`

export const Content = styled.View`
  max-width: 600px;
  width: 100%;
`

export const Overlay = styled.Pressable`
  flex: 1;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: flex-end;
`

export type BottomDrawerProps = {
  onClose: () => void
  subtitle: string
  isVisible: boolean
  primaryButtonProps?: ButtonProps
  secondaryButtonProps?: ButtonProps
  testId?: string
} & PropsWithChildren &
  HeaderProps

/**
 *
 * A modal bottom drawer that can display a title, subtitle, action buttons, and custom content.
 * It is useful for presenting users with a choice or additional information that requires their attention.
 *
 * @param {BottomDrawerProps} props - The props for the BottomDrawer component.
 * @returns {JSX.Element} The rendered BottomDrawer component.

 */

function BottomDrawer({
  title,
  subtitle,
  isVisible,
  onClose,
  primaryButtonProps,
  secondaryButtonProps,
  testId = 'bottomDrawer',
  children,
  closeButtonProps,
}: BottomDrawerProps) {
  return (
    <BottomDrawerProvider isVisible={isVisible} onClose={onClose} testID={testId}>
      <Overlay
        onPress={onClose}
        accessibilityRole="button"
        accessibilityLabel="Close drawer"
        testID="bottomDrawerOverlay"
      >
        <ContentContainer>
          <Content>
            {title && <Header title={title} closeButtonProps={closeButtonProps} />}
            {subtitle && <Subtitle variant="bodyNormal">{subtitle}</Subtitle>}
            {children || (
              <Body>
                {secondaryButtonProps && (
                  <Button
                    variant="secondary"
                    widthVariant="flex"
                    textColor="error"
                    small
                    {...secondaryButtonProps}
                  />
                )}
                {primaryButtonProps && (
                  <Button variant="primary" widthVariant="flex" small {...primaryButtonProps} />
                )}
              </Body>
            )}
          </Content>
        </ContentContainer>
      </Overlay>
    </BottomDrawerProvider>
  )
}

export default BottomDrawer
