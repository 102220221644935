import React, { useEffect, useMemo, useRef, useState } from 'react'
import { View, Text, ActivityIndicator, TouchableOpacity, InteractionManager } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useSelector, useDispatch } from 'react-redux'
import Toast from 'react-native-toast-message'
import I18n from 'APP/Services/i18n'
import HabitsActions from 'APP/Redux/HabitsRedux'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import Analytics from 'APP/Services/Analytics'
import Animation from 'APP/Components/Animation'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import CMSContentList from 'APP/Components/CMSContentList'
import { Header, NoHabitsCard } from 'APP/Components/Habits'
import { ApplicationStyles, Metrics, Colors } from 'APP/Themes'
import Styles from './style'
import HabitCalendarModal from './HabitCalendarModal'
import HabitDailyProgressCalendar from './HabitDailyProgressCalendar'
import moment from 'moment'
import HabitActionModal, { HABIT_ACTION } from './HabitActionModal'
import { ScrollableItemWrapper, useDynamicScrollIntoView } from 'APP/Hooks/ScrollIntoView'
import { nth } from 'APP/Lib/Helpers'
import { isMobile } from 'APP/Helpers/checkPlatform'

const MORNING_CUTOFF = '11:00:00'
const EVENING_CUTOFF = '17:00:00'

const getDiffBetweenTwoTimes = (timeA, timeB) =>
  moment(timeA, ['HH:mm:ss']).diff(moment(timeB, ['HH:mm:ss']), 'hours')

const Habits = ({ style }) => {
  const navigation = useNavigation()
  const analyticsName = 'habitsSection'

  const dailyProgressRef = useRef()
  const [getScrollableItemProps, scrollIntoView] = useDynamicScrollIntoView()
  const dispatch = useDispatch()
  const {
    loading,
    retry,
    data: allHabits,
    progressStats,
  } = useSelector((state) => state.habits.habits)
  const { notifications } = useSelector((state) => state.habits.allNotificationConfigurations)

  const habitListDeferredActions = useSelector((state) => state.habits?.habitListDeferredActions)
  const doneHabitState = useSelector((state) => state.habits.doneHabit)
  const undoneHabitState = useSelector((state) => state.habits.undoneHabit)
  const supportingContent = useSelector((state) => state.habits.habitContent)
  const [habitActionModal, setHabitActionModal] = useState({ isVisible: false })
  const [progressModal, setProgressModal] = useState(null)
  const [habitJustCompleted, setHabitJustCompleted] = useState(null)
  const [supportingContentCupboard, setSupportingContentCupboard] = useState([])
  const today = useMemo(() => moment().startOf('day').format(I18n.t('DateFormat')))
  const [selectedDay, setSelectedDay] = useState(today)

  const isToday = selectedDay === today

  const habitsForSelectedDay = useMemo(() => {
    return allHabits
      .filter(({ cadence }) => !!cadence)
      .map((habit) => {
        const isHabitDone = habit.status.percentage === 100
        const isUserMarkingHabitAsDone =
          doneHabitState.id === habit.identifier && doneHabitState.loading
        const isUserMarkingHabitAsUnDone =
          undoneHabitState.id === habit.identifier && undoneHabitState.loading
        const hasSupportingContent =
          supportingContent[habit.identifier]?.data?.relatedContent?.length > 0

        let subtitle = null
        if (habit.prompt) {
          subtitle =
            habit.prompt.type == 'built-in'
              ? habit.prompt.message[I18n.baseLocale]
              : habit.prompt.custom_message
        }

        return {
          ...habit,
          isHabitDone,
          isUserMarkingHabitAsDone,
          isUserMarkingHabitAsUnDone,
          hasSupportingContent,
          isHabitToday: isToday,
          subtitle,
          ctaText: isToday
            ? I18n.t('Habits.habit.cta')
            : I18n.t('Habits.habit.ctaPastDay', {
                date: nth(moment(selectedDay).format('D'), I18n.baseLocale, {
                  gender: 'm',
                  skipOther: I18n.baseLocale === 'fr',
                }),
              }),
        }
      })
  }, [allHabits, doneHabitState, undoneHabitState, supportingContent])

  const reminderTimeParsedIntoTimezone =
    notifications.length === 1 && notifications[0]?.reminder_time
      ? moment.utc(notifications[0].reminder_time, ['HH:mm:ss']).local().format('HH:mm:ss')
      : null

  const hasOneMorningNotification =
    habitsForSelectedDay.length === 1 &&
    reminderTimeParsedIntoTimezone &&
    getDiffBetweenTwoTimes(reminderTimeParsedIntoTimezone, MORNING_CUTOFF) <= 0
  const hasOneEveningNotification =
    habitsForSelectedDay.length === 1 &&
    reminderTimeParsedIntoTimezone &&
    getDiffBetweenTwoTimes(reminderTimeParsedIntoTimezone, EVENING_CUTOFF) >= 0

  const fetchHabitList = () => dispatch(HabitsActions.getHabitList(selectedDay))

  const doneHabit = (id, title) => {
    Analytics.trackEvent('button_click', {
      button_value: `doneHabit: ${id} : ${title}`,
      trigger: analyticsName,
    })

    dispatch(HabitsActions.doneHabit(id, selectedDay))
    setHabitJustCompleted(id)
  }
  const resetDoneHabit = () => dispatch(HabitsActions.resetDoneHabit())
  const undoneHabit = (id, title) => {
    Analytics.trackEvent('button_click', {
      button_value: `undoneHabit: ${id} : ${title}`,
      trigger: analyticsName,
    })

    dispatch(HabitsActions.undoneHabit(id, selectedDay))
    setHabitJustCompleted(null)
  }
  const resetUndoneHabit = () => dispatch(HabitsActions.resetUndoneHabit())
  const resetDeferredActions = () => dispatch(HabitsActions.resetHabitListDeferredActions())
  const getHabitContent = (id) => dispatch(HabitsActions.getHabitContent(id))

  useEffect(() => {
    fetchHabitList()

    Analytics.trackScreen(analyticsName)

    return () => {
      resetDoneHabit()
      resetUndoneHabit()
    }
  }, [selectedDay])

  const refreshRef = useRef({
    isRefreshStarted: false,
  })
  const resetRefreshRef = () => {
    refreshRef.current = {
      isRefreshStarted: false,
    }
  }
  // clean up deferred actions on unmount
  useEffect(
    () => () => {
      resetDeferredActions()
      resetRefreshRef()
    },
    []
  )
  useEffect(() => {
    // No deferred actions
    if (!habitListDeferredActions) return
    const habitActions = habitListDeferredActions.habit
    const habitActionsAvailibleOnlyForTodayList = ['showPromptModal', 'showModifyModal'].some(
      (key) => !!habitActions?.[key]
    )

    // Process refresh
    const shouldNavigateToToday = habitActionsAvailibleOnlyForTodayList && selectedDay !== today
    const shouldRefresh = habitListDeferredActions.refresh || shouldNavigateToToday

    // Check if refresh was not done & is needed
    if (!refreshRef.current?.isRefreshStarted && shouldRefresh) {
      refreshRef.current = {
        isRefreshStarted: true,
        prevHabitList: habitsForSelectedDay,
      }
      if (shouldNavigateToToday) {
        // rely on selectedDay update to fetch data
        setSelectedDay(today)
      } else {
        fetchHabitList()
      }
      dailyProgressRef.current?.refresh()
      return
    }
    // Check if list update was awaited but has not happened yet
    if (
      refreshRef.current?.isRefreshStarted &&
      refreshRef.current?.prevHabitList === habitsForSelectedDay
    ) {
      // no-op, wait for update
      return
    } else {
      // clean up & continue
      resetRefreshRef()
    }

    // Process habit actions

    if (habitActions?.id) {
      const habitFromTheCurrentList = habitsForSelectedDay.find(
        (h) => h.identifier === habitActions.id
      )

      if (habitFromTheCurrentList) {
        // Act on cupboard
        if (habitActions.showCupboard) {
          const cupboardWasAlreadyOpened = supportingContentCupboard.includes(habitActions.id)

          // If they had this habit added at any point today with the cupboard open, don't bother re-opening the cupboard
          // (because it's already open)
          if (!cupboardWasAlreadyOpened) {
            const habitTitle = habitFromTheCurrentList?.title[I18n.baseLocale]

            toggleSupportingContentCupboard(habitActions.id, habitTitle)
          }
        }

        // Act on prompt/modify actions
        if (habitActions.showPromptModal && isMobile()) {
          openActionModal(habitActions.id, HABIT_ACTION.ADD_HABIT)
        }
        if (habitActions.showModifyModal) {
          openActionModal(habitActions.id)
        }
      }
    }

    // Clear the store, because we've acted on it
    resetDeferredActions()
  }, [habitListDeferredActions, habitsForSelectedDay, selectedDay])

  useEffect(() => {
    if (doneHabitState?.retry || undoneHabitState?.retry) {
      Toast.show({ text1: I18n.t('Habits.habit.retry') })
    }
  }, [doneHabitState, undoneHabitState])

  useEffect(() => {
    if (habitActionModal?.id && habitActionModal?.isVisible) {
      scrollIntoView(habitActionModal.id, {
        align: 'top',
        insets: { top: Metrics.statusBarHeight + Metrics.baseMargin },
      })
    }
  }, [habitActionModal?.id, habitActionModal?.isVisible])

  const navigateToExploreHabits = () => {
    setHabitJustCompleted(null)
    navigation.navigate('exploreHabitsScreen')
  }

  const openActionModal = (id, action) => {
    // Wrap the modal show call inside runAfterInteractions to avoid UI hiccups.
    // This ensures the modal only opens after completing all ongoing transitions.
    // This strategy prevents conflicts with any other potentially still transitioning modal.
    InteractionManager.runAfterInteractions(() => {
      setHabitActionModal({ id, action, isVisible: true })
    })
  }
  const closeActionModal = () => setHabitActionModal((prev) => ({ ...prev, isVisible: false }))

  const openProgressModal = (id, title, fromDate) => {
    Analytics.trackEvent('button_click', {
      button_value: `viewHabitProgress: ${id} : ${title}`,
      trigger: analyticsName,
    })

    setProgressModal({ id, fromDate })
  }

  const closeProgressModal = () => setProgressModal(null)

  const toggleSupportingContentCupboard = (id, title) => {
    if (supportingContentCupboard.includes(id)) {
      const list = supportingContentCupboard.filter((c) => c !== id)
      setSupportingContentCupboard(list)
    } else {
      Analytics.trackEvent('button_click', {
        button_value: `viewHabitSupportingContent: ${id} : ${title}`,
        trigger: analyticsName,
      })

      getHabitContent(id)
      setSupportingContentCupboard([...supportingContentCupboard, id])
    }
  }

  const completeCtaText = (id, ctaText) => {
    // On the day a habit has been done for the 3rd time, show unique copy
    if (progressStats[id]?.weekly?.thirdTimeCompletedOn === selectedDay) {
      const copy = I18n.t('Habits.habit.thirdTime').split(/(\[\[.*\]\])/)

      return (
        <>
          <Text style={Styles.habitCtaDoneText}>{copy[0]}</Text>
          <Text style={Styles.superscript}>{copy[1].slice(2).replace(/\]\]/, '')}</Text>
          <Text style={Styles.habitCtaDoneText}>{copy[2]}</Text>
        </>
      )
    }

    // Otherwise default to standard "✔️ Done today!"
    return (
      <>
        <MaterialIcon
          name="done"
          color={Colors.darkText}
          size={Metrics.icons.small}
          style={Styles.habitCtaIcon}
        />

        <Text style={Styles.habitCtaDoneText}>{ctaText}</Text>
      </>
    )
  }

  const renderWithConfetti = (id, children) => {
    // Show confetti if the member just marked the habit as done AND
    // it's the first time *ever* OR third time *this week* it was marked done AND
    // this CTA is the one the member just interacted with.
    const shouldRenderConfetti =
      !doneHabitState.loading &&
      doneHabitState?.id === id &&
      (progressStats[doneHabitState?.id]?.completedFirstTime ||
        progressStats[doneHabitState?.id]?.weekly?.timesCompleted === 3) &&
      habitJustCompleted === id

    if (!shouldRenderConfetti) {
      return children
    }

    return (
      <Animation text="🔥" numberOfElems={25}>
        <Animation text="💯" numberOfElems={20}>
          {children}
        </Animation>
      </Animation>
    )
  }

  return (
    <View testID="habits" style={[Styles.container, style]}>
      <Header onAddHabitPress={navigateToExploreHabits} />
      {!loading && isToday && (hasOneMorningNotification || hasOneEveningNotification) && (
        <View style={Styles.guidanceBar} testID="timeGuidance">
          <MaterialIcon
            name="nightlight-round"
            size={Metrics.icons.small}
            color={Colors.darkText}
            style={{ transform: [{ rotate: '-25deg' }] }}
          />
          <Text style={Styles.guidanceText} testID="timeGuidanceCopy">
            {hasOneMorningNotification && I18n.t('Habits.guidance.morning')}
            {hasOneEveningNotification && I18n.t('Habits.guidance.evening')}
          </Text>
        </View>
      )}
      {habitsForSelectedDay.length >= 3 && (
        <View style={Styles.guidanceBar} testID="tooManyGuidance">
          <MaterialIcon name="whatshot" size={Metrics.icons.small} color={Colors.darkText} />
          <Text style={Styles.guidanceText}>{I18n.t('Habits.guidance.tooMany')}</Text>
        </View>
      )}
      <HabitDailyProgressCalendar
        ref={dailyProgressRef}
        currentDay={today}
        selectedDay={selectedDay}
        onSelectedDayChange={setSelectedDay}
      />
      {loading && (
        <ActivityIndicator
          size="large"
          color={Colors.text}
          style={Styles.overlay}
          testID="loading"
        />
      )}
      {retry && (
        <DialogueTouchableOpacity
          style={Styles.overlay}
          analyticsName="Refresh"
          onPress={fetchHabitList}
          testID="retry"
        >
          <MaterialIcon
            name="refresh"
            size={Metrics.icons.large}
            color={Colors.text}
            style={Styles.retryIcon}
          />
        </DialogueTouchableOpacity>
      )}
      {!loading && !retry && (
        <>
          {!habitsForSelectedDay.length ? (
            <NoHabitsCard isToday={isToday} onAddHabitPress={navigateToExploreHabits} />
          ) : (
            <View style={Styles.wrapper}>
              <View style={Styles.habits}>
                {habitsForSelectedDay.map(
                  (
                    {
                      identifier,
                      title,
                      started_at,
                      ctaText,
                      isHabitDone,
                      isHabitToday,
                      isUserMarkingHabitAsDone,
                      isUserMarkingHabitAsUnDone,
                      hasSupportingContent,
                      subtitle,
                    },
                    i
                  ) => {
                    return renderWithConfetti(
                      identifier,
                      <ScrollableItemWrapper
                        style={[
                          Styles.habit,
                          isHabitDone ? Styles.habitCompleted : ApplicationStyles.shadow,
                          i === habitsForSelectedDay.length - 1 && Styles.lastHabit,
                        ]}
                        testID="habit"
                        key={identifier}
                        accessible
                        {...getScrollableItemProps(identifier)}
                      >
                        <Text style={isHabitDone ? Styles.habitTitleDone : Styles.habitTitle}>
                          {title[I18n.baseLocale]}
                        </Text>
                        {subtitle && (
                          <Text
                            style={[
                              Styles.habitSubtitle,
                              isHabitDone && Styles.habitSubtitleCompleted,
                            ]}
                            testID="habitSubtitle"
                          >
                            {subtitle}
                          </Text>
                        )}
                        <View style={Styles.habitActions}>
                          {isHabitDone ? (
                            <DialogueTouchableOpacity
                              style={Styles.habitCtaDone}
                              testID="habitCtaDone"
                              onPress={() => undoneHabit(identifier, title[I18n.baseLocale])}
                            >
                              {isUserMarkingHabitAsUnDone ? (
                                <ActivityIndicator color={Colors.darkText} style={Styles.spinner} />
                              ) : (
                                completeCtaText(identifier, ctaText)
                              )}
                            </DialogueTouchableOpacity>
                          ) : (
                            <DialogueTouchableOpacity
                              style={Styles.habitCta}
                              onPress={() => doneHabit(identifier, title[I18n.baseLocale])}
                              testID="habitCta"
                            >
                              {isUserMarkingHabitAsDone ? (
                                <ActivityIndicator
                                  color={Colors.textInverted}
                                  style={Styles.spinner}
                                />
                              ) : (
                                <Text style={Styles.habitCtaText}>{ctaText}</Text>
                              )}
                            </DialogueTouchableOpacity>
                          )}
                          <View style={Styles.habitHelpers}>
                            <TouchableOpacity
                              style={Styles.habitHelper}
                              onPress={() =>
                                openProgressModal(identifier, title[I18n.baseLocale], started_at)
                              }
                              testID="openProgressModal"
                              accessibilityLabel={I18n.t('AccessibilityIcons.habits.progress')}
                            >
                              <MaterialCommunityIcon
                                name="chart-timeline-variant"
                                size={25}
                                color={Colors.text}
                              />
                              {progressStats[identifier]?.weekly?.timesCompleted >= 3 && (
                                <Text style={Styles.progressFire}>🔥</Text>
                              )}
                            </TouchableOpacity>
                            {isHabitToday && (
                              <TouchableOpacity
                                style={Styles.habitHelper}
                                onPress={() => openActionModal(identifier)}
                                testID="openHabitActionsModal"
                                accessibilityLabel={I18n.t('AccessibilityIcons.habits.edit')}
                              >
                                <MaterialCommunityIcon
                                  name="cog-outline"
                                  size={26} // should be 25 but the trash icon is off by 1 pixel
                                  color={Colors.text}
                                />
                              </TouchableOpacity>
                            )}
                          </View>
                        </View>
                        <View>
                          <TouchableOpacity
                            onPress={() =>
                              toggleSupportingContentCupboard(identifier, title[I18n.baseLocale])
                            }
                            style={Styles.cupboardCta}
                            testID="habitCupboardCta"
                          >
                            <MaterialIcon
                              name={
                                supportingContentCupboard.includes(identifier)
                                  ? 'expand-less'
                                  : 'expand-more'
                              }
                              size={25}
                              color={Colors.accent}
                            />
                            <Text style={Styles.cupboardCtaText}>
                              {supportingContentCupboard.includes(identifier)
                                ? I18n.t('Habits.habit.openCupboard')
                                : I18n.t('Habits.habit.closedCupboard')}
                            </Text>
                          </TouchableOpacity>
                          {supportingContentCupboard.includes(identifier) && (
                            <View testID="habitCupboard">
                              {supportingContent[identifier]?.loading && (
                                <ActivityIndicator
                                  size="small"
                                  color={Colors.text}
                                  style={Styles.loadingContent}
                                  testID="loadingContent"
                                />
                              )}
                              {!supportingContent[identifier]?.loading &&
                                !supportingContent[identifier]?.retry &&
                                hasSupportingContent && (
                                  <CMSContentList
                                    data={supportingContent[identifier].data.relatedContent}
                                  />
                                )}
                              {(supportingContent[identifier]?.retry ||
                                (!supportingContent[identifier]?.loading &&
                                  !hasSupportingContent)) && (
                                <View testID="retryContent" style={Styles.retryContentWrapper}>
                                  <MaterialIcon
                                    name="error"
                                    size={Metrics.icons.medium}
                                    color={Colors.errorText}
                                  />
                                  <Text style={Styles.retryContentText}>
                                    {I18n.t('Habits.habit.cupboardError')}
                                  </Text>
                                </View>
                              )}
                            </View>
                          )}
                        </View>
                      </ScrollableItemWrapper>
                    )
                  }
                )}
              </View>
            </View>
          )}
        </>
      )}
      <HabitCalendarModal
        visible={progressModal !== null}
        {...progressModal}
        onClose={closeProgressModal}
      />
      <HabitActionModal
        isVisible={habitActionModal?.isVisible}
        habitId={habitActionModal?.id}
        action={habitActionModal?.action}
        selectedDay={selectedDay}
        onClose={closeActionModal}
      />
    </View>
  )
}

export default Habits
