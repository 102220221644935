import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import {
  AllProviderMatchingAttributes,
  Appointment,
  AppointmentTypeOutput,
  AvailabilitySlot,
  NewAppointment,
} from '@dialogue/timekeeper'
import {
  GetAvailabilitiesParams,
  ProviderBookingState,
  ProviderAttributes,
  Provider,
  UpdateAppointmentParams,
  ProviderDetailsData,
} from 'APP/Store/ProviderBooking/types'

const initialState: ProviderBookingState = {
  selectedDay: null,
  availabilities: {
    data: [],
    loading: false,
    error: null,
  },
  bookAppointmentRequest: {
    data: null,
    loading: false,
    error: null,
  },
  providers: {
    data: {},
    loadingList: false,
    loadingBio: false,
    error: null,
  },
  attributes: {
    loading: false,
    error: null,
    data: null,
  },
  //providerDetails is used to manage the information of a specific provider.
  providerDetails: {
    loading: false,
    error: null,
    data: null,
  },
  appointmentTypes: {
    data: [],
    loading: false,
    error: null,
  },
  updateAppointmentRequest: {
    data: null,
    loading: false,
    error: null,
  },
  selectedPreferences: null,
}

const { actions, reducer } = createSlice({
  name: 'providerBooking',
  initialState,
  reducers: {
    selectDay: (state, action: PayloadAction<string>) => {
      state.selectedDay = action.payload
    },
    getAvailabilities: (state, _: PayloadAction<GetAvailabilitiesParams>) => {
      state.availabilities.loading = true
    },
    getAvailabilitiesSuccess: (state, action: PayloadAction<AvailabilitySlot[]>) => {
      state.availabilities.loading = false
      state.availabilities.data = action.payload
    },
    getAvailabilitiesFailure: (state, action: PayloadAction<{ error: Error }>) => {
      state.availabilities.loading = false
      state.availabilities.error = action.payload.error
    },
    createAppointment: (state, _: PayloadAction<NewAppointment>) => {
      state.bookAppointmentRequest.loading = true
    },
    createAppointmentSuccess: (state, action: PayloadAction<Appointment>) => {
      state.bookAppointmentRequest.loading = false
      state.bookAppointmentRequest.data = action.payload
    },
    createAppointmentFailure: (state, action: PayloadAction<{ error: Error }>) => {
      state.bookAppointmentRequest.loading = false
      state.bookAppointmentRequest.error = action.payload.error
    },
    resetAppointment: (state) => {
      state.bookAppointmentRequest = {
        data: null,
        loading: false,
        error: null,
      }
      state.selectedPreferences = null
    },
    getProviders: (state, _: PayloadAction<number[]>) => {
      state.providers.loadingList = true
    },
    getProvidersSuccess: (state) => {
      state.providers.loadingList = false
      state.providers.error = null
    },
    getProvidersFailure: (state, action: PayloadAction<{ error: Error }>) => {
      state.providers.loadingList = false
      state.providers.error = action.payload.error
    },
    getProviderSuccess: (state, action: PayloadAction<Provider>) => {
      state.providers.data[action.payload.id] = action.payload
    },
    getProviderBio: (state, _: PayloadAction<number>) => {
      state.providers.loadingBio = true
    },
    getProviderBioSuccess: (
      state,
      action: PayloadAction<{ id: number; bio?: string; jobTitle?: string }>
    ) => {
      const providerData = state.providers.data[action.payload.id] || {}
      state.providers.loadingBio = false
      state.providers.data[action.payload.id] = {
        ...providerData,
        ...action.payload,
      }
    },
    getProviderBioFailure: (state) => {
      state.providers.loadingBio = false
    },
    getProvidersAttributes: (state) => {
      state.attributes.loading = true
    },
    getProvidersAttributesSuccess: (
      state,
      action: PayloadAction<{ attributes: ProviderAttributes }>
    ) => {
      state.attributes.loading = false
      state.attributes.data = action.payload.attributes
      state.attributes.error = null
    },
    getProvidersAttributesFailure: (state, action: PayloadAction<{ error: Error }>) => {
      state.attributes.loading = false
      state.attributes.error = action.payload.error
    },
    getProviderById: (state, _: PayloadAction<number>) => {
      state.providerDetails.loading = true
    },
    getProviderByIdSuccess: (
      state,
      action: PayloadAction<{ providerData: ProviderDetailsData }>
    ) => {
      state.providerDetails.loading = false
      state.providerDetails.data = action.payload.providerData
      state.providerDetails.error = null
    },
    getProviderByIdFailure: (state, action: PayloadAction<{ error: Error }>) => {
      state.providerDetails.loading = false
      state.providerDetails.error = action.payload.error
    },
    getAppointmentTypes: (state) => {
      state.appointmentTypes.loading = true
    },
    getAppointmentTypesSuccess: (state, action: PayloadAction<AppointmentTypeOutput[]>) => {
      state.appointmentTypes.loading = false
      state.appointmentTypes.data = action.payload
    },
    getAppointmentTypesFailure: (state, action: PayloadAction<{ error: Error }>) => {
      state.appointmentTypes.loading = false
      state.appointmentTypes.error = action.payload.error
    },
    updateAppointment: (state, _: PayloadAction<UpdateAppointmentParams>) => {
      state.updateAppointmentRequest.loading = true
      state.updateAppointmentRequest.error = null
      state.updateAppointmentRequest.data = null
    },
    updateAppointmentSuccess: (state, action: PayloadAction<Appointment>) => {
      state.updateAppointmentRequest.loading = false
      state.updateAppointmentRequest.data = action.payload
      state.updateAppointmentRequest.error = null
    },
    updateAppointmentFailure: (state, action: PayloadAction<{ error: Error }>) => {
      state.updateAppointmentRequest.loading = false
      state.updateAppointmentRequest.error = action.payload.error
      state.updateAppointmentRequest.data = null
    },
    updateSelectedPreferences: (
      state,
      action: PayloadAction<{ selectedPreferences: AllProviderMatchingAttributes }>
    ) => {
      state.selectedPreferences = action.payload.selectedPreferences
    },
  },
})

export { actions as providerBookingActions, initialState }

export default reducer
