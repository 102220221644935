import { Colors, Fonts, Metrics } from 'APP/Themes'

export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: Colors.bannerBg,
    paddingBottom: Metrics.baseMargin,
    paddingHorizontal: Metrics.baseMargin * 1.5,
    paddingTop: Metrics.baseMargin + Metrics.statusBarHeight,
    width: '100%',
    gap: Metrics.baseMargin / 2,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '85%',
  },
  titleText: {
    ...Fonts.style.h3,
    color: Colors.text,
    textAlign: 'center',
    flex: 1,
    flexShrink: 1,
  },
  closeButton: {
    paddingVertical: Metrics.baseMargin / 2,
    paddingLeft: Metrics.baseMargin / 2,
    alignSelf: 'flex-end',
  },
  closeIcon: {
    fontSize: Fonts.size.h4 + 2,
    color: Colors.text,
    borderRadius: 0,
  },
  secondaryTextContainer: {
    display: 'flex',
  },
  secondaryText: {
    ...Fonts.style.subheader,
    color: Colors.textSecondary, // switch to dim gray
    textAlign: 'center',
    lineHeight: 17.5,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  spacer: {
    flex: 1,
  },
  link: {
    hitSlop: 48, //FIXME: this is for accessibility purposes but not sure if it works correctly
    color: Colors.text,
    textDecorationLine: 'underline',
    fontWeight: 'bold',
  },
}
