import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Animated, Keyboard, View, TouchableWithoutFeedback } from 'react-native'
import { Input } from 'react-native-elements'

// Services
import I18n from 'APP/Services/i18n'

// Components
import { PrimaryButton, SecondaryButton } from 'APP/Components/Buttons'

// Utils
import { useKeyboardVisible } from 'APP/Hooks/Layout'

// Styles
import { Colors, Metrics } from 'APP/Themes'
import { Styles } from './style'

const IntakeText = ({ required = true, placeholder_text, disabled, sendAnswer }) => {
  const [answer, setAnswer] = useState()
  const isSubmitDisabled = disabled || !(typeof answer === 'string' && answer.trim())
  const inputRef = useRef()
  const onContainerPress = () => {
    if (inputRef.current && !inputRef.current.isFocused()) {
      inputRef.current.focus()
    }
  }
  const onNextPress = () => {
    if (!disabled && !!answer) {
      Keyboard.dismiss()
      sendAnswer(answer)
    }
  }
  const onSkipPress = () => {
    sendAnswer(I18n.t('IntakeComponent.skipped'))
  }
  const onTextChange = useCallback(
    (txt) => {
      setAnswer(txt.replace(/(\r\n|\n|\r)/gm, ''))
    },
    [setAnswer]
  )

  const isKeyboardVisible = useKeyboardVisible()

  const translateY = useRef(new Animated.Value(0)).current

  useEffect(() => {
    Animated.timing(translateY, {
      toValue: isKeyboardVisible ? Metrics.bottomSpace : 0,
      duration: 400,
      useNativeDriver: true,
    }).start()
  }, [isKeyboardVisible, translateY])

  return (
    <TouchableWithoutFeedback onPress={onContainerPress}>
      <Animated.View style={[Styles.container, { transform: [{ translateY }] }]}>
        <Input
          onChangeText={onTextChange}
          value={answer}
          ref={inputRef}
          returnKeyType="next"
          disabled={disabled}
          onSubmitEditing={onNextPress}
          placeholder={placeholder_text}
          placeholderTextColor={Colors.buttonPrimarySelected}
          inputStyle={Styles.textInput}
          multiline
          inputContainerStyle={Styles.textInputContainer}
          selectionColor={Colors.text}
          renderErrorMessage={false}
        />
        <View style={Styles.actionsContainer}>
        {
          required || answer ? (
            <PrimaryButton
              slim
              disabled={isSubmitDisabled}
              title={I18n.t('IntakeComponent.next')}
              analyticsName="IntakeComponent.text.next"
              onPress={onNextPress}
            />
          ) : (
            <SecondaryButton
              slim
              title={I18n.t('IntakeComponent.skip')}
              analyticsName="IntakeComponent.text.skip"
              onPress={onSkipPress}
            />
          )
        }
        </View>
      </Animated.View>
    </TouchableWithoutFeedback>
  )
}

export default IntakeText
