import moment from 'moment'

export const getWeeks = (start, end) => {
  const endDay = moment(end).endOf('month')
  const startDay = moment(start).startOf('month')
  // show at least 2 months
  if (startDay.isSame(endDay, 'M')) {
    startDay.subtract(1, 'M')
  }
  startDay.startOf('isoWeek')

  const weekIndex = startDay.clone().startOf('isoWeek')
  const weeks = []

  while (endDay.isSameOrAfter(weekIndex, 'isoWeek')) {
    const day = weekIndex.clone().subtract(1, 'day')
    weeks.push(
      Array(7)
        .fill(0)
        .map(() => day.add(1, 'day').clone())
    )
    weekIndex.add(1, 'week')
  }

  return weeks
}
