import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { View, Text } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import Toast from 'react-native-toast-message'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import I18n from 'APP/Services/i18n'
import HabitsActions from 'APP/Redux/HabitsRedux'
import List from 'APP/Components/List'
import ListItem from 'APP/Converse/ListItem'
import { PrimaryButton, TertiaryButton } from 'APP/Components/Buttons'
import Styles, {
  StyledPromptOptionTitle,
  StyledPromptOption,
  StyledHighlightedPromptOption,
  StyledActivityIndicator,
  StyledHighlightedActivityIndicator,
} from './style'
import { Metrics, Colors } from 'APP/Themes'
import TextInput from 'APP/Converse/Inputs/TextInput'

const useHabitPrompt = ({ habit, selectedDay }) => {
  const dispatch = useDispatch()
  const removeHabitPromptState = useSelector((state) => state.habits.removeHabitPrompt)
  const updateHabitPromptState = useSelector((state) => state.habits.updateHabitPrompt)
  const isLoading = updateHabitPromptState.loading || removeHabitPromptState.loading
  const submittingPromptId =
    isLoading && (updateHabitPromptState.promptId || removeHabitPromptState.promptId)

  const removeHabitPrompt = (prompt) =>
    dispatch(HabitsActions.removeHabitPrompt(habit.identifier, prompt, selectedDay))
  const updateHabitPrompt = (prompt) =>
    dispatch(HabitsActions.updateHabitPrompt(habit.identifier, prompt, selectedDay))

  // Error handling
  useEffect(() => {
    if (updateHabitPromptState?.retry) {
      Toast.show({ text1: I18n.t('Habits.updateOrRemovePrompt.error') })
      dispatch(HabitsActions.resetUpdateHabitPrompt())
    }

    if (removeHabitPromptState?.retry) {
      Toast.show({ text1: I18n.t('Habits.updateOrRemovePrompt.error') })
      dispatch(HabitsActions.resetRemoveHabitPrompt())
    }
  }, [updateHabitPromptState, removeHabitPromptState])

  return {
    submittingPromptId,
    removeHabitPrompt,
    updateHabitPrompt,
  }
}

export const HabitPrompt = ({
  habit,
  showGuidance,
  processing,
  selectedDay,
  goToHabitCustomPrompt,
  testID,
}) => {
  const { submittingPromptId, removeHabitPrompt, updateHabitPrompt } = useHabitPrompt({
    habit,
    selectedDay,
  })

  const isBuiltInPrompt = (prompt) => prompt.type === 'built-in'

  const isPromptOptionHighlighted = useCallback(
    (promptOption) => {
      if (!habit.prompt || !isBuiltInPrompt(habit.prompt)) {
        return false
      }
      return promptOption.identifier === habit?.prompt?.identifier
    },
    [habit]
  )

  const onPromptOptionPress = useCallback(
    (prompt) => {
      isPromptOptionHighlighted(prompt) ? removeHabitPrompt(prompt) : updateHabitPrompt(prompt)
    },
    [habit]
  )

  const listItems = useMemo(() => {
    if (!habit?.prompt_options) {
      return []
    }
    return habit.prompt_options.map((promptOption) => {
      const isLoading = submittingPromptId === promptOption.identifier
      return {
        promptOption,
        props: {
          disabled: processing,
          analyticsName: `${promptOption.identifier}:${promptOption.message['en']}`,
          title: promptOption.message[I18n.baseLocale],
          hideIconComponent: !isLoading,
          IconComponent:
            isLoading &&
            (isPromptOptionHighlighted(promptOption)
              ? StyledHighlightedActivityIndicator
              : StyledActivityIndicator),
        },
      }
    })
  }, [habit, processing, submittingPromptId])

  const renderBoldText = (copy) => {
    const separateCopyByBoldParams = copy.split(/(\[\[.*\]\])/g)

    return (
      <>
        {separateCopyByBoldParams[0]}
        {separateCopyByBoldParams[1] && (
          <Text style={Styles.bold}>
            {separateCopyByBoldParams[1].replace('[[', '').replace(']]', '')}
          </Text>
        )}
        {separateCopyByBoldParams[2]}
      </>
    )
  }

  return (
    <View style={Styles.container} testID={testID}>
      {showGuidance && (
        <View testID="promptGuidance" style={Styles.guidanceBar}>
          <MaterialIcon
            name="check-circle-outline"
            size={Metrics.icons.small}
            color={Colors.darkText}
          />
          <Text style={Styles.guidanceText}>
            {renderBoldText(I18n.t('Habits.updateOrRemovePrompt.banner'))}
          </Text>
        </View>
      )}
      <View style={Styles.listWrapper} testID="prompts">
        <List containerStyle={Styles.list}>
          <ListItem
            disabled
            containerStyle={Styles.titleContainer}
            title={habit.title[I18n.baseLocale] + '...'}
            TitleComponent={StyledPromptOptionTitle}
            hideIconComponent
          />
          {listItems.map(({ props, promptOption }) => {
            const isHighlighted = isPromptOptionHighlighted(promptOption)
            return (
              <ListItem
                {...props}
                containerStyle={{
                  backgroundColor: isHighlighted ? Colors.acceptButtonRipple : null,
                }}
                TitleComponent={isHighlighted ? StyledHighlightedPromptOption : StyledPromptOption}
                testID="promptOption"
                key={promptOption.identifier}
                onPress={() => onPromptOptionPress(promptOption)}
              />
            )
          })}
          <ListItem
            disabled={processing}
            analyticsName="custom prompt options"
            containerStyle={{
              backgroundColor: habit?.prompt?.type === 'custom' ? Colors.acceptButtonRipple : null,
            }}
            title={I18n.t('Habits.updateOrRemovePrompt.otherPromptOption')}
            TitleComponent={
              habit?.prompt?.type === 'custom' ? StyledHighlightedPromptOption : StyledPromptOption
            }
            hideIconComponent
            bottomDivider={false}
            testID="promptOption"
            onPress={() => goToHabitCustomPrompt()}
          />
        </List>
      </View>
    </View>
  )
}

export const HabitCustomPrompt = ({
  habit,
  processing,
  selectedDay,
  maxCustomMessageLength = 30,
  goToHabitPrompt,
  testID,
}) => {
  const { removeHabitPrompt, updateHabitPrompt } = useHabitPrompt({ habit, selectedDay })
  const [customMessageInputValue, setCustomMessageInputValue] = useState(
    () => habit?.prompt?.custom_message || ''
  )

  const ctaIsDisabled = habit?.prompt?.custom_message
    ? customMessageInputValue === habit?.prompt?.custom_message
    : !customMessageInputValue

  const onSubmit = () => {
    if (!customMessageInputValue && habit?.prompt?.custom_message) {
      removeHabitPrompt(habit.prompt)

      return
    }

    updateHabitPrompt({
      custom_message: customMessageInputValue,
    })
  }

  return (
    <View style={Styles.container} testID={testID}>
      <TextInput
        testID="HABIT_TEXT_INPUT"
        value={customMessageInputValue}
        maxLength={maxCustomMessageLength}
        onChangeText={setCustomMessageInputValue}
        placeholder={I18n.t('Habits.updateCustomPrompt.customMessageInputPlaceholder')}
        disabled={processing}
        autoFocus={true}
      />
      <View style={Styles.customMessageInputHintContainer}>
        <Text style={Styles.customMessageInputHintText}>
          {customMessageInputValue?.length || 0}/{maxCustomMessageLength}
        </Text>
      </View>
      <PrimaryButton
        disabled={processing || ctaIsDisabled}
        onPress={onSubmit}
        title={I18n.t('Habits.updateCustomPrompt.save')}
        showActivityIndicator={processing}
        activityIndicatorColor={Colors.textInverted}
        style={Styles.customMessageSubmitButton}
      />
      <TertiaryButton
        disabled={processing}
        onPress={goToHabitPrompt}
        title={I18n.t('Habits.updateCustomPrompt.viewOptions')}
        style={Styles.viewOptionsCta}
      />
    </View>
  )
}
