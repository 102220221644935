import React, { useContext, memo } from 'react'
import Analytics from 'APP/Services/Analytics'
import { useNavigation } from '@react-navigation/native'

import I18n from 'APP/Services/i18n'
import { ChatContext, PostContext } from 'APP/Lib/Context'
import { useCallback } from 'react'
import ActionCard from 'APP/Components/FelixChat/ActionCard'

const CarePlan = () => {
  const { channelId } = useContext(ChatContext)
  const { post, encounter } = useContext(PostContext)
  const navigation = useNavigation()

  const onPress = useCallback(() => {
    Analytics.trackEvent('button_click', { button_value: 'Care plan tap' })
    navigation.navigate('carePlan', { carePlan: encounter, channelId: channelId })
  }, [encounter, channelId])

  return (
    <ActionCard
      title={post.message}
      subtitle={I18n.t('CarePlanList.isReady')}
      iconName="file-plus-outline"
      onPress={onPress}
    />
  )
}

export default memo(CarePlan)
