import { StyleSheet } from 'react-native'
import { ApplicationStyles } from '../../Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  listContainer: {
    ...ApplicationStyles.listLine,
    marginTop: 0,
  },
})
