import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import DeviceInfo from 'APP/Helpers/DeviceInfo'
import { PublicComputerType } from 'APP/Lib/Enums'
// Change this number to force logout users the next time they update
export const TOKEN_VERSION = 7

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  rehydrateReducer: ['entries'],
  // Login
  loginRequest: [],
  loginRequestWithConnection: ['connection', 'options'],
  silentLoginRequestWithConnection: ['connection'],
  signupRequest: [],
  signupRequestWithConnection: ['connection'],
  loginSuccess: [
    'username',
    'idToken',
    'accessToken',
    'refreshToken',
    'tokenExpiration',
    'customClaims',
    'secondaryAccessToken',
  ],
  clearSecondaryAccessToken: null,
  loginFetchComplete: null,
  loginFailure: ['errorCode'],
  accountLinkLoginRequest: [],
  setConnection: ['connection'],
  clearConnection: null,

  // Email Verification
  requestEmailVerificationCheck: null,
  emailVerificationCheck: ['silent'],
  emailVerificationCheckFailure: null,
  emailVerificationCheckSuccess: null,

  // Resend Verification Email
  emailVerificationResendRequest: null,
  emailVerificationResendSuccess: null,
  emailVerificationResendFailure: ['error'],

  setEmailVerificationBlock: ['emailVerificationBlock'],

  // Toggle MFA
  mandatoryMfaRequest: null,
  toggleMfaRequest: null,
  toggleMfaSuccess: null,
  toggleMfaFailure: null,

  // Setters
  setUsername: ['username'],
  setMessagingCredentials: ['userData'],
  refreshAuthSuccess: ['idToken', 'accessToken', 'refreshToken', 'tokenExpiration', 'customClaims'],
  refreshAuthFailure: ['error'],

  // Misc Actions
  requestResetPassword: ['username'],
  logout: ['reason'],
  startRefreshAuthPoll: null,
  stopRefreshAuthPoll: null,
  clearState: null,
  setIsLoggingIn: ['data'],
  setIsLoggingOut: ['data'],

  //public computer
  submitPublicComputer: ['data'],
  updateLastActivity: null,
})

export const LoginTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  username: null,
  error: null,
  fetching: false,
  idToken: null,
  accessToken: null,
  secondaryAccessToken: null,
  refreshToken: null,
  tokenExpiration: null,
  tokenVersion: TOKEN_VERSION,
  emailVerificationCheckSilent: false,
  emailVerificationRunning: false,
  emailVerificationResendRunning: false,
  emailVerificationResendFailed: false,
  creationTime: null,
  customClaims: {},
  messagingUserId: null,
  messagingTeamId: null,
  messagingToken: null,
  messagingCookie: null,
  signupFormConfig: {},
  signupProfileData: null,
  toggleMfaRequestRunning: false,
  toggleMfaRequestFailed: false,
  connection: null,
  clearState: null,
  isLoggingIn: false,
  isLoggingOut: false,

  publicComputer: PublicComputerType.NO_ANSWER,
  deviceId: null,
  lastActivity: null,

  emailVerificationBlock: false,
})

/* ------------- Reducers ------------- */

export const rehydrateReducer = (state, { entries }) => state.merge(entries)

export const loginRequest = (state) => state.merge({ fetching: true, error: null })

export const loginRequestWithConnection = (state) => state.merge({ fetching: true, error: null })

export const signupRequest = (state) => state.merge({ fetching: true, error: null })

export const clearSecondaryAccessToken = (state) => state.merge({ secondaryAccessToken: null })

// we've successfully logged in
export const success = (
  state,
  {
    username,
    idToken,
    accessToken,
    refreshToken,
    tokenExpiration,
    customClaims = {},
    secondaryAccessToken = null,
  }
) =>
  state.merge({
    error: null,
    username,
    idToken,
    customClaims,
    refreshToken,
    tokenExpiration,
    accessToken,
    // Storing 2 access tokens is required when linking accounts
    secondaryAccessToken,
    tokenVersion: TOKEN_VERSION,
    // Remove the password from the store
    password: undefined,
    deviceId: state.deviceId || DeviceInfo.getUniqueId(),
  })

// we've had a problem logging in
export const failure = (state, { errorCode }) =>
  state.merge({ fetching: false, error: { errorCode } })

export const fetchComplete = (state) => state.merge({ fetching: false })

export const logout = (state) => state.merge({ ...INITIAL_STATE, deviceId: state.deviceId })

export const setConnection = (state, { connection }) => state.merge({ connection })

export const clearConnection = (state) => state.merge({ connection: null })

export const resetLoginError = (state) => {
  return state.merge({
    error: null,
  })
}

export const refreshAuthSuccess = (
  state,
  { idToken, accessToken, refreshToken, tokenExpiration, customClaims = {} }
) =>
  state.merge({
    idToken,
    refreshToken,
    accessToken,
    tokenExpiration,
    tokenVersion: TOKEN_VERSION,
    customClaims,
  })

export const refreshAuthFailure = (state, { error }) =>
  state.merge({
    error,
  })

export const setMessagingCredentials = (state, action) => {
  const userData = action.userData
  return state.merge({
    messagingUserId: userData.id,
    messagingTeamId: userData.props.team_id,
  })
}

export const emailVerificationCheck = (state, { silent = false }) =>
  state.merge({
    emailVerificationRunning: true,
    emailVerificationCheckSilent: silent,
  })
export const emailVerificationCheckFailure = (state) =>
  state.merge({
    emailVerificationRunning: false,
    emailVerificationCheckSilent: false,
  })
export const emailVerificationCheckSuccess = (state) =>
  state.merge({
    emailVerificationRunning: false,
    emailVerificationCheckSilent: false,
  })

export const emailVerificationResendRequest = (state) =>
  state.merge({
    emailVerificationResendRunning: true,
    emailVerificationResendFailed: false,
  })
export const emailVerificationResendFailure = (state) =>
  state.merge({
    emailVerificationResendRunning: false,
    emailVerificationResendFailed: true,
  })
export const emailVerificationResendSuccess = (state) =>
  state.merge({ emailVerificationResendRunning: false })

export const toggleMfaRequest = (state) =>
  state.merge({ toggleMfaRequestRunning: true, toggleMfaRequestFailed: false })

export const toggleMfaSuccess = (state) =>
  state.merge({ toggleMfaRequestRunning: false, toggleMfaRequestFailed: false })

export const toggleMfaFailure = (state) =>
  state.merge({ toggleMfaRequestRunning: false, toggleMfaRequestFailed: true })

export const setIsLoggingIn = (state, action) => state.merge({ isLoggingIn: action.data })

export const setIsLoggingOut = (state, action) => state.merge({ isLoggingOut: action.data })

export const clearState = (state) => state.merge({ ...INITIAL_STATE })

export const submitPublicComputer = (state, action) =>
  state.merge({ publicComputer: action.data.publicComputer })

export const updateLastActivity = (state) => state.merge({ lastActivity: Date.now() })

export const setEmailVerificationBlock = (state, { emailVerificationBlock }) => {
  return state.merge({ emailVerificationBlock })
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.REHYDRATE_REDUCER]: rehydrateReducer,
  [Types.EMAIL_VERIFICATION_CHECK]: emailVerificationCheck,
  [Types.EMAIL_VERIFICATION_CHECK_FAILURE]: emailVerificationCheckFailure,
  [Types.EMAIL_VERIFICATION_CHECK_SUCCESS]: emailVerificationCheckSuccess,
  [Types.EMAIL_VERIFICATION_RESEND_REQUEST]: emailVerificationResendRequest,
  [Types.EMAIL_VERIFICATION_RESEND_FAILURE]: emailVerificationResendFailure,
  [Types.EMAIL_VERIFICATION_RESEND_SUCCESS]: emailVerificationResendSuccess,
  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.ACCOUNT_LINK_LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_REQUEST_WITH_CONNECTION]: loginRequestWithConnection,
  [Types.SILENT_LOGIN_REQUEST_WITH_CONNECTION]: loginRequestWithConnection,
  [Types.SIGNUP_REQUEST]: signupRequest,
  [Types.SIGNUP_REQUEST_WITH_CONNECTION]: signupRequest,
  [Types.LOGIN_SUCCESS]: success,
  [Types.CLEAR_SECONDARY_ACCESS_TOKEN]: clearSecondaryAccessToken,
  [Types.LOGIN_FETCH_COMPLETE]: fetchComplete,
  [Types.LOGIN_FAILURE]: failure,
  [Types.SET_CONNECTION]: setConnection,
  [Types.CLEAR_CONNECTION]: clearConnection,
  [Types.SET_MESSAGING_CREDENTIALS]: setMessagingCredentials,
  [Types.REFRESH_AUTH_SUCCESS]: refreshAuthSuccess,
  [Types.MANDATORY_MFA_REQUEST]: toggleMfaRequest,
  [Types.TOGGLE_MFA_REQUEST]: toggleMfaRequest,
  [Types.TOGGLE_MFA_SUCCESS]: toggleMfaSuccess,
  [Types.TOGGLE_MFA_FAILURE]: toggleMfaFailure,
  [Types.LOGOUT]: logout,
  [Types.SET_IS_LOGGING_IN]: setIsLoggingIn,
  [Types.SET_IS_LOGGING_OUT]: setIsLoggingOut,
  [Types.CLEAR_STATE]: clearState,

  [Types.SUBMIT_PUBLIC_COMPUTER]: submitPublicComputer,
  [Types.UPDATE_LAST_ACTIVITY]: updateLastActivity,

  [Types.SET_EMAIL_VERIFICATION_BLOCK]: setEmailVerificationBlock,
})

export const selectLoginState = (state) => state.login
export const selectAccessToken = (state) => state.login.accessToken
