import React, { memo, useMemo } from 'react'
import { View } from 'react-native'

import {
  VictoryLabel,
  VictoryChart,
  VictoryArea,
  VictoryPolarAxis,
  VictoryGroup,
  VictoryScatter,
} from 'victory-native'
import I18n from 'APP/Services/i18n'

import ActiveScore from './ActiveScore'
import { getScoreRange, RANGE_COLORS } from './config'

import { Colors, Metrics } from 'APP/Themes'
import Styles, { MAX_HEIGHT } from './style'
import { useOnLayout } from 'APP/Hooks/Layout'
import useScreenView from 'APP/Hooks/useScreenView'
import Typography from 'APP/Converse/Typography'

const FocusedDataComponent = (p) => {
  const { isBigScreen } = useScreenView()
  return (
    <ActiveScore
      {...p}
      renderContent={(props) => (
        <View style={{ alignItems: 'center' }}>
          <Typography
            variant={isBigScreen ? 'caption' : 'subheaderBold'}
            style={Styles.scoreTooltip}
          >
            {I18n.t(`WellBeingIndexScreen.domains.${props.datum.x}`)}
          </Typography>
          <View style={Styles.scoreValueContainer}>
            <View
              style={[
                Styles.scoreValuePoint,
                { backgroundColor: RANGE_COLORS[getScoreRange(props.datum.y - 1)] },
              ]}
            />
            <Typography
              variant={isBigScreen ? 'caption' : 'subheaderBold'}
              style={Styles.scoreTooltip}
            >
              {I18n.t(`WellBeingIndexScreen.scores.${getScoreRange(props.datum.y - 1)}`)}
            </Typography>
          </View>
        </View>
      )}
    />
  )
}

const FocusChart = ({ breakdown, focus, score_range, limitedSpace }) => {
  const [{ height }, onLayout] = useOnLayout()
  const [data, dataFocused] = useMemo(() => {
    const dataSource = Object.entries(breakdown || {}).map(([key, value]) => ({
      x: key,
      y: value + 1,
    }))
    const dataSourceFocused = dataSource.find(({ x }) => x === focus)

    return [dataSource, dataSourceFocused ? [dataSourceFocused] : []]
  }, [breakdown, focus])

  const getTickLabelText = (index) => {
    const domain = data?.[index]?.x
    return domain ? I18n.t(`WellBeingIndexScreen.domains.${domain}`) : ''
  }

  if (!data?.length || !dataFocused) return null
  return (
    <View
      style={[Styles.container, limitedSpace && Styles.containerLimited]}
      onLayout={onLayout}
      testID="focusRecommendation"
    >
      {!!height && (
        <VictoryChart
          polar
          startAngle={90}
          endAngle={450}
          height={Math.min(height, MAX_HEIGHT)}
          padding={{ top: Metrics.baseMargin * 2, bottom: Metrics.baseMargin }}
        >
          <VictoryGroup
            colorScale={[Colors.grayBg]}
            style={{ data: { fillOpacity: 0, strokeWidth: 1 } }}
          >
            {[...Array(6)].map((_, i, arr) => (
              <VictoryArea
                key={i}
                data={data?.map(({ x }) => ({ x, y: i + 1 }))}
                style={{ data: { opacity: Math.max(i / arr.length, 0.2) } }}
              />
            ))}
          </VictoryGroup>
          <VictoryGroup
            colorScale={(score_range && [RANGE_COLORS[String(score_range).toUpperCase()]]) || []}
            style={{ data: { fillOpacity: 0.1, strokeWidth: 4 } }}
          >
            <VictoryArea data={data} />
          </VictoryGroup>
          <VictoryGroup>
            <VictoryScatter
              data={dataFocused}
              dataComponent={
                <FocusedDataComponent
                  color={RANGE_COLORS[getScoreRange(dataFocused?.[0]?.y - 1)]}
                />
              }
            />
          </VictoryGroup>

          <VictoryPolarAxis
            labelPlacement="vertical"
            style={{
              axis: {
                stroke: 'none',
              },
              tickLabels: {
                ...Styles.tickLabels,
                fill: Colors.text,
                padding: Metrics.baseMargin,
              },
              grid: { stroke: 'none' },
            }}
            tickLabelComponent={<VictoryLabel text={(p) => getTickLabelText(p.index)} />}
          />
        </VictoryChart>
      )}
    </View>
  )
}

export default memo(FocusChart)
