import React, { useContext, useState } from 'react'
import { View, Text } from 'react-native'
import Icon from 'react-native-vector-icons/MaterialIcons'

import { PrimaryButton } from 'APP/Components/Buttons'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import I18n from 'APP/Services/i18n'
import { Colors, Fonts } from 'APP/Themes'
import { ChatContext, PostContext } from 'APP/Lib/Context'

import { Styles } from './style'

const MultiChoice = () => {
  const { sendMultiChoiceAnswer } = useContext(ChatContext)
  const { post } = useContext(PostContext)
  let choices = post?.props?.question?.data?.choices || post.props.choices

  if (post?.props?.type === 'multiSelect.v1') {
    const noneOfTheAboveChoice = {
      display: I18n.t('ChatScreen.noneOfTheAbove'),
      exclusive: true,
    }
    const newChoices = [...choices]
    newChoices.push(noneOfTheAboveChoice)
    choices = newChoices
  }

  const [selectedChoices, setSelectedChoices] = useState([])

  const onNextPress = () => sendMultiChoiceAnswer(post, selectedChoices)
  const onChoicePressed = (choice) => {
    setSelectedChoices((prevChoices) => {
      if (choice.exclusive) {
        return [choice]
      }
      let newChoices = prevChoices.filter((c) => c.group_id === choice.group_id)
      const indexOfChoice = newChoices.indexOf(choice)
      if (indexOfChoice === -1) {
        newChoices.push(choice)
      } else {
        newChoices.splice(indexOfChoice, 1)
      }
      const indexOfNoneOfTheAbove = newChoices.findIndex(
        (c) => c.display === I18n.t('ChatScreen.noneOfTheAbove')
      )
      if (indexOfNoneOfTheAbove > -1) {
        newChoices.splice(indexOfNoneOfTheAbove, 1)
      }
      return newChoices
    })
  }

  return (
    <View style={Styles.container}>
      <View style={Styles.choicesContainer}>
        {choices.map((choice, i, array) => {
          const selected = selectedChoices.map((c) => c.display).indexOf(choice.display) > -1
          return (
            <DialogueTouchableOpacity
              key={i}
              style={[
                Styles.choiceContainer,
                i === 0 && Styles.firstChoiceContainer,
                i === array.length - 1 && Styles.lastChoiceContainer,
                selected && Styles.choiceContainerSelected,
              ]}
              onPress={() => onChoicePressed(choice)}
              analyticsName="Choice pressed"
            >
              <Icon
                name={selected ? 'check-box' : 'check-box-outline-blank'}
                color={selected ? Colors.text : Colors.deactivatedElementBg}
                size={Fonts.size.large}
                testID={selected ? 'checkbox-selected' : 'checkbox-unselected'}
              />
              <Text style={[Styles.choiceText]}>{choice.display}</Text>
            </DialogueTouchableOpacity>
          )
        })}
      </View>
      <View style={Styles.actionsContainer}>
        <PrimaryButton
          disabled={!selectedChoices.length}
          style={Styles.submitButton}
          analyticsName="IntakeComponent.multichoice.next"
          title={I18n.t('ChatScreen.actions.submit')}
          onPress={onNextPress}
        />
      </View>
    </View>
  )
}

export default MultiChoice
