import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Metrics, Fonts } from 'APP/Themes'
export const Styles = StyleSheet.create({
  singleChoice: {
    backgroundColor: Colors.contrastElementBg,
    borderRadius: 10,
    marginTop: Metrics.baseMargin + 4,
    ...ApplicationStyles.shadows.shadowBottomLit,
  },
  singleChoiceOption: {
    display: 'flex',
    marginBottom: 1,
    backgroundColor: Colors.elementsBg,
    padding: Metrics.baseMargin,
    minWidth: 240,
  },
  singleChoiceOptionText: {
    ...Fonts.style.body,
    color: Colors.text,
    textAlign: 'left',
  },
  firstSingleChoiceOption: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  lastSingleChoiceOption: {
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    marginBottom: 0,
  },
})
