import { select, call, delay, actionChannel, take, fork } from 'redux-saga/effects'
import Analytics from 'APP/Services/Analytics'
import { pushLogToAsyncStorage } from 'APP/Services/DeviceLogger'
import Config from 'APP/Config'

import { LoggerTypes } from 'APP/Redux/LoggerRedux'
import { navigationRef as Nav, routeNameToAnalyticsName } from 'APP/Nav'

export const fullState = (state) => state

const selectUserId = (state) => {
  if (state.patient && state.patient.profile) {
    return state.patient.profile.id
  } else {
    return '0'
  }
}

export function* pullFromChannel(chan, batch) {
  while (true) {
    const action = yield take(chan)
    batch.push(action)
  }
}

export function* startDeviceLoggerSync() {
  try {
    if (Config.PROD) return
    const chan = yield actionChannel(LoggerTypes.LOG)

    while (true) {
      let batch = []
      const pullTask = yield fork(pullFromChannel, chan, batch)
      yield delay(5000)
      pullTask.cancel()

      if (batch.length === 0) continue

      yield call(pushLogToAsyncStorage, batch)
    }
  } catch (e) {
    console.error('ERROR in startDeviceLoggerSync', e)
  }
}

export function* log(action) {
  if (__DEV__) {
    const state = yield select(fullState)
    console.log(`userId[${selectUserId(state)}] ${action.message}`)
  }
}

export function* error(action) {
  const state = yield select(fullState)
  if (__DEV__) {
    console.log('Logged error', `userId[${selectUserId(state)}] ${action.message}`)
  }
}

export function* triggerTrackScreen() {
  // Not wild about this tracking being async.
  // Maybe we should pass the screen name in to be 100% sure it's logging for the screen that triggered it
  const routeName = Nav.getCurrentRoute()?.name
  const analyticsName = routeNameToAnalyticsName(routeName)
  if (analyticsName) {
    yield call(Analytics.trackScreen, analyticsName)
  }
}

export function* triggerEvent({ eventName, props }) {
  yield delay(500)
  yield call(Analytics.trackEvent, eventName, props)
}
