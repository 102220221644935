import React, { useEffect, useState, useRef, useMemo } from 'react'
import { ActivityIndicator, View, ImageBackground, Text } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'
import I18n from 'APP/Services/i18n'
import Spinner from 'react-native-loading-spinner-overlay'

// Actions
import PatientActions from 'APP/Redux/PatientRedux'
import { familyActions } from 'APP/Store/Family'

// Components
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import DialogueBranding from 'APP/Components/DialogueBranding'
import Button from 'APP/Converse/Button'
import Alert from 'APP/Converse/Alert'
import { ResponsiveView } from 'APP/Converse/Layout'

// Styling
import HealthCard from 'APP/Images/health-card.svg'
import Styles, { StyledButtonContainerView } from './style'
import { Colors } from 'APP/Themes'
import { useFilePicker } from 'APP/Hooks/FilePicker'
import { isWeb } from 'APP/Helpers/checkPlatform'
import { useRoute } from '@react-navigation/native'

/*

Note: This container is referenced by the following routes. I don't like it.

  - updateDependentPhotoId
  - updatePhotoId

*/

// TODO: Eradicate and replace this splodge of a screen with something that makes sense
const PhotoIdScreen = () => {
  const route = useRoute()
  const inputFile = useRef(null)
  const dispatch = useDispatch()

  //State
  const [imageLoading, setImageLoading] = useState(true)

  //Store
  const idCardUrl = useSelector((state) => state.patient.idCardUrl || state.patient.idCardLocalPath)
  const patientRequestRunning = useSelector((state) => state.patient.requestRunning)
  const patientRequestError = useSelector((state) => state.patient.error)
  const isConnected = useSelector((state) => state.appSession.isConnected)

  //Derived state
  const routeName = route?.name
  const childProfile = route?.params?.childProfile

  const imageUri = useMemo(() => {
    if (routeName === 'updateDependentPhotoId' && childProfile?.healthcardUrl) {
      return childProfile.healthcardUrl
    } else if (routeName === 'updatePhotoId' && idCardUrl) {
      return idCardUrl
    }
  }, [childProfile, idCardUrl, routeName])

  const setIdCard = (fileObj) => {
    if (routeName === 'updateDependentPhotoId') {
      const { childProfile } = route?.params || {}
      const { id, dateOfBirth, familyName, givenName, preferredName } = childProfile
      dispatch(
        familyActions.updateChildProfileRequest({
          childId: id,
          childData: {
            dateOfBirth,
            familyName,
            givenName,
            preferredName,
          },
          fileObj,
        })
      )
    } else {
      dispatch(PatientActions.patientIdCardUpdateRequest(fileObj))
    }
  }

  const { openFilePicker, onFileChangeSubmit } = useFilePicker({
    submitCallback: setIdCard,
    fileInputRef: inputFile,
  })

  useEffect(() => {
    if (patientRequestError) {
      // Used to lower interference with spinner overlay
      setTimeout(
        () =>
          Alert.alert(I18n.t('PhotoIdScreen.error.title'), I18n.t('PhotoIdScreen.error.body'), [
            { text: I18n.t('PhotoIdScreen.error.ok') },
          ]),
        100
      )
    }
  }, [patientRequestError])

  const onFileChanged = (e) => {
    onFileChangeSubmit(e)
  }

  const handleOnLoad = () => setImageLoading(false)

  const editChild = routeName === 'updateDependentPhotoId'
  const photoTitle = imageUri ? 'PhotoIdScreen.editButton' : 'PhotoIdScreen.initialButton'

  return (
    <NoticeBarContainer>
      {isWeb() && (
        <input
          type="file"
          accept="image/*,.pdf"
          ref={inputFile}
          style={{ display: 'none' }}
          onChange={onFileChanged}
        />
      )}
      <ResponsiveView style={Styles.container}>
        {imageUri ? (
          <ImageBackground
            style={Styles.previewContainer}
            imageStyle={Styles.previewImage}
            source={{ uri: imageUri }}
            onLoad={handleOnLoad}
            testID="HEALTH_CARD_IMAGE"
          >
            {imageLoading && (
              <ActivityIndicator color={Colors.text} style={{ alignSelf: 'center' }} />
            )}
          </ImageBackground>
        ) : (
          <View>
            <View style={Styles.placeholderImage}>
              <HealthCard fillLine={Colors.text} fillLineInverted={Colors.textInverted} />
            </View>
            <Text style={[Styles.sectionText, Styles.cameraSection]}>
              {editChild ? I18n.t('PhotoIdScreen.bodyChild') : I18n.t('PhotoIdScreen.body')}
            </Text>
          </View>
        )}
        <StyledButtonContainerView>
          <Button
            variant={imageUri ? 'secondary' : 'primary'}
            title={I18n.t(photoTitle)}
            widthVariant="full"
            onPress={openFilePicker}
            analyticsName={photoTitle}
            disabled={!isConnected}
            testID="DEPENDENT_PHOTO_SCREEN_SELECT_PHOTO_BUTTON"
          />
        </StyledButtonContainerView>

        <Spinner visible={!editChild && patientRequestRunning} cancelable={false} />
        <DialogueBranding />
      </ResponsiveView>
    </NoticeBarContainer>
  )
}

export default PhotoIdScreen
