import React, { useCallback, useEffect, useState } from 'react'

import AttachmentImage from './AttachmentImage'

const defaultResolveAttachmentUrl = (url) => url

const Attachment = ({
  answer,
  resolveAttachmentUrl = defaultResolveAttachmentUrl,
  resolveGetPostFileMetadata,
  style,
  imageStyle,
}) => {
  const [url, setUrl] = useState()

  const getFileInfo = useCallback(() => {
    resolveGetPostFileMetadata(answer.id).then((response) => {
      const localFile = answer.localFile
      const fileMetadata = response[0]
      const fileId = fileMetadata.id

      const normalizedURI =
        typeof localFile === 'string' && !fileId ? localFile : resolveAttachmentUrl(fileId)
      // resolveAttachmentUrl might return a promise, or a string
      const resolveURI =
        normalizedURI && typeof normalizedURI.then === 'function'
          ? normalizedURI
          : Promise.resolve(normalizedURI)

      resolveURI.then((resolved) => {
        setUrl(resolved)
      })
    })
  }, [answer.id, answer.localFile, resolveAttachmentUrl, resolveGetPostFileMetadata])

  useEffect(() => {
    if ((answer.filenames && answer.filenames[0]) || (answer.file_ids && answer.file_ids[0])) {
      getFileInfo()
    }
  }, [answer, getFileInfo])

  return (
    <AttachmentImage
      style={style}
      imageStyle={imageStyle}
      source={url}
      localSource={answer.localFile}
    />
  )
}

export default Attachment
