const setViewRenderToHardwareTexture = (viewRef, value) => {
  if (viewRef && typeof viewRef.setNativeProps === 'function') {
    viewRef.setNativeProps({
      renderToHardwareTextureAndroid: value,
    })
  }
}

export const androidWrapper = (animation, viewRef) => {
  setViewRenderToHardwareTexture(viewRef, true)
  return {
    start(cb) {
      animation.start(({ finished }) => {
        if (finished) {
          setViewRenderToHardwareTexture(viewRef, false)
        }
        if (typeof cb === 'function') {
          cb({ finished })
        }
      })
    },
  }
}
