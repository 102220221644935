import React, { KeyboardEvent } from 'react'

import { Styles } from './style'
import { Image, Pressable, View, useWindowDimensions } from 'react-native'
import { Modal } from 'react-native'
import Icon from 'APP/Converse/Icon'

interface FileViewerModalProps {
  src: string
  isOpened: boolean
  handleCloseModal: () => void
}

const FileViewerModal = ({ src, isOpened, handleCloseModal }: FileViewerModalProps) => {
  const { width, height } = useWindowDimensions()

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
      handleCloseModal()
    }
  }

  return (
    <Modal visible={isOpened} transparent testID="file-viewer">
      <Pressable
        style={[Styles.container, { width, height }]}
        onPress={handleCloseModal}
        // @ts-ignore
        onKeyDownCapture={onKeyDown}
      >
        <View style={Styles.closeIcon} testID="close-icon">
          <Icon src="close" variant="material" size={50} color="white" />
        </View>
        <Image
          source={{ uri: src }}
          style={[Styles.image, { width: width * 0.9, height: height * 0.9 }]}
          resizeMode="contain"
        />
      </Pressable>
    </Modal>
  )
}

export default FileViewerModal
