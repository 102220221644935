import React, { useCallback, useMemo } from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import Animated, { FadeInUp } from 'react-native-reanimated'

import I18n from 'APP/Services/i18n'
import { getRecentMessages } from 'APP/Lib/EpisodeListHelpers'
import Analytics from 'APP/Services/Analytics'

import Styles from './style'
import Typography from 'APP/Converse/Typography'
import Button from 'APP/Converse/Button'
import { useSelector } from 'react-redux'
import EpisodeListItem from 'APP/Converse/EpisodeListItem'

const ENTERING = FadeInUp.duration(500)

export const UnreadMessages = ({ amountOfDays, hideNavigateToInboxBtn }) => {
  const login = useSelector((state) => state.login)
  const history = useSelector((state) => state.history)
  const healthIssueTypeIcons = useSelector((state) => state.content.healthIssueTypeIcons)

  const recentMessages = useMemo(
    () => getRecentMessages(login, history, amountOfDays, healthIssueTypeIcons),
    [login, history, amountOfDays, healthIssueTypeIcons]
  )

  const navigation = useNavigation()

  const onEpisodeClicked = useCallback(
    (id) => {
      Analytics.trackEvent('button_click', {
        button_value: 'Episode clicked',
      })
      navigation.navigate('conversation', { channelId: id })
    },
    [navigation]
  )

  if (!recentMessages || recentMessages.length === 0) return <></>

  return (
    <Animated.View style={Styles.episodesContainer} entering={ENTERING}>
      <View style={Styles.headerContainer}>
        <Typography variant="h3">{I18n.t('HomeScreen.recentMessages.title')}</Typography>
        {hideNavigateToInboxBtn || (
          <Button
            widthVariant="content"
            onPress={() => navigation.navigate('chatTab', { tabName: 'Inbox - Chat' })}
            title={I18n.t('HomeScreen.recentMessages.seeAll')}
            variant="tertiary"
          />
        )}
      </View>
      <View style={Styles.listContainer} testID="recentMessagesList">
        {recentMessages.map((item, index) => (
          <EpisodeListItem
            index={index}
            key={item.id}
            style={index !== 0 && Styles.borderEpisode}
            testID={`episode-${item.id}`}
            title={item?.title}
            subtitle={item?.subtitle}
            date={item?.formattedLastPostDate}
            avatarUrl={item?.avatarUrl}
            healthIssueTypeIcon={item?.healthIssueTypeIcon}
            ctaButton={
              <Button
                disabled={!item.title}
                title={
                  item.isBotActive
                    ? I18n.t('HomeScreen.recentMessages.resume')
                    : I18n.t('HomeScreen.recentMessages.goChat')
                }
                variant={item.isBotActive ? 'primary' : 'tertiary'}
                iconColor={item.isBotActive ? undefined : 'accent'}
                widthVariant="content"
                onPress={() => onEpisodeClicked(item.id)}
                icon="chevron-right"
                iconVariant="material"
                iconPosition="right"
                small
              />
            }
            onPress={() => onEpisodeClicked(item.id)}
          />
        ))}
      </View>
    </Animated.View>
  )
}

export default UnreadMessages
