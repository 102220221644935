import { StyleSheet } from 'react-native'
import { Colors, Fonts, Metrics, ApplicationStyles } from 'APP/Themes'

export default StyleSheet.create({
  modalContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: Metrics.statusBarHeight + Metrics.baseMargin,
  },
  modal: {
    ...ApplicationStyles.shadowBottomLit,
    flex: 1,
    flexDirection: 'column',
    backgroundColor: Colors.appBg,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  modalHeader: {
    ...ApplicationStyles.shadow,
    paddingTop: Metrics.baseMargin,
    paddingHorizontal: Metrics.baseMargin / 2,
    backgroundColor: Colors.appBg,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    zIndex: 1,
  },
  modalHeaderClose: {
    flex: 1 / 8,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalHeaderTitle: {
    flex: 6 / 8,
    ...Fonts.style.h2,
    color: Colors.text,
    textAlign: 'center',
  },
  modalContent: {
    flex: 1,
  },
  row: {
    flexDirection: 'row',
  },
  progressItem: {
    flex: 1,
    alignItems: 'center',
    paddingTop: Metrics.baseMargin,
    paddingBottom: Metrics.baseMargin,
  },
  progressItemHeader: {
    ...Fonts.style.h2,
    color: Colors.text,
    alignSelf: 'center',
    paddingBottom: Metrics.baseMargin / 2,
  },
  progressItemCaption: {
    ...Fonts.style.caption,
    color: Colors.text,
  },
  progressItemCaptionContainer: {
    flexDirection: 'row',
    marginTop: -(Fonts.size.large - Fonts.lineHeight.tiny) * 0.5,
    alignItems: 'center',
  },
  captionIcon: {
    paddingLeft: Metrics.baseMargin * 0.25,
  },
  tooltipText: {
    ...Fonts.style.subheader,
    color: Colors.textInverted,
  },
  networkSection: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  hidden: {
    opacity: 0,
  },
  disabled: {
    opacity: 0.5,
  },
})
