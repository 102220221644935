import React from 'react'
import { Animated, StyleSheet, Keyboard } from 'react-native'
import {
  PanGestureHandler,
  NativeViewGestureHandler,
  State,
  TapGestureHandler,
} from 'react-native-gesture-handler'

import { Metrics } from 'APP/Themes'

class BottomSheet extends React.Component {
  masterdrawer = React.createRef()
  drawer = React.createRef()
  drawerheader = React.createRef()
  scroll = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      lastSnap: props.snapPoints.slice(-1)[0],
      snapPoints: props.snapPoints,
    }
    this._initAnimatedValues()
  }

  _hideKeyboard() {
    Keyboard.dismiss()
  }

  _initAnimatedValues(snapPoints = this.state.snapPoints) {
    const START = snapPoints[0]
    const END = snapPoints.slice(-1)[0]

    this._lastScrollYValue = 0
    this._lastScrollY = new Animated.Value(0)
    this._onRegisterLastScroll = Animated.event(
      [{ nativeEvent: { contentOffset: { y: this._lastScrollY } } }],
      { useNativeDriver: true }
    )
    this._lastScrollY.addListener(({ value }) => {
      this._lastScrollYValue = value
    })

    this._dragY = new Animated.Value(0)
    this._onGestureEvent = Animated.event([{ nativeEvent: { translationY: this._dragY } }], {
      useNativeDriver: true,
    })

    this._translateYOffset = new Animated.Value(END)
    this._translateY = Animated.add(
      this._translateYOffset,
      Animated.subtract(this._dragY, this._lastScrollY)
    ).interpolate({
      inputRange: [START, END],
      outputRange: [START, END],
      extrapolate: 'clamp',
    })
    this.drawerOpenAnimatedValue = this._translateY.interpolate({
      inputRange: [START, END],
      outputRange: [1, 0],
    })
  }
  _onHeaderHandlerStateChange = ({ nativeEvent }) => {
    if (nativeEvent.oldState === State.BEGAN) {
      this._lastScrollY.setValue(0)
    }
    this._onHandlerStateChange({ nativeEvent })
  }
  _onHandlerStateChange = ({ nativeEvent }) => {
    if (nativeEvent.oldState === State.ACTIVE) {
      let { velocityY, translationY } = nativeEvent
      translationY -= this._lastScrollYValue
      const dragToss = translationY > 0 ? 0.1 : 0.5
      const endOffsetY = this.state.lastSnap + translationY + dragToss * velocityY

      let destSnapPoint = this.state.snapPoints[0]
      for (let i = 0; i < this.state.snapPoints.length; i++) {
        const snapPoint = this.state.snapPoints[i]
        const distFromSnap = Math.abs(snapPoint - endOffsetY)
        if (distFromSnap < Math.abs(destSnapPoint - endOffsetY)) {
          destSnapPoint = snapPoint
        }
      }
      this._snapToPoint(destSnapPoint, translationY, velocityY)
    }
  }

  _snapToPoint(destSnapPoint, translationY, velocity = 0) {
    const END = this.state.snapPoints.slice(-1)[0]
    if (destSnapPoint === END) {
      this._hideKeyboard()
    }
    this.setState({ lastSnap: destSnapPoint })
    if (translationY) {
      this._translateYOffset.extractOffset()
      this._translateYOffset.setValue(translationY)
      this._translateYOffset.flattenOffset()
    }
    this._lastScrollY.setValue(0)
    this._dragY.setValue(0)
    Animated.spring(this._translateYOffset, {
      velocity,
      tension: 68,
      friction: 12,
      toValue: destSnapPoint,
      useNativeDriver: true,
    }).start(({ finished }) => {
      if (finished && typeof this.props.onSnap === 'function') {
        this.props.onSnap(this.state.snapPoints.indexOf(destSnapPoint))
      }
    })
  }

  updateSnapPoints = (nextSnapPoints) => {
    this.setState({ snapPoints: nextSnapPoints })
    this._initAnimatedValues(nextSnapPoints)
  }

  open = () => {
    this._snapToPoint(this.state.snapPoints[0])
  }

  close = () => {
    this._snapToPoint(this.state.snapPoints.slice(-1)[0])
  }

  toggle = () => {
    const { lastSnap, snapPoints } = this.state
    const START = snapPoints[0]
    const END = snapPoints.slice(-1)[0]

    this._snapToPoint(lastSnap === START ? END : START)
  }

  render() {
    return (
      <TapGestureHandler
        maxDurationMs={100000}
        ref={this.masterdrawer}
        maxDeltaY={this.state.lastSnap - this.state.snapPoints[0] - 1} // make tap more sensitive for self booking for OnePlus
      >
        <Animated.View
          style={{
            ...StyleSheet.absoluteFillObject,
            zIndex: 100,
            transform: [{ translateY: this._translateY }],
          }}
          pointerEvents="box-none"
        >
          <Animated.View style={[{ flex: 1 }, this.props.style]}>
            <PanGestureHandler
              ref={this.drawerheader}
              simultaneousHandlers={[this.scroll, this.masterdrawer]}
              shouldCancelWhenOutside={false}
              onGestureEvent={this._onGestureEvent}
              onHandlerStateChange={this._onHeaderHandlerStateChange}
            >
              <Animated.View>{this.props.HeaderComponent}</Animated.View>
            </PanGestureHandler>
            <PanGestureHandler
              enabled={!this.props.disableContentPanHandlers}
              ref={this.drawer}
              simultaneousHandlers={[this.scroll, this.masterdrawer]}
              shouldCancelWhenOutside={false}
              onGestureEvent={this._onGestureEvent}
              onHandlerStateChange={this._onHandlerStateChange}
            >
              <Animated.View style={[this.props.contentContainerStyle]}>
                {this.props.ContentHeaderComponent}
                <NativeViewGestureHandler
                  ref={this.scroll}
                  waitFor={this.masterdrawer}
                  simultaneousHandlers={this.drawer}
                >
                  <Animated.ScrollView
                    style={[this.props.scrollStyle]}
                    contentContainerStyle={this.props.scrollContentStyle}
                    bounces={false}
                    onScrollBeginDrag={this._onRegisterLastScroll}
                    scrollEventThrottle={1}
                    keyboardDismissMode="on-drag"
                    keyboardShouldPersistTaps={true}
                  >
                    {this.props.ContentBodyComponent}
                  </Animated.ScrollView>
                </NativeViewGestureHandler>
              </Animated.View>
            </PanGestureHandler>
          </Animated.View>
        </Animated.View>
      </TapGestureHandler>
    )
  }
}

BottomSheet.defaultProps = {
  snapPoints: [Metrics.statusBarHeight, Metrics.screenHeight],
  disableContentPanHandlers: false,
}

export default BottomSheet
