import React, { useMemo, useEffect, useLayoutEffect, useState } from 'react'
import { View, Text, Image, LayoutAnimation } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import { PrimaryButton } from 'APP/Components/Buttons'
import PatientHistoryRedux from 'APP/Redux/PatientHistoryRedux'
import I18n from 'APP/Services/i18n'
import DialogueMarkdown from 'APP/Components/DialogueMarkdown'
import { isMobile, isWeb } from 'APP/Helpers/checkPlatform'
import WellBeingRedux from 'APP/Redux/WellBeingRedux'
import sprigClient from 'APP/Services/Sprig'
import { Recommender } from '@dialogue/services'
import NetworkWrapper from 'APP/Components/NetworkWrapper'
import RecommendationGroup from './RecommendationGroup'

import { Fonts, Images } from 'APP/Themes'
import { animationConfiguration } from './helpers'
import Styles, { MarkdownStyles } from './style'
import Slider from './Slider'
import { ResponsiveScrollView, ResponsiveView } from 'APP/Converse/Layout'

const GROUPS = {
  STANDALONE: 'STANDALONE',
  REST: 'REST',
}

export function WellBeingIndexCardEmpty() {
  return (
    <View style={Styles.wellBeingIndexCard} testID="wellBeingIndexCardEmpty">
      <View>
        <Text style={Styles.wellBeingEmptyCardTitle}>
          {I18n.t('WellBeingIndexScreen.wellBeingEmptyCardTitle')}
        </Text>
      </View>
      <Image style={Styles.emptyChartImage} source={Images.wellBeing.emptyChart} />
      <DialogueMarkdown
        styles={MarkdownStyles}
        markdown={I18n.t('WellBeingIndexScreen.wellBeingEmptyCardDesc')}
      />
    </View>
  )
}

function WellBeingIndexScreen(props) {
  const dispatch = useDispatch()
  const { navigation, route } = props
  const { stressScoreToWBI } = useSelector((state) => state.features)
  // result is passed when a member complets the assessment
  // and launches a deeplink to this screen
  const { result } = { ...route?.params, ...props }
  const assessmentWasJustCompleted = Boolean(result)

  const [isScrollEnabled, setIsScrollEnabled] = useState(true)
  const recommendationStore = useSelector((state) => state.wellBeingAssessment.recommendations)
  const data = recommendationStore.data
  const noRecommendations = !data?.length
  const retry = recommendationStore.retry
  const loading = (assessmentWasJustCompleted || noRecommendations) && recommendationStore.loading
  const isBottomCTAVisible = !loading && !assessmentWasJustCompleted

  const [recommendationGroups, score, score_range, focus] = useMemo(() => {
    const sources =
      data?.find(({ type }) => type === Recommender.Types.RecommendationType.PROGRESS)?.sources ||
      {}
    const nextFocus = data?.find(({ type }) => type === Recommender.Types.RecommendationType.FOCUS)
      ?.sources?.focus

    const nextRecommendationGroups = (data || []).reduce((acc, recommendation) => {
      let key = GROUPS.REST
      if (
        [
          Recommender.Types.RecommendationType.PROGRESS,
          Recommender.Types.RecommendationType.COMPARE,
          Recommender.Types.RecommendationType.FOCUS,
        ].includes(recommendation.type)
      ) {
        key = GROUPS.STANDALONE
      }
      return {
        ...acc,
        [key]: [...(acc[key] || []), key === GROUPS.STANDALONE ? [recommendation] : recommendation],
      }
    }, {})

    return [nextRecommendationGroups, sources.score, sources.score_range, nextFocus]
  }, [data])

  const getOrCreateWbiEpisode = (ctaId) => {
    dispatch(PatientHistoryRedux.getOrCreateWbiEpisodeRequest(ctaId))
  }
  const getRecommendations = () => dispatch(WellBeingRedux.getRecommendations())

  // sprig analytics
  useEffect(() => {
    if (recommendationStore.loading) return
    if (score_range && assessmentWasJustCompleted) {
      sprigClient.track('WBI_SCORE_' + String(score_range).toUpperCase())
    }
    if (!score_range) {
      sprigClient.track('WBI_SCORE_NONE')
    }
  }, [recommendationStore.loading, assessmentWasJustCompleted, score_range])

  useEffect(() => {
    getRecommendations()
  }, [result])

  useLayoutEffect(() => {
    navigation.setOptions({
      onBackButtonPress: () => {
        sprigClient.track('WBI_SCREEN_EXIT')
        // only once a user has completed the WBI assessment from MH -> Monitor your well-being,
        // navigate back twice to Monitor your well-being
        if (stressScoreToWBI && !isBottomCTAVisible) {
          navigation.pop(2)
          return
        }
        navigation.goBack()
      },
    })
  })

  useLayoutEffect(() => {
    !noRecommendations &&
      animationConfiguration &&
      LayoutAnimation.configureNext(animationConfiguration)
    if (isMobile()) {
      navigation.setOptions({
        title: noRecommendations ? I18n.t('WellBeingIndexScreen.title') : undefined,
      })
    }
  }, [noRecommendations])

  const getCtaTextLabel = () =>
    noRecommendations
      ? 'WellBeingIndexScreen.wellBeingCardGetScoreCta'
      : 'WellBeingIndexScreen.wellBeingCardCheckinCta'

  const ctaTextLabel = getCtaTextLabel()
  const groupProps = {
    score,
    score_range,
    focus,
    onSetScrollEnabled: setIsScrollEnabled,
  }

  const BottomCta = () => (
    <PrimaryButton
      testID="wellBeingIndexCta"
      style={Styles.takeAssessment}
      title={I18n.t(ctaTextLabel)}
      analyticsName={ctaTextLabel}
      onPress={() => {
        sprigClient.track('WBI_ASSESSMENT_STARTED')
        getOrCreateWbiEpisode(ctaTextLabel)
      }}
    />
  )

  const WrapperComponent = isWeb() || noRecommendations ? ResponsiveScrollView : ResponsiveView

  return (
    <NoticeBarContainer>
      <NetworkWrapper loading={loading} error={retry} refresh={getRecommendations}>
        <WrapperComponent style={Styles.flexGrow}>
          {noRecommendations && <WellBeingIndexCardEmpty />}
          {!noRecommendations && (
            <Slider
              isScrollEnabled={isScrollEnabled}
              slideOffset={Fonts.lineHeight.h1 * 2}
              bottomCta={isBottomCTAVisible && <BottomCta />}
            >
              {Object.entries(recommendationGroups)
                .map(([key, recommendationGroup]) =>
                  key === GROUPS.STANDALONE ? (
                    recommendationGroup.map((recommendations, i) => (
                      <RecommendationGroup
                        spaceBetween
                        key={`${GROUPS.STANDALONE}-${i}`}
                        recommendations={recommendations}
                        {...groupProps}
                      />
                    ))
                  ) : (
                    <RecommendationGroup
                      key={GROUPS.REST}
                      recommendations={recommendationGroup}
                      {...groupProps}
                    />
                  )
                )
                .flat()}
            </Slider>
          )}
        </WrapperComponent>

        {noRecommendations && isBottomCTAVisible && (
          <ResponsiveView>
            <BottomCta />
          </ResponsiveView>
        )}
      </NetworkWrapper>
    </NoticeBarContainer>
  )
}

export default WellBeingIndexScreen
