import React from 'react'
import { useDispatch } from 'react-redux'

import Button from 'APP/Converse/Button'
import Typography from 'APP/Converse/Typography'
import * as NestedNavHelper from 'APP/Nav/NestedNavHelper'
import { videoSessionActions } from 'APP/Store/VideoSession'

import I18n from 'APP/Services/i18n'

import { StyledHeaderContainer, StyledResponsiveView, StyledContainerView } from './style'

interface VideoCallEndedScreenProps {
  route?: {
    params?: {
      caller: {
        first_name: string
        last_name: string
      }
      episodeId: string
    }
  }
}

const VideoCallEndedScreen: React.FC<VideoCallEndedScreenProps> = (props) => {
  const dispatch = useDispatch()
  const { first_name, last_name } = props.route?.params?.caller || {}
  const { episodeId } = props.route?.params || {}

  const onPress = () => {
    dispatch(videoSessionActions.clearLastVideoCallInfo())

    // Timeout fixes an issue with iOS where the screen is not replaced and a modal is being used
    // https://github.com/react-navigation/react-navigation/issues/11201#issuecomment-1742402353
    setTimeout(() => {
      NestedNavHelper.replace('conversation', { channelId: episodeId })
    }, 500)
  }

  return (
    <>
      <StyledHeaderContainer>
        <Button
          variant="tertiary"
          iconVariant="materialIcons"
          icon="close"
          small={false}
          widthVariant="circle"
          iconPosition="right"
          onPress={onPress}
          analyticsName="Close Video Call"
          testID="close-video-call-screen"
          iconColor="white"
        />
      </StyledHeaderContainer>
      <StyledContainerView>
        <StyledResponsiveView>
          <Typography variant="h3" color="white">
            {first_name} {last_name}
          </Typography>
          <Typography variant="bodyNormal" color="white">
            {I18n.t('VideoCallEndedScreen.callEnded')}
          </Typography>
        </StyledResponsiveView>
      </StyledContainerView>
    </>
  )
}

export default VideoCallEndedScreen
