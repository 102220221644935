import React from 'react'
import Ringing from 'APP/Sounds/ringing.wav'

const SoundBank = {
  ringing: Ringing,
}

const DialogueSound = ({ autoPlay = true, loop = true, name = '' }) => {
  return (
    <audio autoPlay={autoPlay} loop={loop} style={{ display: 'none' }}>
      <source src={SoundBank[name]} type="audio/wav" />
    </audio>
  )
}

export default DialogueSound
