import { StyleSheet } from 'react-native'
import { Colors, Fonts } from 'APP/Themes'

export default StyleSheet.create({
  tag: {
    ...Fonts.style.bodySmallBold,
    color: Colors.darkText,
    paddingTop: 8,
    paddingRight: 11,
    paddingBottom: 8,
    paddingLeft: 11,
    textTransform: 'uppercase',
    backgroundColor: Colors.tagBg,
    borderRadius: 15,
    overflow: 'hidden',
  },
})
