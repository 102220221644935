import { StyleSheet } from 'react-native'
import { Metrics } from 'APP/Themes'

export const Styles = StyleSheet.create({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: Metrics.baseMargin / 4,
    gap: Metrics.baseMargin / 8,
    alignItems: 'center',
  },
})
