import { StyleSheet } from 'react-native'
import { Colors, Fonts } from 'APP/Themes'

export const triangleSize = 7

export default StyleSheet.create({
  container: {
    height: 50,
    backgroundColor: Colors.text,
    borderRadius: 4,
    position: 'relative',
  },
  labelContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  textLabel: {
    ...Fonts.style.bodySmall,
    color: Colors.appBg,
    textAlign: 'center',
  },
  scoreLabel: {
    ...Fonts.style.bodySmallBold,
    color: Colors.appBg,
  },
  triangle: {
    position: 'absolute',
    alignSelf: 'center',
    bottom: -6,
    borderLeftWidth: triangleSize,
    borderTopWidth: triangleSize,
    borderRightWidth: triangleSize,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderTopColor: Colors.text,
  },
})
