import React, { useCallback, useContext, useMemo, useState } from 'react'
import styled from 'styled-components/native'

import { ChatContext, PostContext } from 'APP/Lib/Context'
import Analytics from 'APP/Services/Analytics'
import {
  ProviderChoice,
  ProviderList,
} from 'APP/Components/AppointmentBooking/ProviderBased/providerList'
import {
  Selection,
  ProviderProfileModal,
} from 'APP/Components/AppointmentBooking/ProviderBased/profile/ProviderProfileModal'

const Container = styled.View`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${({ theme }) => theme.metrics.baseMargin}px;
  max-width: ${({ theme: { isBigScreen } }) => (isBigScreen ? '600px' : '280px')};
`

const FelixProviderList = () => {
  const { post } = useContext(PostContext)
  const { sendSingleChoiceAnswer } = useContext(ChatContext)

  const [activeChoice, setActiveChoice] = useState<ProviderChoice | null>(null)

  const choices: ProviderChoice[] = post?.props?.choices || []

  const activeTimeslot: Selection | null = useMemo(() => {
    return activeChoice?.provider_id
      ? {
          choice: activeChoice?.display || '',
          providerId: parseInt(activeChoice.provider_id, 10),
        }
      : null
  }, [activeChoice])

  const onPress = useCallback((choice: ProviderChoice) => {
    setActiveChoice(choice)
    Analytics.trackEvent('button_click', { button_value: 'Provider choice selected' })
  }, [])

  const onPressMoreOptions = useCallback(
    (choice: ProviderChoice) => {
      Analytics.trackEvent('button_click', {
        button_value: 'Provider choice - more options selected',
      })

      sendSingleChoiceAnswer(post, choice)
    },
    [post, sendSingleChoiceAnswer]
  )

  const handleCloseModal = useCallback(() => {
    setActiveChoice(null)
  }, [])

  const handleConfirm = useCallback(() => {
    Analytics.trackEvent('button_click', { button_value: 'Provider choice confirmed' })
    sendSingleChoiceAnswer(post, activeChoice)
  }, [activeChoice])

  return (
    <Container>
      <ProviderList data={choices} onPress={onPress} onPressMoreOptions={onPressMoreOptions} />
      <ProviderProfileModal
        selection={activeTimeslot}
        onConfirm={handleConfirm}
        onClose={handleCloseModal}
      />
    </Container>
  )
}

export default FelixProviderList
