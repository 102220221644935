import { Platform } from 'react-native'
import isNumeric from 'APP/Helpers/isNumeric'

export const animationConfiguration = Platform.select({
  ios: {
    create: {
      property: 'opacity',
      delay: 300,
    },
    delete: {
      property: 'opacity',
    },
    update: {
      property: 'opacity',
    },
    duration: 300,
  },
  default: undefined,
})

export const RangeTypes = {
  Low: 'Low',
  Mid: 'Mid',
  High: 'High',
}

export function getScoreRangeData(score) {
  if (!isNumeric(score)) return {}

  const calculatePercentage = (input, min, max) => {
    const range = max - min
    const value = input - min
    return (value * 100) / range
  }

  const RangeTiers = [
    {
      name: RangeTypes.Low,
      minValue: 0,
      maxValue: 50,
    },
    {
      name: RangeTypes.Mid,
      minValue: 51,
      maxValue: 75,
    },
    {
      name: RangeTypes.High,
      minValue: 76,
      maxValue: 100,
    },
  ]

  const activeTier = RangeTiers.find((tier) => score <= tier.maxValue && score >= tier.minValue)

  if (!activeTier) {
    return {}
  }

  return {
    percentage: calculatePercentage(score, activeTier.minValue, activeTier.maxValue),
    calculatedType: activeTier.name,
  }
}
