// ============= LOCATION ============= //

export function requestLocationService() {
  return new Promise((resolve) => {
    navigator.geolocation.getCurrentPosition(
      () => resolve(true),
      () => resolve(false)
    )
  })
}

export function checkLocationService() {
  return new Promise((resolve) => {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      resolve(result.state === 'granted')
    })
  })
}

export function checkLocationState() {
  return new Promise((resolve) => {
    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      resolve(result.state)
    })
  })
}

export function requestLocation() {
  return new Promise((resolve) => {
    navigator.geolocation.getCurrentPosition(
      () => resolve('granted'),
      () => resolve('denied')
    )
  })
}

// ============= CAMERA ============= //

export function checkCameraState() {
  // Firefox currently doesn't have 'camera' in Permissions query()
  // https://developer.mozilla.org/en-US/docs/Web/API/Permissions_API#browser_compatibility
  const isFirefox = navigator?.userAgent?.toLowerCase().indexOf('firefox') > -1

  if (isFirefox) {
    return new Promise((resolve) => {
      if (navigator.mediaDevices?.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({
            video: true,
          })
          .then(
            (stream) => {
              const videoTrack = stream && stream.getVideoTracks()[0]
              const permission = videoTrack ? 'authorized' : 'denied'
              if (videoTrack) videoTrack.stop()
              resolve(permission)
            },
            (error) => {
              console.error(error)
              resolve('denied')
            }
          )
      }
    })
  } else {
    return new Promise((resolve) => {
      navigator.permissions.query({ name: 'camera' }).then((result) => {
        resolve(result.state)
      })
    })
  }
}

export function requestCameraPermission() {
  return new Promise((resolve) => {
    navigator.mediaDevices.getUserMedia({ video: true }).then(
      () => resolve('granted'),
      () => resolve('denied')
    )
  })
}

// ============= MICROPHONE ============= //

export function checkMicrophoneState() {
  // Firefox currently doesn't have 'camera' in Permissions query()
  // https://developer.mozilla.org/en-US/docs/Web/API/Permissions_API#browser_compatibility
  const isFirefox = navigator?.userAgent?.toLowerCase().indexOf('firefox') > -1

  if (isFirefox) {
    return new Promise((resolve) => {
      if (navigator.mediaDevices?.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({
            audio: true,
          })
          .then(
            (stream) => {
              const audioTrack = stream && stream.getAudioTracks()[0]
              const permission = audioTrack ? 'authorized' : 'denied'
              if (audioTrack) audioTrack.stop()
              resolve(permission)
            },
            (error) => {
              console.error(error)
              resolve('denied')
            }
          )
      }
    })
  } else {
    return new Promise((resolve) => {
      navigator.permissions.query({ name: 'microphone' }).then((result) => {
        resolve(result.state)
      })
    })
  }
}

export function requestMicrophonePermission() {
  return new Promise((resolve) => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then(
      () => resolve('granted'),
      () => resolve('denied')
    )
  })
}

// ============= PUSH NOTIFICATIONS ============= //

// Push notifications are currently not supported on web app
export function checkPostNotificationsState() {
  return Promise.resolve()
}

// Push notifications are currently not supported on web app
export function requestPostNotificationsPermissions() {
  return Promise.resolve()
}

// ============ BLUETOOTH CONNECT ==================== //
export function checkBluetoothConnectState() {
  return new Promise((resolve) => {
    resolve(null)
  })
}

export function requestBluetoothConnectPermission() {
  return new Promise((resolve) => {
    navigator.bluetooth.requestDevice().then(
      () => resolve('granted'),
      () => resolve('denied')
    )
  })
}
