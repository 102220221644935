import React from 'react'
import { Linking, View } from 'react-native'
import { connect } from 'react-redux'

// Actions
import PatientHistoryActions from 'APP/Redux/PatientHistoryRedux'

// Components
import MicroApp from 'APP/Components/MicroApp'
import AppointmentBooking from 'APP/Components/AppointmentBooking'

import Styles from './style'
import Analytics from 'APP/Services/Analytics'
import I18n from 'APP/Services/i18n'
import sprigClient from 'APP/Services/Sprig'

class MicroAppScreen extends React.Component {
  componentWillUnmount() {
    const microAppProps = this.props.microAppProps
    const isSelfNav = microAppProps?.appId === 'cliniaHealthNavigation'

    if (isSelfNav) {
      sprigClient.track(`SELF_NAV_CLOSED_${I18n.baseLocale.toUpperCase()}`)
    }
  }

  componentDidMount() {
    const { title } = this.props
    this.props.navigation?.setOptions({ title })
  }

  closeMicroApp = () => {
    this.props.navigation.goBack()
  }

  track = (payload) => {
    const { name, props } = payload
    if (name && props) {
      if (
        name === 'Health Resource Search Requested' ||
        name === 'Health Resource Result Clicked'
      ) {
        const analyticsEvent = name.replace(/ /g, '_').replace(/[A-Z]/g, (x) => x.toLowerCase())
        Analytics.trackEvent(analyticsEvent, props)
      }
    }
  }

  openUrl = (url) => {
    if (url && Linking.canOpenURL(url)) {
      Linking.openURL(url)
    } else {
      console.log('cannot open url: ', url)
    }
  }

  call = (number) => {
    const telURL = `tel:${number && number.trim && number.trim()}`
    if (telURL && Linking.canOpenURL(telURL)) {
      Linking.openURL(telURL)
    } else {
      console.log('cannot call: ', telURL)
    }
  }

  getDisplayStyle = (displayMode) => {
    switch (displayMode) {
      case 'fullscreen':
        return Styles.navContainerNavbarOverride
      case 'drawer':
        return Styles.navContainerDrawer
      default:
        return Styles.navContainer
    }
  }

  render() {
    const props = {
      close: this.closeMicroApp,
      trackEvent: this.track,
      openUrl: this.openUrl,
      callNumber: this.call,
      userLocation: this.props.userLocation,
      ...this.props.microAppProps,
    }

    const displayMode = this.props.microAppProps && this.props.microAppProps.displayMode
    const showV2AppointmentBooking =
      this.props.microAppProps?.appId === 'selfScheduling' && this.props.showV2AppointmentBooking

    return (
      <View style={this.getDisplayStyle(displayMode)}>
        {showV2AppointmentBooking ? (
          <AppointmentBooking
            appointment_type={props?.post?.props?.microapp.appointment_type}
            {...props}
          />
        ) : (
          <MicroApp renderNavBar={false} {...props} navigation={this.props.navigation} />
        )}
      </View>
    )
  }
}

const mapStateToProps = (state, props) => {
  const userId = (state.patient && state.patient.profile && state.patient.profile.id) || null

  const userLocation =
    (state.patient && state.patient.profile && state.patient.profile.location) || null

  const showV2AppointmentBooking = state.features.showV2AppointmentBooking

  return {
    ...props.route?.params,
    userId,
    userLocation,
    showV2AppointmentBooking,
  }
}

const mapDispatchToProps = (dispatch) => ({
  newEpisode: (ctaId) => dispatch(PatientHistoryActions.createEpisodeRequest(ctaId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MicroAppScreen)
