import React from 'react'
import { View } from 'react-native'

import { Images } from 'APP/Themes'
import { RootScreens } from 'APP/Lib/Enums'
import Icon from 'APP/Converse/Icon'

const TAB_ICONS = {
  homeTab: Images.homeIcon,
  historyTab: Images.messageTextIcon,
  profileTab: Images.accountIcon,
  wc: Images.wellnessIcon,
  wellBeingIndex: Images.wellBeingIndex,
  account: Images.groups,
  servicesTab: Images.servicesIcon,
  [RootScreens.today]: Images.todayIcon,
  [RootScreens.move]: Images.moveIcon,
  [RootScreens.mind]: Images.mindIcon,
  [RootScreens.chat]: Images.chatIcon,
  [RootScreens.browse]: Images.browseIcon,
}

const TAB_ICONS_SELECTED = {
  homeTab: Images.homeIconFilled,
  historyTab: Images.messageTextIconFilled,
  profileTab: Images.accountIconFilled,
  wc: Images.wellnessIconFilled,
  wellBeingIndex: Images.wellBeingIndex,
  account: Images.groups,
  servicesTab: Images.servicesIconFilled,
  [RootScreens.today]: Images.todayIconFilled,
  [RootScreens.move]: Images.moveIconFilled,
  [RootScreens.mind]: Images.mindIconFilled,
  [RootScreens.chat]: Images.chatIconFilled,
  [RootScreens.browse]: Images.browseIconFilled,
}

const styles = {
  iconWrapper: {
    width: 20,
    height: 20,
    flexDirection: 'row',
    justifyContent: 'center',
  },
}

export function TabIcon({ name, focused }) {
  const color = focused ? 'accent' : 'dim'
  const tabImage = focused ? TAB_ICONS_SELECTED[name] : TAB_ICONS[name]

  if (!tabImage) return null

  return (
    <View style={styles.iconWrapper} testId={`tabIcon-${name}`}>
      <Icon variant="svg" src={tabImage} color={color} fillLine={color} />
    </View>
  )
}
