import React from 'react'
import Typography from 'APP/Converse/Typography'
import RadioButton from './RadioButton'
import { RadioGroupProps } from './types'
import { View } from 'react-native'

const RadioGroup = ({
  label,
  options = [],
  currentValue,
  onPress,
  buttonContainerStyle,
  testID = 'radio-group',
}: RadioGroupProps) => (
  <View style={buttonContainerStyle} testID={testID}>
    {!!label && <Typography variant="bodyNormalBold">{label}</Typography>}
    {options.map((option, i) => (
      <RadioButton
        key={option.value}
        status={currentValue === option.value ? 'checked' : 'unchecked'}
        onPress={onPress}
        label={option.label}
        value={option.value}
        testID={`${testID}-${i}`}
      />
    ))}
  </View>
)

export default RadioGroup
