import { StyleSheet } from 'react-native'
import { Metrics } from 'APP/Themes'

export const Styles = StyleSheet.create({
  avatarContainer: {
    width: 40,
    marginRight: Metrics.baseMargin / 2,
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 25,
  },
})
