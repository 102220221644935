// TODO(WEB): Implement stripe-react-native shim

import React from 'react'
import { View } from 'react-native'

export const CardField = () => <View></View>
export const useStripe = () => {
  return {
    createToken: Promise.resolve({ token: { id: '123' } }),
  }
}

export const StripeProvider = ({ children }) => {
  return <>{children}</>
}
