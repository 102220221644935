import React, { useState } from 'react'
import { View, Text } from 'react-native'
import Icon from 'react-native-vector-icons/MaterialIcons'

// Components
import { PrimaryButton } from 'APP/Components/Buttons'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'

// Services
import I18n from 'APP/Services/i18n'

// Styles
import { Colors, Fonts } from 'APP/Themes'
import { Styles } from './style'

const IntakeMultiChoice = ({
  choices: choicesProp,
  maximum_selection,
  minimum_selection = 0,
  disabled,
  sendAnswer,
}) => {
  const [choices, setChoices] = useState([])
  const onNextPress = () => sendAnswer(choices)
  const onChoicePressed = (choice) => {
    setChoices((prevChoices) => {
      let newChoices = prevChoices.filter((c) => c.group_id === choice.group_id)
      const indexOfChoice = newChoices.indexOf(choice)
      if (indexOfChoice === -1 && (!maximum_selection || newChoices.length < maximum_selection)) {
        newChoices.push(choice)
      } else {
        newChoices.splice(indexOfChoice, 1)
      }
      return newChoices
    })
  }

  return (
    <View style={Styles.container}>
      <View style={Styles.choicesContainer}>
        {choicesProp.map((choice, i, array) => {
          const selected = choices.indexOf(choice) > -1
          const isChoiceDisabled =
            disabled || (maximum_selection && maximum_selection === choices.length && !selected)
          return (
            <DialogueTouchableOpacity
              key={i}
              disabled={isChoiceDisabled}
              style={[
                Styles.choiceContainer,
                i === 0 && Styles.firstChoiceContainer,
                i === array.length - 1 && Styles.lastChoiceContainer,
                selected && Styles.choiceContainerSelected,
              ]}
              onPress={() => onChoicePressed(choice)}
              analyticsName="Choice pressed"
            >
              <Icon
                name={selected ? 'check-box' : 'check-box-outline-blank'}
                color={selected ? Colors.text : Colors.deactivatedElementBg}
                size={Fonts.size.large}
              />
              <Text style={[Styles.choiceText, disabled && Styles.choiceDisabledText]}>
                {choice.display}
              </Text>
            </DialogueTouchableOpacity>
          )
        })}
      </View>
      <View style={Styles.actionsContainer}>
        <PrimaryButton
          disabled={disabled || (choices && choices.length < minimum_selection)}
          style={Styles.nextButton}
          analyticsName="IntakeComponent.multichoice.next"
          title={I18n.t('IntakeComponent.next')}
          onPress={onNextPress}
        />
      </View>
    </View>
  )
}

export default IntakeMultiChoice
