import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'

// Components
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import ToggleCard from 'APP/Components/ToggleCard'
import Modal from 'APP/Components/Modal'

// Actions
import StartupActions from 'APP/Redux/StartupRedux'
import LoginActions from 'APP/Redux/LoginRedux'

// Services
import I18n from 'APP/Services/i18n'
import { biometricsState, APP_LOCK_STATE } from 'APP/Services/AppLock'

// Styles
import Styles from './style'
import { isMobile, isWeb } from 'APP/Helpers/checkPlatform'
import { ResponsiveScrollView } from 'APP/Converse/Layout'
import Typography from 'APP/Converse/Typography'
import { Colors } from 'APP/Themes'
import { Divider } from 'react-native-elements'
import SecurityScreenIcon from 'APP/Images/Account/security-screen-icon.svg'

const SecurityScreen = ({
  isAppLockToggled,
  activateAppLockToggle,
  disableAppLockToggle,
  isInForeground,
  isMFAMandatory,
  isMFAToggled,
  isMfaLoading,
  toggleMfa,
}) => {
  const [appLockState, setAppLockState] = useState(APP_LOCK_STATE.NOT_SUPPORTED)
  const [showMFAToggleOnModal, setShowMFAToggleOnModal] = useState(false)

  useEffect(() => {
    checkBiometricsAvailable()
  }, [])

  useEffect(() => {
    if (isInForeground) {
      checkBiometricsAvailable()
    }
  }, [isInForeground])

  const checkBiometricsAvailable = () => {
    if (isWeb()) return
    biometricsState().then((result) => setAppLockState(result))
  }

  const onToggleSwitch = () => {
    if (!isAppLockToggled) {
      activateAppLockToggle()
    } else {
      disableAppLockToggle()
    }
  }

  const renderAppLockUnsupported = () => {
    return (
      <View style={Styles.appLockContainer}>
        <Typography variant="h3">{I18n.t('SecurityScreen.appLock.title')}</Typography>
        <Typography variant="bodySmall" style={Styles.description}>
          {I18n.t('SecurityScreen.unsupported')}
        </Typography>
      </View>
    )
  }

  const renderAppLockNotEnrolled = () => {
    return (
      <View style={Styles.appLockContainer}>
        <Typography variant="h3">{I18n.t('SecurityScreen.appLock.title')}</Typography>
        <Typography variant="bodySmall" style={Styles.description}>
          {I18n.t('SecurityScreen.notEnrolled')}
        </Typography>
      </View>
    )
  }

  const renderAppLockSupported = () => {
    const description = (
      <Typography variant="bodySmall" style={Styles.description}>
        {I18n.t('SecurityScreen.description')}
      </Typography>
    )

    return (
      <ToggleCard
        testID={'APP_LOCK_TOGGLE_CARD'}
        title={I18n.t('SecurityScreen.appLock.title')}
        description={description}
        label={I18n.t('SecurityScreen.appLock.label')}
        isToggled={isAppLockToggled}
        onToggleChange={onToggleSwitch}
      />
    )
  }

  const renderAppLockContent = () => {
    switch (appLockState) {
      case APP_LOCK_STATE.SUPPORTED:
        return renderAppLockSupported()
      case APP_LOCK_STATE.NOT_ENROLLED:
        return renderAppLockNotEnrolled()
      default:
        return renderAppLockUnsupported()
    }
  }

  const getMFADescription = () => {
    if (isMFAToggled) {
      const testId = isMFAMandatory ? 'MANDATORY_TEXT' : 'NOT_MANDATORY_TEXT'
      const mandatoryText = I18n.t('MultiFactorAuthentication.description.mandatory') + '\n\n'
      return (
        <Typography variant="bodySmall" style={Styles.description} testID={testId}>
          {isMFAMandatory && mandatoryText}
          {I18n.t('MultiFactorAuthentication.description.on')}
        </Typography>
      )
    }
    return (
      <Typography variant="bodySmall" style={Styles.description} testID="MFA_OFF">
        {I18n.t('MultiFactorAuthentication.description.off')}
      </Typography>
    )
  }

  const renderMFAContent = () => {
    return (
      <>
        <ToggleCard
          testID={'MFA_TOGGLE_CARD'}
          title={I18n.t('MultiFactorAuthentication.title')}
          description={getMFADescription()}
          label={I18n.t('MultiFactorAuthentication.label')}
          disabled={isMFAMandatory}
          isLoading={isMfaLoading}
          isToggled={isMFAToggled}
          onToggleChange={() => {
            // Only confirm when toggling on
            if (!isMFAToggled) {
              openMFAToggleOnModal()
              setShowMFAToggleOnModal(true)
            } else {
              toggleMfa()
            }
          }}
        />
        <Divider style={Styles.mfaDivider} />
      </>
    )
  }

  const handleAgreeMFAToggleOnPress = () => {
    toggleMfa()
    closeMFAToggleOnModal()
  }

  const openMFAToggleOnModal = () => setShowMFAToggleOnModal(true)

  const closeMFAToggleOnModal = () => setShowMFAToggleOnModal(false)

  return (
    <NoticeBarContainer>
      <ResponsiveScrollView contentContainerStyle={Styles.scrollContainer}>
        <View style={Styles.securityHeader}>
          <SecurityScreenIcon fillLine={Colors.text} accentFillLine={Colors.accent} />
          <Typography variant="h2">{I18n.t('SecurityScreen.title')}</Typography>
          <Divider style={Styles.headerDivider} />
        </View>
        <View style={Styles.container}>
          {/* TODO(consolidation): we could probably have a "platform config" to handle things like this */}
          {isMobile() && (
            <View style={Styles.appLockSection}>
              {renderAppLockContent(appLockState)}
              <Divider />
            </View>
          )}
          {renderMFAContent()}
        </View>
      </ResponsiveScrollView>
      <Modal
        statusBarTranslucent
        visible={showMFAToggleOnModal}
        transparent
        title={I18n.t('MultiFactorAuthentication.confirmModal.title')}
        subtitle={I18n.t('MultiFactorAuthentication.confirmModal.description')}
        primaryActionText={I18n.t('MultiFactorAuthentication.confirmModal.confirm')}
        secondaryActionText={I18n.t('MultiFactorAuthentication.confirmModal.cancel')}
        handleCloseModal={closeMFAToggleOnModal}
        handleSecondaryAction={closeMFAToggleOnModal}
        handlePrimaryAction={handleAgreeMFAToggleOnPress}
      />
    </NoticeBarContainer>
  )
}

const mapStateToProps = (state, props) => {
  const { app, patient, login } = state
  const user_id = patient?.profile?.id
  const isAppLockToggled = app?.appLock?.isToggled

  return {
    ...props.route?.params,
    user_id,
    isAppLockToggled,
    isInForeground: state.appSession.isActive,
    isMFAMandatory: patient?.profile?.mfaRequired ?? false,
    isMFAToggled: patient?.userInfo?.user_metadata?.mfa_enabled ?? false,
    isMfaLoading: login.toggleMfaRequestRunning,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    activateAppLockToggle: () => dispatch(StartupActions.activateAppLockToggle()),
    disableAppLockToggle: () => dispatch(StartupActions.setAppLockToggle(false)),
    toggleMfa: () => dispatch(LoginActions.toggleMfaRequest()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SecurityScreen)
