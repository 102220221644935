import React from 'react'
import { Controller, UseFieldArrayProps } from 'react-hook-form'

import { IMaskMixin } from 'react-imask'
import TextInput from 'APP/Converse/Inputs/TextInput'

interface ControllerDatePickerProps {
  control: UseFieldArrayProps['control']
  name: string
  rules?: UseFieldArrayProps['rules']
  testID?: string
  label: string
  isFocused?: boolean
  infotip?: string
  onFocus?: () => void
  disabled?: boolean
  placeholder?: string
  value?: string
  setValue: (name: string, value: string) => void
  onSelect: () => void
  showRequiredAsterisk?: boolean
}

const MaskedStyledInput = IMaskMixin(({ inputRef, ...props }) => (
  <TextInput {...props} ref={inputRef} />
))

const ControllerDatePicker = ({
  control,
  name,
  rules,
  testID,
  label,
  infotip,
  onFocus,
  disabled,
  placeholder,
  showRequiredAsterisk,
}: ControllerDatePickerProps) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
        <MaskedStyledInput
          mask={Date}
          maxLength={10}
          pattern="Y-m-dd"
          disabled={disabled}
          testID={testID}
          onAccept={onChange}
          value={value}
          onBlur={onBlur}
          onFocus={onFocus}
          returnKeyType="next"
          label={label}
          error={error?.message}
          keyboardType="default"
          infotip={infotip}
          placeholder={placeholder}
          showRequiredAsterisk={showRequiredAsterisk}
        />
      )}
    />
  )
}

export default ControllerDatePicker
