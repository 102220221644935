import { useState } from 'react'
import styled from 'styled-components/native'

import Typography from 'APP/Converse/Typography'
import I18n from 'APP/Services/i18n'

import { isWeb } from 'APP/Helpers/checkPlatform'

const ToggleText = styled(Typography)`
  text-decoration: underline;
`

interface Props {
  maxSymbols: number
  text: string
}

const variant = isWeb() ? 'bodyLarge' : 'bodyNormal';

export const TruncatedTypography = ({ maxSymbols, text, ...rest }: Props) => {
  const canBeTruncated = text.length > maxSymbols
  const [isTruncated, setIsTruncated] = useState(canBeTruncated)

  const handleToggleTruncate = () => {
    setIsTruncated((prev) => !prev)
  }

  if (!canBeTruncated) {
    return <Typography variant={variant} {...rest}>{text}</Typography>
  }

  return (
    <Typography variant= {variant} {...rest}>
      {isTruncated ? `${text.slice(0, maxSymbols)}...` : text}&nbsp;
      <ToggleText variant="bodySmallBold" onPress={handleToggleTruncate}>
        {I18n.t(isTruncated ? 'Common.showMore' : 'Common.showLess')}
      </ToggleText>
    </Typography>
  )
}
