import apisauce from 'apisauce'
import {
  AppointmentsApiFactory,
  AvailabilitiesApiFactory,
  ProvidersApiFactory,
  Configuration,
} from '@dialogue/timekeeper'
import { select, call } from 'typed-redux-saga'

import Config from 'APP/Config'
import { RootState } from 'APP/Store/CreateStore'

export const selectAccessToken = (state: RootState) => state.login.accessToken

export function* getClientConfig() {
  const accessToken = yield* select(selectAccessToken)

  if (!accessToken) {
    throw new Error('Missing access token')
  }

  if (!Config.TIMEKEEPER_DOMAIN) {
    throw new Error('Timekeeper domain is not set')
  }

  return new Configuration({
    accessToken,
    basePath: Config.TIMEKEEPER_DOMAIN,
  })
}

export function* getAxiosClient() {
  const accessToken = yield* select(selectAccessToken)

  if (!accessToken) {
    throw new Error('Missing access token')
  }

  if (!Config.TIMEKEEPER_DOMAIN) {
    throw new Error('Timekeeper domain is not set')
  }

  const apiClient = apisauce.create({
    baseURL: Config.TIMEKEEPER_DOMAIN,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return apiClient.axiosInstance
}

export function* getAppointmentsApi() {
  const axiosInstance = yield* call(getAxiosClient)
  return AppointmentsApiFactory(yield* call(getClientConfig), '', axiosInstance)
}

export function* getAvailabilitiesApi() {
  const axiosInstance = yield* call(getAxiosClient)
  return AvailabilitiesApiFactory(yield* call(getClientConfig), '', axiosInstance)
}

export function* getProvidersApi() {
  const axiosInstance = yield* call(getAxiosClient)
  return ProvidersApiFactory(yield* call(getClientConfig), '', axiosInstance)
}
