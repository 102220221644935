import React, { useLayoutEffect } from 'react'

import { ActiveMinutes } from '@dialogue/services'

import I18n from 'APP/Services/i18n'
import { isMobile } from 'APP/Helpers/checkPlatform'
import { useShareDeepLinkFromConfig } from 'APP/Hooks/Share'
import ButtonMoreAction from 'APP/Components/ButtonMoreAction'

function useChallengeKebabMenu({
  navigation,
  id,
  status,
  analyticsName,
  onSetupTrackerPress,
  joined,
  setLeavingChallengeModalIsVisible,
}) {
  const shareChallengeInvite = useShareDeepLinkFromConfig(
    'WC_CHALLENGE_SCREEN_DEEP_LINK_URL',
    {
      services: 'wellness_challenges',
      id,
    },
    { analyticsName }
  )

  useLayoutEffect(() => {
    const headerRight = () => {
      if (status === ActiveMinutes.Types.ChallengeStatus.Completed) {
        return null
      }

      return (
        <ButtonMoreAction
          key="more"
          analyticsName={analyticsName}
          actions={[
            {
              value: I18n.t('WellnessCenter.trackers.settings'),
              handler() {
                onSetupTrackerPress()
              },
            },
            {
              value: I18n.t('WellnessCenter.challenges.invite'),
              handler() {
                shareChallengeInvite.share()
              },
              enable: isMobile(),
            },
            {
              value: I18n.t('WellnessCenter.challenges.leave'),
              destructive: true,
              enable: joined,
              handler() {
                setLeavingChallengeModalIsVisible(true)
              },
            },
            {
              value: I18n.t('WellnessCenter.challenges.cancel'),
              cancellable: true,
              enable: true,
            },
          ]}
        />
      )
    }

    navigation.setOptions({ headerRight })
  }, [
    shareChallengeInvite.message,
    shareChallengeInvite.url,
    status,
    navigation,
    shareChallengeInvite,
    onSetupTrackerPress,
    setLeavingChallengeModalIsVisible,
    joined,
    analyticsName,
    id,
  ])
}

export default useChallengeKebabMenu
