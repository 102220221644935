import React from 'react'
import moment from 'moment'
import styled from 'styled-components/native'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'

import Typography from 'APP/Converse/Typography'
import I18n from 'APP/Services/i18n'
import { Colors, Images, Metrics } from 'APP/Themes'

interface Props {
  date?: string
  timeslot?: string
  providerName?: string
  providerRole?: string
  icon?: string
  reasonForConsult?: string
}

const Container = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${({ theme }) => theme.metrics.baseMargin / 2}px 0;
  border-radius: ${({ theme }) => theme.metrics.borderRadius.medium}px;
  background-color: ${({ theme }) => theme.colors.elementsBg};
  ${({ theme }) => theme.shadows.soft};
`

const DateContainer = styled.View`
  height: 100%;
  justify-content: center;
  background-color: ${Colors.appointmentDateElement};
  border-top-left-radius: ${({ theme }) => theme.metrics.baseMargin}px;
  border-bottom-left-radius: ${({ theme }) => theme.metrics.baseMargin}px;
  padding: ${({ theme }) => theme.metrics.baseMargin}px;
`

const AppointmentDetails = styled.View`
  flex: 1;
  margin: ${({ theme }) => theme.metrics.baseMargin}px;
`

const TimeContainer = styled.View`
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin / 1.5}px;
`

const ScheduleIcon = styled(MaterialIcon)`
  margin-right: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  color: ${({ theme }) => theme.colors.accent};
`

const ProviderContainer = styled.View`
  flex-direction: row;
  align-items: center;
`

const ProviderImage = styled.Image`
  height: 50px;
  width: 50px;
  margin-right: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  border-radius: 16px;
`

const ProviderInnerContainer = styled.View`
  flex: 1;
  align-self: center;
  gap: ${({ theme }) => theme.metrics.baseMargin / 4}px;
`

const ReasonContainer = styled.View`
  border-top-color: ${({ theme }) => theme.colors.elementsBorder};
  border-top-width: 1px;
  margin-top: ${({ theme }) => theme.metrics.baseMargin}px;
  padding-top: ${({ theme }) => theme.metrics.baseMargin}px;
`

const AppointmentCardStatic = ({
  date,
  timeslot,
  icon,
  providerName,
  providerRole,
  reasonForConsult,
}: Props) => {
  const appointmentDate = moment(date)
  return (
    <Container>
      <DateContainer>
        <Typography variant="h1" align="center">
          {appointmentDate.format('D')}
        </Typography>
        <Typography variant="bodyLarge" align="center">
          {appointmentDate.format('MMM')}
        </Typography>
      </DateContainer>
      <AppointmentDetails>
        <TimeContainer>
          <ScheduleIcon size={Metrics.icons.small} name="schedule" />
          <Typography variant="subheader">{timeslot}</Typography>
        </TimeContainer>
        <ProviderContainer>
          <ProviderImage source={icon ? { uri: icon } : Images.logoNurse} />
          <ProviderInnerContainer>
            <Typography variant="h3">{providerName}</Typography>
            {providerRole && <Typography variant="subheader">{providerRole}</Typography>}
          </ProviderInnerContainer>
        </ProviderContainer>
        {reasonForConsult && (
          <ReasonContainer>
            <Typography>
              <Typography variant="subheaderBold">
                {I18n.t('Appointments.reasonForConsult')}
              </Typography>
              <Typography variant="bodyNormal">{reasonForConsult}</Typography>
            </Typography>
          </ReasonContainer>
        )}
      </AppointmentDetails>
    </Container>
  )
}

export default AppointmentCardStatic
