import { signals, storeName, packetLossStatuses, packetLossThresholds } from './constants'
import { videoSessionActions } from 'APP/Store/VideoSession'
import * as Sentry from '@sentry/react-native'
import { logDdError } from 'APP/Lib/Datadog'

let currentCall = null
let currentConnectionId = null

const onConnectionCreatedEventHandler = () => (connection) => {
  console.info('connection created:', connection)
}

const onConnectionDestroyedEventHandler = () => (connection) => {
  console.info('connection destroyed:', connection)
}

const onSessionConnectedEventHandler = (store) => (session) => {
  if (!session || !session.connection || !session.connection.connectionId) return
  console.info('session connected:', session)
  currentConnectionId = session.connection.connectionId
  store.dispatch(videoSessionActions.sessionConnected())
}

const onSessionDisconnectedEventHandler = (store) => (session) => {
  console.info('session disconnected:', session)
  currentConnectionId = null
  store.dispatch(videoSessionActions.sessionDisconnected())
}

const onStreamCreatedEventHandler = (store) => (stream) => {
  if (stream.name === signals.properties.senderTypes.CARE_PLATFORM) {
    console.info('platform stream created:', stream)
    store.dispatch(videoSessionActions.platformStreamCreated({ stream }))
  }
}

const onStreamDestroyedEventHandler = (store) => (stream) => {
  if (stream.name === signals.properties.senderTypes.CARE_PLATFORM) {
    console.info('platform stream destroyed:', stream)
    store.dispatch(videoSessionActions.platformStreamDestroyed({ stream }))
    store.dispatch(videoSessionActions.resetPublisherStats())
  }
}

const onSessionFailedEventHandler = (store) => (error) => {
  console.info('session failed:', error)
  store.dispatch(videoSessionActions.sessionFailed())
}

const packetLossPercentageToLabel = (percentage) =>
  (percentage >= packetLossThresholds.CRITICAL && packetLossStatuses.CRITICAL) ||
  (percentage >= packetLossThresholds.WARNING && packetLossStatuses.WARNING) ||
  packetLossStatuses.OKAY

const onPublisherStatsUpdate = (store) => (stats) => {
  console.info('publisher stats update', stats)

  // Extract stats from prev stored event
  const videoSession = store.getState()[storeName]
  const prevStats = videoSession.publisherStats

  // Extract from stats event
  const totalPacketsSent = stats.audioPacketsSent + stats.videoPacketsSent
  const totalPacketsLost = stats.audioPacketsLost + stats.videoPacketsLost

  // Calculate next state
  const currentPacketsSent = totalPacketsSent - prevStats.totalPacketsSent
  const currentPacketsLost = totalPacketsLost - prevStats.totalPacketsLost
  if (currentPacketsSent && currentPacketsLost) {
    const currentLossPercent = (currentPacketsLost / currentPacketsSent) * 100

    // Emit action when loss status changes
    const prevLossStatus = packetLossPercentageToLabel(prevStats.currentLossPercent)
    const currentLossStatus = packetLossPercentageToLabel(currentLossPercent)

    // Store the new metrics
    store.dispatch(
      videoSessionActions.setPublisherStats({
        totalPacketsSent,
        totalPacketsLost,
        currentLossPercent,
      })
    )

    if (prevLossStatus !== currentLossStatus) {
      store.dispatch(
        videoSessionActions.publisherPacketLossEvent({
          currentLossStatus,
        })
      )
    }
  }
}

function Call(callId, store) {
  this.store = store
  this.callId = callId
  this.ended = false
  this.answered = false
  this.callingTimeout = null

  this.calling = function (payload) {
    if (this.shouldDispatchCalling()) {
      clearTimeout(this.callingTimeout)
      this.store.dispatch(videoSessionActions.platformCalling(payload))
      // Persists last video call's information, since because it would be clear out when the call ends, but it is needed to display who was last called
      this.store.dispatch(videoSessionActions.saveLastVideoCallInfo(payload))
      this.callingTimeout = setTimeout(() => {
        this.store.dispatch(videoSessionActions.callingTimedOut())
      }, 5000)
    } else {
      console.warn(
        `call will not be dispatch as callid ${this.callId} was ended or answered already`
      )
      clearTimeout(this.callingTimeout)
    }
  }

  this.answer = function () {
    this.answered = true
    clearTimeout(this.callingTimeout)
  }

  this.end = function () {
    this.ended = true
    clearTimeout(this.callingTimeout)
  }

  this.endByPlatform = function () {
    this.end()
    this.store.dispatch(videoSessionActions.platformEndedCall())
  }

  this.handledByRemote = function () {
    this.end()
    this.store.dispatch(videoSessionActions.handledByRemote())
  }

  this.shouldDispatchCalling = function () {
    return !this.ended && !this.answered
  }
}

// Signal Handling
const onSignalEventHandler = (store) => (event) => {
  const signal = event && event
  try {
    signal.data = JSON.parse(signal.data)

    let payload = signal.data
    switch (true) {
      // Care Platform Signals
      case isFromCarePlatform(payload) && signal.type === signals.CALLING:
        if (!currentCall) {
          currentCall = new Call(payload.callId, store)
        } else if (currentCall.callId !== payload.callId) {
          currentCall.end()
          currentCall = new Call(payload.callId, store)
        }

        currentCall.calling(payload)
        break

      case isFromCarePlatform(payload) && signal.type === signals.ENDED_CALL:
        currentCall && currentCall.endByPlatform()
        break

      case isFromCarePlatform(payload):
        store.dispatch(videoSessionActions.unhandledSignal())
        break

      // Patient Signals
      case isFromPatientApp(payload) && signal.type === signals.ENDED_CALL:
        if (signal.from.connectionId === currentConnectionId) {
          currentCall && currentCall.end()
        } else {
          console.info('call ended by remote.')
          currentCall && currentCall.handledByRemote()
        }
        break

      case isFromPatientApp(payload) && signal.type === signals.ACCEPTED_CALL:
        if (signal.from.connectionId === currentConnectionId) {
          currentCall && currentCall.answer()
        } else {
          console.info('call accepted by remote.')
          currentCall && currentCall.handledByRemote()
        }
        break
    }
  } catch (error) {
    store.dispatch(videoSessionActions.malformedSignal())
    Sentry.captureException(error)
    logDdError(error.message, error.stack)
  }
}

const isFromCarePlatform = (payload) =>
  payload.sender === signals.properties.senderTypes.CARE_PLATFORM
const isFromPatientApp = (payload) => payload.sender === signals.properties.senderTypes.PATIENT

export const attachEventListeners = (store, openTokInterface) => {
  openTokInterface.on('signal', onSignalEventHandler(store))
  openTokInterface.on('connectionCreated', onConnectionCreatedEventHandler(store))
  openTokInterface.on('connectionDestroyed', onConnectionDestroyedEventHandler(store))
  openTokInterface.on('sessionConnected', onSessionConnectedEventHandler(store))
  openTokInterface.on('sessionDisconnected', onSessionDisconnectedEventHandler(store))
  openTokInterface.on('streamCreated', onStreamCreatedEventHandler(store))
  openTokInterface.on('streamDestroyed', onStreamDestroyedEventHandler(store))
  openTokInterface.on('sessionFailed', onSessionFailedEventHandler(store))
  openTokInterface.on('publisherStatsUpdate', onPublisherStatsUpdate(store))
}
