/* globals GLOBAL */
import { Platform } from 'react-native'
import { startNetworkLogging } from 'react-native-network-logger'
import * as Sentry from '@sentry/react-native'
import DeviceInfo from 'react-native-device-info'
import moment from 'moment'

import i18n from 'APP/Services/i18n'
import Analytics from 'APP/Services/Analytics'
import Config from 'APP/Config'
import { initDatadogRUM } from 'APP/Lib/Datadog'

export const getEnvTag = () => {
  if (Config.ENVIRONMENT === 'PROD-EU') {
    return 'production-eu'
  }
  return Config.PROD ? 'production' : 'development'
}

export const getRNVersion = () => {
  // TODO(WEB): Implement a web build step that instead sets this to the correct value via env var
  if (Platform.OS === 'web') {
    return '0.0.0'
  }
  const { major, minor, patch } = Platform.constants.reactNativeVersion
  return `${major}.${minor}.${patch}`
}

export const appInfo = {
  brand_id: Config.BRAND_ID,
  os: Platform.OS,
  rn_version: getRNVersion(),
  app_version: DeviceInfo.getVersion(),
  app_build_number: DeviceInfo.getBuildNumber(),
  device_id: DeviceInfo.getUniqueId(),
  app_name: DeviceInfo.getApplicationName(),
  app_id: DeviceInfo.getBundleId(),
}

const initAnalytics = () => {
  Analytics.init()
  Analytics.attachEntity('app_info', appInfo)

  Config.onConfigKeyChange('SNOWPLOW', (done) => {
    Analytics.init()
    done()
  })
}

export const initConfig = async () => {
  await Config.init()
  moment.locale(i18n.baseLocale)
  initAnalytics()
  await initDatadogRUM()
}

export const beforeSendFn = (event) => {
  if (event.message?.startsWith('NetworkError')) {
    return null
  }
  return event
}

export const initSentry = () => {
  // Only initialize sentry for prod and dev releases
  if (!__DEV__) {
    Sentry.init({
      // TODO(WEB): Decide whether to get sentry/react-native working on web:
      // https://forum.sentry.io/t/facing-the-problem-to-integrate-sentry-in-react-native-web/15956/2
      // or to use sentry-expo
      // enableNative: Platform.OS !== 'web',
      dsn: Config.SENTRY_ENDPOINT,
      environment: getEnvTag(),
      enableAutoSessionTracking: true,
      deactivateStacktraceMerging: true,
      serverName: DeviceInfo.getUniqueId(),
      release: `${DeviceInfo.getBundleId()}@${DeviceInfo.getVersion()}+${DeviceInfo.getBuildNumber()}`,
      beforeSend: beforeSendFn,
      // eslint-disable-next-line no-unused-vars
      beforeBreadcrumb(breadcrumb) {
        if (breadcrumb && breadcrumb.category === 'console') {
          return null
        }
        return breadcrumb
      },
    })

    Config.onConfigKeyChange('ENVIRONMENT', (done) => {
      Sentry.setTag('environment', getEnvTag())
      done()
    })

    Sentry.setTag('brand_id', Config.PRODUCT_NAME)
  }
}

export const initNetworkLogging = () => {
  // Note: This will enforce security headers, and may break things.
  const ROUTE_REQUESTS_THROUGH_CHROME_DEBUGGER = false
  if (__DEV__) {
    if (ROUTE_REQUESTS_THROUGH_CHROME_DEBUGGER) {
      GLOBAL.XMLHttpRequest = GLOBAL.originalXMLHttpRequest || GLOBAL.XMLHttpRequest
    }
    startNetworkLogging({ ignoredHosts: ['localhost'] })
  }
}
