import type { RootState } from 'APP/Store/CreateStore'
import { createSelector } from '@reduxjs/toolkit'
import { resolveServiceFeatures } from 'APP/Lib/ServiceFeatures'

export const selectLayoutState = (state: RootState) => state?.content?.layout?.data
export const selectServiceFeaturesState = (state: RootState) =>
  state?.content?.serviceFeatures?.data
export const selectCmsFeedState = (state: RootState) => state?.content?.cmsFeed
export const selectServiceGroupsState = (state: RootState) => state?.content?.serviceGroups?.data

export const selectOneServiceGroupState = (state: RootState, id: string) =>
  state?.content?.serviceGroups?.byId?.[id]

export const selectServiceFeatures = createSelector(
  [
    (state: RootState) => state?.content?.serviceFeatures?.data,
    (state: RootState) => state?.patient?.profile?.eligibleServices,
  ],
  (serviceFeatures, eligibleServices) => resolveServiceFeatures(serviceFeatures, eligibleServices)
)
