import { StyleSheet } from 'react-native'
import styled from 'styled-components/native'

import Typography from 'APP/Converse/Typography'
import { ApplicationStyles, Metrics, Colors, Fonts } from '../../Themes'

const headerContainer = {
  paddingTop: Metrics.baseMargin * 2,
}

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  scrollContainer: {
    minHeight: '100%',
  },
  headerContainer,
  emergencyServicesContainer: {
    paddingBottom: Metrics.baseMargin,
    marginHorizontal: Metrics.baseMargin * 1.5,
    marginBottom: Metrics.baseMargin,
  },
  headerLogo: {
    width: 48,
    height: 48,
    resizeMode: 'contain',
    marginHorizontal: Metrics.baseMargin * 1.5,
  },
  wideLogo: {
    width: 96,
  },
  bigLogo: {
    width: 180,
    height: 56,
  },
  bigLogoGutter: {
    marginHorizontal: Metrics.baseMargin,
  },
  titleText: {
    ...Fonts.style.h1,
    marginTop: Metrics.baseMargin,
    color: Colors.text,
    marginHorizontal: Metrics.baseMargin * 1.5,
  },
  sectionHeader: {
    ...Fonts.style.subheaderBold,
    color: Colors.text,
    paddingHorizontal: Metrics.baseMargin * 1.5,
    paddingTop: Metrics.baseMargin * 1.5,
    paddingBottom: Metrics.baseMargin,
  },
  privacy: {
    ...Fonts.style.body,
    color: Colors.text,
    margin: Metrics.baseMargin * 1.75,
    marginBottom: Metrics.baseMargin * 2.5,
  },
  scope: {
    ...ApplicationStyles.shadow,
  },
  subtitle: {
    marginTop: Metrics.baseMargin / 2,
    marginBottom: Metrics.baseMargin,
    marginHorizontal: Metrics.baseMargin * 1.5,
  },
})

export const StyledScopeOfPracticeTitle = styled(Typography).attrs(() => ({
  variant: 'h4',
}))`
  padding: ${({
    theme: {
      metrics: { baseMargin },
    },
  }) => `${baseMargin / 2}px ${baseMargin}px`};
`

export const StyledScopeOfPracticeSubtitle = styled(Typography).attrs(() => ({
  variant: 'bodyNormal',
}))`
  padding: ${({
    theme: {
      metrics: { baseMargin },
    },
  }) => `0px ${baseMargin}px ${baseMargin / 2}px`};
`
