import { StyleSheet, Platform } from 'react-native'
import { Colors, Fonts, Metrics, ApplicationStyles } from 'APP/Themes'

export const Styles = StyleSheet.create({
  container: {
    paddingTop: 0,
    flex: 1,
    backgroundColor: Colors.appBg,
    justifyContent: 'center',
    alignItems: Platform.select({ default: 'center', web: 'stretch' }),
    display: 'flex',
  },
  button: {
    width: '45%',
    margin: 0,
    marginVertical: 0,
    marginHorizontal: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  footer: {
    position: 'absolute',
    bottom: Metrics.bottomSpace || Metrics.baseMargin,
    left: 0,
    right: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  footerSpacing: {
    // footer buttons' height is 48
    paddingBottom: (Metrics.bottomSpace || Metrics.baseMargin) + 48 + Metrics.baseMargin / 2,
  },
  hintIcon: {
    height: 20,
    width: 20,
    tintColor: Colors.text,
  },
  hintIconButton: {
    padding: 11,
    paddingRight: Metrics.baseMargin,
  },
  imagePicker: {
    flexShrink: 1,
    backgroundColor: Colors.appBg,
  },
  imagePickerInnerContainer: {
    paddingBottom:
      (Metrics.bottomSpace || Metrics.baseMargin) + ApplicationStyles.screen.primaryButton.height,
  },
  notice: {
    backgroundColor: Colors.bannerBg,
    paddingTop: 4,
    paddingBottom: 4,
  },
  noticeTitleStyle: {
    color: Colors.text,
    fontWeight: 'normal',
  },
  pinchIcon: {
    width: 27,
    height: 45,
    marginRight: 17,
    tintColor: Colors.accent,
  },
  popoverContent: {
    margin: Metrics.baseMargin,
    flexDirection: 'column',
    display: 'flex',
  },
  popoverSubtitle: {
    ...Fonts.style.body,
    fontSize: Fonts.size.small,
    color: Colors.text,
    alignItems: 'baseline',
    textAlign: 'center',
  },
  popoverTail: {
    position: 'absolute',
    top: Metrics.baseMargin / 2 + 2,
    right: -25,
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderTopWidth: 14,
    borderRightWidth: 14,
    borderBottomWidth: 14,
    borderLeftWidth: Metrics.baseMargin,
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: 'transparent',
    borderLeftColor: Colors.elementsBg,
  },
  popoverWindow: {
    backgroundColor: Colors.elementsBg,
    top: -10,
    right: '100%',
    transform: [{ translateX: -Metrics.baseMargin / 2 }, { translateY: Metrics.baseMargin / 2 }],
    maxWidth: Metrics.screenWidth - Metrics.baseMargin,
    minWidth: 0.65 * Metrics.screenWidth,
    borderRadius: Metrics.baseMargin / 2,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    elevation: 2,
    alignContent: 'center',
    display: 'flex',
    position: 'absolute',
    ...ApplicationStyles.shadow,
  },
  zoomHintOverlay: StyleSheet.absoluteFillObject,
})
