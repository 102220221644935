import { StyleSheet } from 'react-native'
import { Colors, Metrics } from 'APP/Themes'

export const Styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  cardContainer: {
    borderRadius: 25,
    padding: Metrics.baseMargin,
    backgroundColor: Colors.elementsBg,
    flexDirection: 'row',
    flex: 1,
  },
  innerContainer: {
    maxWidth: 225,
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
  },
  action: {
    marginLeft: 'auto',
  },
  row: {
    flexDirection: 'row',
    flex: 1,
  },
  submitIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 48,
    height: 48,
  },
})
