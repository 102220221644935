import React from 'react'
import { useSelector } from 'react-redux'
import { FlatList, Text } from 'react-native'
import Animated, { FadeInUp } from 'react-native-reanimated'
import AppointmentCard from 'APP/Components/AppointmentCard'

import I18n from 'APP/Services/i18n'

import Styles from './style'
import moment from 'moment'

const ENTERING = FadeInUp.duration(500)

const UpcomingAppointments = ({ style }) => {
  const appointments = useSelector((state) => state.history.appointments.upcoming)
  const fetchAppointmentsRunning = useSelector((state) => state.history.fetchAppointmentsRunning)
  const actualAppointments = appointments.filter((appointment) =>
    moment().isBefore(appointment.end_at)
  )

  if (fetchAppointmentsRunning || !actualAppointments?.length) return null

  const renderItem = ({ item }) => {
    return <AppointmentCard appointment={item} style={{ ...Styles.cardStyle }} />
  }

  return (
    <Animated.View style={style} entering={ENTERING}>
      <Text style={Styles.title}>{I18n.t('Appointments.upcomingListTitle')}</Text>
      <FlatList
        data={actualAppointments}
        renderItem={renderItem}
        keyExtractor={(item) => `${item.episode_id}-${item.start_at}`}
        style={Styles.appointmentList}
        contentContainerStyle={Styles.appointmentListContainer}
      />
    </Animated.View>
  )
}

export default UpcomingAppointments
