import { put, call, select, take } from 'redux-saga/effects'
import moment from 'moment'
import Toast from 'react-native-toast-message'
import * as Sentry from '@sentry/react-native'
import { Linking } from 'react-native'
// Redux Actions
import ActiveMinutesActions, { ActiveMinutesTypes } from 'APP/Redux/ActiveMinutesRedux'
import LoggerActions from '../Redux/LoggerRedux'

// Services
import Config from 'APP/Config'
import I18n from 'APP/Services/i18n'
import { ActiveMinutes } from '@dialogue/services'
import { filterLatestConnections } from 'APP/Lib/ActiveMinutes/trackers/filters'
import { selectServiceFeatures } from 'APP/Store/Content/selectors'
import { SDK_TRACKERS, supportedTrackers } from 'APP/Lib/ActiveMinutes/trackers/constants'
import { CONNECTION_ERRORS } from 'APP/Lib/ActiveMinutes/trackers/errors'
import { initTerraConnection } from './TerraSagas'
import { categorizeChallenges } from 'APP/Lib/ActiveMinutes/challenges'
import { supportedTrackerActivities } from '../Lib/ActiveMinutes/trackers/constants'
import { navigationRef as Nav } from 'APP/Nav'
import { logDdError } from 'APP/Lib/Datadog'

export function* createActiveMinutes() {
  const accessToken = yield select(selectAccessToken)

  return new ActiveMinutes.Service(accessToken, Config.ACTIVE_MINUTES_SERVICE_URL)
}

export const selectAccessToken = (state) => state.login.accessToken
export const selectTerraReferenceId = (state) => state.activeMinutes.serviceLogin.terraReferenceId
export const connectedTrackersState = (state) => state.activeMinutes.connectedTrackers

function* putAndLogAction(action) {
  yield put(action)
  yield put(LoggerActions.log(`Action: ${JSON.stringify(action)}`))
}

export function* activeMinutesLogin() {
  try {
    const serviceFeatures = yield select(selectServiceFeatures)
    const isEligible = serviceFeatures.fitness_trackers
    if (!isEligible) {
      yield call(
        putAndLogAction,
        ActiveMinutesActions.serviceLoginFailure(undefined, 'User is not elligible')
      )
      return
    }

    const ActiveMinutesService = yield call(createActiveMinutes)
    const loginResponse = yield call(ActiveMinutesService.login)

    yield call(
      putAndLogAction,
      ActiveMinutesActions.serviceLoginSuccess(loginResponse.data.terra_reference_id)
    )
  } catch (e) {
    yield call(putAndLogAction, ActiveMinutesActions.serviceLoginFailure(e?.message || e?.name))
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
  }
}

export function* getActiveMinutes() {
  try {
    const ActiveMinutesService = yield call(createActiveMinutes)

    const activeMinutes = yield call(ActiveMinutesService.getActiveMinutes, {
      from: moment().startOf('isoWeek').format(I18n.t('DateFormat')),
      to: moment().endOf('isoWeek').format(I18n.t('DateFormat')),
    })

    yield put(ActiveMinutesActions.getActiveMinutesSuccess(activeMinutes))
  } catch (e) {
    yield put(ActiveMinutesActions.getActiveMinutesFailure())
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
  }
}

export function* getHistory({ from, weeksBackCount }) {
  try {
    const ActiveMinutesService = yield call(createActiveMinutes)

    const history = yield call(ActiveMinutesService.getHistory, {
      from,
      weeksBackCount,
    })

    yield put(ActiveMinutesActions.getHistorySuccess(history.slice().reverse()))
  } catch (e) {
    yield put(ActiveMinutesActions.getHistoryFailure())
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
  }
}

export function* updateWeeklyTarget({
  target,
  changeType = ActiveMinutes.Types.TargetChangeType.MANAUL,
}) {
  try {
    const ActiveMinutesService = yield call(createActiveMinutes)

    yield call(ActiveMinutesService.setWeeklyTarget, {
      target,
      changeType,
    })

    yield put(ActiveMinutesActions.updateWeeklyTargetSuccess())
    yield put(ActiveMinutesActions.getActiveMinutes())

    if (changeType === ActiveMinutes.Types.TargetChangeType.ACCEPT_PROMPT) {
      const { activeMinutes } = yield take(ActiveMinutesTypes.GET_ACTIVE_MINUTES_SUCCESS)
      Toast.show({
        text1: I18n.t('ActiveMinutes.promptNewTarget.updated.title'),
        text2: I18n.t('ActiveMinutes.promptNewTarget.updated.copy', {
          dailyGoal: activeMinutes.daily_goal,
        }),
      })
    }
  } catch (e) {
    Toast.show({
      text1: I18n.t('ActiveMinutes.changeTarget.updateErrorAlert.title'),
      text2: I18n.t('ActiveMinutes.changeTarget.updateErrorAlert.subtitle'),
    })
    yield put(ActiveMinutesActions.updateWeeklyTargetFailure())
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
  }
}

export function* setConfettiShown() {
  try {
    const ActiveMinutesService = yield call(createActiveMinutes)

    yield call(ActiveMinutesService.setConfettiShown, {
      currentDate: moment().format(I18n.t('DateFormat')),
    })

    yield put(ActiveMinutesActions.setConfettiShownSuccess())
    yield put(ActiveMinutesActions.getActiveMinutes())
  } catch (e) {
    yield put(ActiveMinutesActions.setConfettiShownFailure())
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
  }
}

export function* getConnectedTrackers() {
  try {
    const ActiveMinutesService = yield call(createActiveMinutes)

    const data = yield call(ActiveMinutesService.getTrackers)

    const mergedSetOfAllSupportedActivities = data.data?.reduce((acc, tracker) => {
      const trackerActivities = supportedTrackerActivities[tracker.provider] || []
      trackerActivities.forEach((activity) => {
        acc.add(activity)
      })
      return acc
    }, new Set())
    const supportedActivities = Array.from(mergedSetOfAllSupportedActivities)

    yield put(
      ActiveMinutesActions.getConnectedTrackersSuccess(
        // edge case to be probably removed when backend is finished
        // to resolve duplicate trackers to pick only the latest updated
        filterLatestConnections(data.data),
        supportedActivities
      )
    )
  } catch (e) {
    yield put(ActiveMinutesActions.getConnectedTrackersFailure())
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
  }
}

const getRedirectUrls = (provider) => {
  const baseURL = Config.HEALTH_TRACKER_SETTINGS_SCREEN_DEEP_LINK_URL
  const providerQuery = `provider=${encodeURIComponent(provider)}`
  const authSuccessRedirectUrl = `${baseURL}?${providerQuery}&success=true`
  const authFailureRedirectUrl = `${baseURL}?&error=true`

  return {
    authSuccessRedirectUrl,
    authFailureRedirectUrl,
  }
}

export function* processAPIConnectionRequest({ provider }) {
  const ActiveMinutesService = yield call(createActiveMinutes)

  const data = yield call(ActiveMinutesService.authenticateTracker, {
    provider,
    ...getRedirectUrls(provider),
  })

  yield call((url) => Linking.openURL(url), data.data.auth_url)
}

export function* processSDKConnectionRequest({ provider }) {
  yield call(initTerraConnection, { trackerName: provider, passThroughErrors: true })
}

// process a request for a new connection
// outcome for API providers:
//   a browser tab opens with the auth url,
//   a user will come back by a success/error deeplink to HealthTrackerSettingsScreen with set params
// outcome for SDK providers:
//   init a connection via Terra SDK
//   if the connection is successfull
//   success state would propagate to the HealthTrackerSettingsScreen params as well
// for both options the screen would communicate the result to the user via a banner

export function* requestNewConnection({ provider }) {
  try {
    const isSupported = supportedTrackers.includes(provider)
    const isAPIProvider = !SDK_TRACKERS.includes(provider)
    if (!isSupported) {
      throw new Error(CONNECTION_ERRORS.PROVIDER_NOT_SUPPORTED)
    }

    const handler = isAPIProvider ? processAPIConnectionRequest : processSDKConnectionRequest
    yield call(handler, { provider })

    yield put(ActiveMinutesActions.requestNewConnectionSuccess(!isAPIProvider))
  } catch (e) {
    yield put(ActiveMinutesActions.requestNewConnectionFailure(e?.message || e?.name))
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
  }
}

export function* removeConnection({ id }) {
  try {
    const ActiveMinutesService = yield call(createActiveMinutes)

    yield call(ActiveMinutesService.deauthenticateTracker, { trackerId: id })

    yield put(ActiveMinutesActions.removeConnectionSuccess())
    yield put(ActiveMinutesActions.getConnectedTrackers())
  } catch (e) {
    yield put(ActiveMinutesActions.removeConnectionFailure(e?.message || e?.name))
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
    Toast.show({
      text1: I18n.t('HealthTrackerSettings.error.disconnect'),
    })
  }
}

export function* getChallenges() {
  try {
    const ActiveMinutesService = yield call(createActiveMinutes)

    const { data } = yield call(ActiveMinutesService.getChallenges)
    const categorized = categorizeChallenges(data)

    yield put(
      ActiveMinutesActions.getChallengesSuccess(categorized.joined, categorized.newAndUpcoming)
    )
  } catch (e) {
    yield put(ActiveMinutesActions.getChallengesFailure())
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
  }
}

export function* getMyCompletedChallenges() {
  try {
    const ActiveMinutesService = yield call(createActiveMinutes)

    const { data } = yield call(ActiveMinutesService.getCompletedChallenges)

    const completedWithinLastYear = data?.filter((challenge) => {
      return moment(challenge?.ends_at).isAfter(moment().subtract(1, 'years'))
    })

    yield put(
      ActiveMinutesActions.getMyCompletedChallengesSuccess(
        completedWithinLastYear.length === 0 ? data : completedWithinLastYear
      )
    )
  } catch (e) {
    yield put(ActiveMinutesActions.getMyCompletedChallengesFailure())
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
  }
}

export function* getSingleChallenge({ id }) {
  try {
    const ActiveMinutesService = yield call(createActiveMinutes)

    const challenge = yield call(ActiveMinutesService.getChallenge, { challengeId: id })

    yield put(ActiveMinutesActions.getSingleChallengeSuccess(challenge.data))
  } catch (e) {
    yield put(ActiveMinutesActions.getSingleChallengeFailure())
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
  }
}

export function* joinChallenge({ id }) {
  try {
    const ActiveMinutesService = yield call(createActiveMinutes)

    yield call(ActiveMinutesService.joinChallenge, { challengeId: id })

    yield put(ActiveMinutesActions.joinChallengeSuccess())
    yield put(ActiveMinutesActions.getSingleChallenge(id))
    yield put(ActiveMinutesActions.getChallenges())
  } catch (e) {
    yield put(ActiveMinutesActions.joinChallengeFailure())
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
  }
}

export function* getChallengeLeaderboard({ challengeId }) {
  try {
    const ActiveMinutesService = yield call(createActiveMinutes)

    const leaderboard = yield call(ActiveMinutesService.getLeaderboard, { challengeId })

    yield put(ActiveMinutesActions.getChallengeLeaderboardSuccess(leaderboard.data))
  } catch (e) {
    yield put(ActiveMinutesActions.getChallengeLeaderboardFailure())
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
  }
}

export function* leaveChallenge({ id }) {
  try {
    const ActiveMinutesService = yield call(createActiveMinutes)

    yield call(ActiveMinutesService.leaveChallenge, { challengeId: id })

    yield put(ActiveMinutesActions.leaveChallengeSuccess())
    yield put(ActiveMinutesActions.getSingleChallenge(id))
    yield put(ActiveMinutesActions.getChallenges())
  } catch (e) {
    yield put(ActiveMinutesActions.leaveChallengeFailure())
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
  }
}

export function* handleAppleHealthReconnection() {
  // Ensure we have the latest connected trackers
  yield put(ActiveMinutesActions.getConnectedTrackers())
  yield take([
    ActiveMinutesTypes.GET_CONNECTED_TRACKERS_SUCCESS,
    ActiveMinutesTypes.GET_CONNECTED_TRACKERS_FAILURE,
  ])

  const { data } = yield select(connectedTrackersState)

  const appleTracker = data?.find(
    (tracker) => tracker['provider'] === ActiveMinutes.Types.TrackerName.AppleHealth
  )

  if (appleTracker) {
    yield put(
      ActiveMinutesActions.removeConnection(
        ActiveMinutes.Types.TrackerName.AppleHealth,
        appleTracker['id']
      )
    )
    yield call(Nav.navigate, 'healthTrackerSettings')
  }
}
