import { View, Animated } from 'react-native'
import styled from 'styled-components/native'

const HEIGHT = '2px'

export const Container = styled(View)`
  background-color: ${({ theme }) => theme.colors.lightGray};
  border-radius: ${({ theme }) => theme.metrics.borderRadius.small}px;
  height: ${HEIGHT};
`

export const Bar = styled(Animated.View)`
  background-color: ${({ theme }) => theme.colors.accent};
  border-radius: ${({ theme }) => theme.metrics.borderRadius.small}px;
  height: ${HEIGHT};
`
