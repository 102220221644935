import { StyleSheet } from 'react-native'
import { Colors, Fonts, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  dailyBreakDownContainer: {
    flex: 1,
    paddingBottom: Metrics.baseMargin * 0.3,
  },
  dailyBreakDownWeekday: {
    ...Fonts.style.subheaderBold,
    color: Colors.textInverted,
    paddingVertical: Metrics.baseMargin / 2,
  },
  dailyBreakDownMinutes: {
    ...Fonts.style.h2,
    color: Colors.textInverted,
    paddingBottom: Metrics.baseMargin / 4,
  },
  dailyBreakDownSubheader: {
    ...Fonts.style.subheader,
    color: Colors.textInverted,
    paddingTop: Metrics.baseMargin / 2,
  },
  progressPillContainer: {
    width: 16,
    height: 28,
    backgroundColor: Colors.buttonLinen,
    borderRadius: 8,
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-end',
    overflow: 'hidden',
  },
  progressPillContainerDisabled: {
    backgroundColor: Colors.deactivatedElementBg,
    opacity: 0.3,
  },
  progressPill: {
    flexGrow: 0,
    borderRadius: 8,
    backgroundColor: Colors.accent,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: Metrics.baseMargin * 3,
    paddingTop: Metrics.baseMargin * 1.5,
    paddingBottom: Metrics.baseMargin / 2,
  },
  dayContainer: {
    alignItems: 'center',
    paddingVertical: Metrics.baseMargin / 2,
  },
  dayLabel: {
    ...Fonts.style.subheader,
    color: Colors.text,
    paddingTop: Metrics.baseMargin / 2.5,
    textTransform: 'uppercase',
  },
  dayLabelCurrent: {
    ...Fonts.style.subheaderBold,
  },
  dot: {
    marginTop: 6,
    width: 4,
    height: 4,
    borderRadius: 2,
    backgroundColor: Colors.text,
    alignSelf: 'center',
  },
  textDisabled: {
    color: Colors.deactivatedElementBg,
    ...Fonts.style.body,
  },
})
