import React, { useLayoutEffect } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import Moment from 'moment'

import Config from 'APP/Config'
import { Format } from 'APP/Lib/ProfileDataFormat'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import Button from 'APP/Converse/Button'

import Cobranding from 'APP/Components/Cobranding'
import ListItem from 'APP/Converse/ListItem'
import DialogueBranding from 'APP/Components/DialogueBranding'
import List from 'APP/Components/List'
import { getShouldProvideProvince } from 'APP/Lib/ProfileHelpers'

// Styles
import Styles from './style'
import Typography from 'APP/Converse/Typography'

// I18n
import I18n from 'APP/Services/i18n'

//Services
import { getAdminAreaString } from 'APP/Services/AdminAreas'
import { useServiceFeatures } from 'APP/Hooks/ServiceFeatures'
import { ResponsiveScrollView } from 'APP/Converse/Layout'
import PatientHistoryRedux from 'APP/Redux/PatientHistoryRedux'
import { isHealthProfileEnabledState } from 'APP/Sagas/PatientSagas'

const HealthProfileSection = ({ getOrCreateHealthProfileEpisode }) => (
  <View style={Styles.healthProfileContainer} testID="HEALTH_PROFILE_SECTION">
    <Typography variant="h3" style={Styles.healthProfileHeader}>
      {I18n.t('ProfileScreen.createHealthProfileHeader')}
    </Typography>
    <Typography variant="bodySmall" style={Styles.healthProfileDescription}>
      {I18n.t('ProfileScreen.createHealthProfileDescription')}
    </Typography>
    <Button
      testID="HEALTH_PROFILE_SECTION_BUTTON"
      title={I18n.t('ProfileScreen.healthProfile')}
      analyticsName="ProfileScreen_healthProfile"
      onPress={() => {
        getOrCreateHealthProfileEpisode('ctaTextLabel')
      }}
    />
  </View>
)

const ProfileScreen = () => {
  const navigation = useNavigation()
  const profile = useSelector((state) => state.patient.profile)
  const idCardUrl = useSelector((state) => state.patient.idCardUrl || state.patient.idCardLocalPath)
  const showPronouns = useSelector((state) => state.features.pronounDisplay)
  const canChangeEmail = useSelector((state) => {
    const customClaims = state.login.customClaims || {}
    return (
      !Config.PROFILE_CHANGE_EMAIL_DISABLED_ON_SSO_CONNECTION?.includes(
        customClaims['sso-connection']
      ) ?? true
    )
  })
  const serviceFeatures = useServiceFeatures()
  const shouldProvideProvince = getShouldProvideProvince()
  const dispatch = useDispatch()

  const getOrCreateHealthProfileEpisode = (ctaId) => {
    navigation.navigate('healthProfileConversation')
    dispatch(PatientHistoryRedux.getOrCreateHealthProfileEpisodeRequest(ctaId))
  }

  const healthProfileFeatureEnabled = useSelector(isHealthProfileEnabledState)

  useLayoutEffect(() => {
    navigation.setOptions({
      rightButtonIcon: 'pencil',
      rightButtonIconVariant: 'material',
      rightButtonText: 'Edit',
      rightButtonTitle: I18n.t('ProfileScreen.edit'),
      rightButtonOnPress: () => navigation.navigate('editProfile'),
    })
  })

  return (
    <NoticeBarContainer>
      <ResponsiveScrollView>
        <List containerStyle={Styles.listContainer}>
          <ListItem
            key="givenName"
            analyticsName="givenName"
            testID="PROFILE_SCREEN_FIRST_NAME_FIELD"
            title={I18n.t('ProfileScreen.legalName')}
            rightTitle={profile.givenName}
            hideIconComponent
          />
          {Boolean(profile.preferredName && profile.preferredName !== '') && (
            <ListItem
              key="preferredName"
              analyticsName="preferredName"
              testID="PROFILE_SCREEN_PREFERRED_NAME_FIELD"
              title={I18n.t('ProfileScreen.preferredName')}
              rightTitle={profile.preferredName}
              hideIconComponent
            />
          )}
          <ListItem
            key="familyName"
            analyticsName="familyName"
            testID="PROFILE_SCREEN_LAST_NAME_FIELD"
            title={I18n.t('ProfileScreen.familyName')}
            rightTitle={profile.familyName}
            hideIconComponent
          />
          {showPronouns && Boolean(profile?.pronouns) && (
            <ListItem
              key="pronouns"
              analyticsName="pronouns"
              testID="PROFILE_SCREEN_PRONOUNS_FIELD"
              title={I18n.t('ProfileScreen.pronouns')}
              rightTitle={profile.pronouns}
              hideIconComponent
            />
          )}
          <ListItem
            key="bday"
            analyticsName="bday"
            testID="PROFILE_SCREEN_DOB_FIELD"
            title={I18n.t('ProfileScreen.bday')}
            rightTitle={Moment(profile.dateOfBirth).format(Format.DISPLAY_DATE)}
            hideIconComponent
          />
          {profile.genderIdentity && (
            <ListItem
              key="genderIdentity"
              analyticsName="genderIdentity"
              testID="PROFILE_SCREEN_GENDER_IDENTITY_FIELD"
              title={I18n.t('ProfileScreen.genderIdentity')}
              rightTitle={Format.gender(profile.genderIdentity)}
              hideIconComponent
            />
          )}
          {profile.genderIdentity === 'prefer_to_self_identify' && (
            <ListItem
              key="genderIdentityDescription"
              analyticsName="genderIdentityDescription"
              testID="PROFILE_SCREEN_GENDER_IDENTITY_DESCRIPTION_FIELD"
              title={I18n.t('ProfileScreen.genderIdentityDescription')}
              rightTitle={profile.genderIdentityDescription}
              hideIconComponent
            />
          )}
          {shouldProvideProvince && (
            <ListItem
              key="residesIn"
              analyticsName="residesIn"
              testID="PROFILE_SCREEN_PROVINCE_FIELD"
              title={I18n.t('ProfileScreen.residesIn')}
              rightTitle={getAdminAreaString(profile.residesIn) || '-'}
              hideIconComponent
            />
          )}
          <ListItem
            key="mobile"
            analyticsName="mobile"
            testID="PROFILE_SCREEN_PHONE_NUMBER_FIELD"
            title={I18n.t('ProfileScreen.number')}
            rightTitle={Format.phoneNumber(profile.phoneNumber)}
            hideIconComponent
          />
          <ListItem
            key="language"
            analyticsName="language"
            testID="PROFILE_SCREEN_LANG_FIELD"
            title={I18n.t('ProfileScreen.language')}
            rightTitle={Format.language(profile.preferred_language)}
            hideIconComponent
          />
          <ListItem
            key="email"
            analyticsName="email"
            testID="PROFILE_SCREEN_EMAIL_FIELD"
            title={I18n.t('ProfileScreen.email')}
            rightTitle={profile.email}
            onPress={() => canChangeEmail && navigation.navigate('changeEmail')}
            hideIconComponent={!canChangeEmail}
          />
          {(serviceFeatures.photo_id || !!idCardUrl) && (
            <ListItem
              key="photoID"
              analyticsName="photoID"
              testID="PROFILE_SCREEN_ID_FIELD"
              title={I18n.t('ProfileScreen.photoID')}
              rightTitle={
                idCardUrl
                  ? I18n.t('ProfileScreen.photoIDOnFile')
                  : I18n.t('ProfileScreen.photoIDMissing')
              }
              onPress={() => navigation.navigate('updatePhotoId')}
            />
          )}
        </List>
        {healthProfileFeatureEnabled && (
          <HealthProfileSection getOrCreateHealthProfileEpisode={getOrCreateHealthProfileEpisode} />
        )}
      </ResponsiveScrollView>
      <View style={Styles.brandingContainer}>
        <Cobranding />
        <DialogueBranding />
      </View>
    </NoticeBarContainer>
  )
}

export default ProfileScreen
