import React, { useEffect } from 'react'
import { useWindowDimensions, View } from 'react-native'
import { useKeepAwake } from 'expo-keep-awake'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components/native'

import { signals, packetLossStatuses } from 'APP/Lib/VideoSession/constants'

import LoggerActions from 'APP/Redux/LoggerRedux'

import sprigClient from 'APP/Services/Sprig'
import I18n from 'APP/Services/i18n'

import usePrevious from 'APP/Hooks/usePrevious'

// Components
import PublisherView from 'APP/NativeComponents/PublisherView.web'
import SubscriberView from 'APP/NativeComponents/SubscriberView.web'
import VideoCallPacketLossIndicator from 'APP/Components/VideoCallPacketLossIndicator'

// Styles
import Styles from './style'
import Actions from './Actions.web'

export const VideosContainer = styled.View`
  height: 100%;
  width: 100%;
  position: relative;
  display: grid;

  grid-template-rows: 0.5fr 0.5fr;
  grid-template-columns: 1fr;

  ${({ stacked, theme }) =>
    !stacked &&
    `
    padding: 0 ${theme.metrics.baseMargin}px;
    grid-template-rows: 1fr;
    grid-template-columns: 0.5fr 0.5fr;
    align-items: center;
    align-self: center;
  `};
`

export const VideoContainer = styled.View`
  position: relative;
  overflow: hidden;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.primary};
  ${({ stacked, theme }) =>
    !stacked &&
    `
      aspect-ratio: 16 / 10;
      border-radius: ${theme.metrics.borderRadius.small}px;
      margin: 0 ${theme.metrics.baseMargin / 2}px;
      border: 1px solid ${theme.colors.lightGray};
  `}
`

const VideoCallScreen = () => {
  useKeepAwake()
  const dispatch = useDispatch()

  const { height, width } = useWindowDimensions()

  const stacked = height >= width

  const videoSession = useSelector((state) => state.videoSession)
  const { currentLossStatus } = useSelector((state) => state.videoSession.publisherStats)
  const hasCriticalPacketLoss = currentLossStatus === packetLossStatuses.CRITICAL
  const hasPacketLoss = hasCriticalPacketLoss || currentLossStatus === packetLossStatuses.WARNING

  const practitionerStream = videoSession.streams.find(
    (stream) => stream.name === signals.properties.senderTypes.CARE_PLATFORM
  )

  // Get previous status and cache current status
  const previousCurrentLossStatus = usePrevious(currentLossStatus)

  useEffect(() => {
    if (previousCurrentLossStatus !== currentLossStatus) {
      dispatch(LoggerActions.trackVideoQualityChange(currentLossStatus, previousCurrentLossStatus))
    }
    return () => {
      sprigClient.track(`VIDEO_CONSULTATION_ENDED_${I18n.baseLocale.toUpperCase()}`, {
        episode_id: videoSession.episodeId,
      })
    }
  }, [dispatch, currentLossStatus, previousCurrentLossStatus])

  return (
    <View style={Styles.container}>
      <VideosContainer stacked={stacked}>
        <VideoContainer stacked={stacked}>
          <SubscriberView
            streamId={practitionerStream?.streamId}
            isMuted={!practitionerStream?.hasAudio}
            isVideoOff={!practitionerStream?.hasVideo}
          />
        </VideoContainer>
        <VideoContainer stacked={stacked}>
          <PublisherView
            sessionId={videoSession?.sessionId}
            isMuted={videoSession?.patientIsMuted}
            isCameraOff={false}
          />
        </VideoContainer>
      </VideosContainer>
      <Actions />
      <VideoCallPacketLossIndicator
        containerStyle={Styles.pubPacketLossOverlayContainer}
        hasPacketLoss={hasPacketLoss}
        hasCriticalPacketLoss={hasCriticalPacketLoss}
      />
    </View>
  )
}

export default VideoCallScreen
