import RNDeviceInfo from 'react-native-device-info'
import { v4 as uuid } from 'uuid'

// Note that this "deviceId" will be specific to the browser or device and will change if the user clears their browser data or switches devices.
const deviceId = uuid()

const DeviceInfo = {
  ...RNDeviceInfo,

  getUniqueId: () =>
    RNDeviceInfo.getUniqueId() === 'unknown' ? deviceId : RNDeviceInfo.getUniqueId(),
}

export default DeviceInfo
