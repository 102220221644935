import React, { useCallback, useMemo } from 'react'
import { useActionSheet } from '@expo/react-native-action-sheet'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import Analytics from 'APP/Services/Analytics'
import { ApplicationStyles, Colors, Metrics } from 'APP/Themes'

export const ButtonMoreAction = ({
  actions = [],
  analyticsName,
  onPress,
  onPressActionSheetAction,
  size = Metrics.icons.medium,
}) => {
  const { showActionSheetWithOptions } = useActionSheet()
  const handleOnPressActionSheetAction = ({ handler, value, choiceIndex, actions: acts }) => {
    let preventDefaultValue = false
    if (onPressActionSheetAction) {
      onPressActionSheetAction({
        preventDefault() {
          preventDefaultValue = true
        },
        handler,
        value,
        choiceIndex,
        actions: acts,
      })
      if (preventDefaultValue) {
        return
      }
    }
    if (analyticsName) {
      Analytics.trackEvent('button_click', {
        button_value: value,
        screen_name: analyticsName,
      })
    }
    handler && handler()
  }
  const actionsSheetOptions = useMemo(() => {
    const normalizeEnableConds = (enable) => {
      if (Array.isArray(enable)) {
        return enable.flatMap((cond) => normalizeEnableConds(cond))
      }
      if (typeof enable === 'function') {
        return normalizeEnableConds(enable())
      }
      return typeof enable === 'undefined' ? Array.of(true) : Array.of(enable)
    }
    const acts = actions.filter(({ enable }) => {
      const conds = normalizeEnableConds(enable)
      return conds.every((cond) => cond)
    })
    return [
      {
        ...ApplicationStyles.actionSheet,
        options: acts.map(({ value }) => value),
        destructiveButtonIndex: acts.findIndex(({ destructive }) => destructive),
        cancelButtonIndex: acts.findIndex(({ cancellable }) => cancellable),
      },
      (choiceIndex) =>
        handleOnPressActionSheetAction({
          ...acts[choiceIndex],
          choiceIndex,
          actions: acts,
        }),
    ]
  }, [actions])
  const handleOnPress = useCallback(() => {
    let preventDefault = false
    if (onPress) {
      onPress({
        preventDefault() {
          preventDefault = true
        },
        options: actionsSheetOptions[0],
        callback: actionsSheetOptions[1],
      })

      if (preventDefault) {
        return
      }
    }
    if (analyticsName) {
      Analytics.trackEvent('button_click', {
        button_value: 'show action sheet',
        screen_name: analyticsName,
      })
    }
    showActionSheetWithOptions(...actionsSheetOptions)
  }, [actionsSheetOptions])

  return (
    <MaterialIcon
      accessibilityRole="button"
      accessible
      name="more-vert"
      size={size}
      color={Colors.text}
      onPress={handleOnPress}
      testID="moreBtn"
    />
  )
}

export default ButtonMoreAction
