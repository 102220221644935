import apisauce from 'apisauce'
import { WebAuth } from 'auth0-js'
import I18n from 'APP/Services/i18n'

import Config from 'APP/Config'
import { getHref } from 'APP/Lib/Utilities'
import { logDdError } from 'APP/Lib/Datadog'

function getUILocales() {
  return Config.AUTH0_LANGUAGE_LOCALES[I18n.locale] || Config.AUTH0_LANGUAGE_LOCALES.en
}

class AuthClient {
  init() {
    this.baseURL = Config.AUTH0_URL
    this.api = apisauce.create({
      baseURL: this.baseURL,
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: 50000,
    })
    this.auth0 = new WebAuth({
      domain: Config.AUTH0_DOMAIN,
      clientID: Config.AUTH0_WEB_CLIENT_ID,
      redirectUri: `${window.location.href}?lng=${I18n.locale}`,
      responseType: 'token',
      audience: Config.AUTH0_API_AUDIENCE,
      scope: 'openid offline_access app_metadata email',
    })
  }

  authorize = ({ connection = null, triggerSilentlogin = false }) => {
    return new Promise((resolve, reject) => {
      this.auth0.popup.authorize(
        {
          connection,
          brand_id: Config.BRAND_ID,
          ui_locales: getUILocales(),
          //this is needed to force the MFA to be asked again
          acr_values: 'http://schemas.openid.net/pape/policies/2007/06/multi-factor',
          prompt: triggerSilentlogin ? 'none' : 'login',
        },
        (err, res) => {
          //Ignoring coverage because testing this callback would test nothing
          /* istanbul ignore next */
          if (err) {
            reject(err)
          } else {
            resolve(res)
          }
        }
      )
    })
  }

  renewAuth = () => {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({ brand_id: Config.BRAND_ID }, (err, res) => {
        if (err) {
          reject(err)
        } else {
          resolve(res)
        }
      })
    })
  }

  logout = () => {
    return new Promise((resolve, reject) => {
      this.auth0.logout({ returnTo: getHref() }, (err, res) => {
        if (err) {
          reject(err)
        } else {
          resolve(res)
        }
      })
    })
  }

  signup = ({ connection = null, triggerSilentlogin = false }) => {
    return new Promise((resolve, reject) => {
      this.auth0.popup.authorize(
        {
          brand_id: Config.BRAND_ID,
          ui_locales: getUILocales(),
          //this is needed to force the MFA to be asked again
          acr_values: 'http://schemas.openid.net/pape/policies/2007/06/multi-factor',
          screen_hint: 'signup',
          prompt: triggerSilentlogin ? 'none' : 'login', // this is to force a "auth0 logout"
          connection,
        },
        (err, res) => {
          //Ignoring coverage because testing this callback would test nothing
          /* istanbul ignore next */
          if (err) {
            reject(err)
          } else {
            resolve(res)
          }
        }
      )
    })
  }

  getUserInfo = ({ accessToken }) => {
    return this.api
      .get('/userinfo', {}, { headers: { Authorization: `Bearer ${accessToken}` } })
      .then((response) => {
        if (response.ok) {
          const userMetadataKey = 'https://dialogue/claims/user_metadata'
          const userMetadata = response.data && response.data[userMetadataKey]
          if (userMetadata) {
            delete response.data[userMetadataKey]
            response.data.user_metadata = userMetadata
          }
          return response.data
        } else {
          logDdError(
            'Error getting user info from Auth0',
            'App/Services/AuthClient.web.js',
            'CUSTOM',
            {
              data: response.data,
              error: response.data?.errors,
            }
          )
          throw response.data?.errors
        }
      })
  }

  loginWithUsernameAndPassword = ({ username, password, redirectUri }) => {
    let payload = {
      username,
      password,
      realm: Config.AUTH0_CONNECTION,
      brand_id: Config.BRAND_ID,
      ui_locales: getUILocales(),
    }
    if (redirectUri) {
      payload.redirectUri = redirectUri
    }
    return new Promise((resolve, reject) => {
      this.auth0.login(
        payload,
        /* istanbul ignore next */
        (err, res) => {
          //Ignoring coverage because testing this callback would test nothing
          /* istanbul ignore next */
          if (err) {
            reject(err)
          } else {
            resolve(res)
          }
        }
      )
    })
  }

  parseHash = () => {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash({ hash: window.location.hash }, (err, res) => {
        if (err) {
          reject(err)
        } else {
          resolve(res)
        }
      })
    })
  }

  popupCallback = () => {
    this.auth0.popup.callback()
  }
}

const authClient = new AuthClient()
Config.onConfigKeyChange('AUTH0_URL', (done) => {
  authClient.init()
  done()
})
Config.onConfigKeyChange('AUTH0_WEB_CLIENT_ID', (done) => {
  authClient.init()
  done()
})
authClient.init()

export default authClient
