import React, { useState } from 'react'
import { FlatList, View } from 'react-native'

import { SecondaryActiveButton, SecondaryButton } from 'APP/Components/Buttons'
import { isWeb, isMobile } from 'APP/Helpers/checkPlatform'

import Styles from './style'

const Toggle = ({ isActive, copy, onPress, first, last, isVisible }) => {
  if (!isVisible) {
    return null
  }
  let style = { ...Styles.toggle }

  // Only add additional padding for horizontally scrolling mobile toggles
  if (isMobile()) {
    if (first) style = { ...style, ...Styles.firstToggle }
    if (last) style = { ...style, ...Styles.lastToggle }
  }

  if (isActive) {
    return <SecondaryActiveButton style={style} title={copy} onPress={onPress} />
  }

  return <SecondaryButton style={style} title={copy} onPress={onPress} />
}

const Toggles = ({ toggles, onChange, slim }) => {
  const [active, setActive] = useState(toggles[0])

  const handleToggle = (toggle, index) => {
    setActive(toggle)
    onChange(index)
  }

  const renderItem = ({ item, index }) => (
    <Toggle
      copy={item.title}
      isActive={active.name === item.name}
      onPress={() => handleToggle(item, index)}
      first={index === 0}
      last={index === toggles.length - 1}
      isVisible={item.hasContent || item.hasContent === undefined}
    />
  )

  if (isWeb()) {
    return (
      <View style={Styles.webToggles}>
        {toggles.map((toggle, index) => renderItem({ item: toggle, index }))}
      </View>
    )
  } else {
    return (
      <FlatList
        data={toggles}
        horizontal
        showsHorizontalScrollIndicator={false}
        style={slim ? Styles.slimToggles : Styles.toggles}
        keyExtractor={(item, index) => String(index)}
        renderItem={renderItem}
      />
    )
  }
}

export default Toggles
