import React, { useEffect, useMemo, useState } from 'react'
import { View, ActivityIndicator } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { INTAKE_MESSAGE_TYPES, INTAKE_REPLY_FORMS } from 'APP/Components/Intake'
import I18n from 'APP/Services/i18n'
import Analytics from 'APP/Services/Analytics'
import sprigClient from 'APP/Services/Sprig'
import PatientHistoryActions from 'APP/Redux/PatientHistoryRedux'
import NotificationActions from 'APP/Redux/NotificationRedux'
import { Styles } from './style'
import { ChatContext } from 'APP/Lib/Context'
import {
  channelToTimeStampForNewPost,
  formatLegacyMultiSelectAnswer,
} from 'APP/Lib/MessagingHelpers'
import { POSTS_PER_PAGE } from 'APP/Sagas/PatientHistorySagas'
import Modal from 'APP/Components/Modal'
import DialogueAutolink from 'APP/Components/DialogueAutolink'
import Config from 'APP/Config'
import { getPatientMember } from 'APP/Lib/ProfileHelpers'
import { isMobile } from 'APP/Helpers/checkPlatform'
import {
  checkIsGynOrSexualHealthFlow,
  checkIsProviderSelectionSurveyEnabled,
} from 'APP/Helpers/Intake'
import Conversation from 'APP/Components/FelixChat/Conversation'
import { EpisodeAssignee } from 'APP/Converse/Chat/EpisodeAssignee'
import Typography from 'APP/Converse/Typography'
import AppointmentBanner from 'APP/Components/AppointmentBooking/AppointmentBanner'
import styled, { useTheme } from 'styled-components/native'
import { formatTimeForAppointment } from './helper'

const SubHeaderContainer = styled.View`
  padding-bottom: ${({ theme }) => theme.metrics.baseMargin / 2}px;
`

const REQUEST_ESTIMATION_INTERVAL = 5000 // 5 sec

const ChatScreen = ({ channelId, navigation }) => {
  const dispatch = useDispatch()
  const theme = useTheme()

  //Selectors
  const conversation = useSelector((state) => state.history.channels[channelId] || {})
  const userId = useSelector((state) => state.login.messagingUserId)
  const isInForeground = useSelector((state) => state.appSession.isActive)
  const eligibleBrands = useSelector((state) => state.patient?.profile?.eligibleBrands)
  const eligibleServices = useSelector((state) => state.patient.profile?.eligibleServices)
  const createdEpisodeError = useSelector((state) => state.history.createEpisodeError)
  const profile = useSelector((state) => state.patient.profile)
  const practitioners = useSelector((state) => state.history.practitioners)
  const isConnected = useSelector((state) => state.appSession.isConnected)
  const episodes = useSelector((state) => state.history.episodes)
  const showEstimationComponent = useSelector((state) => state.features.showEstimationComponent)
  const readReceipts = useSelector((state) => state.features.readReceipts)
  const episode = useSelector((state) => state.history.episode)
  const episodeMembers = useSelector((state) => state.history.episodeMembers)
  const typingEvent = useSelector((state) => state.history.typingEvent)
  const upcomingAppointments = useSelector((state) => state.history?.appointments?.upcoming)

  //State
  const [renderLimit, setRenderLimit] = useState(POSTS_PER_PAGE)
  const [selectedAddress, setSelectedAddress] = useState()
  const [selectedFile, setSelectedFile] = useState()

  const { order, users, orderedPosts, posts } = useMemo(() => {
    let posts = { ...(conversation.posts || {}), ...(conversation.pendingPosts || {}) }
    let order = [...(conversation.pendingOrder || []), ...(conversation.order || [])]

    const postsLimited = {}
    const users = []
    users.push(getPatientMember(profile, userId))

    order.forEach((_, i) => {
      postsLimited[order[i]] = posts[order[i]]
      const postId = order[i]
      const userId = posts[postId].user_id
      const user = practitioners[userId]
      if (user) {
        const avatarUrl = user.avatar_url || (user.props && user.props.picture)
        users.push({ ...user, ...{ props: { picture: avatarUrl } } })
      }
    })

    posts = postsLimited

    const lastPostId = [...(conversation.pendingOrder || []), ...(conversation.order || [])][0]

    const orderedPosts = order.map((id) => posts[id])

    return {
      order,
      orderedPosts,
      posts,
      users,
      lastPostId,
    }
  }, [conversation, practitioners, profile, renderLimit, userId])

  //Derived data
  const lastPostId = order[0]
  const hasPostFailureError = conversation.hasPostFailureError || false
  const hasEligibleBrands = eligibleBrands?.length > 0
  const isInactive = Object.keys(eligibleServices).length === 0
  const isLoading = (!conversation.order || conversation.order.length === 0) && !createdEpisodeError
  const showError = !!createdEpisodeError && !channelId
  const isInactiveAndEpisodeInactive = isInactive && !conversation.active
  const lastPost = orderedPosts?.[0]
  const healthIssueTypeId = episodes?.[channelId]?.healthIssueTypeId
  const upcomingAppointment = upcomingAppointments?.find(
    (appointment) => appointment.episode_id === channelId
  )

  const isGynOrSexualHealthFlow = checkIsGynOrSexualHealthFlow(posts)
  const isProviderSelectionSurveyEnabled = checkIsProviderSelectionSurveyEnabled(posts, lastPost)

  useEffect(() => {
    navigation.setOptions({
      hideBackButton: false,
      headerShown: !!lastPostId,
      title:
        healthIssueTypeId && healthIssueTypeId !== I18n.t(`health-issue-types:${healthIssueTypeId}`)
          ? I18n.t(`health-issue-types:${healthIssueTypeId}`)
          : I18n.t('EpisodeComponent.newChat'),
      subHeader: episode?.assigneeId && (
        <SubHeaderContainer>
          <EpisodeAssignee
            assigneePicture={episode?.assigneePicture}
            assigneeFirstName={episode?.assigneeFirstName}
          />
        </SubHeaderContainer>
      ),
    })
  }, [healthIssueTypeId, episode?.assigneeId])

  // Check on mount for notification permissions on mobile
  useEffect(() => {
    if (isMobile() && !isLoading && !createdEpisodeError) {
      dispatch(NotificationActions.triggerNotificationPermissionModal())
    }
  }, [dispatch, isLoading, createdEpisodeError])

  // Fetch episode and episode members at a set interval
  useEffect(() => {
    const fetchEpisodeInterval = setInterval(() => {
      dispatch(PatientHistoryActions.fetchOneEpisodeRequest(channelId))
    }, REQUEST_ESTIMATION_INTERVAL)
    const fetchEpisodeMembersInterval = setInterval(() => {
      dispatch(PatientHistoryActions.fetchEpisodeMembersRequest(channelId))
    }, REQUEST_ESTIMATION_INTERVAL)

    if (showEstimationComponent) {
      Analytics.attachEntity('estimation_time', { channelId })
    } else {
      clearInterval(fetchEpisodeInterval)
    }

    if (!readReceipts) {
      clearInterval(fetchEpisodeMembersInterval)
    }

    return () => {
      clearInterval(fetchEpisodeInterval)
      clearInterval(fetchEpisodeMembersInterval)
    }
  }, [dispatch, channelId, showEstimationComponent, readReceipts])

  //Update channel last viewed on mount and dismount
  useEffect(() => {
    dispatch(PatientHistoryActions.updateChannelLastViewed(channelId))
    if (channelId) {
      dispatch(PatientHistoryActions.fetchOneEpisodeRequest(channelId))
      dispatch(PatientHistoryActions.fetchEpisodeMembersRequest(channelId))
    }
    return () => {
      dispatch(PatientHistoryActions.updateChannelLastViewed(channelId))
    }
  }, [dispatch, channelId])

  //Refresh appointments on dismount
  useEffect(
    () => () => {
      dispatch(PatientHistoryActions.refreshAppointmentsRequest())
      dispatch(PatientHistoryActions.fetchOneEpisodeSuccess({}))
      dispatch(PatientHistoryActions.fetchEpisodeMembersSuccess({}))
    },
    [dispatch]
  )

  //Update episode context for analytics
  useEffect(() => {
    Analytics.attachEntity('episode', { channelId })
    return () => {
      Analytics.detachEntity('episode')
    }
  }, [channelId])

  // Sprig analytics
  useEffect(() => {
    // When starting intake, there is a moment this component is rendered before the
    // intake is completed. The only way to make sure we're actually in the chat screen
    // is to check if the episode state is defined.
    if (episode?.state) {
      sprigClient.track(`SCREEN_VIEW_CHAT`)
    }
  }, [episode?.state])

  //Update the channel last viewed when the app is put to foreground
  useEffect(() => {
    if (isInForeground) {
      dispatch(PatientHistoryActions.updateChannelLastViewed(channelId))
    }
  }, [dispatch, channelId, isInForeground])

  //Update the channel last viewed when we receive a new message
  useEffect(() => {
    if (navigation.isFocused()) {
      dispatch(PatientHistoryActions.refreshAppointmentsRequest())
      dispatch(PatientHistoryActions.updateChannelLastViewed(channelId))
    }
  }, [dispatch, channelId, lastPostId])

  // Trigger a Sprig survey if applicable
  // A timeout has been set to allow new message (ex: Add to calendar) to appear immediately in Chat before triggering the Sprig survey
  useEffect(() => {
    setTimeout(() => {
      if (posts) {
        let sprigAction = ''
        Object.keys(posts).some((key) => {
          const post = posts[key]
          if (post?.props?.member_app_metadata?.sprig_action) {
            sprigAction = post?.props?.member_app_metadata?.sprig_action
            return true
          }
        })
        if (sprigAction) {
          sprigClient.track(sprigAction, { episode_id: channelId })
        }
      }
    }, 300)
  }, [posts])

  useEffect(() => {
    if (conversation.hiddenPosts) {
      const callEnded = Object.values(conversation.hiddenPosts).find(
        (el) => el?.props?.dialogue_type === 'episode_call_ended'
      )
      if (callEnded) {
        if (isGynOrSexualHealthFlow) {
          sprigClient.track('MX_UROGYN_AFTERCONSULTATION', { episode_id: channelId })
        } else {
          sprigClient.track('PHONE_OR_VIDEO_ENDED', { episode_id: channelId })
        }
      }
    }
  }, [conversation.hiddenPosts, channelId, isGynOrSexualHealthFlow])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isProviderSelectionSurveyEnabled) {
        sprigClient.track('MEMBER_BOOKED_VIA_PROVIDER_BASED', { episode_id: channelId })
      }
    }, 300)
    return () => clearTimeout(timer)
  }, [conversation, channelId, isProviderSelectionSurveyEnabled, lastPost, posts])

  const getLastPostIntakeForm = () => {
    const lastPostId = [...(conversation.pendingOrder || []), ...(conversation.order || [])][0]
    const lastPost = conversation.posts?.[lastPostId]
    return getPostIntakeForm(lastPost)
  }

  const getPostIntakeForm = (post) => {
    const postType = post?.props?.type
    const intakeReplyForm = INTAKE_REPLY_FORMS?.[postType]

    if (intakeReplyForm) {
      return {
        type: INTAKE_MESSAGE_TYPES[postType],
        sendAnswer:
          typeof intakeReplyForm?.createSubmitHandler === 'function' &&
          intakeReplyForm.createSubmitHandler(post.props, sendIntakeAnswer),
        component: intakeReplyForm.component,
      }
    }
  }

  const sendIntakeAnswer = (reply) => {
    Analytics.trackEvent('button_click', { button_value: 'Send intake answer' })
    const { props, display, fileObj } = reply
    const timeStamp = channelToTimeStampForNewPost(conversation)

    if (fileObj) {
      dispatch(
        PatientHistoryActions.submitFilePostRequest(channelId, userId, fileObj, timeStamp, props)
      )
    } else {
      dispatch(
        PatientHistoryActions.submitTextWithPayloadPostRequest(
          channelId,
          userId,
          display,
          timeStamp,
          props
        )
      )
    }

    dispatch(PatientHistoryActions.updateChannelLastViewed(channelId))
  }

  const sendMessage = ({ message }) => {
    const intakeForm = getLastPostIntakeForm()

    if (intakeForm && intakeForm.type === INTAKE_MESSAGE_TYPES.prompt_text) {
      intakeForm.sendAnswer(message)
    } else {
      Analytics.trackEvent('button_click', { button_value: 'Send text' })
      const timeStamp = channelToTimeStampForNewPost(conversation)
      dispatch(PatientHistoryActions.submitTextPostRequest(channelId, userId, message, timeStamp))
      dispatch(PatientHistoryActions.updateChannelLastViewed(channelId))
    }
  }

  const sendSingleChoiceAnswer = (post, choice) => {
    const intakeForm = getPostIntakeForm(post)
    if (intakeForm) {
      intakeForm.sendAnswer(choice)
    } else {
      sendAnswer([choice], post.props.question)
    }
  }

  const sendMultiChoiceAnswer = (post, choices) => {
    const intakeForm = getPostIntakeForm(post)
    if (intakeForm) {
      intakeForm.sendAnswer(choices)
    } else {
      // used to send legacy multiSelect.v1 message type
      Analytics.trackEvent('button_click', { button_value: 'Answer selected' })
      const flags = {
        update_channel_state: false,
        question_type: post.props.question_type,
      }
      const payload = formatLegacyMultiSelectAnswer(post, choices)
      const timeStamp = channelToTimeStampForNewPost(conversation)
      const display = choices.map(({ display }) => display).join(', ')
      dispatch(
        PatientHistoryActions.submitTextWithPayloadPostRequest(
          channelId,
          userId,
          display,
          timeStamp,
          flags,
          payload
        )
      )
    }
  }

  const sendAnswer = (response, question) => {
    Analytics.trackEvent('button_click', { button_value: 'Answer selected' })
    const replyProps = question.reply_props ? question.reply_props : {}

    const timeStamp = channelToTimeStampForNewPost(conversation)
    dispatch(
      PatientHistoryActions.submitAnswerPostRequest(
        channelId,
        userId,
        {
          question_guid: question.guid,
          question_type: question.type,
          question_name: question.question_id,
          text: Array.isArray(response) ? response.map((item) => item.text).join('; ') : response,
          data: Array.isArray(response) ? response.map((item) => item.value) : response,
        },
        timeStamp,
        replyProps
      )
    )
    dispatch(PatientHistoryActions.updateChannelLastViewed(channelId))
  }

  const sendTextWithPayload = ({ message, payload, payloadTemplate, flags = {} }) => {
    const timeStamp = channelToTimeStampForNewPost(conversation)

    const formatted_payload = payloadTemplate
      ? payloadTemplate.replace('%VALUE%', payload)
      : payload

    dispatch(
      PatientHistoryActions.submitTextWithPayloadPostRequest(
        channelId,
        userId,
        message,
        timeStamp,
        flags,
        formatted_payload
      )
    )
    dispatch(PatientHistoryActions.updateChannelLastViewed(channelId))
  }

  const sendRawPost = (postProps) => {
    Analytics.trackEvent('button_click', { button_value: 'Send raw post' })
    const { flags, payload, display } = postProps
    const timeStamp = channelToTimeStampForNewPost(conversation)

    dispatch(
      PatientHistoryActions.submitTextWithPayloadPostRequest(
        channelId,
        userId,
        display,
        timeStamp,
        flags,
        payload
      )
    )
    dispatch(PatientHistoryActions.updateChannelLastViewed(channelId))
  }

  const handleDismissFailedRetryError = () => {
    dispatch(PatientHistoryActions.setFailedPostError(channelId, false))
  }

  const onFilePicked = (fileObj) => {
    const timeStamp = channelToTimeStampForNewPost(conversation)
    dispatch(
      PatientHistoryActions.submitFilePostRequest(channelId, userId, fileObj, timeStamp, undefined)
    )
  }

  const onBannerCtaPress = () => {
    navigation.navigate('upcomingAppointment', { appointment: upcomingAppointment })
  }

  return (
    <ChatContext.Provider
      key={channelId}
      value={{
        typingEvent,
        order,
        orderedPosts,
        channelId,
        conversation,
        episode,
        episodeMembers,
        users,
        userId,
        sendSingleChoiceAnswer,
        sendMultiChoiceAnswer,
        sendTextWithPayload,
        sendRawPost,
        sendMessage,
        onFilePicked,
        getLastPostIntakeForm,
        isConnected,
        isInactiveAndEpisodeInactive,
        hasEligibleBrands,
        lastPost,
        selectedAddress,
        setSelectedAddress,
        selectedFile,
        setSelectedFile,
        isLoading,
        renderLimit,
        setRenderLimit,
        showError,
      }}
    >
      <View style={Styles.container}>
        {isLoading ? (
          <ActivityIndicator
            size="large"
            color={theme.colors.text}
            style={Styles.loading}
            testID="spinner"
          />
        ) : (
          <>
            {upcomingAppointment && (
              <AppointmentBanner
                title={I18n.t('ConversationScreen.appointmentBanner.title')}
                time={formatTimeForAppointment(upcomingAppointment?.start_at)}
                onCtaPress={onBannerCtaPress}
                ctaLabel={I18n.t('ConversationScreen.appointmentBanner.cta')?.toUpperCase()}
              />
            )}
            <Conversation />
          </>
        )}
        {showError && (
          <Typography style={Styles.genericError}>
            {I18n.t('ConversationScreen.genericError')}
          </Typography>
        )}
        <Modal
          statusBarTranslucent
          visible={hasPostFailureError}
          transparent
          title={I18n.t('ConversationScreen.failedRetryError.title')}
          handleCloseModal={handleDismissFailedRetryError}
          primaryActionText={I18n.t('ConversationScreen.failedRetryError.primaryAction')}
          handlePrimaryAction={handleDismissFailedRetryError}
        >
          <DialogueAutolink
            text={I18n.t('ConversationScreen.failedRetryError.body', {
              supportEmail: Config.SUPPORT_EMAIL[I18n.baseLocale],
            })}
            style={Styles.postFailureModalText}
          />
        </Modal>
      </View>
    </ChatContext.Provider>
  )
}

export default ChatScreen
