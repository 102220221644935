import React from 'react'
import { View, Platform } from 'react-native'
import { useSelector } from 'react-redux'
import { useNavigationBuilder, createNavigatorFactory, StackRouter } from '@react-navigation/native'
import { NativeStackView } from '@react-navigation/native-stack'
import { StackView } from '@react-navigation/stack'

// Services, Hooks, & Helpers
import useScreenView from 'APP/Hooks/useScreenView'
import { useStackRoutes } from 'APP/Nav/useStackRoutes'
import { selectDescriptorOfCurrentRoute, routesToStackSideMenus } from 'APP/Nav/Utils'

// Components
import { DebugCrumbs } from 'APP/Nav/Components/DebugCrumbs'

// Styles
import { Colors, Metrics } from 'APP/Themes'

const styles = {
  container: {
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    flex: 1,
    display: 'flex',
  },
  stackContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  stackMenuContainer: {
    backgroundColor: Colors.elementsBg,
    paddingTop: Metrics.statusBarHeight,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    borderRightColor: Colors.separateLine,
    borderRightWidth: 1,
    shadowColor: 'rgb(0, 0, 0)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.08,
    shadowRadius: 10,
    width: 240,
    zIndex: 2,
  },
}

/*
  Note:
  In order to force iOS style modals on android, we can't use the NativeStack.
  This will impact performance, we might want to consider using native modals
  on android if performance is an issue.
*/
const NavigationStackView = Platform.OS === 'android' ? StackView : NativeStackView

const CustomStackNavigator = ({
  initialRouteName,
  backBehavior,
  children,
  screenOptions,
  ...rest
}) => {
  const { NavigationContent, descriptors, state, navigation } = useNavigationBuilder(StackRouter, {
    initialRouteName,
    backBehavior,
    children,
    screenOptions,
  })

  /*
    Note:
    We use stack routes from the root state instead of the stack navigator supplied
    state, as it includes sub-navigators (so we can see what's up with the tab navigator
    inside of the screen)
  */
  const stackRoutes = useStackRoutes()
  const { isSmallScreen } = useScreenView()
  const { options } = selectDescriptorOfCurrentRoute(descriptors, stackRoutes) || {}
  const { stackMenuStyle } = options || {}
  const stackSideMenus = routesToStackSideMenus(stackRoutes)
  const showDebugCrumbs = useSelector((state) => state?.app?.showDebugCrumbs)

  const showStackMenu = !isSmallScreen && stackSideMenus.length !== 0
  return (
    <View style={styles.container}>
      {showStackMenu ? (
        <View testID="stackMenuContainer" style={[styles.stackMenuContainer, stackMenuStyle]}>
          {stackSideMenus}
        </View>
      ) : null}
      <View style={[styles.stackContainer]}>
        {showDebugCrumbs ? (
          <DebugCrumbs testID="debugCrumbs" {...{ descriptors, stackRoutes }} />
        ) : null}
        <NavigationContent>
          <NavigationStackView {...rest} {...{ descriptors, state, navigation }} />
        </NavigationContent>
      </View>
    </View>
  )
}

export const createCustomStackNavigator = createNavigatorFactory(CustomStackNavigator)
