import sha256 from 'js-sha256'
import { sprig } from '@sprig-technologies/sprig-browser'

import Config from 'APP/Config'
import I18n from 'APP/Services/i18n'

class _Sprig {
  sprigInstance = null
  constructor() {
    if (__DEV__) {
      console.log('Initializing Sprig')
    }
    this.init()
  }
  init() {
    // configure returns an instance with all the api functions
    const object = sprig.configure({ environmentId: Config.SPRIG_ENV_ID })
    if (object) {
      object.setAttribute('brand_id', Config.BRAND_ID)
      object.setAttribute('lng', I18n.baseLocale)
      this.sprigInstance = object
    }
  }

  track(eventName, properties) {
    if (!eventName) return
    // Troubleshooted with a Sprig's engineer.
    // Turns out the track function with callback is not supported until its next release / fix.
    this.sprigInstance.track(eventName, properties)
  }

  setVisitorAttribute(key, value) {
    this.sprigInstance.setAttribute(key, value)
  }

  setUserIdentifier(userId) {
    this.sprigInstance.setUserId(sha256(userId.toString()))
  }

  logout() {
    this.sprigInstance.logoutUser()
  }
}

const sprigClient = new _Sprig()

export default sprigClient
