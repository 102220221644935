import React, { useRef } from 'react'

// Services
import I18n from 'APP/Services/i18n'
import Typography from 'APP/Converse/Typography'
import Button from 'APP/Converse/Button'
import { ButtonContainer, Container } from './style'
import { useFilePicker } from 'APP/Hooks/FilePicker'

const IntakePhoto = ({ sendAnswer, disabled }) => {
  const inputFile = useRef(null)
  const { openFilePicker, onFileChange, file, hasFile, onSubmitPress, isFileTypeValid } =
    useFilePicker({
      submitCallback: sendAnswer,
      fileInputRef: inputFile,
    })

  return (
    <Container>
      <input
        type="file"
        accept="image/*,.pdf"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={onFileChange}
      />
      {hasFile && (
        <Typography variant="bodyLarge" tabIndex="0" type="bodyLarge">
          {file.name}
        </Typography>
      )}
      <ButtonContainer>
        {hasFile && (
          <Button
            id="prompt-file-submit-button"
            title={I18n.t('IntakeComponent.next')}
            onPress={onSubmitPress}
            disabled={!isFileTypeValid(file.type)}
          />
        )}
        <Button
          id="prompt-file-upload-button"
          variant="secondary"
          title={I18n.t('IntakeComponent.upload')}
          onPress={openFilePicker}
          disabled={disabled}
        />
      </ButtonContainer>
    </Container>
  )
}

export default IntakePhoto
