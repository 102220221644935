import React from 'react'
import { Linking, Image, Text, View } from 'react-native'

// Services
import I18n from 'APP/Services/i18n'
import Config from 'APP/Config'
import { supportEmailUrl } from 'APP/Services/UrlBuilder'

// Types and Enums
import InfoWarningTypes, { ButtonActions } from './InfoWarningTypes'

// Components
import { PrimaryButton } from 'APP/Components/Buttons'
import BulletListItem from 'APP/Components/BulletListItem'
import Alert from 'APP/Converse/Alert'
// Styling
import Styles from './style'
import { getImageAspectRatio } from 'APP/Lib/StylingHelpers'
import { ResponsiveScrollView } from 'APP/Converse/Layout'

class GenericInfoWarningScreen extends React.Component {
  getProps = () => ({ ...this.route?.params, ...this.props })

  getErrorConfig = () =>
    this.getProps().errorConfig || InfoWarningTypes[this.props?.route?.name] || {}

  openURL = (url) => {
    return Linking.openURL(url).catch(() => {
      Alert.alert(I18n.t('CannotOpenUrl.title'), I18n.t('CannotOpenUrl.body'), [
        { text: I18n.t('CannotOpenUrl.ok') },
      ])
    })
  }

  gotoContactUs = () => {
    const customContactUs = Config.CUSTOM_SUPPORT_PAGE
    if (customContactUs) {
      this.props.navigation.navigate('contactUs')
    } else {
      const url = supportEmailUrl()
      if (Linking.canOpenURL(url)) {
        Linking.openURL(url).catch(() => {
          Alert.alert(I18n.t('CannotOpenEmail.title'), I18n.t('CannotOpenEmail.body'), [
            { text: I18n.t('CannotOpenEmail.ok') },
          ])
        })
      }
    }
  }

  callSupport = () => {
    const hasSupportNumber = Config.PHONE_NUMBERS && Config.PHONE_NUMBERS.support
    if (hasSupportNumber) {
      Linking.openURL(`tel:${Config.PHONE_NUMBERS.support}`)
    }
  }

  renderInstruction() {
    const { instruction } = this.getErrorConfig()
    if (instruction) {
      return (
        <View style={Styles.instructionContainer}>
          <Text style={Styles.instruction}>{instruction}</Text>
          {this.renderBulletPoints()}
        </View>
      )
    }
  }

  renderBody() {
    const { body } = this.getErrorConfig()
    if (body) {
      return (
        <View style={Styles.instructionContainer}>
          <Text style={Styles.body}>{body}</Text>
        </View>
      )
    }
  }

  renderSubbody() {
    const { subbody } = this.getErrorConfig()
    if (subbody) {
      return (
        <View style={Styles.instructionContainer}>
          <Text style={Styles.subbody}>{subbody}</Text>
        </View>
      )
    }
  }

  renderBulletPoints() {
    const { bulletPoints } = this.getErrorConfig()
    let children = []
    bulletPoints.forEach((element, index) => {
      children.push(<BulletListItem key={index}>{element}</BulletListItem>)
    })
    return children
  }

  renderButtons() {
    const hasSupportNumber = Config.PHONE_NUMBERS && Config.PHONE_NUMBERS.support
    const { buttons } = this.getErrorConfig()

    let children = []
    buttons.forEach((element, index) => {
      let action = () => {}
      let ButtonClass, analyticsName

      // Skip the call support button if there's no number
      if (element.action === ButtonActions.callSupport && !hasSupportNumber) return

      switch (element.action) {
        case ButtonActions.contactUs:
          action = () => {
            this.gotoContactUs()
          }
          analyticsName = 'Try again or contact us'
          ButtonClass = element.buttonClass || PrimaryButton
          break
        default:
          break
      }
      children.push(
        <ButtonClass
          key={index}
          title={element.title}
          onPress={action}
          analyticsName={analyticsName}
        />
      )
    })
    return children
  }

  render() {
    const { icon, title, subtitle } = this.getErrorConfig()
    const iconAspectRatio = getImageAspectRatio(icon)
    return (
      <View style={Styles.fullPrimaryContainer}>
        <ResponsiveScrollView
          alwaysBounceVertical={false}
          keyboardShouldPersistTaps="always"
          contentContainerStyle={Styles.keyboardCoveredContent}
        >
          <View style={Styles.content}>
            <View>
              <Image source={icon} style={[Styles.splashLogo, { aspectRatio: iconAspectRatio }]} />
              <Text style={Styles.title} testID="ERROR">
                {title}
              </Text>
              <Text style={Styles.subtitle} testID="ERROR_SUBTITLE">
                {subtitle}
              </Text>
            </View>
            {this.renderInstruction()}
            {this.renderBody()}
            {this.renderSubbody()}
            <View style={Styles.buttonContainer}>{this.renderButtons()}</View>
          </View>
        </ResponsiveScrollView>
      </View>
    )
  }
}

export default GenericInfoWarningScreen
