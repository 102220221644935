import React from 'react'
import { Text } from 'react-native'

import styled from 'styled-components/native'

const StyledBaseText = styled(Text)`
  color: ${({ theme, color }) => theme.colors[color]};
  text-align: ${({ align }) => align || 'auto'};
  z-index: 1;
`

export const StyledH1 = styled(StyledBaseText)`
  font-family: ${({ theme }) => theme.fonts.fontTypes.header};
  font-size: ${({ theme }) => theme.fonts.fontSize.h1}px;
  line-height: ${({ theme }) => theme.fonts.lineHeight.h1}px;
`

export const StyledH2 = styled(StyledBaseText)`
  font-family: ${({ theme }) => theme.fonts.fontTypes.header};
  font-size: ${({ theme }) => theme.fonts.fontSize.h2}px;
  line-height: ${({ theme }) => theme.fonts.lineHeight.h2}px;
`

export const StyledH3 = styled(StyledBaseText)`
  font-family: ${({ theme }) => theme.fonts.fontTypes.header};
  font-size: ${({ theme }) => theme.fonts.fontSize.h3}px;
  line-height: ${({ theme }) => theme.fonts.lineHeight.h3}px;
`

export const StyledH4 = styled(StyledBaseText)`
  font-family: ${({ theme }) => theme.fonts.fontTypes.header};
  font-size: ${({ theme }) => theme.fonts.fontSize.h4}px;
  line-height: ${({ theme }) => theme.fonts.lineHeight.h4}px;
`

export const StyledBodyLargeBold = styled(StyledBaseText)`
  font-family: ${({ theme }) => theme.fonts.fontTypes.base};
  font-size: ${({ theme }) => theme.fonts.fontSize.large}px;
  line-height: ${({ theme }) => theme.fonts.lineHeight.large}px;
  font-weight: bold;
`

export const StyledBodyLarge = styled(StyledBaseText)`
  font-family: ${({ theme }) => theme.fonts.fontTypes.light};
  font-size: ${({ theme }) => theme.fonts.fontSize.large}px;
  line-height: ${({ theme }) => theme.fonts.lineHeight.large}px;
  font-weight: 300;
`

export const StyledSubheaderBold = styled(StyledBaseText)`
  font-family: ${({ theme }) => theme.fonts.fontTypes.bold};
  font-size: ${({ theme }) => theme.fonts.fontSize.regular}px;
  line-height: ${({ theme }) => theme.fonts.lineHeight.regular}px;
  font-weight: bold;
`

export const StyledSubheader = styled(StyledBaseText)`
  font-family: ${({ theme }) => theme.fonts.fontTypes.base};
  font-size: ${({ theme }) => theme.fonts.fontSize.regular}px;
  line-height: ${({ theme }) => theme.fonts.lineHeight.regular}px;
`

export const StyledBodyNormal = styled(StyledBaseText)`
  font-family: ${({ theme }) => theme.fonts.fontTypes.light};
  font-size: ${({ theme }) => theme.fonts.fontSize.regular}px;
  line-height: ${({ theme }) => theme.fonts.lineHeight.regular}px;
`

export const StyledBodyNormalBold = styled(StyledBaseText)`
  font-family: ${({ theme }) => theme.fonts.fontTypes.bold};
  font-size: ${({ theme }) => theme.fonts.fontSize.regular}px;
  line-height: ${({ theme }) => theme.fonts.lineHeight.regular}px;
`

export const StyledBodySmallBold = styled(StyledBaseText)`
  font-family: ${({ theme }) => theme.fonts.fontTypes.bold};
  font-size: ${({ theme }) => theme.fonts.fontSize.small}px;
  line-height: ${({ theme }) => theme.fonts.lineHeight.small}px;
  font-weight: 500;
`

export const StyledBodySmall = styled(StyledBaseText)`
  font-family: ${({ theme }) => theme.fonts.fontTypes.light};
  font-size: ${({ theme }) => theme.fonts.fontSize.small}px;
  line-height: ${({ theme }) => theme.fonts.lineHeight.small}px;
  font-weight: 300;
`

export const StyledCaptionBold = styled(StyledBaseText)`
  font-family: ${({ theme }) => theme.fonts.fontTypes.bold};
  font-size: ${({ theme }) => theme.fonts.fontSize.captionBold}px;
  line-height: ${({ theme }) => theme.fonts.lineHeight.tinyBold}px;
  font-weight: bold;
`

export const StyledCaption = styled(StyledBaseText)`
  font-family: ${({ theme }) => theme.fonts.fontTypes.base};
  font-size: ${({ theme }) => theme.fonts.fontSize.caption}px;
  line-height: ${({ theme }) => theme.fonts.lineHeight.tiny}px;
`

export const StyledButtonText = styled(StyledBaseText)`
  font-family: ${({ theme }) => theme.fonts.fontTypes.button};
  font-size: ${({ theme }) => theme.fonts.fontSize.regular}px;
  line-height: ${({ theme }) => theme.fonts.lineHeight.regular}px;
  letter-spacing: 1.5px;
  text-transform: ${({ theme }) => theme.fonts.textTransform.button};
`

export const StyledLink = styled(StyledBaseText)`
  font-family: ${({ theme }) => theme.fonts.fontTypes.button};
  font-size: ${({ theme }) => theme.fonts.fontSize.regular}px;
  line-height: ${({ theme }) => theme.fonts.lineHeight.regular}px;
  text-decoration-line: underline;
`

const VariantMapping = {
  h1: StyledH1,
  h2: StyledH2,
  h3: StyledH3,
  h4: StyledH4,
  bodyLargeBold: StyledBodyLargeBold,
  bodyLarge: StyledBodyLarge,
  subheaderBold: StyledSubheaderBold,
  subheader: StyledSubheader,
  bodyNormal: StyledBodyNormal,
  bodyNormalBold: StyledBodyNormalBold,
  bodySmallBold: StyledBodySmallBold,
  bodySmall: StyledBodySmall,
  captionBold: StyledCaptionBold,
  caption: StyledCaption,
  buttonText: StyledButtonText,
  link: StyledLink,
}

const Typography = ({ children, color = 'text', variant = 'bodyNormal', ...rest }) => {
  const Component = VariantMapping[variant]
  return (
    <Component color={color} {...rest}>
      {children}
    </Component>
  )
}

export default Typography
