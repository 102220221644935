import React, { createContext, useContext, useRef } from 'react'
import { ScrollView } from 'react-native'

interface IntakeContextType {
  scrollViewRef: React.RefObject<ScrollView>
  scrollTo: (y: number, animated?: boolean) => void
}

const IntakeContext = createContext<IntakeContextType | undefined>(undefined)

export const useIntakeContext = () => {
  const context = useContext(IntakeContext)
  if (!context) {
    throw new Error('useIntakeContext must be used within an IntakeProvider')
  }
  return context
}

export const IntakeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const scrollViewRef = useRef<ScrollView>(null)

  const scrollTo = (y: number, animated: boolean = true) => {
    scrollViewRef.current?.scrollTo({ y, animated })
  }

  return (
    <IntakeContext.Provider value={{ scrollViewRef, scrollTo }}>{children}</IntakeContext.Provider>
  )
}
