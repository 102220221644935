import React, { useEffect } from 'react'
import { HistoricalWeeklySummary } from 'APP/Components/ActiveMinutes'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import Styles from './style'
import { ScrollView, Text, View } from 'react-native'
import I18n from 'APP/Services/i18n'
import { useSelector } from 'react-redux'
import Analytics from 'APP/Services/Analytics'

const SeeMoreActiveHistoryScreen = () => {
  const { data } = useSelector((state) => state.activeMinutes.history)

  useEffect(() => {
    Analytics.trackScreen('Full history page')
  }, [])

  return (
    <NoticeBarContainer>
      <ScrollView
        contentContainerStyle={Styles.scrollViewContentContainer}
        testID="history"
        scrollIndicatorInsets={{ right: 1 }}
      >
        {data?.map((week) => (
          <View key={week.dateRange} style={Styles.spacing}>
            <HistoricalWeeklySummary
              startDate={week.start}
              endDate={week.end}
              weeklyGoal={week.weekly_goal}
              weeklyProgress={week.weekly_progress.minutes}
              activities={week.activities}
            />
          </View>
        ))}
        <Text testID="limitation" style={Styles.limitationText}>
          {I18n.t('SeeMoreActiveHistoryScreen.limitation')}
        </Text>
      </ScrollView>
    </NoticeBarContainer>
  )
}

export default SeeMoreActiveHistoryScreen
