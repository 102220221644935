import { StyleSheet } from 'react-native'
import { ApplicationStyles, Metrics, Colors } from '../../Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  ctaContainer: {
    minHeight: Metrics.baseMargin,
  },
  container: {
    ...ApplicationStyles.screen.tabContainer,
    backgroundColor: Colors.appBg,
  },
  brandingContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: Colors.elementsBg,
    borderColor: Colors.elementsBorder,
    marginTop: Metrics.baseMargin,
    marginBottom: -1,
    borderBottomWidth: 1,
    borderTopWidth: 1,
  },
  launchpad: {
    paddingHorizontal: Metrics.baseMargin,
  },
  appComponentVerticalSpacing: {
    marginVertical: Metrics.baseMargin,
  },
  footer: {
    width: '100%',
  },
})
