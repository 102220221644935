import React from 'react'
import { View, TouchableOpacity, ActivityIndicator } from 'react-native'

import Styles from './style'
import { Colors } from 'APP/Themes'
import ToggleSwitch from 'APP/Converse/Inputs/ToggleSwitch'
import Typography from 'APP/Converse/Typography'

const ToggleCard = ({
  title,
  description,
  label,
  disabled = false,
  isLoading = false,
  isToggled = false,
  onToggleChange,
  testID = 'TOGGLE_CARD',
}) => {
  return (
    <TouchableOpacity
      testID={testID}
      style={Styles.section}
      onPress={onToggleChange}
      disabled={disabled || isLoading}
    >
      <View style={Styles.container}>
        {title && description && (
          <View style={Styles.textContainer}>
            <Typography variant="h3" testID="TOGGLE_CARD_TITLE">
              {title}
            </Typography>
            <Typography
              variant="bodySmall"
              style={Styles.description}
              testID="TOGGLE_CARD_DESCRIPTION"
            >
              {description}
            </Typography>
          </View>
        )}
        {isLoading && (
          <ActivityIndicator
            animating
            color={Colors.text}
            size={'small'}
            style={Styles.loading}
            testID="TOGGLE_LOADING"
          />
        )}
        {!isLoading && (
          <View style={Styles.toggleSwitchContainer}>
            <Typography variant="subheader">{label}</Typography>
            <ToggleSwitch
              checked={isToggled}
              onValueChange={onToggleChange}
              disabled={disabled}
              testID={`${testID}_TOGGLE_SWITCH`}
            />
          </View>
        )}
      </View>
    </TouchableOpacity>
  )
}

export default ToggleCard
