import { isWeb } from 'APP/Helpers/checkPlatform'
import React from 'react'
import { Image, ImageBackground } from 'react-native'

const AttachmentImage = ({ source, localSource, style, imageStyle }) => {
  const localUrl = isWeb() && localSource ? URL.createObjectURL(localSource) : localSource
  const backgroundUrl = isWeb() ? localUrl || source : localUrl

  return (
    <ImageBackground
      style={style}
      imageStyle={imageStyle}
      source={{ uri: backgroundUrl }}
      testID="localImage"
    >
      <Image style={[{ flex: 1 }, imageStyle]} source={{ uri: source }} testID="remoteImage" />
    </ImageBackground>
  )
}

export default AttachmentImage
