import apisauce from 'apisauce'
import {
  AuthenticateApiFactory,
  Configuration,
} from '@dialogue/health-connect'
import { select, call } from 'typed-redux-saga'

import Config from 'APP/Config'
import { RootState } from 'APP/Store/CreateStore'

export const selectAccessToken = (state: RootState) => state.login.accessToken

export function* getClientConfig() {
  const accessToken = yield* select(selectAccessToken)

  if (!accessToken) {
    throw new Error('Missing access token')
  }

  if (!Config.HEALTH_CONNECT_DOMAIN) {
    throw new Error('Health-Connect domain is not set')
  }

  return new Configuration({
    accessToken,
    basePath: Config.HEALTH_CONNECT_DOMAIN,
  })
}

export function* getAxiosClient() {
  const accessToken = yield* select(selectAccessToken)

  if (!accessToken) {
    throw new Error('Missing access token')
  }

  if (!Config.HEALTH_CONNECT_DOMAIN) {
    throw new Error('Health-Connect domain is not set')
  }

  const apiClient = apisauce.create({
    baseURL: Config.HEALTH_CONNECT_DOMAIN,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
       Authorization: `Bearer ${accessToken}`,
    },
  })

  return apiClient.axiosInstance
}

export function* getAuthenticateApi() {
    const axiosInstance = yield* call(getAxiosClient)
    return AuthenticateApiFactory(yield* call(getClientConfig), '', axiosInstance)
  }
