import React, { useCallback, useEffect } from 'react'

import I18n from 'APP/Services/i18n'
import { View, Image, Keyboard } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { Images } from 'APP/Themes'
import Modal from 'APP/Converse/Modal'
import DialogueSound from 'APP/Components/DialogueSound'

// Styles
import Styles from './style'
import Typography from 'APP/Converse/Typography'
import Button from 'APP/Converse/Button'
import GlowingHalo from 'APP/Components/GlowingHalo'
import { videoSessionActions } from 'APP/Store/VideoSession'

const IncomingCallScreen = (props) => {
  const { avatar_url, mainSpecialization, first_name, last_name } =
    props.route?.params?.caller || {}
  const profileId = useSelector((state) => state.patient.profile.id)
  const dispatch = useDispatch()

  useEffect(() => {
    Keyboard.dismiss()
  }, [])

  const acceptCall = useCallback(() => {
    const lastCallAcceptedAt = Date.now()
    dispatch(videoSessionActions.patientAcceptedCall({ userId: profileId, lastCallAcceptedAt }))
  }, [dispatch, profileId])

  const endCall = useCallback(() => {
    dispatch(videoSessionActions.patientEndedCall({ userId: profileId, declined: true }))
  }, [dispatch, profileId])

  return (
    <Modal variant="oomph">
      <DialogueSound name="ringing" />

      <View style={Styles.centeredContainer}>
        <Typography variant="h3" color="darkText">
          {I18n.t('IncomingCallScreen.title')}
        </Typography>
        <View style={Styles.avatarContainer}>
          <GlowingHalo />
          <Image source={{ uri: avatar_url }} style={Styles.roundImage} />
        </View>
        {(first_name || last_name) && (
          <Typography variant="h2" color="darkText">
            {first_name} {last_name}
          </Typography>
        )}
        {mainSpecialization ? (
          <Typography color="dim" variant="subheaderBold">
            {I18n.t(`Specialties.${mainSpecialization}`)}
          </Typography>
        ) : (
          <Typography variant="subheaderBold" color="darkText">
            &#160;
          </Typography>
        )}

        <View style={Styles.spacedColumnContainer}>
          <Typography variant="bodyNormal" color="darkText">
            {I18n.t('IncomingCallScreen.body')}
          </Typography>
        </View>

        <View style={Styles.spacedRowContainer}>
          <View style={Styles.buttonContainer}>
            <Button
              variant="error"
              widthVariant="circle"
              icon={Images.hangUp}
              iconVariant="image"
              onPress={endCall}
              analyticsName="Decline call"
              style={Styles.button}
              testID="decline-call"
              hasShadow
            />
            <Typography variant="subheader" color="darkText">
              {I18n.t('IncomingCallScreen.cancel')}
            </Typography>
          </View>

          <View style={Styles.buttonContainer}>
            <Button
              variant="success"
              widthVariant="circle"
              icon={Images.video}
              iconVariant="image"
              onPress={acceptCall}
              analyticsName="Accept call"
              style={Styles.button}
              testID="accept-call"
              hasShadow
            />
            <Typography variant="subheader" color="darkText">
              {I18n.t('IncomingCallScreen.answer')}
            </Typography>
          </View>
        </View>
      </View>
    </Modal>
  )
}

export default IncomingCallScreen
