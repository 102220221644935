import React from 'react'

// Components
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import DialogueWebView from 'APP/Components/DialogueWebView'

const WebContentScreen = (props) => {
  return (
    <NoticeBarContainer>
      <DialogueWebView {...props.route?.params} {...props} />
    </NoticeBarContainer>
  )
}

export default WebContentScreen
