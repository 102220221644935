import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Habits } from '@dialogue/services'
import HabitActions from 'APP/Redux/HabitsRedux'
import { HabitDrawer as HabitDrawerComponent } from 'APP/Components/Habits'

const HabitDrawer = ({ category, ...rest }) => {
  const dispatch = useDispatch()
  const { topics: habitTopicsData } = useSelector((state) => state.habits)
  const habitTopics = useMemo(() => {
    return habitTopicsData?.data?.reduce((nextTopics, topic) => {
      const nextHabits = topic?.habits?.filter(
        ({ state }) => state === Habits.Habits.Types.State.Pending
      )

      if (!nextHabits?.length) return nextTopics
      return [...nextTopics, { ...topic, habits: nextHabits }]
    }, [])
  }, [habitTopicsData?.data])
  const getCategoryTopics = useCallback((id) => dispatch(HabitActions.getTopics(id)), [])

  useEffect(() => {
    if (category) {
      getCategoryTopics(category)
    }
  }, [category])

  return (
    <HabitDrawerComponent
      {...rest}
      loading={habitTopicsData.loading}
      error={habitTopicsData.retry}
      refresh={getCategoryTopics}
      habitTopics={habitTopics}
    />
  )
}

export default HabitDrawer
