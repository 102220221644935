import React from 'react'
import { Text, View } from 'react-native'

import Styles from './style'

export const BulletListItem = ({ children, text, textStyle, containerStyle, bulletStyle }) => (
  <View style={[containerStyle, Styles.listItem]}>
    <Text style={[Styles.listItemBullet, bulletStyle]}>{'•'}</Text>
    <Text style={[Styles.listItemText, textStyle]}>{children || text}</Text>
  </View>
)

export default BulletListItem
