import React from 'react'
import { useDispatch } from 'react-redux'
import { Trans } from 'react-i18next'

import Config from 'APP/Config'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import List from 'APP/Components/List'
import ListItem from 'APP/Converse/ListItem'
import Typography from 'APP/Converse/Typography'
import { handlePressContactSupport } from 'APP/Helpers/handlePressContactSupport'
import PatientActions from 'APP/Redux/PatientRedux'
import I18n from 'APP/Services/i18n'
import { ResponsiveScrollView, ResponsiveView } from 'APP/Converse/Layout'
import Styles from './style'

interface ChangeEmailConfirmationScreenProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigation: any
  route: {
    params: {
      email: string
    }
  }
}

const ChangeEmailConfirmationScreen = ({
  route: {
    params: { email },
  },
}: ChangeEmailConfirmationScreenProps) => {
  const dispatch = useDispatch()

  const onClickResend = () => {
    dispatch(PatientActions.patientEmailChangeRequest(email, true))
  }

  return (
    <NoticeBarContainer>
      <ResponsiveScrollView>
        <ResponsiveView style={Styles.container}>
          <Typography variant="h1">{I18n.t('ChangeEmailConfirmationScreen.title')}</Typography>
          <Typography variant="bodyNormal">
            <Trans
              i18n={I18n}
              i18nKey="ChangeEmailConfirmationScreen.description"
              values={{ email }}
              components={[<Typography variant="bodyNormalBold" key="email" />]}
            >
              {{ email }}
            </Trans>
          </Typography>
        </ResponsiveView>
        <List containerStyle={Styles.listContainer}>
          <ListItem
            key="resend"
            title={I18n.t('ChangeEmailConfirmationScreen.resend')}
            onPress={onClickResend}
          />
          <ListItem
            key="support"
            title={I18n.t('ChangeEmailConfirmationScreen.support.title')}
            subtitle={I18n.t('ChangeEmailConfirmationScreen.support.subtitle')}
            onPress={handlePressContactSupport}
            iconName={Config.CUSTOM_SUPPORT_PAGE ? 'chevron-right' : 'exit-to-app'}
          />
        </List>
      </ResponsiveScrollView>
    </NoticeBarContainer>
  )
}

export default ChangeEmailConfirmationScreen
