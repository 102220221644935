import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components/native'

import { AppointmentTypeInput } from '@dialogue/timekeeper'

import { providerBookingActions } from 'APP/Store/ProviderBooking'
import { ProviderButton, MoreOptionsButton } from './providerButton'

const StyledFlatList = styled.FlatList`
  flex-grow: 0;
`

const StyledProviderButton = styled(ProviderButton)`
  margin-top: ${({ theme }) => theme.metrics.baseMargin / 2}px;
`

const StyledMoreOptionsButton = styled(MoreOptionsButton)`
  margin-top: ${({ theme }) => theme.metrics.baseMargin}px;
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin * 2}px;
`

export interface ProviderSlot {
  appointment_type: AppointmentTypeInput
  provider_id: number
  start_at: string
  type: 'appointment'
  until_at: string
}

export interface ProviderChoicePayload {
  slots: {
    booking_provider_list_appointment_slot: ProviderSlot
  }
}

export interface ProviderChoice {
  id: string
  display?: string
  description?: string
  provider_id?: string
  type: 'appointment' | 'skip'
  payload?: ProviderChoicePayload
}

interface Props {
  data: Array<ProviderChoice>
  onPress: (choice: ProviderChoice) => void
  onPressMoreOptions: (choice: ProviderChoice) => void
}

export const ProviderList = ({ data, onPress, onPressMoreOptions }: Props) => {
  const dispatch = useDispatch()

  const availableProviders = useMemo(
    () => data.filter((option) => option.type === 'appointment'),
    [data]
  )
  const moreOptionsChoice = useMemo(() => data.find((option) => option.type === 'skip'), [data])

  useEffect(() => {
    const ids = availableProviders.map((provider) => Number(provider.provider_id))
    dispatch(providerBookingActions.getProviders([...new Set(ids)]))
  }, [availableProviders, dispatch])

  return (
    <StyledFlatList
      data={availableProviders}
      keyExtractor={(choices) => choices.id}
      renderItem={({ item }) =>
        item.display && item.provider_id ? (
          <StyledProviderButton
            title={item.display}
            providerId={parseInt(item.provider_id, 10)}
            onPress={() => onPress(item)}
          />
        ) : null
      }
      ListFooterComponent={
        moreOptionsChoice && (
          <StyledMoreOptionsButton
            display={moreOptionsChoice.display}
            onPress={() => onPressMoreOptions(moreOptionsChoice)}
          />
        )
      }
    />
  )
}
