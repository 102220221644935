import React, { forwardRef } from 'react'
import { Dimensions } from 'react-native'
import Carousel from 'react-native-reanimated-carousel'

import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'

import { StyledViewCarouselDot } from './style'

export const CarouselDot = ({ onPress, index = 0, selected = false }) => {
  return (
    <DialogueTouchableOpacity
      testID={`touchable-dot-${index}`}
      onPress={() => onPress(index)}
      analyticsName={`Carousel dot selected ${index}`}
    >
      <StyledViewCarouselDot testID="carousel-dot" selected={selected} />
    </DialogueTouchableOpacity>
  )
}

// eslint-disable-next-line react/display-name
export const CarouselComponent = forwardRef(
  (
    {
      loop = false,
      width = Dimensions.get('width'),
      height = Dimensions.get('window'),
      data = [],
      onProgressChange = () => {},
      renderItem = () => {},
      enabled = true,
      autoPlay = false,
      scrollAnimationDuration = 1200,
      autoPlayInterval = 5000,
      onScrollEnd = () => {},
    },
    ref
  ) => {
    return (
      <Carousel
        ref={ref}
        loop={loop}
        width={width}
        height={height}
        data={data}
        onProgressChange={onProgressChange}
        renderItem={renderItem}
        enabled={enabled}
        autoPlay={autoPlay}
        scrollAnimationDuration={scrollAnimationDuration}
        autoPlayInterval={autoPlayInterval}
        onScrollEnd={onScrollEnd}
      />
    )
  }
)
