import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const { Types, Creators } = createActions({
  getNotificationPreferences: {},
  updateNotificationPreference: ['data'],
  getNotificationPreferencesSuccess: ['data'],
  getNotificationPreferencesFailure: ['error'],
  triggerNotificationPermissionModal: [],
})

export const NotificationTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  preferences: {
    loading: false,
    data: {},
  },
})

export const getNotificationPreferences = (state) =>
  state.merge(
    {
      preferences: {
        loading: true,
      },
    },
    { deep: true }
  )

export const updateNotificationPreference = (state) =>
  state.merge(
    {
      preferences: {
        loading: true,
      },
    },
    { deep: true }
  )

export const getNotificationPreferencesSuccess = (state, action) =>
  state.merge(
    {
      preferences: {
        loading: false,
        data: action.data,
      },
    },
    { deep: true }
  )

export const getNotificationPreferencesFailure = (state) =>
  state.merge(
    {
      preferences: {
        loading: false,
        data: {},
      },
    },
    { deep: true }
  )

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_NOTIFICATION_PREFERENCES]: getNotificationPreferences,
  [Types.UPDATE_NOTIFICATION_PREFERENCE]: updateNotificationPreference,
  [Types.GET_NOTIFICATION_PREFERENCES_SUCCESS]: getNotificationPreferencesSuccess,
  [Types.GET_NOTIFICATION_PREFERENCES_FAILURE]: getNotificationPreferencesFailure,
})
