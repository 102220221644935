import React from 'react'
import { ActivityIndicator, View, TextInput, Platform } from 'react-native'
import { connect } from 'react-redux'
import { connectActionSheet } from '@expo/react-native-action-sheet'

// Components
import Intake, { IntakeTransition } from 'APP/Components/Intake'
import { AttachmentLoadingOverlay } from 'APP/Components/AttachmentLoadingOverlay'
import Modal from 'APP/Components/Modal'
import DialogueAutolink from 'APP/Components/DialogueAutolink'

import Mattermost from 'APP/Services/Mattermost'

// Actions
import PatientHistoryActions from 'APP/Redux/PatientHistoryRedux'

// Styles
import { Styles } from './style'
import { Colors } from 'APP/Themes'

// Services
import { channelToTimeStampForNewPost } from 'APP/Lib/MessagingHelpers'
import Config from 'APP/Config'
import I18n from 'APP/Services/i18n'
import { getPatientMember } from 'APP/Lib/ProfileHelpers'
import Analytics from 'APP/Services/Analytics'

// Api
import { checkLocationState, checkLocationService } from 'APP/NativeModules/PermissionsService'

import { isPostWhichHidesKeyboard } from 'APP/Lib/MessagingHelpers'

// Config
import { POSTS_PER_PAGE } from 'APP/Sagas/PatientHistorySagas'
import Typography from 'APP/Converse/Typography'

const { State: TextInputState } = TextInput
function dismissKeyboard() {
  if (Platform.OS !== 'web') {
    TextInputState.blurTextInput(TextInputState.currentlyFocusedInput())
  }
}

const getFullscreenProps = ({ props } = {}) =>
  props && !props.missingData && props.member_app_fullscreen

class IntakeScreen extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      allMembers: [],
      loadingExpired: false,
      locationPermission: 'granted',
      locationServiceEnabled: true,
      renderLimit: POSTS_PER_PAGE,
      transitioning: true,
      selectedBodyParts: [],
      hiddenPosts: [],
    }
  }

  _updateLocationState = () => {
    checkLocationState().then((response) => this.setState({ locationPermission: response }))
    checkLocationService().then((result) => this.setState({ locationServiceEnabled: result }))
  }

  UNSAFE_componentWillMount() {
    this.addMembersFromPosts(this.props)
  }

  componentDidMount() {
    this.updateEpisodeContext()
    this.delayedProcessingTimer = setTimeout(() => {
      this.props.updateChannelLastViewed()
      this._updateLocationState()
      this.setState({
        transitioning: false,
      })
    }, 700)
  }

  componentWillUnmount() {
    dismissKeyboard()
    this.props.updateChannelLastViewed()
    this.props.refreshAppointments()
    this.updateEpisodeContext(null)
    clearTimeout(this._clearHiddenPostsTimeoutId)
    clearTimeout(this._loadingTimeoutId)
    clearTimeout(this.delayedProcessingTimer)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.isInForeground) return false

    const { conversation, isInForeground, updateChannelLastViewed } = this.props

    // TODO: Verify whether this is in the right place. Suspect it should be on mount only?
    // UNSAFE_componentWillReceiveProps gets triggered every single message sent, received, and then some.
    this._updateLocationState()

    const channelChanged = nextProps.conversation.channelId !== conversation.channelId
    const movedToForeground =
      nextProps.isInForeground && nextProps.isInForeground !== isInForeground

    const lastPostId = [...(conversation.pendingOrder || []), ...(conversation.order || [])][0]
    const nextLastPostId = [
      ...(nextProps.conversation.pendingOrder || []),
      ...(nextProps.conversation.order || []),
    ][0]

    const receivedNewPost =
      nextProps.navigation.isFocused() && nextProps.isInForeground && lastPostId !== nextLastPostId

    if (channelChanged || movedToForeground || receivedNewPost) {
      updateChannelLastViewed()
    }

    if (channelChanged) {
      this.startLoadingOverlayTimeout()
      this.updateEpisodeContext(nextProps.conversation.channelId)
    }

    // Note: This is AP spec, but in playing it with, it feels kind of annoying to
    // have the keyboard dismiss when a question is received. UX isn't great.
    if (nextLastPostId && receivedNewPost) {
      const nextLastPost =
        nextProps.conversation &&
        nextProps.conversation.posts &&
        nextProps.conversation.posts[nextLastPostId]
      if (isPostWhichHidesKeyboard(nextLastPost)) {
        dismissKeyboard()
      }
    }

    this.addMembersFromPosts(nextProps)

    const nextFullscreenState = this.mapPropsToFullscreenState(nextProps)
    const newPostsWhileTimeoutRunning = this._clearHiddenPostsTimeoutId && receivedNewPost
    if ((this.props.fullscreenState && !nextFullscreenState) || newPostsWhileTimeoutRunning) {
      this.setState({ hiddenPosts: this.getLastNonFullscreenPosts(nextProps) }, () =>
        // TODO: call right after the transition form FS to chat is completed when it's done
        this.startClearHiddenPostsTimeout()
      )
    }
  }

  updateEpisodeContext(id = this.props.channelId) {
    if (id) {
      Analytics.attachEntity('episode', { id })
    } else {
      Analytics.detachEntity('episode')
    }
  }

  mapPropsToFullscreenState(props = this.props) {
    const {
      myMessagingUserId,
      conversation: { posts, order = [] },
    } = props
    const lastNonPatientPostIndex = order.findIndex((id) => posts[id].user_id !== myMessagingUserId)
    const lastNonPatientPostId = order[lastNonPatientPostIndex]
    const post = lastNonPatientPostId && posts[lastNonPatientPostId]
    return post && getFullscreenProps(post)
  }

  getLastNonFullscreenPosts({ conversation } = this.props) {
    const posts = { ...(conversation.posts || {}), ...(conversation.pendingPosts || {}) }
    const order = [...(conversation.pendingOrder || []), ...(conversation.order || [])]

    const lastNonFullscreenPosts = []

    for (let i = 0; i < order.length; i++) {
      const isFullscreen = !!getFullscreenProps(posts[order[i]])
      if (!isFullscreen) {
        lastNonFullscreenPosts.push(order[i])
      } else {
        break
      }
    }
    return lastNonFullscreenPosts
  }

  startClearHiddenPostsTimeout() {
    if (!this._clearHiddenPostsTimeoutId) {
      this._clearHiddenPostsTimeoutId = setTimeout(() => {
        this.setState({ hiddenPosts: [] })
        this._clearHiddenPostsTimeoutId = undefined
      }, 2000)
    }
  }

  startLoadingOverlayTimeout = () => {
    clearTimeout(this._loadingTimeoutId)
    this.setState({ loadingExpired: false })
    this._loadingTimeoutId = setTimeout(() => {
      this.setState({ loadingExpired: true })
    }, 10000)
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (
      !nextProps.navigation?.isFocused() ||
      nextState.transitioning ||
      !nextProps.isInForeground
    ) {
      return false
    }
    const previousOrderLength =
      this.props.conversation &&
      this.props.conversation.order &&
      this.props.conversation.order.length

    const newOrderLength =
      nextProps.conversation && nextProps.conversation.order && nextProps.conversation.order.length

    const previousPendingPosts = this.props.conversation && this.props.conversation.pendingPosts

    const newPendingPosts = nextProps.conversation && nextProps.conversation.pendingPosts

    return (
      previousOrderLength !== newOrderLength ||
      previousPendingPosts !== newPendingPosts ||
      this.state.hiddenPosts !== nextState.hiddenPosts ||
      this.state.allMembers.length !== nextState.allMembers.length ||
      this.state.loadingExpired !== nextState.loadingExpired ||
      this.state.renderLimit !== nextState.renderLimit ||
      this.state.locationPermission !== nextState.locationPermission ||
      this.state.locationServiceEnabled !== nextState.locationServiceEnabled ||
      this.props.isConnected !== nextProps.isConnected ||
      this.props.createdEpisodeError !== nextProps.createdEpisodeError ||
      this.props.profile !== nextProps.profile ||
      this.props.isInForeground !== nextProps.isInForeground ||
      this.props.conversation.fetchingPosts !== nextProps.conversation.fetchingPosts ||
      this.props.conversation.fetchingPostsError !== nextProps.conversation.fetchingPostsError ||
      this.state.transitioning !== nextState.transitioning ||
      this.state.showLoadingAttachment !== nextState.showLoadingAttachment ||
      this.state.selectedBodyParts !== nextState.selectedBodyParts ||
      this.props.active !== nextProps.active ||
      this.state.selectedAddress !== nextState.selectedAddress ||
      this.props.hasPostFailureError !== nextProps.hasPostFailureError ||
      this.props.encounters !== nextProps.encounters
    )
  }

  addMembersFromPosts(props = this.props) {
    const { conversation, practitioners } = props
    const { order, posts } = conversation

    const members = []
    // On first pass populate the patient into the array
    if (this.state.allMembers.length === 0) {

      members.push(getPatientMember(props.profile, props.myMessagingUserId))
    }

    if (order instanceof Array && posts instanceof Object && practitioners instanceof Object) {
      // When it hits the last processed id, it skips the block which has previously
      // been procesed. If the post order only grows from the ends, this won't miss
      // anything and will prevent redundant processing and looping of posts.
      // Ultimately, we should really move all of this jazz into a service, or better: to the server.
      const count = order.length - 1
      const skipStartIndex = order.indexOf(this.postSkipStartId)
      const skipOffset = skipStartIndex - order.indexOf(this.postSkipEndId)
      this.postSkipEndId = order[0]
      this.postSkipStartId = order[count]
      let i = count

      while (i > -1) {
        if (i === skipStartIndex) {
          i -= skipOffset
        } else {
          const postId = order[i]
          const userId = posts[postId].user_id
          const user = practitioners[userId]
          if (user) {
            const avatarUrl = user.avatar_url || (user.props && user.props.picture)
            members.push({ ...user, ...{ props: { picture: avatarUrl } } })
          }
        }
        i--
      }
    }

    if (members.length !== 0) {
      this.setState({
        allMembers: [...this.state.allMembers, ...members],
      })
    }
  }

  _resolveAttachmentUrl = (fileId) => this.props.getAttachmentUrl(fileId)
  _resolveGetPostFileMetadata = (fileId) => this.props.getPostFileMetadata(fileId)

  componentWillUpdate(nextProps) {
    if (
      nextProps.conversation.order &&
      nextProps.conversation.order.length === 0 &&
      this.chatInput &&
      !this.chatInput.isFocused()
    )
      this.chatInput.focus()
  }

  sendIntakeAnswer = (reply) => {
    Analytics.trackEvent('button_click', { button_value: 'Send intake answer' })
    const { props, display, fileObj } = reply
    const timeStamp = channelToTimeStampForNewPost(this.props.conversation)

    if (fileObj) {
      this.props.submitImage(this.props.myMessagingUserId, fileObj, timeStamp, props)
    } else {
      this.props.submitTextWithPayload(this.props.myMessagingUserId, display, timeStamp, props)
    }

    this.props.updateChannelLastViewed()
  }

  _onRetryPost = (postId) => {
    Analytics.trackEvent('button_click', { button_value: 'Retry send message' })

    this.props.retryPost(postId)
  }

  _handleDismissFailedRetryError = () => {
    this.props.dismissPostFailureError()
  }

  _renderLoadingView = () => {
    return (
      <ActivityIndicator
        size="large"
        color={Colors.text}
        style={{
          position: 'absolute',
          alignSelf: 'center',
        }}
      />
    )
  }

  _renderErrorText() {
    return (
      <Typography
        style={{
          position: 'absolute',
          alignSelf: 'center',
          paddingTop: 100,
          textAlign: 'center',
          color: Colors.text,
        }}
      >
        {I18n.t('ConversationScreen.genericError')}
      </Typography>
    )
  }

  getOrderAndPosts = () => {
    const { conversation } = this.props
    const { renderLimit } = this.state
    let posts = { ...(conversation.posts || {}), ...(conversation.pendingPosts || {}) }
    let order = [...(conversation.pendingOrder || []), ...(conversation.order || [])]
    if (this.state.hiddenPosts && this.state.hiddenPosts.length > 0) {
      for (let i = 0; i < this.state.hiddenPosts.length; i++) {
        const hiddenPostIndex = order.indexOf(this.state.hiddenPosts[i])
        if (hiddenPostIndex !== -1) {
          order.splice(hiddenPostIndex, 1)
        }
      }
    }
    order = order.slice(0, renderLimit)
    if (renderLimit) {
      const postsLimited = {}
      for (let i = 0; i < order.length; i++) {
        postsLimited[order[i]] = posts[order[i]]
      }
      posts = postsLimited
    }

    return {
      order,
      posts,
    }
  }

  render() {
    let {
      channelId,
      conversation,
      createdEpisodeError,
      myMessagingUserId,
      hasPostFailureError = false,
    } = this.props

    const { loadingExpired, transitioning, showLoadingAttachment, loadingAttachmentName } =
      this.state

    const showLoading =
      transitioning ||
      ((!conversation.order || conversation.order.length === 0) &&
        !createdEpisodeError &&
        !loadingExpired)

    const showError = !!createdEpisodeError && !channelId

    const { posts, order } = this.getOrderAndPosts()

    const conversationProps = {
      key: 'intake',
      posts,
      order,
      userId: myMessagingUserId,
      fullscreenState: this.props.fullscreenState,
      onIntakeAnswerSend: this.sendIntakeAnswer,
      retryPost: this._onRetryPost,
      resolveAttachmentUrl: this._resolveAttachmentUrl,
      resolveGetPostFileMetadata: this._resolveGetPostFileMetadata,
      exitAlert: this.props.exitAlert,
      exitAction: this.props.exitAction,
      channelId: this.props.channelId,
    }

    return (
      <View style={Styles.container}>
        <IntakeTransition animatedKey="intake" style={Styles.transitionContainer}>
          <Intake {...conversationProps} />
        </IntakeTransition>
        {showLoading && this._renderLoadingView()}
        {showError && this._renderErrorText()}
        {showLoadingAttachment && <AttachmentLoadingOverlay text={loadingAttachmentName} />}
        <Modal
          statusBarTranslucent
          visible={hasPostFailureError}
          transparent
          title={I18n.t('ConversationScreen.failedRetryError.title')}
          handleCloseModal={this._handleDismissFailedRetryError}
          primaryActionText={I18n.t('ConversationScreen.failedRetryError.primaryAction')}
          handlePrimaryAction={this._handleDismissFailedRetryError}
        >
          <DialogueAutolink
            text={I18n.t('ConversationScreen.failedRetryError.body', {
              supportEmail: Config.SUPPORT_EMAIL[I18n.baseLocale],
            })}
            style={Styles.postFailureModalText}
          />
        </Modal>
      </View>
    )
  }
}

const mapStateToProps = (state, props) => {
  const channelId = props.channelId || props.route?.params?.channelId
  const conversation = state.history.channels[channelId] || {}
  const authToken = state.login?.accessToken
  return {
    ...props.route?.params,
    features: state.features,
    practitioners: state.history.practitioners,
    profile: state.patient.profile,
    conversation,
    myMessagingUserId: state.login.messagingUserId,
    authToken,
    encounters: state.history.encounters,
    isConnected: state.appSession.isConnected,
    isInForeground: state.appSession.isActive,
    createdEpisodeError: state.history.createEpisodeError,
    eligibleServices: state.patient.profile && state.patient.profile.eligibleServices,
    eligibleBrands: state.patient?.profile?.eligibleBrands || [],
    active: conversation.active,
    location: state.patient.profile.location,
    hasPostFailureError: conversation.hasPostFailureError,
    getAttachmentUrl: (fileId) => {
      const mattermost = Mattermost.create(authToken)
      return mattermost.getPublicUrl({ fileId })
    },
    getPostFileMetadata: (postId) => {
      const mattermost = Mattermost.create(authToken)
      return mattermost.getPostFileInfo({ postId })
    },
    episodes: state.history.episodes,
    showPronouns: state.features?.pronounDisplay,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const channelId = props.channelId || props.route?.params?.channelId
  return {
    updateChannelLastViewed: () =>
      dispatch(PatientHistoryActions.updateChannelLastViewed(channelId)),
    changeDraftText: (text) => dispatch(PatientHistoryActions.changeDraftText(channelId, text)),
    submitText: (userId, text, timeStamp, flags) =>
      dispatch(
        PatientHistoryActions.submitTextPostRequest(channelId, userId, text, timeStamp, flags)
      ),
    submitImage: (userId, fileObj, timeStamp, flags) =>
      dispatch(
        PatientHistoryActions.submitFilePostRequest(channelId, userId, fileObj, timeStamp, flags)
      ),
    submitAnswer: (userId, answer, timeStamp, flags) =>
      dispatch(
        PatientHistoryActions.submitAnswerPostRequest(channelId, userId, answer, timeStamp, flags)
      ),
    submitTextWithPayload: (userId, text, timeStamp, flags, payload) =>
      dispatch(
        PatientHistoryActions.submitTextWithPayloadPostRequest(
          channelId,
          userId,
          text,
          timeStamp,
          flags,
          payload
        )
      ),
    retryPost: (postId) => dispatch(PatientHistoryActions.retryPostRequest(channelId, postId)),
    dismissPostFailureError: () =>
      dispatch(PatientHistoryActions.setFailedPostError(channelId, false)),
    fetchOlderPostsForChannel: () =>
      dispatch(PatientHistoryActions.fetchOlderPostsForChannel(channelId)),
    shownLocationNotSharedBar: () =>
      dispatch(PatientHistoryActions.shownLocationNotSharedBar(channelId)),
    refreshAppointments: () => dispatch(PatientHistoryActions.refreshAppointmentsRequest()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(connectActionSheet(IntakeScreen))
