import { StyleSheet, Platform } from 'react-native'
import { Metrics, Colors, Fonts, ApplicationStyles } from 'APP/Themes'

export const tabBarStyles = {
  tabBarLabelStyle: {
    ...Fonts.style.bodySmallBold,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: Metrics.baseMargin * 0.25,
  },
  tabBarItemStyle: {
    padding: Metrics.baseMargin * 0.5,
    borderTopWidth: 3,
    paddingBottom: 3,
  },
  tabBarStyle: {
    ...ApplicationStyles.shadowBottomLit,
    height: Metrics.tabBarHeight,
    backgroundColor: Colors.elementsBg,
  },
  tabBarIconStyle: {
    padding: 0,
  },
}

export default StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    flex: 1,
    display: 'flex',
  },
  stackContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  title: {
    ...Fonts.style.h4,
    color: Colors.text,
    textAlign: 'center',
    width: '100%',
    alignSelf: 'center',
  },
  menuContainer: {
    backgroundColor: Colors.appBg,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    borderRightColor: 'rgba(0, 0, 0, 0.16)',
    borderRightWidth: 1,
    shadowColor: 'rgb(0, 0, 0)',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.08,
    shadowRadius: 10,
    width: 240,
    zIndex: 1,
  },
  buttonText: {
    ...Fonts.style.body,
    color: Colors.text,
    ...Platform.select({
      android: {
        paddingLeft: Metrics.baseMargin,
      },
    }),
  },
  buttonIcon: {
    height: 22,
    tintColor: Colors.text,
  },
  header: {
    backgroundColor: Colors.appBg,
    ...ApplicationStyles.shadow,
  },
  headerBackButton: {
    color: Colors.text,
    zIndex: 2,
    ...Platform.select({
      android: {
        paddingLeft: Metrics.baseMargin,
      },
      ios: {
        position: 'absolute',
      },
    }),
  },
  headerBackIcon: {
    tintColor: Colors.text,
  },
  headerModalCloseButton: {
    marginTop: 2,
    color: Colors.text,
    zIndex: 2,
    ...Platform.select({
      android: {
        paddingLeft: Metrics.baseMargin,
      },
    }),
  },
  headerModalCloseIcon: {
    tintColor: Colors.text,
  },
  headerButton: {
    ...Platform.select({
      android: {
        paddingRight: Metrics.baseMargin,
      },
    }),
  },
})
