import React, { useState, useEffect } from 'react'
import { WebView } from 'react-native-webview'
import { ActivityIndicator, View } from 'react-native'
import { useTheme } from 'styled-components/native'

import Styles from './style'
import { isWeb } from 'APP/Helpers/checkPlatform'

const DialogueWebView = (props) => {
  const theme = useTheme()

  const [loading, setLoading] = useState(true)

  const onLoadEnd = () => {
    setTimeout(() => {
      setLoading(false)
    }, 250)
  }

  const onLoadStart = () => setLoading(true)

  const renderLoadingOverlay = () => {
    return (
      <View testID="DialogueWebView-loading-container" style={Styles.loadingOverlay}>
        <ActivityIndicator color={theme.colors.text} />
      </View>
    )
  }

  const onWebViewMessage = (event) => {
    if (isWeb() && event && props.onEvent) {
      props.onEvent(event)
    } else if (event.nativeEvent && props.onEvent) {
      props.onEvent(event.nativeEvent)
    }
  }

  useEffect(() => {
    if (isWeb()) {
      window.addEventListener('message', onWebViewMessage)

      return () => {
        window.removeEventListener('message', onWebViewMessage)
      }
    }
  }, [])

  const injectedJavaScript = `
    var node = document.createElement('style');
    var rules = ""
    rules += "html, body { background: ${theme.colors.background}; }";
    rules += "body {  color: ${theme.colors.text}; margin: 24px; }";
    rules += "a { color: ${theme.colors.accent} }"
    node.innerHTML = rules;
    document.querySelector('HEAD').appendChild(node);
  `

  // Promoted Content links will always be in light mode (DIA-38333)
  // TODO: Change this to be flag-driven, something like "injectStyling:false".
  // In order for `injectedJavaScript` to work on web (iframe), additional work will be needed. Bye dark mode, for now.
  const injected = props.route?.name === 'promotedContent' ? '' : injectedJavaScript
  return (
    <>
      {isWeb() ? (
        <iframe
          style={{
            borderWidth: '0',
            padding: '2rem',
            userSelect: props.userSelect || 'all',
          }}
          src={props.uri}
          height={'100%'}
          onLoad={onLoadEnd}
        />
      ) : (
        <WebView
          style={Styles.webView}
          injectedJavaScript={injected}
          javaScriptEnabledAndroid={true}
          source={{ uri: props.uri, headers: props.headers }}
          scalesPageToFit={false}
          onLoadEnd={onLoadEnd}
          onLoadStart={onLoadStart}
          allowsInlineMediaPlayback
          onMessage={onWebViewMessage}
          allowsFullscreenVideo={true}
        />
      )}
      {loading && renderLoadingOverlay()}
    </>
  )
}

export default DialogueWebView
