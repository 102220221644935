import React from 'react'
import { View, Text } from 'react-native'
import { ImageMapSelector } from '@dialogue/components'
import Icon from 'react-native-vector-icons/MaterialIcons'

import Attachment from 'APP/Components/Attachment'

// Services
import I18n from 'APP/Services/i18n'

//Components
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'

// Styles
import { Colors, Fonts } from 'APP/Themes'
import { Styles } from './style'

const AnswerComponent = ({ answer, ...other }) => {
  switch (answer.props.type) {
    case 'prompt_file_response': {
      return (
        <Attachment
          answer={answer}
          style={Styles.answerImageContainer}
          imageStyle={Styles.answerImage}
          {...other}
        />
      )
    }
    case 'prompt_choice_image_map_response': {
      const { selected_choices, image_metadata, image_source } = answer.props
      let selectedRegions = selected_choices.map((choice) => choice.id)
      return (
        <ImageMapSelector
          imageScale={0.9}
          selectedRegions={selectedRegions}
          imageRegions={image_metadata}
          imageSource={image_source}
          style={Styles.answerImageMap}
          readOnly
          pointerEvents="none"
        />
      )
    }
    default:
      return <Text style={Styles.answerText}>{answer.message}</Text>
  }
}

const IntakeAnswer = ({ answer, onRetry, ...other }) => {
  const showRetry = answer.props && answer.props.failed

  return (
    <DialogueTouchableOpacity
      onPress={() => onRetry(answer.id)}
      disabled={!showRetry}
      analyticsName="Retry resend"
      style={Styles.answerContainer}
    >
      <View style={Styles.answerInnerContainer}>
        <View style={Styles.answer}>
          <AnswerComponent answer={answer} {...other} />
        </View>
        {showRetry && (
          <Icon
            name="error-outline"
            color={Colors.errorText}
            size={Fonts.size.h3}
            style={Styles.answerErrorIcon}
          />
        )}
      </View>
      {showRetry && (
        <View>
          <Text style={Styles.messageErrorText}>
            {I18n.t('IntakeComponent.messageError')}{' '}
            <Text style={Styles.answerRetryText}>{I18n.t('IntakeComponent.resendMessage')}</Text>
          </Text>
        </View>
      )}
    </DialogueTouchableOpacity>
  )
}

export default IntakeAnswer
