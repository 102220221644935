import React, { useRef, useState, createContext, useContext, useCallback, useEffect } from 'react'
import { Modal, Animated, Platform } from 'react-native'
import styled from 'styled-components/native'
import { Metrics } from 'APP/Themes'

type BottomDrawerContextProps = {
  slideIn: Animated.Value
  onClose: () => void
}

type BottomDrawerModalProviderProps = {
  isVisible: boolean
  onClose: () => void
  children: React.ReactNode
  testID: string
}

const BottomDrawerContext = createContext<BottomDrawerContextProps | undefined>(undefined)

export const useBottomDrawer = () => {
  const context = useContext(BottomDrawerContext)
  if (!context) throw new Error('useBottomDrawer must be used within a BottomDrawerProvider')
  return context
}

const KeyboardAvoidingContainer = styled.KeyboardAvoidingView`
  flex: 1;
`

export const BottomDrawerProvider: React.FC<BottomDrawerModalProviderProps> = ({
  isVisible,
  onClose,
  children,
  testID,
}) => {
  const slideIn = useRef(new Animated.Value(0)).current
  const [isVisibleInternal, setIsVisibleInternal] = useState(isVisible)

  const animateIn = useCallback(() => {
    setIsVisibleInternal(true)
    Animated.spring(slideIn, {
      toValue: 1,
      useNativeDriver: true,
      overshootClamping: true,
    }).start()
  }, [slideIn])

  const animateOut = useCallback(() => {
    Animated.spring(slideIn, {
      toValue: 0,
      useNativeDriver: true,
      overshootClamping: true,
    }).start(() => setIsVisibleInternal(false))
  }, [slideIn])

  useEffect(() => {
    if (isVisible) {
      animateIn()
    } else {
      animateOut()
    }
    return () => {
      slideIn.stopAnimation()
    }
  }, [isVisible, animateIn, animateOut, slideIn])

  if (!isVisibleInternal) {
    return null
  }

  return (
    <BottomDrawerContext.Provider value={{ slideIn, onClose }}>
      <Modal
        statusBarTranslucent
        animationType="fade"
        transparent
        visible={isVisibleInternal}
        onRequestClose={onClose}
        testID={testID}
      >
        <KeyboardAvoidingContainer
          behavior={Platform.OS === 'ios' ? 'padding' : undefined}
          keyboardVerticalOffset={-Metrics.bottomSpace}
        >
          {children}
        </KeyboardAvoidingContainer>
      </Modal>
    </BottomDrawerContext.Provider>
  )
}
