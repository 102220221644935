import React, { useCallback, useContext } from 'react'
import { PostContext } from 'APP/Lib/Context'
import { Image, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import Analytics from 'APP/Services/Analytics'
import { Images } from 'APP/Themes'

import { Styles } from './style'

const Avatar = () => {
  const navigation = useNavigation()
  const profile = useSelector((state) => state.patient.profile)

  const { postUser, nextPostIsSameUser } = useContext(PostContext)

  const getAvatarSource = useCallback(() => {
    const avatar = postUser?.avatar_url || postUser?.props?.picture || Images.logoNurse

    const avatarSource =
      typeof avatar === 'string' && avatar.includes('http') ? { uri: avatar } : avatar
    return avatarSource
  }, [postUser])

  const onPressAvatar = useCallback(() => {
    if (postUser?.app_id) {
      const appId = postUser.app_id
      Analytics.trackEvent('button_click', { button_value: 'Practitioner profile tap' })
      navigation.navigate('practitionerProfile', { userAppId: appId, profileId: profile.id })
    }
  }, [postUser])

  if (nextPostIsSameUser) {
    return <View style={Styles.avatarContainer} />
  }

  // If there is no postUser, it means the post is from the bot
  if (!postUser) {
    return (
      <View style={Styles.avatarContainer} testID="avatar">
        <Image style={Styles.avatar} source={Images.logoNurse} />
      </View>
    )
  }

  return (
    <TouchableOpacity style={Styles.avatarContainer} onPress={onPressAvatar} testID="avatar">
      <Image style={Styles.avatar} source={getAvatarSource()} />
    </TouchableOpacity>
  )
}

export default Avatar
