import React, { useContext } from 'react'
import { Linking, Text, TouchableOpacity, View } from 'react-native'
import * as Sentry from '@sentry/react-native'

import MaterialIcon from 'react-native-vector-icons/MaterialIcons'

import TextMessage from 'APP/Components/FelixChat/TextMessage'
import { PostContext } from 'APP/Lib/Context'
import Analytics from 'APP/Services/Analytics'
import I18n from 'APP/Services/i18n'
import Colors from 'APP/Themes/Colors'

import { Styles } from './style'
import { logDdError } from 'APP/Lib/Datadog'

const ResourceLink = () => {
  const { post } = useContext(PostContext)

  const resourceUrl = post.props.resource

  const onPress = () => {
    Linking.openURL(resourceUrl).catch((error) => {
      Sentry.captureException('Error opening link to resource', {
        extra: {
          errorMessage: error.message,
          resourceUrl,
          screen: 'ChatScreen',
        },
      })
      logDdError(error.message, 'ChatScreen ResourceLink')
    })
    Analytics.trackEvent('button_click', { button_value: 'View resources' })
  }

  return (
    <View style={Styles.container}>
      <TextMessage post={post} isMember={false} />
      <TouchableOpacity style={Styles.actionContainer} onPress={onPress} testID="resourceLink">
        <View style={Styles.row}>
          <View style={Styles.icon}>
            <MaterialIcon name="post-add" size={20} color={Colors.accent} />
          </View>
          <View style={Styles.labelContainer}>
            <Text style={Styles.linkLabel}>{I18n.t('ConversationScreen.links.viewResource')}</Text>
          </View>
          <View style={Styles.action}>
            <MaterialIcon name="chevron-right" size={20} color={Colors.accent} />
          </View>
        </View>
      </TouchableOpacity>
    </View>
  )
}

export default ResourceLink
