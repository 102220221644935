import { StyleSheet } from 'react-native'
import { Colors, Fonts, Metrics, ApplicationStyles } from 'APP/Themes'

export default StyleSheet.create({
  container: {
    paddingBottom: Metrics.bottomSpace + Metrics.baseMargin,
  },
  cta: {
    ...ApplicationStyles.shadow,
    backgroundColor: Colors.elementsBg,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    // TODO: Remove references to fixed shadow color
    borderBottomColor: Colors.shadow,
    paddingVertical: Metrics.baseMargin,
    paddingHorizontal: Metrics.baseMargin * 1.25,
    minHeight: Fonts.lineHeight.small + Fonts.lineHeight.regular + Metrics.baseMargin * 2.25 + 5,
  },
  ctaTitle: {
    ...Fonts.style.bodySmall,
    color: Colors.text,
    marginBottom: 5,
  },
  ctaCopy: {
    ...Fonts.style.subheaderBold,
    color: Colors.text,
  },
  ctaActivity: {
    alignSelf: 'flex-start',
  },
  removeCta: {
    marginTop: Metrics.baseMargin * 2,
    display: 'flex',
    alignItems: 'flex-start',
    paddingHorizontal: Metrics.baseMargin * 1.25,
  },
  removeCtaTitle: {
    ...Fonts.style.button,
    color: Colors.text,
  },
  removeCtaCopy: {
    ...Fonts.style.body,
    color: Colors.text,
  },
})
