import React from 'react'

import { Menu } from 'APP/Nav/Components/Menu'
import { usePrimaryTabs, useSecondaryTabs } from './useTabs'

export const TabBarSideMenu = (props) => {
  const primaryTabs = usePrimaryTabs()
  const secondaryTabs = useSecondaryTabs()
  return (
    <Menu {...props}>
      <Menu.Logo />
      <Menu.Divider />
      <Menu.NavList items={primaryTabs} />
      <Menu.ExpandingSpace />
      <Menu.Divider />
      <Menu.NavList items={secondaryTabs} />
    </Menu>
  )
}
