import React, { useLayoutEffect, useEffect, useState } from 'react'
import { View } from 'react-native'
import { Appearance } from 'react-native-appearance'
import { useKeepAwake } from 'expo-keep-awake'

import { useShareDeepLinkFromConfig } from 'APP/Hooks/Share'
import CmsScreen from 'APP/Containers/CmsScreen'
import Chromecast, { ChromecastButton } from 'APP/Components/Chromecast'
import ButtonShare from 'APP/Components/ButtonShare'
import Analytics from 'APP/Services/Analytics'
import sprigClient from 'APP/Services/Sprig'
import UrlBuilder from 'APP/Services/UrlBuilder'
import { Metrics } from 'APP/Themes'
import Styles from './style'

const TRIGGER = {
  PROGRESS: 'progress',
  PLAY: 'play',
  FULLSCREEN: 'fullscreen',
  CHROMECAST: 'chromecast',
}

const ANALYTICS = {
  PAGE_VIEWED: 'WELLNESS_CENTER_SINGLE_VIDEO_PAGE_VIEWED',
  PAGE_CLOSED: 'WELLNESS_CENTER_SINGLE_VIDEO_PAGE_CLOSED',
  VIDEO_PLAY: 'WELLNESS_CENTER_VIDEO_PLAY',
  VIDEO_EXIT_FULLSCREEN: 'WELLNESS_CENTER_VIDEO_EXIT_FULLSCREEN',
  VIDEO_PROGRESS: 'WELLNESS_CENTER_VIDEO_PROGRESS',
}

const WellnessCenterVideoScreen = (props) => {
  const { navigation, route } = props
  const { id, title, services } = { ...route?.params, ...props }
  const analyticsName = route?.name
  const uri = UrlBuilder.cmsVideoUrl(id, Appearance.getColorScheme())

  const [media, setMedia] = useState(null)

  useEffect(() => {
    sprigClient.track(ANALYTICS.PAGE_VIEWED)
    return () => {
      sprigClient.track(ANALYTICS.PAGE_CLOSED)
    }
  }, [])

  const onMessage = ({ nativeEvent: { data } }) => {
    const attributes = JSON.parse(data)

    if (attributes?.trigger === TRIGGER.CHROMECAST) {
      setMedia({
        url: attributes?.url,
        title: attributes?.title,
        poster: attributes?.poster,
      })
      Analytics.trackEvent('button_click', {
        screen_name: analyticsName,
        trigger: uri,
        button_value: 'chromecast',
      })
      return
    }

    if (attributes?.trigger === TRIGGER.FULLSCREEN && attributes?.open === false) {
      // iOS likes to animate the exit and if we don't wait for that to complete the survey won't trigger
      setTimeout(() => {
        sprigClient.track(ANALYTICS.VIDEO_EXIT_FULLSCREEN)
      }, 400)

      Analytics.trackEvent('button_click', {
        screen_name: analyticsName,
        trigger: uri,
        button_value: 'fullscreen_closed',
      })
    }

    if (attributes?.trigger === TRIGGER.PLAY) {
      sprigClient.track(ANALYTICS.VIDEO_PLAY)
      Analytics.trackEvent('button_click', {
        screen_name: analyticsName,
        trigger: uri,
        button_value: 'play',
      })
    }

    if (attributes?.trigger === TRIGGER.PROGRESS) {
      sprigClient.track(
        `${ANALYTICS.VIDEO_PROGRESS}_${attributes.type.toUpperCase()}_${attributes.value}`
      )
      Analytics.trackEvent('video_progress', {
        ...attributes,
        screen_name: analyticsName,
        trigger: uri,
        id,
      })
    }
  }
  const shareVideo = useShareDeepLinkFromConfig(
    'CMS_VIDEO_SCREEN_DEEP_LINK_URL',
    {
      services: 'wellness_content',
      id,
    },
    { analyticsName }
  )

  useLayoutEffect(() => {
    const headerRight = () => (
      <View style={Styles.headerRightButtonsContainer}>
        <ChromecastButton />
        <ButtonShare
          analyticsName={analyticsName}
          onPress={() => shareVideo.share()}
          style={{ marginLeft: Metrics.baseMargin * 1.25 }}
        />
      </View>
    )
    navigation.setOptions({ title: '', headerRight })
  }, [analyticsName, shareVideo, navigation])

  // Don't let device go to sleep
  useKeepAwake()
  return (
    <Chromecast media={media}>
      <View style={Styles.container}>
        <CmsScreen
          uri={uri}
          screen={'cms'}
          onMessage={onMessage}
          analyticsName={`${analyticsName}: ${id}`}
          screenUri={title}
          showShareButton={false}
          navigation={navigation}
          services={services}
        />
      </View>
    </Chromecast>
  )
}

export default WellnessCenterVideoScreen
