import React from 'react'
import moment from 'moment'
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import I18n from 'APP/Services/i18n'
import Typography from 'APP/Converse/Typography'
import { Colors } from 'APP/Themes'
import { StyledViewContainer } from './style'

const LastActivitySync = ({ lastSync }) => (
  <StyledViewContainer>
    <MaterialCommunityIcon
      name="clock-time-ten-outline"
      size={16}
      color={Colors.textSecondary}
      style={{ paddingRight: 5 }}
    />
    <Typography variant="bodySmall" color="secondary">
      {lastSync
        ? I18n.t('ActiveMinutes.activitySync.lastSyncedAt', {
            timeAgo: moment.utc(lastSync).fromNow(),
          })
        : I18n.t('ActiveMinutes.activitySync.noData')}
    </Typography>
  </StyledViewContainer>
)

export default LastActivitySync
