import React from 'react'
import { Image, ImageProps, ImageSourcePropType } from 'react-native'
import styled from 'styled-components/native'
import { squareExternalLogos } from 'APP/Themes/Images'

interface ImageContainerProps extends ImageProps {
  isSquare: boolean
}

const ImageContainer = styled(Image)<ImageContainerProps>`
  width: ${({ isSquare }) => (isSquare ? 84 : 215)}px;
  height: 84px;
  margin-horizontal: ${({ theme }) => theme.metrics.baseMargin * 2}px;
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin * 1.5}px;
`

export const ServiceGroupImage = ({
  source,
  serviceGroup,
  testID = 'service-group-image',
}: {
  source: ImageSourcePropType
  serviceGroup: Record<string, unknown>
  testID: string
}) => {
  const overrideIcon = serviceGroup?.screenIconOverride as string
  const isSquareImage = squareExternalLogos.includes(overrideIcon)

  return (
    <ImageContainer
      source={source}
      testID={testID}
      isSquare={isSquareImage}
      resizeMode={'contain'}
    />
  )
}
