import { StyleSheet } from 'react-native'
import { Metrics } from '../../Themes'

export default StyleSheet.create({
  slimToggles: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
  },
  toggles: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    marginTop: Metrics.baseMargin * 1.5,
  },
  webToggles: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    rowGap: Metrics.baseMargin,
    marginTop: Metrics.baseMargin * 1.5,
    paddingHorizontal: Metrics.baseMargin,
  },
  toggle: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: Metrics.baseMargin * 0.3,
    marginRight: Metrics.baseMargin * 0.3,
  },
  firstToggle: {
    marginLeft: Metrics.baseMargin * 1.2,
  },
  lastToggle: {
    marginRight: Metrics.baseMargin * 1.2,
  },
})
