import React from 'react'
import Icon from 'APP/Converse/Icon'
import { Text, TouchableOpacity, View } from 'react-native'

// Styling
import Styles from './style'
import I18n from 'APP/Services/i18n'
import { Colors, Fonts } from 'APP/Themes'

// Services
import Analytics from 'APP/Services/Analytics'

export default class AlertModal extends React.Component {
  constructor(props) {
    super(props)
  }

  _handleDismissModal = () => {
    Analytics.trackEvent('button_click', { button_value: 'Dismiss' }, ['banner_context'])
    this.props.handleDismissModal()
  }

  _handleLinkAction(linkText) {
    this.props.handleLinkAction()
    return () => {
      Analytics.trackEvent(
        'link_text_click',
        {
          linkText: linkText,
        },
        ['banner_context']
      )
    }
  }

  render() {
    const { titleText, subtitleText, linkText, icon, iconColor, alertStyle, style } = this.props

    const backgroundColor = Colors.bannerBg
    const titleColor = Colors[`alertBannerTitle${alertStyle}`]

    return (
      <View style={[Styles.container, backgroundColor && { backgroundColor }, style]} accessible>
        <TouchableOpacity
          style={Styles.closeButton}
          onPress={this._handleDismissModal}
          accessibilityLabel={I18n.t('AccessibilityIcons.close')}
        >
          <Icon src="close" variant="materialIcons" size={Fonts.size.h2} color="text" />
        </TouchableOpacity>
        <View style={Styles.titleContainer}>
          <Text style={[Styles.titleText, titleColor && { color: titleColor }]}>
            {icon && (
              <Icon
                src={icon}
                variant="ionicons"
                color={iconColor}
                size={Fonts.size.large}
                testId="icon"
              />
            )}
            {icon && ' '}
            {titleText}
          </Text>
        </View>
        <View style={Styles.secondaryTextContainer}>
          <Text style={Styles.secondaryText}>
            {subtitleText}{' '}
            <Text style={Styles.link} onPress={() => this._handleLinkAction(linkText)}>
              {linkText}
            </Text>
          </Text>
        </View>
      </View>
    )
  }
}
