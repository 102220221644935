import { StyleSheet } from 'react-native'
import { ApplicationStyles, Metrics, Colors } from '../../Themes'
import styled from 'styled-components/native'

export const StyledButtonContainerView = styled.View`
  margin-left: ${({ theme: { metrics } }) => metrics.baseMargin}px;
  margin-right: ${({ theme: { metrics } }) => metrics.baseMargin}px;
  gap: ${({ theme: { metrics } }) => metrics.baseMargin}px;
`

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  placeholderImage: {
    resizeMode: 'contain',
    alignSelf: 'center',
    marginTop: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin,
  },
  cameraSection: {
    marginVertical: 0,
    padding: Metrics.baseMargin,
  },
  previewContainer: {
    alignSelf: 'center',
    justifyContent: 'center',
    width: Metrics.screenWidth - Metrics.baseMargin,
    height: Metrics.screenHeight * 0.5,
    marginTop: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin,
  },
  previewImage: {
    resizeMode: 'contain',
  },
  errorText: {
    textAlign: 'center',
    color: Colors.errorText,
  },
})
