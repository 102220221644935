import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  serviceLoginSuccess: ['terraReferenceId'],
  serviceLoginFailure: ['error', 'reason'],

  getActiveMinutes: [],
  getActiveMinutesSuccess: ['activeMinutes'],
  getActiveMinutesFailure: [],

  getHistory: ['from', 'weeksBackCount'],
  getHistorySuccess: ['history'],
  getHistoryFailure: [],

  updateWeeklyTarget: ['target', 'changeType'],
  updateWeeklyTargetSuccess: [],
  updateWeeklyTargetFailure: [],
  resetUpdateWeeklyTarget: [],

  setConfettiShown: [],
  setConfettiShownSuccess: [],
  setConfettiShownFailure: [],

  getConnectedTrackers: [],
  getConnectedTrackersSuccess: ['trackers', 'supportedActivities'],
  getConnectedTrackersFailure: [],

  requestNewConnection: ['provider'],
  requestNewConnectionSuccess: ['connectionCreated'],
  requestNewConnectionFailure: ['error'],
  resetRequestNewConnection: [],

  removeConnection: ['provider', 'id'],
  removeConnectionSuccess: [],
  removeConnectionFailure: ['error'],

  handleAppleHealthReconnection: [],

  getChallenges: [],
  getChallengesSuccess: ['joined', 'newAndUpcoming'],
  getChallengesFailure: [],

  getMyCompletedChallenges: [],
  getMyCompletedChallengesSuccess: ['completed'],
  getMyCompletedChallengesFailure: [],

  getSingleChallenge: ['id'],
  getSingleChallengeSuccess: ['challenge'],
  getSingleChallengeFailure: ['error'],

  joinChallenge: ['id'],
  joinChallengeSuccess: [],
  joinChallengeFailure: [],
  resetJoinChallenge: [],

  getChallengeLeaderboard: ['challengeId'],
  getChallengeLeaderboardSuccess: ['data'],
  getChallengeLeaderboardFailure: [],

  leaveChallenge: ['id'],
  leaveChallengeSuccess: [],
  leaveChallengeFailure: [],
  resetLeaveChallenge: [],
})

export const ActiveMinutesTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  serviceLogin: {
    done: false,
    reason: undefined,
    error: undefined,
    terraReferenceId: undefined,
  },
  activeMinutes: {
    loading: true,
    retry: false,
    data: undefined,
  },
  history: {
    loading: true,
    retry: false,
    data: [],
  },
  updateWeeklyTarget: {
    loading: false,
    updated: false,
    retry: false,
  },
  setConfettiShown: {
    loading: false,
    updated: false,
    retry: false,
  },
  connectedTrackers: {
    loading: false,
    retry: false,
    data: [],
    supportedActivities: [],
  },
  newConnectionRequest: {
    provider: undefined,
    processing: false,
    connectionCreated: false,
    error: undefined,
  },
  removeConnection: {
    provider: undefined,
    processing: false,
    error: undefined,
  },
  challenges: {
    loading: false,
    retry: false,
    joined: undefined,
    newAndUpcoming: undefined,
  },
  myCompletedChallenges: {
    loading: false,
    retry: false,
    completed: undefined,
  },
  selectedChallenge: {
    loading: true,
    retry: false,
    challenge: null,
  },
  leaderboard: {
    loading: true,
    retry: false,
    challengeId: undefined,
    data: null,
  },
  joinChallenge: {
    loading: false,
    retry: false,
    success: false,
    id: undefined,
  },

  leaveChallenge: {
    loading: false,
    retry: false,
    success: false,
    id: undefined,
  },
})

/* ------------- Reducers ------------- */
export const serviceLoginSuccess = (state, { terraReferenceId }) =>
  state.merge({
    serviceLogin: {
      ...state.serviceLogin,
      terraReferenceId,
      done: true,
      reason: undefined,
      error: undefined,
    },
  })

export const serviceLoginFailure = (state, { error, reason }) =>
  state.merge({
    serviceLogin: {
      ...state.serviceLogin,
      done: false,
      error,
      reason,
      terraReferenceId: undefined,
    },
  })

export const getActiveMinutes = (state) =>
  state.merge(
    {
      activeMinutes: {
        loading: true,
        retry: false,
      },
    },
    { deep: true }
  )

export const getActiveMinutesSuccess = (state, { activeMinutes }) =>
  state.merge({
    activeMinutes: {
      loading: false,
      retry: false,
      data: activeMinutes,
    },
  })

export const getActiveMinutesFailure = (state) =>
  state.merge({
    activeMinutes: {
      loading: false,
      retry: true,
    },
  })

export const getHistory = (state) =>
  state.merge({
    history: {
      loading: true,
      retry: false,
      data: [],
    },
  })

export const getHistorySuccess = (state, { history }) =>
  state.merge({
    history: {
      loading: false,
      retry: false,
      data: history,
    },
  })

export const getHistoryFailure = (state) =>
  state.merge({
    history: {
      loading: false,
      retry: true,
    },
  })

export const updateWeeklyTarget = (state) =>
  state.merge({
    updateWeeklyTarget: {
      loading: true,
      updated: false,
      retry: false,
    },
  })

export const updateWeeklyTargetSuccess = (state) =>
  state.merge({
    updateWeeklyTarget: {
      loading: false,
      updated: true,
      retry: false,
    },
  })

export const updateWeeklyTargetFailure = (state) =>
  state.merge({
    updateWeeklyTarget: {
      loading: false,
      updated: false,
      retry: true,
    },
  })

export const resetUpdateWeeklyTarget = (state) =>
  state.merge({
    updateWeeklyTarget: {
      loading: false,
      updated: false,
      retry: false,
    },
  })

export const setConfettiShown = (state) =>
  state.merge({
    setConfettiShown: {
      loading: true,
      updated: false,
      retry: false,
    },
  })

export const setConfettiShownSuccess = (state) =>
  state.merge({
    setConfettiShown: {
      loading: false,
      updated: true,
      retry: false,
    },
  })

export const setConfettiShownFailure = (state) =>
  state.merge({
    setConfettiShown: {
      loading: false,
      updated: false,
      retry: true,
    },
  })

export const getConnectedTrackers = (state) =>
  state.merge({
    connectedTrackers: {
      ...state.connectedTrackers,
      loading: true,
      retry: false,
    },
  })

export const getConnectedTrackersSuccess = (state, { trackers, supportedActivities }) =>
  state.merge({
    connectedTrackers: {
      ...state.connectedTrackers,
      loading: false,
      retry: false,
      data: trackers,
      supportedActivities,
    },
  })

export const getConnectedTrackersFailure = (state) =>
  state.merge({
    connectedTrackers: {
      ...state.connectedTrackers,
      loading: false,
      retry: true,
    },
  })

export const requestNewConnection = (state, { provider }) =>
  state.merge({
    newConnectionRequest: {
      ...state.newConnectionRequest,
      provider,
      connectionCreated: false,
      processing: true,
      error: undefined,
    },
  })

export const requestNewConnectionSuccess = (state, { connectionCreated = false }) =>
  state.merge({
    newConnectionRequest: {
      ...state.newConnectionRequest,
      connectionCreated,
      processing: false,
      error: undefined,
    },
  })

export const requestNewConnectionFailure = (state, { error }) =>
  state.merge({
    newConnectionRequest: {
      ...state.newConnectionRequest,
      processing: false,
      connectionCreated: false,
      error,
    },
  })

export const resetRequestNewConnection = (state) =>
  state.merge({
    newConnectionRequest: {
      ...state.newConnectionRequest,
      processing: false,
      connectionCreated: false,
      error: undefined,
    },
  })

export const removeConnection = (state, { provider }) =>
  state.merge({
    removeConnection: {
      ...state.removeConnection,
      provider,
      processing: true,
      error: undefined,
    },
  })

export const removeConnectionSuccess = (state) =>
  state.merge({
    removeConnection: {
      ...state.removeConnection,
      processing: false,
      error: undefined,
    },
  })

export const removeConnectionFailure = (state, { error }) =>
  state.merge({
    removeConnection: {
      ...state.removeConnection,
      processing: false,
      error,
    },
  })

export const getChallenges = (state) =>
  state.merge({
    challenges: {
      ...state.challenges,
      loading: true,
      retry: false,
    },
  })

export const getChallengesSuccess = (state, { joined, newAndUpcoming }) =>
  state.merge({
    challenges: {
      loading: false,
      retry: false,
      joined,
      newAndUpcoming,
    },
  })

export const getChallengesFailure = (state) =>
  state.merge({
    challenges: {
      loading: false,
      retry: true,
    },
  })

export const getMyCompletedChallenges = (state) =>
  state.merge({
    myCompletedChallenges: {
      ...state.completedChallenges,
      loading: true,
      retry: false,
    },
  })

export const getMyCompletedChallengesSuccess = (state, { completed }) =>
  state.merge({
    myCompletedChallenges: {
      loading: false,
      retry: false,
      completed,
    },
  })

export const getMyCompletedChallengesFailure = (state) =>
  state.merge({
    myCompletedChallenges: {
      loading: false,
      retry: true,
    },
  })

export const getSingleChallenge = (state, { id }) =>
  state.merge({
    selectedChallenge: {
      loading: true,
      retry: false,
      challenge:
        state.selectedChallenge?.challenge?.id === id ? state.selectedChallenge.challenge : null,
    },
  })

export const getSingleChallengeSuccess = (state, { challenge }) =>
  state.merge({
    selectedChallenge: {
      loading: false,
      retry: false,
      challenge,
    },
  })

export const getSingleChallengeFailure = (state) =>
  state.merge({
    selectedChallenge: {
      loading: false,
      retry: true,
      challenge: null,
    },
  })

export const joinChallenge = (state, { id }) =>
  state.merge({
    joinChallenge: {
      loading: true,
      retry: false,
      success: false,
      id,
    },
  })

export const joinChallengeSuccess = (state) =>
  state.merge({
    joinChallenge: {
      loading: false,
      success: true,
      retry: false,
    },
  })

export const joinChallengeFailure = (state) =>
  state.merge({
    joinChallenge: {
      loading: false,
      success: false,
      retry: true,
    },
  })

export const resetJoinChallenge = (state) =>
  state.merge({
    joinChallenge: {
      loading: false,
      success: false,
      retry: false,
    },
  })

export const getChallengeLeaderboard = (state, { challengeId }) =>
  state.merge({
    leaderboard: {
      loading: true,
      retry: false,
      challengeId,
      data: challengeId !== state.leaderboard.challengeId ? null : state.leaderboard.data,
    },
  })

export const getChallengeLeaderboardSuccess = (state, { data }) =>
  state.merge(
    {
      leaderboard: {
        loading: false,
        retry: false,
        data,
      },
    },
    { deep: true }
  )

export const getChallengeLeaderboardFailure = (state) =>
  state.merge(
    {
      leaderboard: {
        loading: false,
        retry: true,
      },
    },
    { deep: true }
  )

export const leaveChallenge = (state, { id }) =>
  state.merge({
    leaveChallenge: {
      loading: true,
      retry: false,
      success: false,
      id,
    },
  })

export const leaveChallengeSuccess = (state) =>
  state.merge({
    leaveChallenge: {
      loading: false,
      success: true,
      retry: false,
    },
  })

export const leaveChallengeFailure = (state) =>
  state.merge({
    leaveChallenge: {
      loading: false,
      success: false,
      retry: true,
    },
  })

export const resetLeaveChallenge = (state) =>
  state.merge({
    leaveChallenge: {
      loading: false,
      success: false,
      retry: false,
    },
  })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SERVICE_LOGIN_SUCCESS]: serviceLoginSuccess,
  [Types.SERVICE_LOGIN_FAILURE]: serviceLoginFailure,

  [Types.GET_ACTIVE_MINUTES]: getActiveMinutes,
  [Types.GET_ACTIVE_MINUTES_SUCCESS]: getActiveMinutesSuccess,
  [Types.GET_ACTIVE_MINUTES_FAILURE]: getActiveMinutesFailure,

  [Types.GET_HISTORY]: getHistory,
  [Types.GET_HISTORY_SUCCESS]: getHistorySuccess,
  [Types.GET_HISTORY_FAILURE]: getHistoryFailure,

  [Types.UPDATE_WEEKLY_TARGET]: updateWeeklyTarget,
  [Types.UPDATE_WEEKLY_TARGET_SUCCESS]: updateWeeklyTargetSuccess,
  [Types.UPDATE_WEEKLY_TARGET_FAILURE]: updateWeeklyTargetFailure,
  [Types.RESET_UPDATE_WEEKLY_TARGET]: resetUpdateWeeklyTarget,

  [Types.SET_CONFETTI_SHOWN]: setConfettiShown,
  [Types.SET_CONFETTI_SHOWN_SUCCESS]: setConfettiShownSuccess,
  [Types.SET_CONFETTI_SHOWN_FAILURE]: setConfettiShownFailure,

  [Types.GET_CONNECTED_TRACKERS]: getConnectedTrackers,
  [Types.GET_CONNECTED_TRACKERS_SUCCESS]: getConnectedTrackersSuccess,
  [Types.GET_CONNECTED_TRACKERS_FAILURE]: getConnectedTrackersFailure,

  [Types.REQUEST_NEW_CONNECTION]: requestNewConnection,
  [Types.REQUEST_NEW_CONNECTION_SUCCESS]: requestNewConnectionSuccess,
  [Types.REQUEST_NEW_CONNECTION_FAILURE]: requestNewConnectionFailure,
  [Types.RESET_REQUEST_NEW_CONNECTION]: resetRequestNewConnection,

  [Types.REMOVE_CONNECTION]: removeConnection,
  [Types.REMOVE_CONNECTION_SUCCESS]: removeConnectionSuccess,
  [Types.REMOVE_CONNECTION_FAILURE]: removeConnectionFailure,

  [Types.GET_CHALLENGES]: getChallenges,
  [Types.GET_CHALLENGES_SUCCESS]: getChallengesSuccess,
  [Types.GET_CHALLENGES_FAILURE]: getChallengesFailure,

  [Types.GET_MY_COMPLETED_CHALLENGES]: getMyCompletedChallenges,
  [Types.GET_MY_COMPLETED_CHALLENGES_SUCCESS]: getMyCompletedChallengesSuccess,
  [Types.GET_MY_COMPLETED_CHALLENGES_FAILURE]: getMyCompletedChallengesFailure,

  [Types.GET_SINGLE_CHALLENGE]: getSingleChallenge,
  [Types.GET_SINGLE_CHALLENGE_SUCCESS]: getSingleChallengeSuccess,
  [Types.GET_SINGLE_CHALLENGE_FAILURE]: getSingleChallengeFailure,

  [Types.JOIN_CHALLENGE]: joinChallenge,
  [Types.JOIN_CHALLENGE_SUCCESS]: joinChallengeSuccess,
  [Types.JOIN_CHALLENGE_FAILURE]: joinChallengeFailure,
  [Types.RESET_JOIN_CHALLENGE]: resetJoinChallenge,

  [Types.GET_CHALLENGE_LEADERBOARD]: getChallengeLeaderboard,
  [Types.GET_CHALLENGE_LEADERBOARD_SUCCESS]: getChallengeLeaderboardSuccess,
  [Types.GET_CHALLENGE_LEADERBOARD_FAILURE]: getChallengeLeaderboardFailure,

  [Types.LEAVE_CHALLENGE]: leaveChallenge,
  [Types.LEAVE_CHALLENGE_SUCCESS]: leaveChallengeSuccess,
  [Types.LEAVE_CHALLENGE_FAILURE]: leaveChallengeFailure,
  [Types.RESET_LEAVE_CHALLENGE]: resetLeaveChallenge,
})
