import React from 'react'
import { Text, View } from 'react-native'
import I18n from 'APP/Services/i18n'
import { SecondaryButton } from 'APP/Components/Buttons'
import WHOLogo from 'APP/Images/who-logo.svg'
import { Colors } from 'APP/Themes'
import Styles from './style'

const WHOBanner = ({ onCtaPress }) => (
  <View style={Styles.container} testID="WHOBanner">
    <WHOLogo fillLine={Colors.text} style={Styles.image} />
    <Text style={Styles.copy}>{I18n.t('RecentActiveHistoryScreen.whoBanner.copy')}</Text>
    <SecondaryButton style={Styles.cta} onPress={onCtaPress} analyticsName="Find out more">
      {I18n.t('RecentActiveHistoryScreen.whoBanner.cta')}
    </SecondaryButton>
  </View>
)

export default WHOBanner
