import { useState, useEffect, useMemo, useRef } from 'react'
import { Dimensions } from 'react-native'

export const useInViewPort = (el, { delay = 100 } = {}) => {
  const ref = useRef(el)
  const watcher = useRef(null)
  const [rect, setRect] = useState({
    x: 0,
    y: 0,
    top: 0,
    bottom: 0,
    width: 0,
  })

  const measure = () => {
    if (!ref.current) {
      return
    }
    ref.current.measure((x, y, width, height, pageX, pageY) => {
      setRect({
        x,
        y,
        top: pageY,
        bottom: pageY + height,
        width: pageX + width,
      })
    })
  }

  const isInViewPort = useMemo(() => {
    if (!ref.current) {
      return false
    }
    const { height, width } = Dimensions.get('window')
    return (
      rect.bottom !== 0 &&
      rect.top >= 0 &&
      rect.bottom <= height &&
      rect.width > 0 &&
      rect.width <= width
    )
  }, [rect, ref.current])

  const watchMeasure = () => {
    const intervalId = setInterval(measure, delay)
    return () => {
      clearInterval(intervalId)
    }
  }

  useEffect(() => {
    watcher.current = watchMeasure()
    return () => {
      watcher.current()
      watcher.current = null
    }
  }, [])

  const unwatch = () => {
    if (watcher.current) {
      watcher.current()
    }
  }

  return {
    ref,
    isInViewPort,
    unwatch,
  }
}
