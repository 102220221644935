import I18n from 'APP/Services/i18n'
import { decode as atob } from 'base-64'
import * as Sentry from '@sentry/react-native'
import { logDdError } from 'APP/Lib/Datadog'

export const getEnrolmentCodeErrorText = (showEnrolmentCodeError, showAuthError, error) => {
  if (showEnrolmentCodeError) {
    return I18n.t('ClaimAccessScreen.error.invalid')
  } else if (showAuthError && error) {
    const { status } = error
    switch (status) {
      case 'TIMEOUT_ERROR':
      case 'NETWORK_ERROR':
        return I18n.t('Common.error.generic')
      default:
        return I18n.t('ClaimAccessScreen.error.invalid')
    }
  }
  return null
}

export const getExpiration = (expiresIn) => Date.now() + expiresIn * 1000

export const getCustomClaims = (accessToken) => {
  const customClaimPrefix = 'https://dialogue/claims/'
  let tokenValues = {}

  try {
    if (accessToken) {
      tokenValues = JSON.parse(atob(accessToken.split('.')[1]))
    }
  } catch (e) {
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
  }

  // Example: {'https://dialogue/claims/random': 'value'} will be returned as {random: 'value'}
  // and all token values not starting with 'https://dialogue/claims/' will be omitted
  return Object.keys(tokenValues)
    .filter((key) => key.startsWith(customClaimPrefix))
    .reduce(
      (map, key) => ({
        ...map,
        [key.replace(customClaimPrefix, '')]: tokenValues[key],
      }),
      {}
    )
}
