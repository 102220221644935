import React from 'react'
import { View } from 'react-native'
import styled from 'styled-components/native'

import Typography from 'APP/Converse/Typography'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import { ResponsiveView } from 'APP/Converse/Layout'

import { Colors } from 'APP/Themes'
import I18n from 'APP/Services/i18n'

export const StyledFullwidthWrapper = styled(View)`
  z-index: 2;
  border-bottom-color: ${Colors.elementsBorder};
  border-bottom-width: 1px;
  margin-top: ${({ theme: { metrics } }) => `${metrics.baseMargin}px`};
`

export const StyledResponsiveViewContainer = styled(ResponsiveView)`
  flex-direction: row;
  padding-left: ${({ theme: { metrics } }) => `${metrics.baseMargin * 1.5}px`};
  padding-right: ${({ theme: { isSmallScreen, metrics } }) =>
    isSmallScreen ? `${metrics.baseMargin * 1.5}px` : '0px'};
  column-gap: ${({ theme: { metrics } }) => metrics.baseMargin}px;
`

export const StyledDialogueTouchableOpacityTab = styled(DialogueTouchableOpacity)`
  min-width: 48px;
`

export const StyledTypographyTabText = styled(Typography)`
  color: ${({ isActive }) => (isActive ? Colors.text : Colors.tabUnselected)};
  text-align: center;
  padding-bottom: ${({ theme: { metrics } }) => metrics.baseMargin / 2}px;
`

export const StyledViewTextWrapper = styled(View)<HeaderTabProps>`
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  ${({ isActive }) => {
    return (
      isActive &&
      `
      border-bottom-width: 3px;
      border-bottom-color: ${Colors.accent};
      `
    )
  }}
`

export const StyledViewNewContentIndicator = styled(View)`
  position: relative;
  top: -5px;
  left: 3px;
  width: 8px;
  height: 8px;
  text-align: center;
  border-radius: 8px;
  background-color: ${Colors.errorSnackBarBg};
`

interface HeaderTab {
  name: string
  title: string
  extraProps: {
    newContent: boolean
  }
}

export interface HeaderTabProps {
  tab: HeaderTab
  onPress: (tabName: string) => void
  isActive: boolean
}

export interface HeaderTabsProps {
  tabs: HeaderTab[]
  onPress: (tabName: string) => void
  activeTabName: string
}

const HeaderTab = ({ tab, onPress, isActive }: HeaderTabProps) => {
  // Local override for SL:US - Get Care -> Connect DIA-72211
  const tabLabel = I18n.exists(`NavigationNameOverwrite.${tab.name}`)
    ? I18n.t(`NavigationNameOverwrite.${tab.name}`)
    : tab.title
  return (
    <StyledDialogueTouchableOpacityTab
      onPress={() => onPress(tab.name)}
      testID={`tab-${tab.name}`}
      analyticsName={tab.title}
    >
      <StyledViewTextWrapper isActive={isActive} testID={`${isActive && 'highlightedTab'}`}>
        <StyledTypographyTabText
          variant={isActive ? 'bodyLargeBold' : 'bodyLarge'}
          isActive={isActive}
        >
          {tabLabel}
        </StyledTypographyTabText>
        {Boolean(tab?.extraProps?.newContent) && (
          <StyledViewNewContentIndicator testID="TAB_NEW_CONTENT" />
        )}
      </StyledViewTextWrapper>
    </StyledDialogueTouchableOpacityTab>
  )
}

// Render the tabs if there's more than one, but still render the parent regardless of tab count for the divider
const HeaderTabs = ({ tabs, onPress, activeTabName }: HeaderTabsProps) => {
  if (!tabs) return null

  return (
    // Outer wrapper draws the full width divider, inner wrapper provides consistent responsive padding
    <StyledFullwidthWrapper>
      <StyledResponsiveViewContainer>
        {tabs.length > 1 &&
          tabs.map((tab) => (
            <HeaderTab
              key={tab.name}
              tab={tab}
              onPress={onPress}
              isActive={activeTabName === tab.name}
            />
          ))}
      </StyledResponsiveViewContainer>
    </StyledFullwidthWrapper>
  )
}

export default HeaderTabs
