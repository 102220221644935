import deepmerge from 'deepmerge'
import { Appearance } from 'react-native-appearance'
import merge from 'deepmerge'
import { brandVersionedI18nFiles, brandVersionedThemeJsons, brandVersionedThemeFiles, brandVersionConfigFiles } from './brandVersionedFiles'

export function getBrandVersionedI18n(language: string, baseI18n: Record<string, unknown>, brandVersion?: string){
    let versionedI18n = {}
  
    if (brandVersion) {
      try {
        versionedI18n = brandVersionedI18nFiles(`./${language}_${brandVersion}.json`)
      } catch (e) {
        // Do nothing
      }
  
      if (!versionedI18n) {
        versionedI18n = {}
  
        console.warn(`Brand versioned i18n file for ${language} not found. Using default translations.`)
      }
    }
  
    return deepmerge(baseI18n, versionedI18n)
  }

  function getColorValues(data: Record<string, unknown>, ColorLibrary: Record<string, unknown>) {  
      return Object.entries(data).reduce((acc,[key, value]) => {
        acc[key] = ColorLibrary[value]
      return acc
      }, {} as Record<string, unknown>)
    }

  export function getBrandVersionedColors(baseColors = {}, darkColors = {}, ColorLibrary: Record<string, unknown>, brandVersion?: string, ) {
    const colorScheme = Appearance.getColorScheme()
    const colors: Record<string, unknown> = 
      colorScheme === 'dark' ? deepmerge(baseColors, darkColors) : baseColors
    let brandVersionColorsBase = {}

    if (!brandVersion) {
      return colors
    } else {
      try {
        const versionedColorFile = brandVersionedThemeJsons(`./ColorsBase_${brandVersion}.json`)
        brandVersionColorsBase = getColorValues(versionedColorFile, ColorLibrary) || {}

        if (colorScheme === 'dark') {
          let brandVersionColorsDark = {}
          const darkVersionedColorFile = brandVersionedThemeJsons(`./ColorsDark_${brandVersion}.json`)
          brandVersionColorsDark = getColorValues(darkVersionedColorFile, ColorLibrary) || {}
          brandVersionColorsBase = deepmerge(brandVersionColorsBase, brandVersionColorsDark)
        }
      } catch (e) {
        // Do nothing
      }
        if (Object.keys(brandVersionColorsBase).length === 0) {
          console.warn(`Brand versioned colors file for ${brandVersion} not found. Using default colors.`)
      }
    }

    return deepmerge(colors, brandVersionColorsBase)
  }

  export function getBrandVersionedImages(baseImages = {}, darkImages = {}, brandVersion?: string) {
    const images: Record<string, unknown> =
      Appearance.getColorScheme() === 'dark' ? deepmerge(baseImages, darkImages) : baseImages
    let brandVersionImages = {}
  
    if (brandVersion) {
      try {
        const versionedImageFile = brandVersionedThemeFiles(`./${brandVersion}.js`)
  
        brandVersionImages = versionedImageFile?.Images || {}
      } catch (e) {
        console.log(
          `Versioned images: ${brandVersion} expected but not found. Defaulting to base version images`
        )
      }
    }
  
    return deepmerge(images, brandVersionImages)
  }

  export function getBaseConfig(initConfig: Record<string, unknown>) {
    // eslint-disable-next-line
      let outputConfig = { ...initConfig }
  
    /*
      Merge in branded config.json if exists
    */
    if (outputConfig.BRAND_VERSION) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const brandedConfig = brandVersionConfigFiles(`./config_${outputConfig.BRAND_VERSION}.json`)
  
        outputConfig = mergeWithBase(outputConfig, brandedConfig)
      } catch (e) {
        console.warn(`Unable to find branded configuration file '${outputConfig.BRAND_VERSION}'`)
      }
    }
  
    return outputConfig
  }

  export function mergeWithBase(base = {}, patch = {}) {
    return merge(base, patch, {
      arrayMerge: (destinationArray, sourceArray) => sourceArray,
    })
  }
