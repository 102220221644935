import { MattermostQuestion, SilkroadQuestion, SilkroadAnswer } from './types'

/* eslint-disable-next-line */
export const isMatterMostPost = (post: any = {}): post is MattermostQuestion =>
  post.user_id !== undefined && post.create_at !== undefined && post.update_at !== undefined

export const convertAnswerToSilkroadFormat = (
  question: SilkroadQuestion,
  answer: string,
  display: string
): SilkroadAnswer => {
  return {
    type: 'answer',
    question_type: 'choice',
    payload: [answer],
    display,
    local_id: question.local_id,
    answer_flags: {
      update_channel_state: false,
    },
  }
}
