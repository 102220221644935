import apisauce from 'apisauce'
import Config from '../Config'

const create = (loginStore, baseURL = Config.ENGAGE_DOMAIN) => {
  const authToken = loginStore.accessToken
  const api = apisauce.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    timeout: 20000,
  })

  const normalizePushPreferences = (rawData) => {
    return {
      brandNewContent: rawData.brand_new_content,
      challengeUpdates: rawData.challenge_updates,
      habitReminders: rawData.habit_reminders,
      importantAnnouncements: rawData.important_announcements,
      wbiReminders: rawData.wbi_reminders,
      intakeReminders: rawData.intake_reminders,
    }
  }

  const normalizeSMSPreferences = (rawData) => {
    return {
      appointmentReminders: rawData.appointment_reminders,
    }
  }

  // WILL BE DEPRECATED
  const getPreferences = () => {
    return api.get('/preferences/get').then((response) => {
      if (response.ok) {
        return normalizePushPreferences(response.data)
      } else {
        throw (
          response.data.errors[0] || {
            status: response.status,
            detail: 'Something went wrong with Engage',
          }
        )
      }
    })
  }

  // WILL BE DEPRECATED
  const updatePreferences = (preferences) => {
    return api.put('/preferences/put', preferences).then((response) => {
      if (response.ok) {
        return response.ok
      }
      throw (
        response.data.errors[0] || {
          status: response.status,
          detail: 'Something went wrong with Engage',
        }
      )
    })
  }

  const getSMSPreferences = (userId, newNotificationCenter) => {
    return api.get(`/users/${userId}/preferences/sms`).then((response) => {
      if (response.ok) {
        if (newNotificationCenter) {
          return {
            sms: normalizeSMSPreferences(response.data),
          }
        }
        return normalizeSMSPreferences(response.data)
      }
      throw (
        response?.data?.errors?.[0] || {
          status: response.status,
          detail: 'Something went wrong with Engage',
        }
      )
    })
  }

  const newUpdatePreferences = (userId, preferences) => {
    return api.patch(`/users/${userId}/preferences`, preferences).then((response) => {
      if (response.ok) {
        return response.ok
      }
      throw (
        response?.data?.errors?.[0] || {
          status: response.status,
          detail: 'Something went wrong with Engage',
        }
      )
    })
  }

  const getPushPreferences = (userId) => {
    return api.get(`/users/${userId}/preferences/push`).then((response) => {
      if (response.ok) {
        return {
          push: normalizePushPreferences(response.data),
        }
      }
      throw (
        response?.data?.errors?.[0] || {
          status: response.status,
          detail: 'Something went wrong with Engage',
        }
      )
    })
  }

  return {
    getPreferences,
    updatePreferences,
    getSMSPreferences,
    newUpdatePreferences,
    normalizeSMSPreferences,
    getPushPreferences,
    normalizePushPreferences,
  }
}

export default {
  create,
}
