import React, { useLayoutEffect, useEffect, useMemo } from 'react'
import { View, ScrollView, ActivityIndicator, Image, Text } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import HabitActions from 'APP/Redux/HabitsRedux'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import { Habits } from '@dialogue/services'
import { PrimaryButton } from 'APP/Components/Buttons'
import Toast from 'react-native-toast-message'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import DialogueMarkdown from 'APP/Components/DialogueMarkdown'
import ButtonShare from 'APP/Components/ButtonShare'
import { useShareDeepLinkFromConfig } from 'APP/Hooks/Share'
import I18n from 'APP/Services/i18n'
import Analytics from 'APP/Services/Analytics'
import { Colors, Metrics, Images } from 'APP/Themes'
import Styles from './style'
import FactCheckedIcon from 'APP/Images/fact-checked.svg'
import sprigClient from 'APP/Services/Sprig'

const HabitDetail = (props) => {
  const { navigation, route } = props
  const { id, hideCTAs = false } = { ...route?.params, ...props }
  const analyticsName = route?.name

  const dispatch = useDispatch()

  const { loading, retry, data } = useSelector((state) => state.habits.habit)
  const startHabitState = useSelector((state) => state.habits.startHabit)
  const habitContentState = useSelector((state) => state.habits.habitContent[id])

  const shareHabitDetails = useShareDeepLinkFromConfig(
    'HABIT_DETAILS_SCREEN_DEEP_LINK_URL',
    {
      services: 'wellness_habits',
      id,
      share: true,
    },
    { analyticsName }
  )

  useEffect(() => {
    if (id) {
      getHabit()
      getHabitContent()
    }
  }, [id])

  useEffect(() => {
    if (data?.identifier) {
      const addedState = data.state === Habits.Habits.Types.State.Ongoing ? 'Added' : 'Not added'
      Analytics.trackScreen(
        `habitDetailScreen: ${id} : ${data.title[I18n.baseLocale]} : ${addedState}`
      )
    }
  }, [id, data])
  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <ButtonShare
          analyticsName={analyticsName}
          onPress={() => shareHabitDetails.share()}
          testID="shareHabitDetailsBtn"
        />
      ),
    })
  }, [analyticsName, shareHabitDetails, navigation])

  const setEditActions = () =>
    dispatch(
      HabitActions.setHabitListDeferredActions(true, {
        id,
        showModifyModal: true,
      })
    )
  const getHabit = () => dispatch(HabitActions.getSingleHabit(id))
  const getHabitContent = () => dispatch(HabitActions.getHabitContent(id))

  const startHabit = () => {
    const hasSupportingContent = habitContentState?.data?.relatedContent?.length > 0

    Analytics.trackEvent('button_click', {
      button_value: `addHabit: ${id} : ${data.title[I18n.baseLocale]}`,
      trigger: analyticsName,
    })
    sprigClient.track('HABIT_ADDED')
    dispatch(HabitActions.startHabit(id, hasSupportingContent))
  }
  const resetStartHabit = () => dispatch(HabitActions.resetStartHabit())

  const editHabit = () => {
    setEditActions()
    navigateToWellnessCenterHabits()
  }

  /**
   * Usag type: 'reset' to replace & unmount the current screen
   * with a tabbar
   */
  const navigateToWellnessCenterHabits = () => {
    navigation.goBack()
    // Delay the navigation to allow the modal to close
    setTimeout(() => {
      navigation.navigate('tabbar', { screen: 'todayTab' })
    }, 300)
  }

  useEffect(() => {
    if (startHabitState.retry) {
      Toast.show({ text1: I18n.t('HabitDetailScreen.retryHabitCta') })
    }

    if (startHabitState.started) {
      navigateToWellnessCenterHabits()
    }

    return () => {
      resetStartHabit()
    }
  }, [startHabitState])

  const isHabitOngoing = useMemo(() => {
    return data?.state === Habits.Habits.Types.State.Ongoing
  }, [data])

  return (
    <View style={Styles.container}>
      {loading && (
        <ActivityIndicator
          size="large"
          color={Colors.text}
          style={Styles.loading}
          testID="loading"
        />
      )}
      {!loading && data?.identifier && (
        <ScrollView>
          <Image
            style={Styles.image}
            source={{ uri: data.image.src }}
            defaultSource={Images.contentLoading}
            resizeMode="cover"
          />
          <View style={Styles.content}>
            <Text testID="title" style={Styles.title}>
              {data.title[I18n.baseLocale]}
            </Text>
            {data.state !== Habits.Habits.Types.State.Pending &&
              I18n.exists(`ExploreHabitsScreen.habitState.${data.state}`) && (
                <View style={Styles.subheaderContainer}>
                  <MaterialIcon color={Colors.accent} size={Metrics.icons.tiny} name="done" />
                  <Text style={Styles.subheader}>
                    {I18n.t(`ExploreHabitsScreen.habitState.${data.state}`)}
                  </Text>
                </View>
              )}
            {!hideCTAs && isHabitOngoing && (
              <PrimaryButton
                title={I18n.t('HabitDetailScreen.editHabitCta')}
                onPress={editHabit}
                style={Styles.addHabitCta}
              />
            )}
            {!hideCTAs && !isHabitOngoing && (
              <PrimaryButton
                title={I18n.t('HabitDetailScreen.addHabitCta')}
                onPress={startHabit}
                disabled={startHabitState.loading}
                showActivityIndicator={startHabitState.loading}
                style={Styles.addHabitCta}
              />
            )}
            <View style={Styles.body}>
              <DialogueMarkdown markdown={data.description[I18n.baseLocale]} />
              {habitContentState?.data?.expert && (
                <View style={Styles.expert} testID="expertTile">
                  <View style={Styles.expertCaption}>
                    <FactCheckedIcon
                      fillLine={Colors.text}
                      accentFillLine={Colors.accent}
                      width={Metrics.icons.small}
                      height={Metrics.icons.small}
                    />
                    <Text style={Styles.expertCaptionText}>
                      {I18n.t('HabitDetailScreen.expertCaption')}
                    </Text>
                  </View>
                  <View style={Styles.expertContent}>
                    <Image
                      style={Styles.expertAvatar}
                      source={{ uri: habitContentState.data.expert?.profile_picture.url }}
                    />
                    <View style={Styles.expertDetails}>
                      <Text style={Styles.expertTitle}>{habitContentState.data.expert?.name}</Text>
                      <Text style={Styles.expertSubtitle}>
                        {habitContentState.data.expert?.job_title}
                      </Text>
                    </View>
                  </View>
                </View>
              )}
            </View>
          </View>
        </ScrollView>
      )}
      {!loading && retry && (
        <DialogueTouchableOpacity
          style={Styles.refresh}
          onPress={getHabit}
          analyticsName="Refresh"
          testID="retry"
        >
          <MaterialIcon name="refresh" size={Metrics.icons.large} color={Colors.text} />
        </DialogueTouchableOpacity>
      )}
    </View>
  )
}

export default HabitDetail
