import React, { useCallback, useEffect, useState } from 'react'
import I18n from 'APP/Services/i18n'

import List from 'APP/Components/List'
import Spinner from 'APP/Converse/Spinner'
import { usePlacesSearch } from 'APP/Hooks/PlacesSearch'
import ListItem from 'APP/Converse/ListItem'

import Typography from 'APP/Converse/Typography'
import SearchCard from 'APP/Converse/SearchCard'
import styled from 'styled-components/native'

const NoResultContainer = styled.View`
  padding: ${({ theme }) => theme.metrics.baseMargin}px;
`

const AddressSearchCard = ({
  title,
  disabled,
  placeholder,
  type,
  onSubmitCallback,
  style,
  ctaOnCancel,
  prefilledValue,
}) => {
  const [{ running, results, error }, submitQuery, resolvePlace] = usePlacesSearch({
    type: type,
  })
  const [selectedPlaceId, setSelectedPlaceId] = useState('')
  const [searchTerm, setSearchTerm] = useState('')

  const isLoading = running && results.length === 0
  const shouldShowNoResultsText = !running && searchTerm && results.length === 0

  const onTextChange = useCallback(
    (e) => {
      setSearchTerm(e)
    },
    [setSearchTerm]
  )

  useEffect(() => {
    if (prefilledValue) setSearchTerm(prefilledValue)
  }, [prefilledValue, setSearchTerm])

  useEffect(() => {
    submitQuery(searchTerm.trim())
  }, [searchTerm, submitQuery])

  const toggleSelectedPlaceId = (placeId) => {
    if (placeId === selectedPlaceId) {
      setSelectedPlaceId('')
    } else {
      setSelectedPlaceId(placeId)
    }
  }

  const mapListItems = (place, i) => {
    const {
      structured_formatting: { main_text, secondary_text },
      place_id,
    } = place

    const isSelected = selectedPlaceId === place_id

    return (
      <ListItem
        selected={isSelected}
        testID={`address-search-card-list-item-${i}`}
        key={i}
        title={main_text}
        subtitle={secondary_text}
        hideIconComponent={!isSelected}
        onPress={() => toggleSelectedPlaceId(place_id)}
        iconName="check"
      />
    )
  }

  const onClearSearchButtonPress = useCallback(() => {
    setSearchTerm('')
  }, [])

  const getOnAddressSubmit = (placeID) => {
    if (!disabled) {
      resolvePlace(placeID).then((resolvedPlace) => {
        onSubmitCallback(resolvedPlace)
      })
    }
  }

  const Body =
    shouldShowNoResultsText || error || isLoading ? (
      <NoResultContainer>
        {shouldShowNoResultsText && (
          <Typography variant="bodyLarge">
            {I18n.t('AddressSearchCard.noResults', { query: searchTerm })}
          </Typography>
        )}
        {error && <Typography variant="bodyLarge">{I18n.t('AddressSearchCard.error')}</Typography>}
        {isLoading && <Spinner />}
      </NoResultContainer>
    ) : (
      <List>{results.map(mapListItems)}</List>
    )

  return (
    <SearchCard
      ctaOnCancel={ctaOnCancel}
      style={style}
      title={title}
      placeholder={placeholder}
      searchTerm={searchTerm}
      onTextChange={onTextChange}
      onClearSearchButtonPress={onClearSearchButtonPress}
      disabled={disabled}
      running={running}
      content={Body}
      ctaTitle={I18n.t('AddressSearchCard.confirm')}
      ctaOnPress={() => getOnAddressSubmit(selectedPlaceId)}
      ctaDisabled={!selectedPlaceId}
    />
  )
}

export default AddressSearchCard
