import React, { useCallback, useMemo } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/native'

import Typography from 'APP/Converse/Typography'
import I18n from 'APP/Services/i18n'
import { Colors, Metrics } from 'APP/Themes'
import { selectSelectedDay } from 'APP/Store/ProviderBooking/selectors'
import { providerBookingActions, DATE_FORMAT } from 'APP/Store/ProviderBooking'

import { createDays } from '../utils/helpers'
import { DateTimeInfo } from '../utils/types'
import Analytics from 'APP/Services/Analytics'

const Wrapper = styled.View`
  padding: ${Metrics.baseMargin * 1.5}px 0;
  background-color: ${({ theme }) => theme.colors.practitionerMsgBg};
`

const StyledScrollView = styled.ScrollView.attrs({
  contentContainerStyle: {
    paddingTop: 0,
    paddingHorizontal: Metrics.baseMargin * 1.5,
    paddingBottom: 0,
  },
})``

const Header = styled(Typography)`
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: ${({ theme }) => `${theme.metrics.baseMargin}px`};
`

const StyledPressable = styled.Pressable<{ selected: boolean; hasAvailability: boolean }>`
  background-color: ${({ selected, hasAvailability }) =>
    selected && hasAvailability ? Colors.buttonPrimary : 'transparent'};
  border-radius: 21px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  margin-left: 4px;
  opacity: ${({ hasAvailability }) => (hasAvailability ? 1 : 0.5)};
  pointer-events: ${({ hasAvailability }) => (hasAvailability ? 'auto' : 'none')};
`

const getColor = (selected: boolean, hasAvailability: boolean) => {
  if (!hasAvailability) return Colors.buttonDisabled
  if (selected) return Colors.buttonSecondarySolid
  return Colors.text
}

const DayTypography = styled(Typography)<{ selected: boolean; hasAvailability: boolean }>`
  color: ${({ selected, hasAvailability }) => getColor(selected, hasAvailability)};
  margin-top: 2px;
`

interface Props {
  dateFrom: string
  dateUntil: string
  availabilities: DateTimeInfo[]
}

export const DayPicker = ({ dateFrom, dateUntil, availabilities }: Props) => {
  const dispatch = useDispatch()
  const selectedDay = useSelector(selectSelectedDay)

  const calendarFormat = I18n.t('AppointmentBooking.timeslot_formats', { returnObjects: true })

  const handleSelectDay = useCallback(
    (day: string) => {
      dispatch(providerBookingActions.selectDay(day))
    },
    [dispatch]
  )

  const onSelectDay = (hasAvailability: boolean, formattedDay: string) => {
    if (hasAvailability) {
      handleSelectDay(formattedDay)
    }
    Analytics.trackEvent('button_click', {
      button_value: 'calendar_date',
    })
  }

  const selectedDate = moment(selectedDay, DATE_FORMAT)
  const days = useMemo(() => createDays(moment(dateFrom), moment(dateUntil)), [dateFrom, dateUntil])

  const datesWithAvailability = new Set(
    availabilities.map((slot: DateTimeInfo) => moment(slot.start_date).format(DATE_FORMAT))
  )

  return (
    <Wrapper>
      <Header variant="bodyNormalBold" align="center">
        {selectedDate.calendar(calendarFormat)}
      </Header>
      <StyledScrollView horizontal centerContent>
        {days.map((day) => {
          const formattedDay = day.format(DATE_FORMAT)
          const selected = day.isSame(selectedDate, 'day')
          const hasAvailability = datesWithAvailability.has(formattedDay)

          return (
            <StyledPressable
              key={formattedDay}
              selected={selected}
              hasAvailability={hasAvailability}
              onPress={() => onSelectDay(hasAvailability, formattedDay)}
            >
              <DayTypography
                variant="bodyNormalBold"
                align="center"
                selected={selected}
                hasAvailability={hasAvailability}
              >
                {day.format('DD')}
              </DayTypography>
              <DayTypography
                variant="caption"
                align="center"
                selected={selected}
                hasAvailability={hasAvailability}
              >
                {day.format('ddd').toUpperCase()}
              </DayTypography>
            </StyledPressable>
          )
        })}
      </StyledScrollView>
    </Wrapper>
  )
}
