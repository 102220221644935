import React, { useMemo, useCallback } from 'react'
import { Image, StyleSheet, View } from 'react-native'
import { useNavigation, CommonActions } from '@react-navigation/native'
import Config from 'APP/Config'

// Components
import { MenuNavList } from './MenuNavList'

// Styles
import { Colors, Images, Metrics } from 'APP/Themes'

const Styles = StyleSheet.create({
  menuContainer: {
    position: 'relative',
    flex: 1,
  },
  expandingSpace: {
    flexGrow: 1,
  },
  divider: {
    height: 0,
    borderBottomWidth: 1,
    borderBottomColor: Colors.separateLine,
    marginHorizontal: Metrics.baseMargin,
  },
  logoContainer: {
    width: 'auto',
    margin: Metrics.baseMargin,
    marginTop: Metrics.baseMargin * 1.5,
    marginBottom: Metrics.baseMargin * 1.5,
  },
  logo: {
    height: Config.LOGO_HEIGHT || 30,
    width: '100%',
  },
  navList: {
    flexDirection: 'column',
  },
})

const stackRouteToName = (route) => route?.state?.routes[route?.state?.index]?.name || route?.name

const getSelectedMenuItemName = ({ stackIndex, stackRoutes, menuRouteNames }) => {
  const afterMenuRoute = stackRoutes[stackIndex + 1]
  const afterMenuRouteIsInMenu =
    afterMenuRoute && menuRouteNames.indexOf(afterMenuRoute?.name) !== -1
  const selectedRoute = afterMenuRouteIsInMenu ? afterMenuRoute : stackRoutes[stackIndex]
  return stackRouteToName(selectedRoute)
}

const resetToMenuRouteWhenNotTopOfStack = async ({ stackIndex, stackRoutes, navigation }) => {
  const lastRouteIndex = stackRoutes?.length - 1
  if (stackIndex !== lastRouteIndex) {
    await navigation.dispatch(
      CommonActions.reset({
        index: stackIndex,
        routes: stackRoutes.slice(0, stackIndex + 1),
      })
    )
  }
}

const handleNavigate = async ({ name, stackIndex, stackRoutes, navigation }) => {
  await resetToMenuRouteWhenNotTopOfStack({ stackIndex, stackRoutes, navigation })
  await navigation.dispatch(CommonActions.navigate(name))
}

const getRouteNamesFromMenuChildren = (children) =>
  children
    .flatMap(({ props }) => props?.items)
    .filter((items) => !!items)
    .map(({ name }) => name)

export const Menu = ({ children = [], stackIndex, stackRoutes, testID, ...rest }) => {
  const navigation = useNavigation()
  const menuChildren = React.Children.toArray(children)
  const menuRouteNames = useMemo(() => getRouteNamesFromMenuChildren(menuChildren), [menuChildren])
  const selectedItemName = useMemo(
    () => getSelectedMenuItemName({ stackIndex, stackRoutes, menuRouteNames }),
    [stackIndex, stackRoutes, menuRouteNames]
  )
  const onItemPress = useCallback(
    (name) => handleNavigate({ name, stackIndex, navigation, stackRoutes }),
    [stackIndex, stackRoutes, navigation]
  )
  return (
    <View style={Styles.menuContainer} testID={testID}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { ...rest, selectedItemName, onItemPress })
      )}
    </View>
  )
}

const Divider = (props) => <View style={Styles.divider} {...props} />

const ExpandingSpace = (props) => <View style={Styles.expandingSpace} {...props} />

const Logo = (props) => (
  <View style={Styles.logoContainer}>
    <Image style={Styles.logo} source={Images.logoLockup} resizeMode="contain" {...props} />
  </View>
)

Menu.Divider = Divider
Menu.NavList = MenuNavList
Menu.ExpandingSpace = ExpandingSpace
Menu.Logo = Logo
