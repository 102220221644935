import { StyleSheet, View } from 'react-native'
import { ApplicationStyles, Colors, Fonts, Metrics } from 'APP/Themes'
import styled from 'styled-components/native'

export const StyledWebViewWebCardContainer = styled(View)`
  padding-top: ${Metrics.baseMargin}px;
  padding-bottom: ${Metrics.baseMargin}px;
  padding-left: ${Metrics.baseMargin}px;
  background-color: ${({ theme: { colors } }) => colors.elementsBg};
  border-width: 1px;
  border-radius: ${Metrics.baseMargin / 2}px;
  margin-bottom: ${Metrics.baseMargin}px;
`

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  container: {
    padding: Metrics.baseMargin,
    margin: Metrics.baseMargin,
  },
  webInnerContainer: {
    textAlign: 'center',
    paddingTop: Metrics.baseMargin,
    paddingHorizontal: Metrics.baseMargin,
    gap: Metrics.baseMargin,
  },
  inputStyles: {
    borderWidth: 1,
    backgroundColor: Colors.elementsBg,
    borderColor: Colors.elementsBorder,
    borderRadius: Metrics.baseMargin / 2,
    fontSize: Fonts.size.regular,
    placeholderColor: Colors.chatPlaceholderText,
  },
  cardField: {
    height: Metrics.section * 2,
    marginBottom: Metrics.baseMargin,
  },
  primaryText: {
    ...Fonts.style.body,
    color: Colors.text,
    padding: Metrics.baseMargin,
    textAlign: 'center',
  },
  cardFieldContainer: {
    alignSelf: 'center',
    marginTop: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin,
  },
  fullWidthButton: {
    alignSelf: 'stretch',
  },
  stripeInput: {
    ...Fonts.style.body,
    color: Colors.text,
    borderWidth: 1,
    backgroundColor: Colors.elementsBg,
    borderColor: Colors.elementsBorder,
    borderRadius: Metrics.baseMargin / 2,
    placeholderColor: Colors.chatPlaceholderText,
    width: '90%',
    height: Metrics.section * 2,
    paddingLeft: Metrics.baseMargin / 2,
  },
  margin: {
    marginBottom: Metrics.baseMargin,
  },
  formInputErrorText: {
    ...ApplicationStyles.screen.formInputErrorText,
    width: '90%',
    marginBottom: Metrics.baseMargin,
  },

  // Web
  webCardElement: {
    base: {
      color: Colors.text,
      fontFamily: Fonts.type.light,
      '::placeholder': {
        color: Colors.textSecondary,
      },
    },
    invalid: {
      color: Colors.text,
    },
  },
  webCardElementError: {
    style: {
      base: {
        color: Colors.errorText,
        '::placeholder': {
          color: Colors.textSecondary,
        },
      },
      invalid: {
        color: Colors.errorText,
      },
    },
  },
})
