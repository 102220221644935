import React from 'react'
import { useSelector } from 'react-redux'
import Moment from 'moment'
import I18n from 'APP/Services/i18n'

// Components
import List from 'APP/Components/List'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import { Format } from 'APP/Lib/ProfileDataFormat'
import ListItem from 'APP/Converse/ListItem'
import { ResponsiveScrollView } from 'APP/Converse/Layout'

// Styling
import Styles from './style'

const DependentProfileScreen = ({ navigation, route }) => {
  const family = useSelector((state) => state.family.members)
  const childId = route?.params?.childId
  const childProfile = family[childId]

  const handlePhotoIdUpdate = () => {
    navigation.navigate('updateDependentPhotoId', { childProfile })
    return true
  }

  return (
    <NoticeBarContainer>
      <ResponsiveScrollView alwaysBounceVertical={false}>
        <List containerStyle={Styles.listContainer}>
          <ListItem
            testID="DEPENDENT_PROFILE_SCREEN_FIRST_NAME_INPUT"
            key="givenName"
            title={I18n.t('FamilyScreen.DependentProfileScreen.givenName')}
            rightTitle={childProfile.givenName}
            hideIconComponent
          />
          <ListItem
            testID="DEPENDENT_PROFILE_SCREEN_LAST_NAME_INPUT"
            key="familyName"
            title={I18n.t('FamilyScreen.DependentProfileScreen.familyName')}
            rightTitle={childProfile.familyName}
            hideIconComponent
          />
          <ListItem
            testID="DEPENDENT_PROFILE_SCREEN_BIRTHDAY_PICKER"
            key="bday"
            title={I18n.t('FamilyScreen.DependentProfileScreen.bday')}
            rightTitle={Moment(childProfile.birthdate).format(Format.DISPLAY_DATE)}
            hideIconComponent
          />
          <ListItem
            testID="DEPENDENT_PROFILE_SCREEN_PHOTO_ID"
            key="photoID"
            title={I18n.t('ProfileScreen.photoID')}
            rightTitle={
              childProfile.healthcardUrl
                ? I18n.t('ProfileScreen.photoIDOnFile')
                : I18n.t('ProfileScreen.photoIDMissing')
            }
            onPress={handlePhotoIdUpdate}
          />
        </List>
      </ResponsiveScrollView>
    </NoticeBarContainer>
  )
}

export default DependentProfileScreen
