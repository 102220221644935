import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Metrics, Fonts } from 'APP/Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  subtitle: {
    ...Fonts.style.body,
    lineHeight: 21,
    color: Colors.text,
    textAlign: 'left',
    marginBottom: Metrics.baseMargin,
  },
  bold: {
    fontFamily: Fonts.type.bold,
    fontWeight: 'bold',
  },
  loadingContainer: {
    backgroundColor: Colors.appBg,
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    minHeight: '100%',
  },
  loadingText: {
    ...Fonts.style.h2,
    color: Colors.text,
    alignItems: 'baseline',
    textAlign: 'center',
  },
  spinner: {
    marginVertical: Metrics.baseMargin * 5,
  },
})
