import React from 'react'
import { View, Linking, Text, Image, Platform } from 'react-native'
import { connect } from 'react-redux'
import * as Sentry from '@sentry/react-native'

import I18n from 'APP/Services/i18n'

import Config from 'APP/Config'
import { PrimaryButton } from 'APP/Components/Buttons'

// Styling
import Styles from './style'
import { Images } from 'APP/Themes'
import { logDdError } from 'APP/Lib/Datadog'

class UnsupportedVersionScreen extends React.Component {
  gotoStore() {
    const store = Platform.OS === 'ios' ? Config.APP_STORE : Config.PLAY_STORE
    const url = Platform.OS === 'ios' ? Config.APP_STORE_URL : Config.PLAY_STORE_URL
    const localizedUrl = url[I18n.baseLocale] || url.en
    Linking.openURL(store)
      .catch((reason) => {
        const msg = `ProfileScreen. Error opening link to store. message: ${reason.message}, url: ${store}`
        Sentry.captureException(msg)
        logDdError(msg, 'UnsupportedVersionScreen.gotoStore')
        Linking.openURL(localizedUrl)
      })
      .catch((anotherReason) => {
        const msg = `ProfileScreen. Error opening link to HTTPS store. message: ${anotherReason.message}, url: ${localizedUrl}`
        Sentry.captureException(msg)
        logDdError(msg, 'UnsupportedVersionScreen.gotoStore')
      })
  }

  render() {
    return (
      <View style={Styles.container}>
        <Image
          source={Images.unsupportedVersionIllustration}
          style={[Styles.sickDialogue, Styles.centered]}
        />
        <Text style={Styles.title}>{I18n.t('UnsupportedVersion.title')}</Text>
        <Text style={[Styles.text]}>{I18n.t('UnsupportedVersion.message')}</Text>
        <PrimaryButton
          style={Styles.fullWidthButton}
          title={I18n.t('UnsupportedVersion.gotoStore')}
          analyticsName="UnsupportedVersion.gotoStore"
          onPress={this.gotoStore}
        />
      </View>
    )
  }
}

export default connect()(UnsupportedVersionScreen)
