import { StyleSheet } from 'react-native'
import { Colors, ApplicationStyles } from 'APP/Themes'

export const Styles = StyleSheet.create({
  ...ApplicationStyles.screen,
  container: {
    flex: 1,
    backgroundColor: Colors.appBg,
    justifyContent: 'center',
  },
  transitionContainer: {
    flex: 1,
  },
  postFailureModalText: {
    color: Colors.text,
    textAlign: 'center',
  },
})
