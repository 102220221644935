import { StackActions } from '@react-navigation/native'
import { navigationRef } from './Provider'
import { NESTED_SCREEN_INDEX } from './index'

import _ from 'lodash'

/*
  -----------------------------------------------------------------------------
  Out of box, react-navigation doesn't support navigating directly to subtabs.
  All of the functions in this file use NESTED_SCREEN_INDEX to extend the
  base functionality to support simple navigation to sub routes directly.
  -----------------------------------------------------------------------------
*/

/*
  Local support functions
  -----------------------------------------------------------------------------
*/

/*
  Given a nested route object, puts params onto the deepest node regardless of depth.

  e.g.
    input:
      _mapRouteToNested(
        { name: 'tabbar', params: {
            screen: 'tab1', params: {
              screen: 'tab1.1', params: { abc: 123 }
            }
          }
        },
        { xyz: 456 }
      )
    output:
      { name: 'tabbar', params: {
        screen: 'tab1', params: {
          screen: 'tab1.1', params: { abc: 123, xyz: 456 }
        }
      }
    }

*/
export function __mergeParamsOnToDeepestScreen(rootNode, params) {
  if (typeof params !== 'object') return rootNode
  let result = _.cloneDeep(rootNode)
  let cursor = result
  // Recurse over .params until we don't find a screen prop
  while (cursor?.params?.screen) {
    cursor = cursor.params
  }
  // Merge params onto last screen in nest
  cursor.params = _.merge(cursor.params, params)
  return result
}

/*
  Given a routeName (including nested routes), and params, returns the equivalent navigate call

  e.g.
    input:
      _mapRouteToNested('todayTab', { abc: 123 })
    output:
      { name: 'tabbar', params: { screen: 'todayTab', params: { abc: 123 }}}
*/
export function __mapRouteToNested(name, params) {
  const nestedScreen = NESTED_SCREEN_INDEX[name]
  if (nestedScreen) {
    const [parentName, nestedParams] = nestedScreen
    return [parentName, __mergeParamsOnToDeepestScreen(nestedParams, params)]
  } else {
    return [name, params]
  }
}

/*
  Equivalent to functions from react-navigation, but with nested route support.
  Maps supplied params onto screen matching supplied index.
  -----------------------------------------------------------------------------
*/
export function navigate(name, params) {
  const [mappedName, mappedParams] = __mapRouteToNested(name, params)
  navigationRef.navigate(mappedName, mappedParams)
}

export function reset({ index, routes }) {
  const mappedRoutes = routes.map((route, i) => {
    const shouldMergeParams = i === index
    const [mappedName, mappedParams] = __mapRouteToNested(
      route?.name,
      shouldMergeParams && route?.params
    )
    return { name: mappedName, params: mappedParams }
  })
  navigationRef.reset({ index, routes: mappedRoutes })
}

/*
  "Simplified" StackActions.(replace|push), normalized to navigation.(replace|push), with nested route support
  -----------------------------------------------------------------------------
*/
export function replace(...args) {
  navigationRef.dispatch(StackActions.replace(...__mapRouteToNested(...args)))
}

export function push(...args) {
  navigationRef.dispatch(StackActions.push(...__mapRouteToNested(...args)))
}

/*
  "Simplified" StackActions.(pop|popToTop), normalized to navigation.(pop|popToTop)
  -----------------------------------------------------------------------------
*/
export function pop(...args) {
  navigationRef.dispatch(StackActions.pop(...args))
}

export function popToTop() {
  navigationRef.dispatch(StackActions.popToTop())
}

/*
  Extras, with nested route support
  -----------------------------------------------------------------------------
*/
export function getRouteNames() {
  if (navigationRef.isReady()) {
    const rootRouteNames = navigationRef.getRootState()?.routeNames || []
    const nestedRouteNames = Object.keys(NESTED_SCREEN_INDEX)
    return [...rootRouteNames, ...nestedRouteNames]
  }
  return []
}
/*
  1:1 API compatible to navigation object provided by navigator context
  +nested route support
  +StackAction functions
  -----------------------------------------------------------------------------
*/
export const navigation = {
  ...navigationRef,
  pop,
  popToTop,
  replace,
  navigate,
  reset,
}
