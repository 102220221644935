import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Metrics, Fonts } from 'APP/Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  splashLogo: {
    resizeMode: 'contain',
    alignSelf: 'baseline',
    marginTop: Metrics.baseMargin * 2,
    marginLeft: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin * 2,
    height: 45,
  },
  title: {
    ...Fonts.style.h1,
    color: Colors.text,
    alignItems: 'baseline',
    textAlign: 'left',
    marginLeft: Metrics.baseMargin,
    marginRight: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin,
  },
  subtitle: {
    ...Fonts.style.body,
    color: Colors.text,
    textAlign: 'left',
    marginLeft: Metrics.baseMargin,
    marginRight: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin,
  },
  instructionContainer: {
    marginVertical: Metrics.baseMargin / 2,
    marginRight: Metrics.baseMargin,
    marginLeft: Metrics.baseMargin,
  },
  instruction: {
    ...Fonts.style.subheaderBold,
    color: Colors.text,
    marginBottom: Metrics.baseMargin / 2,
  },
  body: {
    ...Fonts.style.subheaderBold,
    color: Colors.text,
    marginBottom: Metrics.baseMargin / 2,
  },
  subbody: {
    ...Fonts.style.body,
    color: Colors.text,
    marginBottom: Metrics.baseMargin / 2,
  },
  buttonContainer: {
    paddingBottom: Metrics.baseMargin * 3,
  },
})
