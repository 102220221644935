import { Colors, Fonts, Metrics } from 'APP/Themes'

export default {
  container: {
    display: 'flex',
    padding: Metrics.baseMargin,
    borderRadius: 16,
    backgroundColor: Colors.tagBg,
    flexDirection: 'row',
    alignItems: 'center',
  },
  description: {
    ...Fonts.style.body,
    color: Colors.darkText,
    marginTop: Metrics.baseMargin / 2,
    paddingLeft: 30,
  },
  texts: {
    width: '100%',
  },
  title: {
    ...Fonts.style.subheaderBold,
    color: Colors.darkText,
    paddingLeft: 13,
  },
  titleContainer: {
    flexDirection: 'row',
  },
}
