import React from 'react'
import { STACK_SIDE_MENUS, ANALYTICS_NAME_MAP } from 'APP/Nav'

export const selectDescriptorOfCurrentRoute = (descriptors = {}, stackRoutes = []) => {
  const route = stackRoutes[stackRoutes.length - 1]
  return descriptors[route?.key]
}

export const getNameOfCurrentRoute = (state) => {
  const route = state.routes[state.index]
  if (route.state) {
    return getNameOfCurrentRoute(route.state)
  }
  return route.name
}

export const createScreenFocusTracker = ({ focus, blur }) => {
  let prevRouteName = ''
  return (state) => {
    const currentRouteName = getNameOfCurrentRoute(state)
    if (prevRouteName !== currentRouteName) {
      typeof focus === 'function' && focus(currentRouteName)
      typeof blur === 'function' && prevRouteName && blur(prevRouteName)
    }
    prevRouteName = currentRouteName
  }
}

export const routeNameToAnalyticsName = (name) => ANALYTICS_NAME_MAP[name] || name

export const routesToStackSideMenus = (stackRoutes = []) =>
  stackRoutes
    .map(({ name, key }, stackIndex) => {
      const Menu = STACK_SIDE_MENUS[name]
      return Menu && <Menu key={key} {...{ stackRoutes, stackIndex }} />
    })
    .filter((item) => !!item)
