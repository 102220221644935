import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Metrics, Fonts } from 'APP/Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  scrollView: {
    padding: Metrics.baseMargin * 1.5,
    paddingTop: Metrics.baseMargin * 3.7,
  },
  title: {
    ...Fonts.style.h4,
    color: Colors.text,
    alignItems: 'baseline',
    textAlign: 'center',
    marginBottom: Metrics.baseMargin,
  },
  subtitle: {
    ...Fonts.style.subheader,
    color: Colors.text,
    textAlign: 'center',
    marginBottom: Metrics.baseMargin,
  },
  illustration: {
    marginTop: Metrics.baseMargin * 2,
    marginBottom: Metrics.baseMargin * 2,
    alignSelf: 'center',
    tintColor: Colors.text,
  },
})
