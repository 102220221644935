import React, { useContext, memo } from 'react'
import RNGooglePlaces from 'react-native-google-places'
import styled from 'styled-components/native'

import * as geolib from 'geolib'
import Config from 'APP/Config'
import Analytics from 'APP/Services/Analytics'
import * as Sentry from '@sentry/react-native'
import { isWeb } from 'APP/Helpers/checkPlatform'

import { ChatContext, PostContext } from 'APP/Lib/Context'
import ActionCard from 'APP/Components/FelixChat/ActionCard'
import TextMessage from 'APP/Components/FelixChat/TextMessage'
import { useSelector } from 'react-redux'
import AddressSearchCard from 'APP/Converse/AddressSearchCard'
import { useCallback } from 'react'
import { getSelectedAddressPayload } from 'APP/Lib/PlacesHelper'
import { isCanada } from 'APP/Helpers/RegionHelpers'
import { logDdError } from 'APP/Lib/Datadog'

import { Styles } from './style'

const Container = styled.View`
  flex: 1;
`

const AddressPicker = () => {
  const { post } = useContext(PostContext)
  const { setSelectedAddress, sendTextWithPayload } = useContext(ChatContext)

  const location = useSelector((state) => state.patient.profile.location)

  const getLocationBias = () => {
    if (!location || !location.latitude || !location.latitude) {
      return undefined
    }

    const distanceFromLocationInMeter = 10000

    const boundingBox = geolib.getBoundsOfDistance(
      { latitude: location.latitude, longitude: location.longitude },
      distanceFromLocationInMeter
    )

    const locationBias = {
      latitudeSW: boundingBox[0].latitude,
      longitudeSW: boundingBox[0].longitude,
      latitudeNE: boundingBox[1].latitude,
      longitudeNE: boundingBox[1].longitude,
    }

    return locationBias
  }

  const onPress = () => {
    const { place_type, payload_template } = post.props
    Analytics.trackEvent('button_click', { button_value: 'Address picker tap' })
    let autoCompleteOptions = {
      ...(isCanada() && { country: Config.REGION }),
      type: place_type,
      placeFields: ['name', 'addressComponents', 'address', 'openingHours'],
      useOverlay: true,
    }

    const locationBias = getLocationBias()

    if (locationBias) {
      autoCompleteOptions.locationBias = locationBias
    }

    RNGooglePlaces.openAutocompleteModal(autoCompleteOptions)
      .then((place) => {
        setSelectedAddress(getSelectedAddressPayload(place, place_type, payload_template))
      })
      .catch((error) => {
        // ignore users pressing the cancel button
        if (error.code !== 'E_USER_CANCELED') {
          Sentry.captureException(error)
          logDdError(error.message, 'AddressPicker.RNGooglePlaces')
        }
      })
  }

  const onSubmitCallback = useCallback(
    (resolvedPlace) => {
      const normalizedPlace = {
        placeID: resolvedPlace.place_id,
        address: resolvedPlace.formatted_address,
        addressComponents: resolvedPlace.address_components,
        location: {
          latitude: resolvedPlace.geometry?.location?.lat(),
          longitude: resolvedPlace.geometry?.location?.lng(),
        },
        name: resolvedPlace.name,
        phoneNumber: resolvedPlace.international_phone_number,
      }
      const { place_type, payload_template } = post.props
      const selectedAddressPayload = getSelectedAddressPayload(
        normalizedPlace,
        place_type,
        payload_template
      )
      const { payloadTemplate, payload, addressInfo } = selectedAddressPayload
      Analytics.trackEvent('button_click', { button_value: 'Address confirmed' })
      sendTextWithPayload({
        message: addressInfo.address,
        payload: payload,
        payloadTemplate: payloadTemplate,
        flags: { addressInfo: addressInfo },
      })
    },
    [post.props, sendTextWithPayload]
  )

  return (
    <Container>
      <TextMessage />
      {isWeb() ? (
        <AddressSearchCard
          title={post.props.button}
          placeholder={post.props.button}
          onSubmitCallback={onSubmitCallback}
          type={post.props.place_type}
          style={Styles.addressCard}
        />
      ) : (
        <ActionCard
          title={post.props.button}
          onPress={onPress}
          iconName={post.props.place_type === 'establishment' ? 'pill' : 'map-marker'}
        />
      )}
    </Container>
  )
}

export default memo(AddressPicker)
