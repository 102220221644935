import React from 'react'
import { Appearance } from 'react-native-appearance'
import { useTheme } from 'styled-components/native'
import { useNavigation } from '@react-navigation/native'

import { Images } from 'APP/Themes'
import UrlBuilder from 'APP/Services/UrlBuilder'
import I18n from 'APP/Services/i18n'
import Analytics from 'APP/Services/Analytics'

import { getAnalyticsScreenName } from '../utils'
import { ServiceGroupCard } from '../ServiceGroupCard'

export const ScopeOfPracticeGroupCard = ({ serviceGroup }) => {
  const theme = useTheme()
  const navigation = useNavigation()
  const analyticsScreenName = getAnalyticsScreenName(serviceGroup)
  const { id, slug } = serviceGroup.scopeOfPractice || {}
  const uri = UrlBuilder.cmsScopeUrl(id, Appearance.getColorScheme())
  const onPress = () => {
    navigation.navigate('cmsScreen', { uri, showShareButton: false })
    Analytics.trackScreen(`scopeOfPracticeScreen : ${id} : ${slug}`)
  }

  return (
    <ServiceGroupCard
      onPress={onPress}
      testID="SCOPE_OF_PRACTICE"
      analyticsName="scopeOfPractice"
      icon={
        <Images.scopeOfPracticeIcon
          accentFillLine={theme.colors.accent}
          width={theme.metrics.icon.xsmall}
          height={theme.metrics.icon.xsmall}
        />
      }
      cardText={serviceGroup?.scopeOfPractice?.subtitle}
      callToAction={I18n.t('ServiceGroup.learnMore')}
      analyticsScreenName={analyticsScreenName}
      trackEventValue={`showScopeOfPractice : ${id} : ${slug}`}
      trackEventText="Show Scope of Practice"
    />
  )
}
