import moment from 'moment'
import { createEvent } from 'ics'

export const parseIcsUrl = (url) => {
  let icsContent = decodeURIComponent(url)
  icsContent = icsContent.replace('data:text/calendar;charset=utf8,', '')

  return parseIcsFile(icsContent)
}

const parseIcsFile = (content) => {
  const icsJson = convertToJson(content)

  const vevent = icsJson.VCALENDAR[0].VEVENT[0]

  const description = vevent.DESCRIPTION
  const summary = vevent.SUMMARY

  return {
    startDate: formatDate(vevent.DTSTART),
    endDate: formatDate(vevent.DTEND),
    description: description,
    title: summary,
  }
}

const formatDate = (source) => {
  return moment.utc(source).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
}

// Function that convert an ICS file into JSON for easier manipulation.
// Got from https://github.com/adrianlee44/ical2json/blob/main/src/ical2json.ts
const convertToJson = (source) => {
  const output = {}
  const lines = source.split(/\r\n|\n|\r/)

  let parentObj = {}
  let currentObj = output
  const parents = []

  let currentKey = ''

  for (let i = 0; i < lines.length; i++) {
    let currentValue = ''

    const line = lines[i]
    if (line.charAt(0) === ' ') {
      currentObj[currentKey] += line.substr(1)
    } else {
      const splitAt = line.indexOf(':')

      if (splitAt < 0) {
        continue
      }

      currentKey = line.substr(0, splitAt)
      currentValue = line.substr(splitAt + 1)

      switch (currentKey) {
        case 'BEGIN':
          parents.push(parentObj)
          parentObj = currentObj
          if (parentObj[currentValue] == null) {
            parentObj[currentValue] = []
          }
          currentObj = {}
          parentObj[currentValue].push(currentObj)
          break
        case 'END':
          currentObj = parentObj
          parentObj = parents.pop()
          break
        default:
          if (currentObj[currentKey]) {
            if (!Array.isArray(currentObj[currentKey])) {
              currentObj[currentKey] = [currentObj[currentKey]]
            }
            currentObj[currentKey].push(currentValue)
          } else {
            currentObj[currentKey] = currentValue
          }
      }
    }
  }
  return output
}

export const createIcsFile = (title, description, startDate, endDate) => {
  return new Promise((resolve, reject) => {
    const eventConfig = {
      title,
      description,
      start: [
        startDate.year(),
        startDate.month() + 1, // Moment months are 0 indexed for some reason 😡
        startDate.date(), // You'd think this would be the whole date, but no, it's just the day of the month 🤦‍♂️
        startDate.hours(),
        startDate.minutes(),
      ],
      startInputType: 'utc',
      end: [
        endDate.year(),
        endDate.month() + 1,
        endDate.date(),
        endDate.hours(),
        endDate.minutes(),
      ],
      endInputType: 'utc',
    }

    createEvent(eventConfig, (err, result) => {
      if (err) {
        reject(err)
      }

      resolve(`data:text/calendar;charset=utf8,${encodeURI(result)}`)
    })
  })
}
