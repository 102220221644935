import Config from 'APP/Config'
import I18n from 'APP/Services/i18n'
import { call, put, select } from 'typed-redux-saga'

// API Services
import Scribe from 'APP/Services/Scribe'
import * as NestedNavHelper from 'APP/Nav/NestedNavHelper'
import { navigationRef as Nav } from 'APP/Nav'

// Helpers
import { handleNonRetryableRequestFailure } from 'APP/Lib/SagaRequestFailureHandler'
import type { RootState } from 'APP/Store/CreateStore'
import type { SagaActionProps } from 'APP/Models/Actions'

import type {
  AddAdultActionProps,
  CatchError,
  CreateChildProfileActionProps,
  RemoveAdultActionProps,
  UpdateChildProfileActionProps,
} from './types'
import { familyActions } from './slice'
import { selectFamilyState } from './selectors'

//TODO: Should be taken from related slice
export const patientState = (state: RootState) => state?.patient
export const loginState = (state: RootState) => state.login

export function* fetchFamily() {
  const patient = yield* select(patientState)
  const login = yield* select(loginState)
  const scribe = Scribe.create(login)

  try {
    const { familyId, family, permissions } = yield* call(
      scribe.getFamilyMembers,
      patient.profile.id
    )

    yield put(familyActions.fetchFamilySuccess({ familyId, family, permissions }))
  } catch (e) {
    const error = e as CatchError
    yield put(familyActions.familyError({ error }))
  }
}

export function* addAdult({ payload }: SagaActionProps<AddAdultActionProps>) {
  const { email } = payload
  const patient = yield* select(patientState)
  const login = yield* select(loginState)
  const scribe = Scribe.create(login)
  const brandId = Config.BRAND_ID

  try {
    const { member, familyId } = yield* call(scribe.addAdult, patient.profile.id, email, brandId)
    yield put(familyActions.adultInviteSuccess({ member, familyId }))
  } catch (e) {
    const error = e as CatchError
    yield put(familyActions.familyError({ error }))
    if (error.code) {
      let errorBody
      switch (error.code) {
        case 'no_active_contract':
          errorBody = I18n.t('error.alert.invite.noActiveContract', { email })
          break
        case 'feature_not_in_contract':
          errorBody = I18n.t('error.alert.invite.noFamilyFeature', { email })
          break
        case 'already_in_family':
          errorBody = I18n.t('error.alert.invite.alreadInFamily', { email })
          break
        case 'already_on_contract':
          errorBody = I18n.t('error.alert.invite.alreadOnContract', { email })
          break
        case 'not_family_guardian':
          errorBody = I18n.t('error.alert.invite.notGuardian', { email })
          break
        case 'not_found':
        default:
          errorBody = I18n.t('error.alert.invite.generic', { email })
          break
      }
      yield call(handleNonRetryableRequestFailure, errorBody)
    } else {
      yield call(handleNonRetryableRequestFailure, I18n.t('error.alert.invite.generic', { email }))
    }
  }
  yield call(NestedNavHelper.pop)
}

export function* removeAdult({ payload }: SagaActionProps<RemoveAdultActionProps>) {
  const { memberId, email } = payload
  const login = yield* select(loginState)
  const family = yield* select(selectFamilyState)
  const scribe = Scribe.create(login)

  try {
    const removedMember = yield* call(scribe.removeAdult, family.id, memberId)
    yield put(familyActions.cancelAdultInviteSuccess({ member: removedMember }))
  } catch (e) {
    const error = e as CatchError

    yield put(familyActions.familyError({ error }))
    if (error.code) {
      let errorBody
      switch (error.code) {
        case 'registered_user':
          errorBody = I18n.t('error.alert.removeAdult.registeredUser', { email })
          break
        case 'forbidden':
          errorBody = I18n.t('error.alert.removeAdult.notGuardian', { email })
          break
        case 'not_found':
        default:
          errorBody = I18n.t('error.alert.removeAdult.generic')
          break
      }
      yield call(handleNonRetryableRequestFailure, errorBody)
    } else {
      yield call(handleNonRetryableRequestFailure, I18n.t('error.alert.removeAdult.generic'))
    }
  }
}

export function* createChildProfile(action: SagaActionProps<CreateChildProfileActionProps>) {
  const patient = yield* select(patientState)
  const login = yield* select(loginState)
  const scribe = Scribe.create(login)

  try {
    const response = yield* call(scribe.addChild, patient.profile.id, action.payload.childData)
    yield* put(
      familyActions.updateChildProfileState({
        childProfile: response.child,
        familyId: response.familyId,
      })
    )
  } catch (e) {
    const error = e as CatchError
    yield* put(familyActions.familyError({ error }))
    if (error.code) {
      let errorBody
      switch (error.code) {
        case 'no_active_contract':
          errorBody = I18n.t('error.alert.createChild.noActiveContract')
          break
        case 'feature_not_in_contract':
          errorBody = I18n.t('error.alert.createChild.noFamilyFeature')
          break
        case 'not_family_guardian':
          errorBody = I18n.t('error.alert.createChild.notGuardian')
          break
        case 'not_found':
        default:
          errorBody = I18n.t('error.alert.createChild.generic')
          break
      }
      yield* call(handleNonRetryableRequestFailure, errorBody)
    } else {
      yield* call(handleNonRetryableRequestFailure, I18n.t('error.alert.createChild.generic'))
    }
  }
  yield call(Nav.navigate, 'family')
}

export function* updateChildProfile({ payload }: SagaActionProps<UpdateChildProfileActionProps>) {
  const login = yield* select(loginState)
  const family = yield* select(selectFamilyState)
  const scribe = Scribe.create(login)

  try {
    if (!family.id) throw { error: 'Family missed' }
    const response = yield* call(scribe.updateChild, family.id, payload.childId, payload.childData)
    if (payload.fileObj) {
      yield* call(scribe.uploadChildIdCard, response.url, response.fields, payload.fileObj)
    }
    yield* put(
      familyActions.updateChildProfileState({ childProfile: response.child, familyId: family.id })
    )
  } catch (e) {
    const error = e as CatchError
    yield* put(familyActions.familyError({ error }))
    if (error.code) {
      let errorBody
      switch (error.code) {
        case 'not_family_guardian':
          errorBody = I18n.t('error.alert.editChild.notGuardian')
          break
        case 'not_found':
        default:
          errorBody = I18n.t('error.alert.editChild.generic')
          break
      }
      yield* call(handleNonRetryableRequestFailure, errorBody)
    } else {
      yield* call(handleNonRetryableRequestFailure, I18n.t('error.alert.createChild.generic'))
    }
  }
  yield* call(NestedNavHelper.pop)
}
