import { StyleSheet } from 'react-native'
import { ApplicationStyles, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  content: {
    paddingVertical: Metrics.baseMargin * 2,
    paddingHorizontal: Metrics.baseMargin,
  },
  formInputContainer: {
    ...ApplicationStyles.screen.formInputContainer,
    paddingTop: Metrics.baseMargin / 4,
    marginTop: Metrics.baseMargin / 4,
  },
  container: {
    marginTop: Metrics.baseMargin,
    paddingTop: 0,
  },
  noDisplay: {
    display: 'none',
  },
})
