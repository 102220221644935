import React from 'react'
import { useSelector } from 'react-redux'

// Components
import MicroApp from 'APP/Components/MicroApp'
import AppointmentBooking from 'APP/Components/AppointmentBooking'
import IntakeBottomSheet from '../IntakeBottomSheet'

// Styles
import { Styles } from './style'

const IntakeBooking = ({ style, title, inline = true, onClose, ...rest }) => {
  const showV2AppointmentBooking = useSelector((state) => state.features.showV2AppointmentBooking)

  return (
    <IntakeBottomSheet
      inline={inline}
      title={title}
      ContentBodyComponent={
        showV2AppointmentBooking ? (
          <AppointmentBooking {...rest} />
        ) : (
          <MicroApp appId="selfScheduling" renderNavBar={false} {...rest} />
        )
      }
      onClose={onClose}
      style={style}
      scrollStyle={Styles.scroll}
      contentContainerStyle={Styles.contentContainer}
      scrollContentStyle={Styles.scrollContentStyle}
      disableContentPanHandlers
    />
  )
}

export default IntakeBooking
