import React, { useCallback, memo } from 'react'
import styled from 'styled-components/native'
import { useNavigation } from '@react-navigation/native'

import Button from 'APP/Converse/Button'
import Typography from 'APP/Converse/Typography'

import { isMobile } from 'APP/Helpers/checkPlatform'
import Analytics from 'APP/Services/Analytics'
import useScreenView from 'APP/Hooks/useScreenView'

import { Images } from 'APP/Themes'

const HeaderContainer = styled.View`
  display: flex;
`

export const StyledRowContainer = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  justify-content: center;
`

export const StyledContainer = styled.View`
  background-color: ${({ theme }) => theme.colors.background};
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  padding-top: ${({ theme: { metrics, isSmallScreen } }) =>
    isSmallScreen ? metrics.statusBarHeight + metrics.baseMargin * 2 : metrics.baseMargin * 2}px;
  padding-left: ${({ theme, hasBackButton }) =>
    hasBackButton ? theme.metrics.baseMargin / 2 : theme.metrics.baseMargin * 1.5}px;
`

export const StyledRightButtonContainer = styled.View`
  justify-content: center;
`

const Header = ({
  title,
  hideBackButton,
  backButtonDisabled,
  onBackButtonPress,
  rightButtonOnPress,
  rightButtonIcon,
  rightButtonIconVariant,
  rightButtonTitle,
  rightButtonAnalytics,
  rightButtonDisabled,
  // TODO: "headerLeft" and "headerRight" is temporary solution for backward compatible header
  headerLeft,
  headerRight,
  isModal,
  style,
  subHeader,
}) => {
  const navigation = useNavigation()
  const shouldRenderRightButton = Boolean(rightButtonIcon && !!rightButtonOnPress)

  const onButtonPress = useCallback(() => {
    Analytics.trackEvent('button_click', { button_value: 'Back' })
    if (onBackButtonPress) onBackButtonPress()
    else navigation.goBack()
  }, [navigation, onBackButtonPress])

  const { isBigScreen } = useScreenView()

  const hasBackButton = headerLeft || !hideBackButton

  return (
    <StyledContainer
      isMobilePlatform={isMobile()}
      isModal={isModal}
      hasBackButton={hasBackButton}
      style={style}
    >
      <StyledRowContainer>
        {headerLeft
          ? headerLeft()
          : !hideBackButton && (
              <Button
                variant="tertiary"
                widthVariant="circle"
                icon={Images.backIcon}
                iconVariant="image"
                size={40}
                disabled={backButtonDisabled}
                onPress={onButtonPress}
                analyticsName="Back"
                testID="BACK_BTN"
              />
            )}
        <HeaderContainer>
          {Boolean(title) && <Typography variant="h1">{title}</Typography>}
          {subHeader}
        </HeaderContainer>
      </StyledRowContainer>
      <StyledRightButtonContainer>
        {headerRight
          ? headerRight()
          : shouldRenderRightButton && (
              <Button
                variant="tertiary"
                widthVariant="content"
                title={isBigScreen && rightButtonTitle}
                icon={rightButtonIcon}
                iconVariant={rightButtonIconVariant}
                analyticsName={rightButtonAnalytics}
                disabled={rightButtonDisabled}
                onPress={rightButtonOnPress}
                testID="TOOLBAR_RIGHT_BUTTON"
              />
            )}
      </StyledRightButtonContainer>
    </StyledContainer>
  )
}

export default memo(Header)

// Custom Header for CmsAppTabsScreen
export const HeaderWithoutBottomPadding = memo(styled(Header)`
  padding-bottom: 0;
`)
