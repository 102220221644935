import { StyleSheet } from 'react-native'
import { ApplicationStyles, Metrics, Colors } from 'APP/Themes'

const formInputContainer = {
  ...ApplicationStyles.screen.formInputContainer,
  paddingTop: 4,
  marginTop: 4,
}
const pickerInputContainer = {
  ...formInputContainer,
  marginHorizontal: Metrics.baseMargin + 2,
  marginBottom: 0,
  paddingBottom: 0,
}

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  outerContainer: {
    flex: 1,
    backgroundColor: Colors.appBg,
  },
  innerContainer: {
    flex: 1,
    marginHorizontal: Metrics.baseMargin,
    marginTop: Metrics.baseMargin * 2,
    marginBottom: Metrics.baseMargin,
    padding: Metrics.baseMargin,
  },
  formInputContainer,
  firstNameInputContainer: {
    ...formInputContainer,
    paddingTop: 0,
    marginTop: 0,
  },
  firstNameContainer: {
    marginTop: 0,
    marginBottom: 0,
  },
  lastNameContainer: {
    marginTop: 4,
    paddingTop: 0,
  },
  phoneNumberContainer: {
    marginTop: 0,
    paddingTop: 0,
  },
  preferredNameInputContainer: {
    ...formInputContainer,
    paddingTop: 0,
    marginTop: 0,
  },
  preferredNameContainer: {
    marginTop: Metrics.baseMargin,
    paddingTop: 0,
  },
  pickerInputContainer,
  dobInputContainer: {
    ...pickerInputContainer,
    marginTop: 4,
  },
  provincePickerContainer: {
    ...pickerInputContainer,
    marginTop: -4,
  },
  languagePickerContainer: {
    ...pickerInputContainer,
    marginTop: -4,
  },
  noDisplay: {
    display: 'none',
  },
  radioButtonContainer: {
    paddingBottom: Metrics.baseMargin,
  },
  pronounsInputContainer: {
    ...formInputContainer,
    paddingTop: 0,
    marginTop: 0,
  },
  pronounsContainer: {
    marginTop: Metrics.baseMargin,
    paddingTop: 0,
  },
})
