import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Fonts, Metrics } from 'APP/Themes'

const text = {
  ...Fonts.style.body,
  color: Colors.text,
  paddingBottom: Metrics.baseMargin,
  lineHeight: 21,
}

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  container: {
    flex: 1,
  },
  title: {
    ...Fonts.style.h1,
    color: Colors.text,
    paddingTop: Metrics.baseMargin,
    paddingBottom: Metrics.baseMargin,
  },
  text,
  bold: {
    ...text,
    fontWeight: 'bold',
  },
  link: {
    ...text,
    fontWeight: 'bold',
    color: Colors.accent,
    textDecorationLine: 'underline',
  },
  bulletStyle: {
    color: Colors.text,
  },
  scrollView: {
    paddingRight: Metrics.baseMargin * 2,
    paddingLeft: Metrics.baseMargin * 2,
  },
})
