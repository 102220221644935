import { createActions, createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import {
  normalizePostActions,
  isPatientVisiblePost,
  isReplyPost,
  isValidPostData,
  updateEpisodeWithArchivableStatus,
} from 'APP/Lib/MessagingHelpers'
import { createSelector } from 'reselect'
import { getPatientMember } from 'APP/Lib/ProfileHelpers'

const EPISODE_DEFAULT_STATE = {
  archived: null,
  goBack: {
    error: null,
    running: false,
  },
}
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  addDraftPhoto: ['channelId', 'photoData'],
  changeDraftText: ['channelId', 'newText'],
  cleanChannels: null,
  clearDraft: ['channelId'],
  createEpisodeRequest: ['ctaId', 'commandPost', 'replaceCurrentScene'],
  getOrCreateIcbtEpisodeRequest: ['ctaId', 'commandPost', 'replaceCurrentScene'],
  createEpisodeSuccess: ['episode', 'ctaId', 'commandPost', 'locationPermission'],
  createEpisodeFailure: ['error'],
  fetchEncountersSuccess: ['encounters'],
  fetchEpisodeFailure: ['error'],
  fetchOneEpisodeRequest: ['channelId'],
  fetchOneEpisodeSuccess: ['episode'],
  fetchEpisodeMembersRequest: ['channelId'],
  fetchEpisodeMembersSuccess: ['members'],
  fetchEpisodeRequest: null,
  fetchEpisodeSuccess: ['episodes'],
  fetchChannelsRequest: ['bootstrap'],
  fetchChannelsSuccess: ['channelSets', 'memberEpisodes'],
  fetchChannelsFailure: ['error'],
  fetchPostsFailure: ['channelId', 'error'],
  fetchPostsSuccess: ['channelId', 'postData', 'fullFetch'],
  fetchNewPostsForChannel: ['channelId', 'backgroundFetch'],
  fetchOlderPostsForChannel: ['channelId'],
  fetchAllPostsForChannel: ['channelId'],
  fetchPractitionersFailure: ['error'],
  fetchPractitionersRequest: null,
  fetchPractitionersSuccess: ['practitioners'],

  fetchPractitionerBio: ['userAppId', 'profileId'],
  fetchPractitionerBioFailure: ['error'],
  fetchPractitionerBioRequest: null,
  fetchPractitionerBioSuccess: ['practitionerBio'],

  patientHistoryClear: null,
  receiveEvent: ['event'],
  receivePosts: ['channelId', 'postData', 'fetched'],
  removePosts: ['channelId', 'postIds'],
  removeDraftPhoto: ['channelId', 'photoId'],
  resetChannels: ['channelIds'],
  retryPostRequest: ['channelId', 'postId'],
  launchEpisodeCommand: ['command', 'healthIssueTypeId'],
  shownLocationNotSharedBar: ['channelId'],
  submitAnswerPostRequest: ['channelId', 'userId', 'answer', 'timeStamp', 'flags'],
  submitTextWithPayloadPostRequest: [
    'channelId',
    'userId',
    'text',
    'timeStamp',
    'flags',
    'payload',
  ],
  submitFilePostRequest: ['channelId', 'userId', 'fileObj', 'timeStamp', 'flags'],
  submitPostFailure: ['channelId', 'localId', 'message'],
  submitPostRequest: ['channelId', 'userId'],
  submitPostSuccess: ['channelId', 'post'],
  submitTextPostRequest: ['channelId', 'userId', 'text', 'timeStamp'],
  updateChannelError: ['channelId', 'error'],
  updateChannelState: ['channelId', 'channelState'],
  updateChannelHeader: ['channelId', 'header'],
  updateChannelLastViewed: ['channelId'],
  mattermostSocketClose: null,
  mattermostSocketError: null,
  mattermostSocketReady: null,
  mattermostSocketDestroyed: null,
  mattermostStart: null,
  mattermostLongPollStart: null,
  setInAppNotificationData: ['channelId', 'postId'],
  setAllOlderPostsFetched: ['channelId', 'allOlderPostsFetched'],
  setHasUnreadChannels: ['hasUnreads'],
  setFailedPostError: ['channelId', 'showPostFailureError'],
  getOrCreateWbiEpisodeRequest: ['ctaId'],
  refreshAppointmentsRequest: null,
  fetchAppointmentsRequest: null,
  fetchAppointmentsSuccess: ['appointments'],
  fetchAppointmentsFailure: ['error'],
  rescheduleAppointmentRequest: ['appointment'],
  rescheduleAppointmentSuccess: null,
  rescheduleAppointmentFailure: ['error'],
  cancelAppointmentFromCalendarRequest: ['appointment'],
  cancelAppointmentFromCalendarSuccess: null,
  cancelAppointmentFromCalendarFailure: ['error'],
  resetCancelAppointmentFromCalendar: [],
  cancelAppointmentRequest: ['appointment'],
  cancelAppointmentSuccess: null,
  cancelAppointmentFailure: ['error'],
  navigateToLink: ['link', 'context'],
  navigateToLinkSuccess: [],
  navigateToLinkError: ['error'],
  removePendingPost: ['channelId', 'postId'],
  setTypingEventData: ['event'],
  resetTypingEventData: null,
  getOrCreateHealthProfileEpisodeRequest: ['ctaId'],

  archiveEpisodeRequest: ['channelId', 'sprigSurveyData'],
  archiveEpisodeSuccess: null,
  archiveEpisodeError: ['error'],

  setHealthProfileSuccess: null,
  setPostAsUndone: ['postId'],
  goBackInEpisodeRequest: ['channelId', 'postId'],
  goBackInEpisodeSuccess: ['channelId', 'postId'],
  goBackInEpisodeError: ['channelId', 'postId', 'error'],
})

export const INITIAL_STATE = Immutable({
  channels: {},
  hasUnreadChannels: false,
  createEpisodeError: null,
  createEpisodeRunning: false,
  currentChannelId: null,
  encounters: {},
  completedCarePlans: [],
  episodes: {},
  episode: {},
  episodeMembers: {},
  fetchEpisodeRequestRunning: false,
  fetchEpisodeError: null,
  fetchChannelsRunning: false,
  fetchChannelsError: null,
  practitioners: {},
  fetchPractitionersError: null,
  fetchPractitionersRunning: false,
  orderedEpisodes: [],
  undonePostIds: [],
  posts: {},
  practitionerBio: {},
  fetchPractitionerBioError: null,
  fetchPractitionerBioRunning: false,
  mmSocketNotReady: null,
  inAppNotificationPostId: null,
  failedPostCounters: {},
  hasPostFailureError: false,
  appointments: {
    past: [],
    upcoming: [],
  },
  fetchAppointmentsRunning: false,
  fetchAppointmentsError: null,
  rescheduleAppointmentRunning: false,
  rescheduleAppointmentError: null,
  cancelAppointmentRunning: false,
  cancelAppointmentError: null,
  cancelAppointmentFromCalendarFailure: null,
  cancelAppointmentFromCalendarRunning: false,
  cancelAppointmentFromCalendarSuccess: false,
  link: null,
  linkContext: null,
  linkError: null,
  typingEvent: null,
  archiveEpisodeRunning: false,
  archiveEpisodeError: null,
})

export const createEmptyChannel = (channelId) => ({
  channelId,
  order: [],
  posts: {},
  draft: {},
  pendingPosts: {},
  pendingOrder: [],
  lastFetchedPostId: null,
  lastFetchedPostAt: null,
  fetchingPosts: false,
  fetchingPostsError: null,
  error: null,
  missingData: false, // Can be resolved only by refetching the whole channel
  msgCount: 0,
  allMembers: [],
  lastViewedAt: 0,
})

export const generateBasicPost = (userId, data) => {
  const { answer, message, file, timeStamp, payload, ...rest } = data
  return {
    id: timeStamp,
    create_at: timeStamp,
    update_at: timeStamp,
    props: {
      localId: timeStamp,
      answer: JSON.stringify(answer),
      payload: payload,
      ...rest,
    },
    message: message || (answer && answer.text) || '',
    user_id: userId,
    localFile: file?.file ? file.file : file?.fileUri,
    fileName: file?.fileName,
    fileType: file?.fileType,
    isLocal: true,
  }
}

export const buildInitialFilePayload = (data) => {
  return {
    fileName: data.fileName,
    fileType: data.fileType,
    ...(typeof data.localFile === 'string'
      ? { fileUri: data.localFile } // mobile
      : { file: data.localFile }), // web
  }
}

export const fetchEpisodeRequest = (state) => {
  return state.merge({ fetchEpisodeRequestRunning: true })
}

export const fetchEpisodeSuccess = (state, { episodes }) => {
  if (episodes) {
    let newEpisodes = {}
    let newChannels = { ...state.channels }

    episodes.items.forEach((episode) => {
      const id = episode.id

      newEpisodes[id] = {
        id,
        channelId: id,
        createdAt: episode.created_at,
        serviceId: episode.service_id,
        healthIssueTypeId: episode.health_issue_type_id,
        botStatus: episode.bot_status,
        outcomeId: episode.outcome_id,
        isArchivable: episode.is_archivable,
        ...EPISODE_DEFAULT_STATE,
      }

      if (!newChannels[id]) {
        newChannels[id] = createEmptyChannel(id)
      }
    })

    return withOrderedEpisodes(
      state.merge({
        episodes: newEpisodes,
        channels: newChannels,
        completedCarePlans: filterCompletedCarePlans(state.encounters, newEpisodes),
        fetchEpisodeRequestRunning: false,
      })
    )
  }

  return state
}

export const fetchEpisodeFailure = (state) => {
  return state.merge({ fetchEpisodeRequestRunning: false })
}

export const fetchOneEpisodeSuccess = (state, { episode }) => {
  if (episode && episode.id) {
    const existingEpisode = state.episodes[episode.id]

    if (existingEpisode) {
      const updatedEpisode = {
        ...existingEpisode,
        outcomeId: episode.outcomeId,
      }

      return state.merge({
        episodes: {
          ...state.episodes,
          [existingEpisode.id]: updatedEpisode,
        },
        episode: episode,
      })
    }
  }

  return state.merge({ episode })
}

export const createEpisodeRequest = (state) => {
  return state.merge({ createEpisodeRunning: true, createEpisodeError: null })
}

export const getOrCreateIcbtEpisodeRequest = (state) => {
  return state.merge({ createEpisodeRunning: true, createEpisodeError: null })
}

export const createEpisodeSuccess = (state, { episode }) => {
  const newEpisode = {
    id: episode.id,
    channelId: episode.id,
    serviceId: episode.service_id,
    createdAt: episode.created_at,
    botStatus: episode.bot_status,
    healthIssueTypeId: episode.health_issue_type_id,
    outcomeId: episode.outcome_id,
    isArchivable: episode?.is_archivable,
    ...EPISODE_DEFAULT_STATE,
  }
  const channel = createEmptyChannel(episode.id)

  return withOrderedEpisodes(
    state.merge({
      episodes: {
        ...state.episodes,
        [newEpisode.id]: newEpisode,
      },
      orderedEpisodes: [episode.id, ...state.orderedEpisodes],
      channels: {
        ...state.channels,
        [episode.id]: channel,
      },
      createEpisodeRunning: false,
      createEpisodeError: null,
    })
  )
}

export const createEpisodeFailure = (state, { error }) =>
  state.merge({
    createEpisodeRunning: false,
    createEpisodeError: error,
  })

export const fetchPractitionersRequest = (state) => {
  return state.merge({ fetchPractitionersRunning: true, fetchPractitionersError: null })
}

export const fetchPractitionersSuccess = (state, { practitioners }) => {
  return state.merge({ fetchPractitionersRunning: false, practitioners })
}

export const fetchPractitionersFailure = (state, { error }) => {
  return state.merge({ fetchPractitionersRunning: false, fetchPractitionersError: error })
}

export const fetchPractitionerBioRequest = (state) => {
  return state.merge({ fetchPractitionerRunning: true, fetchPractitionerBioError: null })
}

export const fetchPractitionerBioSuccess = (state, { practitionerBio }) => {
  return state.merge({
    fetchPractitionerBioError: null,
    fetchPractitionerRunning: false,
    practitionerBio,
  })
}

export const fetchPractitionerBioFailure = (state, { error }) => {
  return state.merge({ fetchPractitionerRunning: false, fetchPractitionerBioError: error })
}

export const extractLastPost = (postData) => {
  const postId = postData.order[0]
  return postData.posts[postId]
}

export const extractOldestPost = (postData) => {
  const postIndex = postData.order.length - 1
  const postId = postData.order[postIndex]
  return postData.posts[postId]
}

export const extractPostInfo = (channel, postData, fetched) => {
  if (!fetched || !channel || !isValidPostData(postData)) return null
  const result = {}
  const lastPost = extractLastPost(postData)
  if (!channel.lastFetchedPostAt || channel.lastFetchedPostAt < lastPost.create_at) {
    result.lastFetchedPostAt = lastPost.create_at
    result.lastFetchedPostId = lastPost.id
  }

  const oldestPost = extractOldestPost(postData)
  if (!channel.oldestFetchedPostAt || channel.oldestFetchedPostAt > oldestPost.create_at) {
    result.oldestFetchedPostAt = oldestPost.create_at
    result.oldestFetchedPostId = oldestPost.id
  }

  return result
}

export const extractUpdatedPosts = (channel, postData = {}) => {
  if (!channel || !isValidPostData(postData)) return null
  const postIds = Object.keys(postData.posts).filter(
    (id) => channel.posts[id] && channel.posts[id].update_at !== postData.posts[id].update_at
  )
  if (postIds.length) {
    const updatedPosts = {}
    postIds.forEach((id) => (updatedPosts[id] = normalizePostActions(postData.posts[id])))
    return updatedPosts
  }
  return null
}

export const getNextPostOrder = (channel, order = [], posts = {}, discardPrevState = false) => {
  const nextOrder = [...order, ...((!discardPrevState && channel.order) || [])]
  nextOrder.sort((a, b) => posts[b].create_at - posts[a].create_at)
  const newData = nextOrder.reduce(
    (acc, id) => {
      if (isPatientVisiblePost(posts[id])) return { ...acc, visible: acc.visible.concat(id) }
      else return { ...acc, hidden: acc.hidden.concat(id) }
    },
    { visible: [], hidden: [] }
  )
  return newData
}

export const extractAllMembers = (channel, order = [], posts) => {
  const allMembers = [...channel.allMembers]
  order.forEach((postId) => {
    const userId = posts[postId].user_id
    if (allMembers.indexOf(userId) === -1) allMembers.push(userId)
  })
  return allMembers
}

export const extractAndFilterNewPosts = (channel, postData, discardPrevState = false) => {
  try {
    if (!channel || !isValidPostData(postData)) return null

    const filteredOrder = discardPrevState
      ? { visible: postData.order, hidden: [] }
      : postData.order.reduce(
          (acc, postId) => {
            if (
              isPatientVisiblePost(postData.posts[postId]) &&
              !isReplyPost(postData.posts[postId]) &&
              channel.order.indexOf(postId) === -1
            ) {
              return { ...acc, visible: acc.visible.concat(postId) }
            } else {
              return { ...acc, hidden: acc.hidden.concat(postId) }
            }
          },
          { visible: [], hidden: [] }
        )

    const updatedPosts = extractUpdatedPosts(channel, postData)

    const posts = filteredOrder.visible.reduce(
      (map, postId) => ({ ...map, [postId]: normalizePostActions(postData.posts[postId]) }),
      { ...channel.posts, ...updatedPosts }
    )

    const hiddenPosts = filteredOrder.hidden.reduce(
      (map, postId) => ({ ...map, [postId]: normalizePostActions(postData.posts[postId]) }),
      { ...channel.posts, ...updatedPosts }
    )

    const order = getNextPostOrder(channel, filteredOrder.visible, posts, discardPrevState).visible

    const allMembers = extractAllMembers(channel, filteredOrder.visible, posts)

    const error = postData.error || null

    return { order, posts, error, allMembers, hiddenPosts }
  } catch (error) {
    return { error: String(error) }
  }
}

export const extractFromChannelInfo = (channelInfo, episodeInfo = null) => {
  const episodeIsActive = episodeInfo && episodeInfo.state === 'in_progress'
  return (
    channelInfo && {
      header: channelInfo.header,
      active: episodeIsActive,
      msgCount: channelInfo.total_msg_count || 0,
      updateAt: channelInfo.update_at,
    }
  )
}

export const extractFromMemberInfo = (memberInfo) =>
  memberInfo && {
    lastViewedAt: memberInfo.last_viewed_at,
  }

export const withOrderedEpisodes = (state, channelId) => {
  if (state.orderedEpisodes && !!channelId && state.orderedEpisodes[0] === channelId) return state
  const orderedEpisodes = Object.keys(state.episodes)
    .filter((id) => {
      const serviceId = state.episodes[id]?.serviceId
      return ['wellbeing_index_assessment', 'health_profile'].includes(serviceId) == false
    })
    .sort((a, b) => {
      const chanA = state.channels[a]
      const chanB = state.channels[b]
      const postA = chanA.order.length !== 0 && chanA.posts[chanA.order[0]]
      const postB = chanA.order.length !== 0 && chanB.posts[chanB.order[0]]
      const dateA = postA ? postA.create_at : state.episodes[a].createdAt
      const dateB = postB ? postB.create_at : state.episodes[b].createdAt
      return dateB - dateA
    })
  return state.merge({ orderedEpisodes })
}

export const receivePosts = (state, { channelId, postData, fetched, fullFetch }) => {
  const channel = state.channels[channelId] || createEmptyChannel(channelId)

  const extractedAndFilteredNewPosts = extractAndFilterNewPosts(
    channel,
    postData,
    fullFetch && channel.missingData
  )

  const newChannel = {
    ...extractedAndFilteredNewPosts,
    ...extractPostInfo(channel, postData, fetched),
    missingData: fullFetch || postData.missingData ? postData.missingData : channel.missingData,
  }

  const updatedEpisode = updateEpisodeWithArchivableStatus(
    extractedAndFilteredNewPosts?.hiddenPosts,
    state?.episodes?.[channelId]
  )

  const episodes = updatedEpisode ? { [channelId]: updatedEpisode } : {}

  return withOrderedEpisodes(
    state.merge(
      {
        episodes,
        channels: {
          [channelId]: newChannel,
        },
      },
      { deep: true }
    ),
    channelId
  )
}

export const removePosts = (state, { channelId, postIds = [] }) => {
  const channel = state.channels[channelId] || {}
  const order = (channel.order || []).filter((id) => postIds.indexOf(id) === -1)
  return state.merge(
    {
      channels: {
        [channelId]: {
          order,
        },
      },
    },
    { deep: true }
  )
}

export const resetChannels = (state, { channelIds }) => {
  if (!(channelIds instanceof Array)) {
    return state
  }
  const updatedChannels = {}
  channelIds.forEach((id) => (updatedChannels[id] = createEmptyChannel(id)))
  return state.merge({
    channels: {
      ...state.channels,
      ...updatedChannels,
    },
  })
}

export const fetchChannelsSuccess = (state, { channelSets = [], memberEpisodes }) => {
  let i = channelSets.length
  if (i === 0) return state.merge({ fetchChannelsRunning: false, fetchChannelsError: null })

  const channels = {}
  while (i > 0) {
    i--
    const set = channelSets[i]
    const channel = state.channels[set.channelId]
    const episodeInfo = memberEpisodes.items.find((item) => item.id === set.channelId)

    // Extract / normalize / filter
    channels[set.channelId] = {
      missingData: !!set.postData && set.postData.missingData,
      ...extractFromChannelInfo(set.channelInfo, episodeInfo),
      ...extractFromMemberInfo(set.memberInfo),
      ...extractPostInfo(channel, set.postData, true),
      ...extractAndFilterNewPosts(channel, set.postData),
    }
  }

  return withOrderedEpisodes(
    state.merge(
      {
        fetchChannelsRunning: false,
        fetchChannelsError: null,
        channels,
      },
      { deep: true }
    )
  )
}

export const fetchChannelsRequest = (state) => {
  return state.merge({ fetchChannelsRunning: true, fetchChannelsError: null })
}

export const fetchChannelsFailure = (state, { error }) => {
  return state.merge({ fetchChannelsRunning: false, fetchChannelsError: String(error) })
}

export const fetchPostsSuccess = (state, { channelId, postData, fullFetch }) =>
  receivePosts(
    state.merge(
      {
        channels: {
          [channelId]: {
            fetchingPosts: false,
            fetchingPostsError: null,
          },
        },
      },
      { deep: true }
    ),
    { channelId, postData, fetched: true, fullFetch }
  )

export const fetchPostsRequest = (state, { channelId, backgroundFetch }) =>
  state.merge(
    {
      channels: {
        [channelId]: {
          ...(!backgroundFetch && { fetchingPosts: true }),
          fetchingPostsError: null,
        },
      },
    },
    { deep: true }
  )

export const fetchPostsFailure = (state, { channelId, error }) =>
  state.merge(
    {
      channels: {
        [channelId]: {
          fetchingPosts: false,
          fetchingPostsError: String(error),
        },
      },
    },
    { deep: true }
  )

const filterCompletedCarePlans = (encounters, episodes) => {
  return Object.values(encounters)
    .filter((encounter) => {
      const status = encounter.status && encounter.status.toLowerCase().trim()
      return status === 'completed' && episodes[encounter.episode_id]
    })
    .reverse()
}

export const fetchEncountersSuccess = (state, { encounters = [] }) => {
  if (!encounters) return state

  let encountersMap = {}
  encounters.forEach((encounter) => {
    encountersMap[encounter.id] = encounter
  })
  const mergedEncounters = state.encounters.merge(encountersMap)

  return state.merge(
    {
      encounters: mergedEncounters,
      completedCarePlans: filterCompletedCarePlans(mergedEncounters, state.episodes),
    },
    { deep: true }
  )
}

export const changeDraftText = (state, { channelId, newText }) => {
  const channel = state.channels[channelId]
  if (channel) {
    return state.merge(
      {
        channels: {
          [channelId]: {
            draft: {
              text: newText,
            },
          },
        },
      },
      { deep: true }
    )
  } else {
    return state
  }
}

export const clearDraft = (state, { channelId }) => {
  const channel = state.channels[channelId]
  if (channel) {
    return state.merge(
      {
        channels: {
          [channelId]: {
            draft: {
              text: null,
              photo: null,
              localId: null,
            },
          },
        },
      },
      { deep: true }
    )
  } else {
    return state
  }
}

export const submitPostRequest = (
  state,
  { channelId, userId, answer, fileObj, text, timeStamp, flags = {}, payload }
) => {
  const channel = state.channels && state.channels[channelId]
  if (channel) {
    const currentPendingPosts = channel.pendingPosts || {}
    const currentPendingOrder = channel.pendingOrder || []
    const options = {
      file: fileObj,
      message: text,
      answer: answer,
      payload: payload,
      timeStamp,
      ...flags,
    }
    return state.merge(
      {
        channels: {
          [channelId]: {
            pendingPosts: {
              ...currentPendingPosts,
              [timeStamp]: generateBasicPost(userId, options),
            },
            pendingOrder: [timeStamp, ...currentPendingOrder],
          },
        },
      },
      { deep: true }
    )
  } else {
    return state
  }
}

export const retryPostRequest = (state, { channelId, postId }) => {
  const channel = state.channels && state.channels[channelId]
  if (channel) {
    let pendingPosts = { ...channel.pendingPosts }
    if (pendingPosts[postId]) {
      return state.merge(
        {
          channels: {
            [channelId]: {
              pendingPosts: {
                [postId]: {
                  props: {
                    failed: false,
                  },
                },
              },
            },
          },
        },
        { deep: true }
      )
    }
  }
  return state
}

export const removePendingPost = (state, { channelId, postId }) => {
  const channel = state.channels && state.channels[channelId]
  if (channel && channel.pendingPosts[postId]) {
    let pendingPosts = { ...channel.pendingPosts }
    const pendingOrder = channel.pendingOrder.filter((id) => id !== postId)
    delete pendingPosts[postId]
    return state.merge(
      {
        channels: {
          [channelId]: {
            pendingPosts,
            pendingOrder,
          },
        },
      },
      { deep: true }
    )
  }
  return state
}

export const setFailedPostError = (state, { channelId, showPostFailureError }) => {
  const channel = state.channels && state.channels[channelId]
  if (channel) {
    return state.merge(
      {
        channels: {
          [channelId]: {
            hasPostFailureError: showPostFailureError,
          },
        },
      },
      { deep: true }
    )
  }
  return state
}

/*
  Note:

  There's a race condition between this message post and the fetch posts long poll.
  When the posts long poll wins, we don't need to add the post anymore as it has already been added.
  Instead, we just remove the pending post and get on with our lives.

  And so it goes.
*/
export const submitPostSuccess = (state, { channelId, post }) => {
  const channel = state.channels[channelId]
  if (channel) {
    const postId = post.props && post.props.localId
    const pendingPostIndex = channel.pendingOrder.indexOf(postId)

    const { order } = channel
    const postAlreadyPresent = order.indexOf(post.id) !== -1
    let newPendingPosts = { ...channel.pendingPosts }
    let newPendingOrder = [...channel.pendingOrder]
    delete newPendingPosts[postId]
    newPendingOrder.splice(pendingPostIndex, 1)
    const lastFetchedPostId = post.id

    const nextPosts = { ...channel.posts, [post.id]: post }
    const nextOrder = postAlreadyPresent
      ? channel.order
      : [post.id, ...channel.order].sort((a, b) => nextPosts[b].create_at - nextPosts[a].create_at)

    return state.merge(
      {
        channels: {
          [channelId]: {
            posts: nextPosts,
            order: nextOrder,
            pendingPosts: newPendingPosts,
            pendingOrder: newPendingOrder,
            lastFetchedPostId,
          },
        },
      },
      { deep: true }
    )
  } else {
    return state
  }
}

export const submitPostFailure = (state, { channelId, localId, message }) => {
  const channel = state.channels[channelId]
  if (channel) {
    const pendingPosts = channel.pendingPosts
    const pendingPost = pendingPosts[localId]

    if (pendingPost) {
      return state.merge(
        {
          channels: {
            [channelId]: {
              pendingPosts: {
                ...pendingPosts,
                [localId]: {
                  ...pendingPost,
                  props: {
                    ...pendingPost.props,
                    retryCount: (pendingPost.props.retryCount || 0) + 1,
                    errorMessage: message,
                    failed: true,
                  },
                },
              },
            },
          },
        },
        { deep: true }
      )
    }
  }
  return state
}

export const updateChannelState = (state, { channelId, channelState }) => {
  const isActive =
    channelState === undefined ||
    channelState === 'active' ||
    channelState === 'pending' ||
    channelState === 'followup'
  const isArchived = channelState === 'archived'
  return state.merge(
    {
      channels: {
        [channelId]: {
          active: isActive,
          archived: isArchived,
        },
      },
    },
    { deep: true }
  )
}

export const updateChannelHeader = (state, { channelId, header }) => {
  let currentChannel = state.channels[channelId]
  if (!currentChannel) return state

  currentChannel = currentChannel.merge({
    header: header,
  })

  return state.merge(
    {
      channels: {
        [channelId]: currentChannel,
      },
    },
    { deep: true }
  )
}

export const updateChannelLastViewed = (state, { channelId }) => {
  try {
    if (!channelId || !state.channels || !state.channels[channelId]) return state
    const channel = state.channels[channelId]
    const lastPost = channel.order && channel.posts[channel.order[0]]
    const lastViewedAt = lastPost ? lastPost.create_at + 1 : Date.now()
    return state.merge(
      {
        currentChannelId: channelId,
        channels: {
          [channelId]: {
            lastViewedAt,
          },
        },
      },
      { deep: true }
    )
  } catch (err) {
    return state
  }
}

export const clear = () => INITIAL_STATE

export const cleanChannels = (state) => {
  const channels = state.channels

  let cleanedChannels = { ...channels }
  Object.keys(channels).forEach((channelId) => {
    const channel = channels[channelId]
    const { pendingPosts, pendingOrder } = channel

    if (pendingPosts && pendingOrder) {
      let cleanedPendingPosts = { ...pendingPosts }
      pendingOrder.forEach((pendingPostId) => {
        const pendingPost = pendingPosts[pendingPostId]
        cleanedPendingPosts[pendingPostId] = {
          ...pendingPost,
          props: {
            ...pendingPost.props,
            failed: true,
          },
        }
      })
      cleanedChannels[channelId] = {
        ...channel,
        pendingPosts: cleanedPendingPosts,
      }
    }
  })

  return state.merge(
    {
      channels: cleanedChannels,
    },
    { deep: true }
  )
}

export const receiveMembers = (state, { channelId, members }) => {
  let channel = state.channels[channelId]
  if (channel) {
    ;(channel.members || []).forEach((el) => {
      if (!members.find((member) => member.id === el.id)) {
        members.push(el)
      }
    })

    return state.merge(
      {
        channels: {
          [channelId]: {
            members: members,
          },
        },
      },
      { deep: true }
    )
  }

  return state
}

export const mattermostSocketClose = (state) => state.merge({ mmSocketNotReady: true })
export const mattermostSocketError = (state) => state.merge({ mmSocketNotReady: true })
export const mattermostSocketReady = (state) => state.merge({ mmSocketNotReady: false })
export const mattermostSocketDestroyed = (state) => state.merge({ mmSocketNotReady: true })
export const setInAppNotificationData = (state, { channelId, postId }) =>
  state.merge({ inAppNotificationPostId: postId, inAppNotificationChannelId: channelId })
export const setAllOlderPostsFetched = (state, { channelId, allOlderPostsFetched }) =>
  state.merge({ channels: { [channelId]: { allOlderPostsFetched } } }, { deep: true })

export const setHasUnreadChannels = (state, { hasUnreads }) =>
  state.merge({ hasUnreadChannels: hasUnreads })

export const getOrCreateWbiEpisodeRequest = (state) => {
  return state.merge({ createEpisodeRunning: true, createEpisodeError: null })
}

export const fetchAppointmentsRequest = (state) => {
  return state.merge({ fetchAppointmentsRunning: true, fetchAppointmentsError: null })
}

export const fetchAppointmentsSuccess = (state, { appointments }) => {
  return state.merge({ fetchAppointmentsRunning: false, appointments })
}

export const fetchAppointmentsFailure = (state, { error }) => {
  return state.merge({ fetchAppointmentsRunning: false, fetchAppointmentsError: error })
}

export const rescheduleAppointmentRequest = (state) => {
  return state.merge({ rescheduleAppointmentRunning: true, rescheduleAppointmentError: null })
}

export const rescheduleAppointmentSuccess = (state) => {
  return state.merge({ rescheduleAppointmentRunning: false, rescheduleAppointmentError: null })
}

export const rescheduleAppointmentFailure = (state, { error }) => {
  return state.merge({ rescheduleAppointmentRunning: false, rescheduleAppointmentError: error })
}

export const cancelAppointmentFromCalendarRequest = (state) => {
  return state.merge({
    cancelAppointmentFromCalendarRunning: true,
    cancelAppointmentFromCalendarFailure: null,
    cancelAppointmentFromCalendarSuccess: false,
  })
}

export const cancelAppointmentFromCalendarSuccess = (state) => {
  return state.merge({
    cancelAppointmentFromCalendarRunning: false,
    cancelAppointmentFromCalendarFailure: null,
    cancelAppointmentFromCalendarSuccess: true,
  })
}

export const cancelAppointmentFromCalendarFailure = (state, { error }) => {
  return state.merge({
    cancelAppointmentFromCalendarRunning: false,
    cancelAppointmentFromCalendarFailure: error,
    cancelAppointmentFromCalendarSuccess: false,
  })
}

export const resetCancelAppointmentFromCalendar = (state) => {
  return state.merge({
    cancelAppointmentFromCalendarRunning: false,
    cancelAppointmentFromCalendarFailure: null,
    cancelAppointmentFromCalendarSuccess: false,
  })
}

export const cancelAppointmentRequest = (state) => {
  return state.merge({ cancelAppointmentRunning: true, cancelAppointmentError: null })
}

export const cancelAppointmentSuccess = (state) => {
  return state.merge({ cancelAppointmentRunning: false, cancelAppointmentError: null })
}

export const cancelAppointmentFailure = (state, { error }) => {
  return state.merge({ cancelAppointmentRunning: false, cancelAppointmentError: error })
}

export const navigateToLink = (state, { link, context }) => {
  return state.merge({ link: link, linkContext: context, linkError: null })
}

export const navigateToLinkSuccess = (state) => {
  return state.merge({ link: null, linkContext: null })
}

export const navigateToLinkError = (state, { error }) => {
  return state.merge({ linkError: error })
}

export const updateEpisodeState = (state, { episode }) => state.merge({ episode })

export const updateEpisodeMembersState = (state, { members }) =>
  state.merge({ episodeMembers: members })

export const setTypingEventData = (state, { event }) => state.merge({ typingEvent: event })

export const getOrCreateHealthProfileEpisodeRequest = (state) => {
  return state.merge({ createEpisodeRunning: true, createEpisodeError: null })
}

export const archiveEpisodeRequest = (state) => {
  return state.merge({ archiveEpisodeRunning: true, archiveEpisodeError: null })
}

export const archiveEpisodeSuccess = (state) => {
  return state.merge({ archiveEpisodeRunning: false, archiveEpisodeError: null })
}

export const archiveEpisodeError = (state, { error }) => {
  return state.merge({ archiveEpisodeRunning: false, archiveEpisodeError: error })
}

export const setPostAsUndone = (state, { postId }) =>
  state.merge(
    {
      undonePostIds: [...state.undonePostIds, postId],
    },
    { deep: true }
  )

export const goBackInEpisodeRequest = (state, { channelId, postId }) => {
  return state.merge(
    {
      episodes: {
        [channelId]: {
          goBack: {
            error: null,
            running: true,
          },
        },
      },
      undonePostIds: [...state.undonePostIds, postId],
    },
    { deep: true }
  )
}

export const goBackInEpisodeSuccess = (state, { channelId, postId }) => {
  return state.merge(
    {
      episodes: {
        [channelId]: {
          ...EPISODE_DEFAULT_STATE,
        },
      },
      undonePostIds: [...state.undonePostIds, postId],
    },
    { deep: true }
  )
}

export const goBackInEpisodeError = (state, { error, channelId, postId }) => {
  return state.merge(
    {
      episodes: {
        [channelId]: {
          goBack: {
            error: error,
            running: false,
          },
        },
      },
      undonePostIds: state.undonePostIds.filter((id) => id !== postId),
    },
    { deep: true }
  )
}

export const PatientHistoryTypes = Types
export default Creators

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CHANGE_DRAFT_TEXT]: changeDraftText,
  [Types.CLEAN_CHANNELS]: cleanChannels,
  [Types.CLEAR_DRAFT]: clearDraft,
  [Types.CREATE_EPISODE_FAILURE]: createEpisodeFailure,
  [Types.CREATE_EPISODE_REQUEST]: createEpisodeRequest,
  [Types.GET_OR_CREATE_ICBT_EPISODE_REQUEST]: getOrCreateIcbtEpisodeRequest,
  [Types.CREATE_EPISODE_SUCCESS]: createEpisodeSuccess,
  [Types.FETCH_ENCOUNTERS_SUCCESS]: fetchEncountersSuccess,
  [Types.FETCH_EPISODES_REQUEST]: fetchEpisodeRequest,
  [Types.FETCH_EPISODE_SUCCESS]: fetchEpisodeSuccess,
  [Types.FETCH_EPISODE_FAILURE]: fetchEpisodeFailure,
  [Types.FETCH_CHANNELS_REQUEST]: fetchChannelsRequest,
  [Types.FETCH_CHANNELS_SUCCESS]: fetchChannelsSuccess,
  [Types.FETCH_CHANNELS_FAILURE]: fetchChannelsFailure,
  [Types.FETCH_NEW_POSTS_FOR_CHANNEL]: fetchPostsRequest,
  [Types.FETCH_ALL_POSTS_FOR_CHANNEL]: fetchPostsRequest,
  [Types.FETCH_OLDER_POSTS_FOR_CHANNEL]: fetchPostsRequest,
  [Types.FETCH_POSTS_FAILURE]: fetchPostsFailure,
  [Types.FETCH_POSTS_SUCCESS]: fetchPostsSuccess,
  [Types.FETCH_PRACTITIONERS_FAILURE]: fetchPractitionersFailure,
  [Types.FETCH_PRACTITIONERS_REQUEST]: fetchPractitionersRequest,
  [Types.FETCH_PRACTITIONERS_SUCCESS]: fetchPractitionersSuccess,

  [Types.FETCH_PRACTITIONER_BIO_FAILURE]: fetchPractitionerBioFailure,
  [Types.FETCH_PRACTITIONER_BIO_REQUEST]: fetchPractitionerBioRequest,
  [Types.FETCH_PRACTITIONER_BIO_SUCCESS]: fetchPractitionerBioSuccess,

  [Types.PATIENT_HISTORY_CLEAR]: clear,
  [Types.RECEIVE_CHANNEL_MEMBERS]: receiveMembers,
  [Types.RECEIVE_POSTS]: receivePosts,
  [Types.REMOVE_POSTS]: removePosts,
  [Types.RESET_CHANNELS]: resetChannels,
  [Types.RETRY_POST_REQUEST]: retryPostRequest,
  [Types.SUBMIT_ANSWER_POST_REQUEST]: submitPostRequest,
  [Types.SUBMIT_FILE_POST_REQUEST]: submitPostRequest,
  [Types.SUBMIT_POST_FAILURE]: submitPostFailure,
  [Types.SUBMIT_POST_REQUEST]: submitPostRequest,
  [Types.SUBMIT_POST_SUCCESS]: submitPostSuccess,
  [Types.SUBMIT_TEXT_POST_REQUEST]: submitPostRequest,
  [Types.SUBMIT_TEXT_WITH_PAYLOAD_POST_REQUEST]: submitPostRequest,
  [Types.UPDATE_CHANNEL_STATE]: updateChannelState,
  [Types.UPDATE_CHANNEL_HEADER]: updateChannelHeader,
  [Types.UPDATE_CHANNEL_LAST_VIEWED]: updateChannelLastViewed,
  [Types.MATTERMOST_SOCKET_CLOSE]: mattermostSocketClose,
  [Types.MATTERMOST_SOCKET_ERROR]: mattermostSocketError,
  [Types.MATTERMOST_SOCKET_READY]: mattermostSocketReady,
  [Types.MATTERMOST_SOCKET_DESTROYED]: mattermostSocketDestroyed,
  [Types.SET_IN_APP_NOTIFICATION_DATA]: setInAppNotificationData,
  [Types.SET_ALL_OLDER_POSTS_FETCHED]: setAllOlderPostsFetched,
  [Types.SET_HAS_UNREAD_CHANNELS]: setHasUnreadChannels,
  [Types.SET_FAILED_POST_ERROR]: setFailedPostError,
  [Types.GET_OR_CREATE_WBI_EPISODE_REQUEST]: getOrCreateWbiEpisodeRequest,
  [Types.FETCH_APPOINTMENTS_REQUEST]: fetchAppointmentsRequest,
  [Types.FETCH_APPOINTMENTS_SUCCESS]: fetchAppointmentsSuccess,
  [Types.FETCH_APPOINTMENTS_FAILURE]: fetchAppointmentsFailure,
  [Types.RESCHEDULE_APPOINTMENT_REQUEST]: rescheduleAppointmentRequest,
  [Types.RESCHEDULE_APPOINTMENT_SUCCESS]: rescheduleAppointmentSuccess,
  [Types.RESCHEDULE_APPOINTMENT_FAILURE]: rescheduleAppointmentFailure,
  [Types.CANCEL_APPOINTMENT_FROM_CALENDAR_REQUEST]: cancelAppointmentFromCalendarRequest,
  [Types.CANCEL_APPOINTMENT_FROM_CALENDAR_SUCCESS]: cancelAppointmentFromCalendarSuccess,
  [Types.CANCEL_APPOINTMENT_FROM_CALENDAR_FAILURE]: cancelAppointmentFromCalendarFailure,
  [Types.RESET_CANCEL_APPOINTMENT_FROM_CALENDAR]: resetCancelAppointmentFromCalendar,
  [Types.CANCEL_APPOINTMENT_REQUEST]: cancelAppointmentRequest,
  [Types.CANCEL_APPOINTMENT_SUCCESS]: cancelAppointmentSuccess,
  [Types.CANCEL_APPOINTMENT_FAILURE]: cancelAppointmentFailure,
  [Types.NAVIGATE_TO_LINK]: navigateToLink,
  [Types.NAVIGATE_TO_LINK_SUCCESS]: navigateToLinkSuccess,
  [Types.NAVIGATE_TO_LINK_ERROR]: navigateToLinkError,
  [Types.REMOVE_PENDING_POST]: removePendingPost,
  [Types.SET_TYPING_EVENT_DATA]: setTypingEventData,
  [Types.FETCH_ONE_EPISODE_SUCCESS]: fetchOneEpisodeSuccess,
  [Types.FETCH_EPISODE_MEMBERS_SUCCESS]: updateEpisodeMembersState,
  [Types.GET_OR_CREATE_HEALTH_PROFILE_EPISODE_REQUEST]: getOrCreateHealthProfileEpisodeRequest,
  [Types.ARCHIVE_EPISODE_REQUEST]: archiveEpisodeRequest,
  [Types.ARCHIVE_EPISODE_ERROR]: archiveEpisodeError,
  [Types.ARCHIVE_EPISODE_SUCCESS]: archiveEpisodeSuccess,
  [Types.SET_POST_AS_UNDONE]: setPostAsUndone,
  [Types.GO_BACK_IN_EPISODE_REQUEST]: goBackInEpisodeRequest,
  [Types.GO_BACK_IN_EPISODE_SUCCESS]: goBackInEpisodeSuccess,
  [Types.GO_BACK_IN_EPISODE_ERROR]: goBackInEpisodeError,
})
//TODO not used, but NTH for ChatScreen
export const selectConversationData = createSelector(
  [
    (state) => state.history.channels,
    (arg1, channelId) => channelId,
    (arg1, arg2, renderLimit) => renderLimit,
    (arg1, arg2, arg3, profile) => profile,
    (arg1, arg2, arg3, arg4, userId) => userId,
    (arg1, arg2, arg3, arg4, arg5, practitioners) => practitioners,
  ],
  (channels, channelId, renderLimit, profile, userId, practitioners) => {
    const conversation = channels[channelId]
    let posts = { ...(conversation.posts || {}), ...(conversation.pendingPosts || {}) }
    let order = [...(conversation.pendingOrder || []), ...(conversation.order || [])]
    order = order.slice(0, renderLimit)

    const postsLimited = {}
    const users = []
    users.push(getPatientMember(profile, userId))

    order.forEach((_, i) => {
      postsLimited[order[i]] = posts[order[i]]
      const postId = order[i]
      const userId = posts[postId].user_id
      const user = practitioners[userId]
      if (user) {
        const avatarUrl = user.avatar_url || (user.props && user.props.picture)
        users.push({ ...user, ...{ props: { picture: avatarUrl } } })
      }
    })

    posts = postsLimited

    const lastPostId = [...(conversation.pendingOrder || []), ...(conversation.order || [])][0]

    const orderedPosts = order.map((id) => posts[id])

    return {
      order,
      orderedPosts,
      posts,
      users,
      lastPostId,
    }
  }
)
