// TODO(WEB): Implement react-native-push-notification shim / mapping to web. (Or replace with a cross-platform lib)

export const unregister = () => {}
export const configure = () => {}
export const popInitialNotification = () => {}
export const setApplicationIconBadgeNumber = () => {}
export const getScheduledLocalNotifications = () => {}
export const cancelAllLocalNotifications = () => {}
export const cancelLocalNotification = () => {}
export const localNotificationSchedule = () => {}
export const createChannel = () => {}
export const checkPermissions = () => {
  return Promise.resolve({ alert: 1 })
}
export const requestPermissions = () => {
  Promise.resolve({ alert: 1 })
}

export const channelExists = (_, callback) => {
  callback(true)
}
export default {
  unregister,
  configure,
  popInitialNotification,
  setApplicationIconBadgeNumber,
  getScheduledLocalNotifications,
  cancelAllLocalNotifications,
  cancelLocalNotification,
  localNotificationSchedule,
  createChannel,
  checkPermissions,
  requestPermissions,
  channelExists,
}
