import Config from 'APP/Config'

export const getNameDisplay = (givenName, preferredName, pronouns, showPronouns, familyName) => {
  const hasPreferredName = preferredName && preferredName.length > 0
  let nameDisplay = hasPreferredName ? preferredName : givenName
  if (familyName) {
    nameDisplay = `${nameDisplay} ${familyName}`
  }
  if (showPronouns && pronouns) {
    nameDisplay = `${nameDisplay} (${pronouns})`
  }
  if (!nameDisplay) nameDisplay = null
  return { hasPreferredName, nameDisplay }
}

export const getPatientMember = (profile, myMessagingUserId) => {
  const { nameDisplay } = getNameDisplay(profile.givenName, profile?.preferredName)
  return {
    first_name: nameDisplay,
    last_name: profile.familyName,
    id: myMessagingUserId,
    avatar_url: profile.picture,
    props: {
      picture: profile.picture,
    },
  }
}

export const getShouldProvideProvince = () => Config.REGION === 'CA'
