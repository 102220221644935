import React, { useEffect, useRef } from 'react'
import { Animated, View, StyleSheet, Pressable } from 'react-native'
import Typography from 'APP/Converse/Typography'
import { RadioButtonProps } from './types'
import { useTheme } from 'styled-components/native'
import Styles from './style'

const BORDER_WIDTH = 2

const RadioButtonAndroid = ({
  disabled,
  label,
  onPress,
  value,
  status,
  testID,
}: RadioButtonProps) => {
  const theme = useTheme()
  const buttonColor = theme.colors?.text
  const { current: borderAnim } = useRef<Animated.Value>(new Animated.Value(BORDER_WIDTH))

  const { current: radioAnim } = useRef<Animated.Value>(new Animated.Value(1))

  const isFirstRendering = useRef<boolean>(true)

  useEffect(() => {
    // Do not run animation on very first rendering
    if (isFirstRendering.current) {
      isFirstRendering.current = false
      return
    }

    if (status === 'checked') {
      radioAnim.setValue(1.2)

      Animated.timing(radioAnim, {
        toValue: 1,
        duration: 150,
        useNativeDriver: true,
      }).start()
    } else {
      borderAnim.setValue(10)

      Animated.timing(borderAnim, {
        toValue: BORDER_WIDTH,
        duration: 150,
        useNativeDriver: false,
      }).start()
    }
  }, [status, borderAnim, radioAnim])
  const checked = status === 'checked'

  return (
    <Pressable
      disabled={disabled}
      onPress={() => onPress(value)}
      // @ts-expect-error We keep old a11y props for backwards compat with old RN versions
      accessibilityTraits={disabled ? ['button', 'disabled'] : 'button'}
      accessibilityComponentType={checked ? 'radiobutton_checked' : 'radiobutton_unchecked'}
      accessibilityRole="radio"
      accessibilityState={{ disabled, checked }}
      accessibilityChecked={checked}
      accessibilityLiveRegion="polite"
      testID={testID}
    >
      <View style={Styles.container}>
        <Animated.View
          style={[
            Styles.radio,
            {
              borderColor: buttonColor,
              borderWidth: borderAnim,
            },
          ]}
        >
          {checked ? (
            <View style={[StyleSheet.absoluteFill, Styles.radioContainer]}>
              <Animated.View
                style={[
                  Styles.dot,
                  {
                    backgroundColor: buttonColor,
                    transform: [{ scale: radioAnim }],
                  },
                ]}
              />
            </View>
          ) : null}
        </Animated.View>
        {Boolean(label) && <Typography variant="subheader">{label}</Typography>}
      </View>
    </Pressable>
  )
}

export default RadioButtonAndroid
