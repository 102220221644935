import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/native'
import moment from 'moment'

import { AvailabilitySlot } from '@dialogue/timekeeper'

import I18n from 'APP/Services/i18n'
import Typography from 'APP/Converse/Typography'
import {
  selectBookAppointmentRequest,
  selectLoadingAvailabilities,
  selectProviderPerDayAvailabilities,
  selectAvailabilityPerDateWithTime,
} from 'APP/Store/ProviderBooking/selectors'
import { GetAvailabilitiesParams } from 'APP/Store/ProviderBooking/types'
import useShowBookingSheet from 'APP/Hooks/useShowBookingSheet'

import { DayPicker } from './dayPicker'
import { NoAvailableSlots } from '../common/noAvailableSlots'
import { splitSlotsByDayPart } from '../utils/helpers'
import { ProviderSectionList } from './providerSectionList'
import { LoadingIndicator } from '../common/loadingIndicator'
import { TimeFrameMessage } from '../common/timeFrameMessage'
import { FilterAvailabilities } from '../common/filterAvailabilities'
import { BookingBottomSheet, SheetType } from '../TimeBased/timeslotSelection'

const Root = styled.View`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const ContentWrapper = styled.View`
  padding: ${({ theme }) => theme.metrics.baseMargin * 2}px
    ${({ theme }) => theme.metrics.baseMargin}px 0;
`

const ErrorCopy = styled(Typography)`
  margin-top: ${({ theme }) => theme.metrics.baseMargin}px;
`

const StyledTimeFrameMessage = styled(TimeFrameMessage)`
  text-align: left;
`

interface Props {
  dateUntil: string
  dateFrom: string
  onConfirm: (slot: AvailabilitySlot | null) => void
  onQuit: () => void
  onGetAvailabilities: (params?: Partial<GetAvailabilitiesParams>) => void
  appointmentType: string
}

export const ProviderSelection = ({
  dateFrom,
  dateUntil,
  onGetAvailabilities,
  onConfirm,
  onQuit,
  appointmentType,
}: Props) => {
  const showSheet = useShowBookingSheet(appointmentType)
  const [isSheetVisible, setIsSheetVisible] = useState(false)
  const isLoading = useSelector(selectLoadingAvailabilities)
  const availabilities = useSelector(selectProviderPerDayAvailabilities)
  const availabilitiesWithDateTime = useSelector(selectAvailabilityPerDateWithTime)

  const { error: bookAppointmentError } = useSelector(selectBookAppointmentRequest)

  const availabilitiesPerPartOfDay = useMemo(
    () => splitSlotsByDayPart(availabilities),
    [availabilities]
  )

  const hasAvailabilities = availabilities?.length > 0
  const firstAvailability = availabilities?.[0]

  const isFilterApplied = Boolean(firstAvailability?.provider_matching_attributes)
  const noMatchedProviders =
    !firstAvailability?.provider_matching_attributes?.matched_attributes?.length

  const timeFrameMessageKey =
    isFilterApplied && noMatchedProviders ? 'no_matched_providers' : 'recommended_timeframe'

  const nextAvailability = availabilitiesWithDateTime?.[0]

  const handleOpenSheet = () => setIsSheetVisible(true)
  const handleCloseSheet = () => setIsSheetVisible(false)
  const handleSubmitSheet = () => {
    handleCloseSheet()
    onQuit()
  }

  return (
    <Root>
      <DayPicker
        dateFrom={dateFrom}
        dateUntil={dateUntil}
        availabilities={availabilitiesWithDateTime}
      />
      {!isLoading && (
        <ContentWrapper>
          {bookAppointmentError && (
            <ErrorCopy variant="bodyNormal" align="center" color="error">
              {I18n.t('AppointmentBooking.book_appointment_error')}
            </ErrorCopy>
          )}
          {!bookAppointmentError && hasAvailabilities && (
            <>
              <StyledTimeFrameMessage
                dateFrom={moment(dateFrom)}
                dateUntil={moment(dateUntil)}
                messageKey={`AppointmentBooking.${timeFrameMessageKey}`}
              />
              {!isFilterApplied && <FilterAvailabilities onSubmit={onGetAvailabilities} />}
              <ProviderSectionList
                slots={availabilitiesPerPartOfDay}
                onConfirm={onConfirm}
                onQuit={showSheet ? handleOpenSheet : onQuit}
              />
            </>
          )}
          {!bookAppointmentError && !hasAvailabilities && (
            <NoAvailableSlots
              hasNextAvailability
              onQuit={showSheet ? handleOpenSheet : onQuit}
              nextAvailability={nextAvailability}
            />
          )}
          <BookingBottomSheet
            isVisible={isSheetVisible}
            onCloseModal={handleCloseSheet}
            onConfirmCancel={handleSubmitSheet}
            sheetType={SheetType.AVAILABLE_APPOINTMENT}
          />
        </ContentWrapper>
      )}
      {isLoading && <LoadingIndicator />}
    </Root>
  )
}
