import _ from 'lodash'

type Preferences = {
  push?: {
    [key: string]: boolean
  }
  sms?: {
    [key: string]: boolean
  }
  // TODO: uncomment when email endpoint is available
  // email?: {
  //   [key: string]: boolean
  // }
}

// Appointment reminders and intake reminders are grouped together
const REMINDERS = ['appointmentReminders', 'intakeReminders']

export const ICONS_MAPPING = {
  messagesAndVideoCalls: 'cellphone-message',
  reminders: 'bell-badge-outline',
  challengeUpdates: 'trophy-outline',
  brandNewContent: 'star-outline',
  importantAnnouncements: 'bullhorn-outline',
  wbiReminders: 'speedometer-medium',
}

export const PREFERENCE_MAPPING = {
  // TODO: uncomment when email endpoint is available
  // email: ['intakeReminders'],
  push: [
    'messagesAndVideoCalls',
    'brandNewContent',
    'challengeUpdates',
    'habitReminders',
    'importantAnnouncements',
    'wbiReminders',
    'intakeReminders',
  ],
  sms: ['appointmentReminders'],
}

export const getKeySnakeCase = (key: string) => {
  return key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}

export const buildNotificationCenterData = (preferences: Preferences) => {
  const pushNotifications = _.concat(['messagesAndVideoCalls'], _.keys(preferences?.['push']) || [])
  const smsNotifications = _.keys(preferences?.['sms']) || []
  // TODO: uncomment when email endpoint is available, and add it to the notifications array
  // const emailNotifications = _.keys(preferences?.['email']) || []

  const notifications = _.concat(pushNotifications, smsNotifications)

  const sorted = _.chain(notifications)
    .map((notification) => {
      if (REMINDERS.includes(notification)) {
        return 'reminders'
      }
      return notification
    })
    .uniq()
    .value()

  const remindersIndex = sorted.indexOf('reminders')
  if (remindersIndex > -1) {
    // Reminders should be the second item, after "Messages and Video Calls"
    sorted.splice(remindersIndex, 1)
    sorted.splice(1, 0, 'reminders')
    return sorted
  }
  return sorted
}

export const buildSMSPreference = (
  newNotificationCenter: boolean,
  appointmentReminders: boolean = true,
  isEnabled: boolean = true
) => {
  const preference = {
    appointment_reminders: appointmentReminders,
    is_enabled: isEnabled,
  }
  return newNotificationCenter ? { sms: preference } : preference
}
