import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Linking, Platform } from 'react-native'
import { useNavigation } from '@react-navigation/native'

import { Styles } from './style'

import I18n from 'APP/Services/i18n'
import PatientHistoryActions from 'APP/Redux/PatientHistoryRedux'

import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import {
  checkLocationService,
  checkLocationState,
  requestLocationService,
} from 'APP/NativeModules/PermissionsService'
import { isWeb } from 'APP/Helpers/checkPlatform'

const Notices = ({ conversation, channelId, children }) => {
  const navigation = useNavigation()
  const [locationPermission, setLocationPermission] = useState('granted')
  const [locationServiceEnabled, setLocationServiceEnabled] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    checkLocationState().then((response) => setLocationPermission(response))
    checkLocationService().then((result) => setLocationServiceEnabled(result))
  })

  const getErrorNotices = () => {
    if (conversation?.missingData) {
      return {
        title: I18n.t('ConversationScreen.missingData.title'),
        text: I18n.t('ConversationScreen.missingData.text'),
        iconName: 'refresh',
        onPress: dispatch(PatientHistoryActions.fetchAllPostsForChannel(channelId)),
      }
    }
    return null
  }

  const getNotices = () => {
    const errorNotices = getErrorNotices()
    if (errorNotices) return [errorNotices]
    return [geolocationNotice()]
  }

  const geolocationNotice = () => {
    if (isWeb()) {
      // Do not display location permissions on web
      return null
    } else if (locationPermission === 'denied') {
      return {
        title: I18n.t('GeolocationScreen.locationPermissionsSkippedTitle'),
        text: I18n.t('GeolocationScreen.locationPermissionsSkippedText'),
        onPress: () => navigation.navigate('updateGeolocation'),
      }
    } else if (locationPermission === 'unavailable') {
      return {
        title: I18n.t('GeolocationScreen.locationPermissionsUnavailableTitle'),
        text: I18n.t('GeolocationScreen.locationPermissionsUnavailableText'),
        onPress: Linking.openSettings,
      }
    } else if (locationPermission !== 'granted') {
      return {
        title: I18n.t('GeolocationScreen.locationPermissionsDisabledTitle'),
        text: I18n.t('GeolocationScreen.locationPermissionsDisabledText'),
        onPress: Linking.openSettings,
      }
    } else if (Platform.OS === 'android' && !locationServiceEnabled) {
      return {
        title: I18n.t('GeolocationServiceScreen.locationPermissionsDisabledTitle'),
        text: I18n.t('GeolocationServiceScreen.locationPermissionsDisabledText'),
        onPress: requestLocationService,
      }
    }
    return null
  }

  return (
    <NoticeBarContainer containerStyle={Styles.noticeContainer} top localNotices={getNotices()}>
      {children}
    </NoticeBarContainer>
  )
}

export default Notices
