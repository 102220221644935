import { StyleSheet } from 'react-native'
import styled from 'styled-components/native'
import { ApplicationStyles, Metrics, Colors, Fonts } from 'APP/Themes'

export const MAX_WIDTH = 300
export const LIST_ITEM_MARGIN = Metrics.baseMargin * 0.75

export const StyledHeaderContainer = styled.View`
  flex-direction: row;
  align-items: center;
  margin-left: ${({ theme }) => theme.metrics.baseMargin * 1.5}px;
  margin-right: ${({ theme }) => theme.metrics.baseMargin * 1.5}px;
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin / 2}px;
`

export default StyleSheet.create({
  header: {
    ...Fonts.style.h3,
    flexGrow: 1,
    color: Colors.text,
  },
  listItem: {
    margin: LIST_ITEM_MARGIN,
    backgroundColor: Colors.elementsBg,
    borderRadius: 16,
    ...ApplicationStyles.shadow,
  },
  flatList: {
    paddingLeft: Metrics.baseMargin * 1.5,
    paddingRight: Metrics.baseMargin,
  },
  imageWrapper: {
    position: 'relative',
    overflow: 'hidden',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  image: {
    height: 150,
    width: MAX_WIDTH,
  },
  description: {
    paddingTop: Metrics.baseMargin * 0.3,
    paddingRight: Metrics.baseMargin * 0.6,
    paddingBottom: Metrics.baseMargin * 0.75,
    paddingLeft: Metrics.baseMargin * 0.8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    maxWidth: MAX_WIDTH,
  },
  title: {
    ...Fonts.style.h4,
    marginTop: Metrics.baseMargin / 3,
    marginBottom: 12,
    color: Colors.text,
  },
  subtitleSection: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
  },
  subtitle: {
    ...Fonts.style.bodySmall,
    color: Colors.text,
  },
  scrollIcon: {
    paddingTop: Metrics.baseMargin * 0.75,
    paddingBottom: Metrics.baseMargin * 0.75,
  },
  scrollIconRight: {
    paddingLeft: Metrics.baseMargin / 2,
  },
  scrollIconLeft: {
    paddingRight: Metrics.baseMargin / 2,
  },
})
