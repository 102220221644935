import React, { useEffect, useRef, useState } from 'react'
import { Animated, View, StyleSheet } from 'react-native'
import { equals, union } from 'ramda'

import { useOnLayout } from 'APP/Hooks/Layout'

const getChildrenStateFromProps = (children) => {
  const dict = {}
  const order = []

  React.Children.forEach(children, (child) => {
    dict[child.key] = child
    order.push(child.key)
  })

  return {
    dict,
    order,
  }
}

const IntakeTransition = ({ children: childrenProp, animatedKey, style }) => {
  const [childrenState, setChildrenState] = useState(getChildrenStateFromProps(childrenProp))
  const nextChildrenOrder = getChildrenStateFromProps(childrenProp).order
  const shouldAnimate = !equals(childrenState.order, nextChildrenOrder)

  const [{ height: containerHeight }, onContainerLayout] = useOnLayout()
  const animatedState = useRef(new Animated.Value(1)).current

  useEffect(() => {
    setChildrenState(({ order, dict }) => {
      const { order: nextDesiredOrder, dict: nextDict } = getChildrenStateFromProps(childrenProp)

      const nextChildrenState = {
        dict: { ...dict, ...nextDict },
        order: union(order, nextDesiredOrder).sort((a) => (a === animatedKey ? 1 : -1)),
      }
      return nextChildrenState
    })
  }, [childrenProp])

  useEffect(() => {
    if (!shouldAnimate) return
    const animatedKeyExiting = nextChildrenOrder.indexOf(animatedKey) === -1
    animatedState.setValue(animatedKeyExiting ? 1 : 0)
    Animated.spring(animatedState, {
      toValue: animatedKeyExiting ? 2 : 1,
      damping: 18,
      useNativeDriver: true,
    }).start(() => {
      setChildrenState((prevState) => ({ ...prevState, order: nextChildrenOrder }))
    })
  }, [shouldAnimate])

  const animatedStyles = {
    transform: [
      {
        translateY: animatedState.interpolate({
          inputRange: [0, 1, 2],
          outputRange: [containerHeight, 0, -containerHeight],
        }),
      },
    ],
  }

  return (
    <View
      style={style}
      onLayout={onContainerLayout}
      pointerEvents={shouldAnimate ? 'none' : 'auto'}
    >
      {childrenState.order.map((key) => (
        <Animated.View
          key={key}
          style={[
            style,
            shouldAnimate && { height: containerHeight, ...StyleSheet.absoluteFillObject },
            key === animatedKey && animatedStyles,
          ]}
        >
          {childrenState.dict[key]}
        </Animated.View>
      ))}
    </View>
  )
}

export default IntakeTransition
