import { useEffect, memo } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch, useSelector } from 'react-redux'
import Alert from 'APP/Converse/Alert'
import I18n from 'APP/Services/i18n'
import LoginActions from 'APP/Redux/LoginRedux'
import { PublicComputerType } from 'APP/Lib/Enums'
import { INACTIVITY_TIMEOUT, MINUTE_IN_MILLISECOND } from 'APP/Lib/Utilities'

const IdleTimer = () => {
  const dispatch = useDispatch()
  const { accessToken, publicComputer } = useSelector((state) => state.login)
  const onPrompt = () => {
    Alert.alert(I18n.t('InactiveWarningScreen.title'), I18n.t('InactiveWarningScreen.subtitle'), [
      { text: I18n.t('InactiveWarningScreen.cta') },
    ])
  }

  const onIdle = () => {
    dispatch(LoginActions.logout())
  }

  const onAction = () => {
    start()
    dispatch(LoginActions.updateLastActivity())
  }

  const { start, pause } = useIdleTimer({
    onPrompt,
    onAction,
    onIdle,
    promptBeforeIdle: MINUTE_IN_MILLISECOND,
    timeout: INACTIVITY_TIMEOUT,
    events: [
      'keydown',
      'mousedown',
      'touchstart',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    eventsThrottle: 500,
    startOnMount: false,
    startManually: true,
  })
  useEffect(() => {
    // When the user is logged in, the user is on a
    // public computer, we can start the idle timer
    if (publicComputer !== PublicComputerType.ANSWERED_NO && accessToken) {
      start()
    } else {
      pause()
    }
  }, [publicComputer, accessToken])

  return null
}

export default memo(IdleTimer)
