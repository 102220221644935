import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Fonts, Metrics } from '../../Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  launchpadHeader: {
    color: Colors.text,
    paddingHorizontal: Metrics.baseMargin * 0.5,
    marginBottom: Metrics.baseMargin * 0.5,
    ...Fonts.style.h3,
  },
  cardContainer: {
    padding: 16,
    marginVertical: Metrics.baseMargin * 0.5,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: Metrics.baseMargin,
    backgroundColor: Colors.elementsBg,
    ...ApplicationStyles.shadow,
  },
  copyContainer: {
    flex: 1,
  },
  cardTitle: {
    color: Colors.text,
    paddingBottom: Metrics.baseMargin * 0.25,
    ...Fonts.style.bodySmallBold,
  },
  cardBody: {
    color: Colors.text,
    ...Fonts.style.bodySmall,
  },
})
