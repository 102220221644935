import { StyleSheet } from 'react-native'
import { ApplicationStyles, Metrics, Colors, Fonts } from '../../Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  illustration: {
    marginTop: Metrics.baseMargin * 2,
    marginBottom: Metrics.baseMargin * 2,
    alignSelf: 'center',
  },
  textInput: {
    ...Fonts.style.bodyLarge,
    color: Colors.text,
    borderBottomColor: Colors.grayBg,
    borderBottomWidth: 0,
    padding: 0,
    marginTop: 0,
  },
  label: {
    marginLeft: Metrics.baseMargin,
    fontSize: Fonts.size.small,
    color: Colors.text,
  },
  error: {
    color: Colors.errorText,
    marginTop: 4,
    marginLeft: Metrics.baseMargin,
    fontSize: Fonts.size.small,
  },
  body: {
    color: Colors.text,
    marginVertical: Metrics.baseMargin / 2,
    marginHorizontal: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin * 2,
    flex: 1,
    textAlign: 'center',
    ...Fonts.style.body,
  },
  scrollView: {
    paddingBottom: Metrics.baseMargin,
  },
  buttonContainer: {
    padding: Metrics.baseMargin,
  },
})
