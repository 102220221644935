import { videoSessionActions } from 'APP/Store/VideoSession'

import { signals, storeName } from './constants'

export const createMiddleware = (openTokInterface) => {
  return (store) => (next) => (action) => {
    // eslint-disable-line no-unused-vars
    const videoSessionState = store.getState() ? store.getState()[storeName] : {}
    const { apiKey, sessionId, token, userId } = action.payload || {}
    switch (action.type) {
      case videoSessionActions.connectToSession.type:
        openTokInterface.createSession(apiKey, sessionId, token)
        break
      case videoSessionActions.disconnectFromSession.type:
        openTokInterface.disconnect()
        break
      case videoSessionActions.patientRinging.type:
        openTokInterface.sendSignal(signals.RINGING, {
          [signals.properties.USER_ID]: userId,
          [signals.properties.SENDER]: signals.properties.senderTypes.PATIENT,
          [signals.properties.CALL_ID]: videoSessionState.callId,
        })
        break
      case videoSessionActions.patientAcceptedCall.type:
        openTokInterface.sendSignal(signals.ACCEPTED_CALL, {
          [signals.properties.USER_ID]: userId,
          [signals.properties.SENDER]: signals.properties.senderTypes.PATIENT,
          [signals.properties.CALL_ID]: videoSessionState.callId,
        })
        break
      case videoSessionActions.patientAskingAudioPermission.type:
        openTokInterface.sendSignal(signals.ASKING_FOR_PERMISSION, {
          [signals.properties.USER_ID]: userId,
          [signals.properties.SENDER]: signals.properties.senderTypes.PATIENT,
          [signals.properties.PERMISSIONS_TYPE]: signals.properties.permissionTypes.AUDIO,
          [signals.properties.CALL_ID]: videoSessionState.callId,
        })
        break
      case videoSessionActions.patientAskingVideoPermission.type:
        openTokInterface.sendSignal(signals.ASKING_FOR_PERMISSION, {
          [signals.properties.USER_ID]: userId,
          [signals.properties.SENDER]: signals.properties.senderTypes.PATIENT,
          [signals.properties.PERMISSIONS_TYPE]: signals.properties.permissionTypes.VIDEO,
          [signals.properties.CALL_ID]: videoSessionState.callId,
        })
        break
      case videoSessionActions.patientAcceptedAudioPermission.type:
        openTokInterface.sendSignal(signals.ACCEPTED_PERMISSION, {
          [signals.properties.USER_ID]: userId,
          [signals.properties.SENDER]: signals.properties.senderTypes.PATIENT,
          [signals.properties.PERMISSIONS_TYPE]: signals.properties.permissionTypes.AUDIO,
          [signals.properties.CALL_ID]: videoSessionState.callId,
        })
        break
      case videoSessionActions.patientAcceptedVideoPermission.type:
        openTokInterface.sendSignal(signals.ACCEPTED_PERMISSION, {
          [signals.properties.USER_ID]: userId,
          [signals.properties.SENDER]: signals.properties.senderTypes.PATIENT,
          [signals.properties.PERMISSIONS_TYPE]: signals.properties.permissionTypes.VIDEO,
          [signals.properties.CALL_ID]: videoSessionState.callId,
        })
        break
      case videoSessionActions.patientDeclinedAudioPermission.type:
        openTokInterface.sendSignal(signals.DECLINED_PERMISSION, {
          [signals.properties.USER_ID]: userId,
          [signals.properties.SENDER]: signals.properties.senderTypes.PATIENT,
          [signals.properties.PERMISSIONS_TYPE]: signals.properties.permissionTypes.AUDIO,
          [signals.properties.CALL_ID]: videoSessionState.callId,
        })
        break
      case videoSessionActions.patientDeclinedVideoPermission.type:
        openTokInterface.sendSignal(signals.DECLINED_PERMISSION, {
          [signals.properties.USER_ID]: userId,
          [signals.properties.SENDER]: signals.properties.senderTypes.PATIENT,
          [signals.properties.PERMISSIONS_TYPE]: signals.properties.permissionTypes.VIDEO,
          [signals.properties.CALL_ID]: videoSessionState.callId,
        })
        break
      case videoSessionActions.patientEndedCall.type:
        openTokInterface.sendSignal(signals.ENDED_CALL, {
          [signals.properties.USER_ID]: userId,
          [signals.properties.SENDER]: signals.properties.senderTypes.PATIENT,
          [signals.properties.CALL_ID]: videoSessionState.callId,
        })
        break
      case videoSessionActions.patientMutedAudio.type:
        openTokInterface.sendSignal(signals.MUTED_AUDIO, {
          [signals.properties.USER_ID]: userId,
          [signals.properties.SENDER]: signals.properties.senderTypes.PATIENT,
          [signals.properties.CALL_ID]: videoSessionState.callId,
        })
        break
      case videoSessionActions.patientUnmutedAudio.type:
        openTokInterface.sendSignal(signals.UNMUTED_AUDIO, {
          [signals.properties.USER_ID]: userId,
          [signals.properties.SENDER]: signals.properties.senderTypes.PATIENT,
          [signals.properties.CALL_ID]: videoSessionState.callId,
        })
        break
    }
    next(action)
  }
}
