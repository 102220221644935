import { StyleSheet } from 'react-native'
import { Colors, Fonts, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: Metrics.baseMargin * 1.5,
    paddingBottom: Metrics.baseMargin,
  },
  headerTitle: {
    ...Fonts.style.h3,
    color: Colors.text,
  },
  addCta: {
    flexDirection: 'row',
  },
  addCtaText: {
    ...Fonts.style.button,
    color: Colors.text,
    paddingLeft: Metrics.baseMargin / 3,
  },
})
