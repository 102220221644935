import React from 'react'
import Analytics from 'APP/Services/Analytics'
import Typography from 'APP/Converse/Typography'
import Icon from 'APP/Converse/Icon'

import { ListItemProps } from './types'
import { StyledListItem, StyledListItemContainer } from './style'

const ListItem = ({
  title,
  titleVariant = 'subheaderBold',
  TitleComponent,
  subtitle,
  subtitleVariant = 'bodySmall',
  SubtitleComponent,
  rightTitle,
  hideIconComponent = false,
  iconName = 'chevron-right',
  IconComponent,
  analyticsName,
  onPress,
  selected = false,
  leftIconName,
  ...butter
}: ListItemProps) => {
  const onWrappedPress = (nativeEvent) => {
    if (onPress) {
      if (analyticsName) {
        Analytics.trackEvent('button_click', { button_value: analyticsName })
      }
      onPress(nativeEvent)
    }
  }

  return (
    <StyledListItem selected={selected} onPress={onWrappedPress} testID="LIST_ITEM" {...butter}>
      {leftIconName && (
        <Icon
          src={leftIconName}
          size={24}
          color="text"
          variant="material"
          testID="LIST_ITEM_LEFT_ICON_COMPONENT"
        />
      )}
      <StyledListItemContainer testID="LIST_ITEM_LEFT_CONTAINER">
        {title &&
          (TitleComponent ? (
            <TitleComponent testID="LIST_ITEM_TITLE_COMPONENT">{title}</TitleComponent>
          ) : (
            <Typography variant={titleVariant} testID="LIST_ITEM_TITLE">
              {title}
            </Typography>
          ))}
        {subtitle &&
          (SubtitleComponent ? (
            <SubtitleComponent testID="LIST_ITEM_SUBTITLE_COMPONENT">{subtitle}</SubtitleComponent>
          ) : (
            <Typography variant={subtitleVariant} testID="LIST_ITEM_SUBTITLE">
              {subtitle}
            </Typography>
          ))}
      </StyledListItemContainer>
      {rightTitle && (
        <StyledListItemContainer right testID="LIST_ITEM_RIGHT_CONTAINER">
          <Typography variant="bodySmall" testID="LIST_ITEM_RIGHT_TITLE">
            {rightTitle}
          </Typography>
        </StyledListItemContainer>
      )}
      {!hideIconComponent &&
        (IconComponent ? (
          <IconComponent testID="LIST_ITEM_ICON_COMPONENT" />
        ) : (
          <Icon
            src={iconName}
            size={24}
            color="accent"
            variant="material"
            testID="LIST_ITEM_ICON_COMPONENT"
          />
        ))}
    </StyledListItem>
  )
}

export default ListItem
