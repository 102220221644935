// TODO: Figure out how we want to interact with tracker connection on web
//

export const CustomPermissions = {}
export const Connections = {}

export const initTerra = (...args) => {
  console.log('🦆 terra-react.initTerra()', ...args)
}

export const checkAuth = (...args) => {
  console.log('🦆 terra-react.checkAuth()', ...args)
}

export const initConnection = (...args) => {
  console.log('🦆 terra-react.initConnection()', ...args)
}

export const getDaily = (...args) => {
  console.log('🦆 terra-react.getDaily()', ...args)
}

export const getActivity = (...args) => {
  console.log('🦆 terra-react.getActivity()', ...args)
}

export const getSleep = (...args) => {
  console.log('🦆 terra-react.getSleep()', ...args)
}

export const getNutrition = (...args) => {
  console.log('🦆 terra-react.getNutrition()', ...args)
}

export const getBody = (...args) => {
  console.log('🦆 terra-react.getBody()', ...args)
}

export const getMenstruation = (...args) => {
  console.log('🦆 terra-react.getMenstruation()', ...args)
}

export const getAthlete = (...args) => {
  console.log('🦆 terra-react.getAthlete()', ...args)
}

export const readGlucoseData = (...args) => {
  console.log('🦆 terra-react.readGlucoseData()', ...args)
}

export const activateSensor = (...args) => {
  console.log('🦆 terra-react.activateSensor()', ...args)
}

export const getUserId = (...args) => {
  console.log('🦆 terra-react.getUserId()', ...args)
}
