import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'

import ServiceButtonCard from 'APP/Components/ServiceButtonCard'
import { isWeb } from 'APP/Helpers/checkPlatform'
import { featuresState } from 'APP/Sagas/FeaturesSaga'
import Analytics from 'APP/Services/Analytics'
import I18n from 'APP/Services/i18n'

import { Colors } from 'APP/Themes'
import Styles from './style'

const TakeATour = () => {
  const navigation = useNavigation()
  const { takeATour } = useSelector(featuresState)
  const eligibleServices = useSelector((state) => state?.patient?.profile?.eligibleServices) || {}
  const hasPrimaryCare = Object.keys(eligibleServices).includes('physical_health_consults_md_np')
  const { orderedEpisodes } = useSelector((state) => state.history)

  // Not displaying on web since we don't have web assets for this carousel
  if (isWeb() || !takeATour || !hasPrimaryCare || orderedEpisodes?.length > 0) {
    return null
  }

  const onPress = () => {
    Analytics.trackEvent('button_click', {
      button_value: 'Take a tour',
    })
    navigation.navigate('takeATour')
  }

  return (
    <View style={Styles.container}>
      {/* Technically not a "Service", but since it's a experiment it's worth reusing this ServiceButtonCard for simplicity  */}
      <ServiceButtonCard
        testID="TakeATourButtonCard"
        tag={<MaterialIcon name="star-outline" size={16} color={Colors.darkText} />}
        iconTitle={<MaterialIcon name="lightbulb-outline" size={24} color={Colors.accent} />}
        title={I18n.t('TakeATour.info.title')}
        description={I18n.t('TakeATour.info.description')}
        onPress={onPress}
      />
    </View>
  )
}

export default TakeATour
