import React, { useContext } from 'react'
import { View, Text, TouchableHighlight } from 'react-native'

import { Colors } from 'APP/Themes'
import { Styles } from './style'
import Analytics from 'APP/Services/Analytics'

import { ChatContext, PostContext } from 'APP/Lib/Context'

const SingleChoice = () => {
  const { sendSingleChoiceAnswer } = useContext(ChatContext)
  const { post } = useContext(PostContext)

  const choices = post?.props?.question?.data?.choices || post.props.choices

  const onPress = (choice) => {
    Analytics.trackEvent('button_click', { button_value: 'Single choice selected' })
    sendSingleChoiceAnswer(post, choice)
  }

  return (
    <View style={Styles.singleChoice}>
      {choices.map((choice, i, array) => (
        <TouchableHighlight
          key={i}
          underlayColor={Colors.multiSelectMsgBg}
          style={[
            Styles.singleChoiceOption,
            i === 0 && Styles.firstSingleChoiceOption,
            i === array.length - 1 && Styles.lastSingleChoiceOption,
          ]}
          onPress={() => onPress(choice)}
        >
          <Text style={Styles.singleChoiceOptionText}>{choice.text || choice.display}</Text>
        </TouchableHighlight>
      ))}
    </View>
  )
}

export default SingleChoice
