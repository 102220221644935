import { StyleSheet } from 'react-native'
import { Colors, Fonts, Metrics, ApplicationStyles } from 'APP/Themes'

export default StyleSheet.create({
  container: {
    paddingHorizontal: Metrics.baseMargin * 1.25,
  },
  bottomSpace: {
    paddingBottom: Metrics.bottomSpace,
  },
  networkSection: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  hidden: {
    opacity: 0,
  },
  row: {
    flexDirection: 'row',
  },
  label: {
    ...Fonts.style.captionBold,
    color: Colors.textSecondary,
    paddingLeft: Metrics.baseMargin / 2,
  },
  toggleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: Metrics.baseMargin,
    alignItems: 'center',
    paddingVertical: Metrics.baseMargin,
    paddingHorizontal: Metrics.baseMargin / 2,
  },
  toggleThumb: {
    width: 11,
    height: 11,
  },
  toggleThumbOn: {
    transform: [{ translateX: 10 }],
  },
  toggleTrack: {
    width: 28,
    height: 15,
    padding: 0,
  },
  preview: {
    backgroundColor: Colors.elementsBg,
    borderRadius: 20,
    paddingHorizontal: 20,
    marginHorizontal: Metrics.baseMargin / 2,
    marginBottom: Metrics.baseMargin * 1.5,
    paddingTop: 15,
    paddingBottom: 13,
    ...ApplicationStyles.shadow,
  },
  previewIcon: {
    width: 16,
    resizeMode: 'contain',
    borderRadius: 4,
  },
  previewTitle: {
    ...Fonts.style.bodySmallBold,
    color: Colors.textSecondary,
    paddingLeft: 5,
  },
  reminderTitle: {
    ...Fonts.style.subheaderBold,
    color: Colors.text,
    paddingTop: 6,
  },
  reminderInstruction: {
    ...Fonts.style.subheader,
    color: Colors.textSecondary,
    paddingTop: 6,
  },
  weekDayPicker: {
    paddingTop: Metrics.baseMargin,
  },
  dateInput: {
    marginHorizontal: Metrics.baseMargin / 2,
    marginBottom: Metrics.baseMargin,
  },
  save: {
    marginHorizontal: 0,
  },
})
