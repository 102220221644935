import React from 'react'

import { CompareChart } from '../Charts'
import DefaultRecommendation from './DefaultRecommendation'

const CompareRecommendation = (props) => (
  <>
    <CompareChart score={props.score} />
    <DefaultRecommendation {...props} />
  </>
)

export default CompareRecommendation
