import React from 'react'
import { ActivityIndicator, Text, View } from 'react-native'

// I18n
import I18n from 'APP/Services/i18n'

// Styles
import Styles from './style'
import { Colors } from 'APP/Themes'

export const AttachmentLoadingOverlay = ({ text }) => {
  {
    return (
      <View style={Styles.container}>
        <View style={Styles.innerBox}>
          <Text style={Styles.text}>{text || I18n.t('ConversationScreen.loading')}</Text>
          <ActivityIndicator size="large" color={Colors.text} style={Styles.spinner} />
        </View>
      </View>
    )
  }
}
