import React from 'react'
import { Colors, Metrics } from 'APP/Themes'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'

import styled from 'styled-components/native'
import Typography from 'APP/Converse/Typography'
import { useBottomDrawer } from './provider'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import { ButtonProps } from '../Button'
import { TouchableOpacity } from 'react-native'

const Container = styled.View`
  flex-direction: column;
`

const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.text};
  margin: ${Metrics.baseMargin}px 0;
`

const Button = styled(DialogueTouchableOpacity)`
  align-self: flex-end;
`

export type BottomDrawerHeaderProps = {
  title: string
  closeButtonProps?: Pick<ButtonProps, 'analyticsName' | 'testID'> & TouchableOpacity
}

function Header({ title, closeButtonProps }: BottomDrawerHeaderProps) {
  const { onClose } = useBottomDrawer()

  return (
    <Container>
      <Button
        onPress={onClose}
        accessibilityLabel="Close drawer"
        accessibilityRole="button"
        testID="closeButton"
        {...closeButtonProps}
      >
        <MaterialIcon
          name="close"
          size={Metrics.icons.small}
          color={Colors.text}
          testID="modal.close"
        />
      </Button>
      <Title variant="h3">{title}</Title>
    </Container>
  )
}

export default Header
