import React from 'react'
import { Dimensions } from 'react-native'
import MultiSlider from '@ptomasroos/react-native-multi-slider'
import { Slider } from '@miblanchard/react-native-slider'
import { isWeb } from 'APP/Helpers/checkPlatform'
import { Colors } from 'APP/Themes'
import Styles from './style'

interface SliderProps {
  value: number[];
  minValue: number;
  maxValue: number;
  onChange?: () => void;
  onChangeFinish?: () => void;
  step?: number;
  allowOverlap?: boolean;
  unselectedStyle?: object;
}

/**
 * In the ideal world, we would use the same slider library for both web and mobile.
 *
 * However,
 * - "react-native-multi-slider" works well on mobile, but doesn't display on web.
 * - "react-native-slider" should technically both on mobile and web, but has poor performance on mobile.
 *
 * In addition, most slider libraries do not support multi-thumbs / multi select.
 * Hopefully, expo's slider library will support multi-thumbs in the future.
 * Open issue: https://github.com/callstack/react-native-slider/issues/25
 */
export const SliderComponent = ({
  value,
  minValue,
  maxValue,
  onChange = () => {},
  onChangeFinish = () => {},
  step = 5,
  allowOverlap = false,
  unselectedStyle = Styles.sliderUnselectedBar,
}: SliderProps) => {
  return isWeb() ? (
    <Slider
      animateTransitions
      value={value}
      maximumTrackTintColor={Colors.textInverted}
      maximumValue={maxValue}
      minimumTrackTintColor={Colors.accent}
      minimumValue={minValue}
      step={step}
      thumbTintColor={Colors.accent}
      containerStyle={Styles.sliderLength}
      trackStyle={Styles.sliderTrack}
      onValueChange={onChange}
      onSlidingComplete={onChangeFinish}
      trackClickable
    />
  ) : (
    <MultiSlider
      values={value}
      min={minValue}
      max={maxValue}
      onValuesChange={onChange}
      onValuesChangeFinish={onChangeFinish}
      step={step}
      sliderLength={Dimensions.get('window').width - 60}
      minMarkerOverlapStepDistance={5}
      trackStyle={Styles.sliderTrack}
      markerStyle={Styles.sliderMarker}
      selectedStyle={Styles.sliderBar}
      unselectedStyle={unselectedStyle}
      allowOverlap={allowOverlap}
    />
  )
}
