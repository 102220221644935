import { StyleSheet } from 'react-native'
import { Colors, ApplicationStyles, Metrics, Fonts } from 'APP/Themes'

export default StyleSheet.create({
  drawer: {
    maxHeight: Metrics.screenHeight * 0.9,
    minHeight: Metrics.screenHeight * 0.6,
  },
  container: {
    flexShrink: 1,
    paddingBottom: Metrics.bottomSpace || Metrics.baseMargin,
  },
  itemContainer: {
    ...ApplicationStyles.shadow,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: Colors.elementsBg,
    borderBottomWidth: 1,
    borderBottomColor: Colors.shadow,
    paddingVertical: Metrics.baseMargin,
    paddingLeft: Metrics.baseMargin * 1.25,
    paddingRight: Metrics.baseMargin,
  },
  itemText: {
    ...Fonts.style.subheaderBold,
    color: Colors.text,
    paddingRight: Metrics.baseMargin,
    flex: 1,
  },
  subtitle: {
    ...Fonts.style.body,
    color: Colors.text,
    paddingHorizontal: Metrics.baseMargin * 1.75,
    paddingBottom: Metrics.baseMargin * 1,
  },
  backButton: {
    color: Colors.text,
    flexDirection: 'row',
    alignItems: 'center',
    padding: Metrics.baseMargin,
    paddingTop: Metrics.baseMargin * 1.5,
  },
  backIcon: {
    tintColor: Colors.text,
  },
  backText: {
    ...Fonts.style.button,
    color: Colors.text,
    paddingLeft: Metrics.baseMargin / 2,
  },
})
