import I18n from 'APP/Services/i18n'
import moment from 'moment-timezone'

export const minDomainY = 0
export const maxDomainY = 190

export const getDomainX = (latestDate) => [
  moment.utc(latestDate).subtract(6, 'months'),
  moment.utc(latestDate).add(1, 'months'),
]

export const getPopulationData = (domainX, population_average) => [
  { x: domainX[0], y: population_average },
  { x: domainX[1], y: population_average },
]

export const generateScoreChart = (data) => {
  const [latestData, ...rest] = data
  const latestDate = moment.utc(latestData.date)

  const startItem = data[data.length - 1]
  const startDate = moment.utc(startItem.date)
  const minDate = latestDate.clone().subtract(6, 'months')

  const chartData = [
    {
      x: latestDate,
      y: parseInt(latestData.value, 10),
      textLabel: latestDate.isSame(moment.utc(), 'd')
        ? I18n.t('WellBeingIndexScreen.wellBeingReport.today')
        : latestDate.fromNow(),
    },
  ]

  for (const item of rest) {
    const pointsCount = chartData.length
    const itemDate = moment.utc(item.date)
    const isSameWeek = itemDate.isSame(chartData[pointsCount - 1].x, 'isoWeek')
    if (isSameWeek) {
      continue
    }
    chartData.push({
      x: itemDate,
      y: parseInt(item.value, 10),
      textLabel: itemDate.fromNow(),
    })
  }

  chartData.push({
    x: startDate.subtract(1, 'week'),
    y: 0.5,
  })

  if (startDate.isAfter(minDate)) {
    chartData.push({
      x: minDate,
      y: 0.5,
    })
  }

  return chartData
}
