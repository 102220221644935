import React from 'react'
import { View } from 'react-native'
import Icon from 'react-native-vector-icons/MaterialIcons'
import styled, { useTheme } from 'styled-components/native'
import { useNavigation } from '@react-navigation/native'

import Card from 'APP/Converse/Card'
import Typography from 'APP/Converse/Typography'
import I18n from 'APP/Services/i18n'
import Analytics from 'APP/Services/Analytics'
import { determineExternalProvider, getServiceOrServiceGroupTileIcon } from 'APP/Lib/ContentHelpers'

const CardContainer = styled(Card)`
  border-radius: 16px;
`

const InnerContainer = styled(View)`
  min-height: 24px;
  flex-direction: row;
  gap: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  align-items: center;
`

const LeftContainer = styled(View)`
  flex-direction: row;
  flex: 1;
  align-self: center;
  gap: ${({ theme }) => theme.metrics.baseMargin / 2}px;
`

const TextContainer = styled(View)`
  flex-direction: column;
  flex: 1;
  gap: 4px;
`

const ImageContainer = styled(View)`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.metrics.baseMargin * 0.75}px;
`

const RightIcon = styled(Icon)`
  align-self: center;
`

const ServiceGroupTile = ({ item, analyticsScreenName }) => {
  const navigation = useNavigation()
  const theme = useTheme()

  const handlePress = () => {
    Analytics.trackEvent('button_click', {
      button_value: `serviceGroup : ${item?.id} : ${item?.name}`,
      button_text: item?.title,
      trigger: analyticsScreenName,
    })
    navigation.navigate('serviceGroup', { id: item.id })
  }
  const externalProvider = determineExternalProvider(item?.services)

  return (
    <CardContainer onPress={handlePress} testID={'GROUP_TILE'}>
      <InnerContainer>
        <ImageContainer>
          {getServiceOrServiceGroupTileIcon(item, {
            width: theme.metrics.icon.small,
            height: theme.metrics.icon.small,
            fillLine: theme.colors.serviceIconPrimary,
            accentFillLine: theme.colors.serviceIconAccent,
          })}
        </ImageContainer>
        <LeftContainer>
          <TextContainer>
            <Typography variant="h4">{item.title}</Typography>

            {item?.tileDescription && <Typography variant="bodySmall">{item.tileDescription}</Typography>}

            {externalProvider && (
              <Typography variant="caption">
                {I18n.t('ServiceGroup.external.provider', { provider: externalProvider })}
              </Typography>
            )}
          </TextContainer>
        </LeftContainer>
        <RightIcon
          name={'chevron-right'}
          size={theme.fonts.fontSize.h2}
          color={theme.colors.accent}
          testID="BUTTON_CARD_RIGHT_ICON"
        />
      </InnerContainer>
    </CardContainer>
  )
}

export default ServiceGroupTile
