import Config from 'APP/Config'
import I18n from 'APP/Services/i18n'
import MicroAppManifest from '@dialogue/microapps/microapp-manifest.json'

const MICRO_APP_SET = Object.entries(MicroAppManifest)
const MICRO_APP_PROPS_SET = Object.entries(Config.MICRO_APP_PROPS)

export const getSuitableFullscreenMicroAppMeta = (post) => {
  const microAppId = (post && post.props && post.props.microapp && post.props.microapp.id) || ''
  const appFromManifest = MICRO_APP_SET.find(([key]) => microAppId.indexOf(key) === 0)
  const appPropsFromConfig = MICRO_APP_PROPS_SET.find(([key]) => microAppId.indexOf(key) === 0)
  if (appFromManifest && appPropsFromConfig) {
    appFromManifest[2] = appPropsFromConfig[1]
  }
  if (appFromManifest) {
    return appFromManifest
  }
  return []
}

export const getSuitableFullscreenMicroAppForPost = (post) => {
  const [id, app, props] = getSuitableFullscreenMicroAppMeta(post)
  const microApp = { id, app, props }
  return app && app.type === 'fullscreen' ? microApp : null
}

export const getMicroAppTitle = (id) =>
  (MicroAppManifest &&
    MicroAppManifest[id] &&
    MicroAppManifest[id].title &&
    MicroAppManifest[id].title[I18n.locale]) ||
  MicroAppManifest[id].title['en'] ||
  ''

export const MICRO_APP_TRACKING_TEXT = {
  cliniaHealthNavigation: 'self-nav',
  selfScheduling: 'self-scheduling',
}
