import { StyleSheet, Platform } from 'react-native'
import { Fonts } from '../../Themes'

export const DAY_ITEM_SIZE = 33
export const DAY_BORDER_WIDTH = 3
export const DAY_ITEM_OFFSET = DAY_ITEM_SIZE + DAY_BORDER_WIDTH

export default StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    ...Fonts.style.body,
    textAlign: 'center',
    position: 'absolute',
    paddingLeft: 1,
    paddingTop: Platform.OS === 'ios' ? 1 : 2,
  },
  children: {
    position: 'absolute',
  },
})
