import apisauce from 'apisauce'
import Config from 'APP/Config'

// our "constructor"
const instances = {}
const create = (loginStore, baseURL = Config.USHER_DOMAIN) => {
  const authToken = loginStore.accessToken
  const thisInstanceId = baseURL + authToken

  if (instances[thisInstanceId]) return instances[thisInstanceId]

  const api = apisauce.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    timeout: 30000,
  })

  const setToken = (accessToken) => {
    api.setHeader('Authorization', 'Bearer ' + accessToken)
  }

  const getPractitionersInfo = () => {
    return new Promise((resolve, reject) => {
      return api.get('/physicians/info').then((response) => {
        if (response.ok) {
          const practitioners = response.data && response.data.data
          if (practitioners && practitioners.length > 0) {
            const practitionersMap = {}
            practitioners.forEach((p) => {
              practitionersMap[p.id] = p
            })
            resolve(practitionersMap)
          } else {
            reject({
              error: 'EMPTY_RESPONSE',
              message: 'No practitioners were included in the response',
            })
          }
        } else {
          reject({
            error: response.error,
            message: response.message,
          })
        }
      })
    })
  }
  instances[thisInstanceId] = {
    getPractitionersInfo,
    setToken,
  }
  return instances[thisInstanceId]
}

export default {
  create,
}
