import React from 'react'
import { Linking, Text } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { connect } from 'react-redux'
import Icon from 'react-native-vector-icons/MaterialIcons'
import Alert from 'APP/Converse/Alert'
import PatientActions from 'APP/Redux/PatientRedux'

import Config from 'APP/Config'

// services
import I18n from 'APP/Services/i18n'
import UrlBuilder from 'APP/Services/UrlBuilder'
import * as Sentry from '@sentry/react-native'
import Analytics from 'APP/Services/Analytics'

import { getDisplayableBrands, openExternalBrandApp } from 'APP/Lib/BrandHelpers'

//components
import InfoCard from 'APP/Components/InfoCard'

// Styling
import { Colors } from 'APP/Themes'
import Styles from './style'
import { logDdError } from 'APP/Lib/Datadog'

const _InactiveInfoCard = ({ eligibleBrands, busy, unchallengedInvitationSearch, testId }) => {
  const analyticsName = 'Inactive info card'
  const navigation = useNavigation()
  const filteredBrands = getDisplayableBrands(eligibleBrands)

  const handleOnLinkPressed = () => {
    Analytics.trackEvent(
      'button_click',
      {
        button_text: 'Contact us',
        button_value: `${analyticsName}`,
      },
      ['banner_context']
    )

    if (Config.CUSTOM_SUPPORT_PAGE) {
      navigation.navigate('contactUs')
    } else {
      const url = UrlBuilder.supportEmailUrl()
      if (Linking.canOpenURL(url)) {
        Linking.openURL(url).catch((reason) => {
          const msg = `InactiveCard. Error opening contact us link. message: ${reason.message}, url: ${url}`
          Sentry.captureException(msg)
          logDdError(msg, '_InactiveInfoCard.handleOnLinkPressed')
          Alert.alert(I18n.t('CannotOpenEmail.title'), I18n.t('CannotOpenEmail.body'), [
            { text: I18n.t('CannotOpenEmail.ok') },
          ])
        })
      }
    }
  }

  // Current eligibility is deactivated, but has an active eligibility in other brand
  if (filteredBrands?.length > 0) {
    return filteredBrands.map((brand) => {
      const appName = I18n.t(`InactiveInfoCard.multiBrand.${brand}.title`)
      const broughtBy = I18n.t(`InactiveInfoCard.multiBrand.${brand}.body`)
      const customSubtitle = (
        <Text style={Styles.topSubtitle}>
          <Text style={Styles.title}>{appName}</Text>, {broughtBy}.
        </Text>
      )
      return (
        <InfoCard
          key={brand}
          title={I18n.t('InactiveInfoCard.multiBrand.title')}
          customSubtitle={customSubtitle}
          buttonText={I18n.t('InactiveInfoCard.multiBrand.cta')}
          onButtonPressed={() => openExternalBrandApp(appName, brand)}
          icon={<Icon name="exit-to-app" size={40} color={Colors.accent} />}
          buttonAnalyticsName={analyticsName}
          linkText={I18n.t('Common.somethingWrong')}
          linkCta={I18n.t('Common.contactUs')}
          onLinkPressed={handleOnLinkPressed}
          busy={busy}
          testId={testId}
          style={Styles.multiInactiveContainer}
        />
      )
    })
  }
  return (
    <InfoCard
      title={I18n.t('InactiveInfoCard.singleBrand.title')}
      subtitle={I18n.t('InactiveInfoCard.singleBrand.subtitle')}
      buttonText={I18n.t('InactiveInfoCard.singleBrand.cta')}
      onButtonPressed={unchallengedInvitationSearch}
      icon={<Icon name="lock-outline" size={40} color={Colors.accent} />}
      buttonAnalyticsName={analyticsName}
      linkText={I18n.t('Common.somethingWrong')}
      linkCta={I18n.t('Common.contactUs')}
      onLinkPressed={handleOnLinkPressed}
      busy={busy}
      testId={testId}
    />
  )
}

const mapStateToProps = ({ patient }) => {
  return {
    eligibleBrands: patient?.profile?.eligibleBrands,
    busy:
      (patient?.unchallengedInvitationSearchRunning ?? false) ||
      (patient?.challengedInvitationSearchRunning ?? false),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    unchallengedInvitationSearch: () =>
      dispatch(PatientActions.unchallengedInvitationSearchRequest()),
  }
}

const InactiveInfoCard = connect(mapStateToProps, mapDispatchToProps)(_InactiveInfoCard)

export default InactiveInfoCard
