import React, { useState } from 'react'
import { Linking, Platform, ActivityIndicator } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Config from 'APP/Config'
import I18n from 'APP/Services/i18n'
import * as Sentry from '@sentry/react-native'

// Actions
import LoginActions from 'APP/Redux/LoginRedux'
import StartupActions from 'APP/Redux/StartupRedux'

// Components
import PharmacyAccountMenuItem from 'APP/Components/AccountScreen/PharmacyAccountMenuItem'
import { SecondaryButton } from 'APP/Components/Buttons'
import Icon from 'APP/Converse/Icon'
import List from 'APP/Components/List'
import ListItem from 'APP/Converse/ListItem'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import ProfileHeaderCard from 'APP/Components/ProfileHeaderCard'
import { QRCodeAppModal } from 'APP/Components/QRCodeAppModal'
import Typography from 'APP/Converse/Typography'

// Libs
import { useServiceFeatures } from 'APP/Hooks/ServiceFeatures'

// Styling
import Styles from './style'
import { useTheme } from 'styled-components/native'
import { isMobile, isWeb } from 'APP/Helpers/checkPlatform'
import { ResponsiveScrollView } from 'APP/Converse/Layout'
import { logDdError } from 'APP/Lib/Datadog'

const AccountScreen = () => {
  const navigation = useNavigation()
  const theme = useTheme()
  const { isLoggingOut } = useSelector((state) => state.login)
  const [isQRCodeModalOpen, setQRCodeModalOpen] = useState(false)
  const {
    email,
    givenName,
    familyName,
    pronouns,
    creditCard,
    preferredName,
    isConnected,
    showFamily,
    showDebugToggle,
    hasEligibilityIntervals,
    showPronouns,
    showNotifications,
    showSecurity,
  } = useSelector((state) => {
    const profile = state.patient.profile
    const family = state.family
    const hasFamilyAccess = profile?.hasFamilyAccess
    const hasFamily = Object.keys(family.members).length > 1
    const enableDebugMenu = __DEV__ || Config.ENABLE_DEBUG_MENU
    const customClaims = state.login.customClaims || {}
    const showSecurity =
      !Config.ACCOUNT_SECURITY_DISABLED_ON_SSO_CONNECTION?.includes(
        customClaims['sso-connection']
      ) ?? true

    return {
      email: profile?.email,
      givenName: profile?.givenName,
      familyName: profile?.familyName,
      pronouns: profile?.pronouns,
      creditCard: profile?.creditCard,
      preferredName: profile?.preferredName,
      isConnected: state.appSession.isConnected,
      showFamily: (hasFamily || hasFamilyAccess) && !Config.HIDE_FAMILY_TAB,
      showDebugToggle: enableDebugMenu ? !state.app.showDebugFab : false,
      hasEligibilityIntervals: profile?.eligibilityIntervals?.length > 0,
      showPronouns: state.features?.pronounDisplay,
      showNotifications:
        !!state.notifications.preferences?.data &&
        Object.keys(state.notifications.preferences?.data).length > 0,
      showSecurity,
    }
  }, shallowEqual)
  const serviceFeatures = useServiceFeatures()
  const dispatch = useDispatch()
  const toggleDebugFab = () => dispatch(StartupActions.toggleDebugFab())
  const logout = (reason) => dispatch(LoginActions.logout(reason))
  const rateApp = (action) => dispatch(StartupActions.rateApp(action))

  const goToAppStore = () => {
    const store = Platform.OS === 'ios' ? Config.APP_STORE : Config.PLAY_STORE
    const url = Platform.OS === 'ios' ? Config.APP_STORE_URL : Config.PLAY_STORE_URL
    const localizedUrl = url[I18n.baseLocale] || url.en

    Linking.openURL(store)
      .catch((reason) => {
        const msg = `AccountScreen. Error opening link to store. message: ${reason.message}, url: ${store}`
        Sentry.captureException(msg)
        logDdError(msg, 'AccountScreen.goToAppStore')
        Linking.openURL(localizedUrl)
      })
      .catch((anotherReason) => {
        const msg = `AccountScreen. Error opening link to store. message: ${anotherReason.message}, url: ${localizedUrl}`
        Sentry.captureException(msg)
        logDdError(msg, 'AccountScreen.goToAppStore')
      })
      .then(rateApp('clickedToRateAppFromProfile'))
  }

  if (isLoggingOut) {
    return (
      <NoticeBarContainer containerStyle={Styles.navTabContainer}>
        <ActivityIndicator
          color={theme.colors.text}
          style={Styles.loading}
          animating
          size="large"
        />
      </NoticeBarContainer>
    )
  }

  return (
    <NoticeBarContainer containerStyle={Styles.navTabContainer}>
      <ResponsiveScrollView>
        <ProfileHeaderCard
          givenName={givenName}
          preferredName={preferredName}
          familyName={familyName}
          pronouns={showPronouns ? pronouns : null}
          subTitle={email}
        />
        <List containerStyle={Styles.listContainer}>
          <ListItem
            key="coverage"
            analyticsName="coverage"
            testID="ACCOUNT_SCREEN_COVERAGE_MENU_ITEM"
            title={I18n.t('CoverageScreen.title')}
            onPress={() => navigation.navigate('coverage')}
          />
          <ListItem
            key="profile"
            analyticsName="profile"
            testID="ACCOUNT_SCREEN_ACCOUNT_MENU_ITEM"
            title={I18n.t('AccountScreen.profile')}
            onPress={() => navigation.navigate('profile')}
          />
          <PharmacyAccountMenuItem />
          {showFamily && hasEligibilityIntervals && (
            <ListItem
              key="family"
              analyticsName="family"
              testID="ACCOUNT_SCREEN_FAMILY_MENU_ITEM"
              title={I18n.t('AccountScreen.family')}
              onPress={() => navigation.navigate('family')}
            />
          )}
          {serviceFeatures.fitness_trackers && hasEligibilityIntervals && (
            <ListItem
              key="trackers"
              analyticsName="trackers"
              testID="ACCOUNT_SCREEN_TRACKERS_MENU_ITEM"
              title={I18n.t('AccountScreen.trackers')}
              onPress={() => navigation.navigate('healthTrackerSettings')}
            />
          )}
          {((serviceFeatures.payment && hasEligibilityIntervals) || !!creditCard) && (
            <ListItem
              key="payment"
              analyticsName="payment"
              testID="ACCOUNT_SCREEN_PAYMENT_MENU_ITEM"
              title={I18n.t('AccountScreen.payment')}
              onPress={() => navigation.navigate(creditCard ? 'viewPayment' : 'addPayment')}
            />
          )}
          {showNotifications && (
            <ListItem
              key="notifications"
              analyticsName="notifications"
              testID="ACCOUNT_SCREEN_NOTIFICATIONS_MENU_ITEM"
              title={I18n.t('AccountScreen.notifications')}
              onPress={() => navigation.navigate('notifications')}
            />
          )}
          {showSecurity && (
            <ListItem
              key="security"
              analyticsName="security"
              testID="ACCOUNT_SCREEN_SECURITY_MENU_ITEM"
              title={I18n.t('AccountScreen.security')}
              onPress={() => navigation.navigate('security')}
            />
          )}
          <ListItem
            key="legal"
            analyticsName="legal"
            testID="ACCOUNT_SCREEN_LEGAL_MENU_ITEM"
            title={I18n.t('AccountScreen.legal')}
            onPress={() => navigation.navigate('legal')}
          />
          {isMobile() && hasEligibilityIntervals && (
            <ListItem
              key="rating"
              analyticsName="rating"
              testID="ACCOUNT_SCREEN_RATING_MENU_ITEM"
              title={I18n.t('AccountScreen.rating')}
              onPress={goToAppStore}
              iconName="exit-to-app"
            />
          )}
          {showDebugToggle && (
            <ListItem
              key="debug"
              analyticsName="debug"
              testID="ACCOUNT_SCREEN_DEBUG_MENU_ITEM"
              title="Enable Debug Menu"
              onPress={toggleDebugFab}
            />
          )}
          {isWeb() && (
            <ListItem
              key="qr-code-app-store"
              analyticsName="AccountScreen.qrCodeAppStore"
              testID="ACCOUNT_SCREEN_QR_CODE_APP_STORE_MENU_ITEM"
              title={
                <Typography style={Styles.qrCodeText}>
                  <Icon variant="materialIcons" src="qr-code" size={16} color="accent" />{' '}
                  {I18n.t('QRCodeAppModal.title')}
                </Typography>
              }
              onPress={() => setQRCodeModalOpen(true)}
            />
          )}
        </List>
        <SecondaryButton
          title={I18n.t('AccountScreen.signOut')}
          analyticsName="AccountScreen.signOut"
          onPress={() => logout('Logout from profile')}
          disabled={!isConnected}
        />
        <QRCodeAppModal isVisible={isQRCodeModalOpen} onClose={() => setQRCodeModalOpen(false)} />
      </ResponsiveScrollView>
    </NoticeBarContainer>
  )
}

export default AccountScreen
