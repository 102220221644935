import React, { useState, useCallback, useRef, useLayoutEffect, useMemo } from 'react'
import { View, Animated } from 'react-native'

// Components
import IntakeReplyForm from '../IntakeReplyForm'
import IntakeAnswer from '../IntakeAnswer'

// Helpers
import { androidWrapper } from 'APP/Lib/AnimationHelpers'

// Styles
import { Styles } from './style'
import { ResponsiveView } from 'APP/Converse/Layout'

const IntakeReplyPanel = ({
  shrink,
  noGrow,
  overlayReplyForm,
  showReplyPanel,
  post,
  answers: answersProp,
  retryPost,
  onAnswerSend,
  resolveAttachmentUrl,
  resolveGetPostFileMetadata,
}) => {
  const [answers, setAnswers] = useState([])
  const [replyFormDisabled, setReplyFormDisabled] = useState(false)

  // These properties can affect layout, and shouldn't be changed during animations.
  // These props are also used to inform when we should transition. So we use a buffer
  // for where they impact render (besides animations) so things don't snap in/out.
  const [layoutProps, setLayoutProps] = useState({})

  const updateLayoutProps = useCallback(() => {
    setLayoutProps((prevLayoutProps) => {
      const nextLayoutProps = {
        initialized: true,
        overlayReplyForm,
        showReplyPanel,
        noGrow,
        shrink,
        post,
      }

      const changed = Object.entries(nextLayoutProps).some(
        ([key, value]) => layoutProps[key] !== value
      )

      return changed ? nextLayoutProps : prevLayoutProps
    })
  }, [shrink, noGrow, overlayReplyForm, showReplyPanel, layoutProps, post?.id])

  // Reverse needs to be extracted immediately, no buffered, as it affects the exit transition
  const isReversedTransition = post?.props?.member_app_fullscreen?.transition_reversed
  const isPromptMultiSubprompt = layoutProps?.post?.props?.type === 'prompt_multi_subprompt'

  // Bad practice to use 'props' as a variable name inside a react component.
  // This is actually postProps.
  // TODO: Refactor
  const props = useMemo(() => {
    const propsValue = layoutProps?.post?.props || {}
    if (isPromptMultiSubprompt) {
      // Bad practice to mutate the post structure on its way to lower components
      // TODO: Refactor
      return {
        ...propsValue,
        title: layoutProps?.post?.message,
      }
    }
    return propsValue
  }, [layoutProps?.post, isPromptMultiSubprompt])

  const replyFormViewRef = useRef()

  // When we have a singlechoice post with only one choice, Skip button layout or horizontal display variant
  // we want to make the reply form full width
  const isPromptText = props?.type === 'prompt_text'
  const isSingleChoiceWithOneOption =
    props?.type === 'prompt_choice_single' && props?.choices?.length === 1
  const isSkipButtonLayout =
    props?.type === 'prompt_choice_single' &&
    props?.member_app_fullscreen?.layout_style === 'skip_button'
  const isHorizontalDisplayVariant = props?.choices_display_variant === 'horizontal'
  const isProviderListChoice = props?.type === 'prompt_provider_list'
  const containerWidth =
    isPromptText ||
    isSingleChoiceWithOneOption ||
    isSkipButtonLayout ||
    isHorizontalDisplayVariant ||
    isProviderListChoice
      ? '100%'
      : 'auto'
  const isHorizontalTransition = props?.member_app_fullscreen?.transition_direction === 'horizontal'
  const transformType = isHorizontalTransition ? 'translateX' : 'translateY'

  const replyFormAnimationState = useRef(new Animated.Value(0)).current

  /* 

    I found it pretty confusing, so adding this note on animation states:

    0 - is used by all transitions (vertical + horizontal), it's the "off stage" start of entrance
    1 - is used by all transitions (vertical + horizontal), it's the "on stage" end of entrance
    2 - is only used by horizontal transition, it's an "exit stage -right-" kind of a 
        short-circuit, so exit and entry are on opposite sides.
    
    Horizontal transitions jump back to 0 after a 2 / stage-right transition completes. yack. 
    Difficult to reason about. We need to refactor this.

  */

  const replyFormContainerAnimatedStyles = {
    transform: [
      {
        [transformType]: replyFormAnimationState.interpolate({
          inputRange: [0, 1, 2],
          outputRange: [100, 0, -100],
        }),
      },
    ],
    opacity: replyFormAnimationState.interpolate({
      inputRange: [0, 1, 2],
      outputRange: [0, 1, 0],
    }),
    width: containerWidth,
    ...(isPromptMultiSubprompt && { flexGrow: 1 }),
  }
  useLayoutEffect(() => {
    if (post) {
      if (showReplyPanel && (!answers || answers.length === 0)) {
        // Required for very first render. After that, the exit end fills the buffer
        if (!layoutProps.initialized) {
          updateLayoutProps()
        }

        // Animate the reply form entrance.
        androidWrapper(
          Animated.spring(replyFormAnimationState, {
            toValue: 1,
            damping: 13,
            useNativeDriver: true,
          }),
          replyFormViewRef.current
        ).start()
        setReplyFormDisabled(false)
      } else {
        setReplyFormDisabled(true)
        if (!showReplyPanel && isHorizontalTransition) {
          replyFormAnimationState.setValue(2)
        }
        // Animate the reply form exit.
        androidWrapper(
          Animated.timing(replyFormAnimationState, {
            toValue: isHorizontalTransition ? 2 : 0,
            duration: 300,
            useNativeDriver: true,
          }),
          replyFormViewRef.current
        ).start(() => {
          replyFormAnimationState.setValue(0)
          // When we're done animating the exit, update the layout-affecting props we've received
          updateLayoutProps()
        })
      }
    }
  }, [post, answers, showReplyPanel, isHorizontalTransition])

  const answerContainerAnimationState = useRef(new Animated.Value(0)).current
  const answerContainerAnimatedStyles = {
    transform: [
      {
        [transformType]: answerContainerAnimationState.interpolate({
          inputRange: [0, 1, 2],
          outputRange: [100, 0, -100],
        }),
      },
    ],
    opacity: answerContainerAnimationState.interpolate({
      inputRange: [0, 1, 2],
      outputRange: [0, 1, 0],
    }),
  }
  useLayoutEffect(() => {
    if (showReplyPanel && answersProp && answersProp.length > 0) {
      if (props?.member_app_fullscreen?.animate_answer) {
        setAnswers(answersProp)
        Animated.spring(answerContainerAnimationState, {
          toValue: 1,
          damping: 13,
          useNativeDriver: true,
        }).start()
      }
    } else if (!showReplyPanel || (answers && answers.length > 0)) {
      Animated.timing(answerContainerAnimationState, {
        toValue: 2,
        duration: 300,
        useNativeDriver: true,
      }).start(({ finished }) => {
        if (finished) {
          answerContainerAnimationState.setValue(0)
          setAnswers([])
        }
      })
    }
  }, [answersProp, showReplyPanel])
  const sendAnswer = useCallback(
    (...args) => {
      setReplyFormDisabled(true)
      androidWrapper(
        Animated.timing(replyFormAnimationState, {
          toValue: isHorizontalTransition ? 2 : 0,
          duration: 300,
          useNativeDriver: true,
        }),
        replyFormViewRef.current
      ).start()
      onAnswerSend(...args)
    },
    [onAnswerSend, isHorizontalTransition]
  )

  const ReplyForm = (
    <IntakeReplyForm
      key={layoutProps.post?.id || 'default-reply-panel-key'}
      {...props}
      style={[layoutProps.overlayReplyForm && replyFormContainerAnimatedStyles, Styles.replyForm]}
      disabled={replyFormDisabled}
      sendAnswer={sendAnswer}
      isReversedTransition={isReversedTransition}
    />
  )
  return (
    <>
      <ResponsiveView
        style={[
          !isPromptMultiSubprompt && Styles.replyPanel,
          isPromptMultiSubprompt && Styles.replyPanelMultiSubprompt,
          layoutProps.shrink && Styles.replyPanelShrink,
          layoutProps.noGrow && Styles.replyPanelNoGrow,
        ]}
      >
        <Animated.View style={[Styles.replyPanelAnswerContainer, answerContainerAnimatedStyles]}>
          {answers.map((answer, i) => (
            <IntakeAnswer
              key={i}
              answer={answer}
              onRetry={retryPost}
              resolveAttachmentUrl={resolveAttachmentUrl}
              resolveGetPostFileMetadata={resolveGetPostFileMetadata}
            />
          ))}
        </Animated.View>
        {!layoutProps.overlayReplyForm && (
          <Animated.View style={[replyFormContainerAnimatedStyles]}>
            <View
              style={isPromptMultiSubprompt && Styles.replyFormMultiSubprompt}
              ref={replyFormViewRef}
            >
              {ReplyForm}
            </View>
          </Animated.View>
        )}
      </ResponsiveView>
      {layoutProps.overlayReplyForm && ReplyForm}
    </>
  )
}

export default IntakeReplyPanel
