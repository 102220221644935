import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RefreshControl, ActivityIndicator, Image, View } from 'react-native'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import moment from 'moment'
import { ActiveMinutes } from '@dialogue/services'
import ActiveMinutesActions from 'APP/Redux/ActiveMinutesRedux'
import I18n from 'APP/Services/i18n'
import sprigClient from 'APP/Services/Sprig'

import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import Modal from 'APP/Components/Modal'
import Analytics from 'APP/Services/Analytics'
import { Colors, Metrics } from 'APP/Themes'
import withFeatureEnabled from 'APP/Helpers/withFeatureEnabled'
import WellnessCenterChallengeScreen_legacy from 'APP/Containers/WellnessCenterChallengeScreen_legacy'
import { useFocusEffect } from '@react-navigation/native'
import Typography from 'APP/Converse/Typography'
import { TrackerStatusWrapper } from 'APP/Components/ActiveMinutes'
import { USER_TRACKER_STATUSES } from 'APP/Lib/Enums'
import { useRefreshControlHandlers } from 'APP/Hooks/RefreshControlHandlers'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import JoinButton from './JoinButton'
import Leaderboard from './Leaderboard'
import Countdown from './Countdown'
import useChallengeKebabMenu from './useChallengeKebabMenu'
import { CHALLENGE_ACTIVITY_ICONS } from './constants'
import Styles from './style'

const ANALYTICS = {
  PAGE_VIEWED_NOT_JOINED: 'WELLNESS_CENTER_CHALLENGE_PAGE_VIEWED_NOT_JOINED',
  PAGE_VIEWED_JOINED: 'WELLNESS_CENTER_CHALLENGE_PAGE_VIEWED_JOINED',
}

const ChallengeScreen = (props) => {
  const { navigation, route } = props
  const { id } = { ...route?.params, ...props }
  const dispatch = useDispatch()

  const { loading, retry, challenge } = useSelector(
    (state) => state.activeMinutes.selectedChallenge
  )

  const { supportedActivities } = useSelector((state) => state.activeMinutes.connectedTrackers)

  const isActivitySupported = supportedActivities?.includes(challenge?.activity)

  const { success: leaveChallengeSuccess, loading: loadingLeaveChallenge } = useSelector(
    (state) => state.activeMinutes.leaveChallenge
  )

  const dataLoaded = id === challenge?.id
  const ActivityIcon = !!challenge && CHALLENGE_ACTIVITY_ICONS[challenge.activity]

  const leaveSelectedChallenge = () => {
    dispatch(ActiveMinutesActions.leaveChallenge(challenge.id))
  }

  const analyticsName = 'wcChallenge'
  const locale = I18n.baseLocale

  const [leavingChallengeModalIsVisible, setLeavingChallengeModalIsVisible] = useState(false)

  const getData = useCallback(
    ({ refresh } = {}) => {
      dispatch(ActiveMinutesActions.getSingleChallenge(id))
      dispatch(ActiveMinutesActions.getConnectedTrackers())
      if (refresh) {
        dispatch(ActiveMinutesActions.getChallengeLeaderboard(id))
      }
    },
    [id]
  )

  useFocusEffect(
    useCallback(() => {
      getData()
    }, [getData])
  )

  // Analytics page view events
  useEffect(() => {
    if (!loading && retry) {
      Analytics.trackScreen(analyticsName)
    }

    if (!loading && !retry && challenge) {
      const joined = !!challenge.joined_at
      const joinedAnalyticsStatus = joined ? 'Joined' : 'Not joined'

      Analytics.trackScreen(
        `${analyticsName} : ${challenge.id} : ${challenge?.title?.[locale]} : ${joinedAnalyticsStatus}`
      )

      if (joined) {
        sprigClient.track(ANALYTICS.PAGE_VIEWED_JOINED)
      } else {
        sprigClient.track(ANALYTICS.PAGE_VIEWED_NOT_JOINED)
      }
    }
  }, [loading, retry, challenge, locale])

  const setupTracker = useCallback(() => {
    navigation.navigate('healthTrackerSettings')
  }, [navigation])

  useChallengeKebabMenu({
    navigation,
    id,
    status: challenge?.status,
    analyticsName,
    onSetupTrackerPress: setupTracker,
    joined: !!challenge?.joined_at,
    setLeavingChallengeModalIsVisible,
  })
  const refreshControlHandlers = useRefreshControlHandlers(challenge, getData)

  return (
    <NoticeBarContainer>
      <Leaderboard challengeId={id} refreshControl={<RefreshControl {...refreshControlHandlers} />}>
        {loading && !dataLoaded && (
          <ActivityIndicator
            size="large"
            color={Colors.text}
            style={Styles.loading}
            testID="loading"
          />
        )}
        {!loading && retry && (
          <DialogueTouchableOpacity
            style={Styles.refresh}
            onPress={getData}
            analyticsName="Refresh"
            testID="retry"
          >
            <MaterialIcon name="refresh" size={Metrics.icons.large} color={Colors.text} />
          </DialogueTouchableOpacity>
        )}
        {!!dataLoaded && (
          <>
            <Image
              style={Styles.image}
              source={{ uri: challenge?.image?.[locale] }}
              resizeMode="cover"
            />
            <View style={Styles.body}>
              {challenge.activity && (
                <View style={Styles.tagList}>
                  <View key="activity" style={Styles.tag} testID="activityTag">
                    <ActivityIcon.Lib
                      name={ActivityIcon.name}
                      size={Metrics.icons.tiny}
                      style={Styles.tagIcon}
                    />
                    <Typography variant="caption" color="primaryText">
                      {I18n.t(
                        `WellnessCenter.challenges.activity.${challenge.activity}`
                      ).toLocaleUpperCase()}
                    </Typography>
                  </View>
                  <View key="type" style={Styles.tag} testID="typeTag">
                    <MaterialIcon name="person" size={Metrics.icons.tiny} style={Styles.tagIcon} />
                    <Typography variant="caption" color="primaryText">
                      {I18n.t(`WellnessCenter.challenges.type.individual`).toLocaleUpperCase()}
                    </Typography>
                  </View>
                </View>
              )}
              <View style={Styles.descriptionContainer}>
                <View style={Styles.titleContainer}>
                  <Typography variant="h1" testID="title">
                    {challenge?.title?.[locale]}
                  </Typography>
                </View>
                <View style={Styles.timeBlock}>
                  <MaterialIcon
                    name="schedule"
                    size={Metrics.icons.tiny}
                    color={Colors.accent}
                    style={Styles.clockIcon}
                  />
                  <Typography variant="bodySmall" testID="timeCopy">
                    {challenge.status === ActiveMinutes.Types.ChallengeStatus.Upcoming &&
                      I18n.t('WellnessCenter.challenges.countdown.fullUpcoming')}
                    {challenge.status === ActiveMinutes.Types.ChallengeStatus.Ongoing &&
                      I18n.t('WellnessCenter.challenges.countdown.fullOngoing')}
                    {challenge.status === ActiveMinutes.Types.ChallengeStatus.Completed &&
                      `${moment(challenge.starts_at).format('MMMM D, YYYY')} - ${moment(
                        challenge.ends_at
                      ).format('MMMM D, YYYY')}`}
                  </Typography>
                  <Typography variant="bodySmallBold" testID="timeValue">
                    {challenge.status === ActiveMinutes.Types.ChallengeStatus.Upcoming &&
                      moment(challenge.starts_at).fromNow(true)}
                    {challenge.status === ActiveMinutes.Types.ChallengeStatus.Ongoing && (
                      <Countdown challenge={challenge} />
                    )}
                  </Typography>
                </View>

                <Typography variant="bodyNormal" testID="description">
                  {challenge?.description?.[locale]}
                </Typography>

                {challenge?.brought_by && (
                  <View style={Styles.broughtByContainer}>
                    <Typography variant="bodyNormal" testID="org">
                      {I18n.t('WellnessCenter.challenges.broughtBy')}{' '}
                      {challenge?.brought_by?.[locale]}
                    </Typography>
                  </View>
                )}
              </View>
              <JoinButton hasLeftChallenge={leaveChallengeSuccess} style={Styles.joinCta} />
              {!isActivitySupported && (
                <TrackerStatusWrapper
                  status={USER_TRACKER_STATUSES.NO_COMPATIBLE_TRACKERS}
                  requestedActivity={challenge?.activity}
                />
              )}
              <Modal
                statusBarTranslucent
                visible={leavingChallengeModalIsVisible}
                title={I18n.t('WellnessCenter.challenges.leaveModal.title')}
                subtitle={I18n.t('WellnessCenter.challenges.leaveModal.subtitle')}
                transparent
                primaryActionText={I18n.t('WellnessCenter.challenges.leaveModal.primary')}
                primaryActionDisabled={loadingLeaveChallenge}
                handlePrimaryAction={() => setLeavingChallengeModalIsVisible(false)}
                secondaryActionText={I18n.t('WellnessCenter.challenges.leaveModal.secondary')}
                secondaryActionColor={Colors.accent}
                secondaryActionRunning={loadingLeaveChallenge}
                handleSecondaryAction={() => {
                  setLeavingChallengeModalIsVisible(false)
                  leaveSelectedChallenge()
                }}
                handleCloseModal={() => setLeavingChallengeModalIsVisible(false)}
              />
            </View>
          </>
        )}
      </Leaderboard>
    </NoticeBarContainer>
  )
}

export default withFeatureEnabled({
  memoizationKey: 'ChallengeScreen',
  enabled: ChallengeScreen,
  default: WellnessCenterChallengeScreen_legacy,
  featureKey: 'terraDrivenWellness',
})
