import React, { useEffect } from 'react'
import Animated, {
  cancelAnimation,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated'
import styled from 'styled-components/native'

const StyledContainer = styled.View`
  position: absolute;
  width: 120px;
  height: 120px;
  align-items: center;
  justify-content: center;
`

const StyledAnimatedRing = styled(Animated.View)`
  border: 3px solid #fff;
  border-radius: 60px;
  width: 120px;
  height: 120px;
  position: absolute;
`

const StyledRing = styled.View`
  position: absolute;
  background-color: ${(props) => props.theme.colors.white};
  z-index: 1;
  opacity: 0.05;
  border-radius: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`

const AnimatedRing = ({ duration }) => {
  const animatedOpacity = useSharedValue(0)
  const animatedScale = useSharedValue(1)

  const animatedStyles = useAnimatedStyle(() => {
    return {
      opacity: animatedOpacity.value,
      transform: [{ scale: animatedScale.value }],
    }
  }, [animatedOpacity.value])

  useEffect(() => {
    animatedOpacity.value = withRepeat(
      withTiming(0.3, {
        duration: duration / 2,
      }),
      -1,
      true
    )
    animatedScale.value = withRepeat(
      withTiming(1.3, {
        duration: duration,
      }),
      -1
    )
    return () => {
      cancelAnimation(animatedOpacity)
      cancelAnimation(animatedScale)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <StyledAnimatedRing style={animatedStyles} />
}

const GlowingHalo = () => {
  return (
    <StyledContainer data-chromatic="ignore">
      <AnimatedRing duration="2000" />
      <AnimatedRing duration="3500" />
      <AnimatedRing duration="5200" />
      <StyledRing size={186} />
      <StyledRing size={224} />
      <StyledRing size={256} />
      <StyledRing size={290} />
    </StyledContainer>
  )
}

export default GlowingHalo
