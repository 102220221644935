import { Platform } from 'react-native'
import {
  put,
  call,
  select,
  all,
  spawn,
  take,
  delay,
  retry,
  cancelled,
  race,
  cancel,
  fork,
} from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import * as RNLocalize from 'react-native-localize'
import Toast from 'react-native-toast-message'
import * as Sentry from '@sentry/react-native'
import moment from 'moment'
import merge from 'deepmerge'
// Redux Actions
import LoggerActions from '../Redux/LoggerRedux'
import { LoginTypes } from '../Redux/LoginRedux'
import WellnessCenterActions, { WellnessCenterTypes, Models } from 'APP/Redux/WellnessCenterRedux'
import { PatientTypes } from '../Redux/PatientRedux'
import { AppSessionTypes } from '../Redux/AppSessionRedux'
import { StartupTypes } from '../Redux/StartupRedux'
// Services
import Config from 'APP/Config'
import I18n from 'APP/Services/i18n'
import { getVideos, getAudio, getArticles } from 'APP/Services/CMS'
import { WellnessCenter } from '@dialogue/services'
import * as AppleHealthKit from 'APP/Lib/AppleHealthKit'
import * as TrackerHelpers from 'APP/Lib/TrackerHelpers'
import { transformDateLimit, formatDateToISOString } from '../Lib/DateHelpers'
import { getHealthKitCache, setHealthKitCache } from 'APP/Lib/AppleHealthKit'
import { navigationRef as Nav } from 'APP/Nav'
import { checkRenewAuth } from './AuthSagas'
import { contentActions, contentSelectors } from 'APP/Store/Content'
import { logDdError } from 'APP/Lib/Datadog'

const { Challenges, Trackers, Users } = WellnessCenter

export function* createWellnessCenter() {
  const accessToken = yield select(selectAccessToken)

  return {
    Challenges: new Challenges.Service(accessToken, Config.WELLNESSCENTER_SERVICE_URL),
    Trackers: new Trackers.Service(accessToken, Config.WELLNESSCENTER_SERVICE_URL),
    Users: new Users.Service(accessToken, Config.WELLNESSCENTER_SERVICE_URL),
  }
}

export const selectIsBackgrounded = (state) => !state.appSession.isActive
export const selectTerraDrivenWellnessEnabled = (state) => state.features.terraDrivenWellness
export const selectAccessToken = (state) => state.login.accessToken
export const selectPatientId = (state) => state.patient.profile.id
export const selectPatientProfile = (state) => state.patient.profile
export const selectPatientActiveEligibilityIntervals = (state) =>
  (state.patient.profile.eligibilityIntervals || []).filter((record) => record.status === 'active')
export const selectSelectedChallenge = (state) => state.wellnessCenter.selectedChallenge
export const selectTrackers = (state) => state.wellnessCenter.trackers

export const selectUserTrackerByStatus = (state, trackerStatus) => {
  const { trackers } = selectTrackers(state)
  return trackers.user.filter(({ status }) => status == trackerStatus)
}

export const selectShouldTrackerSyncBeIgnored = (state) => {
  const profile = state.patient?.profile
  const isLoggedIn = profile?.id ? true : false
  if (!isLoggedIn) {
    return 'User is not logged in'
  }

  const curRouteName = Nav.getCurrentRoute()?.name
  const isSyncAllRouteForbidden = SYNC_ALL_SAMPLES_FORBIDDEN_SCENES.some(
    (forbiddenSceneName) => curRouteName === forbiddenSceneName
  )
  if (isSyncAllRouteForbidden) {
    return `Sync is forbidden for this route: ${curRouteName}`
  }

  // no syncing or token refresh allowed in background for members with forcedSessionInactivity
  const isBackgrounded = selectIsBackgrounded(state)
  if (isBackgrounded && profile?.forcedSessionInactivityRequired) {
    return 'forcedSessionInactivity enabled'
  }

  return undefined
}

export const selectAllTrackersToSync = (state, { keys, lastSyncTimeThreshold }) => {
  const trackerKeys = keys ? keys : [WellnessCenter.Trackers.Types.SdkTrackerName.AppleHealthKit]
  let trackersByStatus = selectUserTrackerByStatus(
    state,
    WellnessCenter.Trackers.Types.TrackerStatus.Connected
  )

  return trackersByStatus.reduce((trackers, tracker) => {
    const { tracker_name, last_sync_time } = tracker
    const lastSyncTimeUTC = moment.utc(last_sync_time)
    const isTrackerKeyIncluded = trackerKeys.includes(tracker_name)
    const isLastSyncTimeThresholdReached = lastSyncTimeThreshold
      ? moment(lastSyncTimeUTC).isSameOrBefore(lastSyncTimeThreshold)
      : true
    const needsToBeSync = isTrackerKeyIncluded && isLastSyncTimeThresholdReached
    return needsToBeSync ? [...trackers, tracker] : trackers
  }, [])
}

const groupBySubcategory = (source, data) => {
  data.forEach((item) => {
    // Ignore any item with missing subcategory
    if (!item.subcategory) return

    const group = source.find((g) => g.subcategory.toLowerCase() === item.subcategory.toLowerCase())

    if (group) {
      group.data.push(item)
    } else {
      source.push({
        subcategory: item.subcategory,
        data: [item],
      })
    }
  })
}

// Tictrac requires "en-us" for English content.
const generateLangKey = (lang) => (lang === 'en' ? 'en-us' : lang)

function* selectPlanTags() {
  const eligibilityIntervals = yield select(selectPatientActiveEligibilityIntervals)
  // planTags is used to filter challenges from Tictrac API ([g]org:plan_id_xx is their formatting)
  return eligibilityIntervals.map((record) => `[g]org:plan_id_${record.planId}`)
}

export function* fetchContent({ category }) {
  try {
    // Map copy to explicit CMS category
    const CATEGORIES = {
      [I18n.t('WellnessCenter.library.categories.training')]: 'Training',
      [I18n.t('WellnessCenter.library.categories.mindfulness')]: 'Mindfulness',
      [I18n.t('WellnessCenter.library.categories.nutrition')]: 'Nutrition',
    }

    const videos = yield call(
      getVideos,
      { topic: 'wellness', category: CATEGORIES[category] },
      null
    )
    const audio = yield call(getAudio, { topic: 'wellness', category: CATEGORIES[category] }, null)
    const enArticles = yield call(
      getArticles,
      null,
      true,
      {
        topic: 'wellness',
        category: CATEGORIES[category],
      },
      'en'
    )
    const frArticles = yield call(
      getArticles,
      null,
      true,
      {
        topic: 'wellness',
        category: CATEGORIES[category],
      },
      'fr'
    )

    const formattedContent = []

    // Loop through each response, grouping items by subcategory
    if (videos.items) groupBySubcategory(formattedContent, videos.items)
    if (audio.items) groupBySubcategory(formattedContent, audio.items)
    if (enArticles.items) groupBySubcategory(formattedContent, enArticles.items)
    if (frArticles.items) groupBySubcategory(formattedContent, frArticles.items)

    // Sort items in each group by date (newest -> oldest)
    formattedContent.forEach((group) => {
      group.data.sort((a, b) => moment(b.created_at).diff(moment(a.created_at)))
    })

    // Order grouped content by # of items (most -> least)
    const orderedContent = formattedContent.sort((a, b) => b.data.length - a.data.length)

    yield put(WellnessCenterActions.setContentSuccess(orderedContent))
  } catch {
    yield put(WellnessCenterActions.setContentFailure())
  }
}

export function* getMyChallenges() {
  try {
    const wellnessCenter = yield call(createWellnessCenter)

    const data = yield call(wellnessCenter.Users.getChallenges, {
      lang: [generateLangKey(I18n.baseLocale)],
      tag: yield selectPlanTags(),
      status: [Challenges.Types.ChallengeStatus.UPCOMING, Challenges.Types.ChallengeStatus.ONGOING],
      sort: Challenges.Types.Sort.END_DATE_ASC,
    })

    yield put(WellnessCenterActions.getMyChallengesSuccess(data))
  } catch (e) {
    yield put(WellnessCenterActions.getMyChallengesFailure())
  }
}

export function* getCompletedChallenges() {
  try {
    const wellnessCenter = yield call(createWellnessCenter)

    const data = yield call(wellnessCenter.Users.getChallenges, {
      lang: [generateLangKey(I18n.baseLocale)],
      tag: yield selectPlanTags(),
      status: [Challenges.Types.ChallengeStatus.COMPLETE],
      sort: Challenges.Types.Sort.END_DATE_DESC,
      from_date: moment().subtract(2, 'month').format(I18n.t('DateFormat')),
    })

    yield put(WellnessCenterActions.getCompletedChallengesSuccess(data))
  } catch (e) {
    yield put(WellnessCenterActions.getCompletedChallengesFailure())
  }
}

export function* getNewChallenges() {
  try {
    const wellnessCenter = yield call(createWellnessCenter)

    const data = yield call(wellnessCenter.Challenges.getChallenges, {
      lang: [generateLangKey(I18n.baseLocale)],
      tag: yield selectPlanTags(),
      status: [Challenges.Types.ChallengeStatus.UPCOMING, Challenges.Types.ChallengeStatus.ONGOING],
      sort: Challenges.Types.Sort.END_DATE_ASC,
      participant: false,
    })

    yield put(WellnessCenterActions.getNewChallengesSuccess(data))
  } catch (e) {
    yield put(WellnessCenterActions.getNewChallengesFailure())
  }
}

export function* getChallenge({ challengeId }) {
  try {
    if (!challengeId) throw Error('missing required parameters')
    const parsedId = parseInt(challengeId, 10)

    const wellnessCenter = yield call(createWellnessCenter)
    const { TrackerStatus } = WellnessCenter.Trackers.Types

    const challenge = yield call(wellnessCenter.Challenges.getChallenge, {
      challengeId: parsedId,
      lang: [generateLangKey(I18n.baseLocale)],
    })

    // Capture if user has joined the challenge already
    const allUserChallenges = yield call(wellnessCenter.Users.getChallenges, {
      lang: [generateLangKey(I18n.baseLocale)],
    })

    const userChallenge = allUserChallenges.find((uc) => uc.id === parsedId)
    const inChallenge = userChallenge !== undefined

    // Check if user has at least one tracker connected
    const userTrackers = yield call(wellnessCenter.Users.getUsersTrackers, {})
    const connectedTrackers = userTrackers.filter(
      (tracker) => tracker.status === TrackerStatus.Connected
    )
    const trackerIsBroken = userChallenge
      ? (userTrackers || []).find(
          (tracker) => tracker.tracker_name === userChallenge.participant.tracker_name
        )?.status === TrackerStatus.Broken
      : false

    const userHasTracker = connectedTrackers.length > 0 && !trackerIsBroken

    // Chech if all connected user trackers are not compatible
    let userHasNoCompatibleTrackers = false
    if (userHasTracker) {
      const trackers = yield call(wellnessCenter.Trackers.getTrackers, {})
      const trackerActivitiesMap = trackers.reduce(
        (acc, tracker) => ({ ...acc, [tracker.tracker_name]: tracker.activities }),
        {}
      )
      userHasNoCompatibleTrackers = !connectedTrackers.some((tracker) =>
        (trackerActivitiesMap[tracker.tracker_name] || []).includes(challenge.activity)
      )
    }

    let userChallengeGoal =
      userChallenge && userHasTracker ? userChallenge?.participant?.total_value || 0 : 0

    yield put(
      WellnessCenterActions.getChallengeSuccess(
        challenge,
        inChallenge,
        userHasTracker,
        userHasNoCompatibleTrackers,
        userChallengeGoal
      )
    )
  } catch (e) {
    yield put(WellnessCenterActions.getChallengeFailure())
  }
}

export function* watchForUpdateUserProfileOpportunity(action) {
  while (true) {
    yield race({
      onStartup: all([take(StartupTypes.STARTUP), take(PatientTypes.SCRIBE_V2_USER_FETCH_SUCCESS)]),
      onAppForegrounded: all([
        take(AppSessionTypes.SET_APP_FOREGROUNDED),
        take(PatientTypes.PATIENT_PROFILE_FETCH_SUCCESS),
      ]),
      onLogin: all([
        take(LoginTypes.LOGIN_SUCCESS),
        take(PatientTypes.SCRIBE_V2_USER_FETCH_SUCCESS),
      ]),
      onProfileUpdate: take(PatientTypes.PATIENT_PROFILE_UPDATE_SUCCESS),
    })
    yield call(action)
  }
}

export function* updateUserProfile() {
  try {
    const isAuthenticated = yield select((state) => !!state.patient?.profile?.id)

    if (!isAuthenticated) {
      return
    }
    const wellnessCenter = yield call(createWellnessCenter)

    const patientProfile = yield select(selectPatientProfile)

    // if first or last name is not set there is a
    // data integrity issue or maybe a state issue.
    // in this case only update the timezone in WC.
    const firstName = patientProfile?.preferredName || patientProfile?.givenName
    const lastName = patientProfile?.familyName
    const displayName = !firstName || !lastName ? null : `${firstName} ${lastName}`

    yield call(wellnessCenter.Users.updateUserProfile, {
      ...(displayName && { display_name: displayName, first_name: firstName, last_name: lastName }),
      timezone: RNLocalize.getTimeZone(),
    })
  } catch (error) {
    // report to sentry, no user feedback
    Sentry.captureException(error)
    logDdError(error.message, error.stack)
  }
}

export function* joinIndividualChallenge({ challengeId }) {
  try {
    if (!challengeId) throw Error('missing required parameters')

    const wellnessCenter = yield call(createWellnessCenter)

    // Add user to challenge
    yield call(wellnessCenter.Challenges.addParticipant, { challengeId })

    yield call(getLeaderboard, { challengeId })

    // Refresh main list of challenges
    yield put(WellnessCenterActions.getMyChallenges())
    yield put(WellnessCenterActions.getNewChallenges())
    yield call(getChallenge, { challengeId })

    yield put(WellnessCenterActions.joinIndividualChallengeSuccess(true))
  } catch (e) {
    yield put(WellnessCenterActions.joinIndividualChallengeFailure())
  }
}

export function* joinTeamChallenge({ challengeId, teamId }) {
  try {
    if (!challengeId || !teamId) throw Error('missing required parameters')

    const wellnessCenter = yield call(createWellnessCenter)

    // Add user to team
    yield call(wellnessCenter.Challenges.addParticipantToTeam, {
      challengeId,
      teamId,
    })

    yield call(getLeaderboard, { challengeId })

    // Refresh main list of challenges
    yield put(WellnessCenterActions.getMyChallenges())
    yield put(WellnessCenterActions.getNewChallenges())
    yield call(getChallenge, { challengeId })

    yield put(WellnessCenterActions.joinTeamChallengeSuccess(true))
  } catch (e) {
    yield put(WellnessCenterActions.joinTeamChallengeFailure())
    Toast.show({ text1: I18n.t('WellnessCenter.challenges.retryJoin') })
  }
}

export function* fetchMemberExplicitly({ method, uniqueMethodArgs, patientId, userRank, page }) {
  // Our perfect pageSize is 4 members above our current user rank, so that we can return +/- 3
  // participants in a single API call. HOWEVER there are two edge cases:
  // 1. userRank is significantly smaller than 97 (too small API response could mean many API calls)
  // 2. userRank is signficantly higher than 100 (too large API response could timeout)
  let pageSize = userRank - 4

  // Edge cases. 90 and 250 feel like safe numbers
  if (pageSize <= 90 || pageSize >= 250) {
    pageSize = 100
  }

  let moreParticipants = []
  let memberIndex = -1
  let fetch = 1
  const maxFetch = 20

  while (memberIndex === -1) {
    moreParticipants = yield call(method, {
      ...uniqueMethodArgs,
      pageSize,
      page: page + 1,
    })

    if (moreParticipants.length === 0 || fetch >= maxFetch) {
      break
    }

    memberIndex = moreParticipants.findIndex((p) => p.user.external_id === patientId)
    fetch = fetch += 1
  }

  return moreParticipants.slice(memberIndex - 3, memberIndex + 4)
}

function* fetchAndFilterAndGroupParticipants({
  method,
  uniqueMethodArgs,
  userIsInChallenge,
  userRank,
}) {
  const pageSize = 100 // we'll show top 50ish, so this is slightly larger so we can use index in our logic below.
  const participants = yield call(method, {
    ...uniqueMethodArgs,
    pageSize,
    page: 0,
  })

  // Rank !== index here, and rank is not unique (many users can be the same rank)
  // so it's important to use the index here for the first few usecases to
  // ensure we *always* show the member in the UI.
  // REMEMBER index starts at 0 so numbers are 1 lower than expected.
  const patientId = yield select(selectPatientId)

  let top50ishParticipants = []
  let memberBasedParticipants = []

  // Usecase 1. Member is not in challenge, show basic top 50
  if (!userIsInChallenge) {
    top50ishParticipants = participants.slice(0, 50)
  }

  const memberIndex = participants.findIndex((p) => p.user.external_id === patientId.toString())

  // Usecase 2. Member is in challenge and is index 0-46, show basic top 50
  if (userIsInChallenge && memberIndex <= 46) {
    top50ishParticipants = participants.slice(0, 50)
  }

  // Usecase 3. Member is in challenge and is index 47-53, show up to member & 3 more people afterwards (max 56)
  if (userIsInChallenge && memberIndex >= 47 && memberIndex <= 53) {
    top50ishParticipants = participants.slice(0, memberIndex + 4)
  }

  // Usecase 4. Member is in challenge and is index 54-(end of participant list - 4), show top 50 AND second list of 7 with member in the middle
  if (userIsInChallenge && memberIndex >= 54 && memberIndex <= pageSize - 4) {
    top50ishParticipants = participants.slice(0, 50)
    memberBasedParticipants = participants.slice(memberIndex - 3, memberIndex + 4)
  }

  // Usecase 5. Member is in challenge and index is greater than (end of participant list - 4), show top 50 AND second list of 7 with member in the middle
  if (userIsInChallenge && (memberIndex >= pageSize - 3 || memberIndex === -1)) {
    top50ishParticipants = participants.slice(0, 50)

    memberBasedParticipants = yield call(fetchMemberExplicitly, {
      method,
      uniqueMethodArgs,
      page: 0,
      patientId: patientId.toString(),
      userRank,
    })
  }

  return { participants: top50ishParticipants, additionalParticipants: memberBasedParticipants }
}

export function* getLeaderboard({ challengeId }) {
  try {
    if (!challengeId) throw Error('missing required parameters')
    const parsedId = parseInt(challengeId, 10)

    const wellnessCenter = yield call(createWellnessCenter)

    const { challenge } = yield select(selectSelectedChallenge)

    const isIndividualChallenge = challenge.type === Challenges.Types.ChallengeType.INDIVIDUAL

    const allUserChallenges = yield call(wellnessCenter.Users.getChallenges, {
      lang: [generateLangKey(I18n.baseLocale)],
    })
    const userChallenge = allUserChallenges.find((uc) => uc.id === parsedId)
    const userIsInChallenge = userChallenge !== undefined

    let participants = []
    let additionalParticipants = []

    if (isIndividualChallenge) {
      const individualParticipants = yield call(fetchAndFilterAndGroupParticipants, {
        method: wellnessCenter.Challenges.getParticipants,
        uniqueMethodArgs: {
          challengeId: parsedId,
        },
        userIsInChallenge,
        userRank: userChallenge?.participant?.rank,
      })

      participants = individualParticipants.participants
      additionalParticipants = individualParticipants.additionalParticipants
    } else {
      participants = yield call(wellnessCenter.Challenges.getTeams, {
        challengeId: parsedId,
        lang: [generateLangKey(I18n.baseLocale)],
      })
    }

    const activeLeaderboardItem = new Models.ActiveLeaderboardItem(
      userChallenge?.participant?.user?.id,
      userChallenge?.team?.id
    )

    yield put(
      WellnessCenterActions.getLeaderboardSuccess(
        participants,
        additionalParticipants,
        activeLeaderboardItem
      )
    )
  } catch (e) {
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
    yield put(WellnessCenterActions.getLeaderboardFailure())
  }
}

export function* getTeamParticipants({ challengeId, teamId }) {
  try {
    if (!challengeId || !teamId) throw Error('missing required parameters')

    const wellnessCenter = yield call(createWellnessCenter)

    const allUserChallenges = yield call(wellnessCenter.Users.getChallenges, {
      lang: [generateLangKey(I18n.baseLocale)],
    })
    const userChallenge = allUserChallenges.find((uc) => uc.id === challengeId)

    const { participants, additionalParticipants } = yield call(
      fetchAndFilterAndGroupParticipants,
      {
        method: wellnessCenter.Challenges.getTeamParticipants,
        uniqueMethodArgs: {
          challengeId,
          teamId,
        },
        userIsInChallenge: true,
        userRank: userChallenge?.participant?.rank,
      }
    )

    yield put(
      WellnessCenterActions.getTeamParticipantsSuccess(participants, additionalParticipants)
    )
  } catch (e) {
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
    yield put(WellnessCenterActions.getTeamParticipantsFailure())
  }
}

export function* leaveSelectedChallenge() {
  try {
    const { challenge } = yield select((state) => state.wellnessCenter.selectedChallenge)
    if (!challenge) {
      throw new Error('challenge must be defined')
    }
    if (
      ![
        Challenges.Types.ChallengeStatus.ONGOING,
        Challenges.Types.ChallengeStatus.UPCOMING,
      ].includes(challenge.status)
    ) {
      throw new Error(
        `challenge status must be ${[
          Challenges.Types.ChallengeStatus.ONGOING,
          Challenges.Types.ChallengeStatus.UPCOMING,
        ]}`
      )
    }
    const wellnessCenter = yield call(createWellnessCenter)
    yield call(wellnessCenter.Challenges.removeUser, {
      challengeId: challenge.id,
    })

    // Refresh challenges (list + current one)
    yield all([
      put(WellnessCenterActions.getMyChallenges()),
      put(WellnessCenterActions.getNewChallenges()),
      call(getChallenge, { challengeId: challenge.id }),
    ])
    yield put(WellnessCenterActions.leaveSelectedChallengeSuccess())
  } catch (err) {
    yield put(WellnessCenterActions.leaveSelectedChallengeFailure())
    Sentry.captureException(err)
    logDdError(err.message, err.stack)
    Toast.show({ text1: I18n.t(`WellnessCenter.challenges.leaveError`) })
  }
}

export function* getTrackers_legacy() {
  try {
    const wellnessCenter = yield call(createWellnessCenter)
    const { TrackerStatus } = WellnessCenter.Trackers.Types

    const [trackers, userTrackers] = yield all([
      call(wellnessCenter.Trackers.getTrackers, {}),
      call(wellnessCenter.Users.getUsersTrackers, {}),
    ])

    const SUPPORTED_TRACKERS = { fitbit: 'fitbit', strava: 'strava', garmin: 'garmin' }
    const REMOVED_TRACKERS = {}

    // Add support for Apple Health for iOS users only, remove it entirely for Android users
    if (Platform.OS === 'ios') {
      SUPPORTED_TRACKERS.apple_healthkit = 'apple_healthkit'
    } else {
      REMOVED_TRACKERS.apple_healthkit = 'apple_healthkit'
    }

    // Add support for Google Fit for Android users only
    if (Platform.OS === 'android') {
      SUPPORTED_TRACKERS.google_fit = 'google_fit'
    }

    const user = (userTrackers || []).filter(
      ({ status, tracker_name }) =>
        status !== TrackerStatus.Disconnected && !REMOVED_TRACKERS[tracker_name]
    )
    const supported = []
    const comingSoon = []

    // Update base trackers list to remove trackers user has already connected & ones explicitly not supported
    const plainTrackers = trackers.filter(
      ({ tracker_name }) =>
        !user.some((ut) => ut.tracker_name === tracker_name) && !REMOVED_TRACKERS[tracker_name]
    )

    plainTrackers.forEach((tracker) => {
      // Separate supported trackers from those coming soon.
      if (SUPPORTED_TRACKERS[tracker.tracker_name] !== undefined) {
        supported.push(tracker)
        return
      }

      comingSoon.push(tracker)
    })
    yield put(WellnessCenterActions.getTrackersSuccess({ user, supported, comingSoon }))
  } catch (e) {
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
    yield put(WellnessCenterActions.getTrackersFailure())
    throw e
  }
}

/**
 * @todo deprecated callback pattern see https://dialoguemd.atlassian.net/browse/DIA-43082
 */
export function* connectTracker({ trackerName, id, callback }) {
  try {
    const { Users } = yield call(createWellnessCenter)
    const connectionId = yield call(Users.createUsersTrackerConnection, {
      trackerName,
      id,
    })
    yield call(getTrackers_legacy)
    callback && (yield call(callback, connectionId))
  } catch (e) {
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
    callback && (yield call(callback, null, e))
  }
}

/**
 * @todo deprecated callback pattern see https://dialoguemd.atlassian.net/browse/DIA-43082
 */
export function* disconnectTracker({ trackerName, callback }) {
  try {
    const { Users } = yield call(createWellnessCenter)
    yield call(Users.deleteUsersTrackerConnection, {
      trackerName,
    })
    yield call(getTrackers_legacy)
    callback && (yield call(callback, null))
  } catch (e) {
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
    callback && (yield call(callback, e))
  }
}

/**
 * @todo deprecated callback pattern see https://dialoguemd.atlassian.net/browse/DIA-43082
 */
export function* addMetricsTracker({
  trackerName,
  trackerActivities,
  fromDate,
  toDate,
  syncDate,
  callback,
}) {
  try {
    const { Users } = yield call(createWellnessCenter)

    if (trackerActivities.length <= 0) {
      Sentry.captureMessage(`addMetricsTracker with empty trackerActivities`, {
        level: 'warning',
        extra: {
          trackerName,
          fromDate,
          toDate,
          syncDate,
        },
      })
    }

    trackerActivities.forEach(({ activity, activity_time, metrics }, index) => {
      if (metrics.length <= 0) {
        Sentry.captureMessage(`addMetricsTracker with empty trackerActivities.*.metrics`, {
          level: 'warning',
          extra: {
            trackerName,
            fromDate,
            toDate,
            syncDate,
            index,
            trackerActivity: activity,
            trackerActivityTime: activity_time,
          },
        })
      }
    })

    yield retry(3, 500, Users.addUsersTrackerMetric, {
      trackerName,
      trackerActivities,
      fromDate,
      toDate,
      syncDate,
    })
    callback && (yield call(callback, null))
  } catch (e) {
    Sentry.addBreadcrumb({
      type: 'error',
      category: 'error',
      level: 'error',
      message: JSON.stringify(e),
    })
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
    callback && (yield call(callback, e))
    throw e
  }
}

/**
 * Helper function for syncAllSamplesForAppleHealthKit
 * @param activities array of activites for single day
 * @param cache array of strings for single day
 * @returns {boolean} if syncAllSamplesForAppleHealthKit should proceed
 */
export const dateAlreadySynced = (activities, cache) => {
  const isCacheNotStale = (activities, cache) =>
    activities.every(({ unique_id }) => cache.includes(unique_id))
  const isCacheNew = (activities, cache) => activities.length === 0 && cache === undefined
  const hasSameActivitesLength = (activities, cache) => activities.length === cache?.length

  return hasSameActivitesLength(activities, cache)
    ? isCacheNotStale(activities, cache)
    : isCacheNew(activities, cache)
}

/**
 * Helper function for syncAllSamplesForAppleHealthKit
 * @param startDate start of the timeframe
 * @param cache map of activities per day
 * @returns {string} the date to start the sync from
 */
export const adjustStartDateRangeBasedOnCache = (startDate, cache) => {
  const cacheStartDate = Object.keys(cache).sort()[0]

  return cacheStartDate && moment(cacheStartDate).isAfter(startDate, 'day')
    ? cacheStartDate
    : startDate
}

/**
 * Sync all samples of Apple Health Kit.
 * Computing all samples could increase memory usage and cause a crash.
 * Controlling the number of API call can be done by leveraging `interval` and `maxSize`.
 * Recommendation is to use an interval of 1 day to avoid taking risk of loading too much samples.
 * Caching results in adjusments of the startDate, as there's a limit how many activities can be cached
 * if there's any cache the BE is already populated with data, but not all updates would be synced.
 * The more activities - the smaller window of days back is kept up to date
 */
export function* syncAllSamplesForAppleHealthKit({
  startDate: requestedStartDate,
  endDate,
  interval,
  maxSize,
}) {
  const trackerName = WellnessCenter.Trackers.Types.SdkTrackerName.AppleHealthKit
  try {
    const healthKitActivitiesCache = yield call(getHealthKitCache)
    const newHealthKitActivitiesCache = {}

    const startDate = adjustStartDateRangeBasedOnCache(requestedStartDate, healthKitActivitiesCache)
    const mapDateRangeOptions = {
      startDate: formatDateToISOString(moment(startDate).startOf('day')),
      endDate: formatDateToISOString(transformDateLimit(moment(endDate).endOf('day'), moment())),
      interval: interval || [1, 'days'],
    }

    const metricsQueued = {
      trackerName: WellnessCenter.Trackers.Types.TrackerName.AppleHealthkit,
      fromDate: null,
      toDate: null,
      maxSize: maxSize || 100,
      trackerActivities: [],
    }

    const waitAndSync = function* () {
      const now = moment()
      // check if the roundup to seconds made the toDate in the furure
      // if so delay by the diff so BE validation does not throw
      if (moment(metricsQueued.toDate).isAfter(now)) {
        yield delay(moment(metricsQueued.toDate).diff(now))
      }
      yield spawn(addMetricsTracker, merge.all([{}, metricsQueued]))
      metricsQueued.trackerActivities = []
      metricsQueued.fromDate = null
    }

    const itDateRanges = TrackerHelpers.makeDateRangeIterator(mapDateRangeOptions)

    for (let [startDate, endDate] of itDateRanges) {
      const { fromDate, toDate } = AppleHealthKit.transformSamplesOptionsToAddMetricsParams({
        startDate,
        endDate,
      })

      const cacheDate = moment(startDate).format(I18n.t('DateFormat'))

      const activities = yield call(AppleHealthKit.getAllActivitySamples, { startDate, endDate })
      const cache = healthKitActivitiesCache[cacheDate]

      if (dateAlreadySynced(activities, cache)) {
        // chop the block when cached days are encountered
        if (metricsQueued.fromDate) {
          yield waitAndSync()
        }

        continue
      }

      const activitiesLength = metricsQueued.trackerActivities.push(...activities)
      metricsQueued.fromDate = metricsQueued.fromDate ? metricsQueued.fromDate : fromDate
      metricsQueued.toDate = toDate

      if (activities.length) {
        newHealthKitActivitiesCache[cacheDate] = activities.map(({ unique_id }) => unique_id)
      }

      if (metricsQueued.maxSize <= activitiesLength) {
        yield waitAndSync()
      }
    }

    if (Object.keys(newHealthKitActivitiesCache).length > 0) {
      yield call(setHealthKitCache, { ...healthKitActivitiesCache, ...newHealthKitActivitiesCache })
    }

    if (metricsQueued.fromDate) {
      yield waitAndSync()
    }
  } catch (err) {
    yield put(LoggerActions.error(err))
    yield put(
      LoggerActions.log(`Sync ${trackerName} completed with error: ${String(err?.message || err)}`)
    )
    throw err
  }
}

export const SYNC_ALL_SAMPLES_LAST_SYNC_THRESHOLD = [30, 'seconds']

export const SYNC_ALL_SAMPLES_FORBIDDEN_SCENES = [
  'conversation',
  'newConversation',
  'incomingCall',
  'videoCall',
]

export const SYNC_ALL_SAMPLES_APPLE_HEALTH_KIT_ARGS = (now) => ({
  startDate: now.clone().startOf('day').subtract(3, 'months').toISOString(),
  endDate: now.toISOString(),
})

/**
 * Sync all samples for every connected trackers.
 * @param {Object} options
 * @param {object} options.trackersWithSyncOptions - Record containing some tracker name and associated options.
 * @param {boolean} options.needsSyncTrackerStatus - Boolean to ensure `getTrackers` is refetch and populated.
 * @param {array} options.lastSyncTimeThreshold - Tuple represention to prevent sync a tracker if last_sync_time is not reached.
 *
 * @example
 * // Synchronize all connected trackers.
 * // Error handling is detached when trackerWithSyncOptions is not defined.
 * syncAllSamplesForTrackers()
 *
 * @example
 * // Prevent synchronize all connected trackers if it was already synchronize less than 5 minutes ago.
 * syncAllSamplesForTrackers({
 *   lastSyncTineThreshold: [5, 'minutes']
 * })
 *
 * @example
 * // Synchronize some connected trackers.
 * // Error handling is not detached.
 * syncAllSamplesForTrackers({
 *   trackersWithSyncOptions: {
 *      apple_healthkit: { startDate, endDate }
 *    }
 * })
 */
export function* syncAllSamplesForTrackers({
  trackersWithSyncOptions,
  needsSyncTrackerStatus,
  lastSyncTimeThreshold,
}) {
  try {
    const now = moment()

    const needsToBeSpawned = trackersWithSyncOptions ? false : true

    const applyOptionOrDefaultByTracker = (tracker_name) => {
      if (trackersWithSyncOptions && trackersWithSyncOptions[tracker_name]) {
        return trackersWithSyncOptions[tracker_name]
      }
      const applySyncActionArgs = SYNC_ALL_SAMPLES_APPLE_HEALTH_KIT_ARGS
      return applySyncActionArgs(now)
    }

    if (needsSyncTrackerStatus) {
      yield call(getTrackers_legacy)
    }

    const trackers = yield select(selectAllTrackersToSync, {
      keys: trackersWithSyncOptions ? Object.keys(trackersWithSyncOptions) : undefined,
      lastSyncTimeThreshold: lastSyncTimeThreshold
        ? now.clone().subtract(...lastSyncTimeThreshold)
        : undefined,
    })

    const syncAllSamplesActions = trackers.reduce((actions, { tracker_name }) => {
      if (tracker_name === WellnessCenter.Trackers.Types.SdkTrackerName.AppleHealthKit) {
        const syncAction = syncAllSamplesForAppleHealthKit
        const syncOptions = applyOptionOrDefaultByTracker(tracker_name)
        const syncEffect = needsToBeSpawned
          ? spawn(syncAction, syncOptions)
          : call(syncAction, syncOptions)
        actions.push(syncEffect)
      }
      return actions
    }, [])

    yield all(syncAllSamplesActions)
    yield call(getTrackers_legacy)
  } catch (e) {
    Sentry.captureException(e)
    logDdError(e.message, e.stack)
    yield put(
      LoggerActions.log('Sync all trackers completed with error: ' + String(e?.message || e))
    )
  }
}

export function* healthKitSyncRequest({ trigger }) {
  let isBackgrounded = undefined

  try {
    yield put(
      LoggerActions.log(
        `HealthKit sync request: started to process${
          isBackgrounded ? ' in background' : ' '
        }. ${trigger}`
      )
    )
    const start = moment()
    const trackerName = WellnessCenter.Trackers.Types.TrackerName.AppleHealthkit

    const shouldTrackerSyncBeIgnored = yield select(selectShouldTrackerSyncBeIgnored)
    if (shouldTrackerSyncBeIgnored) {
      yield put(
        LoggerActions.log(`HealthKit sync request: ignored. Reason: ${shouldTrackerSyncBeIgnored}`)
      )
      return
    }

    isBackgrounded = yield select(selectIsBackgrounded)
    if (isBackgrounded) {
      yield put(LoggerActions.log(`HealthKit sync request: checking AUTH`))
      yield call(checkRenewAuth)
    }

    const trackersState = yield select(selectTrackers)
    const noTrackerData = [trackersState?.trackers?.user, trackersState?.trackers?.supported].every(
      (trackerGroup) => !trackerGroup?.length
    )

    yield call(syncAllSamplesForTrackers, {
      needsSyncTrackerStatus: noTrackerData || !isBackgrounded,
      trackersWithSyncOptions: {
        [trackerName]: SYNC_ALL_SAMPLES_APPLE_HEALTH_KIT_ARGS(moment()),
      },
    })
    yield put(
      LoggerActions.log(
        `HealthKit sync request: completed with success${
          isBackgrounded ? ' in background' : ' '
        }. Sync duration: ${moment().diff(start)} ms`
      )
    )
  } catch (e) {
    yield put(LoggerActions.log('HealthKit sync request: completed with error. Error: ' + e))
    logDdError(e.message, e.stack)
    Sentry.captureException(e, {
      tags: {
        trackerName: WellnessCenter.Trackers.Types.TrackerName.AppleHealthkit,
      },
      extra: {
        isBackgrounded,
        trigger,
      },
    })
  }
}

/**
 * Creates a Channel for events from event sources from Apple Health Kit
 * See https://redux-saga.js.org/docs/advanced/Channels
 */
export function createAppleHealthKitChannel() {
  const trackerName = WellnessCenter.Trackers.Types.TrackerName.AppleHealthkit

  const errorHandler = (err, activity) => {
    Sentry.captureException(err, { tags: { trackerName, activity } })
    logDdError(err.message, err.stack)
  }

  return eventChannel((emit) =>
    AppleHealthKit.listenToAllActivities((err, activity) => {
      err ? errorHandler(err, activity) : emit({ activity })
    })
  )
}

export function* listenToHealthKitBackgroundUpdates() {
  yield put(LoggerActions.log('HealthKit sync watcher: background watcher started.'))
  let channel
  try {
    channel = yield call(createAppleHealthKitChannel)
    while (true) {
      const { activity } = yield take(channel)
      yield put(WellnessCenterActions.healthKitSyncRequest(`trigger: new metrics for ${activity}`))
    }
  } finally {
    if (yield cancelled() && channel) {
      channel.close()
    }
  }
}

export function* listenToForegroundingActions() {
  while (true) {
    yield take(AppSessionTypes.SET_APP_FOREGROUNDED)
    yield put(WellnessCenterActions.healthKitSyncRequest('trigger: foregrounding'))
  }
}

const createTrackerStatusChangeActionPatterns = (trackerName) => ({
  isConnected: ({ type, trackers }) =>
    type === WellnessCenterTypes.GET_TRACKERS_SUCCESS &&
    trackers?.user?.some(
      ({ tracker_name, status }) =>
        tracker_name === trackerName &&
        status === WellnessCenter.Trackers.Types.TrackerStatus.Connected
    ),
  isDisconnected: ({ type, trackers }) =>
    type === WellnessCenterTypes.GET_TRACKERS_SUCCESS &&
    trackers?.supported?.some(({ tracker_name }) => tracker_name === trackerName),
  isAboutToConnect: (action) =>
    action.type === WellnessCenterTypes.CONNECT_TRACKER && action.trackerName === trackerName,
})

export function* watchForHKSyncOpportunities(initialIsElligible) {
  let isElligible = initialIsElligible
  // if not elligible form the start, wait for elligibility update
  while (!isElligible) {
    yield put(
      LoggerActions.log(
        'HealthKit sync watcher: waiting for member to become elligible to use fitness trackers ...'
      )
    )
    yield take(PatientTypes.SCRIBE_V2_USER_FETCH_SUCCESS)
    const nextServiceFeatures = yield select(contentSelectors.selectServiceFeatures)
    isElligible = nextServiceFeatures.fitness_trackers
  }

  const trackerName = WellnessCenter.Trackers.Types.TrackerName.AppleHealthkit
  const HealthKitPatterns = createTrackerStatusChangeActionPatterns(trackerName)

  let trackerStatus = undefined
  while (true) {
    if (!trackerStatus) {
      yield put(
        LoggerActions.log('HealthKit sync watcher: member is elligible. Fetching trackers ...')
      )
      yield put(WellnessCenterActions.getTrackers())
      trackerStatus = yield race({
        isConnected: take(HealthKitPatterns.isConnected),
        isDisconnected: take(HealthKitPatterns.isDisconnected),
      })
    }

    if (trackerStatus.isDisconnected) {
      yield put(
        LoggerActions.log(
          'HealthKit sync watcher: tracker not connected. Waiting for member to connect a tracker ...'
        )
      )
      yield all([take(HealthKitPatterns.isAboutToConnect), take(HealthKitPatterns.isConnected)])
    }

    yield put(
      LoggerActions.log('HealthKit sync watcher: tracker connected. Initializing first sync ...')
    )
    yield put(WellnessCenterActions.healthKitSyncRequest(`trigger: first sync`))

    yield race([
      call(listenToHealthKitBackgroundUpdates),
      call(listenToForegroundingActions),
      take(HealthKitPatterns.isDisconnected),
    ])
    yield put(
      LoggerActions.log(
        'HealthKit sync watcher: background watcher terminated. Reason: tracker disconnected'
      )
    )
    trackerStatus = { isDisconnected: true }
  }
}

export function* healthKitSyncProcess() {
  try {
    yield put(LoggerActions.log('HealthKit sync watcher: waiting for initial data ...'))
    yield all([
      take(contentActions.getServiceFeaturesSuccess),
      take(PatientTypes.SCRIBE_V2_USER_FETCH_SUCCESS),
    ])
    yield put(LoggerActions.log('HealthKit sync watcher: data is ready.'))

    const serviceFeatures = yield select(contentSelectors.selectServiceFeatures)
    let isElligible = serviceFeatures.fitness_trackers

    while (true) {
      const watcherProcess = yield fork(watchForHKSyncOpportunities, isElligible)

      yield take(LoginTypes.LOGOUT)
      yield put(LoggerActions.log('HealthKit sync watcher: terminated; Reason: logged out'))
      // reset & go for another loop
      isElligible = false
      yield cancel(watcherProcess)
    }
  } catch (e) {
    yield put(LoggerActions.log('HealthKit sync watcher: terminated; Reason: error; Error: ' + e))
    logDdError(e.message, e.stack)
    Sentry.captureException(e, {
      tags: {
        trackerName: WellnessCenter.Trackers.Types.TrackerName.AppleHealthkit,
      },
    })
  }
}
