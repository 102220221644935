import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/native'

import { AvailabilitySlot } from '@dialogue/timekeeper'

import { isWeb } from 'APP/Helpers/checkPlatform'
import I18n from 'APP/Services/i18n'
import {
  selectBookAppointmentRequest,
  selectLoadingProviderBio,
  selectProvider,
  selectProviderById,
} from 'APP/Store/ProviderBooking/selectors'
import { providerBookingActions } from 'APP/Store/ProviderBooking'
import BottomSheetModal from 'APP/Components/BottomSheetModal'
import { PrimaryButton } from 'APP/Components/Buttons'
import { LoadingIndicator } from 'APP/Components/AppointmentBooking/common/loadingIndicator'
import { ProfileHeader } from './ProfileHeader'
import { ProviderAvailabilities, ProviderAvailability } from './ProviderAvailabilities'

const Wrapper = styled.View`
  flex: 1;
`

const ContentScrollingView = styled.ScrollView.attrs({
  contentContainerStyle: {
    flexGrow: 1,
  },
})``

const ContentView = styled.View`
  padding: 0 ${({ theme }) => theme.metrics.baseMargin}px;
`

const ConfirmationWrapper = styled.View`
  background-color: ${({ theme }) => theme.colors.background};
  box-shadow: 0px -8px 8px ${({ theme }) => theme.colors.background};
  elevation: 4;
`

export interface Selection {
  choice: string
  providerId: number
}

interface Props {
  baseChosenTimeslot?: AvailabilitySlot | null
  selection?: Selection | null
  withAvailabilitySelection?: boolean
  onConfirm: (slot: AvailabilitySlot | null) => void
  onClose: () => void
}

export const ProviderProfileModal = ({
  baseChosenTimeslot,
  withAvailabilitySelection = false,
  selection,
  onConfirm,
  onClose,
}: Props) => {
  const dispatch = useDispatch()
  const [selectedTimeslot, setSelectedTimeslot] = useState<AvailabilitySlot | null>(
    baseChosenTimeslot || null
  )
  const providerId = baseChosenTimeslot?.provider_id || selection?.providerId
  // @ts-expect-error store not typed
  const provider = useSelector((state) => selectProvider(state, providerId))
  const loading = useSelector(selectLoadingProviderBio)
  const providerData = useSelector(selectProviderById)
  const { loading: isBooking, data: bookedAppointment } = useSelector(selectBookAppointmentRequest)

  useEffect(() => {
    if (providerId) {
      dispatch(providerBookingActions.getProviderById(providerId))
    }
  }, [providerId, dispatch])

  // Fetch the providers bio if we don't already have it.
  useEffect(() => {
    if (providerId && !provider?.bio) {
      dispatch(providerBookingActions.getProviderBio(providerId))
    }
  }, [providerId, provider, dispatch])

  // When an appointment has been booked, close this modal.
  useEffect(() => {
    if (bookedAppointment) {
      onClose()
    }
  }, [bookedAppointment, onClose])

  // If parent component has an opinion of what timeslot should be selected, let's honour it.
  useEffect(() => {
    if (baseChosenTimeslot) {
      setSelectedTimeslot(baseChosenTimeslot)
    }
  }, [baseChosenTimeslot])

  const handleCancel = useCallback(() => {
    setSelectedTimeslot(null)
    onClose()
  }, [onClose])

  const handleConfirm = useCallback(() => {
    onConfirm(selectedTimeslot)
  }, [onConfirm, selectedTimeslot])

  return (
    // @ts-expect-error not typed yet
    <BottomSheetModal
      isVisible={!!providerId}
      onClose={handleCancel}
      style={{
        height: '100%',
      }}
      containerStyle={{
        height: '95%',
        ...(isWeb() ? { width: '100%', maxWidth: '1000px', alignSelf: 'center' } : {}),
      }}
      title={I18n.t('AppointmentBooking.profile_title', { name: provider?.givenName })}
      testID="provider-profile-modal"
    >
      {provider && (
        <Wrapper>
          {loading ? (
            <LoadingIndicator />
          ) : (
            <>
              <ContentScrollingView>
                <ContentView onStartShouldSetResponder={() => true}>
                  <ProfileHeader provider={provider} providerData={providerData} />
                  {withAvailabilitySelection ? (
                    <ProviderAvailabilities
                      providerId={provider.id}
                      selectedSlot={selectedTimeslot}
                      onSelect={setSelectedTimeslot}
                    />
                  ) : (
                    (selectedTimeslot || selection) && (
                      <ProviderAvailability slot={selectedTimeslot} selection={selection?.choice} />
                    )
                  )}
                </ContentView>
              </ContentScrollingView>
              {(selectedTimeslot || selection) && (
                <ConfirmationWrapper>
                  <PrimaryButton
                    title={I18n.t('AppointmentBooking.confirm_appointment')}
                    testID="appointmentBooking.confirmAppointment"
                    onPress={handleConfirm}
                    disabled={isBooking}
                  />
                </ConfirmationWrapper>
              )}
            </>
          )}
        </Wrapper>
      )}
    </BottomSheetModal>
  )
}
