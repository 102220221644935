import React, { useEffect, useRef, useState } from 'react'
import { ActivityIndicator, StyleSheet } from 'react-native'
import SessionManager from 'APP/NativeModules/SessionManager'

import { Colors } from 'APP/Themes'
import { VideoContainer } from './Container'

const SubscriberView = ({ streamId }) => {
  const videoWrapper = useRef()

  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (videoWrapper.current && streamId) {
      SessionManager.subscribe(streamId).then((videoElement) => {
        videoElement.style.width = '100%'
        videoElement.style.height = '100%'
        videoElement.style.objectFit = 'cover'

        videoElement.addEventListener('loadeddata', () => setIsReady(true))

        if (typeof videoWrapper.current.appendChild === 'function') {
          videoWrapper.current.appendChild(videoElement)
        }
      })
    }
    return () => {
      SessionManager.unsubscribe()
    }
  }, [streamId])

  return (
    <>
      <VideoContainer
        testID="subscriber-video"
        ref={videoWrapper}
        isReady={isReady}
      ></VideoContainer>
      {!isReady && (
        <ActivityIndicator
          testID="subscriber-loading"
          color={Colors.videoBorder}
          animating
          size="large"
          style={StyleSheet.absoluteFillObject}
        />
      )}
    </>
  )
}

export default SubscriberView
