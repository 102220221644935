import { useCallback, useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Linking } from 'react-native'
import DeviceInfo from 'react-native-device-info'
import { WellnessCenter } from '@dialogue/services'
import WellnessCenterActions from 'APP/Redux/WellnessCenterRedux'
import ActiveMinutesActions from 'APP/Redux/ActiveMinutesRedux'
import { selectTrackers } from 'APP/Sagas/WellnessCenterSagas'
import * as Sentry from '@sentry/react-native'
import Config from 'APP/Config'
import * as AppleHealthKit from 'APP/Lib/AppleHealthKit'
import moment from 'moment'
import { USER_TRACKER_STATUSES } from 'APP/Lib/Enums'
import { logDdError } from 'APP/Lib/Datadog'

const getTrackerUrl = (returnUrl, connectionId, name) => {
  const baseURL = encodeURIComponent(returnUrl)
  const successURL = `${baseURL}?success=${name}`
  const errorURL = `${baseURL}?error=${encodeURIComponent(AuthorizeError)}`
  connectionId = encodeURIComponent(connectionId)
  return `${Config.WELLNESSCENTER_SERVICE_URL}/api/trackers/redirect-to-tracker-auth?connection_id=${connectionId}&success_url=${successURL}&error_url=${errorURL}`
}

export const useTrackerApi = (trackerName) => {
  const dispatch = useDispatch()
  const trackers = useSelector(selectTrackers)

  const dispatchConnectTrackerAsync = useCallback((id) => {
    return new Promise((resolve, reject) => {
      const action = WellnessCenterActions.connectTracker(trackerName, id, (connectionId, err) => {
        err ? reject(Error(ConnectApiError, { cause: err })) : resolve(connectionId)
      })
      dispatch(action)
    })
  }, [])

  const dispatchDisconnectTrackerAsync = useCallback(() => {
    return new Promise((resolve, reject) => {
      const action = WellnessCenterActions.disconnectTracker(trackerName, (err) => {
        err ? reject(Error(DisconnectApiError, { cause: err })) : resolve()
      })
      dispatch(action)
    })
  }, [])

  const dispatchModifyingTrackers = useCallback(
    (value) => {
      const trackerSet = new Set([...trackers.modifying])
      value === true ? trackerSet.add(trackerName) : trackerSet.delete(trackerName)
      return dispatch(WellnessCenterActions.modifyingTrackers(Array.from(trackerSet)))
    },
    [trackers.modifying]
  )

  const dispatchSetAnyTrackerLastConnectedTime = useCallback((connectTime) => {
    return dispatch(WellnessCenterActions.setAnyTrackerLastConnectedTime(connectTime))
  })

  const connectionStatus = useSelector((state) => {
    const { TrackerStatus } = WellnessCenter.Trackers.Types
    const { trackers } = state.wellnessCenter.trackers

    return trackers.user.reduce(
      (connectionStatus, { tracker_name, status }) => {
        if (tracker_name === trackerName) {
          return {
            isNew: status === TrackerStatus.New,
            isConnected: status === TrackerStatus.Connected,
            isDisconnected: status === TrackerStatus.Disconnected,
            isBroken: status === TrackerStatus.Broken,
          }
        }
        return connectionStatus
      },
      {
        isNew: true,
        isConnected: false,
        isDisconnected: false,
        isBroken: false,
      }
    )
  })

  return {
    ...connectionStatus,
    dispatchConnectTrackerAsync,
    dispatchDisconnectTrackerAsync,
    dispatchModifyingTrackers,
    dispatchSetAnyTrackerLastConnectedTime,
  }
}

export const AppleHealtKitPermissions = {
  read: [
    AppleHealthKit.Constants.Permissions.ActivitySummary,
    AppleHealthKit.Constants.Permissions.AppleExerciseTime,
    AppleHealthKit.Constants.Permissions.DistanceCycling,
    AppleHealthKit.Constants.Permissions.DistanceSwimming,
    AppleHealthKit.Constants.Permissions.DistanceWalkingRunning,
    AppleHealthKit.Constants.Permissions.StepCount,
    AppleHealthKit.Constants.Permissions.Steps,
    AppleHealthKit.Constants.Permissions.Workout,
  ],
  write: [AppleHealthKit.Constants.Permissions.ActivitySummary],
}

export const ConnectError = 'Connect Error'
export const ConnectApiError = 'Connect Api Error'
export const AuthorizeError = 'Authorize Error'
export const DisconnectError = 'Disconnect Error'
export const DisconnectApiError = 'Disconnect Api Error'
export const AddMetricsApiError = 'Add Metrics Api Error'

export const Errors = {
  ConnectError,
  ConnectApiError,
  AuthorizeError,
  DisconnectError,
  DisconnectApiError,
  AddMetricsApiError,
}

export const ErrorCodes = {
  FORBIDDEN: 'FORBIDDEN',
}

const useApiBasedTracker = (trackerName) => {
  const [error, setError] = useState(null)

  const permissions = { scopes: [] }
  const {
    dispatchConnectTrackerAsync,
    dispatchDisconnectTrackerAsync,
    dispatchModifyingTrackers,
    dispatchSetAnyTrackerLastConnectedTime,
  } = useTrackerApi(trackerName)

  const captureException = (err) => {
    Sentry.captureException(err, { trackerName })
    logDdError(`Error in tracker: ${trackerName}`, err.stack)
  }

  const redirectToTrackerAuth = (connectionId, returnUrl) => {
    if (!connectionId || !returnUrl) {
      throw new Error(ConnectApiError, { cause: 'Missing required parameters' })
    }

    Linking.openURL(getTrackerUrl(returnUrl, connectionId, trackerName))
  }

  const connect = useCallback(
    async (returnUrl) => {
      try {
        dispatchModifyingTrackers(true)
        const connectionId = await dispatchConnectTrackerAsync()
        redirectToTrackerAuth(connectionId, returnUrl)
        dispatchSetAnyTrackerLastConnectedTime(moment().toString())
      } catch (err) {
        const connectError = Error(ConnectError, { cause: err })
        setError(connectError)
        captureException(connectError)
      } finally {
        dispatchModifyingTrackers(false)
      }
    },
    [permissions]
  )

  const disconnect = useCallback(async () => {
    try {
      dispatchModifyingTrackers(true)
      await dispatchDisconnectTrackerAsync()
    } catch (err) {
      const disconnectError = Error(DisconnectError, { cause: err })
      setError(disconnectError)
      captureException(disconnectError)
    } finally {
      dispatchModifyingTrackers(false)
    }
  }, [permissions])

  return {
    name: trackerName,
    permissions,
    error,
    connect,
    disconnect,
  }
}

export const useFitbit = () => useApiBasedTracker(WellnessCenter.Trackers.Types.TrackerName.Fitbit)
export const useGoogleFit = () =>
  useApiBasedTracker(WellnessCenter.Trackers.Types.TrackerName.GoogleFit)
export const useStrava = () => useApiBasedTracker(WellnessCenter.Trackers.Types.TrackerName.Strava)
export const useGarmin = () => useApiBasedTracker(WellnessCenter.Trackers.Types.TrackerName.Garmin)

export const useAppleHealthKit = () => {
  const name = WellnessCenter.Trackers.Types.TrackerName.AppleHealthkit
  const [error, setError] = useState(null)
  const [permissions] = useState(AppleHealtKitPermissions)
  const {
    dispatchConnectTrackerAsync,
    dispatchDisconnectTrackerAsync,
    dispatchModifyingTrackers,
    isBroken,
    isNew,
    isDisconnected,
    isConnected,
    dispatchSetAnyTrackerLastConnectedTime,
  } = useTrackerApi(name)

  const captureException = (err) => {
    Sentry.captureException(err, { trackerName: name })
    logDdError(`Error in tracker: ${name}`, err.stack)
  }

  const authorize = useCallback(() => {
    return new Promise((resolve, reject) => {
      AppleHealthKit.initHealthKit({ permissions }, (err, result) => {
        err ? reject(Error(AuthorizeError, { cause: err })) : resolve(result)
      })
    })
  }, [permissions])

  const connect = useCallback(async () => {
    try {
      dispatchModifyingTrackers(true)
      await authorize()
      await dispatchConnectTrackerAsync(DeviceInfo.getUniqueId())
      dispatchSetAnyTrackerLastConnectedTime(moment().toString())
    } catch (err) {
      const connectError = Error(ConnectError, { cause: err })
      connectError.code = err?.cause?.additionalInfo?.error_code
      setError(connectError)
      captureException(connectError)
      throw connectError
    } finally {
      dispatchModifyingTrackers(false)
    }
  }, [permissions])

  const disconnect = useCallback(async () => {
    try {
      dispatchModifyingTrackers(true)
      await dispatchDisconnectTrackerAsync()
      AppleHealthKit.clearHealthKitCache()
    } catch (err) {
      const disconnectError = Error(DisconnectError, { cause: err })
      setError(disconnectError)
      captureException(err)
    } finally {
      dispatchModifyingTrackers(false)
    }
  }, [permissions])

  return {
    name,
    permissions,
    error,
    isBroken,
    isNew,
    isDisconnected,
    isConnected,
    authorize,
    connect,
    disconnect,
  }
}

// This hook is used to check if the user has any tracker connected in the past 90sec
// if so, it will expose the syncing state (true) that will be resolved (false) once 90sec pass from the last tracker connection
export const useTrackerSyncingStatus = (userTrackers, onSyncFinished) => {
  const [isSyncing, setIsSyncing] = useState(false)
  const synchronizationDelayLimit = 90000

  const trackerConnectedDifference = useMemo(() => {
    if (!userTrackers) return 0
    const trackerConnectedDifferences = [
      ...userTrackers
        .filter(({ created_at }) => !!created_at)
        .map(({ created_at }) => {
          const difference = moment().diff(moment.utc(created_at))
          return difference < 0 ? 0 : difference
        }),
    ]
    return trackerConnectedDifferences
      .filter((delay) => delay < synchronizationDelayLimit)
      .sort((delay1, delay2) => delay1 - delay2)[0]
  }, [userTrackers])

  useEffect(() => {
    if (trackerConnectedDifference >= 0) {
      let timeoutId
      timeoutId = setTimeout(() => {
        onSyncFinished()
        setIsSyncing(false)
        timeoutId = undefined
      }, synchronizationDelayLimit - trackerConnectedDifference)

      setIsSyncing(() => !!timeoutId)

      return () => {
        clearTimeout(timeoutId)
        setIsSyncing(false)
        timeoutId = undefined
      }
    }
  }, [trackerConnectedDifference])

  return isSyncing
}

export const useConnectedTrackerStatus = ({ enabled, isNoData, onSyncFinished }) => {
  const dispatch = useDispatch()
  const getConnectedTrackers = () => dispatch(ActiveMinutesActions.getConnectedTrackers())

  const {
    data: trackers,
    loading,
    retry,
  } = useSelector((state) => state.activeMinutes.connectedTrackers)

  useEffect(() => {
    if (enabled) {
      getConnectedTrackers()
    }
  }, [enabled])

  const isSyncing = useTrackerSyncingStatus(trackers, onSyncFinished)

  const status = useMemo(() => {
    if (!enabled || !trackers || loading || retry) return undefined

    if (!trackers.length) return USER_TRACKER_STATUSES.NO_TRACKERS

    if (isSyncing) return USER_TRACKER_STATUSES.SYNCING

    if (
      isNoData &&
      trackers.find((tracker) => {
        if (!tracker.created_at) return false

        const lastCreatedAt = moment.utc(tracker.created_at)
        const isntMonday = new Date().getDay() !== 1
        const connectedSince24H = lastCreatedAt.isBefore(moment().subtract(1, 'days'))

        return isntMonday && connectedSince24H
      })
    )
      return USER_TRACKER_STATUSES.NO_DATA

    if (trackers.find((tracker) => tracker.status !== 'connected'))
      return USER_TRACKER_STATUSES.BROKEN_TRACKER
  }, [trackers, enabled, isNoData, isSyncing])

  return status
}

export const useUserTrackerStatus_legacy = ({ enabled, isNoData, onSyncFinished }) => {
  const dispatch = useDispatch()
  const getTrackers = () => dispatch(WellnessCenterActions.getTrackers())

  const { trackers, loading, retry } = useSelector((state) => state.wellnessCenter.trackers)

  useEffect(() => {
    if (enabled) {
      getTrackers()
    }
  }, [enabled])

  const isSyncing = useSyncingStatus_legacy(trackers.user, onSyncFinished)

  const status = useMemo(() => {
    if (!enabled || !trackers?.user || loading || retry) return undefined

    if (!trackers.user.length) return USER_TRACKER_STATUSES.NO_TRACKERS

    if (isSyncing) return USER_TRACKER_STATUSES.SYNCING

    if (
      isNoData &&
      trackers.user.find((tracker) => {
        if (!tracker.last_connected) return false

        const lastConnected = moment.utc(tracker.last_connected)
        const isntMonday = new Date().getDay() !== 1
        const connectedSince24H = lastConnected.isBefore(moment().subtract(1, 'days'))

        return isntMonday && connectedSince24H
      })
    )
      return USER_TRACKER_STATUSES.NO_DATA

    if (trackers.user.find((tracker) => tracker.status === 'broken'))
      return USER_TRACKER_STATUSES.BROKEN_TRACKER
  }, [trackers, enabled, isNoData, isSyncing])

  return status
}

export const useSyncingStatus_legacy = (userTrackers, onSyncFinished) => {
  const { anyTrackerLastConnectedTime } = useSelector((state) => state.wellnessCenter.trackers)
  const [isSyncing, setIsSyncing] = useState(false)
  const synchronizationDelayLimit = 90000

  const trackerConnectedDifference = useMemo(() => {
    const trackerConnectedDifferences = [
      ...userTrackers
        .filter(({ last_connected }) => !!last_connected)
        .map(({ last_connected }) => {
          const difference = moment().diff(moment.utc(last_connected))
          return difference < 0 ? 0 : difference
        }),
    ]

    if (anyTrackerLastConnectedTime)
      trackerConnectedDifferences.push(moment().diff(moment(anyTrackerLastConnectedTime)))

    return trackerConnectedDifferences
      .filter((delay) => delay < synchronizationDelayLimit)
      .sort((delay1, delay2) => delay1 - delay2)[0]
  }, [anyTrackerLastConnectedTime, userTrackers])

  useEffect(() => {
    if (trackerConnectedDifference >= 0) {
      let timeoutId
      timeoutId = setTimeout(() => {
        onSyncFinished()
        setIsSyncing(false)
        timeoutId = undefined
      }, synchronizationDelayLimit - trackerConnectedDifference)

      setIsSyncing(() => !!timeoutId)

      return () => {
        clearTimeout(timeoutId)
        setIsSyncing(false)
        timeoutId = undefined
      }
    }
  }, [trackerConnectedDifference])

  return isSyncing
}
