import React, { Fragment } from 'react'
import { View, Text, TouchableOpacity, Modal } from 'react-native'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import DialogueMarkdown from 'APP/Components/DialogueMarkdown'
import I18n from 'APP/Services/i18n'
import { Metrics, Colors } from 'APP/Themes'
import Styles, { MarkdownStyles } from './style'
import { ResponsiveScrollView } from 'APP/Converse/Layout'

const FAQModal = ({ isVisible, onClose }) => {
  return (
    <Modal
      statusBarTranslucent
      animationType="slide"
      transparent
      visible={isVisible}
      onRequestClose={onClose}
      testID="FAQModal"
    >
      <View style={Styles.modal}>
        <View style={Styles.header}>
          <TouchableOpacity onPress={onClose}>
            <MaterialIcon name="close" size={Metrics.icons.small} color={Colors.text} />
          </TouchableOpacity>
        </View>
        <ResponsiveScrollView contentContainerStyle={Styles.content}>
          <Text style={Styles.titleH1}>{I18n.t('ActiveMinutes.faq.header')}</Text>
          {I18n.t('ActiveMinutes.faq.content', { returnObjects: true }).map(
            ({ header, markdown }) => (
              <Fragment key={header}>
                <Text style={Styles.titleH2}>{header}</Text>
                <DialogueMarkdown markdown={markdown} styles={MarkdownStyles} />
              </Fragment>
            )
          )}
        </ResponsiveScrollView>
      </View>
    </Modal>
  )
}

export default FAQModal
