import { Platform } from 'react-native'
import { Colors } from 'APP/Themes'

export default {
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
    pointerEvents: 'all',
    backgroundColor: Colors.appBg,
    overflow: 'hidden',
    flex: 1,
    borderWidth: 1,
    ...Platform.select({
      web: {
        alignSelf: 'stretch',
        justifySelf: 'stretch',
      },
    }),
  },
  containerDisabled: {
    pointerEvents: 'none',
  },
  containerInner: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 0,
  },
  bodyImage: {
    position: 'absolute',
    zIndex: 1,
    userSelect: 'none',
    userDrag: 'none',
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight: '100%',
    height: '100%',
  },
  bodyPartContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
    height: '100%',
    width: '100%',
    maskType: 'alpha',
    WebkitMaskSize: 'contain',
    WebkitMaskPosition: 'center center',
    WebkitMaskRepeat: 'no-repeat',
  },
  zoomContainer: {
    position: 'absolute',
    right: '3%',
    height: 90,
    borderRadius: 20,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: Colors.appBg,
    backgroundColor: Colors.appBg,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 1,
  },
  zoomIcon: {
    width: 16,
    height: 16,
    margin: 2,
  },
  iconButton: {
    padding: 5,
    borderRadius: '50%',
    borderWidth: 0,
    backgroundColor: Colors.appBg,
  },
}
