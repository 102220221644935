import { Dimensions, Platform } from 'react-native'
import { getStatusBarHeight, getBottomSpace } from 'react-native-iphone-x-helper'
import Constants from 'expo-constants'

const { width, height } = Dimensions.get('window')

const statusBarHeight = Platform.OS === 'ios' ? getStatusBarHeight() + 5 : Constants.statusBarHeight
const navBarHeight = height / 14
const bottomSpace = getBottomSpace()

const metrics = {
  tabletView: {
    max: 956,
    min: 600,
  },
  mobileView: {
    max: 600,
    min: 0,
  },
  baseMargin: 16,
  section: 25,
  horizontalLineHeight: 1,
  screenWidth: width < height ? width : height,
  screenHeight: width < height ? height : width,
  navBarHeight,
  statusBarHeight,
  bottomSpace,
  tabBarHeight: 76 + bottomSpace,
  buttonRadius: 4,
  radius: {
    medium: 10,
  },
  avatar: {
    height: 40,
    width: 40,
    borderRadius: 20,
  },
  buttons: {
    height: 48,
    radius: 30,
  },
  icons: {
    tiny: 15,
    small: 20,
    medium: 30,
    large: 45,
    xl: 60,
  },
  images: {
    small: 20,
    medium: 40,
    large: 60,
    logo: 300,
  },
  carouselDot: {
    size: 8,
    borderRadius: 4,
    margin: 6,
  },
}

export default metrics
