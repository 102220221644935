import { StyleSheet } from 'react-native'
import { Colors, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  container: {
    position: 'absolute',
    right: -4,
    bottom: 100,
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
    borderWidth: 3,
    borderColor: 'black',
    backgroundColor: 'white',
    zIndex: 100000,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    minHeight: 40,
    minWidth: 40,
  },
  toolsContainer: {
    width: 300,
    right: 0,
    bottom: 0,
    padding: 12,
  },
  fab: {
    height: 30,
    width: 30,
    padding: 0,
    margin: 0,
    right: -1,
    bottom: 2,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  },
  fabInline: {
    height: 30,
    position: 'relative',
    right: 0,
    bottom: 0,
  },
  button: {
    margin: 0,
    borderBottomWidth: 1,
    borderColor: 'transparent',
    padding: 10,
    paddingLeft: 16,
    marginRight: 12,
    width: '100%',
  },
  buttonText: {
    color: 'black',
    fontSize: 12,
  },
  buttonTextDisabled: {
    color: Colors.blackLight,
    fontSize: 12,
  },
  infoTextContainer: {
    backgroundColor: 'white',
    width: '100%',
    borderRadius: 20,
    marginBottom: 5,
    overflow: 'hidden',
  },
  infoTextItem: {
    backgroundColor: 'grey',
    width: '100%',
    paddingVertical: 8,
  },
  infoTextItemTrue: {
    backgroundColor: 'green',
    width: '100%',
    paddingVertical: 8,
  },
  infoTextItemFalse: {
    backgroundColor: 'orange',
    width: '100%',
    paddingVertical: 8,
  },
  infoText: {
    color: 'white',
    paddingLeft: 16,
    fontSize: 12,
  },
  featuresModal: {
    height: Metrics.screenHeight / 1.75,
  },
})
