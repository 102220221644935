import base64 from 'base-64'

export const removeEmpty = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key])
    else if (obj[key] === undefined || obj[key] === null) delete obj[key]
  })
  return obj
}

export const RegExBank = {
  isImage: new RegExp('.(jpeg|jpg|png)$', 'i'),
}

export const FileHelpers = {
  /**
   * Get whether a file is an image (tests file extension)
   * @param {string} strUri - URI to test on.
   * @return {boolean}
   */
  isImage: (strUri) => {
    return typeof strUri !== 'string'
      ? false
      : mC.isImage[strUri] || (mC.isImage[strUri] = RegExBank.isImage.test(strUri))
  },

  /**
   * Get the file extension of input string
   * @param {string} strUri - URI to test on.
   * @return {string} - file extension (forced lowercase), '' for any error state
   */
  toFileType: (strUri) => {
    if (typeof mC.toFileType[strUri] !== 'undefined') return mC.toFileType[strUri]
    try {
      const fileName = FileHelpers.toFileName(strUri, true)
      if (!fileName || fileName.lastIndexOf('.') === -1) return (mC.toFileType[strUri] = '')
      const fileExt = fileName.split('.').pop().toLowerCase()
      return (mC.toFileType[strUri] = fileExt)
    } catch (e) {
      return (mC.toFileType[strUri] = '')
    }
  },

  /**
   * Get the file name of input
   * @param {string} strUri - URI to act on.
   * @param {boolean} withExtension - includes extension when true
   * @return {string} - filename, '' for any error state
   */
  toFileName: (strUri, withExtension = true) => {
    const mCKey = String(strUri) + String(withExtension)
    if (typeof mC.toFileName[mCKey] !== 'undefined') return mC.toFileName[mCKey]
    try {
      const fileName = strUri.replace(/\\/g, '/').split('/').pop()
      const fileNameParts = fileName.split('.')
      const fileNameWithoutExt = fileNameParts.shift()
      const fileNameUriDecoded = decodeURIComponent(withExtension ? fileName : fileNameWithoutExt)
      return (mC.toFileName[mCKey] = fileNameUriDecoded)
    } catch (e) {
      return (mC.toFileName[mCKey] = '')
    }
  },
}

// Memoization / cache init
const mC = {}
Object.keys(FileHelpers).forEach((key) => {
  mC[key] = {}
})

// Add ordinal suffix to number
export const nth = (num, locale, { gender = 'f', skipOther = false } = {}) => {
  let j = num % 10
  let k = num % 100

  if (locale == 'fr') {
    if (num == 1 && gender === 'f') return num + 're'
    else if (num === 1 && gender === 'm') return num + 'er'

    if (skipOther) {
      return `${num}`
    }
    return num + 'e'
  } else {
    if (j == 1 && k != 11) return num + 'st'
    if (j == 2 && k != 12) return num + 'nd'
    if (j == 3 && k != 13) return num + 'rd'
    if (skipOther) {
      return `${num}`
    }
    return num + 'th'
  }
}

// export const isImage = (filename) => /\.(jpg|jpeg|png|gif)$/i.test(filename)

export const filenameFromPath = (filename) => {
  if (!filename || (filename && typeof filename !== 'string')) return ''
  return filename.substring(filename.lastIndexOf('/') + 1)
}

export const validFileTypes = /\.(jpg|jpeg|png|gif|pdf)$/

export const getFileInfo = (filename) => {
  const normalizedFile = filenameFromPath(filename)
  const normalizedFilename = normalizedFile.substring(0, normalizedFile.lastIndexOf('.'))
  const normalizedExtension = normalizedFile.substring(normalizedFile.lastIndexOf('.') + 1)
  const isRestrictedFileType = !validFileTypes.test(filename)

  return {
    isImage: FileHelpers.isImage(normalizedFile),
    name: normalizedFilename,
    extension: normalizedExtension,
    isRestrictedFileType,
  }
}

export function decodeBase64Token(token) {
  try {
    return JSON.parse(base64.decode(token))
  } catch (error) {
    return {}
  }
}

export const toCamelCase = (str) => {
  return str.toLowerCase().replace(/_([a-z])/g, (match, letter) => letter.toUpperCase())
}
