import Branch from 'branch-sdk'
import Config from 'APP/Config'
import { END } from 'redux-saga'

const BranchEventChannel = (emmiter) => {
  Branch.init(Config.BRANCH_KEY, (err, data) => {
    if (err) {
      emmiter({ error: err })
    } else {
      emmiter({ params: data?.data_parsed })
    }

    emmiter(END)
  })

  // Web events don't keep firing, no unsubscribe needed
  return () => {}
}

// Stub method. Web shouldn't ever actually call this right now
export const openUrl = () => {}

export default BranchEventChannel
