import { StyleSheet } from 'react-native'

import { ApplicationStyles, Fonts, Metrics, Colors } from 'APP/Themes'

export default StyleSheet.create({
  headerContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    ...Fonts.style.h3,
    marginHorizontal: Metrics.baseMargin * 1.5,
    flexGrow: 1,
    marginBottom: Metrics.baseMargin,
    color: Colors.text,
  },
  seeAllButtonContainer: {
    backgroundColor: 'transparent',
    height: Metrics.buttons.height,
  },
  cardContainer: {
    ...ApplicationStyles.shadow,
    flex: 1,
    backgroundColor: Colors.elementsBg,
    marginHorizontal: Metrics.baseMargin * 1.5,
    paddingHorizontal: Metrics.baseMargin,
    paddingTop: Metrics.baseMargin * 2,
    paddingBottom: Metrics.baseMargin,
    borderRadius: 20,
    alignItems: 'center',
    marginBottom: 4, // Tiny bit of margin so that the shadow doesn't get cut off
  },
  noCarePlanItem: {
    ...ApplicationStyles.shadow,
    borderRadius: 1,
  },
  noCarePlansBody: {
    ...Fonts.style.body,
    marginVertical: Metrics.baseMargin,
    color: Colors.text,
    textAlign: 'center',
  },
})
