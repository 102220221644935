import React, { useEffect, useImperativeHandle, useState } from 'react'
import { View, Text } from 'react-native'
import debounce from 'lodash.debounce'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import I18n from 'APP/Services/i18n'
import BottomSheetModal from 'APP/Components/BottomSheetModal'
import DialogueMarkdown from 'APP/Components/DialogueMarkdown'
import { SliderComponent } from 'APP/Converse/Slider'
import { Metrics, Colors } from 'APP/Themes'
import Styles, { MarkdownStyles } from './style'
import Analytics from 'APP/Services/Analytics'

const TARGET_RANGE = [150, 900]
const TARGET_STEP = 10

const ChangeTargetModal = React.forwardRef(
  ({ isVisible, onClose, currentTarget = TARGET_RANGE[0], onSubmit }, ref) => {
    const [value, setValue] = useState([currentTarget])
    const resetForm = () => setValue([currentTarget])

    useEffect(() => {
      if (isVisible) {
        resetForm()
        Analytics.trackScreen('Change target')
      }
    }, [isVisible])

    useImperativeHandle(ref, () => ({
      reset: resetForm,
    }))

    const handleSubmitDebounced = debounce(() => onSubmit(value[0]), 500)

    return (
      <BottomSheetModal
        isVisible={isVisible}
        onClose={onClose}
        title={I18n.t('ActiveMinutes.changeTarget.title')}
        testID="ChangeTargetModal"
      >
        <View style={Styles.content}>
          <View style={Styles.targetLabel}>
            <Text style={Styles.h1}>{value?.[0]}</Text>
            <Text style={Styles.bodyLarge}>{I18n.t('ActiveMinutes.changeTarget.valueLabel')}</Text>
            <Text style={Styles.bodySmall}>
              {I18n.t('ActiveMinutes.changeTarget.valueFormat', {
                hours: Math.floor((value?.[0] || 0) / 60),
                minutes: (value?.[0] || 0) % 60,
              })}
            </Text>
          </View>
          <View style={Styles.slider} testID="targetSliderContainer">
            <SliderComponent
              value={value}
              minValue={TARGET_RANGE[0]}
              maxValue={TARGET_RANGE[1]}
              onChange={(...args) => {
                handleSubmitDebounced.cancel()
                setValue(...args)
              }}
              onChangeFinish={handleSubmitDebounced}
              step={TARGET_STEP}
              unselectedStyle={Styles.sliderUnselectedBar}
              allowOverlap
            />
            <View style={Styles.sliderValues}>
              {TARGET_RANGE.map((value) => (
                <Text style={Styles.body} key={value}>
                  {I18n.t('ActiveMinutes.changeTarget.sliderValues', { value })}
                </Text>
              ))}
            </View>
          </View>
          <View style={Styles.banner}>
            <MaterialIcon
              name="check-circle-outline"
              size={Metrics.icons.small}
              color={Colors.text}
            />
            <View style={Styles.markdown}>
              <DialogueMarkdown
                markdown={I18n.t('ActiveMinutes.changeTarget.bannerMarkdown')}
                styles={MarkdownStyles}
              />
            </View>
          </View>
        </View>
      </BottomSheetModal>
    )
  }
)
ChangeTargetModal.displayName = 'ChangeTargetModal'

export default ChangeTargetModal
