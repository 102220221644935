// This file was overwritten because of differences in the web and native sdks
import * as LDClient from 'launchdarkly-js-client-sdk'
import * as Sentry from '@sentry/react'

import Config from 'APP/Config'
import { logDdError } from 'APP/Lib/Datadog'

// TODO: DIA-40444, Rehydrate the store redux LD
class _LaunchDarkly {
  constructor() {
    if (__DEV__) {
      console.log('Initializing Launch Darkly')
    }
  }

  init() {
    const multiContexts = {
      kind: 'multi',
      user: {
        key: Config.LD_USER_KEY.web,
      },
    }
    this.ldClientInstance = LDClient.initialize(Config.LD_CLIENT_ID, multiContexts)
    if (__DEV__) {
      console.log('Launch Darkly Web - Init Method', this.ldClientInstance)
    }
  }

  async waitForLDInitialization() {
    try {
      await this.ldClientInstance.waitUntilReady()
    } catch (err) {
      Sentry.captureException(err)
      logDdError(err.message, err.stack)
    }
  }

  async identify(userId) {
    try {
      const multiContexts = {
        kind: 'multi',
        user: {
          key: Config.LD_USER_KEY.web,
        },
        member: {
          key: String(userId),
        },
      }
      await this.ldClientInstance.identify(multiContexts)
      throw Error(`Identify user ${userId} not found`)
    } catch (err) {
      Sentry.captureException(err)
      logDdError(err.message, err.stack)
    }
  }

  async boolVariation(key, defaultValue) {
    return await this.ldClientInstance.variation(key, defaultValue)
  }

  async jsonVariation(key, defaultValue) {
    return await this.ldClientInstance.variation(key, defaultValue)
  }

  async variation(key, defaultValue) {
    return await this.ldClientInstance.variation(key, defaultValue)
  }

  async on(event, callback) {
    return this.ldClientInstance.on(event, callback)
  }
}

export const ld = new _LaunchDarkly()
export const waitForLDInitialization = async () => {
  try {
    await ld.waitForLDInitialization()
  } catch (err) {
    Sentry.captureException(err)
    logDdError(err.message, err.stack)
  }
}

export const initLD = async () => {
  ld.init()
  await waitForLDInitialization()
}

export const identifyLD = async (userId) => {
  try {
    await ld.identify(userId)
  } catch (err) {
    Sentry.captureException(err)
    logDdError(err.message, err.stack)
  }
}

export const ldClient = ld
