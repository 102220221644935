import React from 'react'
import { Controller } from 'react-hook-form'

import RadioGroup from 'APP/Converse/Inputs/RadioGroup'

const ControllerRadioGroup = ({
  control,
  name,
  rules = {},
  label = '',
  options,
  labelStyle = {},
  buttonContainerStyle = {},
  testID,
}) => (
  <Controller
    control={control}
    name={name}
    rules={rules}
    render={({ field: { value, onChange } }) => (
      <RadioGroup
        label={label}
        currentValue={value}
        options={options}
        onPress={onChange}
        labelStyle={labelStyle}
        buttonContainerStyle={buttonContainerStyle}
        testID={testID}
      />
    )}
  />
)

export default ControllerRadioGroup
