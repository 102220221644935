import React from 'react'
// eslint-disable-next-line no-restricted-imports
import { Image } from 'react-native'
import { useSelector } from 'react-redux'

import Cobranding from 'APP/Components/Cobranding'
import EpisodeList from 'APP/Components/EpisodeList'
import { PrimaryButton } from 'APP/Components/Buttons'
import Typography from 'APP/Converse/Typography'

import InactiveChatInfoCard from 'APP/Components/InfoCard/InactiveChatInfoCard'

import I18n from 'APP/Services/i18n'
import { TabScreens } from 'APP/Lib/ContentHelpers'

import styled from 'styled-components/native'
import { ApplicationStyles, Images } from 'APP/Themes'

import { ResponsiveView } from 'APP/Converse/Layout'

const CobrandingContainer = styled(Cobranding)`
  background-color: ${({ theme }) => theme.colors.elementsBg};
  border-color: ${({ theme }) => theme.colors.elementsBorder};
  margin-top: ${({ theme }) => theme.metrics.baseMargin}px;
  border-top-width: 1;
`

const EmptyInboxContainer = styled.View`
  ${ApplicationStyles.shadow};
  flex: 1;
  background-color: ${({ theme }) => theme.colors.elementsBg};
  margin-horizontal: ${({ theme }) => theme.metrics.baseMargin}px;
  padding-horizontal: ${({ theme }) => theme.metrics.baseMargin}px;
  border-radius: 20;
  margin-top: ${({ theme }) => theme.metrics.baseMargin}px;
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin * 1.5}px;
`

const Title = styled(Typography)`
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  margin-top: ${({ theme }) => theme.metrics.baseMargin * 1.5}px;
`

const Subtitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin}px;
  margin-top: ${({ theme }) => theme.metrics.baseMargin}px;
`

const Illustration = styled(Image)`
  height: 240;
  align-self: center;
  width: 100%;
  resize-mode: contain;
`

const TabContainer = styled(ResponsiveView)`
  padding-top: ${({ theme }) => theme.metrics.baseMargin}px;
`

const InboxScreen = ({ goToTabByName }) => {
  const { channels, episodes, encounters, isFetchingEpisodes, practitioners, orderedEpisodes } =
    useSelector((state) => state.history)
  const healthIssueTypeIcons = useSelector((state) => state.content.healthIssueTypeIcons)

  const { profile } = useSelector((state) => state.patient)
  const login = useSelector((state) => state.login)

  const eligibleServices = profile?.eligibleServices
  const isEligible = eligibleServices && Object.keys(eligibleServices).length > 0

  const episodeCount = orderedEpisodes?.length

  const showEpisodes = episodeCount > 0
  const showNoEpisodesCta = !episodeCount && !isFetchingEpisodes && isEligible

  const renderEpisodes = () => {
    return (
      <>
        <EpisodeList
          healthIssueTypeIcons={healthIssueTypeIcons}
          orderedEpisodes={orderedEpisodes}
          episodes={episodes}
          channels={channels}
          encounters={encounters}
          practitioners={practitioners}
          profile={profile}
          login={login}
          placeholderCount={5}
        />
        <CobrandingContainer />
      </>
    )
  }

  const renderNoEpisodes = () => {
    return (
      <TabContainer testID={'NO_EPISODES_VIEW'}>
        <EmptyInboxContainer>
          <Illustration source={Images.homeScreenEmptyIllustration} />
          <Title variant="subheaderBold">{I18n.t('ChatScreen.noEpisodes.title')}</Title>
          <Subtitle variant="bodyNormal">{I18n.t('ChatScreen.noEpisodes.body')}</Subtitle>
          <PrimaryButton
            analyticsName="ChatScreen.noEpisodes.getCare"
            onPress={() => goToTabByName(TabScreens.chat.getCare)}
          >
            {I18n.t('ChatScreen.tabs.getCare')}
          </PrimaryButton>
        </EmptyInboxContainer>
      </TabContainer>
    )
  }
  return (
    <>
      {!isEligible && !episodeCount && <InactiveChatInfoCard />}
      {showNoEpisodesCta && renderNoEpisodes()}
      {showEpisodes && renderEpisodes()}
    </>
  )
}

export default InboxScreen
