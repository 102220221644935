import { StyleSheet } from 'react-native'
import { Colors, Metrics, Fonts } from 'APP/Themes'

export default StyleSheet.create({
  container: {
    marginHorizontal: Metrics.baseMargin,
    marginTop: Metrics.baseMargin * 1.5,
    marginBottom: Metrics.baseMargin * 3,
  },
  innerContainer: {
    marginHorizontal: Metrics.baseMargin,
    marginTop: Metrics.baseMargin * 2,
  },
  prepareTitle: {
    ...Fonts.style.h3,
    color: Colors.text,
    marginBottom: Metrics.baseMargin,
  },
  prepareItem: {
    flexDirection: 'row',
    paddingVertical: Metrics.baseMargin,
    borderBottomColor: Colors.elementsBorder,
    borderBottomWidth: 1,
  },
  prepareText: {
    ...Fonts.style.subheader,
    color: Colors.text,
    marginLeft: Metrics.baseMargin / 1.5,
  },
  termsContainer: {
    marginTop: Metrics.baseMargin,
    paddingBottom: Metrics.baseMargin,
    borderBottomColor: Colors.elementsBorder,
    borderBottomWidth: 1,
  },
  prepareTerms: {
    ...Fonts.style.bodySmall,
    color: Colors.text,
  },
  openChat: {
    ...Fonts.style.subheader,
    color: Colors.text,
    marginTop: Metrics.baseMargin,
  },
  openChatBold: {
    ...Fonts.style.subheaderBold,
    color: Colors.text,
    textDecorationLine: 'underline',
  },
})
