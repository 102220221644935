import moment from 'moment'
import { AvailabilitySlot } from '@dialogue/timekeeper'

import { PARTS_OF_DAY, PART_OF_DAY_END_HOUR } from '../constants'

export const createDays = (dateFrom: moment.Moment, dateUntil: moment.Moment) => {
  const days: moment.Moment[] = []
  let currentDate = dateFrom.clone()
  while (currentDate.isSameOrBefore(dateUntil, 'day')) {
    days.push(currentDate.clone().startOf('day'))

    currentDate = currentDate.add(1, 'days')
  }

  return days
}

export const splitSlotsByDayPart = (slots: AvailabilitySlot[]) => {
  const splittedSlots: Record<PARTS_OF_DAY, AvailabilitySlot[]> = {
    [PARTS_OF_DAY.MORNING]: [],
    [PARTS_OF_DAY.AFTERNOON]: [],
    [PARTS_OF_DAY.EVENING]: [],
  }

  slots.forEach((slot) => {
    const slotHour = moment(slot.start_at).hour()
    const partOfDay =
      slotHour < PART_OF_DAY_END_HOUR.MORNING
        ? PARTS_OF_DAY.MORNING
        : slotHour < PART_OF_DAY_END_HOUR.AFTERNOON
        ? PARTS_OF_DAY.AFTERNOON
        : PARTS_OF_DAY.EVENING
    splittedSlots[partOfDay].push(slot)
  })

  return splittedSlots
}

export const splitSlotsByDay = (slots: AvailabilitySlot[]) =>
  slots.reduce((acc, slot) => {
    const slotDay = moment(slot.start_at).format('YYYY-MM-DD')
    if (!acc[slotDay]) {
      acc[slotDay] = []
    }
    acc[slotDay].push(slot)
    return acc
  }, {} as Record<string, AvailabilitySlot[]>)
