import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { RootState } from 'APP/Store/CreateStore'
import { selectPreferredLanguage } from 'APP/Redux/PatientRedux'
import { contentActions } from 'APP/Store/Content'
import { selectOneServiceGroupState } from 'APP/Store/Content/selectors'

const useServiceData = (serviceGroupId?: string, serviceId?: string) => {
  const dispatch = useDispatch()

  const locale = useSelector(selectPreferredLanguage)
  const serviceGroupState = useSelector((state: RootState) =>
    selectOneServiceGroupState(state, serviceGroupId as string)
  )

  const serviceGroup = serviceGroupState?.data || null
  const service = serviceId ? serviceGroup?.services.find((s) => s.id === serviceId) : null

  useEffect(() => {
    if (!serviceGroupId) return
    dispatch(contentActions.getOneServiceGroup({ id: serviceGroupId, locale }))
  }, [serviceGroupId, locale, dispatch])

  return {
    serviceGroup,
    service,
    loading: serviceGroupState?.loading || false,
    error: serviceGroupState?.error || null,
  }
}

export default useServiceData
