import React, { useEffect } from 'react'
import { Image, FlatList, Text, View, Linking } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import Icon from 'react-native-vector-icons/MaterialIcons'
import { connect } from 'react-redux'
import Alert from 'APP/Converse/Alert'
import PatientActions from 'APP/Redux/PatientRedux'

// Services
import I18n from 'APP/Services/i18n'
import UrlBuilder from 'APP/Services/UrlBuilder'
import Analytics from 'APP/Services/Analytics'
import * as Sentry from '@sentry/react-native'

// Components
import { PrimaryButton } from 'APP/Components/Buttons'
import { ResponsiveScrollView, ResponsiveView } from 'APP/Converse/Layout'

// Config
import Config from 'APP/Config'

// Styles
import { Images, Colors } from 'APP/Themes'
import Styles from './style'
import { getImageAspectRatio } from 'APP/Lib/StylingHelpers'
import { useDevTools } from 'APP/Lib/DevTools'
import { logDdError } from 'APP/Lib/Datadog'

function ClaimAccessSuccessScreen({
  displayNames,
  isInChallengedQueue,
  nextChallenge,
  isOnboarding,
  onboardingDeferredEnrolmentComplete,
}) {
  const navigation = useNavigation()
  const { setToolName, setToolFunction } = useDevTools()

  useEffect(() => {
    setToolName('Next')
    setToolFunction(() => () => {
      handleCtaPress()
    })
    return () => {
      setToolFunction(null)
      setToolName(null)
    }
  }, [])

  const onLinkPressed = () => {
    Analytics.trackEvent(
      'button_click',
      {
        button_text: 'Contact us',
        button_value: `ClaimAccessSuccessScreen.contactUs`,
      },
      ['banner_context']
    )

    if (Config.CUSTOM_SUPPORT_PAGE) {
      navigation.navigate('contactUs')
    } else {
      const url = UrlBuilder.supportEmailUrl()
      if (Linking.canOpenURL(url)) {
        Linking.openURL(url).catch((reason) => {
          const msg = `ClaimAccessSuccessScreen. Error opening contact us link. message: ${reason.message}, url: ${url}`
          Sentry.captureException(msg)
          logDdError(msg, 'ClaimAccessSuccessScreen.onLinkPressed')
          Alert.alert(I18n.t('CannotOpenEmail.title'), I18n.t('CannotOpenEmail.body'), [
            { text: I18n.t('CannotOpenEmail.ok') },
          ])
        })
      }
    }
  }

  const handleCtaPress = () => {
    if (isInChallengedQueue) {
      nextChallenge()
    } else if (isOnboarding && !isInChallengedQueue) {
      onboardingDeferredEnrolmentComplete()
    } else {
      navigation.reset({ index: 0, routes: [{ name: 'tabbar' }] })
    }
  }

  const renderOrganization = (displayName) => {
    return (
      <View style={Styles.orgNameCard}>
        <Text style={Styles.orgName}>{I18n.locale === 'en' ? displayName.en : displayName.fr}</Text>
      </View>
    )
  }

  const renderTexts = () => {
    return (
      <>
        <Text style={Styles.title}>
          {I18n.t('ClaimAccessSuccessScreen.title')}{' '}
          <Icon name="lock-open" size={24} color={Colors.accent} />
        </Text>
        <Text style={Styles.subtitle}>{I18n.t('ClaimAccessSuccessScreen.subtitle')}</Text>
        <FlatList
          style={Styles.orgList}
          data={displayNames}
          keyExtractor={(item, index) => `${index}-${item.en}`}
          renderItem={({ item }) => renderOrganization(item)}
        />
      </>
    )
  }

  const renderCTAs = () => {
    const contactUs = I18n.t('ClaimAccessSuccessScreen.contactUs', { returnObjects: true })
    return (
      <>
        <PrimaryButton
          title={I18n.t(
            isInChallengedQueue || isOnboarding
              ? 'ClaimAccessSuccessScreen.nextBtn'
              : 'ClaimAccessSuccessScreen.finishBtn'
          )}
          analyticsName={'ClaimAccessSuccessScreen.next'}
          onPress={() => handleCtaPress()}
        />
        <Text style={Styles.contactUs}>
          {contactUs[0]}{' '}
          <Text style={Styles.link} onPress={() => onLinkPressed()} testID={'contact-us'}>
            {contactUs[1]}
          </Text>
        </Text>
      </>
    )
  }

  return (
    <View style={Styles.fullPrimaryContainer}>
      <ResponsiveScrollView
        alwaysBounceVertical={false}
        keyboardShouldPersistTaps="always"
        style={Styles.keyboardCoveredContent}
      >
        <ResponsiveView style={Styles.content}>
          <Image
            source={Images.logoBlue}
            style={[Styles.splashLogo, { aspectRatio: getImageAspectRatio(Images.logoBlue) }]}
          />
          {renderTexts()}
          {renderCTAs()}
        </ResponsiveView>
      </ResponsiveScrollView>
    </View>
  )
}

const mapStateToProps = (state, props) => {
  const { enrolWithEnrolmentCodesResult, challengedInvitationQueue, isOnboarding } = state.patient
  const displayNames = enrolWithEnrolmentCodesResult?.map?.(
    (organization) => organization.displayName
  )
  return {
    ...props.route?.params,
    displayNames,
    isInChallengedQueue: (challengedInvitationQueue ?? []).length > 0,
    isOnboarding,
  }
}

const mapDispatchToProps = (dispatch) => ({
  nextChallenge: () => dispatch(PatientActions.challengedInvitationQueueNext()),
  onboardingDeferredEnrolmentComplete: () =>
    dispatch(PatientActions.onboardingDeferredEnrolmentComplete()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ClaimAccessSuccessScreen)
