import { Colors, Fonts, Metrics } from 'APP/Themes'
import { StyleSheet } from 'react-native'

export const ErrorToastStyles = StyleSheet.create({
  container: {
    width: Metrics.screenWidth - 2 * Metrics.baseMargin,
    flexDirection: 'row',
    padding: Metrics.baseMargin,
    paddingRight: 0,
    backgroundColor: Colors.errorSnackBarBg,
    borderRadius: 5,
    alignItems: 'center',
  },
  copyContainer: {
    flex: 1,
  },
  title: {
    ...Fonts.style.subheaderBold,
    color: Colors.text,
  },
  description: {
    ...Fonts.style.caption,
    color: Colors.text,
  },
  cta: {
    marginBottom: 0,
    marginTop: 0,
    marginHorizontal: 0,
  },
})
