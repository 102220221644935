import React, { useContext, memo } from 'react'
import I18n from 'APP/Services/i18n'

import moment from 'moment'
import { PostContext } from 'APP/Lib/Context'
import ActionCard from 'APP/Components/FelixChat/ActionCard'

const VideoCall = () => {
  const { post } = useContext(PostContext)
  const subtitle = I18n.t('ConversationScreen.callStarted', {
    time: moment(post.create_at).format('LT'),
  })

  return <ActionCard title={post.message} subtitle={subtitle} iconName="phone" />
}

export default memo(VideoCall)
