import { StyleSheet } from 'react-native'
import { Colors, Fonts, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  content: {
    paddingHorizontal: Metrics.baseMargin * 2,
    paddingBottom: Metrics.bottomSpace || Metrics.baseMargin,
  },
  copy: {
    ...Fonts.style.body,
    color: Colors.text,
    paddingBottom: Metrics.baseMargin * 2.5,
  },
  button: {
    marginTop: 0,
    marginHorizontal: 0,
  },
})
