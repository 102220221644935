import { StyleSheet } from 'react-native'
import { Fonts, Colors, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  background: {
    position: 'absolute',
    backgroundColor: 'transparent',
    overflow: 'visible',
  },
  container: {
    flex: 1,
  },
  pointer: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 13,
    borderRightWidth: 13,
    borderBottomWidth: 13,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: Colors.tabSelected,
  },
  down: {
    transform: [{ rotate: '180deg' }],
  },
  content: {
    backgroundColor: Colors.tabSelected,
    paddingHorizontal: Metrics.baseMargin * 1.2,
    paddingVertical: Metrics.baseMargin * 1.2,
    borderRadius: Metrics.baseMargin,
    position: 'absolute',
  },
  text: {
    ...Fonts.style.subheader,
    color: Colors.textInverted,
  },
})
