import React, {
  useLayoutEffect,
  useState,
  Fragment,
  createContext,
  useContext,
  useRef,
} from 'react'
import styled from 'styled-components/native'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'

import Typography from 'APP/Converse/Typography'
import Arrow from 'APP/Components/Arrow'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'

// Styles
import { Colors, Metrics } from 'APP/Themes'

const Icon = styled(MaterialIcon)`
  margin-right: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  color: ${({ theme }) => theme.colors.text};
`

const Container = styled.View`
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
  flex: 1;
  flex-direction: row;
  flex-wrap: nowwrap;
`

const SubContainer = styled.View`
  position: relative;
  flex: 1;
  overflow-y: auto;
  max-width: 50%;
  min-width: 200px;
`
const SubContainerOverflowGradient = styled.View`
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 50px;
  pointer-events: none;
  background-color: ${({ theme }) => theme.colors.background};
  mask-image: linear-gradient(transparent, rgba(0, 0, 0, 1));
`

const List = styled.View`
  display: flex;
  padding: 0;
  flex-wrap: nowrap;
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: ${({ theme }) => theme.colors.elementsBorder};
`
const ListItemContainer = styled(DialogueTouchableOpacity)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-color: ${({ theme }) => theme.colors.elementsBorder};
  border-style: solid;
  border-bottom-width: 1px;
  padding: ${({ theme }) => `${theme.metrics.baseMargin}px ${theme.metrics.baseMargin * 1.5}px`};
`

const RowView = styled.View`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`

const ListItem = React.forwardRef(
  ({ title, children, active, rightIcon = true, titleVariant = 'subheaderBold', ...rest }, ref) => {
    return (
      <ListItemContainer active={active} role="listitem" {...rest} innerRef={ref}>
        <RowView>
          {children}
          <Typography variant={titleVariant} color="textPrimary">
            {title}
          </Typography>
        </RowView>
        {rightIcon && <Arrow color={active ? Colors.text : Colors.accent} />}
      </ListItemContainer>
    )
  }
)
ListItem.displayName = 'ListItem'

const ParentGroupListItem = styled(ListItem)`
  border-color: ${({ theme }) => theme.colors.elementsBorder};
  borderstyle: solid;
  borderbottomwidth: 1px;
  borderrightwidth: 1px;
  background: ${(props) => (props.active ? Colors.selected : Colors.elementsBg)};
`

const GroupedChoicePickerContext = createContext()

const ParentGroupsList = React.forwardRef(
  ({ groups, hasGroups, activeGroup, setActiveGroup, parentId, level }, ref) => {
    const { analyticsContainerName } = useContext(GroupedChoicePickerContext)
    const groupId = (parentId && `${parentId}-subtree`) || undefined
    return (
      <SubContainer>
        <List role="group" id={groupId}>
          {hasGroups &&
            groups.map(({ display, children, choices }, i) => {
              const isActive = i === activeGroup
              const size = (children || choices).length
              return (
                <ParentGroupListItem
                  role="treeitem"
                  aria-level={level}
                  aria-expanded={isActive}
                  aria-label={display}
                  aria-owns={`${display}-subtree`}
                  aria-setsize={size}
                  active={isActive}
                  key={i}
                  ref={i === 0 ? ref : undefined}
                  title={display}
                  onPress={() => setActiveGroup(i)}
                  analyticsName={`${analyticsContainerName}.group.expanded: ${display}`}
                  testID={`level-${level}-group-${i}`}
                />
              )
            })}
        </List>
        <SubContainerOverflowGradient />
      </SubContainer>
    )
  }
)
ParentGroupsList.displayName = 'ParentGroupListItem'

const ActiveGroupChoices = React.forwardRef(({ choices, parentId, level }, ref) => {
  const { multi, selectedChoices, onChoicePress, analyticsContainerName } = useContext(
    GroupedChoicePickerContext
  )
  const groupId = (parentId && `${parentId}-subtree`) || undefined

  return (
    <SubContainer>
      <List role="group" id={groupId}>
        {choices.map((choice, i) => {
          const { display, id } = choice
          const selected = multi && selectedChoices.indexOf(id) > -1
          return (
            <ListItem
              key={i}
              title={display}
              titleVariant="bodyNormal"
              rightIcon={false}
              role="treeitem"
              ref={i === 0 ? ref : undefined}
              aria-selected={multi ? selected : undefined}
              aria-level={level}
              aria-label={display}
              analyticsName={`${analyticsContainerName}.choice.${
                selected ? 'un' : ''
              }selected: ${display}`}
              testID={`level-${level}-choice-${i}`}
              onPress={() => onChoicePress(choice)}
            >
              {multi && (
                <Icon
                  name={selected ? 'check-box' : 'check-box-outline-blank'}
                  size={Metrics.icons.small}
                />
              )}
            </ListItem>
          )
        })}
      </List>
      <SubContainerOverflowGradient />
    </SubContainer>
  )
})
ActiveGroupChoices.displayName = 'ActiveGroupChoices'

const Group = React.forwardRef(({ groups, parentId, level = 1 }, ref) => {
  const hasGroups = groups && !!groups.length
  const [activeGroupIndex, setActiveGroupIndex] = useState()
  const activeGroup = groups[activeGroupIndex]
  const childRef = useRef()

  useLayoutEffect(
    () => {
      if (hasGroups) setActiveGroupIndex(0)
    },
    // eslint-disable-next-line
    [groups]
  )

  const setActiveGroup = (i) => {
    setActiveGroupIndex(i)
    childRef.current?.focus?.()
  }

  return (
    <Fragment>
      <ParentGroupsList
        ref={ref}
        level={level}
        parentId={parentId}
        groups={groups}
        hasGroups={hasGroups}
        activeGroup={activeGroupIndex}
        setActiveGroup={setActiveGroup}
      />
      {activeGroup ? (
        activeGroup.children ? (
          <Group
            groups={activeGroup.children}
            parentId={activeGroup.display}
            level={level + 1}
            ref={childRef}
          />
        ) : (
          activeGroup.choices && (
            <ActiveGroupChoices
              {...activeGroup}
              parentId={activeGroup.display}
              level={level + 1}
              ref={childRef}
            />
          )
        )
      ) : null}
    </Fragment>
  )
})
Group.displayName = 'Group'

const GroupedChoicePicker = ({
  groups,
  selectedChoices = [],
  multi = false,
  onChoicePress,
  analyticsName,
  ...other
}) => {
  return (
    <GroupedChoicePickerContext.Provider
      value={{ selectedChoices, multi, onChoicePress, analyticsContainerName: analyticsName }}
    >
      <Container
        role="tree"
        aria-orientation="horizontal"
        aria-multiselectable={!!multi}
        {...other}
      >
        <Group groups={groups} />
      </Container>
    </GroupedChoicePickerContext.Provider>
  )
}

export default GroupedChoicePicker
