import React, { useCallback, useEffect, useState } from 'react'

import GroupedChoicePicker from 'APP/Components/GroupedChoicePicker'
import { useChoiceSearch } from 'APP/Hooks/ChoiceSearch'

// Styles
import SearchCard from 'APP/Converse/SearchCard'

const IntakeSearchChoice = ({
  style,
  choices,
  group_data,
  sendAnswer,
  disabled,
  placeholder_text,
  title,
  search_source,
}) => {
  const [groups, submitQuery, state] = useChoiceSearch(group_data, choices, search_source)

  const [expandGroupIndeciesOnUpdate, setExpandGroupIndeciesOnUpdate] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const onTextChange = useCallback(
    (txt) => {
      setSearchTerm(txt)
    },
    [setSearchTerm]
  )
  const onClearSearchButtonPress = useCallback(() => {
    setSearchTerm('')
  }, [setSearchTerm])

  useEffect(() => {
    if (searchTerm.trim()) {
      setExpandGroupIndeciesOnUpdate([0])
    } else {
      setExpandGroupIndeciesOnUpdate([])
    }
  }, [groups])

  useEffect(() => {
    submitQuery(searchTerm.trim())
  }, [searchTerm, submitQuery])

  const onChoicePress = useCallback(
    (choice) => {
      if (!disabled) {
        sendAnswer(choice)
      }
    },
    [disabled, sendAnswer]
  )

  const Body = (
    <GroupedChoicePicker
      groups={groups}
      disabled={disabled}
      expandGroupIndeciesOnUpdate={expandGroupIndeciesOnUpdate}
      onChoicePress={onChoicePress}
    />
  )

  return (
    <SearchCard
      title={title}
      style={style}
      placeholder={placeholder_text}
      searchTerm={searchTerm}
      onTextChange={onTextChange}
      onClearSearchButtonPress={onClearSearchButtonPress}
      disabled={disabled}
      running={state.running}
      content={Body}
    />
  )
}

export default IntakeSearchChoice
