import { StyleSheet } from 'react-native'

import { Colors, Fonts, Metrics, ApplicationStyles } from 'APP/Themes'

export const ModalWidth = Metrics.screenWidth * 0.8

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  background: {
    width: '100%',
    height: '100%',
    backgroundColor: Colors.appBgInverted,
    position: 'absolute',
    opacity: 0.6,
  },
  contentContainer: {
    position: 'absolute',
  },
  content: {
    width: ModalWidth,
    backgroundColor: Colors.elementsBg,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 16,
    paddingVertical: 24,
    borderRadius: Metrics.baseMargin,
    ...ApplicationStyles.shadow,
  },
  actionsContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  title: {
    ...Fonts.style.h3,
    color: Colors.text,
  },
  subtitle: {
    ...Fonts.style.body,
    textAlign: 'center',
    color: Colors.text,
  },
  textSpacing: {
    marginBottom: Metrics.baseMargin,
  },
  actionButton: {
    marginTop: 20,
    padding: Metrics.baseMargin / 2,
  },
  primaryActionText: {
    ...Fonts.style.button,
    color: Colors.text,
  },
  secondaryActionText: {
    ...Fonts.style.button,
    color: Colors.buttonPrimarySelected,
  },
})
