import { StyleSheet } from 'react-native'
import { ApplicationStyles, Metrics, Colors, Fonts } from '../../Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  headerContainer: {
    paddingHorizontal: Metrics.baseMargin * 1.5,
    paddingTop: Metrics.baseMargin,
  },
  headerRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: Metrics.baseMargin * 2,
  },
  headerRowActionContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  wellBeingIconContainer: {
    alignSelf: 'center',
    backgroundColor: Colors.buttonProfile,
    borderRadius: 30,
    height: 40,
    marginHorizontal: Metrics.baseMargin,
  },
  wellBeingIconRipple: {
    flex: 1,
    borderRadius: 30,
    display: 'flex',
    flexDirection: 'row',
  },
  wellBeingIcon: {
    alignSelf: 'center',
    marginVertical: 10,
    marginLeft: 15,
    marginRight: 5,
  },
  wellBeingScore: {
    ...Fonts.style.bodySmallBold,
    color: Colors.todayHeaderActionIcon,
    alignSelf: 'center',
    marginRight: 15,
    textTransform: 'uppercase',
  },
  profileIconContainer: {
    alignSelf: 'center',
    backgroundColor: Colors.buttonProfile,
    borderRadius: 30,
    width: 40,
    height: 40,
  },
  profileIconRipple: {
    flex: 1,
    borderRadius: 30,
    alignItems: 'center',
    padding: Metrics.baseMargin / 2,
  },
  headerLogo: {
    width: 50,
    resizeMode: 'contain',
  },
  wideLogo: {
    width: 100,
  },
  welcomeText: {
    ...Fonts.style.h1,
    color: Colors.text,
    marginTop: Metrics.baseMargin,
    marginBottom: 0,
    paddingTop: Metrics.baseMargin / 2,
  },
  welcomeTextSmall: {
    ...Fonts.style.bodyLarge,
    color: Colors.textSecondary,
  },
})
