import { Linking } from 'react-native'
import Config from 'APP/Config'
import Analytics from 'APP/Services/Analytics'
import I18n from 'APP/Services/i18n'
import { getBrandConfig } from 'APP/Lib/ConfigHelpers'
import Alert from 'APP/Converse/Alert'

export const getDisplayableBrands = (eligibleBrands) => {
  return (
    eligibleBrands &&
    eligibleBrands.filter((brand) => {
      // For the main 'dia' brand show _any_ other eligible brands,
      // but for partner apps only show ever show 'dia' eligibility
      if (Config.BRAND_ID === 'dia') {
        const self = brand === Config.BRAND_ID
        return !self
      } else {
        return brand === 'dia'
      }
    })
  )
}

export const openExternalBrandApp = (appName, brandId) => {
  Analytics.trackEvent('button_click', {
    button_text: appName,
    button_value: 'External app launchpad',
  })

  const { url } = getBrandConfig(brandId)
  if (Linking.canOpenURL(url)) {
    Linking.openURL(url).catch(() => {
      Alert.alert(I18n.t('CannotOpenUrl.title'), I18n.t('CannotOpenUrl.body'), [
        { text: I18n.t('CannotOpenUrl.ok') },
      ])
    })
  }
}
