import React, { useEffect } from 'react'
import Styles from './style'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import { Colors, Metrics } from 'APP/Themes'
import Animated, {
  cancelAnimation,
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated'

const SyncingLeftElement = () => {
  const rotation = useSharedValue(0)

  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          rotateZ: `-${rotation.value}deg`,
        },
      ],
    }
  }, [rotation.value])

  useEffect(() => {
    rotation.value = withRepeat(
      withTiming(360, {
        duration: 1200,
        easing: Easing.linear,
      }),
      -1
    )
    return () => cancelAnimation(rotation)
  }, [])

  return (
    <Animated.View style={[animatedStyles, Styles.syncMargin]}>
      <MaterialIcon name="sync" size={Metrics.icons.small} color={Colors.text} />
    </Animated.View>
  )
}

export default SyncingLeftElement
