import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import React from 'react'
import { View, Image } from 'react-native'
import styled from 'styled-components/native'
import { Colors, Images, Metrics } from 'APP/Themes'
import Typography from 'APP/Converse/Typography'
import MattermostAvatar from 'APP/Components/MattermostAvatar'
import Config from 'APP/Config'
import Shimmer from 'APP/Components/Shimmer'
import { getServiceCategoryIcon } from 'APP/Lib/CtaInfoHelpers'

interface Props {
  title?: string
  subtitle?: string
  date?: string
  healthIssueTypeIcon?: string
  avatarUrl?: React.ReactNode
  ctaButton?: React.ReactNode
  onPress?: () => void
  testID?: string
}
const iconSize = 32

const StyledRowView = styled.View`
  flex-direction: row;
  padding-top: ${({ theme }) => theme.metrics.baseMargin}px;
  padding-right: ${({ theme }) => theme.metrics.baseMargin}px;
  padding-left: ${({ theme }) => theme.metrics.baseMargin}px;
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  align-items: center;
`

const StyledRowMetaView = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.metrics.baseMargin}px;
  padding-right: ${({ theme }) => theme.metrics.baseMargin}px;
  padding-left: ${({ theme }) => theme.metrics.baseMargin}px;
  align-items: center;
`

const StyledHeader = styled.View`
  flex-direction: row;
`

const StyledRowMessage = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.metrics.baseMargin / 4}px;
`

const StyledEpisodeContainer = styled.View`
  flex: 1;
  margin-left: ${({ theme }) => theme.metrics.baseMargin}px;
`

const StyledMMAvatar = styled(MattermostAvatar)`
  height: ${iconSize}px;
  width: ${iconSize}px;
  border-radius: ${({ theme }) => theme.metrics.borderRadius.medium}px;
  margin-top: 3px;
`

const StyledAvatar = styled(Image)`
  height: ${iconSize}px;
  width: ${iconSize}px;
  border-radius: ${({ theme }) => theme.metrics.borderRadius.medium}px;
  margin-top: 3px;
`

const StyledShimmerAvatar = styled(Shimmer)`
  border-radius: 20px;
`

const StyledHealthIssueTypeIcon = styled(Image)`
  height: ${iconSize}px;
  width: ${iconSize}px;
  overflow: visible;
`

export default function EpisodeListItem({
  healthIssueTypeIcon,
  title,
  subtitle,
  date,
  avatarUrl,
  ctaButton,
  onPress,
  testID,
  ...rest
}: Props) {
  const tintClinicIcon = Boolean(Config.FEATURES?.PRIMARY_COLOR_TINT) && Colors.accent
  const renderAvatar = () => {
    if (!title) {
      return <StyledShimmerAvatar height={Metrics.avatar.height} width={Metrics.avatar.width} />
    }
    if (avatarUrl) {
      return <StyledMMAvatar picture={avatarUrl} />
    } else if (healthIssueTypeIcon) {
      return (
        <StyledHealthIssueTypeIcon
          resizeMode="contain"
          source={getServiceCategoryIcon(healthIssueTypeIcon)}
        />
      )
    } else {
      return <StyledAvatar tintColor={tintClinicIcon || undefined} source={Images.logoNurse} />
    }
  }

  return (
    <View testID={testID} {...rest}>
      <DialogueTouchableOpacity disabled={!title} onPress={onPress}>
        <View>
          <StyledRowView>
            {renderAvatar()}
            <StyledEpisodeContainer>
              <StyledHeader>
                {title ? (
                  <Typography variant="subheaderBold" numberOfLines={1}>
                    {title}
                  </Typography>
                ) : (
                  <Shimmer width={120} height={24} />
                )}
              </StyledHeader>
              <StyledRowMessage>
                {subtitle ? (
                  <Typography variant="bodyNormal" numberOfLines={1}>
                    {subtitle}
                  </Typography>
                ) : (
                  <Shimmer width={160} />
                )}
              </StyledRowMessage>
            </StyledEpisodeContainer>
          </StyledRowView>
          <StyledRowMetaView>
            <Typography variant="bodySmall">{date}</Typography>
            {ctaButton}
          </StyledRowMetaView>
        </View>
      </DialogueTouchableOpacity>
    </View>
  )
}
