import React, { memo } from 'react'
import { mergeDeepRight } from 'ramda'
import { View, useWindowDimensions, Image, Text } from 'react-native'
import RenderHtml, { useInternalRenderer } from 'react-native-render-html'
import { iframeModel, useHtmlIframeProps, HTMLIframe } from '@native-html/iframe-plugin'
import WebView from 'react-native-webview'

import Config from 'APP/Config'

import Constants from 'expo-constants'
import Styles from './style'
import { isWeb } from 'APP/Helpers/checkPlatform'
import { parse } from 'marked'
import { Colors } from 'APP/Themes'

const injectedJavaScript = `
  var node = document.createElement('style');
  var rules = ""
  rules += "html, body { background: ${Colors.appBg}; }";
  rules += "body {  color: ${Colors.text}; }";
  rules += "a { color: ${Colors.accent}; }"
  node.innerHTML = rules;
  document.querySelector('HEAD').appendChild(node);
`

function CustomImageRenderer(style) {
  return function ImageWrapper(props) {
    const { rendererProps } = useInternalRenderer('img', props)

    return (
      <View style={style.imageWrapper}>
        <Image style={style.image} source={rendererProps.source} />
      </View>
    )
  }
}

function CustomHeader(style) {
  return function Header(props) {
    return <Text style={style}>{props.tnode?.domNode?.children?.[0]?.data}</Text>
  }
}

function CustomEmphasis(props) {
  return <Text style={Styles.emphasis}>{props.tnode?.domNode?.children?.[0]?.data}</Text>
}

const customHTMLElementModels = {
  iframe: iframeModel,
}

const IframeRenderer = function IframeRenderer(props) {
  const iframeProps = useHtmlIframeProps(props)
  return isWeb() ? (
    <iframe
      {...iframeProps.htmlAttribs}
      height={iframeProps.style[1].height}
      width="100%"
      style={{
        borderWidth: 0,
        marginBottom: 8,
      }}
    />
  ) : (
    <HTMLIframe
      {...iframeProps}
      style={[iframeProps.style, { width: '100%', height: 400, marginBottom: 8 }]}
    />
  )
}

const DialogueMarkdown = ({ markdown, styles }) => {
  const { width } = useWindowDimensions()

  if (!markdown) {
    return null
  }
  const mergedStyles = styles ? mergeDeepRight(Styles, styles) : Styles
  const type = Config.FONTS

  const systemFonts = [
    ...Constants.systemFonts,
    type.base,
    type.bold,
    type.button,
    type.header,
    type.light,
  ]

  const html = parse(markdown, { breaks: true })

  const renderers = {
    img: CustomImageRenderer(mergedStyles),
    h1: CustomHeader(Styles.h1),
    h2: CustomHeader(Styles.h2),
    h3: CustomHeader(Styles.h3),
    h4: CustomHeader(Styles.h4),
    h5: CustomHeader(Styles.h5),
    h6: CustomHeader(Styles.h6),
    em: CustomEmphasis,
    iframe: IframeRenderer,
  }

  return (
    <View>
      <RenderHtml
        contentWidth={width}
        source={{ html }}
        WebView={WebView}
        tagsStyles={mergedStyles}
        systemFonts={systemFonts}
        renderers={renderers}
        customHTMLElementModels={customHTMLElementModels}
        renderersProps={{
          iframe: {
            scalesPageToFit: false,
            webViewProps: {
              scalesPageToFit: false,
              injectedJavaScript,
              javaScriptEnabledAndroid: true,
              style: {
                flex: 1,
                backgroundColor: Colors.appBg,
              },
            },
          },
        }}
      />
    </View>
  )
}

export default memo(DialogueMarkdown)
