import moment from 'moment'
import React from 'react'
import { Text, View } from 'react-native'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import Day from 'APP/Images/ic_day_select.svg'
import DaySelected from 'APP/Images/ic_day_selected.svg'
import { Colors } from 'APP/Themes'

import Styles from './style'

const weekDays = [0, 1, 2, 3, 4, 5, 6]
export const WeekDayPicker = ({ value, onChange, disabled, style }) => {
  return (
    <View style={[Styles.container, style]}>
      {weekDays.map((i) => {
        const isSelected = value?.[i]
        return (
          <View key={i} style={Styles.dayContainer}>
            <Text style={Styles.dayLabel}>
              {moment
                .weekdays(i + (1 % 7))
                .charAt(0)
                .toLocaleUpperCase()}
            </Text>
            <DialogueTouchableOpacity
              disabled={disabled}
              onPress={() => onChange(value?.map((v, index) => (index === i ? !v : v)))}
              testID="daySelecor"
            >
              {isSelected ? <DaySelected fillLine={Colors.darkText} /> : <Day />}
            </DialogueTouchableOpacity>
          </View>
        )
      })}
    </View>
  )
}
