import { View } from 'react-native'
import { Colors, Metrics } from 'APP/Themes'

import styled from 'styled-components/native'

export const StyledViewCarouselDot = styled(View)`
  background-color: ${({ selected }) =>
    selected
      ? `${Colors.welcomeScreenSlideCurrentDot}`
      : `${Colors.welcomeScreenSlideInvertedDot}`};
  width: ${({ selected }) =>
    selected ? `${Metrics.carouselDot.size * 2}px` : `${Metrics.carouselDot.size}px`};
  height: ${Metrics.carouselDot.size}px;
  max-height: ${Metrics.carouselDot.size}px;
  border-radius: ${Metrics.carouselDot.borderRadius}px;
  margin: ${Metrics.carouselDot.margin}px;
`
