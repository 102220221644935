import React, { useState, createRef } from 'react'
import { Image, View, useWindowDimensions, LayoutChangeEvent } from 'react-native'

import { CarouselDot, CarouselComponent } from 'APP/Components/Carousel'
import Button from 'APP/Converse/Button'
import Typography from 'APP/Converse/Typography'
import Analytics from 'APP/Services/Analytics'

import Styles from './style'

export interface SlideImage {
  source: string
  resizeMode: 'contain' | 'cover'
}

export interface PrimaryButtonProps {
  title: string
  onPress: () => void
}

export interface TertiaryButtonProps {
  title: string
  onPress: () => void
}

export interface SlideData {
  title: string
  description: string
  image: SlideImage
  primaryButtonProps?: PrimaryButtonProps
  tertiaryButtonProps?: TertiaryButtonProps
}

interface CarouselProps {
  slideData: SlideData[]
  header?: React.ReactNode
}

// 0.1 is saved for the header section
const CAROUSEL_HEIGHT_RATIO = 0.9

const Carousel: React.FC<CarouselProps> = ({ slideData, header }) => {
  const carouselRef = createRef()
  const [slideIndex, setSlideIndex] = useState(0)

  const { height, width } = useWindowDimensions()
  const [heroWidth, setHeroWidth] = useState(width)
  const [heroHeight, setHeroHeight] = useState(header ? height * CAROUSEL_HEIGHT_RATIO : height)

  const renderHeader = () => {
    return <View style={Styles.headerContainer}>{header}</View>
  }

  const onLayoutChange = (event: LayoutChangeEvent) => {
    const { width: newWidth, height: newHeight } = event.nativeEvent.layout
    if (newWidth !== heroWidth || newHeight !== heroHeight) {
      setHeroWidth(newWidth)
      setHeroHeight(header ? height * CAROUSEL_HEIGHT_RATIO : height)
    }
  }

  const renderSlide = (item: SlideData) => {
    return (
      <>
        <View style={Styles.imageContainer}>
          <Image
            style={Styles.heroImage}
            source={item?.image?.source}
            resizeMode={item?.image?.resizeMode}
          />
        </View>

        <View style={Styles.heroTexts}>
          <View style={Styles.textContainer}>
            <Typography variant="h3" align="center">
              {item?.title}
            </Typography>
            <Typography variant="bodyNormal" align="center">
              {item?.description}
            </Typography>
          </View>
          <View style={Styles.buttonContainer}>
            {item?.primaryButtonProps && (
              <Button
                variant="primary"
                title={item?.primaryButtonProps?.title}
                widthVariant="full"
                onPress={item?.primaryButtonProps.onPress}
              />
            )}
            {item?.tertiaryButtonProps && (
              <Button
                variant="tertiary"
                title={item?.tertiaryButtonProps.title}
                widthVariant="full"
                onPress={item?.tertiaryButtonProps.onPress}
              />
            )}
          </View>
        </View>
      </>
    )
  }

  // Note that does not work in Storybook only, as it does not load
  // Analytics which are deeply embedded in button clicks.
  const updateSlideIndex = (index: number) => {
    if (index !== slideIndex) {
      carouselRef?.current?.scrollTo({ index })
      setSlideIndex(index)
    }
  }

  const renderDots = () => {
    if (slideData?.length < 2) {
      return null
    }
    return (
      <View style={Styles.dotsContainer} testID="carousel-dots">
        <Button
          variant="tertiary"
          iconVariant="materialIcons"
          icon="keyboard-arrow-left"
          small={false}
          widthVariant="icon"
          iconPosition="right"
          onPress={() => updateSlideIndex(slideIndex - 1)}
          testID="arrow-left"
          analyticsName="Carousel arrow left"
          disabled={slideIndex === 0}
        />
        {slideData?.map((_: object, index: number) => (
          <CarouselDot
            key={index}
            index={index}
            selected={slideIndex === index}
            onPress={() => updateSlideIndex(index)}
          />
        ))}
        <Button
          variant="tertiary"
          iconVariant="materialIcons"
          icon="keyboard-arrow-right"
          small={false}
          widthVariant="icon"
          iconPosition="right"
          onPress={() => updateSlideIndex(slideIndex + 1)}
          testID="arrow-right"
          analyticsName="Carousel arrow right"
          disabled={slideIndex === slideData?.length - 1}
        />
      </View>
    )
  }

  const onScrollEnd = (index: number) => {
    Analytics.trackScreen(`Carousel, slideIndex ${index}`)
  }

  const renderHero = () => {
    return (
      <CarouselComponent
        ref={carouselRef}
        width={heroWidth}
        height={heroHeight}
        data={slideData}
        onProgressChange={(_offsetProgress, absoluteProgress) => {
          if (
            carouselRef.current &&
            (absoluteProgress > 0.5 || carouselRef.current?.getCurrentIndex() === 0)
          ) {
            setSlideIndex(carouselRef.current.getCurrentIndex())
          }
        }}
        renderItem={({ item }) => renderSlide(item)}
        loop={false}
        onScrollEnd={onScrollEnd}
        scrollAnimationDuration={300}
      />
    )
  }

  return (
    <View style={Styles.container} onLayout={onLayoutChange}>
      {header && renderHeader()}
      {renderHero()}
      {renderDots()}
    </View>
  )
}

export default Carousel
