import { StyleSheet } from 'react-native'
import { Colors, Metrics, Fonts } from 'APP/Themes'

export const Styles = StyleSheet.create({
  errorBanner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: Colors.errorSnackBarBg,
    paddingHorizontal: Metrics.baseMargin,
  },
  errorBannerText: {
    ...Fonts.style.subheaderBold,
    color: Colors.text,
  },
  errorBannerCta: {
    marginTop: Metrics.baseMargin / 2,
    marginBottom: Metrics.baseMargin / 2,
    marginRight: 0,
  },
  contentContainer: {
    flex: 1,
    backgroundColor: Colors.appBg,
  },
  headerContainer: {
    marginBottom: Metrics.baseMargin,
  },
  searchContainer: {
    backgroundColor: Colors.elementsBg,
  },
  searchField: {
    padding: Metrics.baseMargin,
    paddingTop: 0,
  },
  scroll: {
    backgroundColor: Colors.appBg,
  },
  scrollContent: {
    flex: 1,
    paddingBottom: 0,
    marginBottom: Metrics.bottomSpace + Metrics.baseMargin,
  },
  addressOption: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: Metrics.baseMargin * 2.5,
    borderBottomWidth: 1,
    borderBottomColor: Colors.shadow,
  },
  addressOptionTextContainer: {
    flexShrink: 1,
  },
  addressOptionTitle: {
    ...Fonts.style.subheaderBold,
    color: Colors.text,
  },
  addressOptionSubtitle: {
    ...Fonts.style.subheader,
    color: Colors.text,
  },
  addressSearchCard: {
    minHeight: 200,
    overflow: 'scroll',
  },
})
