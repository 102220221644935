import React, { useCallback, useState, createRef, useEffect } from 'react'
import { Image, useWindowDimensions } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigation } from '@react-navigation/native'

import LoginActions from 'APP/Redux/LoginRedux'

import Config from 'APP/Config'

import I18n from 'APP/Services/i18n'
import usePrevious from 'APP/Hooks/usePrevious'
import useScreenView from 'APP/Hooks/useScreenView'

import Button from 'APP/Converse/Button'
import Typography from 'APP/Converse/Typography'
import { CarouselDot, CarouselComponent } from 'APP/Components/Carousel'
import DialogueBranding from 'APP/Components/DialogueBranding'
import ConfigPicker from 'APP/Components/ConfigPicker'
import { QRCodeAppModal } from 'APP/Components/QRCodeAppModal'

import { getImageAspectRatio } from 'APP/Lib/StylingHelpers'

// Styling
import Styles from './style'
import {
  StyledViewContainer,
  StyledViewHero,
  StyledViewCTAs,
  StyledVideo,
  StyledViewVideoContainer,
  StyledViewImageContainer,
  StyledViewTextContainer,
  StyledViewCarousel,
  StyledViewDotsContainer,
  StyledImageHero,
  StyledLinearGradient,
  StyledViewButtonsContainer,
  StyledViewCTAsContainer,
  StyledViewQRContainer,
} from './style'
import { Images, Colors } from 'APP/Themes'
import Videos from '../../Themes/Videos'
import { isWeb } from 'APP/Helpers/checkPlatform'

export const CTAS = {
  SIGNUP: 'SIGNUP',
  SIGNIN: 'SIGNIN',
  SSOSIGNIN: 'SSOSIGNIN',
}

const LOGIN_LONG_PRESS_DELAY_MS = 5000

const WelcomeScreen = ({ openUniversalLogin }) => {
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const busy = useSelector((state) => state.login?.fetching || state.patient?.requestRunning)
  const loginConnection = useSelector((state) => state.login?.connection)
  const features = useSelector((state) => state.features)

  const slideIndicies = (Config.WELCOMESCREEN && Config.WELCOMESCREEN.SLIDER_INDICES) || [
    0, 1, 2, 3,
  ]
  const isSlideImageStretch =
    Config.WELCOMESCREEN && Config.WELCOMESCREEN.SLIDE_IMAGE_STRETCH && slideIndicies.length > 1

  const [actionType, setActionType] = useState('')
  const [configResolutionDone, setConfigResolutionDone] = useState(false)

  const { isBigScreen } = useScreenView()

  const { height, width } = useWindowDimensions()
  const heroWidth = isBigScreen ? width / 2 : width
  const heroHeight = isBigScreen ? height : height * (isSlideImageStretch ? 0.7 : 0.6)

  const useSSOLogin = features?.useSSOLogin || Config?.HAS_MULTIPLE_LOGIN_STRATEGIES === true

  useEffect(() => {
    if (openUniversalLogin) {
      dispatch(LoginActions.loginRequest())
    }
  }, [])

  const prevBusy = usePrevious(busy)
  useEffect(() => {
    if (prevBusy && !busy) {
      setActionType(undefined)
    }
  }, [busy, prevBusy])

  const slideData = slideIndicies.map(({ type }, index) => {
    const isVideoSlide = type === Config.ASSET_TYPES.video
    return {
      isVideoSlide,
      color: Colors[`welcomeScreenBackgroundSlide${index}`],
      image: Images[`welcomeSlide${index}`],
      video: Videos[`welcomeSlide${index}`],
      title: I18n.t(`WelcomeScreen.slide${index}Header`),
      text: I18n.t(`WelcomeScreen.slide${index}Text`),
    }
  })

  const carouselRef = createRef()
  const configPickerRef = createRef()

  const [slideIndex, setSlideIndex] = useState(0)

  const [isQRCodeModalOpen, setQRCodeModalOpen] = useState(false)

  const onDotPress = (index) => {
    if (index !== slideIndex) {
      carouselRef?.current?.scrollTo({ index })
      setSlideIndex(index)
    }
  }

  const renderSlideImage = (item) => {
    return (
      <StyledViewImageContainer item={item} testID="view-image-container-slide">
        <StyledImageHero
          testID="hero-image"
          source={item.image}
          resizeMode={isSlideImageStretch ? 'cover' : 'contain'}
          isWeb={isWeb()}
        />
        <StyledViewTextContainer testID="text-container">
          <Typography variant="h2" color="text" align="center">
            {item.title}
          </Typography>
          <Typography color="text" align="center" variant="bodyNormal">
            {item.text}
          </Typography>
        </StyledViewTextContainer>
      </StyledViewImageContainer>
    )
  }

  const renderSlideVideo = ({ color, video }) => {
    return (
      <StyledViewVideoContainer slideBackgroundColor={color} testID="view-video-container-slide">
        <StyledVideo
          isLooping
          resizeMode="cover"
          videoStyle={Styles.videoStyle}
          source={video}
          shouldPlay={true}
          isMuted={true}
        />
        <StyledLinearGradient colors={['#ffffff00', color]} locations={[0.1, 0.85]} />
      </StyledViewVideoContainer>
    )
  }

  const renderHero = () => {
    return (
      <StyledViewCarousel testID="hero" slideIndex={slideIndex}>
        <CarouselComponent
          ref={carouselRef}
          width={heroWidth}
          height={heroHeight}
          data={slideData}
          onProgressChange={(_offsetProgress, absoluteProgress) => {
            if (
              carouselRef.current &&
              (absoluteProgress > 0.5 || carouselRef.current?.getCurrentIndex() === 0)
            ) {
              setSlideIndex(carouselRef.current.getCurrentIndex())
            }
          }}
          renderItem={({ item }) => {
            return item.isVideoSlide ? renderSlideVideo(item) : renderSlideImage(item)
          }}
          enabled={slideData?.length > 1}
          loop={isWeb()}
          autoPlay={isWeb() && slideData?.length > 1}
        />
      </StyledViewCarousel>
    )
  }

  const renderDots = () => {
    return (
      <StyledViewDotsContainer testID="view-dots-container">
        {slideData.map((data, index) => {
          return (
            <CarouselDot
              index={index}
              key={index}
              onPress={onDotPress}
              selected={slideIndex === index}
            />
          )
        })}
      </StyledViewDotsContainer>
    )
  }

  const withCTAPress = (ctaId, action) => async () => {
    if (actionType || busy) return

    setActionType(ctaId)
    setConfigResolutionDone(false)
    await configPickerRef.current?.applyConfigChanges?.()
    action()
    setConfigResolutionDone(true)
  }

  const attemptSignUp = useCallback(() => {
    // TODO: Add useSSOAccountCreation flag here
    dispatch(
      loginConnection
        ? LoginActions.signupRequestWithConnection(loginConnection)
        : LoginActions.signupRequest()
    )
  }, [loginConnection, dispatch])

  const attemptLogin = useCallback(() => {
    if (useSSOLogin && !loginConnection) {
      setActionType(undefined)
      navigation.navigate('multiLogin')
      return
    }
    dispatch(
      loginConnection
        ? LoginActions.loginRequestWithConnection(loginConnection)
        : LoginActions.loginRequest()
    )
  }, [loginConnection, dispatch, useSSOLogin, navigation])

  const renderCTAs = () => {
    const showActivityIndicator = configResolutionDone || busy
    const showTexts = isBigScreen || (!isBigScreen && slideData.length === 1)

    const iconAspectRatio = getImageAspectRatio(Images.logoBlue)

    return (
      <StyledViewCTAsContainer testID="ctas">
        <StyledViewTextContainer testID="texts-container">
          {isBigScreen && (
            <Image
              style={[Styles.icon, { aspectRatio: iconAspectRatio }]}
              source={Images.logoBlue}
              resizeMode="contain"
            />
          )}
          {showTexts && (
            <>
              <Typography color="text" variant="h3" align="center">
                {I18n.t('WelcomeScreen.title')}
              </Typography>
              <Typography color="text" variant="bodyNormal" align="center">
                {I18n.t('WelcomeScreen.subtitle')}
              </Typography>
            </>
          )}
        </StyledViewTextContainer>

        <StyledViewButtonsContainer>
          <Button
            variant="primary"
            title={I18n.t('WelcomeScreen.signUp')}
            widthVariant="full"
            onPress={withCTAPress(CTAS.SIGNUP, attemptSignUp)}
            isLoading={showActivityIndicator && actionType === CTAS.SIGNUP}
            analyticsName="WelcomeScreen.signUp"
            testID="signup-button"
          />
          <Button
            variant="secondary"
            title={I18n.t('WelcomeScreen.logIn')}
            widthVariant="full"
            onPress={withCTAPress(CTAS.SIGNIN, attemptLogin)}
            isLoading={showActivityIndicator && actionType === CTAS.SIGNIN}
            analyticsName="WelcomeScreen.logIn"
            onLongPress={() => {
              // Only show the tenant switcher if there are other tenants to switch to
              if (Config.HOST_OVERRIDES && Object.keys(Config.HOST_OVERRIDES).length > 0) {
                navigation.navigate('tenantSwitcherScreen')
              }
            }}
            delayLongPress={LOGIN_LONG_PRESS_DELAY_MS}
            testID="login-button"
          />
        </StyledViewButtonsContainer>
        <ConfigPicker ref={configPickerRef} busy={Boolean(actionType) || busy} />
        <DialogueBranding />
      </StyledViewCTAsContainer>
    )
  }

  return (
    <StyledViewContainer slideIndex={slideIndex} testID="view-container">
      <StyledViewHero testID="view-hero">
        {renderHero()}
        {slideData?.length > 1 && renderDots()}
      </StyledViewHero>
      <StyledViewCTAs hasDialogueBranding={Config.DIALOGUE_BRANDING_ENABLED} testID="view-ctas">
        {renderCTAs()}
        {isWeb() && (
          <StyledViewQRContainer testID="view-qr">
            <Button
              testID="qr-code-button"
              variant="tertiary"
              title={I18n.t('WelcomeScreen.downloadApp')}
              widthVariant={isBigScreen ? 'full' : 'fixed'}
              analyticsName="WelcomeScreen.downloadApp"
              icon="qr-code"
              iconPosition="right"
              iconVariant="materialIcons"
              onPress={() => setQRCodeModalOpen(true)}
            />
          </StyledViewQRContainer>
        )}
      </StyledViewCTAs>
      <QRCodeAppModal isVisible={isQRCodeModalOpen} onClose={() => setQRCodeModalOpen(false)} />
    </StyledViewContainer>
  )
}

export default WelcomeScreen
