import React from 'react'
import { Text, View, SectionList } from 'react-native'
import { connect } from 'react-redux'
import PatientActions from 'APP/Redux/PatientRedux'

import I18n from 'APP/Services/i18n'

import { PrimaryButton } from 'APP/Components/Buttons'
import { ResponsiveScrollView, ResponsiveView } from 'APP/Converse/Layout'

import Styles from './style'
import { Colors } from 'APP/Themes'
import ZeroEligibilityInfoCard from 'APP/Components/InfoCard/ZeroEligibilityInfoCard'

const mapIntervals = (eligibilityIntervals) => {
  const activeIntervals = {
    id: 'active',
    title: I18n.t('CoverageScreen.activeHeader'),
    data: [],
  }
  const inactiveIntervals = {
    id: 'inactive',
    title: I18n.t('CoverageScreen.inactiveHeader'),
    data: [],
  }

  eligibilityIntervals?.forEach?.((interval) => {
    if (interval?.status === 'active') {
      activeIntervals.data.push(interval)
    } else if (
      // filter out inactive intervals for orgs with an active interval
      !activeIntervals.data.some(
        (activeInterval) =>
          activeInterval?.eligibilityRecord?.organization?.name ===
          interval?.eligibilityRecord?.organization?.name
      )
    ) {
      inactiveIntervals.data.push(interval)
    }
  })

  return [activeIntervals, inactiveIntervals]
}

const CoverageHeader = ({ title }) => <Text style={Styles.sectionHeader}>{title}</Text>

const CoverageItem = ({ item, active }) => {
  const organization = item?.eligibilityRecord?.organization

  let containerStyle, textStyle
  if (active) {
    containerStyle = Styles.activeItemContainer
    textStyle = Styles.activeItemText
  } else {
    containerStyle = Styles.inactiveItemContainer
    textStyle = Styles.inactiveItemText
  }

  return (
    <View style={[Styles.itemContainer, containerStyle]}>
      <Text style={[Styles.itemTitle, textStyle]}>
        {I18n.locale === 'en' ? organization.displayName.en : organization.displayName.fr}
      </Text>
    </View>
  )
}

const Footer = ({ busy, unchallengedInvitationSearch }) => (
  <View>
    <PrimaryButton
      style={Styles.addCoverageButton}
      title={I18n.t('CoverageScreen.addCoverageButton')}
      onPress={unchallengedInvitationSearch}
      analyticsName={'Add Coverage'}
      showActivityIndicator={busy}
      activityIndicatorColor={Colors.buttonPrimaryText}
    />
    <Text style={Styles.footerDescription}>{I18n.t('CoverageScreen.addCoverageDescription')}</Text>
  </View>
)

const CoverageScreen = ({ eligibilityIntervals, busy, unchallengedInvitationSearch }) => {
  if (eligibilityIntervals?.length === 0) {
    return (
      <View style={Styles.container}>
        <View style={Styles.zeroEligibilityContainer}>
          <ZeroEligibilityInfoCard testId="zeroEligibilityCard" />
        </View>
      </View>
    )
  }

  return (
    <ResponsiveScrollView>
      <ResponsiveView style={Styles.container}>
        <SectionList
          style={Styles.coverageList}
          sections={mapIntervals(eligibilityIntervals)}
          keyExtractor={(item, index) => item + index}
          renderItem={({ item, section }) => (
            <CoverageItem item={item} active={section.id === 'active'} />
          )}
          renderSectionHeader={({ section }) =>
            section.data.length > 0 && <CoverageHeader title={section.title} />
          }
          stickySectionHeadersEnabled={false}
        />
        <Footer busy={busy} unchallengedInvitationSearch={unchallengedInvitationSearch}/>
      </ResponsiveView>
    </ResponsiveScrollView>
  )
}

const mapStateToProps = ({ patient }, props) => ({
  ...props.route?.params,
  eligibilityIntervals: patient?.profile?.eligibilityIntervals,
  busy:
    (patient?.unchallengedInvitationSearchRunning ?? false) ||
    (patient?.challengedInvitationSearchRunning ?? false),
})

const mapDispatchToProps = (dispatch) => {
  return {
    unchallengedInvitationSearch: () =>
      dispatch(PatientActions.unchallengedInvitationSearchRequest()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CoverageScreen)
