import { Keyboard, Image } from 'react-native'
import Card from 'APP/Converse/Card'
import styled from 'styled-components/native'
import Typography from 'APP/Converse/Typography'
import React, { memo, useEffect } from 'react'
import ControllerRadioGroup from 'APP/Components/ControllerFields/ControllerRadioGroup'
import I18n from 'APP/Services/i18n'
import { PrimaryButton } from 'APP/Components/Buttons'
import { Colors } from 'APP/Themes'
import { useForm } from 'react-hook-form'
import ControllerCheckbox from 'APP/Components/ControllerFields/ControllerCheckbox'
import { useDispatch } from 'react-redux'
import LoginActions from 'APP/Redux/LoginRedux'
import { PublicComputerType } from 'APP/Lib/Enums'
import { Images } from 'APP/Themes'
import { useDevTools } from 'APP/Lib/DevTools'

const ContentCard = styled(Card)`
  width: 100%;
  max-width: 550px;
  align-items: center;
`

const Logo = styled(Image)`
  height: 50px;
  width: 200px;
  margin-top: ${({ theme }) => theme.metrics.baseMargin}px;
`

const Content = styled.View`
  padding: ${({ theme }) => theme.metrics.baseMargin}px;
  gap: ${({ theme }) => theme.metrics.baseMargin}px;
  text-align: center;
`

const Page = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`

interface FormProps {
  publicComputer: PublicComputerType | null
  dontAskPublicComputer: boolean
}

const options = [
  {
    label: I18n.t('PublicComputerScreen.yes'),
    value: PublicComputerType.ANSWERED_YES,
  },
  {
    label: I18n.t('PublicComputerScreen.no'),
    value: PublicComputerType.ANSWERED_NO,
  },
]

const radioButtonContainerStyle = { marginBottom: 16 }

const PublicDeviceScreen = () => {
  const dispatch = useDispatch()
  const { setToolFunction, setToolName } = useDevTools()

  useEffect(() => {
    setToolName('Private')
    setToolFunction(() => () => {
      dispatch(
        LoginActions.submitPublicComputer({ publicComputer: PublicComputerType.ANSWERED_NO })
      )
    })
    return () => {
      setToolFunction(null)
      setToolName(null)
    }
  }, [])

  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      publicComputer: null,
      dontAskPublicComputer: false,
    },
  })

  const publicComputer = watch('publicComputer')

  const onSubmit = (values: FormProps) => {
    dispatch(LoginActions.submitPublicComputer(values))
  }

  return (
    <Page>
      <ContentCard>
        <Logo source={Images.logoBlue} resizeMode="contain" />
        <Content>
          <Typography variant="h3">{I18n.t('PublicComputerScreen.title')}</Typography>
          <Typography>{I18n.t('PublicComputerScreen.subtitle')}</Typography>
        </Content>
        <ControllerRadioGroup
          buttonContainerStyle={radioButtonContainerStyle}
          name="publicComputer"
          control={control}
          options={options}
        />

        <ControllerCheckbox
          name="dontAskPublicComputer"
          control={control}
          label={I18n.t('PublicComputerScreen.remember')}
        />

        <PrimaryButton
          onPress={() => {
            Keyboard.dismiss()
            handleSubmit(onSubmit)()
          }}
          analyticsName="Next"
          activityIndicatorColor={Colors.buttonPrimaryText}
          disabled={!publicComputer}
        >
          {I18n.t('PublicComputerScreen.continue')}
        </PrimaryButton>
      </ContentCard>
    </Page>
  )
}

export default memo(PublicDeviceScreen)
