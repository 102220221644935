import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'react-native-loading-spinner-overlay'
import { useNavigation } from '@react-navigation/native'
import { loadStripe } from '@stripe/stripe-js'
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import { CreditCardView } from 'react-native-credit-card-input'

import PatientActions from 'APP/Redux/PatientRedux'

import Styles from './style'
import { StyledWebViewWebCardContainer } from './style'
import { ComponentConfigs } from 'APP/Themes'

import I18n from 'APP/Services/i18n'
import Config from 'APP/Config'
import { routeNameToAnalyticsName } from 'APP/Nav'
import Button from 'APP/Converse/Button'
import Typography from 'APP/Converse/Typography'
import { ResponsiveView } from 'APP/Converse/Layout'

const Form = (props) => {
  const stripe = useStripe()
  const elements = useElements()

  // Make sure Stripe.js has loaded, to disable form submission
  const isStripeClientLoaded = Boolean(stripe && elements)

  const { route } = props
  const { chargeId } = { ...route?.params, ...props }
  const analyticsName = routeNameToAnalyticsName(route?.name)
  const dispatch = useDispatch()
  const navigation = useNavigation()

  const { givenName, familyName } = useSelector((state) => state.patient?.profile)
  const { patientCreditCardRegisterRunning, patientCreditCardRegisterSuccess } = useSelector(
    (state) => state.patient
  )

  const [busy, setBusy] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(() => {
    // user just registered a card after being sent a charge from chat
    if (chargeId && patientCreditCardRegisterSuccess) {
      dispatch(PatientActions.patientCreditCardCharge({ chargeId }))
      navigation.goBack()
    }
  }, [patientCreditCardRegisterSuccess, chargeId, navigation, dispatch])

  const callFocus = (error) => {
    const cardNumberElement = elements.getElement(CardNumberElement)
    const expirationElement = elements.getElement(CardExpiryElement)
    const cvcElement = elements.getElement(CardCvcElement)

    switch (error.code) {
      case 'invalid_expiry_month':
      case 'invalid_expiry_year':
      case 'invalid_expiry_year_past':
      case 'incomplete_expiry':
        expirationElement.update(Styles.webCardElementError)
        expirationElement.focus()
        break

      case 'invalid_cvc':
      case 'incomplete_cvc':
        cvcElement.update(Styles.webCardElementError)
        cvcElement.focus()
        break

      default:
        cardNumberElement.update(Styles.webCardElementError)
        cardNumberElement.focus()
    }
  }

  const handleSubmit = (event) => {
    event && event.preventDefault()
    if (!isStripeClientLoaded) return

    setBusy(true)

    const expirationElement = elements.getElement(CardExpiryElement)
    const cvcElement = elements.getElement(CardCvcElement)
    const cardNumberElement = elements.getElement(CardNumberElement)
    stripe
      .createToken(cardNumberElement, { name: `${givenName} ${familyName}` })
      .then((payload) => {
        if (payload.error) {
          setErrorMessage(payload.error.message)
          callFocus(payload.error)
          dispatch(
            PatientActions.patientCreditCardRegisterFailure(JSON.stringify(payload.error.message))
          )
        } else {
          cardNumberElement.clear()
          expirationElement.clear()
          cvcElement.clear()
          setErrorMessage(null)
          dispatch(PatientActions.patientCreditCardRegisterRequest(payload.token.id, chargeId))
        }
        setBusy(false)
      })
  }

  return (
    <ResponsiveView style={Styles.webInnerContainer}>
      <CreditCardView
        {...ComponentConfigs.creditCardForm}
        focusedField="number"
        style={Styles.cardFieldContainer}
      />

      <Typography color="text" align="center" variant="bodyNormal">
        {I18n.t('PaymentScreen.add.description')}
      </Typography>

      {errorMessage && (
        <Typography color="error" align="center" variant="bodyNormal">
          {errorMessage}
        </Typography>
      )}

      <Spinner
        testID="spinner"
        visible={busy || patientCreditCardRegisterRunning}
        cancelable={false}
      />
      <form onSubmit={handleSubmit}>
        <StyledWebViewWebCardContainer>
          <CardNumberElement
            options={{
              style: Styles.webCardElement,
            }}
          />
        </StyledWebViewWebCardContainer>
        <StyledWebViewWebCardContainer>
          <CardExpiryElement
            options={{
              style: Styles.webCardElement,
            }}
          />
        </StyledWebViewWebCardContainer>
        <StyledWebViewWebCardContainer>
          <CardCvcElement
            options={{
              style: Styles.webCardElement,
            }}
          />
        </StyledWebViewWebCardContainer>
        <Button
          title={I18n.t('PaymentScreen.add.save')}
          widthVariant="full"
          onPress={handleSubmit}
          analyticsName={analyticsName}
          disabled={!isStripeClientLoaded}
        />
      </form>
    </ResponsiveView>
  )
}

const stripePromise = loadStripe(Config.STRIPE_PUBLIC_KEY, { locale: I18n.baseLocale })

const AddPaymentScreen = (props) => (
  <Elements stripe={stripePromise}>
    <Form {...props} />
  </Elements>
)

export default AddPaymentScreen
