import { Platform } from 'react-native'
import url from 'url'
import { all, put, call, select, race, take, cancel, fork } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import Toast from 'react-native-toast-message'
import * as Sentry from '@sentry/react-native'

// Services
import Config from 'APP/Config'
import ConfigEnv from 'APP/NativeModules/ConfigEnv'
import I18n from 'APP/Services/i18n'
import BranchEventChannel from 'APP/Services/Branch'
import Analytics from 'APP/Services/Analytics'

// Actions
import DeeplinkActions from '../Redux/DeeplinkRedux'
import LoginActions from 'APP/Redux/LoginRedux'
import PatientHistoryActions from 'APP/Redux/PatientHistoryRedux'
import LoggerActions from 'APP/Redux/LoggerRedux'
import AccountLinkingActions from 'APP/Redux/AccountLinkingRedux'
import { isWeb } from 'APP/Helpers/checkPlatform'

// Types
import { DeeplinkTypes } from 'APP/Redux/DeeplinkRedux'
import { LoginTypes } from 'APP/Redux/LoginRedux'

// Selectors
import { loginState, logout } from './AuthSagas'
import { patientProfileState, enrolWithEnrolmentCodeDeeplink } from './PatientSagas'

// Helpers
import { TabScreens } from 'APP/Lib/ContentHelpers'
import { RootScreens, VerifyEmailErrors } from 'APP/Lib/Enums'

import * as NestedNavHelper from 'APP/Nav/NestedNavHelper'
import moment from 'moment/moment'
import { decodeBase64Token } from 'APP/Lib/Helpers'
import { isMobile } from 'APP/Helpers/checkPlatform'
import PatientActions from 'APP/Redux/PatientRedux'
import { clearAllQueryParamsAndHash } from 'APP/Lib/Utilities'

// Simple predicate for paths that should skip startup
export const simplePathCheckPredicate = (path) =>
  ['action/login', 'verifyemail'].some((p) => path?.includes(p) ?? false)

// Signup enrolment code should only skip startup when the user is not logged in (assumed to be a new user)
export const signupEnrolmentCodePredicate = function* (path) {
  const login = yield select(loginState)
  return !login.accessToken && path.includes('signupEnrolmentCode')
}

// A collection of predicates for deeplink paths that shouldn't wait for startup to complete
// before processing. Made up of functions that take a path and return a boolean.
export const LINKS_THAT_SKIP_READY_TO_PROCESS = [
  simplePathCheckPredicate,
  signupEnrolmentCodePredicate,
]

export const deeplinkState = (state) => state?.deeplink || {}
export const featuresState = (state) => state.features

const urlSchemes =
  Platform.OS === 'ios' ? ConfigEnv.URL_SCHEMES : ConfigEnv.getConstants()['URL_SCHEMES']
const regExpTrailingAndLeadingSlashes = /^\/|\/$/g
const regExpUrlScheme = new RegExp(`(${(urlSchemes || []).join('|')})://`, 'gi')

/**
 * Using Share.share() could wrongly encode URL Query Params on some application.
 * This function removed the wrong encoding on the Query Params.
 * See https://dialoguemd.atlassian.net/browse/DIA-45572
 */
const stripEncodedAmpersandKey = (params) => {
  return Object.entries(params).reduce((p, [key, value]) => {
    if (key.startsWith('amp;')) {
      p[key.slice('amp;'.length)] = value
      return p
    }
    p[key] = value
    return p
  }, {})
}

const parseBranchParams = (params) => {
  const { $deeplink_path: deeplinkPath, ...other } = stripEncodedAmpersandKey(params)
  let nonBranchLink = params['+non_branch_link']
  let nonBranchParams = {}
  if (nonBranchLink) {
    nonBranchLink = nonBranchLink.replace(regExpUrlScheme, '')
    const { pathname, query } = url.parse(nonBranchLink, true)
    nonBranchParams = { pathname, query }
  }

  const {
    contentful_service_group_id,
    contentful_external_service_id: healthResource,
    id,
    ...rest
  } = { ...other, ...nonBranchParams.query }
  return {
    path: (deeplinkPath || nonBranchParams.pathname || '').replace(
      regExpTrailingAndLeadingSlashes,
      ''
    ),
    props: {
      ...rest,
      id: id || contentful_service_group_id,
      healthResource,
    },
  }
}

export function* watchDeeplinkRequests() {
  let waitDeferredLinkResolutionTask
  try {
    const deeplinkChannel = eventChannel(BranchEventChannel)
    // Infinite loop on mobile, as deeplinks can be received at any time in the process lifetime whereas on web it's only at startup
    do {
      const { error, params } = yield take(deeplinkChannel)
      if (error) {
        yield put(LoggerActions.error(`Error while processing deeplink ${JSON.stringify(error)}`))
        Sentry.captureMessage(`Error while processing deeplink ${JSON.stringify(error)}`, {
          level: 'warning',
        })
        continue
      }
      // Link is for universal login, and should not be processed
      if (params['+non_branch_link']?.startsWith?.(Config.AUTH0_URL)) {
        continue
      }

      // Handle the data...
      const { path, props } = parseBranchParams(params)
      if (props && props['~referring_link']) {
        Analytics.trackEvent('deep_link_opened', { trigger: path, ...props })
      }

      if (path || props.type) {
        yield put(DeeplinkActions.setDeferredDeeplink(path, props))

        // Check each special case predicate to see if we should skip the readyToProcess check (triggered at the end of startup)
        // and process the deeplink immediately. If any of the predicates return true the deeplink will be processed immediately.
        const shouldSkipReadyToProcess = (yield all(
          LINKS_THAT_SKIP_READY_TO_PROCESS.map((predicate) => call(predicate, path, props.type))
        )).some(Boolean)

        if (shouldSkipReadyToProcess) {
          yield put(DeeplinkActions.processDeferredDeeplink())
        } else {
          const { readyToProcess } = yield select(deeplinkState)

          if (readyToProcess) {
            yield put(DeeplinkActions.processDeferredDeeplink())
          } else {
            // Rely on the last step of startup nav to trigger deeplink processing
            // This watcher is only needed till we define the UX for an interrupted flow (e.g. incomplete profile, logged out)
            if (waitDeferredLinkResolutionTask) {
              yield cancel(waitDeferredLinkResolutionTask)
            }
            waitDeferredLinkResolutionTask = yield fork(waitDeferredLinkResolution)
          }
        }
      }
    } while (isMobile())
  } catch (error) {
    yield put(
      LoggerActions.error(
        `Fatal error while processing deeplink requests: ${JSON.stringify(error)}`
      )
    )
  }
}

export function* rejectDeferredDeeplink(reasonProp, path, props, ...other) {
  let reason = reasonProp
  if (!reason) {
    const login = yield select(loginState)
    reason = !login.accessToken ? 'unauthorized' : 'incompleteProfile'
  }
  yield put(DeeplinkActions.rejectDeferredDeeplink(reason, path, props, ...other))
  Analytics.trackEvent('deep_link_rejected', {
    reason,
    trigger: path || null,
    id: props?.id || null,
    services: props?.services || null,
    $marketing_title: props?.['$marketing_title'],
    '~referring_link': props?.['~referring_link'],
  })
  Toast.show({
    text1: I18n.t(`Deeplinks.error.title`),
    text2: I18n.t(`Deeplinks.error.${reason}`),
  })
}

export function* waitDeferredLinkResolution() {
  const { logout } = yield race({
    logout: take(LoginTypes.LOGOUT),
    ready: take(({ type, ready }) => type === DeeplinkTypes.SET_READY_TO_PROCESS && ready),
  })
  if (logout) {
    yield call(rejectDeferredDeeplink)
  }
}

export function* verifyNewEmailDeeplink({ token: rowToken }) {
  const tokenValues = decodeBase64Token(rowToken)
  const { expiration_date, new_email, old_email, token } = tokenValues

  const isTokenExpired = moment(expiration_date).diff(moment()) < 0

  const isTokenValid = new_email && old_email && token && expiration_date

  yield put(PatientActions.setChangeEmailToken(tokenValues))

  if (isTokenExpired || !isTokenValid) {
    const errorType = isTokenExpired
      ? VerifyEmailErrors.TOKEN_EXPIRED
      : VerifyEmailErrors.TOKEN_INVALID
    yield call(NestedNavHelper.navigate, 'verifyNewEmailResult', { errorType })
  } else {
    yield call(NestedNavHelper.navigate, 'verifyNewEmail')
  }
}

export function* processDeferredDeeplink() {
  try {
    const { path, props, expiry } = yield select(deeplinkState)
    const { eligibleServices, healthResources } = yield select(patientProfileState)
    const { accessToken } = yield select(loginState)
    const isStillValid = Date.now() < Date.parse(expiry)

    if (!isStillValid) {
      Analytics.trackEvent('deep_link_expired', { trigger: path, ...props })
      yield put(DeeplinkActions.processDeferredDeeplinkExpired())

      return
    }

    if (props?.type) {
      switch (props.type) {
        default:
          LoggerActions.error('Unknown deeplink type passed')
          break
      }
    } else if (path) {
      // When a deeplink is picked up by the watchDeeplinkRequests saga, the path is trimmed. When a second_action is processed, we skip this step. This ensures that all paths are in the same format.
      const trimmedPath = path.replace(regExpTrailingAndLeadingSlashes, '')
      const isAction = trimmedPath.split('/')[0] === 'action'
      if (isAction) {
        const action = trimmedPath.split('/')[1]

        switch (action) {
          case 'newConsult': {
            const { command_post } = props
            if (!command_post) break
            yield put(PatientHistoryActions.createEpisodeRequest(null, command_post))
            yield put(DeeplinkActions.processDeferredDeeplinkSuccess())
            return
          }
          case 'login': {
            const { connection, second_action } = props
            if (!connection) break
            yield put(DeeplinkActions.processDeferredDeeplinkSuccess())

            if (accessToken) {
              const reason = 'Log out before log in deeplink'

              if (isWeb()) {
                // Dispatching this logout action to clear accessToken
                yield put(LoginActions.logout(reason))
                // Logging out on web causes a redirection, where it goes away from the app, which then causes the app to restart when it comes back.
                // This permits to stop code execution until logout happens
                return
              } else {
                yield call(logout, { reason })
              }
            }

            // To stop infinite loop of processing SSO deeplink branch
            if (isWeb()) {
              clearAllQueryParamsAndHash()
            }

            yield put(AccountLinkingActions.resetAccountLinking())
            // By setting the connection here, when the member chooses to either
            // create an account or login, they will only be allowed to use the
            // supplied connection.
            yield put(LoginActions.setConnection(connection))
            // Note: we used to explicitly attempt to trigger the login sequence here.
            // This was poor UX, because browsers tend to block pop-ups that are
            // programatically triggered.
            if (second_action) {
              yield put(DeeplinkActions.setDeferredDeeplink(path, props))
            }
            return
          }
          default:
            break
        }
      } else if (path === 'signupEnrolmentCode') {
        // Special case for enrolment code when logged in, behaves more like an action than just navigation
        if (accessToken && props?.code) {
          yield call(enrolWithEnrolmentCodeDeeplink, props?.code)
          yield put(DeeplinkActions.processDeferredDeeplinkSuccess())
        } else if (!accessToken) {
          yield put(LoginActions.signupRequest())
        }
        return
      } else if (path === 'navigateToIcbt') {
        // ⚠️ This is brittle and could change, breaking this deeplink ⚠️
        const hasIcbtService = !!(
          eligibleServices['icbt_guided'] || eligibleServices['icbt_self_serve']
        )

        if (!hasIcbtService) {
          yield call(rejectDeferredDeeplink, 'notEligible', path, props)
          yield put(DeeplinkActions.processDeferredDeeplinkSuccess())
          return
        }

        yield call(NestedNavHelper.navigate, RootScreens?.mind, {
          tabName: TabScreens?.mind?.toolkits,
        })
        yield put(DeeplinkActions.processDeferredDeeplinkSuccess())
        return
      } else if (path === 'verifyemail') {
        yield call(verifyNewEmailDeeplink, props)
        return
      } else {
        // If service passed, confirm user is eligible
        const { services } = props || {}
        const servicesSplit = services ? services.split(',') : []

        const userDoesNotHaveAccess = services
          ? servicesSplit.filter((p) => eligibleServices[p]).length < servicesSplit.length
          : false

        // Check if item_id is an eligible external service
        const itemIdNotInEligibleServices =
          props?.healthResource &&
          !healthResources.some((resource) => {
            if (!resource.attributes) return false
            const { cmsContentId, cmsContent } = resource.attributes

            return (
              cmsContentId?.includes(props.healthResource) ||
              cmsContent?.some(({ cmsContentId }) => cmsContentId === props.healthResource)
            )
          })

        if (userDoesNotHaveAccess || itemIdNotInEligibleServices) {
          yield call(rejectDeferredDeeplink, 'notEligible', path, props)
          yield put(DeeplinkActions.processDeferredDeeplinkSuccess())
          return
        }

        const routeExists = NestedNavHelper.getRouteNames().indexOf(path) !== -1
        if (routeExists) {
          yield call(NestedNavHelper.navigate, path, props)
          yield put(DeeplinkActions.processDeferredDeeplinkSuccess())
          return
        }
      }
    }

    yield call(rejectDeferredDeeplink, 'invalidLink', path, props)
    yield put(DeeplinkActions.processDeferredDeeplinkSuccess())
  } catch (error) {
    yield put(DeeplinkActions.processDeferredDeeplinkFailure(error.message))
  }
}
