import { ActivityIndicator, ActivityIndicatorProps } from 'react-native'
import styled from 'styled-components/native'

import { Colors } from 'APP/Themes'

const LoadingContainer = styled.View`
  flex-grow: 100;
  justify-content: center;
  align-items: center;
`

export const LoadingIndicator = (props: ActivityIndicatorProps) => (
  <LoadingContainer>
    <ActivityIndicator size="large" color={Colors.text} testID="loading-indicator" {...props} />
  </LoadingContainer>
)
