import { Linking } from 'react-native'
import DeviceInfo from 'react-native-device-info'
import PushNotification from 'react-native-push-notification'
import Toast from 'react-native-toast-message'
import { eventChannel } from 'redux-saga'
import { all, select, call, put, take, race, spawn, fork, cancel, delay } from 'redux-saga/effects'
import { omit } from 'ramda'
import * as Sentry from '@sentry/react-native'
import { camelizeKeys } from 'humps'

import {
  navigationRef as Nav,
  CONVERSATION_ROUTES,
  CURRENT_CHANNEL_POLL_ROUTES,
  NOTIFICATION_BLACKLIST_ROUTES,
} from 'APP/Nav'
import * as NestedNavHelper from 'APP/Nav/NestedNavHelper'

// Actions and Types
import PatientHistoryActions, { buildInitialFilePayload } from 'APP/Redux/PatientHistoryRedux'
import PatientActions from 'APP/Redux/PatientRedux'
import { LoginTypes } from 'APP/Redux/LoginRedux'
import LoggerActions from 'APP/Redux/LoggerRedux'
import { AppSessionTypes } from 'APP/Redux/AppSessionRedux'
import { updateAppointment } from 'APP/Store/ProviderBooking/sagas'

// Services
import { EmergencyRoom, Silkroad } from '@dialogue/services'
import { getBio } from 'APP/Services/CMS'
import Mattermost from 'APP/Services/Mattermost'
import MattermostSocket from 'APP/Services/Mattermost/MattermostSocket'
import Usher from 'APP/Services/Usher'
import { ldClient } from 'APP/Services/LaunchDarkly'
import { normalizeAppointments } from 'APP/Lib/AppointmentHelpers'
import { addCommandSlots, isValidPostData } from 'APP/Lib/MessagingHelpers'
import {
  isSelfPost,
  isEncounterPost,
  isChannelInconsistent,
  isPostWhichTriggersNotifications,
} from 'APP/Lib/MessagingHelpers'
import Config from 'APP/Config'
import { checkLocationService } from 'APP/NativeModules/PermissionsService'
import { resolveExtraProps } from 'APP/Services/ExtraPropsSourceService'
import i18n from 'APP/Services/i18n'
import CoreData from 'APP/Services/CoreData'
import sprigClient from 'APP/Services/Sprig'
import { serviceHeaders } from 'APP/Lib/ServiceHeaders'

// Sagas
import { setIsConnectedIfDisconnected } from 'APP/Sagas/AppSessionSagas'
import { logDdError } from 'APP/Lib/Datadog'

export const POSTS_PER_PAGE = 50
export const POSTS_FETCH_COUNT_FOR_CHANNEL_INIT = 25

// state selector functions
export const appSessionState = (state) => state.appSession
export const fullState = (state) => state
export const patientState = (state) => state.patient.profile
export const loginState = (state) => state.login
export const loggedInState = (state) => state.login && state.login.accessToken
export const historyState = (state) => state.history
export const featuresState = (state) => state.features
export const familyState = (state) => state.family
export const providerBookingState = (state) => state.providerBooking

export function* fetchNewPostsRequestHelper({
  channelId,
  limit = 1000,
  forceFetchAllPosts = false,
  mmClient = null,
  channel = null,
} = {}) {
  try {
    const { lastFetchedPostAt } = channel
    const postData =
      !lastFetchedPostAt || forceFetchAllPosts
        ? yield call(mmClient.getPosts, channelId, { limit: forceFetchAllPosts ? 1000 : limit })
        : yield call(mmClient.getPostsSince, channelId, lastFetchedPostAt)

    yield spawn(setIsConnectedIfDisconnected)

    return postData
  } catch (error) {
    return { error, posts: {}, order: [] }
  }
}

export function* fetchNewPostsForChannel({ channelId } = {}) {
  try {
    if (channelId) {
      const { login, history } = yield select(fullState)
      const mmClient = Mattermost.create(login.accessToken)
      const { channels } = history
      const channel = channels[channelId]

      const postData = yield call(fetchNewPostsRequestHelper, {
        channelId,
        mmClient,
        channel,
      })

      if (postData && postData.error) throw postData.error

      yield put(PatientHistoryActions.fetchPostsSuccess(channelId, postData))

      const lastPost = isValidPostData(postData) && postData.posts[postData.order[0]]
      if (lastPost) {
        yield call(handleNewMessage, lastPost)
      }
    }
  } catch (error) {
    yield put(LoggerActions.error(`fetchNewPostsForChannel Saga Error: ${JSON.stringify(error)}`))
    yield put(PatientHistoryActions.fetchPostsFailure(channelId, error))
  }
}

export function* fetchOlderPostsForChannel({ channelId } = {}) {
  try {
    if (channelId) {
      const { login, history } = yield select(fullState)
      const mmClient = Mattermost.create(login.accessToken)
      const { oldestFetchedPostId } = history.channels[channelId]
      if (!oldestFetchedPostId) return
      const postData = yield call(
        mmClient.getPostsBeforePostId,
        channelId,
        oldestFetchedPostId,
        POSTS_PER_PAGE
      )

      if (postData?.order?.length === 0) {
        yield put(PatientHistoryActions.setAllOlderPostsFetched(channelId, true))
      }

      yield put(PatientHistoryActions.fetchPostsSuccess(channelId, postData))
    }
  } catch (error) {
    yield put(LoggerActions.error(`fetchOlderPostsForChannel Saga Error: ${JSON.stringify(error)}`))
    yield put(PatientHistoryActions.fetchPostsFailure(channelId, error))
  }
}

export function* fetchAllPostsForChannel({ channelId, limit = 1000 } = {}) {
  try {
    if (channelId) {
      const { login } = yield select(fullState)
      const mmClient = Mattermost.create(login.accessToken)
      const postData = yield call(mmClient.getPosts, channelId, { limit })

      yield spawn(setIsConnectedIfDisconnected)

      yield put(PatientHistoryActions.fetchPostsSuccess(channelId, postData, true))
    }
  } catch (error) {
    yield put(PatientHistoryActions.fetchPostsFailure(channelId, error))
  }
}

export function* fetchChannels({ bootstrap = false } = {}) {
  try {
    if (yield select(loggedInState)) {
      yield delay(200) // Debounce

      yield call(channelsConsistencyCheck)

      const { history, login, patient } = yield select(fullState)

      const { channels, episodes } = history
      const mmClient = Mattermost.create(login.accessToken)
      const silkroad = new Silkroad(
        login.accessToken,
        Config.SILKROAD_DOMAIN,
        undefined,
        serviceHeaders
      )

      const [mmChannels, mmMembers, memberEpisodes] = yield all([
        call(mmClient.getMyChannels, login.messagingTeamId),
        call(mmClient.getMyChannelsMembers, login.messagingTeamId),
        call(silkroad.getMemberEpisodes, patient.profile.id),
      ])
      yield spawn(setIsConnectedIfDisconnected)

      const updatedChannelIds = []
      const postFetches = {}
      const newChannelInfo = {}
      const newMemberInfo = {}
      const channelsToCheck = mmChannels.filter((c) => channels[c.id] && episodes[c.id])
      let i = channelsToCheck.length
      while (i > 0) {
        i--

        const channelInfo = channelsToCheck[i]
        const { id } = channelInfo
        const channel = channels[id]

        // Channels with errors or missing posts
        const forceFetchAllPosts =
          channel.error ||
          channel.missingData ||
          (channel.order && channel.order.length === 0 && !!channel.msgCount)

        // Channels with new posts
        // The msg count diff on the all channels info fetch lets us efficiently see if there's a new post on any channel
        const msgCountDiff = channelInfo.total_msg_count - channel.msgCount
        const hasPostsToFetch = forceFetchAllPosts || msgCountDiff
        if (hasPostsToFetch) {
          postFetches[id] = call(fetchNewPostsRequestHelper, {
            channelId: id,
            forceFetchAllPosts,
            limit: bootstrap && POSTS_FETCH_COUNT_FOR_CHANNEL_INIT,
            channel,
            mmClient,
          })
        }

        // Channels with new member info (used for last_viewed_at)
        const matchingMember = mmMembers.find((member) => member.channel_id == id)
        const hasNewMemberInfo = matchingMember
          ? matchingMember.last_viewed_at !== channel.lastViewedAt
          : false
        if (hasNewMemberInfo) {
          newMemberInfo[id] = matchingMember
        }

        // Channels with new channel info
        const hasNewChannelInfo = channelInfo.update_at !== channel.updateAt
        if (hasPostsToFetch || hasNewMemberInfo || hasNewChannelInfo) {
          newChannelInfo[id] = channelInfo
          updatedChannelIds.push(id)
        }
      }

      const fetchedPosts = yield all(postFetches)

      const updatedChannelSets = updatedChannelIds.map((id) => ({
        channelId: id,
        postData: fetchedPosts[id],
        channelInfo: newChannelInfo[id],
        memberInfo: newMemberInfo[id],
      }))

      yield put(PatientHistoryActions.fetchChannelsSuccess(updatedChannelSets, memberEpisodes))
      yield call(refreshUnreadChannels)

      if (!bootstrap) {
        const newPost = Object.values(fetchedPosts).find(
          (postData) => isValidPostData(postData) && postData.posts[postData.order[0]]
        )
        if (newPost) {
          yield call(handleNewMessage, newPost)
        }
      }
    }
  } catch (error) {
    yield put(LoggerActions.error(`fetchChannels Saga Error: ${error}`))
    yield put(PatientHistoryActions.fetchChannelsFailure(error))
  }
}

export function* handleChannelArchiveEvent(channelId) {
  try {
    const { currentChannelId } = yield select(historyState)
    const curRouteName = Nav.getCurrentRoute()?.name
    const onConversationScene = CONVERSATION_ROUTES.indexOf(curRouteName) !== -1
    if (onConversationScene && currentChannelId === channelId) {
      yield call(Nav.goBack)
      yield delay(500)
      yield call(Toast.show, { text1: i18n.t('Intake.toast.success'), type: 'success' })
    }
  } catch (err) {
    yield put(LoggerActions.error(`handleChannelArchiveEvent Saga Error: ${err}`))
  }
}

export function* broadcastMessageHandler(channelId, post = {}) {
  try {
    const { dialogue_type, state } = post?.props || {}
    if (dialogue_type === 'episode_state_changed') {
      yield put(PatientHistoryActions.updateChannelState(channelId, state))
      if (state === 'archived') {
        yield call(handleChannelArchiveEvent, channelId)
      }
    }
  } catch (err) {
    yield put(LoggerActions.error(`broadcastMessageHandler Saga Error: ${err}`))
  }
}

export function* inAppNotificationHandler(post) {
  try {
    const { inAppNotificationPostId, currentChannelId } = yield select(historyState)
    const curRouteName = Nav.getCurrentRoute()?.name
    const onNotifyingScene = NOTIFICATION_BLACKLIST_ROUTES.indexOf(curRouteName) === -1
    const onConversationScene = CONVERSATION_ROUTES.indexOf(curRouteName) !== -1
    const onConversationOfPost = onConversationScene && post.channel_id === currentChannelId
    const neverNotifiedBefore = inAppNotificationPostId !== post.id
    if (
      onNotifyingScene &&
      !onConversationOfPost &&
      neverNotifiedBefore &&
      isPostWhichTriggersNotifications(post)
    ) {
      yield put(PatientHistoryActions.setInAppNotificationData(post.channel_id, post.id))
    }
  } catch (err) {
    yield put(LoggerActions.error(`inAppNotificationHandler Saga Error: ${err}`))
  }
}

export function* handleNewMessage(post) {
  try {
    const { isActive } = yield select(appSessionState)
    if (isActive) {
      yield spawn(inAppNotificationHandler, post)
      yield spawn(refreshUnreadChannels)
    }
  } catch (err) {
    yield put(LoggerActions.error(`handleNewMessage Saga Error: ${err}`))
  }
}

const mattermostWebsocketChannel = (socket) => {
  const events = ['open', 'message', 'error', 'close', 'channel_viewed']
  return eventChannel((emit) => {
    events.forEach((event) => socket.addListener(event, emit))
    return () => events.forEach((event) => socket.removeListener(event, emit))
  })
}

// TODO: remove instance counter + logs once we're sure we don't have dupe instances
let mattermostWebsocketInstanceCounter = 0
export function* mattermostWebsocket() {
  const instance = mattermostWebsocketInstanceCounter++
  console.log(`mattermostWebsocket[${instance}] started`)

  const login = yield select(loginState)
  const features = yield select(featuresState)
  const mmSocket = MattermostSocket.create(login.accessToken)
  try {
    const channel = mattermostWebsocketChannel(mmSocket)
    while (true) {
      const event = yield take(channel)
      if (event) {
        console.info(`mattermostWebsocket[${instance}] event received `)
        switch (true) {
          case !!event.data:
            if (!features.showTypingIndicator && event.event === 'typing') break
            yield put(PatientHistoryActions.receiveEvent(event))
            break
          case event.type === 'open':
            yield put(PatientHistoryActions.mattermostSocketReady())
            yield spawn(setIsConnectedIfDisconnected)
            break
          case event.type === 'close':
            yield put(PatientHistoryActions.mattermostSocketClose())
            break
          case event.type === 'error':
            yield put(PatientHistoryActions.mattermostSocketError())
            break
          default:
            break
        }
      }
    }
  } catch (err) {
    console.log(err)
    yield put(LoggerActions.error(`mattermostWebsocket Saga Error: ${JSON.stringify(err)}`))
  } finally {
    console.log(`mattermostWebsocket[${instance}] stopped`)
    --mattermostWebsocketInstanceCounter
    if (mmSocket && !mmSocket.destroyed) mmSocket.destroy()
  }
}

// TODO: remove instance counter + logs once we're sure we don't have dupe instances
let mattermostLongPollInstanceCounter = 0
export function* mattermostLongPoll() {
  const instance = mattermostLongPollInstanceCounter++
  console.log(`mattermostLongPoll[${instance}] started`)
  let i = 0
  try {
    while (true) {
      const { history, appSession } = yield select(fullState)
      const { currentChannelId, mmSocketNotReady } = history
      const channel = history.channels && history.channels[currentChannelId]
      const submitting = channel && channel.draft && channel.draft.submitting
      if (!submitting) {
        ++i
        const fastCheck = mmSocketNotReady || !appSession.isConnected
        // Socket on? -> Check all channels every 120s, current channel every 2s.
        // Socket off? -> Check all channels every 30s, current channel every 2s.
        const shouldFetchChannels = i % (fastCheck ? 15 : 60) === 0
        const curRouteName = Nav.getCurrentRoute()?.name
        const shouldFetchCurrentChannel =
          !!curRouteName &&
          currentChannelId &&
          /* istanbul ignore next */
          CURRENT_CHANNEL_POLL_ROUTES.indexOf(curRouteName) !== -1
        if (shouldFetchChannels) {
          yield put(PatientHistoryActions.fetchChannelsRequest())
        } else if (shouldFetchCurrentChannel) {
          yield put(PatientHistoryActions.fetchNewPostsForChannel(currentChannelId, true))
        }
      }
      yield delay(2000)
    }
  } catch (error) {
    yield put(LoggerActions.error(`mattermostLongPoll Saga Error: ${error}`))
  } finally {
    console.log(`mattermostLongPoll[${instance}] stopped`)
    --mattermostLongPollInstanceCounter
  }
}

// Starts all mattermost related activity, waits for backgrounding or logout to stop
export function* mattermostStart() {
  if (yield select(loggedInState)) {
    const bgMattermostWebsocketTask = yield fork(mattermostWebsocket)
    const bgMattermostLongPollTask = yield fork(mattermostLongPoll)

    while (true) {
      const { backgrounded } = yield race({
        logout: take(LoginTypes.LOGOUT),
        backgrounded: take(AppSessionTypes.SET_APP_BACKGROUNDED),
      })

      if (backgrounded) {
        console.log('mattermost stopping in 10s unless we return to foreground')
        const { timeout } = yield race({
          timeout: delay(10000),
          foregrounded: take(AppSessionTypes.SET_APP_FOREGROUNDED),
        })
        if (timeout) break
      } else {
        break
      }
    }

    yield cancel(bgMattermostWebsocketTask)
    yield cancel(bgMattermostLongPollTask)
  }
}

export function* retryPost({ channelId, postId }) {
  const state = yield select(fullState)
  const history = state.history
  const channel = history.channels[channelId]
  const postToRetry = channel.pendingPosts[postId]
  if (postToRetry) {
    const mmClient = Mattermost.create(state.login.accessToken)
    const deviceId = DeviceInfo.getUniqueId()
    let file_ids = []
    if (postToRetry.localFile) {
      try {
        const imageUploadResponse = yield call(
          mmClient.uploadFile,
          channelId,
          buildInitialFilePayload(postToRetry)
        )
        file_ids = [imageUploadResponse.file_infos[0].id]
      } catch (err) {
        Sentry.captureException(err)
        logDdError(err.message, 'PatientHistorySagas.retryPost')
        yield put(PatientHistoryActions.submitPostFailure(channelId, postId, err.message))
        return
      }
    }
    const postProps = postToRetry.props
    const flags = omit(['localId', 'failed'], postProps)
    const response = yield call(
      mmClient.createUserPost,
      channelId,
      postToRetry.message,
      file_ids,
      deviceId,
      postId,
      postProps && postProps.answer,
      flags
    )
    if (response.ok) {
      yield put(PatientHistoryActions.submitPostSuccess(channelId, response.data))
    } else {
      yield put(PatientHistoryActions.submitPostFailure(channelId, postId))

      if (flags.retryCount >= 3) {
        yield put(PatientHistoryActions.setFailedPostError(channelId, true))
      }
    }
  }
}

export function* navigateToLink({ link, context }) {
  try {
    if (NestedNavHelper.getRouteNames().indexOf(link) !== -1) {
      const { link_type, ...linkContext } = context || {}
      switch (link_type) {
        case 'REPLACE':
          yield call(NestedNavHelper.replace, link, linkContext)
          break
        case 'RESET':
          yield call(NestedNavHelper.reset, {
            index: 0,
            routes: [{ name: link, params: linkContext }],
          })
          break
        case 'BACK_ACTION':
          yield call(NestedNavHelper.pop)
          break
        case 'FOCUS':
          yield call(NestedNavHelper.navigate, link, linkContext)
          break
        case 'PUSH':
        default:
          yield call(NestedNavHelper.navigate, link, linkContext)
          break
      }
    } else if (yield call(Linking.canOpenURL, link)) {
      yield call(Linking.openURL, link)
    } else {
      throw Error(`Link cannot be open`, { cause: link })
    }
    yield put(PatientHistoryActions.navigateToLinkSuccess())
  } catch (err) {
    const error = Error('Navigate to external link failed', { cause: err })
    yield put(PatientHistoryActions.navigateToLinkError(error))
    Sentry.captureException(error)
    logDdError(err.message, err.stack)
  }
}

export function* submitPost({ channelId, answer, fileObj, text, timeStamp, flags, payload }) {
  const state = yield select(fullState)
  const mmClient = Mattermost.create(state.login.accessToken)
  const deviceId = DeviceInfo.getUniqueId()

  let file_ids = []
  let message = answer ? answer.text : text
  // Upload any photos as attachments
  if (fileObj) {
    try {
      const fileUploadResponse = yield call(mmClient.uploadFile, channelId, fileObj)
      file_ids = [fileUploadResponse.file_infos[0].id]
      message = 'Image Upload'
    } catch (err) {
      Sentry.captureException(err)
      logDdError(err.message, 'PatientHistorySagas.submitPost')
      yield put(PatientHistoryActions.submitPostFailure(channelId, timeStamp, err.message))
      return
    }
  }

  try {
    if (flags?.selected_choice?.link) {
      yield call(navigateToLink, {
        link: flags?.selected_choice?.link,
        context: flags?.selected_choice?.context,
      })
      return
    }

    const response = yield call(
      mmClient.createUserPost,
      channelId,
      message,
      file_ids,
      deviceId,
      timeStamp,
      answer,
      flags,
      payload
    )

    yield put(PatientHistoryActions.submitPostSuccess(channelId, response.data))
  } catch (err) {
    yield put(PatientHistoryActions.submitPostFailure(channelId, timeStamp))
    Sentry.captureException(err)
    logDdError(err.message, err.stack)
  }
}

export function* refreshUnreadChannels() {
  const { orderedEpisodes, channels } = yield select(historyState)
  let totalUnreadMessages = 0

  const unreadChannels = orderedEpisodes.filter((episodeId) => {
    const channel = channels[episodeId]
    const lastPost = channel.order.length && channel.posts[channel.order[0]]
    const { posts, lastViewedAt } = channel
    const postIds = Object.keys(posts)

    const numUnreadMessages = postIds.filter((postId) => {
      const post = posts[postId]
      return post.create_at > lastViewedAt
    }).length
    totalUnreadMessages += numUnreadMessages
    return channel.lastViewedAt < lastPost.create_at
  })

  const weHaveUnreadChannels = !!unreadChannels.length > 0
  PushNotification.setApplicationIconBadgeNumber(totalUnreadMessages)
  yield put(PatientHistoryActions.setHasUnreadChannels(weHaveUnreadChannels))
}

export function* updateChannelLastViewed({ channelId }) {
  if (channelId) {
    try {
      const state = yield select(fullState)
      if (state.login.messagingTeamId && channelId) {
        const mmClient = Mattermost.create(state.login.accessToken)
        yield call(mmClient.viewMyChannel, channelId)
      }
      yield spawn(refreshUnreadChannels)
    } catch (err) {
      if (__DEV__) console.log(`updateChannelLastViewed Saga Error: ${err}`)
    }
  }
}

export function* resetTypingEventDataAfterDelay() {
  yield delay(2000)
  yield put(PatientHistoryActions.setTypingEventData(null))
}

export function* receiveEvent(action) {
  const eventType = action.event.event
  const channelId = action.event.broadcast?.channel_id
  if (channelId) {
    yield call(handleNewChannel, channelId)
    const post = JSON.parse(action?.event?.data?.post || '{}')
    yield spawn(broadcastMessageHandler, channelId, post)
  }
  switch (eventType) {
    case 'posted':
      try {
        if (!channelId) break // channelId is required for processing the post
        yield put(PatientHistoryActions.setTypingEventData(null))

        const post = JSON.parse(action.event.data.post)

        /*
          TODO: Clean up / document the message types. We currently have:
          1. post.type                -- mattermost post types
          2. post.props.type          -- current dialogue post types
          3. post.props.dialogue_type -- deprecated(?) dialogue post types
        */

        if (!isSelfPost(post)) {
          const login = yield select(loginState)
          const postData = yield call(
            resolveExtraProps,
            {
              order: [post.id],
              posts: {
                [post.id]: post,
              },
            },
            login.accessToken
          )
          yield put(PatientHistoryActions.receivePosts(channelId, postData))

          if (isEncounterPost(post)) {
            yield put(PatientActions.patientProfileFetchRequest())
          }

          yield call(handleNewMessage, post)

          // Another instance of Member App requested to go back on this post id.
          if (post.props?.type === 'action_back' && post.props?.undone_post_id) {
            yield put(PatientHistoryActions.setPostAsUndone(post.props.undone_post_id))
          }
        }

        if (post.type === 'system_header_change') {
          const messageComponents = post.message && post.message.split('to: ')
          const header = messageComponents && messageComponents.length === 2 && messageComponents[1]
          yield put(PatientHistoryActions.updateChannelHeader(channelId, header))
        }

        // Update the episode, each time when assigne changed and only when the episode is open.
        const history = yield select(historyState)
        if (post.type === 'system_add_remove' && history.episode.id) {
          yield put(PatientHistoryActions.fetchOneEpisodeRequest(history.episode.id))
        }

        const { dialogue_type } = post.props || {}
        if (dialogue_type === 'episode_state_changed') {
          yield put(PatientHistoryActions.updateChannelState(channelId, post.props.state))
        }

        if (dialogue_type === 'episode_message_retracted') {
          const postIdToHide = post.props.deeplink && post.props.deeplink.message_id
          yield put(PatientHistoryActions.removePosts(channelId, [postIdToHide]))
        }
      } catch (e) {
        yield put(LoggerActions.error(`Error processing posted message event ${JSON.stringify(e)}`))
      }
      break
    case 'typing':
      yield put(PatientHistoryActions.setTypingEventData(action.event))
      yield put(PatientHistoryActions.resetTypingEventData())
      break
    default:
      break
  }
}

export function* handleNewChannel(channelId) {
  const history = yield select(historyState)
  const episode = history.episodes[channelId]
  const channel = history.channels[channelId]
  if (!episode || isChannelInconsistent(channel)) {
    yield call(channelsConsistencyCheck)
    // TODO: Future optimization -- only fetch the required episode.
    yield call(fetchEpisodes)
    yield call(fetchAllPostsForChannel, { channelId })
  }
}

export function* channelsConsistencyCheck() {
  const history = yield select(historyState)
  const channelIds = Object.keys(history.channels)
  const inconsistentChannelIds = channelIds.filter((id) =>
    isChannelInconsistent(history.channels[id])
  )
  if (inconsistentChannelIds.length > 0) {
    yield put(PatientHistoryActions.resetChannels(inconsistentChannelIds))
  }
}

export function* createEpisode({ ctaId, commandPost, replaceCurrentScene }) {
  const login = yield select(loginState)
  const profile = yield select(patientState)
  const silkroad = new Silkroad(
    login.accessToken,
    Config.SILKROAD_DOMAIN,
    undefined,
    serviceHeaders
  )

  if (replaceCurrentScene) {
    yield call(NestedNavHelper.replace, 'newConversation')
  } else {
    yield call(Nav.navigate, 'newConversation')
  }
  try {
    const locationPermission = yield checkLocationService()
    const response = yield call(silkroad.createEpisode, profile.id)
    const command =
      commandPost ||
      Config.EPISODE_CREATION_COMMANDS[ctaId] ||
      Config.SUPPORTED_CHAT_BOT.default_intent_command
    if (command) {
      const fullscreen_enabled = yield call(
        [ldClient, ldClient.boolVariation],
        'request-fsui-from-bm',
        true
      )

      yield call(
        silkroad.sendCommand,
        response.id,
        addCommandSlots(command, {
          fullscreen_enabled,
        })
      )
    }
    yield put(
      PatientHistoryActions.createEpisodeSuccess(response, ctaId, command, locationPermission)
    )
    yield call(Nav.setParams, { channelId: response.id })
    yield call(fetchNewPostsForChannel, { channelId: response.id })
    // TODO: add update channel call? Can we infer values on create?
  } catch (err) {
    yield put(PatientHistoryActions.createEpisodeFailure(err))
    yield put(LoggerActions.error(`Error creating a new episode: ${JSON.stringify(err)}`))
  }
}

export function* getOrCreateIcbtEpisode({ ctaId, commandPost, replaceCurrentScene }) {
  const login = yield select(loginState)
  const profile = yield select(patientState)
  const er = new EmergencyRoom(login.accessToken, `${Config.EMERGENCY_ROOM_DOMAIN}/v1`)
  const silkroad = new Silkroad(
    login.accessToken,
    Config.SILKROAD_DOMAIN,
    undefined,
    serviceHeaders
  )
  const { eligibleServices } = profile

  if (replaceCurrentScene) {
    yield call(NestedNavHelper.replace, 'newConversation')
  } else {
    yield call(Nav.navigate, 'newConversation')
  }
  try {
    const hasIcbtGuided = Object.prototype.hasOwnProperty.call(eligibleServices, 'icbt_guided')

    if (!hasIcbtGuided) {
      throw new Error('Tried to create an iCBT guided episode without access to the service.')
    }

    const locationPermission = yield checkLocationService()
    const { data: episode, status } = yield call(er.getOrCreateIcbtEpisode)
    const command =
      commandPost ||
      Config.EPISODE_CREATION_COMMANDS[ctaId] ||
      Config.SUPPORTED_CHAT_BOT.default_intent_command
    if (status === 201 && command) {
      const fullscreen_enabled = yield call(
        [ldClient, ldClient.boolVariation],
        'request-fsui-from-bm',
        true
      )
      yield call(
        silkroad.sendCommand,
        episode.id,
        addCommandSlots(command, {
          fullscreen_enabled,
        })
      )
      yield put(
        PatientHistoryActions.createEpisodeSuccess(episode, ctaId, command, locationPermission)
      )
    }
    yield call(Nav.setParams, { channelId: episode.id })
    yield call(fetchNewPostsForChannel, { channelId: episode.id })
  } catch (err) {
    yield put(PatientHistoryActions.createEpisodeFailure(err))
    yield put(LoggerActions.error(`Error creating a new episode: ${JSON.stringify(err)}`))
  }
}

export function* fetchEpisodes() {
  const login = yield select(loginState)
  const profile = yield select(patientState)
  yield put(PatientHistoryActions.fetchEpisodeRequest())

  const silkroad = new Silkroad(
    login.accessToken,
    Config.SILKROAD_DOMAIN,
    undefined,
    serviceHeaders
  )

  try {
    const memberEpisodes = yield call(silkroad.getMemberEpisodes, profile.id)
    yield put(PatientHistoryActions.fetchEpisodeSuccess(memberEpisodes))
  } catch (err) {
    yield put(PatientHistoryActions.fetchEpisodeFailure(err))
  }
}

export function* fetchEpisode({ channelId }) {
  const { accessToken } = yield select(loginState)
  const coredataClient = CoreData.create(accessToken)

  try {
    const response = yield call(coredataClient.getEpisode, channelId)
    yield put(PatientHistoryActions.fetchOneEpisodeSuccess(response))
  } catch (error) {
    yield put(LoggerActions.error(`Error fetch episode: ${JSON.stringify(error)}`))
  }
}

export function* fetchEpisodeMembers({ channelId }) {
  if (!channelId) return
  const { accessToken } = yield select(loginState)
  const mmClient = Mattermost.create(accessToken)

  try {
    const response = yield call(mmClient.getMyChannelMembers, channelId)
    yield put(PatientHistoryActions.fetchEpisodeMembersSuccess(response))
  } catch (error) {
    yield put(LoggerActions.error(`Error fetch episode members: ${JSON.stringify(error)}`))
  }
}

export function* fetchPractitioners() {
  if (!Config.USHER_DOMAIN) return
  const login = yield select(loginState)
  const usher = Usher.create(login)
  yield put(PatientHistoryActions.fetchPractitionersRequest())
  try {
    const data = yield call(usher.getPractitionersInfo)
    yield put(PatientHistoryActions.fetchPractitionersSuccess(data))
  } catch (error) {
    yield put(PatientHistoryActions.fetchPractitionersFailure(error))
  }
}

export function* launchEpisodeCommand({ command, healthIssueTypeId }) {
  const login = yield select(loginState)
  const profile = yield select(patientState)
  const silkroad = new Silkroad(
    login.accessToken,
    Config.SILKROAD_DOMAIN,
    undefined,
    serviceHeaders
  )
  const features = yield select(featuresState)

  yield call(Nav.navigate, 'newConversation')
  try {
    const locationPermission = yield checkLocationService()
    let response = {}
    if (features.defaultHealthIssueTypeId) {
      response = yield call(silkroad.createEpisode, profile.id, {
        health_issue_type_id: healthIssueTypeId,
      })
    } else {
      response = yield call(silkroad.createEpisode, profile.id)
    }

    yield call(
      silkroad.sendCommand,
      response.id,
      addCommandSlots(command, { fullscreen_enabled: true })
    )

    yield put(
      PatientHistoryActions.createEpisodeSuccess(response, '123', command, locationPermission)
    )
    yield call(Nav.setParams, { channelId: response.id })
    yield call(fetchNewPostsForChannel, { channelId: response.id })
  } catch (err) {
    yield put(PatientHistoryActions.createEpisodeFailure(err))
    yield put(LoggerActions.error(`Error creating a new episode: ${JSON.stringify(err)}`))
  }
}

export function* getOrCreateWbiEpisode({ ctaId }) {
  try {
    const command = Config.EPISODE_CREATION_COMMANDS.wbiAssessment
    if (!command) {
      throw Error('Config.EPISODE_CREATION_COMMANDS.wbiAssessment required')
    }

    const login = yield select(loginState)
    const er = new EmergencyRoom(login.accessToken, `${Config.EMERGENCY_ROOM_DOMAIN}/v1`)
    const silkroad = new Silkroad(
      login.accessToken,
      Config.SILKROAD_DOMAIN,
      undefined,
      serviceHeaders
    )

    const hasLocationPermission = yield checkLocationService()

    const { data: episode, status } = yield call(er.getOrCreateWbiEpisode)
    if (![200, 201].includes(status)) {
      throw Error('get or create wbi episode failed', { cause: [status, episode] })
    }

    yield call(
      silkroad.sendCommand,
      episode.id,
      addCommandSlots(command, { fullscreen_enabled: true })
    )

    yield put(
      PatientHistoryActions.createEpisodeSuccess(
        episode,
        ctaId || 'not provided',
        command,
        hasLocationPermission
      )
    )

    yield call(fetchNewPostsForChannel, { channelId: episode.id })
    yield put(PatientHistoryActions.resetChannels([episode.id]))
    yield call(Nav.navigate, 'newConversation', {
      exitAlert: i18n.t('WellBeingIndexScreen.intakeExitAlert', { returnObjects: true }),
      channelId: episode.id,
    })
  } catch (err) {
    yield put(PatientHistoryActions.createEpisodeFailure(err))
    Sentry.captureException(err)
    logDdError(err.message, err.stack)
    yield put(LoggerActions.error(err.message))
    Toast.show({ text1: i18n.t(`WellBeingIndexScreen.takeAssessmentError`) })
  }
}

export function* fetchAppointments() {
  const history = yield select(historyState)
  const episodes = history?.orderedEpisodes
  if (!episodes || episodes.length === 0) return

  yield put(PatientHistoryActions.fetchAppointmentsRequest())

  const login = yield select(loginState)
  const patient = yield select(patientState)
  const family = yield select(familyState)
  const silkroad = new Silkroad(
    login.accessToken,
    Config.SILKROAD_DOMAIN,
    undefined,
    serviceHeaders
  )

  try {
    const response = yield call(silkroad.getFamilyAppointments, patient.id)
    const data = response?.data || []
    const appointments = normalizeAppointments(data, family)

    const bios = yield all(
      appointments.upcoming.map((appointment) =>
        (function* () {
          if (!appointment?.practitioner?.id) {
            return null
          }
          try {
            return yield call(getBio, appointment.practitioner.id)
          } catch (e) {
            return null
          }
        })()
      )
    )

    appointments.upcoming = appointments.upcoming.map((appointment, i) => {
      const bio = bios[i]
      return {
        ...appointment,
        practitioner: {
          ...appointment?.practitioner,
          job_title: bio?.job_title,
          picture: bio?.profile_picture?.url,
        },
      }
    })

    yield put(PatientHistoryActions.fetchAppointmentsSuccess(appointments))
  } catch (error) {
    yield put(PatientHistoryActions.fetchAppointmentsFailure(error))
  }
}

/**
 * Retrieves the current navigation routes from Nav state.
 *
 * @returns {Array<Object>} An array of route objects, or an empty array if none.
 */
export function getCurrentRoutes() {
  if (Nav.isReady()) {
    return Nav?.getState()?.routes || []
  }
  return []
}

/**
 * Creates a navigation state for resetting to a conversation screen
 *
 * - Removes 'upcomingAppointment', 'conversation' or 'newConversation'screens from navigation history
 * - Adds a new conversation screen at the end of the stack
 * - Sets the active route index to point to this conversation screen
 *
 * This ensures the conversation screen is always the last screen in the navigation stack,
 * while preserving the rest of the navigation history.
 *
 * @param {string} channelId - Episode/channel ID to pass to the conversation screen as params
 * @param {Array<Object>} routes - The current array of navigation routes
 * @returns {Object} The navigation state object with the updated routes and active route
 */
export function buildConversationState(channelId, routes) {
  const filteredRoutes = routes.filter(
    (route) =>
      route?.name !== 'upcomingAppointment' &&
      route?.name !== 'conversation' &&
      route?.name !== 'newConversation'
  )

  // Add the conversation screen at the end
  filteredRoutes.push({
    name: 'conversation',
    params: { channelId },
  })

  return {
    routes: filteredRoutes,
    index: filteredRoutes.length - 1,
  }
}

/**
 * Composes a navigation state for resetting to a conversation screen.
 *
 * @param {string} channelId - Episode/channel ID for conversation params.
 * @returns {Object} Navigation state with routes array and active route index.
 */
export function buildConversationNavigationState(channelId) {
  const routes = getCurrentRoutes()
  return buildConversationState(channelId, routes)
}

export function* rescheduleAppointment({ appointment }) {
  const login = yield select(loginState)
  const silkroad = new Silkroad(
    login.accessToken,
    Config.SILKROAD_DOMAIN,
    undefined,
    serviceHeaders
  )
  try {
    const command = addCommandSlots('/r reschedule_appt', {
      appointment_id: appointment.tk_appointment.id,
      provider_id: appointment.tk_appointment.provider_id,
      booking_appointment_type: appointment.appointment_type,
      // Slots below are deprecated, remove 7 days after BM supports the new slots above.
      appointment_id_for_status_update: appointment.id,
      input_health_practitioner_id: appointment.practitioner_emr_id,
    })
    yield call(silkroad.sendCommand, appointment.episode_id, command)

    const navigationState = buildConversationNavigationState(appointment.episode_id)
    yield call(Nav.reset, navigationState)

    yield put(PatientHistoryActions.rescheduleAppointmentSuccess())
  } catch (err) {
    yield put(PatientHistoryActions.rescheduleAppointmentFailure(err))
  }
}

export function* cancelAppointmentFromCalendar({ appointment }) {
  try {
    yield call(updateAppointment, {
      appointment_id: appointment.id,
      appointment: {
        status: 'cancelled',
      },
    })
    yield put(PatientHistoryActions.cancelAppointmentFromCalendarSuccess())
  } catch (err) {
    yield put(LoggerActions.error(`Error updating episode: ${JSON.stringify(err)}`))
    yield put(PatientHistoryActions.cancelAppointmentFromCalendarFailure(err))
    yield delay(600)
    Toast.show({
      text1: i18n.t('AppointmentBooking.cancelAppointmentSheet.error'),
      type: 'error',
    })
  }
}

export function* cancelAppointment({ appointment }) {
  const login = yield select(loginState)
  const silkroad = new Silkroad(
    login.accessToken,
    Config.SILKROAD_DOMAIN,
    undefined,
    serviceHeaders
  )
  const features = yield select(featuresState)
  const canConfirmCancelAppointment = features.confirmCancelAppointment
  const navigationState = buildConversationNavigationState(appointment.episode_id)

  try {
    if (canConfirmCancelAppointment) {
      const response = yield call(updateAppointment, {
        appointment_id: appointment.tk_appointment.id,
        appointment: {
          status: 'cancelled',
        },
      })

      if (response?.error) {
        const command = addCommandSlots('/r failed_cancel_appointment')

        yield call(silkroad.sendCommand, appointment.episode_id, command)
        yield call(Nav.reset, navigationState)
        throw Error('Failed to cancel appointment')
      }
    }

    const rasaCommand = canConfirmCancelAppointment
      ? '/r set_cancellation_reason_only'
      : '/r cancel_appointment'

    const command = addCommandSlots(rasaCommand, {
      appointment_id: appointment.tk_appointment.id,
      // Slot below is deprecated, remove 7 days after BM supports the new slot above.
      appointment_id_for_status_update: appointment.id,
    })

    yield call(silkroad.sendCommand, appointment.episode_id, command)

    yield put(PatientHistoryActions.cancelAppointmentSuccess())

    // We should refresh appointments after cancellation since other components may depend on it
    yield put(PatientHistoryActions.refreshAppointmentsRequest())

    // This delay is workaround fix to allow the Modal to properly dismount before navigating, otherwise it crashes on iOS
    // source: https://github.com/react-navigation/react-navigation/issues/11270
    yield delay(500)

    yield call(Nav.reset, navigationState)

    if (canConfirmCancelAppointment) {
      sprigClient.track('CANCEL_APPOINTMENT_SUCCESS', {
        episode_id: appointment.episode_id,
      })
    }
  } catch (err) {
    yield put(PatientHistoryActions.cancelAppointmentFailure(err))
  }
}

export function* fetchPractitionerBio({ userAppId, profileId }) {
  yield put(PatientHistoryActions.fetchPractitionerBioRequest())
  try {
    const practitionerBio = yield call(getBio, userAppId, undefined, profileId)
    yield put(PatientHistoryActions.fetchPractitionerBioSuccess(camelizeKeys(practitionerBio)))
  } catch (error) {
    yield put(PatientHistoryActions.fetchPractitionerBioFailure(error))
  }
}

export function* getOrCreateHealthProfileEpisode({ ctaId }) {
  try {
    const command = Config.EPISODE_CREATION_COMMANDS.healthProfile
    if (!command) {
      throw Error('Config.EPISODE_CREATION_COMMANDS.healthProfile required')
    }

    const login = yield select(loginState)
    const er = new EmergencyRoom(login.accessToken, `${Config.EMERGENCY_ROOM_DOMAIN}/v1`)
    const silkroad = new Silkroad(
      login.accessToken,
      Config.SILKROAD_DOMAIN,
      undefined,
      serviceHeaders
    )

    const hasLocationPermission = yield checkLocationService()

    const { data: episode, status } = yield call(er.getOrCreateHealthProfileEpisode)
    if (![200, 201].includes(status)) {
      throw Error('get or create health profile episode failed', { cause: [status, episode] })
    }

    yield call(
      silkroad.sendCommand,
      episode.id,
      addCommandSlots(command, {
        fullscreen_enabled: true,
        exit_to_link: ctaId === 'onboarding' ? 'commitment' : 'profile',
      })
    )

    yield put(
      PatientHistoryActions.createEpisodeSuccess(
        episode,
        ctaId || 'not provided',
        command,
        hasLocationPermission
      )
    )

    yield call(fetchNewPostsForChannel, { channelId: episode.id })
    yield put(PatientHistoryActions.resetChannels([episode.id]))
    yield call(Nav.setParams, { channelId: episode.id })
    return episode
  } catch (err) {
    yield put(PatientHistoryActions.createEpisodeFailure(err))
    Sentry.captureException(err)
    logDdError(err.message, err.stack)
    yield put(LoggerActions.error(err.message))
  }
}

export function* archiveEpisode({ channelId, sprigSurveyData }) {
  const { accessToken } = yield select(loginState)
  const coredataClient = CoreData.create(accessToken)
  try {
    yield call(coredataClient.updateEpisodeState, channelId, 'archived')
    yield call(fetchEpisodes)
    yield put(PatientHistoryActions.archiveEpisodeSuccess())

    yield call(Nav.goBack)
    yield delay(500)
    Toast.show({ text1: i18n.t('Intake.toast.success'), type: 'success' })

    if (sprigSurveyData) {
      sprigClient.track(sprigSurveyData.trackName, sprigSurveyData.eventProperties)
    }
  } catch (error) {
    yield put(PatientHistoryActions.archiveEpisodeError(error))
    yield put(LoggerActions.error(`Error updating episode: ${JSON.stringify(error)}`))

    yield call(Nav.goBack)
    yield delay(500)
    Toast.show({ text1: i18n.t('Intake.toast.error'), type: 'error' })
  }
}

export function* goBackInEpisode({ channelId, postId }) {
  try {
    if (channelId) {
      const login = yield select(loginState)
      const mmClient = Mattermost.create(login.accessToken)
      const deviceId = DeviceInfo.getUniqueId()

      const response = yield call(
        mmClient.createUserPost,
        channelId,
        i18n.t('Intake.backButton.chatMessage'),
        [],
        deviceId,
        Date.now(),
        null,
        {
          type: 'action_back',
          version: '1',
          provider_app_visible: true,
          member_app_visible: true,
          undone_post_id: postId,
        }
      )

      if (response.ok) {
        yield put(PatientHistoryActions.goBackInEpisodeSuccess(channelId, postId))
      } else {
        throw Error('goBackInEpisode createUserPost failure', {
          cause: { status: response.status, channelId, postId },
        })
      }
    }
  } catch (error) {
    yield put(PatientHistoryActions.goBackInEpisodeError(channelId, postId, error))
    yield put(LoggerActions.error(`Error going back in episode: ${JSON.stringify(error)}`))
  }
}
