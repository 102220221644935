import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  ensureNotificationParity: [],
  getCategories: [],
  getCategoriesSuccess: ['categories'],
  getCategoriesFailure: [],

  getTopics: ['id'],
  getTopicsSuccess: ['topics'],
  getTopicsFailure: [],

  getSingleHabit: ['id'],
  getSingleHabitSuccess: ['habit'],
  getSingleHabitFailure: [],

  getSingleHabitStats: ['id'],
  getSingleHabitStatsSuccess: ['id', 'stats'],
  getSingleHabitStatsFailure: ['id'],

  getSingleHabitProgress: ['id', 'fromDate'],
  getSingleHabitProgressSuccess: ['id', 'progress'],
  getSingleHabitProgressFailure: ['id'],

  getHabitList: ['date'],
  getHabitListSuccess: ['habits', 'progressStatsByHabit'],
  getHabitListFailure: [],

  getHabitContent: ['id'],
  getHabitContentSuccess: ['id', 'habit'],
  getHabitContentFailure: ['id'],

  startHabit: ['id', 'hasSupportingContent'],
  startHabitSuccess: ['id', 'deferredActions'],
  startHabitFailure: [],

  resetStartHabit: [],

  setHabitListDeferredActions: ['refresh', 'habitDeferredActions'],
  resetHabitListDeferredActions: [],

  removeHabit: ['id', 'date'],
  removeHabitSuccess: [],
  removeHabitFailure: [],
  resetRemoveHabit: [],

  doneHabit: ['id', 'date'],
  doneHabitSuccess: [],
  doneHabitFailure: [],

  resetDoneHabit: [],

  undoneHabit: ['id', 'date'],
  undoneHabitSuccess: [],
  undoneHabitFailure: [],

  resetUndoneHabit: [],

  getDailyProgressList: ['fromDate', 'toDate'],
  getDailyProgressListSuccess: ['dailyProgressList'],
  getDailyProgressListFailure: [],

  updateHabitPrompt: ['id', 'prompt', 'date'],
  updateHabitPromptSuccess: [],
  updateHabitPromptFailure: [],

  resetUpdateHabitPrompt: [],

  removeHabitPrompt: ['id', 'prompt', 'date'],
  removeHabitPromptSuccess: [],
  removeHabitPromptFailure: [],

  resetRemoveHabitPrompt: [],

  cancelAllHabitLocalNotifications: [],

  getAllNotificationConfigurations: [],
  getAllNotificationConfigurationsSuccess: ['notifications'],
  getAllNotificationConfigurationsFailure: [],

  getNotificationConfigurations: ['habitId'],
  getNotificationConfigurationsSuccess: ['habitId', 'notifications'],
  getNotificationConfigurationsFailure: ['habitId'],

  updateNotificationConfiguration: ['habitId', 'notificationType', 'values'],
  updateNotificationConfigurationSuccess: ['habitId', 'notification'],
  updateNotificationConfigurationFailure: [],

  resetUpdateNotificationConfiguration: [],
})

export const HabitTypes = Types
export default Creators

const HABIT_STATS_INITIAL_STATE = {
  data: undefined,
  loading: true,
  retry: false,
}
const HABIT_PROGRESS_INITIAL_STATE = {
  data: undefined,
  loading: true,
  retry: false,
}
export const INITIAL_STATE = Immutable({
  categories: {
    loading: true,
    retry: false,
    data: [],
  },
  topics: {
    loading: true,
    retry: false,
    data: [],
  },
  habit: {
    loading: true,
    retry: false,
    data: {},
  },
  habitStats: {},
  habitProgress: {},
  habits: {
    loading: true,
    retry: false,
    data: [],
    progressStats: {},
  },
  habitContent: {},
  startHabit: {
    loading: false,
    retry: false,
    started: false,
  },
  habitListDeferredActions: null,
  removeHabit: {
    loading: false,
    retry: false,
    removed: false,
  },
  doneHabit: {
    loading: false,
    retry: false,
    id: null,
  },
  undoneHabit: {
    loading: false,
    retry: false,
    id: null,
  },
  dailyProgress: {
    loading: false,
    retry: false,
    data: [],
  },
  updateHabitPrompt: {
    loading: false,
    retry: false,
    updated: false,
    promptId: null,
  },
  removeHabitPrompt: {
    loading: false,
    retry: false,
    removed: false,
    promptId: null,
  },
  allNotificationConfigurations: {
    loading: false,
    retry: false,
    notifications: [],
  },
  notificationConfigurations: {},
  updateNotificationConfiguration: {
    habitId: undefined,
    type: undefined,
    loading: false,
    retry: false,
    updated: false,
  },
})

/* ------------- Reducers ------------- */

export const getCategories = (state) =>
  state.merge({
    categories: {
      loading: true,
      retry: false,
      data: [],
    },
  })

export const getCategoriesSuccess = (state, { categories }) =>
  state.merge({
    categories: {
      loading: false,
      retry: false,
      data: categories,
    },
  })

export const getCategoriesFailure = (state) =>
  state.merge({
    categories: {
      loading: false,
      retry: true,
    },
  })

export const getTopics = (state) =>
  state.merge({
    topics: {
      loading: true,
      retry: false,
      data: [],
    },
  })

export const getTopicsSuccess = (state, { topics }) =>
  state.merge({
    topics: {
      loading: false,
      retry: false,
      data: topics,
    },
  })

export const getTopicsFailure = (state) =>
  state.merge({
    topics: {
      loading: false,
      retry: true,
    },
  })

export const getSingleHabit = (state) =>
  state.merge({
    habit: {
      loading: true,
      retry: false,
      data: {},
    },
  })

export const getSingleHabitSuccess = (state, { habit }) =>
  state.merge({
    habit: {
      loading: false,
      retry: false,
      data: habit,
    },
  })

export const getSingleHabitFailure = (state) =>
  state.merge({
    habit: {
      loading: false,
      retry: true,
    },
  })

export const getSingleHabitStats = (state, { id }) =>
  state.merge(
    {
      habitStats: {
        [id]: HABIT_STATS_INITIAL_STATE,
      },
    },
    { deep: true }
  )

export const getSingleHabitStatsSuccess = (state, { id, stats }) =>
  state.merge(
    {
      habitStats: {
        [id]: {
          loading: false,
          retry: false,
          data: stats,
        },
      },
    },
    { deep: true }
  )

export const getSingleHabitStatsFailure = (state, { id }) =>
  state.merge(
    {
      habitStats: {
        [id]: {
          loading: false,
          retry: true,
        },
      },
    },
    { deep: true }
  )

export const getSingleHabitProgress = (state, { id }) =>
  state.merge(
    {
      habitProgress: {
        [id]: HABIT_PROGRESS_INITIAL_STATE,
      },
    },
    { deep: true }
  )

export const getSingleHabitProgressSuccess = (state, { id, progress }) =>
  state.merge(
    {
      habitProgress: {
        [id]: {
          loading: false,
          retry: false,
          data: progress,
        },
      },
    },
    { deep: true }
  )

export const getSingleHabitProgressFailure = (state, { id }) =>
  state.merge(
    {
      habitProgress: {
        [id]: {
          loading: false,
          retry: true,
        },
      },
    },
    { deep: true }
  )

export const getHabitList = (state) =>
  state.merge({
    habits: {
      loading: true,
      retry: false,
      data: state.habits.data,
      progressStats: state.habits.progressStats,
    },
  })

export const getHabitListSuccess = (state, { habits, progressStatsByHabit }) => {
  return state.merge({
    habits: {
      loading: false,
      retry: false,
      data: habits,
      progressStats: progressStatsByHabit,
    },
  })
}

export const getHabitListFailure = (state) =>
  state.merge({
    habits: {
      loading: false,
      retry: true,
      data: state.habits.data,
      progressStats: state.habits.progressStats,
    },
  })

export const getHabitContent = (state, { id }) => {
  if (!id) {
    return state
  }

  return state.merge(
    {
      habitContent: {
        [id]: {
          loading: true,
          retry: false,
          data: {},
        },
      },
    },
    { deep: true }
  )
}

export const getHabitContentSuccess = (state, { id, habit }) => {
  if (!id) {
    return state
  }

  return state.merge(
    {
      habitContent: {
        [id]: {
          loading: false,
          retry: false,
          data: habit,
        },
      },
    },
    { deep: true }
  )
}

export const getHabitContentFailure = (state, { id }) => {
  if (!id) {
    return state
  }

  return state.merge(
    {
      habitContent: {
        [id]: {
          loading: false,
          retry: true,
          data: {},
        },
      },
    },
    { deep: true }
  )
}

export const startHabit = (state) =>
  state.merge({
    startHabit: {
      loading: true,
      retry: false,
      started: false,
    },
    habitListDeferredActions: null,
  })

export const startHabitSuccess = (state, { id, deferredActions }) =>
  state.merge({
    startHabit: {
      loading: false,
      retry: false,
      started: true,
    },
    habitListDeferredActions: {
      refresh: true,
      habit: {
        id,
        ...deferredActions,
      },
    },
  })

export const startHabitFailure = (state) =>
  state.merge({
    startHabit: {
      loading: false,
      retry: true,
      started: false,
    },
    habitListDeferredActions: null,
  })

export const resetStartHabit = (state) =>
  state.merge({
    startHabit: INITIAL_STATE.startHabit,
  })

export const setHabitListDeferredActions = (state, { refresh, habitDeferredActions }) =>
  state.merge({
    habitListDeferredActions: {
      refresh,
      habit: habitDeferredActions,
    },
  })

export const resetHabitListDeferredActions = (state) =>
  state.merge({
    habitListDeferredActions: null,
  })

export const removeHabit = (state) =>
  state.merge({
    removeHabit: {
      loading: true,
      retry: false,
      removed: false,
    },
  })

export const removeHabitSuccess = (state) =>
  state.merge({
    removeHabit: {
      loading: false,
      retry: false,
      removed: true,
    },
  })

export const removeHabitFailure = (state) =>
  state.merge({
    removeHabit: {
      loading: false,
      retry: true,
      removed: false,
    },
  })

export const resetRemoveHabit = (state) =>
  state.merge({
    removeHabit: INITIAL_STATE.removeHabit,
  })

export const doneHabit = (state, { id }) =>
  state.merge({
    doneHabit: {
      loading: true,
      retry: false,
      id,
    },
  })

export const doneHabitSuccess = (state) =>
  state.merge({
    doneHabit: {
      loading: false,
      retry: false,
      id: state.doneHabit.id,
    },
  })

export const doneHabitFailure = (state) =>
  state.merge({
    doneHabit: {
      loading: false,
      retry: true,
      id: null,
    },
  })

export const resetDoneHabit = (state) =>
  state.merge({
    doneHabit: INITIAL_STATE.doneHabit,
  })

export const undoneHabit = (state, { id }) =>
  state.merge({
    undoneHabit: {
      loading: true,
      retry: false,
      id,
    },
  })

export const undoneHabitSuccess = (state) =>
  state.merge({
    undoneHabit: {
      loading: false,
      retry: false,
      id: null,
    },
  })

export const undoneHabitFailure = (state) =>
  state.merge({
    undoneHabit: {
      loading: false,
      retry: true,
      id: null,
    },
  })

export const resetUndoneHabit = (state) =>
  state.merge({
    undoneHabit: INITIAL_STATE.undoneHabit,
  })

export const getDailyProgressList = (state) =>
  state.merge({
    dailyProgress: {
      loading: true,
      retry: false,
      data: state.dailyProgress.data,
    },
  })

export const getDailyProgressListSuccess = (state, { dailyProgressList }) =>
  state.merge({
    dailyProgress: {
      loading: false,
      retry: false,
      data: dailyProgressList,
    },
  })

export const getDailyProgressListFailure = (state) =>
  state.merge({
    dailyProgress: {
      loading: false,
      retry: true,
      data: state.dailyProgress.data,
    },
  })

export const updateHabitPrompt = (state, { prompt }) =>
  state.merge({
    updateHabitPrompt: {
      loading: true,
      retry: false,
      updated: false,
      promptId: prompt.identifier,
    },
  })

export const updateHabitPromptSuccess = (state) =>
  state.merge({
    updateHabitPrompt: {
      loading: false,
      retry: false,
      updated: true,
      promptId: null,
    },
  })

export const updateHabitPromptFailure = (state) =>
  state.merge({
    updateHabitPrompt: {
      loading: false,
      retry: true,
      updated: false,
      promptId: null,
    },
  })

export const resetUpdateHabitPrompt = (state) =>
  state.merge({
    updateHabitPrompt: INITIAL_STATE.updateHabitPrompt,
  })

export const removeHabitPrompt = (state, { prompt }) =>
  state.merge({
    removeHabitPrompt: {
      loading: true,
      retry: false,
      removed: false,
      promptId: prompt.identifier,
    },
  })

export const removeHabitPromptSuccess = (state) =>
  state.merge({
    removeHabitPrompt: {
      loading: false,
      retry: false,
      removed: true,
      promptId: null,
    },
  })

export const removeHabitPromptFailure = (state) =>
  state.merge({
    removeHabitPrompt: {
      loading: false,
      retry: true,
      removed: false,
      promptId: null,
    },
  })

export const resetRemoveHabitPrompt = (state) =>
  state.merge({
    removeHabitPrompt: INITIAL_STATE.removeHabitPrompt,
  })

export const getAllNotificationConfigurations = (state) =>
  state.merge({
    allNotificationConfigurations: {
      loading: true,
      retry: false,
      notifications: [],
    },
  })

export const getAllNotificationConfigurationsSuccess = (state, { notifications }) =>
  state.merge({
    allNotificationConfigurations: {
      loading: false,
      retry: false,
      notifications,
    },
  })

export const getAllNotificationConfigurationsFailure = (state) =>
  state.merge({
    allNotificationConfigurations: {
      loading: false,
      retry: true,
      notifications: [],
    },
  })

export const getNotificationConfigurations = (state, { habitId }) =>
  state.merge(
    {
      notificationConfigurations: {
        [habitId]: {
          data: undefined,
          loading: true,
          retry: false,
        },
      },
    },
    { deep: true }
  )

export const getNotificationConfigurationsSuccess = (state, { habitId, notifications }) =>
  state.merge(
    {
      notificationConfigurations: {
        [habitId]: {
          data: notifications,
          loading: false,
          retry: false,
        },
      },
    },
    { deep: true }
  )

export const getNotificationConfigurationsFailure = (state, { habitId }) =>
  state.merge(
    {
      notificationConfigurations: {
        [habitId]: {
          loading: false,
          retry: true,
        },
      },
    },
    { deep: true }
  )

export const updateNotificationConfiguration = (state, { habitId, notificationType }) =>
  state.merge({
    updateNotificationConfiguration: {
      habitId,
      type: notificationType,
      loading: true,
      retry: false,
      updated: false,
    },
  })

export const updateNotificationConfigurationSuccess = (state, { habitId, notification }) => {
  const prevNotifications = state.notificationConfigurations[habitId]?.data
  let nextNotifications = prevNotifications

  const indexToUpdate = (nextNotifications || []).findIndex((el) => el.type === notification.type)
  if (indexToUpdate !== -1) {
    nextNotifications = [
      ...nextNotifications.slice(0, indexToUpdate),
      notification,
      ...nextNotifications.slice(indexToUpdate + 1),
    ]
  }

  return state.merge(
    {
      updateNotificationConfiguration: {
        loading: false,
        retry: false,
        updated: true,
      },
      notificationConfigurations: {
        [habitId]: {
          data: nextNotifications,
        },
      },
    },
    { deep: true }
  )
}

export const updateNotificationConfigurationFailure = (state) =>
  state.merge({
    updateNotificationConfiguration: {
      habitId: undefined,
      type: undefined,
      loading: false,
      retry: true,
      updated: false,
    },
  })

export const resetUpdateNotificationConfiguration = (state) =>
  state.merge({
    updateNotificationConfiguration: INITIAL_STATE.updateNotificationConfiguration,
  })
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_CATEGORIES]: getCategories,
  [Types.GET_CATEGORIES_SUCCESS]: getCategoriesSuccess,
  [Types.GET_CATEGORIES_FAILURE]: getCategoriesFailure,

  [Types.GET_TOPICS]: getTopics,
  [Types.GET_TOPICS_SUCCESS]: getTopicsSuccess,
  [Types.GET_TOPICS_FAILURE]: getTopicsFailure,

  [Types.GET_SINGLE_HABIT]: getSingleHabit,
  [Types.GET_SINGLE_HABIT_SUCCESS]: getSingleHabitSuccess,
  [Types.GET_SINGLE_HABIT_FAILURE]: getSingleHabitFailure,

  [Types.GET_SINGLE_HABIT_STATS]: getSingleHabitStats,
  [Types.GET_SINGLE_HABIT_STATS_SUCCESS]: getSingleHabitStatsSuccess,
  [Types.GET_SINGLE_HABIT_STATS_FAILURE]: getSingleHabitStatsFailure,

  [Types.GET_SINGLE_HABIT_PROGRESS]: getSingleHabitProgress,
  [Types.GET_SINGLE_HABIT_PROGRESS_SUCCESS]: getSingleHabitProgressSuccess,
  [Types.GET_SINGLE_HABIT_PROGRESS_FAILURE]: getSingleHabitProgressFailure,

  [Types.GET_HABIT_LIST]: getHabitList,
  [Types.GET_HABIT_LIST_SUCCESS]: getHabitListSuccess,
  [Types.GET_HABIT_LIST_FAILURE]: getHabitListFailure,

  [Types.GET_HABIT_CONTENT]: getHabitContent,
  [Types.GET_HABIT_CONTENT_SUCCESS]: getHabitContentSuccess,
  [Types.GET_HABIT_CONTENT_FAILURE]: getHabitContentFailure,

  [Types.START_HABIT]: startHabit,
  [Types.START_HABIT_SUCCESS]: startHabitSuccess,
  [Types.START_HABIT_FAILURE]: startHabitFailure,

  [Types.RESET_START_HABIT]: resetStartHabit,

  [Types.SET_HABIT_LIST_DEFERRED_ACTIONS]: setHabitListDeferredActions,
  [Types.RESET_HABIT_LIST_DEFERRED_ACTIONS]: resetHabitListDeferredActions,

  [Types.REMOVE_HABIT]: removeHabit,
  [Types.REMOVE_HABIT_SUCCESS]: removeHabitSuccess,
  [Types.REMOVE_HABIT_FAILURE]: removeHabitFailure,
  [Types.RESET_REMOVE_HABIT]: resetRemoveHabit,

  [Types.DONE_HABIT]: doneHabit,
  [Types.DONE_HABIT_SUCCESS]: doneHabitSuccess,
  [Types.DONE_HABIT_FAILURE]: doneHabitFailure,

  [Types.RESET_DONE_HABIT]: resetDoneHabit,

  [Types.UNDONE_HABIT]: undoneHabit,
  [Types.UNDONE_HABIT_SUCCESS]: undoneHabitSuccess,
  [Types.UNDONE_HABIT_FAILURE]: undoneHabitFailure,
  [Types.RESET_UNDONE_HABIT]: resetUndoneHabit,

  [Types.GET_DAILY_PROGRESS_LIST]: getDailyProgressList,
  [Types.GET_DAILY_PROGRESS_LIST_SUCCESS]: getDailyProgressListSuccess,
  [Types.GET_DAILY_PROGRESS_LIST_FAILURE]: getDailyProgressListFailure,

  [Types.UPDATE_HABIT_PROMPT]: updateHabitPrompt,
  [Types.UPDATE_HABIT_PROMPT_SUCCESS]: updateHabitPromptSuccess,
  [Types.UPDATE_HABIT_PROMPT_FAILURE]: updateHabitPromptFailure,
  [Types.RESET_UPDATE_HABIT_PROMPT]: resetUpdateHabitPrompt,

  [Types.REMOVE_HABIT_PROMPT]: removeHabitPrompt,
  [Types.REMOVE_HABIT_PROMPT_SUCCESS]: removeHabitPromptSuccess,
  [Types.REMOVE_HABIT_PROMPT_FAILURE]: removeHabitPromptFailure,
  [Types.RESET_REMOVE_HABIT_PROMPT]: resetRemoveHabitPrompt,

  [Types.GET_ALL_NOTIFICATION_CONFIGURATIONS]: getAllNotificationConfigurations,
  [Types.GET_ALL_NOTIFICATION_CONFIGURATIONS_SUCCESS]: getAllNotificationConfigurationsSuccess,
  [Types.GET_ALL_NOTIFICATION_CONFIGURATIONS_FAILURE]: getAllNotificationConfigurationsFailure,

  [Types.GET_NOTIFICATION_CONFIGURATIONS]: getNotificationConfigurations,
  [Types.GET_NOTIFICATION_CONFIGURATIONS_SUCCESS]: getNotificationConfigurationsSuccess,
  [Types.GET_NOTIFICATION_CONFIGURATIONS_FAILURE]: getNotificationConfigurationsFailure,

  [Types.UPDATE_NOTIFICATION_CONFIGURATION]: updateNotificationConfiguration,
  [Types.UPDATE_NOTIFICATION_CONFIGURATION_SUCCESS]: updateNotificationConfigurationSuccess,
  [Types.UPDATE_NOTIFICATION_CONFIGURATION_FAILURE]: updateNotificationConfigurationFailure,
  [Types.RESET_UPDATE_NOTIFICATION_CONFIGURATION]: resetUpdateNotificationConfiguration,
})
