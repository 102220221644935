import { StyleSheet } from 'react-native'
import { ApplicationStyles, Metrics, Fonts, Colors } from 'APP/Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  container: {
    padding: Metrics.baseMargin,
  },
  illustration: {
    alignSelf: 'center',
    alignItems: 'center',
    marginTop: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin,
  },
  primaryText: {
    ...Fonts.style.body,
    color: Colors.text,
    padding: Metrics.baseMargin,
    textAlign: 'center',
  },
})
