import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useDevTools } from 'APP/Lib/DevTools'
import IntakeScreen from 'APP/Containers/IntakeScreen'
import LoadingScreen from 'APP/Containers/LoadingScreen'
import PatientActions from 'APP/Redux/PatientRedux'

const HealthProfileConversationScreen = (props) => {
  const { navigation, route } = props
  const isOnboarding = route.params?.isOnboarding

  const dispatch = useDispatch()

  const { setToolName, setToolFunction } = useDevTools()
  useEffect(() => {
    if (!isOnboarding) {
      return
    }

    setToolName('Skip')
    setToolFunction(() => () => dispatch(PatientActions.submitHealthProfileCompleted()))

    return () => {
      setToolFunction(null)
      setToolName(null)
    }
  }, [dispatch, setToolName, setToolFunction, isOnboarding])

  useEffect(() => () => {
    dispatch(PatientActions.setHealthProfileSuccess())
  })

  const handleClose = () => {
    dispatch(PatientActions.submitHealthProfileCompleted())
  }

  if (!route.params?.channelId) {
    return <LoadingScreen hideLogo={true} />
  }

  return (
    <IntakeScreen
      exitAction={handleClose}
      channelId={route.params.channelId}
      navigation={navigation}
      route={route}
    />
  )
}

export default HealthProfileConversationScreen
