import React, { PropsWithChildren } from 'react'
import styled from 'styled-components/native'
import { Colors } from 'APP/Themes'
import { useDialog } from './provider'

const Container = styled.Pressable`
  position: absolute;
  background-color: ${Colors.appBgInverted};
  width: 100%;
  height: 100%;
  opacity: 0.6;
`

function Overlay({ children }: PropsWithChildren) {
  const { onClose } = useDialog()

  return (
    <Container
      accessibilityRole="button"
      testID="dialogOverlay"
      accessibilityLabel="Close modal"
      onPress={onClose}
    >
      {children}
    </Container>
  )
}

export default Overlay
