import { StyleSheet } from 'react-native'
import { Colors } from 'APP/Themes'
import { isWeb } from 'APP/Helpers/checkPlatform'

export const Styles = StyleSheet.create({
  pdfContainer: {
    width: 250,
    flex: isWeb() ? 0.5 : 0,
  },
  attachmentCta: {
    marginLeft: 'auto',
    justifyContent: 'center',
  },
  imageContainer: {
    borderRadius: 10,
    backgroundColor: Colors.practitionerMsgBg,
  },
  imagePreview: {
    borderRadius: 10,
    width: 240,
    height: 300,
  },
  reloadImage: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    width: 240,
    height: 300,
  },
})
