import React, { useContext } from 'react'
import { Linking, View, Text, TouchableOpacity } from 'react-native'
import I18n from 'APP/Services/i18n'

import { ChatContext } from 'APP/Lib/Context'
import Analytics from 'APP/Services/Analytics'
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import { Colors, Images } from 'APP/Themes'

import { Styles } from './style'

const InputAddress = () => {
  const { selectedAddress, setSelectedAddress, sendTextWithPayload } = useContext(ChatContext)

  const { address, phoneNumber, placeType, name, placeId } = selectedAddress.addressInfo

  const onAddressCancelled = () => {
    Analytics.trackEvent('button_click', { button_value: 'Address canceled' })
    setSelectedAddress(null)
  }

  const onAddressTap = () => {
    Analytics.trackEvent('button_click', { button_value: 'Address tapped' })
    Linking.openURL(
      `https://www.google.com/maps/search/?api=1&query=${encodeURI(
        address
      )}&query_place_id=${placeId}`
    )
  }

  const onAddressConfirmed = () => {
    const { payloadTemplate, payload, addressInfo } = selectedAddress
    Analytics.trackEvent('button_click', { button_value: 'Address confirmed' })
    sendTextWithPayload({
      message: addressInfo.address,
      payload: payload,
      payloadTemplate: payloadTemplate,
      flags: { addressInfo: addressInfo },
    })

    setSelectedAddress(null)
  }

  return (
    <View style={Styles.container}>
      <View style={Styles.cardContainer}>
        <View style={Styles.row}>
          <View style={Styles.icon}>
            <MaterialCommunityIcon
              name={placeType === 'establishment' ? 'pill' : 'map-marker'}
              size={24}
              color={Colors.accent}
            />
          </View>
          <TouchableOpacity style={Styles.innerContainer} onPress={onAddressTap}>
            <Text style={Styles.title}>
              {placeType === 'address' ? I18n.t('ConversationScreen.locationType.home') : name}
            </Text>
            <Text style={Styles.subtitle}>{address}</Text>
            {phoneNumber ? <Text style={Styles.subtitle}>{phoneNumber}</Text> : null}
          </TouchableOpacity>
          <TouchableOpacity style={Styles.action} onPress={onAddressCancelled} testID="CLOSE_BTN">
            <MaterialCommunityIcon name="close" size={20} color={Colors.text} />
          </TouchableOpacity>
        </View>
      </View>
      <TouchableOpacity style={Styles.submitIcon} onPress={onAddressConfirmed} testID="SUBMIT_BTN">
        <Images.sendMessage accentFillLine={Colors.accent} width={20} height={20} />
      </TouchableOpacity>
    </View>
  )
}

export default InputAddress
