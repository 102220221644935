import React from 'react'
import { View } from 'react-native'
import IntakeQuestion from 'APP/Components/Intake/IntakeQuestion'
import IntakeSingleChoice from 'APP/Components/Intake/IntakeSingleChoice'
import { Styles } from './style'
import { Controller, FieldValues } from 'react-hook-form'
import { Control } from 'react-hook-form/dist/types/form'
import { PromptChoiceSingleItemProps } from 'APP/Components/Intake/IntakeMultiSubPrompt/types'

interface PromptChoiceSingleControllerProps {
  name: string
  control: Control<FieldValues, unknown>
  item: PromptChoiceSingleItemProps
  memberAppFullscreen?: boolean
  utterance?: string | null
  isFocused: boolean
  onFocus: (value: string | null) => void
}

export const PromptChoiceSingleController = ({
  name,
  control,
  item,
  memberAppFullscreen,
  utterance,
  onFocus,
}: PromptChoiceSingleControllerProps) => {
  const fullItem = { ...item, member_app_fullscreen: memberAppFullscreen }
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: true,
        validate: {
          isValid: (value) => !!value.display,
        },
      }}
      render={({ field: { onChange, onBlur, value } }) => (
        <View style={Styles.subPromptQuestionContainer}>
          <IntakeQuestion
            post={{ message: fullItem.title, props: fullItem }}
            animated={false}
            scrollEnabled={false}
            multiPromptUtteranceKey={utterance}
          />
          <IntakeSingleChoice
            {...fullItem}
            choice={value}
            sendAnswer={(answer) => {
              onChange({ display: answer?.display, props: { selected_choice: answer } })
              onFocus(name)
              onBlur()
            }}
          />
        </View>
      )}
    />
  )
}
