import { StyleSheet } from 'react-native'
import { ApplicationStyles, Fonts } from 'APP/Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  dateOfBirthOffset: { marginTop: -4 },
  inputLabel: {
    ...Fonts.style.bodySmallBold,
  },
})
