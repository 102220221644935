import { StyleSheet } from 'react-native'
import { Colors, Fonts, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  container: {
    padding: Metrics.baseMargin * 1.5,
    borderRadius: 16,
    backgroundColor: Colors.bannerBg,
  },
  titleContainer: {
    flexDirection: 'row',
  },
  icon: {
    paddingRight: Metrics.baseMargin / 1.6,
  },
  title: {
    ...Fonts.style.h4,
    color: Colors.text,
    paddingBottom: Metrics.baseMargin / 2,
  },
  copyContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  copy: {
    ...Fonts.style.body,
    color: Colors.text,
  },
  syncCopy: {
    ...Fonts.style.body,
    color: Colors.text,
    p: {
      marginBottom: 0,
    },
  },
  cta: {
    marginTop: Metrics.baseMargin / 2,
    marginHorizontal: 0,
    marginBottom: 0,
  },
})
