import React from 'react'
import { LinearGradient } from 'expo-linear-gradient'
import ShimmerPlaceHolder from 'react-native-shimmer-placeholder'

import Styles from './style'

const Shimmer = (props) => {
  const { ...butter } = props
  return (
    <ShimmerPlaceHolder
      LinearGradient={LinearGradient}
      shimmerColors={Styles.shimmerColors}
      {...butter}
    />
  )
}

export default Shimmer
