import { View } from 'react-native'
import { isMobile } from 'APP/Helpers/checkPlatform'
import styled from 'styled-components/native'
import { ResponsiveView } from 'APP/Converse/Layout'

export const StyledHeaderContainer = styled(View)`
  background-color: ${({ theme }) => theme.colors.videoBg};
  display: flex;
  padding-top: ${({ theme }) =>
    isMobile() ? theme.metrics.statusBarHeight : theme.metrics.baseMargin};
  align-items: flex-end;
  padding-right: ${({ theme }) => theme.metrics.baseMargin + 4};
`

export const StyledContainerView = styled(View)`
  display: flex;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.videoBg};
  align-items: center;
  justify-content: center;
`

export const StyledResponsiveView = styled(ResponsiveView)`
  align-items: center;
  gap: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  padding-bottom: 150px;
`
