import React, { useEffect, useRef } from 'react'
import { Animated } from 'react-native'
import { Container, Bar } from './style'

const ProgressBar = ({ progress = 0 }) => {
  const animatedProgress = useRef(new Animated.Value(0)).current

  useEffect(() => {
    const clampedProgress = Math.min(Math.max(progress, 0), 100)

    Animated.timing(animatedProgress, {
      toValue: clampedProgress,
      duration: 500,
      useNativeDriver: false,
    }).start()
  }, [progress])

  // Interpolate animated progress value to width percentage
  const animatedWidth = animatedProgress.interpolate({
    inputRange: [0, 100],
    outputRange: ['0%', '100%'],
  })

  return (
    <Container>
      <Bar style={{ width: animatedWidth }} testID="progress-bar"/>
    </Container>
  )
}

export default ProgressBar
