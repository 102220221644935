import type { RootState } from 'APP/Store/CreateStore'
import { createSelector } from 'reselect'

const QC_AREA_CODE = 'QC'

export const selectPharmacies = (state: RootState) => state.pharmacies.data
export const selectPharmaciesLoading = (state: RootState) => state.pharmacies.loading
export const selectPharmaciesError = (state: RootState) => state.pharmacies.error

export const selectPharmacyById = createSelector(
  [selectPharmacies, (_, id: number) => id],
  (pharmacies, id) => pharmacies.find((pharmacy) => pharmacy.id === id) || null
)

// Returns the closets ephamacy to the member's location
// The same logic is applied in BM:
// https://github.com/dialoguemd/beautiful-mind/blob/ee35d9e989b895b5714e15596a39bab97d54b016/action-server/beautiful_mind/actions/dialoguemd/booking/delivery.py#L51

export const selectPharmacyByLocation = createSelector(
  [selectPharmacies, (_, location: string) => location],
  (pharmacies, location) => {
    if (!pharmacies.length) return null

    const pharmacy = pharmacies.find(
      (pharmacy) => pharmacy.admin_area_level_1_iso_code === location
    )
    if (pharmacy) return pharmacy

    // For now we only have 2 pharmacies for Quebec and all other provinces
    // This will be refactored in Q1 to support a more specific form of filtering
    return pharmacies.find((pharmacy) => pharmacy.admin_area_level_1_iso_code !== QC_AREA_CODE)
  }
)
