import React, { useState, useEffect, useRef } from 'react'
import { Animated, View, Image, Text, Modal, InteractionManager } from 'react-native'

// Components
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'

// Services
import I18n from 'APP/Services/i18n'

// Styles
import { Images } from 'APP/Themes'
import { Styles } from './style'

export const ZoomHintComponent = ({ onClose, style }) => {
  return (
    <DialogueTouchableOpacity
      activeOpacity={1.0}
      style={[Styles.container, Styles.zoomPopupOverlay, style]}
      onPress={onClose}
      analyticsName="Close zoom"
    >
      <View style={Styles.zoomPopup}>
        <Image source={Images.pinchIcon} style={Styles.pinchIcon} resizeMode={'contain'} />
        <Text style={Styles.zoomPopupText}>{I18n.t('ImageMap.zoomPopup')}</Text>
      </View>
    </DialogueTouchableOpacity>
  )
}

export const ZoomHint = ({ ready = true, modal = true, style }) => {
  const [animationFinished, setAnimationFinished] = useState(true)
  const [showZoomPopup, setShowZoomPopup] = useState(false)
  const showAnimatedValue = useRef(new Animated.Value(0)).current
  const closeTimeout = useRef()
  useEffect(() => {
    if (ready) {
      InteractionManager.runAfterInteractions(() => {
        openZoomPopup()
        closeTimeout.current = setTimeout(() => closeZoomPopup(), 1500)
      })
    }
  }, [ready])

  useEffect(() => {
    if (!modal) {
      Animated.timing(showAnimatedValue, {
        toValue: (showZoomPopup && 1) || 0,
        duration: 300,
        useNativeDriver: true,
      }).start(({ finished }) => {
        if (finished && !showZoomPopup) {
          setAnimationFinished(true)
        }
      })
    }
  }, [modal, showZoomPopup])

  const openZoomPopup = () => {
    setAnimationFinished(false)
    setShowZoomPopup(true)
  }
  const closeZoomPopup = () => {
    clearTimeout(closeTimeout.current)
    setShowZoomPopup(false)
  }

  if (modal) {
    return (
      <Modal
        statusBarTranslucent
        animationType="fade"
        visible={showZoomPopup}
        transparent
        onRequestClose={closeZoomPopup}
      >
        <ZoomHintComponent style={style} onClose={closeZoomPopup} />
      </Modal>
    )
  }

  const animatedStyles = {
    opacity: showAnimatedValue,
  }

  if (animationFinished) return null

  return (
    <Animated.View style={[Styles.container, animatedStyles, style]}>
      <ZoomHintComponent onClose={closeZoomPopup} />
    </Animated.View>
  )
}

export default ZoomHint
