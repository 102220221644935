import { Platform, StyleSheet } from 'react-native'
import { Colors, Metrics } from 'APP/Themes'

const textInput = {
  backgroundColor: Colors.elementsBg,
  borderRadius: 25,
  borderWidth: 0,
  paddingLeft: 16,
  paddingTop: 16,
  paddingBottom: 16,
  paddingRight: 16,
  color: Colors.text,
  minHeight: Platform.OS === 'web' ? 48 : 54,
  maxHeight: 150,
}

export const Styles = StyleSheet.create({
  container: {
    paddingHorizontal: Metrics.baseMargin,
    paddingTop: 8,
    marginBottom: 20,
    backgroundColor: 'transparent',
  },
  inputContainer: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  textInputContainer: {
    borderBottomWidth: 0,
    backgroundColor: 'transparent',
    maxHeight: 150,
  },
  textInput,
  disableTextInput: {
    ...textInput,
    backgroundColor: Colors.contrastElementBg,
  },
  rightIconButton: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 48,
    height: 48,
    marginRight: 2, // wrapper is 48x48, icon is 20x20, 48-20/2 = 14. Needs an extra 2 pixels to be right-aligned with the chat elements
  },
  rightIconContainer: {
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
  },
})
