import Config from 'APP/Config'
import Button from 'APP/Converse/Button'
import { createContext, useContext, useEffect, useState } from 'react'
import styled from 'styled-components/native'

const DevToolsContext = createContext({
  setToolFunction: (func: Function | null) => {},
  setToolName: (toolName: string | null) => {},
})

const StyledDebugToolsWrapper = styled.View`
  position: absolute;
  top: ${({ theme }) => theme.metrics.statusBarHeight + theme.metrics.baseMargin * 2}px;
  right: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  z-index: 999;
`

const DevToolsProvider = ({ children }: { children: React.ReactNode }) => {
  const [toolFunction, setToolFunction] = useState<Function | null>()
  const [toolName, setToolName] = useState<string | null>()
  const [disabled, setDisabled] = useState<boolean>(false)

  const handleOnPress = () => {
    if (Config.PROD === true || !toolFunction) {
      return
    }
    setDisabled(true)
    toolFunction()
  }

  useEffect(() => {
    setDisabled(false)
  }, [toolFunction])

  return (
    <DevToolsContext.Provider value={{ setToolFunction, setToolName }}>
      {children}
      {Config.PROD === false && toolName && toolFunction && (
        <StyledDebugToolsWrapper>
          <Button
            disabled={disabled}
            icon="bug"
            iconVariant="material"
            title={toolName}
            widthVariant="content"
            onPress={handleOnPress}
            testID="devtools-button"
          />
        </StyledDebugToolsWrapper>
      )}
    </DevToolsContext.Provider>
  )
}

export default DevToolsProvider

export const useDevTools = () => useContext(DevToolsContext)
