import { ValidationRule } from 'react-hook-form'

export enum MultiSubPromptType {
  CHOICE_SINGLE = 'prompt_choice_single',
  ADDRESS = 'prompt_address',
  TEXT = 'prompt_text',
}

export interface PromptChoiceSingleItemProps {
  choices: Choice[]
  choices_display_variant: string
  member_app_visible: boolean
  provider_app_visible: boolean
  sender: string
  title: string
  type: string
  utterance: string
  version: string
}

export interface Choice {
  display: string
  id: string
  payload: Payload
}

export interface Payload {
  slots: unknown
}

interface ItemProps {
  type: string
  title: string
  placeholder_text: string
  required?: string | ValidationRule<boolean>
  member_app_visible: boolean
  provider_app_visible: boolean
  sender: string
  utterance: string
  version: string
  showRequiredAsterisk: boolean
  prefilled_values?: null | string | boolean | number | Array<string | boolean | number>
}

export interface PromptTextItemProps extends ItemProps {
  text_type: string
}

export interface PromptAddressItemProps extends ItemProps {
  place_type: string
}
