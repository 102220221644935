import Fonts from './Fonts'
import Metrics from './Metrics'
import Colors from './Colors'

// This file is for a reusable grouping of Theme items.
// Similar to an XML fragment layout in Android

const ApplicationStyles = {
  screen: {
    fullPrimaryContainer: {
      flex: 1,
      backgroundColor: Colors.appBg,
    },
    sceneStyle: {
      backgroundColor: Colors.appBg,
    },
    navContainer: {
      flex: 1,
      backgroundColor: Colors.appBg,
      paddingTop: Metrics.navBarHeight + Metrics.statusBarHeight,
    },
    navContainerBottomSpace: {
      flex: 1,
      paddingTop: Metrics.navBarHeight + Metrics.statusBarHeight,
      paddingBottom: Metrics.bottomSpace,
    },
    navContainerDrawer: {
      flex: 1,
      height: Metrics.screenHeight - Metrics.navBarHeight,
      paddingBottom: Metrics.bottomSpace,
    },
    navContainerNavbarOverride: {
      flex: 1,
      height: Metrics.screenHeight - Metrics.navBarHeight,
      paddingTop: Metrics.statusBarHeight,
      paddingBottom: Metrics.bottomSpace,
    },
    tabContainer: {
      flex: 1,
      paddingTop: 0,
    },
    fullScreenContainer: {
      flex: 1,
      backgroundColor: Colors.appBg,
      paddingTop: 0,
    },
    navTabContainer: {
      flex: 1,
      paddingTop: Metrics.navBarHeight + Metrics.statusBarHeight,
    },
    keyboardCoveredContent: {
      height: '100%',
      paddingHorizontal: Metrics.baseMargin,
      paddingBottom: Metrics.navBarHeight + Metrics.statusBarHeight,
    },
    backgroundImage: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
    container: {
      flex: 1,
      paddingTop: Metrics.baseMargin,
    },
    section: {
      marginVertical: Metrics.section,
      padding: Metrics.baseMargin,
    },
    sectionText: {
      ...Fonts.style.body,
      color: Colors.text,
      marginVertical: Metrics.baseMargin / 2,
      marginHorizontal: Metrics.baseMargin,
      textAlign: 'center',
    },
    sectionTitle: {
      ...Fonts.style.body,
      color: Colors.text,
    },
    secondarySectionText: {
      ...Fonts.style.bodySmall,
      color: Colors.text,
      textAlign: 'center',
      marginBottom: Metrics.baseMargin,
    },
    title: {
      ...Fonts.style.subheader,
      color: Colors.textInverted,
      padding: Metrics.baseMargin / 2,
      marginHorizontal: Metrics.baseMargin / 2,
      alignItems: 'center',
      textAlign: 'center',
    },
    subtitle: {
      ...Fonts.style.body,
      color: Colors.textInverted,
      textAlign: 'center',
      marginBottom: Metrics.baseMargin,
      marginTop: Metrics.baseMargin,
    },
    h4: {
      ...Fonts.style.h4,
      justifyContent: 'center',
      alignSelf: 'center',
      color: Colors.textInverted,
      marginBottom: Metrics.baseMargin / 2,
    },
    textInput: {
      ...Fonts.style.bodyLarge,
      color: Colors.text,
      borderBottomColor: Colors.grayBg,
      borderBottomWidth: 0,
      padding: 0,
      marginTop: 0,
    },
    formInputContainer: {
      width: 'auto',
      paddingTop: Metrics.baseMargin / 2,
      marginTop: Metrics.baseMargin / 2,
      borderBottomColor: Colors.grayBg,
      borderBottomWidth: 1,
      marginHorizontal: Metrics.baseMargin / 2,
    },
    formInputIcon: {
      ...Fonts.style.h4,
      color: Colors.accent,
    },
    formInputErrorText: {
      ...Fonts.style.caption,
      minHeight: Fonts.size.regular,
      lineHeight: Fonts.size.regular,
      color: Colors.errorText,
      marginHorizontal: Metrics.baseMargin,
      marginTop: Metrics.baseMargin / 2,
      marginBottom: 0,
      paddingHorizontal: 4,
    },
    formInputHelpText: {
      ...Fonts.style.caption,
      minHeight: Fonts.size.regular,
      lineHeight: Fonts.size.regular,
      color: Colors.text,
      marginHorizontal: Metrics.baseMargin,
      marginTop: Metrics.baseMargin / 2,
      marginBottom: 0,
      paddingHorizontal: 4,
    },
    picker: {
      backgroundColor: Colors.elementsBg,
    },
    pickerFormInputContainer: {
      marginTop: Metrics.baseMargin / 2,
      marginHorizontal: Metrics.baseMargin + 2,
      justifyContent: 'center',
    },
    pickerFormInputText: {
      ...Fonts.style.bodyLarge,
      color: Colors.text,
      marginBottom: 0,
      marginHorizontal: 0,
      marginTop: 0,
      paddingTop: 0,
      lineHeight: 36,
      height: 36,
    },
    pickerFormInputPlaceholder: {
      ...Fonts.style.bodyLarge,
      color: Colors.buttonPrimarySelected,
      marginTop: 0,
      paddingTop: 0,
      marginBottom: 0,
      marginHorizontal: 0,
      lineHeight: 36,
      height: 36,
    },
    primaryButton: {
      backgroundColor: Colors.buttonPrimary,
      height: Metrics.buttons.height,
      borderRadius: Metrics.buttons.radius,
      margin: Metrics.baseMargin,
    },
    primaryButtonDisabled: {
      backgroundColor: Colors.deactivatedElementBg,
      margin: Metrics.baseMargin,
      height: Metrics.buttons.height,
      borderRadius: Metrics.buttons.radius,
      opacity: 0.5,
    },
    primaryButtonText: {
      ...Fonts.style.body,
      textAlign: 'center',
      color: Colors.buttonPrimaryText,
    },
    primaryButtonDisabledText: {
      ...Fonts.style.body,
      color: Colors.textDisabled,
    },
    primaryButtonAlternative: {
      backgroundColor: Colors.buttonPrimary,
      marginTop: Metrics.baseMargin / 2,
      marginBottom: Metrics.baseMargin / 2,
      height: Metrics.buttons.height,
      borderRadius: Metrics.buttons.radius,
    },
    primaryButtonAlternativeText: {
      ...Fonts.style.button,
      color: Colors.buttonPrimaryText,
      textAlign: 'center',
    },
    primaryButtonWhite: {
      backgroundColor: Colors.elementsBg,
      marginBottom: Metrics.baseMargin,
      marginTop: Metrics.baseMargin,
      height: Metrics.buttons.height,
      borderRadius: Metrics.buttons.radius,
    },
    primaryContrastButtonDisabled: {
      backgroundColor: Colors.disabledPrimaryContrastButton,
      marginBottom: Metrics.baseMargin,
      marginTop: Metrics.baseMargin,
      height: Metrics.buttons.height,
      borderRadius: Metrics.buttons.radius,
      opacity: 0.5,
    },
    primaryButtonWhiteText: {
      ...Fonts.style.body,
      color: Colors.text,
    },
    secondaryButtonAlternative: {
      backgroundColor: 'transparent',
      borderColor: Colors.text,
      borderWidth: 1,
      marginTop: Metrics.baseMargin / 2,
      marginBottom: Metrics.baseMargin / 2,
      height: Metrics.buttons.height,
      borderRadius: Metrics.buttons.radius,
    },
    secondaryButtonAlternativeText: {
      ...Fonts.style.button,
      color: Colors.text,
    },
    shadowHoverButton: {
      backgroundColor: Colors.buttonPrimary,
      marginTop: Metrics.baseMargin / 2,
      marginBottom: Metrics.baseMargin / 2,
      height: Metrics.buttons.height,
      borderRadius: Metrics.buttons.radius,
      shadowColor: Colors.shadow,
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowOpacity: 1,
      shadowRadius: 4,
    },
    shadowHoverButtonText: {
      ...Fonts.style.body,
      color: Colors.textInverted,
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      minWidth: 125,
    },
    secondaryButtonWhite: {
      backgroundColor: Colors.text,
      borderColor: Colors.white,
      marginTop: Metrics.baseMargin,
      marginBottom: Metrics.baseMargin,
      borderWidth: 1,
      height: 41,
    },
    secondaryContrastButtonDisabled: {
      backgroundColor: Colors.text,
      borderColor: Colors.disabledPrimaryContrastButton,
      marginTop: Metrics.baseMargin,
      marginBottom: Metrics.baseMargin,
      borderWidth: 1,
      height: 41,
      opacity: 0.5,
    },
    secondaryButtonWhiteText: {
      ...Fonts.style.body,
      color: Colors.textInverted,
    },
    secondaryButton: {
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderColor: Colors.text,
      height: Metrics.buttons.height,
      borderRadius: Metrics.buttons.radius,
      marginBottom: 15,
      marginTop: 15,
    },
    secondaryButtonDisabled: {
      backgroundColor: Colors.appBg,
      borderWidth: 1,
      borderColor: Colors.disabledSecondaryButton,
      height: Metrics.buttons.height,
      borderRadius: Metrics.buttons.radius,
      marginBottom: 15,
      marginTop: 15,
      opacity: 0.5,
    },
    secondaryButtonText: {
      ...Fonts.style.body,
      color: Colors.text,
    },
    secondaryButtonDisabledText: {
      color: Colors.disabledSecondaryButton,
    },
    primaryTextButton: {
      backgroundColor: 'transparent',
      marginBottom: Metrics.baseMargin,
      marginTop: Metrics.baseMargin,
      height: Metrics.buttons.height,
      borderRadius: Metrics.buttons.radius,
    },
    primaryTextButtonDisabled: {
      backgroundColor: 'transparent',
      marginBottom: Metrics.baseMargin,
      marginTop: Metrics.baseMargin,
      height: Metrics.buttons.height,
      borderRadius: Metrics.buttons.radius,
    },
    primaryTextButtonText: {
      ...Fonts.style.body,
      color: Colors.text,
    },
    tertiaryButton: {
      backgroundColor: 'transparent',
      height: Metrics.buttons.height,
      borderRadius: Metrics.buttons.radius,
      margin: Metrics.baseMargin,
    },
    tertiaryButtonText: {
      ...Fonts.style.button,
      color: Colors.text,
    },
    tertiaryButtonDisabledText: {
      ...Fonts.style.button,
      color: Colors.buttonSecondaryDisabled,
    },
    linkButton: {
      backgroundColor: 'transparent',
      height: Metrics.buttons.height,
      borderRadius: Metrics.buttons.radius,
      margin: Metrics.baseMargin,
    },
    linkButtonText: {
      ...Fonts.style.subheaderBold,
      color: Colors.text,
      textDecorationLine: 'underline',
    },
    linkButtonDisabledText: {
      ...Fonts.style.button,
      color: Colors.buttonSecondaryDisabled,
    },
    secondaryActiveButton: {
      backgroundColor: Colors.buttonSecondaryActive,
      height: Metrics.buttons.height,
      borderRadius: Metrics.buttons.radius,
      margin: Metrics.baseMargin,
    },
    secondaryActiveButtonDisabled: {
      backgroundColor: Colors.deactivatedElementBg,
      margin: Metrics.baseMargin,
      height: Metrics.buttons.height,
      borderRadius: Metrics.buttons.radius,
      opacity: 0.5,
    },
    secondaryActiveButtonText: {
      ...Fonts.style.body,
      textAlign: 'center',
      color: Colors.darkText,
    },
    secondaryActiveButtonDisabledText: {
      ...Fonts.style.body,
      color: Colors.textDisabled,
    },
    labelText: {
      ...Fonts.style.subheaderBold,
      color: Colors.text,
      marginVertical: 2,
    },
    familyLabelText: {
      ...Fonts.style.h4,
      color: Colors.text,
      marginVertical: 2,
    },
    emailLabelText: {
      ...Fonts.style.body,
      color: Colors.text,
      marginVertical: 2,
    },
    valueText: {
      ...Fonts.style.bodySmall,
      color: Colors.text,
      fontWeight: 'normal',
    },
    rightTitleText: {
      ...Fonts.style.bodySmall,
      color: Colors.text,
      fontWeight: 'normal',
    },
    chevron: {
      color: Colors.accent,
    },
    listContainer: {
      marginTop: -1,
      borderTopWidth: 1,
      borderBottomWidth: 0,
      borderColor: Colors.elementsBorder,
      backgroundColor: 'transparent',
    },
    listTitle: {
      margin: Metrics.baseMargin,
      color: Colors.text,
    },
    link: {
      fontWeight: 'bold',
      color: Colors.accent,
      textDecorationLine: 'underline',
    },
  },
  splashLogo: {
    resizeMode: 'contain',
    width: Metrics.screenWidth - Metrics.baseMargin,
  },
  line: {
    borderBottomColor: Colors.grayBg,
  },
  listLine: {
    marginTop: 0,
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderColor: Colors.text,
    backgroundColor: 'transparent',
  },
  listItem: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.elementsBorder,
    backgroundColor: Colors.elementsBg,
  },
  spacer: {
    flex: 1,
    backgroundColor: 'transparent',
  },
  listSpacer: {
    height: Metrics.baseMargin / 2,
    backgroundColor: 'transparent',
  },
  shadow: {
    shadowColor: '#000000',
    shadowOpacity: 0.06,
    shadowRadius: 5,
    shadowOffset: {
      width: 0,
      height: 3,
    },
  },
  normalShadow: {
    shadowColor: Colors.blackShadow,
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2,
  },
  shadowBottomLit: {
    shadowColor: '#000000',
    shadowOpacity: 0.06,
    shadowRadius: 5,
    shadowOffset: {
      width: 0,
      height: -2,
    },
  },
  shadowHeavy: {
    shadowColor: '#000000',
    shadowOpacity: 0.85,
    shadowRadius: 12,
  },
  shadowHeavyBottomLit: {
    shadowColor: '#000000',
    shadowOpacity: 0.85,
    shadowRadius: 12,
    shadowOffset: {
      width: 0,
      height: -4,
    },
  },
  modal: {
    marginTop: Metrics.statusBarHeight + Metrics.baseMargin,
    backgroundColor: Colors.appBg,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    shadowColor: '#000000',
    shadowOpacity: 0.06,
    shadowRadius: 4,
    shadowOffset: {
      width: 0,
      height: 4,
    },
  },
  // Shadow styles according to design system
  shadows: {
    shadowBottomLit: {
      shadowColor: Colors.blackShadow,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.08,
      shadowRadius: 4,
    },
  },
  softBorder: {
    borderWidth: 1,
    borderRadius: 8,
    borderColor: Colors.elementsBorder,
  },
  softShadow: {
    shadowColor: Colors.blackShadow,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.06,
    shadowRadius: 4,
    elevation: 2,
  },
}

export default ApplicationStyles
