import React, { useEffect } from 'react'
import { View, Image, ActivityIndicator } from 'react-native'
import SplashScreen from 'react-native-splash-screen'

// Styling
import { Images } from 'APP/Themes'
import styled, { useTheme } from 'styled-components/native'
import DialogueBranding from 'APP/Components/DialogueBranding'

interface LoadingScreenProps {
  route?: {
    params?: {
      hideLogo?: boolean
    }
  }
  hideLogo?: boolean
}

const SplashLogoImage = styled(Image)`
  align-self: center;
  width: 70%;
  height: 160px;
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin * 2}px;
`

const BrandingContainer = styled(DialogueBranding)`
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin}px;
  width: 100%;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.background};
`

const ContentContainer = styled(View)`
  background-color: ${({ theme }) => theme.colors.background};
  align-items: center;
  justify-content: center;
  flex: 1;
`

function LoadingScreen(props: LoadingScreenProps) {
  const theme = useTheme()
  const { hideLogo = false } = { ...props?.route?.params, ...props }

  useEffect(() => {
    SplashScreen.hide()
  }, [])

  return (
    <>
      <ContentContainer>
        {!hideLogo && <SplashLogoImage source={Images.logoLockup} resizeMode="contain" />}
        <ActivityIndicator size="large" color={theme.colors.text} />
      </ContentContainer>
      <BrandingContainer />
    </>
  )
}

export default LoadingScreen
