import { Metrics } from 'APP/Themes'
import { StyleSheet } from 'react-native'
import styled from 'styled-components/native'

export const StyledSlide = styled.View`
  align-self: stretch;
  flex: 1;
  flex-basis: auto;
  paddingbottom: ${({ theme }) => theme.metrics.baseMargin}px;
  ${({ sliderEnabled, slideHeight, theme }) =>
    sliderEnabled
      ? `
    height: ${slideHeight}px;
    paddingBottom: 0px;
    paddingRight: ${theme.metrics.baseMargin}px;
  `
      : ''}
  ${({ theme: { isBigScreen, metrics } }) =>
    isBigScreen
      ? `
    marginTop: ${metrics.baseMargin * 4}px;
  `
      : ''};
`

export default StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  },
  scrollContainer: {
    flex: 1,
  },
  scroll: {
    flexGrow: 1,
  },
  indicatorContainer: {
    position: 'absolute',
    right: Metrics.baseMargin / 2,
    alignItems: 'center',
    paddingBottom: Metrics.statusBarHeight + Metrics.navBarHeight,
  },
  indicator: {
    marginVertical: 2,
    borderRadius: 4,
  },
  gradient: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    alignSelf: 'stretch',
  },
})
