import { StyleSheet } from 'react-native'
import { Colors, Fonts, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  container: {
    paddingBottom: Metrics.baseMargin,
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
  },
  refresh: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  section: {
    minHeight: 200,
    paddingHorizontal: Metrics.baseMargin / 2,
  },
  sectionHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: Metrics.baseMargin * 1.5,
    paddingBottom: Metrics.baseMargin,
    paddingLeft: Metrics.baseMargin * 1.5,
  },
  sectionHeaderButton: {
    marginBottom: 0,
    marginTop: 0,
    marginRight: 0,
  },
  sectionTitle: {
    ...Fonts.style.h3,
    color: Colors.text,
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  empty: {
    marginTop: Metrics.baseMargin * 5,
    paddingHorizontal: Metrics.baseMargin * 2,
  },
  emptyTitle: {
    ...Fonts.style.subheaderBold,
    color: Colors.text,
    textAlign: 'center',
    marginBottom: Metrics.baseMargin * 1.25,
  },
  emptyCopy: {
    ...Fonts.style.body,
    color: Colors.text,
    textAlign: 'center',
  },
})
