import { all, spawn, takeEvery, takeLatest, takeLeading, throttle } from 'redux-saga/effects'

import { poll } from './poll'

/* ------------- Types ------------- */

import { LoggerTypes } from '../Redux/LoggerRedux'
import { StartupTypes } from '../Redux/StartupRedux'
import { AppSessionTypes } from '../Redux/AppSessionRedux'
import { PatientTypes } from '../Redux/PatientRedux'
import { LoginTypes } from '../Redux/LoginRedux'
import { PatientHistoryTypes } from '../Redux/PatientHistoryRedux'

import { DeeplinkTypes } from '../Redux/DeeplinkRedux'
import { WellnessCenterTypes } from '../Redux/WellnessCenterRedux'
import { HabitTypes } from '../Redux/HabitsRedux'
import { AccountLinkingTypes } from '../Redux/AccountLinkingRedux'
import { featuresActions } from 'APP/Redux/FeaturesSlice'
import { NotificationTypes } from '../Redux/NotificationRedux'
import { ActiveMinutesTypes } from '../Redux/ActiveMinutesRedux'
import { AppointmentBookingTypes } from '../Redux/AppointmentBookingRedux'
import { WellBeingTypes } from '../Redux/WellBeingRedux'

/* ------------- Sagas ------------- */

import {
  startup,
  gotoForeground,
  gotoBackground,
  setIsConnected,
  registerPushToken,
  toggleMandatoryMfa,
  createNotificationChannels,
} from './StartupSagas'

import {
  login,
  logout,
  resetPassword,
  requestEmailVerificationResend,
  triggerAppLock,
  activateAppLockToggle,
  checkRenewAuth,
  toggleMfa,
  addRememberedDevice,
  persistLoginData,
  cleanupAuthData,
} from './AuthSagas'

import { log, error, triggerTrackScreen, triggerEvent, startDeviceLoggerSync } from './LoggerSagas'

import { updateSprigVisitorAttributes } from './AppSessionSagas'

import {
  createEpisode,
  getOrCreateIcbtEpisode,
  submitPost,
  retryPost,
  updateChannelLastViewed,
  receiveEvent,
  mattermostStart,
  fetchChannels,
  fetchAllPostsForChannel,
  fetchNewPostsForChannel,
  fetchOlderPostsForChannel,
  launchEpisodeCommand,
  getOrCreateWbiEpisode,
  navigateToLink,
  fetchAppointments,
  rescheduleAppointment,
  cancelAppointment,
  resetTypingEventDataAfterDelay,
  fetchEpisode,
  fetchEpisodeMembers,
  fetchPractitionerBio,
  getOrCreateHealthProfileEpisode,
  archiveEpisode,
  cancelAppointmentFromCalendar,
  goBackInEpisode,
} from './PatientHistorySagas'

import {
  fetchPatientProfile,
  navigateWithLocationPermission,
  navigateWithPermission,
  navigateWithMandatoryMfa,
  patientProfileGiveConsent,
  requestLocationPermission,
  redirectToLocationService,
  updateBasicProfile,
  updateProfile,
  updateIdCard,
  registerCreditCard,
  chargeCreditCard,
  changeEmail,
  enrolWithEnrolmentCodes,
  unchallengedInvitationSearch,
  challengedInvitationQueueNext,
  resendInvitation,
  challengeInvitation,
  navigateToGeolocationCheck,
  requestPostNotifications,
  authorizeAndChangeEmail,
  runStartupAfterChangeEmail,
  navigateToCommitmentScreen,
  navigateWithEmailVerified,
  submitHealthProfileCompleted,
  getPillwayAccessToken,
} from './PatientSagas'

import { processDeferredDeeplink } from './DeeplinkSaga'

import {
  fetchContent,
  getMyChallenges,
  getNewChallenges,
  getCompletedChallenges,
  getChallenge,
  joinIndividualChallenge,
  joinTeamChallenge,
  getLeaderboard,
  getTeamParticipants,
  leaveSelectedChallenge,
  connectTracker,
  disconnectTracker,
  addMetricsTracker,
} from './WellnessCenterSagas'

import {
  getCategories,
  getSingleHabit,
  getHabitList,
  getHabitContent,
  getTopics,
  startHabit,
  removeHabit,
  doneHabit,
  undoneHabit,
  getDailyProgressList,
  getSingleHabitStats,
  getSingleHabitProgress,
  removeHabitPrompt,
  updateHabitPrompt,
  onHabitNotificationFeatureChange,
  cancelAllHabitLocalNotifications,
  getAllNotificationConfigurations,
  getNotificationConfigurations,
  updateNotificationConfiguration,
  watchForHabitNotificationSafetyCheck,
  ensureNotificationParity,
} from './HabitsSagas'
import { requestAccountLinking } from './AccountLinkingSagas'

import {
  updateNotificationPreference,
  triggerNotificationPermissionModal,
} from './NotificationSagas'

import {
  getActiveMinutes,
  getConnectedTrackers,
  getHistory,
  setConfettiShown,
  updateWeeklyTarget,
  requestNewConnection,
  removeConnection,
  getChallenges,
  getMyCompletedChallenges,
  getSingleChallenge,
  joinChallenge,
  getChallengeLeaderboard,
  leaveChallenge,
  handleAppleHealthReconnection,
} from './ActiveMinutesSagas'
import { isIOS, isMobile } from 'APP/Helpers/checkPlatform'

import { getLatestWellBeingAssessment, getRecommendations } from './WellBeingSagas'
import { initTerra, initTerraConnection } from './TerraSagas'
import { bookAppointment, getAvailableAppointments } from './AppointmentBookingSagas'

import { familyActions, familySagas } from 'APP/Store/Family'
import { contentActions, contentSagas } from 'APP/Store/Content'
import { providerBookingActions, providerBookingSagas } from 'APP/Store/ProviderBooking'
import { videoSessionActions, videoSessionSagas } from 'APP/Store/VideoSession'
import { pharmaciesActions, pharmaciesSagas } from 'APP/Store/Pharmacies'

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    takeEvery(LoggerTypes.LOG, log),
    takeEvery(LoggerTypes.ERROR, error),
    spawn(startDeviceLoggerSync),
    // Video Session and OpenTok related
    takeLatest(videoSessionActions.platformCalling, videoSessionSagas.incomingCall),
    takeLatest(videoSessionActions.patientAcceptedCall, videoSessionSagas.patientAcceptedCall),
    throttle(
      5000,
      [
        videoSessionActions.sessionDisconnected,
        videoSessionActions.sessionFailedToConnect,
        videoSessionSagas.Types.CONNECT_TO_OPENTOK,
      ],
      videoSessionSagas.connectToOpenTok
    ),
    takeLatest(
      [
        videoSessionActions.callingTimedOut,
        videoSessionActions.patientEndedCall,
        videoSessionActions.platformEndedCall,
        videoSessionActions.platformStreamDestroyed,
        videoSessionActions.handledByRemote,
        videoSessionActions.sessionDisconnected,
      ],
      videoSessionSagas.dismissVideoCallScreen
    ),
    takeLatest(
      [videoSessionActions.patientEndedCall, videoSessionActions.platformStreamDestroyed],
      videoSessionSagas.afterCallAppRatingPromptCheck
    ),

    takeLatest([StartupTypes.STARTUP, LoginTypes.LOGOUT], startup),
    takeLatest(StartupTypes.SET_PUSH_TOKEN_REQUEST, registerPushToken),
    takeLatest(StartupTypes.SET_NOTIFICATION_PERMISSION_ACCEPTED, navigateWithPermission),
    takeLatest(StartupTypes.SET_LOCATION_PERMISSION_ACCEPTED, navigateWithLocationPermission),
    takeLatest(StartupTypes.TRIGGER_APP_LOCK, triggerAppLock),
    takeLatest(StartupTypes.ACTIVATE_APP_LOCK_TOGGLE, activateAppLockToggle),
    takeLatest(StartupTypes.MANDATORY_MFA_TOGGLE_REQUEST, toggleMandatoryMfa),
    takeLatest(StartupTypes.MANDATORY_MFA_TOGGLED, navigateWithMandatoryMfa),
    takeLatest(StartupTypes.CREATE_NOTIFICATION_CHANNELS, createNotificationChannels),

    takeLatest(AppSessionTypes.SET_IS_CONNECTED, setIsConnected),
    takeLatest(AppSessionTypes.SET_APP_FOREGROUNDED, gotoForeground),
    takeLatest(AppSessionTypes.SET_APP_BACKGROUNDED, gotoBackground),

    takeLatest(DeeplinkTypes.PROCESS_DEFERRED_DEEPLINK, processDeferredDeeplink),

    takeLatest(LoginTypes.LOGIN_REQUEST, login, {
      triggerStartupNav: true,
      triggerLogoutOnFailure: false,
    }),

    takeLatest(LoginTypes.ACCOUNT_LINK_LOGIN_REQUEST, login, {
      triggerStartupNav: true,
      triggerLogoutOnFailure: false,
      saveSecondaryAccessToken: true,
    }),

    takeLatest(LoginTypes.LOGIN_REQUEST_WITH_CONNECTION, login, {
      triggerStartupNav: true,
      triggerLogoutOnFailure: true,
      isEphemeralSession: false,
    }),

    takeLatest(LoginTypes.SILENT_LOGIN_REQUEST_WITH_CONNECTION, login, {
      triggerStartupNav: true,
      triggerLogoutOnFailure: true,
      triggerSilentlogin: true,
      isEphemeralSession: false,
    }),

    takeLatest(LoginTypes.SIGNUP_REQUEST, login, {
      triggerStartupNav: true,
      triggerLogoutOnFailure: false,
      openSignUp: true,
    }),

    takeLatest(LoginTypes.SIGNUP_REQUEST_WITH_CONNECTION, login, {
      triggerStartupNav: true,
      triggerLogoutOnFailure: false,
      openSignUp: true,
    }),

    takeLatest(LoginTypes.LOGOUT, logout),
    takeLatest(LoginTypes.LOGOUT, cleanupAuthData),
    takeLatest(LoginTypes.LOGOUT, videoSessionSagas.disconnect),
    takeLatest(LoginTypes.REQUEST_RESET_PASSWORD, resetPassword),
    takeLatest(LoginTypes.EMAIL_VERIFICATION_RESEND_REQUEST, requestEmailVerificationResend),
    takeLatest(LoginTypes.TOGGLE_MFA_REQUEST, toggleMfa),
    takeLatest(LoginTypes.SUBMIT_PUBLIC_COMPUTER, addRememberedDevice),

    takeLatest([LoginTypes.LOGIN_SUCCESS, LoginTypes.REFRESH_AUTH_SUCCESS], persistLoginData),
    poll(
      LoginTypes.START_REFRESH_AUTH_POLL,
      LoginTypes.STOP_REFRESH_AUTH_POLL,
      5 * 60000, // we check to refresh auth every 5 minutes
      checkRenewAuth
    ),
    takeEvery(PatientHistoryTypes.RETRY_POST_REQUEST, retryPost),
    takeLatest(PatientHistoryTypes.FETCH_ALL_POSTS_FOR_CHANNEL, fetchAllPostsForChannel),
    takeEvery(PatientHistoryTypes.FETCH_NEW_POSTS_FOR_CHANNEL, fetchNewPostsForChannel),
    takeEvery(PatientHistoryTypes.FETCH_OLDER_POSTS_FOR_CHANNEL, fetchOlderPostsForChannel),
    takeEvery(PatientHistoryTypes.SUBMIT_TEXT_POST_REQUEST, submitPost),
    takeEvery(PatientHistoryTypes.SUBMIT_FILE_POST_REQUEST, submitPost),
    takeEvery(PatientHistoryTypes.SUBMIT_ANSWER_POST_REQUEST, submitPost),
    takeEvery(PatientHistoryTypes.SUBMIT_TEXT_WITH_PAYLOAD_POST_REQUEST, submitPost),
    takeEvery(PatientHistoryTypes.UPDATE_CHANNEL_LAST_VIEWED, updateChannelLastViewed),
    takeLatest(PatientHistoryTypes.CREATE_EPISODE_REQUEST, createEpisode),
    takeLatest(PatientHistoryTypes.LAUNCH_EPISODE_COMMAND, launchEpisodeCommand),
    takeLatest(PatientHistoryTypes.GET_OR_CREATE_ICBT_EPISODE_REQUEST, getOrCreateIcbtEpisode),
    takeLatest(PatientHistoryTypes.RECEIVE_EVENT, receiveEvent),

    takeLatest(PatientHistoryTypes.MATTERMOST_START, mattermostStart),
    takeLatest(PatientHistoryTypes.FETCH_CHANNELS_REQUEST, fetchChannels),
    takeLatest(PatientHistoryTypes.RESCHEDULE_APPOINTMENT_REQUEST, rescheduleAppointment),
    takeLatest(PatientHistoryTypes.CANCEL_APPOINTMENT_REQUEST, cancelAppointment),
    takeLatest(
      PatientHistoryTypes.CANCEL_APPOINTMENT_FROM_CALENDAR_REQUEST,
      cancelAppointmentFromCalendar
    ),
    takeLatest(PatientHistoryTypes.REFRESH_APPOINTMENTS_REQUEST, fetchAppointments),
    takeLatest(PatientHistoryTypes.FETCH_PRACTITIONER_BIO, fetchPractitionerBio),

    takeLatest(PatientHistoryTypes.RESET_TYPING_EVENT_DATA, resetTypingEventDataAfterDelay),
    takeLatest(PatientHistoryTypes.FETCH_ONE_EPISODE_REQUEST, fetchEpisode),
    takeLatest(PatientHistoryTypes.FETCH_EPISODE_MEMBERS_REQUEST, fetchEpisodeMembers),
    takeLeading(PatientHistoryTypes.ARCHIVE_EPISODE_REQUEST, archiveEpisode),
    takeLeading(PatientHistoryTypes.GO_BACK_IN_EPISODE_REQUEST, goBackInEpisode),

    takeLatest(PatientTypes.PATIENT_CREDIT_CARD_REGISTER_REQUEST, registerCreditCard),
    takeLatest(PatientTypes.PATIENT_CREDIT_CARD_CHARGE, chargeCreditCard),
    takeLatest(PatientTypes.PATIENT_PROFILE_FETCH_REQUEST, fetchPatientProfile),
    takeLatest(PatientTypes.PATIENT_PROFILE_GIVE_CONSENT, patientProfileGiveConsent),
    takeLatest(PatientTypes.PATIENT_PROFILE_BASIC_UPDATE_REQUEST, updateBasicProfile),
    takeLatest(PatientTypes.SCRIBE_V2_USER_FETCH_SUCCESS, updateSprigVisitorAttributes),
    takeLatest(PatientTypes.PILLWAY_AUTHENTICATION_REQUEST, getPillwayAccessToken),
    takeLatest(PatientTypes.PATIENT_LOCATION_PERMISSION_REQUEST, requestLocationPermission),
    takeLatest(PatientTypes.PATIENT_LOCATION_SERVICE_ACCESS, redirectToLocationService),
    takeLatest(PatientTypes.PATIENT_PROFILE_UPDATE_REQUEST, updateProfile),
    takeLatest(PatientTypes.PATIENT_ID_CARD_UPDATE_REQUEST, updateIdCard),
    takeLatest(PatientTypes.PATIENT_ENROL_WITH_ENROLMENT_CODES_REQUEST, enrolWithEnrolmentCodes),
    takeLatest(PatientTypes.RESEND_INVITATION_REQUEST, resendInvitation),
    takeLeading(PatientTypes.PATIENT_EMAIL_CHANGE_REQUEST, changeEmail),
    takeLeading(PatientTypes.UNCHALLENGED_INVITATION_SEARCH_REQUEST, unchallengedInvitationSearch),
    takeLatest(PatientTypes.CHALLENGED_INVITATION_QUEUE_REQUEST, challengedInvitationQueueNext),
    takeLatest(PatientTypes.CHALLENGED_INVITATION_QUEUE_NEXT, challengedInvitationQueueNext),
    takeLatest(PatientTypes.CHALLENGE_INVITATION_REQUEST, challengeInvitation),
    takeLatest(PatientTypes.SUBMIT_COMMITMENT, navigateToGeolocationCheck),
    takeLatest(PatientTypes.ONBOARDING_DEFERRED_ENROLMENT_COMPLETE, navigateToCommitmentScreen),
    takeLatest(PatientTypes.POST_NOTIFICATIONS_REQUEST, requestPostNotifications),
    takeLatest(PatientTypes.CHANGE_EMAIL_REQUEST, authorizeAndChangeEmail),
    takeLatest(PatientTypes.RUN_STARTUP_AFTER_CHANGE_EMAIL, runStartupAfterChangeEmail),

    takeLatest(familyActions.fetchFamilyRequest, familySagas.fetchFamily),
    takeLatest(familyActions.adultInviteRequest, familySagas.addAdult),
    takeLatest(familyActions.cancelAdultInviteRequest, familySagas.removeAdult),
    takeLatest(familyActions.createChildProfileRequest, familySagas.createChildProfile),
    takeLatest(familyActions.updateChildProfileRequest, familySagas.updateChildProfile),
    takeLatest(LoggerTypes.TRACK_VIDEO_QUALITY_CHANGE, videoSessionSagas.trackVideoQualityStats),
    takeEvery(LoggerTypes.TRIGGER_EVENT, triggerEvent),
    takeEvery(LoggerTypes.TRIGGER_TRACK_SCREEN, triggerTrackScreen),

    takeLatest(WellnessCenterTypes.SET_CONTENT, fetchContent),
    takeLatest(WellnessCenterTypes.GET_MY_CHALLENGES, getMyChallenges),
    takeLatest(WellnessCenterTypes.GET_NEW_CHALLENGES, getNewChallenges),
    takeLatest(WellnessCenterTypes.GET_COMPLETED_CHALLENGES, getCompletedChallenges),
    takeLatest(WellnessCenterTypes.GET_CHALLENGE, getChallenge),
    takeLatest(WellnessCenterTypes.JOIN_INDIVIDUAL_CHALLENGE, joinIndividualChallenge),
    takeLatest(WellnessCenterTypes.LEAVE_SELECTED_CHALLENGE, leaveSelectedChallenge),
    takeLatest(WellnessCenterTypes.JOIN_TEAM_CHALLENGE, joinTeamChallenge),
    takeLatest(WellnessCenterTypes.GET_LEADERBOARD, getLeaderboard),
    takeLatest(WellnessCenterTypes.GET_TEAM_PARTICIPANTS, getTeamParticipants),
    takeLatest(WellnessCenterTypes.CONNECT_TRACKER, connectTracker),
    takeLatest(WellnessCenterTypes.DISCONNECT_TRACKER, disconnectTracker),
    takeEvery(WellnessCenterTypes.ADD_METRICS_TRACKER, addMetricsTracker),
    isIOS() &&
      all([
        takeLatest(ActiveMinutesTypes.SERVICE_LOGIN_SUCCESS, initTerra),
        takeLatest(WellnessCenterTypes.CONNECT_TRACKER, initTerraConnection),
      ]),
    takeLeading(ActiveMinutesTypes.REQUEST_NEW_CONNECTION, requestNewConnection),
    takeLeading(ActiveMinutesTypes.REMOVE_CONNECTION, removeConnection),
    takeLatest(ActiveMinutesTypes.GET_ACTIVE_MINUTES, getActiveMinutes),
    takeLatest(ActiveMinutesTypes.GET_HISTORY, getHistory),
    takeLatest(ActiveMinutesTypes.UPDATE_WEEKLY_TARGET, updateWeeklyTarget),
    takeLatest(ActiveMinutesTypes.SET_CONFETTI_SHOWN, setConfettiShown),
    takeLatest(ActiveMinutesTypes.GET_CONNECTED_TRACKERS, getConnectedTrackers),
    takeLatest(ActiveMinutesTypes.GET_CHALLENGES, getChallenges),
    takeLatest(ActiveMinutesTypes.GET_MY_COMPLETED_CHALLENGES, getMyCompletedChallenges),
    takeLatest(ActiveMinutesTypes.GET_SINGLE_CHALLENGE, getSingleChallenge),
    takeLatest(ActiveMinutesTypes.JOIN_CHALLENGE, joinChallenge),
    takeLatest(ActiveMinutesTypes.GET_CHALLENGE_LEADERBOARD, getChallengeLeaderboard),
    takeLatest(ActiveMinutesTypes.LEAVE_CHALLENGE, leaveChallenge),
    takeLatest(ActiveMinutesTypes.HANDLE_APPLE_HEALTH_RECONNECTION, handleAppleHealthReconnection),

    takeLatest(HabitTypes.GET_CATEGORIES, getCategories),
    takeLatest(HabitTypes.GET_TOPICS, getTopics),
    takeLatest(HabitTypes.GET_SINGLE_HABIT, getSingleHabit),
    takeLatest(HabitTypes.GET_SINGLE_HABIT_STATS, getSingleHabitStats),
    takeLatest(HabitTypes.GET_SINGLE_HABIT_PROGRESS, getSingleHabitProgress),
    takeLatest(HabitTypes.GET_HABIT_LIST, getHabitList),
    takeLatest(HabitTypes.GET_HABIT_CONTENT, getHabitContent),
    takeLatest(HabitTypes.START_HABIT, startHabit),
    takeLatest(HabitTypes.REMOVE_HABIT, removeHabit),
    takeLeading(HabitTypes.DONE_HABIT, doneHabit),
    takeLeading(HabitTypes.UNDONE_HABIT, undoneHabit),
    takeLatest(HabitTypes.GET_DAILY_PROGRESS_LIST, getDailyProgressList),
    takeLatest(HabitTypes.UPDATE_HABIT_PROMPT, updateHabitPrompt),
    takeLatest(HabitTypes.REMOVE_HABIT_PROMPT, removeHabitPrompt),
    takeLatest(HabitTypes.CANCEL_ALL_HABIT_LOCAL_NOTIFICATIONS, cancelAllHabitLocalNotifications),
    takeLatest(HabitTypes.GET_ALL_NOTIFICATION_CONFIGURATIONS, getAllNotificationConfigurations),
    takeLatest(HabitTypes.GET_NOTIFICATION_CONFIGURATIONS, getNotificationConfigurations),
    takeLatest(HabitTypes.UPDATE_NOTIFICATION_CONFIGURATION, updateNotificationConfiguration),
    takeLatest(HabitTypes.ENSURE_NOTIFICATION_PARITY, ensureNotificationParity),

    takeLatest(
      [
        featuresActions.setFeatures,
        featuresActions.setOverrides,
        featuresActions.resetFeatures,
        PatientTypes.SCRIBE_V2_USER_FETCH_SUCCESS,
        AppSessionTypes.SET_APP_FOREGROUNDED,
      ],
      onHabitNotificationFeatureChange
    ),
    isMobile() && watchForHabitNotificationSafetyCheck(ensureNotificationParity),
    takeLatest(AccountLinkingTypes.REQUEST_ACCOUNT_LINKING, requestAccountLinking),

    takeLatest(contentActions.getCmsFeed, contentSagas.fetchCmsFeedContent),
    takeLeading(contentActions.getServiceGroups, contentSagas.fetchServiceGroups),
    takeEvery(contentActions.getOneServiceGroup, contentSagas.fetchOneServiceGroup),

    takeLatest(NotificationTypes.UPDATE_NOTIFICATION_PREFERENCE, updateNotificationPreference),
    takeLatest(
      NotificationTypes.TRIGGER_NOTIFICATION_PERMISSION_MODAL,
      triggerNotificationPermissionModal
    ),

    takeLatest(PatientHistoryTypes.GET_OR_CREATE_WBI_EPISODE_REQUEST, getOrCreateWbiEpisode),
    takeLatest(PatientHistoryTypes.NAVIGATE_TO_LINK, navigateToLink),
    takeLatest(
      PatientHistoryTypes.GET_OR_CREATE_HEALTH_PROFILE_EPISODE_REQUEST,
      getOrCreateHealthProfileEpisode
    ),

    takeLatest(WellBeingTypes.GET_LATEST, getLatestWellBeingAssessment),
    takeLatest(WellBeingTypes.GET_RECOMMENDATIONS, getRecommendations),

    takeLatest(PatientTypes.SKIP_EMAIL_VERIFICATION, navigateWithEmailVerified),

    takeEvery(AppointmentBookingTypes.GET_AVAILABLE_APPOINTMENTS, getAvailableAppointments),
    takeLatest(AppointmentBookingTypes.BOOK_APPOINTMENT, bookAppointment),
    takeLatest(PatientTypes.SUBMIT_HEALTH_PROFILE_COMPLETED, submitHealthProfileCompleted),

    takeLatest(providerBookingActions.getAvailabilities, providerBookingSagas.getAvailabilities),
    takeLatest(providerBookingActions.createAppointment, providerBookingSagas.bookAppointment),
    takeLatest(providerBookingActions.getProviders, providerBookingSagas.getProviders),
    takeLatest(providerBookingActions.updateAppointment, providerBookingSagas.updateAppointment),
    takeLatest(providerBookingActions.getProviderBio, providerBookingSagas.getProviderBio),
    takeLatest(providerBookingActions.getProviderById, providerBookingSagas.getProviderById),
    takeLatest(
      providerBookingActions.getProvidersAttributes,
      providerBookingSagas.getProvidersAttributes
    ),
    takeLatest(
      providerBookingActions.getAppointmentTypes,
      providerBookingSagas.getAppointmentTypes
    ),
    takeLatest(pharmaciesActions.getPharmacies, pharmaciesSagas.getPharmacies),
  ])
}
