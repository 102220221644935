import { StyleSheet } from 'react-native'
import { ApplicationStyles, Metrics, Colors, Fonts } from '../../Themes'

export default StyleSheet.create({
  container: {
    marginVertical: Metrics.baseMargin,
    marginHorizontal: Metrics.baseMargin * 1.5,
  },
  title: {
    color: Colors.text,
    marginBottom: Metrics.baseMargin * 0.5,
    ...Fonts.style.h3,
  },
  cardContainer: {
    marginTop: Metrics.baseMargin * 0.5,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: Metrics.baseMargin,
    backgroundColor: Colors.elementsBg,
    ...ApplicationStyles.shadow,
  },
  cardImage: {
    width: '100%',
    height: 160,
    borderTopLeftRadius: Metrics.baseMargin,
    borderTopRightRadius: Metrics.baseMargin,
  },
  titleContainer: {
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: Metrics.baseMargin,
    backgroundColor: Colors.elementsBg,
  },
  copyContainer: {
    flex: 1,
    paddingRight: Metrics.baseMargin,
  },
  cardTitle: {
    ...Fonts.style.h4,
    paddingBottom: Metrics.baseMargin / 4,
    color: Colors.text,
  },
  cardBody: {
    color: Colors.text,
    ...Fonts.style.bodySmall,
  },
})
