import React from 'react'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'

// Config
import { screenOptionsForTabs } from 'APP/Nav/ScreenOptions'

// Hooks & Services
import useScreenView from 'APP/Hooks/useScreenView'
import { usePrimaryTabs } from './useTabs'

const Tab = createBottomTabNavigator()

export const TabBarScreen = () => {
  const { isSmallScreen } = useScreenView()
  const primaryTabs = usePrimaryTabs()
  return (
    <Tab.Navigator
      screenOptions={screenOptionsForTabs}
      /* When not on a small screen, prevent default tabBar render, 
      as side menu will be present. */
      tabBar={isSmallScreen ? undefined : () => null}
    >
      {primaryTabs?.map((navItem) => (
        <Tab.Screen key={navItem.name} {...navItem} />
      ))}
    </Tab.Navigator>
  )
}
