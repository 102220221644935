import React, { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { SectionList, View } from 'react-native'

import { ResponsiveScrollView } from 'APP/Converse/Layout'
import { shouldRenderService } from 'APP/Lib/ContentHelpers'
import withFeatureEnabled from 'APP/Helpers/withFeatureEnabled'
import GetCareScreen_legacy from 'APP/Containers/GetCareScreen_legacy'
import Styles from './style'
import Typography from 'APP/Converse/Typography'
import I18n from 'APP/Services/i18n'
import ServiceGroupTile from 'APP/Components/ServiceGroupTile'
import PromotedServiceGroupTile from 'APP/Components/PromotedServiceGroupTile'
import { contentActions } from 'APP/Store/Content'
import { selectExternalServices } from 'APP/Redux/PatientRedux'

const GetCareScreen = () => {
  const dispatch = useDispatch()
  const { serviceGroups } = useSelector((state) => state.content)
  const { profile } = useSelector((state) => state.patient)
  const externalServices = useSelector(selectExternalServices)
  const eligibleServices = profile?.eligibleServices

  useEffect(() => {
    dispatch(contentActions.getServiceGroups())
  }, [])

  const serviceGroupSections = useMemo(() => {
    return Object.entries(serviceGroups?.data ?? {}).map(([key, value]) => {
      const data = Array.isArray(value.serviceGroups) ? value.serviceGroups : []
      if (!data.length)
        console.warn(
          'GetCareScreen',
          `entry: ${value.name} is not a valid item for the GetCareScreen`
        )
      return {
        key: key,
        title: value?.title,
        data: data,
      }
    })
  }, [serviceGroups])

  const filteredServiceGroupSections = useMemo(() => {
    return serviceGroupSections
      .map((section) => {
        const filteredData = section.data
          .map((serviceGroup) => {
            const filteredServices = serviceGroup.services.filter((service) =>
              shouldRenderService(service, eligibleServices, externalServices)
            )

            return { ...serviceGroup, services: filteredServices }
          })
          .filter((serviceGroup) => serviceGroup.services.length > 0)

        return {
          ...section,
          data: filteredData,
          eligibleServices,
          externalServices,
        }
      })
      .filter((section) => section.data.length > 0)
  }, [serviceGroupSections, externalServices, eligibleServices])

  const isPromotedSection = (section) => {
    return (
      filteredServiceGroupSections.indexOf(section) === 0 &&
      filteredServiceGroupSections[0].key === serviceGroupSections[0].key
    )
  }

  const ServiceGroupHeader = ({ title }) =>
    title && (
      <View style={Styles.sectionHeader}>
        <Typography variant="h4"> {title} </Typography>
      </View>
    )

  return (
    <ResponsiveScrollView testID="GET_CARE_VIEW" style={Styles.serviceContainer}>
      {I18n.t('GetCareScreen.header') && (
        <Typography variant="h3" style={Styles.mainHeader}>
          {I18n.t('GetCareScreen.header')}
        </Typography>
      )}
      {Boolean(filteredServiceGroupSections.length) && (
        <SectionList
          style={Styles.sectionList}
          sections={filteredServiceGroupSections}
          keyExtractor={(item, index) => item + index}
          renderItem={({ item, section }) => {
            if (isPromotedSection(section)) {
              return null
            } else {
              return (
                <View style={Styles.ctaContainer}>
                  <ServiceGroupTile key={item.key} item={item} analyticsScreenName={'chatScreen'} />
                </View>
              )
            }
          }}
          renderSectionHeader={({ section }) => {
            if (isPromotedSection(section)) {
              return (
                <>
                  <ServiceGroupHeader title={section.title} />
                  <View style={Styles.promotedSectionList}>
                    {section.data.map((item, index) => (
                      <View style={Styles.ctaPromotedContainer} key={index}>
                        <PromotedServiceGroupTile item={item} analyticsScreenName={'chatScreen'} />
                      </View>
                    ))}
                  </View>
                </>
              )
            } else {
              return <ServiceGroupHeader title={section.title} />
            }
          }}
        />
      )}
    </ResponsiveScrollView>
  )
}

export default withFeatureEnabled({
  memoizationKey: 'GetCareScreen',
  enabled: GetCareScreen,
  default: GetCareScreen_legacy,
  featureKey: 'useBenefitsNavigation',
})
