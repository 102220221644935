import React from 'react'
import { TouchableOpacity } from 'react-native'

import Analytics from 'APP/Services/Analytics'

class DialogueTouchableOpacity extends React.Component {
  onWrappedPress = (analyticsName) => (event) => {
    if (this.props.onPress) {
      Analytics.trackEvent('button_click', { button_value: analyticsName })
      this.props.onPress(event)
    }
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { analyticsName, children, onPress, innerRef, ...butter } = this.props
    return (
      <TouchableOpacity {...butter} ref={innerRef} onPress={this.onWrappedPress(analyticsName)}>
        {children}
      </TouchableOpacity>
    )
  }
}

export default DialogueTouchableOpacity
