import { Platform } from 'react-native'

const getDataFromNotification = (notification) => {
  const getData = notification?.getData
  return notification?.data || (typeof getData === 'function' && getData())
}

export const getChannelIdFromNotification = (notification) => {
  if (notification) {
    const data = getDataFromNotification(notification)
    return data?.channelId
  }

  return undefined
}

export const getDeeplinkFromNotification = (notification) => {
  if (notification) {
    const data = getDataFromNotification(notification)

    // Check to see if the notifcation was interacted with on iOS as well
    if (Platform.OS === 'ios') {
      if (notification.userInteraction) {
        return data?.data?.pinpoint?.deeplink
      }
    } else {
      return data?.['pinpoint.deeplink'] // Android
    }
  }

  return undefined
}
