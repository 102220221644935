import React, { useCallback } from 'react'

import AddressSearchCard from 'APP/Converse/AddressSearchCard'
import { Styles } from './style'
import { transformAnswer } from 'APP/Lib/PlacesHelper'

const IntakeAddressSearch = ({
  title,
  sendAnswer,
  onClose,
  disabled,
  placeholder_text,
  place_type = 'address',
  style,
  prefilled_values,
}) => {
  const onSubmitCallback = useCallback(
    (answer) => {
      sendAnswer(transformAnswer(answer))
    },
    [sendAnswer]
  )

  return (
    <AddressSearchCard
      ctaOnCancel={onClose}
      style={[style, Styles.addressSearchCard]}
      title={title}
      disabled={disabled}
      placeholder={placeholder_text}
      onSubmitCallback={onSubmitCallback}
      type={place_type}
      prefilledValue={prefilled_values}
    />
  )
}

export default IntakeAddressSearch
