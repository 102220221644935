import { useState } from 'react'
import { useSharedValue, withTiming, withDelay, useAnimatedStyle } from 'react-native-reanimated'

const useHideAnimation = ({ duration = 500, initiallyVisible = true } = {}) => {
  const opacity = useSharedValue(initiallyVisible ? 1 : 0)
  const height = useSharedValue(initiallyVisible ? null : 0)
  const [initialHeightSet, setInitialHeightSet] = useState(false)

  const triggerHideAnimation = () => {
    if (initialHeightSet) {
      // Animate the opacity first
      opacity.value = withTiming(0, { duration: duration * 0.5 })

      // delay the height animation a touch for a better feel
      height.value = withDelay(duration * 0.2, withTiming(0, { duration: duration / 2 }))
    }
  }

  const triggerAppearAnimation = () => {
    // Animate the opacity first
    opacity.value = withTiming(1, { duration: duration * 0.5 })

    // delay the height animation a touch for a better feel
    height.value = withDelay(duration * 0.2, withTiming(80, { duration: duration / 2 }))
  }

  const onLayout = (event) => {
    if (!initialHeightSet) {
      const { height: measuredHeight } = event.nativeEvent.layout
      height.value = measuredHeight
      setInitialHeightSet(true)
    }
  }

  const animatedStyles = useAnimatedStyle(() => ({
    opacity: opacity.value,
    height: height.value === null ? undefined : height.value,
    overflow: 'hidden',
  }))

  return { animatedStyles, triggerHideAnimation, triggerAppearAnimation, onLayout }
}

export default useHideAnimation
