import { StyleSheet } from 'react-native'
import { Colors, Fonts, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  text: {
    ...Fonts.style.bodyLarge,
    color: Colors.text,
  },
  bold: {
    fontWeight: 'bold',
    color: Colors.text,
  },
  italic: {
    fontStyle: 'italic',
    color: Colors.text,
  },
  underline: {
    textDecorationLine: 'underline',
    color: Colors.text,
  },
  h1: {
    ...Fonts.style.h1,
    color: Colors.text,
  },
  h2: {
    ...Fonts.style.h2,
    color: Colors.text,
  },
  h3: {
    ...Fonts.style.h3,
    color: Colors.text,
  },
  h4: {
    ...Fonts.style.h4,
    color: Colors.text,
  },
  bulletStyle: {
    //color: Colors.text,
    marginLeft: Metrics.baseMargin,
    lineHeight: 28,
  },
  textBulletPoint: {
    color: 'red',
    lineHeight: 31,
  },
})
