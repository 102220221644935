import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors } from '../../Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  brandingContainer: {
    width: '100%',
    justifyContent: 'center',
    backgroundColor: Colors.appBg,
  },
  healthProfileContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 40,
    marginBottom: 10,
    padding: 20,
  },
  healthProfileHeader: {
    width: '100%',
    textAlign: 'center',
    marginBottom: 10,
  },
  healthProfileDescription: {
    width: '100%',
    textAlign: 'center',
    marginBottom: 20,
  },
})
