import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { packetLossStatuses } from 'APP/Lib/VideoSession/constants'
import {
  VideoSessionState,
  ConnectToSessionPayload,
  PublisherStatsPayload,
  PacketLossEventPayload,
  PlatformCallingPayload,
  StreamPayload,
  UserIdPayload,
} from './types'

const INITIAL_STATE: VideoSessionState = {
  sessionId: null,
  episodeId: null,
  userId: null,
  connected: false,
  connections: [],
  streams: [],
  practitionerId: null,
  callId: null,
  lastCallAcceptedAt: null,
  patientIsMuted: false,
  practitionerIsMuted: false,
  publisherStats: {
    numPacketLossEvents: 0,
    highestLossPercent: 0,
    totalPacketsSent: 0,
    totalPacketsLost: 0,
    currentLossPercent: 0,
    currentLossStatus: packetLossStatuses.OKAY as keyof typeof packetLossStatuses,
  },
  lastVideoCall: {
    practitionerId: null,
    episodeId: null,
  },
}

const resetCallState = (state: VideoSessionState) => {
  state.episodeId = null
  state.practitionerId = null
  state.callId = null
  state.patientIsMuted = false
  state.practitionerIsMuted = false
}

const resetLastVideoCallInfo = (state: VideoSessionState) => {
  state.practitionerId = null
  state.episodeId = null
  state.lastVideoCall = {
    practitionerId: null,
    episodeId: null,
  }
}

const middlewareEvent = (state: VideoSessionState, action: PayloadAction<UserIdPayload>) => {
  state.userId = action.payload.userId
}

const resetState = () => ({ ...INITIAL_STATE })

const { actions, reducer } = createSlice({
  name: 'videoSession',
  initialState: INITIAL_STATE,
  reducers: {
    connectToSession: (_, action: PayloadAction<ConnectToSessionPayload>) => {
      return { ...INITIAL_STATE, sessionId: action.payload.sessionId }
    },
    setPublisherStats: (state, action: PayloadAction<PublisherStatsPayload>) => {
      const { totalPacketsSent, totalPacketsLost, currentLossPercent } = action.payload
      state.publisherStats.totalPacketsSent = totalPacketsSent
      state.publisherStats.totalPacketsLost = totalPacketsLost
      state.publisherStats.currentLossPercent = currentLossPercent
      state.publisherStats.highestLossPercent = Math.max(
        currentLossPercent,
        state.publisherStats.highestLossPercent
      )
    },
    resetPublisherStats: (state) => {
      state.publisherStats = {
        ...state.publisherStats,
        totalPacketsSent: 0,
        totalPacketsLost: 0,
        currentLossPercent: 0,
        currentLossStatus: packetLossStatuses.OKAY as keyof typeof packetLossStatuses,
      }
    },
    publisherPacketLossEvent: (state, action: PayloadAction<PacketLossEventPayload>) => {
      state.publisherStats.numPacketLossEvents += 1
      state.publisherStats.currentLossStatus = action.payload.currentLossStatus
    },
    sessionConnected: (state) => {
      state.connected = true
    },
    connectionCreated: (state, action: PayloadAction<{ connection: { connectionId: string } }>) => {
      const connectionId = action.payload.connection.connectionId
      if (connectionId) {
        state.connections.push(connectionId)
      }
    },
    connectionDestroyed: (
      state,
      action: PayloadAction<{ connection: { connectionId: string } }>
    ) => {
      const connectionId = action.payload.connection.connectionId
      if (connectionId) {
        state.connections = state.connections.filter((id) => id !== connectionId)
      }
    },
    platformCalling: (state, action: PayloadAction<PlatformCallingPayload>) => {
      const { callId, userId, episodeId } = action.payload
      state.callId = callId
      state.practitionerId = userId
      state.episodeId = episodeId
    },
    platformMutedAudio: (state) => {
      state.practitionerIsMuted = true
    },
    platformUnmutedAudio: (state) => {
      state.practitionerIsMuted = false
    },
    platformStreamCreated: (state, action: PayloadAction<StreamPayload>) => {
      state.streams.push(action.payload.stream)
    },
    platformStreamDestroyed: (state, action: PayloadAction<StreamPayload>) => {
      state.streams = state.streams.filter(
        (stream) => stream.streamId !== action.payload.stream.streamId
      )
    },
    patientMutedAudio: (state, action: PayloadAction<UserIdPayload>) => {
      state.patientIsMuted = true
      state.userId = action.payload.userId
    },
    patientUnmutedAudio: (state, action: PayloadAction<UserIdPayload>) => {
      state.patientIsMuted = false
      state.userId = action.payload.userId
    },
    handledByRemote: () => {},
    saveLastVideoCallInfo: (state, action: PayloadAction<PlatformCallingPayload>) => {
      const { userId, episodeId } = action.payload
      state.practitionerId = userId
      state.episodeId = episodeId
      state.lastVideoCall = {
        practitionerId: userId,
        episodeId: episodeId,
      }
    },
    clearLastVideoCallInfo: resetLastVideoCallInfo,

    disconnectFromSession: resetState,

    callingTimedOut: resetCallState,
    sessionDisconnected: resetCallState,
    sessionFailed: resetCallState,
    sessionFailedToConnect: resetCallState,
    platformEndedCall: resetCallState,
    malformedSignal: resetCallState,
    unhandledSignal: resetCallState,

    patientRinging: middlewareEvent,
    patientAskingAudioPermission: middlewareEvent,
    patientAskingVideoPermission: middlewareEvent,
    patientAcceptedAudioPermission: middlewareEvent,
    patientAcceptedVideoPermission: middlewareEvent,
    patientDeclinedAudioPermission: middlewareEvent,
    patientDeclinedVideoPermission: middlewareEvent,
    patientAcceptedCall: (state, action) => {
      state.lastCallAcceptedAt = action.payload.lastCallAcceptedAt || null
      state.userId = action.payload.userId
    },
    patientEndedCall: middlewareEvent,
  },
})

export { actions as videoSessionActions, INITIAL_STATE }

export default reducer
