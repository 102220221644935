import React from 'react'
import { ScrollView, View, FlatList } from 'react-native'
import { wrapScrollView } from 'react-native-scroll-into-view'

const Styles = {
  contentContainer: {
    alignItems: 'center',
  },
  scrollViewInnerContainer: {
    width: '100%',
    maxWidth: 800,
  },
  innerContainer: {
    width: '100%',
    maxWidth: 800,
    alignSelf: 'center',
  },
  webViewHeight: {
    // TODO: make sure that it is not brake other place as for DIA-61522
    height: 700,
  },
}

const ScrollIntoView = wrapScrollView(ScrollView)
export const ResponsiveScrollView = ({
  children,
  wrapIntoView = false,
  contentContainerStyle,
  contentContainerInnerStyle,
  footer,
  ...rest
}) => {
  const WrapperView = wrapIntoView ? ScrollIntoView : ScrollView

  return (
    <>
      <WrapperView
        {...rest}
        contentContainerStyle={[Styles.contentContainer, contentContainerStyle]}
      >
        <View style={[Styles.scrollViewInnerContainer, contentContainerInnerStyle]}>
          {children}
        </View>
      </WrapperView>
      {footer}
    </>
  )
}

export const ResponsiveView = ({ children, style, ...rest }) => {
  return (
    <View style={[Styles.innerContainer, style]} {...rest}>
      {children}
    </View>
  )
}

export const ResponsiveFlatList = React.forwardRef(
  ({ contentContainerStyle, contentContainerInnerStyle, ...rest }, ref) => {
    return (
      <FlatList
        style={[contentContainerStyle]}
        contentContainerStyle={[Styles.innerContainer, contentContainerInnerStyle]}
        ref={ref}
        {...rest}
      />
    )
  }
)

export const ResponsiveWebView = ({ children, style, ...rest }) => {
  return (
    <View style={[Styles.innerContainer, Styles.webViewHeight, style]} {...rest}>
      {children}
    </View>
  )
}
