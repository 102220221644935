import React, { useEffect, useRef } from 'react'
import { Animated } from 'react-native'
import Color from 'color'
import { LinearGradient } from 'expo-linear-gradient'

// Utils
import { useOnLayout } from 'APP/Hooks/Layout'

// Styles
import { Colors } from 'APP/Themes'
import { Styles } from './style'

const progressingColor = Color(Colors.accent).lighten(0.3).hex()
const transparent = Color(progressingColor).alpha(0).rgb().string()
const gradientColors = [transparent, progressingColor, transparent]

const ProgressBar = ({ total, current, processing, show = true }) => {
  const [{ width }, onLayout] = useOnLayout()
  const currentInt = Number(current)
  const totalInt = !Number(total) ? 1 : Number(total)
  const progress = currentInt / totalInt || 0
  const showAnimation = useRef(new Animated.Value(+show)).current
  const progressAnimated = useRef(new Animated.Value(progress)).current
  const processingAnimated = useRef(new Animated.Value(1)).current

  useEffect(() => {
    Animated.spring(showAnimation, {
      toValue: show ? 1 : 0,
      speed: 2,
      useNativeDriver: true,
      delay: show ? 0 : 500,
    }).start()
  }, [show])
  useEffect(() => {
    Animated.timing(progressAnimated, {
      toValue: progress,
      duration: 300,
      useNativeDriver: true,
    }).start()
  }, [progress])

  useEffect(() => {
    if (processing) {
      Animated.loop(
        Animated.sequence([
          Animated.timing(processingAnimated, {
            toValue: 0,
            duration: 0,
            useNativeDriver: true,
          }),
          Animated.timing(processingAnimated, {
            toValue: 1,
            duration: 500 + 500 * progress,
            useNativeDriver: true,
          }),
        ])
      ).start()
      return () =>
        Animated.spring(processingAnimated, {
          toValue: 1,
          useNativeDriver: true,
        }).start()
    }
  }, [processing])

  const progressTranslateX = progressAnimated.interpolate({
    inputRange: [0, 1],
    outputRange: [-width, 0],
  })
  const processingTranslateX = processingAnimated.interpolate({
    inputRange: [0, 1],
    outputRange: [-width * progress, width * 0.4],
  })

  const containerAnimatedStyle = {
    opacity: showAnimation,
    transform: [
      {
        scaleY: showAnimation.interpolate({ inputRange: [0, 0.5, 1], outputRange: [0, 0.2, 1] }),
      },
      {
        scaleX: showAnimation.interpolate({ inputRange: [0, 0.5, 1], outputRange: [0, 1, 1] }),
      },
    ],
  }

  return (
    <Animated.View style={[Styles.container, containerAnimatedStyle]} onLayout={onLayout}>
      <Animated.View style={[Styles.progress, { transform: [{ translateX: progressTranslateX }] }]}>
        <Animated.View
          style={[Styles.processing, { transform: [{ translateX: processingTranslateX }] }]}
        >
          <LinearGradient
            colors={gradientColors}
            locations={[0.1, 0.9, 1]}
            style={{ flex: 1 }}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 1 }}
          />
        </Animated.View>
      </Animated.View>
    </Animated.View>
  )
}

export default ProgressBar
