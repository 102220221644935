import I18n from 'APP/Services/i18n'
import { Images } from 'APP/Themes'

export const ButtonActions = {
  contactUs: 1,
}

export default {
  infoWarning_noInvitationsFound: {
    icon: Images.logoErrorIcon,
    title: I18n.t('NoInvitationsFoundErrorScreen.title'),
    subtitle: I18n.t('NoInvitationsFoundErrorScreen.subtitle'),
    buttons: [{ title: I18n.t('Common.contactSupport'), action: ButtonActions.contactUs }],
  },
}
