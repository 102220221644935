import { Colors, Fonts, Metrics, ApplicationStyles } from 'APP/Themes'
import { StyleSheet } from 'react-native'

export const markdownStyles = {
  p: {
    ...Fonts.style.body,
    marginBottom: 0,
  },
}
const bubbleTailSize = 12
export default StyleSheet.create({
  container: {
    flexGrow: 0,
    flexDirection: 'row',
    paddingTop: Metrics.baseMargin,
  },
  author: {
    flexGrow: 0,
    justifyContent: 'flex-end',
    paddingRight: Metrics.baseMargin * 0.75,
  },
  authorIcon: {
    ...Metrics.avatar,
    resizeMode: 'contain',
  },
  authorLabel: {
    ...Fonts.style.caption,
    color: Colors.textSecondary,
    paddingBottom: Metrics.baseMargin / 4,
  },
  textBubbleContainer: {
    flex: 1,
  },
  textBubble: {
    backgroundColor: Colors.elementsBorder,
    padding: Metrics.baseMargin * 0.75,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    marginBottom: bubbleTailSize / 2,
  },
  textBubbleTail: {
    position: 'absolute',
    alignSelf: 'flex-start',
    bottom: -6,
    left: 0,
    borderBottomWidth: bubbleTailSize,
    borderLeftWidth: bubbleTailSize,
    borderTopWidth: bubbleTailSize,
    borderBottomColor: 'transparent',
    borderTopColor: 'transparent',
    borderLeftColor: Colors.elementsBorder,
  },
  action: {
    ...ApplicationStyles.shadow,
    padding: Metrics.baseMargin,
    marginTop: Metrics.baseMargin / 2,
    backgroundColor: Colors.elementsBg,
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionText: {
    ...Fonts.style.button,
    color: Colors.text,
    flex: 1,
  },
})
