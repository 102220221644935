import { StyleSheet, Appearance } from 'react-native'
import Color from 'color'
import { Colors, Metrics } from 'APP/Themes'

const chartBlurredColor =
  Appearance.getColorScheme() === 'dark' ? Colors.text : Color(Colors.accent).lighten(0.5).hex()

export default StyleSheet.create({
  chartContainer: {
    marginLeft: -Metrics.baseMargin,
  },
  populationAverageLine: {
    stroke: Colors.buttonSecondaryDisabled,
    strokeWidth: 1,
    strokeDasharray: '3, 3',
  },
  populationAverageCopy: { fill: Colors.buttonSecondaryDisabled, fontSize: 12 },
  line: {
    stroke: Colors.accent,
    strokeWidth: 3,
    clipPath: 'url(#activeClip)',
  },
  blurredLine: {
    stroke: chartBlurredColor,
    strokeWidth: 3,
    clipPath: 'url(#baseClip)',
  },
  scatterDots: {
    fill: ({ datum }) => (datum.blured ? chartBlurredColor : Colors.accent),
    stroke: ({ datum }) => (datum.active ? Colors.accent : Colors.appBg),
    strokeOpacity: ({ datum }) => (datum.active ? 0.2 : 1),
    strokeWidth: ({ datum }) => (datum.active ? 20 : 1),
  },
})
