import React from 'react'
import { View } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import Styles from './style'
import { Colors } from 'APP/Themes'
import { ResponsiveScrollView, ResponsiveView } from 'APP/Converse/Layout'

export default function ScrollViewWithFooter({ children, footerComponent, ...butter }) {
  return (
    <View style={Styles.container}>
      <View style={Styles.flex}>
        <ResponsiveScrollView {...butter} style={Styles.flex}>
          {children}
        </ResponsiveScrollView>
        {footerComponent && (
          <LinearGradient colors={[`${Colors.appBg}00`, Colors.appBg]} style={Styles.gradient} />
        )}
      </View>
      <ResponsiveView>{footerComponent}</ResponsiveView>
    </View>
  )
}
