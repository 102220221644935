import AsyncStorage from '@react-native-community/async-storage'

const LOG_DUMP_LIMIT_ENTRIES = 10000
export const DEVICE_LOGGER_KEY = 'logDump'

export const pushLogToAsyncStorage = async (logs) => {
  const logDump = await AsyncStorage.getItem(DEVICE_LOGGER_KEY)
  const nextLogDump = JSON.parse(logDump) || []
  nextLogDump.unshift(...logs.sort((a, b) => b.timestamp - a.timestamp))
  if (nextLogDump.length > LOG_DUMP_LIMIT_ENTRIES) {
    nextLogDump.splice(0, LOG_DUMP_LIMIT_ENTRIES - nextLogDump.length)
  }
  await AsyncStorage.setItem(DEVICE_LOGGER_KEY, JSON.stringify(nextLogDump))
}

export const getDeviceLogs = async () => {
  try {
    const logDump = await AsyncStorage.getItem(DEVICE_LOGGER_KEY)
    return JSON.parse(logDump) || []
  } catch (e) {
    console.log('Error getting logs from AsyncStorage', e)
    return []
  }
}

export const clearDeviceLogs = async () => {
  try {
    await AsyncStorage.removeItem(DEVICE_LOGGER_KEY)
  } catch (e) {
    console.log('Error clearing logs from AsyncStorage', e)
  }
}
