import { StyleSheet } from 'react-native'
import { Colors, Fonts, Metrics } from 'APP/Themes'

const tooltipTailSize = 7
export const tooltipStyles = StyleSheet.create({
  container: { pointerEvents: 'none' },
  foreignObject: { overflow: 'visible' },
  outerContainer: { width: '100%', height: '100%', backgroundColor: 'transparent' },
  innerContainer: {
    position: 'absolute',
    alignItems: 'center',
  },
  tooltip: { position: 'absolute' },
  content: {
    padding: Metrics.baseMargin / 2,
    backgroundColor: Colors.text,
    borderRadius: 4,
    maxWidth: 300,
  },
  tail: {
    position: 'absolute',
    alignSelf: 'center',
    borderLeftWidth: tooltipTailSize,
    borderTopWidth: tooltipTailSize,
    borderRightWidth: tooltipTailSize,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderTopColor: Colors.text,
  },
  topTail: {
    top: -tooltipTailSize,
    transform: [{ rotate: '180deg' }],
  },
  bottomTail: {
    bottom: -tooltipTailSize,
  },
})

export const MAX_HEIGHT = 230
export default StyleSheet.create({
  container: {
    justifyContent: 'center',
    minHeight: MAX_HEIGHT,
    marginLeft: -Metrics.baseMargin,
  },
  containerLimited: {
    minHeight: null,
    flexGrow: 1,
  },
  scoreTooltip: {
    color: Colors.textInverted,
    fontWeight: 'bold',
  },
  scoreValueContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  scoreValuePoint: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginRight: Metrics.baseMargin / 4,
  },
  tickLabels: Fonts.style.captionBold,
})
