import React, { useEffect } from 'react'
import { View, Text } from 'react-native'
import I18n from 'APP/Services/i18n'
import BottomSheetModal from 'APP/Components/BottomSheetModal'
import Analytics from 'APP/Services/Analytics'
import { PrimaryButton, SecondaryButton } from 'APP/Components/Buttons'
import Styles from './style'

const PromptNewTargetModal = ({
  isVisible,
  currentTarget,
  newTarget,
  onClose,
  onAccept,
  onDecline,
}) => {
  const isIncreasePrompted = currentTarget < newTarget
  const i18nKey = isIncreasePrompted ? 'increase' : 'decrease'

  useEffect(() => {
    if (isVisible) {
      Analytics.trackScreen('New target prompt')
    }
  }, [isVisible])

  return (
    <BottomSheetModal
      isVisible={isVisible}
      onClose={onClose}
      title={I18n.t(`ActiveMinutes.promptNewTarget.title.${i18nKey}`)}
      testID="newTargetPromptModal"
    >
      <View style={Styles.content}>
        <Text style={Styles.copy}>
          {I18n.t(`ActiveMinutes.promptNewTarget.copy.${i18nKey}`, { newTarget })}
        </Text>
        <PrimaryButton
          title={I18n.t('ActiveMinutes.promptNewTarget.accept')}
          onPress={onAccept}
          analyticsName="Accept new target"
          style={Styles.button}
        />
        <SecondaryButton
          title={I18n.t('ActiveMinutes.promptNewTarget.decline')}
          onPress={onDecline}
          analyticsName="Keep current target"
          style={Styles.button}
        />
      </View>
    </BottomSheetModal>
  )
}

export default PromptNewTargetModal
