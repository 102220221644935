import React, { useCallback, useEffect, useRef } from 'react'
import { View } from 'react-native'
import { useScrollIntoView } from 'react-native-scroll-into-view'

/**
 * Helper View wrapper to access ref and unmount cycle
 */
export const ScrollableItemWrapper = React.forwardRef(({ onUnmount, ...props }, ref) => {
  useEffect(() => onUnmount, [])
  return <View ref={ref} {...props} />
})
ScrollableItemWrapper.displayName = 'ScrollableItemWrapper'

/**
 * For managing a banch of views that change overtime
 * Requires context of react-native-scroll-into-view
 * (inner of the wrapped ScrollView)
 */
export function useDynamicScrollIntoView(defaultOptions) {
  const elements = useRef({})
  const RNScrollIntoView = useScrollIntoView()

  const getOnUnmount = (id) => () => {
    delete elements.current[id]
  }
  const getItemRef = (id) => (el) => {
    elements.current[id] = el
  }
  const scrollIntoView = useCallback((id, options = defaultOptions) => {
    RNScrollIntoView(elements.current[id], options)
  })

  const getItemProps = useCallback((id) => ({
    ref: getItemRef(id),
    onUnmount: getOnUnmount(id),
  }))

  return [getItemProps, scrollIntoView]
}
