import React, { useState, useEffect, useCallback } from 'react'
import { View, Keyboard } from 'react-native'
import { connect } from 'react-redux'
import { useForm } from 'react-hook-form'

import PatientActions from 'APP/Redux/PatientRedux'

import DialogueBranding from 'APP/Components/DialogueBranding'
import SignupLogoHeader from 'APP/Components/SignupLogoHeader'
import { ResponsiveScrollView, ResponsiveView } from 'APP/Converse/Layout'

import ControllerDatePicker from 'APP/Components/ControllerFields/ControllerDatePicker'
import ControllerInput from 'APP/Components/ControllerFields/ControllerInput'
import ControllerPicker from 'APP/Components/ControllerFields/ControllerPicker'
import ControllerRadioGroup from 'APP/Components/ControllerFields/ControllerRadioGroup'

import { getShouldProvideProvince } from 'APP/Lib/ProfileHelpers'
import { validateIsValidDate, validateIsAdult } from 'APP/Lib/DateHelpers'
import { KeyLabelArrays } from 'APP/Lib/ProfileDataFormat'

import Styles from './style'
import { Colors } from 'APP/Themes'

import I18n from 'APP/Services/i18n'
import { useDevTools } from 'APP/Lib/DevTools'
import Button from 'APP/Converse/Button'

const OnboardingProfileScreen = ({
  profile,
  signupProfileData,
  busy,
  patientUpdateBasicProfile,
  setIsOnboarding,
  showPronouns,
}) => {
  const [focusedField, setFocusedField] = useState(null)
  const { setToolFunction, setToolName } = useDevTools()

  const {
    control,
    handleSubmit,
    watch,
    trigger,
    getValues,
    setValue,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      firstName: profile.givenName || signupProfileData.firstName,
      lastName: profile.familyName || signupProfileData.lastName,
      preferredName: profile.preferredName,
      preferredNameRadio: profile.preferredName ? 'yes' : null,
      pronouns: profile.pronouns,
      pronounsRadio: profile.pronouns ? 'yes' : null,
      province: profile.residesIn,
      phoneNumber: profile.phoneNumber,
      preferredLanguage:
        profile.preferred_language ||
        (KeyLabelArrays.LANGUAGES.length === 1 && KeyLabelArrays.LANGUAGES[0].key),
      dateOfBirth: profile.dateOfBirth || signupProfileData.dateOfBirth,
    },
  })

  const watchPreferredNameRadio = watch('preferredNameRadio')
  const watchPronounRadio = watch('pronounsRadio')

  const showCommunicationPreference = KeyLabelArrays.LANGUAGES.length > 1

  const onSubmit = useCallback(
    (formData) => {
      if (formData.preferredNameRadio === 'no') {
        formData.preferredName = null // Ensure we don't send a preferred name if the user selects no
      }
      if (formData.pronounsRadio === 'no') {
        formData.pronouns = null // Ensure we don't send pronouns if the user selects no
      }
      patientUpdateBasicProfile(formData)
      setIsOnboarding(true)
    },
    [patientUpdateBasicProfile, setIsOnboarding]
  )

  const fillWithData = useCallback(() => {
    const userName = profile.email.split('@')[0]
    setValue('firstName', userName.split?.('.')?.[0])
    setValue('lastName', userName.split?.('.')?.[1])
    setValue('dateOfBirth', '1990-01-01')
    setValue('province', 'QC')
    setValue('phoneNumber', '5555555555')
    setValue('preferredLanguage', 'en')
    setValue('preferredNameRadio', 'no')
    setValue('pronounsRadio', 'no')
    handleSubmit(onSubmit)()
  }, [profile.email, setValue, handleSubmit, onSubmit])

  useEffect(() => {
    setToolName('Skip')
    setToolFunction(() => fillWithData)
    return () => {
      setToolName(null)
      setToolFunction(null)
    }
  }, [setToolFunction, setToolName, fillWithData])

  const renderFirstName = () => {
    return (
      <ControllerInput
        name="firstName"
        rules={{
          required: I18n.t('ProfileScreen.error.invalidGivenName'),
        }}
        testID="firstNameFormInput"
        control={control}
        label={I18n.t('ProfileScreen.givenName')}
        infotip={I18n.t('ProfileScreen.hint.nameHint')}
      />
    )
  }

  const renderLastName = () => {
    return (
      <ControllerInput
        name="lastName"
        rules={{
          required: I18n.t('ProfileScreen.error.invalidFamilyName'),
        }}
        testID="lastNameFormInput"
        control={control}
        label={I18n.t('ProfileScreen.familyName')}
        infotip={I18n.t('ProfileScreen.hint.nameHint')}
      />
    )
  }

  const renderDateOfBirth = () => {
    return (
      <ControllerDatePicker
        control={control}
        name="dateOfBirth"
        rules={{
          required: I18n.t('ProfileScreen.error.invalidBdayRequiredField'),
          validate: {
            isValid: (value) =>
              validateIsValidDate(value) || I18n.t('ProfileScreen.error.invalidDate'),
            isAdult: (value) => validateIsAdult(value) || I18n.t('ProfileScreen.error.invalidBday'),
          },
        }}
        testID="dobFormInput"
        isFocused={focusedField === 'dateOfBirth'}
        label={I18n.t('ProfileScreen.bdayWeb')}
        onFocus={() => setFocusedField('dateOfBirth')}
        value={getValues().dateOfBirth}
        onSelect={() => {
          setFocusedField(null)
          trigger('dateOfBirth')
        }}
        setValue={(_, value) => setValue('dateOfBirth', value)}
      />
    )
  }

  const renderProvinces = () => {
    const shouldProvideProvince = getShouldProvideProvince()
    if (!shouldProvideProvince) return

    const sortedProvinces = KeyLabelArrays.PROVINCES.sort((a, b) => a.label.localeCompare(b.label))

    return (
      <ControllerPicker
        name="province"
        rules={{
          required: I18n.t('ProfileScreen.error.invalidJurisdictionRequiredField'),
        }}
        control={control}
        label={I18n.t('UpdateProfileScreen.province')}
        placeholder={I18n.t('ProfileScreen.residesIn')}
        items={sortedProvinces}
        testID="UPDATE_PROFILE_SCREEN_PROVINCE_FIELD"
        isFocused={focusedField === 'jurisdiction'}
        analyticsName={'province'}
      />
    )
  }

  const renderPhoneNumber = () => {
    return (
      <ControllerInput
        name="phoneNumber"
        rules={{
          required: I18n.t('ProfileScreen.error.invalidNumber'),
        }}
        control={control}
        label={I18n.t('ProfileScreen.number')}
        infotip={I18n.t('ProfileScreen.hint.numberHint')}
        testID="UPDATE_PROFILE_SCREEN_PHONE_NUMBER_FIELD"
        keyboardType="phone-pad"
      />
    )
  }

  const renderPreferredLanguage = () => {
    return (
      <ControllerPicker
        name="preferredLanguage"
        rules={{
          required: I18n.t('ProfileScreen.error.invalidLanguage'),
        }}
        control={control}
        label={I18n.t('UpdateProfileScreen.communicationPref')}
        placeholder={I18n.t('ProfileScreen.language')}
        items={KeyLabelArrays.LANGUAGES}
        testID="UPDATE_PROFILE_LANG_INPUT"
        isFocused={focusedField === 'language'}
        analyticsName="Language"
      />
    )
  }

  const renderPreferredName = () => {
    const showPreferredName = watchPreferredNameRadio === 'yes'
    const firstName = getValues().firstName
    const radioLabel = firstName
      ? `${I18n.t('ProfileScreen.preferredNameRadio')}: ${firstName}?`
      : `${I18n.t('ProfileScreen.preferredNameRadio')}?`
    return (
      <>
        <ControllerRadioGroup
          name={'preferredNameRadio'}
          control={control}
          rules={{
            required: true,
            value: I18n.t('ProfileScreen.error.invalidPreferredName'),
          }}
          label={radioLabel}
          options={[
            {
              label: I18n.t('ProfileScreen.yes'),
              value: 'yes',
            },
            {
              label: I18n.t('ProfileScreen.no'),
              value: 'no',
            },
          ]}
          buttonContainerStyle={Styles.radioButtonContainer}
        />
        <ControllerInput
          name="preferredName"
          control={control}
          rules={{
            required: showPreferredName
              ? I18n.t('ProfileScreen.error.invalidPreferredName')
              : false,
          }}
          label={I18n.t('ProfileScreen.preferredName')}
          infotip={I18n.t('ProfileScreen.hint.preferredNameHint')}
          testID="UPDATE_PROFILE_SCREEN_PREFERRED_NAME_FIELD"
          hide={!showPreferredName}
        />
      </>
    )
  }

  const renderPronouns = () => {
    const showPronoun = watchPronounRadio === 'yes'
    const radioLabel = `${I18n.t('ProfileScreen.pronounsRadio')}`
    return (
      <>
        <ControllerRadioGroup
          name="pronounsRadio"
          control={control}
          rules={{
            required: true,
            value: I18n.t('ProfileScreen.error.invalidPronouns'),
          }}
          label={radioLabel}
          options={[
            {
              label: I18n.t('ProfileScreen.yes'),
              value: 'yes',
            },
            {
              label: I18n.t('ProfileScreen.no'),
              value: 'no',
            },
          ]}
          buttonContainerStyle={Styles.radioButtonContainer}
        />

        <ControllerInput
          name="pronouns"
          control={control}
          rules={{
            required: showPronoun ? I18n.t('ProfileScreen.error.invalidPronouns') : false,
          }}
          label={I18n.t('ProfileScreen.pronouns')}
          infotip={I18n.t('ProfileScreen.hint.pronounsHint')}
          testID="UPDATE_PROFILE_SCREEN_PRONOUN_FIELD"
          onFocus={() => setFocusedField('pronouns')}
          inputContainerStyle={Styles.pronounsInputContainer}
          containerStyle={Styles.pronounsContainer}
          hide={!showPronoun}
        />
      </>
    )
  }

  return (
    <View style={Styles.outerContainer}>
      <ResponsiveScrollView>
        <ResponsiveView style={Styles.innerContainer}>
          <SignupLogoHeader
            title={I18n.t('OnboardingProfileScreen.title')}
            subtitle={I18n.t('OnboardingProfileScreen.subtitle')}
          />
          {renderFirstName()}
          {renderLastName()}
          {renderDateOfBirth()}
          {renderProvinces()}
          {renderPhoneNumber()}
          {showCommunicationPreference && renderPreferredLanguage()}
          {renderPreferredName()}
          {showPronouns && renderPronouns()}
          <Button
            widthVariant="full"
            title={I18n.t('OnboardingProfileScreen.next')}
            analyticsName="Next"
            onPress={() => {
              Keyboard.dismiss()
              setFocusedField(null)
              handleSubmit(onSubmit)()
            }}
            activityIndicatorColor={Colors.buttonPrimaryText}
            showActivityIndicator={busy}
            disabled={!isDirty || !isValid}
          />
        </ResponsiveView>
      </ResponsiveScrollView>
      {!focusedField && <DialogueBranding />}
    </View>
  )
}

const mapStateToProps = (state, props) => {
  return {
    ...props.route?.params,
    busy: state.patient.requestRunning,
    profile: state.patient.profile || {},
    signupProfileData: state.login.signupProfileData || {},
    showPronouns: state.features?.pronounDisplay,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    patientUpdateBasicProfile: ({
      firstName,
      preferredName,
      pronouns,
      lastName,
      dateOfBirth,
      province,
      phoneNumber,
      preferredLanguage,
    }) =>
      dispatch(
        PatientActions.patientProfileBasicUpdateRequest({
          givenName: firstName.trim(),
          preferredName: preferredName?.trim(),
          pronouns: pronouns?.trim(),
          familyName: lastName.trim(),
          dateOfBirth: dateOfBirth,
          residesIn: province,
          phoneNumber: phoneNumber.replace(/\D+/g, ''),
          preferred_language: preferredLanguage,
        })
      ),
    setIsOnboarding: (value) => dispatch(PatientActions.setIsOnboarding(value)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingProfileScreen)
