import { StyleSheet } from 'react-native'
import { Colors, ApplicationStyles } from 'APP/Themes'

export const Styles = StyleSheet.create({
  ...ApplicationStyles.screen,
  container: {
    flex: 1,
    backgroundColor: Colors.appBg,
    justifyContent: 'center',
  },
  transitionContainer: {
    flex: 1,
  },
  postFailureModalText: {
    color: Colors.text,
    textAlign: 'center',
  },
  loading: {
    position: 'absolute',
    alignSelf: 'center',
  },
  genericError: {
    position: 'absolute',
    alignSelf: 'center',
    paddingTop: 100,
    textAlign: 'center',
    color: Colors.text,
  },
  navBar: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.grayBg,
  },
})
