import React from 'react'
import { Platform } from 'react-native'
import NetworkLogger from 'react-native-network-logger'
import { createCustomStackNavigator } from 'APP/Nav/CustomStackNavigator'

// Services
import I18n from 'APP/Services/i18n'

// Components
import { withModalToast } from 'APP/Components/Toast'
import { withBottomSheetModal } from 'APP/Converse/BottomSheetModal'
import { HeaderModalCloseButton, HeaderMentalHealthHelpButton } from 'APP/Nav/Components/Buttons'

// Containers
import AccountLinkingScreen from 'APP/Containers/AccountLinkingScreen'
import AccountScreen from 'APP/Containers/AccountScreen'
import AddPaymentScreen from 'APP/Containers/AddPaymentScreen'
import BodyPartScreen from 'APP/Containers/BodyPartScreen'
import CarePlanScreen from 'APP/Containers/CarePlanScreen'
import ChangeEmailScreen from 'APP/Containers/ChangeEmailScreen'
import ClaimAccessScreen from 'APP/Containers/ClaimAccessScreen'
import CloseAccountScreen from 'APP/Containers/CloseAccountScreen'
import CmsScreen from 'APP/Containers/CmsScreen'
import ConsentScreen from 'APP/Containers/ConsentScreen'
import ContactUsScreen from 'APP/Containers/ContactUsScreen'
import ConversationScreen from 'APP/Containers/ConversationScreen'
import DependentConsentScreen from 'APP/Containers/DependentConsentScreen'
import DependentProfileScreen from 'APP/Containers/DependentProfileScreen'
import DeviceLogsScreen from 'APP/Containers/DeviceLogsScreen'
import FamilyScreen from 'APP/Containers/FamilyScreen'
import GeolocationScreen from 'APP/Containers/GeolocationScreen'
import GeolocationServiceScreen from 'APP/Containers/GeolocationServiceScreen'
import IncomingCallScreen from 'APP/Containers/IncomingCallScreen'
import InviteFamilyMemberScreen from 'APP/Containers/InviteFamilyMemberScreen'
import LegalScreen from 'APP/Containers/LegalScreen'
import LoadingScreen from 'APP/Containers/LoadingScreen'
import LockScreen from 'APP/Containers/LockScreen'
import MandatoryMFAScreen from 'APP/Containers/MandatoryMFAScreen'
import MicroAppScreen from 'APP/Containers/MicroAppScreen'
import PaymentScreen from 'APP/Containers/PaymentScreen'
import PhotoIdScreen from 'APP/Containers/PhotoIdScreen'
import PublicComputerScreen from 'APP/Containers/PublicComputerScreen'
import ProfileScreen from 'APP/Containers/ProfileScreen'
import OnboardingProfileScreen from 'APP/Containers/OnboardingProfileScreen'
import PushNotificationPermissionModalScreen from 'APP/Containers/PushNotificationPermissionModalScreen'
import PushNotificationPermissionScreen from 'APP/Containers/PushNotificationPermissionScreen'
import SecurityScreen from 'APP/Containers/SecurityScreen'
import AddDependentScreen from 'APP/Containers/AddDependentScreen'
import UpdateProfileScreen from 'APP/Containers/UpdateProfileScreen'
import VerifyEmailHelpScreen from 'APP/Containers/VerifyEmailHelpScreen'
import VerifyEmailScreen from 'APP/Containers/VerifyEmailScreen'
import VerifyEmailSuccessScreen from 'APP/Containers/VerifyEmailSuccessScreen'
import VideoCallScreen from 'APP/Containers/VideoCallScreen'
import WebContentScreen from 'APP/Containers/WebContentScreen'
import WelcomeScreen from 'APP/Containers/WelcomeScreen'
import GenericInfoWarningScreen from 'APP/Containers/GenericInfoWarningScreen'
import UnsupportedVersionScreen from 'APP/Containers/UnsupportedVersionScreen'
import LoginErrorScreen from 'APP/Containers/LoginErrorScreen'
import CompletedChallengesScreen from 'APP/Containers/CompletedChallengesScreen'
import ChallengeScreen from 'APP/Containers/ChallengeScreen'
import WellnessCenterTeamDetailScreen from 'APP/Containers/WellnessCenterTeamDetailScreen'
import WellnessCenterVideoScreen from 'APP/Containers/WellnessCenterVideoScreen'
import InvitationEmailScreen from 'APP/Containers/InvitationEmailScreen'
import CmsAudioScreen from 'APP/Containers/CmsAudioScreen'
import HealthTrackerSettingsScreen from 'APP/Containers/HealthTrackerSettingsScreen'
import CoverageScreen from 'APP/Containers/CoverageScreen'
import ClaimAccessSuccessScreen from 'APP/Containers/ClaimAccessSuccessScreen'
import ChallengedEnrolmentsSearchScreen from 'APP/Containers/ChallengedEnrolmentsSearchScreen'
import CheckEmailScreen from 'APP/Containers/CheckEmailScreen'
import ChallengedEnrolmentUniqueIdScreen from 'APP/Containers/ChallengedEnrolmentUniqueIdScreen'
import ExploreHabitsScreen from 'APP/Containers/ExploreHabitsScreen'
import HabitDetailScreen from 'APP/Containers/HabitDetailScreen'
import ScheduledLocalNotificationListScreen from 'APP/Containers/ScheduledLocalNotificationListScreen'
import NotificationsScreen from 'APP/Containers/NotificationsScreen'
import RecentActiveHistoryScreen from 'APP/Containers/RecentActiveHistoryScreen'
import SeeMoreActiveHistoryScreen from 'APP/Containers/SeeMoreActiveHistoryScreen'
import ServiceCategory from 'APP/Containers/ServiceCategory'
import UpcomingAppointmentScreen from 'APP/Containers/UpcomingAppointmentScreen'
import WellBeingIndexScreen from 'APP/Containers/WellBeingIndexScreen'
import VerifyNewEmailScreen from 'APP/Containers/VerifyNewEmailScreen'
import VerifyNewEmailResultScreen from 'APP/Containers/VerifyNewEmailResultScreen'
import CommitmentScreen from 'APP/Containers/CommitmentScreen'
import ServiceGroup from 'APP/Containers/ServiceGroup'
import NotificationDetailScreen from 'APP/Containers/NotificationDetailScreen'
import TakeATourScreen from 'APP/Containers/TakeATourScreen'
import PillwayPortalScreen from 'APP/Containers/PillwayPortalScreen'
import MultiLoginScreen from 'APP/Containers/MultiLoginScreen'
import VideoCallEndedScreen from 'APP/Containers/VideoCallEndedScreen'

// Tab Containers
import { TabBarScreen } from 'APP/Nav/TabSets/TabBar/Screen'

import {
  screenOptions,
  screenOptionsForModals,
  screenOptionsForTransparentModals,
  screenOptionsForBottomSheetModals,
} from 'APP/Nav/ScreenOptions'
import { EligibilityClaimScreen } from 'APP/Containers/EligibilityClaimScreen'
import { PractitionerScreen } from 'APP/Containers/PractitionerScreen'
import HealthProfileConversationScreen from 'APP/Containers/HealthProfileConversationScreen'
import TenantSwitcherScreen from 'APP/Containers/TenantSwitcherScreen'
import ChangeEmailConfirmationScreen from 'APP/Containers/ChangeEmailConfirmationScreen'

const Stack = createCustomStackNavigator()

export const StackScreens = () => (
  <Stack.Navigator id="screenStack" initialRouteName="loading">
    <Stack.Group screenOptions={screenOptions}>
      {/* Screens presented as modals (shows menu, if applicable) */}
      <Stack.Group screenOptions={screenOptionsForModals}>
        <Stack.Screen name="microApp" component={withModalToast(MicroAppScreen)} />
        <Stack.Screen name="microAppDrawer" component={withModalToast(MicroAppScreen)} />
        <Stack.Screen name="cmsVideoScreen" component={withModalToast(WellnessCenterVideoScreen)} />
        <Stack.Screen name="cmsAudioScreen" component={CmsAudioScreen} />
        <Stack.Screen name="habitDetailScreen" component={withModalToast(HabitDetailScreen)} />
        <Stack.Screen name="cmsScreen" component={withModalToast(CmsScreen)} />
        <Stack.Screen name="pillwayPortalScreen" component={withModalToast(PillwayPortalScreen)} />
      </Stack.Group>

      {/* Screens presented as fullscreen modals (overlays menu) */}
      <Stack.Group screenOptions={screenOptionsForTransparentModals}>
        <Stack.Screen name="incomingCall" component={IncomingCallScreen} />
        <Stack.Screen
          name="videoCall"
          component={VideoCallScreen}
          options={{ headerShown: false, __statusBarStyle: 'light' }}
        />
        <Stack.Screen name="verifyNewEmail" component={VerifyNewEmailScreen} />
        <Stack.Screen name="verifyNewEmailResult" component={VerifyNewEmailResultScreen} />
        <Stack.Screen
          name="videoCallEnded"
          component={VideoCallEndedScreen}
          options={{ headerShown: false }}
        />
      </Stack.Group>

      {/* Screens presented as bottom sheet modals */}
      <Stack.Group screenOptions={screenOptionsForBottomSheetModals}>
        <Stack.Screen
          name="pushNotificationPermissionModal"
          component={withBottomSheetModal(PushNotificationPermissionModalScreen)}
        />
        <Stack.Screen
          name="tenantSwitcherScreen"
          component={withBottomSheetModal(TenantSwitcherScreen)}
        />
      </Stack.Group>

      <Stack.Group screenOptions={{ headerShown: true }}>
        <Stack.Screen
          name="practitionerProfile"
          component={PractitionerScreen}
          options={{
            title: I18n.t('PractitionerScreen.title'),
          }}
        />
        <Stack.Screen
          name="challengedEnrolmentsSearchScreen"
          component={ChallengedEnrolmentsSearchScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="challengedEnrolmentUniqueId"
          component={ChallengedEnrolmentUniqueIdScreen}
        />
        <Stack.Screen name="eligibilityClaimScreen" component={EligibilityClaimScreen} />
        <Stack.Screen name="claimAccessScreen" component={ClaimAccessScreen} />
        <Stack.Screen name="claimAccessSuccessScreen" component={ClaimAccessSuccessScreen} />
        <Stack.Screen name="contactUs" component={ContactUsScreen} />
        <Stack.Screen name="infoWarning_noInvitationsFound" component={GenericInfoWarningScreen} />
        <Stack.Screen name="invitationEmail" component={InvitationEmailScreen} />
      </Stack.Group>

      <Stack.Screen
        name="wellBeingIndex"
        component={WellBeingIndexScreen}
        options={{
          headerShown: true,
          title: Platform.OS === 'web' ? I18n.t('WellBeingIndexScreen.title') : undefined,
        }}
      />
      <Stack.Screen name="tabbar" component={TabBarScreen} />
      <Stack.Screen
        name="profile"
        component={ProfileScreen}
        options={{ title: I18n.t('ProfileScreen.title'), headerShown: true }}
      />
      <Stack.Screen name="loading" component={LoadingScreen} />
      <Stack.Screen name="welcome" component={WelcomeScreen} />
      <Stack.Screen name="accountLinking" component={AccountLinkingScreen} />
      <Stack.Screen
        name="verifyEmail"
        component={VerifyEmailScreen}
        options={{ headerShown: true }}
      />
      <Stack.Screen
        name="verifyEmailHelp"
        component={VerifyEmailHelpScreen}
        options={{ headerShown: true }}
      />
      <Stack.Screen name="verifyEmailSuccess" component={VerifyEmailSuccessScreen} />
      <Stack.Screen name="consent" component={ConsentScreen} />
      <Stack.Screen name="newProfile" component={OnboardingProfileScreen} />
      <Stack.Screen name="mandatoryMFAScreen" component={MandatoryMFAScreen} />
      <Stack.Screen
        name="newGeolocation"
        component={GeolocationScreen}
        options={{ headerShown: true, title: I18n.t('GeolocationScreen.title') }}
      />
      <Stack.Screen
        name="geolocationService"
        component={GeolocationServiceScreen}
        options={{ headerShown: true, title: I18n.t('GeolocationServiceScreen.title') }}
      />
      <Stack.Screen
        name="updateGeolocation"
        component={GeolocationScreen}
        options={{ headerShown: true, title: I18n.t('GeolocationScreen.title') }}
      />
      <Stack.Screen
        name="pushNotificationPermission"
        component={PushNotificationPermissionScreen}
      />
      <Stack.Screen
        name="unsupportedVersion"
        component={UnsupportedVersionScreen}
        options={{
          headerShown: false,
          title: I18n.t('UnsupportedVersion.title'),
          __statusBarStyle: 'light',
        }}
      />
      <Stack.Screen
        name="loginErrorScreen"
        component={LoginErrorScreen}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="checkEmail"
        component={CheckEmailScreen}
        options={{ headerShown: true }}
      />
      <Stack.Screen name="applock" component={LockScreen} />
      <Stack.Screen
        name="family"
        component={FamilyScreen}
        options={{
          headerShown: true,
          title: I18n.t('FamilyScreen.title'),
        }}
      />
      <Stack.Screen
        name="inviteFamilyMember"
        component={InviteFamilyMemberScreen}
        options={{
          headerShown: true,
          title: I18n.t('FamilyScreen.addAdult.title'),
        }}
      />
      <Stack.Screen
        name="newDependent"
        component={AddDependentScreen}
        options={{
          title: I18n.t('FamilyScreen.AddDependentScreen.add.title'),
          headerShown: true,
        }}
      />
      <Stack.Screen
        name="dependentProfile"
        component={DependentProfileScreen}
        options={{
          title: I18n.t('FamilyScreen.DependentProfileScreen.title'),
          headerShown: true,
        }}
      />
      <Stack.Screen
        name="updateDependentPhotoId"
        component={PhotoIdScreen}
        options={{
          headerShown: true,
          headerTitle: I18n.t('PhotoIdScreen.title'),
        }}
      />
      <Stack.Screen
        name="dependentConsent"
        component={DependentConsentScreen}
        options={{
          headerShown: true,
          title: I18n.t('DependentConsentScreen.title'),
        }}
      />
      <Stack.Screen
        name="account"
        component={AccountScreen}
        options={{
          headerShown: true,
          title: I18n.t('AccountScreen.title'),
        }}
      />
      <Stack.Screen
        name="editProfile"
        component={UpdateProfileScreen}
        options={{
          title: I18n.t('UpdateProfileScreen.title'),
          headerShown: true,
          headerBackVisible: false,
        }}
      />
      <Stack.Screen
        name="security"
        component={SecurityScreen}
        options={{
          headerShown: true,
        }}
      />
      <Stack.Screen
        name="legal"
        component={LegalScreen}
        options={{
          headerShown: true,
          title: I18n.t('LegalScreen.title'),
        }}
      />
      <Stack.Screen
        name="helpDocument"
        component={WebContentScreen}
        options={{
          headerShown: true,
          title: I18n.t('HelpScreen.title'),
        }}
      />
      <Stack.Screen
        name="consentDocument"
        component={WebContentScreen}
        options={{
          headerShown: true,
          title: I18n.t('LegalScreen.consent'),
        }}
      />
      <Stack.Screen
        name="embeddedUrlWithAuth"
        component={WebContentScreen}
        options={{
          headerShown: true,
          /*
            TODO: Change how this is structured, make it fully generic or make it content-specific.
            It's pretty wrong to have them mixed like this. (injecting mental health into all embedded url)
          */
          headerRight: HeaderMentalHealthHelpButton,
          headerLeft: HeaderModalCloseButton,
        }}
      />
      <Stack.Screen
        name="guardianConsentDocument"
        component={WebContentScreen}
        options={{
          headerShown: true,
          title: I18n.t('DependentConsentScreen.title'),
        }}
      />
      <Stack.Screen
        name="updatePhotoId"
        component={PhotoIdScreen}
        options={{
          title: I18n.t('PhotoIdScreen.title'),
          headerShown: true,
        }}
      />
      <Stack.Screen
        name="addPayment"
        component={AddPaymentScreen}
        options={{
          headerShown: true,
          headerBackVisible: false,
          title: I18n.t('PaymentScreen.add.title'),
        }}
      />
      <Stack.Screen
        name="emptyPayment"
        component={PaymentScreen}
        options={{
          headerShown: true,
          title: I18n.t('PaymentScreen.title'),
        }}
      />
      <Stack.Screen
        name="viewPayment"
        component={PaymentScreen}
        options={{
          headerShown: true,
          title: I18n.t('PaymentScreen.title'),
        }}
      />
      <Stack.Screen name="conversation" component={ConversationScreen} />
      <Stack.Screen
        name="newConversation"
        component={ConversationScreen}
        options={{
          animation: 'slide_from_bottom',
        }}
      />
      <Stack.Screen
        name="serviceCategory"
        component={ServiceCategory}
        options={{ headerShown: true, headerTitle: '' }}
      />
      <Stack.Screen
        name="bodyPart"
        component={BodyPartScreen}
        options={{
          title: I18n.t('BodyPart.screen.title'),
          headerShown: true,
        }}
      />
      <Stack.Screen
        name="carePlan"
        component={CarePlanScreen}
        options={{
          headerShown: true,
          title: I18n.t('CarePlanScreen.carePlan.title'),
        }}
      />
      <Stack.Screen
        name="changeEmail"
        component={ChangeEmailScreen}
        options={{
          title: I18n.t('ChangeEmailScreen.title'),
          headerShown: true,
        }}
      />
      <Stack.Screen
        name="promotedContent"
        component={WebContentScreen}
        options={{
          title: I18n.t('HomeScreen.promotedContentTitle'),
          headerShown: true,
        }}
      />
      <Stack.Screen
        name="deviceLogsScreen"
        component={DeviceLogsScreen}
        options={{ headerShown: true }}
      />
      <Stack.Screen name="publicComputer" component={PublicComputerScreen} />
      <Stack.Screen
        name="networkLogger"
        component={NetworkLogger}
        options={{ headerShown: true }}
      />
      <Stack.Screen
        name="scheduledLocalNotificationList"
        component={ScheduledLocalNotificationListScreen}
        options={{
          headerShown: true,
          title: I18n.t('ScheduledLocalNotificationListScreen.title'),
        }}
      />
      <Stack.Screen
        name="wcCompletedChallenges"
        component={CompletedChallengesScreen}
        options={{
          headerShown: true,
          title: I18n.t('WellnessCenter.challenges.completed.title'),
        }}
      />
      <Stack.Screen
        name="wcChallenge"
        component={ChallengeScreen}
        options={{
          /* TODO: Remove this hardcoded string, replace with i18n string. Get text from Holly? */
          title: 'Challenge',
          headerShown: true,
        }}
      />
      <Stack.Screen
        name="wcChallengeTeam"
        component={WellnessCenterTeamDetailScreen}
        options={{
          headerShown: true,
        }}
      />
      <Stack.Screen
        name="healthTrackerSettings"
        component={HealthTrackerSettingsScreen}
        options={{
          headerShown: true,
          title: I18n.t('HealthTrackerSettings.title'),
        }}
      />
      <Stack.Screen
        name="coverage"
        component={CoverageScreen}
        options={{
          headerShown: true,
          title: I18n.t('CoverageScreen.title'),
        }}
      />
      <Stack.Screen
        name="closeAccount"
        component={CloseAccountScreen}
        options={{
          headerShown: true,
          title: I18n.t('LegalScreen.closeAccount'),
        }}
      />
      <Stack.Screen
        name="exploreHabitsScreen"
        component={ExploreHabitsScreen}
        options={{
          /*
            TODO: Implement slim navbar, or abandon the idea.
            Personal opinion: not worth it. To confirm with Holly and Heather

            Setting the header height isn't supported out of box by react-navigation.
            They tend towards native guidelines, which don't accomodate navbar height changes
            https://developer.apple.com/documentation/uikit/uinavigationcontroller/customizing_your_app_s_navigation_bar

            If we want to do this for sure, we would need to use something like this that
            reimplements the navbar functionality: header: () => (<CustomSlimNavBar />)
          */
          headerShadowVisible: false,
          headerShown: true,
          title: I18n.t('ExploreHabitsScreen.title'),
        }}
      />
      <Stack.Screen
        name="notifications"
        component={NotificationsScreen}
        options={{
          headerShown: true,
        }}
      />
      <Stack.Screen
        name="recentActiveHistory"
        component={RecentActiveHistoryScreen}
        options={{
          headerShown: true,
          title: I18n.t('RecentActiveHistoryScreen.title'),
        }}
      />
      <Stack.Screen
        name="seeMoreActiveHistory"
        component={SeeMoreActiveHistoryScreen}
        options={{
          headerShown: true,
          title: I18n.t('SeeMoreActiveHistoryScreen.title'),
        }}
      />
      <Stack.Screen
        name="upcomingAppointment"
        component={UpcomingAppointmentScreen}
        options={{
          headerShown: true,
          title: I18n.t('UpcomingAppointmentScreen.title'),
        }}
      />
      <Stack.Screen name="commitment" component={CommitmentScreen} />
      <Stack.Screen name="healthProfileConversation" component={HealthProfileConversationScreen} />
      <Stack.Screen
        name="changeEmailConfirmation"
        component={ChangeEmailConfirmationScreen}
        options={{
          headerShown: true,
        }}
      />
      <Stack.Screen name="serviceGroup" component={ServiceGroup} options={{ headerShown: true }} />
      <Stack.Screen name="takeATour" component={TakeATourScreen} />
      <Stack.Screen
        name="notificationDetail"
        component={NotificationDetailScreen}
        options={{ headerShown: true }}
      />
      <Stack.Screen
        name="multiLogin"
        component={MultiLoginScreen}
        options={{
          headerShown: true,
          title: I18n.t('MultiLoginScreen.title'),
        }}
      />
    </Stack.Group>
  </Stack.Navigator>
)
