import styled from 'styled-components/native'

import { AvatarProps } from './types'

export const SIZES = {
  small: 16,
  regular: 24,
  medium: 32,
  large: 40,
}

const sizeFn = ({ size = 'medium' }: Pick<AvatarProps, 'size'>) => SIZES[size]

export const Avatar = styled.Image<AvatarProps>`
  height: ${sizeFn}px;
  width: ${sizeFn}px;
  border-radius: ${sizeFn}px;
`
