import { useCallback, useReducer, useRef } from 'react'
import debounce from 'lodash.debounce'

import Config from 'APP/Config'
import { isCanada } from 'APP/Helpers/RegionHelpers'

export const initialSearchState = {
  error: undefined,
  running: false,
  results: [],
  query: undefined,
}

export function SearchReducer(state, action) {
  switch (action.type) {
    case 'fetchStart':
      return {
        ...state,
        error: undefined,
        running: true,
        query: action.query,
      }
    case 'fetchSuccess':
      if (state.query === action.query) {
        return {
          ...state,
          error: undefined,
          running: false,
          results: action.results || initialSearchState.results,
        }
      }
      return {
        ...state,
        running: false,
      }
    case 'fetchFailure':
      return {
        ...state,
        error: action.error,
        running: false,
      }
    case 'resetState':
      return initialSearchState
    default:
      throw new Error()
  }
}

const defaultSearchOptions = {
  ...(isCanada() && { country: Config.REGION }),
  type: 'address',
  placeFields: [
    'place_id',
    'name',
    'formatted_address',
    'address_components',
    'geometry.location',
    'international_phone_number',
  ],
}

export const usePlacesSearch = (searchOptionsRequested = {}, debounceMs = 1000) => {
  const autocompleteService = useRef(new google.maps.places.AutocompleteService()).current
  const placesService = useRef(
    new google.maps.places.PlacesService(document.createElement('div'))
  ).current
  const { type, country, placeFields } = Object.assign(
    {},
    defaultSearchOptions,
    searchOptionsRequested
  )
  const [state, dispatch] = useReducer(SearchReducer, initialSearchState)
  const callId = useRef()

  const getPlacePredictions = useCallback(
    async (query) => {
      const results = await autocompleteService.getPlacePredictions({
        input: query,
        types: [type],
        componentRestrictions: { country },
      })
      return results?.predictions
    },
    [type, country, autocompleteService]
  )

  const debouncedFetch = useRef(
    debounce(async (query) => {
      const currentId = Date.now()
      callId.current = currentId

      let results
      let error

      try {
        if (query) {
          results = await getPlacePredictions(query)
        }
      } catch (e) {
        error = e
      }

      if (currentId === callId.current) {
        if (error) {
          dispatch({ type: 'fetchFailure', error })
        } else {
          dispatch({ type: 'fetchSuccess', query, results })
        }
      }
    }, debounceMs)
  ).current

  const resolvePlace = useCallback(
    async (placeId) => {
      const place = await new Promise((resolve, reject) =>
        placesService.getDetails(
          { placeId, fields: placeFields },
          (placeResults, PlacesServiceStatus) => {
            if (PlacesServiceStatus === 'OK') {
              resolve(placeResults)
            } else {
              reject(PlacesServiceStatus)
            }
          }
        )
      )
      return place
    },
    [placesService, placeFields]
  )

  const submitQuery = useCallback(
    (query) => {
      if (query) {
        dispatch({ type: 'fetchStart', query })
        debouncedFetch(query)
      } else {
        dispatch({ type: 'resetState' })
      }
    },
    [debouncedFetch]
  )

  return [state, submitQuery, resolvePlace, getPlacePredictions]
}
