import { datadogRum } from '@datadog/browser-rum'
import { globalContextConfig, sharedInitConfig } from './utils'
import Config from 'APP/Config/index'
import { UserInfo } from '@datadog/mobile-react-native/src/sdk/UserInfoSingleton/types'
import { appInfo } from 'APP/Lib/Initializers'
import { ldClient } from 'APP/Services/LaunchDarkly.web'

export const initDatadogRUM = async () => {
  // Temporarily setting session sample rate to 0
  // We will enable it later after gaining a better understanding of the missing masking issue on images.

  // NOTE: Before using a feature flag to fetch the sample rate,
  // ensure the LaunchDarkly SDK is initialized in `initializers.js` before calling this function,
  // or it will not work.

  datadogRum.init({
    ...sharedInitConfig,
    sessionSampleRate: 0,
  })

  // Set global context for Datadog RUM
  datadogRum.setGlobalContext({ ...globalContextConfig, ...appInfo })
  //Dynamically update the environment tag in the global context
  Config.onConfigKeyChange('ENVIRONMENT', (done, updatedEnv: string) => {
    // TODO: We should stop the session and re-init the SDK here so it uses the proper site and env tag.
    // This isn't possible at the moment, so instead we add the `environment` context property.
    datadogRum.setGlobalContextProperty('environment', updatedEnv.toLowerCase())
    done()
  })
} 

// Platform-specific implementations
export const trackDdEvent = (eventName: string, context?: object) => {
  datadogRum.addAction(eventName, context)
}

export const startDdView = (viewName: string) => {
  datadogRum.startView(viewName)
}

export const logDdError = (
  message: string,
  stacktrace: string,
  source: string = 'CUSTOM',
  context?: object
) => {
  datadogRum.addError(message, {
    source,
    stacktrace,
    context,
  })
}

export const setDdUser = (props: UserInfo) => {
  datadogRum.setUser(props)
}

