import React from 'react'
import Typography from 'APP/Converse/Typography'
import styled from 'styled-components/native'
import Icon from 'APP/Converse/Icon'
import Button from 'APP/Converse/Button'

const Container = styled.View`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.elementsBg};
  padding: 16px 0;
  justify-content: center;
  align-items: center;
`

const Banner = styled.View<{ maxWidth?: number }>`
  display: flex;
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth}px;
  padding-left: 26px;
  padding-right: 4px;
  justify-content: space-between;
  flex-direction: row;
`

const Content = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`

const TextContainer = styled.View`
  display: flex;
  gap: 4px;
`

type Props = {
  title: string
  time: string
  onCtaPress: () => void
  ctaLabel: string
  maxWidth?: number
}

export default function AppointmentBanner({
  title,
  time,
  onCtaPress,
  ctaLabel,
  maxWidth = 800,
}: Props) {
  return (
    <Container
      testID="appointmentBanner"
      accessibilityLabel={`Appointment banner: ${title} ${time}`}
    >
      <Banner maxWidth={maxWidth}>
        <Content>
          <Icon src="calendar-month" size={24} variant="materialIcons" color="accent" />
          <TextContainer>
            <Typography variant="bodySmallBold">{title}</Typography>
            <Typography variant="bodySmall">{time}</Typography>
          </TextContainer>
        </Content>
        <Button
          onPress={onCtaPress}
          title={ctaLabel}
          variant="tertiary"
          icon="arrow-forward-ios"
          iconVariant="materialIcons"
          iconPosition="right"
          iconSize={14}
          widthVariant="content"
          testID="appointmentBannerCta"
          small
          analyticsName="Appointment banner"
        />
      </Banner>
    </Container>
  )
}
