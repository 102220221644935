import { StyleSheet } from 'react-native'
import { Colors, Fonts, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  container: {
    marginTop: Metrics.baseMargin * 1.5,
    marginHorizontal: Metrics.baseMargin,
    padding: Metrics.baseMargin * 1.5,
    borderRadius: 16,
    backgroundColor: Colors.bannerBg,
  },
  image: {
    alignSelf: 'center',
    marginBottom: Metrics.baseMargin * 1.5,
  },
  copy: {
    ...Fonts.style.body,
    color: Colors.text,
    textAlign: 'center',
    paddingBottom: Metrics.baseMargin,
  },
  cta: {
    marginTop: Metrics.baseMargin / 2,
    marginHorizontal: 0,
    marginBottom: 0,
  },
})
