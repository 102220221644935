import Immutable from 'seamless-immutable'

export const actionGenerator = (baseName) => ({
  [`${baseName}`]: ['args'],
  [`${baseName}Request`]: null,
  [`${baseName}Success`]: ['data'],
  [`${baseName}Failure`]: ['error'],
})

export const handlerGenerator = (baseName, startReducer, successReducer, failureReducer) => {
  const request = (state, action) => {
    const newState = Immutable({
      ...state,
      requests: {
        ...state.requests,
        [baseName]: {
          status: 'request',
        },
      },
    })
    return startReducer ? startReducer(newState, action) : newState
  }
  const success = (state, action) => {
    const newState = Immutable({
      ...state,
      requests: {
        ...state.requests,
        [baseName]: {
          status: 'success',
        },
      },
    })
    return successReducer ? successReducer(newState, action) : newState
  }
  const failure = (state, action) => {
    const newState = Immutable({
      ...state,
      requests: {
        ...state.requests,
        [baseName]: {
          status: 'error',
          error: action.error,
        },
      },
    })
    return failureReducer ? failureReducer(newState, action) : newState
  }

  return {
    [`${baseName}_REQUEST`]: request,
    [`${baseName}_SUCCESS`]: success,
    [`${baseName}_FAILURE`]: failure,
  }
}

export default {
  actionGenerator,
  handlerGenerator,
}
