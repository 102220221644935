import React, { useLayoutEffect, useRef, useState } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  Modal,
  TouchableWithoutFeedback,
  Pressable,
  Animated,
  KeyboardAvoidingView,
  Platform,
} from 'react-native'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import { Metrics, Colors } from 'APP/Themes'
import Toast from 'APP/Components/Toast'
import { useOnLayout } from 'APP/Hooks/Layout'
import Styles from './style'

const BottomSheetModal = ({
  isVisible: isVisibleProp,
  goBack,
  onClose,
  title,
  subtitle,
  children,
  testID,
  style,
  containerStyle = {},
  titleStyle = {},
  headerStyle = {},
}) => {
  const slideIn = useRef(new Animated.Value(0)).current
  const animate = (toValue, cb) =>
    Animated.spring(slideIn, { toValue, useNativeDriver: true, overshootClamping: true }).start(
      () => {
        if (typeof cb === 'function') cb()
      }
    )
  const [isVisible, setIsVisible] = useState(false)
  useLayoutEffect(() => {
    if (isVisibleProp) {
      setIsVisible(true)
      animate(1)
    } else {
      animate(0, () => setIsVisible(false))
    }
  }, [isVisibleProp])
  const [{ height: modalHeight }, onModalLayout] = useOnLayout()

  return (
    <Modal
      statusBarTranslucent
      animationType="fade"
      transparent
      visible={isVisible}
      onRequestClose={onClose}
      testID={testID}
    >
      <KeyboardAvoidingView
        style={Styles.keyboardAvoidingView}
        behavior={Platform.OS === 'ios' ? 'padding' : null}
        // negative value is needed to remove
        // extra bottom space if any
        // when keyboard is shown
        keyboardVerticalOffset={-Metrics.bottomSpace}
      >
        <Pressable onPress={onClose} style={Styles.overlay}>
          <TouchableWithoutFeedback>
            <Animated.View
              style={{
                transform: [
                  {
                    translateY: slideIn.interpolate({
                      inputRange: [0, 1],
                      outputRange: [modalHeight, 0],
                    }),
                  },
                ],
                ...containerStyle,
              }}
            >
              <View style={[Styles.modal, style]} onLayout={onModalLayout}>
                <View style={[Styles.header, headerStyle]}>
                  {goBack && (
                    <TouchableOpacity
                      onPress={goBack}
                      testID="modal.goBack"
                      style={Styles.goBackCta}
                    >
                      <MaterialIcon
                        name="arrow-back-ios"
                        size={Metrics.icons.small}
                        color={Colors.text}
                      />
                    </TouchableOpacity>
                  )}
                  <View style={Styles.headerText}>
                    {subtitle && <Text style={Styles.subtitle}>{subtitle}</Text>}
                    <Text style={[Styles.title, titleStyle]}>{title}</Text>
                  </View>
                  {!goBack && (
                    <TouchableOpacity onPress={onClose} testID="modal.close">
                      <MaterialIcon name="close" size={Metrics.icons.small} color={Colors.text} />
                    </TouchableOpacity>
                  )}
                </View>
                {children}
              </View>
            </Animated.View>
          </TouchableWithoutFeedback>
        </Pressable>
      </KeyboardAvoidingView>
      <Toast darkBackground />
    </Modal>
  )
}

export default BottomSheetModal
