import React from 'react'

// Components
import { ResponsiveScrollView } from 'APP/Converse/Layout'

// Styles
import { Images, Metrics } from 'APP/Themes'
import { getImageAspectRatio } from 'APP/Lib/StylingHelpers'
import Typography from 'APP/Converse/Typography'
import styled from 'styled-components/native'
import useScreenView from 'APP/Hooks/useScreenView'
import { Image } from 'react-native'

const StyledLogoContainer = styled.View`
  align-items: ${({ theme }) => (theme.isBigScreen ? 'center' : 'flex-start')};
  justify-content: center;
`

const StyledLogo = styled(Image)`
  height: 45px;
`

const StyledHeader = styled.View`
  padding: ${({ theme }) => `0 ${theme.isBigScreen ? '0' : theme.metrics.baseMargin * 1.5}px`};
  margin-top: ${Metrics.navBarHeight + Metrics.statusBarHeight}px;
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin * 2}px;
  gap: ${({ theme }) => theme.metrics.baseMargin}px;
`

const StyledContent = styled.View`
  padding: ${({ theme }) => `0 ${theme.metrics.baseMargin * 1.5}px`};
`

interface OnboardingScreenProps {
  title: string
  subtitle: string
  children: React.ReactNode
}

const OnboardingScreen = ({ title, subtitle, children }: OnboardingScreenProps) => {
  const { isBigScreen } = useScreenView()

  return (
    <ResponsiveScrollView
      contentContainerInnerStyle={{ maxWidth: 600 }}
      alwaysBounceVertical={false}
      keyboardShouldPersistTaps="always"
    >
      <StyledHeader>
        <StyledLogoContainer>
          <StyledLogo
            source={Images.logoBlue}
            resizeMode="contain"
            style={{ aspectRatio: getImageAspectRatio(Images.logoBlue) }}
          />
        </StyledLogoContainer>
        <Typography align={isBigScreen ? 'center' : 'auto'} variant="h1">
          {title}
        </Typography>
        <Typography align={isBigScreen ? 'center' : 'auto'} variant="bodyNormal">
          {subtitle}
        </Typography>
      </StyledHeader>
      <StyledContent>{children}</StyledContent>
    </ResponsiveScrollView>
  )
}

export default OnboardingScreen
