import React, { useState, createRef, useEffect, useCallback, Fragment } from 'react'
import { View } from 'react-native'
import styles from './style'
import { ResponsiveScrollView } from 'APP/Converse/Layout'
import I18n from 'APP/Services/i18n'
import Button from 'APP/Converse/Button'
import { Images } from 'APP/Themes'
import LoginActions from 'APP/Redux/LoginRedux'
import { useDispatch, useSelector } from 'react-redux'
import DialogueMarkdown from 'APP/Components/DialogueMarkdown'
import Typography from 'APP/Converse/Typography'
import { Divider } from 'react-native-elements'
import usePrevious from 'APP/Hooks/usePrevious'
import Config from 'APP/Config'

export const CTAS = {
  SIGNUP: 'SIGNUP',
  SIGNIN: 'SIGNIN',
  SSOSIGNIN: (key) => `SSOSIGNIN${key}`,
}

const MultiLoginScreen = () => {
  const dispatch = useDispatch()
  const busy = useSelector((state) => state.login?.fetching || state.patient?.requestRunning)
  const [actionType, setActionType] = useState('')
  const [configResolutionDone, setConfigResolutionDone] = useState(false)

  const hasCopy = I18n.exists('MultiLoginScreen.copy')
  const hasMarkdown = I18n.exists('MultiLoginScreen.markdown')

  const showActivityIndicator = configResolutionDone || busy
  const configPickerRef = createRef()

  // Reset actionType when busy state changes from true to false (i.e. when an action completes)
  const prevBusy = usePrevious(busy)
  useEffect(() => {
    if (prevBusy && !busy) {
      setActionType(undefined)
    }
  }, [busy, prevBusy])

  const loginConnection = useSelector((state) => state.login?.connection)

  const withCTAPress = (ctaId, action) => async () => {
    if (actionType || busy) return

    setActionType(ctaId)
    setConfigResolutionDone(false)
    await configPickerRef.current?.applyConfigChanges?.()
    action()
    setConfigResolutionDone(true)
  }

  const renderLoginButtons = (emphasisLevel) => {
    const loginStrategies = Config?.LOGIN_STRATEGIES || {}
    return (
      <>
        {Object.entries(loginStrategies).map(([key, strategy]) => {
          const { provider, logo, emphasis, connection } = strategy
          if (emphasis !== emphasisLevel) return null

          const handleConnectionSignIn = useCallback(() => {
            dispatch(
              connection
                ? LoginActions.loginRequestWithConnection(connection, {
                    triggerLogoutOnFailure: false,
                  })
                : LoginActions.loginRequest()
            )
          }, [dispatch, connection])

          const handleConnectionSignUp = useCallback(() => {
            dispatch(
              connection
                ? LoginActions.signupRequestWithConnection(connection)
                : LoginActions.signupRequest()
            )
          }, [dispatch, connection])

          return (
            <Fragment key={key}>
              <Button
                variant={emphasis}
                style={styles.button}
                widthVariant="full"
                title={I18n.t('MultiLoginScreen.CTA.provider', { provider })}
                icon={Images.loginLogos[logo]}
                iconVariant="image"
                onPress={withCTAPress(CTAS.SSOSIGNIN(key), handleConnectionSignIn)}
                testID={`${key}-sign-in`}
                disabled={
                  showActivityIndicator &&
                  actionType !== undefined &&
                  actionType !== CTAS.SSOSIGNIN(key)
                }
                isLoading={showActivityIndicator && actionType === CTAS.SSOSIGNIN(key)}
              />
              <View style={styles.subtext}>
                <Typography variant="bodySmall">
                  {I18n.t('MultiLoginScreen.subtext.prompt') + ' '}
                </Typography>
                <Typography
                  variant="link"
                  onPress={withCTAPress(CTAS.SIGNUP, handleConnectionSignUp)}
                  color="primary"
                  testID={`${key}-sign-up`}
                >
                  {I18n.t('MultiLoginScreen.subtext.action')}
                </Typography>
              </View>
            </Fragment>
          )
        })}
      </>
    )
  }

  const handleDialogueSignIn = useCallback(() => {
    dispatch(
      loginConnection
        ? LoginActions.loginRequestWithConnection(loginConnection)
        : LoginActions.loginRequest()
    )
  }, [dispatch, loginConnection])

  return (
    <ResponsiveScrollView>
      <View style={styles.container}>
        {renderLoginButtons('primary')}
        <View style={styles.dividerContainer}>
          <Divider style={styles.divider} />
          <Typography variant="bodyNormalBold" style={styles.dividerText}>
            {I18n.t('MultiLoginScreen.divider')}
          </Typography>
          <Divider style={styles.divider} />
        </View>
        {renderLoginButtons('secondary')}
        <View style={styles.noticeContainer}>
          {hasCopy && (
            <Typography variant="bodyLarge" style={styles.noticeText}>
              {I18n.t('MultiLoginScreen.copy')}
            </Typography>
          )}
          {hasMarkdown && <DialogueMarkdown markdown={I18n.t('MultiLoginScreen.markdown')} />}
        </View>
        <Button
          variant="secondary"
          style={styles.button}
          widthVariant="full"
          title={I18n.t('MultiLoginScreen.CTA.dialogue')}
          onPress={withCTAPress(CTAS.SIGNIN, handleDialogueSignIn)}
          isLoading={showActivityIndicator && actionType === CTAS.SIGNIN}
          icon={Images.logoBlue}
          iconVariant="image"
          testID="dialogue-sign-in"
          disabled={showActivityIndicator && actionType !== undefined && actionType !== CTAS.SIGNIN}
        />
      </View>
    </ResponsiveScrollView>
  )
}

export default MultiLoginScreen
