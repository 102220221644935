import * as React from 'react'
interface Element {
  name: string
  component: React.ReactNode
}
const PortalContext = React.createContext({
  addComponent: (element: Element) => {},
  removeComponent: (name: string) => {},
  getComponent: (name: string): React.ReactNode => <></>,
})
export default PortalContext
