import { Platform } from 'react-native'
import apisauce from 'apisauce'
import Config from '../Config'
import DeviceInfo from 'react-native-device-info'

// our "constructor"
const create = (loginStore, baseURL = Config.PUSHIT_DOMAIN) => {
  const authToken = loginStore.accessToken
  const api = apisauce.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    timeout: 20000,
  })

  // URL builder
  const buildDeleteDeviceUrl = () => `v1/devices/${DeviceInfo.getUniqueId()}`

  // API
  const registerPushToken = (token, language_tag) => {
    return api.post('/v1/devices', {
      data: {
        device_id: DeviceInfo.getUniqueId(),
        tokens: [
          {
            token,
            platform: Platform.select({ ios: 'APNS', android: 'FCM' }),
          },
        ],
        brand_id: Config.BRAND_ID,
        application_id: DeviceInfo.getBundleId(),
        language_tag,
      },
    })
  }

  const unregisterPushToken = () => {
    return api
      .delete(
        buildDeleteDeviceUrl(),
        {},
        {
          data: {
            brand_id: Config.BRAND_ID,
          },
        }
      )
      .then((response) => response)
  }

  return {
    registerPushToken,
    unregisterPushToken,
  }
}

export default {
  create,
}
