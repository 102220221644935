import React from 'react'
import { Pressable } from 'react-native'

// Components
import { Badge } from './Badge'
import Typography from 'APP/Converse/Typography'

// Styles
import { Colors, Metrics } from 'APP/Themes'

const styles = {
  item: {
    margin: 0,
    gap: Metrics.baseMargin * 0.5,
    paddingHorizontal: Metrics.baseMargin,
    paddingVertical: Metrics.baseMargin * 0.75,
    borderRadius: 0,
    borderRightWidth: 3,
    borderRightColor: 'transparent',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  hover: {
    backgroundColor: Colors.activeTabBackgroundColor,
    borderRightColor: Colors.activeTabBackgroundColor,
  },
  selected: {
    backgroundColor: Colors.activeTabBackgroundColor,
    borderRightColor: Colors.accent,
  },
}

export const MenuItem = ({ name, options, onPress, focused = false }) => {
  const { title, tabBarLabel, tabBarIcon, tabBarBadge } = options || {}
  const label = tabBarLabel || title || name
  return (
    <Pressable
      testID={`menuItem-${name}`}
      onPress={onPress}
      isSelected={focused}
      style={({ hovered }) => [styles.item, hovered && styles.hover, focused && styles.selected]}
    >
      {tabBarIcon ? tabBarIcon({ focused }) : null}
      <Typography
        color={focused ? 'accent' : 'dim'}
        variant={focused ? 'subheaderBold' : 'subheader'}
      >
        {label}
      </Typography>
      {tabBarBadge ? <Badge testID={`menuItemBadge-${name}`} /> : null}
    </Pressable>
  )
}
