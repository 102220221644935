import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Fonts, Metrics } from 'APP/Themes'

const subtitle = {
  ...Fonts.style.body,
  color: Colors.text,
  lineHeight: 21,
  marginHorizontal: Metrics.baseMargin,
}

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  content: {
    marginHorizontal: Metrics.baseMargin,
  },
  splashLogo: {
    resizeMode: 'contain',
    alignSelf: 'baseline',
    marginLeft: Metrics.baseMargin,
    marginTop: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin,
    height: 45,
  },
  title: {
    ...Fonts.style.h1,
    color: Colors.text,
    marginHorizontal: Metrics.baseMargin,
    paddingBottom: 10,
  },
  subtitle,
  boldSubtitle: {
    ...subtitle,
    marginHorizontal: 0,
    fontWeight: 'bold',
  },
  inviteList: {
    marginTop: 8,
  },
  inviteOuterContainer: {
    marginVertical: Metrics.baseMargin * 0.75,
    marginHorizontal: Metrics.baseMargin,
    ...ApplicationStyles.shadow,
    backgroundColor: Colors.elementsBg,
    borderRadius: 10,
  },
  inviteContainer: {
    borderRadius: 10,
  },
  inviteInnerContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    margin: Metrics.baseMargin * 0.5,
  },
  inviteText: {
    ...Fonts.style.subheader,
    color: Colors.text,
    flex: 1,
    flexGrow: 1,
  },
})
