import React, { useState, useCallback } from 'react'
import { FlatList, Image, Text, View } from 'react-native'
import { connect } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { TouchableRipple, Checkbox } from 'react-native-paper/lib/module'

import PatientActions from 'APP/Redux/PatientRedux'

import { PrimaryButton } from 'APP/Components/Buttons'
import ChallengedInvitationFooter from 'APP/Components/ChallengedInvitationFooter'

import Config from 'APP/Config'
import I18n from 'APP/Services/i18n'
import { getBrandConfig } from 'APP/Lib/ConfigHelpers'

import { Images, Colors } from 'APP/Themes'
import { getImageAspectRatio } from 'APP/Lib/StylingHelpers'
import Styles from './style'
import Header from 'APP/Converse/Header'
import { ResponsiveScrollView, ResponsiveView } from 'APP/Converse/Layout'

const ChallengedEnrolmentsSearchScreen = ({
  analyticsName,
  searchResult,
  challengedInvitationQueueRequest,
  isOnboarding,
  onboardingDeferredEnrolmentComplete,
}) => {
  const navigation = useNavigation()
  const buildInvitationList = () => {
    let invitationList = searchResult

    if (Config.SUPPORTED_CHALLENGED_ENROLMENT_METHOD != null) {
      invitationList = [
        ...invitationList,
        {
          id: 'claim_access',
          name: I18n.t('ChallengedEnrolmentsSearchScreen.otherOrganization'),
        },
      ]
    }

    return invitationList.map((invitation) => {
      return {
        invitation,
        checked: false,
      }
    })
  }

  const [invitationList, setInvitationList] = useState(buildInvitationList())

  const handleNext = () => {
    // Filter out the invitations that are not checked and extract the invite for use
    const selectedInvitations = invitationList.reduce((selected, item) => {
      if (item.checked) {
        selected.push(item.invitation)
      }
      return selected
    }, [])
    challengedInvitationQueueRequest(selectedInvitations)
  }

  const handleSkipForNow = () => {
    if (isOnboarding) {
      onboardingDeferredEnrolmentComplete()
    } else {
      navigation.goBack()
    }
  }

  const handleInvitationPressed = useCallback(
    (item) => {
      const newList = invitationList.map((i) => {
        if (i.invitation.id === item.invitation.id) {
          return {
            ...i,
            checked: !i.checked,
          }
        }
        return i
      })

      setInvitationList(newList)
    },
    [invitationList]
  )

  const renderInvitation = ({ item }) => {
    let name
    if (item.invitation.id === 'claim_access') {
      name = item.invitation.name
    } else {
      name = I18n.serverString(item?.invitation?.organization?.displayName)
    }

    return (
      <View style={Styles.inviteOuterContainer}>
        <TouchableRipple
          borderless
          style={Styles.inviteContainer}
          onPress={() => handleInvitationPressed(item)}
        >
          <View style={Styles.inviteInnerContainer}>
            <Checkbox.Android
              color={Colors.text}
              uncheckedColor={Colors.text}
              status={item.checked ? 'checked' : 'unchecked'}
            />
            <Text style={Styles.inviteText}>{name}</Text>
          </View>
        </TouchableRipple>
      </View>
    )
  }

  return (
    <View style={Styles.fullPrimaryContainer}>
      {!isOnboarding && (
        <ResponsiveView>
          <Header />
        </ResponsiveView>
      )}
      <ResponsiveScrollView>
        <View style={Styles.content}>
          <Image
            source={Images.logoBlue}
            style={[Styles.splashLogo, { aspectRatio: getImageAspectRatio(Images.logoBlue) }]}
          />
          <Text style={Styles.title}>{I18n.t('ChallengedEnrolmentsSearchScreen.title')}</Text>
          <Text style={Styles.subtitle}>
            {I18n.t('ChallengedEnrolmentsSearchScreen.subtitle')}
            <Text style={Styles.boldSubtitle}>
              {getBrandConfig(Config.BRAND_ID, I18n.locale).productName}
            </Text>
            {'.'}
          </Text>
          <FlatList
            style={Styles.inviteList}
            data={invitationList}
            keyExtractor={(item) => item?.invitation?.id ?? item?.invitation?.organization?.name}
            renderItem={renderInvitation}
          />
          <PrimaryButton
            title={I18n.t('ChallengedEnrolmentsSearchScreen.nextButton')}
            analyticsName={`${analyticsName}.next`}
            onPress={handleNext}
          />
          <ChallengedInvitationFooter analyticsName={analyticsName} onPress={handleSkipForNow} />
        </View>
      </ResponsiveScrollView>
    </View>
  )
}

const mapStateToProps = (state, props) => {
  const { patient } = state
  return {
    ...props.route?.params,
    isOnboarding: patient?.isOnboarding,
  }
}

const mapDispatchToProps = (dispatch) => ({
  challengedInvitationQueueRequest: (queue) =>
    dispatch(PatientActions.challengedInvitationQueueRequest(queue)),
  onboardingDeferredEnrolmentComplete: () =>
    dispatch(PatientActions.onboardingDeferredEnrolmentComplete()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChallengedEnrolmentsSearchScreen)
