import React, { useState } from 'react'
import { View, Keyboard } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import Spinner from 'react-native-loading-spinner-overlay'

// Actions
import PatientActions from 'APP/Redux/PatientRedux'

// Components
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import ProfileHeaderCard from 'APP/Components/ProfileHeaderCard'
import DialogueBranding from 'APP/Components/DialogueBranding'
import Button from 'APP/Converse/Button'

import ControllerInput from 'APP/Components/ControllerFields/ControllerInput'
import ControllerPicker from 'APP/Components/ControllerFields/ControllerPicker'

// Lib
import { KeyLabelArrays, Format } from 'APP/Lib/ProfileDataFormat'
import { getShouldProvideProvince } from 'APP/Lib/ProfileHelpers'

// Styling
import Styles from './style'

// I18n
import I18n from 'APP/Services/i18n'
import { getAdminAreas, getServicedAdminAreasString } from 'APP/Services/AdminAreas'
import { ResponsiveScrollView } from 'APP/Converse/Layout'

const UpdateProfileScreen = () => {
  const { requestRunning } = useSelector((state) => state.patient)
  const { profile } = useSelector((state) => state.patient) || {}
  const { signupProfileData } = useSelector((state) => state.login) || {}
  const { pronounDisplay: showPronouns } = useSelector((state) => state.features)
  const dispatch = useDispatch()

  const currentPreferredName: string = profile.preferredName || signupProfileData?.preferredName
  const currentPronouns: string = profile.pronouns || signupProfileData?.pronouns
  const currentLanguage: string =
    profile.preferred_language ||
    (KeyLabelArrays.LANGUAGES.length === 1 && KeyLabelArrays.LANGUAGES[0].key)

  const currentGenderIdentity: string = profile.genderIdentity || signupProfileData?.genderIdentity
  const currentGenderIdentityDescription: string =
    profile.genderIdentityDescription || signupProfileData?.genderIdentityDescription

  const [focusedField, setFocusedField] = useState<string | null>(null)

  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      preferredName: currentPreferredName,
      pronouns: currentPronouns,
      genderIdentity: currentGenderIdentity,
      genderIdentityDescription: currentGenderIdentityDescription,
      phoneNumber: profile.phoneNumber,
      preferredLanguage: currentLanguage,
      jurisdiction: profile.residesIn,
    },
  })

  const watchGenderIdentity = watch('genderIdentity')
  const watchProvince = watch('jurisdiction')

  const onSubmit = (formData) => {
    if (formData.genderIdentity !== 'prefer_to_self_identify') {
      formData.genderIdentityDescription = null // Ensure we don't send a genderIdentityDescription if the user didn't select prefer_to_self_identify
    }

    dispatch(
      PatientActions.patientProfileUpdateRequest({
        preferredName: formData.preferredName?.trim(),
        pronouns: formData.pronouns?.trim(),
        residesIn: formData.jurisdiction,
        genderIdentity: formData.genderIdentity,
        genderIdentityDescription: formData.genderIdentityDescription?.trim(),
        phoneNumber: parsePhone(formData.phoneNumber),
        preferred_language: formData.preferredLanguage,
      })
    )
  }

  const renderPreferredName = () => {
    return (
      <ControllerInput
        name="preferredName"
        testID="UPDATE_PROFILE_SCREEN_PREFERRED_NAME_FIELD"
        control={control}
        label={I18n.t('ProfileScreen.preferredNameOptional')}
        infotip={String(I18n.t('UpdateProfileScreen.preferredNameHint'))}
        onFocus={() => setFocusedField('preferredName')}
        isFocused={focusedField === 'preferredName'}
      />
    )
  }

  const renderPronouns = () => {
    return (
      <ControllerInput
        name="pronouns"
        testID="UPDATE_PROFILE_SCREEN_PRONOUNS_FIELD"
        control={control}
        label={I18n.t('ProfileScreen.pronounsOptional')}
        infotip={String(I18n.t('UpdateProfileScreen.pronounsHint'))}
        onFocus={() => setFocusedField('pronouns')}
        isFocused={focusedField === 'pronouns'}
      />
    )
  }

  const renderGenderIdentity = () => {
    const genderIdentities = KeyLabelArrays.GENDER
    return (
      <ControllerPicker
        name="genderIdentity"
        testID="UPDATE_PROFILE_GENDER_IDENTITY_FIELD"
        control={control}
        label={I18n.t('ProfileScreen.genderIdentity')}
        placeholder={String(I18n.t('ProfileScreen.selectGenderIdentity'))}
        items={genderIdentities}
      />
    )
  }

  const renderPreferToSelfIdentify = () => {
    const showPreferToSelfIdentify = watchGenderIdentity === 'prefer_to_self_identify'

    return (
      showPreferToSelfIdentify && (
        <ControllerInput
          name="genderIdentityDescription"
          testID="UPDATE_PROFILE_SCREEN_PREFER_TO_SELF_IDENTIFY_FIELD"
          control={control}
          rules={{
            required: showPreferToSelfIdentify
              ? I18n.t('ProfileScreen.error.invalidPreferToSelfIdentify')
              : false,
          }}
          label={I18n.t('ProfileScreen.genders.preferToSelfIdentify')}
          onFocus={() => setFocusedField('genderIdentityDescription')}
          isFocused={focusedField === 'genderIdentityDescription'}
        />
      )
    )
  }

  const renderProvinces = () => {
    if (!getShouldProvideProvince()) return

    const sortedProvinces = KeyLabelArrays.PROVINCES.sort((a, b) => a.label.localeCompare(b.label))

    const adminAreas = getAdminAreas()
    const servicedAreasString = getServicedAdminAreasString(adminAreas)

    const errorText = !watchProvince
      ? I18n.t('ProfileScreen.error.invalidJurisdictionRequiredField')
      : I18n.t('ProfileScreen.error.invalidJurisdiction', {
          servicedAreas: servicedAreasString,
        })

    return (
      <ControllerPicker
        name="jurisdiction"
        rules={{
          required: errorText,
        }}
        control={control}
        label={I18n.t('UpdateProfileScreen.province')}
        placeholder={String(I18n.t('ProfileScreen.residesIn'))}
        items={sortedProvinces}
        testID={'UPDATE_PROFILE_SCREEN_PROVINCE_FIELD'}
        isFocused={focusedField === 'jurisdiction'}
      />
    )
  }

  const renderPhoneNumber = () => {
    return (
      <ControllerInput
        name="phoneNumber"
        testID="UPDATE_PROFILE_SCREEN_PHONE_NUMBER_FIELD"
        control={control}
        rules={{ required: String(I18n.t('ProfileScreen.error.invalidNumber')) }}
        label={I18n.t('ProfileScreen.number')}
        infotip={String(I18n.t('ProfileScreen.hint.numberHint'))}
        onFocus={() => setFocusedField('phoneNumber')}
        keyboardType="phone-pad"
        isFocused={focusedField === 'phoneNumber'}
      />
    )
  }

  const renderPreferredLanguage = () => {
    return (
      <ControllerPicker
        name="preferredLanguage"
        rules={{
          required: String(I18n.t('ProfileScreen.error.invalidLanguage')),
          validate: (value) =>
            !!Format.language(value) || String(I18n.t('ProfileScreen.error.invalidLanguage')),
        }}
        control={control}
        label={I18n.t('UpdateProfileScreen.communicationPref')}
        placeholder={String(I18n.t('ProfileScreen.language'))}
        items={KeyLabelArrays.LANGUAGES}
        testID="UPDATE_PROFILE_LANG_INPUT"
        isFocused={focusedField === 'preferredLanguage'}
      />
    )
  }

  const renderProfileSummary = () => {
    const { givenName, preferredName, familyName, email, pronouns } = profile

    return (
      <ProfileHeaderCard
        testID="PROFILE_SUMMARY"
        givenName={givenName}
        preferredName={preferredName}
        familyName={familyName}
        pronouns={showPronouns ? pronouns : null}
        subTitle={email}
      />
    )
  }

  const showCommunicationPreference = KeyLabelArrays.LANGUAGES.length > 1

  return (
    <NoticeBarContainer>
      <Spinner visible={requestRunning} cancelable={false} />
      <ResponsiveScrollView alwaysBounceVertical={false} keyboardShouldPersistTaps="always">
        {renderProfileSummary()}
        <View style={Styles.content}>
          {renderPreferredName()}
          {renderPronouns()}
          {renderGenderIdentity()}
          {renderPreferToSelfIdentify()}
          {renderProvinces()}
          {renderPhoneNumber()}
          {showCommunicationPreference && renderPreferredLanguage()}

          <Button
            variant="primary"
            title={String(I18n.t('UpdateProfileScreen.save'))}
            widthVariant="full"
            onPress={() => {
              Keyboard.dismiss()
              setFocusedField(null)
              handleSubmit(onSubmit)()
            }}
            analyticsName="Done"
            testID="SAVE_BTN"
            disabled={!isDirty || !isValid}
          />
        </View>
      </ResponsiveScrollView>
      {!focusedField && <DialogueBranding />}
    </NoticeBarContainer>
  )
}

const parsePhone = (phoneNumber: string) => phoneNumber.replace(/\D+/g, '')

export default UpdateProfileScreen
