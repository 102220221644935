import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Fonts, Metrics } from 'APP/Themes'

export const MarkdownStyles = {
  p: {
    marginBottom: Metrics.baseMargin * 1.25,
    ...Fonts.style.body,
  },
}

export default StyleSheet.create({
  modal: {
    paddingVertical: Metrics.baseMargin * 2,
    marginTop: Metrics.statusBarHeight + Metrics.baseMargin,
    backgroundColor: Colors.appBg,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    ...ApplicationStyles.shadow,
    flex: 1,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    paddingHorizontal: Metrics.baseMargin * 1.6,
    paddingBottom: Metrics.baseMargin,
  },
  content: {
    paddingTop: Metrics.baseMargin,
    paddingHorizontal: Metrics.baseMargin * 2,
    paddingBottom: Metrics.baseMargin + Metrics.bottomSpace,
  },
  titleH1: {
    ...Fonts.style.h1,
    color: Colors.text,
    paddingBottom: Metrics.baseMargin * 2,
  },
  titleH2: {
    ...Fonts.style.h2,
    color: Colors.accent,
    paddingTop: Metrics.baseMargin * 1.25,
    paddingBottom: Metrics.baseMargin,
    paddingRight: Metrics.baseMargin * 1.25,
  },
  markdown: {
    color: Colors.text,
  },
})
