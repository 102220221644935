import { take } from 'redux-saga/effects'
import { END, eventChannel } from 'redux-saga'

import { navigationRef as Nav } from 'APP/Nav'

// Exposed for testing
export function _navStateChannel() {
  return eventChannel((emitter) => {
    const unsub = Nav.current.addListener('state', () => emitter(END))
    return () => unsub()
  })
}

export function* syncReset(payload) {
  const navStateChannel = _navStateChannel()

  Nav.reset(payload)
  // Wait for the listener to emit an event
  yield take(navStateChannel)

  navStateChannel.close()
}
