import React, { useCallback, useEffect, useMemo } from 'react'
import { View, Text, ActivityIndicator, TouchableOpacity } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'
import Toast from 'react-native-toast-message'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import I18n from 'APP/Services/i18n'
import Analytics from 'APP/Services/Analytics'
import HabitsActions from 'APP/Redux/HabitsRedux'
import { selectNotificationConfiguration } from 'APP/Sagas/HabitsSagas'
import { Habits } from '@dialogue/services'
import Styles from './style'
import { Metrics, Colors } from 'APP/Themes'
import moment from 'moment'
import { isMobile } from 'APP/Helpers/checkPlatform'

const Remove = ({ onRemoveHabit, onErrorToastShown, processing }) => {
  const removeHabitState = useSelector((state) => state.habits.removeHabit)

  useEffect(() => {
    if (removeHabitState?.retry) {
      Toast.show({ text1: I18n.t('Habits.removeHabit.error') })
      onErrorToastShown()
    }
  }, [removeHabitState])

  return (
    <TouchableOpacity
      onPress={onRemoveHabit}
      disabled={processing}
      style={Styles.removeCta}
      testID="explicitlyRemoveHabit"
    >
      {removeHabitState?.loading ? (
        <ActivityIndicator color={Colors.text} style={{ flex: 1 }} />
      ) : (
        <Text style={Styles.removeCtaTitle}>{I18n.t('Habits.removeHabit.cta')}</Text>
      )}
      <Text style={[Styles.removeCtaCopy, { marginTop: Metrics.baseMargin }]}>
        {I18n.t('Habits.removeHabit.copy')}
      </Text>
    </TouchableOpacity>
  )
}

const HabitEdit = ({
  habit,
  processing,
  selectedDay,
  goToHabitReminder,
  goToHabitPrompt,
  navigateToHabit,
  analyticsName,
  testID,
}) => {
  const dispatch = useDispatch()

  const fetchNotification = () =>
    dispatch(HabitsActions.getNotificationConfigurations(habit.identifier))

  const isHabitNotificationsEnable =
    isMobile() && useSelector((state) => state.features.habitNotifications)
  const notificationConfigurationState = useSelector(
    (state) =>
      selectNotificationConfiguration(
        state,
        habit.identifier,
        Habits.Habits.Types.NotificationType.DO_HABIT
      ) || {}
  )

  const removeHabit = useCallback(() => {
    Analytics.trackEvent('button_click', {
      button_value: `removeHabit: ${habit.identifier} : ${habit.title[I18n.baseLocale]}`,
      trigger: analyticsName,
    })
    dispatch(HabitsActions.removeHabit(habit.identifier, selectedDay))
  }, [habit, habit.identifier, selectedDay])
  const onErrorToastShown = useCallback(() => dispatch(HabitsActions.resetRemoveHabit()), [])

  const reminders = useMemo(() => {
    const { data } = notificationConfigurationState

    if (data && data.enabled && data.reminder_time) {
      const reminderTime = moment(data.reminder_time, 'HH:mm:ss').local().format('LT')
      const weekdaysShort = (data.week_days || [1, 2, 3, 4, 5, 6, 7])
        .map((isoWeekday) => I18n.t(`Habits.editHabit.weekdaysShort.${isoWeekday}`))
        .join(', ')

      return I18n.t('Habits.editHabit.reminders', {
        weekdaysShort,
        reminderTime,
      })
    }

    return I18n.t('Habits.editHabit.noReminders')
  }, [notificationConfigurationState])

  useEffect(() => {
    if (habit?.identifier) {
      fetchNotification()
    }
  }, [habit?.identifier])

  return (
    <View style={Styles.container} testID={testID}>
      <TouchableOpacity
        onPress={navigateToHabit}
        disabled={processing}
        style={Styles.cta}
        testID="navigateToHabit"
      >
        <View>
          <Text style={Styles.ctaCopy}>{I18n.t('Habits.editHabit.goToHabit')}</Text>
        </View>
        <MaterialIcon
          accessibilityRole="button"
          color={Colors.accent}
          size={Metrics.icons.small}
          name="chevron-right"
        />
      </TouchableOpacity>
      <TouchableOpacity
        onPress={goToHabitPrompt}
        disabled={processing}
        style={Styles.cta}
        testID="promptEditCta"
      >
        <View>
          <Text style={Styles.ctaTitle}>{I18n.t('Habits.updateOrRemovePrompt.title')}</Text>
          <Text style={Styles.ctaCopy} testID="promptEditCtaCopy">
            {habit?.prompt &&
              habit?.prompt?.type === 'built-in' &&
              habit.prompt.message[I18n.baseLocale]}
            {habit?.prompt && habit?.prompt?.type !== 'built-in' && habit.prompt.custom_message}
            {!habit?.prompt && I18n.t('Habits.editHabit.noPrompt')}
          </Text>
        </View>
        <MaterialIcon
          accessibilityRole="button"
          color={Colors.accent}
          size={Metrics.icons.small}
          name="chevron-right"
        />
      </TouchableOpacity>
      {isHabitNotificationsEnable && (
        <TouchableOpacity
          onPress={goToHabitReminder}
          disabled={processing}
          style={Styles.cta}
          testID="notificationEditCta"
        >
          <View>
            <Text style={Styles.ctaTitle}>{I18n.t('Habits.editReminder.title')}</Text>
            {notificationConfigurationState.loading ? (
              <ActivityIndicator
                size="small"
                color={Colors.text}
                style={Styles.ctaActivity}
                testID="notificationEditCtaLoading"
              />
            ) : (
              <Text style={Styles.ctaCopy}>{reminders}</Text>
            )}
          </View>
          <MaterialIcon
            accessibilityRole="button"
            color={Colors.accent}
            size={Metrics.icons.small}
            name="chevron-right"
          />
        </TouchableOpacity>
      )}
      <Remove
        processing={processing}
        onRemoveHabit={removeHabit}
        onErrorToastShown={onErrorToastShown}
      />
    </View>
  )
}

export default HabitEdit
