import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Metrics, Fonts } from 'APP/Themes'

export const Styles = StyleSheet.create({
  container: {
    flexGrow: 0,
    flexShrink: 0,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginTop: Metrics.baseMargin + 2,
    paddingBottom: Metrics.bottomSpace || Metrics.baseMargin,
    paddingHorizontal: Metrics.baseMargin * 2,
  },
  choicesContainer: {
    display: 'flex',
    ...ApplicationStyles.shadow,
  },
  choiceContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 1,
    alignItems: 'center',
    backgroundColor: Colors.elementsBg,
    padding: Metrics.baseMargin,
    minWidth: 240,
  },
  choiceContainerSelected: {
    backgroundColor: Colors.multiSelectMsgBg,
  },
  firstChoiceContainer: {
    borderTopLeftRadius: Metrics.radius.medium,
    borderTopRightRadius: Metrics.radius.medium,
  },
  lastChoiceContainer: {
    borderBottomLeftRadius: Metrics.radius.medium,
    borderBottomRightRadius: Metrics.radius.medium,
    marginBottom: 0,
  },
  choiceText: {
    ...Fonts.style.subheader,
    color: Colors.text,
    textAlign: 'left',
    paddingLeft: Metrics.baseMargin / 2,
    flexShrink: 1,
  },
  choiceDisabledText: {
    color: Colors.deactivatedElementText,
  },
  actionsContainer: {
    display: 'flex',
  },
  submitButton: {
    flex: 1,
    marginRight: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginTop: Metrics.baseMargin - 4,
  },
})
