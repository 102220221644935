import { useState, useCallback, useEffect, useRef, useLayoutEffect } from 'react'
import { Keyboard } from 'react-native'

export function useOnLayout() {
  const [layout, setLayout] = useState({ x: 0, y: 0, width: 0, height: 0 })
  const onLayout = useCallback((event) => {
    setLayout(event.nativeEvent.layout)
  }, [])
  return [layout, onLayout]
}

export function useKeyboardVisible() {
  const [isKeyboardVisible, setKeyboardVisible] = useState(false)

  useEffect(() => {
    const keyboardWillShowListener = Keyboard.addListener('keyboardWillShow', () => {
      setKeyboardVisible(true)
    })
    const keyboardWillHideListener = Keyboard.addListener('keyboardWillHide', () => {
      setKeyboardVisible(false)
    })

    return () => {
      keyboardWillHideListener.remove()
      keyboardWillShowListener.remove()
    }
  }, [])

  return isKeyboardVisible
}

export function useScrollToTop(deps = []) {
  const scrollViewRef = useRef()

  useLayoutEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({ y: 0 })
    }
  }, deps)

  return scrollViewRef
}
