import React, { useCallback } from 'react'
import styled from 'styled-components/native'
import moment from 'moment-timezone'

import Button from 'APP/Converse/Button'
import { Colors } from 'APP/Themes'
import { AvailabilitySlot } from '@dialogue/timekeeper'

const StyledButton = styled(Button)<{ $selected: boolean }>`
  background: ${(props) => (props.$selected ? Colors.buttonPrimary : Colors.elementsBg)};
  width: 30%;
  max-width: 31.5%; // stops button from taking full width of parent
  flex-grow: 1;
  padding-left: 12px;
  padding-right: 12px;
`

interface Props {
  appointment: AvailabilitySlot
  selected: boolean
  onSelect: (appointment: AvailabilitySlot) => void
}

export const TimeslotButton: React.FC<Props> = ({ onSelect, appointment, selected, ...rest }) => {
  const handleClick = useCallback(() => {
    onSelect(appointment)
  }, [onSelect, appointment])

  const displayTime = moment(appointment.start_at).format('LT')

  return (
    <StyledButton
      {...rest}
      variant={selected ? 'primary' : 'secondary'}
      onPress={handleClick}
      widthVariant="content"
      title={displayTime}
      $selected={selected}
    />
  )
}

export default TimeslotButton
