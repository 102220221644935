import React from 'react'
import { Defs, ClipPath, Rect } from 'react-native-svg'

// Even though Victory should handle interpolation from dates to SVG coordinates
// we found it to not be the case sometimes
// so it's better to use pixel coordinates when working with SVGs
const LineClip = ({ startDate, latestDate, activeDate, scale }) => (
  <Defs>
    <ClipPath id="baseClip">
      <Rect x={scale.x(startDate)} y={0} width={scale.x(latestDate)} height="100%" />
    </ClipPath>
    <ClipPath id="activeClip" key={scale.x(activeDate)}>
      <Rect x={scale.x(startDate)} y={0} width={scale.x(activeDate)} height="100%" />
    </ClipPath>
  </Defs>
)

export default LineClip
