import { PharmaciesApiGetPharmaciesRequest } from '@dialogue/coredata'
import { SagaActionProps } from 'APP/Models/Actions'
import { call, put } from 'typed-redux-saga'

import { getPharmaciesApi } from '../utils/emergencyRoom'
import { pharmaciesActions } from './slice'

export function* getPharmacies({ payload }: SagaActionProps<PharmaciesApiGetPharmaciesRequest>) {
  try {
    const api = yield* call(getPharmaciesApi)

    const response = yield* call(api.getPharmacies, payload)
    const pharmacies = response.data?.data
    yield put(pharmaciesActions.getPharmaciesSuccess(pharmacies))
  } catch (e) {
    yield put(pharmaciesActions.getPharmaciesFailure({ error: e as Error }))
  }
}
