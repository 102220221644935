import React from 'react'
import { View, ActivityIndicator } from 'react-native'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'

import { Colors, Metrics } from 'APP/Themes'
import Styles from './style'

// Simple component to help handle basic api states
const NetworkWrapper = ({ loading, error, refresh, children }) => {
  if (!loading && !error) return children
  return (
    <View style={Styles.container}>
      {loading && <ActivityIndicator size="large" color={Colors.text} testID="loading" />}
      {error && (
        <DialogueTouchableOpacity analyticsName="Refresh" onPress={refresh} testID="refresh">
          <MaterialIcon name="refresh" size={Metrics.icons.large} color={Colors.text} />
        </DialogueTouchableOpacity>
      )}
    </View>
  )
}

export default NetworkWrapper
