import apisauce from 'apisauce'
import Config from '../Config'
import { linkUserAccountUrl } from './MultipassHelper'

const create = (loginStore, baseURL = Config.MULTIPASS_DOMAIN) => {
  const authToken = loginStore.accessToken
  const api = apisauce.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authToken}`,
    },
    timeout: 20000,
  })

  const handleResponse = (response) => {
    if (response.ok) {
      return response.status
    } else {
      throw {
        status: response.status,
        detail: response.data?.detail || 'no details',
      }
    }
  }

  // API
  const linkUserAccount = (secondaryAccessToken) => {
    const payload = secondaryAccessToken ? { access_token: secondaryAccessToken } : undefined
    return api.post(linkUserAccountUrl(), payload).then(handleResponse)
  }

  return {
    linkUserAccount,
  }
}

export default {
  create,
}
