import Colors from './Colors'
import Images from './Images'
import ApplicationStyles from './ApplicationStyles'
import I18n from 'APP/Services/i18n'

export const creditCardForm = {
  cardImageFront: Images.creditCardFrontIllustration,
  cardImageBack: Images.creditCardBackIllustration,
  validColor: Colors.text,
  imageFront: Images.creditCardFrontIllustration,
  imageBack: Images.creditCardBackIllustration,
  invalidColor: Colors.errorText,
  placeholderColor: Colors.textDisabled, // seems to be broken
  labelStyle: {
    ...ApplicationStyles.screen.labelText,
    fontWeight: '500',
    lineHeight: 30,
    minWidth: 130,
    paddingLeft: 20,
  },
  inputStyle: { color: Colors.text },
  inputContainerStyle: {
    borderTopColor: Colors.grayBg,
    borderBottomColor: Colors.grayBg,
  },
  placeholders: {
    name: I18n.t('PaymentScreen.add.namePlaceholder'),
    monthYear: I18n.t('PaymentScreen.add.monthYearPlaceholder'), //broken :(
    expiry: I18n.t('PaymentScreen.add.expiryPlaceholder'),
    number: I18n.t('PaymentScreen.add.cardNumberPlaceholder'),
  },
  fontFamily: 'Courier',
}

export default {
  creditCardForm,
}
