import React from 'react'
import { useSelector } from 'react-redux'
import { SafeAreaView, Image } from 'react-native'
import I18n from 'APP/Services/i18n'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import Leaderboard from '../WellnessCenterChallengeScreen_legacy/Leaderboard'
import { ResponsiveScrollView } from 'APP/Converse/Layout'

import { goalMetric } from 'APP/Components/Challenge_legacy/constants'
import { Images } from 'APP/Themes'
import Styles from './style'

const WellnessCenterTeamDetailScreen = (props) => {
  const { teamId, count, image } = { ...props.route?.params, ...props }
  const { challenge } = useSelector((state) => state.wellnessCenter.selectedChallenge)
  return (
    <NoticeBarContainer>
      <ResponsiveScrollView>
        <SafeAreaView style={Styles.scrollContainer} testID="leaderboard">
          <Image
            style={Styles.image}
            source={{ uri: image }}
            defaultSource={Images.contentLoading}
            resizeMode="cover"
          />
          <Leaderboard
            title={`${I18n.t(`WellnessCenter.challenges.teamMembers`)} (${count})`}
            subtitle={goalMetric(challenge.status, challenge.metric, true)}
            viewTeamId={teamId}
          />
        </SafeAreaView>
      </ResponsiveScrollView>
    </NoticeBarContainer>
  )
}

export default WellnessCenterTeamDetailScreen
