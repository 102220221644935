import React, { useCallback, useContext, useState, createRef, useEffect, useRef } from 'react'
import { View, Keyboard, TouchableOpacity } from 'react-native'
import { Input } from 'react-native-elements'
import Icon from 'react-native-vector-icons/MaterialIcons'

import I18n from 'APP/Services/i18n'
import { useFilePicker } from 'APP/Hooks/FilePicker'
import { ChatContext } from 'APP/Lib/Context'
import InputAddress from 'APP/Components/FelixChat/InputAddress'

import { Colors, Images, Metrics } from 'APP/Themes'
import { Styles } from './style'
import { isWeb } from 'APP/Helpers/checkPlatform'
import InputFile from 'APP/Components/FelixChat/InputFile'
import Config from 'APP/Config'

const InputBar = ({ scrollToBottom, enableSoftDisable }) => {
  const {
    sendMessage,
    onFilePicked,
    isConnected,
    isInactiveAndEpisodeInactive,
    hasEligibleBrands,
    lastPost,
    selectedAddress,
    selectedFile,
    setSelectedFile,
    episode,
  } = useContext(ChatContext)

  const isEpisodeComplete = Config.BRAND_ID == 'sunlifeus' && episode?.stateId === 'resolved'
  const [forceEnabled, setForceEnabled] = useState(false)
  const [inputTextHeight, setInputTextHeight] = useState(0)
  const [message, setMessage] = useState()
  const hasMessage = message?.length > 0
  let rightIcon = hasMessage > 0 ? 'send' : 'attach-file'

  const inputRef = createRef()
  const inputFile = useRef(null)

  const isTextOrFileQuestion = ['text.v1', 'prompt_text', 'prompt_file'].includes(lastPost?.props?.type)
  const isAnsweringQuestion = lastPost?.props?.utterance?.includes('utter_ask_') && !isTextOrFileQuestion

  // A soft disable can be overridden by clicking on the input
  const isSoftDisabled = enableSoftDisable && isAnsweringQuestion
  const isHardDisabled = !isConnected || isInactiveAndEpisodeInactive || isEpisodeComplete || (!enableSoftDisable && isAnsweringQuestion)
  const isCurrentlyDisabled = (isSoftDisabled || isHardDisabled) && !forceEnabled

  const onSelectFile = (fileData) => {
    setSelectedFile(fileData)
  }

  const { openFilePicker, onFileChangeSubmit, resetFile } = useFilePicker({
    submitCallback: onSelectFile,
    showFiles: true,
    fileInputRef: inputFile,
  })

  // Scrolls to bottom of chat screen when the keyboard is visible
  // "keyboardDidShow" event works on both Android and iOS, but not sure why `keyboardWillShow` doesn't on Android...
  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', () => {
      scrollToBottom()
    })

    return () => {
      keyboardDidShowListener.remove()
    }
  }, [scrollToBottom])

  const prevLastPost = useRef()
  useEffect(() => {
    const receivedNewPost = prevLastPost?.current && lastPost?.id !== prevLastPost.current?.id
    if (receivedNewPost) {
      setForceEnabled(false)
    }
    prevLastPost.current = lastPost
  }, [lastPost])

  const onChange = useCallback((txt) => setMessage(txt), [setMessage])

  const onFocus = () => {
    if (isSoftDisabled) {
      setForceEnabled(true)
    }
  }

  const handleSubmit = () => {
    if (!isConnected || isInactiveAndEpisodeInactive) return
    if (hasMessage) {
      sendMessage({ message })
      setMessage('')
      setInputTextHeight(30)
    }
  }

  const handleClickIcon = () => {
    if (hasMessage) {
      handleSubmit()
    } else {
      openFilePicker()
    }
  }

  const renderRightIcon = () => {
    if (isHardDisabled) return null
    return (
      <TouchableOpacity
        disabled={isCurrentlyDisabled}
        onPress={handleClickIcon}
        style={Styles.rightIconButton}
        testID={hasMessage ? 'send-message-right-icon' : 'attach-file-right-icon'}
        activeOpacity={0.5}
        accessibilityLabel={
          hasMessage
            ? I18n.t('AccessibilityIcons.chat.send')
            : I18n.t('AccessibilityIcons.chat.attachFile')
        }
      >
        {hasMessage ? (
          <Images.sendMessage
            accentFillLine={isCurrentlyDisabled ? Colors.buttonSecondaryDisabled : Colors.accent}
            width={Metrics.icons.small}
            height={Metrics.icons.small}
          />
        ) : (
          <Icon
            name={rightIcon}
            size={Metrics.icons.small}
            color={isCurrentlyDisabled ? Colors.buttonSecondaryDisabled : Colors.accent}
          />
        )}
      </TouchableOpacity>
    )
  }

  const getPlaceholderText = () => {
    if (isEpisodeComplete) {
      return I18n.t('ConversationScreen.complete.inputPlaceholder')
    }
    if (!isConnected) {
      return I18n.t('Navigation.offlineWarning')
    }
    if (isInactiveAndEpisodeInactive) {
      return hasEligibleBrands
        ? I18n.t('Common.inactiveCoverage.multiBrand')
        : I18n.t('Common.inactiveCoverage.singleBrand')
    }
    if (isCurrentlyDisabled) {
      return I18n.t('ChatScreen.input.placeholder.tap')
    }
    return I18n.t('ChatScreen.input.placeholder.send')
  }

  const onContentSizeChange = (e) => {
    setInputTextHeight(e?.nativeEvent?.contentSize?.height)
    scrollToBottom()
  }

  const onInputFileChange = (e) => {
    onFileChangeSubmit(e)
  }

  const cleanUpFile = () => {
    setSelectedFile(null)
    if (resetFile) resetFile(null)
  }

  const onSubmitFile = () => {
    onFilePicked(selectedFile)
    setSelectedFile(null)
  }

  const renderInput = () => {
    if (selectedFile) {
      return <InputFile fileData={selectedFile} onCancel={cleanUpFile} onSubmit={onSubmitFile} />
    }
    if (selectedAddress) {
      return <InputAddress />
    }
    return (
      <Input
        ref={inputRef}
        enterKeyHint="next"
        value={message}
        placeholder={getPlaceholderText()}
        inputStyle={[
          isCurrentlyDisabled ? Styles.disableTextInput : Styles.textInput,
          {
            height: inputTextHeight, // Can just use the raw height, because min and max heights are set in inputStyle
          },
        ]}
        containerStyle={Styles.inputContainer}
        inputContainerStyle={Styles.textInputContainer}
        onFocus={onFocus}
        onChangeText={onChange}
        selectionColor={Colors.text}
        placeholderTextColor={Colors.grayBg}
        multiline
        blurOnSubmit={isWeb()}
        onContentSizeChange={onContentSizeChange}
        rightIcon={renderRightIcon()}
        rightIconContainerStyle={Styles.rightIconContainer}
        disabled={isHardDisabled}
        testID="input-bar"
        renderErrorMessage={false}
        onSubmitEditing={handleSubmit}
      />
    )
  }

  return (
    <View style={Styles.container}>
      {isWeb() && (
        <input
          key={selectedFile?.fileName || 'file-input'} // Forces re-render when file changes
          type="file"
          accept="image/*,.pdf"
          ref={inputFile}
          style={{ display: 'none' }}
          onChange={onInputFileChange}
        />
      )}
      {renderInput()}
    </View>
  )
}

export default InputBar
