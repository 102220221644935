import React, { useEffect, useLayoutEffect, useState } from 'react'
import { FlatList, RefreshControl } from 'react-native'
import { clearDeviceLogs, getDeviceLogs } from 'APP/Services/DeviceLogger'
import styled from 'styled-components/native'
import moment from 'moment'

const LogContainer = styled.View`
  flex-direction: row;
  padding: 10px;
  background-color: white;
  border-bottom-width: 1px;
  border-bottom-color: #ccc;
`
const LogTimestamp = styled.Text`
  flex-shrink: 0;
  font-size: 12px;
  color: #666;
  padding-right: 10px;
`
const LogMessage = styled.Text`
  flex: 3;
  font-size: 12px;
  color: black;
`

const DeviceLogsScreen = ({ navigation }) => {
  const [logs, setLogs] = useState([])
  const [busy, setBusy] = useState(false)

  useEffect(() => {
    getLogs()
  }, [])

  useLayoutEffect(() => {
    navigation.setOptions({
      rightButtonIcon: 'delete',
      rightButtonIconVariant: 'material',
      rightButtonOnPress: clearLogs,
    })
  })

  const getLogs = async () => {
    if (busy) return
    setBusy(true)
    const logs = await getDeviceLogs()
    setLogs(logs)
    setBusy(false)
  }

  const clearLogs = async () => {
    setBusy(true)
    await clearDeviceLogs()
    setLogs([])
    setBusy(false)
  }

  return (
    <FlatList
      data={logs}
      renderItem={({ item }) => (
        <LogContainer>
          <LogTimestamp>{moment(item.timestamp).format('YYYY-MM-DD HH:mm')}</LogTimestamp>
          <LogMessage>{item.message}</LogMessage>
        </LogContainer>
      )}
      keyExtractor={(_, index) => `${index}`}
      refreshControl={<RefreshControl refreshing={busy} onRefresh={getLogs} />}
    />
  )
}

export default DeviceLogsScreen
