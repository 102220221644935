import styled from 'styled-components/native'
import { ListItem as RNEListItem } from 'react-native-elements'
import { Metrics } from 'APP/Themes'

export const StyledListItem = styled(RNEListItem).attrs(
  ({ bottomDivider = true, containerStyle = {}, theme, selected }) => {
    const bottomDividerStyle = bottomDivider
      ? {
          borderBottomWidth: 1,
          borderBottomColor: theme.colors.elementsBorder,
        }
      : {}
    return {
      underlayColor: theme.colors.background,
      containerStyle: {
        backgroundColor: selected ? theme.colors.lightGray : theme.colors.elementsBg,
        paddingHorizontal: Metrics.baseMargin * 1.5,
        ...bottomDividerStyle,
        ...containerStyle,
      },
    }
  }
)``

export const StyledListItemContainer = styled(RNEListItem.Content)`
  flex: 1;
  row-gap: ${({ theme: { metrics } }) => metrics.baseMargin / 8}px;
`
