import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors } from '../../Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  container: {
    flex: 1,
    overflow: 'hidden',
  },
  webView: {
    flex: 1,
    backgroundColor: Colors.appBg,
  },
  loadingOverlay: {
    backgroundColor: Colors.appBg,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    position: 'absolute',
    zIndex: 111,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerShadowBarTop: {
    position: 'absolute',
    left: -10,
    right: -10,
    top: -10,
    height: 10,
    width: '120%',
    zIndex: 1000,
    ...ApplicationStyles.shadow,
    backgroundColor: 'rgba(0,0,0,1)',
  },
  innerShadowBarBottom: {
    position: 'absolute',
    left: -10,
    right: -10,
    bottom: -10,
    height: 10,
    width: '120%',
    zIndex: 1000,
    ...ApplicationStyles.shadow,
    backgroundColor: 'rgba(0,0,0,1)',
  },
})
