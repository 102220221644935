import immutablePersistenceTransform from 'APP/Services/ImmutablePersistenceTransform'
import AsyncStorage from '@react-native-community/async-storage'
import { KEY_PREFIX } from 'redux-persist/constants'
import * as Sentry from '@sentry/react-native'
import { createBlacklistFilter } from 'redux-persist-transform-filter'
import { getSecureStorageEntry, setSecureStorageEntry } from 'APP/Lib/SecureStorage'
import { toCamelCase } from 'APP/Lib/Helpers'
import { isWeb } from 'APP/Helpers/checkPlatform'

export const securelyPersistedLoginEntries = [
  'accessToken',
  'refreshToken',
  'idToken',
  'secondaryAccessToken',
]
export const getSecureStorageKeys = () =>
  securelyPersistedLoginEntries.map((key) => ['login', key].join('.'))

export const migrateTokensFromAsyncStorage = async () => {
  try {
    const secureStorageKeys = getSecureStorageKeys()
    let entries = await Promise.all(secureStorageKeys.map((key) => getSecureStorageEntry(key)))

    // entries found in the secure location no need to migrate
    if (entries.some(Boolean)) return

    const prevLoginData = await AsyncStorage.getItem(`${KEY_PREFIX}login`)
    const parsedLoginData = prevLoginData ? JSON.parse(prevLoginData) : {}

    entries = securelyPersistedLoginEntries.map((entry) => parsedLoginData[entry])

    await Promise.all(
      secureStorageKeys.map((key, index) => setSecureStorageEntry(key, entries[index]))
    )
  } catch (e) {
    Sentry.captureException(e)
  }
}

const patientProfileBlacklistItems = [
  'date_of_birth',
  'email',
  'family_name',
  'given_name',
  'preferred_name',
  'healthcard_number',
  'phone_number',
  'postal_code',
  'street_number',
].flatMap((item) => [item, toCamelCase(item)])

// This prevents specific keys in various stores from being saved and rehydrated
const storeKeyBlackListFilter = [
  ...securelyPersistedLoginEntries.map((key) => ['login', key]),
  ['login', 'fetching'],
  ['login', 'username'],
  ['login', 'password'],
  ['login', 'toggleMfaRequestRunning'],
  ['login', 'toggleMfaRequestFailed'],
  ['login', 'isLoggingOut'],
  ['login', 'customClaims.email'],
  ['login', 'customClaims.name'],
  ['family', 'requestRunning'],
  ...patientProfileBlacklistItems.map((key) => ['patient', `profile.${key}`]),
  ['patient', 'userInfo.email'],
  ['patient', 'profileFetchRunning'],
  ['patient', 'requests'],
  ['patient', 'requestRunning'],
  ['patient', 'changeEmailRunning'],
  ['patient', 'changeEmailError'],
  ['patient', 'unchallengedInvitationSearchRunning'],
  ['patient', 'challengedInvitationSearchRunning'],
  ['patient', 'enrolWithEnrolmentCodesResult'],
  ['patient', 'challengedInvitationQueue'],
  ['patient', 'challengeInvitationRunning'],
  ['patient', 'challengeInvitationError'],
  ['family', 'members'],
  ['app', 'pushTokenRetryCount'],
  ['app', 'updateStatusDuration'],
].map((e) => createBlacklistFilter(e[0], [e[1]], [e[1]]))

const REDUX_PERSIST = {
  active: true,
  reducerVersion: '5.0',
  storeConfig: {
    storage: AsyncStorage,
    blacklist: [
      'appSession',
      'deeplink',
      'videoSession',
      'forms',
      'handoff',
      'history',
      'wellnessCenter',
      'habits',
      'activeMinutes',
      'wellBeingAssessment',
      'appointmentBooking',
      'providerBooking',
      'notifications',
      ...(isWeb() ? ['content'] : []),
    ],
    transforms: [immutablePersistenceTransform, ...storeKeyBlackListFilter],
  },
}

export default REDUX_PERSIST
