import moment from 'moment'

import I18n from 'APP/Services/i18n'
import { SUBTITLE_VARIANT } from 'APP/Components/RankedList/constants'
import { cleanupMetricValue } from 'APP/Lib/ActiveMinutes/challenges'

export function formatSyncedMessage(timestamp) {
  const timeAgo = moment.utc(timestamp).fromNow(true)
  return I18n.t('ActiveMinutes.trackerSyncedAt', { timeAgo })
}

// Crafts the items and separatorIndex from the data
// to handle 2 representations of the leaderboard
// 1. members rank is higher than 50: show all participants
// 2. members rank is lower than 50: show top 3 and 2-3 participants above and below the member
// separatorIndex is used to render a separator
// between the top 3 and the rest of the list for the 2nd case only
export function prepareLeaderboard({ data, isActivitySupported, memberUserId, metric }) {
  let nextSeparatorIndex = null
  const memberRank = data?.find(({ user_id }) => user_id === memberUserId)?.rank
  let nextItems = data?.map((item) => {
    let subtitle = !item.metric_value
      ? I18n.t('WellnessCenter.trackers.notSynced')
      : formatSyncedMessage(item.last_synced_at)
    let subtitleVariant = SUBTITLE_VARIANT.DEFAULT
    if (!isActivitySupported && item.user_id === memberUserId) {
      subtitle = I18n.t('WellnessCenter.trackers.noneCompatible.title')
      subtitleVariant = SUBTITLE_VARIANT.ERROR
    }
    return {
      rank: item.rank,
      title: item.user_display_name,
      subtitle,
      subtitleVariant,
      value: cleanupMetricValue(item.metric_value, metric),
      highlighted: item.user_id === memberUserId,
    }
  })

  if ([nextItems?.length, memberRank].every((n) => n > 50)) {
    const topThree = nextItems?.slice(0, 3)
    const myIndex = data.findIndex((item) => item.user_id === memberUserId)
    const start = Math.max(myIndex - 2, 0)
    const end = Math.min(start + 5, data.length)
    const middleSection = nextItems.slice(start, end)
    nextItems = [...topThree, ...middleSection]
    nextSeparatorIndex = 2
  }

  return [nextItems, nextSeparatorIndex]
}
