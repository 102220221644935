import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ActivityIndicator, SectionList, View, Text, Image, TouchableOpacity } from 'react-native'
import { Appearance } from 'react-native-appearance'
import moment from 'moment'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import { WellnessCenter } from '@dialogue/services'
import I18n from 'APP/Services/i18n'
import Analytics from 'APP/Services/Analytics'
import UrlBuilder from 'APP/Services/UrlBuilder'
import WellnessCenterActions from 'APP/Redux/WellnessCenterRedux'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import Toast from 'react-native-toast-message'
import {
  useAppleHealthKit,
  useFitbit,
  useGoogleFit,
  useStrava,
  useGarmin,
  Errors,
  ErrorCodes,
} from 'APP/Hooks/Tracker'
import Styles from './style'
import { Colors, Metrics } from 'APP/Themes'
import Config from 'APP/Config'
import TrackerIntroModal from './TrackerIntroModal'
import { isMobile } from 'APP/Helpers/checkPlatform'
import { ResponsiveScrollView } from 'APP/Converse/Layout'

const { TrackerStatus } = WellnessCenter.Trackers.Types

const SECTION_KEYS = {
  USER: 'user',
  CONNECTED: 'connected',
  BROKEN: 'broken',
  SUPPORTED: 'supported',
  COMING_SOON: 'comingSoon',
}

const SECTION_TITLES = {
  [SECTION_KEYS.USER]: I18n.t('HealthTrackerSettings.sections.usersTrackers'),
  [SECTION_KEYS.SUPPORTED]: I18n.t('HealthTrackerSettings.sections.allTrackers'),
  [SECTION_KEYS.COMING_SOON]: I18n.t('HealthTrackerSettings.sections.comingSoon'),
}

const SECTION_CTA = {
  [SECTION_KEYS.CONNECTED]: I18n.t('HealthTrackerSettings.ctas.disconnect'),
  [SECTION_KEYS.BROKEN]: I18n.t('HealthTrackerSettings.ctas.reconnect'),
  [SECTION_KEYS.SUPPORTED]: I18n.t('HealthTrackerSettings.ctas.connect'),
  [SECTION_KEYS.COMING_SOON]: I18n.t('HealthTrackerSettings.ctas.request'),
}

const HealthTrackerSettingsScreen_legacy = (props) => {
  const { navigation, route } = props
  const { error, success } = { ...route?.params, ...props }
  const dispatch = useDispatch()
  const trackerIntroModalRef = useRef()

  const appleHealthKit = useAppleHealthKit()
  const googleFit = useGoogleFit()
  const fitbit = useFitbit()
  const strava = useStrava()
  const garmin = useGarmin()
  const [trackerIntroModal, setTrackerIntroModal] = useState(null)

  const { loading, retry, trackers, modifying } = useSelector(
    (state) => state.wellnessCenter.trackers
  )
  const { challenge } = useSelector((state) => state.wellnessCenter.selectedChallenge)
  const trackersDisableForWeb = [
    WellnessCenter.Trackers.Types.TrackerName.GoogleFit,
    WellnessCenter.Trackers.Types.TrackerName.AppleHealthkit,
  ]

  const groupedTrackers = useMemo(() => {
    return Object.entries(trackers).map(([key, value]) => ({
      title: SECTION_TITLES[key],
      key,
      data: value.filter(
        ({ tracker_name }) =>
          tracker_name !== WellnessCenter.Trackers.Types.TrackerName.PhoneTracker &&
          (isMobile() || !trackersDisableForWeb.includes(tracker_name))
      ),
    }))
  }, [trackers])

  useEffect(() => {
    if (success) {
      Toast.show({
        text1: I18n.t('HealthTrackerSettings.trackerConnect_legacy.successTitle'),
        text2: I18n.t(`HealthTrackerSettings.trackerConnect_legacy.trackerMessages.${success}`),
      })
      Analytics.trackEvent('banner_view', { banner_name: 'Tracker successful banner' })
    }
  }, [success])

  useEffect(() => {
    getTrackers()
  }, [])

  // Tracker error messaging
  useEffect(() => {
    const toastConfig = {}
    const errorCode = (error && error[1]) || (appleHealthKit.error && appleHealthKit.error.code)
    const connectionError =
      (appleHealthKit.error && appleHealthKit.error.message === Errors.ConnectError) ||
      (googleFit.error && googleFit.error.message === Errors.ConnectError) ||
      (fitbit.error && fitbit.error.message === Errors.ConnectError) ||
      (strava.error && strava.error.message === Errors.ConnectError) ||
      (garmin.error && garmin.error.message === Errors.ConnectError)

    const authorizationError =
      (fitbit.error && fitbit.error.message === Errors.AuthorizeError) ||
      (googleFit.error && googleFit.error.message === Errors.AuthorizeError) ||
      (strava.error && strava.error.message === Errors.AuthorizeError) ||
      (garmin.error && garmin.error.message === Errors.AuthorizeError) ||
      (error && error[0] === Errors.AuthorizeError)

    const disconnectError =
      (appleHealthKit.error && appleHealthKit.error.message === Errors.DisconnectError) ||
      (fitbit.error && fitbit.error.message === Errors.DisconnectError) ||
      (googleFit.error && googleFit.error.message === Errors.DisconnectError) ||
      (strava.error && strava.error.message === Errors.DisconnectError) ||
      (garmin.error && garmin.error.message === Errors.DisconnectError)

    const syncSamplesError =
      appleHealthKit.error && appleHealthKit.error.message === Errors.SyncSamplesError

    if (connectionError || authorizationError || disconnectError || syncSamplesError) {
      Analytics.trackEvent('banner_view', { banner_name: 'Tracker error banner' })
    }

    if (connectionError) {
      toastConfig.text1 = I18n.t('HealthTrackerSettings.error.connect')
    }

    if (authorizationError) {
      toastConfig.text1 = I18n.t('HealthTrackerSettings.error.authorize')
    }

    if (disconnectError) {
      toastConfig.text1 = I18n.t('HealthTrackerSettings.error.disconnect')
    }

    if (syncSamplesError) {
      toastConfig.text1 = I18n.t('HealthTrackerSettings.error.syncSamples')
    }

    if (String(errorCode).toUpperCase() === ErrorCodes.FORBIDDEN) {
      toastConfig.type = 'error'
      toastConfig.position = 'bottom'
      toastConfig.autoHide = false
      toastConfig.text1 = I18n.t('HealthTrackerSettings.errorTitle')
      toastConfig.text2 = I18n.t('HealthTrackerSettings.error.forbidden')
    }

    if (Object.keys(toastConfig).length > 0) Toast.show(toastConfig)
  }, [appleHealthKit.error, googleFit.error, fitbit.error, strava.error, garmin.error, error])

  const getTrackers = () => dispatch(WellnessCenterActions.getTrackers())

  const handleHelpPress = () => {
    const uri = UrlBuilder.cmsConsultUrl('healthTrackers', Appearance.getColorScheme())
    navigation.navigate('cmsScreen', { uri, screen: 'cms', showShareButton: false })
  }

  const handleTrackerPress = async (key, trackerName, trackerStatus) => {
    // Ignore any additional presses while tracker is being modified
    if (modifying && modifying.includes(trackerName)) return

    if (key === SECTION_KEYS.COMING_SOON) {
      Analytics.trackEvent('button_click', {
        button_value: trackerName,
        trigger: 'request_tracker',
      })
      Toast.show({
        text1: I18n.t('HealthTrackerSettings.requestTrackerNotification.title'),
        text2: I18n.t('HealthTrackerSettings.requestTrackerNotification.copy'),
      })
    }

    if (
      key === SECTION_KEYS.SUPPORTED ||
      (key === SECTION_KEYS.USER && trackerStatus === TrackerStatus.Broken)
    ) {
      Analytics.trackEvent('button_click', {
        button_value: trackerName,
        trigger: key === SECTION_KEYS.SUPPORTED ? 'connect_tracker' : 'reconnect_tracker',
      })

      if (trackerName === appleHealthKit.name) {
        await appleHealthKit.connect()
        // Update router params to trigger success banner
        navigation.setParams({ success: appleHealthKit.name })
      } else if (trackerName === googleFit.name) {
        googleFit.connect(Config.HEALTH_TRACKER_SETTINGS_SCREEN_DEEP_LINK_URL)
      } else if (trackerName === fitbit.name) {
        fitbit.connect(Config.HEALTH_TRACKER_SETTINGS_SCREEN_DEEP_LINK_URL)
      } else if (trackerName === strava.name) {
        strava.connect(Config.HEALTH_TRACKER_SETTINGS_SCREEN_DEEP_LINK_URL)
      } else if (trackerName === garmin.name) {
        garmin.connect(Config.HEALTH_TRACKER_SETTINGS_SCREEN_DEEP_LINK_URL)
      }
    }

    if (key === SECTION_KEYS.USER && trackerStatus === TrackerStatus.Connected) {
      Analytics.trackEvent('button_click', {
        button_value: trackerName,
        trigger: 'disconnect_tracker',
      })
      if (trackerName === appleHealthKit.name) {
        appleHealthKit.disconnect()
      } else if (trackerName === googleFit.name) {
        googleFit.disconnect()
      } else if (trackerName === fitbit.name) {
        fitbit.disconnect()
      } else if (trackerName === strava.name) {
        strava.disconnect()
      } else if (trackerName === garmin.name) {
        garmin.disconnect()
      }
    }
  }

  const openConnectModalOrHandleTrackerPress = (key, tracker) => {
    if (
      key === SECTION_KEYS.SUPPORTED &&
      I18n.exists(`HealthTrackerSettings.modal_legacy.${tracker.tracker_name}`)
    ) {
      setTrackerIntroModal(tracker)
    } else {
      handleTrackerPress(key, tracker.tracker_name, tracker.status)
    }
  }

  const handleTrackerIntroConnect = async (tracker) => {
    await trackerIntroModalRef.current?.terminateModal()
    await handleTrackerPress(SECTION_KEYS.SUPPORTED, tracker.tracker_name, tracker.status)
  }

  return (
    <NoticeBarContainer>
      <ResponsiveScrollView>
        {loading && (
          <ActivityIndicator
            size="large"
            color={Colors.text}
            style={Styles.loading}
            testID="loading"
          />
        )}
        {!loading && !retry && (
          <View>
            <DialogueTouchableOpacity
              style={Styles.help}
              testID="help"
              onPress={handleHelpPress}
              analyticsName="How trackers work"
            >
              <Text style={Styles.helpCopy}>{I18n.t('HealthTrackerSettings.help')}</Text>
              <MaterialIcon
                name="arrow-forward-ios"
                size={Metrics.icons.tiny}
                color={Colors.accent}
              />
            </DialogueTouchableOpacity>
            <SectionList
              sections={groupedTrackers}
              keyExtractor={(item, index) => item + index}
              renderItem={({ item, section: { key } }) => {
                const supportsRequestedActivity =
                  item?.activities && challenge?.activity
                    ? item.activities.includes(challenge?.activity)
                    : true

                return (
                  <View
                    style={
                      !supportsRequestedActivity
                        ? [Styles.trackerWrapper, Styles.disabledTrackerWrapper]
                        : Styles.trackerWrapper
                    }
                    testID="tracker"
                  >
                    <View style={Styles.tracker}>
                      <Image
                        style={Styles.image}
                        source={{ uri: item.content.badge['200x200'] }}
                        resizeMode="cover"
                      />
                      <Text style={Styles.name}>
                        {I18n.t(`HealthTrackerSettings.trackers_legacy.${item.tracker_name}`)}
                      </Text>
                      {supportsRequestedActivity ? (
                        <TouchableOpacity
                          testID="trackerCta"
                          onPress={() => openConnectModalOrHandleTrackerPress(key, item)}
                        >
                          {modifying && modifying.includes(item.tracker_name) ? (
                            <ActivityIndicator
                              testID={`${item.tracker_name}-modifying`}
                              color={Colors.accent}
                            />
                          ) : (
                            <Text style={Styles.cta}>
                              {key === SECTION_KEYS.USER
                                ? SECTION_CTA[item.status]
                                : SECTION_CTA[key]}
                            </Text>
                          )}
                        </TouchableOpacity>
                      ) : (
                        <Text style={Styles.disabledCta} testID="notCompatible">
                          {I18n.t('HealthTrackerSettings.ctas.notCompatible')}
                        </Text>
                      )}
                    </View>
                    {key === SECTION_KEYS.USER && item.last_sync_time && (
                      <View style={Styles.subInfo} testID="trackerSync">
                        <Text style={Styles.syncedCopy}>
                          {I18n.t('HealthTrackerSettings.synced')}{' '}
                          {moment.utc(item.last_sync_time).fromNow()}
                        </Text>
                        <MaterialIcon
                          name="done"
                          size={Metrics.icons.small}
                          color={Colors.accent}
                        />
                      </View>
                    )}
                    {key === SECTION_KEYS.USER && item.status === TrackerStatus.Broken && (
                      <View style={Styles.subInfo} testID="trackerReconnect">
                        <Text style={Styles.reconnectCopy}>
                          {I18n.t('HealthTrackerSettings.reconnect')}
                        </Text>
                        <MaterialIcon
                          name="error"
                          size={Metrics.icons.small}
                          color={
                            Appearance.getColorScheme() === 'dark' ? Colors.text : Colors.errorText
                          }
                        />
                      </View>
                    )}
                  </View>
                )
              }}
              renderSectionHeader={({ section: { title, data } }) => {
                // Don't render title if there's no trackers in this section
                if (data.length === 0) return null

                return (
                  <Text testID="sectionTitle" style={Styles.header}>
                    {title}
                  </Text>
                )
              }}
            />
          </View>
        )}
        {!loading && retry && (
          <DialogueTouchableOpacity
            style={Styles.refresh}
            onPress={getTrackers}
            analyticsName="Refresh"
            testID="retry"
          >
            <MaterialIcon name="refresh" size={Metrics.icons.large} color={Colors.text} />
          </DialogueTouchableOpacity>
        )}
      </ResponsiveScrollView>
      <TrackerIntroModal
        ref={trackerIntroModalRef}
        tracker={trackerIntroModal}
        onClose={() => setTrackerIntroModal(null)}
        onConnect={handleTrackerIntroConnect}
      />
    </NoticeBarContainer>
  )
}

export default HealthTrackerSettingsScreen_legacy
