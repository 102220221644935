import React from 'react'
import { Linking } from 'react-native'
import * as Sentry from '@sentry/react-native'
import { useTheme } from 'styled-components/native'

import { Images } from 'APP/Themes'
import { logDdError } from 'APP/Lib/Datadog'
import UrlBuilder from 'APP/Services/UrlBuilder'
import I18n from 'APP/Services/i18n'

import { getAnalyticsScreenName } from '../utils'
import { ServiceGroupCard } from '../ServiceGroupCard'

export const PrivacyPolicyGroupCard = ({
  serviceGroup,
}: {
  serviceGroup: Record<string, unknown>
}) => {
  const theme = useTheme()
  const analyticsScreenName = getAnalyticsScreenName(serviceGroup)
  const privacyUrl = UrlBuilder.privacyUrl()
  const onPress = async () => {
    await Linking.openURL(privacyUrl).catch((reason) => {
      const msg = `Error opening privacy policy link. message: ${reason.message}, url: ${privacyUrl}`

      Sentry.captureException(msg)
      logDdError(msg, 'ServiceGroup.openURL')
    })
  }

  return (
    <ServiceGroupCard
      onPress={onPress}
      testID="PRIVACY_POLICY"
      analyticsName="privacyPolicy"
      icon={
        <Images.verifiedUserIcon
          accentFillLine={theme.colors.accent}
          width={theme.metrics.icon.xsmall}
          height={theme.metrics.icon.xsmall}
        />
      }
      cardText={I18n.t('ServiceGroup.privacyPolicy.text')}
      callToAction={I18n.t('ServiceGroup.privacyPolicy.link')}
      analyticsScreenName={analyticsScreenName}
      trackEventValue="showPrivacyPolicy"
      trackEventText="Show Privacy Policy"
    />
  )
}
