import * as Sentry from '@sentry/react-native'

const PRESCRIPTION_UTTERANCES = ['utter_ask_drug_name', 'utter_ask_prescription_picture']
const PRESCRIPTION_ELSEWHERE_MESSAGE = ['Somewhere else', 'Autre part']

const GYN_SEXUAL_HEALTH_GROUP_IDS = ['urology_gynecology', 'sexual_health']

const isPostSomewhereElse = (post) => {
  return PRESCRIPTION_ELSEWHERE_MESSAGE.includes(post?.message)
}

export const checkIsPrescriptionFlow = (posts, questionPost) => {
  if (!PRESCRIPTION_UTTERANCES.includes(questionPost?.props?.utterance)) {
    return false
  }

  if (posts) {
    return Object.values(posts).findIndex(isPostSomewhereElse) !== -1
  }

  return false
}

export const checkIsGynOrSexualHealthFlow = (posts) => {
  if (posts) {
    return (
      Object.values(posts).findIndex((post) =>
        GYN_SEXUAL_HEALTH_GROUP_IDS.includes(post?.props?.selected_choice?.group_id)
      ) !== -1
    )
  }

  return false
}

export const checkIsProviderSelectionSurveyEnabled = (posts, lastPost) => {
  if (posts) {
    let isProviderSelectionFlow = false
    let memberJustBooked = false
    Object.values(posts).forEach((post) => {
      if (post.props?.type === 'prompt_provider_list') {
        isProviderSelectionFlow = true
      }
    })
    if (lastPost?.props?.utterance === 'utter_open_chat_for_mhs_appointment') {
      memberJustBooked = true
    }
    return isProviderSelectionFlow && memberJustBooked
  }

  return false
}

// Log to see if a post is missing a message, since this might have caused the blank Intake issue.
export const captureUndefinedMessages = (posts, channelId) => {
  const emptyMessages = Object.values(posts).filter((post) => {
    return !Object.prototype.hasOwnProperty.call(post, 'message')
  })
  if (emptyMessages?.length > 0) {
    Sentry.captureMessage('A post message is undefined', {
      level: 'warning',
      extra: {
        posts,
        emptyMessages,
        episodeId: channelId,
      },
    })
  }
}
