import React from 'react'
import styled from 'styled-components/native'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'

import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import Typography from 'APP/Converse/Typography'
import { Metrics } from 'APP/Themes'

export { default as TabContent } from './TabContent'

const Tabs = ({ value, onChange, children, analyticsName }) => {
  return (
    <TabsContainer>
      {React.Children.map(children, (child, index) => (
        <TabButton
          key={index}
          active={value === index}
          onPress={() => onChange(index)}
          testID={child.props.testID}
          analyticsName={`${analyticsName}.tab.pressed: ${child.props.title}`}
        >
          <TabIcon name={child.props.icon} active={value === index} size={Metrics.icons.tiny} />
          <TabText active={value === index} variant="bodySmallBold">
            {child.props.title}
          </TabText>
        </TabButton>
      ))}
    </TabsContainer>
  )
}

Tabs.Item = () => null
Tabs.Item.displayName = 'Tabs.Item'

const TabsContainer = styled.View`
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.elementsBg};
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.elementsBorder};
`

const TabButton = styled(DialogueTouchableOpacity)`
  flex: 0.2;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.metrics.baseMargin}px;
  border-bottom-width: ${({ active }) => (active ? '2px' : '0px')};
  border-bottom-color: ${({ theme }) => theme.colors.disabled};
`

const TabIcon = styled(MaterialIcon)`
  margin-right: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  color: ${({ active, theme }) => (active ? theme.colors.text : theme.colors.disabled)};
`

const TabText = styled(Typography)`
  color: ${({ active, theme }) => (active ? theme.colors.text : theme.colors.disabled)};
`

export default Tabs
