import React from 'react'
import { Controller, UseFieldArrayProps } from 'react-hook-form'

import Picker from 'APP/Converse/Inputs/Picker'
import { Item } from 'react-native-picker-select'

interface ControllerPickerProps {
  control: UseFieldArrayProps['control']
  name: string
  rules?: UseFieldArrayProps['rules']
  placeholder: string
  label: string
  items: Item[]
  testID?: string
  isFocused?: boolean
  analyticsName?: string
  infotip?: string
  disabled?: boolean
}

const ControllerPicker = ({
  control,
  name,
  rules = {},
  placeholder,
  label,
  items,
  testID,
  isFocused,
  analyticsName,
  infotip,
  disabled,
}: ControllerPickerProps) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <Picker
          analyticsName={analyticsName}
          label={label}
          placeholder={placeholder}
          value={value}
          onValueChange={onChange}
          items={items}
          testID={testID}
          isFocused={isFocused}
          infotip={infotip}
          error={error?.message}
          disabled={disabled}
        />
      )}
    />
  )
}

export default ControllerPicker
