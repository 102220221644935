import { StyleSheet } from 'react-native'
import { Colors, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  modal: {
    backgroundColor: Colors.appBg,
    borderRadius: 20,
    width: 500,
    height: 400,
    alignSelf: 'center',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  innerContent: {
    paddingLeft: Metrics.baseMargin,
    paddingRight: Metrics.baseMargin,
    alignItems: 'center',
    gap: Metrics.baseMargin,
  },
  title: {
    textAlign: 'center',
  },
})
