import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.transparentClickable,
  },
  innerBox: {
    width: 200,
    display: 'flex',
    backgroundColor: Colors.elementsBg,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
    borderRadius: 15,
    ...ApplicationStyles.shadow,
    marginBottom: Metrics.statusBarHeight + Metrics.navBarHeight,
  },
  text: {
    color: Colors.text,
    paddingTop: 15,
    paddingBottom: 5,
  },
  spinner: {
    alignSelf: 'center',
    margin: 15,
  },
})
