import { StyleSheet } from 'react-native'
import { Colors, Fonts, Metrics, ApplicationStyles } from 'APP/Themes'
import Color from 'color'

export const Styles = StyleSheet.create({
  container: {
    width: '100%',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  zoomPopupOverlay: {
    backgroundColor: Color(Colors.overlay).alpha(0.6).rgb().string(),
  },
  zoomPopup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 24,
    paddingVertical: 16,
    borderRadius: Metrics.baseMargin,
    backgroundColor: Colors.appBg,
    ...ApplicationStyles.shadow,
  },
  zoomPopupText: {
    ...Fonts.style.bodyLargeBold,
    color: Colors.text,
    alignItems: 'baseline',
    textAlign: 'center',
  },
  pinchIcon: {
    width: 27,
    height: 45,
    marginRight: 17,
    tintColor: Colors.accent,
  },
})
