import { createActions, createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  startup: null,
  setPersistedLoginId: ['persistedLoginId'],
  setPushTokenRequest: ['pushToken', 'retryCount'],
  setPushTokenSuccess: ['pushToken'],
  setPushTokenFailure: ['pushToken', 'error', 'retryCount'],
  setNotificationPermissionAccepted: ['isAccepted'],
  setLocationPermissionAccepted: ['isAccepted'],
  setRatingThreshold: ['hideRatingModal', 'episodeThreshold'],
  rateApp: ['action'],
  setChangeEmailBannerHidden: ['userId'],
  setBusierThanUsualBannerVisible: ['isVisible'],
  setBusierThanUsualBannerHidden: ['userId', 'timestamp'],
  setAppleWatchDisconnectedBannerVisible: ['isVisible'],
  setAppLockToggle: ['value'],
  toggleDebugFab: null,
  toggleDebugCrumbs: null,
  triggerAppLock: null,
  activateAppLockToggle: null,
  mandatoryMfaToggleRequest: null,
  mandatoryMfaToggled: null,
  createNotificationChannels: null,
})

export const INITIAL_STATE = Immutable({
  pushToken: null,
  pushTokenError: null,
  pushTokenRetryCount: 0,
  persistedLoginId: null,
  pushPermissionsAccepted: null,
  locationPermissionAccepted: null,
  episodeThreshold: 2,
  showDebugFab: false,
  showDebugCrumbs: false,
})

export const asUserSettings = (userId = 'UNSPECIFIED_USER', propsToStore) => ({
  userSettings: {
    [userId]: propsToStore,
  },
})

export const setPersistedLoginId = (state, { persistedLoginId }) =>
  state.merge({ persistedLoginId })

export const setPushTokenRequest = (state, { pushToken }) =>
  state.merge({ pushToken, pushTokenRetryCount: 0 })

export const setPushTokenSuccess = (state, { pushToken }) =>
  state.merge({ pushToken, pushTokenError: null, pushTokenRetryCount: 0 })

export const setPushTokenFailure = (state, { pushToken, error = null, retryCount = 0 }) =>
  state.merge({ pushToken, pushTokenError: error, pushTokenRetryCount: retryCount })

export const setNotificationPermissionAccepted = (state, { isAccepted }) =>
  state.merge({ pushPermissionsAccepted: isAccepted })

export const setLocationPermissionAccepted = (state, { isAccepted }) =>
  state.merge({ locationPermissionAccepted: isAccepted })

export const setRatingThreshold = (state, { hideRatingModal, episodeThreshold }) =>
  state.merge({ hideRatingModal, episodeThreshold })

export const toggleDebugFab = (state) => state.merge({ showDebugFab: !state.showDebugFab })

export const toggleDebugCrumbs = (state) => state.merge({ showDebugCrumbs: !state.showDebugCrumbs })

export const setChangeEmailBannerHidden = (state, { userId }) =>
  state.merge(asUserSettings(userId, { hideChangeEmailModal: true }), { deep: true })

export const setBusierThanUsualBannerVisible = (state, { isVisible }) =>
  state.merge({ showBusierThanUsualModal: isVisible })

export const setBusierThanUsualBannerHidden = (state, { userId, timestamp }) =>
  state.merge(asUserSettings(userId, { lastBusierThanUsualModal: timestamp }), { deep: true })

export const setAppleWatchDisconnectedBannerVisible = (state, { isVisible }) =>
  state.merge({ showAppleWatchDisconnectedModal: isVisible })

export const setAppLockToggle = (state, { value }) => state.merge({ appLock: { isToggled: value } })

export const setDeeplink = (state, { deeplink }) => state.merge({ deeplink })

export const clearDeeplink = (state) => state.merge({ deeplink: null })

export const StartupTypes = Types

export default Creators

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_PERSISTED_LOGIN_ID]: setPersistedLoginId,
  [Types.SET_PUSH_TOKEN_REQUEST]: setPushTokenRequest,
  [Types.SET_PUSH_TOKEN_SUCCESS]: setPushTokenSuccess,
  [Types.SET_PUSH_TOKEN_FAILURE]: setPushTokenFailure,
  [Types.SET_NOTIFICATION_PERMISSION_ACCEPTED]: setNotificationPermissionAccepted,
  [Types.SET_LOCATION_PERMISSION_ACCEPTED]: setLocationPermissionAccepted,
  [Types.SET_RATING_THRESHOLD]: setRatingThreshold,
  [Types.TOGGLE_DEBUG_FAB]: toggleDebugFab,
  [Types.TOGGLE_DEBUG_CRUMBS]: toggleDebugCrumbs,
  [Types.SET_CHANGE_EMAIL_BANNER_HIDDEN]: setChangeEmailBannerHidden,
  [Types.SET_BUSIER_THAN_USUAL_BANNER_VISIBLE]: setBusierThanUsualBannerVisible,
  [Types.SET_BUSIER_THAN_USUAL_BANNER_HIDDEN]: setBusierThanUsualBannerHidden,
  [Types.SET_APPLE_WATCH_DISCONNECTED_BANNER_VISIBLE]: setAppleWatchDisconnectedBannerVisible,
  [Types.SET_APP_LOCK_TOGGLE]: setAppLockToggle,
})
