import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Metrics } from '../../Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  scrollContainer: {
    flex: 1,
    backgroundColor: Colors.appBg,
  },
  image: {
    height: 230,
    flex: 1,
    marginBottom: Metrics.baseMargin * 1.5,
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    minHeight: '100%',
  },
  refresh: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%',
  },
})
