import React, { useMemo } from 'react'
import { Text, View } from 'react-native'
import I18n from 'APP/Services/i18n'
import { Recommender } from '@dialogue/services'

import {
  CompareRecommendation,
  DefaultRecommendation,
  FocusRecommendation,
  ProgressRecommendation,
} from '../Recommendations'
import Styles from './style'

const getGroupTitle = (recommendations, score, focus) => {
  switch (recommendations?.[0]?.type) {
    case Recommender.Types.RecommendationType.PROGRESS:
      return I18n.t('WellBeingIndexScreen.recommendationGroup.title.progress', { score })
    case Recommender.Types.RecommendationType.COMPARE:
      return I18n.t('WellBeingIndexScreen.recommendationGroup.title.compare')
    case Recommender.Types.RecommendationType.FOCUS:
      return I18n.t('WellBeingIndexScreen.recommendationGroup.title.focus', {
        domain: I18n.t(`WellBeingIndexScreen.domains.${focus}`).toLowerCase(),
      })
    default:
      return I18n.t('WellBeingIndexScreen.recommendationGroup.title.default')
  }
}

const getRecommendationComponent = (recommendation) => {
  switch (recommendation.type) {
    case Recommender.Types.RecommendationType.PROGRESS:
      return ProgressRecommendation
    case Recommender.Types.RecommendationType.COMPARE:
      return CompareRecommendation
    case Recommender.Types.RecommendationType.FOCUS:
      return FocusRecommendation
    default:
      return DefaultRecommendation
  }
}

const RecommendationGroup = ({ recommendations, spaceBetween, ...rest }) => {
  const title = useMemo(
    () => getGroupTitle(recommendations, rest.score, rest.focus),
    [recommendations, rest.score, rest.focus]
  )

  return (
    <View style={[Styles.container, spaceBetween && Styles.spaceBetween]}>
      <Text style={Styles.title}>{title}</Text>
      {recommendations.map((recommendation, index) => {
        const Component = getRecommendationComponent(recommendation)
        return <Component key={index} {...recommendation} {...rest} />
      })}
    </View>
  )
}

export default RecommendationGroup
