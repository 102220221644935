import React from 'react'
import { View } from 'react-native'
import styled, { useTheme } from 'styled-components/native'
import { getServiceOrServiceGroupTileIcon } from 'APP/Lib/ContentHelpers'
import ServiceButtonCard from 'APP/Components/ServiceButtonCard'
import useHandleOnBenefitsPress from 'APP/Hooks/BenefitNavigationCTA'
import { ServiceActionButton } from '../ServiceActionButton'
import { getAnalyticsScreenName } from '../utils'

const ServicesContainer = styled(View)`
  margin-horizontal: ${({ theme }) => theme.metrics.baseMargin * 2}px;
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin * 2}px;
  gap: 20px;
`

export const ServiceButtonsContainer = ({
  services,
  hasExternalProvider,
  serviceGroup,
}: {
  services: Record<string, string>[]
  hasExternalProvider: boolean
  serviceGroup: Record<string, unknown>
}) => {
  const theme = useTheme()
  const handleOnBenefitsPress = useHandleOnBenefitsPress()
  const analyticsScreenName = getAnalyticsScreenName(serviceGroup)
  const onPress = (service: Record<string, string>) => {
    handleOnBenefitsPress(service, analyticsScreenName, serviceGroup)
  }

  return (
    <ServicesContainer>
      {services.length === 1 ? (
        <ServiceActionButton
          service={services[0]}
          hasExternalProvider={hasExternalProvider}
          onPress={() => onPress(services[0])}
        />
      ) : (
        services.map((service, index) => (
          <ServiceButtonCard
            key={`sbc-${index}`}
            iconTitle={getServiceOrServiceGroupTileIcon(service, {
              width: theme.metrics.icon.small,
              height: theme.metrics.icon.small,
              fillLine: theme.colors.serviceIconPrimary,
              accentFillLine: theme.colors.serviceIconAccent,
            })}
            title={service.title}
            description={service.subtitle}
            tag={service.tag}
            onPress={() => onPress(service)}
            testID="SERVICE_CARD"
          />
        ))
      )}
    </ServicesContainer>
  )
}
