import React from 'react'
import Typography from 'APP/Converse/Typography'
import { useSelector } from 'react-redux'
import { CreditCardView } from 'react-native-credit-card-input'

// Services
import I18n from 'APP/Services/i18n'
import { routeNameToAnalyticsName } from 'APP/Nav'

// Components
import Button from 'APP/Converse/Button'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import DialogueBranding from 'APP/Components/DialogueBranding'
import ListItem from 'APP/Converse/ListItem'
import List from 'APP/Components/List'

// Styling
import Styles from './style'
import { ComponentConfigs } from 'APP/Themes'
import { isWeb } from 'APP/Helpers/checkPlatform'
import { ResponsiveView } from 'APP/Converse/Layout'

const PaymentScreen = ({ navigation, route }) => {
  const analyticsName = routeNameToAnalyticsName(route?.name)
  const { name, last4Digits, expirationMonth, expirationYear } = useSelector(
    (state) => state.patient?.profile?.creditCard
  )

  const renderCardInfo = () => {
    return (
      <ResponsiveView>
        <CreditCardView
          {...ComponentConfigs.creditCardForm}
          focusedField="number"
          name={name}
          number={`**** **** **** ${last4Digits}`}
          expiry={`${expirationMonth}/${expirationYear}`}
          style={Styles.illustration}
        />
        {isWeb() && (
          <List>
            <ListItem
              testID="web-payment-screen-card-name"
              analyticsName="name"
              key="name"
              title={'Name'}
              rightTitle={name}
              hideIconComponent
            />
            <ListItem
              testID="web-payment-screen-card-last4digits"
              analyticsName="last4Digits"
              key="last4Digits"
              title={'Card Number'}
              rightTitle={`**** **** **** ${last4Digits}`}
              hideIconComponent
            />
            <ListItem
              testID="web-payment-screen-card-expiration"
              analyticsName="expiration"
              key="expiration"
              title={'Expiration'}
              rightTitle={`${expirationMonth}/${expirationYear}`}
              hideIconComponent
            />
          </List>
        )}
      </ResponsiveView>
    )
  }

  return (
    <NoticeBarContainer>
      <ResponsiveView style={Styles.container}>
        {renderCardInfo()}
        <Typography style={Styles.primaryText} variant="bodyNormal">
          {I18n.t('PaymentScreen.body')}
        </Typography>
        <Button
          variant="primary"
          widthVariant="full"
          analyticsName={analyticsName}
          title={I18n.t('PaymentScreen.edit.button')}
          onPress={() => navigation.navigate('addPayment')}
        />
      </ResponsiveView>
      <DialogueBranding />
    </NoticeBarContainer>
  )
}

export default PaymentScreen
