import { ChatContext, PostContext } from 'APP/Lib/Context'
import React, { useContext, useState, memo } from 'react'
import { Text, View, TouchableOpacity, Linking } from 'react-native'
import moment from 'moment'
import I18n from 'APP/Services/i18n'

import { useActionSheet } from '@expo/react-native-action-sheet'
import Animated, { FadeIn, Layout } from 'react-native-reanimated'
import ParsedText from 'react-native-parsed-text'
import Communications from 'react-native-communications'
import Clipboard from '@react-native-clipboard/clipboard'
import PatientHistoryActions from 'APP/Redux/PatientHistoryRedux'
import Analytics from 'APP/Services/Analytics'
import { useDispatch } from 'react-redux'

import { Styles } from './style'
import { MessageContainer } from 'APP/Converse/Chat/MessageContainer'

const WWW_URL_PATTERN = /^www\./i

const TextMessage = () => {
  const { post, isMember } = useContext(PostContext)
  const { channelId } = useContext(ChatContext)

  const [isOpened, setIsOpened] = useState(false)
  const { showActionSheetWithOptions } = useActionSheet()
  const dispatch = useDispatch()

  const error = post?.props?.failed
  const date = `${moment(post.create_at).format('LT')}`

  const handleURL = (url) => {
    if (WWW_URL_PATTERN.test(url)) {
      handleURL(`https://${url}`)
    } else {
      Linking.canOpenURL(url).then((supported) => {
        if (supported) {
          Linking.openURL(url)
        }
      })
    }
  }

  const handlePhone = (phone) => {
    const options = [
      I18n.t('ConversationScreen.actionSheets.call'),
      I18n.t('ConversationScreen.actionSheets.text'),
      I18n.t('ConversationScreen.actionSheets.cancel'),
    ]
    const cancelButtonIndex = options.length - 1
    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            Communications.phonecall(phone, true)
            break
          case 1:
            Communications.text(phone)
            break
        }
      }
    )
  }

  const handleEmail = (email) => {
    Communications.email([email], null, null, null, null)
  }

  const handleLongPressError = () => {
    const options = [
      I18n.t('ConversationScreen.actionSheets.retry'),
      I18n.t('ConversationScreen.actionSheets.cancel'),
    ]

    showActionSheetWithOptions(
      {
        options,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) {
          Analytics.trackEvent('button_click', { button_value: 'Retry send message' })
          dispatch(PatientHistoryActions.retryPostRequest(channelId, post.id))
        } else {
          Analytics.trackEvent('button_click', { button_value: 'Cancel send message' })
          dispatch(PatientHistoryActions.removePendingPost(channelId, post.id))
        }
      }
    )
  }

  const handleLongPress = () => {
    const options = [
      I18n.t('ConversationScreen.actionSheets.copy'),
      I18n.t('ConversationScreen.actionSheets.cancel'),
    ]

    const cancelButtonIndex = options.length - 1
    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
      },
      (buttonIndex) => buttonIndex === 0 && Clipboard.setString(post.message)
    )
  }

  const handlePress = () => {
    setIsOpened(!isOpened)
  }

  return (
    <View style={{ alignItems: isMember ? 'flex-end' : 'flex-start' }}>
      <TouchableOpacity onPress={handlePress}>
        <MessageContainer rtl={isMember} isError={!!error}>
          <ParsedText
            style={isMember ? Styles.rightText : Styles.leftText}
            onPress={handlePress}
            onLongPress={error ? handleLongPressError : handleLongPress}
            parse={[
              { type: 'url', style: Styles.link, onPress: handleURL },
              { type: 'phone', style: Styles.link, onPress: handlePhone },
              { type: 'email', style: Styles.link, onPress: handleEmail },
            ]}
          >
            {post.message}
          </ParsedText>
        </MessageContainer>
      </TouchableOpacity>
      {isOpened && (
        <Animated.View style={Styles.dateContainer} entering={FadeIn} layout={Layout}>
          <Text style={Styles.date}>{date}</Text>
        </Animated.View>
      )}
    </View>
  )
}

export default memo(TextMessage)
