import { StyleSheet } from 'react-native'
import { ApplicationStyles, Metrics } from 'APP/Themes'

export const Styles = StyleSheet.create({
  ...ApplicationStyles.screen,
  addressCard: {
    flex: 1,
    flexBasis: 'auto',
    marginHorizontal: 0,
    marginVertical: Metrics.baseMargin,
  },
})
