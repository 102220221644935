import { StyleSheet } from 'react-native'
import { ApplicationStyles, Metrics, Colors, Fonts } from 'APP/Themes'

export default StyleSheet.create({
  container: {
    padding: Metrics.baseMargin / 2,
  },
  containerSmall: {
    flexBasis: '50%',
    alignSelf: 'stretch',
  },
  card: {
    borderRadius: 16,
    backgroundColor: Colors.elementsBg,
    ...ApplicationStyles.shadow,
    flex: 1,
  },
  image: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    aspectRatio: 2 / 1,
  },
  tagList: {
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: Metrics.baseMargin * 0.8,
    marginTop: Metrics.baseMargin * -1,
    marginRight: Metrics.baseMargin * -0.3,
  },
  tag: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Colors.tabSelected,
    paddingVertical: Metrics.baseMargin * 0.3,
    paddingHorizontal: Metrics.baseMargin / 2,
    borderRadius: 2,
    marginRight: Metrics.baseMargin * 0.3,
  },
  tagIcon: {
    color: Colors.textInverted,
  },
  tagCopy: {
    ...Fonts.style.caption,
    color: Colors.textInverted,
    textTransform: 'uppercase',
    marginLeft: Metrics.baseMargin * 0.3,
  },
  clockIcon: {
    marginRight: Metrics.baseMargin * 0.3,
  },
  description: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
    padding: Metrics.baseMargin,
  },
  title: {
    ...Fonts.style.h4,
    color: Colors.text,
    paddingTop: Metrics.baseMargin * 1.3,
    paddingBottom: Metrics.baseMargin * 0.3,
  },
  subtitle: {
    flexShrink: 1,
    ...Fonts.style.bodySmall,
    color: Colors.text,
  },
  details: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: Metrics.baseMargin,
  },
  detailsItem: {
    flexGrow: 1,
    flexShrink: 0,
    flexDirection: 'row',
    alignItems: 'center',
  },
  detailsCopy: {
    ...Fonts.style.bodySmall,
    color: Colors.text,
    flexShrink: 1,
  },
  totalValue: {
    flex: 1,
    alignItems: 'center',
    marginTop: Metrics.baseMargin,
    paddingTop: Metrics.baseMargin * 0.75,
    borderTopWidth: 1,
    borderColor: Colors.elementsBorder,
  },
  totalValueText: {
    ...Fonts.style.h4,
    color: Colors.text,
    textTransform: 'lowercase',
  },
})
