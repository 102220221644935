// NOTE: copied directly from MWA/Lib/SessionManager.js, lint modifications, and changed OT from global to npm module
// TODO(WEB): Implement Session manager on web
import OT from '@opentok/client'

export const SessionManager = {
  currentSession: null,
  streamsById: {},
  callbacks: {},
  createSession(apiKey, sessionId, token) {
    if (this.currentSession) {
      this.currentSession.disconnect()
    }
    const callbacktypes = Object.keys(this.callbacks)
    this.currentSession = OT.initSession(apiKey, sessionId)
    this.currentSession.connect(token, (error) => {
      if (error && __DEV__) console.log(error)
    })
    callbacktypes.forEach((type) => {
      const callback = this.callbacks[type]
      this.currentSession.on(type, callback)
    })

    this.updateVideoSourceCount()

    this.streamsById = {}
  },
  destroySession() {
    if (this.currentSession) {
      this.currentSession.disconnect()
      this.currentSession = null
      this.streamsById = {}
      this.callbacks = {}
    }
  },
  pauseSession() {
    if (this.currentSession) {
      this.currentSession.onPause()
    }
  },
  resumeSession() {
    if (this.currentSession) {
      this.currentSession.onResume()
    }
  },
  sendSignal(type, data) {
    if (this.currentSession) {
      this.currentSession.signal({ type, data: JSON.stringify(data) })
    }
  },
  cycleVideo() {
    this.pub?.cycleVideo()
  },
  cycleAudioOutput() {
    this.pub?.cycleAudioOutput()
  },
  on(type, callback) {
    const onEvent = (event) => {
      let signalType = event.type
      if (signalType && signalType.indexOf('signal:') !== -1) event.type = signalType.split(':')[1]

      if (signalType === 'streamCreated') {
        const oldCallback = callback
        callback = (stream) => {
          this.streamsById[stream.streamId] = stream
          oldCallback(stream)
        }
        event = event.stream
      }

      if (signalType === 'streamDestroyed') {
        const oldCallback = callback
        callback = (stream) => {
          delete this.streamsById[stream.streamId]
          oldCallback(stream)
        }
        event = event.stream
      }

      if (signalType === 'sessionConnected') {
        const oldCallback = callback
        callback = () => {
          oldCallback({
            sessionId: this.currentSession.sessionId,
            connection: this.currentSession.connection,
          })
        }
      }

      callback(event)
    }

    if (this.currentSession) {
      this.currentSession.on(type, onEvent)
    }
    this.callbacks[type] = onEvent
  },
  off(type, callback) {
    if (this.currentSession) {
      this.currentSession.off(type)
    }
    if (callback) {
      callback()
    }
  },
  disconnect() {
    if (this.currentSession) {
      this.currentSession.off()
      this.currentSession.disconnect()
      this.currentSession = null
      this.streamsById = {}
    }
  },
  mutePublisher(muteAudio) {
    if (this.pub) {
      this.pub.publishAudio(!muteAudio)
    }
  },
  videoSourceCount: 0,
  updateVideoSourceCount() {
    OT.getDevices((error, devices) => {
      if (devices instanceof Array && !error) {
        const videoInputDevices = devices.filter((element) => element.kind == 'videoInput')
        this.videoSourceCount = videoInputDevices.length
      }
    })
  },
  publish() {
    return new Promise((resolve, reject) => {
      if (this.currentSession) {
        this.pub = OT.initPublisher(
          null,
          { insertDefaultUI: false, name: 'PatientDesktop', echoCancellation: true },
          (err) => {
            if (err) this.sendSignal('callEvent', 'patientMediaDeviceError')
          }
        )
        this.currentSession.publish(this.pub, (err) => {
          if (err) {
            console.error(err)
            this.sendSignal('callEvent', 'patientMediaDeviceError')
          }
        })

        this.pub.on('streamAvailableError', (event) => {
          event.preventDefault()
        })

        this.pub.once('videoElementCreated', (event) => {
          resolve(event.element)
        })

        this.pub.once('streamDisconnected', (event) => {
          resolve(event.element)
          return true
        })

        this.pub.once('streamCreated', () => {
          this.startPublisherStatsMonitor()
        })

        this.pub.once('streamDestroyed', (event) => {
          this.stopPublisherStatsMonitor()
          event.preventDefault()
          resolve(event.element)
          return true
        })
      } else {
        reject(new Error('No session exists'))
      }
    })
  },
  unpublish() {
    if (this.pub) {
      this.pub.destroy()
    }
  },
  subscribe(streamId) {
    return new Promise((resolve, reject) => {
      if (this.currentSession && this.streamsById[streamId]) {
        this.sub = this.currentSession.subscribe(this.streamsById[streamId], null, {
          insertDefaultUI: false,
        })
        this.sub.once('videoElementCreated', (event) => {
          resolve(event.element)
        })
        this.sub.once('streamDisconnected', (event) => {
          resolve(event.element)
        })
        this.sub.once('streamDestroyed', (event) => {
          event.preventDefault()
          resolve(event.element)
        })
      } else {
        reject(new Error('No session or stream exists'))
      }
    })
  },
  unsubscribe() {
    if (this.sub) {
      this.sub.destroy()
    }
  },

  publisherStatsUpdate() {
    if (
      this.pub &&
      typeof this.pub.getStats === 'function' &&
      typeof this.callbacks.publisherStatsUpdate === 'function'
    ) {
      this.pub.getStats((error, [{ stats }]) => {
        if (!error && stats && stats.video && stats.audio) {
          const statsPayload = {
            videoPacketsSent: stats.video.packetsSent,
            videoPacketsLost: stats.video.packetsLost,
            audioPacketsSent: stats.audio.packetsSent,
            audioPacketsLost: stats.audio.packetsLost,
          }
          this.callbacks.publisherStatsUpdate(statsPayload)
        }
      })
    }
  },

  publisherStatsUpdateTimer: null,

  startPublisherStatsMonitor() {
    this.publisherStatsUpdateTimer = setInterval(() => this.publisherStatsUpdate(), 5000)
  },

  stopPublisherStatsMonitor() {
    clearInterval(this.publisherStatsUpdateTimer)
  },
}

export default SessionManager
