import React from 'react'
import { Controller } from 'react-hook-form'

import Checkbox from 'APP/Converse/Inputs/Checkbox'

const ControllerCheckbox = ({ control, name, rules = {}, label = '', labelStyle = {} }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange } }) => (
        <Checkbox
          label={label}
          onPress={() => onChange(!value)}
          status={value ? 'checked' : 'unchecked'}
          labelStyle={labelStyle}
        />
      )}
    />
  )
}

export default ControllerCheckbox
