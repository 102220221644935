import styled from 'styled-components/native'
import SearchField from 'APP/Components/SearchField'
import { Animated, StyleSheet } from 'react-native'

import { Colors, Metrics } from 'APP/Themes'
import { isWeb } from 'APP/Helpers/checkPlatform'

export const Styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    backgroundColor: Colors.appBg,
  },
  scroll: {
    backgroundColor: Colors.appBg,
  },
  scrollContent: {
    paddingBottom: Metrics.bottomSpace + Metrics.baseMargin,
  },
})

export const ContentContainer = styled(Animated.View)`
  flex: 1;
  overflow: hidden;
  flex-wrap: nowrap;
  margin-vertical: ${({ theme }) => theme.metrics.baseMargin * 2}px;
  margin-horizontal: ${({ theme }) => theme.metrics.baseMargin}px;
  border-radius: ${({ theme }) => theme.metrics.borderRadius.medium}px;
  background-color: ${({ theme }) => theme.colors.elementsBg};
  ${({ theme }) => theme.shadows.normal}
`

export const SearchContainer = styled.View`
  background-color: ${({ theme }) => theme.colors.elementsBg};
  margin-bottom: ${({ theme }) => (isWeb() ? 0 : theme.metrics.baseMargin)}px;
  padding-top: ${({ theme }) => (isWeb() ? theme.metrics.baseMargin : 0)}px;
`

export const StyledSearchField = styled(SearchField)`
  padding: ${({ theme }) => theme.metrics.baseMargin}px;
  padding-top: 0;
`

export const ButtonContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: ${({ theme }) => theme.metrics.baseMargin}px;
  padding: ${({
    theme: {
      metrics: { baseMargin },
    },
  }) => `0 ${baseMargin}px ${baseMargin}px`};
  align-items: flex-end;
`
