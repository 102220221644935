import React from 'react'
import { Image } from 'react-native'

import MattermostAvatar from 'APP/Components/MattermostAvatar'
import Config from 'APP/Config'
import I18n from 'APP/Services/i18n'
import { Images, Colors } from 'APP/Themes'

export const getPractitionerName = (practitioner) => {
  if (!practitioner) return null

  const { title, givenName, familyName = '' } = practitioner
  return `${title ? `${title} ` : ''}${givenName} ${familyName}`
}

export const getEmptyPractitioner = (lang = 'en', screen = undefined) => {
  const isFr = lang === 'fr'
  const name = I18n.t('CarePlanScreen.emptyPractitioner.name')
  const mainSpecialization = I18n.t('CarePlanScreen.emptyPractitioner.mainSpecialization')

  if (screen === 'carePlanList' && isFr) {
    return { name: name.toLowerCase(), mainSpecialization }
  }
  return { name, mainSpecialization }
}

export const getImage = (practitioner, styles) => {
  if (practitioner?.picture) {
    if (practitioner?.picture === Images.amyBarnes) {
      // Special case for the fake care plan in the RecentCarePlans component
      return <Image source={practitioner?.picture} style={styles} />
    } else {
      return <MattermostAvatar picture={practitioner.picture} style={styles} />
    }
  } else if (practitioner && !practitioner.picture) {
    const tintClinicIcon = Config.FEATURES && Config.FEATURES.PRIMARY_COLOR_TINT && Colors.accent
    return (
      <Image
        source={Images.logoNurse}
        style={[styles, { tintColor: tintClinicIcon || undefined }]}
      />
    )
  } else {
    return <Image source={Images.logoBlue} style={styles} />
  }
}

export const getPractitionerFromChat = ({ post, isUser, users }) => {
  const practitionerArr = isUser
    ? ''
    : users.filter((user) => {
        return user.id == post.user_id
      })
  return practitionerArr && practitionerArr[0]
}

export const patchPractitionerProfileData = (practitioner, practitioners) => {
  if (!practitioner || practitioner?.full_name || !practitioners) {
    return practitioner
  }

  const practitionerFromStore =
    Object.values(practitioners).find((p) => String(p.app_id) === String(practitioner?.id)) || {}
  const { first_name = '', last_name = '' } = practitionerFromStore

  return {
    ...practitioner,
    full_name: `${first_name} ${last_name}`.trim(),
  }
}
