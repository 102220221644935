// Utility functions

import { isMobile } from 'APP/Helpers/checkPlatform'
import Config from 'APP/Config'
import I18n from 'APP/Services/i18n'
import moment from 'moment'

export const isValidEmailAddress = (address) => {
  const EMAIL_REGEXP =
    /^[a-z0-9!#$%&'*+ /=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i

  return address && address.length > 5 && EMAIL_REGEXP.test(address)
}

export const isValidPassword = (password) => {
  return password && password.length > 5
}

export const hasLoginHash = (hash) => {
  const loginHashFields = ['access_token', 'scope', 'expires_in', 'token_type', 'state']
  const hashFields = hash.split('&').map((piece) => piece.split('=')[0].replace('#', ''))
  return loginHashFields.every((field) => hashFields.includes(field))
}

export const MINUTE_IN_MILLISECOND = 60000

export const clearAllQueryParamsAndHash = () => {
  if (isMobile()) return
  const url = new URL(window.location.origin + window.location.pathname)
  window.history.replaceState(null, null, url)
}

// Returns the oldest timestamp allowed for a public session
export const getMaxPublicSessionTimestamp = () => {
  return Date.now() - Config.PUBLIC_COMPUTER_MINUTE_SESSION_LENGTH * MINUTE_IN_MILLISECOND
}

// show the modal after the session length - 1 min (2h59)
export const INACTIVITY_TIMEOUT =
  Config.PUBLIC_COMPUTER_MINUTE_SESSION_LENGTH * MINUTE_IN_MILLISECOND - 60 * 1000

// Ensure to return a proper url, where query params are appended properly
// Query params are located at the end of the URL, where it starts with a ‘?’, and multiple params are then separated by an ‘&’
// ex: https://dialogue.co/path?name=Branch&products=[phone,tablet]
export const getHref = () => {
  if (isMobile()) return
  const url = new URL(window.location.href)
  url.searchParams.set('lng', I18n.locale)
  return url.href
}

export const secondsToHoursAndMinutes = (seconds) => {
  if (isNaN(seconds) || seconds < 0) return 'Invalid input'

  const duration = moment.duration(seconds, 'seconds')

  const hours = duration.hours() + duration.days() * 24
  const minutes = duration.minutes()

  if (hours && minutes) return I18n.t('Timing.hoursAndMinutes', { hours, minutes })
  else if (hours && !minutes) return I18n.t('Timing.hours', { hours })
  else if (!hours && minutes) return I18n.t('Timing.minutes', { minutes })
  return null
}
