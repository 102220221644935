import Moment from 'moment'

//Services
import I18n from 'APP/Services/i18n'
export const getFormattedEpisodeItem = (
  episode,
  channel,
  practitioners,
  login,
  healthIssueTypeIcons
) => {
  const members = getAllMembersFromPosts(channel, practitioners)
  const concern = channel && channel.header

  const { rawLastPost, lastPost } = getFormattedLastPost(members, channel)
  const participantNames = getLastPostUserName(members, lastPost, login)

  // busy is an intermediate state where the posts are not all fetched yet
  return {
    healthIssueTypeIcon:
      episode?.healthIssueTypeId && healthIssueTypeIcons[episode.healthIssueTypeId],
    title: getEpisodeTitle(episode),
    episodeId: episode.id,
    channelId: channel.channelId,
    participantNames,
    concern,
    lastPost,
    unread: channel.lastViewedAt < rawLastPost.create_at,
    busy: channel.order ? channel.order.length === 0 : false,
    lastMessageTimestamp: rawLastPost.create_at,
    outcomeId: episode.outcomeId,
  }
}

// TODO: Remove this entire thing. Should come from the store.
export const getAllMembersFromPosts = (channel, practitioners) => {
  let membersFromPosts = []
  if (
    channel.order instanceof Array &&
    channel.posts instanceof Object &&
    practitioners instanceof Object
  ) {
    let addedMembers = []
    channel.order.forEach((id) => {
      const mmUserId = channel.posts[id].user_id
      if (addedMembers.indexOf(mmUserId) === -1) {
        addedMembers.push(mmUserId)
        const user =
          practitioners[mmUserId] ||
          (channel.allMembers && channel.allMembers.find((u) => u === mmUserId))
        // found practitioner
        if (user?.app_id) {
          const avatarUrl = user.avatar_url || (user.props && user.props.picture)
          membersFromPosts.push({
            ...user,
            ...{ props: { picture: avatarUrl, app_id: user.props && user.props.app_id } },
          })
          // found user
        } else {
          membersFromPosts.push({
            id: user,
          })
        }
      }
    })
  }
  return (membersFromPosts && membersFromPosts.length > 0 && membersFromPosts) || []
}

export const getFormattedLastPost = (members, channel) => {
  const rawLastPost =
    (channel.order && channel.order.length > 0 && channel.posts[channel.order[0]]) || {}
  let lastPost = {
    user: members.find((a) => a.id === rawLastPost.user_id),
    message: (rawLastPost.message || '').trim(),
    date: rawLastPost.update_at ? Moment(parseInt(rawLastPost.update_at)).fromNow(true) : '',
  }

  if (rawLastPost) {
    if (rawLastPost.actionType === 'videoCall')
      lastPost.message = I18n.t('ConversationScreen.videoConsultation')
    if (rawLastPost.actionType === 'carePlan')
      lastPost.message = I18n.t('ConversationScreen.carePlan')
    if (rawLastPost.actionType === 'createChild') lastPost.message = rawLastPost.actionContext.text
    if (rawLastPost.actionType === 'takeIdPhoto') lastPost.message = rawLastPost.actionContext.text

    const hasAttachment = rawLastPost.filenames || rawLastPost.file_ids || rawLastPost.localFile
    if (hasAttachment) {
      lastPost.message = I18n.t('ConversationScreen.snippets.attachment')
    }
  }
  return { rawLastPost, lastPost }
}

export const getLastPostUserName = (members, lastPost, login) => {
  const lastPostUserId = lastPost?.user?.id
  const user = members.filter((member) => member.id === lastPostUserId)
  const { first_name, last_name } = user?.[0] || {}
  const name = [first_name, last_name].filter((el) => el).join(' ')
  if (name) {
    return name
  } else if (user?.[0]?.id === login?.messagingUserId) {
    return I18n.t('EpisodeComponent.you')
  } else {
    return I18n.t('Common.careTeam')
  }
}

const getFormattedRecentEpisodeItem = (
  episode,
  channel,
  practitioners,
  login,
  healthIssueTypeIcons
) => {
  const members = getAllMembersFromPosts(channel, practitioners)

  const { rawLastPost, lastPost } = getFormattedLastPost(members, channel)

  const postUserName = lastPost?.user?.id && getLastPostUserName(members, lastPost, login)

  const userId = (lastPost && lastPost.user && lastPost.user.id) || null
  const user = practitioners && practitioners[userId]
  const avatarUrl = user && (user.avatar_url || (user.props && user.props.picture))

  return {
    id: episode.id,
    title: episode?.healthIssueTypeId ? getEpisodeTitle(episode) : postUserName,
    subtitle: lastPost.message,
    lastPostDate: rawLastPost.create_at,
    formattedLastPostDate:
      rawLastPost.create_at && Moment.utc(rawLastPost.create_at).local().fromNow(),
    isBotActive: episode.botStatus === 'active',
    healthIssueTypeIcon:
      episode?.healthIssueTypeId && healthIssueTypeIcons[episode.healthIssueTypeId],
    avatarUrl,
    outcomeId: episode.outcomeId,
  }
}

export const isEpisodeVisible = (episode, channel) =>
  Boolean(episode && channel && !channel.archived)

export const getRecentMessages = (login, history, amountOfDays = 30, healthIssueTypeIcons) => {
  const { orderedEpisodes, episodes, channels, practitioners } = history

  const thirtyDaysAgo = Moment().subtract(amountOfDays, 'days').startOf('day')
  const availableEpisodes = orderedEpisodes?.filter((id) =>
    isEpisodeVisible(episodes[id], channels[id])
  )

  const formattedEpisodes = availableEpisodes
    ?.map((id) =>
      getFormattedRecentEpisodeItem(
        episodes[id],
        channels[id],
        practitioners,
        login,
        healthIssueTypeIcons
      )
    )
    ?.filter((episode) =>
      episode.lastPostDate
        ? Moment(episode.lastPostDate).isAfter(thirtyDaysAgo) && episode.outcomeId !== 'complete'
        : false
    )
    .slice(0, 3)

  return formattedEpisodes
}

export const getEpisodeTitle = (episode) => {
  const healthIssueTypeId = episode?.healthIssueTypeId
  if (
    healthIssueTypeId &&
    healthIssueTypeId !== I18n.t(`health-issue-types:${healthIssueTypeId}`)
  ) {
    return I18n.t(`health-issue-types:${healthIssueTypeId}`)
  } else {
    // there is no translation for the issue type id
    // or there is no issue type id yet (going through intake)
    return I18n.t('EpisodeComponent.newChat')
  }
}
