import { Metrics } from 'APP/Themes'
import { StyleSheet } from 'react-native'

export const Styles = StyleSheet.create({
  container: {
    'backdrop-filter': 'blur(5px)',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.7)',
  },
  image: {
    borderRadius: Metrics.baseMargin,
  },
  closeIcon: {
    position: 'absolute',
    top: Metrics.baseMargin,
    right: Metrics.baseMargin,
    width: 50,
    height: 50,
    zIndex: 100,
  },
})
