import { StyleSheet } from 'react-native'
import { Colors } from 'APP/Themes'

export const Styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 5,
    backgroundColor: Colors.multiSelectMsgBg,
    overflow: 'hidden',
  },
  progress: {
    display: 'flex',
    alignItems: 'flex-end',
    overflow: 'hidden',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: Colors.accent,
  },
  processing: {
    flex: 1,
    width: '40%',
    opacity: 1,
  },
})
