import I18n from 'APP/Services/i18n'
import DeviceInfo from 'react-native-device-info'
import * as Sentry from '@sentry/react-native'
import Config from 'APP/Config'
import AddressPicker from 'APP/Components/FelixChat/AddressPicker'
import AddToCalendar from 'APP/Components/FelixChat/AddToCalendar'
import BodyPart from 'APP/Components/FelixChat/BodyPart'
import CarePlan from 'APP/Components/FelixChat/CarePlan'
import Charge from 'APP/Components/FelixChat/Charge'
import CmsContent from 'APP/Components/FelixChat/CmsContent'
import EpharmacyPrescription from 'APP/Components/FelixChat/EpharmacyPrescription'
import File from 'APP/Components/FelixChat/File'
import ResourceLink from 'APP/Components/FelixChat/ResourceLink'
import TextMessage from 'APP/Components/FelixChat/TextMessage'
import MicroApp from 'APP/Components/FelixChat/MicroApp'
import PostWithMissingData from 'APP/Components/FelixChat/PostWithMissingData'
import VideoCall from 'APP/Components/FelixChat/VideoCall'
const DIALOGUE_SYSTEM_TYPE = 'custom_dialogue_system'
const LEGACY_DIALOGUE_SYSTEM_TYPE = 'dialogue_system'
import merge from 'lodash/merge'
import { logDdError } from 'APP/Lib/Datadog'

export const formatPostMessage = (post) => {
  if (!post) return ''
  switch (post.actionType) {
    case 'videoCall':
      return I18n.t('ConversationScreen.videoConsultation')
    case 'carePlan':
      return I18n.t('ConversationScreen.carePlan')
    case 'createChild':
      return post.actionContext && post.actionContext.text
    case 'takeIdPhoto':
      return post.actionContext && post.actionContext.text
    default: {
      const hasAttachment = post.file_ids || post.filenames
      if (hasAttachment) {
        return I18n.t('ConversationScreen.snippets.attachment')
      } else {
        return post.message || ''
      }
    }
  }
}

export const normalizePostActions = (post) => {
  let action = {}
  post.actionType = 'none'
  // TODO: Remove LEGACY_DIALOGUE_SYSTEM_TYPE once it's completely deprecated
  if (
    (post.type === LEGACY_DIALOGUE_SYSTEM_TYPE || post.type === DIALOGUE_SYSTEM_TYPE) &&
    post.props
  ) {
    post.actionType = post.props.type
    post.actionContext = { ...post.props }
  }

  if (post && post.props && post.props.actions && post.props.actions[0]) {
    action = post.props.actions[0]
  }

  if (post && post.message && /%\[.*?\]\(.*?\)/.test(post.message)) {
    const messageParts = post.message.match(/%\[(.*?)\]\((.*?)\)/)
    action = {
      uri: messageParts[2],
      text: messageParts[1],
    }
  }

  if (action.uri) {
    const uri = action.uri
    const uriComponents = uri.split('/')
    if (uri.indexOf('/start-encounter/') > -1) {
      post.actionType = 'videoCall'
      post.actionContext = { encounterId: uriComponents[uriComponents.length - 1] }
    }
    if (uri.indexOf('/encounter/') > -1) {
      post.actionType = 'carePlan'
      post.actionContext = { encounterId: uriComponents[uriComponents.length - 1] }
    }
    if (uri.indexOf('/create-child') > -1) {
      post.actionType = 'createChild'
      post.actionContext = { text: action.text }
    }
    if (uri.indexOf('/photo-id') > -1) {
      post.actionType = 'takeIdPhoto'
      post.actionContext = { text: action.text }
    }
    if (uri.indexOf('/charge') > -1) {
      post.actionType = 'charge'
      let params = {}
      uri
        .split('?')[1]
        .split('&')
        .map((s) => s.split('='))
        .forEach((item) => {
          params[item[0]] = item[1]
        })
      post.actionContext = {
        text: action.text,
        amount: params.amt,
        description: params.desc,
        uuid: params.uuid,
      }
    }
  }
  post.message = formatPostMessage(post)
  return post
}

export const featureBlockedPost = () => {
  return true
}

export const isPatientVisiblePost = (post) => {
  if (
    post &&
    post.props &&
    (post.props.patient_visible === false || post.props.member_app_visible === false)
  ) {
    return false
  }

  if (post.props && post.props.retracted) {
    return false
  }

  if (Config.SUPPORTED_CHAT_BOT.name !== 'countdown') {
    if (post && post.props && post.props.sender && post.props.sender === 'countdown') {
      return false
    }
  }

  const noType = !post.type
  const emptyType = post.type === ''
  // TODO: Remove LEGACY_DIALOGUE_SYSTEM_TYPE once it's completely deprecated
  const hasSubType =
    (post.type === LEGACY_DIALOGUE_SYSTEM_TYPE || post.type === DIALOGUE_SYSTEM_TYPE) &&
    post.props.type
  const isFeatureEnabled = featureBlockedPost(post)

  return (noType || emptyType || hasSubType) && isFeatureEnabled
}

export const isReplyPost = (post) => !!post.parent_id

export const isSelfPost = (post) =>
  post && post.props && post.props.deviceId === DeviceInfo.getUniqueId()

export const isVideoCallPost = (post) =>
  (post && post.actionType === 'videoCall') || (post.props && post.props.type === 'VIDEO_CALL')

export const isEncounterPost = (post) =>
  post &&
  post.props &&
  post.props.actions &&
  post.props.actions[0] &&
  post.props.actions[0].uri &&
  (post.props.actions[0].uri.indexOf('/start-encounter/') !== -1 ||
    post.props.actions[0].uri.indexOf('/encounter/') !== -1)

export const ensureParsing = (data) => {
  if (typeof data === 'object') return data
  if (typeof data === 'string') {
    try {
      return JSON.parse(data)
    } catch (e) {
      Sentry.captureException(e)
      logDdError(e.message, e.stack)
    }
  }
}

export const getQuestionFromPost = (post) =>
  ensureParsing(post && post.props && post.props.question)

export const getAnswerFromPost = (post) => ensureParsing(post && post.props && post.props.answer)

export const isAnswerPost = (post) => !!getAnswerFromPost(post)

export const isQuestionPost = (post) => !!getQuestionFromPost(post)

export const isQuestionWithResponseUi = (post) => {
  const question = getQuestionFromPost(post)
  return typeof question === 'object' && question.type === 'multiChoiceQuestion'
}

export const isAnswerFromResponseUi = (post) => {
  const answer = getAnswerFromPost(post)
  return typeof answer === 'object' && answer.question_type === 'multiChoiceQuestion'
}

export const isPostWhichHidesKeyboard = (post) =>
  isQuestionWithResponseUi(post) || isAnswerFromResponseUi(post)

export const isPostWhichTriggersNotifications = (post) =>
  post &&
  post.type === '' &&
  isPatientVisiblePost(post) &&
  !isVideoCallPost(post) &&
  !isSelfPost(post)

export const channelToTimeStampForNewPost = (channel) => {
  try {
    const lastPostId = channel.pendingOrder[0] || channel.order[0]
    const lastPost = channel.pendingPosts[lastPostId] || channel.posts[lastPostId]
    const lastPostAt = (lastPost && lastPost.create_at) || 0
    const timeStamp = Math.max(lastPostAt + 1, Date.now())
    return timeStamp
  } catch (err) {
    return Date.now()
  }
}

export const isChannelInconsistent = (channel) => {
  return (
    typeof channel !== 'object' ||
    !channel ||
    !!channel.error ||
    typeof channel.channelId !== 'string' ||
    typeof channel.posts !== 'object' ||
    typeof channel.draft !== 'object' ||
    typeof channel.pendingPosts !== 'object' ||
    typeof channel.msgCount !== 'number' ||
    !(channel.order instanceof Array) ||
    !(channel.pendingOrder instanceof Array) ||
    !(channel.allMembers instanceof Array)
  )
}

const slotRegEx = /{.+}/
export const addCommandSlots = (cmd, extraSlots) => {
  try {
    const match = cmd.match(slotRegEx) || ['{}']
    const slots = JSON.parse(match[0])
    const newSlots = {
      ...slots,
      ...extraSlots,
    }
    const newCommand = (match.index ? cmd.slice(0, match.index) : cmd) + JSON.stringify(newSlots)
    return newCommand
  } catch (e) {
    return cmd
  }
}

export const isValidPostData = (postData) => {
  return !!(
    postData &&
    postData.order &&
    postData.order.length &&
    postData.posts &&
    Object.keys(postData.posts).length
  )
}

export const isCustomMessageType = (post) =>
  post &&
  post.props &&
  ['prompt_choice_single'].includes(post.props.type) &&
  post.props.choices &&
  post.props.choices.some((choice) => choice.link !== undefined)

export const isCalendar = (post) => {
  return (
    isCustomMessageType(post) &&
    post?.props?.utterance === 'utter_calendar_url' &&
    post?.props?.choices[0]?.link?.startsWith('data:text/calendar')
  )
}

export const isEpharmacyPrescription = (post) =>
  isCustomMessageType(post) && !!post?.props?.choices[0]?.context?.epharmacy_id

export const isCmsContent = (post) => {
  return isCustomMessageType(post) && post?.props?.choices[0]?.context?.link_type === 'cms'
}

export const isFile = (post) => {
  return Boolean(post.file_ids?.[0] || post.localFile)
}

export const isResourseLink = (post) => post && post.props && post.props.resource

export const getPostComponent = (post) => {
  // support legacy type for single choice
  const isSingleChoice =
    post?.props?.type === 'prompt_choice_single' || post?.props?.type === 'multiChoiceQuestion'

  if (isFile(post)) {
    return File
  } else if (post.actionType === 'charge') {
    return Charge
  } else if (post.actionType === 'carePlan') {
    return CarePlan
  } else if (post.actionType === 'videoCall') {
    return VideoCall
  } else if (post?.props?.type === 'findAddress.v1') {
    return AddressPicker
  } else if (post?.props?.type === 'microAppCta.v1') {
    return MicroApp
  } else if (isSingleChoice && isEpharmacyPrescription(post)) {
    return EpharmacyPrescription
  } else if (isSingleChoice && isCmsContent(post)) {
    return CmsContent
  } else if (isSingleChoice && !isCalendar(post)) {
    return TextMessage
  } else if (isSingleChoice && isCalendar(post)) {
    return AddToCalendar
  } else if (isResourseLink(post)) {
    return ResourceLink
  } else if (post.props?.missingData) {
    return PostWithMissingData
  } else if (post?.props?.type === 'bodyPartSelector.v1') {
    // only used in legacy /r intake flow
    return BodyPart
  } else {
    return TextMessage
  }
}

export const onlyDisplayedWhenLastPost = (post) => {
  if (
    post?.props?.type === 'findAddress.v1' ||
    post?.props?.microapp?.displayed_on === 'last_message'
  ) {
    return true
  }

  return false
}

export const formatLegacyMultiSelectAnswer = (post, choices) => {
  const allChoices = post?.props?.choices
  const requestedSlot = post?.props?.requested_slot

  let answer = {
    [requestedSlot]: true,
  }

  allChoices.forEach((value) => {
    if (choices.indexOf(value) !== -1) {
      answer[value.slot] = value.true_value
    } else {
      answer[value.slot] = value.false_value
    }
  })
  return `/answer_question${JSON.stringify(answer)}`
}

/**
 * Checks if a mattermost message is an archivable system post.
 * @param post - The post to check.
 * @returns True if the post is an archivable system post, else false.
 */
export const hasIsArchivableSystemPost = (post) =>
  post?.type === 'custom_dialogue_system' &&
  post?.props?.dialogue_type === 'episode_props_updated' &&
  typeof post.props.updated_props?.is_archivable === 'boolean'

/**
 * Finds the archivable system post within hidden posts.
 * @param hiddenPosts - The collection of hidden posts.
 * @returns The archivable post if found, else null.
 */
export const findArchivableSystemPost = (hiddenPosts) => {
  if (!hiddenPosts) {
    return null
  }

  const posts = Object.values(hiddenPosts)
  const archivablePost = posts.findLast(hasIsArchivableSystemPost)

  if (archivablePost) {
    return archivablePost
  }

  return null
}

/**
 * Updates the episode with the archivable status from hidden posts.
 * @param hiddenPosts - The collection of hidden posts.
 * @param episode - The episode to update.
 * @returns The updated episode or null
 */
export const updateEpisodeWithArchivableStatus = (hiddenPosts = {}, episode) => {
  if (!episode) {
    return null
  }

  const archivablePost = findArchivableSystemPost(hiddenPosts)

  if (archivablePost) {
    return merge({}, episode, {
      isArchivable: archivablePost.props.updated_props.is_archivable,
    })
  }

  return null
}
