import I18n from 'APP/Services/i18n'
import Config from 'APP/Config'
import { DISTANCE_UNITS, REGION_UNIT } from './constants'

export function getLocalUnitForMetric(metric) {
  const region = Config.REGION
  const regionUnitMap = REGION_UNIT[region] || REGION_UNIT.default
  const regionUnit = regionUnitMap[metric]

  return regionUnit
}

export function convertMetricToLocalUnit(value, metric) {
  const regionUnitForMetric = getLocalUnitForMetric(metric)

  switch (regionUnitForMetric) {
    // meters to miles
    case DISTANCE_UNITS.MILE:
      return value * 0.000621371
    // meters to km
    case DISTANCE_UNITS.KM:
      return value / 1000
    default:
      return value
  }
}

const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export function getLocalUnitLabelForMetric(metric, total = false) {
  // Get the unit for the given metric based on the region
  const regionUnitForMetric = getLocalUnitForMetric(metric)

  // Initialize the base part of the translation key
  let baseKey = 'WellnessCenter.challenges.metric.'
  if (Object.values(DISTANCE_UNITS).includes(regionUnitForMetric)) {
    // Working with distance units
    baseKey += 'distance'
    // If the unit is not kilometers (KM), modify the base key to surface the correct translation
    if (regionUnitForMetric !== DISTANCE_UNITS.KM) {
      const capitalizedUnit = capitalizeFirstLetter(regionUnitForMetric)
      baseKey += capitalizedUnit
    }
  } else {
    // no special handling for other metrics
    baseKey += metric
  }

  // Append 'Total' to the key if the total flag is true
  const translationKey = total ? `${baseKey}Total` : baseKey

  return I18n.exists(translationKey) ? I18n.t(translationKey) : undefined
}
