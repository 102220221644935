import { Colors, Fonts, Metrics, ApplicationStyles } from 'APP/Themes'

const avatar = {
  width: 32,
  height: 32,
  marginTop: 3,
}

export default {
  ...ApplicationStyles.screen,
  spacer: ApplicationStyles.spacer,
  listSpacer: ApplicationStyles.listSpacer,
  rowContainer: {
    flexDirection: 'row',
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: Colors.elementsBorder,
    paddingVertical: 12,
    paddingHorizontal: Metrics.baseMargin * 1.5,
    backgroundColor: Colors.elementsBg,
  },
  rowUnreadContainer: {
    flexDirection: 'row',
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: Colors.elementsBorder,
    paddingVertical: 12,
    paddingHorizontal: Metrics.baseMargin * 1.5,
    backgroundColor: Colors.elementsBg,
  },
  rowTextContainerView: {
    flexDirection: 'column',
    flex: 1,
    marginLeft: 15,
  },
  rowTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
  },
  rowBodyContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  rowBodyTextContainer: {
    flexDirection: 'column',
    flex: 1,
  },
  rowIconContainer: {
    flex: 0,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 10,
  },
  participantNamesText: {
    flex: 1,
    alignSelf: 'center',
    color: Colors.text,
    ...Fonts.style.subheader,
  },
  participantNamesUnreadText: {
    flex: 1,
    alignSelf: 'center',
    color: Colors.text,
    ...Fonts.style.subheaderBold,
  },
  dateText: {
    color: Colors.textSecondary,
    ...Fonts.style.caption,
  },
  dateUnreadText: {
    color: Colors.textSecondary,
    ...Fonts.style.captionBold,
  },
  concernText: {
    color: Colors.text,
    ...Fonts.style.bodySmall,
  },
  concernUnreadText: {
    color: Colors.text,
    ...Fonts.style.bodySmallBold,
  },
  chatSnippetText: {
    color: Colors.text,
    ...Fonts.style.bodySmall,
  },
  chatSnippetUnreadText: {
    color: Colors.text,
    ...Fonts.style.bodySmallBold,
  },
  avatar,
  healthIcon: {
    ...avatar,
    overflow: 'visible',
  },
  episodesStateContainer: {
    flex: 1,
  },
  episodesHeaderContainer: {
    flexDirection: 'row',
    paddingVertical: 14,
    paddingHorizontal: Metrics.baseMargin * 1.5,
    borderBottomWidth: 1,
    borderBottomColor: Colors.elementsBorder,
  },
  episodesList: {
    flex: 1,
    paddingVertical: 0,
  },
  illustration: {
    alignSelf: 'center',
    marginTop: Metrics.baseMargin * 2,
    height: 160,
    resizeMode: 'contain',
    marginBottom: Metrics.baseMargin,
  },
  title: {
    ...ApplicationStyles.screen.title,
    color: Colors.text,
    marginBottom: Metrics.baseMargin / 2,
  },
  subtitle: {
    color: Colors.text,
    textAlign: 'center',
    ...Fonts.style.bodySmall,
    marginBottom: Metrics.baseMargin,
  },
  leftTitle: {
    ...Fonts.style.subheaderBold,
    color: Colors.text,
  },
  rightTitle: {
    ...Fonts.style.button,
    color: Colors.text,
  },
  shimmerAvatar: {
    borderRadius: Metrics.avatar.borderRadius,
  },
}
