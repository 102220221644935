import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Metrics, Fonts } from 'APP/Themes'

export default StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: Colors.elementsBg,
    borderRadius: 6,
    ...ApplicationStyles.shadow,
    position: 'absolute',
    zIndex: 10000,
    left: Metrics.baseMargin / 2,
    right: Metrics.baseMargin / 2,
    marginTop: Metrics.statusBarHeight + Metrics.baseMargin / 2,
    padding: Metrics.baseMargin / 3,
  },
  messageContainer: {
    flexDirection: 'row',
    flexShrink: 1,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  messageTextContainer: {
    flexDirection: 'column',
    flexShrink: 1,
    marginRight: Metrics.baseMargin,
  },
  avatarContainer: {
    margin: Metrics.baseMargin / 2,
  },
  name: {
    ...Fonts.style.body,
    color: Colors.text,
  },
  message: {
    ...Fonts.style.bodySmall,
    color: Colors.text,
    marginTop: 2,
    opacity: 0.75,
  },
  defaultAvatar: {
    width: 36,
    height: 36,
    resizeMode: 'contain',
  },
})
