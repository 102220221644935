import Config from 'APP/Config'
import { getBrandVersionedImages } from 'APP/Helpers/brandVersionedUtils'

import AccountIcon from 'APP/Images/Navigation/account-icon.svg'
import AccountIconFilled from 'APP/Images/Navigation/account-icon-filled.svg'
import CopyIcon from 'APP/Images/ic_copy.svg'
import CopiedIcon from 'APP/Images/ic_copied.svg'
import HomeIcon from 'APP/Images/Navigation/home-icon.svg'
import HomeIconFilled from 'APP/Images/Navigation/home-icon-filled.svg'
import MessageTextIcon from 'APP/Images/Navigation/message-text-icon.svg'
import MessageTextIconFilled from 'APP/Images/Navigation/message-text-icon-filled.svg'
import WellnessIcon from 'APP/Images/wellness-icon.svg'
import WellnessCenterIcon from 'APP/Images/ic_homescreen_cta_wellnesscenter.svg'
import WellnessIconFilled from 'APP/Images/wellness-icon-filled.svg'
import TodayIcon from 'APP/Images/Navigation/today-icon.svg'
import TodayIconFilled from 'APP/Images/Navigation/today-icon-filled.svg'
import MoveIcon from 'APP/Images/Navigation/move-icon.svg'
import MoveIconFilled from 'APP/Images/Navigation/move-icon-filled.svg'
import MindIcon from 'APP/Images/Navigation/mind-icon.svg'
import MindIconFilled from 'APP/Images/Navigation/mind-icon-filled.svg'
import ChatIcon from 'APP/Images/Navigation/chat-icon.svg'
import ChatIconFilled from 'APP/Images/Navigation/chat-icon-filled.svg'
import BrowseIcon from 'APP/Images/Navigation/browse-icon.svg'
import BrowseIconFilled from 'APP/Images/Navigation/browse-icon-filled.svg'
import ServicesIcon from 'APP/Images/Navigation/services-icon.svg'
import ServicesIconFilled from 'APP/Images/Navigation/services-icon-filled.svg'
import IcSendMessage from 'APP/Images/ic_send_message.svg'
import ArrowLeftIcon from 'APP/Images/arrow-left.svg'

import WellBeingIndex from 'APP/Images/Navigation/well-being-index.svg'
import Groups from 'APP/Images/Navigation/group.svg'

import GoalsIcon from 'APP/Images/Commitments/goals.svg'
import DataIcon from 'APP/Images/Commitments/data.svg'
import RespectIcon from 'APP/Images/Commitments/respect.svg'
import SafetyIcon from 'APP/Images/Commitments/safety.svg'
import { ActiveMinutes } from '@dialogue/services'

import QRCodeAppStore from 'APP/Images/qr_code_app_store.svg'

import DefaultServiceIcon from 'APP/Images/ServiceGroups/default.svg'
import DietNutritionIcon from 'APP/Images/ServiceGroups/dietNutrition.svg'
import ExternalHealthResourcesIcon from 'APP/Images/ServiceGroups/externalHealthResources.svg'
import FamilyRelationshipsIcon from 'APP/Images/ServiceGroups/familyRelationships.svg'
import FinancialIcon from 'APP/Images/ServiceGroups/financial.svg'
import HeartDiseaseIcon from 'APP/Images/ServiceGroups/heartDisease.svg'
import JointMusclePainIcon from 'APP/Images/ServiceGroups/jointMusclePain.svg'
import KidneyDiseaseIcon from 'APP/Images/ServiceGroups/kidneyDisease.svg'
import LegalIcon from 'APP/Images/ServiceGroups/legal.svg'
import MentalHealthIcon from 'APP/Images/ServiceGroups/mentalHealth.svg'
import PhysicalHealthIcon from 'APP/Images/ServiceGroups/physicalHealth.svg'
import PrescriptionRefillIcon from 'APP/Images/ServiceGroups/prescriptionRefill.svg'
import SelfCareToolkitsIcon from 'APP/Images/ServiceGroups/selfCareToolkits.svg'
import TechnicalSupportIcon from 'APP/Images/ServiceGroups/technicalSupport.svg'
import PrescriptionAddIcon from 'APP/Images/ServiceGroups/prescriptionAdd.svg'
import PrescriptionChatIcon from 'APP/Images/ServiceGroups/prescriptionChat.svg'
import PrescriptionManageIcon from 'APP/Images/ServiceGroups/prescriptionManage.svg'
import TrackIcon from 'APP/Images/ServiceGroups/track.svg'
import WorkCareerIcon from 'APP/Images/ServiceGroups/workCareer.svg'
import ScopeOfPracticeIcon from 'APP/Images/scope_of_practice.svg'
import VerifiedUserIcon from 'APP/Images/verified_user.svg'
import FertilityIcon from 'APP/Images/ServiceGroups/fertility.svg'
import MenopauseIcon from 'APP/Images/ServiceGroups/menopause.svg'

import InviteAdultIcon from 'APP/Images/invite_adult_email.svg'
import EmptyFamilyIcon from 'APP/Images/empty_family.svg'

// leave off @2x/@3x and .ios/.android
const imagesBase = {
  adultInviteIcon: InviteAdultIcon,
  emptyFamilyIcon: EmptyFamilyIcon,

  backIcon: require('APP/Images/ic_back.png'),
  bodyIcon: require('APP/Images/ic_body.png'),
  contentLoading: require('APP/Images/content_loading.png'),
  closeIcon: require('APP/Images/ic_close.png'),
  questionMark: require('APP/Images/ic_question.png'),
  questionMarkCircle: require('APP/Images/ic_question_circle.png'),
  creditCardBackIllustration: require('APP/Images/card-back.png'),
  creditCardFrontIllustration: require('APP/Images/card-front.png'),
  geolocation: require('APP/Images/il_geolocation.png'),
  hangUp: require('APP/Images/ic_hang_up.png'),
  homeScreenEmptyIllustration: require('APP/Images/il_home_empty.png'),
  logoErrorIcon: require('APP/Images/ic_logo.png'),
  logoBlue: require('APP/Images/ic_logo.png'),
  logoLockup: require('APP/Images/logo_lockup.png'),
  logoNurse: require('APP/Images/logo_icon_nurse.png'),
  logoBrand: require('APP/Images/logo_dialogue_brand.png'),
  logoDiaBrand: require('APP/Images/ic_logo_dialogue_heart.png'),
  logoProviderBrand: require('APP/Images/ServiceGroups/sunLifeSquareLogo.png'),
  pinchIcon: require('APP/Images/ic_pinch.png'),
  poorConnection: require('APP/Images/ic_poor_connection.png'),
  micUnmuted: require('APP/Images/ic_mic_unmuted.png'),
  micMuted: require('APP/Images/ic_mic_muted.png'),
  taskComplete: require('APP/Images/task-complete.png'),

  switchCamera: require('APP/Images/ic_camera_rear.png'),
  video: require('APP/Images/ic_video.png'),
  captureIcon: require('APP/Images/ic_photo_camera_36pt.png'),

  welcomeSlide0: require('APP/Images/welcome_slide_0.png'),
  welcomeSlide1: require('APP/Images/welcome_slide_1.png'),
  welcomeSlide2: require('APP/Images/welcome_slide_2.png'),
  welcomeSlide3: require('APP/Images/welcome_slide_3.png'),

  accountIcon: AccountIcon,
  accountIconFilled: AccountIconFilled,
  copyIcon: CopyIcon,
  copiedIcon: CopiedIcon,
  homeIcon: HomeIcon,
  homeIconFilled: HomeIconFilled,
  messageTextIcon: MessageTextIcon,
  messageTextIconFilled: MessageTextIconFilled,
  wellnessIcon: WellnessIcon,
  wellnessIconFilled: WellnessIconFilled,
  todayIcon: TodayIcon,
  todayIconFilled: TodayIconFilled,
  moveIcon: MoveIcon,
  moveIconFilled: MoveIconFilled,
  mindIcon: MindIcon,
  mindIconFilled: MindIconFilled,
  chatIcon: ChatIcon,
  chatIconFilled: ChatIconFilled,
  browseIcon: BrowseIcon,
  browseIconFilled: BrowseIconFilled,
  servicesIcon: ServicesIcon,
  servicesIconFilled: ServicesIconFilled,
  sendMessage: IcSendMessage,
  arrowLeftIcon: ArrowLeftIcon,
  wellBeingIndex: WellBeingIndex,
  groups: Groups,

  qrCodeAppStore: QRCodeAppStore,

  scopeOfPracticeIcon: ScopeOfPracticeIcon,
  verifiedUserIcon: VerifiedUserIcon,

  homeCta: {
    healthConcerns: require('APP/Images/ic_homescreen_cta_concerns.png'),
    mentalHealthBasic: require('APP/Images/ic_homescreen_cta_mental_health.png'),
    mentalHealth: require('APP/Images/ic_homescreen_cta_mental_health.png'),
    mentalHealthIcbt: require('APP/Images/ic_homescreen_cta_mental_health.png'),
    mentalHealthBasicIcbt: require('APP/Images/ic_homescreen_cta_mental_health.png'),
    icbt: require('APP/Images/ic_homescreen_cta_mental_health.png'),
    nutrition: require('APP/Images/ic_homescreen_cta_nutrition.png'),
    prescriptions: require('APP/Images/ic_homescreen_cta_prescriptions.png'),
    navigation: require('APP/Images/ic_homescreen_cta_health_navigation.png'),
    legal: require('APP/Images/ic_homescreen_cta_legal.png'),
    financial: require('APP/Images/ic_homescreen_cta_financial.png'),
    workCareer: require('APP/Images/ic_homescreen_cta_work_career.png'),
    familyRelationships: require('APP/Images/ic_homescreen_cta_family_relationships.png'),
    fullscreenIntake: require('APP/Images/ic_homescreen_cta_light_intake.png'),
    hingeHealth: require('APP/Images/WellnessCategories/hingeHealth.png'),
    somLogo: require('APP/Images/WellnessCategories/somLogo.png'),
    wellnessCenter: WellnessCenterIcon,
  },

  serviceCategories: {
    healthConcerns: require('APP/Images/WellnessCategories/physicalHealth.png'),
    mentalHealth: require('APP/Images/WellnessCategories/mentalHealth.png'),
    legal: require('APP/Images/WellnessCategories/legal.png'),
    financial: require('APP/Images/WellnessCategories/financial.png'),
    familyRelationships: require('APP/Images/WellnessCategories/familyRelationships.png'),
    workCareer: require('APP/Images/WellnessCategories/workCareer.png'),
    hingeHealth: require('APP/Images/WellnessCategories/hingeHealth.png'),
    somLogo: require('APP/Images/WellnessCategories/somLogo.png'),
    technicalSupport: require('APP/Images/WellnessCategories/technicalSupport.png'),
  },

  unsupportedVersionIllustration: require('APP/Images/il_download.png'),
  changeEmailIllustration: require('APP/Images/il_email.png'),
  emailVerifiedIllustration: require('APP/Images/il_email.png'),

  inChatMicroApp: {
    cliniaHealthNavigation: require('APP/Images/ic_health_navigation_marker.png'),
    selfScheduling: require('APP/Images/ic_appointment.png'),
  },
  profileIcon: require('APP/Images/ic_profile.png'),
  amyBarnes: require('APP/Images/ic_amy_barnes.png'),

  wellBeing: {
    emptyChart: require('APP/Images/WellBeing/empty_chart.png'),
  },
  notificationsExample: require('APP/Images/il_notifications_example.png'),

  commitments: {
    goals: GoalsIcon,
    data: DataIcon,
    respect: RespectIcon,
    safety: SafetyIcon,
  },

  serviceGroups: {
    default: DefaultServiceIcon,
    dietNutrition: DietNutritionIcon,
    externalHealthResources: ExternalHealthResourcesIcon,
    familyRelationships: FamilyRelationshipsIcon,
    financial: FinancialIcon,
    heartDisease: HeartDiseaseIcon,
    hingeHealth: require('APP/Images/ServiceGroups/hingeHealth.png'),
    jointMusclePain: JointMusclePainIcon,
    kidneyDisease: KidneyDiseaseIcon,
    legal: LegalIcon,
    mentalHealth: MentalHealthIcon,
    physicalHealth: PhysicalHealthIcon,
    prescriptionRefill: PrescriptionRefillIcon,
    prescriptionAdd: PrescriptionAddIcon,
    prescriptionChat: PrescriptionChatIcon,
    prescriptionManage: PrescriptionManageIcon,
    track: TrackIcon,
    selfCareToolkits: SelfCareToolkitsIcon,
    somLogo: require('APP/Images/ServiceGroups/somLogo.png'),
    sunLifeLogo: require('APP/Images/ServiceGroups/sunLifeLogo.png'),
    sunLifeSquareLogo: require('APP/Images/ServiceGroups/sunLifeSquareLogo.png'),
    technicalSupport: TechnicalSupportIcon,
    workCareer: WorkCareerIcon,
    fertility: FertilityIcon,
    menopause: MenopauseIcon,
    progynyLogo: require('APP/Images/ServiceGroups/progynyLogo.png'),
  },

  trackers: {
    [ActiveMinutes.Types.TrackerName.AppleHealth]: require('APP/Images/Trackers/Icons/apple.png'),
    [ActiveMinutes.Types.TrackerName.Fitbit]: require('APP/Images/Trackers/Icons/fitbit.png'),
    [ActiveMinutes.Types.TrackerName.GoogleFit]: require('APP/Images/Trackers/Icons/google.png'),
    [ActiveMinutes.Types.TrackerName.Garmin]: require('APP/Images/Trackers/Icons/garmin.png'),
    [ActiveMinutes.Types.TrackerName.Strava]: require('APP/Images/Trackers/Icons/strava.png'),
    [ActiveMinutes.Types.TrackerName
      .SamsungHealth]: require('APP/Images/Trackers/Icons/samsung.png'),
    [ActiveMinutes.Types.TrackerName.Withings]: require('APP/Images/Trackers/Icons/withings.png'),
  },

  takeATour: {
    en: {
      slide0: require('APP/Images/TakeATour/en_slide_0.png'),
      slide1: require('APP/Images/TakeATour/en_slide_1.png'),
      slide2: require('APP/Images/TakeATour/en_slide_2.png'),
      slide3: require('APP/Images/TakeATour/en_slide_3.png'),
      slide4: require('APP/Images/TakeATour/en_slide_4.png'),
    },
    fr: {
      slide0: require('APP/Images/TakeATour/fr_slide_0.png'),
      slide1: require('APP/Images/TakeATour/fr_slide_1.png'),
      slide2: require('APP/Images/TakeATour/fr_slide_2.png'),
      slide3: require('APP/Images/TakeATour/fr_slide_3.png'),
      slide4: require('APP/Images/TakeATour/fr_slide_4.png'),
    },
  },

  loginLogos: {
    sunLifeLogo: require('APP/Images/ServiceGroups/sunLifeSquareLogo.png'),
  },
}

const imagesDark = {
  geolocation: require('APP/Images/il_geolocation_dark.png'),
  logoBlue: require('APP/Images/ic_logo_dark.png'),
  logoErrorIcon: require('APP/Images/ic_logo_dark.png'),
  logoLockup: require('APP/Images/logo_lockup_dark.png'),
  logoNurse: require('APP/Images/logo_icon_nurse_dark.png'),
  logoBrand: require('APP/Images/logo_dialogue_brand_dark.png'),
  logoDiaBrand: require('APP/Images/ic_logo_dialogue_heart_dark.png'),

  wellnessIcon: WellnessIcon,
  wellnessIconFilled: WellnessIconFilled,
  todayIcon: TodayIcon,
  todayIconFilled: TodayIconFilled,
  moveIcon: MoveIcon,
  moveIconFilled: MoveIconFilled,
  mindIcon: MindIcon,
  mindIconFilled: MindIconFilled,
  chatIcon: ChatIcon,
  chatIconFilled: ChatIconFilled,
  browseIcon: BrowseIcon,
  browseIconFilled: BrowseIconFilled,
  servicesIcon: ServicesIcon,
  servicesIconFilled: ServicesIconFilled,
  sendMessage: IcSendMessage,
  contentLoading: require('APP/Images/content_loading_dark.png'),

  homeCta: {
    prescriptions: require('APP/Images/ic_homescreen_cta_prescriptions_dark.png'),
    mentalHealth: require('APP/Images/ic_homescreen_cta_mental_health_dark.png'),
    mentalHealthBasic: require('APP/Images/ic_homescreen_cta_mental_health_dark.png'),
    mentalHealthIcbt: require('APP/Images/ic_homescreen_cta_mental_health_dark.png'),
    mentalHealthBasicIcbt: require('APP/Images/ic_homescreen_cta_mental_health_dark.png'),
    icbt: require('APP/Images/ic_homescreen_cta_mental_health_dark.png'),
    nutrition: require('APP/Images/ic_homescreen_cta_nutrition_dark.png'),
    healthConcerns: require('APP/Images/ic_homescreen_cta_concerns_dark.png'),
    navigation: require('APP/Images/ic_homescreen_cta_health_navigation_dark.png'),
    legal: require('APP/Images/ic_homescreen_cta_legal_dark.png'),
    financial: require('APP/Images/ic_homescreen_cta_financial_dark.png'),
    workCareer: require('APP/Images/ic_homescreen_cta_work_career_dark.png'),
    familyRelationships: require('APP/Images/ic_homescreen_cta_family_relationships_dark.png'),
    wellnessCenter: WellnessCenterIcon,
  },
  welcomeSlide0: require('APP/Images/welcome_slide_0_dark.png'),
  welcomeSlide1: require('APP/Images/welcome_slide_1_dark.png'),
  welcomeSlide2: require('APP/Images/welcome_slide_2_dark.png'),
  welcomeSlide3: require('APP/Images/welcome_slide_3_dark.png'),

  serviceCategories: {
    healthConcerns: require('APP/Images/WellnessCategories/physicalHealthDark.png'),
    mentalHealth: require('APP/Images/WellnessCategories/mentalHealthDark.png'),
    legal: require('APP/Images/WellnessCategories/legalDark.png'),
    financial: require('APP/Images/WellnessCategories/financialDark.png'),
    familyRelationships: require('APP/Images/WellnessCategories/familyRelationshipsDark.png'),
    technicalSupport: require('APP/Images/WellnessCategories/technicalSupportDark.png'),
    workCareer: require('APP/Images/WellnessCategories/workCareerDark.png'),
  },

  serviceGroups: {
    sunLifeLogo: require('APP/Images/ServiceGroups/sunLifeLogoDark.png'),
    progynyLogo: require('APP/Images/ServiceGroups/progynyLogoDark.png'),
  },

  changeEmailIllustration: require('APP/Images/il_email_dark.png'),
  unsupportedVersionIllustration: require('APP/Images/il_download_dark.png'),
  homeScreenEmptyIllustration: require('APP/Images/il_home_empty_dark.png'),
  profileIcon: require('APP/Images/ic_profile_dark.png'),

  wellBeing: {
    emptyChart: require('APP/Images/WellBeing/empty_chart_dark.png'),
  },

  commitments: {
    goals: GoalsIcon,
    data: DataIcon,
    respect: RespectIcon,
    safety: SafetyIcon,
  },

  takeATour: {
    en: {
      slide0: require('APP/Images/TakeATour/en_slide_0_dark.png'),
      slide1: require('APP/Images/TakeATour/en_slide_1_dark.png'),
      slide2: require('APP/Images/TakeATour/en_slide_2_dark.png'),
      slide3: require('APP/Images/TakeATour/en_slide_3_dark.png'),
      slide4: require('APP/Images/TakeATour/en_slide_4_dark.png'),
    },
    fr: {
      slide0: require('APP/Images/TakeATour/fr_slide_0_dark.png'),
      slide1: require('APP/Images/TakeATour/fr_slide_1_dark.png'),
      slide2: require('APP/Images/TakeATour/fr_slide_2_dark.png'),
      slide3: require('APP/Images/TakeATour/fr_slide_3_dark.png'),
      slide4: require('APP/Images/TakeATour/fr_slide_4_dark.png'),
    },
  },
}

export const squareExternalLogos = ['sunLifeSquareLogo']
export const bigExternalLogos = ['hingeHealth', 'somLogo']
export const wideExternalLogos = ['somLogo']

export const Images = getBrandVersionedImages(imagesBase, imagesDark, Config.BRAND_VERSION)

export default Images
