import { StyleSheet } from 'react-native'
import { Metrics } from 'APP/Themes'

export default StyleSheet.create({
  ctaContainer: {
    marginBottom: Metrics.baseMargin,
    display: 'flex',
    flexDirection: 'column',
  },
  ctaPromotedContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
    marginBottom: Metrics.baseMargin,
    marginTop: Metrics.baseMargin / 2,
    marginHorizontal: Metrics.baseMargin / 2,
  },
  sectionList: {
    marginTop: Metrics.baseMargin,
    paddingHorizontal: Metrics.baseMargin * 1.25,
  },
  sectionHeader: {
    alignSelf: 'flex-start',
    marginBottom: Metrics.baseMargin,
    marginTop: Metrics.baseMargin,
  },
  promotedSectionList: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'stretch',
    marginHorizontal: -Metrics.baseMargin / 2,
  },
  lastPromoted: {
    marginBottom: Metrics.baseMargin,
  },
  mainHeader: {
    marginTop: Metrics.baseMargin * 2,
    marginHorizontal: Metrics.baseMargin * 1.25,
    textAlign: 'center',
    alignSelf: 'center',
  },
})
