import { ActiveMinutes } from '@dialogue/services'

export const DISTANCE_UNITS = {
  KM: 'km',
  MILE: 'mile',
}

export const REGION_UNIT = {
  US: {
    [ActiveMinutes.Types.ActivityMetric.DistanceMeters]: DISTANCE_UNITS.MILE,
  },
  default: {
    [ActiveMinutes.Types.ActivityMetric.DistanceMeters]: DISTANCE_UNITS.KM,
  },
}
