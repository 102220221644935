import I18n from 'APP/Services/i18n'
import Alert from 'APP/Converse/Alert'

export function handleRetryableRequestFailure(failureBody) {
  return new Promise((resolve) => {
    setTimeout(() => {
      Alert.alert(I18n.t('error.alert.title'), failureBody, [
        { text: I18n.t('error.alert.cancel'), onPress: () => resolve(false) },
        { text: I18n.t('error.alert.tryAgain'), onPress: () => resolve(true) },
      ])
    }, 100)
  })
}

export function handleNonRetryableRequestFailure(
  failureBody,
  failureTitle = I18n.t('error.alert.title')
) {
  return new Promise((resolve) => {
    setTimeout(() => {
      Alert.alert(failureTitle, failureBody, [{ text: I18n.t('error.alert.ok'), onPress: resolve }])
    }, 100)
  })
}

export default {
  handleNonRetryableRequestFailure,
  handleRetryableRequestFailure,
}
