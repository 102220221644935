import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Fonts, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  modal: {
    ...ApplicationStyles.modal,
    flex: 1,
  },
  topOptions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: Metrics.baseMargin * 1.5,
    paddingHorizontal: Metrics.baseMargin * 1.5,
  },
  textCta: {
    padding: 0,
    margin: 0,
  },
  textCtaCopy: {
    ...Fonts.style.subheaderBold,
    color: Colors.accent,
    textDecorationLine: 'underline',
  },
  footer: {
    paddingTop: Metrics.baseMargin / 2,
    paddingBottom: Metrics.bottomSpace,
    marginVertical: 0,
  },
  footerCta: {
    marginBottom: Metrics.baseMargin / 2,
    marginTop: Metrics.baseMargin / 2,
  },
  content: {
    paddingBottom: Metrics.baseMargin / 2,
  },
  filterGroup: {
    marginTop: Metrics.baseMargin * 2.5,
    ...ApplicationStyles.shadow,
  },
  filterTitle: {
    ...Fonts.style.subheader,
    marginHorizontal: Metrics.baseMargin * 1.5,
    marginBottom: Metrics.baseMargin * 0.37,
    color: Colors.text,
  },
  filter: {
    backgroundColor: Colors.elementsBg,
    paddingVertical: Metrics.baseMargin * 0.8,
    paddingHorizontal: Metrics.baseMargin * 1.5,
    borderBottomWidth: 1,
    borderBottomColor: Colors.elementsBorder,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filterCopy: {
    ...Fonts.style.subheaderBold,
    color: Colors.text,
    paddingVertical: Metrics.baseMargin * 0.125,
  },
  slider: {
    backgroundColor: Colors.elementsBg,
    paddingBottom: Metrics.baseMargin,
    paddingHorizontal: Metrics.baseMargin * 1.5,
    display: 'flex',
    alignItems: 'center',
  },
  sliderRange: {
    ...Fonts.style.bodyLarge,
    width: '100%',
    color: Colors.text,
  },
})
