import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollView, View } from 'react-native'
import styled from 'styled-components/native'
import moment, { Moment } from 'moment'

import { PrimaryButton } from 'APP/Components/Buttons'
import AppointmentBookingRedux, { DATE_FORMAT } from 'APP/Redux/AppointmentBookingRedux'
import PatientHistoryActions from 'APP/Redux/PatientHistoryRedux'
import I18n from 'APP/Services/i18n'
import { Colors } from 'APP/Themes'

import { AppointmentBookingProps } from 'APP/Components/AppointmentBooking'
import { useCompatibility } from 'APP/Components/AppointmentBooking/utils/useCompatibility'
import { BookedAppointement } from 'APP/Components/AppointmentBooking/utils/types'

import { TimeslotSelection } from 'APP/Components/AppointmentBooking/TimeBased/timeslotSelection'
import ExpiredSchedule from 'APP/Components/AppointmentBooking/common/expiredSchedule'
import { BOOKING_FLOW } from '../constants'
import { AvailabilitySlot, NewAppointment } from '@dialogue/timekeeper'

const ConfirmationWrapper = styled(View)`
  background-color: ${Colors.selectedBg};
  box-shadow: 0px -8px 8px ${Colors.selectedBg};
  elevation: 4;
`

const TimeAppointmentBooking = (props: AppointmentBookingProps) => {
  const dispatch = useDispatch()
  const {
    appointment_id,
    is_rescheduling,
    close,
    sendAnswer,
    appointment_type,
    date_from,
    date_until,
    member_id,
    guardian_id,
    dia_provider_id,
  } = useCompatibility(props, BOOKING_FLOW.TIME_BASED)

  const isBookedRef = useRef(false)

  const { availabilities, selectedDay, selectedAppointment, bookAppointmentRequest } = useSelector(
    // @ts-expect-error store not typed
    (state) => state.appointmentBooking
  )

  // @ts-expect-error store not typed
  const episodeId = useSelector((state) => state.history.currentChannelId)
  // @ts-expect-error store not typed
  const { cancelAppointmentFromCalendarSuccess } = useSelector((state) => state.history)
  // @ts-expect-error store not typed
  const { rescheduleAppointmentMX } = useSelector((state) => state.features)
  const {
    data: bookedAppointmentData,
    error: bookingError,
    isLoading: isBookingAppointment,
  } = bookAppointmentRequest

  const isExpired = moment(date_until).isBefore(moment())

  const handleGetAvailabilities = useCallback(
    (day: Moment) => {
      const payload = {
        provider_ids: dia_provider_id ? [dia_provider_id] : [],
        start_at: day.format(),
        type: appointment_type,
      }
      dispatch(AppointmentBookingRedux.getAvailableAppointments(day, payload, date_until))
    },
    [dispatch, date_until, dia_provider_id, appointment_type]
  )

  const handleSelectDay = useCallback(
    (day: Moment) => {
      dispatch(AppointmentBookingRedux.selectDay(day.format(DATE_FORMAT)))

      handleGetAvailabilities(day)
    },
    [dispatch, handleGetAvailabilities]
  )

  const handleSelectAppointment = useCallback(
    (appointment: AvailabilitySlot) => {
      dispatch(AppointmentBookingRedux.selectAppointment(appointment))
    },
    [dispatch]
  )

  const handleBookAppointment = useCallback(() => {
    const payload: NewAppointment = {
      start_at: selectedAppointment.start_at,
      end_at: selectedAppointment.end_at,
      provider_id: selectedAppointment.provider_id,
      episode_id: episodeId,
      member_id: parseInt(member_id),
      appointment_type,
      episode_owner_id: parseInt(guardian_id ? guardian_id : member_id),
    }

    dispatch(AppointmentBookingRedux.bookAppointment(payload))
  }, [dispatch, selectedAppointment, episodeId, member_id, guardian_id, appointment_type])

  const onSuccessBooking = useCallback(() => {
    const displayMessage = moment.tz(selectedAppointment.start_at, moment.tz.guess()).format('LLL')

    const payload: BookedAppointement = {
      is_booked: true,
      ...{
        ...selectedAppointment,
        until_at: selectedAppointment.end_at,
        // Since service_id is required in BM payload and we don't have it in timekeeper response, we set it as -1
        service_id: -1,
      },
    }

    // NICHE BUG FIX ALERT 🐛 -------------
    // On a successful booking, before sending the member along in the flow,
    // clear the redux store, to allow for multiple appointment bookings in the same session
    dispatch(AppointmentBookingRedux.resetBookAppointment())
    // END OF NICHE BUG FIX 🐛 ------------
    sendAnswer({ display: displayMessage, payload })
    close?.()
  }, [selectedAppointment, sendAnswer, close, dispatch])

  const onErrorBooking = useCallback(() => {
    setTimeout(() => {
      dispatch(AppointmentBookingRedux.resetBookAppointment())
      handleGetAvailabilities(moment(selectedDay))
    }, 2000)
  }, [selectedDay, handleGetAvailabilities, dispatch])

  const quitScheduling = useCallback(() => {
    if (rescheduleAppointmentMX && is_rescheduling) {
      dispatch(
        PatientHistoryActions.cancelAppointmentFromCalendarRequest({
          id: appointment_id!.replace('-', ''), // ids are negative
        })
      )
    } else {
      sendAnswer({
        display: I18n.t('AppointmentBooking.none_of_these_work'),
        payload: { is_booked: false },
      })
      close?.()
    }
  }, [is_rescheduling, appointment_id, dispatch])

  // resets selected appointment on each day selection and initial render
  useEffect(() => {
    dispatch(AppointmentBookingRedux.selectAppointment(null))
  }, [selectedDay])

  useEffect(() => {
    handleSelectDay(moment(date_from))
  }, [date_from, handleSelectDay])

  useEffect(() => {
    if (bookedAppointmentData && !isBookingAppointment && !isBookedRef.current) {
      isBookedRef.current = true
      onSuccessBooking()
    }
  }, [bookedAppointmentData, isBookingAppointment, onSuccessBooking])

  useEffect(() => {
    if (bookingError && !isBookingAppointment) {
      onErrorBooking()
    }
  }, [bookingError, isBookingAppointment, onErrorBooking])

  useEffect(() => {
    if (rescheduleAppointmentMX && cancelAppointmentFromCalendarSuccess) {
      sendAnswer({
        display: I18n.t('AppointmentBooking.none_of_these_work'),
        payload: { is_booked: false },
      })
      close?.()
      dispatch(PatientHistoryActions.resetCancelAppointmentFromCalendar())
    }
  }, [cancelAppointmentFromCalendarSuccess])

  return (
    <View testID="appointmentBooking" style={{ flex: 1 }}>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        {isExpired ? (
          <ExpiredSchedule quitScheduling={quitScheduling} />
        ) : (
          <TimeslotSelection
            availabilities={availabilities}
            dateFrom={moment(date_from)}
            dateUntil={moment(date_until)}
            selectedDay={moment(selectedDay, DATE_FORMAT)}
            selectDay={handleSelectDay}
            selectedAppointment={selectedAppointment}
            bookAppointmentError={bookingError}
            selectAppointment={handleSelectAppointment}
            getAvailabilities={handleGetAvailabilities}
            quitScheduling={quitScheduling}
            hasExistingAppointment={is_rescheduling}
            appointmentType={appointment_type}
          />
        )}
      </ScrollView>
      {selectedAppointment && (
        <ConfirmationWrapper>
          <PrimaryButton
            title={I18n.t('AppointmentBooking.confirm_appointment')}
            testID="appointmentBooking.confirmAppointment"
            onPress={handleBookAppointment}
            disabled={isBookingAppointment}
          />
        </ConfirmationWrapper>
      )}
    </View>
  )
}

export default TimeAppointmentBooking
