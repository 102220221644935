import React from 'react'
import styled from 'styled-components/native'

const Container = styled.View`
  flex: 1;
`

const TabContent = ({ value, children }) => {
  const filteredChildren = React.Children.toArray(children).filter(
    (child) => child.props.index === value
  )

  return <Container>{filteredChildren}</Container>
}

const ItemContainer = styled.View`
  flex: 1;
`

const Item = ({ children }) => {
  return <ItemContainer>{children}</ItemContainer>
}

TabContent.Item = Item

export default TabContent
