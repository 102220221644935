import React, { useState, useEffect } from 'react'
import { ActivityIndicator, Linking, Text, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

// Services
import Analytics from 'APP/Services/Analytics'
import I18n from 'APP/Services/i18n'
import UrlBuilder from 'APP/Services/UrlBuilder'
import * as Sentry from '@sentry/react-native'
import Alert from 'APP/Converse/Alert'
// Actions
import LoginActions from 'APP/Redux/LoginRedux'
import AccountLinkingActions from 'APP/Redux/AccountLinkingRedux'

// Components
import WelcomeCtaContainer from 'APP/Components/WelcomeCtaContainer'

// Styles
import Styles from './style'
import { Colors } from 'APP/Themes'
import { logDdError } from 'APP/Lib/Datadog'

const SubtitleWithEmail = ({ text, email }) => (
  <Text style={Styles.subtitle}>
    {I18n.t(text, { returnObjects: true })[0]}
    <Text style={Styles.bold}>{email}</Text>
    {I18n.t(text, { returnObjects: true })[1]}
  </Text>
)

const AccountLinkingScreen = () => {
  const dispatch = useDispatch()
  const {
    login: {
      customClaims: { email, sso: ssoStatus },
      fetching,
    },
    accountLinking: { running, error, errorType },
  } = useSelector((state) => state)

  const [screenType, setScreenType] = useState('generalError')

  useEffect(() => {
    if (error) {
      setScreenType('linkingError')
      return
    }
    if (!(running || fetching)) {
      if (ssoStatus === 'force-link') {
        setScreenType('matchingEmailLogin')
      } else if (ssoStatus === 'ask-link') {
        setScreenType('nonMatchingEmail')
      }
    }
  }, [ssoStatus, running, error, fetching])

  useEffect(() => {
    const analyticsConfig = {
      matchingEmailLogin: 'SSOAccountMatch',
      nonMatchingEmail: 'SSONoAccountMatch',
      nonMatchingEmailLogin: 'SSOAccountLinking',
      linkingError: 'SSOAccountLinkingError',
    }
    const screenName = analyticsConfig[screenType]
    if (screenName) {
      Analytics.trackScreen(screenName)
    }
  }, [screenType])

  const openSupportEmail = () => {
    const url = UrlBuilder.supportEmailUrl()
    Linking.openURL(url).catch((reason) => {
      const msg = `AccountLinkingScreen. Error opening support email link. message: ${reason.message}, url: ${url}`
      Sentry.captureException(msg)
      logDdError(msg, 'AccountLinkingScreen.openSupportEmail')
      Alert.alert(I18n.t('CannotOpenEmail.title'), I18n.t('CannotOpenEmail.body'), [
        { text: I18n.t('CannotOpenEmail.ok') },
      ])
    })
  }

  const requestAccountLinkLogin = () => {
    dispatch(LoginActions.accountLinkLoginRequest())
  }

  const requestAccountLinking = (accountCreation = false) => {
    dispatch(AccountLinkingActions.requestAccountLinking(accountCreation))
  }
  const requestLogoutWithAccountLinkingReset = () => {
    dispatch(AccountLinkingActions.resetAccountLinking())
    dispatch(LoginActions.logout('Failed account linking'))
  }

  const config = {
    matchingEmailLogin: {
      title: I18n.t('AccountLinkingScreen.matchingEmailLogin.title'),
      subtitle: (
        <SubtitleWithEmail text="AccountLinkingScreen.matchingEmailLogin.subtitle" email={email} />
      ),
      primaryButtonConfig: {
        title: I18n.t('AccountLinkingScreen.matchingEmailLogin.primaryButton'),
        analyticsName: 'AccountLinkingScreen.matchingEmailLogin.login',
        onPress: requestAccountLinkLogin,
      },
      hintText: I18n.t('AccountLinkingScreen.matchingEmailLogin.hintText'),
      hintCtaConfig: {
        text: I18n.t('AccountLinkingScreen.matchingEmailLogin.hintCta'),
        onPress: openSupportEmail,
      },
    },
    nonMatchingEmail: {
      title: I18n.t('AccountLinkingScreen.nonMatchingEmail.title'),
      subtitle: I18n.t('AccountLinkingScreen.nonMatchingEmail.subtitle'),
      primaryButtonConfig: {
        title: I18n.t('AccountLinkingScreen.nonMatchingEmail.primaryButton'),
        analyticsName: 'AccountLinkingScreen.nonMatchingEmail.yes',
        onPress: () => setScreenType('nonMatchingEmailLogin'),
      },
      secondaryButtonConfig: {
        title: I18n.t('AccountLinkingScreen.nonMatchingEmail.secondaryButton'),
        analyticsName: 'AccountLinkingScreen.nonMatchingEmail.no',
        onPress: () => requestAccountLinking(true),
      },
    },
    nonMatchingEmailLogin: {
      title: I18n.t('AccountLinkingScreen.nonMatchingEmailLogin.title'),
      subtitle: (
        <SubtitleWithEmail
          text="AccountLinkingScreen.nonMatchingEmailLogin.subtitle"
          email={email}
        />
      ),
      primaryButtonConfig: {
        title: I18n.t('AccountLinkingScreen.nonMatchingEmailLogin.primaryButton'),
        analyticsName: 'AccountLinkingScreen.nonMatchingEmailLogin.login',
        onPress: requestAccountLinkLogin,
      },
      onBackButtonPress: () => setScreenType('nonMatchingEmail'),
    },
    linkingError: {
      title: I18n.t('AccountLinkingScreen.linkingError.title'),
      subtitle: I18n.t('AccountLinkingScreen.linkingError.subtitle'),
      primaryButtonConfig: {
        title: I18n.t('AccountLinkingScreen.linkingError.primaryButton'),
        analyticsName: 'AccountLinkingScreen.linkingError.tryAgain',
        onPress:
          errorType === 'linking'
            ? () => requestAccountLinking()
            : () => requestAccountLinking(true),
      },
      tertiaryButtonConfig: {
        title: I18n.t('AccountLinkingScreen.linkingError.tertiaryButton'),
        analyticsName: 'AccountLinkingScreen.linkingError.takeMeBackHome',
        onPress: requestLogoutWithAccountLinkingReset,
      },
    },
    generalError: {
      title: I18n.t('AccountLinkingScreen.generalError.title'),
      subtitle: I18n.t('AccountLinkingScreen.generalError.subtitle'),
      primaryButtonConfig: {
        title: I18n.t('AccountLinkingScreen.generalError.primaryButton'),
        analyticsName: 'AccountLinkingScreen.generalError.takeMeBackHome',
        onPress: requestLogoutWithAccountLinkingReset,
      },
    },
  }

  // When the user logs into their dia account, the access token no longer has the sso custom claim defined
  // Since we haven't reached startupNav again yet, we need to handle ssoStatus=undefined in this container
  if (!error && (!ssoStatus || running || fetching)) {
    return (
      <View style={Styles.loadingContainer}>
        <Text style={Styles.loadingText}>{I18n.t('AccountLinkingScreen.loading.title')}</Text>
        <ActivityIndicator size="large" color={Colors.text} style={Styles.spinner} />
      </View>
    )
  }

  const screenData = config[screenType]
  return (
    <WelcomeCtaContainer
      title={screenData.title}
      subtitle={screenData.subtitle}
      primaryButton={screenData.primaryButtonConfig}
      secondaryButton={screenData.secondaryButtonConfig}
      tertiaryButton={screenData.tertiaryButtonConfig}
      hintText={screenData.hintText}
      hintCta={screenData.hintCtaConfig}
      onBackButtonPress={screenData.onBackButtonPress}
    />
  )
}

export default AccountLinkingScreen
