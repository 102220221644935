import React from 'react'

// Makes sure the output is a valid react element
// this might be required by some components
// like FlatList prop for header/footer component
// as it expects a valid react element which is not always guaranteed
// by using react children
// e.g. more then one conditional render of components results in an array of react children
// render() { return (<CustomComponent>{condition1 && <Component1 />}{condition2 && <Component2 />}</CustomComponent>) }
// in that case CustomComponent.children === [<Component1 />, <Component2 />] or [false, <Component2 />] etc.
// an array is not a valid react element
export function transformChildrenToAValidElement(childrenArray) {
  if (!childrenArray) {
    return null
  }

  // Filter out non-element values (false, null, undefined)
  const validElements = childrenArray.filter((item) => React.isValidElement(item))

  // If there are no valid elements, return null
  if (validElements.length === 0) {
    return null
  }

  // If there's only one valid element, return it directly
  if (validElements.length === 1) {
    return validElements[0]
  }

  // If there are multiple elements, wrap them in a React Fragment
  return <React.Fragment>{validElements}</React.Fragment>
}
