import React, { useEffect, useMemo } from 'react'
import { ActivityIndicator, Modal, Text, TouchableOpacity, View } from 'react-native'
import Styles from './style'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import Tooltip from 'APP/Components/Tooltip'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import { CalendarList } from 'APP/Components/CalendarList'
import { Metrics, Colors, Fonts } from 'APP/Themes'
import I18n from 'APP/Services/i18n'
import { useDispatch, useSelector } from 'react-redux'
import HabitsActions from 'APP/Redux/HabitsRedux'

const NetworkStatsSection = ({ loading, retry, refresh }) => {
  if (!loading && !retry) return null
  return (
    <View style={[Styles.networkSection]}>
      {loading && (
        <ActivityIndicator
          size="large"
          color={Colors.text}
          style={Styles.loading}
          testID="loading"
        />
      )}
      {retry && (
        <DialogueTouchableOpacity
          style={Styles.retry}
          analyticsName="Refresh"
          onPress={refresh}
          testID="retry"
        >
          <MaterialIcon name="refresh" size={Metrics.icons.large} color={Colors.text} />
        </DialogueTouchableOpacity>
      )}
    </View>
  )
}

const HabitCalendarModal = ({ visible, id, fromDate, onClose }) => {
  const statsState = useSelector((state) => (id ? state.habits.habitStats[id] : null))
  const progressState = useSelector((state) => (id ? state.habits.habitProgress[id] : null))
  const dispatch = useDispatch()
  const fetchStats = () => {
    if (id) {
      dispatch(HabitsActions.getSingleHabitStats(id))
    }
  }
  const fetchProgress = () => {
    if (id && fromDate) {
      dispatch(HabitsActions.getSingleHabitProgress(id, fromDate))
    }
  }
  useEffect(() => {
    fetchStats()
    fetchProgress()
  }, [visible])

  const activeDates = useMemo(
    () =>
      (progressState?.data || []).reduce(
        (acc, { day, progress }) => ({ ...acc, [day]: progress?.percentage === 100 }),
        {}
      ),
    [progressState?.data]
  )

  const {
    days: { done } = {},
    longest_daily_streak: longestStreak,
    weeks: { done: doneWeeks } = {},
  } = statsState?.data || {}

  const loading = progressState?.loading || statsState?.loading
  const retry = !loading && (progressState?.retry || statsState?.retry)
  const refresh = () => {
    if (progressState?.retry) fetchProgress()
    if (statsState?.retry) fetchStats()
  }

  return (
    <Modal
      statusBarTranslucent
      animationType="slide"
      transparent
      visible={visible}
      onRequestClose={onClose}
      testID="habitProgressModal"
    >
      <View style={Styles.modalContainer}>
        <View style={Styles.modal}>
          <View style={Styles.modalHeader}>
            <View style={Styles.row}>
              <TouchableOpacity
                onPress={onClose}
                testID="closeHabitProgressModal"
                style={Styles.modalHeaderClose}
              >
                <MaterialIcon name="close" size={Metrics.icons.small} color={Colors.text} />
              </TouchableOpacity>
              <Text style={Styles.modalHeaderTitle}>{I18n.t('Habits.progress.title')}</Text>
            </View>
            <View style={[Styles.row, !statsState?.data && Styles.hidden]}>
              <View style={Styles.progressItem}>
                <Text style={Styles.progressItemHeader}>{done || 0}</Text>
                <Text style={Styles.progressItemCaption}>
                  {I18n.t('Habits.progress.doneCaption', { count: done })}
                </Text>
              </View>
              <View style={Styles.progressItem}>
                <Text style={Styles.progressItemHeader}>{longestStreak || 0}</Text>
                <Text style={Styles.progressItemCaption}>
                  {I18n.t('Habits.progress.longestStreak')}
                </Text>
              </View>
              <Tooltip
                content={
                  <Text style={Styles.tooltipText}>{I18n.t('Habits.progress.doneWeeksFAQ')}</Text>
                }
                containerStyle={Styles.progressItem}
              >
                <Text style={Styles.progressItemHeader}>{doneWeeks || 0}</Text>
                <View style={Styles.progressItemCaptionContainer}>
                  <Text style={Styles.progressItemCaption}>
                    🔥 {I18n.t('Habits.progress.doneWeeks', { count: doneWeeks })}
                  </Text>
                  <MaterialIcon
                    name="info-outline"
                    size={Fonts.size.large}
                    color={Colors.text}
                    style={Styles.captionIcon}
                  />
                </View>
              </Tooltip>
            </View>
          </View>
          <View style={Styles.modalContent} testID="calendarListContainer">
            <CalendarList
              startDay={fromDate}
              activeDates={activeDates}
              style={retry && Styles.disabled}
            />
            <NetworkStatsSection loading={loading} retry={retry} refresh={refresh} />
          </View>
        </View>
      </View>
    </Modal>
  )
}

export default HabitCalendarModal
