import { StyleSheet } from 'react-native'
import { Colors, Fonts, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  header: {
    flex: 0.8,
    ...Fonts.style.h2,
    color: Colors.text,
    textAlign: 'center',
    paddingTop: Metrics.baseMargin * 1.5,
  },
  menuContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    alignItems: 'flex-end',
    paddingRight: Metrics.baseMargin,
    paddingTop: Metrics.baseMargin,
  },
  loading: {
    marginVertical: Metrics.baseMargin * 8,
  },
  retry: {
    display: 'flex',
    alignItems: 'center',
    marginTop: Metrics.baseMargin * 4,
  },
  retryTitle: {
    ...Fonts.style.h3,
    color: Colors.text,
    marginTop: Metrics.baseMargin * 2,
    marginBottom: Metrics.baseMargin,
  },
  retryCopy: {
    ...Fonts.style.bodyLarge,
    color: Colors.text,
  },
  retryCta: {
    marginTop: Metrics.baseMargin * 2,
    minWidth: 140,
  },
  subheading: {
    ...Fonts.style.body,
    color: Colors.textSecondary,
    textAlign: 'center',
    marginTop: Metrics.baseMargin * 0.5,
    marginBottom: Metrics.baseMargin * 1.5,
    marginHorizontal: Metrics.baseMargin,
  },
  image: {
    position: 'absolute',
    top: 0,
    height: 240,
    width: 120,
    resizeMode: 'contain',
  },
  imageRight: {
    right: 0,
  },
  progressWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  progressFraction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progress: {
    ...Fonts.style.h1,
    color: Colors.text,
  },
  inProgressHighlight: {
    color: Colors.accent,
  },
  achievedHighlight: {
    color: Colors.accessiblePeppermintText,
  },
  progressCopy: {
    ...Fonts.style.body,
    color: Colors.text,
    marginTop: Metrics.baseMargin * 0.5,
  },
  historyCta: {
    ...Fonts.style.button,
    color: Colors.text,
    textAlign: 'center',
    marginTop: Metrics.baseMargin * 1.25,
  },
})
