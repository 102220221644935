import React, { useEffect, useReducer } from 'react'
import { View } from 'react-native'

import { getArticles } from 'APP/Services/CMS'
import BottomSheetModal from 'APP/Components/BottomSheetModal'
import CMSContentList from 'APP/Components/CMSContentList'
import Styles from './style'
import NetworkWrapper from 'APP/Components/NetworkWrapper'

const initialState = {
  loading: false,
  error: null,
  articles: [],
}
const reducer = (state, action) => {
  switch (action.type) {
    case 'fetchArticles':
      return { ...state, error: null, loading: true }
    case 'fetchArticlesSuccess':
      return { ...state, error: null, loading: false, articles: action.articles }
    case 'fetchArticlesFailure':
      return { ...state, error: action.error, loading: false }
  }
}

const ArticlesDrawer = ({ category, subcategory, ...rest }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const fetchArticles = async () => {
    try {
      dispatch({ type: 'fetchArticles' })
      const response = await getArticles(null, true, {
        category,
        subcategory,
      })
      dispatch({ type: 'fetchArticlesSuccess', articles: response.items })
    } catch (error) {
      dispatch({ type: 'fetchArticlesFailure', error })
    }
  }
  const onItemPressBeforeNav = () => {
    rest?.onClose?.()
  }
  useEffect(() => {
    if (category || subcategory) {
      fetchArticles()
    }
  }, [category, subcategory])

  return (
    <BottomSheetModal {...rest} testID="articleRecommendationDrawer">
      <View style={Styles.container}>
        <NetworkWrapper {...state} refresh={fetchArticles}>
          <CMSContentList data={state.articles} onPressBeforeNav={onItemPressBeforeNav} />
        </NetworkWrapper>
      </View>
    </BottomSheetModal>
  )
}

export default ArticlesDrawer
