import styled from 'styled-components/native'
import { LinearGradient } from 'expo-linear-gradient'
import { hexToRgba } from 'APP/Helpers/styling'

export const StyledModalWrapper = styled.View`
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: rgba(54, 54, 53, 0.4);
`

export const StyledModal = styled.View`
  background-color: ${({ theme }) => hexToRgba(theme.colors.background, 0.9)};
  border-radius: ${({ theme }) => theme.metrics.borderRadius.medium}px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  padding: ${({ theme }) => theme.metrics.baseMargin * 2}px;
  ${({ theme }) => theme.shadows.normal}
  backdrop-filter: blur(2px);
`

export const StyledOomphModal = styled(StyledModal)`
  padding: 0;
  background-color: transparent;
`

export const StyledLinearGradient = styled(LinearGradient).attrs(({ theme }) => ({
  colors: [hexToRgba(theme.colors.gradientStart, 0.9), hexToRgba(theme.colors.gradientEnd, 0.9)],
}))`
  border-radius: ${({ theme }) => theme.metrics.borderRadius.medium}px;
  padding: ${({ theme }) => theme.metrics.baseMargin * 2}px;
`
