import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ActiveMinutes } from '@dialogue/services'
import ActiveMinutesActions from 'APP/Redux/ActiveMinutesRedux'
import {
  ChangeTargetModal as ChangeTargetModalComponent,
  PromptNewTargetModal as PromptNewTargetModalComponent,
} from 'APP/Components/ActiveMinutes'

const TargetModals = ({
  isChangeTargetModalOpen,
  currentTarget,
  newTarget,
  onChangeTargetModalClose,
}) => {
  const changeTargetRef = useRef()
  const dispatch = useDispatch()
  const { retry } = useSelector((state) => state.activeMinutes.updateWeeklyTarget)
  const [isPromptNewTargetModalOpen, setIsPromptNewTargetModalOpen] = useState(false)

  const updateWeeklyTarget = (target, changeType) => {
    if (changeType !== ActiveMinutes.Types.TargetChangeType.MANAUL) {
      setIsPromptNewTargetModalOpen(false)
    }
    dispatch(ActiveMinutesActions.updateWeeklyTarget(target, changeType))
  }
  const onAcceptNewTarget = () =>
    updateWeeklyTarget(newTarget, ActiveMinutes.Types.TargetChangeType.ACCEPT_PROMPT)
  const onDeclineNewTarget = () =>
    updateWeeklyTarget(currentTarget, ActiveMinutes.Types.TargetChangeType.DECLINE_PROMPT)
  const onPostponeNewTarget = () =>
    updateWeeklyTarget(currentTarget, ActiveMinutes.Types.TargetChangeType.POSTPONE_PROMPT)

  useEffect(() => {
    if (retry && isChangeTargetModalOpen && changeTargetRef.current) {
      changeTargetRef.current.reset()
      // clean up
      dispatch(ActiveMinutesActions.resetUpdateWeeklyTarget())
    }
  }, [retry])

  useEffect(() => {
    if (!currentTarget || !newTarget || currentTarget === newTarget) {
      setIsPromptNewTargetModalOpen(false)
    } else {
      setIsPromptNewTargetModalOpen(true)
    }
  }, [currentTarget, newTarget])

  return (
    <>
      <ChangeTargetModalComponent
        isVisible={isChangeTargetModalOpen}
        onClose={onChangeTargetModalClose}
        ref={changeTargetRef}
        currentTarget={currentTarget}
        onSubmit={updateWeeklyTarget}
      />
      <PromptNewTargetModalComponent
        isVisible={isPromptNewTargetModalOpen}
        currentTarget={currentTarget}
        newTarget={newTarget}
        onClose={onPostponeNewTarget}
        onAccept={onAcceptNewTarget}
        onDecline={onDeclineNewTarget}
      />
    </>
  )
}

export default TargetModals
