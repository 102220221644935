import { Platform } from 'react-native'
import PushNotification from 'react-native-push-notification'
import Config from 'APP/Config'
import I18n from 'APP/Services/i18n'

const videoChannelId = Config.ANDROID_APP_IDENTIFIER + '.video_channel'
const textChannelId = Config.ANDROID_APP_IDENTIFIER + '.text_channel'
const habitChannelId = Config.ANDROID_APP_IDENTIFIER + '.habit_channel'

export const createChannels = () => {
  PushNotification.createChannel({
    channelId: videoChannelId,
    channelName: I18n.t('NotificationChannels.video'),
    importance: 5,
  })

  PushNotification.createChannel({
    channelId: textChannelId,
    channelName: I18n.t('NotificationChannels.text'),
    importance: 4,
  })

  PushNotification.createChannel({
    channelId: habitChannelId,
    channelName: I18n.t('NotificationChannels.habit'),
    importance: 4,
  })
}

export const videoChannelExists = () => {
  if (Platform.OS === 'ios') return false

  return new Promise((resolve) => {
    PushNotification.channelExists(videoChannelId, (exists) => {
      resolve(exists)
    })
  })
}

export function textChannelExists() {
  if (Platform.OS === 'ios') return false

  return new Promise((resolve) => {
    PushNotification.channelExists(textChannelId, (exists) => {
      resolve(exists)
    })
  })
}

export function habitChannelExists() {
  if (Platform.OS === 'ios') return false

  return new Promise((resolve) => {
    PushNotification.channelExists(habitChannelId, (exists) => {
      resolve(exists)
    })
  })
}
