import { StyleSheet } from 'react-native'
import { Colors, ApplicationStyles, Metrics, Fonts } from '../../Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  container: {
    flex: 1,
    backgroundColor: Colors.appBg,
  },
  content: {
    paddingHorizontal: Metrics.baseMargin * 1.8,
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    minHeight: '100%',
  },
  refresh: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%',
  },
  image: {
    height: 238,
    width: '100%',
  },
  body: {
    paddingTop: Metrics.baseMargin * 1.5,
    paddingBottom: Metrics.baseMargin * 2,
  },
  addHabitCta: {
    marginTop: Metrics.baseMargin * 2,
    marginBottom: Metrics.baseMargin * 0.5,
    marginHorizontal: 0,
  },
  title: {
    ...Fonts.style.h1,
    color: Colors.text,
    paddingTop: Metrics.baseMargin * 1.25,
  },
  subheaderContainer: {
    flexDirection: 'row',
    paddingTop: Metrics.baseMargin * 0.5,
  },
  subheader: {
    ...Fonts.style.bodySmall,
    color: Colors.text,
    paddingLeft: 5,
  },
  expert: {
    ...ApplicationStyles.shadow,
    color: Colors.text,
    backgroundColor: Colors.elementsBg,
    display: 'flex',
    flex: 1,
    paddingTop: Metrics.baseMargin,
    paddingHorizontal: Metrics.baseMargin,
    marginTop: Metrics.baseMargin * 1.25,
    borderRadius: 10,
  },
  expertCaption: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  expertCaptionText: {
    ...Fonts.style.captionBold,
    color: Colors.text,
    paddingLeft: Metrics.baseMargin * 0.3,
  },
  expertContent: {
    flex: 1,
    flexDirection: 'row',
    paddingVertical: Metrics.baseMargin,
  },
  expertTitle: {
    color: Colors.text,
    ...Fonts.size.h3,
    fontSize: Fonts.size.large,
    fontFamily: 'PoynterOSDisp-Semibold',
  },
  expertSubtitle: {
    ...Fonts.style.captionBold,
    color: Colors.text,
    marginTop: Metrics.baseMargin * 0.25,
  },
  expertAvatar: {
    ...Metrics.avatar,
  },
  expertDetails: {
    marginLeft: Metrics.baseMargin * 0.5,
  },
})
