import React, { useMemo, useRef, useLayoutEffect } from 'react'
import { View, FlatList } from 'react-native'
import moment from 'moment'
import DayItem from './DayItem'
import Styles, { WEEK_ITEM_SIZE } from './style'
import I18n from 'APP/Services/i18n'

const CalendarWeekly = ({
  startDate,
  endDate,
  currentDay,
  selectedDay,
  onSelectedDayChange,
  percentages,
  unitOfTime = 'isoWeek',
  testID = 'calendarWeekly',
}) => {
  const flatListRef = useRef()
  const currentWeek = moment(currentDay).startOf('isoWeek')

  const startDay = useMemo(() => {
    return moment(startDate).startOf(unitOfTime).format(I18n.t('DateFormat'))
  }, [startDate, unitOfTime])

  const endDay = useMemo(() => {
    return moment(endDate).endOf(unitOfTime).format(I18n.t('DateFormat'))
  }, [endDate, unitOfTime])

  const weeks = useMemo(() => {
    const total = moment(endDay).diff(startDay, 'days') + 1
    return [...Array(total)]
      .map((_, index) => {
        const day = moment(startDay).add(index, 'days').format(I18n.t('DateFormat'))
        const isCurrent = day === currentDay
        const isPastWeek = moment(day).isBefore(currentWeek)
        const isAfter = moment(day).isAfter(currentDay, 'day')
        const isBefore = moment(day).isBefore(currentDay, 'day')
        const percentage = percentages[day]
        const text = isPastWeek
          ? moment(day).format('D')
          : moment.weekdays()[moment(day).isoWeekday() % 7].charAt(0).toLocaleUpperCase()

        return {
          day,
          text,
          percentage,
          selectedDay,
          isCurrent,
          isAfter,
          isBefore,
        }
      })
      .reduce((weeks, day, index) => {
        if (index % 7 === 0) {
          weeks.push([day])
          return weeks
        }
        weeks[weeks.length - 1] = [...weeks[weeks.length - 1], day]
        return weeks
      }, [])
  }, [currentDay, endDay, startDay, selectedDay, percentages])

  useLayoutEffect(() => {
    if (flatListRef.current && weeks && weeks.length > 0) {
      const defaultWeekIndex = 1
      const currentWeekIndex = weeks.findIndex((week) =>
        week.some((dayOfWeek) => dayOfWeek.day == selectedDay)
      )
      const selectedWeekIndex = currentWeekIndex !== -1 ? currentWeekIndex : defaultWeekIndex
      flatListRef.current.scrollToIndex({ index: selectedWeekIndex })
    }
  }, [weeks])

  return (
    <View testID={testID}>
      <FlatList
        ref={flatListRef}
        data={weeks}
        renderItem={({ item }) => (
          <View style={Styles.weekItemContainer}>
            {item.map((dayItemProps) => (
              <DayItem
                {...dayItemProps}
                key={dayItemProps.day}
                onPress={() => onSelectedDayChange(dayItemProps.day)}
              />
            ))}
          </View>
        )}
        contentContainerStyle={Styles.container}
        keyExtractor={(_, index) => `${index}`}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        pagingEnabled={true}
        getItemLayout={(_, index) => ({
          length: WEEK_ITEM_SIZE,
          offset: WEEK_ITEM_SIZE * index,
          index,
        })}
      />
    </View>
  )
}

export default CalendarWeekly
