import React, { memo, useMemo, useState } from 'react'
import { View } from 'react-native'

import {
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory-native'
import I18n from 'APP/Services/i18n'
import TooltipFlyout from 'APP/Components/VictoryChart/TooltipFlyout'
import LineClip from 'APP/Components/VictoryChart/LineClip'

import { minDomainY, maxDomainY, generateScoreChart, getDomainX, getPopulationData } from './config'
import Styles from './style'

const CustomTooltip = (props) => (
  <VictoryTooltip {...props} dy={10} flyoutComponent={<TooltipFlyout />} />
)

const PersistantTooltip = (props) => <CustomTooltip active={props.datum.active} {...props} />

const HistoricalLineChart = ({ score_history, population_average, onSetScrollEnabled }) => {
  const { created_at: latestDate } = score_history[0]

  const scoreData = useMemo(() => generateScoreChart(score_history), [score_history])

  const domainX = useMemo(() => getDomainX(latestDate), [latestDate])

  const [minDomainX] = domainX

  const [activePoint, setActivePoint] = useState(scoreData[0])

  const scorePoints = scoreData
    .filter((data) => data.textLabel && data.x.isAfter(minDomainX))
    .map((data) => {
      const active = activePoint?.x?.isSame(data.x, 'd')
      const blured = activePoint?.x?.isBefore(data.x)

      return { ...data, active, blured }
    })

  return (
    <View testID="historicalLineChart">
      <View style={Styles.chartContainer}>
        <VictoryChart
          domain={{ x: domainX, y: [minDomainY, maxDomainY] }}
          height={225}
          padding={0}
          scale={{ x: 'time' }}
          containerComponent={
            <VictoryVoronoiContainer
              labels={() => ' '}
              labelComponent={<CustomTooltip />}
              voronoiBlacklist={['populationLine', 'scoreLineBlurred', 'scoreLine']}
              voronoiDimension="x"
              onActivated={(points) => setActivePoint(points[0])}
              onTouchStart={() => onSetScrollEnabled(false)}
              onTouchEnd={() => {
                onSetScrollEnabled(true)
                setActivePoint(scoreData[0])
              }}
            />
          }
        >
          {/* HIDE CHART AXIS */}
          <VictoryAxis
            style={{
              axis: { stroke: 'transparent' },
              ticks: { stroke: 'transparent' },
              tickLabels: { fill: 'transparent' },
            }}
          />

          {/* POPULATION AVERAGE LINE */}
          <VictoryLine
            name="populationLine"
            data={getPopulationData(domainX, population_average)}
            style={{
              data: Styles.populationAverageLine,
            }}
            labels={() =>
              I18n.t('WellBeingIndexScreen.wellBeingReport.populationAverage', {
                population: population_average,
              })
            }
            labelComponent={
              <VictoryLabel
                dy={15}
                dx={20}
                textAnchor="start"
                style={Styles.populationAverageCopy}
              />
            }
          />

          {/* GRADIENT FOR LINE AND BLURRED LINE */}
          <LineClip
            latestDate={scoreData[0].x.toDate()}
            startDate={minDomainX.toDate()}
            activeDate={activePoint?.x.toDate()}
          />

          {/* SCORES PLOTTED IN LINE (BLURRED) */}
          <VictoryLine
            name="scoreLineBlurred"
            data={scoreData}
            style={{
              data: Styles.blurredLine,
            }}
            interpolation="monotoneX"
          />

          {/* SCORES PLOTTED IN LINE */}
          <VictoryLine
            name="scoreLine"
            data={scoreData}
            style={{
              data: Styles.line,
            }}
            interpolation="monotoneX"
          />

          {/* SCORES PLOTTED IN DOTS */}
          <VictoryScatter
            name="scoreScatter"
            data={scorePoints}
            style={{
              data: Styles.scatterDots,
            }}
            labels={() => ' '}
            labelComponent={<PersistantTooltip />}
            size={5}
          />
        </VictoryChart>
      </View>
    </View>
  )
}

export default memo(HistoricalLineChart)
