import Moment from 'moment'
import React, { useEffect } from 'react'
import { useNavigation, useRoute } from '@react-navigation/native'
import { Image, Text, View, Pressable } from 'react-native'
import { useSelector } from 'react-redux'

// Services
import I18n from 'APP/Services/i18n'
import Analytics from 'APP/Services/Analytics'
import { getPractitionerName, getEmptyPractitioner, getImage } from 'APP/Lib/PractitionerHelpers'
import sprigClient from 'APP/Services/Sprig'

// Components
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import DialogueBranding from 'APP/Components/DialogueBranding'
import DialogueAutolink from 'APP/Components/DialogueAutolink'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'

// Styles
import Styles from './style'
import { Images } from 'APP/Themes'
import { getImageAspectRatio } from 'APP/Lib/StylingHelpers'
import { ResponsiveScrollView } from 'APP/Converse/Layout'

const Practitioner = ({ practitioner, endTime }) => {
  const navigation = useNavigation()
  const profile = useSelector((state) => state.patient.profile)

  const handlePractitionerProfilePress = (id) => {
    if (!id) return
    navigation.navigate('practitionerProfile', { userAppId: id, profileId: profile.id })
  }

  const emptyPractitioner = getEmptyPractitioner(I18n.baseLocale)
  const id = practitioner?.id
  const mainSpecialization =
    practitioner?.mainSpecialization || emptyPractitioner.mainSpecialization
  const practitionerName = getPractitionerName(practitioner) || emptyPractitioner.name
  const styles = practitioner ? Styles.avatar : Styles.diaLogo

  return (
    <DialogueTouchableOpacity
      style={Styles.practitionerContainer}
      analyticsName="CarePlanScreen.practitionerProfile"
      testID={'practitioner-details'}
      onPress={() => handlePractitionerProfilePress(id)}
    >
      {getImage(practitioner, styles)}
      <View style={Styles.practitionerTitleContainer}>
        <Text style={Styles.practitionerName}>{practitionerName}</Text>
        <Text style={Styles.practitionerSpecialization}>{mainSpecialization}</Text>
      </View>
      <Text style={Styles.carePlanTimestamp}>{Moment.utc(endTime).local().fromNow()}</Text>
    </DialogueTouchableOpacity>
  )
}

const CarePlanCard = ({ title, body }) => {
  const iconSource = Images.logoDiaBrand
  return (
    <View style={Styles.cardContainer}>
      <Image
        style={[Styles.cardLogo, { aspectRatio: getImageAspectRatio(iconSource) }]}
        source={iconSource}
      />
      <View style={Styles.cardContent}>
        <Text style={Styles.cardTitle}>{title}</Text>
        <DialogueAutolink style={Styles.cardBody} text={body} />
      </View>
    </View>
  )
}

export default function CarePlanScreen() {
  const navigation = useNavigation()
  const route = useRoute()

  const { carePlan, channelId } = route.params || {}

  const handleOpenConsultPress = () => {
    Analytics.trackEvent('button_click', {
      button_value: 'CarePlanScreen.openConsult',
    })
    navigation.navigate('conversation', { channelId })
  }

  useEffect(() => {
    return function unmount() {
      sprigClient.track(`CARE_PLAN_CLOSED_${I18n.baseLocale.toUpperCase()}`)
    }
  })

  return (
    <NoticeBarContainer>
      <ResponsiveScrollView alwaysBounceVertical={false}>
        {carePlan && (
          <Practitioner practitioner={carePlan.practitioner} endTime={carePlan.endTime} />
        )}
        {carePlan && carePlan.reason && (
          <CarePlanCard title={I18n.t('CarePlanScreen.carePlan.concern')} body={carePlan.reason} />
        )}
        {carePlan && carePlan.note && (
          <CarePlanCard
            title={I18n.t('CarePlanScreen.carePlan.recommendations')}
            body={carePlan.note}
          />
        )}
        <Pressable onPress={handleOpenConsultPress}>
          <Text style={Styles.footer} testID="open-consult-text">
            {I18n.t('CarePlanScreen.carePlan.footer')}
            {'\n'}
            <Text style={Styles.footerCta}>{I18n.t('CarePlanScreen.carePlan.footerCta')}</Text>
          </Text>
        </Pressable>
      </ResponsiveScrollView>
      <DialogueBranding />
    </NoticeBarContainer>
  )
}
