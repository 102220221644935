import React from 'react'
// eslint-disable-next-line no-restricted-imports
import { Dimensions, Image, Text, TouchableHighlight, View } from 'react-native'

import MattermostAvatar from 'APP/Components/MattermostAvatar'
import Shimmer from 'APP/Components/Shimmer'

import Styles from './style'
import { Images, Colors, Metrics } from 'APP/Themes'

import Config from 'APP/Config'
import { isWeb } from 'APP/Helpers/checkPlatform'
import { getServiceCategoryIcon } from 'APP/Lib/CtaInfoHelpers'

const LoadingRow = () => {
  const width = Dimensions.get('window').width
  return (
    <TouchableHighlight testID="EPISODE_LOADING_ROW">
      <View style={Styles.rowContainer}>
        <Shimmer
          height={Metrics.avatar.height}
          width={Metrics.avatar.width}
          shimmerStyle={Styles.shimmerAvatar}
        />
        <View style={Styles.rowTextContainerView}>
          <View style={Styles.rowTitleContainer}>
            <Shimmer width={isWeb() ? '80%' : width * 0.6} />
          </View>
          <View style={Styles.rowBodyContainer}>
            <View style={Styles.rowBodyTextContainer}>
              <Shimmer width={isWeb() ? '50%' : width * 0.5} />
            </View>
          </View>
        </View>
      </View>
    </TouchableHighlight>
  )
}

const Row = ({ item, onPress, practitioners }) => {
  const userId = item?.lastPost?.user?.id || null
  const user = practitioners?.[userId]
  const avatarUrl = user && (user.avatar_url || user.props?.picture)
  const displayName = item.participantNames
  const tintClinicIcon = Config.FEATURES && Config.FEATURES.PRIMARY_COLOR_TINT && Colors.accent

  if (item.busy) {
    return <LoadingRow />
  }

  return (
    <TouchableHighlight
      underlayColor={Colors.listFocus}
      onPress={onPress}
      testID="BASE_EPISODE_LIST"
    >
      <View style={item.unread ? Styles.rowUnreadContainer : Styles.rowContainer}>
        {avatarUrl ? (
          <MattermostAvatar picture={avatarUrl} style={Styles.avatar} />
        ) : (
          <Image
            resizeMode="contain"
            source={
              item?.healthIssueTypeIcon
                ? getServiceCategoryIcon(item?.healthIssueTypeIcon)
                : Images.logoNurse
            }
            style={[
              item?.healthIssueTypeIcon ? Styles.healthIcon : Styles.avatar,
              {
                tintColor: tintClinicIcon || undefined,
              },
            ]}
          />
        )}
        <View style={Styles.rowTextContainerView}>
          <View style={Styles.rowTitleContainer}>
            <Text
              numberOfLines={1}
              style={item.unread ? Styles.participantNamesUnreadText : Styles.participantNamesText}
            >
              {item.title}
            </Text>
            <View style={Styles.rowIconContainer}>
              <Text numberOfLines={1} style={item.unread ? Styles.dateUnreadText : Styles.dateText}>
                {item.lastPost.date}
              </Text>
            </View>
          </View>
          <View style={Styles.rowBodyContainer}>
            <View style={Styles.rowBodyTextContainer}>
              <Text
                numberOfLines={1}
                style={item.unread ? Styles.chatSnippetUnreadText : Styles.chatSnippetText}
              >
                {displayName}: {item.lastPost.message}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </TouchableHighlight>
  )
}

export default Row
