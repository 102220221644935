import React from 'react'
import styled, { useTheme } from 'styled-components/native'
import { useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import Analytics from 'APP/Services/Analytics'

import { TabScreens } from 'APP/Lib/ContentHelpers'
import Button from 'APP/Converse/Button'
import Card from 'APP/Converse/Card'
import Typography from 'APP/Converse/Typography'

import UpdatesAtGlance from 'APP/Images/updates_at_glance.svg'
import I18n from 'APP/Services/i18n'

const Wrapper = styled.View`
  margin-top: ${({ theme }) => theme.metrics.baseMargin}px;
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin}px;
`

const ActionContainer = styled(Card)`
  ${({ theme }) => theme.shadows.normal};
  background-color: ${({ theme }) => theme.colors.elementsBg};
  border-radius: 20px;
  align-items: center;
  padding-horizontal: ${({ theme }) => theme.metrics.baseMargin}px;
  padding-top: ${({ theme }) => theme.metrics.baseMargin}px;
  padding-bottom: ${({ theme }) => theme.metrics.baseMargin * 1.5}px;
  margin-horizontal: ${({ theme }) => theme.metrics.baseMargin}px;
`

const OverviewContainer = styled(Card)`
  ${({ theme }) => theme.shadows.normal};
  background-color: ${({ theme }) => theme.colors.elementsBg};
  border-radius: 20px;
  align-items: center;
  padding-horizontal: ${({ theme }) => theme.metrics.baseMargin}px;
  padding-top: ${({ theme }) => theme.metrics.baseMargin}px;
  padding-bottom: ${({ theme }) => theme.metrics.baseMargin * 1.5}px;
  margin-horizontal: ${({ theme }) => theme.metrics.baseMargin}px;
  margin-top: ${({ theme }) => theme.metrics.baseMargin * 2}px;
`

const CardText = styled(Typography)`
  margin-vertical: ${({ theme }) => theme.metrics.baseMargin}px;
`

const MainAction = () => {
  const theme = useTheme()
  const navigation = useNavigation()
  const { appointments, orderedEpisodes } = useSelector((state) => state?.history)
  const showUpdatesAtGlance = orderedEpisodes?.length === 0 && appointments?.upcoming?.length === 0

  const navToChat = () => {
    Analytics.trackEvent('button_click', {
      button_value: 'Today.getCareNow',
    })
    navigation.navigate('chatTab', { tabName: TabScreens.chat.getCare })
  }

  return (
    <Wrapper>
      <ActionContainer analyticsName="TodayScreen.getCareNowCard">
        <CardText align="center">{I18n.t('TodayScreen.getCareNowCard.body')}</CardText>
        <Button
          variant="oomph"
          title={I18n.t('TodayScreen.getCareNowCard.cta')}
          onPress={navToChat}
          testID="getCareNow"
        />
      </ActionContainer>
      {showUpdatesAtGlance && (
        <OverviewContainer analyticsName="TodayScreen.updatesAtGlanceCard" testID="updatesAtGlance">
          <CardText align="center" variant="h4">
            {I18n.t('TodayScreen.updatesAtAGlanceCard.title')}
          </CardText>
          <UpdatesAtGlance
            accentFillLine={theme.colors.elementsBg}
            fillLine={theme.colors.elementsBorder}
            width="180"
            height="101"
          />
          <CardText align="center">{I18n.t('TodayScreen.updatesAtAGlanceCard.body')}</CardText>
        </OverviewContainer>
      )}
    </Wrapper>
  )
}

export default MainAction
