import { Dimensions, StyleSheet } from 'react-native'
import { Colors, Fonts } from 'APP/Themes'

const windowWidth = Dimensions.get('window').width

export default StyleSheet.create({
  title: {
    fontFamily: Fonts.type.button,
    fontSize: Fonts.size.regular,
    fontWeight: 'bold',
    color: Colors.text,
  },
  text: {
    fontFamily: Fonts.type.base,
    fontSize: Fonts.size.small,
    color: Colors.text,
  },
  noticeItemStyleInner: {
    padding: 10,
  },
  centerText: {
    textAlign: 'center',
  },
  noticeBarInline: {
    backgroundColor: Colors.bannerBg,
    alignSelf: 'center',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
  },
  noticeBarFloat: {
    backgroundColor: Colors.bannerBg,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    alignSelf: 'center',
    justifyContent: 'center',
    width: windowWidth,
    flexDirection: 'column',
  },
  noticePressContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 10,
  },
  icon: {
    backgroundColor: 'transparent',
    flex: 0.1,
  },
  offlineStyle: {
    backgroundColor: Colors.errorSnackBarBg,
  },
  offlineTextStyle: {
    textAlign: 'center',
    fontSize: Fonts.size.regular,
    color: Colors.darkText,
    fontWeight: 'bold',
  },
})
