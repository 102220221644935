import React, { useState } from 'react'
import _ from 'lodash'
import { ScrollView, View } from 'react-native'
import { RouteProp } from '@react-navigation/native'
import { Divider } from 'react-native-elements'
import { useSelector, useDispatch } from 'react-redux'

import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import ToggleCard from 'APP/Components/ToggleCard'
import Typography from 'APP/Converse/Typography'
import { ResponsiveView } from 'APP/Converse/Layout'

import NotificationActions from 'APP/Redux/NotificationRedux'

import Analytics from 'APP/Services/Analytics'
import I18n from 'APP/Services/i18n'
import Styles from './style'
import { getKeySnakeCase, PREFERENCE_MAPPING } from 'APP/Lib/PreferenceHelpers'
import { Format } from 'APP/Lib/ProfileDataFormat'

type NotificationDetailScreenRouteProp = RouteProp<
  {
    params: { notification: string }
  },
  'params'
>

const NotificationDetailScreen = ({ route }: { route: NotificationDetailScreenRouteProp }) => {
  const dispatch = useDispatch()
  const [toggledNotification, setToggledNotification] = useState({ type: '', key: '' })

  const { phoneNumber } = useSelector((state) => state.patient.profile)
  const { preferences } = useSelector((state) => state.notifications)
  const currentPreferences = preferences?.data
  const loading = preferences?.loading

  const notification = route?.params?.notification

  const getTitle = (notificationName: string) => {
    return I18n.t(
      `NotificationsScreen.notificationCenter.notificationDetails.${notificationName}.title`
    )
  }

  const getDescription = (notificationName: string) => {
    const descriptionKey = `NotificationsScreen.notificationCenter.notificationDetails.${notificationName}.description`

    return notificationName === 'appointmentReminders'
      ? I18n.t(descriptionKey, {
          phoneNumber: Format.phoneNumber(phoneNumber),
        })
      : I18n.t(descriptionKey)
  }

  const onToggleChange = (key: string, isToggled: boolean, type: string) => {
    setToggledNotification({ type, key })
    const snakeKey = getKeySnakeCase(key)
    const data = {
      [type]: {
        [snakeKey]: isToggled,
      },
    }
    Analytics.trackEvent('button_click', {
      button_value: `notification_toggle_${snakeKey}_${isToggled ? 'on' : 'off'}`,
    })
    dispatch(NotificationActions.updateNotificationPreference(data))
  }

  const renderToggleCard = (notificationName: string) => {
    const types = _.keys(_.pickBy(PREFERENCE_MAPPING, (value) => value.includes(notificationName)))

    return types.map((type) => {
      const label = I18n.t(
        `NotificationsScreen.notificationCenter.notificationDetails.types.${type}`
      )
      const isToggled =
        notification === 'messagesAndVideoCalls'
          ? true // Always true for push new messages and video calls
          : currentPreferences?.[type]?.[notificationName]

      const isLoading =
        loading &&
        toggledNotification?.type === type &&
        toggledNotification?.key === notificationName

      return (
        <ToggleCard
          testID={`toggle-card-${type}-${notificationName}`}
          label={label}
          onToggleChange={() => onToggleChange(notificationName, !isToggled, type)}
          isToggled={isToggled}
          key={`toggle-card-${type}-${notificationName}`}
          isLoading={isLoading}
          disabled={notificationName === 'messagesAndVideoCalls'}
        />
      )
    })
  }

  const renderSections = () => {
    const notificationNames = []

    if (notification === 'reminders') {
      notificationNames.push('appointmentReminders')
      notificationNames.push('intakeReminders')
    } else {
      notificationNames.push(notification)
    }

    return notificationNames.map((notificationName, index) => {
      return (
        <View key={index} style={Styles.section}>
          <Typography variant="h2">{getTitle(notificationName)}</Typography>
          <Typography variant="subheader" color="dim">
            {getDescription(notificationName)}
          </Typography>
          {renderToggleCard(notificationName)}
          <Divider style={Styles.divider} />
        </View>
      )
    })
  }

  return (
    <NoticeBarContainer>
      <ScrollView style={Styles.scrollContainer}>
        <ResponsiveView style={Styles.container}>
          {renderSections()}
          <Typography variant="bodySmall">
            {I18n.t(`NotificationsScreen.notificationCenter.notificationDetails.pushDisclaimer`)}
          </Typography>
        </ResponsiveView>
      </ScrollView>
    </NoticeBarContainer>
  )
}

export default NotificationDetailScreen
