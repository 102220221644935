import React, { useMemo } from 'react'
import { View, Text } from 'react-native'
import SentimentLowIcon from 'APP/Images/WellBeing/sentiment_dissatisfied.svg'
import SentimentMidIcon from 'APP/Images/WellBeing/sentiment_neutral.svg'
import SentimentHighIcon from 'APP/Images/WellBeing/sentiment_satisfied.svg'
import I18n from 'APP/Services/i18n'
import { getScoreRangeData, RangeTypes } from '../../helpers'

import Styles from './style'

function IndexBar({ score, targetType }) {
  const [isRangeActive, activeElementStyle] = useMemo(() => {
    const { calculatedType, percentage } = getScoreRangeData(score)
    const isActive = targetType === calculatedType

    return [
      isActive,
      {
        left: percentage + '%',
        opacity: isActive ? 1 : 0,
      },
    ]
  }, [score, targetType])

  const styleIsActive = {
    ...Styles.indexBarSection,
    zIndex: isRangeActive ? 1 : 0,
    elevation: isRangeActive ? 1 : 0,
  }

  const lineActiveStyle = Styles['indexLine' + targetType + 'Active']

  const SentimentIcon = {
    [RangeTypes.Low]: SentimentLowIcon,
    [RangeTypes.Mid]: SentimentMidIcon,
    [RangeTypes.High]: SentimentHighIcon,
  }[targetType]

  return (
    <View style={styleIsActive} testID={'indexBar' + targetType}>
      <SentimentIcon style={Styles.sentimentIcon} />
      <View
        style={[
          Styles['indexLine' + targetType],
          isRangeActive ? lineActiveStyle : Styles.indexLineDeactivated,
        ]}
      >
        <View style={[Styles['indexBarLineDot' + targetType], activeElementStyle]} />
      </View>
      <View style={[Styles.tooltipContainer, activeElementStyle]}>
        <View style={Styles.tooltip}>
          <Text style={Styles.tooltipLabel} numberOfLines={1}>
            {I18n.t('WellBeingIndexScreen.domains.default')}
          </Text>
          <View style={Styles.tooltipContent}>
            <View
              style={[
                Styles.scoreValuePoint,
                { backgroundColor: lineActiveStyle?.backgroundColor },
              ]}
            />
            <Text style={Styles.scoreValue} numberOfLines={1}>
              {I18n.t(`WellBeingIndexScreen.scores.${String(targetType).toUpperCase()}`)}
            </Text>
          </View>
          <View style={Styles.tail} />
        </View>
      </View>
    </View>
  )
}

function CompareChart({ score }) {
  return (
    <View style={Styles.indexBar}>
      <IndexBar score={score} targetType={RangeTypes.Low} />
      <IndexBar score={score} targetType={RangeTypes.Mid} />
      <IndexBar score={score} targetType={RangeTypes.High} />
    </View>
  )
}

export default CompareChart
