import React from 'react'
import { ScrollView, View, Text, Image } from 'react-native'

// i18n
import I18n from 'APP/Services/i18n'

// Components
import { PrimaryButton } from 'APP/Components/Buttons'

// Styles
import Styles from './style'
import { ApplicationStyles, Images } from 'APP/Themes'

class VerifyEmailSuccessScreen extends React.Component {
  render() {
    return (
      <View style={ApplicationStyles.screen.navContainer}>
        <ScrollView alwaysBounceVertical={false} contentContainerStyle={Styles.scrollView}>
          <Text style={Styles.title}>{I18n.t('VerifyEmailSuccessScreen.title')}</Text>
          <Text style={Styles.subtitle}>{I18n.t('VerifyEmailSuccessScreen.subtitle')}</Text>
          <Image
            resizeMode={'contain'}
            style={Styles.illustration}
            source={Images.emailVerifiedIllustration}
          />
          <PrimaryButton
            style={Styles.primaryButton}
            onPress={() => this.props.navigation.goBack()}
            analyticsName="VerifyEmailSuccessScreen.continueButtonLabel"
          >
            {I18n.t('VerifyEmailSuccessScreen.continueButtonLabel')}
          </PrimaryButton>
        </ScrollView>
      </View>
    )
  }
}

export default VerifyEmailSuccessScreen
