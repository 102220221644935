import { StyleSheet } from 'react-native'
import { Colors, Fonts, Metrics } from 'APP/Themes'

export const Styles = StyleSheet.create({
  container: {
    marginRight: 2,
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  cardContainer: {
    borderRadius: 25,
    padding: Metrics.baseMargin,
    backgroundColor: Colors.elementsBg,
    flexDirection: 'row',
    flex: 1,
  },
  icon: {
    paddingRight: 12,
  },
  innerContainer: {
    maxWidth: 225,
  },
  title: {
    ...Fonts.style.h4,
    color: Colors.text,
  },
  subtitle: {
    ...Fonts.style.body,
    marginTop: Metrics.baseMargin / 4,
    color: Colors.text,
  },
  action: {
    marginLeft: 'auto',
  },
  row: {
    flexDirection: 'row',
    flex: 1,
  },
  submitIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 48,
    height: 48,
  },
})
