import { createActions, createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setAppForegrounded: null,
  setAppBackgrounded: null,
  setSystemError: ['errorMessage', 'fatal'],
  clearSystemError: ['index'],
  setDarkModeRestartRequired: ['darkModeRestartRequired'],
  setIsConnected: ['isConnected'],
  setAppBackgroundedTimestamp: ['backgroundTimestamp'],
})

export const INITIAL_STATE = Immutable({
  isActive: true,
  isConnected: true,
  systemErrors: [],
  systemExperiencedFatalError: null,
  darkModeRestartRequired: null,
  backgroundTimestamp: null,
})

export const setSystemError = (state, { errorMessage, fatal }) => {
  let errors = [...state.systemErrors]
  errors.push({
    errorMessage: String(errorMessage),
    timestamp: Date.now(),
    fatal,
  })
  return state.merge({
    systemErrors: errors,
    systemExperiencedFatalError: state.systemExperiencedFatalError || fatal,
  })
}

export const clearSystemError = (state, { index }) =>
  state.merge({
    systemErrors: state.systemErrors ? state.systemErrors.filter((i) => i !== index) : [],
  })

export const setDarkModeRestartRequired = (state, { darkModeRestartRequired }) =>
  state.merge({ darkModeRestartRequired: !!darkModeRestartRequired })

export const setAppForegrounded = (state) => state.merge({ isActive: true })

export const setAppBackgrounded = (state) => state.merge({ isActive: false })

export const setIsConnected = (state, { isConnected }) => state.merge({ isConnected })

export const setAppBackgroundedTimestamp = (state, { backgroundTimestamp }) =>
  state.merge({ backgroundTimestamp })

export const AppSessionTypes = Types

export default Creators

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_SYSTEM_ERROR]: setSystemError,
  [Types.CLEAR_SYSTEM_ERROR]: clearSystemError,
  [Types.SET_DARK_MODE_RESTART_REQUIRED]: setDarkModeRestartRequired,
  [Types.SET_APP_FOREGROUNDED]: setAppForegrounded,
  [Types.SET_APP_BACKGROUNDED]: setAppBackgrounded,
  [Types.SET_IS_CONNECTED]: setIsConnected,
  [Types.SET_APP_BACKGROUNDED_TIMESTAMP]: setAppBackgroundedTimestamp,
})
