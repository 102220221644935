import React, { ReactNode } from 'react'
import { View, Image, PixelRatio } from 'react-native'

import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import Typography from 'APP/Converse/Typography'

import Styles from './style'
import { Images } from 'APP/Themes'
import ContentCardNotice, { ContentNoticeType } from './ContentCardNotice'


interface ContentCardProps {
  title: string
  onPress: () => void
  icon: ReactNode
  info: string
  imageUrl: string
  notice?: { name: string; type: ContentNoticeType }
  testID?: string
  tag?: string
  maxWidth: number
}

const ContentCard: React.FC<ContentCardProps> = ({
  title,
  onPress,
  icon,
  info,
  imageUrl,
  testID = 'content-card-link',
  tag,
  notice,
  maxWidth = 300,
}) => {
  const imageWidth = PixelRatio.getPixelSizeForLayoutSize(maxWidth)

  return (
    <DialogueTouchableOpacity
      onPress={onPress}
      style={Styles.getListItem(maxWidth)}
      testID={testID}
    >
      <View style={Styles.imageWrapper}>
        <Image
          style={Styles.image}
          source={{ uri: `${imageUrl}?w=${imageWidth}`, cache: 'force-cache' }}
          defaultSource={Images.contentLoading}
          resizeMode="cover"
        />
      </View>
      <View style={{ ...Styles.description, maxWidth }}>
        <Typography variant="h4">{title}</Typography>
        <View style={Styles.infoSection}>
          {icon}
          <Typography variant="bodySmall">{info}</Typography>
          {tag && (
            <Typography testID="content-card-tag" variant="bodySmall" style={Styles.tag}>
              {tag}
            </Typography>
          )}
        </View>
      </View>
      {!!notice && <ContentCardNotice {...notice} />}
    </DialogueTouchableOpacity>
  )
}

export default ContentCard
