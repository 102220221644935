import React from 'react'
import { Controller, FieldValues } from 'react-hook-form'
import I18n from 'APP/Services/i18n'
import TextInput from 'APP/Converse/Inputs/TextInput'
import styled from 'styled-components/native'
import { Control } from 'react-hook-form/dist/types/form'
import { PromptAddressItemProps } from './types'

const Container = styled.View`
  padding: 0 ${({ theme }) => theme.metrics.baseMargin}px;
`

interface PromptAddressControllerProps {
  name: string
  item: PromptAddressItemProps
  control: Control<FieldValues, unknown>
  openConditionalForm(value): void
  isFocused: boolean
  onFocus: (value: string | null) => void
}

export const PromptAddressController = ({
  name,
  item,
  control,
  openConditionalForm,
  onFocus,
}: PromptAddressControllerProps) => {
  const { required, title, placeholder_text, showRequiredAsterisk } = item

  const onPressIn = (value?: string) => {
    openConditionalForm({
      item: { ...item, prefilled_values: value || item.prefilled_values },
      fieldName: name,
    })
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: required && I18n.t('Form.error.required'),
      }}
      render={({ field: { value, onBlur, ref }, fieldState: { error } }) => (
        <Container>
          <TextInput
            onBlur={onBlur}
            ref={ref}
            value={value?.display}
            label={title}
            showRequiredAsterisk={showRequiredAsterisk}
            onPressIn={() => {
              onFocus(name)
              onPressIn(value?.display)
            }}
            placeholder={placeholder_text}
            error={error?.message}
          />
        </Container>
      )}
    />
  )
}
