// *HEAVILY* based on rn-tooltip. (https://github.com/AndreiCalazans/rn-tooltip/)
// But now it is very opinionated to Dialogue design.

import React, { useState, useRef } from 'react'
import { Platform, Modal, View, Dimensions } from 'react-native'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import getTooltipCoordinate from './getTooltipCoordinate'

import { Metrics } from 'APP/Themes'
import Styles from './style'

// --- Using this component --- //
// <Tooltip content="This is the content that renders within the tooltip">
//   <Icon name="help-outline" /> (or whatever else you want the toggle to connect to)
// </Tooltip>
// --------------------------- //

const Tooltip = ({ content, children, disabled, testID, analyticsName, containerStyle }) => {
  const tooltipElem = useRef(null)
  const Screen = Dimensions.get('window')
  const width = Metrics.screenWidth * 0.9

  const [isVisible, setIsVisible] = useState(false)
  const [yOffset, setYOffset] = useState(0)
  const [xOffset, setXOffset] = useState(0)
  const [elementWidth, setElementWidth] = useState(0)
  const [elementHeight, setElementHeight] = useState(0)

  const { x, y } = getTooltipCoordinate(
    xOffset,
    yOffset,
    elementWidth,
    elementHeight,
    Screen.width,
    Screen.height,
    width
  )

  const pastMiddleLine = yOffset > y

  const tooltipStyle = {
    left: x + 10,
    width,
    ...Styles.content,
  }

  if (pastMiddleLine) {
    tooltipStyle.bottom =
      Screen.height - y - (Platform.OS === 'android' ? Metrics.statusBarHeight : 0)
  } else {
    tooltipStyle.top = y
  }

  const toggleTooltip = () => {
    getTooltipPosition()
    setIsVisible(!isVisible)
  }

  const getTooltipPosition = () => {
    tooltipElem.current &&
      tooltipElem.current.measureInWindow((pageOffsetX, pageOffsetY, width, height) => {
        setXOffset(pageOffsetX)
        setYOffset(pageOffsetY)
        setElementWidth(width)
        setElementHeight(height)
      })
  }

  return (
    <View ref={tooltipElem} onLayout={getTooltipPosition} style={containerStyle} testID={testID}>
      <DialogueTouchableOpacity
        testID="toggle"
        disabled={disabled}
        onPress={toggleTooltip}
        activeOpacity={1}
        analyticsName={analyticsName}
      >
        {children}
      </DialogueTouchableOpacity>
      <Modal
        statusBarTranslucent
        animationType="fade"
        visible={isVisible}
        transparent
        testID="modal"
      >
        <DialogueTouchableOpacity
          style={Styles.container}
          onPress={toggleTooltip}
          activeOpacity={1}
        >
          <View
            style={{
              position: 'absolute',
              top: !tooltipStyle.top ? yOffset - 13 : yOffset + elementHeight - 2,
              left: xOffset + elementWidth / 2 - 13,
            }}
          >
            <View style={[Styles.pointer, !tooltipStyle.top ? Styles.down : {}]} />
          </View>
          <View testID="tooltip" style={tooltipStyle}>
            {content}
          </View>
        </DialogueTouchableOpacity>
      </Modal>
    </View>
  )
}

export default Tooltip
