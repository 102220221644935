import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Metrics, Fonts } from '../../Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  container: {
    flex: 1,
    paddingHorizontal: Metrics.baseMargin,
    backgroundColor: Colors.elementsBg,
  },
  placeholderImage: {
    resizeMode: 'contain',
    alignSelf: 'center',
    width: Metrics.screenWidth - Metrics.baseMargin,
    height: 160,
    marginTop: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin,
  },
  subtitle: {
    color: Colors.text,
    textAlign: 'center',
    ...Fonts.style.body,
    marginBottom: Metrics.baseMargin,
  },
  buttonContainer: {
    marginHorizontal: Metrics.baseMargin,
    marginTop: Metrics.baseMargin,
  },
})
