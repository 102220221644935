import React from 'react'
import { Text, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { connect } from 'react-redux'

import { PrimaryTextButton } from 'APP/Components/Buttons'
import CarePlanListItem from 'APP/Components/CarePlanListItem'

import I18n from 'APP/Services/i18n'
import { TabScreens } from 'APP/Lib/ContentHelpers'

import Styles from './style'
import { ResponsiveFlatList } from 'APP/Converse/Layout'
import Button from 'APP/Converse/Button'

const RecentCarePlansHeader = ({ showNavButton }) => {
  const navigation = useNavigation()
  return (
    <View style={Styles.headerContainer} testID={'RECENT_CARE_PLANS_HEADER'}>
      <Text style={Styles.header}>{I18n.t('RecentCarePlans.header')}</Text>
      {showNavButton && (
        <PrimaryTextButton
          analyticsName="RecentCarePlans.seeAll"
          onPress={() => {
            navigation.navigate('chatTab', { tabName: TabScreens.chat.carePlans })
          }}
          containerStyle={Styles.seeAllButtonContainer}
          disabledContainerStyle={Styles.seeAllButtonContainer}
        >
          {I18n.t('RecentCarePlans.headerButton')}
        </PrimaryTextButton>
      )}
    </View>
  )
}

const NoCarePlans = ({ fromChatScreen, goToTabByName }) => {
  const navigation = useNavigation()

  const handleOnPress = () => {
    if (fromChatScreen) {
      goToTabByName(TabScreens.chat.getCare)
    } else {
      navigation.navigate('chatTab', { tabName: TabScreens.chat.getCare })
    }
  }

  return (
    <View style={Styles.cardContainer}>
      <Text style={Styles.noCarePlansBody}>{I18n.t('RecentCarePlans.noCarePlansBody')}</Text>
      <Button
        variant="oomph"
        title={I18n.t('RecentCarePlans.noCarePlansButton')}
        onPress={handleOnPress}
        analyticsName="RecentCarePlans.getCare"
      />
    </View>
  )
}

const RecentCarePlans = ({ carePlans, episodes, style, fromChatScreen = false, goToTabByName }) => {
  const processedCarePlans =
    carePlans
      ?.slice(...(fromChatScreen ? [0] : [0, 3]))
      ?.map((carePlan) => ({ carePlan, episode: episodes[carePlan.episode_id] })) ?? []

  return (
    <ResponsiveFlatList
      style={style}
      data={processedCarePlans}
      keyExtractor={(carePlan) => String(carePlan?.carePlan.id)}
      renderItem={({ item: { carePlan, episode } }) => (
        <CarePlanListItem carePlan={carePlan} episode={episode} />
      )}
      ListHeaderComponent={() =>
        fromChatScreen ? null : (
          <RecentCarePlansHeader showNavButton={processedCarePlans.length > 0} />
        )
      }
      ListEmptyComponent={
        <NoCarePlans fromChatScreen={fromChatScreen} goToTabByName={goToTabByName} />
      }
    />
  )
}

const mapStateToProps = (state) => {
  const { history } = state
  return {
    episodes: history.episodes,
    carePlans: history.completedCarePlans,
  }
}

export default connect(mapStateToProps)(RecentCarePlans)
