import { convertMetricToLocalUnit } from '../general/helpers'

export function categorizeChallenges(challenges = []) {
  const categorized = {
    joined: [],
    newAndUpcoming: [],
  }

  challenges.forEach((challenge) => {
    if (challenge.joined_at) {
      categorized.joined.push(challenge)
    } else {
      categorized.newAndUpcoming.push(challenge)
    }
  })

  return categorized
}

export const cleanupMetricValue = (initialValue, metric) => {
  const value = convertMetricToLocalUnit(initialValue, metric)

  let fixedMetric = +parseFloat(value).toFixed(2)
  const decimals = fixedMetric.toString().split('.')[1]

  if (decimals && decimals.length === 1) {
    fixedMetric = `${fixedMetric}0`
  }

  return fixedMetric.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
