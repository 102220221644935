import React, { useEffect } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Image, Text, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { TouchableRipple } from 'react-native-paper/lib/module'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'

import I18n from 'APP/Services/i18n'
import { getNameDisplay } from 'APP/Lib/ProfileHelpers'
import Analytics from 'APP/Services/Analytics'
import WellBeingRedux from 'APP/Redux/WellBeingRedux'
import { selectIsWellBeingIndexAssessmentEligible } from 'APP/Sagas/WellBeingSagas'
import Icon from 'APP/Converse/Icon'

import Styles from './style'
import { Colors, Metrics, Images } from 'APP/Themes'
import { getImageAspectRatio } from 'APP/Lib/StylingHelpers'

const WIDE_ASPECT_RATIO = 1.5

const TodayHeader = ({ profile, hasWellBeingIndexAssessmentEligibility, style }) => {
  const navigation = useNavigation()
  const dispatch = useDispatch()

  const { givenName, preferredName, pronouns } = profile
  const showPronouns = useSelector((state) => state.features.pronounDisplay)
  const wellBeingAssessment = useSelector((state) => state.wellBeingAssessment)

  const { nameDisplay } = getNameDisplay(givenName, preferredName)
  const iconAspectRatio = getImageAspectRatio(Images.logoBlue)

  const isWideLogo = iconAspectRatio > WIDE_ASPECT_RATIO

  const getLatestWellBeingAssessment = () => dispatch(WellBeingRedux.getLatest())

  useEffect(() => {
    getLatestWellBeingAssessment()
  }, [])

  const handleAccountPress = () => {
    Analytics.trackEvent('button_click', { button_value: 'TodayScreen.profile' })
    navigation.navigate('account')
  }

  const handleWbiPress = () => {
    Analytics.trackEvent('button_click', { button_value: 'TodayScreen.wbi' })
    navigation.navigate('wellBeingIndex')
  }

  return (
    <View style={[Styles.headerContainer, style]}>
      <View style={Styles.headerRow}>
        <Image
          style={[
            Styles.headerLogo,
            isWideLogo && Styles.wideLogo,
            { aspectRatio: iconAspectRatio },
          ]}
          source={Images.logoBlue}
        />
        <View style={Styles.headerRowActionContainer}>
          {hasWellBeingIndexAssessmentEligibility && (
            <View style={Styles.wellBeingIconContainer}>
              <TouchableRipple
                borderless
                style={Styles.wellBeingIconRipple}
                onPress={handleWbiPress}
                testID="wbiHeaderAction"
                accessibilityLabel={I18n.t('AccessibilityIcons.wellbeingIndex')}
              >
                <>
                  <MaterialIcon
                    name="insights"
                    color={Colors.todayHeaderActionIcon}
                    style={Styles.wellBeingIcon}
                    size={Metrics.icons.small}
                  />
                  {!wellBeingAssessment.latest?.loading && (
                    <Text style={Styles.wellBeingScore}>
                      {wellBeingAssessment.latest.data?.score || I18n.t('HomeScreen.featured')}
                    </Text>
                  )}
                </>
              </TouchableRipple>
            </View>
          )}
          <View style={Styles.profileIconContainer}>
            <TouchableRipple
              borderless
              style={Styles.profileIconRipple}
              onPress={handleAccountPress}
              accessibilityLabel={I18n.t('AccountScreen.title')}
              accessibilityRole="button"
              testID={'profile-icon'}
            >
              <Icon variant="svg" src={Images.groups} color="todayHeaderActionIcon" />
            </TouchableRipple>
          </View>
        </View>
      </View>
      <Text style={Styles.welcomeText} numberOfLines={1} testID="TODAY_SCREEN_HEADER_TITLE">
        {I18n.t('TodayScreen.welcome', { name: nameDisplay })}
      </Text>
      {showPronouns && Boolean(pronouns) && (
        <Text style={Styles.welcomeTextSmall} numberOfLines={1}>
          {pronouns}
        </Text>
      )}
    </View>
  )
}

const mapStateToProps = (state) => {
  return {
    profile: state.patient?.profile,
    hasWellBeingIndexAssessmentEligibility: selectIsWellBeingIndexAssessmentEligible(state),
  }
}

export default connect(mapStateToProps, null)(TodayHeader)
