import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { Platform, Share } from 'react-native'
import { isWeb } from 'APP/Helpers/checkPlatform'
import Config from 'APP/Config'
import I18n from 'APP/Services/i18n'
import Analytics from 'APP/Services/Analytics'
import { appendQueryParams } from 'APP/Services/UrlBuilder'

export const copyToClipboard = (value = '') => {
  navigator.clipboard.writeText(value)
}

export const useShare = (content = {}, shareOptions = {}) => {
  const [title, _setTitle] = useState(null)
  const [message, _setMessage] = useState(null)
  const [url, _setUrl] = useState(null)
  const [sharing, _setSharing] = useState(false)
  const [options, setOptions] = useState(shareOptions)
  const [results, _setResults] = useState([])
  const [error, _setError] = useState(null)

  const setTitle = (value) => {
    _setTitle(['', undefined, null].includes(value) ? null : value)
  }

  const setMessage = (value) => {
    _setMessage(['', undefined, null].includes(value) ? null : value)
  }

  const setUrl = (value) => {
    if (['', null, undefined].includes(value)) {
      _setUrl(null)
      return
    }
    let url = new URL(value).toString()
    url = url.endsWith('/') ? url.slice(0, -1) : url
    _setUrl(url)
  }

  useEffect(() => {
    content.title && setTitle(content.title)
    content.message && setMessage(content.message)
    content.url && setUrl(content.url)
  }, [])

  const share = useCallback(async () => {
    try {
      _setError(null)
      _setSharing(true)
      const content = {
        title,
        message,
        url,
      }
      if (isWeb()) {
        copyToClipboard(url)
      } else {
        const result = await Share.share(content, options)
        _setResults([result, ...results])
        return result[0]
      }
    } catch (err) {
      _setError(err)
    } finally {
      _setSharing(false)
    }
  }, [title, message, url, options])

  return {
    title,
    setTitle,
    message,
    setMessage,
    url,
    setUrl,
    options,
    setOptions,
    share,
    isSharing: sharing,
    result: useMemo(() => results[0], [results]),
    results: results,
    error,
  }
}

export const useShareDeepLink = (baseURL, params = {}, { analyticsName } = {}) => {
  const deepLinkURL = useMemo(() => {
    return Object.entries(params)
      .filter(([, value]) => [null, undefined].includes(value) === false)
      .reduce(
        (url, [key, value]) => appendQueryParams(url, { [key]: value }),
        new URL('', baseURL).toString()
      )
      .toString()
  }, [baseURL, params])

  const share = Platform.select({
    ios() {
      return useShare({ url: deepLinkURL })
    },
    android() {
      return useShare({ message: deepLinkURL })
    },
    default() {
      return useShare({ url: deepLinkURL })
    },
  })()

  useEffect(() => {
    Platform.select({
      ios() {
        share.setUrl(deepLinkURL)
      },
      android() {
        share.setMessage(deepLinkURL)
      },
      default() {
        share.setUrl(deepLinkURL)
      },
    })()
  }, [deepLinkURL])

  useEffect(() => {
    if (share.result && analyticsName) {
      Analytics.trackEvent('button_click', {
        button_value: share.result.action,
        trigger: share.result.activityType,
        screen: analyticsName,
      })
    }
  }, [share.result, analyticsName])

  return share
}

const loadUrlFromConfig = (configKey, locales = [], config = Config) => {
  return locales.reduce((url, locale, index, confHash) => {
    if (url) {
      return url
    } else if (config[configKey] && typeof config[configKey] === 'string') {
      return new URL('', config[configKey]).toString()
    } else if (config[configKey] && config[configKey][locale]) {
      return new URL('', config[configKey][locale]).toString()
    } else if (index + 1 >= confHash.length) {
      throw new Error(`Conf[${config[configKey]}][${locales.join('|')}] not defined`)
    }
  }, null)
}

export const useShareDeepLinkFromConfig = (
  configKeyOrConfigHashOrUrl,
  params,
  { analyticsName, locale = I18n.locale } = {},
  config = Config
) => {
  const [baseURL] = useState(() =>
    loadUrlFromConfig(configKeyOrConfigHashOrUrl, [locale, I18n.locale, I18n.baseLocale], config)
  )
  return useShareDeepLink(baseURL, params, { analyticsName })
}

export const withShare = (Component, useShareFn, key = 'share') => {
  const toCamelCase = (str) => {
    return str
      .replace(/(?:^\w|\[A-Z\]|\b\w)/g, (word, index) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase()
      })
      .replace(/\s+/g, '')
  }
  function WithShareComponent(props) {
    const { share, message, url, title } = useShareFn(props, key)
    const propsWithShare = useMemo(
      () => ({
        [toCamelCase(key)]: share,
        [toCamelCase(`${key} message`)]: message,
        [toCamelCase(`${key} url`)]: url,
        [toCamelCase(`${key} title`)]: title,
      }),
      [message, url, title]
    )
    return <Component {...props} {...propsWithShare} />
  }
  WithShareComponent.displayName = `WithShareComponent(${
    Component.displayName || Component.name || 'Component'
  })`
  return WithShareComponent
}
