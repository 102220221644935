import React, { createContext, useContext } from 'react'
import { Modal, Platform } from 'react-native'
import styled from 'styled-components/native'
import { Metrics } from 'APP/Themes'

type DialogContextProps = {
  onClose: () => void
  isVisible: boolean
}

export type DialogProviderProps = {
  isVisible: boolean
  onClose: () => void
  children: React.ReactNode
  testID?: string
}

const DialogContext = createContext<DialogContextProps | undefined>(undefined)

export const useDialog = () => {
  const context = useContext(DialogContext)
  if (!context) throw new Error('useDialog must be used within a DialogProvider')
  return context
}

const KeyboardAvoidingContainer = styled.KeyboardAvoidingView`
  flex: 1;
`

export const DialogProvider: React.FC<DialogProviderProps> = ({
  isVisible,
  onClose,
  children,
  testID,
}) => {
  return (
    <DialogContext.Provider
      value={{
        onClose,
        isVisible,
      }}
    >
      <Modal
        statusBarTranslucent
        animationType="fade"
        transparent
        visible={isVisible}
        onRequestClose={onClose}
        testID={testID}
      >
        <KeyboardAvoidingContainer
          behavior={Platform.OS === 'ios' ? 'padding' : undefined}
          keyboardVerticalOffset={-Metrics.bottomSpace}
        >
          {children}
        </KeyboardAvoidingContainer>
      </Modal>
    </DialogContext.Provider>
  )
}
