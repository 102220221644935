import React from 'react'
import { Controller, UseFieldArrayProps } from 'react-hook-form'

import TextInput from 'APP/Converse/Inputs/TextInput'
import { KeyboardTypeOptions, TextInputProps } from 'react-native'

interface ControllerInputProps {
  control: UseFieldArrayProps['control']
  name: string
  rules?: UseFieldArrayProps['rules']
  autoFocus?: boolean
  testID?: string
  label: string
  isFocused?: boolean
  infotip?: string
  keyboardType?: KeyboardTypeOptions
  onFocus?: () => void
  disabled?: boolean
  isSecureEntry?: boolean
  placeholder?: string
  hide?: boolean
  autoCorrect?: boolean
  spellCheck? : boolean
  showRequiredAsterisk?: boolean
  onPressIn?: TextInputProps['onPressIn']
}

const ControllerInput = ({
  control,
  name,
  rules,
  autoFocus,
  testID,
  label,
  isFocused,
  infotip,
  keyboardType = 'default',
  onFocus,
  disabled,
  isSecureEntry,
  placeholder,
  hide,
  autoCorrect,
  spellCheck,
  showRequiredAsterisk,
  onPressIn,
}: ControllerInputProps) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange, onBlur, ref }, fieldState: { error } }) => (
        <TextInput
          disabled={disabled}
          ref={ref}
          autoFocus={autoFocus}
          testID={testID}
          onChangeText={onChange}
          value={value}
          onBlur={onBlur}
          onFocus={onFocus}
          returnKeyType="next"
          label={label}
          showRequiredAsterisk={showRequiredAsterisk}
          onPressIn={onPressIn}
          error={error?.message}
          keyboardType={keyboardType}
          isSecureEntry={isSecureEntry}
          isFocused={isFocused}
          infotip={infotip}
          placeholder={placeholder}
          hide={hide}
          spellCheck={spellCheck}
          autoCorrect={autoCorrect}
        />
      )}
    />
  )
}

export default ControllerInput
