import { Colors, Fonts, Metrics } from 'APP/Themes'
import { StyleSheet } from 'react-native'

export const ROW_HEIGHT = 64

export default StyleSheet.create({
  container: {
    backgroundColor: Colors.elementsBg,
  },
  bottomSpace: {
    marginBottom: Metrics.baseMargin * 1.5,
  },
  topSpace: {
    marginTop: Metrics.baseMargin * 1.5,
  },
  row: {
    flexDirection: 'row',
    flex: 1,
    paddingLeft: Metrics.baseMargin,
    height: ROW_HEIGHT,
  },
  monthText: {
    flex: 1,
    position: 'absolute',
    top: -Fonts.style.subheaderBold.fontSize,
    zIndex: 1,
    textTransform: 'uppercase',
    ...Fonts.style.subheaderBold,
    color: Colors.text,
  },
  week: {
    flexDirection: 'row',
    flex: 0.82,
    alignItems: 'center',
    paddingRight: Metrics.baseMargin,
    borderRightWidth: 1,
    borderColor: Colors.elementsBorder,
  },
  dayContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    aspectRatio: 1,
    marginVertical: Metrics.baseMargin * 0.6,
  },
  dayHidden: {
    opacity: 0,
  },
  day: {
    flex: 1,
    alignItems: 'center',
    aspectRatio: 1,
    justifyContent: 'center',
    margin: Metrics.baseMargin / 4,
    borderRadius: 100,
  },
  dayActive: {
    backgroundColor: Colors.habitDoneAccent,
  },
  dayText: {
    ...Fonts.style.subheader,
    color: Colors.text,
  },
  dayTextActive: {
    color: Colors.darkText,
  },
  dayTextInactive: {
    color: Colors.deactivatedElementBg,
  },
  todayMark: {
    position: 'absolute',
    width: 4,
    height: 4,
    borderRadius: 2,
    backgroundColor: Colors.text,
    bottom: -Metrics.baseMargin / 4,
  },
  streak: {
    flex: 0.18,
    alignItems: 'center',
    justifyContent: 'center',
  },
  streakText: {
    ...Fonts.style.caption,
    color: Colors.text,
  },
})
