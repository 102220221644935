import React from 'react'
import Icon from 'react-native-vector-icons/MaterialIcons'
import { connect } from 'react-redux'

import { Text, ScrollView, View } from 'react-native'

// Actions
import StartupActions from 'APP/Redux/StartupRedux'

// Services
import I18n from 'APP/Services/i18n'

// Styling
import Styles from './style'
import { Colors } from 'APP/Themes'

class LockScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      triggered: false,
    }
  }

  componentDidMount() {
    this.debouncedAuthentication()
  }

  debouncedAuthentication = () => {
    if (this.debounceAuthentication) clearTimeout(this.debounceAuthentication)
    this.debounceAuthentication = setTimeout(() => {
      this.props.triggerAuthentication()
    }, 2000)
  }

  componentWillUnmount() {
    clearTimeout(this.debounceAuthentication)
  }

  render() {
    return (
      <ScrollView contentContainerStyle={Styles.scrollContainer}>
        <View style={Styles.background} />
        <Icon name="lock" size={40} color={Colors.text} />
        <Text style={Styles.title}>{I18n.t('LockScreen.title')}</Text>
        <Text style={Styles.subtitle}>{I18n.t('LockScreen.subtitle')}</Text>
      </ScrollView>
    )
  }
}

const mapStateToProps = (state) => {
  const { app } = state
  const user_id = state.patient && state.patient.profile && state.patient.profile.id
  const isAppLockToggled = app && app.appLock && app.appLock.isToggled
  return { user_id, isAppLockToggled }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAppLockToggle: (value) => dispatch(StartupActions.setAppLockToggle(value)),
    triggerAuthentication: () => dispatch(StartupActions.triggerAppLock()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LockScreen)
