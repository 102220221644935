import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { ScrollView, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import styled from 'styled-components/native'

import { AllProviderMatchingAttributes } from '@dialogue/timekeeper'

import Button from 'APP/Converse/Button'
import Typography from 'APP/Converse/Typography'
import BottomSheetModal from 'APP/Components/BottomSheetModal'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'

import { isWeb } from 'APP/Helpers/checkPlatform'
import Analytics from 'APP/Services/Analytics'
import I18n from 'APP/Services/i18n'
import { RootState } from 'APP/Store/CreateStore'
import {
  selectProvidersAttributesLoading,
  selectProvidersAttributesError,
  selectProvidersAttributes,
} from 'APP/Store/ProviderBooking/selectors'
import { Attributes } from 'APP/Store/ProviderBooking/types'
import { providerBookingActions } from 'APP/Store/ProviderBooking'
import { Metrics, Colors } from 'APP/Themes'

import { LoadingIndicator } from '../common/loadingIndicator'

const OpenModalBtn = styled(DialogueTouchableOpacity)`
  background-color: ${({ theme }) => theme.colors.elementsBg};
  border: 1px solid ${({ theme }) => theme.colors.elementsBorder};
  border-radius: 12px;
  padding: ${({ theme }) => theme.metrics.baseMargin / 2}px
    ${({ theme }) => theme.metrics.baseMargin}px;
  margin: ${({ theme }) => theme.metrics.baseMargin * 1.5}px 0
    ${({ theme }) => theme.metrics.baseMargin / 1.5}px;
  color: ${({ theme }) => theme.colors.text};
  ${({ theme }) => theme.shadows.soft};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`

const FilterIcon = styled(MaterialIcon)`
  padding: ${({ theme }) => theme.metrics.baseMargin / 4}px;
`

const StyledBottomSheet = styled(BottomSheetModal)`
  height: 100%;
`

const Wrapper = styled.View`
  flex: 1;
`

const ContentWrapper = styled(ScrollView)`
  padding: 0 24px;
`

const ButtonWrapper = styled.View`
  padding: 24px;
`

const ConfirmButtonWrapper = styled.View`
  margin-bottom: 8px;
`

const DropdownWrapper = styled.View`
  margin-top: 20px;
`

const DropdownTitle = styled.Pressable`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const DropdownOptions = styled.View`
  margin-top: 12px;
`

const DropdownOption = styled.Pressable<{ $checked: boolean }>`
  background-color: ${({ $checked, theme }) =>
    $checked ? Colors.activeTabBackgroundColor : theme.colors.elementsBg};
  border: 1px solid
    ${({ $checked, theme }) => ($checked ? theme.colors.accent : theme.colors.elementsBorder)};
  border-radius: 12px;
  padding: 12px;
  margin: 2px 0;
  color: ${({ theme }) => theme.colors.text};
  ${({ theme }) => theme.shadows.soft};
  display: flex;
  align-items: center;
  flex-direction: row;
`

const Checkbox = styled.View<{ $checked: boolean }>`
  border-radius: 4px;
  border: 1px solid ${Colors.text};
  background-color: ${({ $checked }) => ($checked ? Colors.darkText : 'transparent')};
  margin-right: 8px;
`

const OptionCopy = styled(Typography)<{ $checked: boolean }>`
  color: ${({ $checked }) => ($checked ? Colors.darkText : Colors.text)} !important;
`

const DropdownSubTitle = styled(Typography)`
  margin-top: 4px;
`

export const Dropdown = ({
  title,
  isOpen,
  allOptions,
  selectedOptions,
  handleOpen,
  setSelectedOptions,
}: {
  title: string
  allOptions: Array<{
    id: string
    en: string
    fr: string
  }>
  selectedOptions: Array<string>
  isOpen: boolean
  setSelectedOptions: (options: Array<string>) => void
  handleOpen: () => void
}) => {
  // @ts-expect-error I18n service isn't typed.
  const localeKey = I18n.locale === 'fr' ? 'fr' : 'en'

  const handleSelected = (selectedOption: string) => {
    if (selectedOptions.includes(selectedOption)) {
      const updatedOptions = selectedOptions.filter((s) => s !== selectedOption)
      setSelectedOptions(updatedOptions)
    } else {
      setSelectedOptions([...selectedOptions, selectedOption])
    }
  }

  return (
    <DropdownWrapper>
      <DropdownTitle onPress={handleOpen} testID="openDropdown">
        <Typography variant="h4">{title}</Typography>
        <MaterialIcon
          name={isOpen ? 'keyboard-arrow-up' : 'keyboard-arrow-down'}
          size={Metrics.icons.small}
          color={Colors.text}
        />
      </DropdownTitle>
      {isOpen ? (
        <DropdownOptions>
          {allOptions.map((option) => {
            const isSelected = selectedOptions.includes(option.id)

            return (
              <DropdownOption
                key={option.id}
                onPress={() => handleSelected(option.id)}
                $checked={isSelected}
                testID="options"
              >
                <Checkbox $checked={isSelected}>
                  <MaterialIcon
                    name={'check'}
                    size={Metrics.icons.tiny}
                    color={isSelected ? Colors.activeTabBackgroundColor : Colors.elementsBg}
                  />
                </Checkbox>
                <OptionCopy variant="bodySmall" $checked={isSelected}>
                  {option[localeKey]}
                </OptionCopy>
              </DropdownOption>
            )
          })}
        </DropdownOptions>
      ) : (
        <DropdownSubTitle variant="bodySmall" testID="selectedOptions">
          {selectedOptions.length > 0
            ? selectedOptions
                .map((id) => allOptions.find((o) => o?.id === id)?.[localeKey])
                .join(', ')
            : I18n.t(`AppointmentBooking.filterPreferences.groups.noPreference`)}
        </DropdownSubTitle>
      )}
    </DropdownWrapper>
  )
}

enum MODAL_STATE {
  OPEN = 'open',
  CLOSED = 'closed',
}

type AllSelectedOptions = Record<keyof AllProviderMatchingAttributes, Array<string>>

interface Props {
  onSubmit: (params: any) => void
}

export const FilterAvailabilities = ({ onSubmit }: Props) => {
  const dispatch = useDispatch()

  const [modalStatus, setModalStatus] = useState<MODAL_STATE>(MODAL_STATE.CLOSED)
  const [selectedPreferences, setSelectedPreferences] = useState<AllSelectedOptions>({
    [Attributes.GENDERS]: [],
    [Attributes.CULTURAL_BACKGROUNDS]: [],
    [Attributes.ADDITIONAL_INTERESTS]: [],
  })
  const [activeAttribute, setActiveAttribute] = useState<Attributes | null>(Attributes.GENDERS)

  const loading = useSelector(selectProvidersAttributesLoading)
  const error = useSelector(selectProvidersAttributesError)
  const attributes = useSelector(selectProvidersAttributes)

  const { highlightProviderSelectionPreferences } = useSelector(
    (state: RootState) => state.features
  )

  const atLeastOneOptionSelected = useMemo(
    () => Object.values(selectedPreferences).flat().length > 0,
    [selectedPreferences]
  )

  useEffect(() => {
    dispatch(providerBookingActions.getProvidersAttributes())
  }, [dispatch])

  const handleAttributeSelection = useCallback(
    (name: Attributes) => (options: string[]) => {
      setSelectedPreferences((prev) => ({
        ...prev,
        [name]: options,
      }))
    },
    []
  )

  const handleOpenDropdown = useCallback(
    (attribute: Attributes) => () => {
      setActiveAttribute((prev) => (prev === attribute ? null : attribute))
    },
    []
  )

  const handleOpenPress = () => {
    Analytics.trackEvent('button_click', {
      button_value: 'providerBooking.selectAdditionalPreferences',
    })
    setModalStatus(MODAL_STATE.OPEN)
  }

  const handleRetryPress = () => {
    dispatch(providerBookingActions.getProvidersAttributes())
  }

  const handleCancelPress = () => {
    Analytics.trackEvent('button_click', {
      button_value: 'providerBooking.cancelAdditionalPreferences',
    })
    setModalStatus(MODAL_STATE.CLOSED)
  }

  const mapPreferences = (
    selectedKeys: string[],
    attributesList: Array<{ id: string; en: string; fr: string }>
  ) => {
    return selectedKeys.map((key) => attributesList.find((item) => item.id === key)?.en)
  }

  const handleSubmitPress = () => {
    Analytics.trackEvent('button_click', {
      button_value: 'providerBooking.applyAdditionalPreferences',
    })

    let selectedPreferencesEn = { ...selectedPreferences}

    if (attributes) {
      const mappedPreferences = {
        genders: mapPreferences(selectedPreferences.genders, attributes.genders),
        cultural_backgrounds: mapPreferences(
          selectedPreferences.cultural_backgrounds,
          attributes.culturalBackgrounds
        ),
        additional_interests: mapPreferences(
          selectedPreferences.additional_interests,
          attributes.additionalInterests
        ),
      }

      Analytics.trackEvent('applied_provider_preferences', mappedPreferences)
      selectedPreferencesEn = mappedPreferences
    }

    onSubmit(selectedPreferences)

    dispatch(
      providerBookingActions.updateSelectedPreferences({
        selectedPreferences: selectedPreferencesEn,
      })
    )

    setModalStatus(MODAL_STATE.CLOSED)
  }
  

  return (
    <View>
      <OpenModalBtn onPress={handleOpenPress} testID="openFilterModal">
        <Typography variant="subheader">
          {I18n.t(`AppointmentBooking.filterPreferences.buttonOpenModal`)}
        </Typography>
        {highlightProviderSelectionPreferences ? (
          <FilterIcon
            name="filter-list"
            size={Metrics.icons.small}
            color={Colors.accent}
            testID="filter-icon"
          />
        ) : (
          <MaterialIcon
            name="chevron-right"
            size={Metrics.icons.medium}
            color={Colors.text}
            testID="chevron-icon"
          />
        )}
      </OpenModalBtn>
      {/* @ts-expect-error BottomSheetModal isn't typed. */}
      <StyledBottomSheet
        isVisible={modalStatus === MODAL_STATE.OPEN}
        onClose={handleCancelPress}
        title={I18n.t(`AppointmentBooking.filterPreferences.title`)}
        testID="filterModal"
        containerStyle={{
          height: '95%',
          ...(isWeb() ? { width: '100%', maxWidth: '1000px', alignSelf: 'center' } : {}),
        }}
      >
        <Wrapper>
          <ContentWrapper>
            {loading && <LoadingIndicator />}
            {!loading && error && (
              <Typography variant="bodyNormal">
                {I18n.t(`AppointmentBooking.filterPreferences.retry.copy`)}
              </Typography>
            )}
            {!loading && attributes && (
              <>
                <Typography>{I18n.t(`AppointmentBooking.filterPreferences.copy`)}</Typography>
                <Dropdown
                  title={I18n.t(`AppointmentBooking.filterPreferences.groups.gender`)}
                  allOptions={attributes.genders}
                  selectedOptions={selectedPreferences.genders}
                  setSelectedOptions={handleAttributeSelection(Attributes.GENDERS)}
                  isOpen={activeAttribute === Attributes.GENDERS}
                  handleOpen={handleOpenDropdown(Attributes.GENDERS)}
                />
                <Dropdown
                  title={I18n.t(`AppointmentBooking.filterPreferences.groups.culturalBackground`)}
                  allOptions={attributes.culturalBackgrounds}
                  selectedOptions={selectedPreferences.cultural_backgrounds}
                  setSelectedOptions={handleAttributeSelection(Attributes.CULTURAL_BACKGROUNDS)}
                  isOpen={activeAttribute === Attributes.CULTURAL_BACKGROUNDS}
                  handleOpen={handleOpenDropdown(Attributes.CULTURAL_BACKGROUNDS)}
                />
                <Dropdown
                  title={I18n.t(`AppointmentBooking.filterPreferences.groups.additionalInterests`)}
                  allOptions={attributes.additionalInterests}
                  selectedOptions={selectedPreferences.additional_interests}
                  setSelectedOptions={handleAttributeSelection(Attributes.ADDITIONAL_INTERESTS)}
                  isOpen={activeAttribute === Attributes.ADDITIONAL_INTERESTS}
                  handleOpen={handleOpenDropdown(Attributes.ADDITIONAL_INTERESTS)}
                />
              </>
            )}
          </ContentWrapper>
          <ButtonWrapper>
            {!loading && error && (
              <Button
                variant="primary"
                widthVariant="full"
                title={I18n.t(`AppointmentBooking.filterPreferences.retry.cta`) || ''}
                onPress={handleRetryPress}
                small={false}
              />
            )}
            {!loading && attributes && (
              <>
                <ConfirmButtonWrapper>
                  <Button
                    variant="primary"
                    widthVariant="full"
                    title={I18n.t(`AppointmentBooking.filterPreferences.buttonConfirm`) || ''}
                    onPress={handleSubmitPress}
                    small={false}
                    testID="confirmFilters"
                    disabled={!atLeastOneOptionSelected}
                  />
                </ConfirmButtonWrapper>
                <Button
                  variant="secondary"
                  widthVariant="full"
                  title={I18n.t(`AppointmentBooking.filterPreferences.buttonCancel`) || ''}
                  onPress={handleCancelPress}
                  small={false}
                  testID="cancelFilters"
                />
              </>
            )}
          </ButtonWrapper>
        </Wrapper>
      </StyledBottomSheet>
    </View>
  )
}

export default FilterAvailabilities
