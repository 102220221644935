import React from 'react'
import Button from 'APP/Converse/Button'
import styled from 'styled-components/native'
import { serviceGroupActionType } from 'APP/Hooks/BenefitNavigationCTA'
import I18n from 'APP/Services/i18n'

const ButtonContainer = styled(Button)`
  margin: 0px;
  margin-horizontal: 0px;
`

export const ServiceActionButton = ({
  service,
  hasExternalProvider,
  onPress,
}: {
  service: Record<string, string>
  hasExternalProvider: boolean
  onPress: () => void
}) => {
  const { actionType, actionValue, ctaLabel } = service
  const isURL = actionType === serviceGroupActionType.url
  const isNavigationToMicroApp = actionType === serviceGroupActionType.navigation && !actionValue

  const icons = {
    external: I18n.t('ServiceGroup.external.icon'),
    navigation: I18n.t('ServiceGroup.navigation.icon'),
    default: undefined,
  }

  let icon: string | undefined = icons.default

  if (hasExternalProvider && isURL) {
    icon = icons.external
  } else if (isNavigationToMicroApp) {
    icon = icons.navigation
  }

  return (
    <ButtonContainer
      variant="primary"
      title={ctaLabel}
      onPress={() => onPress()}
      icon={icon}
      iconPosition="right"
      iconVariant="material"
      widthVariant="full"
      testID="CTA_BUTTON"
    />
  )
}
