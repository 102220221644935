import React, { useEffect, useMemo } from 'react'
import { View, Text, ActivityIndicator, Image } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import * as Sentry from '@sentry/react-native'
import { WellnessCenter } from '@dialogue/services'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import WellnessCenterActions from 'APP/Redux/WellnessCenterRedux'
import Analytics from 'APP/Services/Analytics'
import I18n from 'APP/Services/i18n'
import { cleanupMetric } from 'APP/Helpers/Wellness/challenges'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import JoinButton from '../JoinButton'

import { Colors, Metrics, Images } from 'APP/Themes'
import Styles from './style'

const { Challenges } = WellnessCenter
const { TrackerStatus } = WellnessCenter.Trackers.Types

const Row = ({ item, showSimple, isTeam, isHighlighted }) => {
  const { trackers } = useSelector((state) => state.wellnessCenter.trackers)
  const { givenName, preferredName, familyName } = useSelector((state) => state.patient.profile)
  const userTrackers = trackers.user
  const userHasNoCompatibleTrackers = useSelector(
    (state) => state.wellnessCenter.selectedChallenge?.userHasNoCompatibleTrackers
  )

  const connectedTrackers = userTrackers.filter(
    (tracker) => tracker.status === TrackerStatus.Connected
  )
  const trackerIsBroken = item.tracker_name
    ? (userTrackers || []).find((tracker) => tracker.tracker_name === item.tracker_name)?.status ===
      TrackerStatus.Broken
    : false
  const trackerIsNotSynced = !item.last_sync_time && connectedTrackers.length > 0
  const trackerIsSynced = item.last_sync_time && connectedTrackers.length > 0

  // Pull values out of participant depending on type of challenge
  const name = isTeam ? item.content?.title : item.user?.display_name
  const value = isTeam ? item.team_value?.avg : item.total_value

  // For individual leaderboards, if the current member exists (isHighlighted)
  // and doesn't have a name, log it
  useEffect(() => {
    if (!isTeam && isHighlighted && (!name || name === '')) {
      const nameExists = givenName || preferredName || familyName

      // Capturing whether we even have the name from Core Data cause
      // If we don't, then it makes sense we didn't send it to Tictrac
      // But if we do, then this is an issue with how/when we call Tictrac to
      // update the name.
      Sentry.captureMessage(
        `Wellness Center: Member has no name in leaderboard: ${
          nameExists ? 'Name is in core profile' : 'Name is NOT in core profile'
        }`
      )
    }
  }, [isTeam, isHighlighted, name, givenName, preferredName, familyName])

  return (
    <>
      {!showSimple && (
        <Text
          style={isHighlighted ? { ...Styles.rank, ...Styles.highlightedCopy } : Styles.rank}
          testID="rank"
        >
          {item.rank}
        </Text>
      )}
      {!isTeam ? (
        <Text style={Styles.icon}>{name?.charAt(0)}</Text>
      ) : (
        <Image
          style={Styles.teamImage}
          source={{ uri: item?.content?.image }}
          defaultSource={Images.contentLoading}
          resizeMode="cover"
        />
      )}
      <View style={Styles.person}>
        <Text
          style={isHighlighted ? { ...Styles.name, ...Styles.highlightedCopy } : Styles.name}
          testID="participantName"
        >
          {name}
        </Text>
        {!showSimple && (
          <View style={Styles.subtextWrapper} testID="rowSubtext">
            {/* Team view shows number of participants */}
            {isTeam && (
              <Text
                style={
                  isHighlighted ? { ...Styles.subtext, ...Styles.highlightedCopy } : Styles.subtext
                }
                testID="teamParticipantNumber"
              >
                {item.participants_count}&nbsp;
                {item.participants_count !== 1
                  ? I18n.t('WellnessCenter.challenges.members')
                  : I18n.t('WellnessCenter.challenges.member')}
              </Text>
            )}

            {/* Individual view for current member with no connected trackers */}
            {!isTeam && isHighlighted && connectedTrackers.length === 0 && !trackerIsBroken && (
              <>
                <MaterialIcon name="error" color={Colors.errorText} style={Styles.errorIcon} />
                <Text style={Styles.errorSubtext} testID="noTrackersConnected">
                  {I18n.t('WellnessCenter.trackers.notConnected')}
                </Text>
              </>
            )}

            {/* Individual view for current member with no compatible trackers */}
            {!isTeam && isHighlighted && userHasNoCompatibleTrackers && (
              <>
                <MaterialIcon name="error" color={Colors.errorText} style={Styles.errorIcon} />
                <Text style={Styles.errorSubtext} testID="noCompatibleTrackersConnected">
                  {I18n.t('WellnessCenter.trackers.noneCompatible.title')}
                </Text>
              </>
            )}

            {/* Individual view for current member with a broken tracker */}
            {!isTeam && isHighlighted && trackerIsBroken && (
              <>
                <MaterialIcon name="error" color={Colors.errorText} style={Styles.errorIcon} />
                <Text style={Styles.errorSubtext} testID="trackerConnectionIssue">
                  {I18n.t('WellnessCenter.trackers.connectionIssue')}
                </Text>
              </>
            )}

            {/* Individual view for a member with a connected tracker that hasn't synced */}
            {((!isTeam &&
              isHighlighted &&
              trackerIsNotSynced &&
              !trackerIsBroken &&
              !userHasNoCompatibleTrackers) ||
              (!isTeam && !isHighlighted && !item.last_sync_time)) && (
              <>
                <Text style={isHighlighted ? {...Styles.subtext, ...Styles.highlightedCopy} : Styles.subtext} testID="trackerNotSynced">
                  {I18n.t('WellnessCenter.trackers.notSynced')}
                </Text>
              </>
            )}

            {/* Individual view for users who are NOT the current member */}
            {/* Individual view for current member with a connected tracker */}
            {((!isTeam && !isHighlighted && !!item.last_sync_time) ||
              (!isTeam &&
                isHighlighted &&
                !trackerIsBroken &&
                trackerIsSynced &&
                !userHasNoCompatibleTrackers)) && (
              <Text
                style={
                  isHighlighted ? { ...Styles.subtext, ...Styles.highlightedCopy } : Styles.subtext
                }
                testID="lastSyncAt"
              >
                {I18n.t('WellnessCenter.trackers.synced')}&nbsp;
                {I18n.baseLocale === 'fr' ? `${I18n.t('WellnessCenter.trackers.ago')} ` : ''}
                {moment.utc(item.last_sync_time).fromNow(true)}&nbsp;
                {I18n.baseLocale === 'en' ? `${I18n.t('WellnessCenter.trackers.ago')}` : ''}
              </Text>
            )}
          </View>
        )}
      </View>
      {!showSimple && (
        <Text
          style={
            isHighlighted ? { ...Styles.progress, ...Styles.highlightedCopy } : Styles.progress
          }
          testID="goalValue"
        >
          {cleanupMetric(value, item.metric)}
        </Text>
      )}
    </>
  )
}

const Leaderboard = ({ title, subtitle, joinChallenge, viewTeamId }) => {
  const dispatch = useDispatch()
  const navigation = useNavigation()

  const {
    challenge,
    participants,
    additionalParticipants,
    activeLeaderboardItem,
    loadingLeaderboard,
    retryLeaderboard,
  } = useSelector((state) => state.wellnessCenter.selectedChallenge)
  const selectedTeam = useSelector((state) => state.wellnessCenter.selectedTeam)

  const isUpcoming = challenge?.status === Challenges.Types.ChallengeStatus.UPCOMING
  const isTeam = challenge?.type === Challenges.Types.ChallengeType.TEAM && viewTeamId === undefined
  const highlightedId = isTeam ? activeLeaderboardItem.team : activeLeaderboardItem.user
  const items = viewTeamId ? selectedTeam.participants : participants
  const adjacentItems = viewTeamId ? selectedTeam.additionalParticipants : additionalParticipants

  const loading = useMemo(
    () => (viewTeamId ? selectedTeam.loading : loadingLeaderboard),
    [viewTeamId, loadingLeaderboard, selectedTeam]
  )
  const retry = useMemo(
    () => (viewTeamId ? selectedTeam.retry : retryLeaderboard),
    [viewTeamId, retryLeaderboard, selectedTeam]
  )
  const hasNoParticipants = useMemo(
    () => !loading && !retry && (!items || items.length === 0),
    [loading, retry, items]
  )
  const hasItems = useMemo(() => !loading && items.length > 0, [(loading, items)])
  const hasAdjacentItems = useMemo(
    () => !loading && adjacentItems && adjacentItems.length > 0,
    [loading, adjacentItems]
  )

  const getLeaderboard = () => dispatch(WellnessCenterActions.getLeaderboard(challenge.id))
  const getTeamLeaderboard = () =>
    dispatch(WellnessCenterActions.getTeamParticipants(challenge.id, viewTeamId))

  useEffect(() => {
    if (viewTeamId === undefined && challenge.id) {
      getLeaderboard()
    }

    if (viewTeamId !== undefined && challenge.id) {
      getTeamLeaderboard()
    }
  }, [viewTeamId, challenge.id])

  const navigateToTeamDetail = (team) => {
    Analytics.trackEvent('button_click', { button_value: 'View challenge team detail' })
    navigation.navigate('wcChallengeTeam', {
      challengeId: challenge?.id,
      teamId: team.id,
      count: team.participants_count,
      image: team.content?.image,
      title: `${team.content?.title} (${team.participants_count})`,
    })
  }

  return (
    <>
      <View style={Styles.participantsHeader}>
        <Text style={Styles.participantsTitle} testID="leaderboardTitle">
          {title}
        </Text>
        {subtitle && (
          <Text style={Styles.participantsMetric} numberOfLines={1} testID="metric">
            {subtitle}
          </Text>
        )}
      </View>

      {loading && (
        <ActivityIndicator
          size="large"
          color={Colors.text}
          style={Styles.loading}
          testID="loading"
        />
      )}

      {hasNoParticipants && (
        <View style={Styles.noParticipants} testID="noParticipants">
          <Text style={Styles.noParticipantsTitle}>
            {I18n.t('WellnessCenter.challenges.noParticipants.title')}
          </Text>
          <Text style={Styles.noParticipantsCopy}>
            {I18n.t('WellnessCenter.challenges.noParticipants.copy')}
          </Text>
          <JoinButton style={Styles.joinCta} setVisibility={joinChallenge} />
        </View>
      )}

      {hasItems && (
        <View style={Styles.participants} testID="participants">
          {items.map((p) => {
            const baseParticipantStyle = isUpcoming ? Styles.simpleParticipant : Styles.participant
            const id = isTeam ? p.id : p.user?.id
            const isHighlighted = id === highlightedId

            // User's team is pressable to show detailed view
            if (isTeam && p.participants_count > 0) {
              return (
                <DialogueTouchableOpacity
                  key={id}
                  style={
                    isHighlighted
                      ? { ...Styles.highlight, ...baseParticipantStyle }
                      : baseParticipantStyle
                  }
                  testID="pressable-participant"
                  onPress={() => navigateToTeamDetail(p)}
                >
                  <Row
                    item={p}
                    showSimple={isUpcoming}
                    isTeam={isTeam}
                    isHighlighted={isHighlighted}
                  />
                </DialogueTouchableOpacity>
              )
            }

            return (
              <View
                key={id}
                style={
                  isHighlighted
                    ? { ...Styles.highlight, ...baseParticipantStyle }
                    : baseParticipantStyle
                }
                testID="participant"
              >
                <Row
                  item={p}
                  showSimple={isUpcoming}
                  isTeam={isTeam}
                  isHighlighted={isHighlighted}
                />
              </View>
            )
          })}
        </View>
      )}

      {hasAdjacentItems && (
        <>
          <View style={Styles.seperator}>
            <MaterialIcon
              name="drag-handle"
              color={Colors.textSecondary}
              size={Metrics.icons.small}
            />
          </View>
          <View style={Styles.addtlParticipants} testID="addtlParticipants">
            {adjacentItems.map((p) => {
              const baseParticipantStyle = isUpcoming
                ? Styles.simpleParticipant
                : Styles.participant
              const isHighlighted = p.user?.id === highlightedId

              return (
                <View
                  key={p.user?.id}
                  style={
                    isHighlighted
                      ? { ...Styles.highlight, ...baseParticipantStyle }
                      : baseParticipantStyle
                  }
                  testID="participant"
                >
                  <Row
                    item={p}
                    showSimple={isUpcoming}
                    isTeam={false}
                    isHighlighted={isHighlighted}
                  />
                </View>
              )
            })}
          </View>
        </>
      )}

      {retry && (
        <DialogueTouchableOpacity
          style={Styles.refresh}
          onPress={viewTeamId !== undefined ? getTeamLeaderboard : getLeaderboard}
          analyticsName="Refresh"
          testID="retry"
        >
          <MaterialIcon name="refresh" size={Metrics.icons.large} color={Colors.text} />
        </DialogueTouchableOpacity>
      )}
    </>
  )
}

export default Leaderboard
