import { StyleSheet } from 'react-native'
import { Colors, Fonts, Metrics, ApplicationStyles } from 'APP/Themes'
import Color from 'color'

export default StyleSheet.create({
  card: {
    ...ApplicationStyles.shadow,
    flex: 1,
    alignItems: 'center',
    backgroundColor: Colors.elementsBg,
    marginHorizontal: Metrics.baseMargin,
    paddingTop: Metrics.baseMargin * 2,
    paddingBottom: Metrics.baseMargin * 1.5,
    borderRadius: 20,
  },
  cardOutline: {
    flex: 1,
    alignItems: 'center',
    marginHorizontal: Metrics.baseMargin,
    paddingTop: Metrics.baseMargin * 2,
    paddingBottom: Metrics.baseMargin * 1.5,
    borderRadius: 20,
    borderWidth: 3,
    borderStyle: 'dashed',
    borderColor: Color(Colors.buttonSecondaryDisabled).alpha(0.3).rgb().string(),
  },
  title: {
    ...Fonts.style.subheaderBold,
    color: Colors.text,
    paddingHorizontal: Metrics.baseMargin,
    paddingBottom: Metrics.baseMargin * 0.5,
  },
  copy: {
    ...Fonts.style.body,
    color: Colors.text,
    textAlign: 'center',
    paddingVertical: Metrics.baseMargin * 0.5,
    paddingHorizontal: Metrics.baseMargin,
  },
  featured: {
    alignSelf: 'stretch',
    paddingBottom: Metrics.baseMargin,
  },
  featuredTitle: {
    flex: 1,
    ...Fonts.style.h4,
    color: Colors.text,
    paddingBottom: Metrics.baseMargin * 0.8,
    paddingHorizontal: Metrics.baseMargin,
  },
  featuredContent: {
    borderTopWidth: 1,
    borderTopColor: Colors.shadow,
  },
  featuredHabit: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: Colors.shadow,
    paddingVertical: Metrics.baseMargin,
    paddingHorizontal: Metrics.baseMargin,
  },
  featuredHabitText: {
    ...Fonts.style.subheaderBold,
    color: Colors.text,
    paddingRight: Metrics.baseMargin,
    flex: 1,
  },
})
