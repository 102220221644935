import { call, cancel, delay, fork, take } from 'redux-saga/effects'

/**
 * Effect creator which will perform polling of a provided worker.
 * The worker will run when `startPattern` is dispatched, and repeat after
 * provided `intervalMs` has elapsed. Polling ends when `stopPattern` is dispatched.
 * The worker gets called with the contents of the `startPattern` action, as well
 * as additional args passed to this method.
 *
 * Note that this system debounces start/stop polling actions, so you can
 * safely dispatch `startPattern` multiple times in a row, and the latter
 * will be ignored.
 *
 * This effect creator returns a redux-saga fork effect, so it is non-blocking.
 * (see https://redux-saga.js.org/docs/advanced/ForkModel.html)
 *
 * Example:
 * ```typescript
 *  yield poll(
 *    'START_POLLING',
 *    'STOP_POLLING',
 *    2000,
 *    function* worker(firstArg: string, action) {
 *      console.log(firstArg)
 *      // "YOLO"
 *    },
 *    'YOLO'
 *  )
 * ```
 */
export function poll(startPattern, stopPattern, intervalMs, worker, ...args) {
  function* pollRunner(action) {
    while (true) {
      yield call(worker, ...[...args, action])
      yield delay(intervalMs)
    }
  }

  function* pollWatcher() {
    while (true) {
      const action = yield take(startPattern)
      const pollingTask = yield fork(pollRunner, action)

      yield take(stopPattern)
      yield cancel(pollingTask)
    }
  }
  return fork(pollWatcher)
}
