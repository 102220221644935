import React from 'react'

import { connect } from 'react-redux'
import Icon from 'react-native-vector-icons/MaterialIcons'

import PatientActions from 'APP/Redux/PatientRedux'

// services
import I18n from 'APP/Services/i18n'

// components
import InfoCard from 'APP/Components/InfoCard'

// Styling
import { Colors } from 'APP/Themes'

const _ZeroEligibilityInfoCard = ({ busy, unchallengedInvitationSearch, testId }) => {
  const analyticsName = 'Zero Eligibility'

  return (
    <InfoCard
      title={I18n.t('ZeroEligibilityInfoCard.title')}
      subtitle={I18n.t('ZeroEligibilityInfoCard.subtitle.todayScreen')}
      buttonText={I18n.t('ZeroEligibilityInfoCard.button')}
      onButtonPressed={unchallengedInvitationSearch}
      buttonAnalyticsName={analyticsName}
      icon={<Icon name="lock-outline" size={40} color={Colors.accent} />}
      busy={busy}
      testId={testId}
    />
  )
}

const mapStateToProps = ({ patient }) => {
  return {
    busy:
      (patient?.unchallengedInvitationSearchRunning ?? false) ||
      (patient?.challengedInvitationSearchRunning ?? false),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    unchallengedInvitationSearch: () =>
      dispatch(PatientActions.unchallengedInvitationSearchRequest()),
  }
}

const ZeroEligibilityInfoCard = connect(
  mapStateToProps,
  mapDispatchToProps
)(_ZeroEligibilityInfoCard)

export default ZeroEligibilityInfoCard
