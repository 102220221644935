import url from 'url'
import Config from 'APP/Config'

// Used strictly for debugging purposes through debug menu
export let disableRequests = false
export const toggleDisableExtraPropsSourceRequests = () => (disableRequests = !disableRequests)

const AUTH_ALLOWLIST = Config.EXTRA_PROPS_SOURCE_AUTH_ALLOWLIST || []
export const urlMatchesAuthWhitelist = (requestUrl) => {
  const { hostname } = url.parse(requestUrl) || {}
  return !!AUTH_ALLOWLIST.find((domain) => (hostname || '').endsWith(domain))
}

export const urlIsHttps = (requestUrl) => requestUrl.indexOf('https') === 0

async function getExtraProps(requestUrl, authToken) {
  const headers = {
    'Content-Type': 'application/json',
  }
  if (authToken && urlMatchesAuthWhitelist(requestUrl) && urlIsHttps(requestUrl)) {
    Object.assign(headers, { Authorization: `Bearer ${authToken}` })
  }

  const res = await fetch(requestUrl, { headers })
  if (!res.ok || disableRequests) {
    throw new Error('Failed to resolve extra_props_source', requestUrl)
  } else {
    const extraProps = await res.json()
    return extraProps
  }
}

export async function resolveExtraProps(postData = {}, authToken) {
  let missingData = false
  const posts = postData.posts || {}
  const postEntries = await Promise.all(
    Object.entries(posts).map(([id, post]) => {
      if (post && post.props && post.props.extra_props_source) {
        return getExtraProps(post.props.extra_props_source, authToken)
          .then((extraProps) => [
            id,
            {
              ...post,
              props: {
                ...post.props,
                ...extraProps,
                extra_props_source: undefined,
                missingData: undefined,
                missingDataReason: undefined,
              },
            },
          ])
          .catch((err) => {
            missingData = true
            return [
              id,
              {
                ...post,
                props: {
                  ...post.props,
                  extra_props_source: undefined,
                  missingData: true,
                  missingDataReason: err,
                },
              },
            ]
          })
      } else {
        return Promise.resolve([id, post])
      }
    })
  )

  return {
    ...postData,
    posts: (postData.posts && Object.fromEntries(postEntries)) || postData.posts,
    missingData,
  }
}
