import 'setimmediate' // https://github.com/necolas/react-native-web/issues/1891#issuecomment-887502705
import 'react-native-gesture-handler'
import { registerRootComponent } from 'expo'
import { initNetworkLogging, initSentry } from 'APP/Lib/Initializers'

import App from 'APP/Containers/App'
// Init before app loads
initSentry()
initNetworkLogging()

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in the Expo client or in a native build,
// the environment is set up appropriately
registerRootComponent(App)
