import React from 'react'

import Analytics from 'APP/Services/Analytics'
import I18n from 'APP/Services/i18n'

import BottomDrawer, { BottomDrawerProps } from 'APP/Converse/BottomDrawer'
import { isWeb } from 'APP/Helpers/checkPlatform'
import Dialog from 'APP/Converse/Dialog'
interface IntakeCancelChatProps {
  isVisible: boolean
  onCancelChat: () => void
  onCloseModal: () => void
  onSaveChat: () => void
  isCancelling: boolean
}

const IntakeCancelChat = ({
  isVisible,
  onCancelChat,
  onCloseModal,
  onSaveChat,
  isCancelling,
}: IntakeCancelChatProps) => {
  const onClose = () => {
    Analytics.trackEvent('button_click', {
      button_value: 'Intake close cancel chat modal',
    })
    onCloseModal()
  }

  const secondaryButtonProps = {
    title: I18n.t('Intake.cancelChatModal.cancel'),
    analyticsName: 'Intake cancel chat',
    testID: 'intakeCancelChatModalCancel',
    isLoading: isCancelling,
    onPress: onCancelChat,
  }

  const primaryButtonProps = {
    title: I18n.t('Intake.cancelChatModal.cta'),
    analyticsName: 'Intake save chat',
    testID: 'intakeCancelChatModalCta',
    onPress: onSaveChat,
  }

  const cancelChatProps: BottomDrawerProps = {
    title: I18n.t('Intake.cancelChatModal.title'),
    subtitle: I18n.t('Intake.cancelChatModal.subtitle'),
    isVisible,
    onClose,
    testId: 'intakeCancelChatModal',
    closeButtonProps: {
      analyticsName: 'Intake close cancel chat',
    },
    primaryButtonProps,
    secondaryButtonProps,
  }

  const Component = isWeb() ? Dialog : BottomDrawer

  return <Component {...cancelChatProps} />
}

export default IntakeCancelChat
