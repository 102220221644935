import { StyleSheet } from 'react-native'
import Color from 'color'

import { Colors, Metrics, Fonts } from 'APP/Themes'

export const Styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    flexGrow: 1,
    width: null,
  },
  textInput: {
    ...Fonts.style.subheader,
    flex: 1,
    borderBottomWidth: 0,
    color: Colors.text,
  },
  textInputContainer: {
    borderBottomWidth: 0,
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    borderRadius: 30,
    marginLeft: Metrics.baseMargin * 0.6,
    paddingHorizontal: Metrics.baseMargin * 0.6,
    paddingVertical: 2,
    backgroundColor: Color(Colors.text).alpha(0.05).rgb().string(),
  },
})
