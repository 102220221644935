import { createActions, createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'

const DEEPLINK_EXPIRY_HOURS = 1

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setReadyToProcess: ['ready'],
  setDeferredDeeplink: ['path', 'props'],
  rejectDeferredDeeplink: ['reason', 'path', 'props'],
  processDeferredDeeplink: null,
  processDeferredDeeplinkSuccess: null,
  processDeferredDeeplinkFailure: ['error'],
  processDeferredDeeplinkExpired: null,
  setDeeplinkPath: ['path'],
})

export const INITIAL_STATE = Immutable({
  readyToProcess: false,
  path: null,
  props: {},
  deferredLinkRejected: false,
  reason: null,
})

export const setReadyToProcess = (state, { ready }) =>
  state.merge({
    readyToProcess: ready,
  })

export const setDeeplinkPath = (state, { path }) => state.merge({ path })

export const setDeferredDeeplink = (state, { path, props }) => {
  let expiry = new Date()
  expiry.setHours(expiry.getHours() + DEEPLINK_EXPIRY_HOURS)

  return state.merge(
    {
      path,
      props,
      deferredLinkRejected: false,
      reason: null,
      expiry,
    },
    { deep: true }
  )
}

export const clearDeferredDeeplinkState = (state) => state.merge({ path: null, props: {} })

export const rejectDeferredDeeplink = (state, { reason }) =>
  clearDeferredDeeplinkState(state).merge({ deferredLinkRejected: true, reason })

export const DeeplinkTypes = Types

export default Creators

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_READY_TO_PROCESS]: setReadyToProcess,
  [Types.SET_DEFERRED_DEEPLINK]: setDeferredDeeplink,
  [Types.SET_DEEPLINK_PATH]: setDeeplinkPath,
  [Types.PROCESS_DEFERRED_DEEPLINK_SUCCESS]: clearDeferredDeeplinkState,
  [Types.PROCESS_DEFERRED_DEEPLINK_FAILURE]: clearDeferredDeeplinkState,
  [Types.PROCESS_DEFERRED_DEEPLINK_EXPIRED]: clearDeferredDeeplinkState,
  [Types.REJECT_DEFERRED_DEEPLINK]: rejectDeferredDeeplink,
})
