import { StyleSheet } from 'react-native'
import { ApplicationStyles, Metrics, Colors, Fonts } from '../../../Themes'

export default StyleSheet.create({
  container: {
    flex: 1,
    padding: Metrics.baseMargin * 0.5,
    paddingTop: Metrics.statusBarHeight + Metrics.baseMargin,
    backgroundColor: Colors.appBg,
  },
  backButton: {
    color: Colors.text,
    zIndex: 2,
    paddingHorizontal: Metrics.baseMargin * 0.5,
  },
  backIcon: {
    tintColor: Colors.text,
  },
  title: {
    ...Fonts.style.h1,
    color: Colors.text,
    paddingTop: Metrics.baseMargin * 2,
    paddingBottom: Metrics.baseMargin,
    paddingHorizontal: Metrics.baseMargin * 1,
  },
  list: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: Metrics.baseMargin * 0.5,
    paddingBottom: Metrics.bottomSpace + Metrics.baseMargin,
  },
  tileContainer: {
    flexBasis: '50%',
    alignSelf: 'stretch',
    padding: Metrics.baseMargin / 2,
  },
  tile: {
    flex: 1,
    backgroundColor: Colors.elementsBg,
    borderRadius: 16,
    ...ApplicationStyles.shadow,
  },
  image: {
    height: 98,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  teamName: {
    ...Fonts.style.h4,
    color: Colors.text,
    padding: Metrics.baseMargin,
    paddingTop: Metrics.baseMargin * 0.75,
  },
  loading: {
    backgroundColor: Colors.elementsBg,
    position: 'absolute',
    borderRadius: 16,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.8,
  },
})
