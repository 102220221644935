import React, { useEffect, useRef, useState } from 'react'
import { TouchableOpacity, Animated } from 'react-native'
import { ExpandProps } from 'APP/Converse/Expand/types'

export const Expand = ({
  collapseTriggerContent,
  children,
  onExpand,
  expandToHeight,
  expanded,
}: ExpandProps) => {
  const [isExpanded, setIsExpanded] = useState(true)
  const [height] = useState(new Animated.Value(0))
  const ref = useRef()

  const contentHeight = ref.current?.scrollHeight || expandToHeight

  useEffect(() => {
    Animated.timing(height, {
      toValue: isExpanded ? contentHeight : 0,
      duration: 150,
      useNativeDriver: false,
    }).start()
  }, [isExpanded, height, contentHeight])

  useEffect(() => {
    setIsExpanded(!!expanded)
  }, [expanded])

  const onPress = () => {
    if (onExpand) onExpand(!isExpanded)
    else setIsExpanded(!isExpanded)
  }

  return (
    <TouchableOpacity onPress={onPress}>
      {collapseTriggerContent}
      <Animated.View style={{ maxHeight: height, overflow: 'hidden' }} ref={ref}>
        {children}
      </Animated.View>
    </TouchableOpacity>
  )
}
