import React, { useMemo } from 'react'
import { ScrollView, View } from 'react-native'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import moment from 'moment-timezone'
import { Immutable } from 'immer'
import styled from 'styled-components/native'

import { DATE_FORMAT } from 'APP/Redux/AppointmentBookingRedux'
import I18n from 'APP/Services/i18n'
import { Colors } from 'APP/Themes'
import Typography from 'APP/Converse/Typography'

import DayTab from './dayTab'

import { Availabilities } from '../utils/types'
import { createDays } from '../utils/helpers'

const DayTabWrapper = styled.View`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 12px;
`

const StyledDot = styled(MaterialIcon)<{
  $hasAvailabilities: boolean
}>`
  opacity: ${(props) => (props.$hasAvailabilities ? 1 : 0)};
`

const TitleWrapper = styled.View`
  text-transform: uppercase;
  background-color: ${Colors.selectedBg};
  padding: 12px;
  margin: 4px 4px 0 4px;
`

interface Props {
  dateFrom: moment.Moment
  dateUntil: moment.Moment
  selectedDate: moment.Moment
  onSelect: (day: moment.Moment) => void
  getAvailabilities: (day: moment.Moment) => void
  availabilities: Immutable<Availabilities>
}

export const DayPicker: React.FC<Props> = ({
  dateFrom,
  dateUntil,
  onSelect,
  selectedDate,
  getAvailabilities,
  availabilities,
  ...rest
}) => {
  const calendarFormat = I18n.t('AppointmentBooking.timeslot_formats', { returnObjects: true })
  const days = useMemo(() => createDays(dateFrom, dateUntil), [dateFrom, dateUntil])

  const daysButtons = days.map((day) => {
    const formattedSelectedDay = day.format(DATE_FORMAT)
    const availabilitiesForSelectedDay = availabilities[formattedSelectedDay] || {}
    const { hasAvailabilities } = availabilitiesForSelectedDay
    return (
      <DayTabWrapper key={formattedSelectedDay}>
        <DayTab
          day={day}
          onSelect={() => onSelect(day)}
          selected={day.isSame(selectedDate, 'day')}
          getAvailabilities={getAvailabilities}
          hasAvailabilities={hasAvailabilities}
        />
        <StyledDot
          name="fiber-manual-record"
          size={12}
          $hasAvailabilities={hasAvailabilities}
          color={Colors.acceptButtonBg}
        />
      </DayTabWrapper>
    )
  })

  return (
    <View {...rest}>
      <TitleWrapper>
        <Typography variant="bodyNormalBold" align="center" style={{ textTransform: 'uppercase' }}>
          {`${selectedDate.calendar(calendarFormat)}`}
        </Typography>
      </TitleWrapper>
      <ScrollView
        horizontal
        centerContent
        contentContainerStyle={{ paddingTop: 4, paddingBottom: 0, paddingHorizontal: 6 }}
      >
        {daysButtons}
      </ScrollView>
    </View>
  )
}

export default DayPicker
