import { StyleSheet } from 'react-native'
import { Fonts, Colors, Metrics } from 'APP/Themes'

export default StyleSheet.create({
  body: {
    ...Fonts.style.body,
    color: Colors.text,
  },
  p: {
    ...Fonts.style.bodySmall,
    marginTop: 0,
    marginBottom: Metrics.baseMargin / 2,
    lineHeight: Metrics.baseMargin * 1.25,
    color: Colors.text,
  },
  a: {
    color: Colors.link,
    fontWeight: 'bold',
    textDecorationLine: 'underline',
  },
  blockquote: {
    backgroundColor: Colors.contrastElementBg,
    borderLeftWidth: 4,
    borderLeftColor: Colors.accent,
    marginTop: 0,
    marginLeft: Metrics.baseMargin / 2,
    marginBottom: Metrics.baseMargin / 2,
    marginRight: 0,
    paddingTop: Metrics.baseMargin / 2,
    paddingLeft: Metrics.baseMargin / 2,
    paddingBottom: 4,
  },
  code: {
    fontFamily: Fonts.type.base,
    backgroundColor: Colors.bannerBg,
  },
  hr: {
    marginBottom: 10,
  },
  li: {
    marginLeft: 4,
    marginBottom: 4,
    color: Colors.text,
    fontWeight: '300',
  },
  ul: {
    marginTop: 0,
    marginBottom: Metrics.baseMargin / 2,
    marginLeft: 0,
  },
  ol: {
    marginTop: 0,
    marginBottom: Metrics.baseMargin / 2,
    listStyleType: 'decimal',
  },
  h1: {
    ...Fonts.style.h1,
    marginTop: 0,
    marginBottom: Metrics.baseMargin / 2,
    color: Colors.text,
  },
  h2: {
    ...Fonts.style.h2,
    marginTop: 0,
    marginBottom: Metrics.baseMargin / 2,
    color: Colors.text,
  },
  h3: {
    ...Fonts.style.h3,
    marginTop: 0,
    marginBottom: Metrics.baseMargin / 2,
    color: Colors.text,
  },
  h4: {
    ...Fonts.style.h4,
    marginTop: 0,
    marginBottom: Metrics.baseMargin / 2,
    color: Colors.text,
  },
  h5: {
    ...Fonts.style.subheader,
    marginTop: 0,
    marginBottom: Metrics.baseMargin / 2,
    color: Colors.text,
  },
  h6: {
    ...Fonts.style.subheader,
    marginTop: 0,
    marginBottom: Metrics.baseMargin / 2,
    color: Colors.text,
  },
  imageWrapper: { alignItems: 'center', height: 180 },
  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
  emphasis: {
    fontWeight: 'bold',
  },
})
