import React, { useCallback, useEffect } from 'react'
import { Pressable } from 'react-native'
import styled from 'styled-components/native'
import moment from 'moment-timezone'

import I18n from 'APP/Services/i18n'
import Typography from 'APP/Converse/Typography'
import { Colors } from 'APP/Themes'

interface Props {
  day: moment.Moment
  selected: boolean
  onSelect: (day: moment.Moment) => void
  getAvailabilities: (day: moment.Moment) => void
  hasAvailabilities: boolean
}

const StyledPressable = styled(Pressable)<{
  selected: boolean
}>`
  border-radius: 6px;
  margin: 4px;
  padding: 12px 8px;
  background-color: ${({ selected }) => (selected ? Colors.buttonPrimary : 'transparent')};
  ${({ theme, selected }) => (selected ? theme.shadows.normal : null)};
  width: 50px;
`

const StyledTitle = styled(Typography)<{ hasAvailabilities: boolean; selected: boolean }>`
  color: ${({ selected }) => (selected ? Colors.buttonSecondarySolid : Colors.text)};
  text-align: center;
`

export const DayTab: React.FC<Props> = ({
  onSelect,
  day,
  getAvailabilities,
  hasAvailabilities,
  selected,
}) => {
  const ariaLabel = hasAvailabilities
    ? I18n.t('AppointmentBooking.appointments_available')
    : I18n.t('AppointmentBooking.no_availability_today')

  const handlePress = useCallback(
    /* eslint-disable-next-line */
    (day: any) => {
      onSelect(day)
    },
    [onSelect]
  )

  useEffect(() => {
    /* eslint-disable */
    getAvailabilities(day)
  }, [])

  const DayLabel = (
    <>
      <StyledTitle
        variant={hasAvailabilities ? 'bodyNormalBold' : 'bodyNormal'}
        hasAvailabilities={hasAvailabilities}
        selected={selected}
      >
        {day.format('ddd')}
      </StyledTitle>
      <StyledTitle
        variant={hasAvailabilities ? 'bodyNormalBold' : 'bodyNormal'}
        hasAvailabilities={hasAvailabilities}
        selected={selected}
      >
        {day.format('DD')}
      </StyledTitle>
    </>
  )

  return (
    <StyledPressable
      aria-label={`${day.format('LL')} ${ariaLabel}`}
      onPress={handlePress}
      selected={selected}
    >
      {DayLabel}
    </StyledPressable>
  )
}

export default DayTab
