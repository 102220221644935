import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Metrics, Fonts } from '../../Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  buttonBar: {
    flexDirection: 'row',
    justifyContent: 'center',
    borderTopColor: Colors.grayBg,
    borderTopWidth: 1,
    paddingBottom: Metrics.bottomSpace,
    display: 'flex',
  },
  button: {
    flex: 1,
  },
  noRightSpacing: {
    marginRight: 0,
  },
  consentButton: {
    marginHorizontal: Metrics.baseMargin,
    alignSelf: 'center',
  },
  consentButtonText: {
    ...Fonts.style.body,
    color: Colors.text,
  },
  disagreeButtonText: {
    ...Fonts.style.body,
    color: Colors.text,
  },
  responsiveWebView: {
    flex: 1,
    width: '100%',
    maxWidth: 800,
    alignSelf: 'center',
  },
})
