import React, { useCallback, useEffect, useLayoutEffect, useState, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ActivityIndicator,
  SafeAreaView,
  Image,
  View,
  Text,
  Animated,
  Modal as RNModal,
} from 'react-native'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import moment from 'moment'
import { WellnessCenter } from '@dialogue/services'
import WellnessCenterActions from 'APP/Redux/WellnessCenterRedux'
import I18n from 'APP/Services/i18n'
import sprigClient from 'APP/Services/Sprig'
import {
  CHALLENGE_ACTIVITY_ICONS,
  CHALLENGE_TYPE_ICONS,
  goalMetric,
  challengeOrganizationName,
} from 'APP/Components/Challenge_legacy/constants'
import { useShareDeepLinkFromConfig } from 'APP/Hooks/Share'

import ButtonMoreAction from 'APP/Components/ButtonMoreAction'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import Modal from 'APP/Components/Modal'
import { SecondaryButton } from 'APP/Components/Buttons'
import Analytics from 'APP/Services/Analytics'
import Leaderboard from './Leaderboard'
import ProgressTracker from './ProgressTracker'
import JoinButton from './JoinButton'
import TeamList from './TeamList'
import Styles from './style'
import { Colors, Metrics } from 'APP/Themes'
import DialogueMarkdown from 'APP/Components/DialogueMarkdown'
import { ResponsiveScrollView } from 'APP/Converse/Layout'
import { isMobile } from 'APP/Helpers/checkPlatform'

const { Challenges } = WellnessCenter

const ANALYTICS = {
  PAGE_VIEWED_NOT_JOINED: 'WELLNESS_CENTER_CHALLENGE_PAGE_VIEWED_NOT_JOINED',
  PAGE_VIEWED_JOINED: 'WELLNESS_CENTER_CHALLENGE_PAGE_VIEWED_JOINED',
}

const WellnessCenterChallengeScreen = (props) => {
  const { navigation, route } = props
  const { id, refresh } = { ...route?.params, ...props }
  const dispatch = useDispatch()

  const {
    loading,
    leaving,
    retry,
    challenge,
    participants,
    inChallenge,
    userHasTracker,
    activeLeaderboardItem,
    userHasNoCompatibleTrackers,
  } = useSelector((state) => state.wellnessCenter.selectedChallenge)
  const { eligibilityIntervals } = useSelector((state) => state.patient.profile)

  const mainCtaAnimation = useRef(new Animated.Value(1)).current
  const challengeIsNotComplete = challenge.status !== Challenges.Types.ChallengeStatus.COMPLETE
  const challengeIsUpcoming = challenge.status === Challenges.Types.ChallengeStatus.UPCOMING
  const challengeIsOngoing = challenge.status === Challenges.Types.ChallengeStatus.ONGOING
  const challengeIsTeam = challenge.type === Challenges.Types.ChallengeType.TEAM
  const selectedTeam = useSelector((state) => state.wellnessCenter.selectedTeam)
  const [countdown, setCountdown] = useState('')
  const [mainCtaIsVisible, setMainCtaIsVisible] = useState(challengeIsNotComplete && !inChallenge)

  const [leavingChallengeModalIsVisible, setLeavingChallengeModalIsVisible] = useState(false)
  const [teamModalIsVisible, toggleTeamModal] = useState(false)

  const ActivityIcon = CHALLENGE_ACTIVITY_ICONS[challenge.activity]
  const TypeIcon = CHALLENGE_TYPE_ICONS[challenge.type]
  const analyticsName = 'wcChallenge'
  const organizationName = challengeOrganizationName(challenge.tags, eligibilityIntervals)

  useEffect(() => {
    getChallenge()
    return function () {
      resetChallenge()
    }
  }, [id])

  const getTeamParticipants = () =>
    dispatch(WellnessCenterActions.getTeamParticipants(challenge.id, activeLeaderboardItem.team))

  useEffect(() => {
    if (challenge?.id && activeLeaderboardItem?.team) {
      getTeamParticipants()
    }
  }, [challenge, activeLeaderboardItem])

  // Initial check: once challenge has been loaded, determine the correct CTA visibility
  useEffect(() => {
    // When challenge is done loading, re-check
    if (!loading) {
      setCtaVisibility()
    }
  }, [loading])

  // Analytics page view events
  useEffect(() => {
    if (!loading && retry) {
      Analytics.trackScreen(analyticsName)
    }

    if (!loading && !retry) {
      Analytics.trackScreen(
        `${analyticsName} : ${challenge.id} : ${challenge.content?.title} : ${
          inChallenge ? 'Joined' : 'Not joined'
        }`
      )

      if (inChallenge) {
        sprigClient.track(ANALYTICS.PAGE_VIEWED_JOINED)
      } else {
        sprigClient.track(ANALYTICS.PAGE_VIEWED_NOT_JOINED)
      }
    }
  }, [loading, retry, challenge.id, challenge.content, inChallenge])

  useEffect(() => {
    getTrackers()
  }, [])

  // Refresh challenge & tracker data
  useEffect(() => {
    if (refresh) {
      getChallenge()
      getTrackers()
      navigation.setParams({ refresh: false })
    }
  }, [refresh])

  const needsTrackerSyncBrokenAlert = useMemo(() => {
    if (
      !userHasTracker ||
      userHasNoCompatibleTrackers ||
      !inChallenge ||
      !activeLeaderboardItem.user ||
      !challengeIsOngoing
    ) {
      return false
    }

    const p = challengeIsTeam ? selectedTeam?.participants : participants
    const participant = (p || []).find(({ user }) => activeLeaderboardItem.user === user.id)

    if (!participant) {
      return false
    }

    if (!participant.last_sync_time) {
      return false
    }

    const joinedMoreThan24HAgo = moment(participant.date_joined).isSameOrBefore(
      moment().subtract('1', 'days')
    )
    return joinedMoreThan24HAgo && participant.total_value <= 0
  }, [
    challengeIsOngoing,
    challengeIsTeam,
    participants,
    selectedTeam,
    inChallenge,
    activeLeaderboardItem,
    userHasNoCompatibleTrackers,
    userHasTracker,
  ])

  // If inChallenge changes, determine correct CTA visibility
  // NOTE: This check can ONLY happen for team challenges, otherwise
  // the CTA toggle overrides the animation (this.ctaVisibilityToggle)
  useEffect(() => {
    const isTeamChallenge = challenge.type === Challenges.Types.ChallengeType.TEAM
    if (isTeamChallenge && inChallenge) setCtaVisibility()
  }, [challenge.type, inChallenge])

  useEffect(() => {
    // Countdown setup logic for ongoing challenges
    let intervalCall

    if (challenge.id && challenge.status === Challenges.Types.ChallengeStatus.ONGOING) {
      const endDate = moment.utc(challenge.end_date)
      const now = moment()
      const diff = endDate.format('x') - now.format('x')

      if (diff > 0) {
        var duration = moment.duration(diff, 'milliseconds')
        var interval = 1000

        // Capture first second
        countdownTimer(duration)

        // Update every second moving forward
        intervalCall = setInterval(function () {
          duration = moment.duration(duration - interval, 'milliseconds')
          countdownTimer(duration)
        }, interval)
      }
    }

    return function cleanup() {
      clearInterval(intervalCall)
    }
  }, [challenge.id, challenge.status, challenge.end_date])

  const setCtaVisibility = () => {
    setMainCtaIsVisible(challengeIsNotComplete && !inChallenge)
  }

  const getChallenge = () => dispatch(WellnessCenterActions.getChallenge(id))
  const resetChallenge = () => dispatch(WellnessCenterActions.resetChallenge())
  const getTrackers = () => dispatch(WellnessCenterActions.getTrackers())

  const leaveSelectedChallenge = () => dispatch(WellnessCenterActions.leaveSelectedChallenge())

  const countdownTimer = (time) => {
    const safelyPrependZero = (value) => {
      if (value < 10) return `0${value}`

      return value
    }

    const days = safelyPrependZero(time.days())
    const hours = safelyPrependZero(time.hours())
    const minutes = safelyPrependZero(time.minutes())
    const seconds = safelyPrependZero(time.seconds())

    setCountdown(`${days} : ${hours} : ${minutes} : ${seconds}`)
  }

  const ctaVisibilityToggle = () => {
    Animated.timing(mainCtaAnimation, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start(() => {
      setMainCtaIsVisible(false)
      mainCtaAnimation.setValue(1)
    })
  }

  const shareChallengeInvite = useShareDeepLinkFromConfig(
    'WC_CHALLENGE_SCREEN_DEEP_LINK_URL',
    {
      services: 'wellness_challenges',
      id,
    },
    { analyticsName }
  )

  useLayoutEffect(() => {
    const headerRight = () => {
      if (challenge.status === Challenges.Types.ChallengeStatus.COMPLETE) {
        return null
      }

      return (
        <ButtonMoreAction
          key="more"
          analyticsName={analyticsName}
          actions={[
            {
              value: I18n.t('WellnessCenter.trackers.settings'),
              handler() {
                setupTracker()
              },
            },
            {
              value: I18n.t('WellnessCenter.challenges.invite'),
              handler() {
                shareChallengeInvite.share()
              },
              enable: isMobile(),
            },
            {
              value: I18n.t('WellnessCenter.challenges.leave'),
              enable: [inChallenge],
              destructive: true,
              handler() {
                setLeavingChallengeModalIsVisible(true)
              },
            },
            {
              value: I18n.t('WellnessCenter.challenges.cancel'),
              cancellable: true,
              enable: true,
            },
          ]}
        />
      )
    }

    navigation.setOptions({ headerRight })
  }, [
    shareChallengeInvite.message,
    shareChallengeInvite.url,
    challenge.status,
    inChallenge,
    navigation,
    shareChallengeInvite,
  ])

  const setupTracker = useCallback(() => {
    navigation.navigate('healthTrackerSettings')
  })

  return (
    <NoticeBarContainer>
      <ResponsiveScrollView>
        {loading && (
          <ActivityIndicator
            size="large"
            color={Colors.text}
            style={Styles.loading}
            testID="loading"
          />
        )}
        {!loading && challenge.id && (
          <SafeAreaView>
            <ResponsiveScrollView>
              <Image
                style={Styles.image}
                source={{ uri: challenge.content?.image }}
                resizeMode="cover"
              />
              <View style={Styles.body}>
                {challenge.activity && challenge.type && (
                  <View style={Styles.tagList}>
                    <View key="activity" style={Styles.tag} testID="activityTag">
                      <ActivityIcon.Lib
                        name={ActivityIcon.name}
                        size={Metrics.icons.tiny}
                        style={Styles.tagIcon}
                      />
                      <Text style={Styles.tagCopy}>
                        {I18n.t(`WellnessCenter.challenges.activity.${challenge.activity}`)}
                      </Text>
                    </View>
                    <View key="type" style={Styles.tag} testID="typeTag">
                      <TypeIcon.Lib
                        name={TypeIcon.name}
                        size={Metrics.icons.tiny}
                        style={Styles.tagIcon}
                      />
                      <Text style={Styles.tagCopy}>
                        {I18n.t(`WellnessCenter.challenges.type.${challenge.type}`)}
                      </Text>
                    </View>
                  </View>
                )}

                <Text style={Styles.title} testID="title">
                  {challenge.content?.title}
                </Text>
                <Modal
                  statusBarTranslucent
                  visible={leavingChallengeModalIsVisible}
                  title={I18n.t('WellnessCenter.challenges.leaveModal.title')}
                  subtitle={I18n.t('WellnessCenter.challenges.leaveModal.subtitle')}
                  transparent
                  primaryActionText={I18n.t('WellnessCenter.challenges.leaveModal.primary')}
                  primaryActionDisabled={leaving}
                  handlePrimaryAction={() => setLeavingChallengeModalIsVisible(false)}
                  secondaryActionText={I18n.t('WellnessCenter.challenges.leaveModal.secondary')}
                  secondaryActionColor={Colors.accent}
                  secondaryActionRunning={leaving}
                  handleSecondaryAction={() => {
                    leaveSelectedChallenge()
                    setLeavingChallengeModalIsVisible(false)
                  }}
                  handleCloseModal={() => setLeavingChallengeModalIsVisible(false)}
                />
                <View style={Styles.timeBlock}>
                  <MaterialIcon
                    name="schedule"
                    size={Metrics.icons.tiny}
                    color={Colors.accent}
                    style={Styles.clockIcon}
                  />
                  <Text style={Styles.time} testID="timeCopy">
                    {challenge.status === Challenges.Types.ChallengeStatus.UPCOMING &&
                      I18n.t('WellnessCenter.challenges.countdown.fullUpcoming')}
                    {challenge.status === Challenges.Types.ChallengeStatus.ONGOING &&
                      I18n.t('WellnessCenter.challenges.countdown.fullOngoing')}
                    {challenge.status === Challenges.Types.ChallengeStatus.COMPLETE &&
                      `${moment.utc(challenge.start_date).format('MMMM D, YYYY')} - ${moment
                        .utc(challenge.end_date)
                        .format('MMMM D, YYYY')}`}
                  </Text>
                  <Text style={Styles.timeBold} testID="timeValue">
                    {challenge.status === Challenges.Types.ChallengeStatus.UPCOMING &&
                      moment.utc(challenge.start_date).fromNow(true)}
                    {challenge.status === Challenges.Types.ChallengeStatus.ONGOING && countdown}
                  </Text>
                </View>

                <Text style={Styles.description} testID="description">
                  {challenge.content?.description}
                </Text>

                {organizationName && (
                  <Text style={Styles.org} testID="org">
                    {I18n.t('WellnessCenter.challenges.broughtBy')} {organizationName}
                  </Text>
                )}

                <View style={mainCtaIsVisible || !userHasTracker ? Styles.ctaWrapper : {}}>
                  {mainCtaIsVisible && (
                    <Animated.View style={{ elevated: 2, zIndex: 2, opacity: mainCtaAnimation }}>
                      <JoinButton
                        style={Styles.joinCta}
                        setVisibility={ctaVisibilityToggle}
                        toggleTeamModal={toggleTeamModal}
                      />
                    </Animated.View>
                  )}

                  {!userHasTracker && (
                    <View style={Styles.trackerCta} testID="trackerCta">
                      <Text style={Styles.trackerCopy}>
                        {I18n.t('WellnessCenter.trackers.cta')}
                      </Text>
                      <SecondaryButton style={Styles.trackerButton} onPress={setupTracker}>
                        {I18n.t('WellnessCenter.trackers.settings')}
                      </SecondaryButton>
                    </View>
                  )}
                  {needsTrackerSyncBrokenAlert && (
                    <View style={Styles.trackerCta} testID="trackerSyncBrokenAlert">
                      <Text style={Styles.trackerTitle}>
                        {I18n.t('WellnessCenter.challenges.trackerSyncBrokenAlert.title', {
                          metric: I18n.t(
                            `WellnessCenter.challenges.metric.${challenge.metric}`
                          ).toLowerCase(),
                        })}
                      </Text>
                      <DialogueMarkdown
                        markdown={I18n.t(
                          'WellnessCenter.challenges.trackerSyncBrokenAlert.markdown'
                        )}
                      />
                    </View>
                  )}
                  {userHasNoCompatibleTrackers && (
                    <View style={Styles.trackerCta} testID="trackerCta">
                      <Text style={Styles.trackerTitle}>
                        {I18n.t('WellnessCenter.trackers.noneCompatible.title')}
                      </Text>
                      <Text style={Styles.trackerCopy}>
                        {I18n.t('WellnessCenter.trackers.noneCompatible.copy')}
                      </Text>
                      <SecondaryButton style={Styles.trackerButton} onPress={setupTracker}>
                        {I18n.t('WellnessCenter.trackers.settings')}
                      </SecondaryButton>
                    </View>
                  )}
                </View>
              </View>

              <View style={Styles.metrics}>
                {challenge.participant_goal > 0 && <ProgressTracker />}
                <Leaderboard
                  title={
                    challengeIsUpcoming
                      ? `${I18n.t(
                          `WellnessCenter.challenges.${
                            !challengeIsTeam ? 'participants' : 'participantTeams'
                          }`
                        )} (${
                          challengeIsTeam ? participants.length : challenge.participants_count
                        })`
                      : I18n.t('WellnessCenter.challenges.leaderboard')
                  }
                  subtitle={goalMetric(
                    challenge.status,
                    challenge.metric,
                    true,
                    challenge?.type === Challenges.Types.ChallengeType.TEAM
                  )}
                  joinChallenge={ctaVisibilityToggle}
                />
              </View>

              <RNModal
                animationType="slide"
                visible={teamModalIsVisible}
                onRequestClose={() => toggleTeamModal(false)}
                testID="teamsModal"
              >
                <TeamList goBack={() => toggleTeamModal(false)} />
              </RNModal>
            </ResponsiveScrollView>
          </SafeAreaView>
        )}
        {!loading && retry && (
          <DialogueTouchableOpacity
            style={Styles.refresh}
            onPress={getChallenge}
            analyticsName="Refresh"
            testID="retry"
          >
            <MaterialIcon name="refresh" size={Metrics.icons.large} color={Colors.text} />
          </DialogueTouchableOpacity>
        )}
      </ResponsiveScrollView>
    </NoticeBarContainer>
  )
}

export default WellnessCenterChallengeScreen
