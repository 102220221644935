import styled from 'styled-components/native'
import Typography from 'APP/Converse/Typography'
import { Images } from 'APP/Themes'
import { TruncatedTypography } from 'APP/Converse/Typography/TruncatedTypography'
import { Provider, ProviderDetailsData } from 'APP/Store/ProviderBooking/types'
import { Colors } from 'APP/Themes'
import I18n from 'APP/Services/i18n'
import { View } from 'react-native'

const Wrapper = styled.View`
  padding: ${({ theme }) => theme.metrics.baseMargin}px 0;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.disabled};
`

const InfoWrapper = styled.View`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const MainInfo = styled.View`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const ProfilePicture = styled.Image`
  width: 120px;
  height: 120px;
  border-radius: 200px;
  margin-right: ${({ theme }) => theme.metrics.baseMargin * 2}px;
`

const JobTitle = styled(Typography)`
  color: ${({ theme }) => theme.colors.dim};
  margin-top: ${({ theme }) => theme.metrics.baseMargin / 2}px;
`

const Bio = styled(TruncatedTypography)`
  margin-top: ${({ theme }) => theme.metrics.baseMargin}px;
`

const TagContainer = styled(View)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.metrics.baseMargin}px;
`

const TagWrapper = styled(View)`
  padding-top: ${({ theme }) => theme.metrics.baseMargin / 2}px
  padding-right: ${({ theme }) => theme.metrics.baseMargin * 0.75}px
  padding-bottom: ${({ theme }) => theme.metrics.baseMargin / 3}px
  padding-left: ${({ theme }) => theme.metrics.baseMargin * 0.75}px
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.tagBg}; 
  border-radius: ${({ theme }) => theme.metrics.borderRadius.medium};
  margin-right: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  margin-bottom: ${({ theme }) => theme.metrics.baseMargin / 2}px; 
`
const Tag = styled(Typography)`
  color: ${Colors.blackShadow};
`

const MAX_BIO_SYMBOLS = 120

interface Props {
  provider: Provider
  providerData: ProviderDetailsData | null
}

export const ProfileHeader = ({ provider, providerData }: Props) => {
  return (
    <Wrapper>
      <InfoWrapper>
        <ProfilePicture source={provider.picture ? { uri: provider.picture } : Images.logoNurse} />
        <MainInfo>
          <Typography variant="h3">
            {provider.givenName} {provider.familyName}
          </Typography>
          {provider.jobTitle && <JobTitle variant="bodyNormalBold">{provider.jobTitle}</JobTitle>}
        </MainInfo>
      </InfoWrapper>
      {providerData?.additionalInterests && (
        <TagContainer>
          {providerData.additionalInterests.map((interest: string, index: number) => (
            <TagWrapper key={index}>
              <Tag variant="bodySmall">
                {I18n.t(`AppointmentBooking.providerAdditionalInterests.${interest}`) || interest}
              </Tag>
            </TagWrapper>
          ))}
        </TagContainer>
      )}
      {provider.bio && <Bio text={provider.bio} maxSymbols={MAX_BIO_SYMBOLS} />}
    </Wrapper>
  )
}
