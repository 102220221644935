import React from 'react'
import { View, Text } from 'react-native'
import Svg, { G, Circle, Defs, LinearGradient, Stop, ClipPath, Rect } from 'react-native-svg'
import { Colors } from '../../Themes'
import Style from './style'

const ProgressBarCircle = ({
  percentage = 0,
  radius = 16.5,
  strokeWidth = 3,
  primaryColor = Colors.buttonLinen,
  primaryOpacity = 1,
  secondaryColor = Colors.habitDoneAccent,
  secondaryOpacity = 1,
  textColor = Colors.text,
  text,
  textFontSize,
  textStyles,
  testID = 'progressBar',
  children,
  gradientWhenFull = false,
}) => {
  const circleRadius = radius + strokeWidth
  const circleDiameter = circleRadius * 2
  const circleCircumference = 2 * Math.PI * radius
  const strokeDashoffset =
    circleCircumference - (circleCircumference * Math.min(percentage, 100)) / 100

  const shouldRenderFullStateInGradient = percentage >= 100 && gradientWhenFull

  const Gradient = () => (
    <Defs>
      <ClipPath id="cut-off">
        <Rect x="0%" y="50%" width={circleDiameter} height={circleDiameter} />
      </ClipPath>
      <LinearGradient id={'gradient'}>
        <Stop offset={'0%'} stopColor={secondaryColor} />
        <Stop offset={'100%'} stopColor={secondaryColor} stopOpacity="0.3" />
      </LinearGradient>
    </Defs>
  )

  return (
    <View testID={testID} style={Style.container}>
      <Svg
        width={circleDiameter}
        height={circleDiameter}
        viewBox={`0 0 ${circleDiameter}  ${circleDiameter}`}
      >
        <G rotation="-90" origin={`${circleRadius}, ${circleRadius}`}>
          <Circle
            cx="50%"
            cy="50%"
            r={radius}
            stroke={shouldRenderFullStateInGradient ? 'none' : primaryColor}
            strokeWidth={strokeWidth}
            fill="none"
            opacity={primaryOpacity}
            testID={`${testID}CirclePrimary`}
          />
          {!shouldRenderFullStateInGradient && strokeDashoffset !== circleCircumference && (
            <Circle
              cx="50%"
              cy="50%"
              r={radius}
              stroke={secondaryColor}
              strokeWidth={strokeWidth}
              fill="none"
              strokeDasharray={circleCircumference}
              strokeDashoffset={strokeDashoffset}
              opacity={secondaryOpacity}
              strokeLinecap="round"
              testID={`${testID}CircleSecondary`}
            />
          )}
          {shouldRenderFullStateInGradient && (
            <>
              <Gradient />
              <Circle
                cx="50%"
                cy="50%"
                r={radius}
                stroke="url(#gradient)"
                clipPath="url(#cut-off)"
                strokeWidth={strokeWidth}
                fill="none"
                opacity={secondaryOpacity}
                testID={`${testID}CircleSecondaryGradientPart`}
              />
              <Circle
                cx="50%"
                cy="50%"
                r={radius}
                stroke={secondaryColor}
                strokeWidth={strokeWidth}
                fill="none"
                strokeDasharray={circleCircumference}
                strokeDashoffset={-circleCircumference / 2}
                opacity={secondaryOpacity}
                strokeLinecap="round"
                testID={`${testID}CircleSecondaryNonGradientPart`}
              />
            </>
          )}
        </G>
      </Svg>
      {text && (
        <Text
          style={[
            Style.text,
            textStyles,
            {
              fontSize: textFontSize ?? radius / 2,
              color: textColor,
            },
          ]}
        >
          {text}
        </Text>
      )}
      {children && <View style={Style.children}>{children}</View>}
    </View>
  )
}

export default ProgressBarCircle
