import { createReducer, createActions } from 'reduxsauce'
import { isEqual } from 'lodash'
import Immutable from 'seamless-immutable'
import moment from 'moment'
/* ------------- Types and Action Creators ------------- */
const TODAY = moment()

export const DATE_FORMAT = 'MM/DD/YYYY'

export const PartsOfDay = {
  MORNING: 'morning',
  AFTERNOON: 'afternoon',
  EVENING: 'evening',
}

export const DEFAULT_TIMES = {
  [PartsOfDay.MORNING]: [],
  [PartsOfDay.AFTERNOON]: [],
  [PartsOfDay.EVENING]: [],
}

const { Types, Creators } = createActions({
  getAvailableAppointments: ['day', 'payload', 'dateUntil'],
  getAvailableAppointmentsSuccess: ['availabilities', 'hasAvailabilities', 'day'],
  getAvailableAppointmentsError: ['error', 'day'],

  selectDay: ['day'],
  selectAppointment: ['appointment'],

  bookAppointment: ['payload'],
  bookAppointmentSuccess: ['data'],
  bookAppointmentError: ['error'],
  resetBookAppointment: [],
})

export const AppointmentBookingTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  availabilities: {
    [TODAY.format(DATE_FORMAT)]: {
      times: {
        [PartsOfDay.MORNING]: [],
        [PartsOfDay.AFTERNOON]: [],
        [PartsOfDay.EVENING]: [],
      },
      isLoading: false,
      hasAvailabilities: false,
    },
  },
  selectedAppointment: null,
  selectedDay: TODAY.format(DATE_FORMAT),

  bookAppointmentRequest: {
    isLoading: false,
    data: null,
    error: null,
  },
})

/* ------------- Reducers ------------- */
export const getAvailableAppointments = (state, { day }) => {
  const formattedDay = day.format(DATE_FORMAT)

  // If day is already in the store, leave it as is but update it's loading state.
  if (state.availabilities[formattedDay]) {
    return state.merge({
      availabilities: {
        ...state.availabilities,
        [formattedDay]: {
          ...state.availabilities[formattedDay],
          isLoading: true,
        },
      },
    })
  }

  // Day is not captured yet, save it with default times.
  return state.merge({
    availabilities: {
      ...state.availabilities,
      [formattedDay]: {
        times: DEFAULT_TIMES,
        isLoading: true,
        hasAvailabilities: false,
      },
    },
  })
}

export const getAvailableAppointmentsSuccess = (
  state,
  { availabilities, hasAvailabilities, day }
) => {
  const formattedDay = day.format(DATE_FORMAT)

  return state.merge({
    availabilities: {
      ...state.availabilities,
      [formattedDay]: {
        times: availabilities,
        isLoading: false,
        hasAvailabilities,
      },
    },
  })
}

export const getAvailableAppointmentsError = (state, { error, day }) => {
  const formattedDay = day.format(DATE_FORMAT)

  return state.merge({
    availabilities: {
      ...state.availabilities,
      [formattedDay]: {
        isLoading: false,
        hasAvailabilities: false,
        times: DEFAULT_TIMES,
        errorProperties: error,
      },
    },
  })
}

export const selectAppointment = (state, { appointment }) =>
  state.merge({
    selectedAppointment: isEqual(state.selectedAppointment, appointment) ? null : appointment,
  })

export const selectDay = (state, { day }) =>
  state.merge({
    selectedDay: day,
  })

export const bookAppointment = (state) =>
  state.merge({ bookAppointmentRequest: { isLoading: true, data: null, error: null } })

export const bookAppointmentSuccess = (state, { data }) =>
  state.merge({ bookAppointmentRequest: { isLoading: false, data, error: null } })

export const bookAppointmentError = (state, { error }) =>
  state.merge({ bookAppointmentRequest: { isLoading: false, data: null, error } })

export const resetBookAppointment = (state) =>
  state.merge({ bookAppointmentRequest: { isLoading: false, data: null, error: null } })

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_AVAILABLE_APPOINTMENTS]: getAvailableAppointments,
  [Types.GET_AVAILABLE_APPOINTMENTS_SUCCESS]: getAvailableAppointmentsSuccess,
  [Types.GET_AVAILABLE_APPOINTMENTS_ERROR]: getAvailableAppointmentsError,

  [Types.SELECT_DAY]: selectDay,
  [Types.SELECT_APPOINTMENT]: selectAppointment,

  [Types.BOOK_APPOINTMENT]: bookAppointment,
  [Types.BOOK_APPOINTMENT_SUCCESS]: bookAppointmentSuccess,
  [Types.BOOK_APPOINTMENT_ERROR]: bookAppointmentError,
  [Types.RESET_BOOK_APPOINTMENT]: resetBookAppointment,
})
