import { combineReducers } from '@reduxjs/toolkit'
import { reducer as formReducer } from 'redux-form'
import features from './FeaturesSlice'
import { familyReducer } from 'APP/Store/Family'
import { contentReducer } from 'APP/Store/Content'
import { providerBookingReducer } from 'APP/Store/ProviderBooking'
import { videoSessionReducer } from 'APP/Store/VideoSession'
import { pharmaciesReducer } from 'APP/Store/Pharmacies'

const rootReducer = {
  // Non-persisted
  form: formReducer,
  appSession: require('./AppSessionRedux').reducer,
  deeplink: require('./DeeplinkRedux').reducer,
  features,
  wellnessCenter: require('./WellnessCenterRedux').reducer,
  habits: require('./HabitsRedux').reducer,
  accountLinking: require('./AccountLinkingRedux').reducer,
  activeMinutes: require('./ActiveMinutesRedux').reducer,
  wellBeingAssessment: require('./WellBeingRedux').reducer,
  appointmentBooking: require('./AppointmentBookingRedux').reducer,
  // Persisted
  app: require('./StartupRedux').reducer,
  login: require('./LoginRedux').reducer,
  patient: require('./PatientRedux').reducer,
  history: require('./PatientHistoryRedux').reducer,
  family: familyReducer,
  content: contentReducer,
  providerBooking: providerBookingReducer,
  notifications: require('./NotificationRedux').reducer,
  videoSession: videoSessionReducer,
  pharmacies: pharmaciesReducer,
}

export default combineReducers(rootReducer)
