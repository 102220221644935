import { useEffect, useState } from 'react'
import moment from 'moment'
import { ActiveMinutes } from '@dialogue/services'

const useCountdown = (challenge) => {
  const [countdown, setCountdown] = useState('')

  useEffect(() => {
    // Countdown setup logic for ongoing challenges
    let intervalCall

    if (!challenge) return

    if (challenge.id && challenge.status === ActiveMinutes.Types.ChallengeStatus.Ongoing) {
      const endDate = moment.utc(challenge.ends_at)
      const now = moment()
      const diff = endDate.format('x') - now.format('x')

      if (diff > 0) {
        var duration = moment.duration(diff, 'milliseconds')
        var interval = 1000

        // Capture first second
        countdownTimer(duration)

        // Update every second moving forward
        intervalCall = setInterval(function () {
          duration = moment.duration(duration - interval, 'milliseconds')
          countdownTimer(duration)
        }, interval)
      }
    }

    return function cleanup() {
      clearInterval(intervalCall)
    }
  }, [challenge])

  const countdownTimer = (time) => {
    const safelyPrependZero = (value) => {
      if (value < 10) return `0${value}`

      return value
    }

    const days = safelyPrependZero(time.days())
    const hours = safelyPrependZero(time.hours())
    const minutes = safelyPrependZero(time.minutes())
    const seconds = safelyPrependZero(time.seconds())

    setCountdown(`${days} : ${hours} : ${minutes} : ${seconds}`)
  }

  return countdown
}

export default useCountdown
