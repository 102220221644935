import { StyleSheet } from 'react-native'
import { Colors, ApplicationStyles, Metrics, Fonts } from 'APP/Themes'
import { isWeb } from 'APP/Helpers/checkPlatform'

let scrollViewContainer = {
  flex: 1,
  paddingHorizontal: Metrics.baseMargin,
}

if (!isWeb()) {
  scrollViewContainer = {
    ...scrollViewContainer,
    transform: [{ scaleY: -1 }], // Invert the scrollView with CSS because FlatList inverted has bad performance on mobile
  }
}

export const Styles = StyleSheet.create({
  ...ApplicationStyles.screen,
  container: {
    flex: 1,
  },
  scrollViewContainer,
  messagesContainer: {
    backgroundColor: Colors.appBg,
  },
  unreadMessagesContainer: {
    position: 'absolute',
    alignSelf: 'center',
    bottom: Metrics.baseMargin * 5,
    ...ApplicationStyles.shadow,
  },
  unreadMessagesButton: {
    ...ApplicationStyles.screen.primaryButton,
    height: 36,
    paddingHorizontal: Metrics.baseMargin / 2,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  unreadMessagesText: {
    ...Fonts.style.captionBold,
    color: Colors.textInverted,
  },
  unreadMessagesIcon: {
    color: Colors.textInverted,
  },
  loadingContainer: {
    ...ApplicationStyles.shadow,
    display: 'flex',
    position: 'absolute',
    margin: Metrics.baseMargin,
    padding: Metrics.baseMargin / 2,
    flexDirection: 'row',
    alignSelf: 'center',
    backgroundColor: Colors.appBg,
    borderRadius: 100,
    zIndex: 2,
  },
  loadingText: {
    paddingHorizontal: Metrics.baseMargin / 2,
    color: Colors.text,
  },
  contentContainerStyle: {
    paddingBottom: 75,
  },
  webViewPostContainer: {
    transform: [{ scaleY: -1 }],
  },
})
