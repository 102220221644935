import { DefaultPrivacyLevel } from '@datadog/browser-rum'
import Config from 'APP/Config/index'
import { version as packageJsonVersion } from '../../../package.json'

const usConfig = {
  site: 'datadoghq.com',
  applicationId: Config.DD_US_APPLICATION_ID as string,
  clientToken: Config.DD_US_CLIENT_TOKEN as string,
}

const euConfig = {
  site: 'datadoghq.eu',
  applicationId: Config.DD_EU_APPLICATION_ID as string,
  clientToken: Config.DD_EU_CLIENT_TOKEN as string,
}

export const sharedInitConfig = {
  env: (Config.ENVIRONMENT as string).toLowerCase(),
  service: 'member-app',
  defaultPrivacyLevel: DefaultPrivacyLevel.MASK,
  sessionReplaySampleRate: 100, // Enable replay on all sampled sessions
  traceSampleRate: 100, // Enable tracing for all requests
  telemetrySampleRate: 0,
  trackResources: true,
  trackUserInteractions: true,
  useSecureSessionCookie: true,
  version: packageJsonVersion,
  allowedTracingUrls: [/^(?!.*auth).*dialoguecorp/],
  ...(Config.ENVIRONMENT === 'PROD-EU' ? euConfig : usConfig),
}

export const globalContextConfig = {
  product_name: Config.PRODUCT_NAME,
}
