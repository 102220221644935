import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Metrics, Fonts } from '../../Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  container: {
    ...ApplicationStyles.screen.fullPrimaryContainer,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 0,
  },
  sickDialogue: {
    resizeMode: 'contain',
  },
  title: {
    ...Fonts.style.h4,
    color: Colors.text,
    width: '100%',
    textAlign: 'center',
    marginTop: Metrics.baseMargin * 2,
    fontWeight: '500',
  },
  text: {
    color: Colors.text,
    textAlign: 'center',
    marginTop: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin * 2,
    marginHorizontal: Metrics.baseMargin * 3,
  },
  fullWidthButton: {
    alignSelf: 'stretch',
    marginHorizontal: Metrics.baseMargin * 2,
  },
})
