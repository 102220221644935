import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Fonts, Metrics } from 'APP/Themes'

export const Styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  actionContainer: {
    borderRadius: 10,
    padding: Metrics.baseMargin,
    marginVertical: Metrics.baseMargin,
    backgroundColor: Colors.elementsBg,
    flexDirection: 'row',
    flex: 1,
    ...ApplicationStyles.shadows.shadowBottomLit,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  icon: {
    paddingRight: 12,
  },
  linkLabel: {
    ...Fonts.style.body,
    textTransform: 'none',
    fontWeight: '600',
  },
  action: {
    marginLeft: 'auto',
    justifyContent: 'center',
  },
})
