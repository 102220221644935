import { useState } from 'react'
import Analytics from 'APP/Services/Analytics'

export const useFilePicker = ({ submitCallback, fileInputRef }) => {
  const [file, setFile] = useState()
  const hasFile = !!file
  const fileTypes = ['image/jpeg', 'image/gif', 'image/png', 'image/jpg', 'application/pdf']

  const onFileChange = (e) => {
    const newFile = e.target?.files?.[0]
    setFile(newFile)
  }

  const isFileTypeValid = (fileType) => {
    return fileTypes.includes(fileType)
  }

  const openFilePicker = () => {
    fileInputRef?.current?.click()
  }

  const trackFilePicked = (file) => {
    const label = file?.name || ''
    const fileSize = file?.size || 0
    // 1,048,576 binary bytes for 1 megabyte
    const fileSizeInMB = (fileSize / 1048576).toFixed(2)
    const property = `${fileSizeInMB}MB`

    Analytics.trackStructuredEvent('Chat Action', 'File upload', label, property, 0)
  }

  const onSubmit = (fileObject) => {
    if (isFileTypeValid(fileObject.type)) {
      trackFilePicked(fileObject)
      submitCallback({
        file: fileObject,
        fileType: fileObject.type,
        fileName: fileObject.name,
      })
    }
  }

  const onSubmitPress = () => {
    if (hasFile) {
      onSubmit(file)
    }
  }

  const onFileChangeSubmit = (e) => {
    const newFile = e.target?.files?.[0]
    setFile(newFile)
    if (newFile) {
      onSubmit(newFile)
    }
  }

  const resetFile = () => setFile(null)

  return {
    openFilePicker,
    onFileChange,
    onFileChangeSubmit,
    onSubmitPress,
    file,
    hasFile,
    isFileTypeValid,
    resetFile,
  }
}
