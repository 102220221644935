import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Metrics, Fonts } from 'APP/Themes'

const text = {
  ...Fonts.style.body,
  color: Colors.text,
}

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  scrollContainer: {
    minHeight: '100%',
  },
  container: {
    flex: 1,
    paddingHorizontal: Metrics.baseMargin * 2,
    paddingBottom: Metrics.baseMargin,
  },
  notificationsAllowedContainer: {
    display: 'flex',
    width: '100%',
    alignSelf: 'center',
    paddingBottom: Metrics.baseMargin * 2,
  },
  text,
  description: {
    color: Colors.textSecondary,
  },
  textBulletPoint: {
    ...text,
    lineHeight: 21,
  },
  boldNotify: {
    ...text,
    lineHeight: 21,
    fontWeight: 'bold',
    paddingBottom: Metrics.baseMargin / 2,
  },
  bulletStyle: {
    color: Colors.text,
    marginLeft: Metrics.baseMargin,
  },
  imageSection: {
    paddingTop: 25,
    paddingBottom: 25,
    alignSelf: 'center',
  },
  primaryButton: {
    marginHorizontal: 0,
    marginBottom: Metrics.baseMargin,
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    minHeight: '100%',
  },
  notificationsHeader: {
    display: 'flex',
    width: '100%',
    alignSelf: 'center',
    gap: Metrics.baseMargin * 0.75,
    paddingHorizontal: Metrics.baseMargin * 1.5,
    paddingTop: Metrics.baseMargin * 1.5,
  },
  headerDivider: {
    marginTop: Metrics.baseMargin * 0.75,
    width: '100%',
    alignSelf: 'center',
  },
  toggleCardContainer: {
    gap: Metrics.baseMargin * 1.5,
    marginHorizontal: Metrics.baseMargin * 1.5,
  },

  notificationCenterContainer: {
    display: 'flex',
    width: '100%',
    alignSelf: 'center',
    paddingTop: Metrics.baseMargin * 2,
    paddingHorizontal: Metrics.baseMargin * 1.5,
    gap: Metrics.baseMargin / 2,
    paddingBottom: Metrics.baseMargin * 2,
  },
  notificationListContainer: {
    overflow: 'hidden',
    borderRadius: 8,
    borderColor: Colors.elementsBorder,
    borderWidth: 1,
    backgroundColor: Colors.elementsBorder,
  },
  divider: {
    marginVertical: Metrics.baseMargin * 0.75,
    width: '100%',
    alignSelf: 'center',
  },
  listItem: {
    paddingHorizontal: 12,
  },
})
