import { StyleSheet } from 'react-native'
import { ApplicationStyles, Metrics, Colors, Fonts } from 'APP/Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: Colors.appBg,
  },
  bodyPicker: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flex: 1,
  },
  bodyPickerInnerContainer: {
    paddingTop: 34,
    width: '100%',
  },
  button: {
    width: '45%',
    margin: 0,
    marginVertical: 0,
    marginHorizontal: 0,
    marginTop: 0,
    marginBottom: 0,
  },
  rightIconButton: {
    padding: 11,
    paddingRight: Metrics.baseMargin,
  },
  rightIcon: {
    height: 20,
    width: 20,
    tintColor: Colors.accent,
  },
  popoverOverlayButton: {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  popoverWindow: {
    backgroundColor: Colors.elementsBg,
    top: '100%',
    right: 0,
    transform: [{ translateX: -Metrics.baseMargin / 2 }, { translateY: Metrics.baseMargin / 2 }],
    maxWidth: Metrics.screenWidth - Metrics.baseMargin,
    minWidth: 0.65 * Metrics.screenWidth,
    borderRadius: Metrics.baseMargin,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    elevation: 2,
    alignContent: 'center',
    display: 'flex',
    position: 'absolute',
    ...ApplicationStyles.shadow,
  },
  popoverTail: {
    position: 'absolute',
    top: -15,
    right: Metrics.baseMargin / 2 + 2,
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderLeftWidth: 8,
    borderRightWidth: 8,
    borderBottomWidth: Metrics.baseMargin,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: Colors.elementsBg,
  },
  popoverContent: {
    margin: Metrics.baseMargin,
    flexDirection: 'column',
    display: 'flex',
  },
  popText: {
    ...Fonts.style.body,
    fontWeight: 'bold',
    color: Colors.text,
    alignItems: 'baseline',
    textAlign: 'center',
    marginTop: 10,
  },
  popSubtitle: {
    ...Fonts.style.body,
    fontSize: Fonts.size.small,
    color: Colors.text,
    alignItems: 'baseline',
    textAlign: 'center',
    marginTop: 14,
    marginBottom: 10,
  },
  notice: {
    width: '100%',
    top: 0,
    backgroundColor: Colors.bannerBg,
  },
  footer: {
    left: 0,
    right: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    backgroundColor: Colors.appBg,
    paddingTop: Metrics.baseMargin,
    paddingBottom: Metrics.bottomSpace || Metrics.baseMargin,
  },
})
