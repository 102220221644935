import { StyleSheet, Dimensions } from 'react-native'
import { Colors, Fonts, Metrics } from 'APP/Themes'
import { isWeb } from 'APP/Helpers/checkPlatform'

export default StyleSheet.create({
  slider: {
    backgroundColor: Colors.elementsBg,
    paddingBottom: Metrics.baseMargin,
    paddingHorizontal: Metrics.baseMargin * 1.5,
    display: 'flex',
    alignItems: 'center',
  },
  sliderMarker: {
    backgroundColor: Colors.accent,
    borderColor: Colors.accent,
    marginTop: Metrics.baseMargin / 4,
    width: 20,
    height: 20,
  },
  sliderTrack: {
    height: 6,
    borderRadius: 4,
  },
  sliderBar: { backgroundColor: Colors.accent },
  sliderUnselectedBar: { backgroundColor: Colors.textInverted },
  sliderRange: {
    ...Fonts.style.bodyLarge,
    width: '100%',
    color: Colors.text,
  },
  sliderLength: {
    width: isWeb() ? '100%' : Dimensions.get('window').width - 60,
  },
})
