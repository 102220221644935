import { StyleSheet } from 'react-native'
import { Colors, Fonts, Metrics, ApplicationStyles } from 'APP/Themes'

export default StyleSheet.create({
  button: {
    borderRadius: Metrics.baseMargin,
    backgroundColor: Colors.elementsBg,
    ...ApplicationStyles.shadow,
    padding: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin,
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: Metrics.baseMargin,
  },
  image: {
    resizeMode: 'contain',
    width: 40,
    height: 40,
  },
  wideImage: {
    width: 80,
  },
  bigLogo: {
    width: 180,
    height: 56,
  },
  title: {
    ...Fonts.style.h4,
    paddingBottom: Metrics.baseMargin / 4,
    color: Colors.text,
    textAlign: 'center',
  },
  subtitle: {
    ...Fonts.style.body,
    color: Colors.text,
    textAlign: 'center',
  },
  tagWrapper: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
})
