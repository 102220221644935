import { useMemo, useCallback } from 'react'
import { pick } from 'lodash'

import { AppointmentBookingProps } from 'APP/Components/AppointmentBooking'
import {
  Answer,
  MattermostQuestion,
  SilkroadSelfSchedulingQuestion,
  AppointmentBookingMicroAppProps,
  AnswerMessage,
} from './types'
import { isMatterMostPost, convertAnswerToSilkroadFormat } from './messageHelpers'
import { BOOKING_FLOW } from '../constants'

export function renameKeys(
  /* eslint-disable-next-line */
  obj: Record<string, any>,
  keysToRename: Record<string, string>
  /* eslint-disable-next-line */
): Record<string, any> {
  const objWithRenames = Object.assign({}, obj)
  Object.entries(keysToRename).forEach(([key, newKey]) => {
    if (objWithRenames[key]) {
      objWithRenames[newKey] = objWithRenames[key]
      delete objWithRenames[key]
    }
  })

  return objWithRenames
}

// -------------------------------------- //
// COPY-PASTED AS-IS FROM THE MICROAPP.
// IF THE CODE IS WEIRD HERE, WHO KNOWS WHY
// -------------------------------------- //
export function useCompatibility(
  { post, sendAnswer: sendAnswerProp, ...rest }: AppointmentBookingProps,
  flow: BOOKING_FLOW
): Omit<AppointmentBookingProps, 'post'> & {
  sendAnswer: (answer: Answer) => void
} {
  const appPropsKeys = [
    'date_from',
    'date_until',
    'appointment_type',
    'requested_slot',
    'patient_id',
    'member_id',
    'provider_id',
    'guardian_id',
    'display_on',
    'appointment_title',
    'episode_title',
    'is_rescheduling',
    'appointment_id',
    'dia_provider_id',
  ]
  const renames = {
    patient_id: 'member_id',
    episode_title: 'appointment_title',
  }
  const appPropsFromProps = pick(rest, appPropsKeys)
  const isMMPost = post && isMatterMostPost(post)
  const isSilkroadPost = post && !isMMPost

  const appProps = useMemo(() => {
    /* eslint-disable-next-line */
    let nextAppProps = appPropsFromProps as any
    if (isMMPost) {
      nextAppProps = renameKeys(
        pick((post as MattermostQuestion).props.microapp, appPropsKeys),
        renames
      )
    } else if (isSilkroadPost) {
      nextAppProps = renameKeys(
        pick((post as SilkroadSelfSchedulingQuestion).microapp_props, appPropsKeys),
        renames
      )
    }
    return {
      ...nextAppProps,
      /* eslint-disable-next-line */
      title: post && (post as any).message,
    } as AppointmentBookingMicroAppProps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...Object.values(appPropsFromProps), post, isMMPost, isSilkroadPost])

  const sendAnswer = useCallback(
    (answer: Answer) => {
      let compatibleAnswer: Answer | AnswerMessage = answer

      const answerPayload = renameKeys(answer.payload, {
        service_id: 'ih_service_id',
        ...(flow === BOOKING_FLOW.TIME_BASED && { provider_id: 'ih_practitioner_user_id' }),
      })

      if (isMMPost || isSilkroadPost) {
        const payload = `/answer_question${JSON.stringify({
          [appProps.requested_slot]: answer.payload.is_booked ? answerPayload : {},
        })}`

        if (isMMPost) {
          compatibleAnswer = {
            payload: [payload],
            type: 'answer',
            display: answer.display,
            question_type: 'multiChoiceQuestion',
            answer_flags: { update_channel_state: false },
          }
        }
        if (isSilkroadPost) {
          compatibleAnswer = convertAnswerToSilkroadFormat(
            post as SilkroadSelfSchedulingQuestion,
            payload,
            answer.display
          )
        }
      }

      sendAnswerProp(compatibleAnswer)
    },
    [isMMPost, isSilkroadPost, appProps.requested_slot, post, sendAnswerProp]
  )

  return {
    ...rest,
    ...appProps,
    sendAnswer,
  }
}
