import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
/* ------------- Types and Action Creators ------------- */

class ActiveLeaderboardItem {
  constructor(user, team) {
    this.user = user || null
    this.team = team || null
  }
}

export const Models = {
  ActiveLeaderboardItem,
}

const { Types, Creators } = createActions({
  setContent: ['category'],
  setContentSuccess: ['library'],
  setContentFailure: [],

  getMyChallenges: [],
  getMyChallengesSuccess: ['data'],
  getMyChallengesFailure: [],

  getNewChallenges: [],
  getNewChallengesSuccess: ['data'],
  getNewChallengesFailure: [],

  getCompletedChallenges: [],
  getCompletedChallengesSuccess: ['data'],
  getCompletedChallengesFailure: [],

  getChallenge: ['challengeId', 'challengeType'],
  getChallengeSuccess: [
    'challenge',
    'inChallenge',
    'userHasTracker',
    'userHasNoCompatibleTrackers',
    'userChallengeGoal',
  ],
  getChallengeFailure: [],
  resetChallenge: [],

  joinIndividualChallenge: ['challengeId'],
  joinIndividualChallengeSuccess: ['inChallenge'],
  joinIndividualChallengeFailure: [],

  joinTeamChallenge: ['challengeId', 'teamId'],
  joinTeamChallengeSuccess: ['inChallenge'],
  joinTeamChallengeFailure: [],

  getLeaderboard: ['challengeId', 'teamId'],
  getLeaderboardSuccess: ['participants', 'additionalParticipants', 'activeLeaderboardItem'],
  getLeaderboardFailure: [],

  getTeamParticipants: ['challengeId', 'teamId'],
  getTeamParticipantsSuccess: ['participants', 'additionalParticipants'],
  getTeamParticipantsFailure: [],

  leaveSelectedChallenge: ['challengeId'],
  leaveSelectedChallengeSuccess: ['participants'],
  leaveSelectedChallengeFailure: [],

  // getTrackers Legacy
  getTrackers: [],
  getTrackersSuccess: ['trackers'],
  getTrackersFailure: [],

  connectTracker: ['trackerName', 'id', 'callback'],
  disconnectTracker: ['trackerName', 'callback'],

  modifyingTrackers: ['trackers'],

  addMetricsTracker: [
    'trackerName',
    'trackerActivities',
    'fromDate',
    'toDate',
    'syncDate',
    'callback',
  ],

  syncAllSamplesForTrackers: [
    'trackersWithSyncOptions',
    'needsSyncTrackerStatus',
    'lastSyncTimeThreshold',
    'callback',
  ],

  healthKitSyncRequest: ['trigger'],

  setAnyTrackerLastConnectedTime: ['anyTrackerLastConnectedTime'],
})

export const WellnessCenterTypes = Types
export default Creators

export const INITIAL_STATE = Immutable({
  loading: true,
  retry: false,
  library: [],
  myChallenges: {
    loading: true,
    retry: false,
    data: undefined,
  },
  newChallenges: {
    loading: true,
    retry: false,
    data: undefined,
  },
  completedChallenges: {
    loading: true,
    retry: false,
    data: undefined,
  },
  selectedChallenge: {
    loading: true,
    retry: false,
    joining: false,
    leaving: false,
    inChallenge: false,
    userHasTracker: false,
    userHasNoCompatibleTrackers: false,
    userChallengeGoal: 0,
    participants: [],
    additionalParticipants: [],
    activeLeaderboardItem: new Models.ActiveLeaderboardItem(),
    challenge: {},
    loadingLeaderboard: true,
    retryLeaderboard: false,
  },
  selectedTeam: {
    loading: true,
    retry: false,
    participants: [],
    additionalParticipants: [],
  },
  trackers: {
    loading: true,
    retry: false,
    modifying: [],
    trackers: {
      user: [],
      supported: [],
      comingSoon: [],
    },
    anyTrackerLastConnectedTime: '',
  },
})

/* ------------- Reducers ------------- */

export const setAnyTrackerLastConnectedTime = (state, { anyTrackerLastConnectedTime }) =>
  state.merge(
    {
      trackers: {
        anyTrackerLastConnectedTime: anyTrackerLastConnectedTime,
      },
    },
    { deep: true }
  )

export const setContent = (state) =>
  state.merge({
    library: [],
    loading: true,
    retry: false,
  })

export const setContentSuccess = (state, { library }) =>
  state.merge({
    loading: false,
    retry: false,
    library,
  })

export const setContentFailure = (state) =>
  state.merge({
    loading: false,
    retry: true,
  })

export const getMyChallenges = (state) =>
  state.merge(
    {
      myChallenges: {
        loading: true,
        retry: false,
      },
    },
    { deep: true }
  )

export const getMyChallengesSuccess = (state, { data }) =>
  state.merge({
    myChallenges: {
      loading: false,
      retry: false,
      data,
    },
  })

export const getMyChallengesFailure = (state) =>
  state.merge({
    myChallenges: {
      loading: false,
      retry: true,
    },
  })

export const getNewChallenges = (state) =>
  state.merge(
    {
      newChallenges: {
        loading: true,
        retry: false,
      },
    },
    { deep: true }
  )

export const getNewChallengesSuccess = (state, { data }) =>
  state.merge({
    newChallenges: {
      loading: false,
      retry: false,
      data,
    },
  })

export const getNewChallengesFailure = (state) =>
  state.merge({
    newChallenges: {
      loading: false,
      retry: true,
    },
  })

export const getCompletedChallenges = (state) =>
  state.merge(
    {
      completedChallenges: {
        loading: true,
        retry: false,
      },
    },
    { deep: true }
  )

export const getCompletedChallengesSuccess = (state, { data }) =>
  state.merge({
    completedChallenges: {
      loading: false,
      retry: false,
      data,
    },
  })

export const getCompletedChallengesFailure = (state) =>
  state.merge({
    completedChallenges: {
      loading: false,
      retry: true,
    },
  })

export const getChallenge = (state) =>
  state.merge({
    selectedChallenge: {
      ...state.selectedChallenge,
      loading: true,
      retry: false,
      participants: [],
      challenge: {},
      activeLeaderboardItem: new Models.ActiveLeaderboardItem(),
    },
  })

export const getChallengeSuccess = (
  state,
  { challenge, inChallenge, userHasTracker, userHasNoCompatibleTrackers, userChallengeGoal }
) =>
  state.merge({
    selectedChallenge: {
      ...state.selectedChallenge,
      loading: false,
      retry: false,
      inChallenge,
      userHasTracker,
      userHasNoCompatibleTrackers,
      userChallengeGoal,
      challenge,
    },
  })

export const getChallengeFailure = (state) =>
  state.merge({
    selectedChallenge: {
      ...state.selectedChallenge,
      loading: false,
      retry: true,
    },
  })

export const resetChallenge = (state) =>
  state.merge({
    selectedChallenge: {
      loading: true,
      retry: false,
      joining: false,
      leaving: false,
      inChallenge: false,
      userHasTracker: false,
      userHasNoCompatibleTrackers: false,
      userChallengeGoal: 0,
      participants: [],
      activeLeaderboardItem: new Models.ActiveLeaderboardItem(),
      challenge: {},
      loadingLeaderboard: true,
      retryLeaderboard: false,
    },
  })

export const joinIndividualChallenge = (state) =>
  state.merge({
    selectedChallenge: {
      ...state.selectedChallenge,
      joining: true,
      retry: false,
    },
  })

export const joinIndividualChallengeSuccess = (state, { inChallenge }) =>
  state.merge({
    selectedChallenge: {
      ...state.selectedChallenge,
      joining: false,
      retry: false,
      inChallenge,
    },
  })

export const joinIndividualChallengeFailure = (state) =>
  state.merge({
    selectedChallenge: {
      ...state.selectedChallenge,
      joining: false,
      retry: true,
    },
  })

export const joinTeamChallenge = (state) =>
  state.merge({
    selectedChallenge: {
      ...state.selectedChallenge,
      joining: true,
      retry: false,
    },
  })

export const joinTeamChallengeSuccess = (state, { inChallenge }) =>
  state.merge({
    selectedChallenge: {
      ...state.selectedChallenge,
      joining: false,
      retry: false,
      inChallenge,
    },
  })

export const joinTeamChallengeFailure = (state) =>
  state.merge({
    selectedChallenge: {
      ...state.selectedChallenge,
      joining: false,
      retry: true,
    },
  })

export const getLeaderboard = (state) =>
  state.merge({
    selectedChallenge: {
      ...state.selectedChallenge,
      loadingLeaderboard: true,
      retryLeaderboard: false,
      participants: [],
    },
  })

export const getLeaderboardSuccess = (
  state,
  { participants, additionalParticipants, activeLeaderboardItem }
) =>
  state.merge({
    selectedChallenge: {
      ...state.selectedChallenge,
      loadingLeaderboard: false,
      retryLeaderboard: false,
      participants,
      additionalParticipants,
      activeLeaderboardItem,
    },
  })

export const getLeaderboardFailure = (state) =>
  state.merge({
    selectedChallenge: {
      ...state.selectedChallenge,
      loadingLeaderboard: false,
      retryLeaderboard: true,
    },
  })

export const getTeamParticipants = (state) =>
  state.merge({
    selectedTeam: {
      ...state.selectedTeam,
      loading: true,
      retry: false,
      participants: [],
      additionalParticipants: [],
    },
  })

export const getTeamParticipantsSuccess = (state, { participants, additionalParticipants }) =>
  state.merge({
    selectedTeam: {
      ...state.selectedTeam,
      loading: false,
      retry: false,
      participants,
      additionalParticipants,
    },
  })

export const getTeamParticipantsFailure = (state) =>
  state.merge({
    selectedTeam: {
      ...state.selectedTeam,
      loading: false,
      retry: true,
    },
  })

export const leaveSelectedChallenge = (state) =>
  state.merge({
    selectedChallenge: {
      ...state.selectedChallenge,
      loading: true,
      leaving: true,
      retry: false,
    },
  })

export const leaveSelectedChallengeSuccess = (state) =>
  state.merge({
    selectedChallenge: {
      ...state.selectedChallenge,
      loading: false,
      leaving: false,
      retry: false,
    },
  })

export const leaveSelectedChallengeFailure = (state) =>
  state.merge({
    selectedChallenge: {
      ...state.selectedChallenge,
      loading: false,
      leaving: false,
      retry: true,
    },
  })

// getTrackers Legacy
export const getTrackers = (state) =>
  state.merge({
    trackers: {
      ...state.trackers,
      loading: true,
      retry: false,
      modifying: [],
    },
  })

export const getTrackersSuccess = (state, { trackers }) =>
  state.merge({
    trackers: {
      ...state.trackers,
      loading: false,
      retry: false,
      modifying: [],
      trackers,
    },
  })

export const getTrackersFailure = (state) =>
  state.merge({
    trackers: {
      ...state.trackers,
      loading: false,
      retry: true,
      modifying: [],
    },
  })

export const connectTracker = (state) => state

export const disconnectTracker = (state) => state

export const modifyingTrackers = (state, { trackers }) =>
  state.merge({
    trackers: {
      ...state.trackers,
      modifying: trackers,
    },
  })

export const addMetricsTracker = (state) => state

export const syncAllSamplesForTrackers = (state) => state

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_CONTENT]: setContent,
  [Types.SET_CONTENT_SUCCESS]: setContentSuccess,
  [Types.SET_CONTENT_FAILURE]: setContentFailure,

  // getMyChallenges Legacy
  [Types.GET_MY_CHALLENGES]: getMyChallenges,
  [Types.GET_MY_CHALLENGES_SUCCESS]: getMyChallengesSuccess,
  [Types.GET_MY_CHALLENGES_FAILURE]: getMyChallengesFailure,

  // getNewChallenges Legacy
  [Types.GET_NEW_CHALLENGES]: getNewChallenges,
  [Types.GET_NEW_CHALLENGES_SUCCESS]: getNewChallengesSuccess,
  [Types.GET_NEW_CHALLENGES_FAILURE]: getNewChallengesFailure,

  // getCompletedChallenges Legacy
  [Types.GET_COMPLETED_CHALLENGES]: getCompletedChallenges,
  [Types.GET_COMPLETED_CHALLENGES_SUCCESS]: getCompletedChallengesSuccess,
  [Types.GET_COMPLETED_CHALLENGES_FAILURE]: getCompletedChallengesFailure,

  // getChallenge Legacy
  [Types.GET_CHALLENGE]: getChallenge,
  [Types.GET_CHALLENGE_SUCCESS]: getChallengeSuccess,
  [Types.GET_CHALLENGE_FAILURE]: getChallengeFailure,
  [Types.RESET_CHALLENGE]: resetChallenge,

  [Types.JOIN_INDIVIDUAL_CHALLENGE]: joinIndividualChallenge,
  [Types.JOIN_INDIVIDUAL_CHALLENGE_SUCCESS]: joinIndividualChallengeSuccess,
  [Types.JOIN_INDIVIDUAL_CHALLENGE_FAILURE]: joinIndividualChallengeFailure,

  [Types.JOIN_TEAM_CHALLENGE]: joinTeamChallenge,
  [Types.JOIN_TEAM_CHALLENGE_SUCCESS]: joinTeamChallengeSuccess,
  [Types.JOIN_TEAM_CHALLENGE_FAILURE]: joinTeamChallengeFailure,

  [Types.GET_LEADERBOARD]: getLeaderboard,
  [Types.GET_LEADERBOARD_SUCCESS]: getLeaderboardSuccess,
  [Types.GET_LEADERBOARD_FAILURE]: getLeaderboardFailure,

  [Types.GET_TEAM_PARTICIPANTS]: getTeamParticipants,
  [Types.GET_TEAM_PARTICIPANTS_SUCCESS]: getTeamParticipantsSuccess,
  [Types.GET_TEAM_PARTICIPANTS_FAILURE]: getTeamParticipantsFailure,

  [Types.LEAVE_SELECTED_CHALLENGE]: leaveSelectedChallenge,
  [Types.LEAVE_SELECTED_CHALLENGE_SUCCESS]: leaveSelectedChallengeSuccess,
  [Types.LEAVE_SELECTED_CHALLENGE_FAILURE]: leaveSelectedChallengeFailure,

  // getTrackers Legacy
  [Types.GET_TRACKERS]: getTrackers,
  [Types.GET_TRACKERS_SUCCESS]: getTrackersSuccess,
  [Types.GET_TRACKERS_FAILURE]: getTrackersFailure,

  [Types.CONNECT_TRACKER]: connectTracker,
  [Types.DISCONNECT_TRACKER]: disconnectTracker,

  [Types.MODIFYING_TRACKERS]: modifyingTrackers,

  [Types.ADD_METRICS_TRACKER]: addMetricsTracker,

  [Types.SYNC_ALL_SAMPLES_FOR_TRACKERS]: syncAllSamplesForTrackers,

  [Types.SET_ANY_TRACKER_LAST_CONNECTED_TIME]: setAnyTrackerLastConnectedTime,
})
