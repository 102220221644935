import React from 'react'
import { Pressable } from 'react-native'

// Styles
import { Colors } from 'APP/Themes'

const styles = {
  active: {
    borderTopColor: Colors.accent,
    backgroundColor: Colors.activeTabBackgroundColor,
  },
  inactive: {
    borderTopColor: Colors.elementsBg,
    backgroundColor: Colors.appBg,
  },
}

export const TabBarButton = (props) => {
  const isSelected = props?.accessibilityState?.selected
  const style = isSelected ? styles.active : styles.inactive
  return <Pressable isSelected={isSelected} {...props} style={[style, props.style]} />
}
