// Third Party
import React, { useCallback, useEffect, useMemo, useState } from 'react'
// eslint-disable-next-line no-restricted-imports
import { ActivityIndicator, Text, View, Image } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'

// Components
import WebContentView from 'APP/Components/WebContentView'
import PatientActions from 'APP/Redux/PatientRedux'
import LoginActions from 'APP/Redux/LoginRedux'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import Checkbox from 'APP/Converse/Inputs/Checkbox'
import RadioGroup from 'APP/Converse/Inputs/RadioGroup'
import Modal from 'APP/Components/Modal'
import { PrimaryButton, SecondaryButton } from 'APP/Components/Buttons'
import { ResponsiveScrollView, ResponsiveView, ResponsiveWebView } from 'APP/Converse/Layout'

// Services
import I18n from 'APP/Services/i18n'
import UrlBuilder from 'APP/Services/UrlBuilder'
import { getImageAspectRatio } from 'APP/Lib/StylingHelpers'

// Styles
import Styles from './style'
import { Images } from 'APP/Themes'
import { useRoute } from '@react-navigation/native'
import { useDevTools } from 'APP/Lib/DevTools'

const ConsentScreenHeader = ({ flow }) => (
  <View style={Styles.header}>
    <View>
      <Image
        style={[Styles.headerLogo, { aspectRatio: getImageAspectRatio(Images.logoBlue) }]}
        source={Images.logoBlue}
      />
      <Text style={Styles.title}>{I18n.t(`ConsentScreen.${flow}.title`)}</Text>
      <Text style={Styles.subtitle}>{I18n.t(`ConsentScreen.${flow}.subtitle`)}</Text>
    </View>
  </View>
)

const ConsentForm = ({ form, setState }) => {
  const { member, uri } = form
  const { type } = member
  const [loaded, setLoaded] = useState(form.loaded)
  const [formState, setFormState] = useState(form.state)

  const showRadioGroup = type === 'child'
  const showCheckBox = formState === 'displayed' || formState === 'accept'
  const showConsentForm = formState === 'displayed' || !loaded

  const checkBoxStatus = formState === 'accept' ? 'checked' : 'unchecked'

  const handleRadioGroupPress = (value) => {
    setFormState(value)
    setState(value)
  }

  const handleCheckBoxPress = () => {
    setFormState(formState === 'accept' ? 'displayed' : 'accept')
    setState(formState === 'accept' ? 'displayed' : 'accept')
  }

  const handleConsentLoad = useCallback(() => {
    setLoaded(true)
  }, [])

  const checkBoxLabel =
    member.type === 'child'
      ? I18n.t('ConsentScreen.accept.dependant', { ...member })
      : I18n.t('ConsentScreen.accept.primary')

  return (
    <View>
      <View style={Styles.formContent}>
        {showRadioGroup && (
          <RadioGroup
            label={I18n.t('ConsentScreen.guardianshipConfirmation', { ...member })}
            currentValue={formState}
            options={[
              {
                label: I18n.t('ConsentScreen.actions.yes'),
                value: formState === 'accept' ? 'accept' : 'displayed',
              },
              { label: I18n.t('ConsentScreen.actions.no'), value: 'not_applicable' },
            ]}
            onPress={handleRadioGroupPress}
          />
        )}
        {showCheckBox && (
          <Checkbox
            label={checkBoxLabel}
            status={checkBoxStatus}
            testID={'CONSENT_SCREEN_ACCEPT_CHECK'}
            onPress={handleCheckBoxPress}
            disabled={!loaded}
          />
        )}
      </View>

      <ResponsiveWebView
        style={[
          Styles.consentFormContainer,
          !showConsentForm && { height: 0, opacity: 0, marginVertical: 0 },
        ]}
      >
        <WebContentView
          autoHeight
          autoHeightStyle={Styles.consentFormView}
          showInnerShadow
          uri={uri}
          onLoad={handleConsentLoad}
          screen="consent"
        />
      </ResponsiveWebView>
    </View>
  )
}

const DeclineAcceptButtonBar = ({ flow, onDecline, onAccept, submitDisabled }) => (
  <ResponsiveView style={Styles.buttonBar}>
    <SecondaryButton
      style={[Styles.button, Styles.noRightSpacing]}
      onPress={onDecline}
      analyticsName="ConsentScreen.actions.cancel"
    >
      {I18n.t('ConsentScreen.actions.cancel')}
    </SecondaryButton>
    <PrimaryButton
      analyticsName="Accept"
      disabled={submitDisabled}
      style={Styles.button}
      onPress={onAccept}
    >
      {I18n.t(`ConsentScreen.${flow}.actions.finish`)}
    </PrimaryButton>
  </ResponsiveView>
)

const memberToFormState = (member = {}, userProfile = {}) => {
  if (member.type === 'child') {
    return {
      member,
      loaded: null,
      state: null,
      uri: UrlBuilder.guardianConsentUrl(
        userProfile.givenName,
        userProfile.familyName,
        member.givenName,
        member.familyName
      ),
    }
  }
  return {
    member,
    loaded: false,
    state: 'displayed',
    uri: UrlBuilder.consentUrl(),
  }
}

const familyStateToDependentFormSet = (familyMembers = {}, userProfile = {}) =>
  Object.values(familyMembers)
    .filter(({ type }) => type === 'child')
    .sort((a) => (a.type === 'child' ? 1 : -1))
    .map((member) => memberToFormState(member, userProfile))

const ConsentScreen = () => {
  const [showAcceptModal, setShowAcceptModal] = useState(false)
  const [showDeclineModal, setShowDeclineModal] = useState(false)
  const dispatch = useDispatch()
  const route = useRoute()
  const { setToolFunction, setToolName } = useDevTools()

  const {
    profile,
    giveConsentRunning,
    error: giveConsentError,
  } = useSelector((state) => state.patient)
  const { isConnected } = useSelector((state) => state.appSession)
  const {
    members: familyMembers,
    requestRunning: fetchFamilyRunning,
    requestError: fetchFamilyError,
  } = useSelector((state) => state.family)

  const forms = useMemo(() => {
    return [
      memberToFormState(profile, {}),
      ...familyStateToDependentFormSet(familyMembers, profile),
    ]
  }, [familyMembers, profile])

  const [formStates, setFormStates] = useState(forms.map((form) => form.state))

  const setFormStateIndex = (index, state) => {
    const newFormStates = [...formStates]
    newFormStates[index] = state
    setFormStates(newFormStates)
  }

  useEffect(() => {
    setToolName('Skip')
    setToolFunction(() => () => {
      dispatch(PatientActions.patientProfileGiveConsent(forms))
    })
    return () => {
      setToolName(null)
      setToolFunction(null)
    }
  }, [forms, dispatch])

  const openAcceptModal = () => setShowAcceptModal(true)
  const closeAcceptModal = () => setShowAcceptModal(false)
  const openDeclineModal = () => setShowDeclineModal(true)
  const closeDeclineModal = () => setShowDeclineModal(false)

  const handleAgreeOnAcceptPress = () => {
    closeAcceptModal()
    dispatch(PatientActions.patientProfileGiveConsent(forms))
  }

  const handleAgreeOnDeclinePress = () => {
    closeDeclineModal()
    dispatch(PatientActions.patientProfileDeclineConsent(forms))
    dispatch(LoginActions.logout('Declined consent'))
  }

  const handleGiveConsentPress = () => {
    openAcceptModal()
  }

  const handleDeclineConsentPress = () => {
    openDeclineModal()
  }

  const getSubmitDisabledState = () =>
    !isConnected ||
    !!fetchFamilyError ||
    formStates.length === 0 ||
    !!formStates.find((state) => state === null || state === 'displayed')

  const flow = route?.params?.flow

  return (
    <NoticeBarContainer containerStyle={Styles.fullPrimaryContainer}>
      <ResponsiveScrollView>
        <ConsentScreenHeader flow={flow} />
        {forms.map((form, index) => (
          <ConsentForm
            key={index}
            form={form}
            setState={(state) => setFormStateIndex(index, state)}
          />
        ))}
      </ResponsiveScrollView>
      <DeclineAcceptButtonBar
        flow={flow}
        onDecline={handleDeclineConsentPress}
        onAccept={handleGiveConsentPress}
        submitDisabled={getSubmitDisabledState()}
      />
      <Modal
        statusBarTranslucent
        visible={showAcceptModal}
        transparent
        title={I18n.t('ConsentScreen.finishPopup.title')}
        subtitle={I18n.t('ConsentScreen.finishPopup.body')}
        primaryActionText={I18n.t('ConsentScreen.finishPopup.agree')}
        secondaryActionText={I18n.t('ConsentScreen.finishPopup.decline')}
        handleCloseModal={closeAcceptModal}
        handlePrimaryAction={handleAgreeOnAcceptPress}
        handleSecondaryAction={closeAcceptModal}
      />
      <Modal
        statusBarTranslucent
        visible={showDeclineModal}
        transparent
        title={I18n.t('ConsentScreen.cancelPopup.title')}
        subtitle={I18n.t('ConsentScreen.cancelPopup.body')}
        secondaryActionText={I18n.t('ConsentScreen.cancelPopup.agree')}
        primaryActionText={I18n.t('ConsentScreen.cancelPopup.decline')}
        handleCloseModal={closeDeclineModal}
        handleSecondaryAction={handleAgreeOnDeclinePress}
        handlePrimaryAction={closeDeclineModal}
      />
      {(fetchFamilyRunning || giveConsentRunning || giveConsentError) && (
        <ActivityIndicator
          size={'large'}
          color={'#FFFFFF'}
          animating={true}
          style={Styles.activityIndicator}
        />
      )}
    </NoticeBarContainer>
  )
}

export default ConsentScreen
