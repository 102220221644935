import * as Sentry from '@sentry/react-native'
import Analytics from 'APP/Services/Analytics'
import { setDdUser } from 'APP/Lib/Datadog'

const selectProfile = (action, state) => (action && action.profile) || state?.patient?.profile || {}

const selectDeeplinkUserId = (action, state) => {
  const isDeferredDeeplink =
    ['SET_DEFERRED_DEEPLINK', 'REJECT_DEFERRED_DEEPLINK'].indexOf(action.type) > -1
  if (isDeferredDeeplink) {
    return (action.props || (state.deeplink || {}).props || {}).user_id
  }
}

const selectUserId = (action, state) => {
  const profile = selectProfile(action, state)
  const deeplinkUserId = selectDeeplinkUserId(action, state)
  return String((profile && profile.id) || deeplinkUserId) || null
}

export default (store) => (next) => (action) => {
  if (!action.type) return next(action)

  const state = store.getState()

  const userId = selectUserId(action, state)

  if (userId) {
    Sentry.setUser({ id: userId })
    setDdUser({ id: userId })
  }
  if (userId && userId !== undefined) {
    Analytics.identify(userId)
  } else if (action.type === 'LOGOUT') {
    Analytics.clearIdentify()
  }

  return next(action)
}
