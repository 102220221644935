import React, { useEffect } from 'react'
import { View, Text } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import I18n from 'APP/Services/i18n'
import { USER_TRACKER_STATUSES } from 'APP/Lib/Enums'
import { SecondaryButton } from 'APP/Components/Buttons'
import { Metrics, Colors } from 'APP/Themes'
import Styles from './style'
import DialogueMarkdown from 'APP/Components/DialogueMarkdown'
import Analytics from 'APP/Services/Analytics'
import SyncingLeftElement from './SyncingLeftElement'

const STATUS_CONTENT_MAP = {
  [USER_TRACKER_STATUSES.NO_TRACKERS]: {
    title: I18n.t('ActiveMinutes.trackerBanner.noneConnected.title'),
    copy: I18n.t('ActiveMinutes.trackerBanner.noneConnected.copy'),
    shouldHaveCta: true,
  },
  [USER_TRACKER_STATUSES.NO_COMPATIBLE_TRACKERS]: {
    title: I18n.t('WellnessCenter.trackers.noneCompatible.title'),
    copy: I18n.t('WellnessCenter.trackers.noneCompatible.copy'),
    shouldHaveCta: true,
  },
  [USER_TRACKER_STATUSES.BROKEN_TRACKER]: {
    title: I18n.t('ActiveMinutes.trackerBanner.trackerBroken.title'),
    titleIcon: 'error',
    copy: I18n.t('ActiveMinutes.trackerBanner.trackerBroken.copy'),
    shouldHaveCta: true,
  },
  [USER_TRACKER_STATUSES.NO_DATA]: {
    title: I18n.t('ActiveMinutes.trackerBanner.noData.title'),
    copy: I18n.t('ActiveMinutes.trackerBanner.noData.copy'),
    shouldHaveCta: false,
  },
  [USER_TRACKER_STATUSES.SYNCING]: {
    copy: I18n.t('ActiveMinutes.trackerBanner.syncing.copy'),
    LeftElement: SyncingLeftElement,
    shouldHaveCta: false,
  },
}

const TrackerStatusWrapper = ({ status, children = null, requestedActivity, style }) => {
  const navigation = useNavigation()
  if (!Object.keys(STATUS_CONTENT_MAP).includes(status)) return children

  const setupTracker = () => {
    navigation.navigate('healthTrackerSettings', { requestedActivity })
  }
  const { title, titleIcon, copy, shouldHaveCta, LeftElement } = STATUS_CONTENT_MAP[status] || {}

  useEffect(() => {
    if (Object.keys(STATUS_CONTENT_MAP).includes(status)) {
      Analytics.trackEvent('banner_view', { banner_name: status })
    }
  }, [status])

  return (
    <View style={[Styles.container, style]} testID={`${status}_BANNER`}>
      {title && (
        <View style={Styles.titleContainer}>
          {titleIcon && (
            <MaterialIcon
              name={titleIcon}
              size={Metrics.icons.small}
              color={Colors.errorText}
              style={Styles.icon}
            />
          )}
          <Text style={Styles.title}>{title}</Text>
        </View>
      )}
      {LeftElement ? (
        <View style={Styles.copyContainer}>
          <LeftElement />
          <DialogueMarkdown markdown={copy} styles={Styles.syncCopy} />
        </View>
      ) : (
        <DialogueMarkdown markdown={copy} styles={Styles.copy} />
      )}
      {shouldHaveCta && (
        <SecondaryButton style={Styles.cta} onPress={setupTracker}>
          {I18n.t('WellnessCenter.trackers.settings')}
        </SecondaryButton>
      )}
    </View>
  )
}

export default TrackerStatusWrapper
