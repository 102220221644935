import React from 'react'
import { connect } from 'react-redux'
import { Linking, View, Platform } from 'react-native'
import Moment from 'moment'
import I18n from 'APP/Services/i18n'
import * as Sentry from '@sentry/react-native'

// Actions
import PatientHistoryActions from 'APP/Redux/PatientHistoryRedux'
import StartupActions from 'APP/Redux/StartupRedux'
import LoggerActions from 'APP/Redux/LoggerRedux'
import ActiveMinutesActions from 'APP/Redux/ActiveMinutesRedux'

// Components
import AlertModal from 'APP/Components/AlertModal'

// Services
import Config from 'APP/Config'
import UrlBuilder from 'APP/Services/UrlBuilder'
import { Metrics } from '../../Themes'
import Analytics from 'APP/Services/Analytics'
import { navigationRef as Nav } from 'APP/Nav'
import { logDdError } from 'APP/Lib/Datadog'

// TODO: Refactor this screen to be a functional component, and
// Implement useNavigation instead of global navigationRef
class Banner extends React.Component {
  _startNewChat = (ctaId) => this.props.isConnected && this.props.newEpisode(ctaId)

  handleDismissBusierThanUsual = () => {
    this.props.setBusierThanUsualBannerHidden(this.props.userId, Moment().startOf('day').valueOf())
  }

  handleChangeEmailStart = () => {
    Nav.navigate('changeEmail')
    this.props.setChangeEmailBannerHidden(this.props.userId)
  }

  handleDismissChangeEmail = () => {
    this.props.setChangeEmailBannerHidden(this.props.userId)
  }

  handleAppleWatchReconnection = () => {
    this.props.handleAppleWatchReconnection()
    this.props.setAppleWatchDisconnectedBannerHidden()
    Analytics.trackEvent('button_click', { button_value: 'AppleWatchReconnection' })
  }

  handleDismissAppleWatchDisconnected = () => {
    this.props.setAppleWatchDisconnectedBannerHidden()
    Analytics.trackEvent('button_click', { button_value: 'DismissAppleWatchDisconnected' })
  }

  goToAppStore = () => {
    const { setRatingThreshold, episodeThreshold } = this.props
    const store = Platform.OS === 'ios' ? Config.APP_STORE : Config.PLAY_STORE
    const url = Platform.OS === 'ios' ? Config.APP_STORE_URL : Config.PLAY_STORE_URL
    const localizedUrl = url[I18n.baseLocale] || url.en
    Linking.openURL(store)
      .catch((reason) => {
        const msg = `HomeScreen. Error opening link to store. message: ${reason.message}, url: ${store}`
        Sentry.captureException(msg)
        logDdError(msg, 'Banner.goToAppStore')
        Linking.openURL(localizedUrl)
      })
      .catch((anotherReason) => {
        const msg = `HomeScreen. Error opening link to HTTPS store. message: ${anotherReason.message}, url: ${localizedUrl}`
        logDdError(msg, 'Banner.goToAppStore')
        Sentry.captureException(msg)
      })
      .then(setRatingThreshold(true, episodeThreshold))
  }

  handleContactUs = () => {
    const customContactUs = Config.CUSTOM_SUPPORT_PAGE

    if (customContactUs) {
      Nav.navigate('contactUs')
    } else {
      const url = UrlBuilder.supportEmailUrl()
      Linking.openURL(url).catch((reason) => {
        const msg = `HomeScreen. Sending email. message: ${reason.message}, address: ${url}`
        Sentry.captureException(msg)
        logDdError(msg, 'Banner.handleContactUs')
      })
    }
  }

  handleDismissRating = () => {
    const { setRatingThreshold, episodeCount } = this.props

    setRatingThreshold(false, (episodeCount || 0) + 3)
  }

  render() {
    const {
      isConnected,
      shouldShowRatingBanner,
      shouldShowChangeEmailBanner,
      shouldShowBusierThanUsualBanner,
      shouldShowAppleWatchDisconnectedBanner,
      importantCommunicationBannerContent,
    } = this.props

    const baseBannerProps = {
      userId: this.props.userId,
      primaryActionDisabled: !isConnected,
    }

    const localeKey = I18n.locale === 'fr' ? 'fr' : 'en'

    // Banner precendence is determined by the order of this array
    const bannerPropsAndOrder = shouldShowBusierThanUsualBanner
      ? {
          ...baseBannerProps,
          key: 'BusierThanUsual',
          alertStyle: 1,
          handleDismissModal: this.handleDismissBusierThanUsual,
          titleText: importantCommunicationBannerContent.title[localeKey],
          subtitleText: importantCommunicationBannerContent.subtitle[localeKey],
          viewTrackingValue: 'Important Communication Banner',
        }
      : shouldShowChangeEmailBanner
      ? {
          ...baseBannerProps,
          key: 'ChangeEmail',
          alertStyle: 4,
          handleDismissModal: this.handleDismissChangeEmail,
          handleLinkAction: this.handleChangeEmailStart,
          titleText: I18n.t('ChangeEmailBanner.title'),
          subtitleText: I18n.t('ChangeEmailBanner.subTitle'),
          linkText: I18n.t('ChangeEmailBanner.linkText'),
          viewTrackingValue: 'Change Email Banner',
        }
      : shouldShowRatingBanner
      ? {
          ...baseBannerProps,
          key: 'AppRating',
          alertStyle: 1,
          handleDismissModal: this.handleDismissRating,
          handleLinkAction: this.goToAppStore,
          titleText: I18n.t('RatingBanner.title'),
          linkText: I18n.t('RatingBanner.linkText'),
          subtitleText: I18n.t('RatingBanner.subTitle'),
          viewTrackingValue: 'App Rating Banner',
        }
      : shouldShowAppleWatchDisconnectedBanner
      ? {
          ...baseBannerProps,
          key: 'AppleWatchDisconnected',
          alertStyle: 1,
          icon: 'warning',
          iconColor: 'error',
          linkText: I18n.t('AppleWatchDisconnectedBanner.linkText'),
          handleDismissModal: this.handleDismissAppleWatchDisconnected,
          handleLinkAction: this.handleAppleWatchReconnection,
          titleText: I18n.t('AppleWatchDisconnectedBanner.title'),
          subtitleText: I18n.t('AppleWatchDisconnectedBanner.subTitle'),
          viewTrackingValue: 'Apple Watch Disconnected Banner',
        }
      : null

    if (bannerPropsAndOrder) {
      return <AlertModal {...bannerPropsAndOrder} />
    }

    return <View style={{ height: Metrics.statusBarHeight }} />
  }

  updateBannerContext = () => {
    const { bannerName } = this.props
    if (bannerName) {
      const bannerPayload = { banner_name: bannerName }
      this.props.trackEvent('banner_view', bannerPayload)
      Analytics.attachEntity('banner_context', bannerPayload)
    } else {
      Analytics.detachEntity('banner_context')
    }
  }

  componentDidMount() {
    this.updateBannerContext()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bannerName !== this.props.bannerName) {
      this.updateBannerContext()
    }
  }
}

const mapStateToProps = (state) => {
  const { app, appSession, patient, history, features } = state
  const profile = patient && patient.profile
  const episodeCount = history?.orderedEpisodes ? history?.orderedEpisodes?.length : 0
  const userId = profile.id
  const userSettings = (app.userSettings && app.userSettings[userId]) || {}

  const shouldShowBusierThanUsualBanner =
    features.importantCommunicationBannerContent &&
    Object.keys(features.importantCommunicationBannerContent).length > 0 &&
    !(Moment().startOf('day').valueOf() === userSettings.lastBusierThanUsualModal)

  const shouldShowChangeEmailBanner = !userSettings.hideChangeEmailModal

  const shouldShowAppleWatchDisconnectedBanner = app.showAppleWatchDisconnectedModal

  const shouldShowRatingBanner =
    (app.hideRatingModal === undefined && episodeCount >= 2) ||
    (!app.hideRatingModal && episodeCount >= app.episodeThreshold)

  const bannerName = shouldShowAppleWatchDisconnectedBanner
    ? 'AppleWatchDisconnected'
    : shouldShowBusierThanUsualBanner
    ? 'BusierThanUsual'
    : shouldShowChangeEmailBanner
    ? 'ChangeEmail'
    : shouldShowRatingBanner
    ? 'AppRating'
    : null

  return {
    profile: patient.profile,
    userId,
    isConnected: appSession.isConnected,
    episodeCount,
    userSettings,
    app,
    shouldShowAppleWatchDisconnectedBanner,
    shouldShowBusierThanUsualBanner,
    shouldShowChangeEmailBanner,
    shouldShowRatingBanner,
    bannerName,
    importantCommunicationBannerContent: features.importantCommunicationBannerContent,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    newEpisode: (ctaId) => dispatch(PatientHistoryActions.createEpisodeRequest(ctaId)),
    setRatingThreshold: (hideModal, threshold) =>
      dispatch(StartupActions.setRatingThreshold(hideModal, threshold)),
    rateApp: (action) => dispatch(StartupActions.rateApp(action)),
    setChangeEmailBannerHidden: (userId) =>
      dispatch(StartupActions.setChangeEmailBannerHidden(userId)),
    setBusierThanUsualBannerHidden: (userId, timestamp) =>
      dispatch(StartupActions.setBusierThanUsualBannerHidden(userId, timestamp)),
    setAppleWatchDisconnectedBannerHidden: () =>
      dispatch(StartupActions.setAppleWatchDisconnectedBannerVisible(false)),
    handleAppleWatchReconnection: () =>
      dispatch(ActiveMinutesActions.handleAppleHealthReconnection()),
    trackEvent: (eventName, props) => dispatch(LoggerActions.triggerEvent(eventName, props)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Banner)
