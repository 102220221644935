import React from 'react'
import { View, Text } from 'react-native'
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import I18n from 'APP/Services/i18n'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'

import { Colors } from 'APP/Themes'
import Styles from './style'

const Header = ({ onAddHabitPress }) => (
  <View style={Styles.header}>
    <Text style={Styles.headerTitle}>{I18n.t('Habits.title')}</Text>
    <DialogueTouchableOpacity testID="addCta" style={Styles.addCta} onPress={onAddHabitPress}>
      <MaterialCommunityIcon name="plus-thick" size={14} color={Colors.accent} />
      <Text style={Styles.addCtaText}>{I18n.t('Habits.add')}</Text>
    </DialogueTouchableOpacity>
  </View>
)

export default Header
