import { StyleSheet } from 'react-native'
import { ApplicationStyles, Metrics, Colors, Fonts } from 'APP/Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  quickActionsContainer: {
    paddingHorizontal: Metrics.baseMargin,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  quickAction: {
    // Copied over from `normalShadow` in App/Theme/Theme.ts
    shadowColor: Colors.blackShadow,
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.16,
    shadowRadius: 5,
    elevation: 2,

    backgroundColor: Colors.elementsBg,
    paddingVertical: Metrics.baseMargin,
    paddingHorizontal: Metrics.baseMargin / 4,
    flex: 1,
    margin: Metrics.baseMargin * 0.5,
    borderRadius: 16,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iconWrapper: {
    width: 20,
    height: 20,
  },
  titleWrapper: {
    marginTop: Metrics.baseMargin / 2,
    display: 'flex',
    width: '100%',
  },
  title: {
    ...Fonts.style.bodySmallBold,
    color: Colors.text,
    textAlign: 'center',
  },
})
