import React from 'react'
import { TransitionPresets } from '@react-navigation/stack'
import { Platform, StyleSheet } from 'react-native'
import { TabBarButton } from 'APP/Nav/Components/TabBarButton'
import { tabBarBadgeStyle } from 'APP/Nav/Components/Badge'

import { tabBarStyles } from './style'
import { Colors } from 'APP/Themes'

import Header, { HeaderWithoutBottomPadding } from 'APP/Converse/Header'
import { ResponsiveView } from 'APP/Converse/Layout'

export const screenOptions = {
  headerShown: false,
  freezeOnBlur: true,
  animation: 'slide_from_right',
  __statusBarStyle: 'auto',
  header: (props) => (
    <ResponsiveView>
      <Header {...props} {...props.options} />
    </ResponsiveView>
  ),
  contentStyle: {
    zIndex: 0,
  },
  stackMenuStyle: {
    zIndex: 1,
  },
  ...Platform.select({ web: { animationEnabled: false } }),
}

export const screenOptionsForModals = {
  ...TransitionPresets.ModalPresentationIOS,
  ...screenOptions,
  presentation: 'modal',
  headerShown: true,
  gestureEnabled: true,
  header: (props) => <Header {...props} {...props.options} isModal />,
  headerTransparent: false,
  headerShadowVisible: true,
  cardShadowEnabled: true,
  cardOverlayEnabled: true,
  __statusBarStyle: 'light',
  detachPreviousScreen: false,
}

export const screenOptionsForTransparentModals = {
  ...screenOptionsForModals,
  presentation: 'containedTransparentModal',
  animation: 'fade',
  headerShown: false,
  stackMenuStyle: {
    zIndex: -1,
  },
  contentStyle: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 0,
    ...Platform.select({
      web: {
        position: 'fixed',
        pointerEvents: 'all',
        backdropFilter: ' blur(2px)',
      },
    }),
  },
}

export const screenOptionsForBottomSheetModals = {
  ...screenOptionsForModals,
  headerShown: false,
  presentation: 'transparentModal',
  cardOverlayEnabled: true,
  gestureResponseDistance: 1000,
}

export const screenOptionsForTabs = {
  ...tabBarStyles,
  tabBarBadgeStyle,
  tabBarActiveTintColor: Colors.tabSelected,
  tabBarInactiveTintColor: Colors.tabUnselected,
  tabBarLabelPosition: 'below-icon',
  tabBarButton: TabBarButton,
  __statusBarStyle: 'auto',
  header: (props) => (
    <ResponsiveView>
      <HeaderWithoutBottomPadding {...props} {...props.options} />
    </ResponsiveView>
  ),
  hideBackButton: true,
  headerShown: true,
  freezeOnBlur: true,
  accessibilityRole: 'button',
  accessibilityState: ({ focused }) => {
    focused ? { selected: true } : {}
  },
}
