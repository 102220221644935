import React from 'react'
import useCountdown from 'APP/Hooks/useCountdown'
import { Text } from 'react-native'

const Countdown = ({ challenge }) => {
  const countdown = useCountdown(challenge)

  return <Text>{countdown}</Text>
}

export default Countdown
