import React from 'react'
import { Image, TouchableOpacity } from 'react-native'

// Helpers & Services
import { buildAvatarUrl } from 'APP/Services/Mattermost'

// Styling
import Styles from './style'

export default class MattermostAvatar extends React.Component {
  loadSource = () => {
    const { id, picture, token } = this.props
    let url = typeof picture === 'string' ? picture : buildAvatarUrl(id)
    url = url.replace('http://', 'https://')
    return {
      uri: url,
      headers: picture ? {} : { Authorization: `Bearer ${token}` },
    }
  }

  render() {
    const { user } = this.props

    return (
      <TouchableOpacity disabled={!user}>
        <Image style={[Styles.avatar, this.props.style]} source={this.loadSource()} />
      </TouchableOpacity>
    )
  }
}
