import React, { useCallback, useState } from 'react'
import { Animated } from 'react-native'
import { isWeb } from 'APP/Helpers/checkPlatform'

// Components
import ImageMapPicker from 'APP/Components/ImageMapPicker'
import IntakeBottomSheet from '../IntakeBottomSheet'

// Styles
import { Styles } from './style'

const IntakeImageMap = ({ style, title, ...rest }) => {
  const [ready, setReady] = useState(false)
  const onOpen = useCallback(() => setReady(true), [])

  if (isWeb()) {
    return (
      <Animated.View testID="view-intake-image-map-web" style={[Styles.webContainer, style]}>
        <ImageMapPicker includeListView {...rest} ready={ready} />
      </Animated.View>
    )
  }

  return (
    <IntakeBottomSheet
      title={title}
      ContentBodyComponent={<ImageMapPicker {...rest} ready={ready} />}
      style={style}
      scrollStyle={Styles.scroll}
      contentContainerStyle={Styles.contentContainer}
      scrollContentStyle={Styles.scrollContentStyle}
      disableContentPanHandlers
      onOpen={onOpen}
    />
  )
}

export default IntakeImageMap
