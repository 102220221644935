import { StyleSheet } from 'react-native'
import { Metrics, Colors } from 'APP/Themes'

export default StyleSheet.create({
  container: {
    height: '100%',
    width: '100%',
    backgroundColor: Colors.videoBackground,
  },
  buttonRow: {
    justifyContent: 'center',
    flexDirection: 'row',
    position: 'absolute',
    left: Metrics.baseMargin * 2,
    bottom: Metrics.bottomSpace + Metrics.baseMargin / 2,
    right: Metrics.baseMargin * 2,
    zIndex: 4,
    gap: Metrics.baseMargin * 4,
  },
  pubPacketLossOverlayContainer: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 1,
    padding: Metrics.baseMargin,
    top: 0,
    position: 'absolute',
    alignItems: 'flex-end',
    justifyContent: 'start',
  },
})
