import { Platform, Switch } from 'react-native'
import React from 'react'
import { useTheme } from 'styled-components/native'
import { ToggleSwitchProps } from './types'

const ToggleSwitch = ({ checked, onValueChange, disabled, testID }: ToggleSwitchProps) => {
  const { colors } = useTheme()
  return (
    <Switch
      trackColor={{ true: colors.accent, false: colors.switchTrackColor }}
      thumbColor={colors.elementsBg}
      ios_backgroundColor={colors.switchTrackColor}
      value={checked}
      onValueChange={onValueChange}
      disabled={disabled}
      {...Platform.select({
        web: {
          activeThumbColor: colors.elementsBg,
          style: {
            opacity: disabled ? 0.4 : 1,
          },
        },
      })}
      accessibilityLabel="Toggle Switch"
      testID={testID}
    />
  )
}

export default ToggleSwitch
