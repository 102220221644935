import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'

import InactiveInfoCard from 'APP/Components/InfoCard/InactiveInfoCard'
import ZeroEligibilityInfoCard from 'APP/Components/InfoCard/ZeroEligibilityInfoCard'

import Styles from './InactiveInfoCard/style'

const InactiveChatInfoCard = () => {
  const { eligibleServices, eligibilityIntervals } = useSelector((state) => state.patient.profile)
  const isEligible = eligibleServices && Object.keys(eligibleServices).length > 0
  const hasEligibilityIntervals = eligibilityIntervals?.length > 0

  if (isEligible) return null

  if (hasEligibilityIntervals) {
    return (
      <View style={Styles.inactiveContainer} testID={'INACTIVE_INFO_CARD_VIEW'}>
        <InactiveInfoCard testId="inactiveCard" />
      </View>
    )
  } else {
    return (
      <View style={Styles.inactiveContainer} testID={'ZERO_ELIGIBILITY_INFO_CARD_VIEW'}>
        <ZeroEligibilityInfoCard testId="zeroEligibilityCard" />
      </View>
    )
  }
}

export default InactiveChatInfoCard
