import { StyleSheet } from 'react-native'
import { Colors, Fonts, Metrics, ApplicationStyles } from 'APP/Themes'

export default StyleSheet.create({
  card: {
    backgroundColor: Colors.elementsBg,
    borderRadius: 8,
    marginHorizontal: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin,
    ...ApplicationStyles.shadow,
    paddingHorizontal: Metrics.baseMargin,
    paddingTop: Metrics.baseMargin * 0.75,
    paddingBottom: Metrics.baseMargin * 1.25,
  },
  dateRange: {
    ...Fonts.style.bodySmallBold,
    color: Colors.textSecondary,
    textTransform: 'uppercase',
    marginBottom: Metrics.baseMargin,
  },
  progress: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  current: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  checkmark: {
    marginRight: 3,
  },
  weeklyProgress: {
    ...Fonts.style.h3,
    color: Colors.accent,
  },
  achievedWeeklyProgress: {
    color: Colors.accessiblePeppermintText,
  },
  weeklyGoal: {
    ...Fonts.style.h4,
    color: Colors.text,
  },
  progressBar: {
    height: 8,
    borderRadius: 4,
    backgroundColor: Colors.buttonLinen,
    marginTop: Metrics.baseMargin * 0.5,
    overflow: 'hidden',
  },
  currentProgress: {
    height: 8,
    borderRadius: 4,
    backgroundColor: Colors.accent,
  },
  achievedCurrentProgress: {
    backgroundColor: Colors.habitDoneAccent,
  },
  noActivities: {
    ...Fonts.style.bodySmallBold,
    color: Colors.textSecondary,
    textAlign: 'center',
    marginTop: Metrics.baseMargin,
  },
  activity: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: Metrics.baseMargin,
  },
  activityTypeGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  activityIcon: {
    marginRight: Metrics.baseMargin,
  },
  activityType: {
    ...Fonts.style.body,
    color: Colors.text,
  },
  activityDuration: {
    ...Fonts.style.bodySmallBold,
    color: Colors.text,
  },
})
