import React, { useRef, useState, useEffect } from 'react'
import { FlatList, TouchableOpacity, Text, View } from 'react-native'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'

import I18n from 'APP/Services/i18n'
import { getContentIcon, navigateToCms } from 'APP/Lib/ContentHelpers'

import ContentCard from 'APP/Converse/ContentCard'

import Styles, { MAX_WIDTH, LIST_ITEM_MARGIN, StyledHeaderContainer } from './style'
import { Colors } from 'APP/Themes'

const DiscoverNewContent = ({ appComponent, style }) => {
  const flatListRef = useRef()
  const [index, setIndex] = useState(0)

  useEffect(() => {
    flatListRef.current?.scrollToIndex({
      index: index,
      animated: true,
      viewOffset: -LIST_ITEM_MARGIN, // Account for  the margin around the list item
    })
  }, [index])

  const documents = appComponent?.data?.documents
  if (!documents || !documents.length) return null

  const leftScrollDisabled = index == 0
  const rightScrollDisabled = index == documents.length - 1

  const scrollLeft = () => {
    setIndex(Math.max(0, index - 1))
  }
  const scrollRight = () => {
    setIndex(Math.min(documents.length - 1, index + 1))
  }

  const handleOnPress = (item, title) => {
    navigateToCms(item, title, 'DiscoverNewContentScreen', false)
  }

  const renderListItem = (item) => {
    const title = item.title
    const length = `${item.length} ${I18n.t('DiscoverNewContent.length.minutes')}`
    const difficulty = item.difficulty
    const info = difficulty ? `${length} ⋅ ${difficulty}` : length

    return (
      <ContentCard
        title={title}
        info={info}
        icon={getContentIcon(item)}
        imageUrl={item.thumbnail?.url}
        notice={item.notice}
        onPress={() => handleOnPress(item, title)}
        testID={'DOCUMENT_ITEM'}
        maxWidth={MAX_WIDTH}
      />
    )
  }

  return (
    <View style={style}>
      <StyledHeaderContainer>
        <Text style={Styles.header}>{I18n.t('DiscoverNewContent.title')}</Text>
        <TouchableOpacity
          disabled={leftScrollDisabled}
          onPress={scrollLeft}
          style={[Styles.scrollIcon, Styles.scrollIconLeft]}
          testID="scrollLeft"
        >
          <MaterialIcon
            name="chevron-left"
            size={24}
            color={leftScrollDisabled ? Colors.textDisabled : Colors.text}
          />
        </TouchableOpacity>
        <TouchableOpacity
          disabled={rightScrollDisabled}
          onPress={scrollRight}
          style={[Styles.scrollIcon, Styles.scrollIconRight]}
          testID="scrollRight"
        >
          <MaterialIcon
            name="chevron-right"
            size={24}
            color={rightScrollDisabled ? Colors.textDisabled : Colors.text}
          />
        </TouchableOpacity>
      </StyledHeaderContainer>
      <FlatList
        ref={flatListRef}
        data={documents}
        horizontal
        showsHorizontalScrollIndicator={false}
        testID="DISCOVER_NEW_CONTENT_LIST"
        style={Styles.flatList}
        keyExtractor={(item) => item.id}
        renderItem={({ item, index }) => renderListItem(item, index)}
        getItemLayout={(_, index) => {
          const itemWidthWithMargin = MAX_WIDTH + LIST_ITEM_MARGIN * 2
          return {
            length: itemWidthWithMargin,
            offset: itemWidthWithMargin * index,
            index,
          }
        }}
      />
    </View>
  )
}

export default DiscoverNewContent
