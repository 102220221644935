import React from 'react'
import ToastComponent from 'react-native-toast-message'
import { Platform, View, Text } from 'react-native'
import I18n from 'APP/Services/i18n'
import styled from 'styled-components/native'

import { PrimaryTextButton } from '../Buttons'
import AlertModal from 'APP/Components/AlertModal'
import { ErrorToastStyles } from './style'

const StyledAlertModal = styled(AlertModal)`
  ${({ theme }) => theme.shadows.normal};
`

const BaseToast = ({ text1, text2, hide }) =>
  (!!text1 || !!text2) && (
    <StyledAlertModal titleText={text1} subtitleText={text2} handleDismissModal={hide} />
  )

const ErrorToast = ({ text1, text2, hide }) =>
  (!!text1 || !!text2) && (
    <View style={ErrorToastStyles.container}>
      <View style={ErrorToastStyles.copyContainer}>
        <Text style={ErrorToastStyles.title}>{text1}</Text>
        <Text style={ErrorToastStyles.description}>{text2}</Text>
      </View>
      <PrimaryTextButton slim onPress={hide} containerStyle={ErrorToastStyles.cta}>
        {I18n.t('Toast.dismiss')}
      </PrimaryTextButton>
    </View>
  )

const getConfig = (configProps) => ({
  success: (props) => <BaseToast {...configProps} {...props} />,
  error: (props) => <ErrorToast {...configProps} {...props} />,
  info: (props) => <BaseToast {...configProps} {...props} />,
})

const Toast = (props) => <ToastComponent topOffset={0} config={getConfig(props)} />

// exposed for tests
export const MODAL_TOAST_CONFIG = {
  ios: (WrappedComponent) => (props) =>
    (
      <>
        <WrappedComponent {...props} />
        <Toast />
      </>
    ),
  default: (WrappedComponent) => WrappedComponent,
}
export const withModalToast = Platform.select(MODAL_TOAST_CONFIG)

export default Toast
