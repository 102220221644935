import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Fonts, Metrics } from '../../../Themes'

export default StyleSheet.create({
  wrapper: {
    ...ApplicationStyles.shadow,
    backgroundColor: Colors.elementsBg,
    borderRadius: 10,
    marginHorizontal: Metrics.baseMargin,
    marginTop: Metrics.baseMargin * -2.5,
    marginBottom: Metrics.baseMargin * 1.8,
    padding: Metrics.baseMargin,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: Metrics.baseMargin / 2,
  },
  headerCopy: {
    ...Fonts.style.captionBold,
    color: Colors.text,
    textTransform: 'uppercase',
  },
  progressBar: {
    height: 4,
    borderRadius: 2,
    backgroundColor: Colors.textInverted,
    overflow: 'hidden',
  },
  progressBarComplete: {
    height: '100%',
    backgroundColor: Colors.accent,
  },
  challengeGoalCompleted: {
    ...Fonts.style.h4,
    marginTop: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin,
    alignSelf: 'center',
    color: Colors.text,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footerCopy: {
    ...Fonts.style.bodyLarge,
    color: Colors.accent,
  },
})
