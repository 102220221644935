import React, { useState } from 'react'
import { View, Keyboard } from 'react-native'
import { connect } from 'react-redux'
import Spinner from 'react-native-loading-spinner-overlay'
import { useForm } from 'react-hook-form'
import Config from 'APP/Config'

// Actions
import { familyActions } from 'APP/Store/Family'

// Hooks
import useScreenView from 'APP/Hooks/useScreenView'

// Components
import BulletListItem from 'APP/Components/BulletListItem'
import ControllerInput from 'APP/Components/ControllerFields/ControllerInput'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import { ResponsiveView } from 'APP/Converse/Layout'
import Typography from 'APP/Converse/Typography'
import RadioGroup from 'APP/Converse/Inputs/RadioGroup'

// Styling
import Styles from './style'
import { Colors, Images } from 'APP/Themes'

// Regex
import Regex from 'APP/Lib/Regex'

// Services
import I18n from 'APP/Services/i18n'
import Button from 'APP/Converse/Button'

const InviteFamilyMemberScreen = ({ adultInviteRunning, familyMembers, inviteFamilyMember }) => {
  const {
    control,
    handleSubmit,
    register,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: {
      email: null,
    },
  })
  const [userHasAccount, setUserHasAccount] = useState(null)

  const { isSmallScreen } = useScreenView()

  // Returns the error string if the email already exists
  // Otherwise, returns true
  const checkDuplicateFamilyMembers = (email) => {
    const lowerCaseEmail = email?.toLowerCase().trim()
    let isEmailUnique = true
    Object.keys(familyMembers).forEach((key) => {
      if (familyMembers[key].email === lowerCaseEmail) {
        isEmailUnique = false
        return
      }
    })

    if (!isEmailUnique) return I18n.t('FamilyScreen.addAdult.error.alreadyFamilyMember')

    return isEmailUnique
  }

  const renderTexts = () => {
    return (
      <View style={Styles.section}>
        <Typography variant={isSmallScreen ? 'h4' : 'h3'} style={Styles.sectionTitle}>
          {I18n.t('FamilyScreen.addAdult.body')}
        </Typography>

        <View style={Styles.listContainer}>
          <BulletListItem key={1}>{I18n.t('FamilyScreen.addAdult.bullet1')}</BulletListItem>
          <BulletListItem key={2}>{I18n.t('FamilyScreen.addAdult.bullet2')}</BulletListItem>
          <BulletListItem key={3}>{I18n.t('FamilyScreen.addAdult.bullet3')}</BulletListItem>
        </View>
      </View>
    )
  }

  const renderEmailInput = () => {
    return (
      <View style={Styles.section}>
        <ControllerInput
          name="email"
          {...register('email', {
            required: I18n.t('FamilyScreen.addAdult.error.invalidEmail'),
            pattern: {
              value: Regex.email,
              message: I18n.t('FamilyScreen.addAdult.error.invalidEmail'),
            },
            validate: checkDuplicateFamilyMembers,
          })}
          autoCorrect={false}
          spellCheck={false}
          testID="emailFormInput"
          control={control}
          disabled={Config.IS_SSO_ONLY && userHasAccount == null}
          label={
            userHasAccount
              ? I18n.t('FamilyScreen.addAdult.email.alternate')
              : I18n.t('FamilyScreen.addAdult.email.default')
          }
        />
      </View>
    )
  }

  const renderRadioGroup = () => {
    return (
      <View style={Styles.section}>
        <RadioGroup
          label={I18n.t('FamilyScreen.addAdult.hasAccountRadioTitle')}
          currentValue={userHasAccount}
          options={[
            {
              label: I18n.t('ConsentScreen.actions.yes'),
              value: true,
            },
            {
              label: I18n.t('ConsentScreen.actions.no'),
              value: false,
            },
          ]}
          onPress={setUserHasAccount}
        />
      </View>
    )
  }

  const onSubmit = (formData) => {
    const email = formData.email?.toLowerCase().trim()
    inviteFamilyMember(email)
  }

  const handleOnPress = () => {
    Keyboard.dismiss()
    handleSubmit(onSubmit)()
  }

  return (
    <NoticeBarContainer>
      <Spinner visible={adultInviteRunning} cancelable={false} />
      <ResponsiveView style={Styles.containerInviteFam} testID="invite-family-view">
        <View style={Styles.topSection}>
          <Images.adultInviteIcon
            fillLine={Colors.text}
            fillLineInverted={Colors.textInverted}
            testID="adultInviteIcon"
          />
        </View>

        {renderTexts()}

        {Config.IS_SSO_ONLY && renderRadioGroup()}

        {renderEmailInput()}

        <Button
          widthVariant="full"
          title={I18n.t('FamilyScreen.addAdult.button')}
          analyticsName="FamilyScreen.addAdult"
          isLoading={adultInviteRunning}
          onPress={handleOnPress}
          disabled={!isDirty || !isValid}
        />
      </ResponsiveView>
    </NoticeBarContainer>
  )
}

const mapStateToProps = (state, props) => ({
  ...props.route?.params,
  adultInviteRunning: state.family.requestRunning,
  familyMembers: state.family.members,
})

const mapDispatchToProps = (dispatch) => ({
  inviteFamilyMember: (email) => dispatch(familyActions.adultInviteRequest({ email })),
})

export default connect(mapStateToProps, mapDispatchToProps)(InviteFamilyMemberScreen)
