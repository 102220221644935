import React from 'react'

import Styles from './style'
import { Text, View } from 'react-native'

const BannerCard = (props) => {
  const { description, iconTitle, title } = props
  return (
    <View testID={'BUTTON_CARD'} style={Styles.container}>
      <View style={Styles.texts}>
        <View style={Styles.titleContainer}>
          {iconTitle}
          {title && <Text style={Styles.title}>{title}</Text>}
        </View>

        {description && <Text style={Styles.description}>{description}</Text>}
      </View>
    </View>
  )
}

export default BannerCard
