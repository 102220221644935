import { StyleSheet } from 'react-native'
import { Appearance } from 'react-native-appearance'
import { ApplicationStyles, Colors, Fonts, Metrics } from '../../Themes'

const bar = {
  backgroundColor: Colors.elementsBg,
  shadowColor: Colors.shadow,
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.4,
  shadowRadius: 4,
  borderBottomWidth: 1,
  borderBottomColor: Colors.shadow,
}

export const modalTrackerMarkdownAlert = {
  p: {
    marginRight: Metrics.baseMargin * 2,
    marginBottom: 0,
    ...Fonts.style.body,
    lineHeight: Metrics.baseMargin * 1.25,
  },
}

export const modalTrackerMarkdownContent = {
  body: {
    lineHeight: 21,
    marginLeft: Metrics.baseMargin,
  },
  a: {
    color: Colors.accent,
  },
}

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    minHeight: '100%',
  },
  refresh: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%',
  },
  pedometer: {
    ...bar,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: Metrics.baseMargin * 2,
    paddingLeft: Metrics.baseMargin * 1.25,
    paddingRight: Metrics.baseMargin,
    paddingVertical: Metrics.baseMargin,
  },
  pedometerCopy: {
    paddingRight: Metrics.baseMargin * 1.5,
    flex: 1,
  },
  pedometerTitle: {
    ...Fonts.style.subheaderBold,
    color: Colors.text,
    marginBottom: Metrics.baseMargin * 0.25,
  },
  pedometerSubtitle: {
    ...Fonts.style.bodySmall,
    color: Colors.text,
  },
  switch: {
    transform: [{ scaleX: 0.7 }, { scaleY: 0.7 }],
  },
  help: {
    ...bar,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: Metrics.baseMargin,
    paddingHorizontal: Metrics.baseMargin * 1.25,
    paddingVertical: Metrics.baseMargin,
  },
  helpCopy: {
    ...Fonts.style.subheaderBold,
    color: Colors.text,
    flex: 1,
  },
  header: {
    ...Fonts.style.captionBold,
    color: Colors.textSecondary,
    textTransform: 'uppercase',
    marginTop: Metrics.baseMargin * 2,
    marginBottom: Metrics.baseMargin / 2,
    paddingHorizontal: Metrics.baseMargin * 1.25,
  },
  trackerWrapper: {
    ...bar,
  },
  disabledTrackerWrapper: {
    backgroundColor: Colors.selectedBg,
  },
  tracker: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: Metrics.baseMargin * 1.25,
    paddingRight: Metrics.baseMargin,
    paddingVertical: Metrics.baseMargin / 2,
  },
  image: {
    width: 45,
    height: 45,
  },
  name: {
    ...Fonts.style.subheaderBold,
    color: Colors.text,
    flex: 1,
    paddingHorizontal: Metrics.baseMargin / 2,
  },
  cta: {
    ...Fonts.style.button,
    color: Colors.accent,
  },
  disabledCta: {
    ...Fonts.style.button,
    color: Colors.buttonSecondaryDisabled,
  },
  subInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: Metrics.baseMargin * 1.25,
    paddingBottom: Metrics.baseMargin * 0.6,
  },
  syncedCopy: {
    ...Fonts.style.bodySmall,
    color: Colors.text,
    flex: 1,
  },
  reconnectCopy: {
    ...Fonts.style.bodySmall,
    color: Appearance.getColorScheme() === 'dark' ? Colors.text : Colors.errorText,
    flex: 1,
  },
  modalContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: Metrics.statusBarHeight + Metrics.baseMargin,
  },
  modal: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: Colors.appBg,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    ...ApplicationStyles.shadow,
    shadowOffset: {
      height: -4,
    },
  },
  row: {
    flexDirection: 'row',
  },
  modalHeader: {
    paddingVertical: Metrics.baseMargin * 1.25,
    paddingHorizontal: Metrics.baseMargin / 2,
    backgroundColor: Colors.appBg,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    ...ApplicationStyles.shadow,
  },
  modalHeaderClose: {
    position: 'absolute',
    top: Metrics.baseMargin * 0.5,
    left: Metrics.baseMargin,
    zIndex: 1,
  },
  modalHeaderTitle: {
    flex: 1,
    ...Fonts.style.h2,
    color: Colors.text,
    textAlign: 'center',
  },
  modalTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: Metrics.baseMargin * 2,
    marginLeft: Metrics.baseMargin,
  },
  modalTitleImage: {
    width: 45,
    height: 45,
  },
  modalTitle: {
    ...Fonts.style.h3,
    padding: 16 * 0.85,
    color: Colors.text,
  },
  modalSubtitle: {
    ...Fonts.style.h4,
    paddingHorizontal: Metrics.baseMargin * 1.25,
    color: Colors.text,
    marginBottom: Metrics.baseMargin,
  },
  modalContent: {
    paddingHorizontal: Metrics.baseMargin * 1.25,
    paddingTop: Metrics.baseMargin,
  },
  modalAlert: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: Colors.bannerBg,
    borderRadius: 16,
    paddingVertical: Metrics.baseMargin,
    paddingHorizontal: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin,
    marginHorizontal: 16 / 2,
  },
  modalAlertIcon: {
    marginRight: Metrics.baseMargin * 0.5,
  },
  modalTrackerCta: {
    marginBottom: Metrics.baseMargin * 2,
  },
})
