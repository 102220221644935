import React from 'react'
import { View } from 'react-native'

import Styles from './style'

// FYI, in order for `injectedJavaScript` to work on web (iframe), additional work will be needed. Bye dark mode, for now.
const WebContentView = ({ onLoad, uri }) => {
  return (
    <View style={Styles.container} testID="webContentView">
      <iframe
        style={{
          borderWidth: '0',
          padding: '2rem',
        }}
        src={uri}
        onLoad={() => onLoad && onLoad()}
        height={'100%'}
        allow="fullscreen"
      />
    </View>
  )
}

export default WebContentView
