import { StyleSheet } from 'react-native'
import { Colors, ApplicationStyles, Fonts } from 'APP/Themes'

export const Styles = StyleSheet.create({
  ...ApplicationStyles.screen,
  container: {
    flex: 1,
  },
  raised: {
    ...ApplicationStyles.shadow,
  },
  text: {
    fontSize: Fonts.size.regular,
    color: Colors.text,
    minHeight: Fonts.size.regular,
    alignSelf: 'center',
  },
  btnText: {
    color: Colors.text,
    flexShrink: 1,
    paddingLeft: 14,
    textTransform: 'uppercase',
  },
  boldText: {
    fontWeight: '700',
  },
  bubble: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Colors.elementsBg,
    paddingVertical: 8,
    paddingHorizontal: 12,
    marginTop: 8,
  },
  bubbleOptions: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
    paddingHorizontal: 12,
    marginTop: 16,
  },
  bubbleText: {
    fontSize: 16,
    color: Colors.text,
    fontWeight: '300',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
    marginRight: 9,
    borderColor: Colors.grayBg,
    borderWidth: 1,
    borderRadius: 4,
  },
  buttonText: {
    fontSize: 16,
    color: Colors.text,
  },
  forwardIcon: {
    width: 20,
    height: 20,
    tintColor: Colors.accent,
    transform: [{ rotate: '180deg' }],
  },
  bodyPartAnswer: {
    width: '100%',
    aspectRatio: 0.75,
    marginTop: 8,
    backgroundColor: Colors.elementsBg,
    maxWidth: 320,
  },
  bodyPartQuestion: {
    justifyContent: 'space-between',
  },
  inChatIcon: {
    tintColor: Colors.accent,
    resizeMode: 'contain',
    height: 20,
    width: 20,
  },
})
