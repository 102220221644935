import { StyleSheet } from 'react-native'
import { Colors, ApplicationStyles } from 'APP/Themes'

export const Styles = StyleSheet.create({
  ...ApplicationStyles.screen,
  noticeContainer: {
    backgroundColor: Colors.elementsBg,
    display: 'flex',
    flex: 0,
    paddingTop: 0,
    paddingBottom: 0,
    zIndex: 1,
  },
})
