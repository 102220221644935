import Config from 'APP/Config'

export const LD_FEATURES = [
  {
    name: 'showProviderShortName',
    ldKey: 'show-provider-short-name',
    defaultValue: false,
  },
  {
    name: 'enableAfterCallAppRatingPrompt',
    ldKey: 'enable-after-call-app-rating-prompt',
    defaultValue: false,
  },
  {
    name: 'showTypingIndicator',
    ldKey: 'show-typing-indicator-for-chart',
    defaultValue: false,
  },
  {
    name: 'promotedContent',
    ldKey: 'promoted-content',
    defaultValue: {},
  },
  {
    name: 'organizationEnrolmentCode',
    ldKey: 'org_enrolment_code',
    defaultValue: true,
  },
  {
    name: 'habitNotifications',
    ldKey: 'enable-habit-notifications',
    defaultValue: false,
  },
  {
    name: 'weeklyActiveMinutes',
    ldKey: 'weekly-active-minutes',
    defaultValue: false,
  },
  {
    name: 'pronounDisplay',
    ldKey: 'pronoun-display',
    defaultValue: true,
  },
  {
    name: 'showEstimationComponent',
    ldKey: 'show-estimation-component-in-chat',
    defaultValue: false,
  },
  {
    name: 'showEstimatedComponentForQueues',
    ldKey: 'show-estimated-time-for-queues',
    defaultValue: {},
  },
  {
    name: 'readReceipts',
    ldKey: 'read-receipts',
    defaultValue: false,
  },
  {
    name: 'showCommitmentScreen',
    ldKey: 'show-commitment-screen',
    defaultValue: false,
  },
  {
    name: 'terraDrivenWellness',
    ldKey: 'terra-driven-wellness',
    defaultValue: true,
  },
  {
    name: 'enableGoogleFit',
    ldKey: 'enable-google-fit',
    defaultValue: false,
  },
  {
    name: 'showV2AppointmentBooking',
    ldKey: 'show-v2-appointment-booking',
    defaultValue: true,
  },
  {
    name: 'useHealthProfile',
    ldKey: 'use-health-profile',
    defaultValue: false,
  },
  {
    name: 'enableArchiveEpisode',
    ldKey: 'enable-archive-episode',
    defaultValue: false,
  },
  {
    name: 'stressScoreToWBI',
    ldKey: 'stress-score-to-wbi',
    defaultValue: false,
  },
  {
    name: 'confirmCancelAppointment',
    ldKey: 'confirm-cancel-appointment',
    defaultValue: false,
  },
  {
    name: 'confirmNoneOfTheseTimesWorkForMe',
    ldKey: 'confirm-none-of-these-times-work-for-me',
    defaultValue: false,
  },
  {
    name: 'defaultHealthIssueTypeId',
    ldKey: 'default-health-issue-type-id',
    defaultValue: false,
  },
  {
    name: 'rescheduleAppointmentMX',
    ldKey: 'reschedule-appointment-mx',
    defaultValue: false,
  },
  {
    name: 'appleHealthClientSideBackfill',
    ldKey: 'apple-health-client-side-backfill',
    defaultValue: false,
  },
  {
    name: 'useBenefitsNavigation',
    ldKey: 'use-benefits-navigation',
    defaultValue: false,
  },
  {
    name: 'useBenefitsNavigationBrowse',
    ldKey: 'use-benefits-navigation-browse',
    defaultValue: false,
  },
  {
    name: 'enableSMSAppointmentReminders',
    ldKey: 'enable-sms-appointment-reminders',
    defaultValue: false,
  },
  {
    name: 'highlightProviderSelectionPreferences',
    ldKey: 'highlight-provider-selection-preferences',
    defaultValue: false,
  },
  {
    name: 'importantCommunicationBannerContent',
    ldKey: 'important-communication-banner-v2',
    defaultValue: {},
  },
  {
    name: 'displayMemberCharter',
    ldKey: 'display-member-charter',
    defaultValue: false,
  },
  {
    name: 'enabledSprigEvents',
    ldKey: 'enabled-sprig-events',
    defaultValue: {},
  },
  {
    name: 'takeATour',
    ldKey: 'take-a-tour',
    defaultValue: false,
  },
  {
    name: 'useSSOLogin',
    ldKey: 'use-sso-login',
    defaultValue: false,
  },
  {
    name: 'useSSOAccountCreation',
    ldKey: 'use-sso-account-creation',
    defaultValue: false,
  },
  {
    name: 'newNotificationCenter',
    ldKey: 'new-notification-center',
    defaultValue: false,
  },
  {
    name: 'enableIntakeBackButton',
    ldKey: 'enable-intake-back-button',
    defaultValue: false,
  },
  {
    name: 'enableMainAction',
    ldKey: 'enable-main-action',
    defaultValue: false,
  },
  {
    name: 'enableSoftDisablingChat',
    ldKey: 'enable-soft-disabling-chat',
    defaultValue: false,
  },
] as const

export type LD_FEATURES_TYPE = {
  [K in (typeof LD_FEATURES)[number]['name']]: K extends 'promotedContent' // Check if the property key is 'promotedContent'
    ? Record<string, unknown> // If so, allow objects with any string key and any unknown value
    : boolean // Otherwise, use the default value type from LD_FEATURES
}
