import { StyleSheet } from 'react-native'
import { ApplicationStyles, Colors, Metrics, Fonts } from 'APP/Themes'

const text = {
  ...Fonts.style.body,
  color: Colors.text,
  lineHeight: 21,
}

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  splashLogo: {
    resizeMode: 'contain',
    alignSelf: 'baseline',
    marginLeft: Metrics.baseMargin,
    marginTop: Metrics.baseMargin,
    marginBottom: Metrics.baseMargin,
    height: 45,
  },
  scrollContainer: {
    flex: 1,
    backgroundColor: Colors.appBg,
  },
  container: {
    flex: 1,
    paddingHorizontal: Metrics.baseMargin * 2,
    paddingTop: Metrics.baseMargin * 2,
    paddingBottom: Metrics.baseMargin,
  },
  text,
  bold: {
    ...text,
    fontWeight: 'bold',
  },
  boldNotify: {
    ...text,
    fontWeight: 'bold',
    paddingBottom: Metrics.baseMargin / 2,
  },
  title: {
    ...Fonts.style.h1,
    color: Colors.text,
    marginLeft: Metrics.baseMargin,
    paddingBottom: 10,
  },
  bulletStyle: {
    color: Colors.text,
    marginLeft: Metrics.baseMargin,
  },
  imageSection: {
    paddingTop: 25,
    paddingBottom: 25,
    alignSelf: 'center',
  },
  primaryButton: {
    marginHorizontal: 0,
    marginBottom: Metrics.baseMargin,
  },
})
