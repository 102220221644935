import { select, put } from 'redux-saga/effects'
import sprigClient from 'APP/Services/Sprig'

import AppSessionActions from 'APP/Redux/AppSessionRedux'
export const isConnectedState = (state) => state.appSession.isConnected
export const isDisconnectedState = (state) => !state.appSession.isConnected
export const isActive = (state) => state.appSession.isActive
export const appSessionState = (state) => state.appSession
export const profileState = (state) => state.patient?.profile
export const eligibleServicesState = (state) => state.patient?.profile?.eligibleServices

export function* setIsConnectedIfDisconnected() {
  if (yield select(isDisconnectedState)) {
    yield put(AppSessionActions.setIsConnected(true))
  }
}

export function* handleNetworkErrors(err) {
  const errorName = typeof err === 'object' && err.name
  if (errorName === 'NETWORK_ERROR' || errorName === 'TIMEOUT_ERROR') {
    if (yield select(isConnectedState)) yield put(AppSessionActions.setIsConnected(false))
  }
}

export const WELLNESS_ELLIGIBLE_SERVICES = [
  'wellness_challenges',
  'wellness_content',
  'wellness_habits',
]
export function* updateSprigVisitorAttributes() {
  const eligibleServices = yield select(eligibleServicesState)
  const hasWellness = Object.entries(eligibleServices).some(
    ([key, value]) => WELLNESS_ELLIGIBLE_SERVICES.includes(key) && value
  )
  const profile = yield select(profileState)

  sprigClient.setVisitorAttribute('has_wellness', String(hasWellness))
  sprigClient.setVisitorAttribute('sex_at_birth', String(profile?.sex))
  sprigClient.setVisitorAttribute('gender_identity', String(profile?.genderIdentity))
}
