import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Animated } from 'react-native'

// Components
import DialogueAutolink from 'APP/Components/DialogueAutolink'
import DialogueMarkdown from 'APP/Components/DialogueMarkdown'

// Styles
import styled, { useTheme } from 'styled-components/native'
import { INTAKE_MAX_WIDTH } from 'APP/Components/Intake/constants'

import { QuestionDescription } from 'APP/Converse/Intake/QuestionDescription'
import IntakeWaitingSteps from 'APP/Components/Intake/IntakeWaitingSteps'
import { DescriptionStyle } from 'APP/Services/Mattermost/types'

const QuestionContainer = styled(Animated.View)`
  flex-shrink: 3;
  flex-grow: 0;
  width: 100%;
  max-width: ${INTAKE_MAX_WIDTH};
  align-self: center;
  padding-bottom: ${({ theme }) => theme.metrics.baseMargin * 2};
`

const QuestionContainerDropdown = styled(QuestionContainer)`
  padding-bottom: 0;
`

const QuestionScrollView = styled.ScrollView`
  padding-horizontal: ${({ theme }) => theme.metrics.baseMargin * 2};
  max-width: 600px;
`

const QuestionAutolink = styled(DialogueAutolink)`
  font-family: ${({ theme }) => theme.fonts.fontTypes.header};
  font-size: ${({ theme }) => theme.fonts.fontSize.h2};
  line-height: ${({ theme }) => theme.fonts.lineHeight.h2};
  color: ${({ theme }) => theme.colors.text};
  align-self: flex-start;
  margin-top: ${({ theme }) => theme.metrics.baseMargin * 1.5};
  padding-bottom: ${({ theme }) => theme.metrics.baseMargin};
`

const SubQuestionAutolink = styled(DialogueAutolink)`
  font-family: ${({ theme }) => theme.fonts.fontTypes.header};
  font-size: ${({ theme }) => theme.fonts.fontSize.h3};
  line-height: ${({ theme }) => theme.fonts.lineHeight.h3};
  color: ${({ theme }) => theme.colors.text};
  align-self: flex-start;
  margin-top: ${({ theme }) => theme.metrics.baseMargin * 1.5};
`

const IntakeQuestion = ({
  post: postProp,
  onQuestionShow,
  currentStepIndex,
  multiPromptUtteranceKey = null,
  scrollEnabled,
  animated = true,
  isReversedTransition,
}) => {
  const theme = useTheme()
  const [post, setPost] = useState()
  const scrollRef = useRef()
  const animatedState = useRef(new Animated.Value(0)).current
  const isHorizontalTransition =
    post?.props?.member_app_fullscreen?.transition_direction === 'horizontal'
  const transformType = isHorizontalTransition ? 'translateX' : 'translateY'

  const markdownStyles = {
    p: {
      fontFamily: theme.fonts.fontTypes.light,
      fontSize: theme.fonts.fontSize.regular,
      lineHeight: theme.fonts.lineHeight.regular,
      minWidth: 220,
      color: theme.colors.dim,
    },
    li: {
      fontFamily: theme.fonts.fontTypes.light,
      fontSize: theme.fonts.fontSize.regular,
      lineHeight: theme.fonts.lineHeight.regular,
      color: theme.colors.dim,
    },
    imageWrapper: {
      height: 340,
    },
  }

  const cardMarkdownStyles = {
    p: {
      fontFamily: theme.fonts.fontTypes.light,
      fontSize: theme.fonts.fontSize.regular,
      lineHeight: 21,
      minWidth: 220,
    },
    imageWrapper: {
      height: 340,
    },
  }

  const questionContainerAnimatedStyle = {
    transform: [
      {
        [transformType]: animatedState.interpolate({
          inputRange: [0, 1, 2],
          outputRange: isReversedTransition ? [-100, 0, 100] : [100, 0, -100],
        }),
      },
    ],
    opacity: animatedState.interpolate({ inputRange: [0, 1, 2], outputRange: [0, 1, 0] }),
  }
  useEffect(() => {
    if (post && postProp && post.id === postProp.id && post !== postProp) {
      setPost(postProp)
    }
  }, [postProp])

  useEffect(() => {
    Animated.timing(animatedState, {
      toValue: 2,
      duration: animated ? 300 : 0,
      useNativeDriver: true,
    }).start(({ finished }) => {
      if (finished) {
        if (scrollRef.current) {
          scrollRef.current.scrollTo({ y: 0, animated: false })
        }
        setPost(postProp)
        animatedState.setValue(0)
        Animated.spring(animatedState, {
          toValue: 1,
          damping: animated ? 13 : 100,
          useNativeDriver: true,
        }).start()

        if (postProp && onQuestionShow) {
          onQuestionShow()
        }
        if (!postProp && multiPromptUtteranceKey) {
          onQuestionShow()
        }
      }
    })
  }, [postProp && postProp.id, animated, multiPromptUtteranceKey])

  const { message: message, props: { description, member_app_fullscreen } = {} } = post || {}
  const renderQuestionHeader = useCallback(() => {
    if (multiPromptUtteranceKey) {
      return <SubQuestionAutolink text={message} />
    }
    return <QuestionAutolink text={message} />
  }, [message])

  const isPromptChoiceSingleAndDropdown =
    postProp?.props?.type === 'prompt_choice_single' &&
    postProp?.props?.choices_display_variant === 'dropdown'

  const ContainerComponent = isPromptChoiceSingleAndDropdown
    ? QuestionContainerDropdown
    : QuestionContainer

  return (
    <ContainerComponent style={questionContainerAnimatedStyle}>
      {message && (
        <QuestionScrollView
          ref={scrollRef}
          alwaysBounceVertical={false}
          scrollEnabled={scrollEnabled}
        >
          {renderQuestionHeader()}
          {(description || member_app_fullscreen?.loading?.steps) && (
            <QuestionDescription member_app_fullscreen={member_app_fullscreen}>
              <DialogueMarkdown
                styles={
                  member_app_fullscreen?.description_style === DescriptionStyle.card
                    ? cardMarkdownStyles
                    : markdownStyles
                }
                markdown={description}
              />
              {member_app_fullscreen?.loading && (
                <IntakeWaitingSteps
                  steps={member_app_fullscreen?.loading.steps}
                  currentStepIndex={currentStepIndex}
                />
              )}
            </QuestionDescription>
          )}
        </QuestionScrollView>
      )}
    </ContainerComponent>
  )
}

export default IntakeQuestion
