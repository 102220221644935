import { StyleSheet } from 'react-native'
import { Metrics } from 'APP/Themes'

export const Styles = StyleSheet.create({
  subPromptQuestionContainer: {
    flexGrow: 1,
  },
  flatListContainer: {
    flexGrow: 1,
  },
  footer: {
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  listContainer: {
    flexGrow: 1,
  },
  conditionalOverlayForm: {
    marginBottom: Metrics.baseMargin,
    marginTop: 0,
  },
})
