import { StyleSheet } from 'react-native'
import { ApplicationStyles, Metrics, Colors } from '../../Themes'

export default StyleSheet.create({
  ...ApplicationStyles.screen,
  scrollContainer: {
    minHeight: '100%',
  },
  securityHeader: {
    display: 'flex',
    width: '100%',
    alignSelf: 'center',
    gap: Metrics.baseMargin * 0.625,
    paddingHorizontal: Metrics.baseMargin * 1.5,
    paddingTop: Metrics.baseMargin * 1.5,
  },
  container: {
    display: 'flex',
    width: '100%',
    alignSelf: 'center',
    paddingHorizontal: Metrics.baseMargin * 1.5,
  },
  section: {
    marginTop: 20,
    backgroundColor: Colors.elementsBg,
    padding: Metrics.baseMargin,
    borderRadius: 20,
    ...ApplicationStyles.shadow,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 10,
  },
  bold: {
    fontWeight: 'bold',
  },
  appLockSection: {
    gap: Metrics.baseMargin * 1.5,
  },
  appLockContainer: {
    gap: 10,
    marginTop: Metrics.baseMargin * 1.75,
    marginBottom: Metrics.baseMargin * 0.5,
  },
  description: {
    color: Colors.textSecondary,
  },
  mfaDivider: {
    marginTop: Metrics.baseMargin * 1.75,
  },
  headerDivider: {
    marginTop: Metrics.baseMargin * 0.75,
  },
})
