import React, { Component } from 'react'
import { Animated, Image, Text, View, PanResponder } from 'react-native'
import { connect } from 'react-redux'

// Actions
import LoggerActions from 'APP/Redux/LoggerRedux'

// Styling
import Styles from './style'
import { Images, Metrics } from 'APP/Themes'

//Components
import MattermostAvatar from 'APP/Components/MattermostAvatar'

// Services
import I18n from 'APP/Services/i18n'
import { navigationRef as Nav, CONVERSATION_ROUTES, NOTIFICATION_BLACKLIST_ROUTES } from 'APP/Nav'

export const MESSAGE_AUTO_HIDE_TIMEOUT = 7000

const OFFSCREEN_OFFSET = -(100 + Metrics.statusBarHeight)

class InAppMessageNotification extends Component {
  state = {
    pan: new Animated.ValueXY({ x: 0, y: OFFSCREEN_OFFSET }),
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.shouldNotify) {
      this.show(nextProps)
    } else {
      const dismissedByChannelVisit =
        this.active && nextProps.onConversationOfPost && !this.props.onConversationOfPost
      if (dismissedByChannelVisit) {
        this.props.log(`InAppMessageNotification hidden by visiting channel ${this.props.postId}`)
      }
      this.hide()
    }
  }

  componentWillUnmount() {
    clearTimeout(this.autoHideTimeout)
  }

  panResponder = PanResponder.create({
    onStartShouldSetPanResponder: () => true,
    onMoveShouldSetPanResponder: () => true,
    onPanResponderMove: Animated.event(
      [
        null,
        {
          dy: this.state.pan.y,
        },
      ],
      { useNativeDriver: false }
    ),
    onPanResponderStart: (e) => {
      this.dragYStart = e.nativeEvent.pageY
    },
    onPanResponderRelease: (e) => {
      const dragYOffset = e.nativeEvent.pageY - this.dragYStart
      if (Math.abs(dragYOffset) < 2) {
        // Tapped
        this.goToEpisode()
      } else if (dragYOffset < -10) {
        // Swiped up
        this.hide()
        this.props.log(`InAppMessageNotification hidden by swipe (postId: ${this.props.postId})`)
      } else {
        // Swiped down. (reset timeout)
        this.show()
      }
    },
  })

  goToEpisode = () => {
    if (!this.active) return
    const { channelId, onConversationScene } = this.props
    this.hide()
    this.props.log(`InAppMessageNotification tapped (postId: ${this.props.postId})`)
    setTimeout(() => {
      if (onConversationScene) {
        // Technically will never trigger, as none of the conversation screens show this notification.
        Nav.setParams({ channelId })
      } else {
        Nav.navigate('conversation', {
          channelId: channelId,
        })
      }
    }, 333)
  }

  hide = () => {
    this.active = false
    clearTimeout(this.autoHideTimeout)
    Animated.spring(this.state.pan, {
      toValue: { x: 0, y: OFFSCREEN_OFFSET },
      useNativeDriver: false,
    }).start()
  }

  show = ({ message, avatarUrl, name, postId } = this.props) => {
    if (this.props.postId !== postId && !!postId) {
      this.setState({ message, avatarUrl, name })
      if (!this.active) {
        this.props.log(`InAppMessageNotification shown (postId: ${postId})`)
        this.active = true
      }
      Animated.spring(this.state.pan, { toValue: { x: 0, y: 0 }, useNativeDriver: false }).start()
      clearTimeout(this.autoHideTimeout)
      this.autoHideTimeout = setTimeout(() => {
        this.hide()
        this.props.log(`InAppMessageNotification hidden by timeout (postId: ${postId})`)
      }, MESSAGE_AUTO_HIDE_TIMEOUT)
    }
  }

  render() {
    const { name, message, avatarUrl } = this.props
    return (
      <Animated.View
        {...this.panResponder.panHandlers}
        style={[this.state.pan.getLayout(), Styles.container]}
      >
        <View style={Styles.messageContainer} onLayout={this.handleOnLayout}>
          <View style={Styles.avatarContainer}>
            {avatarUrl ? (
              <MattermostAvatar picture={avatarUrl} />
            ) : (
              <Image style={Styles.defaultAvatar} source={Images.logoNurse} />
            )}
          </View>
          <View style={Styles.messageTextContainer}>
            <Text numberOfLines={1} ellipsizeMode={'tail'} style={Styles.name}>
              {name}
            </Text>
            <Text numberOfLines={1} ellipsizeMode={'tail'} style={Styles.message}>
              {message}
            </Text>
          </View>
        </View>
      </Animated.View>
    )
  }
}

const mapStateToProps = ({ history }) => {
  const {
    currentChannelId,
    inAppNotificationPostId: postId,
    inAppNotificationChannelId: channelId,
  } = history

  const channel = (history.channels && history.channels[channelId]) || {}
  const post = (channel.posts && channel.posts[postId]) || {}
  const practitioner = (history.practitioners && history.practitioners[post.user_id]) || {}

  // Returned episodes are branded for multi-eligibility
  // Branded episodes is a feature flag in LD.
  const orderedEpisodes = history.orderedEpisodes || []
  const onBrand = orderedEpisodes.indexOf(channelId) >= 0

  const curRouteName = Nav.isReady() ? Nav.getCurrentRoute()?.name : ''

  const onNotifyingScene = NOTIFICATION_BLACKLIST_ROUTES.indexOf(curRouteName) === -1
  const onConversationScene = CONVERSATION_ROUTES.indexOf(curRouteName) !== -1
  const onConversationOfPost = onConversationScene && post.channel_id === currentChannelId
  const shouldNotify = postId && onNotifyingScene && !onConversationOfPost && onBrand
  const name = [practitioner.first_name, practitioner.last_name].filter((el) => el).join(' ')
  return {
    onConversationScene,
    onConversationOfPost,
    channelId: post.channel_id,
    postId: post.id,
    message: post.message,
    avatarUrl: practitioner.avatar_url,
    name: name || I18n.t('Common.clinic'),
    shouldNotify,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    log: (message) => dispatch(LoggerActions.log(message)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InAppMessageNotification)
