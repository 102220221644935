import React, { useState, useEffect, forwardRef } from 'react'
import { View, Animated } from 'react-native'
import RNDateTimePicker from '@react-native-community/datetimepicker'
import Moment from 'moment'
import Styles from './style'

import { Format } from 'APP/Lib/ProfileDataFormat'
import { DateSheetPropsIos } from './types.d'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import I18n from 'APP/Services/i18n'
import Typography from 'APP/Converse/Typography'
import { MAX_USER_AGE } from 'APP/Config/constants'

export const DateSheetSpacer = forwardRef<View, { show: boolean }>(({ show }, ref) => {
  if (!show) return null
  return <View ref={ref} style={Styles.dateSheetSpacer} />
})

const DateSheet = ({
  show = false,
  height = 216,
  duration = 300,
  mode = 'date',
  display = 'spinner',
  maximumDate = Moment().toDate(),
  minimumDate = Moment().subtract(MAX_USER_AGE, 'y').toDate(),
  date,
  onChange,
  onDone,
}: DateSheetPropsIos) => {
  const [animatedHeight, setAnimatedHeight] = useState(new Animated.Value(0))
  const initialDate = Moment().subtract(50, 'y')

  useEffect(() => {
    if (show) {
      Animated.timing(animatedHeight, {
        toValue: height,
        duration: duration,
        useNativeDriver: false,
      }).start()
    } else {
      setAnimatedHeight(new Animated.Value(0))
    }
  }, [show])

  const handleOnDone = () => {
    if (date) onDone()
    else onDone(initialDate.format(Format.STORE_DATE))
  }

  return (
    <View style={Styles.dateSheetIOS}>
      {Boolean(onDone) && (
        <DialogueTouchableOpacity onPress={handleOnDone} analyticsName="Done">
          <Typography style={Styles.done}>{I18n.t('Common.formNavBar.done')}</Typography>
        </DialogueTouchableOpacity>
      )}
      <RNDateTimePicker
        value={date || initialDate.toDate()}
        mode={mode}
        onChange={onChange}
        display={display}
        maximumDate={maximumDate}
        minimumDate={minimumDate}
        testID="BASE_DATE_PICKER"
      />
    </View>
  )
}

export default DateSheet
