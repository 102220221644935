import React, { useCallback, useContext, useEffect, useRef, useState, memo } from 'react'
import { View, TouchableOpacity, Text, ActivityIndicator } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import PatientHistoryActions from 'APP/Redux/PatientHistoryRedux'
import { ChatContext } from 'APP/Lib/Context'
import Post from 'APP/Components/FelixChat/Post'
import I18n from 'APP/Services/i18n'
import InputBar from 'APP/Components/FelixChat/InputBar'
import { PostContext } from 'APP/Lib/Context'
import Notices from 'APP/Components/FelixChat/Notices'
import moment from 'moment'
import { Styles } from './style'
import { Colors } from 'APP/Themes'
import { isMobile, isWeb } from 'APP/Helpers/checkPlatform'
import { ResponsiveFlatList, ResponsiveView } from 'APP/Converse/Layout'
import { EstimatedTimeComponent } from 'APP/Converse/Chat/EstimatedTimeComponent'
import { TypingIndicator } from 'APP/Converse/Chat/TypingIndicator'
import Icon from 'APP/Converse/Icon'
import { CompleteCard } from 'APP/Components/FelixChat/Conversation/CompleteCard'
import Config from 'APP/Config'

const BOTTOM_OF_CHAT_OFFSET = 70

const maintainVisibleContentPosition = {
  minIndexForVisible: 0,
  autoscrollToTopThreshold: 0,
}

const PostProvider = memo(function PostProvider({
  item,
  users,
  order,
  index,
  orderedPosts,
  encounters,
}) {
  const currentUserId = item.user_id
  const isMember = currentUserId == users[0].id
  const isLastPost = order[0] === item.id
  const postUser = users.find((u) => u.id === item.user_id)
  const previousPost = orderedPosts?.[index + 1]
  const previousUserId = previousPost?.user_id
  const nextUserId = orderedPosts?.[index - 1]?.user_id
  const previousPostIsSameUser = previousUserId === currentUserId
  const nextPostIsSameUser = nextUserId === currentUserId
  const encounter = encounters?.[item?.actionContext?.encounterId]

  const previousPostIsSameDay = previousPost
    ? moment(item.create_at).isSame(previousPost?.create_at, 'day')
    : false
  return (
    <PostContext.Provider
      key={item.id}
      value={{
        key: item.id,
        post: item,
        postUser,
        isMember,
        previousPostIsSameUser,
        nextPostIsSameUser,
        previousPostIsSameDay,
        encounter,
        isLastPost,
      }}
    >
      <Post />
    </PostContext.Provider>
  )
})

const Conversation = () => {
  const dispatch = useDispatch()
  const {
    order,
    orderedPosts,
    users,
    conversation,
    channelId,
    lastPost,
    userId,
    episode,
    typingEvent,
  } = useContext(ChatContext)
  const { enableSoftDisablingChat, showEstimationComponent, showEstimatedComponentForQueues } = useSelector(
    (state) => state.features
  )

  const isEpisodeComplete =
    Config.IS_EPISODE_CLOSE_FEATURE_ACTIVE && episode?.outcomeId === 'complete'

  const shouldShowEstimatedTimeForQueue =
    showEstimatedComponentForQueues &&
    Object.keys(showEstimatedComponentForQueues).length > 0 &&
    showEstimatedComponentForQueues[episode?.queueId]

  // Hide estimated-time component in MH ER and Crisis episodes
  const hideEstimatedTimeInEpisode =
    episode?.outcomeId === 'mhs_coaching' &&
    (episode?.priorityName === 'Crisis' || episode?.priorityName === 'ER')

  const shouldShowEstimatedTimeComponent =
    showEstimationComponent && shouldShowEstimatedTimeForQueue && !hideEstimatedTimeInEpisode

  const encounters = useSelector((state) => state.history.encounters)
  const scrollViewRef = useRef()

  const [isAtBottom, setIsAtBottom] = useState(true)
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false)

  const prevLastPost = useRef()

  const isTyping =
    typingEvent?.broadcast?.channel_id === channelId &&
    typingEvent?.data?.user_id.endsWith(episode?.assigneeId)

  useEffect(() => {
    const receivedNewPost = prevLastPost.current && lastPost?.id !== prevLastPost.current?.id
    const lastPostIsFromUser = lastPost?.user_id === userId
    if (receivedNewPost && !isAtBottom && !lastPostIsFromUser) {
      setHasUnreadMessages(true)
    }
    if (receivedNewPost && isAtBottom) {
      setTimeout(() => scrollToBottom(), 0)
    }
    prevLastPost.current = lastPost
  }, [isAtBottom, lastPost, userId])

  const checkIsAtBottom = useCallback(
    (scrollOffset) => {
      const newIsAtBottom = scrollOffset < BOTTOM_OF_CHAT_OFFSET
      if (isAtBottom !== newIsAtBottom) {
        if (newIsAtBottom) {
          setHasUnreadMessages(false)
        }
        setIsAtBottom(newIsAtBottom)
      }
    },
    [isAtBottom, setIsAtBottom, setHasUnreadMessages]
  )

  const onScroll = useCallback(
    (event) => {
      const { layoutMeasurement, contentSize, contentOffset } = event.nativeEvent
      checkIsAtBottom(contentOffset.y, layoutMeasurement.height, contentSize.height)
    },
    [checkIsAtBottom]
  )

  const scrollToBottom = (animated = true) => {
    setIsAtBottom(true)
    setHasUnreadMessages(false)
    scrollViewRef?.current?.scrollToOffset({ offset: 0, animated })
  }

  const onReachedEndOfChat = useCallback(() => {
    if (!conversation.fetchingPosts && !conversation.allOlderPostsFetched) {
      dispatch(PatientHistoryActions.fetchOlderPostsForChannel(channelId))
    }
  }, [conversation.fetchingPosts, conversation.allOlderPostsFetched, dispatch])

  const renderPost = useCallback(
    ({ item, index }) => {
      const postProvider = (
        <PostProvider
          item={item}
          index={index}
          users={users}
          order={order}
          orderedPosts={orderedPosts}
          encounters={encounters}
        />
      )

      if (isWeb()) {
        return <View style={Styles.webViewPostContainer}>{postProvider}</View>
      }

      return postProvider
    },
    [users, order, orderedPosts, encounters]
  )

  const keyExtractor = useCallback((props) => props.id, [])

  /**
   * TODO: In web, the user will not be able to / have a hard time to tab to the input bar due to the `inverted` property. In the DOM, the messages are in inverted order. However, this property is needed for now to be able to fetch old messages (and to unblock from launch). Eventually, a better solution should be implemented to make the chat accessible.
   */
  return (
    <View style={Styles.container}>
      <Notices channelId={channelId} conversation={conversation} />
      <ResponsiveFlatList
        testID="conversation"
        ref={scrollViewRef}
        style={Styles.scrollViewContainer}
        contentContainerStyle={Styles.contentContainerStyle}
        data={orderedPosts}
        renderItem={renderPost}
        keyExtractor={keyExtractor}
        onScroll={onScroll}
        onEndReached={onReachedEndOfChat}
        onEndReachedThreshold={0.5}
        maintainVisibleContentPosition={maintainVisibleContentPosition}
        ListHeaderComponent={
          isTyping &&
          episode && (
            <View style={isMobile() && Styles.webViewPostContainer}>
              <TypingIndicator
                avatarSource={episode.assigneePicture}
                assigneName={episode.assigneeFirstName}
              />
            </View>
          )
        }
        inverted={isWeb()}
      />
      {!isEpisodeComplete && conversation.fetchingPosts ? (
        <View style={Styles.loadingContainer}>
          <ActivityIndicator color={Colors.text} testID="spinner" />
          <Text style={Styles.loadingText}>{I18n.t('ConversationScreen.loading')}</Text>
        </View>
      ) : null}
      {!isEpisodeComplete && hasUnreadMessages && (
        <View style={Styles.unreadMessagesContainer}>
          <TouchableOpacity onPress={scrollToBottom} style={Styles.unreadMessagesButton}>
            <Icon
              variant="materialIcons"
              size={20}
              name="arrow-downward"
              style={Styles.unreadMessagesIcon}
            />
            <Text style={Styles.unreadMessagesText}>
              {I18n.t('ConversationScreen.unreadMessages')}
            </Text>
          </TouchableOpacity>
        </View>
      )}
      {!isEpisodeComplete && shouldShowEstimatedTimeComponent && episode?.waitingStats && (
        <EstimatedTimeComponent
          seconds={episode.waitingStats.estimatedWaitingTime}
          positionInQueue={episode.waitingStats.positionInQueue}
        />
      )}
      {isEpisodeComplete && <CompleteCard />}
      <ResponsiveView>
        <InputBar scrollToBottom={scrollToBottom} enableSoftDisable={enableSoftDisablingChat} />
      </ResponsiveView>
    </View>
  )
}

export default Conversation
