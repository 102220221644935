import React, { useEffect, useRef } from 'react'
import { Animated, Pressable } from 'react-native'
import Typography from 'APP/Converse/Typography'
import Icon from 'APP/Converse/Icon'

import { CheckboxProps } from './types'
import styled from 'styled-components/native'

const ANIMATION_DURATION = 100

const Container = styled.View`
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.metrics.baseMargin / 2}px;
`

const Checkbox = ({ status, disabled, onPress, testID, label }: CheckboxProps) => {
  const { current: scaleAnim } = useRef<Animated.Value>(new Animated.Value(1))
  const isFirstTimeRendering = useRef<boolean>(true)

  useEffect(() => {
    // Do not run animation on very first rendering
    if (isFirstTimeRendering.current) {
      isFirstTimeRendering.current = false
      return
    }

    const checked = status === 'checked'

    Animated.sequence([
      Animated.timing(scaleAnim, {
        toValue: 0.85,
        duration: checked ? ANIMATION_DURATION : 0,
        useNativeDriver: false,
      }),
      Animated.timing(scaleAnim, {
        toValue: 1,
        duration: checked ? ANIMATION_DURATION : ANIMATION_DURATION * 1.75,
        useNativeDriver: false,
      }),
    ]).start()
  }, [status, scaleAnim])

  const checked = status === 'checked'
  const indeterminate = status === 'indeterminate'
  const icon = indeterminate ? 'minus-box' : checked ? 'checkbox-marked' : 'checkbox-blank-outline'
  const color = disabled ? 'disabled' : 'text'

  return (
    <Pressable
      onPress={onPress}
      disabled={disabled}
      // @ts-expect-error We keep old a11y props for backwards compat with old RN versions
      accessibilityTraits={disabled ? ['button', 'disabled'] : 'button'}
      accessibilityComponentType="button"
      accessibilityRole="checkbox"
      accessibilityState={{ disabled, checked }}
      accessibilityChecked={checked}
      accessibilityLiveRegion="polite"
      testID={testID}
    >
      <Container>
        <Animated.View style={{ transform: [{ scale: scaleAnim }] }}>
          <Icon variant="material" src={icon} size={24} color={color} />
        </Animated.View>
        {Boolean(label) && <Typography variant="subheader">{label}</Typography>}
      </Container>
    </Pressable>
  )
}

export default Checkbox
