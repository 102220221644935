import React from 'react'
import { Image } from 'react-native'
import { Appearance } from 'react-native-appearance'
import { useSelector } from 'react-redux'

import UrlBuilder from 'APP/Services/UrlBuilder'
import I18n from 'APP/Services/i18n'

import styled from 'styled-components/native'
import Config from 'APP/Config'
import Typography from 'APP/Converse/Typography'

const CobrandingContainer = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.metrics.baseMargin / 2}px;
  padding-vertical: ${({ theme }) => theme.metrics.baseMargin}px;
  ${(props) => props.customStyling}
`

const CobrandingImage = styled(Image)`
  resize-mode: contain;
  width: 120;
  height: 60;
`

const Cobranding = ({ style }) => {
  const cobrands = useSelector((state) => state.patient?.profile?.cobrands || [])

  const cobrandingImageURL =
    cobrands && cobrands.length === 1
      ? UrlBuilder.cobrandAssetUrl(cobrands[0], Appearance.getColorScheme(), I18n.baseLocale)
      : null

  if (!Config.COBRANDING_ENABLED) return null

  return (
    cobrandingImageURL && (
      <CobrandingContainer testID="cobrandingId" customStyling={style}>
        <Typography variant="bodyNormal">{I18n.t('Cobranding.broughtBy')}</Typography>
        <CobrandingImage source={{ uri: cobrandingImageURL }} />
      </CobrandingContainer>
    )
  )
}

export default Cobranding
