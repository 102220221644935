import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { View, ScrollView, Linking, ActivityIndicator } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'
import PushNotification from 'react-native-push-notification'
import { useNavigation } from '@react-navigation/native'

import Analytics from 'APP/Services/Analytics'
import I18n from 'APP/Services/i18n'
import {
  ICONS_MAPPING,
  getKeySnakeCase,
  buildNotificationCenterData,
} from 'APP/Lib/PreferenceHelpers'
import { Format } from 'APP/Lib/ProfileDataFormat'

import BulletListItem from 'APP/Components/BulletListItem'
import List from 'APP/Components/List'
import ListItem from 'APP/Converse/ListItem'
import NoticeBarContainer from 'APP/Components/NoticeBarContainer'
import SignupLogoHeader from 'APP/Components/SignupLogoHeader'
import ToggleCard from 'APP/Components/ToggleCard'
import { PrimaryButton } from 'APP/Components/Buttons'

import NotificationActions from 'APP/Redux/NotificationRedux'

import Styles from './style'
import Notification from 'APP/Images/notifications.svg'
import { Colors, Metrics } from 'APP/Themes'
import Typography from 'APP/Converse/Typography'
import NotificationsScreenIcon from 'APP/Images/Account/notifications-screen-icon.svg'
import { Divider } from 'react-native-elements'
import { isMobile, isWeb } from 'APP/Helpers/checkPlatform'
import { ResponsiveView } from 'APP/Converse/Layout'
import { featuresState } from 'APP/Sagas/FeaturesSaga'

// Exported for tests
export const NotificationsNotAllowed = () => {
  const handleOpenSettings = useCallback(async () => {
    Linking.openSettings()
  }, [])

  const bulletPoints = I18n.t('NotificationsScreen.notificationsNotAllowed.bulletPoints', {
    returnObjects: true,
  })

  useEffect(() => {
    Analytics.trackScreen('ENABLE PUSH')
  }, [])

  return (
    <View style={Styles.container}>
      <SignupLogoHeader
        title={I18n.t('NotificationsScreen.title')}
        subtitle={
          <Typography style={Styles.boldNotify}>
            {I18n.t('NotificationsScreen.notificationsNotAllowed.body')}
          </Typography>
        }
      />
      <Typography style={Styles.textBulletPoint}>{bulletPoints[0]}</Typography>
      <BulletListItem bulletStyle={Styles.bulletStyle}>
        <Typography style={Styles.textBulletPoint}>{bulletPoints[1]}</Typography>
      </BulletListItem>
      <BulletListItem bulletStyle={Styles.bulletStyle}>
        <Typography style={Styles.textBulletPoint}>{bulletPoints[2]}</Typography>
      </BulletListItem>
      <View style={Styles.imageSection}>
        <Notification
          fillLine={Colors.text}
          fillLineInverted={Colors.textInverted}
          width={Metrics.screenWidth - Metrics.baseMargin}
        />
      </View>
      <PrimaryButton
        style={Styles.primaryButton}
        title={I18n.t('NotificationsScreen.notificationsNotAllowed.cta')}
        analyticsName={'NotificationsScreen.goToDeviceSettings'}
        onPress={handleOpenSettings}
      />
    </View>
  )
}

// Exported for tests
export const NotificationsAllowed = ({ preferences, loading, profile = {} }) => {
  const [clickedKey, setClickedKey] = useState(undefined)
  const dispatch = useDispatch()

  useEffect(() => {
    Analytics.trackScreen('NOTIFICATION PREFERENCES')
  }, [])

  const onToggleChange = (key, isToggled) => {
    setClickedKey(key)
    const snakeKey = getKeySnakeCase(key)
    const data = {
      [snakeKey]: isToggled,
    }
    Analytics.trackEvent('button_click', {
      button_value: `${key} ${isToggled}`,
    })

    dispatch(NotificationActions.updateNotificationPreference(data))
  }

  const renderToggleCard = (key, value) => {
    const titleKey = `NotificationsScreen.notificationsAllowed.${key}.title`
    const descriptionKey = `NotificationsScreen.notificationsAllowed.${key}.description`
    const labelKey = `NotificationsScreen.notificationsAllowed.${key}.label`
    const hasTitleAndDescription = I18n.exists(titleKey) && I18n.exists(descriptionKey)

    const description =
      key === 'appointmentReminders'
        ? I18n.t(descriptionKey, {
            phoneNumber: Format.phoneNumber(profile.phoneNumber),
          })
        : I18n.t(descriptionKey)

    return (
      hasTitleAndDescription && (
        <View style={Styles.toggleCardContainer} key={key}>
          <ToggleCard
            testID={`notifications${key}`}
            title={I18n.t(titleKey)}
            description={description}
            label={I18n.t(labelKey)}
            isToggled={value}
            onToggleChange={() => onToggleChange(key, !value)}
            isLoading={clickedKey === key && loading}
          />
          <Divider />
        </View>
      )
    )
  }

  return (
    <View style={Styles.notificationsAllowedContainer}>
      <View style={Styles.toggleCardContainer}>
        {isMobile() && (
          <ToggleCard
            testID={'NOTIFICATIONS_MESSAGES_VIDEO_CALLS'}
            title={I18n.t('NotificationsScreen.notificationsAllowed.messagesAndVideoCalls.title')}
            description={I18n.t(
              'NotificationsScreen.notificationsAllowed.messagesAndVideoCalls.description'
            )}
            label={I18n.t('NotificationsScreen.notificationsAllowed.messagesAndVideoCalls.label')}
            disabled={true}
            isToggled={true}
          />
        )}
        {Object.entries(preferences).length > 1 && <Divider />}
      </View>

      {preferences &&
        Object.entries(preferences).map(([key, value]) => renderToggleCard(key, value))}
    </View>
  )
}

const NotificationCenter = ({ preferences }) => {
  const navigation = useNavigation()
  const data = buildNotificationCenterData(preferences) || []

  const renderListItem = (key) => {
    const titleKey = `NotificationsScreen.notificationCenter.${key}.title`
    const typesKey = `NotificationsScreen.notificationCenter.${key}.types`
    const hasTitleAndSubtitle = I18n.exists(titleKey) && I18n.exists(typesKey)

    if (!hasTitleAndSubtitle) return null

    return (
      <ListItem
        key={key}
        leftIconName={ICONS_MAPPING[key]}
        title={I18n.t(titleKey)}
        titleVariant={'subheader'}
        subtitle={I18n.t(typesKey)}
        iconName="chevron-right"
        containerStyle={Styles.listItem}
        onPress={() =>
          navigation.navigate('notificationDetail', {
            notification: key,
          })
        }
      />
    )
  }

  return (
    <View style={Styles.notificationCenterContainer} testID="notification-center">
      <Typography variant="h2">{I18n.t('NotificationsScreen.notificationCenter.title')}</Typography>
      <Typography variant="bodySmall">
        {I18n.t('NotificationsScreen.notificationCenter.subtitle')}
      </Typography>

      {data && (
        <List containerStyle={Styles.notificationListContainer}>
          {data.map((key) => renderListItem(key))}
        </List>
      )}

      <Divider style={Styles.divider} />

      <Typography variant="bodySmall">
        {I18n.t('NotificationsScreen.notificationCenter.habitReminder')}
      </Typography>
    </View>
  )
}

const NotificationsScreen = () => {
  const { isActive } = useSelector((state) => state.appSession)
  const { preferences } = useSelector((state) => state.notifications)
  const { eligibleServices, phoneNumber } = useSelector((state) => state.patient.profile)
  const hasWellnessChallenges = eligibleServices?.wellness_challenges
  const hasWBI = eligibleServices?.wellbeing_index_assessment
  const [allowNotifications, setAllowNotifications] = useState(undefined)
  const { newNotificationCenter } = useSelector(featuresState)

  const eligiblePreferences = useMemo(
    () =>
      Object.entries(preferences?.data || {}).reduce((nextEligiblePreferences, [key, value]) => {
        let isEligible = true
        switch (key) {
          case 'challengeUpdates':
            isEligible = hasWellnessChallenges
            break
          case 'wbiReminders':
            isEligible = hasWBI
            break
        }

        if (isEligible) nextEligiblePreferences[key] = value

        return nextEligiblePreferences
      }, {}),
    [preferences, hasWellnessChallenges, hasWBI]
  )

  // Re-check notification permissions when the app is backgrounded or foregrounded
  useEffect(() => {
    if (isWeb()) {
      setAllowNotifications(true)
      return
    }

    PushNotification.checkPermissions((result) => {
      setAllowNotifications((previousState) => {
        const permission = result?.alert
        if (permission !== previousState) {
          const screenName = permission ? 'NOTIFICATION PREFERENCES' : 'ENABLE PUSH'
          Analytics.trackScreen(screenName)
        }
        return result?.alert
      })
    })
  }, [isActive])

  return (
    <NoticeBarContainer>
      <ScrollView style={Styles.scrollContainer}>
        <ResponsiveView>
          <View style={Styles.notificationsHeader}>
            <NotificationsScreenIcon fillLine={Colors.text} accentFillLine={Colors.accent} />
            <Typography variant="h2">{I18n.t('NotificationsScreen.header.title')}</Typography>
            <Typography variant="subheader" style={Styles.description}>
              {I18n.t('NotificationsScreen.header.subtitle')}
            </Typography>
            <Divider style={Styles.headerDivider} />
          </View>
          {allowNotifications === undefined && (
            <ActivityIndicator
              size="large"
              color={Colors.text}
              style={Styles.loading}
              testID="loading"
            />
          )}
          {allowNotifications === false && <NotificationsNotAllowed />}
          {allowNotifications && !newNotificationCenter && (
            <NotificationsAllowed
              preferences={eligiblePreferences}
              loading={preferences?.loading}
              profile={{ phoneNumber }}
            />
          )}
          {allowNotifications && newNotificationCenter && (
            <NotificationCenter preferences={eligiblePreferences} />
          )}
        </ResponsiveView>
      </ScrollView>
    </NoticeBarContainer>
  )
}

export default NotificationsScreen
