import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

// Services & Helpers
import { RootScreens } from 'APP/Lib/Enums'
import I18n from 'APP/Services/i18n'
import { shouldRenderComponent } from 'APP/Lib/ContentHelpers'

// Containers
import TodayScreen from 'APP/Containers/TodayScreen'
import CmsAppTabScreen from 'APP/Containers/CmsAppTabScreen'

// Components
import { TabIcon } from 'APP/Nav/Components/TabIcon'

export const primaryScreens = [
  { name: RootScreens.today, component: TodayScreen, options: { headerShown: false } },
  { name: RootScreens.move, component: CmsAppTabScreen },
  { name: RootScreens.mind, component: CmsAppTabScreen },
  { name: RootScreens.chat, component: CmsAppTabScreen },
  { name: RootScreens.browse, component: CmsAppTabScreen },
]

export const withTabBarIcons = (tabs) =>
  tabs.map((tab) => ({
    ...tab,
    options: {
      ...tab?.options,
      tabBarIcon: (props) => <TabIcon name={tab?.options?.tabBarIconName || tab?.name} {...props} />,
    },
  }))

export const usePrimaryTabs = () => {
  const eligibleServices = useSelector((state) => state?.patient?.profile?.eligibleServices)
  const orderedEpisodes = useSelector((state) => state?.history?.orderedEpisodes)
  const carePlans = useSelector((state) => state?.history?.completedCarePlans)
  const cmsLayout = useSelector((state) => state.content?.layout?.data?.screens)
  const hasUnreadChannels = useSelector((state) => state.history?.hasUnreadChannels)

  const tabs = useMemo(() => {
    if (!cmsLayout) return []

    // Merge the hardcode redesignNavItems and the CMS content, filtering based on any service filters sent by the CMS
    return cmsLayout?.reduce((normalizedNavItems, screen) => {
      const navItem = primaryScreens.find(({ name }) => screen.mobile_scene_key === name)

      let shouldRenderScreen = shouldRenderComponent(screen.service_filter, eligibleServices)

      // Special case for inactive members to access their episodes and care plans
      if (screen.mobile_scene_key === RootScreens.chat) {
        shouldRenderScreen =
          shouldRenderScreen || orderedEpisodes?.length > 0 || carePlans?.length > 0
      }

      if (navItem && shouldRenderScreen) {
        const tabLabel = I18n.exists(`NavigationNameOverwrite.${navItem.name}`)
          ? I18n.t(`NavigationNameOverwrite.${navItem.name}`)
          : screen.title || navItem.name
        const tabBarIconName = I18n.exists(`NavigationIconNameOverwrite.${navItem.name}`)
          ? I18n.t(`NavigationIconNameOverwrite.${navItem.name}`)
          : screen.nav_icon_name
        const options = {
          ...navItem.options,
          title: tabLabel,
          tabBarIconName: tabBarIconName,
          tabBarLabel: tabLabel,
          tabBarBadge: (screen.mobile_scene_key === RootScreens.chat && hasUnreadChannels) || null,
        }
        normalizedNavItems.push({ ...navItem, options })
      }

      return normalizedNavItems
    }, [])
  }, [cmsLayout, eligibleServices, hasUnreadChannels])

  return withTabBarIcons(tabs)
}

export const useSecondaryTabs = () => {
  const eligibleServices = useSelector((state) => state?.patient?.profile?.eligibleServices)
  const isWellBeingIndexAssessmentEligible = Boolean(eligibleServices?.wellbeing_index_assessment)

  return useMemo(() => {
    const tabs = [
      {
        name: 'account',
        options: {
          tabBarLabel: I18n.t('AccountScreen.title'),
        },
      },
    ]

    if (isWellBeingIndexAssessmentEligible) {
      tabs.push({
        name: 'wellBeingIndex',
        options: {
          tabBarLabel: I18n.t('WellBeingIndexScreen.title'),
        },
      })
    }

    return withTabBarIcons(tabs)
  }, [isWellBeingIndexAssessmentEligible])
}
