import React, { useContext } from 'react'
import PortalContext from './PortalContext'

interface PortalPreviewProps {
  name: string
}
const PortalPreview = ({ name }: PortalPreviewProps) => {
  const { getComponent } = useContext(PortalContext)

  return <>{getComponent(name)}</>
}
export default PortalPreview
