import { StyleSheet } from 'react-native'
import { Colors } from 'APP/Themes'

export const Styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    backgroundColor: Colors.appBg,
  },
  scroll: {
    flex: 1,
    backgroundColor: Colors.appBg,
  },
  scrollContentStyle: {
    flex: 1,
  },
})
