import React, { ReactNode, useEffect, useRef } from 'react'
import { ViewStyle } from 'react-native'
import Animated, {
  cancelAnimation,
  Easing,
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated'
import { Colors } from 'APP/Themes'

interface IntakePulsatingViewProps {
  children: ReactNode
  style?: ViewStyle
}

const IntakePulsatingView = ({ children, style }: IntakePulsatingViewProps) => {
  const color = useSharedValue(0)

  const animatedStyle = useAnimatedStyle(() => {
    const animatedColor = interpolateColor(color.value, [0, 1], [Colors.appBg, Colors.appPulseBg])

    return {
      backgroundColor: animatedColor,
    }
  })

  useEffect(() => {
    color.value = withRepeat(
      withTiming(1, {
        duration: 2000,
        easing: Easing.inOut(Easing.ease),
      }),
      -1,
      true
    )
    return () => cancelAnimation(color)
  }, [])

  return <Animated.View style={[style, animatedStyle]}>{children}</Animated.View>
}

export default IntakePulsatingView
